import { useEffect } from 'react';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { useGetPipelineQuery } from 'store/entities/hiring-pipelines/hiring-pipelines.api';
import {
  hiringPipelineActions,
  hiringPipelineSelectors,
} from 'store/entities/hiring-pipelines/hiring-pipelines.reducer';

type UseSinglePipelineProps = {
  pipelineId?: string;
  jobId?: string;
};

export const useSinglePipeline = ({ pipelineId: propsPipelineId = '', jobId = '' }: UseSinglePipelineProps) => {
  const dispatch = useAppDispatch();

  const jobPipelineId = useAppSelector(hiringPipelineSelectors.selectPipelineIdByJobId, jobId);

  const pipelineId = propsPipelineId || jobPipelineId;

  useEffect(() => {
    if (!pipelineId) {
      return;
    }

    dispatch(hiringPipelineActions.setSinglePipelineId(pipelineId));
  }, [dispatch, pipelineId]);

  return useGetPipelineQuery({ pipelineId }, { skip: !pipelineId });
};
