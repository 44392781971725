export const MODAL_SHOW = 'modal/show';
export const MODAL_PROPS_UPDATE = 'modal/props/update';
export const MODAL_RESULT_UPDATE = 'modal/result/update';
export const MODAL_CANCEL = 'modal/cancel';
export const MODAL_CONFIRM = 'modal/confirm';
export const MODAL_HIDE = 'modal/hide';

export const MODAL_WIZARD_FORWARD = 'modal/wizard/forward';
export const MODAL_WIZARD_BACKWARD = 'modal/wizard/backward';
export const MODAL_WIZARD_ERROR = 'modal/wizard/error';

export const MODAL_PAGE_UPDATE = 'modal/page/update';
export const MODAL_NAVIGATION_UPDATE = 'modal/navigation/update';
