import { call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { invokeExModal, prepareExModalChannel } from 'utils/sagas';

import { exModalHideAction } from 'store/modals/modals.actions';

import * as questionsActions from './interview-template-questions.actions';
import { interviewTemplateQuestionsApi } from './interview-template-questions.api';

function* questionDeleteModalShowWorker(
  action: ReturnType<typeof questionsActions.interviewTemplateQuestionDeleteModalShowAction>,
) {
  const { modalId, sagaChannel } = yield prepareExModalChannel();

  const selector: ReturnType<typeof interviewTemplateQuestionsApi.endpoints.questionList.select> = yield call(
    interviewTemplateQuestionsApi.endpoints.questionList.select,
    action.payload.interviewTemplateId,
  );

  const { data }: ReturnType<typeof selector> = yield select(selector);

  const question = data?.entities[action.payload.questionId];

  yield fork(invokeExModal, {
    modalId,
    channel: sagaChannel,
    modalType: ModalsTypeKey.confirmModal,
    modalConfig: {
      content: {
        withTitle: true,
        title: 'Confirm Remove',
        withActions: true,
        buttonOk: 'Remove',
        buttonOkVariant: 'danger',
        message: `You confirm remove the question\n "${question?.questionText}"`,
      },
    },
  });
  const { cancel } = yield take(sagaChannel);
  if (cancel) {
    return;
  }
  const thunk = yield call(interviewTemplateQuestionsApi.endpoints.deleteQuestion.initiate, {
    interviewTemplateId: action.payload.interviewTemplateId,
    interviewTemplateQuestionId: action.payload.questionId,
  });

  yield put(thunk);

  yield put(exModalHideAction({ id: modalId }));
}
export function* questionSagaWatcher() {
  yield takeEvery(questionsActions.interviewTemplateQuestionDeleteModalShowAction, questionDeleteModalShowWorker);
}
