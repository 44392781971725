import { createSelector, Dictionary, EntityAdapter, EntityId, EntityState } from '@reduxjs/toolkit';

import { PaginationState } from 'store/app-files/appFiles.models';
import { RootState } from 'store/rootReducer';

export function createSelectors<T, Q>(
  domain: (root: RootState) => EntityState<T> & PaginationState<T, Q>,
  adapter: EntityAdapter<T>,
) {
  type ReversDict<Dictionary> = Dictionary[keyof Dictionary];
  type Domain = EntityState<T> & PaginationState<T, Q>;

  const { selectAll, selectById, selectEntities, selectIds, selectTotal } = adapter.getSelectors(domain);

  type Lists = (state: RootState) => Domain['pagination']['lists'];
  const selectLists: Lists = createSelector(domain, (state) => state.pagination.lists);

  type ListById = (state: RootState, id: string) => ReversDict<ReturnType<Lists>>;
  const selectListById: ListById = createSelector(
    selectLists,
    (_: RootState, id: string) => id,
    (lists, id) => lists[id],
  );

  type QueryReturnType = ReversDict<
    Omit<ReversDict<Domain['pagination']['lists']>, 'loading' | 'error' | 'prev' | 'mostRecent'>
  >;

  type Query = (state: RootState, id: string) => QueryReturnType | undefined;
  const selectQuery: Query = createSelector(selectListById, (list) => {
    const queryId = list?.mostRecent?.queryId;
    if (list && queryId) {
      return list[queryId];
    }
  });

  type PageReturnType = ReversDict<Omit<QueryReturnType, 'queryParams'>>;

  type Page = (state: RootState, id: string) => PageReturnType | undefined;
  const selectPage: Page = createSelector(selectListById, selectQuery, (listById, query) => {
    const pageId = listById?.mostRecent?.pageId;
    if (query && pageId) {
      return query[pageId];
    }
  });

  type PageIds = (state: RootState, id: string) => EntityId[] | undefined;
  const pageIds: PageIds = createSelector(selectPage, (page) => {
    if (page) {
      return page.ids;
    }
  });

  type QueryParams = () => (
    state: RootState,
    id: string,
  ) => ReversDict<Pick<QueryReturnType, 'queryParams'>> | undefined;
  const selectQueryParams: QueryParams = () =>
    createSelector(selectListById, (list) => {
      const pageId = list?.mostRecent?.pageId;
      const queryId = list?.mostRecent?.queryId;
      if (queryId && pageId && list) {
        const query = list[queryId];
        if (query) {
          return query.queryParams;
        }
      }
    });

  type PageParams = () => (state: RootState, id: string) => PageReturnType['pageParams'] | undefined;
  const pageParams: PageParams = () =>
    createSelector(selectPage, (page) => {
      if (page) {
        return page.pageParams;
      }
    });

  function getEntitiesById(entities: Dictionary<T>, ids: EntityId[] | undefined) {
    if (ids) {
      return ids.reduce<T[]>((acc, id) => {
        const candidateItem = entities[id];
        if (candidateItem) {
          return [...acc, candidateItem];
        }
        return acc;
      }, []);
    }
  }

  type PageEntities = () => (state: RootState, id: string) => T[] | undefined;
  const selectPageE = createSelector(selectEntities, pageIds, getEntitiesById);
  const pageEntities: PageEntities = () => selectPageE;

  const isListLoadingById: () => (state: RootState, id: string) => boolean = () =>
    createSelector(selectListById, (list) => !!list?.loading);

  const listLastFetchErrorById: () => (state: RootState, id: string) => string | undefined = () =>
    createSelector(selectListById, (list) => list?.error);

  const selectSelectedIds = createSelector(selectListById, (list) => list?.selectedIds);
  const selectedItemsIds: () => (state: RootState, id: string) => Array<EntityId> | undefined = () => selectSelectedIds;

  type PageEntitiesWithSelected = () => (state: RootState, id: string) => T[] | undefined;
  const pageEntitiesWithSelected: PageEntitiesWithSelected = () =>
    createSelector(selectPageE, selectSelectedIds, (entities, selectedIds) => {
      return entities?.map((entity) => ({
        ...entity,
        selected: selectedIds?.includes(adapter.selectId(entity)),
      }));
    });

  type SelectedEntities = () => (state: RootState, id: string) => T[] | undefined;
  const selectedEntities: SelectedEntities = () => createSelector(selectEntities, selectSelectedIds, getEntitiesById);

  return {
    isListLoadingById,
    listById: selectListById,
    listLastFetchErrorById,
    page: selectPage,
    pageEntities,
    pageEntitiesWithSelected,
    pageParams,
    query: selectQuery,
    queryParams: selectQueryParams,
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,
    selectedEntities,
    selectedItemsIds,
  };
}
