import React from 'react';
import styled from 'styled-components';

import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { DialogProps } from 'components/Dialog';
import { ExDialog, ExDialogContent } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import * as fromModalsActions from 'store/modals/modals.actions';
import { ExModal } from 'store/modals/modals.interfaces';

export interface ConfirmModalProps extends ExModal {}

export const ConfirmModalStyled = styled(ExDialog)`
  max-height: 80vh;
`;

export const ConfirmModalBasic: React.FC<ConfirmModalProps> = ({ id, modalConfig }) => {
  const { content } = modalConfig || {};
  const {
    buttonOk = 'Confirm',
    buttonCancel = 'Cancel',
    message = 'default confirm modal message',
    title,
    withTitle,
    withActions,
    buttonCancelVariant,
    buttonOkVariant,
    status,
    children,
  } = content || {};

  const dialogContent: ExDialogContent = {
    message,
    withTitle,
    title,
    withActions,
    buttonOk,
    buttonCancel,
    buttonCancelVariant,
    buttonOkVariant,
    status,
    children,
  };

  const dispatch = useAppDispatch();

  const onCloseDialogHandler: DialogProps['onClose'] = (result) => {
    if (result) {
      dispatch(
        fromModalsActions.exModalConfirmAction({
          id,
          modalType: ModalsTypeKey.confirmModal,
          modalResult: { reason: 'User click Confirm' },
        }),
      );
    } else {
      dispatch(fromModalsActions.exModalCancelAction({ id, modalResult: { reason: 'User Click Close' } }));
    }
  };

  return <ConfirmModalStyled onClose={onCloseDialogHandler} content={dialogContent} />;
};

export const ConfirmModal = ConfirmModalBasic;
