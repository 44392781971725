import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UIKeys } from 'store/constants';

export const initialState = [] as Task[];

interface Task {
  id: string;
  silent: boolean;
}

export default createSlice({
  name: UIKeys.loader,
  initialState,
  reducers: {
    start(state, action: PayloadAction<string>) {
      const taskId = action.payload;
      state.push({ id: taskId, silent: false });
    },
    startSilent(state, action: PayloadAction<string>) {
      const taskId = action.payload;
      state.push({ id: taskId, silent: true });
    },
    stop(state, action: PayloadAction<string | Task>) {
      const taskId = action.payload;
      return state.filter(activeTask => activeTask.id !== taskId);
    },
  },
});
