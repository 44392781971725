import type { DropdownItemProps } from 'react-bootstrap/DropdownItem';
import { SelectCallback } from 'react-bootstrap/helpers';

export enum Status {
  visible,
  hidden,
  locked,
}

export interface DropdownProps {
  menuItems: MenuItem[];
  buttonId: string;
  className?: string;
  mainLabel?: string;
  onSelect?: SelectCallback;
  disabled?: boolean;
  alignRight?: boolean;
  dropDirection?: 'up' | 'left' | 'right' | 'down';
}

export interface MenuItem extends DropdownItemProps {
  label: string;
  action?: (...props: any) => void;
  getStatus?: (...props: any) => Status | undefined;
  handlers?: Record<Partial<Status>, (...props: any) => void>;
  variant?: any;
  title?: string;
  feature?: string;
  [x: string]: any;
}
