import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useAppRouterParams } from 'router';
import isEqualWith from 'lodash/isEqualWith';

import { JobStatus } from 'model/api-enums.constants';

import { jobNameFieldName } from 'pages/Jobs/CreateJobV2/constants';
import { useIsAuthUserCanEditJob } from 'pages/Jobs/Job/hooks/useIsAuthUserCanEditJob';

import { jobFormSelectors } from 'containers/JobForms/state';
import { jobFormActions } from 'containers/JobForms/state/reducer';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { jobScreeningQuestionsSelectors } from 'store/entities/job-screening-questions';
import { jobCreateRequest } from 'store/entities/jobs/job.actions';

const compareFormValues = (leftValue, rightValue) => {
  if (Number(leftValue) === Number(rightValue)) {
    return true;
  }
};

export const useCheckFormHasChanges = () => {
  const { getValues } = useFormContext();

  const formData = useAppSelector(jobFormSelectors.selectFormData);

  const isScreeningQuestionsNotChanged = useAppSelector(
    jobScreeningQuestionsSelectors.selectIsScreeningQuestionsNotChanged,
    formData?.jobId,
  );

  const checkFormHasChanges = useCallback(() => {
    const isSameForm = isEqualWith(getValues(), formData, compareFormValues);
    const isFormUnchanged = isSameForm && isScreeningQuestionsNotChanged;

    return !isFormUnchanged;
  }, [getValues, formData, isScreeningQuestionsNotChanged]);

  return {
    checkFormHasChanges,
  };
};

export const useJobAutoSaveHandler = () => {
  const dispatch = useAppDispatch();

  const { getValues } = useFormContext();

  const jobAutoSaveHandler = useCallback(() => {
    const form = getValues();

    if (!form[jobNameFieldName].trim()) {
      return;
    }

    dispatch(jobFormActions.saveForm({ form }));

    dispatch(jobCreateRequest({ isAutosave: true }));
  }, [getValues, dispatch]);

  return {
    jobAutoSaveHandler,
  };
};

export const useJobSubmitHandler = () => {
  const dispatch = useAppDispatch();

  const jobOpenSubmitHandler = useCallback(
    (form) => {
      dispatch(jobFormActions.saveForm({ form }));

      dispatch(jobCreateRequest({ asOpen: true }));
    },
    [dispatch],
  );

  const jobEditSubmitHandler = useCallback(
    (form) => {
      dispatch(jobFormActions.saveForm({ form }));

      dispatch(jobCreateRequest({ isOpenEdit: true }));
    },
    [dispatch],
  );

  return {
    jobOpenSubmitHandler,
    jobEditSubmitHandler,
  };
};

export const useIsDisabledJobEditByRole = () => {
  const { jobId: jobIdFromUrl } = useAppRouterParams();

  const { hasAccess } = useIsAuthUserCanEditJob(jobIdFromUrl!);

  const isDisabledJobEditByRole = !!jobIdFromUrl && !hasAccess;

  return {
    isDisabledJobEditByRole,
  };
};

export const useJobNameBlurHandler = () => {
  const { getValues, trigger } = useFormContext();

  const formDataJobName = useAppSelector(jobFormSelectors.selectFormDataJobName);

  const { jobAutoSaveHandler } = useJobAutoSaveHandler();

  const jobNameBlurHandler = useCallback(async () => {
    const jobNameValue = getValues(jobNameFieldName);

    if (jobNameValue === formDataJobName) {
      return;
    }

    const jobStatusValue = getValues('status');

    if (jobStatusValue !== JobStatus.draft) {
      return;
    }

    const isValid = await trigger(jobNameFieldName);

    if (!isValid) {
      return;
    }

    jobAutoSaveHandler();
  }, [getValues, trigger, jobAutoSaveHandler, formDataJobName]);

  return {
    jobNameBlurHandler,
  };
};

export const useFormChanged = () => {
  const { control } = useFormContext();

  const values = useWatch({ control });

  const formData = useAppSelector(jobFormSelectors.selectFormData);

  const isSameForm = isEqualWith(values, formData, compareFormValues);

  const isScreeningQuestionsNotChanged = useAppSelector(
    jobScreeningQuestionsSelectors.selectIsScreeningQuestionsNotChanged,
    formData?.jobId,
  );

  const isFormUnchanged = isSameForm && isScreeningQuestionsNotChanged;

  return {
    isFormUnchanged,
    isFormChanged: !isFormUnchanged,
  };
};
