import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  emailTemplateActions,
  EmailTemplateModel,
  emailTemplateSelectors,
} from 'pages/Company/CompanyTabs/CompanyEmailTemplateTab';

import { Form, FormSelect } from 'components';
import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { ExDialogActionButton, ExDialogActions, ExDialogFooter } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { ModalStepProps } from 'store/modals/config';
import { exModalConfirmAction } from 'store/modals/modals.actions';

import { backupIdValidationSchema, ModalBodyStyled } from './remove-with-backup-id.shared';

const EmailTemplateSelectFormBody = ({ idForExclude }: { name: string; idForExclude: string }) => {
  const formMethods = useFormContext();

  const emailTemplates = useAppSelector(emailTemplateSelectors.selectEmailTemplatesForReassignExcludeByIds, [
    idForExclude,
  ]);

  return (
    <FormSelect
      name="backupId"
      required
      label="Select a new email"
      options={emailTemplates}
      validated={formMethods.formState.isSubmitted}
      errors={formMethods.errors['backupId']}
      getOptionLabel={(option: EmailTemplateModel) => option?.emailTitle}
      getOptionValue={(option: EmailTemplateModel) => option?.templateId}
    />
  );
};

type EmailTemplateSelectModalProps = ModalStepProps & { modalProps: { idForExclude: string } };

const useEmailTemplateSelectModalState = ({ id, modalProps, onCloseDialogHandler }: EmailTemplateSelectModalProps) => {
  const { idForExclude } = modalProps;

  const dispatch = useAppDispatch();

  const submitHandler = useCallback(
    (form: any) => {
      dispatch(exModalConfirmAction({ id, modalResult: form }));
    },
    [dispatch, id],
  );

  return { idForExclude, onCloseDialogHandler, submitHandler } as const;
};

export const EmailTemplateSelectModal: React.FC<EmailTemplateSelectModalProps> = (props) => {
  const { submitHandler, onCloseDialogHandler, idForExclude } = useEmailTemplateSelectModalState(props);

  return (
    <ModalBodyStyled>
      <Form validationSchema={backupIdValidationSchema} onSubmit={submitHandler}>
        <EmailTemplateSelectFormBody name="pipelineIdBody" idForExclude={idForExclude} />
        <ExDialogFooter>
          <ExDialogActions>
            <ExDialogActionButton onClick={() => onCloseDialogHandler()} variant="light">
              Cancel
            </ExDialogActionButton>
            <ExLoaderHandlerWrapper action={emailTemplateActions.removeSaga}>
              <ExDialogActionButton type="submit">Save</ExDialogActionButton>
            </ExLoaderHandlerWrapper>
          </ExDialogActions>
        </ExDialogFooter>
      </Form>
    </ModalBodyStyled>
  );
};
