import React from 'react';
import styled from 'styled-components/macro';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { jobsListUiSlice } from 'store/ui/jobs/jobs-list/jobs-list.reducer';

type JobListClearFiltersProps = {
  className?: string;
};

const JobListClearFiltersStyled = styled.button`
  border: 0;
  background: none;
  padding: 0 10px;
  margin: 0 0 0 10px;
`;

const useJobListClearFiltersState = ({ className }: JobListClearFiltersProps) => {
  const dispatch = useAppDispatch();

  const onClickHandler = () => {
    dispatch(jobsListUiSlice.actions.resetFilters());
  };

  return {
    className,
    onClickHandler,
  } as const;
};

export const JobListClearFilters: React.FC<JobListClearFiltersProps> = (props) => {
  const { className, onClickHandler } = useJobListClearFiltersState(props);

  return (
    <JobListClearFiltersStyled className={className} onClick={onClickHandler}>
      Clear
    </JobListClearFiltersStyled>
  );
};
