import { PaginationHookArgument } from 'components/ListView/ListViewProps';

/**
 * Custom hook for generate pages for pagination.
 *
 * @description Custom hook take current page and total page and returns array of page number for pagination.
 * @param   {PaginationHookArgument} { pageCount, pageNo } current page and total page
 *
 * @return  {number[]}                Array of pages for pagination
 */
export function useCalculatePages({ pageCount, pageNo }: PaginationHookArgument): number[] {
  const length = 6;
  const list = Array.from(Array(pageCount).keys());
  const arr: number[] = [];
  for (let i = 0; i < length; i++) {
    if (i === 0) {
      arr.push(list[pageNo]);
    } else {
      arr.push(list[pageNo + i]);
      arr.unshift(list[pageNo - i]);
    }
  }

  const filtered = arr.filter((item) => item !== undefined);

  const itemsToTrim = Math.floor((filtered.length - length) / 2);
  const firstItem =
    filtered.length > length && filtered.indexOf(pageNo) - itemsToTrim > length / 2 ? itemsToTrim + 1 : itemsToTrim;
  return filtered.splice(firstItem > 0 ? firstItem : 0, length);
}
