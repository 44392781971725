import React, { useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Routes } from 'router';
import styled from 'styled-components/macro';

import { PipelineStageType } from 'model/api-enums.constants';
import { AppRouterParams } from 'model/router';

import { useSinglePipeline } from 'pages/Jobs/Job/hooks/useSinglePipeline';

import { loadApplicant } from 'containers/ApplicantLists/store/effects';
import {
  ActionButtonStyled,
  ButtonsWrapper,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalFooter/ApplicantModalFooterComponents';
import { applicantModalSlice } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/store';
import { isApplicantsModalPath } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/utils';

import { IconArrowLeft } from 'components/Icons/IconArrowLeft';
import { IconArrowRight } from 'components/Icons/IconArrowRight';
import { ExVisible } from 'components/ui/ExVisible';
import { useLoaderSubscription } from 'modules/LoaderManager/react';
import { useApplicantsByLocation } from 'utils/hooks/applicant';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useKeyPress } from 'utils/hooks/useKeyPress';
import { useNavigation } from 'utils/hooks/useNavigation';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { Applicant, applicantsActions, applicantSelectors } from 'store/entities/applicants';
import { hiringPipelinesStagesSelectors } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.reducer';
import { hiringPipelineSelectors } from 'store/entities/hiring-pipelines/hiring-pipelines.reducer';

type ApplicantModalFooterViewProps = {
  className?: string;
  isCandidateView?: boolean;
};

const ApplicantModalFooterViewStyled = styled(ButtonsWrapper)``;

const ActionButtonStyledPrev = styled(ActionButtonStyled)`
  margin-left: 0;
  margin-right: auto;
  min-width: 175px;
`;

const ActionButtonStyledNext = styled(ActionButtonStyled)`
  margin-left: auto;
  margin-right: 0;
  min-width: 175px;
`;

const getPipelineStageId = (object): string => {
  return object?.pipelineStageId ?? '';
};

const getJobId = ({ applicant, params }: { applicant?: Applicant; params: AppRouterParams }) => {
  return applicant?.jobId ?? params.jobId;
};

const getBtnText = (isCandidateView?: boolean) => {
  const prevBtnText = isCandidateView ? 'Previous Application' : 'Previous Applicant';
  const nextBtnText = isCandidateView ? 'Next Application' : 'Next Applicant';

  return {
    prevBtnText,
    nextBtnText,
  };
};

export const usePushToSiblingApplicantConfig = () => {
  const match = useRouteMatch<AppRouterParams>();
  const { applicantId } = match.params;

  const { pathname } = useLocation();
  const [applicantsModalTab, setApplicantsModalTab] = useState(Routes.applicantOverview as string);

  const applicant = useAppSelector(applicantSelectors.selectApplicantModalData, applicantId);
  const applicants = useApplicantsByLocation(applicant);

  const applicantIndexOf = applicant
    ? applicants.findIndex((applicantItem) => applicantItem.applicantId === applicantId)
    : -1;
  const applicantIndex = applicantIndexOf > -1 ? applicantIndexOf : 0;

  useEffect(() => {
    const matchedPath = isApplicantsModalPath(pathname);

    if (matchedPath && matchedPath.params.applicantsModalTab) {
      setApplicantsModalTab(matchedPath.params.applicantsModalTab);
    }
  }, [pathname]);

  return (direction: 'prev' | 'next') => {
    const dir = direction === 'prev' ? 1 : -1;
    const index = applicantIndex - dir;
    let nextIndex: number;

    if (index < 0) {
      nextIndex = applicants.length - 1;
    } else if (index >= applicants.length) {
      nextIndex = 0;
    } else {
      nextIndex = index;
    }

    const path = match.path + `/${applicantsModalTab}`;

    return {
      config: {
        params: {
          ...match.params,
          applicantId: applicants[nextIndex].id,
          jobId: match.params.jobId,
        },
      },
      path,
    };
  };
};

const useApplicantModalFooterViewState = ({ className, isCandidateView }: ApplicantModalFooterViewProps) => {
  const dispatch = useAppDispatch();
  const { push, generatePath } = useNavigation();
  const match = useRouteMatch<AppRouterParams>();
  const { isLoading: isApplicantLoading } = useLoaderSubscription({ type: loadApplicant.name });
  const { isLoading: applicantsStageChangeProcessing } = useLoaderSubscription(applicantsActions.applicantsStageChange);
  const { applicantId } = match.params;

  const applicant = useAppSelector(applicantSelectors.selectApplicantModalData, applicantId);

  const applicants = useApplicantsByLocation(applicant);

  const applicantPipelineStageId = getPipelineStageId(applicant);

  const jobId = getJobId({ applicant, params: match.params });

  useSinglePipeline({ jobId, pipelineId: applicant?.pipelineId });

  const isNewApplicant = useAppSelector(hiringPipelinesStagesSelectors.selectIsNewStage, applicantPipelineStageId);

  const firstStageButtonLabel = useAppSelector(hiringPipelineSelectors.selectFirstStageButtonLabelByJobId, jobId);
  const declineButtonLabel = useAppSelector(hiringPipelineSelectors.selectDeclineButtonLabelByJobId, jobId);

  const nextStage = useAppSelector(hiringPipelinesStagesSelectors.selectFirstPipelineStageAfterNew, jobId);

  const isStageButtonsVisible = Boolean(isNewApplicant && firstStageButtonLabel && nextStage?.pipelineStageId);

  const declinedStage = useAppSelector(hiringPipelinesStagesSelectors.selectDeclinedPipelineStage, jobId);

  const isProcessing = [isApplicantLoading, applicantsStageChangeProcessing].some(Boolean);

  const pushToSiblingApplicantConfig = usePushToSiblingApplicantConfig();

  const pushToSiblingApplicant = (direction: 'prev' | 'next') => {
    dispatch(applicantModalSlice.actions.loadNextApplicant(direction));
    const config = pushToSiblingApplicantConfig(direction);
    push(config);
  };

  const handleClickPrevApplicant = (_e: React.MouseEvent<HTMLButtonElement>) => pushToSiblingApplicant('prev');
  const handleClickNextApplicant = (_e: React.MouseEvent<HTMLButtonElement>) => pushToSiblingApplicant('next');
  const isPrevButtonDisabled = applicants?.length <= 1;
  const isNextButtonDisabled = applicants?.length <= 1;

  const handlerMoveApplicant = async (stageId: string) => {
    if (!jobId || !applicantId) {
      return;
    }

    let pushConfig: undefined | ReturnType<typeof pushToSiblingApplicantConfig> = undefined;
    let pushPath: undefined | ReturnType<typeof generatePath> = undefined;

    if (!isNextButtonDisabled) {
      pushConfig = pushToSiblingApplicantConfig('next');
    } else if (!isPrevButtonDisabled) {
      pushConfig = pushToSiblingApplicantConfig('prev');
    }

    if (pushConfig) {
      pushPath = generatePath(pushConfig.path, pushConfig.config);
    }

    dispatch(
      applicantsActions.applicantsStageChange({
        applicantId,
        currentStageId: applicantPipelineStageId,
        jobId,
        stageId,
        pushPath,
      }),
    );
  };

  const isMoveToShortlistedDisabled = [applicant?.stageType === PipelineStageType.Shortlisted, isProcessing].some(
    Boolean,
  );
  const isMoveToDeclinedDisabled = [applicant?.stageType === PipelineStageType.Declined, isProcessing].some(Boolean);

  const handlerClickMoveToShortlisted = () => {
    if (!isNewApplicant || isMoveToShortlistedDisabled) {
      return;
    }

    handlerMoveApplicant(nextStage.pipelineStageId);
  };

  const handlerClickMoveToDeclined = () => {
    if (!isNewApplicant || isMoveToDeclinedDisabled) {
      return;
    }

    handlerMoveApplicant(getPipelineStageId(declinedStage));
  };

  const { prevBtnText, nextBtnText } = getBtnText(isCandidateView);

  useKeyPress('ArrowLeft', () => {
    pushToSiblingApplicant('prev');
  });

  useKeyPress('ArrowRight', () => {
    pushToSiblingApplicant('next');
  });

  useKeyPress('KeyY', () => {
    handlerClickMoveToShortlisted();
  });

  useKeyPress('KeyN', () => {
    handlerClickMoveToDeclined();
  });

  return {
    className,
    isProcessing,
    handleClickPrevApplicant,
    handleClickNextApplicant,
    isPrevButtonDisabled,
    isNextButtonDisabled,
    handlerClickMoveToShortlisted,
    handlerClickMoveToDeclined,
    isMoveToShortlistedDisabled,
    isMoveToDeclinedDisabled,
    applicant,
    firstStageButtonLabel,
    declineButtonLabel,
    pushToSiblingApplicant,
    prevBtnText,
    nextBtnText,
    isStageButtonsVisible,
  } as const;
};

export const ApplicantModalFooterView: React.FC<ApplicantModalFooterViewProps> = (props) => {
  const {
    className,
    isProcessing,
    handleClickPrevApplicant,
    handleClickNextApplicant,
    isPrevButtonDisabled,
    isNextButtonDisabled,
    handlerClickMoveToShortlisted,
    handlerClickMoveToDeclined,
    isMoveToShortlistedDisabled,
    isMoveToDeclinedDisabled,
    firstStageButtonLabel,
    declineButtonLabel,
    prevBtnText,
    nextBtnText,
    isStageButtonsVisible,
  } = useApplicantModalFooterViewState(props);

  return (
    <ApplicantModalFooterViewStyled className={className}>
      <ExVisible visible={!isPrevButtonDisabled}>
        <ActionButtonStyledPrev
          block
          variant="light"
          prependIcon={IconArrowLeft}
          onClick={handleClickPrevApplicant}
          disabled={isProcessing}
        >
          {prevBtnText}
        </ActionButtonStyledPrev>
      </ExVisible>
      <ExVisible visible={isStageButtonsVisible}>
        <>
          <ActionButtonStyled variant="danger" onClick={handlerClickMoveToDeclined} disabled={isMoveToDeclinedDisabled}>
            {declineButtonLabel}
          </ActionButtonStyled>
          <ActionButtonStyled
            variant="success"
            onClick={handlerClickMoveToShortlisted}
            disabled={isMoveToShortlistedDisabled}
          >
            {firstStageButtonLabel}
          </ActionButtonStyled>
        </>
      </ExVisible>
      <ExVisible visible={!isNextButtonDisabled}>
        <ActionButtonStyledNext
          appendIcon={IconArrowRight}
          block
          onClick={handleClickNextApplicant}
          disabled={isProcessing}
        >
          {nextBtnText}
        </ActionButtonStyledNext>
      </ExVisible>
    </ApplicantModalFooterViewStyled>
  );
};
