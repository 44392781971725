import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { Routes } from 'router';
import { call, fork, join, put, select, takeLatest } from 'redux-saga/effects';

import * as jobBoardApi from 'api-endpoints/job-board';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { JobBoardSettingsForm } from 'containers/JobBoardsForm/state/models';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';
import { modalsActions } from 'containers/Modals/store';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { invokeApiCall, ReturnData } from 'utils/sagas';

import { JobBoardSettings } from 'store/entities/job-boards/models';

import { jobBoardFormActions, jobBoardFormSelectors } from '.';

const prepareJobBoardSettingsFields = (d: object) =>
  Object.entries(d)
    .filter(([key, value]) => key !== 'id' && value !== undefined)
    .map(([key, value]) => ({ key, value }));

type JobBoardSettingsApiMethod = (
  args: jobBoardApi.JobBoardSettingsRequestPayload,
) => Promise<AxiosResponse<JobBoardSettings>>;

const jobBoardSettingsApiMethods: Record<'update' | 'subscribe', JobBoardSettingsApiMethod> = {
  update: jobBoardApi.putJobBoardSettings,
  subscribe: jobBoardApi.postJobBoardSettings,
};

function* jobBoardFormSaveSubscription({ data }: ReturnType<typeof jobBoardFormActions.saveSubscription>['payload']) {
  const { jobBoardId: id, ...settings }: Partial<JobBoardSettingsForm> = yield select(
    jobBoardFormSelectors.selectFormData,
  );

  const putData: jobBoardApi.JobBoardSettingsRequestPayload = {
    urlParams: { jobBoardId: id! },
    data: {
      settings: prepareJobBoardSettingsFields(data || settings),
    },
  };

  const apiMethodType = data ? 'update' : 'subscribe';

  const apiMethod = jobBoardSettingsApiMethods[apiMethodType];

  const { message }: ReturnData<JobBoardSettingsApiMethod> = yield invokeApiCall(apiMethod, putData);

  if (!message) {
    yield put(alertsEffects.showSuccess({ message: 'Job board settings changed' }));
  }

  return !message;
}

function* jobBoardFormCreateSubscriptionWorker(action: ReturnType<typeof jobBoardFormActions.saveSubscription>) {
  yield call(startLoader, action);
  const { data } = action.payload;
  const task = yield fork(jobBoardFormSaveSubscription, { data });
  const result = yield join(task);

  if (result) {
    yield put(
      push({
        pathname: Routes.jobBoardsFormFinal,
      }),
    );
  }
  yield call(stopLoader, action);
}

function* jobBoardFormUpdateSubscriptionWorker(action: ReturnType<typeof jobBoardFormActions.saveSubscription>) {
  yield call(startLoader, action);
  const { data } = action.payload;
  const task = yield fork(jobBoardFormSaveSubscription, { data });
  const result = yield join(task);

  if (result) {
    yield put(modalsActions.hideModalByIds({ modalType: [ModalsTypeKey.editJobBoardSettings] }));
  }
  yield call(stopLoader, action);
}

export function* jobBoardsFormSagas() {
  yield takeLatest(jobBoardFormActions.createSubscription, jobBoardFormCreateSubscriptionWorker);
  yield takeLatest(jobBoardFormActions.updateSubscription, jobBoardFormUpdateSubscriptionWorker);
}
