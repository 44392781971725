import { createEntityAdapter } from '@reduxjs/toolkit';

import { providesList, transformListResponse } from 'api-client/axiosBaseQuery';
import { replaceParamsInUrl } from 'api-client/utils';
import { JobAdEndpointsForApi } from 'api-endpoints/job-ad/endpoints';
import { CurrentJobAdsResponseItem, JobAdStatusResponseItem } from 'api-endpoints/job-ad/models';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { jobAdListActions } from 'containers/JobAdLists/store';
import { mapJobAds } from 'containers/JobAdLists/store/mappers';
import { getIsSelectable } from 'containers/JobAdLists/store/selectors';

import { getTranslate } from 'utils/i18utils';

import { emptyApi } from 'store/entities/emptyApi';
import { JobAd, JobAdBelongsTo } from 'store/entities/job-ads';

export const JOB_AD_TAG_TYPE = 'JobAd';

export const jobAdAdapter = createEntityAdapter<JobAd>({
  selectId: (item) => item.jobAdId as string,
});

const transformGetJobAdsResponse = (response: any) => {
  const { items = [], ...rest } = response;
  const { entities } = mapJobAds(items);

  return transformListResponse(jobAdAdapter)({
    ...rest,
    items: entities,
  });
};

const jobAdsUrlContext = {
  [JobAdBelongsTo.jobBoard]: (jobBoardId) =>
    replaceParamsInUrl(JobAdEndpointsForApi.SearchJobAdsForJobBoardFunc, { jobBoardId }),
  [JobAdBelongsTo.job]: (jobId) => replaceParamsInUrl(JobAdEndpointsForApi.SearchJobAdsForJobFunc, { jobId }),
  [JobAdBelongsTo.company]: () => replaceParamsInUrl(JobAdEndpointsForApi.SearchJobAdsForActiveJobsFunc, {}),
};

const getQueryUrl = (belongsTo) => {
  const [entityType, entityId] = belongsTo;

  return jobAdsUrlContext[entityType]?.(entityId);
};

const extendedJobAdsApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getJobAds: builder.query({
      query: ({ belongsTo, params }) => {
        return {
          url: getQueryUrl(belongsTo),
          method: 'GET',
          params,
        };
      },
      transformResponse: transformGetJobAdsResponse,
      onQueryStarted: async (args, { dispatch, getState }) => {
        try {
          const selectable = getIsSelectable(getState() as any, { id: args.listId });

          if (selectable) {
            dispatch(jobAdListActions.setCanBeSelectedIds({ id: args.listId, canBeSelectedIds: [], more: false }));
          }
        } catch (e) {}
      },
    }),
    getJobAdsLoadMore: builder.query({
      query: ({ belongsTo, params }) => ({
        url: getQueryUrl(belongsTo),
        method: 'GET',
        params,
      }),
      transformResponse: transformGetJobAdsResponse,
      onQueryStarted: async (args, { queryFulfilled, dispatch, getState }) => {
        try {
          const selectable = getIsSelectable(getState() as any, { id: args.listId });

          if (selectable && args?.params?.pageNo === 0) {
            dispatch(jobAdListActions.setCanBeSelectedIds({ id: args.listId, canBeSelectedIds: [], more: false }));
          }

          const { data } = await queryFulfilled;

          if (data?.pageNo > 0) {
            dispatch(
              extendedJobAdsApi.util.updateQueryData(
                'getJobAdsLoadMore',
                { ...args, params: { ...args.params, pageNo: 0 } },
                (draft) => {
                  if (data.pageNo > draft.pageNo) {
                    draft.pageCount = data.pageCount;
                    draft.pageNo = data.pageNo;
                  }
                },
              ),
            );
          }
        } catch (e) {}
      },
    }),
    getCurrentJobAds: builder.query<CurrentJobAdsResponseItem, { jobId: string }>({
      query({ jobId }) {
        return {
          url: replaceParamsInUrl(JobAdEndpointsForApi.GetCurrentJobAdsForJobFunc, { jobId }),
          method: 'GET',
        };
      },
    }),
    getJobAd: builder.mutation<JobAd, { jobAdId: string }>({
      query({ jobAdId }) {
        return {
          url: replaceParamsInUrl(JobAdEndpointsForApi.GetJobAdFunc, { jobAdId }),
          method: 'GET',
        };
      },
    }),
    getJobAdStatus: builder.query<JobAdStatusResponseItem, { jobAdId: string }>({
      query({ jobAdId }) {
        return {
          url: replaceParamsInUrl(JobAdEndpointsForApi.GetJobAdStatusFunc, { jobAdId }),
          method: 'GET',
        };
      },
    }),
    stopMultipleJobAds: builder.mutation({
      query: (data) => ({
        url: JobAdEndpointsForApi.StopJobAdsByJobAdIdsFunc,
        method: 'DELETE',
        data,
      }),
      onQueryStarted: async ({ jobAdIds = [] }, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          const listId = JobAdBelongsTo.job;
          dispatch(alertsEffects.showSuccess({ message: getTranslate(`jobAdMessages.AdDeleteSuccess`) }));
          dispatch(jobAdListActions.deselectAllItems({ id: listId }));
          dispatch(jobAdListActions.filterCanBeSelectedIds({ id: listId, canBeSelectedIds: jobAdIds }));
        } catch (e) {}
      },
    }),
    stopJobAdsForJob: builder.mutation({
      query: (args) => ({
        url: replaceParamsInUrl(JobAdEndpointsForApi.StopJobAdsForJobFunc, { jobId: args.jobId }),
        method: 'DELETE',
        data: args.data,
      }),
      onQueryStarted: async ({ needUpdateJobAds }, { queryFulfilled, dispatch }) => {
        if (!needUpdateJobAds) {
          return;
        }

        try {
          await queryFulfilled;
          dispatch(alertsEffects.showSuccess({ message: getTranslate(`jobAdMessages.AdDeleteSuccess`) }));
        } catch (e) {}
      },
    }),
    getJobAdLogs: builder.query({
      query: ({ jobAdId, params }) => ({
        url: replaceParamsInUrl(JobAdEndpointsForApi.SearchJobAdChangeNotesFunc, { jobAdId }),
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const enhancedJobAdsApi = extendedJobAdsApi.enhanceEndpoints({
  addTagTypes: [JOB_AD_TAG_TYPE],
  endpoints: {
    getJobAds: {
      providesTags: (result) => providesList(result?.ids, JOB_AD_TAG_TYPE),
    },
    getJobAdsLoadMore: {
      providesTags: (result) => providesList(result?.ids, JOB_AD_TAG_TYPE),
    },
    stopMultipleJobAds: {
      invalidatesTags: (_, __, { jobAdIds }) => [
        ...jobAdIds.map((jobAdId) => ({ type: JOB_AD_TAG_TYPE, id: jobAdId })),
      ],
    },
    stopJobAdsForJob: {
      invalidatesTags: [{ type: JOB_AD_TAG_TYPE, id: 'LIST' }],
    },
  },
});

export const {
  useStopMultipleJobAdsMutation,
  useStopJobAdsForJobMutation,
  useGetJobAdsQuery,
  useLazyGetJobAdsQuery,
  useGetJobAdsLoadMoreQuery,
  useLazyGetJobAdsLoadMoreQuery,
  useGetJobAdMutation,
  useGetCurrentJobAdsQuery,
  useGetJobAdStatusQuery,
} = enhancedJobAdsApi;
