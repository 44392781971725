import { createSelector } from '@reduxjs/toolkit';

import { pluck } from 'utils/pluck';

import { JobBoard } from 'store/entities/job-boards/models';
import { jobBoardSelectors } from 'store/entities/job-boards/selectors';
import { jobsSelectors } from 'store/entities/jobs/selectors';
import { RootState } from 'store/rootReducer';

export const domain = (state: RootState) => state.formJobAd;

export const selectFormData = createSelector(domain, (state) => state.formData);

export const selectFormDataJobBoardsIds = createSelector(selectFormData, (formData) => formData.jobBoardIds);

export const selectSelectedJobId = createSelector(selectFormData, (formData) => formData.jobId);

export const selectValidSteps = createSelector(domain, (state) => state.validSteps);

export const selectShowSteps = createSelector(domain, (state) => state.showSteps);

export const selectApiErrors = createSelector(domain, (state) => state.apiErrors);

export const selectAdditionFieldsView = createSelector(domain, (state) => state.additionFieldsView);

export const selectSelectedJob = createSelector(jobsSelectors.getLookup, selectSelectedJobId, (byId, jobId) => {
  if (byId && jobId) {
    return byId[jobId];
  }
});

export const selectSelectedJobBoards = createSelector(
  selectFormDataJobBoardsIds,
  jobBoardSelectors.selectEntities,
  (ids, entities) => {
    if (entities && ids?.length) {
      return ids.reduce<JobBoard[]>((acc, id) => {
        const candidate = entities[id];
        if (candidate) {
          return [...acc, candidate];
        }
        return acc;
      }, []);
    }
    return [];
  },
);

export const selectSelectedJobBoardsNames = createSelector(selectSelectedJobBoards, (jobBoards) =>
  jobBoards.map(pluck('name')).join(', '),
);
export const missingJobBoardsIds = createSelector(
  selectFormDataJobBoardsIds,
  jobBoardSelectors.selectEntities,
  (ids, entities) => {
    if (entities && ids?.length) {
      return ids.reduce<Array<JobBoard['jobBoardId']>>((acc, id) => {
        const candidate = entities[id];
        if (!candidate) {
          return [...acc, id];
        }
        return acc;
      }, []);
    }
  },
);
