import React from 'react';
import styled from 'styled-components';

import { JobAdAdditionalRequirementsFormBody } from 'containers/JobAdForms/FormBody';
import { useNewJobAdsContextFormUpdater } from 'containers/Modals/ModalsContent/JobAds/NewJobAds/NewJobAds.context';

import { StepComponent, StepDescription } from 'components/StepForm';
import { getTranslate } from 'utils/i18utils';

export interface NewJobAdAdditionalRequirementsStepProps extends StepComponent {
  className?: string;
}

const NewJobAdAdditionalRequirementsStepPure: React.FC<NewJobAdAdditionalRequirementsStepProps> = ({ className }) => {
  useNewJobAdsContextFormUpdater();

  return (
    <div className={className}>
      <StepDescription>
        <StepDescription.Title>Step 4: Additional Details</StepDescription.Title>
        <StepDescription.Description>{getTranslate('new_job_ad.step_4.description')}</StepDescription.Description>
      </StepDescription>
      <JobAdAdditionalRequirementsFormBody />
    </div>
  );
};

export const NewJobAdAdditionalRequirementsStep = styled(NewJobAdAdditionalRequirementsStepPure)``;
