import React from 'react';
import Button, { ButtonProps } from 'react-bootstrap/Button';
import { LinkProps } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components/macro';

import { Spinner } from 'components/Spinner';

const SpinnerStyled = styled(Spinner)`
  height: 10px;
  width: 10px;
  font-size: 2px;
  margin: auto;
`;

const SpinnerOverlay = styled.span<{ variant?: keyof DefaultTheme['colors'] | 'link' }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors[props.variant || 'primary']};
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ExButtonInnerWrapper = styled.span`
  display: inline-flex;
  place-content: center;
  place-items: center;
`;

export type ExButtonExtendedProps = Omit<ButtonProps, 'variant'> &
  ButtonProps &
  Pick<ButtonProps, 'size'> & {
    isLoading?: boolean;
    dropdown?: boolean;
    plus?: boolean;
    prependIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
    appendIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
    variant?: keyof DefaultTheme['colors'] | 'link';
    form?: string;
    to?: LinkProps['to'];
    forwardedAs?: any;
    rel?: string;
  };

export const ButtonStyled = styled(Button)<ExButtonExtendedProps>`
  border-radius: 20px;
  font-weight: 600;
  position: relative;
  padding: ${({ variant }) => (variant === 'link' ? '0' : '0.375rem 0.75rem')};
  line-height: 1.45;

  &:hover {
    text-decoration: none;
  }
`;

export const ExButton = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ExButtonExtendedProps>(
  ({ children, disabled, isLoading, variant, dropdown, plus, prependIcon, appendIcon, ...rest }, ref) => {
    const PrependIcon = prependIcon;
    const AppendIcon = appendIcon;

    const isDisabled = disabled || isLoading;

    return (
      <ButtonStyled
        {...rest}
        variant={variant}
        ref={(event) => {
          if (typeof ref === 'function') {
            ref(event);
          }
          if (ref && typeof ref !== 'function') {
            ref.current = event;
          }
        }}
        disabled={isDisabled}
      >
        <ExButtonInnerWrapper>
          {PrependIcon && <PrependIcon style={{ marginRight: '5px' }} />}
          {children}
          {AppendIcon && <AppendIcon style={{ marginLeft: '5px' }} />}
        </ExButtonInnerWrapper>
        {isLoading && (
          <SpinnerOverlay variant={variant}>
            <SpinnerStyled />
          </SpinnerOverlay>
        )}
      </ButtonStyled>
    );
  },
);
