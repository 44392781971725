import { createSlice } from '@reduxjs/toolkit';

import { formJobBoardInitialState, JobBoardFormState } from 'containers/JobBoardsForm/state/models';
import * as reducers from 'containers/JobBoardsForm/state/reducers';

export const formJobBoardKey = 'formJobBoard';

type FormJobBoardCaseReducers = {
  clearForm: typeof reducers.clearForm;
  toggleShowSteps: typeof reducers.toggleShowSteps;
  saveForm: typeof reducers.saveForm;
};

const jobBoardFormSlice = createSlice<JobBoardFormState, FormJobBoardCaseReducers>({
  name: formJobBoardKey,
  initialState: formJobBoardInitialState,
  reducers,
});

export default jobBoardFormSlice;
