import * as yup from 'yup';

import { Ability } from 'model';

import {
  emptyStringOrZeroToUndefined,
  emptyStringToUndefined,
  linkValidator,
  maxLengthForCurrencyAmount,
  validateStringLengthWithoutHtmlTags,
} from 'utils/validator-helpers';

import { Job } from 'store/entities/jobs/models';

const maximum = yup
  .number()
  .label('Salary To')
  .min(0)
  .test(maxLengthForCurrencyAmount)
  .transform(emptyStringToUndefined);

export const validationSchemaJobPosition: yup.AnyObjectSchema = yup.object({
  category: yup.string(),
  name: yup.string(),
  categoryId: yup.string().required().label('Position Category'),
  positionId: yup
    .number()
    .required()
    .label('Position')
    .typeError(({ label }) => `${label} is a required field`),
});

const validationSchemaMoneyRange: yup.AnyObjectSchema = yup.object({
  minimum: yup
    .number()
    .label('Salary From')
    .min(1)
    .transform(emptyStringToUndefined)
    .test(maxLengthForCurrencyAmount)
    .required()
    .typeError(({ type }) => `Only ${type} are accepted`), // eslint-disable-line no-template-curly-in-string
  maximum: yup
    .number()
    .required()
    .when('minimum', (minimum: number) => {
      if (minimum > 0) {
        return maximum
          .required()
          .default(minimum)
          .min(yup.ref('minimum'), ({ label }) => `${label} must be greater than or equal Salary From or empty`);
      }
      if (minimum === 0) {
        return maximum.required().min(1);
      }
      return maximum.required().min(1);
    }),
});

const validationSchemaMoneyRangeNotRequired: yup.AnyObjectSchema = yup.object({
  minimum: yup
    .number()
    .label('Salary From')
    .min(0)
    .transform(emptyStringToUndefined)
    .default(0)
    .test(maxLengthForCurrencyAmount)
    .typeError(({ type }) => `Only ${type} are accepted`), // eslint-disable-line no-template-curly-in-string
  maximum: yup.number().when('minimum', (minimum: number) => {
    if (minimum > 0) {
      return maximum
        .default(minimum)
        .min(yup.ref('minimum'), ({ label }) => `${label} must be greater than or equal Salary From or empty`);
    }
    if (minimum === 0) {
      return maximum.default(0);
    }
    return maximum.default(0);
  }),
});

export const validationSchemaSalary: yup.AnyObjectSchema = yup.object({
  currency: yup.string().label('Currency').transform(emptyStringOrZeroToUndefined).required(),
  frequency: yup.number().label('Salary frequency').transform(emptyStringOrZeroToUndefined).required(),
  moneyRange: validationSchemaMoneyRange.notRequired().default(undefined),
});

export const validationSchemaSalaryNotRequired: yup.AnyObjectSchema = yup.object({
  currency: yup.string().label('Currency').transform(emptyStringOrZeroToUndefined),
  frequency: yup.number().label('Salary frequency').transform(emptyStringOrZeroToUndefined),
  moneyRange: validationSchemaMoneyRangeNotRequired.notRequired().default(undefined),
});

export const validationSchemaFormSalary = yup.object().shape({
  salary: validationSchemaSalary.notRequired().default(undefined),
});

export const validationSchemaFormSalaryNotRequired = yup.object().shape({
  salary: validationSchemaSalaryNotRequired.notRequired().default(undefined),
});

export const jobNameValidator = yup.string().label('Job Name').trim().required().max(75);

export const minimalJobDescriptionLength = 150;
export const maximalJobDescriptionLength = 15_000;

export const descriptionValidationSchema = yup
  .string()
  // eslint-disable-next-line no-template-curly-in-string
  .test('valid-link', '${path} have invalid link(s)', linkValidator)
  .test(
    'stripped-html',
    // eslint-disable-next-line no-template-curly-in-string
    '${path} must be at least ' + minimalJobDescriptionLength + ' characters',
    validateStringLengthWithoutHtmlTags(minimalJobDescriptionLength),
  )
  .max(maximalJobDescriptionLength)
  .label('Job Description')
  .nullable()
  .required();

export const descriptionValidationSchemaNotRequired = yup.string().label('Job Description');

export const requirementsValidationSchema = yup.array<Ability>().min(1).label('Job Requirements');

export const requirementsValidationSchemaNotRequired = yup.array<Ability>().label('Job Requirements');

export const responsibilitiesValidationSchema = yup.array<Ability>().min(1).label('Job Responsibilities');

export const responsibilitiesValidationSchemaNotRequired = yup.array<Ability>().label('Job Responsibilities');

export const validationSchemaFormPositionDescription: yup.SchemaOf<
  Pick<Job, 'description' | 'requirements' | 'responsibilities'>
> = yup.object({
  description: descriptionValidationSchema,
  requirements: requirementsValidationSchema,
  responsibilities: responsibilitiesValidationSchema,
});

export const validationSchemaFormPositionDescriptionNotRequired: yup.SchemaOf<
  Partial<Pick<Job, 'description' | 'requirements' | 'responsibilities'>>
> = yup.object({
  description: descriptionValidationSchemaNotRequired,
  requirements: requirementsValidationSchemaNotRequired,
  responsibilities: responsibilitiesValidationSchemaNotRequired,
});
