import React, { useCallback, useEffect, useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import { FormCard } from 'components/FormCard';
import { FormTooltip } from 'components/FormTooltip';
import { Spacer } from 'components/Spacer';
import { ExVisible } from 'components/ui/ExVisible';
import { getThemeColor } from 'utils/styled/getThemeColor';

import { FormInputProps } from './FormInputProps';

const FormControlStyled = styled(Form.Control)`
  border-radius: 99px;
  color: ${(prop) => (prop.readOnly ? getThemeColor('gray') : 'auto')};
`;

const FormCardInputLabel = styled(FormCard.InputLabel)`
  display: block;
`;

const FormValidationHint = styled.div`
  color: ${getThemeColor('gray')};
  text-align: right;
`;

const Subtext = styled.div`
  color: ${getThemeColor('gray')};
`;

export const FormInput: React.FC<FormInputProps> = ({
  inputRef,
  name,
  className,
  errors,
  label,
  required,
  validated,
  onKeyDown,
  hint,
  defaultValue,
  labelLeft,
  noOverlay,
  setValue,
  asIs,
  classNameInput,
  onBlur,
  tooltipOffset: tooltipOffsetProps,
  validator,
  onChange,
  ...rest
}) => {
  const [focus, setFocus] = useState(false);
  const [showError, setShowError] = useState(true);

  const formContext = useFormContext();
  const defaultInnerValue = formContext?.getValues(name);
  const [innerValue, setInnerValue] = useState(defaultInnerValue || '');
  const showChartCount = validator?.type === 'fieldMaxLength';
  const chartCount = showChartCount ? Math.max(Number(validator.control) - innerValue.length, 0) : null;

  const onLocalChange = useCallback(
    (event) => {
      setInnerValue(event.target.value);
      if (onChange) {
        onChange(event);
      }
    },
    [onChange],
  );

  useEffect(() => {
    const hasError = Boolean(errors) || !isEmpty(errors);

    if (hasError) {
      setShowError(true);
      return;
    }

    setShowError(false);
  }, [errors, name]);

  const onBlurHandler = useCallback(
    (e) => {
      setFocus(false);
      onBlur && onBlur(e);
    },
    [onBlur],
  );

  const tooltipOffset: [number, number] | undefined = tooltipOffsetProps ?? (!label ? [0, 0] : undefined);

  const target = useRef<any>();
  const element = React.useMemo(
    () => (
      <FormControlStyled
        onFocus={() => setFocus(true)}
        onBlur={onBlurHandler}
        name={name}
        id={name}
        ref={inputRef}
        onKeyDown={onKeyDown}
        defaultValue={defaultValue}
        {...rest}
        onChange={onLocalChange}
        as={asIs}
        className={classNameInput}
        isInvalid={validated && !!errors}
      />
    ),
    [
      asIs,
      classNameInput,
      defaultValue,
      errors,
      inputRef,
      name,
      onBlurHandler,
      onKeyDown,
      onLocalChange,
      rest,
      validated,
    ],
  );

  return (
    <Form.Group ref={target} className={className} {...(Boolean(labelLeft) ? { as: Row } : {})}>
      <ExVisible visible={Boolean(label)}>
        <FormCardInputLabel htmlFor={name} labelLeft={!!labelLeft}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <span style={{ flexGrow: 1 }}>
              {label} {required && <FormCard.InputLabelRequired>*</FormCard.InputLabelRequired>}{' '}
            </span>
            <ExVisible visible={showChartCount}>
              <FormValidationHint>Remaining characters: {chartCount}</FormValidationHint>
            </ExVisible>
          </div>
          <Subtext>{hint}</Subtext>
        </FormCardInputLabel>
      </ExVisible>

      {labelLeft ? (
        <>
          <Spacer />
          <Col sm={labelLeft}>{element}</Col>
        </>
      ) : (
        element
      )}

      <FormTooltip
        hideOverlay={noOverlay}
        target={target}
        show={focus && showError && !!validated}
        errors={errors}
        offset={tooltipOffset}
      />
    </Form.Group>
  );
};

export default styled(FormInput)``;
