import styled, { css } from 'styled-components';

type AppFileCardBasicProps = {
  grayscale?: boolean;
};

const AppFileCardBasicGrayscale = ({ grayscale = true }: AppFileCardBasicProps) =>
  grayscale &&
  css`
    filter: grayscale(1);
  `;

export const AppFileCardBasic = styled.div<AppFileCardBasicProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 125px;
  height: 125px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(p) => p.theme.colors.neutral};
  border-radius: ${(p) => p.theme.border.borderRadius};
  padding: 5px 5px 20px;
  cursor: pointer;
  transition: filter 0.3s linear, border 0.3s ease-in;
  margin-bottom: 1rem;
  position: relative;
  ${AppFileCardBasicGrayscale};
`;

export const AppFileCardActions = styled.div`
  position: absolute;
  display: flex;
  top: 8px;
  right: 8px;
`;

export const FileLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;
