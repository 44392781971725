import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExRoutesNames, ExRoutesPathMap } from 'router/routes';

import { HistoryEntity } from 'modules/HistoryManager/model';

import { RootState } from 'store/rootReducer';

type HistoryManagerState = {
  history: HistoryEntity[];
  currentRouteName: string;
};

const initialState: HistoryManagerState = {
  history: [],
  currentRouteName: '',
};

/**
 * Length of 50 items was chosen to be consistent with browser history default length.
 */
const HISTORY_MANAGER_HISTORY_LENGTH = 50;

const historyManagerSlice = createSlice({
  initialState,
  name: 'historyManager',
  reducers: {
    clear(state) {
      state.history.length = 0;
    },
    pop(state) {
      state.history.pop();
    },
    push(state, action: PayloadAction<HistoryEntity>) {
      const prev = state.history.at(-1);

      if (prev?.location.key === action.payload.location.key) {
        return state;
      }

      if (state.history.length === HISTORY_MANAGER_HISTORY_LENGTH) {
        state.history.shift();
      }

      state.history.push(action.payload);
    },
    setCurrentRouteName(state, action) {
      state.currentRouteName = action.payload;
    },
  },
});

function createSelectors<S extends {}>(domain: (state: S) => HistoryManagerState) {
  const selectAll = createSelector(domain, (historyManager) => historyManager.history);
  const selectLatest = createSelector(selectAll, (history) => {
    const index = history.length - 1;

    if (index < 0) {
      return null;
    }

    return history[index];
  });

  const isInHistory = createSelector(
    selectAll,
    (_: S, location: HistoryEntity['location']) => location,
    (history, location) => {
      const { pathname } = location;

      return Boolean(history.find((historyItem) => historyItem.location.pathname === pathname));
    },
  );

  const findIndexByPathname = createSelector(
    selectAll,
    (_: S, pathname: string) => pathname,
    (history, pathname) => {
      return history.map((storedHistory) => storedHistory.location.pathname).lastIndexOf(pathname);
    },
  );

  const findIndexByRouteName = createSelector(
    selectAll,
    (_: S, routeName: ExRoutesNames) => routeName,
    (history, routeName) => {
      return history.map((storedHistory) => storedHistory.routeName).lastIndexOf(routeName);
    },
  );

  const selectPreviousByIndex = <T>(array: T[], index: number) => {
    if (index < 0) {
      return null;
    }

    return array[index - 1];
  };

  const selectPreviousByPathname = createSelector(selectAll, findIndexByPathname, selectPreviousByIndex);

  const selectPreviousByRouteName = createSelector(selectAll, findIndexByRouteName, selectPreviousByIndex);

  const selectPreviousRouteNameByRouteName = createSelector(
    selectPreviousByRouteName,
    (historyEntity) => historyEntity?.routeName as ExRoutesNames,
  );

  const selectPreviousAllowedByRouteName = createSelector(selectAll, findIndexByRouteName, (history, index) => {
    if (index < 0) {
      return;
    }

    const { routeName } = history[index];
    const allowedBackPathList = ExRoutesPathMap[routeName].allowedBackPathList;
    const restrictedBackPathList = ExRoutesPathMap[routeName].restrictedBackPathList || [];

    const previousHistory = history.slice(0, index).filter((historyEntity) => historyEntity.routeName !== routeName);

    if (allowedBackPathList && allowedBackPathList.length > 0) {
      return [...previousHistory]
        .reverse()
        .find((historyEntity) => allowedBackPathList.includes(historyEntity.routeName));
    }

    return [...previousHistory].reverse().find((historyEntity) => {
      const preventBackLink = ExRoutesPathMap[historyEntity.routeName].preventBackLink;

      return (
        historyEntity.routeName !== routeName &&
        !preventBackLink &&
        !restrictedBackPathList.includes(historyEntity.routeName)
      );
    });
  });

  const selectCurrentRouteName = createSelector(
    domain,
    (historyManager) => historyManager.currentRouteName as ExRoutesNames,
  );

  return {
    findIndexByPathname,
    isInHistory,
    selectAll,
    selectLatest,
    selectPreviousAllowedByRouteName,
    selectPreviousByPathname,
    selectPreviousByRouteName,
    selectPreviousRouteNameByRouteName,
    selectCurrentRouteName,
  };
}

export const historyManagerActions = {
  ...historyManagerSlice.actions,
};

export const historyManagerReducer = historyManagerSlice.reducer;

const historyManagerDomain = (state: RootState) => state.historyManager;

export const historyManagerSelectors = createSelectors(historyManagerDomain);
