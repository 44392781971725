import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ExRoutes } from 'router/routes';
import styled from 'styled-components/macro';

import { jobAdFormActions } from 'containers/JobAdForms/state';

import { ExAddNewButton } from 'components/ui/ExAddNewButton/ExAddNewButton';
import { ExButtonExtendedProps } from 'components/ui/ExButton';
import { getFieldsForJobAdFormData } from 'utils/additional-fields-helper';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { Job, jobsSelectors } from 'store/entities/jobs';

type JobAdListNewButtonProps = {
  className?: string;
  jobId?: string;
  btnText?: string;
  variant?: ExButtonExtendedProps['variant'];
  size?: ExButtonExtendedProps['size'];
};

const JobAdListNewButtonStyled = styled(Link)``;

type UsePreSetJobAdFormProps = {
  job?: Job;
};

export const usePreSetJobAdForm = ({ job }: UsePreSetJobAdFormProps) => {
  const dispatch = useAppDispatch();

  const preSetJobAdForm = useCallback(() => {
    dispatch(
      jobAdFormActions.saveForm({
        formData: {
          ...getFieldsForJobAdFormData(job),
        },
      }),
    );
  }, [dispatch, job]);

  return {
    preSetJobAdForm,
  };
};

const useJobAdListNewButtonState = ({
  className,
  jobId,
  btnText,
  variant = 'primary',
  size = 'sm',
}: JobAdListNewButtonProps) => {
  const job = useAppSelector(jobsSelectors.getById, jobId);
  const { preSetJobAdForm } = usePreSetJobAdForm({ job });

  const btnLink = jobId ? ExRoutes.jobAdNewFromJobSelectJobBoards({ jobId }) : ExRoutes.jobAdNew();

  return {
    className,
    btnLink,
    btnText,
    variant,
    size,
    preSetJobAdForm,
  } as const;
};

export const JobAdListNewButton: React.FC<JobAdListNewButtonProps> = (props) => {
  const { className, btnLink, btnText, variant, size, preSetJobAdForm } = useJobAdListNewButtonState(props);

  return (
    <JobAdListNewButtonStyled className={className} to={btnLink} onClick={preSetJobAdForm}>
      <ExAddNewButton variant={variant} size={size}>
        {btnText}
      </ExAddNewButton>
    </JobAdListNewButtonStyled>
  );
};
