import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { EmptyLayout } from 'layouts/EmptyLayout/EmptyLayout';

import { LoginActions, LogoutActions } from 'containers/Auth/state/auth.constants';

import { LoginPage } from './LoginPage';
import { LogoutPage } from './LogoutPage';

export type AuthRootProps = {};

export const AuthRoot: React.FC<AuthRootProps> = () => {
  return (
    <EmptyLayout>
      <Switch>
        <Route path="/login" render={(props) => <LoginPage action={LoginActions.Login} {...props} />} />
        <Route path="/login-confirm" render={(props) => <LoginPage action={LoginActions.LoginCallback} {...props} />} />
        <Route path="/logout" render={(props) => <LogoutPage action={LogoutActions.Logout} {...props} />} />
        <Route
          path="/logout-confirm"
          render={(props) => <LogoutPage action={LogoutActions.LogoutCallback} {...props} />}
        />
      </Switch>
    </EmptyLayout>
  );
};
