import { createAction } from '@reduxjs/toolkit';

import { ExComment } from 'model';

import type { CommentAddHandlerParams, CommentUpdateHandlerParams } from 'components/ui/ExComments/ExCommentListProps';

import { applicantCommentsSlice } from 'store/entities/applicant-comments/applicant-comments.reducer';
import { Applicant } from 'store/entities/applicants/models';
import { Job } from 'store/entities/jobs/models';

const APPLICANT_COMMENTS_GET = `${applicantCommentsSlice.name}/get`;
const APPLICANT_COMMENTS_FETCH = `${applicantCommentsSlice.name}/fetch`;
const APPLICANT_COMMENTS_START_WATCHER_TASK = `${applicantCommentsSlice.name}/fetch/watcher/start`;
const APPLICANT_COMMENTS_STOP_WATCHER_TASK = `${applicantCommentsSlice.name}/fetch/watcher/stop`;
const APPLICANT_COMMENTS_FETCH_MORE = `${applicantCommentsSlice.name}/fetch/more`;
const APPLICANT_COMMENTS_CREATE = `${applicantCommentsSlice.name}/create`;
const APPLICANT_COMMENTS_UPDATE = `${applicantCommentsSlice.name}/update`;
const APPLICANT_COMMENTS_REMOVE = `${applicantCommentsSlice.name}/remove`;
const APPLICANT_COMMENTS_READ = `${applicantCommentsSlice.name}/read`;
const APPLICANT_COMMENTS_READ_ALL = `${applicantCommentsSlice.name}/read/all`;

const applicantCommentGet = createAction<
  Pick<Applicant, 'applicantId'> & Pick<Job, 'jobId'> & Pick<ExComment, 'commentId'>
>(APPLICANT_COMMENTS_GET);

const applicantCommentsFetch = createAction<Pick<Applicant, 'applicantId'> & Pick<Job, 'jobId'>>(
  APPLICANT_COMMENTS_FETCH,
);

const applicantCommentsStartWatcherTask = createAction(APPLICANT_COMMENTS_START_WATCHER_TASK);
const applicantCommentsStopWatcherTask = createAction(APPLICANT_COMMENTS_STOP_WATCHER_TASK);

const applicantCommentsFetchMore = createAction(APPLICANT_COMMENTS_FETCH_MORE);

const applicantCommentsCreate = createAction<CommentAddHandlerParams>(APPLICANT_COMMENTS_CREATE);

const applicantCommentsUpdate = createAction<CommentUpdateHandlerParams>(APPLICANT_COMMENTS_UPDATE);

const applicantCommentsRemove = createAction<{
  commentId: Required<ExComment>['commentId'];
}>(APPLICANT_COMMENTS_REMOVE);

const applicantCommentsRead = createAction<{
  comments: Array<Required<ExComment>['commentId']>;
}>(APPLICANT_COMMENTS_READ);

const applicantCommentsReadAll =
  createAction<Partial<Pick<Applicant, 'applicantId'> & Pick<Job, 'jobId'>>>(APPLICANT_COMMENTS_READ_ALL);

export const applicantCommentsActions = {
  ...applicantCommentsSlice.actions,
  applicantCommentGet,
  applicantCommentsFetch,
  applicantCommentsCreate,
  applicantCommentsUpdate,
  applicantCommentsRemove,
  applicantCommentsRead,
  applicantCommentsReadAll,
  applicantCommentsFetchMore,
  applicantCommentsStartWatcherTask,
  applicantCommentsStopWatcherTask,
};
