import React from 'react';
import styled from 'styled-components';

import { CandidateValidationErrorCodes } from 'model/api-errors.constants';

import { FileIconStyled } from 'components/Icons/FileIcon';
import { IconCloseStyled } from 'components/Icons/IconClose';
import { Spacer } from 'components/Spacer';
import { getTranslate } from 'utils/i18utils';
import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';

type BulkItemFileProps = {
  className?: string;
  fileName?: string;
  fileId?: string | null;
  isSuccess?: boolean;
  onRemove?: (fileId?: BulkItemFileProps['fileId']) => void;
  validationErrorCodes?: string[] | null;
};

const BulkItemFileStyled = styled.div<{ isError?: boolean }>`
  border-radius: ${getThemeBorderProps('borderRadius')};
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.isError ? props.theme.colors.dangerLight : props.theme.colors.bodyBackgroundColor};
  padding: 12px 20px;
  font-weight: bold;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  ${FileIconStyled} {
    margin-right: 15px;
  }

  ${IconCloseStyled} {
    width: 18px;
    height: 18px;
    flex: 0 0 auto;
  }

  span {
    padding-right: 5px;
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const ErrorMsg = styled.div`
  font-weight: 400;
`;

const useBulkItemFileState = ({
  className,
  isSuccess,
  fileId,
  fileName,
  onRemove,
  validationErrorCodes,
}: BulkItemFileProps) => {
  const isError = !(Boolean(fileId) || isSuccess);

  const showRemoveIcon = Boolean(onRemove);

  const removeIconClickHandler: React.MouseEventHandler<SVGElement> = () => onRemove && onRemove(fileId);

  const errorMessage = (validationErrorCodes || [])
    .map((validationErrorCode: any) => {
      if (validationErrorCode === CandidateValidationErrorCodes.EmailNotUnique) {
        return 'A candidate with this email already exists';
      }

      return getTranslate(validationErrorCode, { ns: 'validationErrorCodes' });
    })
    .join(', ');

  const showErrorMessage = Boolean(isError && errorMessage);

  return {
    isError,
    className,
    fileName,
    removeIconClickHandler,
    showRemoveIcon,
    errorMessage,
    showErrorMessage,
  };
};

export const BulkItemFile: React.FC<BulkItemFileProps> = (props) => {
  const { className, fileName, removeIconClickHandler, isError, showRemoveIcon, errorMessage, showErrorMessage } =
    useBulkItemFileState(props);

  return (
    <BulkItemFileStyled className={className} isError={isError}>
      <FileIconStyled />
      <div>
        <span title={fileName}>{fileName}</span>
        {showErrorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
      </div>
      <Spacer />
      {showRemoveIcon && <IconCloseStyled onClick={removeIconClickHandler} />}
    </BulkItemFileStyled>
  );
};
