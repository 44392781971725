import React from 'react';

import { JobAbilitiesModal } from 'containers/Modals/ModalsContent/Job/JobAbilitiesModal';
import { useJobAdAbilityItems } from 'containers/Modals/ModalsContent/Job/JobAbilitiesModal/hooks/useJobAdAbilityItems';

import { JobAdRequirementsModalProps } from './JobAdRequirementsModalProps';

export const JobAdRequirementsModal: React.FC<JobAdRequirementsModalProps> = ({ ...dialogProps }) => {
  const title = 'Job Ad Requirements';
  const { onSubmitValidation, items } = useJobAdAbilityItems({
    abilityEntity: 'requirements',
    validationSchemaLabel: title,
    onClose: dialogProps.onClose,
  });

  return <JobAbilitiesModal title={title} onSubmitValidation={onSubmitValidation} items={items} {...dialogProps} />;
};
