import { InterviewState, InterviewType, InterviewTemplateState, QuestionType } from 'model/api-enums.constants';
import { JobAdValidationErrorCodes } from 'model/api-errors.constants';

const en = {
  entityTypes: {
    interviewQuestion: 'interview question',
    interviewTemplate: 'interview template',
    interviewTemplateQuestion: 'interview template question',
    interview: 'interview',
    screeningQuestions: 'screening questions',
    screeningQuestion: 'screening question',
    job: 'job',
    talentPool: 'talent pool',
    pipelineStage: 'pipeline stage',
  },
  entityActions: {
    added: 'added',
    created: 'created',
    updated: 'updated',
    deleted: 'deleted',
    duplicated: 'duplicated',
  },
  entity_success: 'The {{entityType}} "{{name}}" was successfully {{entityAction}}.',
  entity_success_no_name: 'The {{entityType}} was successfully {{entityAction}}.',
  text_must_be_at_least: '{{fieldName}} must be at least {{min}} character.',
  text_must_be_at_least_plural: '{{fieldName}} must be at least {{min}} characters.',
  Cancel: 'Cancel',
  Save: 'Save',
  Create: 'Create',
  'Edit Interview Settings': 'Edit Interview Settings',
  'New Interview Settings': 'New Interview Settings',
  'Select Applicant': 'Select Applicant',
  'Select Interview Template': 'Select Interview Template',
  'Interview Name': 'Interview Name',
  Date: 'Date',
  'Time From': 'Time From',
  'Time To': 'Time To',
  'Interview Type': 'Interview Type',
  Interviewers: 'Interviewers',
  Location: 'Location',
  candidate_list_empty_by_filters: 'There are no candidates by that filters.',
  central_candidate_list_empty: 'There are no candidates. Start adding candidates now.',
  talent_pool_candidates_list_empty: 'There are no candidates in this talent pool. Start adding candidates now.',
  candidate_added_to_talent_pool_success: 'Candidate was successfully added to the talent pool.',
  candidates_added_to_talent_pool_success: 'Candidates were successfully added to the talent pool.',
  job_interview_template_updated_success: 'The job interview templates have been successfully updated.',
  entity_order_updated_success: 'The order of the questions has been successfully updated.',
  maintenance: 'Site under maintenance',
  notEnoughData: 'Not enough data.',
  noRecentComments: 'No recent comments to display.',
  noRecentMentions: 'No recent mentions to display.',
  job_title: 'Job Title',
  jobs_page_title: 'Jobs',
  jobs_change_status: {
    open: 'You are about to open a job with a deadline in the past.<br/> This job will not be visible on the public career page and marked as unlisted automatically.<br/>',
    complete: 'There is {{countOfHired}} application in filled status,<br/>',
    confirm: 'Are you sure you want to {{status}} the Job {{jobName}}?',
  },
  dashboard_page_title: 'Hire Dashboard',
  job_status_change_success: 'Job Status was successfully updated',
  work_experience_update_success: 'Work Experience was successfully updated',
  work_experience_delete_success: 'Work Experience was successfully deleted',
  work_experience_create_success: 'Work Experience was successfully created',
  education_update_success: 'Education was successfully updated',
  education_delete_success: 'Education was successfully deleted',
  education_create_success: 'Education was successfully created',
  common: {
    buttonOk: 'Confirm',
    buttonCancel: 'Cancel',
  },
  dialogDefaults: {
    title: 'SetTitle',
    message: 'SetMessage or Component',
  },
  job_boards_page_title: 'Job Boards',
  job_ad_page_title: 'Job Ads',
  job_ad_no_external_link:
    'The external link is not available for this job ad. Please login to your job board account to view the link.',
  job_ad_missing_required_field_modal:
    'There’re some missing required fields, \nplease fill in those fields in \n<strong>Position Details and Ad Settings tabs</strong>.',
  talent_pool_page_title: 'Talent Pools',
  candidates_page_title: 'Candidates',
  placements_page_title: 'Placements',
  auth_user_do_not_have_sufficient_rights: 'You do not have sufficient rights for this action.',
  jobAdValidationErrorCodes: {
    [JobAdValidationErrorCodes.LocationInvalidErrorCode]: 'Location Required',
    [JobAdValidationErrorCodes.RequiredAdditionalFieldInvalidErrorCode]: 'Additional Field Required',
    [JobAdValidationErrorCodes.SectorInvalidErrorCode]: 'Sector Required',
  },
  jobAdMessages: {
    AdSettingsUpdateSuccess: 'Job Ad Settings Successfully Updated',
    AdDeleteSuccess: 'Job Ads are stopped.',
  },
  new_job: {
    step_1: {
      description:
        'This step will allow you to enter the basic information on the Job. We encourage you to set the job deadline so that our system can highlight when the job is about to reach that day. Please also note that the Reference Number must be unique. This number will be used as an identifier for the job.',
    },
    step_2: {
      description:
        'This step will allow you to set the budget for this job. Please note that this information will be visible in the job ad all the time. You might be able to overwrite and hide the salary information in some Job Boards such as SEEK.',
    },
    step_3: {
      description:
        'This step will allow you to write an awesome introduction to your team and the ad that attracts talent. For your convenience, we provide position responsibilities and requirements template based on your selection in Step 1. The position details will be copied to the job ad. You can always modify the position details when creating a job ad.',
      disclaimer:
        'To ensure the job ad is displayed correctly and can be posted to the job board, please do not use < and > symbols.',
    },
    step_4: {
      description:
        'Below is a summary of your new job. You can save this job as Draft or Open it. If you select Create Job, it will become open. Your team members can start posting job ads.',
    },
  },
  new_job_ad: {
    step_1: {
      description:
        'Please select a job for the new job ad. If you create a new job ad from a job details page, the system will auto-select the job for your convenience.',
    },
    step_2: {
      description:
        'You can select multiple job boards and post the same job ad to different places. If you cannot find the job board you are looking for from the list below, it may be because you have not subscribed to that job board.',
    },
    step_3: {
      description:
        'This step will allow you to overwrite the position details of the selected job in step 1. You can modify this content at any time in the Job Ad Details page.',
      disclaimer:
        'To ensure the job ad is displayed correctly and can be posted to the job board, please do not use < and > symbols.',
    },
    step_4: {
      description:
        'Expedo Hire integrates with Idibu, a platform that provides multi-job boards integration. These forms below are loaded dynamically from the Idibu system. Each job board you selected from Step 2 may require some additional settings. Please ensure you fill in all required fields before navigating to the next steps.',
    },
    step_5: {
      description:
        'Below is a summary of your new job ad. You can save this job ad as Draft or post it to the selected job boards in step 2. Once the job ad is posted, its status will change from pending to active. It will take some time for the job ad to become active.',
    },
  },
  new_applicant: {
    step_1: {
      description:
        'Please select a candidate you wish to apply for a job. You can filter the candidate list by talent pool, country, location, availability, and source. You can also add a new candidate on the fly in this step. Please note that you can only create a new application for 1 candidate at a time.',
    },
    step_2: {
      description:
        'Please select a job you would like to apply for the selected candidate. Please note that only open jobs are listed.',
    },
    step_3: {
      description:
        'It is required to attach a resume to a new application. You can select an existing resume or upload a new one. Accepted file formats are MS Word and PDF.',
    },
    step_4: {
      description: 'Cover Letter is optional. You can choose to upload a cover letter in PDF or MS Word format.',
    },
    step_5: {
      description: 'Details is optional.',
    },
    step_6: {
      description:
        'Below is a summary of the new application. Once the application is created, you cannot edit its details.',
    },
  },
  public_new_applicant: {
    resume: {
      title: 'Step 1: Upload Resume',
      description: 'It is required to attach a resume to a new application. Accepted file formats are MS Word and PDF.',
    },
    details: {
      title: 'Step 2: Candidate Details',
      description:
        'Please enter your details in the form below. If you used to apply for a job in our company, please kindly re-enter the email you used before. It will help us in the hiring process.',
    },
    cover_letter: {
      title: 'Step 3: Upload Cover Letter',
      description: 'Cover Letter is optional. You can choose to upload a cover letter in PDF or MS Word format.',
    },
    screening_questions: {
      title: 'Step {{stepNumber}}: Screening Questions',
      description: 'Description for screening questions.',
    },
    review: {
      title: 'Step {{stepNumber}}: Review',
      description:
        'Below is a summary of the new application. Please review the details of your application before submitting.',
    },
    confirmMessage:
      'By clicking “Confirm” button, you agreed on our <0>Terms & Conditions</0>. \nYour data will be used for the purpose of hiring process',
    success: {
      title: 'You have successfully submitted your application',
      message:
        'Your application is on the list and will be reviewed. You will be contacted by the Hiring representatives should you move on to the next step. Good luck!',
    },
    failure: {
      title: 'There’s an error submitting your application',
      message: 'It seems like you have submitted an application for this job using the same email address.',
    },
  },
  subscribe_to_job_board: {
    step_1: {
      description:
        'Below is the list of available job boards in your selected countries. You can add or remove country preference in the company settings. You can also subscribe to a new job board in other countries than your default one.',
    },
    step_2: {
      description:
        ' Each job board will require a different setup. Expedo Hire only connects your job board account to the system. All the settings of the job board need to be done from the job board system.',
    },
  },
  company: {
    countries: {
      update: {
        success: 'Countries successfully updated',
        emptyCountryList: 'Please select at least one country',
      },
    },
    currencies: {
      update: {
        success: 'Currencies successfully updated',
        emptyCurrencyList: 'Please select at least one currency',
      },
    },
    industries: {
      update: {
        success: 'Industries successfully updated',
        listEmpty: 'Please select at least one industry',
      },
    },
    dateFormat: {
      update: {
        success: 'Company Date and Time format successfully updated',
      },
    },
    integrations: {
      update: {
        success: 'Company integrations successfully updated',
      },
    },
    qualificationTypes: {
      update: {
        success: 'Company qualification types successfully updated',
      },
      remove: {
        success: 'Company qualification type successfully removed',
      },
    },
    qualificationTypesMaps: {
      update: {
        success: 'Company qualification Mapping successfully updated',
      },
    },
    onCost: {
      addNew: {
        allCountriesAlreadyAdded: 'All Countries Already Added',
        success: 'On-cost successfully added',
      },
      update: {
        success: 'On-cost successfully updated',
      },
      remove: {
        success: 'On-cost successfully removed',
      },
    },
    hiringPipeLine: {
      edit: {
        success: 'Hiring pipeline successfully updated',
      },
      create: {
        success: 'Hiring pipeline successfully created',
      },
      remove: {
        success: 'Hiring pipeline successfully removed',
      },
      rename: {
        success: 'Hiring pipeline successfully renamed',
      },
      duplicate: {
        success: 'Hiring pipeline successfully duplicated',
      },
      setDefault: {
        success: 'Hiring pipeline successfully set as default',
      },
      enable: {
        success: 'Hiring pipeline successfully enabled',
      },
      disable: {
        success: 'Hiring pipeline successfully disabled',
      },
      stageCreate: {
        success: 'Hiring pipeline stage successfully created',
      },
      stageRemove: {
        success: 'Hiring pipeline stage successfully removed',
      },
    },
    emailTemplates: {
      update: {
        success: 'Company email template successfully updated',
      },
      remove: {
        confirmMessage: 'You confirm remove "{{emailTitle}}" email template?',
        success: 'Company email template successfully removed',
      },
      create: {
        success: 'Company email template successfully created',
      },
    },
  },
  talentPool: {
    remove: {
      success: 'Talent Pool is successfully removed',
    },
  },
  daxtraQualifications: {
    doctorate: 'Doctorate',
    'some post-graduate': 'Some Post-Graduate',
    masters: 'Masters',
    postprofessional: 'Postprofessional',
    professional: 'Professional',
    bachelors: 'Bachelors',
    associates: 'Associates',
    intermediategraduate: 'Intermediate Graduate',
    certification: 'Certification',
    vocational: 'Vocational',
    'HND/HNC or equivalent': 'HND/HNC or Equivalent',
    'some college': 'Some College',
    'high school or equivalent': 'High School or Equivalent',
    'some high school or equivalent': 'Some High School or Equivalent',
    secondary: 'Secondary',
    ged: 'GED',
  },
  job: {
    salary: {
      update: {
        success: "Job's salary was successfully updated",
      },
    },
    details: {
      update: {
        success: "Job's details was successfully updated",
      },
      errors: {
        deadlineInPast: 'The job has passed its deadline. Thus, Job Type cannot be changed to External.',
      },
    },
    description: {
      update: {
        success: "Job's description was successfully updated",
      },
    },
    responsibilities: {
      update: {
        success: "Job's responsibilities was successfully updated",
      },
    },
    requirements: {
      update: {
        success: "Job's requirements was successfully updated",
      },
    },
    team: {
      update: {
        success: 'Hiring team was successfully updated',
      },
    },
  },
  candidate: {
    create: {
      success: 'Candidate successfully created.',
    },
    candidate_marked_as_do_not_hire:
      'You cannot add this candidate to a talent pool because they are marked as Do Not Hire.<br />Please uncheck <b>Do Not Hire</b> in the candidate details.</div>',
    update: {
      doNotHireSuccess: 'Candidate\'s "Do Not Hire" status was successfully updated.',
    },
  },
  applicant: {
    update: {
      success: 'Applicant was successfully updated.',
      ratingSuccess: 'Applicant rating was successfully updated.',
      doNotHireSuccess: 'Applicant\'s "Do Not Hire" status was successfully updated.',
    },
  },
  interviewTemplate: {
    questionTypes: {
      [`${QuestionType.SelectBoxList}`]: 'Select Box',
      [QuestionType.TextField]: 'Text Field',
      [QuestionType.YesNo]: 'Yes/No',
      [QuestionType.CheckBoxList]: 'Checkbox',
      [QuestionType.TextArea]: 'Text Area',
      [QuestionType.FileUpload]: 'File Upload',
    },
    questionMandatory: {
      yes: 'Yes',
      no: 'No',
    },
    status: {
      [InterviewTemplateState.Saved]: 'Published',
      [InterviewTemplateState.Draft]: 'Draft',
    },
    removeMessage: {
      inUse:
        'This interview template is already in use for interviews, editing the interview \n template will only affect any future interviews that are created using the template.\n',
      statusToDraft: "This interview template's status will be change to Draft.",
      needPublish: 'You will need to {{action}} this interview template for use in a job.',
      actionPublish: 'publish',
      actionRePublish: 're-publish',
    },
    editMessages: {
      emptyQuestionsList: 'Your questionnaire is empty.\nYou need to create at least 1 question to publish.',
      unsavedChangesInForm: 'You have unsaved changes.\n Do you want save it as draft?',
      publishTitle: 'Publish Interview Template',
      publishUnsavedChangesTitle: 'You have unsaved changes',
      publishUnsavedChanges: 'All the unsaved changes will be saved and it would be Published',
      changesEffectOnlyFutureInterview:
        'The interview template would be published and usable in the job.\n Any changes will affect only the newly created interview using the job.',
    },
  },
  interview: {
    type: {
      [InterviewType.InPerson]: 'In Person',
      [InterviewType.Online]: 'Online',
      [InterviewType.Phone]: 'Phone',
    },
    status: {
      [InterviewState.Saved]: 'Published',
      [InterviewState.Draft]: 'Draft',
    },
  },
};

export default en;
