import { call, fork, put, take } from 'redux-saga/effects';

import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { ExDialogContent } from 'components/ui/ExDialog/ExDialogProps';
import { invokeExModal, prepareExModalChannel } from 'utils/sagas';

import { interviewTemplateDeleteConformModalShowAction } from 'store/entities/interview-template/interview-template.actions';
import { interviewTemplateApi } from 'store/entities/interview-template/interview-template.api';
import { exModalHideAction } from 'store/modals/modals.actions';

export function* interviewTemplateDeleteModalShowWorker(
  action: ReturnType<typeof interviewTemplateDeleteConformModalShowAction>,
) {
  const { modalId, sagaChannel } = yield prepareExModalChannel();
  const interviewTemplate = action.payload;

  if (!interviewTemplate?.canDelete) {
    return;
  }

  const configs: Record<string, ExDialogContent> = {
    isInUse: {
      withTitle: true,
      title: 'You cannot delete this template',
      withActions: true,
      buttonOk: null,
      message: `This interview template is “attached” to active job(s) and it cannot be deleted\n until it has been detached.`,
    },
    isNotInUse: {
      withTitle: true,
      title: 'Confirm Remove',
      withActions: true,
      buttonOk: 'Remove',
      buttonOkVariant: 'danger',
      message: `You confirm remove the interview template\n "${interviewTemplate?.name}"`,
    },
  };

  const isUsed = interviewTemplate.isUsed;

  yield fork(invokeExModal, {
    modalId,
    channel: sagaChannel,
    modalType: ModalsTypeKey.confirmModal,
    modalConfig: {
      content: isUsed ? configs.isInUse : configs.isNotInUse,
    },
  });

  const { cancel } = yield take(sagaChannel);

  if (cancel) {
    return;
  }

  const thunk = yield call(interviewTemplateApi.endpoints.interviewTemplateDelete.initiate, interviewTemplate);

  yield put(thunk);

  yield put(exModalHideAction({ id: modalId }));
}
