type JobAdEditConfigObject = {
  showPostingDuration: boolean;
};

const jobAdEditConfig: Record<string, JobAdEditConfigObject> = {
  indeed: {
    showPostingDuration: false,
  },
  jora: {
    showPostingDuration: false,
  },
  jobstreet: {
    showPostingDuration: false,
  },
  seek: {
    showPostingDuration: false,
  },
  adzuna: {
    showPostingDuration: true,
  },
  default: {
    showPostingDuration: true,
  },
};

export const getJobAdEditConfigByJobBoardName = (jobBoardName = 'default'): JobAdEditConfigObject =>
  jobAdEditConfig[jobBoardName.toLowerCase()] ?? {};

export const getJobAdEditConfigFieldByJobBoardName = (jobBoardName = 'default', fieldName = '') => {
  const config = getJobAdEditConfigByJobBoardName(jobBoardName);

  return config[fieldName];
};
