import { takeLeading } from '@redux-saga/core/effects';

import * as fromApplicantModalActions from './ApplicantModal.actions';
import * as fromApplicantModalWorkers from './sagas';

export function* applicantModalSagas() {
  yield takeLeading(
    fromApplicantModalActions.updateApplicantModalAction,
    fromApplicantModalWorkers.updateApplicantModalWorker,
  );
}
