import { useMemo } from 'react';

import { EmploymentType } from 'model/api-enums.constants';

import { getEmploymentType } from 'utils/getEmploymentType';

export function useEmploymentTypeOptions() {
  return useMemo(
    () =>
      Object.values(EmploymentType)
        .filter((value) => !Number.isNaN(Number(value)))
        .map((value: any) => ({ label: getEmploymentType(value)!, value })),
    [],
  );
}
