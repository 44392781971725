import { SearchCommentsFuncResponse, SearchMentionsFuncResponse } from 'api-endpoints/applicant/models';
import { CompanyEndpoints } from 'api-endpoints/company/endpoints';
import { createApiMethod, getApiMappedFunction } from 'api-endpoints/utils';

import { requestMapperUtil, responseMapperUtil } from 'utils/api';

import * as fromModels from './models';

//--------------------------------------------------------------------------------------------------
// GET COMPANY - GetCompanyFunc
export const getCompanyInfo = createApiMethod<fromModels.GetCompanyFuncRequestParams, fromModels.CompanyInfoDto>({
  url: CompanyEndpoints.GetCompanyFunc,
});
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// PUT COMPANY - GetCompanyFunc
const updateCompanyFuncMethod = createApiMethod<fromModels.UpdateCompanyFuncRequestParams, void>({
  url: CompanyEndpoints.UpdateCompanyFunc,
  method: 'PUT',
});
export const updateCompanyFunc = getApiMappedFunction<
  fromModels.UpdateCompanyFuncRequestParams,
  ReturnType<typeof updateCompanyFuncMethod>,
  ReturnType<typeof updateCompanyFuncMethod>
>(updateCompanyFuncMethod, requestMapperUtil(), responseMapperUtil());
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// UpdateCompanyCurrenciesFunc
const updateCompanyCurrenciesFuncMethod = createApiMethod<fromModels.UpdateCompanyCurrenciesFuncRequestParams, void>({
  url: CompanyEndpoints.UpdateCompanyCurrenciesFunc,
  method: 'PUT',
});
export const updateCompanyCurrenciesFunc = getApiMappedFunction<
  fromModels.UpdateCompanyCurrenciesFuncRequestParams,
  ReturnType<typeof updateCompanyCurrenciesFuncMethod>,
  ReturnType<typeof updateCompanyCurrenciesFuncMethod>
>(updateCompanyCurrenciesFuncMethod, requestMapperUtil(), responseMapperUtil());
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// UpdateCompanyCountriesFunc
const updateCompanyCountriesFuncMethod = createApiMethod<fromModels.UpdateCompanyCountriesFuncRequestParams, void>({
  url: CompanyEndpoints.UpdateCompanyCountriesFunc,
  method: 'PUT',
});
export const updateCompanyCountriesFunc = getApiMappedFunction<
  fromModels.UpdateCompanyCountriesFuncRequestParams,
  ReturnType<typeof updateCompanyCountriesFuncMethod>,
  ReturnType<typeof updateCompanyCountriesFuncMethod>
>(updateCompanyCountriesFuncMethod, requestMapperUtil(), responseMapperUtil());
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// UpdateCompanyIndustriesFunc
const updateCompanyIndustriesFuncMethod = createApiMethod<fromModels.UpdateCompanyIndustriesFuncRequestParams, void>({
  url: CompanyEndpoints.UpdateCompanyIndustriesFunc,
  method: 'PUT',
});
export const updateCompanyIndustriesFunc = getApiMappedFunction<
  fromModels.UpdateCompanyIndustriesFuncRequestParams,
  ReturnType<typeof updateCompanyIndustriesFuncMethod>,
  ReturnType<typeof updateCompanyIndustriesFuncMethod>
>(updateCompanyIndustriesFuncMethod, requestMapperUtil(), responseMapperUtil());
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// GET COMPANY IDIBU INTEGRATION - GetCompanyIdibuSettingsFunc
export const getCompanyIdibuSettings = createApiMethod<any, fromModels.CompanyIntegrationSettingsIdibuResponse>({
  url: CompanyEndpoints.GetCompanyIdibuSettingsFunc,
});

export const updateCompanyIdibuSettings = createApiMethod<fromModels.UpdateCompanyIntegrationSettingsIdibuParams, void>(
  {
    url: CompanyEndpoints.UpdateCompanyIdibuSettingsFunc,
    method: 'PUT',
  },
);

//--------------------------------------------------------------------------------------------------
// GET COMPANY DAXTRA QUALIFICATIONS - DaxtraQualificationsFunc
export const getCompanyDaxtraQualificationsFunc = createApiMethod<any, fromModels.DaxtraQualificationResponse>({
  url: CompanyEndpoints.DaxtraQualificationsFunc,
});
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// POST UPLOAD COMPANY LOGO - UploadCompanyLogoFunc
const uploadCompanyLogoApiMethod = createApiMethod<
  fromModels.UploadCompanyLogoRequestDto,
  fromModels.CompanyLogoResponseDto
>({
  url: CompanyEndpoints.UploadCompanyLogoFunc,
  method: 'POST',
});

export const uploadCompanyLogo = getApiMappedFunction<
  fromModels.UploadCompanyLogoRequestDto,
  ReturnType<typeof uploadCompanyLogoApiMethod>,
  ReturnType<typeof uploadCompanyLogoApiMethod>
>(uploadCompanyLogoApiMethod, requestMapperUtil(), responseMapperUtil());
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// SearchCommentsFunc
export const getSearchComments = createApiMethod<any, SearchCommentsFuncResponse>({
  url: CompanyEndpoints.SearchCommentsFunc,
});
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// SearchMentionsFunc
export const getSearchMentions = createApiMethod<any, SearchMentionsFuncResponse>({
  url: CompanyEndpoints.SearchMentionsFunc,
});
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// GetAutocompleteUsersFunc
export const getAdminTeamMembers = createApiMethod<any, SearchCommentsFuncResponse>({
  url: CompanyEndpoints.GetAutocompleteUsersFunc,
});

//--------------------------------------------------------------------------------------------------
