import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styled from 'styled-components';

interface EllipsisWithTooltipProps {
  className?: string;
  label: string;
  children: React.ReactNode;
}

type EllipsisInnerProps = Omit<EllipsisWithTooltipProps, 'label'> & {
  onMouseEnter?: React.MouseEventHandler;
};

const EllipsisInnerComponent = styled.div``;

const EllipsisInner = React.forwardRef<HTMLDivElement, EllipsisInnerProps>(({ children, className, ...props }, ref) => {
  const childElement = React.Children.only(children);
  if (React.isValidElement(childElement)) {
    return React.cloneElement(childElement, {
      ref,
      className: `${childElement.props.className} ${className}`,
      ...props,
    });
  }
  return (
    <EllipsisInnerComponent ref={ref} {...props} className={className}>
      {childElement}
    </EllipsisInnerComponent>
  );
});

const EllipsisWithTooltipPure: React.FC<EllipsisWithTooltipProps> = ({ className, label, children }) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const linkRef = React.useRef<any>();

  const checkEllipsis = React.useCallback(
    () => {
      if (linkRef?.current?.offsetWidth < linkRef?.current?.scrollWidth) {
        setShowTooltip(true);
      }
    },
    // eslint-disable-next-line
    [children],
  );

  if (!showTooltip) {
    return (
      <EllipsisInner onMouseEnter={checkEllipsis} ref={linkRef} className={className}>
        {children}
      </EllipsisInner>
    );
  }

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip className="expedo-tooltip" id={`tooltip-${label}`}>
          {label}
        </Tooltip>
      }
    >
      <EllipsisInner ref={linkRef} onMouseEnter={checkEllipsis} className={className}>
        {children}
      </EllipsisInner>
    </OverlayTrigger>
  );
};

export const EllipsisWithTooltip = styled(EllipsisWithTooltipPure)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
