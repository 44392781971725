import React from 'react';
import { useFieldArray } from 'react-hook-form';
import styled from 'styled-components/macro';

import { rbacConstants } from 'containers/Auth/AuthMatrix';
import { RBAC } from 'containers/Auth/components/RBAC';
import { WorkExperienceFormBody } from 'containers/WorkExperienceForm';

import { ExAddNewButton } from 'components/ui/ExAddNewButton/ExAddNewButton';
import { ExButton } from 'components/ui/ExButton';
import { ExCard } from 'components/ui/ExCard';
import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';
import { getThemeColor } from 'utils/styled/getThemeColor';

type ApplicantModalWorkExperienceEditProps = {
  className?: string;
};

const ApplicantModalWorkExperienceEditStyled = styled(ExCard)`
  padding: 0;
  box-shadow: none;

  > div:nth-of-type(2) > div {
    background-color: ${getThemeColor('bodyBackgroundColor')};
    padding: 20px;
    border-radius: ${getThemeBorderProps('borderRadius')};
    margin-bottom: 10px;
  }
`;

const useApplicantModalWorkExperienceEditState = ({ className }: ApplicantModalWorkExperienceEditProps) => {
  const { fields, remove, append } = useFieldArray({
    name: 'workExperiences',
  });

  const addHandler = () => append({ startDate: null, endDate: null });

  const removeHandler = (index: number) => () => remove(index);

  return {
    className,
    fields,
    removeHandler,
    addHandler,
  } as const;
};

export const ApplicantModalWorkExperienceEdit: React.FC<ApplicantModalWorkExperienceEditProps> = (props) => {
  const { className, fields, removeHandler, addHandler } = useApplicantModalWorkExperienceEditState(props);

  return (
    <ApplicantModalWorkExperienceEditStyled
      className={className}
      title="Work Experience"
      headerActions={
        <RBAC feature={rbacConstants.candidates.editExperience}>
          <ExAddNewButton onClick={addHandler}>Add New</ExAddNewButton>
        </RBAC>
      }
    >
      {fields.map((field, index) => (
        <div key={field.id}>
          <WorkExperienceFormBody
            index={index}
            value={field}
            groupName="workExperiences"
            idFieldName={'jobApplicantWorkExperienceId'}
          />
          <div className="w-100"></div>
          <ExButton onClick={removeHandler(index)}>Remove</ExButton>
        </div>
      ))}
    </ApplicantModalWorkExperienceEditStyled>
  );
};
