import { createLookupReducer, InitialState } from 'utils/reducer/reducer-helper';

import { EntityKeys } from 'store/constants';
import { applicantApi } from 'store/entities/applicants/applicant.api';
import { Applicant } from 'store/entities/applicants/models';
import { enhancedJobApi } from 'store/entities/jobs/jobs.api';
import { enhancedPlacementApi } from 'store/entities/placements/placements.api';

export const applicantSlice = createLookupReducer({
  name: EntityKeys.applicants,
  initialState: {
    allIds: [],
    byId: {},
  } as InitialState<Applicant>,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(applicantApi.endpoints.getApplicant.matchFulfilled, (draft, { payload }) => {
      const savedApplicant = draft.byId[payload.applicantId];
      if (savedApplicant) {
        Object.assign(savedApplicant, payload);
      } else {
        draft.allIds.push(payload.applicantId);
        draft.byId[payload.applicantId] = payload;
      }
    });

    builder.addMatcher(enhancedPlacementApi.endpoints.placementList.matchFulfilled, (draft, { payload, type }) => {
      applicantSlice.caseReducers.upsert(draft, {
        payload: { items: payload.items.map((item) => ({ ...item, id: item.applicantId })) },
        type,
      });
    });

    builder.addMatcher(enhancedJobApi.endpoints.jobPipelines.matchFulfilled, (draft, { payload, type }) => {
      applicantSlice.caseReducers.upsert(draft, {
        payload: { items: payload.applicants.map((item) => ({ ...item, id: item.applicantId })) },
        type,
      });
    });
  },
});
