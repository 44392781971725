import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import { ListViewSelectedItems } from 'components/ListView';
import { safariFlexBoxTweak } from 'utils/styled/common';

import { Job } from 'store/entities/jobs';
import { RootState } from 'store/rootReducer';

import JobListFilter from './JobListFilter';
import { JobListComponentProps } from './JobListProps';
import { jobListActions, jobListSelectors } from './store';

const JobListHeader: React.FC<JobListComponentProps & PropsFromRedux> = ({
  listId,
  className,
  selectedEntities,
  toggleItem,
  selectable,
}) => {
  const handleCandidateRemove = (candidate: Job) => () => {
    if (listId && candidate.id) {
      toggleItem({ id: listId, selectedItemId: candidate.id });
    }
  };

  return (
    <div className={className}>
      <JobListFilter className="list-filter-container" listId={listId} />
      {selectable && (
        <ListViewSelectedItems
          entityName="job"
          handleItemRemove={handleCandidateRemove}
          selectedEntities={selectedEntities}
          getItemId={(item: Job) => item.id}
          getItemValue={(item: Job) => item.jobName}
        />
      )}
    </div>
  );
};

const mapDispatch = {
  toggleItem: jobListActions.toggleItemSelect,
};

const mapState = (state: RootState, own: JobListComponentProps) => ({
  selectedEntities: jobListSelectors.getSelectedEntities(state, own),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default styled(connector(JobListHeader))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  ${safariFlexBoxTweak};

  .list-filter-container {
    margin-bottom: 4px;
  }

  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;
