import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';

import { candidates } from 'containers/Auth/AuthMatrix/authMatrix.constants';
import { useRBAC } from 'containers/Auth/components/RBAC';

import { FormInput } from 'components/FormInput';

import { Candidate } from 'store/entities/candidates/models';

type ApplicantModalNameInfoEditProps = {
  className?: string;
};

const ApplicantModalNameInfoEditStyled = styled.div``;

const useApplicantModalNameInfoEditState = ({ className }: ApplicantModalNameInfoEditProps) => {
  const { errors, formState, register } = useFormContext<Candidate>();
  const disabled = !useRBAC(candidates.editDetails);
  const validated = formState.isSubmitted;

  return {
    className,
    errors,
    validated,
    register,
    disabled,
  } as const;
};

export const ApplicantModalNameInfoEdit: React.FC<ApplicantModalNameInfoEditProps> = (props) => {
  const { className, errors, validated, register, disabled } = useApplicantModalNameInfoEditState(props);

  return (
    <ApplicantModalNameInfoEditStyled className={className}>
      <FormInput
        label="First Name"
        name="firstName"
        required={!disabled}
        errors={errors?.firstName}
        validated={validated}
        inputRef={register}
        disabled={disabled}
      />
      <FormInput
        label="Last Name"
        name="lastName"
        required={!disabled}
        errors={errors?.lastName}
        validated={validated}
        inputRef={register}
        disabled={disabled}
      />
    </ApplicantModalNameInfoEditStyled>
  );
};
