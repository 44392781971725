import React from 'react';
import styled from 'styled-components';

import { ExButton } from 'components/ui/ExButton/ExButton';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

import { DialogContentChildrenProps, DialogProps } from './DialogProps';

const useDialogState = ({ children, onClose, content, className, theme }: DialogProps) => {
  return { children, onClose, content, className, theme };
};

const DialogBody: React.FC<DialogProps> = (props) => {
  const { children, onClose, content, theme } = useDialogState(props);

  if (children) {
    return React.cloneElement(children as React.ReactElement, { ...content.childrenProps, theme });
  }

  if (content.children) {
    return React.createElement(content.children, { ...content.childrenProps, onClose, theme });
  }

  if (content.message) {
    return <>{content.message}</>;
  }

  return null;
};

const DialogPure: React.FC<DialogProps> = (props) => {
  const { onClose, content, className } = useDialogState(props);

  return (
    <div className={className}>
      {content.withTitle && <div className="dialog-title">{content.title}</div>}
      <div className="dialog-body">
        <DialogBody {...props} />
      </div>
      {content.withActions && (
        <div className="dialog-actions">
          <ExButton
            variant={content.buttonCancelVariant ?? 'light'}
            className={content.buttonOk ? 'mr-3' : ''}
            onClick={() => onClose(false)}
          >
            {content.buttonCancel}
          </ExButton>
          {content.buttonOk && (
            <ExButton variant={content.buttonOkVariant ?? 'primary'} onClick={() => onClose(true)}>
              {content.buttonOk}
            </ExButton>
          )}
        </div>
      )}
    </div>
  );
};

export const makeChildren =
  <P extends DialogContentChildrenProps>(Component: React.ComponentType<P>) =>
  (props: P) =>
    <Component {...props} />;

/**
 * @deprecated Use ExDialog
 */
export const Dialog = styled(DialogPure)`
  background-color: ${({ theme }) => theme.colors.white};
  min-width: 300px;
  border-radius: ${({ theme }) => theme.border.borderRadius};
  flex-direction: column;
  display: flex;
  max-height: 100vh;
  padding: 24px;

  .dialog {
    &-title {
      text-align: center;
      font-size: ${getThemeFontSize('extraLarge')};
      font-weight: 600;
      letter-spacing: ${({ theme }) => theme.fonts.headingBaseNormal.letterSpacing};
      line-height: ${({ theme }) => theme.fonts.headingBaseNormal.lineHeight};
      padding: 0;
    }

    &-body {
      display: flex;
      justify-content: center;
      overflow: auto;
      height: 100%;
      padding: 24px 0;
    }

    &-actions {
      text-align: center;
      padding: 0;
    }
  }
`;
