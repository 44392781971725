import React, { useMemo } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Routes } from 'router';

import { AppRouterParams } from 'model/router';

import { ApplicantsTabMenuSection } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/ApplicantModalV3Components';
import {
  ApplicantCoverLetterTab,
  ApplicantResumeTab,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/Tabs';
import {
  ApplicantModalAlertsWrapper,
  ApplicantModalBodyStyled,
  TabContentWrapperStyled,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalV4Components';
import { InterviewsTab } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/Tabs/InterviewsTab';

import { ExTabMenuV2, NavItem } from 'components/TabMenu';

import { applicantSelectors } from 'store/entities/applicants';
import { useSelector } from 'store/rootSelectors';

import { ApplicantModalAlert } from './ApplicantModalAlert';
import { ApplicantOverviewTab, ApplicationsTab } from './Tabs';

type ApplicantModalBodyProps = {
  className?: string;
};

const useApplicantModalBodyState = ({ className }: ApplicantModalBodyProps) => {
  const match = useRouteMatch<AppRouterParams>();

  const { applicantId } = match.params;
  const applicant = useSelector((state) => applicantSelectors.selectApplicantModalData(state, applicantId));
  const previousApplicationsNumber = applicant?.previousApplicationsNumber;
  const numberOfInterviews = applicant?.numberOfInterviews;

  const navTabs: NavItem[] = useMemo(
    () => [
      {
        component: ApplicantOverviewTab,
        label: 'Overview',
        path: Routes.applicantOverview,
      },
      {
        component: ApplicantResumeTab,
        label: 'Resume',
        path: Routes.applicantResume,
      },
      {
        component: ApplicantCoverLetterTab,
        label: 'Cover Letter',
        path: Routes.applicantCoverLetter,
      },
      {
        component: InterviewsTab,
        label: 'Interviews',
        path: Routes.applicantInterviews,
        badgeNumber: numberOfInterviews,
      },
      ...(!!previousApplicationsNumber
        ? [
            {
              component: ApplicationsTab,
              label: 'Other Applications',
              path: Routes.applicantApplications,
              badgeNumber: previousApplicationsNumber + 1,
            },
          ]
        : []),
    ],
    [previousApplicationsNumber, numberOfInterviews],
  );

  return {
    className,
    match,
    applicant,
    navTabs,
  } as const;
};

export const ApplicantModalBody: React.FC<ApplicantModalBodyProps> = (props) => {
  const { className, match, applicant, navTabs } = useApplicantModalBodyState(props);

  return (
    <ApplicantModalBodyStyled className={className}>
      <ApplicantsTabMenuSection>
        <ExTabMenuV2 navItems={navTabs} />
      </ApplicantsTabMenuSection>
      <ApplicantModalAlertsWrapper>
        <ApplicantModalAlert />
      </ApplicantModalAlertsWrapper>
      <TabContentWrapperStyled>
        <Switch>
          {navTabs.map(({ component: Component, ...route }) => (
            <Route
              key={route.path}
              path={`${match.path}/${route.path}`}
              render={() => <Component className="applicant-tab" item={applicant} />}
            />
          ))}
          <Redirect from="*" to={`${match.url}/${Routes.applicantOverview}`} />
        </Switch>
      </TabContentWrapperStyled>
    </ApplicantModalBodyStyled>
  );
};
