import { TextEditorToolbarExtensions } from 'components/Form/TextEditor/TextEditorProps';

export const ExEmailEditorConfig = {
  height: '100%',
  plugins: ['variables', 'link'],
  toolbar: [
    'undo',
    'redo',
    '|',
    'sizeselect',
    'fontsizeselect',
    '|',
    'bold',
    'italic',
    'numlist',
    'bullist',
    '|',
    'alignleft',
    'aligncenter',
    'alignjustify',
    'link',
    '|',
    'ExMergeFields',
  ] as TextEditorToolbarExtensions[],
};
