import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityAdapter,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import { LoaderEntity } from 'modules/LoaderManager/model';
import { adapterHelper } from 'utils/reducer/adapter-helper';

function createLoaderManagerSlice() {
  const loaderManagerAdapter = createEntityAdapter<LoaderEntity>();
  const slice = createSlice({
    initialState: loaderManagerAdapter.getInitialState(),
    name: 'loaderManager',
    reducers: {
      ...adapterHelper(loaderManagerAdapter),
      reset(state) {
        const stateToUpdate: Array<Update<LoaderEntity>> = Object.values(state.entities)
          .filter((loaderEntity) => Boolean(loaderEntity?.id))
          .map((loaderEntity) => ({
            changes: {
              state: 'idle',
            },
            id: loaderEntity?.id!,
          }));

        slice.caseReducers.updateMany(state, stateToUpdate);
      },
      start(state, action: PayloadAction<LoaderEntity['id']>) {
        slice.caseReducers.upsertOne(state, { id: action.payload, state: 'processing' });
      },
      stop(state, action: PayloadAction<LoaderEntity['id']>) {
        slice.caseReducers.upsertOne(state, { id: action.payload, state: 'idle' });
      },
    },
  });

  return {
    actions: slice.actions,
    adapter: loaderManagerAdapter,
    reducer: slice.reducer,
  };
}

function createLoaderManagerSelectors<T extends { [X: string]: any }>(adapter: EntityAdapter<LoaderEntity>) {
  const domain = createSelector(
    (state: T) => state,
    (state) => state['loaderManager'],
  );

  const loaderManagerAdapterSelectors = adapter.getSelectors(domain);
  const selectStateById = createSelector(
    loaderManagerAdapterSelectors.selectById,
    (loaderEntity) => loaderEntity?.state,
  );
  const selectIsLoadingById = createSelector(selectStateById, (state) => state === 'processing');
  const selectIsSomeProcessing = createSelector(loaderManagerAdapterSelectors.selectAll, (loaderEntities) =>
    loaderEntities.some((loaderEntity) => loaderEntity.state === 'processing'),
  );

  return {
    ...loaderManagerAdapterSelectors,
    selectIsLoadingById,
    selectIsSomeProcessing,
    selectStateById,
  };
}

export const loaderManagerSlice = createLoaderManagerSlice();
export const loaderManagerSelectors = createLoaderManagerSelectors(loaderManagerSlice.adapter);
export const loaderManagerActions = loaderManagerSlice.actions;
export const loaderManagerReducer = loaderManagerSlice.reducer;
