import React from 'react';
import styled from 'styled-components/macro';

import {
  ApplicantInfoLabel,
  IconSeniorityLevelStyled,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/ApplicantModalV3Components';
import { ApplicantModalInfoWrapper } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalV4Components';
import { useApplicantFromModal } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/hook/useApplicantFromModal';

type ApplicantModalSeniorityLevelInfoViewProps = {
  className?: string;
};

const ApplicantModalSeniorityLevelInfoViewStyled = styled(ApplicantModalInfoWrapper)``;

const useApplicantModalSeniorityLevelInfoViewState = ({ className }: ApplicantModalSeniorityLevelInfoViewProps) => {
  const applicant = useApplicantFromModal();
  const seniority = applicant?.seniority;

  return {
    className,
    seniority,
  } as const;
};

export const ApplicantModalSeniorityLevelInfoView: React.FC<ApplicantModalSeniorityLevelInfoViewProps> = (props) => {
  const { className, seniority } = useApplicantModalSeniorityLevelInfoViewState(props);

  return (
    <ApplicantModalSeniorityLevelInfoViewStyled className={className}>
      <IconSeniorityLevelStyled />
      <div>
        <div>{seniority}</div>
        <ApplicantInfoLabel>Seniority Level</ApplicantInfoLabel>
      </div>
    </ApplicantModalSeniorityLevelInfoViewStyled>
  );
};
