import { push } from 'connected-react-router';
import { all, call, fork, put, putResolve, race, select, take, takeLatest } from 'redux-saga/effects';

import { getPreviousJobApplicationsNumberFunc, updateJobApplicantFunc } from 'api-endpoints/applicant';
import { cancelDelayedPipelineStageAction } from 'api-endpoints/hiring-pipeline';

import { PipelineStageType } from 'model/api-enums.constants';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { applicantListEffects } from 'containers/ApplicantLists/store';
import { loadApplicant } from 'containers/ApplicantLists/store/effects';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';
import { isApplicantsModalPath } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/utils';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { getTranslate } from 'utils/i18utils';
import { invokeApiCall, invokeExModal, prepareExModalChannel, ReturnData } from 'utils/sagas';

import { companySelectors } from 'store/company/company.selectors';
import { Applicant, ApplicantBelongsTo, applicantsActions } from 'store/entities/applicants';
import {
  applicantsModalFetchWorker,
  updateApplicantAfterStageChange,
} from 'store/entities/applicants/sagas/applicantsModalFetchWorker.saga';
import { applicantSelectors } from 'store/entities/applicants/selectors';
import { utilsPrepareUpdateApplicantData } from 'store/entities/applicants/utils/prepareUpdateApplicantData';
import { candidatesApi } from 'store/entities/candidates/candidates.api';
import { hiringPipelinesStagesSelectors } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.reducer';
import type { HiringPipelineStage } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.types';
import { jobEffects } from 'store/entities/jobs/job.effects';
import { exModalCancelAction, exModalConfirmAction, exModalHideAction } from 'store/modals/modals.actions';
import { ModalGeneralResult } from 'store/modals/modals.interfaces';
import { routerSelectors } from 'store/router';

function* applicantsModalShowWorker({ payload }: ReturnType<typeof applicantsActions.applicantsModalShow>) {
  const { modalId, sagaChannel } = yield prepareExModalChannel();

  yield fork(invokeExModal, {
    channel: sagaChannel,
    modalId,
    modalType: ModalsTypeKey.applicantViewModal,
    modalProps: { applicantId: payload },
  });
}

type CloseApplicantsModalProps = {
  applicant?: Applicant;
  stageId: string;
};

function* closeApplicantsModal({ applicant, stageId }: CloseApplicantsModalProps) {
  const route = yield select(routerSelectors.selectLocation);

  if (!applicant) {
    return;
  }

  const { pathname } = route;
  const currentStage: HiringPipelineStage = yield select(
    hiringPipelinesStagesSelectors.selectById,
    applicant.pipelineStageId!,
  );
  const nextStage: HiringPipelineStage = yield select(hiringPipelinesStagesSelectors.selectById, stageId);
  const backPathname: string = pathname.split('/').slice(0, -2).join('/');

  const wasInPipeline = ![PipelineStageType.New].includes(currentStage.stageType ?? '');
  const willBeInPipeline = ![PipelineStageType.New].includes(nextStage.stageType ?? '');

  const movedOutOfPipeline = (wasInPipeline && willBeInPipeline) === false;

  if (movedOutOfPipeline) {
    yield put(push({ pathname: backPathname }));
  }
}

const findRequiredStageBetween = (
  pipelineStages: HiringPipelineStage[],
  currentStage?: HiringPipelineStage,
  nextStage?: HiringPipelineStage,
) => {
  if (!pipelineStages.length || !nextStage || !currentStage) {
    return null;
  }

  if (nextStage.order < currentStage.order) {
    return null;
  }

  const foundStage = pipelineStages.find((stage) => {
    return stage.order > currentStage.order && stage.order < nextStage.order && stage.isRequired;
  });

  return foundStage || null;
};

/**
 * I move updating applicant process into the separate method to prevent code duplication;
 */
function* applicantsStageChangeWorkerUpdateFlow({
  jobId,
  applicantId,
  stageId,
  currentStageId,
}: ReturnType<typeof applicantsActions.applicantsStageChange>['payload']) {
  const pipelineStages = yield select(hiringPipelinesStagesSelectors.selectJobPipelineStages, jobId);
  const currentStage: HiringPipelineStage | undefined = yield select(
    hiringPipelinesStagesSelectors.selectById,
    currentStageId,
  );
  const nextStage: HiringPipelineStage | undefined = yield select(hiringPipelinesStagesSelectors.selectById, stageId);

  const isNextStageContainsActions = Boolean(nextStage?.needConfirmationModal);

  const requiredStageBetween = findRequiredStageBetween(pipelineStages, currentStage, nextStage);

  const requestPayload = {
    jobId,
    applicantId,
    stageId,
    requiredStageBetween,
  };
  const { message, cancellationId } = yield putResolve(jobEffects.changeWorkflowStage(requestPayload) as any);

  if (message) {
    return false;
  }

  if (isNextStageContainsActions) {
    yield put(
      alertsEffects.showCancelableCountdown({
        message: `Sending email to applicant confirming progress to "${nextStage?.name}".\n Do you want to cancel this?`,
        cancelMessage: 'Email sending cancelled',
        successMessage: 'Email sent successfully',
        duration: 10000,
        onCancel: () => {
          cancelDelayedPipelineStageAction({ urlParams: { cancellationId }, data: { cancellationId } });
        },
      }),
    );
  }

  /**
   * Here we have to do requests sequentially because of there
   * need some time on the BE to commit the applicants stage change.
   * And if we will invoke these requests concurrently
   * we still receive the old state of applicants in the loadApplicants (list) response.
   */
  yield putResolve(applicantListEffects.loadApplicants({ listId: ApplicantBelongsTo.job }) as any);

  yield updateApplicantAfterStageChange({ applicantId, jobId, stageId });

  yield put(alertsEffects.showSuccess({ message: `Successfully moved applicant to ${nextStage?.name}` }));

  return true;
}

function* applicantsStageChangeWorker(action: ReturnType<typeof applicantsActions.applicantsStageChange>) {
  const { applicantId, jobId, stageId, currentStageId, pushPath } = action.payload;

  /**
   * Here we create bounded action which will undo the applicants stage change
   * in cases when user cancels the action or something went wrong with the API call;
   */
  const undoApplicantChangeStageAction = applicantsActions.updateOne.bind(null, {
    id: applicantId,
    pipelineStageId: currentStageId,
    stageChangedOn: new Date().toISOString(),
  });

  const applicant: Applicant | undefined = yield select(applicantSelectors.getById, applicantId);
  const nextStage: HiringPipelineStage | undefined = yield select(hiringPipelinesStagesSelectors.selectById, stageId);

  yield put(
    applicantsActions.updateOne({
      id: applicantId,
      pipelineStageId: nextStage?.pipelineStageId,
      stageChangedOn: new Date().toISOString(),
    }),
  );

  const isNextStageReadyToOnboard = yield select(
    hiringPipelinesStagesSelectors.selectIsPipelineStageIsReadyToOnboardStage,
    nextStage?.pipelineStageId!,
  );

  const isEnabledInHr = yield select(companySelectors.selectIsEnabledInHr);
  const showReadyToOnboardModal = isEnabledInHr && isNextStageReadyToOnboard;

  /**
   * If the next stage contains actions or it has stageType of `Hired`
   * we must show the confirmation modal to allow user to cancel this action.
   */
  if (stageId !== currentStageId && showReadyToOnboardModal) {
    const { modalId, sagaChannel } = yield call(prepareExModalChannel);
    yield fork(invokeExModal, {
      modalId,
      channel: sagaChannel,
      modalType: ModalsTypeKey.applicantConfirmMoveModal,
      modalConfig: {
        content: {
          withActions: true,
          withTitle: true,
          title: "Confirm Applicant's Details",
          message: 'The following details are going to be shared with the Onboarding Manager:',
        },
      },
      modalProps: {
        applicantId,
        jobId,
      },
    });

    while (true) {
      yield call(stopLoader, action);
      const { confirm, cancel }: ModalGeneralResult = yield take(sagaChannel);

      if ([cancel, !confirm].some(Boolean)) {
        yield put(undoApplicantChangeStageAction());
        yield call(stopLoader, action);
        yield put(exModalHideAction({ id: modalId }));
        return;
      }

      yield call(startLoader, action);
      const result = yield applicantsStageChangeWorkerUpdateFlow({ jobId, applicantId, stageId, currentStageId });
      if (!result) {
        yield put(undoApplicantChangeStageAction());
        yield put(exModalHideAction({ id: modalId }));
        continue;
      }
      break;
    }
    yield put(exModalHideAction({ id: modalId }));
  } else {
    yield call(startLoader, action);
    const result = yield applicantsStageChangeWorkerUpdateFlow({ jobId, applicantId, stageId, currentStageId });
    if (!result) {
      yield call(stopLoader, action);
      yield put(undoApplicantChangeStageAction());
      return;
    }
  }

  yield call(stopLoader, action);
  /**
   * Here we close applicants modal via changing the route because
   * applicants modal is related to the router change but not redux;
   */
  const { pathname } = yield select(routerSelectors.selectLocation);
  const isApplicantsModalShown = Boolean(isApplicantsModalPath(pathname));

  if (pushPath && isApplicantsModalShown) {
    yield put(push(pushPath));
    return;
  }

  yield closeApplicantsModal({ applicant, stageId });
}

function* applicantsModalEditDetailsWorker(
  action: ReturnType<typeof applicantsActions.applicantsModalEditDetailsShow>,
) {
  const applicantId = action.payload;

  const { modalId, sagaChannel } = yield prepareExModalChannel();

  yield fork(invokeExModal, {
    channel: sagaChannel,
    modalId,
    modalType: ModalsTypeKey.applicantDetailsEditModal,
    modalProps: { applicantId },
    modalConfig: {
      content: {
        title: 'Edit Applicant details',
        withTitle: true,
      },
    },
  });

  while (sagaChannel) {
    const { confirm, cancel }: ModalGeneralResult = yield race({
      confirm: take(exModalConfirmAction),
      cancel: take(exModalCancelAction),
    });

    if (!confirm || cancel) {
      yield put(exModalHideAction({ id: modalId }));
      break;
    }

    yield startLoader(action);
    const formApplicant = confirm.payload.modalResult?.applicant;
    const data = formApplicant;

    const { errorData, message }: ReturnData<typeof updateJobApplicantFunc> = yield invokeApiCall(
      updateJobApplicantFunc,
      {
        data,
        urlParams: {
          applicantId,
          jobId: data.jobId,
        },
      },
    );
    yield stopLoader(action);
    if (!message && !errorData) {
      yield all([
        put(exModalHideAction({ id: modalId })),
        put(loadApplicant({ applicantId, jobId: data.jobId }) as any),
        put(alertsEffects.showSuccess({ message: getTranslate('applicant.update.success') })),
      ]);
      break;
    }
  }
}

function* applicantsModalPatchUpdateWorker(action: ReturnType<typeof applicantsActions.applicantsModalUpdatePatch>) {
  yield startLoader(action);
  const { id: applicantId, updateList, ...rest } = action.payload;

  const { data, oldData } = yield call(utilsPrepareUpdateApplicantData, applicantId, { ...rest });

  yield put(applicantsActions.updateOne({ id: applicantId, ...rest }));

  const { errorData, message }: ReturnData<typeof updateJobApplicantFunc> = yield invokeApiCall(
    updateJobApplicantFunc,
    {
      data,
      urlParams: {
        applicantId,
        jobId: data.jobId,
      },
    },
  );
  yield stopLoader(action);
  if (message || errorData) {
    yield all([
      put(applicantsActions.updateOne({ id: applicantId, ...oldData })),
      put(loadApplicant({ applicantId, jobId: data.jobId }) as any),
    ]);
    return;
  }
  yield put(candidatesApi.util.invalidateTags([{ type: 'Candidates', id: oldData.candidateId }]));
  yield put(alertsEffects.showSuccess({ message: getTranslate('applicant.update.success') }));
  if (updateList) {
    yield put(applicantListEffects.loadApplicants({ listId: ApplicantBelongsTo.candidate }) as any);
  }
}

function* getPreviousJobApplicationsNumberWorker(
  action: ReturnType<typeof applicantsActions.getPreviousJobApplicationsNumberAction>,
) {
  const { applicantId, candidateId } = action.payload;

  const { data }: { data: { previousApplicationsNumber: number } } = yield invokeApiCall(
    getPreviousJobApplicationsNumberFunc,
    {
      urlParams: {
        applicantId,
        candidateId,
      },
    },
  );

  if (data) {
    const previousApplicationsNumber = data.previousApplicationsNumber;

    if (previousApplicationsNumber !== undefined) {
      yield put(applicantsActions.updateOne({ id: applicantId, previousApplicationsNumber }));
    }
  }
}

export function* applicantsSagas() {
  yield takeLatest(applicantsActions.applicantsModalShow, applicantsModalShowWorker);
  yield takeLatest(applicantsActions.applicantsModalFetchAction, applicantsModalFetchWorker);
  yield takeLatest(applicantsActions.applicantsStageChange, applicantsStageChangeWorker);
  yield takeLatest(applicantsActions.applicantsStageChangeFromPipeline, applicantsStageChangeWorker);
  yield takeLatest(applicantsActions.applicantsModalEditDetailsShow, applicantsModalEditDetailsWorker);
  yield takeLatest([applicantsActions.applicantsModalUpdatePatch], applicantsModalPatchUpdateWorker);
  yield takeLatest(applicantsActions.getPreviousJobApplicationsNumberAction, getPreviousJobApplicationsNumberWorker);
}
