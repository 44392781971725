import styled, { DefaultTheme } from 'styled-components';

import { DropZonePlaceholderProps } from './AppImagePickerDialogProps';

export const AppImagePickerDialogStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const getColor = ({
  theme,
  isDragAccept,
  isDragActive,
  isDragReject,
  isImageSelected,
}: DropZonePlaceholderProps & { theme: DefaultTheme }) => {
  if (isDragAccept) {
    return theme.colors.success;
  }
  if (isDragReject) {
    return theme.colors.danger;
  }
  if (isDragActive) {
    return theme.colors.primary;
  }
  if (isImageSelected) {
    return 'transparent';
  }

  return theme.colors.light;
};

export const AppDropZone = styled.div<DropZonePlaceholderProps>`
  height: ${(p) => (p.isImageSelected ? 'auto' : '232px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  margin: 0 auto;
  border-width: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export const AppDropZonePlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
