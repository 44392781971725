import { useMemo, useRef } from 'react';

import { ExHeaderInnerProps, ExHeaderProps, TableFooterCountersProps, UseExTableStateProps } from './ExTable.props';
import { addSticky, addStickyInitialValue, getTableCounters } from './ExTable.utils';

export const useExTableState = <T extends {}, S>({
  items,
  headers,
  minCellWidth,
  pageCount,
  pageNo,
  isLoading,
  selectId,
  isError,
  onSortClick,
  orderDir,
  orderBy,
  scrolled = true,
  tableFooterText,
}: UseExTableStateProps<T, S>) => {
  const sortClickHandler =
    (header: ExHeaderInnerProps<T, S>): React.MouseEventHandler =>
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const orderByValue = header.sortBy; //?? header.field;
      onSortClick?.({ orderBy: orderByValue });
    };

  const tableRef = useRef<HTMLTableElement>(null);
  const tbodyRef = useRef<HTMLTableSectionElement>(null);

  /**
   * Update incoming headers for sticky columns
   */
  const preparedHeaders = useMemo(
    () =>
      [
        ...headers
          .reduce<{ result: Array<ExHeaderProps<T, S>>; offset: number }>(
            addSticky(minCellWidth, 'left'),
            addStickyInitialValue(),
          )
          .result.reduceRight<{ result: Array<ExHeaderProps<T, S>>; offset: number }>(
            addSticky(minCellWidth, 'right'),
            addStickyInitialValue(),
          ).result,
      ].reverse(),
    [headers, minCellWidth],
  );

  const headerWithSort = useMemo(
    () =>
      preparedHeaders.map((item) => {
        if (orderBy === item.sortBy) {
          return { ...item, sortDir: orderDir };
        }
        return item;
      }),
    [orderBy, orderDir, preparedHeaders],
  );

  const columnsCount = Number(headers.length);

  /**
   * if props selectId provided we replace id in items.
   * For rendering rows
   */
  const preparedData = useMemo(
    () => (selectId ? items.map((item) => ({ ...item, id: selectId(item) })) : items),
    [items, selectId],
  );

  /**
   * We show overlay when data is fetching and previous items was provided
   */
  const showLoadingOverlay = Boolean(isLoading && preparedData.length);

  /**
   * We show row with loader when is fetching and items array is empty
   */
  const showLoadingRow = Boolean(isLoading && !preparedData.length);

  return {
    data: preparedData as Array<T & { id: string | number }>,
    headers: headerWithSort,
    minCellWidth,
    sortClickHandler,
    pageCount,
    pageNo,
    showLoadingRow,
    isError,
    columnsCount,
    showLoadingOverlay,
    tbodyRef,
    tableRef,
    scrolled,
    tableFooterText,
  };
};

export const useTableFooterCounters = ({
  pageCount,
  pageNo,
  pageSize,
  totalItemsCount,
  section = 'rows',
}: TableFooterCountersProps) => {
  const tableFooterCountersText = getTableCounters({ pageCount, pageNo, pageSize, totalItemsCount, section });

  return {
    tableFooterCountersText,
  };
};
