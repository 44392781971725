/* eslint-disable sonarjs/no-duplicate-string */
export enum CandidateEndpoints {
  // PUT
  UpdateCandidateFunc = '/api/company/{companyId}/candidate/{candidateId}',
  // POST
  CreateCandidateEducationFunc = '/api/company/{companyId}/candidate/{candidateId}/education',
  //GET
  GetCandidateEducationsFunc = '/api/company/{companyId}/candidate/{candidateId}/education',
  //PUT
  UpdateCandidateEducationFunc = '/api/company/{companyId}/candidate/{candidateId}/education/{educationId}',
  // GET
  GetCandidateEducationFunc = '/api/company/{companyId}/candidate/{candidateId}/education/{educationId}',
  // DELETE
  DeleteCandidateEducationFunc = '/api/company/{companyId}/candidate/{candidateId}/education/{educationId}',
  // POST
  CreateCandidateWorkExperienceFunc = '/api/company/{companyId}/candidate/{candidateId}/work-experience',
  // GET
  GetCandidateWorkExperiencesFunc = '/api/company/{companyId}/candidate/{candidateId}/work-experience',
  // PUT
  UpdateCandidateWorkExperienceFunc = '/api/company/{companyId}/candidate/{candidateId}/work-experience/{workExperienceId}',
  // GET
  GetCandidateWorkExperienceFunc = '/api/company/{companyId}/candidate/{candidateId}/work-experience/{workExperienceId}',
  // DELETE
  DeleteCandidateWorkExperienceFunc = '/api/company/{companyId}/candidate/{candidateId}/work-experience/{workExperienceId}',

  // POST
  UploadCandidatePhotoFunc = '/api/company/{companyId}/candidate/{candidateId}/photo',

  // DELETE
  DeleteCandidatePhotoFunc = '/api/company/{companyId}/candidate/{candidateId}/photo',
  //POST
  UploadCandidateEducationAttachmentFunc = '/api/company/{companyId}/candidate/{candidateId}/education-attachment',

  // GET
  GetTalentPoolKeywordsFunc = '/api/company/{companyId}/talent-pool/{talentPoolId}/keyword',
  // GET
  GetJobKeywordsFunc = '/api/company/{companyId}/job/{jobId}/keyword',
}

export enum CandidateEndpointsForApi {
  // GET
  SearchCommentsForCandidateFunc = '/candidate/{candidateId}/comment',
  // POST
  AddCommentForCandidateFunc = '/candidate/{candidateId}/comment',
  // GET
  GetCommentForCandidateFunc = '/candidate/{candidateId}/comment/{commentId}',
  // PUT
  UpdateCommentForCandidateFunc = '/candidate/{candidateId}/comment/{commentId}',
  // DELETE
  DeleteCommentForCandidateFunc = '/candidate/{candidateId}/comment/{commentId}',
  // POST
  ReadCommentsForCandidateFunc = '/candidate/{candidateId}/comment/read',
  // POST
  ReadAllCommentForCandidateFunc = '/candidate/{candidateId}/comment/read-all',
  // POST
  SearchCandidatesFunc = '/candidate/search',
  // GET
  GetCandidateFunc = '/candidate/{candidateId}',
  // PUT
  UpdateCandidateFunc = '/candidate/{candidateId}',
  // POST
  CreateCandidateEducationFunc = '/candidate/{candidateId}/education',
  //GET
  GetCandidateEducationsFunc = '/candidate/{candidateId}/education',
  //PUT
  UpdateCandidateEducationFunc = '/candidate/{candidateId}/education/{educationId}',
  // GET
  GetCandidateEducationFunc = '/candidate/{candidateId}/education/{educationId}',
  // DELETE
  DeleteCandidateEducationFunc = '/candidate/{candidateId}/education/{educationId}',
  // POST
  CreateCandidateWorkExperienceFunc = '/candidate/{candidateId}/work-experience',
  // GET
  GetCandidateWorkExperiencesFunc = '/candidate/{candidateId}/work-experience',
  // PUT
  UpdateCandidateWorkExperienceFunc = '/candidate/{candidateId}/work-experience/{workExperienceId}',
  // GET
  GetCandidateWorkExperienceFunc = '/candidate/{candidateId}/work-experience/{workExperienceId}',
  // DELETE
  DeleteCandidateWorkExperienceFunc = '/candidate/{candidateId}/work-experience/{workExperienceId}',

  // POST
  UploadCandidatePhotoFunc = '/candidate/{candidateId}/photo',

  // DELETE
  DeleteCandidatePhotoFunc = '/candidate/{candidateId}/photo',
  //POST
  UploadCandidateEducationAttachmentFunc = '/candidate/{candidateId}/education-attachment',

  // GET
  GetTalentPoolKeywordsFunc = '/talent-pool/{talentPoolId}/keyword',
  // GET
  GetJobKeywordsFunc = '/job/{jobId}/keyword',
}
