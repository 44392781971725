import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import validationErrorCodes from 'lang/validation-error-codes';
import styled from 'styled-components/macro';

import { InterviewQuestionsValidationErrorCodes } from 'model/api-errors.constants';

import { FormControlContainerWithErrorMessage } from 'components/Form/FormControlContainerWithErrorMessage';
import { FormInput } from 'components/FormInput';
import { QuestionViewLoading } from 'components/InterviewTemplate/QuestionView/QuestionViewLoading';
import { QuestionViewProps } from 'components/InterviewTemplate/QuestionView/QuestionViewProps';
import { getTranslate } from 'utils/i18utils';

import { useUpdateInterviewQuestionMutation } from 'store/entities/interview-question/interview-question.api';

type QuestionTextAreaProps = QuestionViewProps & {
  className?: string;
};

const QuestionTextAreaStyled = styled.div`
  width: 100%;
`;

const FormInputStyled = styled(FormInput)`
  margin-bottom: 0;

  textarea {
    border-radius: 20px;
    min-height: 88px;

    &:disabled {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const MAX_LENGTH = 2500;

const useQuestionTextAreaState = ({ className, editable, question, interviewId }: QuestionTextAreaProps) => {
  const [updateInterviewQuestionMutation, { isLoading }] = useUpdateInterviewQuestionMutation();
  const disabled = !editable || isLoading;

  const name = question.questionId;
  const { register, setError, errors } = useFormContext();
  const fieldError = errors[name];

  const onBlur = useCallback(
    (event) => {
      if (interviewId) {
        const { interviewQuestionFiles, ...questionForUpdate } = question;
        updateInterviewQuestionMutation({
          ...questionForUpdate,
          answer: event.target.value,
          interviewQuestionId: question.questionId,
          interviewId,
        }).then((result) => {
          const { error } = result as { error?: { data: { validationErrorCodes: string[] } } };
          if (error) {
            const message = error.data.validationErrorCodes
              .map((code) => getTranslate(code, { ns: 'validationErrorCodes' }))
              .join(', ');
            setError(name, { message });
          } else {
            setError(name, {});
          }
        });
      }
    },
    [interviewId, name, question, setError, updateInterviewQuestionMutation],
  );

  return {
    className,
    disabled,
    name,
    onBlur,
    isLoading,
    register,
    fieldError,
  } as const;
};

export const QuestionTextArea: React.FC<QuestionTextAreaProps> = (props) => {
  const { className, disabled, name, onBlur, isLoading, register, fieldError } = useQuestionTextAreaState(props);

  return (
    <QuestionTextAreaStyled className={className}>
      <QuestionViewLoading isLoading={isLoading} />
      <FormControlContainerWithErrorMessage show={!!fieldError} errors={fieldError}>
        <FormInputStyled
          asIs="textarea"
          classNameInput="form-control"
          label=""
          name={name}
          disabled={disabled}
          onBlur={onBlur}
          inputRef={
            register({
              required: props.question.isMandatory
                ? validationErrorCodes[InterviewQuestionsValidationErrorCodes.EmptyMandatoryAnswer]
                : false,
              maxLength: MAX_LENGTH,
            }) as any
          }
        />
      </FormControlContainerWithErrorMessage>
    </QuestionTextAreaStyled>
  );
};
