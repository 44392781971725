import styled from 'styled-components';

import { ExButton } from './ExButton';

export const ExFilterClearButton = styled(ExButton)`
  color: inherit;
  transition: color 0.2s ease-out;
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;
