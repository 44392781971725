import * as effects from 'containers/CandidateForm/CandidateFormState/candidate-effects';
import { candidateFormReducer } from 'containers/CandidateForm/CandidateFormState/candidate-form.reducer';

export { candidateFormActions } from 'containers/CandidateForm/CandidateFormState/candidate-form.reducer';

export { candidateFormSelectors } from 'containers/CandidateForm/CandidateFormState/candidate.selectors';

export const candidateFormEffects = effects;

export default candidateFormReducer;
