import { takeEvery } from 'redux-saga/effects';

import * as fromInterviewTemplateActions from './interview-template.actions';
import * as fromInterviewTemplateSagas from './sagas';

export function* interviewTemplateSagaWatcher() {
  yield takeEvery(
    fromInterviewTemplateActions.interviewTemplateDeleteConformModalShowAction,
    fromInterviewTemplateSagas.interviewTemplateDeleteModalShowWorker,
  );
}
