import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { exModalHideAction, exModalPropsAction, exModalShowAction, updateWizardPage } from './modals.actions';
import { ExModal } from './modals.interfaces';

export const modalsAdapter = createEntityAdapter<ExModal>({});

export const modalsSlice = createSlice({
  name: 'Modals',
  initialState: modalsAdapter.getInitialState(),
  reducers: {
    openModal(state, { payload }) {
      const modal: ExModal = {
        ...payload,
        isOpen: true,
      };
      modalsAdapter.addOne(state, modal);
    },
    closeModal(state, { payload }) {
      modalsAdapter.updateOne(state, {
        id: payload.id,
        changes: { ...payload, isOpen: false },
      });
    },
    updateModalProps(state, { payload }) {
      modalsAdapter.updateOne(state, { id: payload.id, changes: { ...payload } });
    },
    removeModal(state, { payload }) {
      modalsAdapter.removeOne(state, payload.id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(exModalShowAction, (state, action) => {
      modalsSlice.caseReducers.openModal(state, action);
    });
    builder.addCase(exModalPropsAction, (state, action) => {
      modalsSlice.caseReducers.updateModalProps(state, action);
    });
    builder.addCase(updateWizardPage, (state, { payload, type }) => {
      const candidate = state.entities[payload.id];
      if (!candidate) {
        return;
      }
      modalsSlice.caseReducers.updateModalProps(state, {
        type,
        payload: {
          ...candidate,
          modalConfig: {
            ...candidate.modalConfig,
            page: payload.page,
            ...payload.modalConfig,
          },
          modalProps: {
            ...candidate.modalProps,
            ...payload.modalProps,
          },
        },
      });
    });
    builder.addCase(exModalHideAction, (state, action) => {
      modalsSlice.caseReducers.closeModal(state, action);
    });
  },
});
