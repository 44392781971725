import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { DropdownToggleProps } from 'react-bootstrap/esm/DropdownToggle';
import { createPortal } from 'react-dom';
import { ENTERED, TransitionStatus } from 'react-transition-group/Transition';
import styled, { createGlobalStyle, DefaultTheme } from 'styled-components/macro';

import { RBAC } from 'containers/Auth/components/RBAC';

import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';
import { getThemeColor } from 'utils/styled/getThemeColor';

import { DropdownProps } from './ActionsDropdownProps';

const CustomToggleStyled = styled.div`
  margin: 0 auto;
`;

const CustomToggle = React.forwardRef<HTMLDivElement, DropdownToggleProps>(
  ({ children, onClick, disabled, className }, ref) => {
    return (
      <CustomToggleStyled
        ref={ref}
        onMouseDown={(e) => {
          e.preventDefault();
          if (!disabled) {
            onClick && onClick(e);
          }
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className={className}
      >
        {children}
      </CustomToggleStyled>
    );
  },
);

interface DropdownLabelProps {
  variant?: keyof DefaultTheme['colors'];
}

const DropdownItem = styled(Dropdown.Item)`
  line-height: 156.32%;
  padding: 8px 16px;

  &:hover {
    background-color: ${(props) => props.theme.colors.bodyBackgroundColor};
    color: inherit;
  }

  &:first-child {
    border-top-left-radius: ${getThemeBorderProps('borderRadius')};
    border-top-right-radius: ${getThemeBorderProps('borderRadius')};
  }

  &:last-child {
    border-bottom-left-radius: ${getThemeBorderProps('borderRadius')};
    border-bottom-right-radius: ${getThemeBorderProps('borderRadius')};
  }
`;

const DropdownLabel = styled.div<DropdownLabelProps>`
  color: ${({ variant }) => (variant && variant !== 'light' ? getThemeColor(variant) : getThemeColor('dark'))};
`;

const ExpedoDropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  margin: 0;
  z-index: 1040;
`;

const ActionsDropdownMenu = styled(ExpedoDropdownMenu)`
  margin-top: 6px;
`;

const ActionsDropdownPure: React.FC<DropdownProps> = ({
  className,
  menuItems,
  buttonId,
  children,
  onSelect,
  disabled,
  dropDirection,
  alignRight = true,
}) => {
  return (
    <Dropdown alignRight={alignRight} onSelect={onSelect} className={className} drop={dropDirection}>
      <Dropdown.Toggle as={CustomToggle} id={buttonId} disabled={disabled} className="expedo-dropdown-toggle-wrapper">
        {children}
      </Dropdown.Toggle>
      {createPortal(
        <ActionsDropdownMenu rootCloseEvent="mousedown">
          {menuItems.map((item, index) => {
            const { action, getStatus, hide, ...restItemProps } = item;
            return (
              <React.Fragment key={index}>
                <RBAC feature={item.feature}>
                  <DropdownItem
                    className="expedo-dropdown-item"
                    eventKey={index.toString()}
                    title={item.title}
                    {...restItemProps}
                  >
                    <DropdownLabel variant={item.variant}>{item.label}</DropdownLabel>
                  </DropdownItem>
                </RBAC>
              </React.Fragment>
            );
          })}
        </ActionsDropdownMenu>,
        document.body,
        buttonId,
      )}
    </Dropdown>
  );
};

export const ActionsDropdown = styled(ActionsDropdownPure)`
  padding: 0 10px;
  margin-right: -10px;

  .expedo-dropdown-toggle-wrapper {
    display: inline-block;

    &::after,
    &::before {
      display: none;
    }
  }

  .dropdown-divider {
    margin: 0;
  }
`;

export const GlobalStyleExpedoDropdownMenu = createGlobalStyle<{
  state: TransitionStatus;
}>`
  ${ExpedoDropdownMenu} {
    transition: opacity 0.3s ease-out;
    opacity: ${({ state }) => ([ENTERED].includes(state) ? 1 : 0)};
  }
`;
