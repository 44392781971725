import { createSelector } from '@reduxjs/toolkit';

import { ExHeaderProps } from 'components/ui/ExTable/ExTable.props';
import { truthGuard } from 'utils/funcs';
import { initListState } from 'utils/reducer/reducer-helper';
import { createDeepEqualSelector, createListSelectors } from 'utils/reducer/selector-helper';

import { EntityKeys, SortDirections, UIKeys } from 'store/constants';
import {
  Applicant,
  ApplicantFilters,
  ApplicantFiltersNames,
  applicantHeadersKeys,
  applicantHeadersNames,
  ApplicantTableRow,
  SortBy,
} from 'store/entities/applicants/models';
import { applicantSelectors } from 'store/entities/applicants/selectors';
import { RootState } from 'store/rootReducer';
import { uiDomain } from 'store/rootSelectors';
import { applicantFiltersSelectors } from 'store/ui/applicant-filters/applicantFilters.reducer';

export const domain = createSelector(uiDomain, (state) => state[UIKeys.applicantLists]);

export const { getItems: getApplicants, getRecentItems: getRecentApplicants } = createListSelectors<
  Applicant,
  SortBy,
  ApplicantFilters
>(EntityKeys.applicants, (state: RootState) => state.ui.applicantLists);

export const getListById = createSelector(
  domain,
  (_: any, props: { id: string }) => props.id,
  (lists, listId) => lists?.[listId] || initListState,
);

export const getFilters = createDeepEqualSelector(getListById, (list) => list?.filters);

export const getFilter = createDeepEqualSelector(getListById, (list) => list?.filter);

export const getSortMode = createSelector(getListById, (list) => list?.sortMode);

export const getListParams = createSelector(getListById, (list) => ({
  viewMode: list.viewMode,
  sortMode: list.sortMode,
  pageNo: list.pageNo,
  pageSize: list.pageSize,
  filters: list.filters,
}));

export const getParamsForPlacementList = createSelector(getListById, (list) => {
  return {
    pageNo: list.pageNo,
    pageSize: list.pageSize,
    ...(list.sortMode?.orderBy && {
      orderBy: `${list.sortMode.orderBy}${list.sortMode.orderDir === SortDirections.desc ? ' descending' : ''}`,
    }),
    ...(list.filters?.status !== undefined && { placementStage: list.filters.status }),
    ...(list.filters?.jobName !== undefined && { jobName: list.filters.jobName }),
    ...(list.filters?.name !== undefined && { name: list.filters.name }),
    ...(list.filters?.searchTerm !== undefined && { searchTerm: list.filters.searchTerm }),
  };
});

export const getListParamsForApi = createSelector(getListById, (list) => {
  return {
    pageNo: list.pageNo,
    pageSize: list.pageSize,
    ...(list.filters.availabilities?.length && {
      availabilities: list.filters.availabilities.filter(
        (availability: any) => typeof availability.availabilityType !== 'undefined',
      ),
    }),
    ...(list.filters.sources?.length && { sources: list.filters.sources }),
    ...(list.filters.countries?.length && { countries: list.filters.countries }),
    ...(list.filters.locations?.length && { locations: list.filters.locations }),
    ...(list.filters.keywords?.length && { keywords: list.filters.keywords }),
    ...(list.filters?.pipelineStages !== undefined && list.filters?.pipelineStages.length
      ? { pipelineStages: [list.filters.pipelineStages] }
      : {
          pipelineStages: [],
        }),
    ...(list.sortMode?.orderBy && {
      orderBy: `${list.sortMode.orderBy}${list.sortMode.orderDir === SortDirections.desc ? ' descending' : ''}`,
    }),
    ...(list.filters?.employer && {
      employer: list.filters?.employer,
    }),
    ...(list.filters?.education && {
      education: list.filters?.education,
    }),
  };
});

export const isApplicantSelected = createSelector(
  getListById,
  (_: RootState, own: any) => own?.item?.id,
  (list, id) => {
    return list.selectedItems.includes(id);
  },
);

export const getSelectedItems = createSelector(getListById, (list) => list?.selectedItems ?? []);

export const selectSelectedLength = createSelector(getListById, (list) => (list?.selectedItems ?? []).length);

export const selectIsSelected = createSelector(selectSelectedLength, (length) => length !== 0);

export const getViewMode = createSelector(getListById, (list) => list?.viewMode);

export const selectIsAllSelected = createSelector(
  getApplicants,
  selectSelectedLength,
  (selectedApplicants, selectedLength) => selectedLength !== 0 && selectedApplicants.length === selectedLength,
);

export const isApplicantFiltersInJobChanged = createSelector(getFilters, (filters) => {
  let result = false;
  if (!filters) {
    return result;
  }

  if (result) {
    return result;
  }
  const filtersNames = [
    ApplicantFiltersNames.sources,
    ApplicantFiltersNames.keywords,
    ApplicantFiltersNames.locations,
    ApplicantFiltersNames.employer,
    ApplicantFiltersNames.education,
    ApplicantFiltersNames.countries,
    ApplicantFiltersNames.pipelineStages,
  ];

  for (const element of filtersNames) {
    result = (filters[element] as any)?.length > 0;
    if (result) {
      return result;
    }
  }

  result = filters.availabilities?.some((availability) => availability.availabilityType !== undefined) ?? false;

  if (result) {
    return result;
  }

  return result;
});

export const selectApplicantsForJobTable = createSelector(
  (state: RootState) => state,
  getApplicants,
  getRecentApplicants,
  (state, applicants, recentAppicants) => {
    const result = applicants.length ? applicants : recentAppicants;
    return result.map((item) => applicantSelectors.selectApplicantForJobTable(state, item.applicantId));
  },
);

type ApplicantTableHeadersSortByMapProps = typeof applicantHeadersKeys[number];

const applicantTableHeadersSortByMap: Record<ApplicantTableHeadersSortByMapProps, string> = {
  salaryExpectation: 'SalaryExpectationAmount',
  source: 'Source',
  location: 'Location',
  lastRole: 'LastRole',
  seniorityLevel: 'SeniorityLevel',
  rating: 'Rating',
  availability: 'JobApplicantAvailability',
};

export const selectDynamicHeadersForApplicantsForJobTable = createSelector(
  applicantFiltersSelectors.selectAll,
  (filters): Array<ExHeaderProps<ApplicantTableRow, SortBy>> => {
    const headers = applicantHeadersKeys.map((filterName): ExHeaderProps<ApplicantTableRow, SortBy> | false => {
      const filterValue = filters[filterName];

      if (filterValue === false) {
        return false;
      }

      return {
        label: applicantHeadersNames[filterName],
        field: filterName,
        isSortable: true,
        sortBy: applicantTableHeadersSortByMap[filterName] as SortBy,
        css: { width: '150px' },
      };
    });

    return headers.filter(truthGuard);
  },
);
