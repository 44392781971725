/**
 * Invoke the standard method for saving a file in the browser
 *
 * @export
 * @param {Blob} data response Blob data
 * @param {string} [fileName] optional file name
 */
export function saveFile(data: Blob, fileName?: string): void {
  const url = URL.createObjectURL(data);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName ?? 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  // For release URL object and one time download we add listener.
  a.addEventListener('click', clickHandler, false);
  // Programmatically click
  a.click();
}
