import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';

import { dateFormat } from 'config';

import { CandidateDetailsFormBody, candidateFormEffects } from 'containers/CandidateForm';
import { validationSchemaEditCandidateDetails } from 'containers/CandidateForm/CandidateFormValidators';
import { Loader } from 'containers/Loader';
import { modalsSelectors } from 'containers/Modals/store';

import { Form } from 'components';
import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { DialogProps } from 'components/Dialog';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { ExDialog } from 'components/ui/ExDialog';
import { loaderThunkActionWrapper } from 'modules/LoaderManager/redux';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { findSocialNetwork, socialNetworkKeys } from 'utils/socialNetworks';

import { candidatesSelectors } from 'store/entities/candidates';
import { candidatesApi } from 'store/entities/candidates/candidates.api';
import { Candidate } from 'store/entities/candidates/models';
import { useSelector } from 'store/rootSelectors';

const EditCandidateDetailsModalStyled = styled(ExDialog)`
  width: 100%;
  max-width: 980px;
  max-height: 80vh;
`;

const EditCandidateDetailsModalForm = styled(Form)`
  width: 100%;
`;

const EditCandidateDetailsModalFooter = styled.div`
  display: flex;
  justify-content: center;
`;

const ExButtonStyled = styled(ExButton)`
  &:last-child {
    margin-left: 10px;
  }
`;

const socialNetworksDefaultValues = (socialNetworks: Candidate['socialNetworks']) => {
  return socialNetworkKeys.map((socialNetwork) => ({
    type: socialNetwork,
    url: socialNetworks?.find(findSocialNetwork(socialNetwork))?.url,
  }));
};

export const EditCandidateDetails: React.FC<any> = ({ className, onClose }) => {
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();
  const candidateId = useSelector(modalsSelectors.modalCandidateId);
  const memo = useMemo(() => candidatesSelectors.createGetById(), []);
  const candidate = useSelector((state) => memo(state, candidateId!));
  const content: DialogProps['content'] = {
    title: 'Edit Candidate Details',
    withTitle: true,
  };
  const submitHandler = async (data: Partial<Candidate>) => {
    if (!candidateId) {
      return;
    }

    await dispatch(
      candidateFormEffects.updateCandidate({ data: { ...candidate, ...data, candidateId }, preloader: true }),
    );
    dispatch(
      candidatesApi.util.invalidateTags([
        { type: 'Candidates', id: candidateId },
        { type: 'Candidates', id: 'LIST' },
      ]),
    );
    onClose();
  };
  useEffect(() => {
    if (!candidate || !candidateId) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [candidate, candidateId]);
  if (!show) {
    return <Loader />;
  }

  const actualDate = candidate?.candidateAvailability?.actualDate;
  const specificDate = actualDate
    ? format(parseISO(actualDate), dateFormat.formatDateForDateInput, {
        weekStartsOn: 1,
      })
    : undefined;
  const location = candidate?.candidateLocation!;
  const defaultValues = {
    ...candidate!,
    ...(candidate?.candidateAvailability && {
      candidateAvailability: {
        ...candidate?.candidateAvailability,
        actualDate: specificDate,
      },
    }),
    ...(location && { location }),
    ...(candidate?.socialNetworks &&
      (candidate?.socialNetworks).length && {
        socialNetworks: socialNetworksDefaultValues(candidate?.socialNetworks),
      }),
  };

  return (
    <EditCandidateDetailsModalStyled className={className} onClose={onClose} content={content}>
      <EditCandidateDetailsModalForm
        onSubmit={submitHandler}
        validationSchema={validationSchemaEditCandidateDetails}
        defaultValues={defaultValues}
        formOptions={{
          shouldUnregister: true,
        }}
      >
        <FormGroup name="candidateDetailFormBody" />
        <EditCandidateDetailsModalFooter>
          <ExButtonStyled variant="light" onClick={() => onClose(false)} type="button">
            Cancel
          </ExButtonStyled>
          <ExLoaderHandlerWrapper action={loaderThunkActionWrapper(candidateFormEffects.updateCandidate)}>
            <ExButtonStyled variant="primary" type="submit">
              Update
            </ExButtonStyled>
          </ExLoaderHandlerWrapper>
        </EditCandidateDetailsModalFooter>
      </EditCandidateDetailsModalForm>
    </EditCandidateDetailsModalStyled>
  );
};

const FormGroup: React.FC<{ name?: string }> = () => <CandidateDetailsFormBody />;
