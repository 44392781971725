import React, { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';

import { AppRouterParams } from 'model/router';

import { JobList, JobListHeader, JobPositionRow } from 'containers/JobLists';
import { jobListActions, jobListSelectors } from 'containers/JobLists/store';
import {
  NewJobAdContextForm,
  NewJobAdsContext,
  useNewJobAdsContextFormUpdater,
} from 'containers/Modals/ModalsContent/JobAds/NewJobAds/NewJobAds.context';

import { Remaining } from 'components';
import { ColumnMap } from 'components/ListViewNew/ListViewProps';
import { StepComponent, StepDescription } from 'components/StepForm';
import { innerTablesStyled } from 'components/TableList/TableListComponents';
import { ExCard } from 'components/ui/ExCard';
import { useFormShowAlertError } from 'utils/hooks/formsHooks/useFormShowAlertError';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getTranslate } from 'utils/i18utils';

import { JobAd } from 'store/entities/job-ads/models';
import { Columns, Job, JobBelongsTo, jobsSelectors, SortByForJobList } from 'store/entities/jobs';
import { loadJobPage } from 'store/entities/jobs/job.actions';

export interface NewJobAdSelectJobStepProps extends StepComponent {
  className?: string;
  formData: Partial<JobAd>;
}

const columns: ColumnMap<Job, SortByForJobList, Columns> = {
  [Columns.position]: {
    label: 'Job Name',
    order: SortByForJobList.JobName,
    component: ({ item }) => <JobPositionRow jobId={item.jobId} preventLink />,
    style: { width: '50%' },
  },
  [Columns.deadline]: {
    label: 'End Date',
    order: SortByForJobList.Deadline,
    component: ({ item }) => <Remaining deadline={item.deadline} />,
    style: { width: '46%' },
  },
};

const JobListStyled = innerTablesStyled(JobList);

const NewJobAdSelectJobStepPure: React.FC<NewJobAdSelectJobStepProps> = ({ className, formData }) => {
  useNewJobAdsContextFormUpdater();
  const { changeFormValue } = useContext(NewJobAdsContext);
  const { jobId } = useParams<AppRouterParams>();
  const { watch, register, formState, setValue, getValues, reset } = useFormContext<JobAd>();
  useFormShowAlertError();
  const dispatch = useAppDispatch();
  const selectedJobId = watch('jobId');
  const selectedItems = useAppSelector(jobListSelectors.getSelected, { id: JobBelongsTo.newJobAd });
  const selectedJob = useAppSelector(jobsSelectors.getById, selectedJobId);
  const formDataJobId = formData?.jobId;

  useEffect(() => {
    if (selectedJobId || (jobId && !formData?.jobId)) {
      const selectedItemId = selectedJobId || jobId!;
      dispatch(
        jobListActions.setItemSelect({
          selectedItemId,
          id: JobBelongsTo.newJobAd,
        }),
      );
    }
    register('jobId', { required: true });
    register('employmentType', { required: true });
    register('country', { required: true });
    register('jobAdLocation', { required: true });
    register('description', { required: true });
    register('requirements', { required: true });
    register('responsibilities', { required: true });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedJob) {
      const { requirements, responsibilities, employmentType, jobLocation, countryHiringFrom, description, jobName } =
        selectedJob;

      const fields: Array<{ name: string; value: any }> = [
        { name: 'employmentType', value: employmentType },
        { name: 'requirements', value: requirements },
        { name: 'responsibilities', value: responsibilities },
        { name: 'description', value: description },
        { name: 'jobAdName', value: jobName },
      ];

      if (formDataJobId !== selectedJobId) {
        fields.push({ name: 'jobAdLocation', value: jobLocation }, { name: 'country', value: countryHiringFrom });
      }

      fields.forEach(({ name, value }) => {
        if (!isEqual(value, getValues(name))) {
          setValue(name, value, { shouldValidate: formState.isSubmitted });
          changeFormValue(name as keyof NewJobAdContextForm, value);
        }
      });
    }
  }, [
    selectedJob,
    setValue,
    register,
    formState.isSubmitted,
    changeFormValue,
    getValues,
    formDataJobId,
    selectedJobId,
  ]);

  const jobIdSelected = selectedItems[0];

  useEffect(() => {
    setValue('jobId', jobIdSelected, {
      shouldValidate: false,
    });

    if (jobIdSelected) {
      dispatch(loadJobPage({ jobId: jobIdSelected }));
    }
  }, [dispatch, jobIdSelected, setValue]);

  useEffect(() => {
    if (jobIdSelected && jobIdSelected !== selectedJobId) {
      reset({ ...getValues(), jobBoards: {} });
    }
  }, [jobIdSelected, selectedJobId, reset, getValues]);

  return (
    <div className={className}>
      <StepDescription>
        <StepDescription.Title>Step 1: Select a Job</StepDescription.Title>
        <StepDescription.Description>{getTranslate('new_job_ad.step_1.description')}</StepDescription.Description>
      </StepDescription>
      <ExCard title="Available Job">
        <JobListHeader listId={JobBelongsTo.newJobAd} selectable />
        <JobListStyled id={JobBelongsTo.newJobAd} columns={columns} selectable />
      </ExCard>
    </div>
  );
};

export const NewJobAdSelectJobStep = styled(NewJobAdSelectJobStepPure)``;
