import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';

import { ApplicantForm } from 'containers/ApplicantForm/ApplicantFormState/applicant-form.models';

import { FormSelect } from 'components';
import { ExSalaryInputWithCountry } from 'components/ui/ExSalaryInputWithCountry/ExSalaryInputWithCountry';
import { useFrequencyOptions } from 'utils/hooks/formsHooks/useFrequencyOptions';

type ApplicantModalSalaryInfoEditProps = {
  className?: string;
};

const ApplicantModalSalaryInfoEditStyled = styled.div``;

const useApplicantModalSalaryInfoEditState = ({ className }: ApplicantModalSalaryInfoEditProps) => {
  const { errors, formState } = useFormContext<ApplicantForm>();
  const validated = formState.isSubmitted;

  const frequencyOptions = useFrequencyOptions();

  return {
    className,
    errors,
    validated,
    frequencyOptions,
  } as const;
};

export const ApplicantModalSalaryInfoEdit: React.FC<ApplicantModalSalaryInfoEditProps> = (props) => {
  const { className, errors, validated, frequencyOptions } = useApplicantModalSalaryInfoEditState(props);

  return (
    <ApplicantModalSalaryInfoEditStyled className={className}>
      <ExSalaryInputWithCountry label="Salary Expectation" />
      <FormSelect
        label="Salary Frequency"
        placeholder=""
        name="salaryExpectationFrequency"
        errors={errors?.salaryExpectationFrequency}
        validated={validated}
        defaultOptions
        options={frequencyOptions}
        isClearable
      />
    </ApplicantModalSalaryInfoEditStyled>
  );
};
