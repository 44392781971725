import React from 'react';

type Layouts = 'app-layout' | 'empty-layout' | 'fragment-layout';
type Action = { type: Layouts };
type Dispatch = (action: Action) => void;
type State = { layout: Layouts };
type LayoutProviderProps = { children: React.ReactNode };

const LayoutStateContext = React.createContext<State | undefined>(undefined);
const LayoutDispatchContext = React.createContext<Dispatch | undefined>(undefined);

const layout: Record<Layouts, Layouts> = {
  'app-layout': 'app-layout',
  'empty-layout': 'empty-layout',
  'fragment-layout': 'fragment-layout',
} as const;

function layoutReducer(state: State, action: Action) {
  return { ...state, layout: layout[action.type] };
}

const LayoutProvider = ({ children }: LayoutProviderProps) => {
  const [state, dispatch] = React.useReducer(layoutReducer, { layout: 'app-layout' });
  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>{children}</LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
};

function useLayoutState() {
  const context = React.useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error('useLayoutState must be used within a LayoutProvider');
  }
  return context;
}

function useLayoutDispatch() {
  const context = React.useContext(LayoutDispatchContext);
  if (context === undefined) {
    throw new Error('useLayoutDispatch must be used within a LayoutProvider');
  }
  return context;
}

export { LayoutProvider, useLayoutState, useLayoutDispatch };
