import React, { useCallback, useMemo } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import styled from 'styled-components';

import { PipelineValidationErrors } from 'model/api-errors.constants';

import { CompanyInfoItem } from 'pages/Company/components/CompanyInfoItem';

import { ReactComponent as IconEdit } from 'assets/img/edit.svg';
import { ReactComponent as IconClose } from 'assets/img/redesign/icons/close.svg';

import { ExIconButton, ExIconButtonWithRef } from 'components/ui/ExIconButton';
import { ExLoaderWrapper } from 'components/ui/ExLoaderWrapper';
import { useLoaderSubscription } from 'modules/LoaderManager/react';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getTranslate } from 'utils/i18utils';

import {
  hiringPipelinesStagesSelectors,
  hiringPipelineStageActions,
  isRemovableStage,
  isRequiredCustomStage,
} from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.reducer';
import type { HiringPipelineStage } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.types';

const IconEditSizeStyles = {
  width: '13px',
  height: '13px',
};

const IconEditStyled = styled(IconEdit)(IconEditSizeStyles);

const IconCloseInnerStyled = styled(IconClose)`
  width: 15px;
  height: 15px;
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const ButtonRemoveStage = styled(ExIconButtonWithRef)`
  margin-left: 10px;
`;

const ButtonRemoveStageWithPopover = ({ disabled, onClick, popoverTitle, popoverContent }) => {
  return (
    <OverlayTrigger
      placement="left"
      overlay={
        <Popover className="expedo-popover" id="popover-remove-stage" placement="left">
          <Popover.Title>{popoverTitle}</Popover.Title>
          <Popover.Content>{popoverContent}</Popover.Content>
        </Popover>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <ButtonRemoveStage
          ref={ref}
          {...triggerHandler}
          icon={<IconCloseInnerStyled />}
          disabled={disabled}
          onClick={onClick}
        />
      )}
    </OverlayTrigger>
  );
};

type HiringPipelineEditModalStageProps = {
  className?: string;
  hiringPipelineStage: HiringPipelineStage;
};

const HiringPipelineEditModalStageStyled = styled(CompanyInfoItem)``;

const useHiringPipelineEditModalStageState = ({
  className,
  hiringPipelineStage,
}: HiringPipelineEditModalStageProps) => {
  const onlySecondaryStageErrorMessage = getTranslate(
    PipelineValidationErrors.PipelineMustHaveAtLeastOneOfSecondaryStage,
    { ns: 'validationErrorCodes' },
  );
  const stageIsInUseErrorMessage = getTranslate(PipelineValidationErrors.PipelineStageHasDependentApplicants, {
    ns: 'validationErrorCodes',
  });

  const { pipelineId, name, pipelineStageId, inUse } = hiringPipelineStage;

  const dispatch = useAppDispatch();

  const editHandler = () =>
    dispatch(
      hiringPipelineStageActions.showEditModal({
        pipelineId,
        pipelineStageId,
      }),
    );

  const removeHandler = useCallback(
    () =>
      dispatch(
        hiringPipelineStageActions.showRemoveModal({
          pipelineId,
          pipelineStageId,
        }),
      ),
    [dispatch, pipelineId, pipelineStageId],
  );

  const { isLoading } = useLoaderSubscription(hiringPipelineStageActions.actionFetch(pipelineStageId));

  const isRemovable = isRemovableStage(hiringPipelineStage);
  const isRequiredCustom = isRequiredCustomStage(hiringPipelineStage);

  const hasEnoughRequiredCustomStages = useAppSelector(
    hiringPipelinesStagesSelectors.checkHasEnoughRequiredCustomStages,
    pipelineId,
  );

  const isOnlySecondaryStage = isRequiredCustom && !hasEnoughRequiredCustomStages;

  const isRemoveButtonVisible = isRemovable;

  const isRemoveButtonDisabled = isOnlySecondaryStage || inUse;

  const { popoverTitle, popoverContent } = useMemo(() => {
    if (isOnlySecondaryStage) {
      return {
        popoverTitle: 'The only secondary stage',
        popoverContent: onlySecondaryStageErrorMessage,
      };
    }

    if (inUse) {
      return {
        popoverTitle: 'This stage is in use',
        popoverContent: stageIsInUseErrorMessage,
      };
    }

    return {
      popoverTitle: '',
      popoverContent: '',
    };
  }, [isOnlySecondaryStage, onlySecondaryStageErrorMessage, inUse, stageIsInUseErrorMessage]);

  return {
    className,
    name,
    removeHandler,
    editHandler,
    isLoading,
    isRemoveButtonVisible,
    isRemoveButtonDisabled,
    popoverTitle,
    popoverContent,
  };
};

export const HiringPipelineEditModalStage: React.FC<HiringPipelineEditModalStageProps> = (props) => {
  const {
    className,
    name,
    editHandler,
    removeHandler,
    isLoading,
    isRemoveButtonVisible,
    isRemoveButtonDisabled,
    popoverTitle,
    popoverContent,
  } = useHiringPipelineEditModalStageState(props);

  return (
    <HiringPipelineEditModalStageStyled className={className}>
      {name}
      <ActionButtons>
        <ExLoaderWrapper isLoading={isLoading} style={{ ...IconEditSizeStyles, margin: 0 }}>
          <ExIconButton icon={<IconEditStyled />} onClick={editHandler} />
        </ExLoaderWrapper>
        {isRemoveButtonVisible &&
          (isRemoveButtonDisabled ? (
            <ButtonRemoveStageWithPopover
              disabled={isRemoveButtonDisabled}
              onClick={removeHandler}
              popoverTitle={popoverTitle}
              popoverContent={popoverContent}
            />
          ) : (
            <ButtonRemoveStage onClick={removeHandler}>
              <IconCloseInnerStyled />
            </ButtonRemoveStage>
          ))}
      </ActionButtons>
    </HiringPipelineEditModalStageStyled>
  );
};
