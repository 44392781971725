import styled from 'styled-components';

import { EllipsisPros } from 'components/Ellipsis/EllipsisProps';

export const Ellipsis = styled.span<EllipsisPros>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => props.clamp ?? 1};
  overflow: hidden;
  word-break: ${(props) => (props.clamp ? 'break-word' : 'break-all')};
`;
