import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';

import { companyInterviewTemplateListUiSlice } from './company-interview-templates.reducer';

const domain = (state: RootState) => state.ui.companyUi[companyInterviewTemplateListUiSlice.name];

const filters = createSelector(domain, (state) => state.filters);

export const companyInterviewTemplateListUiSelectors = {
  filters,
};
