import { api } from 'api-client';
import { TalentPoolEndpoints } from 'api-endpoints/talent-pool/endpoints';
import {
  bulkCreateTalentPoolCandidatesFromResumesFuncParams,
  bulkCreateTalentPoolCandidatesFromResumesFuncResponse,
  RemoveTalentPoolParams,
  TalentPoolItemResponse,
  TalentPoolListItemResponse,
} from 'api-endpoints/talent-pool/models';
import { createApiMethod, getApiMappedFunction } from 'api-endpoints/utils';

import { ApiResponseWithPagination, Location, SearchAutocompleteOptions } from 'model';
import { AvailabilityType } from 'model/api-enums.constants';
import { WithRequired } from 'model/utils';

import { CandidateListParams } from 'containers/CandidateLists/store/models';
import { TalentPoolListParams } from 'containers/TalentPoolLists/store/models';

import { requestMapperUtil, responseMapperUtil } from 'utils/api';
import { curry } from 'utils/funcs';
import { getGlobalGetState } from 'utils/globalStore';
import { createUrlWithParams } from 'utils/url';

import { authSelectors } from 'store/auth/auth.selectors';
import { SortDirections } from 'store/constants';
import { Candidate } from 'store/entities/candidates/models';
import { talentPoolParamsMapper } from 'store/entities/talent-pools/api/talent-pool.request.mappers';
import { TalentPoolListRequestParams } from 'store/entities/talent-pools/api/talent-pool.requests';
import { TalentPoolListResponse } from 'store/entities/talent-pools/api/talent-pool.responses';
import { TalentPool } from 'store/entities/talent-pools/models';

export * from 'api-endpoints/talent-pool/models';

const jobAdBasicUrl = '/api/company/{companyId}/talent-pool';

const createWithBasicUrl = createUrlWithParams(jobAdBasicUrl);

export const getTalentPools = ({
  pageNo,
  pageSize,
  filters,
  sortMode,
}: WithRequired<TalentPoolListParams, 'filters'>) => {
  return api().request<ApiResponseWithPagination<TalentPoolListItemResponse>>({
    url: createWithBasicUrl([]),
    method: 'GET',
    params: {
      pageNo,
      pageSize,
      ...(filters.name && { searchTerm: filters.name }),
      ...(sortMode?.orderBy && {
        orderBy: `${sortMode.orderBy}${sortMode.orderDir === SortDirections.desc ? ' descending' : ''}`,
      }),
    },
  });
};

const getTalentPoolListApiMethod = createApiMethod<TalentPoolListRequestParams, TalentPoolListResponse>({
  url: TalentPoolEndpoints.SearchTalentPoolsFunc,
});

export const getTalentPoolList = getApiMappedFunction<
  TalentPoolListRequestParams,
  ReturnType<typeof getTalentPoolListApiMethod>,
  ReturnType<typeof getTalentPoolListApiMethod>
>(getTalentPoolListApiMethod, requestMapperUtil([talentPoolParamsMapper]), responseMapperUtil());

export const getTalentPoolCandidates = curry(
  (talentPoolId: TalentPool['id'], { pageNo, pageSize, filters }: WithRequired<CandidateListParams, 'filters'>) => {
    const companyId = authSelectors.selectCurrentCompanyId(getGlobalGetState());

    return api().request<ApiResponseWithPagination<Candidate>>({
      method: 'POST',
      url: createWithBasicUrl([talentPoolId, 'candidates']),
      data: {
        talentPoolId,
        companyId,
        pageNo,
        pageSize,
        ...(filters.keywords?.length && { keywords: filters.keywords.map(({ value }) => value) }),
        ...(filters.locations?.length && {
          locations: filters.locations.map((location: Location) => ({
            countryCode: location.countryCode,
            locationId: location.id,
          })),
        }),
        ...(filters.availabilities?.length && {
          availabilities: filters.availabilities.map((availability: AvailabilityType) => ({
            availabilityType: availability,
            ...(availability === AvailabilityType.ActualDate && {
              availabilityActualDate: filters.availabilityDate,
            }),
          })),
        }),
        ...(filters.name && {
          searchTerm: filters.name,
        }),
        doNotHire: filters.doNotHire,
        orderBy: filters.orderBy,
      },
    });
  },
);
export const getTalentPoolCandidatesNewApplicant = (params: WithRequired<CandidateListParams, 'filters'>) => {
  const talentPoolId = params.filters.talentPool?.[0]?.value;
  if (!talentPoolId) {
    return { message: 'First select Talent pool from the Filters' };
  }
  return getTalentPoolCandidates(talentPoolId, params);
};

export const getTalentPoolById = ({ id }: { id: TalentPool['id'] }) =>
  api().request<TalentPoolItemResponse>({
    url: createWithBasicUrl([id]),
  });

export const postTalentPool = ({ data }: { data: Partial<TalentPool> }) =>
  api().request<TalentPoolItemResponse>({
    url: createWithBasicUrl([]),
    method: 'POST',
    data,
  });

export const putTalentPool = ({ data }: { data: Partial<TalentPool> }) =>
  api().request<TalentPoolItemResponse>({
    url: createWithBasicUrl([data.id!]),
    method: 'PUT',
    data,
  });

export const removeTalentPool = createApiMethod<RemoveTalentPoolParams, void>({
  url: TalentPoolEndpoints.DeleteTalentPoolFunc,
  method: 'DELETE',
});

export const talentPoolCandidateAutocomplete = curry((talentPoolId: string, searchTerm: string) =>
  api().request<SearchAutocompleteOptions>({
    method: 'GET',
    url: createWithBasicUrl([talentPoolId, 'autocomplete']),
    params: { searchTerm },
  }),
);

export const talentPoolAutocompleteFunc = (searchTerm: string) =>
  api().request<SearchAutocompleteOptions>({
    method: 'GET',
    url: createWithBasicUrl(['autocomplete']),
    params: { searchTerm },
  });

export const getTalentPoolsForCandidateFunc = (searchTerm: string) =>
  api().request<SearchAutocompleteOptions>({
    method: 'GET',
    url: createWithBasicUrl(['for-candidate']),
    params: { searchTerm: searchTerm || ' ' },
  });

// -------------------------------------------------------------------------------------------------
// BulkCreateTalentPoolCandidatesFromResumesFunc
export const bulkCreateTalentPoolCandidatesFromResumesFunc = createApiMethod<
  bulkCreateTalentPoolCandidatesFromResumesFuncParams,
  bulkCreateTalentPoolCandidatesFromResumesFuncResponse
>({
  url: TalentPoolEndpoints.BulkCreateTalentPoolCandidatesFromResumesFunc,
  method: 'POST',
});
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// BulkCreateCandidatesFromResumesFunc
export const bulkCreateCandidatesFromResumesFunc = createApiMethod<
  bulkCreateTalentPoolCandidatesFromResumesFuncParams,
  bulkCreateTalentPoolCandidatesFromResumesFuncResponse
>({
  url: TalentPoolEndpoints.BulkCreateCandidatesFromResumesFunc,
  method: 'POST',
});
// -------------------------------------------------------------------------------------------------
