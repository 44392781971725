import { createSelector } from '@reduxjs/toolkit';

import { selectByIds as selectByIdsFromUtils, selectIds } from 'utils/reducer/adapter-helper';

import { entitiesDomain } from 'store/rootSelectors';

import { jobBoardsAdapter } from './reducer';

const domain = createSelector(entitiesDomain, state => state.jobBoards);
const jobBoardsAdapterSelectors = jobBoardsAdapter.getSelectors(domain);
const selectByIds = createSelector(jobBoardsAdapterSelectors.selectEntities, selectIds, selectByIdsFromUtils);

export const jobBoardSelectors = { ...jobBoardsAdapterSelectors, selectByIds };
