import { AppFile } from 'model';

export type UploaderProps = {
  url: string;
  formData: FormData;
};

export const uploader = async ({ url, formData }: UploaderProps): Promise<AppFile> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      body: formData,
    }).then((response) => {
      if (response.ok) {
        response.json().then((res) => {
          resolve(res);
        });
        return;
      }

      response.json().then((err) => {
        reject(err);
      });
    });
  });
};

export const filesToFormData = (files: File[]) => {
  const formData = new FormData();
  files.forEach((file) => formData.append('document', file, file.name));
  return formData;
};
