import styled from 'styled-components/macro';

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderRowMb = styled(HeaderRow)`
  margin-bottom: 20px;
`;

export const HeaderColLeft = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0;
  margin-right: auto;
`;

export const HeaderColRight = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
`;
