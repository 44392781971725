import { WithRequired } from 'model/utils';

import { FormJobAdCaseReducer } from 'containers/JobAdForms/state/models';

import { JobAd } from 'store/entities/job-ads/models';

export const updateAdditionFieldsViewByJobBoardId: FormJobAdCaseReducer<
  WithRequired<JobAd, 'jobBoardId' | 'jobAdId'> & {
    postSuccess: boolean;
    additionFieldView?: JobAd['additionalFieldsView'];
  }
> = (state, { payload }) => {
  const jobBoardId = payload.jobBoardId;
  if (jobBoardId) {
    const additionFieldForJobAd = state.additionFieldsView[jobBoardId];
    state.additionFieldsView[jobBoardId] = { ...additionFieldForJobAd, ...payload };
  }
};
