import { ExInterviewTemplateQuestion } from 'model';

import { InterviewTemplateEditFormCaseReducer } from 'containers/InterviewTemplateForms/InterviewTemplateEditForm/state/InterviewTemplateEditForm.models';

import { interviewTemplateQuestionsAdapter } from 'store/entities/interview-template-questions/interview-template-questions.api';

export const interviewTemplateEditFormRemoveQuestionReducer: InterviewTemplateEditFormCaseReducer<
  ExInterviewTemplateQuestion['interviewTemplateQuestionId']
> = (draft, { payload }) => {
  interviewTemplateQuestionsAdapter.removeOne(draft.formData.questions, payload);
};
