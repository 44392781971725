import { ButtonHTMLAttributes } from 'react';
import { DefaultTheme } from 'styled-components';

import { ExButtonExtendedProps } from 'components/ui/ExButton';

export interface ExDialogProps {
  content: ExDialogContent;
  theme?: DefaultTheme;
  className?: string;
  onClose: (result: boolean | null) => void;
  children?: React.ReactElement;
}

export interface ExDialogContentChildrenProps {
  onClose: ExDialogProps['onClose'];
  theme?: DefaultTheme;
}

export enum ExDialogStatus {
  success = 'success',
}

export interface ExDialogContent {
  title?: string;
  message?: string;
  buttonOk?: string | null;
  buttonOkProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  buttonOkVariant?: ExButtonExtendedProps['variant'];
  buttonCancel?: string | null;
  buttonCancelVariant?: ExButtonExtendedProps['variant'];
  withTitle?: boolean;
  withActions?: boolean;
  children?: React.FC<ExDialogContentChildrenProps>;
  childrenProps?: any;
  status?: ExDialogStatus;
}
