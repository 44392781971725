import styled from 'styled-components/macro';

export const QuestionFromList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export const QuestionFromListItem = styled.li`
  margin: 0;
  padding: 0;

  &:not(:first-child) {
    margin-top: 10px;
  }
`;

export const QuestionFormStyled = styled.div``;

type QuestionFormLabelProps = {
  $disabled?: boolean;
};

export const QuestionFormLabel = styled.label<QuestionFormLabelProps>`
  margin-bottom: 0;
  position: relative;
  padding-left: 20px;
  cursor: ${(props) => (props.$disabled ? 'auto' : 'pointer')};

  &::before {
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    display: block;
    width: 10px;
    height: 10px;
    background: #c4c4c4;
    border: 1px solid ${(props) => props.theme.colors.inactive};
  }
`;

export const QuestionFormInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;

  &[type='radio'] {
    & ~ ${QuestionFormLabel} {
      &::before {
        border-radius: 50%;
      }
    }
  }

  &:checked ~ ${QuestionFormLabel} {
    &::before {
      background: ${(props) => props.theme.colors.primary};
      border-color: ${(props) => props.theme.colors.primary};
    }

    &::after {
      position: absolute;
      top: 5px;
      left: 4px;
      content: '';
      display: block;
      width: 5px;
      height: 8px;
      border-radius: 1px;
      border-bottom: 2px solid ${(props) => props.theme.colors.white};
      border-right: 2px solid ${(props) => props.theme.colors.white};
      transform: rotate(45deg);
      transform-origin: center;
    }
  }
`;
