import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAuth } from 'auth';

import { authGetUserAction } from 'containers/Auth/state/auth.actions';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { Routes } from './names';

export const AuthRoute = ({ children, ...rest }: RouteProps) => {
  const dispatch = useAppDispatch();
  const {
    state: { isAuthenticated },
  } = useAuth();

  useEffect(() => {
    dispatch(authGetUserAction());
  }, [dispatch]);

  if (isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: `/${Routes.dashboard}`,
        }}
      />
    );
  }

  return <Route {...rest} render={() => children} />;
};
