/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { Controller, useFieldArray, useForm, UseFormMethods } from 'react-hook-form';
import { Option } from 'react-select/src/filters';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled from 'styled-components/macro';

import { ExInterviewTemplate } from 'model';
import { EmailCategory, PipelineEmailRecipientType, PipelineStageType } from 'model/api-enums.constants';
import { ErrorDTO } from 'model/api-errors.constants';

import { emailTemplateActions, emailTemplateSelectors } from 'pages/Company/CompanyTabs/CompanyEmailTemplateTab';

import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import { ExModalBodyStyledWide } from 'containers/Modals/ModalsContent/Company/ExModalComponents';

import { ExLoaderHandlerWrapper, ExLoaderHandlerWrapperStrict } from 'components/Common/LoaderHandlerWrapper';
import { DialogProps } from 'components/Dialog';
import { FormInput } from 'components/FormInput';
import { FormSelect } from 'components/FormSelect';
import { FormToggleButtons } from 'components/FormToggleButtons';
import { IconCloseStyled } from 'components/Icons/IconClose';
import { Spacer } from 'components/Spacer';
import { ExAccordion, ExAccordionToggle } from 'components/ui/ExAccordion';
import { ExButton } from 'components/ui/ExButton';
import { ExDialogActionButton, ExDialogActions, ExDialogFooter } from 'components/ui/ExDialog';
import { ExIconButton } from 'components/ui/ExIconButton';
import { ExColumn, ExRow } from 'components/ui/ExLayout';
import { useLoaderSubscription } from 'modules/LoaderManager/react/hooks';
import { getInterviewTemplateAsSelectOption } from 'utils/getInterviewTemplateAsSelectOption';
import {
  pipelineStageTypeNumbersWithoutCustom,
  usePipelineStageTypeOptions,
  usePipelineStageTypeOptionsCreatable,
} from 'utils/hooks/formsHooks/usePipelineStageTypeOptions';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { stringOptional, stringRequired } from 'utils/validator-helpers';

import {
  HiringPipelineActions,
  HiringPipelineStagePrepareCreate,
  PipelineActionType,
} from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.model';
import {
  hiringPipelineStageActions,
  isInterviewTemplateStage,
  isPossiblyRequiredStage,
  isSystemStage,
} from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.reducer';
import type { HiringPipelineStage } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.types';
import { hiringPipelineSelectors } from 'store/entities/hiring-pipelines/hiring-pipelines.reducer';
import {
  INTERVIEW_TEMPLATE_TAG_TYPE,
  interviewTemplateApi,
  useInterviewTemplateListQuery,
} from 'store/entities/interview-template';
import { wizardBackward } from 'store/modals/modals.actions';
import { ExModal } from 'store/modals/modals.interfaces';

const pipelineActionTypeOptions = [
  {
    label: 'Send Email',
    value: PipelineActionType.SendEmail,
  },
];

const requiredStageToggleOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

type HiringPipelineStageCreateModalProps = ExModal &
  AppModalContentProps & {
    modalProps: {
      errorData?: ErrorDTO;
      pipelineId: HiringPipelineStage['pipelineId'];
      hiringPipelineStage: HiringPipelineStage;
    };
  };

type HiringPipelineStageSchemaProps = Partial<HiringPipelineStage>;

const hiringPipelineStageSchema = yup.object({
  actions: yup.array().of(
    yup.object({
      pipelineActionId: stringOptional,
      type: yup.number().optional(),
      actionItemId: yup.mixed().when('type', (type) => {
        if (type === PipelineActionType.SendEmail) {
          return stringRequired.label('Select Email Template');
        }

        if (type === PipelineActionType.Interview) {
          return stringRequired.label('Select Interview Template');
        }

        return yup.string().transform(() => {
          return undefined;
        });
      }),
      recipientType: yup.string().when('type', (type) => {
        if (type === PipelineActionType.SendEmail) {
          return stringRequired;
        }
        return stringOptional;
      }),
    }),
  ),
  name: stringRequired.label('Stage Name'),
  stageBadgeLabel: stringRequired.trim().label('Badge Label'),
  stageType: yup
    .mixed()
    .required()
    .oneOf(pipelineStageTypeNumbersWithoutCustom, `Please select a value from the options.`)
    .label('Stage Type'),
  isRequired: yup.boolean().default(false),
});

const ModalContentRow = styled(ExRow)`
  &:first-child {
    padding-top: 10px;
  }

  &:not(:first-child) {
    padding-top: 0;
  }
`;

const ModalContentColumn = styled(ExColumn)`
  .form-group {
    margin-bottom: 0;
  }
`;

const RemoveFieldButton = styled(ExIconButton)`
  height: 38px;
`;

const mapInterviewTemplateOptionsByIds = (ids, entities) => {
  if (!ids?.length || !entities) {
    return [];
  }

  return ids.reduce((acc, id) => {
    const template: ExInterviewTemplate = entities[id];

    if (template) {
      const option = getInterviewTemplateAsSelectOption(template);

      acc.push(option);
    }

    return acc;
  }, []);
};

const useInterviewTemplateSelectorOptions = ({ skipQuery = false }) => {
  const { data, isFetching } = useInterviewTemplateListQuery(
    {
      pageNo: 0,
      pageSize: 100,
      orderBy: 'Name',
    },
    {
      skip: skipQuery,
    },
  );

  const fullInterviewTemplatesOptions = useMemo(() => {
    return mapInterviewTemplateOptionsByIds(data?.ids, data?.entities);
  }, [data?.ids, data?.entities]);

  const getFullInterviewTemplatesOptions = useCallback(() => {
    return fullInterviewTemplatesOptions;
  }, [fullInterviewTemplatesOptions]);

  const getInterviewTemplatesOptionById = useCallback(
    (templateId) => {
      return mapInterviewTemplateOptionsByIds([templateId], data?.entities);
    },
    [data?.entities],
  );

  const getAvailableInterviewTemplatesOptions = useCallback(
    (selectedIds) => {
      const availableTemplateIds = data?.ids?.filter((templateId) => !selectedIds?.includes(templateId));

      return mapInterviewTemplateOptionsByIds(availableTemplateIds, data?.entities);
    },
    [data?.ids, data?.entities],
  );

  return {
    data,
    isFetching,
    getFullInterviewTemplatesOptions,
    getInterviewTemplatesOptionById,
    getAvailableInterviewTemplatesOptions,
  };
};

function useHiringPipelineStageCreateModalState({ id, modalConfig, modalProps }: HiringPipelineStageCreateModalProps) {
  const dispatch = useAppDispatch();

  const { pipelineId, hiringPipelineStage } = modalProps || {};

  useLayoutEffect(() => {
    dispatch(interviewTemplateApi.util.invalidateTags([{ type: INTERVIEW_TEMPLATE_TAG_TYPE, id: 'LIST' }]));
  }, [dispatch]);

  const parsedActions = useMemo(
    () =>
      hiringPipelineStage?.actions?.map((action: HiringPipelineActions) => {
        const parsedRecipientType =
          action?.recipientType !== undefined && Object.keys(PipelineEmailRecipientType)[action?.recipientType];
        return {
          ...action,
          recipientType: parsedRecipientType,
        };
      }),
    [hiringPipelineStage?.actions],
  );

  const parsedDefaultValues = useMemo(
    () => ({
      ...hiringPipelineStage,
      actions: parsedActions,
    }),
    [hiringPipelineStage, parsedActions],
  );

  const { handleSubmit, errors, formState, register, getValues, watch, control, reset } =
    useForm<HiringPipelineStageSchemaProps>({
      defaultValues: parsedDefaultValues,
      resolver: yupResolver(hiringPipelineStageSchema),
      shouldFocusError: true,
    });

  const {
    fields: actionsField,
    prepend,
    append,
    remove,
  } = useFieldArray<HiringPipelineActions>({
    control,
    name: 'actions',
  });

  const hasSendEmailAction = actionsField.some((field) => field.type === PipelineActionType.SendEmail);

  const hasInterviewTemplateAction = actionsField.some((field) => field.type === PipelineActionType.Interview);

  const { getFullInterviewTemplatesOptions, getAvailableInterviewTemplatesOptions } =
    useInterviewTemplateSelectorOptions({ skipQuery: !hasInterviewTemplateAction });

  const actionsValues = watch('actions', []);

  const selectedTemplateIds = useMemo(
    () =>
      actionsValues?.reduce((acc: string[], action) => {
        const { type, actionItemId } = action;

        if (type === PipelineActionType.Interview && actionItemId) {
          acc.push(actionItemId);
        }

        return acc;
      }, []),
    [actionsValues],
  );

  const addEmailTemplateClick = useCallback(() => {
    const prevValues = getValues();
    const prevActions = prevValues?.actions ?? [];

    const newField: Partial<HiringPipelineActions> = {
      pipelineActionId: '',
      type: PipelineActionType.SendEmail,
      actionItemId: '',
    };

    prepend(newField);

    reset({
      ...prevValues,
      actions: [newField, ...prevActions],
    });
  }, [prepend, getValues, reset]);

  const addInterviewTemplateClick = useCallback(() => {
    const fullOptions = getFullInterviewTemplatesOptions();
    const availableOptions = getAvailableInterviewTemplatesOptions(selectedTemplateIds);

    if (fullOptions.length && !availableOptions.length) {
      return;
    }

    const prevValues = getValues();
    const prevActions = prevValues?.actions ?? [];

    const last = prevActions?.at(-1);

    if (last?.type === PipelineActionType.Interview && !last?.actionItemId) {
      return;
    }

    const newField: Partial<HiringPipelineActions> = {
      pipelineActionId: '',
      type: PipelineActionType.Interview,
      actionItemId: '',
    };

    append(newField);

    reset({
      ...prevValues,
      actions: [...prevActions, newField],
    });
  }, [
    append,
    getValues,
    reset,
    getFullInterviewTemplatesOptions,
    getAvailableInterviewTemplatesOptions,
    selectedTemplateIds,
  ]);

  const onRemoveFieldClick = useCallback(
    (index) => {
      return () => {
        remove(index);
      };
    },
    [remove],
  );

  const hasHiringPipelineStage = Boolean(hiringPipelineStage);
  const modalTitleVerb = hasHiringPipelineStage ? 'Edit' : 'Add New';
  const okButtonVerb = hasHiringPipelineStage ? 'Update' : 'Add';
  const content: DialogProps['content'] = {
    title: `${modalTitleVerb} Stage`,
    withTitle: true,
    ...modalConfig?.content,
  };

  const isPipelineInUse = useAppSelector(hiringPipelineSelectors.checkIsInUsePipeline, pipelineId);

  const isStageTypeSelectDisabled = (hasHiringPipelineStage && isPipelineInUse) || isSystemStage(hiringPipelineStage);

  const pipelineStageTypeOptionsAll = usePipelineStageTypeOptions();
  const pipelineStageTypeOptionsCreatable = usePipelineStageTypeOptionsCreatable();

  const pipelineStageTypeOptions = isStageTypeSelectDisabled
    ? pipelineStageTypeOptionsAll
    : pipelineStageTypeOptionsCreatable;

  const stageTypeSelectDefaultValue = hiringPipelineStage?.stageType ?? PipelineStageType.New;

  const onSubmitClickHandler = (data: HiringPipelineStageSchemaProps) => {
    if (hiringPipelineStage) {
      const hiringPipelineForEdit: Pick<
        HiringPipelineStage,
        | 'pipelineStageId'
        | 'pipelineId'
        | 'name'
        | 'stageType'
        | 'actions'
        | 'stageBadgeLabel'
        | 'stageButtonLabel'
        | 'isRequired'
      > = {
        actions: data.actions,
        name: data.name!,
        pipelineId: data.pipelineId!,
        pipelineStageId: data.pipelineStageId!,
        stageBadgeLabel: data.stageBadgeLabel!,
        stageButtonLabel: data.stageButtonLabel,
        stageType: data.stageType! || hiringPipelineStage?.stageType,
        isRequired: data.isRequired!,
      };
      dispatch(hiringPipelineStageActions.prepareEdit(hiringPipelineForEdit));
      return;
    }

    const preparedData: HiringPipelineStagePrepareCreate = {
      actions: data.actions,
      name: data.name!,
      pipelineId,
      stageBadgeLabel: data.stageBadgeLabel!,
      stageType: data.stageType!,
      isRequired: data.isRequired!,
    };
    dispatch(hiringPipelineStageActions.prepareCreate(preparedData));
  };

  const onBackHandler = () => dispatch(wizardBackward({ id }));

  const showStageButtonLabel =
    hiringPipelineStage?.order === 1 || hiringPipelineStage?.stageType === PipelineStageType.Declined;

  const canAddInterviewTemplate = !hiringPipelineStage || isInterviewTemplateStage(hiringPipelineStage);

  const canBeRequiredStage = !hiringPipelineStage || isPossiblyRequiredStage(hiringPipelineStage);

  return {
    actionsField,
    content,
    control,
    errors,
    formState,
    handleSubmit,
    okButtonVerb,
    onBackHandler,
    onSubmitClickHandler,
    register,
    remove,
    showStageButtonLabel,
    watch,
    pipelineStageTypeOptions,
    isStageTypeSelectDisabled,
    stageTypeSelectDefaultValue,
    hasSendEmailAction,
    hasInterviewTemplateAction,
    canAddInterviewTemplate,
    canBeRequiredStage,
    addEmailTemplateClick,
    addInterviewTemplateClick,
    onRemoveFieldClick,
    selectedTemplateIds,
  } as const;
}

export const HiringPipelineStageCreateModal = (props: HiringPipelineStageCreateModalProps) => {
  const {
    onSubmitClickHandler,
    handleSubmit,
    errors,
    formState,
    register,
    onBackHandler,
    okButtonVerb,
    actionsField,
    control,
    watch,
    showStageButtonLabel,
    pipelineStageTypeOptions,
    isStageTypeSelectDisabled,
    stageTypeSelectDefaultValue,
    hasSendEmailAction,
    hasInterviewTemplateAction,
    canAddInterviewTemplate,
    canBeRequiredStage,
    addEmailTemplateClick,
    addInterviewTemplateClick,
    onRemoveFieldClick,
    selectedTemplateIds,
  } = useHiringPipelineStageCreateModalState(props);

  return (
    <ExModalBodyStyledWide>
      <form onSubmit={handleSubmit(onSubmitClickHandler)}>
        <input ref={register} hidden name="pipelineId" />
        <input ref={register} hidden name="pipelineStageId" />
        <FormInput
          inputRef={register}
          name="name"
          label="Stage Name"
          required
          errors={errors.name}
          validated={formState.isSubmitted}
        />
        <FormInput
          inputRef={register}
          name="stageBadgeLabel"
          label="Badge Label"
          required
          errors={errors.stageBadgeLabel}
          validated={formState.isSubmitted}
        />
        <Controller
          control={control}
          name="stageType"
          render={({ onChange, value }) => (
            <FormSelect
              name="stageType"
              label="Stage type"
              required
              isSearchable={false}
              isDisabled={isStageTypeSelectDisabled}
              errors={errors.stageType}
              validated={formState.isSubmitted}
              options={pipelineStageTypeOptions}
              value={value}
              defaultValue={stageTypeSelectDefaultValue}
              onChange={(option: Option) => {
                onChange(option?.value);
              }}
            />
          )}
        />
        {showStageButtonLabel && (
          <FormInput
            inputRef={register}
            name="stageButtonLabel"
            label="Button Label"
            errors={errors.stageButtonLabel}
            validated={formState.isSubmitted}
          />
        )}
        {canBeRequiredStage ? (
          <FormToggleButtons
            label="Is this a required step?"
            name="isRequired"
            control={control}
            options={requiredStageToggleOptions}
            errors={errors?.isRequired}
            defaultValue={false}
          />
        ) : (
          <input hidden type="checkbox" name="isRequired" ref={register()} defaultChecked={false} />
        )}
        <ExAccordion>
          <ExAccordionToggle eventKey="hiring-pipeline-stage-actions">
            Additional Information&nbsp;&nbsp;
          </ExAccordionToggle>
          <ExAccordion.Collapse eventKey="hiring-pipeline-stage-actions">
            <>
              <ModalContentRow>
                <ExColumn>
                  <ExButton variant="link" onClick={addEmailTemplateClick}>
                    Add Action
                  </ExButton>
                </ExColumn>
              </ModalContentRow>
              {hasSendEmailAction &&
                actionsField.map((field, index) => {
                  if (field.type !== PipelineActionType.SendEmail) {
                    return null;
                  }

                  return (
                    <ModalContentRow key={field.id}>
                      <input
                        ref={register()}
                        hidden
                        name={`actions[${index}].pipelineActionId`}
                        defaultValue={field.pipelineActionId}
                      />
                      <ModalContentColumn custom={Boolean(watch(`actions[${index}].type`, '')) ? '3' : '11'}>
                        <Controller
                          control={control}
                          name={`actions[${index}].type`}
                          render={({ onChange, value }) => {
                            return (
                              <FormSelect
                                name={`actions[${index}].type`}
                                label="Pipeline Action"
                                isSearchable={false}
                                isDisabled={field.pipelineActionId}
                                errors={errors.actions?.[index]?.type}
                                validated={formState.isSubmitted}
                                options={pipelineActionTypeOptions}
                                value={value}
                                onChange={(option: Option) => {
                                  onChange(option?.value);
                                }}
                              />
                            );
                          }}
                        />
                      </ModalContentColumn>
                      {watch(`actions[${index}].type`) === PipelineActionType.SendEmail ? (
                        <EmailTemplateSelector
                          control={control}
                          name={`actions[${index}].actionItemId`}
                          errors={errors.actions?.[index]?.actionItemId}
                          isDisabled={Boolean(watch(`actions[${index}].pipelineActionId`, ''))}
                          isRequired={Boolean(watch(`actions[${index}].type`, ''))}
                          isValidated={formState.isSubmitted}
                        />
                      ) : (
                        <Spacer />
                      )}
                      {watch(`actions[${index}].type`) === PipelineActionType.SendEmail ? (
                        <EmailRecipientSelector
                          control={control}
                          name={`actions[${index}].recipientType`}
                          errors={errors.actions?.[index]?.recipientType}
                          isDisabled={Boolean(watch(`actions[${index}].pipelineActionId`, ''))}
                          isRequired={Boolean(watch(`actions[${index}].type`, ''))}
                          isValidated={formState.isSubmitted}
                        />
                      ) : (
                        <Spacer />
                      )}
                      <ExColumn custom="1" flex column>
                        <Spacer />
                        <RemoveFieldButton icon={<IconCloseStyled />} onClick={onRemoveFieldClick(index)} />
                      </ExColumn>
                    </ModalContentRow>
                  );
                })}
              {canAddInterviewTemplate && (
                <>
                  <ModalContentRow>
                    <ExColumn>
                      <ExButton variant="link" onClick={addInterviewTemplateClick}>
                        Add Interview Template
                      </ExButton>
                    </ExColumn>
                  </ModalContentRow>
                  {hasInterviewTemplateAction && (
                    <ModalContentRow>
                      <ExColumn>
                        Selected templates must be completed before the candidate advances to the next step.
                      </ExColumn>
                    </ModalContentRow>
                  )}
                  {actionsField.map((field, index) => {
                    if (field.type !== PipelineActionType.Interview) {
                      return null;
                    }

                    return (
                      <ModalContentRow key={field.id}>
                        <input
                          ref={register()}
                          hidden
                          name={`actions[${index}].pipelineActionId`}
                          defaultValue={field.pipelineActionId}
                        />
                        <input
                          ref={register({
                            valueAsNumber: true,
                          })}
                          hidden
                          name={`actions[${index}].type`}
                          defaultValue={field.type}
                        />
                        <InterviewTemplateSelector
                          control={control}
                          name={`actions[${index}].actionItemId`}
                          errors={errors.actions?.[index]?.actionItemId}
                          isDisabled={Boolean(watch(`actions[${index}].pipelineActionId`, ''))}
                          isRequired={Boolean(watch(`actions[${index}].type`, ''))}
                          isValidated={formState.isSubmitted}
                          selectedTemplateIds={selectedTemplateIds}
                        />
                        <ExColumn custom="1" flex column>
                          <RemoveFieldButton icon={<IconCloseStyled />} onClick={onRemoveFieldClick(index)} />
                        </ExColumn>
                      </ModalContentRow>
                    );
                  })}
                </>
              )}
            </>
          </ExAccordion.Collapse>
        </ExAccordion>
        <ExDialogFooter>
          <ExDialogActions>
            <ExDialogActionButton variant="light" onClick={onBackHandler}>
              Back
            </ExDialogActionButton>
            <ExLoaderHandlerWrapperStrict>
              <ExLoaderHandlerWrapper>
                <ExDialogActionButton className="ml-3" type="submit">
                  {okButtonVerb}
                </ExDialogActionButton>
              </ExLoaderHandlerWrapper>
            </ExLoaderHandlerWrapperStrict>
          </ExDialogActions>
        </ExDialogFooter>
      </form>
    </ExModalBodyStyledWide>
  );
};

type EmailTemplateSelectorProps = {
  control: UseFormMethods['control'];
  name: string;
  isRequired: boolean;
  errors: UseFormMethods['errors'] | undefined;
  isValidated: boolean;
  isDisabled?: boolean;
};

const useEmailTemplateSelectorState = ({
  control,
  name,
  isRequired,
  errors,
  isDisabled,
  isValidated,
}: EmailTemplateSelectorProps) => {
  const dispatch = useAppDispatch();
  const emailTemplatesOptions = useAppSelector(emailTemplateSelectors.emailTemplateOptionsForSelectorSendEmailActions);

  const { isLoading } = useLoaderSubscription(emailTemplateActions.fetchSaga.start);

  useEffect(() => {
    dispatch(emailTemplateActions.setFilters({ emailCategory: EmailCategory.PipelineNotifications }));
    dispatch(emailTemplateActions.fetchSaga.start({ silent: true }));
  }, [dispatch]);

  return {
    control,
    emailTemplatesOptions,
    errors,
    isDisabled,
    isRequired,
    isValidated,
    name,
    isLoading,
  } as const;
};

const EmailTemplateSelector: React.FC<EmailTemplateSelectorProps> = (props) => {
  const { control, errors, isDisabled, isRequired, isValidated, name, emailTemplatesOptions, isLoading } =
    useEmailTemplateSelectorState(props);

  return (
    <ModalContentColumn custom="4">
      <Controller
        control={control}
        name={name}
        render={({ onChange, value }) => (
          <FormSelect
            name={name}
            label="Select Email Template"
            isSearchable={false}
            required={isRequired}
            isDisabled={isDisabled}
            errors={errors}
            validated={isValidated}
            options={emailTemplatesOptions}
            value={value}
            menuPlacement="top"
            onChange={(option: Option) => onChange(option?.value)}
            isLoading={isLoading}
          />
        )}
      />
    </ModalContentColumn>
  );
};

type EmailRecipientSelectorProps = {
  control: UseFormMethods['control'];
  name: string;
  isRequired: boolean;
  errors: UseFormMethods['errors'] | undefined;
  isValidated: boolean;
  isDisabled?: boolean;
};

const useEmailRecipientSelectorState = ({
  control,
  name,
  isRequired,
  errors,
  isDisabled,
  isValidated,
}: EmailRecipientSelectorProps) => {
  const emailRecipientOptions = [
    {
      value: 'Candidate',
      label: 'Candidate',
    },
    {
      value: 'Recruiter',
      label: 'Recruiter',
    },
    {
      value: 'HiringManager',
      label: 'Hiring Manager',
    },
  ];

  return {
    control,
    emailRecipientOptions,
    errors,
    isDisabled,
    isRequired,
    isValidated,
    name,
  } as const;
};

const EmailRecipientSelector: React.FC<EmailRecipientSelectorProps> = (props) => {
  const { control, errors, isDisabled, isRequired, isValidated, name, emailRecipientOptions } =
    useEmailRecipientSelectorState(props);

  return (
    <ModalContentColumn custom="4">
      <Controller
        control={control}
        name={name}
        render={({ onChange, value }) => (
          <FormSelect
            name={name}
            label="Select Recipient"
            isSearchable={false}
            required={isRequired}
            isDisabled={isDisabled}
            errors={errors}
            validated={isValidated}
            options={emailRecipientOptions}
            value={value}
            menuPlacement="top"
            onChange={(option: Option) => onChange(option?.value)}
          />
        )}
      />
    </ModalContentColumn>
  );
};

type InterviewTemplateSelectorProps = {
  control: UseFormMethods['control'];
  name: string;
  isRequired: boolean;
  errors: UseFormMethods['errors'] | undefined;
  isValidated: boolean;
  isDisabled?: boolean;
  selectedTemplateIds?: string[];
};

const useInterviewTemplateSelectorState = ({
  control,
  name,
  isRequired,
  errors,
  isDisabled,
  isValidated,
  selectedTemplateIds,
}: InterviewTemplateSelectorProps) => {
  const { isFetching, getAvailableInterviewTemplatesOptions } = useInterviewTemplateSelectorOptions({});

  return {
    control,
    errors,
    isDisabled,
    isRequired,
    isValidated,
    name,
    isLoading: isFetching,
    selectedTemplateIds,
    getAvailableInterviewTemplatesOptions,
  } as const;
};

const InterviewTemplateSelector: React.FC<InterviewTemplateSelectorProps> = (props) => {
  const {
    control,
    errors,
    isDisabled,
    isRequired,
    isValidated,
    name,
    isLoading,
    selectedTemplateIds,
    getAvailableInterviewTemplatesOptions,
  } = useInterviewTemplateSelectorState(props);

  return (
    <ModalContentColumn custom="11">
      <Controller
        control={control}
        name={name}
        render={({ onChange, value }) => {
          const availableTemplateOptions = value
            ? selectedTemplateIds?.filter((templateId) => templateId !== value)
            : selectedTemplateIds;

          const interviewTemplatesOptions = getAvailableInterviewTemplatesOptions(availableTemplateOptions);

          return (
            <FormSelect
              name={name}
              label=""
              placeholder="Select Interview Template"
              isSearchable={false}
              required={isRequired}
              isDisabled={isDisabled}
              errors={errors}
              validated={isValidated}
              options={interviewTemplatesOptions}
              value={value}
              onChange={(option) => {
                onChange(option?.interviewTemplateId);
              }}
              isLoading={isLoading}
              getOptionLabel={(option) => option.interviewTemplateName}
              getOptionValue={(option) => option.interviewTemplateId}
              menuPlacement="top"
              isOptionDisabled={(option) => option.isDisabled}
            />
          );
        }}
      />
    </ModalContentColumn>
  );
};
