import React from 'react';
import styled from 'styled-components/macro';

import { FormTagSelect } from 'components/FormTagSelect';
import { ExFilterButton } from 'components/ui/ExButton/ExFilterButton';
import { ExFilterClearButton } from 'components/ui/ExButton/ExFilterClearButton';
import { ExVisible } from 'components/ui/ExVisible';

const ExFilterButtonStyled = styled(ExFilterButton)`
  margin-left: 10px;
`;

const ExFilterClearButtonStyled = styled(ExFilterClearButton)`
  margin-left: 10px;
`;

type Value = Record<string, any>;

type ApplicantFilterHeaderProps = {
  options?: Value[];
  value?: Value;
  handleStatusChange: (selected: any) => void;
  handlerClickFilters?: () => void;
  isFilterSelected?: boolean;
  handleClearFilters?: () => void;
};

export const ApplicantFilterHeader: React.FC<ApplicantFilterHeaderProps> = ({
  options,
  value,
  handleStatusChange,
  handlerClickFilters,
  isFilterSelected = false,
  handleClearFilters,
}) => {
  const hasClickFilters = Boolean(handlerClickFilters);

  return (
    <>
      <div className="list-filter-search">
        <FormTagSelect
          isSearchable={false}
          isMulti={false}
          defaultOptions={options}
          value={value}
          onChange={handleStatusChange}
          className="applicant-status-select"
        />
      </div>
      <ExVisible visible={hasClickFilters}>
        <ExFilterButtonStyled onClick={handlerClickFilters} />
      </ExVisible>
      <ExVisible visible={isFilterSelected}>
        <ExFilterClearButtonStyled variant="link" onClick={handleClearFilters}>
          Clear
        </ExFilterClearButtonStyled>
      </ExVisible>
    </>
  );
};
