import React, { useEffect, useMemo, useState } from 'react';
import SelectAsync, { components } from 'react-select';
import { AxiosResponse } from 'axios';
import { useAppRouterParams } from 'router';
import uniqBy from 'lodash/uniqBy';

import { apiConfig } from 'config';

import { getJobKeywordsFunc, getTalentPoolKeywordsFunc } from 'api-endpoints/candidate';

import { ApiResponseWithPagination } from 'model';

import { LoadingIndicator, MultiValueRemove } from 'components';
import { useReactSelectDebounceHandler } from 'utils/hooks/useReactSelectDebounce';

import { KeywordSelectStyled } from './KeywordSelect.components';
import { useKeywordSelectStyles } from './KeywordSelect.styles';

type KeywordSelectProps = {
  className?: string;
  onChange: (keywords: readonly KeywordSelectOption[]) => void;
  value?: KeywordSelectOption[];
  isCandidateList?: boolean;
};

export type KeywordSelectOption = { label: string; value: string };

type UseKeywordSelectState = {
  options: KeywordSelectOption[];
  pageNo: number;
  pageCount: number;
  defaultPageNo: number;
  defaultPageCount: number;
  isLoading: boolean;
  searchTerm: string;
};

const useKeywordSelectState = ({ className, onChange, value, isCandidateList }: KeywordSelectProps) => {
  const [options, setOptions] = useState<UseKeywordSelectState>({
    options: [],
    pageNo: 0,
    defaultPageNo: 0,
    defaultPageCount: 0,
    pageCount: 0,
    isLoading: false,
    searchTerm: '',
  });
  const { jobId, talentPoolId } = useAppRouterParams();
  const uniqueOptions = useMemo(() => uniqBy(options.options, 'label'), [options.options]);

  const isLoading = options.isLoading;
  const pageNo = options.pageNo;
  const pageCount = options.pageCount;

  const loadOptionsHandler = async (searchTerm = '', page = 0) => {
    let keywords: string[] = [];
    const newState: Partial<UseKeywordSelectState> = {};
    newState.pageNo = !!searchTerm ? page : options.defaultPageNo;
    newState.pageCount = pageCount;
    newState.searchTerm = searchTerm;
    newState.isLoading = false;
    const keyword = searchTerm ? searchTerm : undefined;
    let result: AxiosResponse<ApiResponseWithPagination<string>> | undefined;
    if (isCandidateList && talentPoolId) {
      result = await getTalentPoolKeywordsFunc({
        urlParams: {
          talentPoolId,
        },
        params: { pageNo: newState.pageNo, keyword },
      });
    } else if (jobId) {
      result = await getJobKeywordsFunc({
        urlParams: { jobId },
        params: { pageNo: newState.pageNo, keyword },
      });
    }
    if (result?.data) {
      keywords = result?.data.items ?? [];
      if (!!searchTerm) {
        newState.pageCount = result.data.pageCount;
      } else {
        newState.defaultPageCount = result.data.pageCount;
        newState.defaultPageNo = newState.pageNo + 1;
      }
    }
    const loadedOptions = keywords.map((matchedKeyword) => ({
      label: matchedKeyword,
      value: matchedKeyword,
    }));
    const optionsMerged = [...options.options, ...loadedOptions];
    setOptions((prev) => ({
      ...prev,
      ...newState,
      options: optionsMerged,
    }));
  };

  const debouncedLoadOptions = useReactSelectDebounceHandler({
    asyncLoader: loadOptionsHandler,
    delay: apiConfig.debounceTime,
  });
  useEffect(() => {
    setOptions((prev) => ({ ...prev, isLoading: true }));
    const { searchTerm } = options;
    debouncedLoadOptions(searchTerm, pageNo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styles = useKeywordSelectStyles();

  const nextPage = () => {
    const pageN = options.searchTerm ? pageNo : options.defaultPageNo;
    const pageC = options.searchTerm ? pageCount : options.defaultPageCount;
    if (!isLoading && pageNo <= pageC) {
      setOptions((prev) => ({ ...prev, isLoading: true }));
      debouncedLoadOptions(options.searchTerm, pageN + 1);
    }
  };

  const onInputChangeHandler = (searchTerm = '') => {
    setOptions((prev) => ({ ...prev, searchTerm, pageNo: 0, pageCount: 0, isLoading: true }));
    debouncedLoadOptions(searchTerm);
  };

  const noOptionsHandler = ({ inputValue }: { inputValue: string }) => {
    if (inputValue.length === 0) {
      return 'Start typing';
    }
    if (inputValue.length < 3) {
      return 'Enter at least three characters';
    }
    return 'No Options';
  };

  return {
    className,
    styles,
    onChange,
    value,
    pageNo,
    nextPage,
    isLoading,
    onInputChangeHandler,
    uniqueOptions,
    noOptionsHandler,
    options,
  } as const;
};

export const KeywordSelect: React.FC<KeywordSelectProps> = (props) => {
  const {
    className,
    styles,
    onChange,
    value,
    nextPage,
    isLoading,
    onInputChangeHandler,
    uniqueOptions,
    noOptionsHandler,
  } = useKeywordSelectState(props);

  return (
    <KeywordSelectStyled className={className}>
      <SelectAsync<KeywordSelectOption, true>
        name="keywords"
        openMenuOnFocus
        isMulti={true}
        noOptionsHandler={noOptionsHandler}
        defaultOptions={uniqueOptions}
        options={uniqueOptions}
        onInputChange={onInputChangeHandler}
        maxMenuHeight={150}
        placeholder="Select Keyword(s)"
        styles={styles}
        isLoading={isLoading}
        value={value}
        onMenuScrollToBottom={nextPage}
        captureMenuScroll
        components={{
          Placeholder: (placeholderProps) => <components.Placeholder {...placeholderProps} />,
          MultiValueRemove,
          LoadingIndicator,
          IndicatorSeparator: null,
        }}
        onChange={onChange}
      />
    </KeywordSelectStyled>
  );
};
