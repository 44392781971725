import { FC } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styled, { createGlobalStyle } from 'styled-components/macro';

import { IconLock } from 'components/Icons/IconLock';

const LockStyled = styled.div`
  cursor: pointer;
`;

const LockTooltipStyled = createGlobalStyle`
  .lock-tooltip {
    .tooltip-inner {
      background-color: #30374F;
      padding: 6px 10px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      border-radius: 6px;
    }

    &.tooltip .arrow {
      width: 20px;
      height: 7px;
    }

    &.bs-tooltip-bottom .arrow::before,
    &.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
      bottom: -1px;
      border-bottom-color: #30374F;
      border-width: 0 10px 7px 10px;
    }
  }
`;

type CardLockProps = {
  className?: string;
  hint?: string;
  visible?: boolean;
};

export const ApplicantLock: FC<CardLockProps> = ({ className, hint, visible }) => {
  if (!visible) {
    return null;
  }

  return (
    <LockStyled className={className}>
      <LockTooltipStyled />
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip className="lock-tooltip" id="lock-tooltip">
            {hint}
          </Tooltip>
        }
      >
        <IconLock />
      </OverlayTrigger>
    </LockStyled>
  );
};
