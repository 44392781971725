import { createEntityAdapter } from '@reduxjs/toolkit';
import type { ExInterview } from 'model';

import { EntityStateWithPagination, providesList, transformListResponse } from 'api-client/axiosBaseQuery';
import { replaceParamsInUrl } from 'api-client/utils';
import type * as fromApiEndpointsInterview from 'api-endpoints/interview';
import { InterviewEndpoints } from 'api-endpoints/interview/interview.endpoints';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';

import { getSuccessToastForEntityAction } from 'utils/i18utils';

import { emptyApi } from 'store/entities/emptyApi';
import { RootState } from 'store/rootReducer';
import { ApplicantInterviewTabListFilters } from 'store/ui/applicants/applicant-interview-tab-list/applicant-interview-tab-list.reducer';
import { JobInterviewTabListFilters } from 'store/ui/jobs/job-interview-tab-list/job-interview-tab-list.reducer';
import { jobInterviewTabListSelectors } from 'store/ui/jobs/job-interview-tab-list/job-interview-tab-list.selectors';

export const interviewAdapter = createEntityAdapter<ExInterview>({ selectId: (item) => item.interviewId });

type InterviewTabListFilters = Partial<JobInterviewTabListFilters> & Partial<ApplicantInterviewTabListFilters>;

export const extendedApiByInterview = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    jobInterviewList: builder.query<EntityStateWithPagination<ExInterview>, InterviewTabListFilters>({
      query: (args) => ({
        url: replaceParamsInUrl(InterviewEndpoints.SearchInterviewsFunc, {}),
        method: 'POST',
        data: { ...args },
      }),
      transformResponse: transformListResponse(interviewAdapter),
    }),
    interview: builder.query<ExInterview, ExInterview['interviewId']>({
      query: (interviewId) => ({
        url: replaceParamsInUrl(InterviewEndpoints.GetInterviewFunc, { interviewId }),
        method: 'GET',
      }),
    }),
    interviewRemove: builder.mutation<void, ExInterview>({
      query: (arg) => ({
        url: replaceParamsInUrl(InterviewEndpoints.DeleteInterviewFunc, { interviewId: arg.interviewId }),
        method: 'DELETE',
      }),
      onQueryStarted: async ({ name, interviewId }, { dispatch, queryFulfilled, getState }) => {
        const filters = jobInterviewTabListSelectors.filters(getState() as RootState);
        const patches = dispatch(
          enhancedApiByInterview.util.updateQueryData('jobInterviewList', filters, (draft) => {
            interviewAdapter.removeOne(draft, interviewId);
          }),
        );

        try {
          await queryFulfilled;
          const message = getSuccessToastForEntityAction({
            name,
            action: 'deleted',
            entityName: 'interview',
          });
          dispatch(alertsEffects.showSuccess({ message }));
        } catch (e) {
          patches.undo();
        }
      },
    }),
    interviewCreate: builder.mutation<ExInterview, fromApiEndpointsInterview.CreateInterviewFuncRequestParams>({
      query: (args) => ({
        url: replaceParamsInUrl(InterviewEndpoints.CreateInterviewFunc, {}),
        method: 'POST',
        data: { ...args },
      }),
      onQueryStarted: async ({ name }, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          const message = getSuccessToastForEntityAction({
            name,
            action: 'created',
            entityName: 'interview',
          });
          dispatch(alertsEffects.showSuccess({ message }));
        } catch (error) {}
      },
    }),
    interviewUpdate: builder.mutation<
      fromApiEndpointsInterview.UpdateInterviewFuncRequestResponse,
      fromApiEndpointsInterview.UpdateInterviewFuncRequestParams
    >({
      query: (arg) => ({
        url: replaceParamsInUrl(InterviewEndpoints.UpdateInterviewFunc, { interviewId: arg.interviewId }),
        method: 'PUT',
        data: arg,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled, getState }) => {
        const filters = jobInterviewTabListSelectors.filters(getState() as RootState);
        const patches = dispatch(
          extendedApiByInterview.util.updateQueryData('jobInterviewList', filters, (draft) => {
            interviewAdapter.updateOne(draft, { id: args.interviewId, changes: { ...args } });
          }),
        );
        try {
          await queryFulfilled;
          const message = getSuccessToastForEntityAction({
            name: args.name,
            action: 'updated',
            entityName: 'interview',
          });
          dispatch(alertsEffects.showSuccess({ message }));
        } catch (error) {
          patches.undo();
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const enhancedApiByInterview = extendedApiByInterview.enhanceEndpoints({
  addTagTypes: ['Interviews', 'Applicant'],
  endpoints: {
    jobInterviewList: {
      providesTags: (result) => providesList(result?.ids, 'Interviews'),
    },
    interview: {
      providesTags: (_, __, interviewId) => [{ type: 'Interviews', id: interviewId }],
    },
    interviewUpdate: {
      invalidatesTags: (_, __, arg) => [
        { type: 'Interviews', id: arg.interviewId },
        { type: 'Interviews', id: 'LIST' },
        { type: 'Applicant', id: arg.jobApplicantId },
      ],
    },
    interviewRemove: {
      invalidatesTags: (_, __, arg) => [
        { type: 'Interviews', id: 'LIST' },
        { type: 'Applicant', id: arg.jobApplicantId },
      ],
    },
    interviewCreate: {
      invalidatesTags: (_, __, arg) => [
        { type: 'Interviews', id: 'LIST' },
        { type: 'Interviews', id: arg.applicantId },
      ],
    },
  },
});

export const {
  useJobInterviewListQuery,
  useInterviewQuery,
  useInterviewCreateMutation,
  useInterviewRemoveMutation,
  useInterviewUpdateMutation,
} = enhancedApiByInterview;
