import React from 'react';

import { TabMenu } from 'components/TabMenu';

import { FormStepsPanelStyled, FormStepsPanelTitle } from './FormStepsPanelComponents';
import { FormStepsPanelProps } from './FormStepsPanelProps';

export const FormStepsPanel: React.FC<FormStepsPanelProps> = ({ className, steps }) => {
  return (
    <FormStepsPanelStyled className={className}>
      <FormStepsPanelTitle>Steps</FormStepsPanelTitle>
      <TabMenu navItems={steps} vertical />
    </FormStepsPanelStyled>
  );
};
