import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ExInterview, ExInterviewTemplate } from 'model';
import pull from 'lodash/pull';

import { UIKeys } from 'store/constants';
import { Applicant } from 'store/entities/applicants';
import { CandidateEducation } from 'store/entities/candidate-education/candidate-education.models';
import { CandidateWorkExperience } from 'store/entities/candidate-work-experience/candidate-work-experience.models';
import { Candidate } from 'store/entities/candidates/models';
import { JobAd } from 'store/entities/job-ads';
import { JobBoard } from 'store/entities/job-boards';
import { Job } from 'store/entities/jobs';
import { TalentPool } from 'store/entities/talent-pools';

interface ModalResult {
  id: string;
  payload: any;
}

const initialState = {
  candidateId: null as Candidate['id'] | null,
  educationId: null as CandidateEducation['educationId'] | null,
  jobAdId: null as JobAd['id'] | null,
  jobBoardId: null as JobBoard['jobBoardId'] | null,
  jobId: null as Job['id'] | null,
  modalType: [] as string[],
  payloads: [] as ModalResult[],
  talentPoolId: null as TalentPool['id'] | null,
  workExperienceId: null as CandidateWorkExperience['workExperienceId'] | null,
  interviewTemplateId: null as ExInterviewTemplate['interviewTemplateId'] | null,
  interviewId: null as ExInterview['interviewId'] | null,
  applicantId: null as Applicant['applicantId'] | null,
} as const;

export const modalSlice = createSlice({
  initialState,
  name: UIKeys.modals,
  reducers: {
    hideModal: (state) => {
      state.modalType.splice(-1, 1);
    },
    hideModalAll: (state) => {
      state.modalType = [];
    },
    hideModalByIds: (state, { payload }: PayloadAction<{ modalType: string[] }>) => {
      pull(state.modalType, ...payload.modalType);
    },
    removeResult: (state, action: PayloadAction<{ id: ModalResult['id'] }>) => {
      state.payloads = state.payloads.filter((payload) => payload.id !== action.payload.id);
    },
    saveResult: (state, { payload }: PayloadAction<ModalResult>) => {
      state.payloads.push(payload);
    },
    setCandidateId: (state, { payload }: PayloadAction<{ candidateId: Candidate['id'] | null }>) => ({
      ...state,
      ...payload,
    }),
    setJobBoardId: (state, { payload }: PayloadAction<{ jobBoardId: JobBoard['jobBoardId'] | null }>) => ({
      ...state,
      ...payload,
    }),
    setJobId: (state, { payload }: PayloadAction<{ jobId: Job['id'] | null }>) => ({
      ...state,
      ...payload,
    }),
    setTalentPoolId: (state, { payload }: PayloadAction<{ talentPoolId: TalentPool['id'] | null }>) => ({
      ...state,
      ...payload,
    }),
    setInterviewTemplateId: (
      state,
      { payload }: PayloadAction<{ interviewTemplateId: ExInterviewTemplate['interviewTemplateId'] | null }>,
    ) => ({
      ...state,
      ...payload,
    }),
    setInterviewId: (state, { payload }: PayloadAction<{ interviewId: ExInterview['interviewId'] | null }>) => ({
      ...state,
      ...payload,
    }),
    setApplicantId: (state, { payload }: PayloadAction<{ applicantId: Applicant['applicantId'] | null }>) => ({
      ...state,
      ...payload,
    }),
    showModal: (state, { payload }: PayloadAction<{ modalType: string | null }>) => {
      if (payload.modalType) {
        state.modalType.push(payload.modalType);
      }
    },
    updateState: (state, { payload }: PayloadAction<Partial<typeof initialState>>) => ({
      ...state,
      ...payload,
    }),
  },
});
