import { QualificationTypeEndpoints } from 'api-endpoints/qualification-type/endpoints';
import { createApiMethod } from 'api-endpoints/utils';

import * as fromQualificationTypeEntities from 'store/entities/qualification-type';

export const getQualificationTypes = createApiMethod<any, fromQualificationTypeEntities.QualificationTypeModel[]>({
  url: QualificationTypeEndpoints.SearchQualificationsFunc,
});

export const removeQualificationTypeApiCall = createApiMethod<
  fromQualificationTypeEntities.QualificationTypeRequestParams,
  void
>({
  url: QualificationTypeEndpoints.DeleteQualificationFunc,
  method: 'DELETE',
});

export const createQualificationTypeApiCall = createApiMethod<
  fromQualificationTypeEntities.QualificationTypeCreateRequestParams,
  void
>({
  url: QualificationTypeEndpoints.CreateQualificationFunc,
  method: 'POST',
});

export const updateQualificationTypeApiCall = createApiMethod<
  fromQualificationTypeEntities.QualificationTypeUpdateRequestParams,
  void
>({
  url: QualificationTypeEndpoints.UpdateQualificationFunc,
  method: 'PUT',
});

export const getDaxtraMappingApiCall = createApiMethod<
  any,
  fromQualificationTypeEntities.QualificationTypeMappingResponse
>({
  url: QualificationTypeEndpoints.GetDaxtraMappingFunc,
});

export const assignDaxtraMappingApiCall = createApiMethod<
  fromQualificationTypeEntities.QualificationTypeAssignDaxtraRequestParams,
  void
>({
  url: QualificationTypeEndpoints.AssignDaxtraMappingFunc,
  method: 'PUT',
});

export const getDaxtraQualificationsApiCall = createApiMethod<
  any,
  fromQualificationTypeEntities.DaxtraQualificationsResponse
>({
  url: QualificationTypeEndpoints.GetDaxtraMappingFunc,
});
