import React, { ReactElement } from 'react';
import { ButtonProps } from 'react-bootstrap/Button';
import styled, { css } from 'styled-components';

export const ExIconButtonBaseStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;
`;

export const ExIconButtonBase = styled.button`
  ${ExIconButtonBaseStyles}
`;

interface ExIconButtonProps extends ButtonProps {
  icon?: ReactElement;
}

export const ExIconButton = ({ icon, children, ...props }: ExIconButtonProps) => {
  return (
    <ExIconButtonBase {...props}>
      {icon}
      {children}
    </ExIconButtonBase>
  );
};

export const ExIconButtonWithRef = React.forwardRef<HTMLButtonElement, ExIconButtonProps>(
  ({ icon, children, ...props }, ref) => {
    return (
      <ExIconButtonBase ref={ref} {...props}>
        {icon}
        {children}
      </ExIconButtonBase>
    );
  },
);
