import React, { CSSProperties, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { components, createFilter, OptionProps, SingleValueProps } from 'react-select';
import Select from 'react-select/src/Select';

import { apiConfig } from 'config';

import { Country, Location } from 'model';

import { FormTagSelect } from 'components/FormTagSelect';
import {
  SelectCounty,
  SelectLocationOption,
  SelectPlaceName,
} from 'components/LocationServiceForm/LocationServiceFormComponents';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useReactSelectDebounceHandler } from 'utils/hooks/useReactSelectDebounce';

import { useCompanyCountryQuerySelectors } from 'store/dictionary/hooks/useCompanyCountryQuerySelectors';
import { useCountryQuerySelectors } from 'store/dictionary/hooks/useCountryQuerySelectors';
import { findLocationsThunk } from 'store/dictionary/locations';
import { RootState } from 'store/rootReducer';

type CountriesSource = 'country' | 'companyCountry';

export interface LocationServiceFormProps {
  countryFieldName: string;
  locationFieldName: string;
  countryClassNames?: string;
  locationClassNames?: string;
  countryFieldRequired?: boolean;
  locationFieldRequired?: boolean;
  countriesSource?: CountriesSource;
  isDisabled?: boolean;
}

type CountriesSourceStrategyProps = Record<CountriesSource, { selectors: any }>;

const countriesSourceStrategy: CountriesSourceStrategyProps = {
  country: {
    selectors: useCountryQuerySelectors,
  },
  companyCountry: {
    selectors: useCompanyCountryQuerySelectors,
  },
};

const LocationServiceForm: React.FC<LocationServiceFormProps> = ({
  countryFieldName,
  locationFieldName,
  countryClassNames,
  locationClassNames,
  countryFieldRequired,
  locationFieldRequired,
  countriesSource = 'country',
  isDisabled,
}) => {
  const { setValue, watch, errors, control, formState } = useFormContext();
  const formSelectedCountry = watch(countryFieldName);
  const [selectedCountryCode, setCountryCode] = useState<string | undefined>(formSelectedCountry);
  const dispatch = useAppDispatch();
  const countryRef = useRef<Select<Country>>(null);
  const locationRef = useRef<Select<Location>>(null);

  const source = countriesSourceStrategy[countriesSource].selectors();
  const countries: Country[] = useSelector(source.selectors.selectAll);
  const selectedCountry = useSelector((state: RootState) =>
    source.selectors.selectByExpedoCode2(state, selectedCountryCode || ''),
  ) as Country;

  const changeCountryHandler = (onChange: Function) => (selected: Country) => {
    const locationDirty = formState.dirtyFields.locationFieldName && formState.isSubmitted;
    setValue(locationFieldName, null, {
      shouldValidate: locationDirty,
    });
    setCountryCode(selected?.code.expedoCode2);
    onChange(selected?.code.expedoCode2 ?? null);
  };

  const noOptionsLocationHandler = ({ inputValue }: { inputValue: string }) => {
    if (!selectedCountry?.countryId) {
      return 'First select country';
    }
    if (inputValue.length === 0) {
      return 'Start typing';
    }
    if (inputValue.length < 3) {
      return 'Enter at least three characters';
    }
    return 'No Options';
  };

  const loadLocationsHandler = async (placeName = '') => {
    const code = selectedCountry?.code;
    if (placeName && code) {
      const { idibuCode2: countryCode } = code;
      const result = await dispatch(findLocationsThunk({ params: { countryCode, placeName } }));
      return result.payload;
    }
  };

  const debouncedLoadOptions = useReactSelectDebounceHandler({
    asyncLoader: loadLocationsHandler,
    delay: apiConfig.debounceTime,
  });

  return (
    <>
      <Controller
        render={({ onChange, value }) => {
          const defaultCountry =
            countries.find((item) => item.code.expedoCode2 === value) ||
            (value
              ? {
                  name: value,
                  code: { expedoCode2: value },
                }
              : null);
          return (
            <FormTagSelect
              isClearable
              label="Country"
              required={countryFieldRequired}
              className={countryClassNames}
              getOptionLabel={(option: Country) => option.name}
              getOptionValue={(option: Country) => option.code.expedoCode2}
              maxMenuHeight={150}
              validated={formState.isSubmitted}
              options={countries}
              isMulti={false}
              defaultOptions={countries}
              value={defaultCountry}
              errors={errors?.[countryFieldName]}
              filterOption={createFilter(null)}
              loadOptions={() => Promise.resolve(countries)}
              components={{
                Placeholder: () => null,
                IndicatorSeparator: null,
              }}
              onChange={changeCountryHandler(onChange)}
              ref={countryRef}
              isDisabled={isDisabled}
            />
          );
        }}
        onBlurName="blur"
        name={countryFieldName}
        onFocus={() => countryRef?.current?.focus()}
      />
      <Controller
        isClearable
        required={locationFieldRequired}
        label="Location"
        className={locationClassNames}
        as={<FormTagSelect ref={locationRef} />}
        name={locationFieldName}
        isMulti={false}
        control={control}
        loadOptions={debouncedLoadOptions}
        errors={errors?.[locationFieldName]}
        validated={formState.isSubmitted}
        getOptionLabel={(option: Location) => option.placeName}
        getOptionValue={(option: Location) => option.id}
        maxMenuHeight={150}
        onFocus={() => locationRef?.current?.focus()}
        onBlurName="blur"
        noOptionsMessage={noOptionsLocationHandler}
        overrideStyles={{
          singleValue: (base: CSSProperties) => ({
            ...base,
            width: 'calc(100% - 14px)',
          }),
        }}
        isDisabled={isDisabled}
        components={{
          Placeholder: () => null,
          IndicatorSeparator: null,
          DropdownIndicator: null,
          SingleValue: (props: SingleValueProps<Location>) => {
            const item: Location = props.data;
            return (
              <components.SingleValue {...props}>
                <SelectLocationOption>
                  <SelectPlaceName>{item.placeName}</SelectPlaceName>
                  <SelectCounty>{item.county ?? item.region}</SelectCounty>
                </SelectLocationOption>
              </components.SingleValue>
            );
          },
          Option: (props: OptionProps<Location, false>) => {
            const item: Location = props.data;
            return (
              <components.Option {...props}>
                <SelectLocationOption>
                  <SelectPlaceName>{item.placeName}</SelectPlaceName>
                  <SelectCounty>{item.county ?? item.region}</SelectCounty>
                </SelectLocationOption>
              </components.Option>
            );
          },
        }}
      />
    </>
  );
};

export default LocationServiceForm;
