import styled from 'styled-components';

export const SelectLocationOption = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
`;
export const SelectPlaceName = styled.div`
  margin-right: 16px;
  white-space: nowrap;
`;
export const SelectCounty = styled.div`
  color: var(--gray);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
