import { EntityId, nanoid, PayloadAction } from '@reduxjs/toolkit';
import { format, isAfter, isMatch, parseISO } from 'date-fns';
import { call, put, select } from 'redux-saga/effects';

import { ExComment, ExCommentView } from 'model';

import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { CommentAddHandlerParams } from 'components/ui/ExComments/ExCommentListProps';

import { authSelectors } from 'store/auth/auth.selectors';
import type { UserAPI } from 'store/auth/auth.type';
import { exModalHideAction } from 'store/modals/modals.actions';
import { ModalGeneralResult } from 'store/modals/modals.interfaces';
import { modalSagaWorker } from 'store/modals/modals.sagas';

export function makeCommentRead(id: EntityId) {
  return { changes: { read: true }, id };
}
export function makeCommentTextWithMentionHtml(text: string) {
  const regString = /(?:(?:\s+|^)(?<at>@))(?:\[)(?<userName>.+?)(?:\])(?:(?<uuid>\(.+?\)))/;
  const regexp = new RegExp(regString, 'g');
  return text.replaceAll(regexp, ' <span class="user">$1$2</span> ');
}

export function makeExCommentView(
  comment: ExComment | undefined,
  user: UserAPI | null,
  defaultDateFormat: string,
): ExCommentView | null {
  if (!comment) {
    return null;
  }

  const isOwnComment = user?.userId === comment.createdBy.userId;

  const defaultTime = new Date().toLocaleTimeString();
  const match = isMatch(defaultTime, 'pp');
  const createdOnDate = parseISO(comment.createdOn);
  const updatedOnDate = comment.lastModifiedOn ? parseISO(comment.lastModifiedOn) : createdOnDate;
  const patternByMatch = match ? 'p' : 'HH:mm';
  const createdOn = format(parseISO(comment.createdOn), defaultDateFormat + ', ' + patternByMatch);
  const isEdited = isAfter(updatedOnDate, createdOnDate);
  const updatedOn = isEdited ? format(updatedOnDate, defaultDateFormat + ', ' + patternByMatch) : createdOn;

  const text = makeCommentTextWithMentionHtml(comment.text);

  return {
    createdOn,
    isEdited,
    commentId: comment.commentId,
    isEditable: isOwnComment,
    isRemoved: comment.createdBy.isRemoved,
    read: comment.read || isOwnComment,
    textView: text,
    text: comment.text,
    updatedHint: `${updatedOn} by ${comment.lastModifiedBy}`,
    userAvatar: comment.createdBy.photo ?? '',
    userFullName: comment.createdBy.userName,
  };
}

type CreateDirectCommentActionLoaderProps = Omit<PayloadAction<{ commentId: EntityId }>, 'type'>;
export function createDirectCommentActionLoader<A extends CreateDirectCommentActionLoaderProps>(action: A) {
  return { type: `${action.payload.commentId}` };
}

export function* removeCommentConfirmModal() {
  const result: ModalGeneralResult = yield call(modalSagaWorker, {
    modalConfig: {
      content: {
        message: 'You confirm remove comment?',
        title: 'Confirm Action',
        withActions: true,
        withTitle: true,
      },
    },
    modalType: ModalsTypeKey.confirmModal,
  });

  if (result.cancel || !result.confirm) {
    return false;
  }

  yield put(exModalHideAction({ id: result.confirm.payload.id }));
  return true;
}

export function* createTemporaryComment(data: Partial<ExComment> & CommentAddHandlerParams) {
  const temporaryCommentCommentId = nanoid();
  const user: ReturnType<typeof authSelectors.apiUser> = yield select(authSelectors.apiUser);
  const now = new Date().toISOString();

  /**
   * We create Temporary comment for show in the UI while API request is in processing
   *
   * @type {ExComment}
   */
  const temporaryComment: ExComment = {
    ...data,
    commentId: temporaryCommentCommentId,
    createdBy: {
      isRemoved: false,
      photo: user?.pictureUrl!,
      userId: user?.userId!,
      userName: user?.name!,
    },
    createdOn: now,
    lastModifiedOn: null,
    lastModifiedBy: null,
    read: false,
  };

  return {
    temporaryComment,
    temporaryCommentCommentId,
  };
}
