import { call, put } from 'redux-saga/effects';

import { basicPublicUrl } from 'pages/public/state/index';
import { publicCountryActions } from 'pages/public/state/public-country/public-country.actions';

export function* publicCountryFetchAction() {
  const url = `${basicPublicUrl}/api/dictionary/country`;

  const response = yield call(fetch, url);
  const json = yield call([response, 'json']);

  yield put(publicCountryActions.setAll(json));
}
