import React from 'react';
import styled from 'styled-components/macro';

import { SalaryBudgetStatus } from 'model/api-enums.constants';

import {
  ApplicantInfoLabel,
  IconCurrencyDollarStyled,
  OnBudgetBadge,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/ApplicantModalV3Components';
import { ApplicantModalInfoWrapper } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalV4Components';
import { useApplicantFromModal } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/hook/useApplicantFromModal';

import { ExVisible } from 'components/ui/ExVisible';

type ApplicantModalSalaryInfoViewProps = {
  className?: string;
};

const ApplicantModalSalaryInfoViewStyled = styled(ApplicantModalInfoWrapper)``;

const useApplicantModalSalaryInfoViewState = ({ className }: ApplicantModalSalaryInfoViewProps) => {
  const applicant = useApplicantFromModal();

  const salaryExp = applicant?.salaryExp;
  const salaryBudgetStatus = applicant?.salaryBudgetStatus;

  const showObBudgetBadge =
    applicant?.salaryBudgetStatus !== null &&
    applicant?.salaryBudgetStatus !== undefined &&
    applicant?.salaryBudgetStatus &&
    applicant?.salaryBudgetStatus !== SalaryBudgetStatus.NotCompared;

  return {
    className,
    salaryExp,
    salaryBudgetStatus,
    showObBudgetBadge,
  } as const;
};

export const ApplicantModalSalaryInfoView: React.FC<ApplicantModalSalaryInfoViewProps> = (props) => {
  const { className, salaryExp, salaryBudgetStatus, showObBudgetBadge } = useApplicantModalSalaryInfoViewState(props);

  return (
    <ApplicantModalSalaryInfoViewStyled className={className}>
      <IconCurrencyDollarStyled />
      <div>
        <div>{salaryExp}</div>
        <ApplicantInfoLabel>Salary Expectation</ApplicantInfoLabel>
        <div>
          <ExVisible visible={showObBudgetBadge}>
            <OnBudgetBadge salaryBudgetStatus={salaryBudgetStatus} />
          </ExVisible>
        </div>
      </div>
    </ApplicantModalSalaryInfoViewStyled>
  );
};
