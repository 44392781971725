import { ENTERED, TransitionStatus } from 'react-transition-group/Transition';
import styled from 'styled-components/macro';

import {
  ApplicantContactsItem as ApplicantContactsItemV3,
  ApplicantContactsItemLabel as ApplicantContactsItemLabelV3,
  ApplicantModalInfoWrapper as ApplicantModalInfoWrapperV3,
  ApplicantSection,
  ApplicantSectionDivider as ApplicantSectionDividerV3,
  TabContentWrapper,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/ApplicantModalV3Components';
import { ApplicantCommentTab } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/Tabs';

import { ExCommentItemStyled } from 'components/ui/ExComments';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

export const ApplicantSectionDivider = styled(ApplicantSectionDividerV3)`
  margin-top: 0;
  background-color: ${(props) => props.theme.colors.neutral};
`;

export const ApplicantModalInfoWrapper = styled(ApplicantModalInfoWrapperV3)`
  margin-bottom: 20px;

  & + & {
    margin-top: 0;
  }
`;

export const ApplicantContactsItem = styled(ApplicantContactsItemV3)`
  margin-bottom: 10px;
  display: flex;

  &:not(:first-child) {
    margin-top: 0;
  }
`;

export const ApplicantContactsItemLabel = styled(ApplicantContactsItemLabelV3)`
  flex-shrink: 0;
`;

export const ApplicantContactsItemValue = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SpinnerWrapper = styled.div<{ $state: TransitionStatus }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  transition: opacity 0.1s ease-in-out;
  opacity: ${({ $state }) => ([ENTERED].includes($state) ? 1 : 0)};
  z-index: 20;
`;

export const ApplicantModalV4Styled = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1485px;
  max-height: 860px;
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ApplicantModalContent = styled.div`
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  position: relative;
`;

export const ApplicantModalFooterWrapper = styled.div`
  flex-shrink: 0;
  box-shadow: 0 -4px 4px #eee;
  padding-top: 22px;
  padding-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
`;

export const ApplicantModalInfo = styled.div`
  width: 260px;
  flex-shrink: 0;
  overflow-y: auto;
  background: #f9f9f9;
`;

export const ApplicantModalBodyWrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
`;

export const ApplicantModalComments = styled.div`
  width: 330px;
  flex-shrink: 0;
  overflow: hidden;
  margin-top: 44px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
`;

export const ApplicantModalClose = styled.button`
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 20px;
  width: 24px;
  height: 24px;
  border: 0;
  margin: 0;
  padding: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }
`;

export const ApplicantCommentTabStyled = styled(ApplicantCommentTab)`
  height: 100%;
  overflow: hidden;

  ${ExCommentItemStyled} {
    &:first-child {
      padding-top: 0;
    }
  }
`;

export const ApplicantModalTitle = styled.div`
  font-size: ${getThemeFontSize('cardHeading')};
  font-weight: 700;
  margin-bottom: 18px;
`;

export const ApplicantModalForm = styled.form.attrs({ id: 'applicant-modal-form' })`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

export const ApplicantSectionStyled = styled(ApplicantSection)`
  background: transparent;
  height: 100%;

  .form-group {
    padding: 0 10px;
    margin-bottom: 15px;
  }
`;

export const ApplicantModalBodyStyled = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const TabContentWrapperStyled = styled(TabContentWrapper)`
  height: auto;
  overflow-y: auto;
  display: grid;
`;

export const ApplicantModalAlertsWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;
