import toLower from 'lodash/toLower';

import { AppFile, Country, DateTimeDto } from 'model';

import { DocumentFile } from 'store/app-files/documents/documents.models';
import { ResumeFile } from 'store/app-files/resumes/resumes.models';

export const pipe = (value: any, ...funcs: Array<Function>): any => funcs.reduce((a, f) => f(a), value);

export const capitalize = (value: string) => {
  if (!value) {
    return '';
  }
  value = value.toString().toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const keys = <T extends object>(o: T): Array<keyof T> => Object.keys(o) as Array<keyof T>;

type NumberReturnType<T> = T extends (infer E)[] ? (E extends number ? E : never) : never;
export const StringIsNumber = <T>(value: any): value is NumberReturnType<T> => !isNaN(Number(value));

export const idX = <T>(x: T): T => x;

export const compose = (...funcs: Function[]) =>
  funcs.length
    ? funcs.reduce(
        (a: Function, b: Function) =>
          (...args: Array<any>) =>
            a(b(...args)),
      )
    : idX;

export const curry = (fn: Function, arity: number = fn.length, ...args: any): Function =>
  arity <= args.length ? fn(...args) : curry.bind(null, fn, arity, ...args);

export function isResumeFile(item: AppFile): item is ResumeFile {
  return !!item.resumeId;
}

export function isDocumentFile(item: AppFile): item is DocumentFile {
  return !!item.documentId;
}

/**
 * Function for sorting objects in an array by order field
 *
 * @template T must extend object with field order
 * @param {T} a
 * @param {T} b
 * @returns number
 */
export function sortByOrderField<T extends { order?: number }>(a: T, b: T) {
  return a?.order && b?.order ? a.order - b.order : 1;
}

type IsFunction<T> = T extends (...args: any[]) => any ? T : never;

/**
 * Type guard for checking that an argument is a function
 * @param value [Function]
 */
export const isFunction = <T extends {}>(value: T): value is IsFunction<T> => typeof value === 'function';

export type EnumLiteralsOf<T extends object, key extends keyof T = keyof T> = T[key] extends infer U
  ? U extends number | string
    ? U
    : never
  : never;
/**
 * Find and return key in object by value
 *
 * @param {object} object  object
 * @param {any} value  value in object
 * @returns {string|undefined}  string key in object or undefined
 */
export function getKeyByValue<T extends { [key: string]: any }, V extends any>(
  object: T,
  value: V,
): string | undefined {
  return Object.keys(object).find((key) => object[key] === value);
}

/**
 *Check is given string is color
 *
 * @param {string} strColor
 * @returns {boolean}
 */
export function isColor(strColor: string): boolean {
  const s = new Option().style;
  s.color = strColor;
  const test1 = s.color === strColor;
  const test2 = /^#[0-9A-F]{6}$/i.test(strColor);
  if (test1 === true || test2 === true) {
    return true;
  }
  return false;
}

/**
 * Calls a callback for each child of the node
 *
 * @param {HTMLElement} node
 * @param {(node: HTMLElement) => void} func
 */
export function walkDOM(node: HTMLElement, func: (node: HTMLElement) => void) {
  const children = node.childNodes;
  children.forEach((n) => walkDOM(n as HTMLElement, func));
  func(node);
}

function getSortResult(a: any, b: any, direction: 1 | -1) {
  switch (true) {
    case a > b:
      return direction;
    case a < b:
      return -direction;
    default:
      return 0;
  }
}

type OrderFieldConfig<T> = { prop: keyof T; direction?: 'asc' | 'dsc'; toLowerCase?: boolean };
/**
 * @description Sort Items by provided config
 *
 * @param   {OrderFieldConfig<T>}  fields  array of config
 * @param   {[]}          T       array of objects
 * @example // Simple Example:
 * // [
 * //   { field1:'b' },
 * //   { field1:'a' }
 * // ].sort(fieldSorter([{ prop:'field1' }])) => [
 * //    { field1:'a' },
 * //    { field1:'b' }
 * // ]
 *
 * @returns number result for Array.sort method
 */
export function fieldSorter<T>(fields: OrderFieldConfig<T>[]) {
  return (a: T, b: T) =>
    fields
      .map((o) => {
        const direction = o.direction === 'dsc' ? -1 : 1;
        const fn = Boolean(o.toLowerCase) ? toLower : (x: any) => x;
        const aValue = fn(a[o.prop]);
        const bValue = fn(b[o.prop]);

        return getSortResult(aValue, bValue, direction);
      })
      .reduce((p, n) => (p ? p : n), 0);
}

/**
 * Guard to filter the truthy entities;
 *
 * @param t
 */
export function truthGuard<T>(t: T | undefined | false): t is T {
  return !!t;
}

/**
 * Function return actualDate or null from object
 *
 * @param   {string|DateTimeDto|null|undefined}  workExperienceStartDate  [workExperienceStartDate description]
 *
 * @return  {string|null}                           [return description]
 */
export function getActualDate(workExperienceStartDate: DateTimeDto | null | undefined): string | null {
  return typeof workExperienceStartDate === 'string'
    ? workExperienceStartDate
    : workExperienceStartDate?.actualDate ?? null;
}

/**
 * Function that removes properties which have value null or undefined.
 * Object passed as reference.
 *
 * @template T
 * @param {T} object
 */
export function removeNullAndUndefinedPropertiesFromObject<T extends { [key in keyof T]: any }>(object: T) {
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      const jobAdKey = key as keyof T;
      if (object[jobAdKey] === undefined || object[jobAdKey] === null) {
        delete object[jobAdKey];
      }
    }
  }
}

/**
 * Function pick and rename object keys
 *
 * @export
 * @template T object
 * @param {Array<[keyof T, string]>} fields config
 * @returns {(item: T)=> Record<NewKey, T[OldKeys]>} record with renamed keys
 */
export function pickAndRename<
  T extends { [x: string]: any },
  OldKeys extends keyof T = keyof T,
  NewKey extends string = string,
>(fields: Array<[OldKeys | keyof T, NewKey]>): (item: T) => Record<NewKey, T[OldKeys]> {
  return function (item: T) {
    const result = {} as Record<NewKey, T[OldKeys]>;
    fields.forEach(([oldName, newName]) => {
      (result as any)[newName] = item[oldName];
    });
    return result;
  };
}

/**
 * Function pick or pick and rename object keys
 *
 * @export
 * @template T object
 * @param {item: T} object to pick fields
 * @param {Array<[keyof T, string]>} fields config
 * @returns {T} record with renamed keys
 */
export function pickOrRename<
  T extends { [x: string]: any },
  OldKeys extends keyof T = keyof T,
  NewKey extends string = string,
>(item: T, fields: Array<[OldKeys | keyof T, NewKey] | string>): T {
  const result = {} as T;

  fields.forEach((field) => {
    const [oldName, newName] = Array.isArray(field) ? field : [field, field];
    (result as any)[newName] = item[oldName];
  });

  return result;
}

/**
 * Function helper for count total
 *
 * @template T object that include field value
 * @param {number} acc accumulator
 * @param {T} item
 * @returns {number} sum
 */
export function totalReducerObjectValue<T extends { value: number }>(acc: number, item: T): number {
  return acc + item.value;
}

/**
 * Function helper to compare countries
 * @param {string} countryCode  selected Country code
 * @param {keyof Country['code']} fieldName Company`s field for comparing
 * @returns {(item:Country)=>boolean} comparing result
 */
export const compareCountryByCountryCode =
  (countryCode: string, fieldName: keyof Country['code']): ((item: Country) => boolean) =>
  (item: Country) =>
    item.code[fieldName] === countryCode;

export const returnWhenFalsy = <T, R>(returnedValue: T, value?: R) => {
  return value || returnedValue;
};

export const returnWhenNullish = <T, R>(returnedValue: T, value?: R) => {
  return value ?? returnedValue;
};

/**
 * The function generates a full name from an object with fields firstName + lastName or name
 * @param {Record<string,any>} applicant
 * @returns {string}
 */
export function getApplicantFullName<T extends Record<string, any>>(applicant: T | undefined): string {
  if (!applicant) {
    return '--';
  }
  const name = applicant?.name;
  let lastName = applicant?.lastName;
  let firstName = applicant?.firstName;

  if (firstName && lastName) {
    return `${firstName} ${lastName}`.trim();
  }

  firstName = firstName ?? '--';
  lastName = lastName ?? '--';

  const computedName = name ?? `${firstName} ${lastName}`;

  return computedName.trim();
}
