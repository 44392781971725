import { CandidateItemResponse } from 'api-endpoints/candidate/models';

import { pipe } from 'utils/funcs';
import { KeyMap, toStateKeys, TwoWayMap } from 'utils/key-mapper';

import { Candidate } from 'store/entities/candidates/models';

const candidateItemMapper: KeyMap<CandidateItemResponse, Candidate> = {
  candidateId: 'id',
  createdDate: 'createdOn',
  name: 'fullName',
};

export const mapToModel = toStateKeys(new TwoWayMap(candidateItemMapper));

const assignModelKeys = (candidate: Candidate): Candidate => {
  return Object.assign(candidate, mapToModel(candidate));
};

const assignCountry = (candidate: Candidate): Candidate => {
  const country = candidate?.candidateLocation?.countryCode || candidate.country;
  return Object.assign(candidate, {
    country,
  });
};

export const candidateToState = (candidate: CandidateItemResponse): Candidate =>
  pipe(candidate, assignModelKeys, assignCountry);
