import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';

import { ApplicantForm } from 'containers/ApplicantForm/ApplicantFormState/applicant-form.models';

import { FormSelect } from 'components';
import { useSeniorityLevelOptions } from 'utils/hooks/formsHooks/useSeniorityLevelOptions';

type ApplicantModalSeniorityLevelInfoEditProps = {
  className?: string;
};

const ApplicantModalSeniorityLevelInfoEditStyled = styled.div``;

const useApplicantModalSeniorityLevelInfoEditState = ({ className }: ApplicantModalSeniorityLevelInfoEditProps) => {
  const { errors, formState } = useFormContext<ApplicantForm>();
  const validated = formState.isSubmitted;

  const seniorityLevel = useSeniorityLevelOptions();

  return {
    className,
    errors,
    validated,
    seniorityLevel,
  } as const;
};

export const ApplicantModalSeniorityLevelInfoEdit: React.FC<ApplicantModalSeniorityLevelInfoEditProps> = (props) => {
  const { className, errors, validated, seniorityLevel } = useApplicantModalSeniorityLevelInfoEditState(props);

  return (
    <ApplicantModalSeniorityLevelInfoEditStyled className={className}>
      <FormSelect
        name="seniorityLevel"
        label="Seniority Level"
        placeholder=""
        errors={errors?.seniorityLevel}
        validated={validated}
        defaultOptions
        options={seniorityLevel}
        isClearable
      />
    </ApplicantModalSeniorityLevelInfoEditStyled>
  );
};
