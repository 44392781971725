import { CSSProperties } from 'react';
import { Action, ActionCreator } from 'redux';

type LoaderEntityState = 'idle' | 'processing';

export type LoaderEntity = {
  id: string; // Action type
  state: LoaderEntityState;
};

export interface LoaderManager {
  selectors: [];
  start: (id: LoaderEntity['id']) => void;
  stop: (id: LoaderEntity['id']) => void;
  reset: () => void;
  clear: () => void;
  add: (loaderEntity: LoaderEntity) => void;
  remove: (id: LoaderEntity['id']) => void;
}

export type ThunkActionType = ((x: any) => any) | ActionCreator<any>;

export type LoaderWrapperProps = {
  action?: Action<string>;
  children: React.ReactNode;
  isLoading?: boolean;
  style?: CSSProperties;
};

export const emptyAction = { type: 'emptyAction' };
