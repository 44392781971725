import React, { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { matchPath, useLocation } from 'react-router';
import { push } from 'connected-react-router';
import { ExRoutes, ExRoutesPathMap } from 'router/routes';
import styled from 'styled-components';

import { WithRequired } from 'model/utils';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import { NewTalentPoolFormBody, validationSchemaNewTalentPool } from 'containers/TalentPoolForms';
import { talentPoolFormActions, talentPoolFormSelectors } from 'containers/TalentPoolForms/state';

import { Form } from 'components';
import { DialogProps } from 'components/Dialog';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { ExDialog } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getSuccessToastForEntityAction } from 'utils/i18utils';

import { TalentPool, talentPoolActions, talentPoolsApi } from 'store/entities/talent-pools';

export interface NewTalentPoolProps extends AppModalContentProps {
  className?: string;
}

const FormStyled = styled(Form)`
  min-width: 400px;

  & > *:not(:last-child) {
    margin-bottom: 0;
  }
`;

const DialogActions = styled.div`
  display: flex;
  justify-content: center;
`;

const NewTalentPool: React.FC<NewTalentPoolProps> = ({ className, onClose }) => {
  const dispatch = useAppDispatch();

  const content: DialogProps['content'] = {
    title: 'Create New Talent Pool',
    withTitle: true,
  };

  const candidateTalentPools = useAppSelector(talentPoolFormSelectors.candidateTalentPoolIds);
  const [localTalentPoolFormData, setLocalTalentPoolFormData] = useState<any>(null);
  const { pathname } = useLocation();
  const matched = matchPath(pathname, { path: ExRoutesPathMap.jobApplicantsModal.path });

  const defaultValues = {};

  useEffect(() => {
    return () => {
      dispatch(talentPoolFormActions.updateState({ apiErrors: [] }));
    };
  }, []); // eslint-disable-line

  const [createTalentPool, { isLoading, isSuccess, data, originalArgs }] =
    talentPoolsApi.endpoints.talentPoolCreate.useMutation();

  const submitHandler = React.useCallback<
    SubmitHandler<WithRequired<TalentPool, 'categoryId' | 'name' | 'categoryName' | 'positionName' | 'positionId'>>
  >(
    (talentPoolFormData) => {
      createTalentPool({
        categoryId: talentPoolFormData.categoryId,
        categoryName: talentPoolFormData.categoryName,
        name: talentPoolFormData.name,
        positionId: talentPoolFormData.positionId,
        positionName: talentPoolFormData.positionName,
      });
      setLocalTalentPoolFormData(talentPoolFormData);
    },
    [createTalentPool],
  );

  useEffect(() => {
    if (isSuccess) {
      onClose();
      if (matched) {
        if (data?.id) {
          const talentPoolsIds = [...candidateTalentPools, data.id];
          dispatch(
            talentPoolFormActions.updateState({
              selectedTalentPoolsIds: talentPoolsIds,
            }),
          );
          dispatch(
            talentPoolActions.upsert({
              items: [
                {
                  id: data.id,
                  name: localTalentPoolFormData?.name,
                },
              ] as TalentPool[],
            }),
          );
        }
      } else {
        dispatch(push(ExRoutes.talentPoolPage({ talentPoolId: data?.id })));
      }
      const message = getSuccessToastForEntityAction({
        action: 'created',
        entityName: 'talentPool',
        name: originalArgs?.name ?? 'TalentPool',
      });
      dispatch(alertsEffects.showSuccess({ message }));
    }
  }, [
    candidateTalentPools,
    data?.id,
    dispatch,
    isSuccess,
    localTalentPoolFormData,
    matched,
    onClose,
    originalArgs?.name,
  ]);

  return (
    <div className={className}>
      <ExDialog onClose={onClose} content={content} className="dialog">
        <FormStyled
          onSubmit={submitHandler}
          validationSchema={validationSchemaNewTalentPool}
          defaultValues={defaultValues}
        >
          <NewTalentPoolFormBody />
          <DialogActions>
            <ExButton variant="light" onClick={onClose}>
              Cancel
            </ExButton>
            <ExButton className="ml-3" variant="primary" type="submit" isLoading={isLoading} disabled={isLoading}>
              Save
            </ExButton>
          </DialogActions>
        </FormStyled>
      </ExDialog>
    </div>
  );
};

export default styled(NewTalentPool)`
  .dialog {
    background-color: ${(props) => props.theme.colors.white};
  }
`;
