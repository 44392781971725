import { combineReducers } from '@reduxjs/toolkit';

import { candidateSourcesReducer } from 'store/ui/widgets/candidate-sources';
import { WidgetReducers } from 'store/ui/widgets/widgets.constants';

const widgetsReducers = combineReducers({
  [WidgetReducers.CandidateSources]: candidateSourcesReducer,
});

export { widgetsReducers };
