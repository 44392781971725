import { createSelector } from '@reduxjs/toolkit';

import { getCacheListID, initListState } from 'utils/reducer/reducer-helper';
import { createListSelectors } from 'utils/reducer/selector-helper';

import { EntityKeysNew, UIKeys } from 'store/constants';
import { JobBoard, JobBoardFilters, jobBoardSelectors, SortBy } from 'store/entities/job-boards';
import { RootState } from 'store/rootReducer';
import { uiDomain } from 'store/rootSelectors';

export function checkIsFiltersSelected<T extends Object>(filters: T, checkFunction: (value: [string, any]) => boolean) {
  return Object.entries(filters).some(checkFunction);
}

export const domain = createSelector(uiDomain, (state) => state[UIKeys.jobBoardLists]);

export const {
  // getListById,
  // getFilters,
  // getSortMode,
  // getListParams,
  // getViewMode,
  // getSelected,
  // getItems: getJobs,
  getSelectedEntities: getSelectedJobBoards,
  getRecentItems: getRecentJobBoards,
} = createListSelectors<JobBoard, SortBy, JobBoardFilters>(
  EntityKeysNew.jobBoards as any,
  (state: RootState) => state.ui.jobBoardLists,
);

export const getListById = createSelector(
  domain,
  (_: RootState, props: { id: string }) => props.id,
  (jobBoardLists, listId) => jobBoardLists?.[listId] || initListState,
);

export const getFilters = createSelector(getListById, (list) => list?.filters);

export const getSortMode = createSelector(getListById, (list) => list?.sortMode);

export const getJobBoards = createSelector(
  domain,
  getListById,
  jobBoardSelectors.selectEntities,
  (lists, list, entities) => {
    const cachedList = lists[list.id]?.pagination[getCacheListID(list)];
    return (
      cachedList?.items.reduce(
        (acc: Array<any>, entityId: any) => (entities && entities[entityId] ? [...acc, entities[entityId]] : acc),
        [],
      ) || []
    );
  },
);

export const getListParams = createSelector(getListById, (list) => ({
  viewMode: list.viewMode,
  sortMode: list.sortMode,
  pageNo: list.pageNo,
  pageSize: list.pageSize,
  filters: list.filters,
}));

export const getViewMode = createSelector(getListById, (list) => list?.viewMode);

export const getSelected = createSelector(getListById, (list) => list?.selectedItems ?? []);

export const isFiltersChanged = createSelector(getFilters, (filtersState) => {
  if (!filtersState) {
    return false;
  }
  return checkIsFiltersSelected(filtersState, ([key, value]) => {
    return ['industries', 'countryCodes', 'name'].includes(key) && value?.length > 0;
  });
});
