import React from 'react';

import { JobAbilitiesModal } from 'containers/Modals/ModalsContent/Job/JobAbilitiesModal';
import { useJobAbilityItems } from 'containers/Modals/ModalsContent/Job/JobAbilitiesModal/hooks/useJobAbilityItems';

import { JobResponsibilitiesModalProps } from './JobResponsibilitiesModalProps';

export const JobResponsibilitiesModal: React.FC<JobResponsibilitiesModalProps> = ({ ...dialogProps }) => {
  const title = 'Job Responsibilities';
  const { onSubmitValidation, items } = useJobAbilityItems({
    modalId: dialogProps.id,
    abilityEntity: 'responsibilities',
    validationSchemaLabel: title,
  });

  return <JobAbilitiesModal title={title} onSubmitValidation={onSubmitValidation} items={items} {...dialogProps} />;
};
