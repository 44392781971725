import { compose, createSelector } from '@reduxjs/toolkit';

import { ApplicationRole, CompanyDateFormatValues } from 'model/api-enums.constants';

import { sortByCurrency, sortByMain, sortByName } from 'utils/list';

import type { CompanyInfo } from 'store/company/company.types';
import { JobTeamMember } from 'store/entities/jobs';
import { QualificationTypeModel } from 'store/entities/qualification-type/qualification-type.model';
import { RootState } from 'store/rootReducer';
import { companyDomain } from 'store/rootSelectors';

export const getCompanyInfo = createSelector(companyDomain, (state) => state.info);

export const selectCompanyPublicUrl = createSelector(getCompanyInfo, (state) => state?.companyPublicUrl);

const getDefaultCurrency = createSelector(
  getCompanyInfo,
  (state) => state?.currencies?.find((currency) => currency.main)?.currency || 'AUD',
);

const getDefaultDateFormat = createSelector(getCompanyInfo, (state) =>
  state?.dateFormat !== undefined ? CompanyDateFormatValues[state.dateFormat] : CompanyDateFormatValues[0],
);

const sortCompanyCountriesByNameAndMain = compose<
  CompanyInfo['countries'],
  CompanyInfo['countries'],
  CompanyInfo['countries']
>(sortByMain, sortByName);

const getCompanyCountries = createSelector(getCompanyInfo, (state) => state?.countries ?? []);
const getCompanyCountriesSortedByMainAndName = createSelector(getCompanyInfo, (state) =>
  sortCompanyCountriesByNameAndMain(state?.countries ?? []),
);
const getDefaultCompanyCountry = createSelector(getCompanyCountries, (countries) =>
  countries.find((country) => Boolean(country.main)),
);

const getCompanyIndustries = createSelector(getCompanyInfo, (state) => sortByName(state?.industries ?? []));

const sortByCurrencyAndMain = compose<CompanyInfo['currencies'], CompanyInfo['currencies'], CompanyInfo['currencies']>(
  sortByMain,
  sortByCurrency,
);

const getCompanyCurrencies = createSelector(getCompanyInfo, (state) => state?.currencies ?? []);
const getCompanyCurrenciesSortedByNameAndMain = createSelector(getCompanyCurrencies, (currencies) =>
  sortByCurrencyAndMain(currencies),
);

const getCompanyCurrenciesSortedByName = createSelector(getCompanyCurrencies, (countries) =>
  [...countries].sort((a, b) => new Intl.Collator('en').compare(a.currencyFullName, b.currencyFullName)),
);

const selectCompanyLogo = createSelector(getCompanyInfo, (state) => state?.companyLogoUrl);

const selectCompanyName = createSelector(getCompanyInfo, (state) => state?.name);

const getCompanyDaxtra = createSelector(companyDomain, (state) => state.daxtra);

const getCompanyDaxtraMapping = createSelector(getCompanyDaxtra, (state) => state?.mapping ?? []);
const getCompanyDaxtraQualifications = createSelector(getCompanyDaxtra, (state) => state?.qualifications ?? []);

const getCompanyDaxtraMappingExcludeById = createSelector(
  getCompanyDaxtraMapping,
  (state: RootState, qualificationId: QualificationTypeModel['qualificationId']) => qualificationId,
  (state, qualificationId) => state.filter((daxtraMapped) => daxtraMapped.qualificationId !== qualificationId),
);

const selectCompanyComments = createSelector(companyDomain, (state) => state.comments);

const selectIsEnabledInHr = createSelector(getCompanyInfo, (state) => state?.isEnabledInHr);

const selectHrNavigationUrl = createSelector(getCompanyInfo, (state) => state?.hrNavigationUrl ?? '');

const selectAdminTeamMembers = createSelector(
  companyDomain,
  (state) =>
    state?.adminTeamMembers.filter(
      (teamMember) =>
        teamMember.applicationRoles === ApplicationRole.Admin ||
        teamMember.applicationRoles === ApplicationRole.Recruiter,
    ) ?? [],
);

const selectAdminTeamMemberById = createSelector(
  selectAdminTeamMembers,
  (state: RootState, { userId }: { userId: string }) => userId,
  (adminTeamMembers: JobTeamMember[], userId: string) => {
    return adminTeamMembers?.find((member) => member.id === userId);
  },
);

export const companySelectors = {
  getCompanyInfo,
  getDefaultCurrency,
  getDefaultDateFormat,
  getDefaultCompanyCountry,
  getCompanyCountries,
  getCompanyIndustries,
  getCompanyCurrencies,
  getCompanyDaxtra,
  getCompanyDaxtraMapping,
  getCompanyDaxtraQualifications,
  getCompanyDaxtraMappingExcludeById,
  getCompanyCountriesSortedByMainAndName,
  getCompanyCurrenciesSortedByNameAndMain,
  getCompanyCurrenciesSortedByName,
  selectCompanyLogo,
  selectCompanyName,
  selectCompanyPublicUrl,
  selectCompanyComments,
  selectIsEnabledInHr,
  selectHrNavigationUrl,
  selectAdminTeamMembers,
  selectAdminTeamMemberById,
};
