import { compose, createSelector } from '@reduxjs/toolkit';
import { ChartDataSets } from 'chart.js';
import qs from 'qs';
import { theme } from 'theme';
import { format, parseISO } from 'date-fns';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';

import { dateFormat } from 'config';

import { ApplicationRole, JobStatus, PipelineStageType } from 'model/api-enums.constants';

import { totalReducerObjectValue } from 'utils/funcs';
import { filterLineManagers, filterLineManagersNotRemoved } from 'utils/jobTeamMembers';
import { BasicModel } from 'utils/reducer/reducer-helper';
import { createLookupSelectors } from 'utils/reducer/selector-helper';

import { authSelectors } from 'store/auth/auth.selectors';
import { EntityKeys } from 'store/constants';
import { Applicant } from 'store/entities/applicants/models';
import { applicantSelectors } from 'store/entities/applicants/selectors';
import { Job, JobApplicantsPerSources } from 'store/entities/jobs/models';
import { RootState } from 'store/rootReducer';
import { entitiesDomain } from 'store/rootSelectors';
import {
  chartDataDoughnutDataBuilder,
  convertSummaryPerSourceElementToAnalytics,
  prepareChartDataForDoughnut,
} from 'store/ui/widgets/candidate-sources/candidate-sources.selectors';

export const domain = createSelector(entitiesDomain, (state) => state.jobs);

const jobsLookupSelectors = createLookupSelectors<Job>(EntityKeys.jobs);

const getById = createSelector(
  domain,
  (_: any, { id }: { id: BasicModel['id'] }) => id,
  (state, id) => {
    return state.byId && state.byId[id];
  },
);

const getApplicants = createSelector(getById, applicantSelectors.getLookup, (job: Job, applicants) =>
  (job?.applicantsIds || []).map((id) => applicants[id]).filter(Boolean),
);

const getApplicantsByStageType = createSelector(
  getApplicants,
  (_: RootState, { stageType }: { stageType: PipelineStageType | undefined }) => stageType,
  (applicants, stageType) => {
    return applicants.filter((applicant) => applicant?.stageType === stageType) || [];
  },
);

const selectJobByApplicantId = createSelector(
  jobsLookupSelectors.getList,
  applicantSelectors.getById,
  (jobs, applicant?: Applicant) => jobs.find((job) => job.jobId === applicant?.jobId),
);
// history: ReturnType<typeof useHistory>,
// location: ReturnType<typeof useLocation>,
// params: ReturnType<typeof useParams>,
const getApplicantsByPipelineStageId = createSelector(
  getApplicants,
  (_: RootState, { pipelineStageId }: { pipelineStageId: Applicant['pipelineStageId'] }) => pipelineStageId,
  (applicants, pipelineStageId) => {
    /**
     * This reverse is a dirty hack and must be destroyed in the nearest future!!!!
     */
    return [...(applicants.filter((applicant) => applicant?.pipelineStageId === pipelineStageId) || [])].reverse();
  },
);

const checkJobStatus = () =>
  createSelector(
    domain,
    (_: any, own: any) => own,
    (state, { status, location }) => {
      const jobId = location.pathname.split('/')[2];
      return (
        state.byId &&
        (state.byId[jobId]?.status === status ||
          qs.parse(location.search, { ignoreQueryPrefix: true })['status'] === JobStatus[status])
      );
    },
  );

const summaryPerSource = createSelector(jobsLookupSelectors.getById, (job) => job?.summaryPerSource ?? []);

const jobApplicantsPerSourceFigures = createSelector(
  jobsLookupSelectors.getById,
  (job) => job?.jobApplicantsPerSourceFigures ?? [],
);

const candidateSourcesDataSelector = createSelector(jobApplicantsPerSourceFigures, (data) => {
  const convertedData: JobApplicantsPerSources[] = [];
  data.forEach((source) => {
    source.sources.forEach((sourceInner) => convertedData.push(sourceInner));
  });

  const totalCount = convertedData.reduce((acc, item) => {
    acc += item.count;
    return acc;
  }, 0);

  const sortedData = [...convertedData].sort((a, b) => b.count - a.count);

  return {
    totalCount,
    data: sortedData,
  };
});

const candidateSourcesChartDataSelector = createSelector(
  summaryPerSource,
  (
    data,
  ): {
    data: Chart.ChartData;
    totalCount: number;
    options: Chart.ChartOptions;
  } => {
    const totalCount = data.reduce((acc, item) => {
      acc += item.value.reduce(totalReducerObjectValue, 0);
      return acc;
    }, 0);
    const dataCount = data.length;
    const labels = Array.from({ length: dataCount }, (_, index) => index);
    return {
      data: {
        datasets: data.reduce<ChartDataSets[]>((acc, { key: label, value }) => {
          const valueTotal = value.reduce(totalReducerObjectValue, 0);
          return acc.concat({
            borderColor: 'white',
            data: [valueTotal, totalCount - valueTotal],
            hoverBorderColor: 'white',
            label,
          });
        }, []),
        labels,
      },
      options: {
        cutoutPercentage: 100 - 12 * dataCount,
        elements: {
          center: {
            count: totalCount,
            fontSize: 25,
            text: 'Total Application',
          },
        } as any,
      },
      totalCount,
    };
  },
);

const composed = compose(
  chartDataDoughnutDataBuilder,
  prepareChartDataForDoughnut,
  convertSummaryPerSourceElementToAnalytics,
);

const candidateSourcesChartDoughnutDataSelector = createSelector(summaryPerSource, composed);

const jobsApplicationChartDataSelector = createSelector(summaryPerSource, (data): Chart.ChartData => {
  let resultData: Chart.ChartData = {
    datasets: [],
    labels: [],
  };
  if (!data.length) {
    return resultData;
  }
  const totalArray = data.flatMap(({ key, value }) => [...value.map((v) => ({ ...v, key }))]);
  const sumByPipelineStageId = Object.entries(groupBy(totalArray, 'pipelineStageId')).reduce<Record<string, number>>(
    (acc, [key, value]) => {
      acc[key] = value.reduce(totalReducerObjectValue, 0);
      return acc;
    },
    {},
  );
  resultData = data.reduce<Chart.ChartData>(
    (acc, item) => {
      const dataset = {
        data: item.value.map((i) => +(i.value / sumByPipelineStageId[i.pipelineStageId]).toFixed(2)),
        label: item.key,
      };
      acc.datasets?.push(dataset);
      const currentLabels = item.value.map((o) => o.name);
      acc.labels?.push(...currentLabels);
      acc.labels = uniq(acc.labels);

      return acc;
    },
    { datasets: [], labels: [] },
  );

  return resultData;
});

const selectSummaryPerDay = createSelector(jobsLookupSelectors.getById, (job) => job?.summaryPerDay ?? []);

/**
 * This selector groups the data at 7 days intervals
 *
 */
const selectSummaryPerDayGrouped = createSelector(selectSummaryPerDay, (summaryPerDay) =>
  summaryPerDay.reduce<typeof summaryPerDay>((acc, item, index) => {
    if (index % 7 === 0 || index === summaryPerDay.length - 1) {
      acc.push(item);
    }
    return acc;
  }, []),
);

const selectSourcePerDayChartData = createSelector(selectSummaryPerDayGrouped, (summaryPerDay) => {
  /**
   * At this step we create Map.
   *
   * @example
   * {
   *  'Seek TEST': [0,2,1,0,2,3,4],
   *  'Idibu': [2,2,2,0,2,3,4],
   *  ...
   * }
   */
  const chartLineDatasetMap = summaryPerDay?.reduce((acc, { value }) => {
    value.forEach((analytic) => {
      const key = analytic.key;
      const existedValues = acc.get(key) ?? [];
      existedValues.push(analytic.value);
      acc.set(key, existedValues);
    });

    return acc;
  }, new Map<string, number[]>());

  /**
   * Create the chart dataset data
   *
   * @example
   * [
   *  {
   *    label: 'Seek TEST',
   *    data: [0,2,1,0,2,3,4]
   *  },
   *  {
   *    label: 'Idibu',
   *    data: [2,2,2,0,2,3,4]
   *  }
   * ]
   */
  const chartLinePropsDatasets = Array.from(
    chartLineDatasetMap ?? new Map<string, number[]>(),
    (item: [string, number[]]) => ({ data: item[1], label: item[0] }),
  ).filter((dataset) => dataset.label !== null);

  const labels =
    summaryPerDay?.map(({ key }) => format(parseISO(key), dateFormat.formatDateForLineChartView).toUpperCase()) ?? [];

  return {
    datasets: chartLinePropsDatasets.map((dataset, index) => ({
      ...dataset,
      borderColor: theme.chartColors.backgroundColor[index],
    })),
    labels,
  };
});
const selectStatus = createSelector(jobsLookupSelectors.getById, (job) => job?.status);
const selectReferenceNo = createSelector(jobsLookupSelectors.getById, (job) => job?.referenceNo);
const selectJobType = createSelector(jobsLookupSelectors.getById, (job) => job?.jobType);
const selectDaysToDeadline = createSelector(jobsLookupSelectors.getById, (job) => job?.daysToDeadline ?? 0);
const selectDaysOpen = createSelector(jobsLookupSelectors.getById, (job) => job?.daysOpen ?? 0);
const selectAcceptanceRate = createSelector(jobsLookupSelectors.getById, (job) => job?.acceptanceRate ?? 0);
const selectAverageTimeToHire = createSelector(jobsLookupSelectors.getById, (job) => job?.averageTimeToHire ?? 0);
const selectTeamMembers = createSelector(
  jobsLookupSelectors.getById,
  (job) =>
    job?.teamMembers?.map((teamMember) => ({
      ...teamMember,
      id: teamMember.userId,
    })) ?? [],
);

const filterMemberNotRemoved = (member) => !member.isRemoved;

const selectTeamMembersActive = createSelector(selectTeamMembers, (teamMembers) =>
  teamMembers.filter(filterMemberNotRemoved),
);

const selectTeamMembersNotAdmins = createSelector(selectTeamMembers, (teamMembers) =>
  teamMembers.filter(
    (member) =>
      member?.applicationRoles !== ApplicationRole.SuperAdmin && member?.applicationRoles !== ApplicationRole.Admin,
  ),
);

const selectJobLineManagers = createSelector(selectTeamMembers, (teamMembers) =>
  teamMembers.filter(filterLineManagers),
);

const selectJobLineManagersNotRemoved = createSelector(selectTeamMembers, (teamMembers) =>
  teamMembers.filter(filterLineManagersNotRemoved),
);

const selectTeamMembersForHiringTeamWidget = createSelector(selectTeamMembersNotAdmins, (teamMembers) =>
  teamMembers.filter(filterMemberNotRemoved),
);

const selectTeamMembersForComments = createSelector(
  selectTeamMembersActive,
  authSelectors.apiUser,
  (teamMembers, user) =>
    teamMembers
      .filter((teamMember) => teamMember.id !== user?.userId && teamMember.applicationRoles !== ApplicationRole.None)
      .map((member) => ({ ...member, display: member.name })),
);

const selectTeamMembersRemoved = createSelector(selectTeamMembers, (teamMembers) =>
  teamMembers.filter((member) => member.isRemoved),
);

const selectTeamMemberById = createSelector(
  (state: RootState, { jobId }: { jobId?: Job['id'] }) => selectTeamMembers(state, jobId),
  (_: RootState, { userId }: { userId: string }) => userId,
  (teamMembers: Job['teamMembers'] | undefined, userId: string) => {
    return teamMembers?.find((member) => member.userId === userId);
  },
);

const selectShowHiringTeam = createSelector(jobsLookupSelectors.getById, (job) => {
  if (job?.status) {
    return [JobStatus.open].includes(job?.status);
  }

  return false;
});

const selectListOnCareerPage = createSelector(jobsLookupSelectors.getById, (job) => job?.listOnCareerPage ?? false);

const selectTopKeywords = createSelector(jobsLookupSelectors.getById, (job) => job?.topKeywords ?? []);

const selectTopEmployers = createSelector(jobsLookupSelectors.getById, (job) => job?.topEmployers ?? []);

const selectTopEducations = createSelector(jobsLookupSelectors.getById, (job) => job?.topEducations ?? []);

const selectTopEmployersOptions = createSelector(selectTopEmployers, (employers) =>
  employers.map((item) => ({
    label: item.key,
    value: item.key,
  })),
);

const selectTopEducationsOptions = createSelector(selectTopEducations, (educations) =>
  educations.map((item) => ({
    label: item.key,
    value: item.key,
  })),
);

const selectIsJobStatusOpenByJobId = createSelector(selectStatus, (jobStatus) => jobStatus === JobStatus.open);

const selectIsJobStatusDraftByJobId = createSelector(selectStatus, (jobStatus) => jobStatus === JobStatus.draft);

const selectIsJobTypeEditable = createSelector(jobsLookupSelectors.getById, (job) => job?.isEditable);

const selectIsJobTypeEditDisabled = createSelector(selectIsJobTypeEditable, (isEditable) => !isEditable);

const selectCreatedById = createSelector(jobsLookupSelectors.getById, (job) => job?.createdById);

const selectIsQuestionsLocked = createSelector(jobsLookupSelectors.getById, (job) => job?.isQuestionsLocked);

export const jobsSelectors = {
  ...jobsLookupSelectors,
  candidateSourcesDataSelector,
  candidateSourcesChartDataSelector,
  candidateSourcesChartDoughnutDataSelector,
  selectTeamMembersForComments,
  checkJobStatus,
  getApplicants,
  getApplicantsByPipelineStageId,
  getApplicantsByStageType,
  jobsApplicationChartDataSelector,
  selectAcceptanceRate,
  selectAverageTimeToHire,
  selectDaysOpen,
  selectDaysToDeadline,
  selectStatus,
  selectReferenceNo,
  selectJobType,
  selectJobByApplicantId,
  selectShowHiringTeam,
  selectSourcePerDayChartData,
  selectTeamMemberById,
  selectTeamMembers,
  selectTeamMembersActive,
  selectTeamMembersRemoved,
  selectListOnCareerPage,
  selectTopKeywords,
  selectTopEmployers,
  selectTopEducations,
  selectTopEmployersOptions,
  selectTopEducationsOptions,
  selectIsJobStatusOpenByJobId,
  selectIsJobStatusDraftByJobId,
  selectTeamMembersNotAdmins,
  selectTeamMembersForHiringTeamWidget,
  selectIsJobTypeEditable,
  selectIsJobTypeEditDisabled,
  selectCreatedById,
  selectIsQuestionsLocked,
  selectJobLineManagers,
  selectJobLineManagersNotRemoved,
};
