import { AnyAction, nanoid } from '@reduxjs/toolkit';
import { put, race, take } from 'redux-saga/effects';

import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import * as fromModalsActions from './modals.actions';
import { ShowModal } from './modals.interfaces';
import { modalsSlice } from './modals.reducer';

function takeModalAction(id: string, actionType: string) {
  return take(function (action: AnyAction) {
    const type = action.type;
    const modalId = action?.payload?.id;
    return type === actionType && modalId === id;
  });
}
function takeModalCancelAction(id: string) {
  return takeModalAction(id, fromModalsActions.exModalCancelAction.type);
}
function takeModalConfirmAction(id: string) {
  return takeModalAction(id, fromModalsActions.exModalConfirmAction.type);
}

export function* modalSagaWorker(payload: ShowModal) {
  const modalType = payload.modalType;
  const id = payload.id || nanoid();
  const { modalConfig, ...rest } = payload;
  const { page, ...restConfig } = modalConfig || {};
  const modal = { ...rest, modalConfig: { ...restConfig } };
  yield put(fromModalsActions.exModalShowAction({ id, ...modal } as any));
  if (modalType === ModalsTypeKey.wizard) {
    yield put(fromModalsActions.updateWizardPage({ id, page: page! }));
  }
  const result = yield race({
    cancel: takeModalCancelAction(id),
    confirm: takeModalConfirmAction(id),
  });

  if (result.cancel) {
    yield put(modalsSlice.actions.closeModal({ ...result.cancel.payload }));
  }
  return result;
}
