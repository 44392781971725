import { updateJobAdStatusAction } from './job-ad.actions';
import jobAdSlice from './reducer';

export { jobAdSelectors } from './selectors';

export const jobAdActions = { ...jobAdSlice.actions, updateJobAdStatusAction };

export * from './models';

export default jobAdSlice.reducer;
