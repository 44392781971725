const jobInterviewTemplateBasePath = '/job/{jobId}/interview-template';

export const JobInterviewTemplate = {
  // GET
  GetJobInterviewTemplatesFunc: jobInterviewTemplateBasePath,
  // POST
  AssignInterviewTemplatesFunc: jobInterviewTemplateBasePath,
  // DELETE
  DeleteInterviewTemplatesFunc: jobInterviewTemplateBasePath,
} as const;
