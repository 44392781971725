/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useMemo, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { components, createFilter, OptionTypeBase, PlaceholderProps } from 'react-select';
import { Props } from 'react-select/async';

import { FormSelectProps } from 'components/FormSelect/FormSelectProps';
import { FormTagSelect } from 'components/FormTagSelect';

const FormSelectInner = <T extends OptionTypeBase & { isFixed?: boolean }, IsMulti extends boolean = false>(
  {
    name,
    className,
    options,
    maxMenuHeight = 150,
    onChange,
    createFilterOuter,
    loadOptions,
    rules,
    overrideStyles,
    isMulti,
    noRegister,
    isCreatable = false,
    placeholder = null,
    tooltipOffset,
    components: componentsProp,
    clearWhenNotInOptions = false,
    ...rest
  }: FormSelectProps<T, IsMulti>,
  ref,
) => {
  const methods = useFormContext();
  const validated = methods?.formState.isSubmitted;
  const errors = methods?.errors?.[name];
  const innerRef = useRef<any>();
  const getOptionValue = useMemo<(item: T) => string | number>(
    () => (typeof rest.getOptionValue === 'function' ? rest.getOptionValue : (item: T) => item.value ?? null),
    [rest.getOptionValue],
  );

  const findDefaultValue = useCallback(
    (value: string | number) => (option: T) => getOptionValue(option) === value,
    [getOptionValue],
  );

  const select = useCallback(
    ({ onChange: onChangeHandler, value }) => {
      if (clearWhenNotInOptions && value && !options?.find((item) => getOptionValue(item) === value)) {
        onChangeHandler({});
      }

      const defaultValue =
        Array.isArray(value) && isMulti
          ? (options as T[])?.filter((o) => value.includes(getOptionValue(o)))
          : (options as T[])?.find(findDefaultValue(value));

      const formTagSelectValue = isCreatable ? value : defaultValue ?? rest.defaultValue ?? null;

      const onChangeSelect: Props<T, IsMulti>['onChange'] = (selected, actionMeta) => {
        switch (actionMeta.action) {
          case 'remove-value':
          case 'pop-value':
            if (actionMeta.removedValue?.isFixed) {
              return;
            }
            break;
          case 'clear':
            selected = Array.isArray(defaultValue)
              ? defaultValue.filter((v) => v.isFixed)
              : options.filter((v: T) => v.isFixed);
            break;
          case 'create-option':
            onChangeHandler(selected);
            return;
        }
        const selectedValue =
          !isCreatable && isMulti && Array.isArray(selected) ? selected.map(getOptionValue) : selected;
        onChangeHandler(selectedValue);
      };

      return (
        <FormTagSelect
          className={className}
          name={name}
          errors={errors}
          validated={validated}
          maxMenuHeight={maxMenuHeight}
          options={options}
          isMulti={isMulti}
          components={{
            Placeholder: (props: PlaceholderProps<T, IsMulti>) => (
              <components.Placeholder {...props}>{placeholder}</components.Placeholder>
            ),
            IndicatorSeparator: null,
            ...componentsProp,
          }}
          ref={(e) => {
            if (ref instanceof Function) {
              ref(e);
            } else if (ref && 'current' in ref) {
              ref.current = e;
            }
            innerRef.current = e;
          }}
          onChange={onChangeSelect}
          filterOption={createFilterOuter ?? createFilter(null)}
          loadOptions={loadOptions ?? (() => Promise.resolve(options))}
          defaultOptions={options}
          overrideStyles={overrideStyles}
          isCreatable={isCreatable}
          {...rest}
          value={formTagSelectValue}
          tooltipOffset={tooltipOffset}
        />
      );
    },
    [
      className,
      clearWhenNotInOptions,
      componentsProp,
      createFilterOuter,
      errors,
      findDefaultValue,
      getOptionValue,
      isCreatable,
      isMulti,
      loadOptions,
      maxMenuHeight,
      name,
      options,
      overrideStyles,
      placeholder,
      ref,
      rest,
      tooltipOffset,
      validated,
    ],
  );

  return methods && !noRegister ? (
    <Controller
      name={name}
      onBlurName="blur"
      onFocus={() => innerRef?.current?.focus()}
      rules={rules}
      defaultValue={rest.defaultValue}
      render={({ onChange: innerChange, value }) => {
        return select({
          value: value ?? rest.defaultValue ?? rest.value,
          onChange:
            onChange ??
            ((selected: T) => {
              return isMulti ? innerChange(selected) : innerChange(getOptionValue(selected));
            }),
        });
      }}
    />
  ) : (
    select({ onChange, value: rest.value })
  );
};

export const FormSelect = React.forwardRef(FormSelectInner) as <
  T extends OptionTypeBase,
  IsMulti extends boolean = false,
>(
  props: FormSelectProps<T, IsMulti> & { ref?: React.ForwardedRef<Props<T, IsMulti>> },
) => ReturnType<typeof FormSelectInner>;
