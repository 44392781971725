import { ExRoutes } from 'router/routes';
import { useAppRouterParams } from 'router/useAppRouterParams';

import { rbacConstants } from 'containers/Auth/AuthMatrix';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';
import { ApplicantInfoSectionProps } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalV4Props';
import { useApplicantCounter } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/hook/useApplicantCounter';
import { applicantModalSlice } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/store';
import { modalsActions } from 'containers/Modals/store';

import { MenuItem as MenuItemActionButton } from 'components/ActionsDropdown';
import { getUserInitials } from 'utils/getUserInitials';
import { useDateDefaultFormatFunction } from 'utils/hooks/dateTime';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useNavigation } from 'utils/hooks/useNavigation';

import { applicantsActions, applicantSelectors } from 'store/entities/applicants';
import { useSelector } from 'store/rootSelectors';

export const useApplicantInfoSectionState = ({ className }: ApplicantInfoSectionProps) => {
  const dispatch = useAppDispatch();
  const { push } = useNavigation();

  const { applicantId, talentPoolId } = useAppRouterParams();

  const applicant = useSelector((state) => applicantSelectors.selectApplicantModalData(state, applicantId));

  const candidatePageUrl = talentPoolId
    ? ExRoutes.talentPoolCandidatePage({ candidateId: applicant?.candidateId, talentPoolId })
    : ExRoutes.candidatePage({ candidateId: applicant?.candidateId });

  const showApplicantModalCounter = useApplicantCounter();

  const userInitials = getUserInitials(`${applicant?.fullName || ''}`);

  const { formatDateToDefault } = useDateDefaultFormatFunction();

  const onClickEditDetailsHandler = () => dispatch(applicantModalSlice.actions.toggleEdit());

  const onClickDoNotHireHandler = () =>
    dispatch(
      applicantsActions.applicantsModalUpdatePatch({
        id: applicantId!,
        doNotHire: !applicant?.doNotHire,
        updateList: true,
      }),
    );

  const moreOptionsMenuItems: MenuItemActionButton[] = [
    {
      action: () => dispatch(modalsActions.showModal({ modalType: ModalsTypeKey.addToTalentPool })),
      feature: rbacConstants.applicants.addToTalentPool,
      label: 'Add to Talent Pool',
    },
    {
      action: onClickEditDetailsHandler,
      label: 'Edit Applicant details',
    },
    {
      action: onClickDoNotHireHandler,
      label: applicant?.doNotHire ? 'Remove Do Not Hire' : 'Do Not Hire',
    },
    {
      action: () => {
        push({ path: candidatePageUrl });
      },
      label: 'View Full Profile',
      href: candidatePageUrl,
    },
  ];

  return {
    className,
    moreOptionsMenuItems,
    applicant,
    formatDateToDefault,
    userInitials,
    showApplicantModalCounter,
  } as const;
};
