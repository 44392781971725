/* eslint-disable sonarjs/no-duplicate-string */
import { createApiMethod } from 'api-endpoints/utils';

import {
  EmailTemplateCreateResponseDTO,
  EmailTemplateCreateUrlParams,
  EmailTemplateDeleteResponseDTO,
  EmailTemplateGetResponseDTO,
  EmailTemplateRequestUrlParams,
  EmailTemplateSearchRequestParams,
  EmailTemplateSearchResponseDTO,
  EmailTemplateUpdateResponseDTO,
  EmailTemplateUpdateUrlParams,
} from 'pages/Company/CompanyTabs/CompanyEmailTemplateTab/state';

const CompanyTemplatesEndpoints = {
  SearchTemplateFunc: '/api/company/{companyId}/template/email',
  CreateTemplateFunc: '/api/company/{companyId}/template/email',
  GetTemplateFunc: '/api/company/{companyId}/template/email/{templateId}',
  UpdateTemplateFunc: '/api/company/{companyId}/template/email/{templateId}',
  DeleteTemplateFunc: '/api/company/{companyId}/template/email/{templateId}',
};

export const searchTemplateFuncApiCall = createApiMethod<
  EmailTemplateSearchRequestParams,
  EmailTemplateSearchResponseDTO
>({
  url: CompanyTemplatesEndpoints.SearchTemplateFunc,
});
export const createTemplateFuncApiCall = createApiMethod<EmailTemplateCreateUrlParams, EmailTemplateCreateResponseDTO>({
  url: CompanyTemplatesEndpoints.CreateTemplateFunc,
  method: 'POST',
});
export const getTemplateFuncApiCall = createApiMethod<EmailTemplateRequestUrlParams, EmailTemplateGetResponseDTO>({
  url: CompanyTemplatesEndpoints.GetTemplateFunc,
});
export const updateTemplateFuncApiCall = createApiMethod<EmailTemplateUpdateUrlParams, EmailTemplateUpdateResponseDTO>({
  url: CompanyTemplatesEndpoints.UpdateTemplateFunc,
  method: 'PUT',
});
export const deleteTemplateFuncApiCall = createApiMethod<EmailTemplateRequestUrlParams, EmailTemplateDeleteResponseDTO>(
  {
    url: CompanyTemplatesEndpoints.DeleteTemplateFunc,
    method: 'DELETE',
  },
);
