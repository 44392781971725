import { createSelector } from '@reduxjs/toolkit';

import { candidateWorkExperiencesAdapter } from 'store/entities/candidate-work-experience/candidate-work-experience.reducer';
import { entitiesDomain } from 'store/rootSelectors';

const domain = createSelector(entitiesDomain, (state) => state.candidateWorkExperiences);

const adapterSelectors = candidateWorkExperiencesAdapter.getSelectors(domain);

export const candidateWorkExperienceSelectors = {
  ...adapterSelectors,
  selectIsCandidateWorkExperiencesListEmpty: createSelector(adapterSelectors.selectTotal, (total) => total === 0),
};
