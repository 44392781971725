const baseInterviewUrl = '/interview/{interviewId}';

export const InterviewEndpoints = {
  // POST
  CreateInterviewFunc: '/interview',
  // GET
  GetInterviewFunc: baseInterviewUrl,
  // PUT
  UpdateInterviewFunc: baseInterviewUrl,
  // DELETE
  DeleteInterviewFunc: baseInterviewUrl,
  // POST
  SearchInterviewsFunc: '/interview/search',
} as const;
