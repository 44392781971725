import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import {
  addMonths,
  endOfMonth,
  format,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfToday,
  startOfYesterday,
  sub,
  subMonths,
} from 'date-fns';

import { useCalendar } from 'components/DateRangePicker/hooks/useCalendar';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { ExVisible } from 'components/ui/ExVisible';
import { consoleWarnForDevEnv } from 'utils/consoleErrorForDevEnv';
import { useDateDefaultFormatFunction } from 'utils/hooks/dateTime';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';

import {
  BtnWrapper,
  Calendar,
  CalendarTable,
  DateRangePicker as DateRangePickerStyled,
  DateRangePickerContentWrapper,
  Next,
  Prev,
  Ranges,
  SelectedDate,
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from './DateRangePickerComponents';
import DateRangePickerInput from './DateRangePickerInput';
import { CalendarDay, DateRangePickerProps } from './DateRangePickerProps';

function useDateRangePickerMainComponentState({
  isOpen,
  range,
  onApply,
  onCancel,
  toggleRef,
  onClear,
  onFocus,
  onBlur,
  clearable,
  isRange = true,
  value,
  min,
  isDisabled,
  validated,
  errors,
  className,
  placeholder,
}: DateRangePickerProps) {
  const now = startOfToday();
  const isValid = useMemo(() => validated && !errors, [errors, validated]);
  const isInvalid = useMemo(() => validated && !!errors, [errors, validated]);
  /* eslint-disable sort-keys */
  const ranges = {
    Today: [now, now],
    Yesterday: [startOfYesterday(), startOfYesterday()],
    'Last 7 Days': [sub(now, { days: 6 }), now],
    'Last 30 Days': [sub(now, { days: 29 }), now],
    'This Month': [startOfMonth(now), endOfMonth(now)],
    'Last Month': [startOfMonth(sub(now, { months: 1 })), endOfMonth(sub(now, { months: 1 }))],
  };
  /* eslint-enable sort-keys */
  const ref = useRef<HTMLDivElement | null>(null);
  const refInput = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [custom, setCustom] = useState(!isRange);
  const [selectedMonth, setSelectedMonth] = useState(() => min ?? range?.[0] ?? value ?? now);
  const [startDate, setStartDate] = useState<Date | null>(() => range?.[0] ?? value ?? null);
  const [endDate, setEndDate] = useState<Date | null>(() => range?.[0] ?? value ?? null);
  const [changeStartDate, setChangeStartDate] = useState(true);
  const { calendar, setMonth, setEnd, setStart, onHoverDay } = useCalendar(isRange);
  const {
    calendar: calendarRight,
    month: monthRight,
    setMonth: setMonthRight,
    setEnd: setEndRight,
    setStart: setStartRight,
    onHoverDay: onHoverDayRight,
  } = useCalendar(true);
  const weekDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
  const { formatDateToDefault } = useDateDefaultFormatFunction();
  const isApplyButtonDisabled = [!startDate, !endDate].some(Boolean);

  const isDateLessMin = (date: Date, minDate = min) => {
    return !!(date && minDate && date < minDate && !isSameDay(date, minDate));
  };

  const getCorrectedDate = (date: Date, currentMonth: Date) => {
    if (!isSameMonth(date, currentMonth) || isDateLessMin(date)) {
      return min ?? range?.[0] ?? value ?? now;
    }

    return date;
  };

  const updateStartEndDates = useCallback(
    (date: Date) => {
      if (changeStartDate) {
        setStartDate(date);
        if (!isRange) {
          setEndDate(date);
        } else {
          setEndDate(null);
          setChangeStartDate(false);
        }
      } else {
        if (startDate && startDate <= date) {
          setEndDate(date);
          setChangeStartDate(true);
        } else {
          setStartDate(date);
        }
      }
    },
    [changeStartDate, isRange, startDate],
  );

  const isActiveRange = (dates: Date[]) => {
    if (startDate && endDate) {
      return isSameDay(startDate, dates[0]) && isSameDay(endDate, dates[1]) ? 'active' : '';
    }
    return '';
  };

  const prepareClassNames = (day: CalendarDay): string => {
    const classes: string[] = [];
    if (day.isStartDate) {
      classes.push('start-date');
      classes.push('selected');
    }
    if (day.isEndDate) {
      classes.push('end-date');
      classes.push('selected');
    }
    if (day.isWithinInterval) {
      classes.push('in-range');
    }
    if (!day.isSelectedMonth) {
      classes.push('off');
    }
    if (min && isDateLessMin(day.date)) {
      classes.push('off');
    }
    return classes.join(' ');
  };

  const formatDate = () => (range ?? [value]).map((v) => v && formatDateToDefault(v)).filter(Boolean) as string[];

  const changeMonth = (direction: 'forward' | 'backward', amount = 1) => {
    const date = selectedMonth;
    const modifier = direction === 'forward' ? addMonths : subMonths;

    const newMonth = modifier(date, amount);
    setSelectedMonth(newMonth);

    if (!isRange) {
      const newStartDate = modifier(startDate as Date, amount);
      const correctedDate = getCorrectedDate(newStartDate, newMonth);
      updateStartEndDates(correctedDate);
    }
  };

  const handlerClickDate = ({ date, isSelectedMonth }: CalendarDay) => {
    if (!isSelectedMonth || isDateLessMin(date)) {
      return;
    }

    updateStartEndDates(date);

    if (date && changeStartDate && !isRange) {
      onApply([date, date]);
      handlerClickCancel();
    }
  };

  const handlerClickApply = () => {
    onApply([startDate, endDate]);
    handlerClickCancel();
  };

  const handlerClickCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel();
    setOpen(false);
  }, [onCancel]);

  const handlerClickSelectRange = (clickValues: Date[]) => {
    setStartDate(clickValues[0]);
    setEndDate(clickValues[1]);
    setCustom(false);
    onApply(clickValues);
    handlerClickCancel();
  };
  const handlerMouseOver = (e: CalendarDay) => {
    if (startDate && !endDate) {
      onHoverDay(e.date);
    }
  };
  const handlerMouseOverRight = (e: CalendarDay) => {
    if (startDate && !endDate) {
      onHoverDayRight(e.date);
      onHoverDay(endOfMonth(e.date));
    }
  };
  const handlerMouseLeaveRight = (_e: CalendarDay) => {
    if (!endDate) {
      onHoverDayRight();
    }
  };

  const refs = useMemo(() => {
    const result = [ref, refInput];
    if (typeof isOpen === 'boolean' && typeof onCancel === 'function' && !toggleRef) {
      consoleWarnForDevEnv('You provided isOpen and onCancel in this case preferably provide toggleRef');
    }
    if (typeof toggleRef?.current?.contains === 'function') {
      result.push(toggleRef as typeof result[number]);
    }
    return result;
  }, [ref, refInput, toggleRef?.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useOnClickOutside(refs, handlerClickCancel);

  useEffect(() => {
    setStart(startDate);
    setStartRight(startDate);
  }, [setStart, setStartRight, startDate]);

  useEffect(() => {
    setEnd(endDate);
    setEndRight(endDate);
  }, [endDate, setEnd, setEndRight]);

  useEffect(() => {
    setMonth(selectedMonth);
    setMonthRight(addMonths(selectedMonth, 1));
  }, [selectedMonth, setMonth, setMonthRight]);

  useEffect(() => {
    setOpen(!!isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (range?.[0]) {
      setSelectedMonth(range?.[0]);
    }
  }, [range]);

  const [styles, setStyles] = useState<CSSProperties>({});

  const startValue = useMemo(() => range?.[0] ?? value ?? now, [range, value, now]);

  useEffect(() => {
    // set datepicker to current value
    if (startValue && !startDate && !isRange) {
      updateStartEndDates(startValue);
    }
  }, [startValue, startDate, isRange, updateStartEndDates]);

  useEffect(() => {
    // reset unapplied values to current value
    if (!isRange && !open && startValue && startDate && !isSameDay(startValue, startDate)) {
      updateStartEndDates(startValue);
    }

    if (!open && startValue && selectedMonth && !isSameMonth(startValue, selectedMonth)) {
      setSelectedMonth(startValue);
    }
  }, [isRange, open, startValue, startDate, selectedMonth, updateStartEndDates]);

  const openToggle: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (isDisabled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    const rect = e.currentTarget.getBoundingClientRect();
    const translateByX = rect.top + rect.height + window.scrollY;
    setStyles({
      left: rect.x + rect.width,
      top: 0,
      transform: `translate(-100%,${translateByX}px)`,
    });

    setOpen(!open);

    if (!open) {
      if (onFocus instanceof Function) {
        onFocus();
      }
    } else {
      if (onBlur instanceof Function) {
        onBlur();
      }
    }
  };

  const handleOnClear = () => {
    setOpen(false);
    setStartDate(null);
    setEndDate(null);
    setSelectedMonth(now);
    onClear && onClear();
  };

  const isApplyCancelButtonsVisible = useMemo(() => isRange, [isRange]);
  const isBtnWrapperVisible = useMemo(
    () => !!(startDate && endDate) || isApplyCancelButtonsVisible,
    [startDate, endDate, isApplyCancelButtonsVisible],
  );

  return {
    refInput,
    openToggle,
    isValid,
    handleOnClear,
    clearable,
    open,
    isDisabled,
    isRange,
    isInvalid,
    custom,
    formatDate,
    ranges,
    isActiveRange,
    ref,
    styles,
    handlerClickSelectRange,
    setCustom,
    changeMonth,
    selectedMonth,
    prepareClassNames,
    monthRight,
    handlerClickDate,
    startDate,
    endDate,
    formatDateToDefault,
    handlerClickCancel,
    isApplyButtonDisabled,
    weekDays,
    calendarRight,
    handlerMouseOverRight,
    handlerMouseLeaveRight,
    calendar,
    handlerMouseOver,
    handlerClickApply,
    className,
    isApplyCancelButtonsVisible,
    isBtnWrapperVisible,
    placeholder,
  } as const;
}

const DateRangePickerMainComponent = React.forwardRef(
  (props: DateRangePickerProps, componentInputRef: React.ForwardedRef<HTMLDivElement>) => {
    const {
      refInput,
      openToggle,
      isValid,
      handleOnClear,
      clearable,
      open,
      isDisabled,
      isRange,
      isInvalid,
      custom,
      formatDate,
      ranges,
      isActiveRange,
      ref,
      styles,
      handlerClickSelectRange,
      setCustom,
      changeMonth,
      selectedMonth,
      prepareClassNames,
      monthRight,
      handlerClickDate,
      startDate,
      endDate,
      formatDateToDefault,
      handlerClickCancel,
      isApplyButtonDisabled,
      weekDays,
      calendarRight,
      handlerMouseOverRight,
      handlerMouseLeaveRight,
      calendar,
      handlerMouseOver,
      handlerClickApply,
      className,
      isApplyCancelButtonsVisible,
      isBtnWrapperVisible,
      placeholder,
    } = useDateRangePickerMainComponentState(props);

    const attachRef = (el: HTMLDivElement) => {
      refInput.current = el;

      if (typeof componentInputRef === 'function') {
        componentInputRef(el);
      } else if (componentInputRef) {
        componentInputRef.current = el;
      }
    };

    return (
      <>
        <DateRangePickerInput
          ref={attachRef}
          onClick={openToggle}
          value={formatDate().join(' - ')}
          onClear={handleOnClear}
          clearable={clearable}
          isDisabled={isDisabled}
          isValid={isValid}
          isInvalid={isInvalid}
          className={className}
          placeholder={placeholder}
        />
        {createPortal(
          <DateRangePickerStyled isOpen={open} ref={ref} style={styles}>
            <DateRangePickerContentWrapper>
              {isRange && (
                <Ranges>
                  <ul className={custom ? 'mt-2' : ''}>
                    {Object.entries(ranges).map(([key, dates]) => (
                      <li className={isActiveRange(dates)} key={key} onClick={() => handlerClickSelectRange(dates)}>
                        {key}
                      </li>
                    ))}
                    <li onClick={() => setCustom(true)}>Custom</li>
                  </ul>
                </Ranges>
              )}
              <Calendar isOpen={custom} left={isRange} single={!isRange}>
                <CalendarTable left={isRange}>
                  <Table>
                    <THead>
                      <Tr>
                        <Th className="clickable" onClick={() => changeMonth('backward')}>
                          <Prev />
                        </Th>
                        <Th colSpan={isRange ? 4 : 2}>{format(selectedMonth, 'MMMM')}</Th>
                        {!isRange && (
                          <Th className="clickable" onClick={() => changeMonth('forward')}>
                            <Next />
                          </Th>
                        )}
                        <Th className="clickable" onClick={() => changeMonth('backward', 12)}>
                          <Prev />
                        </Th>
                        <Th>{format(selectedMonth, 'yyyy')}</Th>
                        <Th className="clickable" onClick={() => changeMonth('forward', 12)}>
                          <Next />
                        </Th>
                      </Tr>
                      <Tr>
                        {weekDays.map((n, key) => (
                          <Th key={key}>{n}</Th>
                        ))}
                      </Tr>
                    </THead>
                    <TBody>
                      {calendar.map((week, weekIndex) => (
                        <Tr key={weekIndex}>
                          {week.map((day, dayIndex) => (
                            <Td
                              className={prepareClassNames(day)}
                              key={dayIndex}
                              onClick={() => handlerClickDate(day)}
                              onMouseOver={() => handlerMouseOver(day)}
                            >
                              {day.text}
                            </Td>
                          ))}
                        </Tr>
                      ))}
                    </TBody>
                  </Table>
                </CalendarTable>
              </Calendar>
              {isRange && (
                <Calendar isOpen={custom} right>
                  <CalendarTable>
                    <Table>
                      <THead>
                        <Tr>
                          <Th colSpan={6}>{format(monthRight, 'MMMM')}</Th>
                          <Th className="clickable" onClick={() => changeMonth('forward')}>
                            <Next />
                          </Th>
                        </Tr>
                        <Tr>
                          {weekDays.map((n, key) => (
                            <Th key={key}>{n}</Th>
                          ))}
                        </Tr>
                      </THead>
                      <TBody>
                        {calendarRight.map((week, weekIndex) => (
                          <Tr key={weekIndex}>
                            {week.map((day, dayIndex) => (
                              <Td
                                className={prepareClassNames(day)}
                                key={dayIndex}
                                onClick={() => handlerClickDate(day)}
                                onMouseOver={() => handlerMouseOverRight(day)}
                                onMouseLeave={() => handlerMouseLeaveRight(day)}
                              >
                                {day.text}
                              </Td>
                            ))}
                          </Tr>
                        ))}
                      </TBody>
                    </Table>
                  </CalendarTable>
                </Calendar>
              )}
            </DateRangePickerContentWrapper>
            {custom ? (
              <ExVisible visible={isBtnWrapperVisible}>
                <BtnWrapper isApplyCancelButtonsVisible={isApplyCancelButtonsVisible}>
                  {startDate && endDate && (
                    <SelectedDate>
                      {formatDateToDefault(startDate)}
                      {isRange && (
                        <>
                          {' - '}
                          {formatDateToDefault(endDate)}
                        </>
                      )}
                    </SelectedDate>
                  )}
                  <ExVisible visible={isApplyCancelButtonsVisible}>
                    <ExButton variant="light" size="sm" onClick={handlerClickCancel}>
                      Cancel
                    </ExButton>
                    <ExButton size="sm" onClick={handlerClickApply} disabled={isApplyButtonDisabled}>
                      Apply
                    </ExButton>
                  </ExVisible>
                </BtnWrapper>
              </ExVisible>
            ) : null}
          </DateRangePickerStyled>,
          document.body,
        )}
      </>
    );
  },
);

export const DateRangePicker = styled(DateRangePickerMainComponent)``;
