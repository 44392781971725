import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';

import { companyScreeningQuestionListUiSlice } from './company-screening-question-list.reducer';

const domain = (state: RootState) => state.ui.companyUi[companyScreeningQuestionListUiSlice.name];

const filters = createSelector(domain, (state) => state.filters);

const searchScreeningQuestionParams = createSelector(filters, ({ pageNo, searchTerm, orderBy }) => {
  return {
    ...(pageNo && { pageNo }),
    ...(searchTerm && { searchTerm }),
    ...(orderBy && { orderBy }),
  };
});

export const companyScreeningQuestionListUiSelectors = {
  filters,
  searchScreeningQuestionParams,
};
