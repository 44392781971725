type InputKeyboardEvent = React.KeyboardEvent<HTMLInputElement>;
type UseInputEventHandlerCallback = (e: InputKeyboardEvent) => void;
type UseInputEventHandlerCallbackProps = (callback: Function) => UseInputEventHandlerCallback;

/**
 * Hook for handling enter key press on input events.
 *
 * @param {Function} callback
 * @returns {UseInputEventHandlerCallback}
 */
const useInputEventHandler: UseInputEventHandlerCallbackProps = (
  callback: Function,
): ReturnType<UseInputEventHandlerCallbackProps> => {
  return (e: InputKeyboardEvent) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      callback();
    }
  };
};

export { useInputEventHandler };
