import React from 'react';
import styled from 'styled-components';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { rbacConstants } from 'containers/Auth/AuthMatrix';
import { JobBoardActionButtonProps } from 'containers/JobBoardLists/JobBoardListProps';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';
import { modalsActions } from 'containers/Modals/store';

import { MenuItem } from 'components/ActionsDropdown';
import { ExActionButton } from 'components/ui/ExActionButton';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useModal } from 'utils/hooks/useModal';

import { jobBoardActions } from 'store/entities/job-boards';

const JobBoardActionButton: React.FC<JobBoardActionButtonProps> = ({ jobBoard, className }) => {
  const dispatch = useAppDispatch();
  const modal = useModal({
    initialOpen: false,
  });
  const menuItems: MenuItem[] = [
    {
      label: 'Unsubscribe',
      action: async () => {
        const result = await modal.show({
          title: 'Confirmation',
          message: `Are you sure you want to unsubscribe from ${jobBoard.name}?`,
          withActions: true,
          withTitle: true,
          buttonOk: 'Continue',
          buttonOkVariant: 'primary',
        });
        if (result) {
          const success = dispatch(jobBoardActions.unsubscribeSettings({ jobBoardId: jobBoard.jobBoardId }));
          if (success) {
            const message = `You successfully unsubscribed from ${jobBoard.name}`;
            dispatch(alertsEffects.showSuccess({ message }));
          }
        }
      },
      feature: rbacConstants.jobBoards.unsubscribe,
    },
    ...(jobBoard.isEditable
      ? [
          {
            label: 'Edit settings',
            action: async () => {
              dispatch(modalsActions.setJobBoardId({ jobBoardId: jobBoard.jobBoardId }));
              dispatch(modalsActions.showModal({ modalType: ModalsTypeKey.editJobBoardSettings }));
            },
            feature: rbacConstants.jobBoards.edit,
          },
        ]
      : []),
  ];

  return (
    <ExActionButton
      buttonId="jobBoardActionDropdown"
      menuItems={menuItems}
      dropDirection="down"
      className={className}
    />
  );
};

export default styled(JobBoardActionButton)``;
