import React from 'react';
import styled from 'styled-components/macro';

import { ApplicantModalSwitchMode } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalSwitchMode';

import { ApplicantModalAvailabilityInfoEdit } from './ApplicantModalAvailabilityInfoEdit';
import { ApplicantModalAvailabilityInfoView } from './ApplicantModalAvailabilityInfoView';

type ApplicantModalAvailabilityInfoProps = {
  className?: string;
};

const ApplicantModalAvailabilityInfoStyled = styled(ApplicantModalSwitchMode)``;

const useApplicantModalAvailabilityInfoState = ({ className }: ApplicantModalAvailabilityInfoProps) => {
  return {
    className,
  } as const;
};

export const ApplicantModalAvailabilityInfo: React.FC<ApplicantModalAvailabilityInfoProps> = (props) => {
  const { className } = useApplicantModalAvailabilityInfoState(props);

  return (
    <ApplicantModalAvailabilityInfoStyled
      className={className}
      viewComponent={<ApplicantModalAvailabilityInfoView />}
      editComponent={<ApplicantModalAvailabilityInfoEdit />}
    />
  );
};
