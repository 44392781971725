import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';

import { modalsAdapter, modalsSlice } from './modals.reducer';

const { selectAll, selectById } = modalsAdapter.getSelectors((state: RootState) => state[modalsSlice.name]);

export const modalResultById = createSelector(selectById, (modal) => modal?.modalResult);

export const modalWizardPageById = createSelector(selectById, (modal) => modal?.modalConfig?.page);

export const modalById = selectById;

export const openedModals = createSelector(selectAll, (modals) => modals.filter((modal) => modal.isOpen));

export const modalWizardTypeById = createSelector(modalById, (modalState) => modalState?.modalConfig?.wizardType);
