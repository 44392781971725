import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { IsEntity } from 'model/utils';

import { isFunction } from 'utils/funcs';
import { useNavigation } from 'utils/hooks/useNavigation';

export type UseModalPathProps<T> = {
  nameClickPath?: (entity?: UseModalPathProps<T>['entity']) => string;
  entity?: T;
  preventSelect?: boolean;
  preventNavigation?: boolean;
  handleSelect?: React.MouseEventHandler<HTMLDivElement>;
};

/**
 * Hook return handlers and path for modal
 * @param nameClickPath
 * @param entity
 * @param preventSelect
 * @param handleSelect
 * @param preventNavigation
 *
 * @return {
 *       handleClick,
 *       handleClickTick,
 *       modalPath,
 * }
 *  Return handlers, and modal path
 */
export function useModalPath<T extends object & { id?: string }>({
  nameClickPath,
  entity,
  preventSelect,
  preventNavigation,
  handleSelect,
}: UseModalPathProps<IsEntity<T>>) {
  const { url } = useRouteMatch();
  const { push } = useNavigation();

  const modalPath = useMemo(() => {
    if (nameClickPath && isFunction(nameClickPath)) {
      return nameClickPath(entity);
    }
    return [url, entity?.id].join('/');
  }, [entity, nameClickPath, url]);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (preventNavigation) {
      return;
    }
    push({ path: modalPath });
  };

  const handleClickTick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (preventSelect) {
      return;
    }
    if (handleSelect && isFunction(handleSelect)) {
      handleSelect(e);
    }
  };

  return {
    handleClick,
    handleClickTick,
    modalPath,
  };
}
