import { useCallback, useState } from 'react';

export function useListFiltersModalOpenState() {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const handlerClickFilters = useCallback(() => setIsFiltersOpen(true), []);
  const handlerToggleFilters = useCallback(() => setIsFiltersOpen(!isFiltersOpen), [isFiltersOpen]);
  const onSubmit = useCallback(() => {
    setIsFiltersOpen(!isFiltersOpen);
  }, [isFiltersOpen]);
  return {
    handlerClickFilters,
    handlerToggleFilters,
    onSubmit,
    isFiltersOpen,
  };
}
