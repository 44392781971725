import { pluralize } from 'utils/pluralize';

export type ApplicantModalCountInfoProps = {
  inListPosition: number;
  totalApplicantsCount: number;
};

export const getApplicantModalCountInfo = (
  { inListPosition, totalApplicantsCount }: ApplicantModalCountInfoProps = {
    inListPosition: 0,
    totalApplicantsCount: 0,
  },
) => {
  return `${inListPosition}/${totalApplicantsCount} ${pluralize('Applicant', totalApplicantsCount)}`;
};
