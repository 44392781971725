import React, { useContext } from 'react';
import BaseSelect, { components, OptionTypeBase, Props, StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async';
import styled, { ThemeContext } from 'styled-components/macro';

import { LoadingIndicator } from 'components';
import { useAppSelectStyles } from 'components/FormTagSelect/FormTagSelectStyles';

const FormSelectMultiCheckboxStyled = styled.div``;

const ValueStyled = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  color: ${({ theme }) => theme.colors.primary};
  padding: 5px;
  margin: 4px;
  font-size: 12px;
  display: flex;
  line-height: 1;
  overflow: hidden;
  font-weight: 700;
  white-space: nowrap;
`;

const OptionLabel = styled.label`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OptionCheckbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 5px;
`;

const OptionStyled = styled(components.Option)`
  && {
    display: flex;
    align-items: center;
    padding: 3px 8px;
  }
`;

const ValuesCountStyled = styled.div`
  white-space: nowrap;
`;

const ValueContainerWithValues: React.FC<any> = ({ children, ...props }) => {
  const { selectProps, hasValue } = props;
  const { value, getOptionLabel } = selectProps;

  const firstValue = hasValue ? value[0] : null;
  const totalCount = hasValue ? value.length : 0;
  const otherValuesCount = totalCount - 1;

  const showValue = hasValue && firstValue;
  const showOtherValuesCount = otherValuesCount >= 1;

  const Placeholder = children.find((item) => {
    if (!item) {
      return false;
    }
    return item.key === 'placeholder';
  });
  const Input = children[children.length - 1];

  return (
    <components.ValueContainer {...props}>
      {showValue && <ValueStyled>{getOptionLabel(firstValue)}</ValueStyled>}
      {showOtherValuesCount && <ValuesCountStyled>+{otherValuesCount} more</ValuesCountStyled>}
      {Placeholder}
      {Input}
    </components.ValueContainer>
  );
};

const Option = (props) => {
  const onChange = () => {};

  return (
    <OptionStyled {...props}>
      <OptionCheckbox checked={props.isSelected} onChange={onChange} />
      <OptionLabel>{props.label}</OptionLabel>
    </OptionStyled>
  );
};

export type FormSelectMultiCheckboxProps<Option> = Props<Option, true> & {
  className?: string;
};

const useFormSelectMultiCheckboxState = <Option extends OptionTypeBase>({
  className,
  ...rest
}: FormSelectMultiCheckboxProps<Option>) => {
  const theme = useContext(ThemeContext);
  const appSharedStyles = useAppSelectStyles(['control', 'menuList', 'option', 'multiValueRemove']);

  const componentStyles: StylesConfig<OptionTypeBase, boolean> = {
    control: (base, props) => ({
      ...base,
      ...(appSharedStyles.control && appSharedStyles.control(base, props)),
      boxShadow: 'none',
      '&:hover': {},
    }),
    valueContainer: (base) => ({
      ...base,
      flexWrap: 'nowrap',
    }),
    option: (base, props) => ({
      ...base,
      ...(props.isSelected && {
        backgroundColor: `${theme.colors.primaryLight}`,
        color: `${theme.colors.primary}`,
      }),
    }),
    menuPortal: (base) => ({ ...base, zIndex: 1040 }),
  };

  const styles = { ...appSharedStyles, ...componentStyles };
  const menuPortalTarget = document.body;

  const Select = (typeof rest.loadOptions === 'function' ? AsyncSelect : BaseSelect) as React.ElementType;

  const ValueContainer = rest.controlShouldRenderValue === false ? components.ValueContainer : ValueContainerWithValues;

  return {
    className,
    styles,
    menuPortalTarget,
    rest,
    Select,
    ValueContainer,
  } as const;
};

export const FormSelectMultiCheckbox = <Option extends OptionTypeBase>(props: FormSelectMultiCheckboxProps<Option>) => {
  const { className, styles, menuPortalTarget, rest, Select, ValueContainer } = useFormSelectMultiCheckboxState(props);

  return (
    <FormSelectMultiCheckboxStyled className={className}>
      <Select
        isMulti
        styles={styles}
        components={{
          IndicatorSeparator: null,
          ValueContainer,
          Option,
          LoadingIndicator,
        }}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        allowSelectAll={true}
        menuPortalTarget={menuPortalTarget}
        backspaceRemovesValue={false}
        {...rest}
      />
    </FormSelectMultiCheckboxStyled>
  );
};
