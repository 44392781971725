import { Action } from 'redux';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import * as fromOnCostApiMethods from 'api-endpoints/on-cost';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { getTranslate } from 'utils/i18utils';
import { sortByName } from 'utils/list';
import { invokeApiCall } from 'utils/sagas';

import { companySelectors } from 'store/company/company.selectors';
import * as fromOnCostReducer from 'store/entities/onCost/index';
import * as onCostModel from 'store/entities/onCost/onCost.model';
import type { OnCost } from 'store/entities/onCost/onCost.types';
import { exModalHideAction } from 'store/modals/modals.actions';
import { ModalGeneralResult } from 'store/modals/modals.interfaces';
import { modalSagaWorker } from 'store/modals/modals.sagas';

/**
 * Helper complete saga
 *
 * @param {CompanyInfo['companyId']} companyId Company Id
 * @param {string} messagePath path in lang file
 * @param {string} [modalId] current modal id
 */
function* onApiCallSuccess(messagePath: string, modalId?: string) {
  yield all([
    put(exModalHideAction({ id: modalId })),
    put(
      alertsEffects.showSuccess({
        message: getTranslate(messagePath),
      }),
    ),
  ]);
}

function* onCostSagaFetchWorker() {
  const { data, errorData, message } = yield invokeApiCall(fromOnCostApiMethods.getCompanyOnCostValuesFunc, {});

  if (!errorData && !message) {
    yield put(fromOnCostReducer.onCostActions.setAll(data.map(onCostModel.OnCost)));
  }
}

function* onCostSagaGetWorker({ payload }: ReturnType<typeof fromOnCostReducer.onCostActions.onCostGET.start>) {
  const onCostId = payload.onCostId;
  const { data, errorData, message } = yield invokeApiCall(fromOnCostApiMethods.getCompanyOnCostValueFunc, {
    urlParams: { onCostId },
  });

  if (!errorData && !message && data) {
    yield put(fromOnCostReducer.onCostActions.upsertOne(onCostModel.OnCost(data)));
  }
}

function* onCostSagaCreateWorker(action: Action) {
  const company: ReturnType<typeof companySelectors.getCompanyInfo> = yield select(companySelectors.getCompanyInfo);

  if (!company) {
    return;
  }

  let modalId;

  const companyOnCosts: OnCost[] = yield select(fromOnCostReducer.onCostSelectors.selectAll);
  const companyOnCostCountryCodes = companyOnCosts
    .map(({ country: onCostCountry }) => onCostCountry?.code)
    .filter(Boolean) as string[];
  const companyCountries = company.countries.filter(
    (companyCountry) => !companyOnCostCountryCodes.includes(companyCountry.code.expedoCode2),
  );
  const defaultOnCost = yield select(fromOnCostReducer.onCostSelectors.selectDefault);

  const { id, country, isDefault, onCostId, employmentType, ...defaultOnCostValues } = defaultOnCost || {};

  const isLastCountry = companyCountries.length === 1;

  if (isLastCountry) {
    defaultOnCostValues.country = companyCountries[0];
  }
  if (companyCountries.length === 0) {
    yield put(
      alertsEffects.showWarning({
        message: getTranslate('company.onCost.addNew.allCountriesAlreadyAdded'),
      }),
    );
    return;
  }

  while (true) {
    const result: ModalGeneralResult = yield call(modalSagaWorker, {
      modalType: ModalsTypeKey.companyUpdateOnCost,
      ...(modalId ? { id: modalId } : {}),
      modalConfig: {
        content: {
          title: 'Add On-cost %',
          withTitle: true,
        },
      },
      modalProps: {
        countries: sortByName(companyCountries),
        item: defaultOnCostValues,
      },
    });

    if (result.cancel || !result.confirm) {
      return;
    }
    yield call(startLoader, action);
    modalId = result.confirm.payload.id;

    const { onCostValues, country: modalOnCostCountry } = result.confirm.payload.modalResult?.item;
    const onCost: Pick<OnCost, 'country' | 'onCostValues'> = {
      country: {
        code: modalOnCostCountry?.code?.expedoCode2,
        name: modalOnCostCountry?.name,
      },
      onCostValues,
    };

    const { data, errorData, message } = yield invokeApiCall(fromOnCostApiMethods.createCompanyOnCostValueFunc, {
      data: onCost,
    });

    if (!errorData || !message) {
      yield call(onApiCallSuccess, 'company.onCost.addNew.success', modalId);
      yield put(fromOnCostReducer.onCostActions.addOne(onCostModel.OnCost({ ...onCost, ...data })));
      yield call(stopLoader, action);
      break;
    }
    yield call(stopLoader, action);
  }
}

function* onCostSagaUpdateWorker(action: ReturnType<typeof fromOnCostReducer.onCostActions.onCostUpdate.start>) {
  const company: ReturnType<typeof companySelectors.getCompanyInfo> = yield select(companySelectors.getCompanyInfo);

  if (!company) {
    return;
  }

  let modalId;

  const onCostId = action.payload.onCostItem.onCostId!;
  const companyOnCosts: OnCost[] = yield select(fromOnCostReducer.onCostSelectors.selectAll);
  const companyOnCostCountryCodes = companyOnCosts.map(({ country }) => country?.code).filter(Boolean) as string[];
  const companyCountries = company.countries.filter(
    (country) => !companyOnCostCountryCodes.includes(country.code.expedoCode2),
  );

  while (true) {
    const result: ModalGeneralResult = yield call(modalSagaWorker, {
      modalType: ModalsTypeKey.companyUpdateOnCost,
      ...(modalId ? { id: modalId } : {}),
      modalConfig: {
        content: {
          withTitle: true,
          title: 'Edit On-cost %',
        },
      },
      modalProps: {
        item: action.payload.onCostItem,
        countries: companyCountries,
        isCountrySelectDisabled: true,
      },
    });

    if (result.cancel || !result.confirm) {
      return;
    }
    yield call(startLoader, action);
    modalId = result.confirm.payload.id;

    const { onCostValues, isDefault, country } = result.confirm.payload.modalResult?.item;
    const onCost: Pick<OnCost, 'country' | 'onCostValues' | 'onCostId' | 'isDefault'> = {
      onCostValues,
      onCostId,
      isDefault,
      country: isDefault
        ? null
        : {
            code: country?.code,
            name: country?.name,
          },
    };

    const { errorData, message } = yield invokeApiCall(fromOnCostApiMethods.updateCompanyOnCostValueFunc, {
      data: onCost,
      urlParams: { onCostId },
    });

    if (!errorData || !message) {
      yield call(onApiCallSuccess, 'company.onCost.update.success', modalId);
      yield put(fromOnCostReducer.onCostActions.updateOne({ id: onCostId, changes: onCostModel.OnCost(onCost) }));
      yield call(stopLoader, action);
      break;
    }

    yield call(stopLoader, action);
  }
}

function* onCostSagaDeleteWorker(action: ReturnType<typeof fromOnCostReducer.onCostActions.onCostRemove.start>) {
  const { id: onCostId } = action.payload.onCostItem;
  let modalId;

  while (true) {
    const result: ModalGeneralResult = yield call(modalSagaWorker, {
      modalType: ModalsTypeKey.confirmModal,
      ...(modalId ? { id: modalId } : {}),
      modalConfig: {
        content: {
          withTitle: true,
          title: 'Confirm Action',
          message: 'You confirm remove On-Cost?',
          withActions: true,
        },
      },
    });

    if (result.cancel || !result.confirm) {
      return;
    }
    yield call(startLoader, action);
    modalId = result.confirm.payload.id;

    const { errorData, message } = yield invokeApiCall(fromOnCostApiMethods.deleteCompanyOnCostValueFunc, {
      urlParams: { onCostId },
    });

    if (!errorData && !message) {
      yield call(onApiCallSuccess, 'company.onCost.remove.success', modalId);
      yield put(fromOnCostReducer.onCostActions.removeOne(onCostId));
      yield call(stopLoader, action);
      break;
    }
    yield call(stopLoader, action);
  }
}

export function* onCostSaga() {
  yield takeEvery(fromOnCostReducer.onCostActions.onCostFetch.start, onCostSagaFetchWorker);
  yield takeEvery(fromOnCostReducer.onCostActions.onCostGET.start, onCostSagaGetWorker);
  yield takeEvery(fromOnCostReducer.onCostActions.onCostCreate.start, onCostSagaCreateWorker);
  yield takeEvery(fromOnCostReducer.onCostActions.onCostUpdate.start, onCostSagaUpdateWorker);
  yield takeEvery(fromOnCostReducer.onCostActions.onCostRemove.start, onCostSagaDeleteWorker);
}
