import { createSelector } from '@reduxjs/toolkit';

import { createDeepEqualSelector, createListSelectors } from 'utils/reducer/selector-helper';

import { EntityKeys, UIKeys } from 'store/constants';
import { Candidate, CandidateFilters, CandidateListSortBy } from 'store/entities/candidates/models';
import { RootState } from 'store/rootReducer';
import { uiDomain } from 'store/rootSelectors';

export const domain = createSelector(uiDomain, (state) => state[UIKeys.candidateLists]);

export const {
  getListById,
  getFilters,
  getSortMode,
  getListParams,
  getViewMode,
  getSelected,
  getItems: getCandidates,
  getRecentItems: getRecentCandidates,
  getSelectedEntities,
} = createListSelectors<Candidate, CandidateListSortBy, CandidateFilters>(
  EntityKeys.candidates,
  (state: RootState) => state.ui.candidateLists,
);

export const getFilter = createDeepEqualSelector(getListById, (list) => list?.filter);
