import { useEffect, useState } from 'react';
import { OptionsType } from 'react-select';

type ReactSelectSetDebounceProps = { cb?: Function; delay?: number };

type UseReactSelectDebounceProps = {
  asyncLoader: (searchTerm: string, pageNo?: number) => Promise<any>;
  delay: number;
};

type UseReactSelectDebounceHandlerType = <T>(
  searchTerm: string | undefined,
  callback?: number | ((options: OptionsType<T>) => void),
) => void;

// noinspection JSValidateJSDoc
/**
 * Handle debounce refreshing.
 *
 * @param {UseReactSelectDebounceProps} props
 * @returns {React.Dispatch<React.SetStateAction<ReactSelectDebounceProps>>}
 */
const useReactSelectDebounce = (props: ReactSelectSetDebounceProps) => {
  const [debounce, setDebounce] = useState<ReactSelectSetDebounceProps>(props);

  useEffect(() => {
    const { cb, delay } = debounce;
    if (cb) {
      const timeout = setTimeout(cb, delay);
      return () => clearTimeout(timeout);
    }
  }, [debounce]);

  return setDebounce;
};

/**
 * Hook can be used for debouncing react-select async option loaders.
 *
 * @param {UseReactSelectDebounceProps} props
 * @returns {UseReactSelectDebounceHandlerType}
 */
const useReactSelectDebounceHandler = (props: UseReactSelectDebounceProps): UseReactSelectDebounceHandlerType => {
  const setDebounce = useReactSelectDebounce({});

  return <T>(searchTerm: string | undefined, callback?: number | ((options: OptionsType<T>) => void)) => {
    setDebounce({
      cb: async () => {
        const result = await props.asyncLoader(searchTerm ?? '', typeof callback === 'number' ? callback : undefined);
        if (callback instanceof Function) {
          callback((result as T[]) || []);
        }
      },
      delay: props.delay,
    });
  };
};

export { useReactSelectDebounce, useReactSelectDebounceHandler };
