import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Option } from 'react-select/src/filters';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled, { css } from 'styled-components';

import { EmailCategory } from 'model/api-enums.constants';

import { CompanyEmailTemplatePreview } from 'pages/Company/CompanyTabs/CompanyEmailTemplateTab/components/CompanyEmailTemplatePreview';
import {
  EmailTemplateFormModel,
  emailTemplateSelectors,
} from 'pages/Company/CompanyTabs/CompanyEmailTemplateTab/state';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import { ExModalBodyStyled, ExModalWrapperStyled } from 'containers/Modals/ModalsContent/Company/ExModalComponents';

import { ExEmailEditor } from 'components/Form/EmailEditor/EmailEditor';
import { FormCard } from 'components/FormCard';
import { FormInput } from 'components/FormInput/FormInput';
import { FormSelect } from 'components/FormSelect';
import { ExDialogProps, isConfirmButtonPressed } from 'components/ui/ExDialog';
import { ExColumn, ExRow } from 'components/ui/ExLayout';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import {
  emailListValidator,
  emailRecipientBuilder,
  emailSeparatorValidator,
  linkValidator,
  stringOptional,
  validateStringLengthWithoutHtmlTags,
} from 'utils/validator-helpers';

import { exModalConfirmAction } from 'store/modals/modals.actions';
import { ExModal } from 'store/modals/modals.interfaces';
import { useSelector } from 'store/rootSelectors';

type CompanyEmailTemplateFormModalProps = ExModal & AppModalContentProps;

const CompanyEmailTemplateFormModalStyled = styled(ExModalWrapperStyled)`
  max-width: calc(100% - 200px);
  height: 100%;
  max-height: calc(100% - 200px);

  @media screen and (max-width: 1200px) {
    max-height: calc(100% - 50px);
    max-width: calc(100% - 50px);
  }
`;

const PreviewWrapper = styled(ExColumn)``;

const EmailContainer = styled(ExRow)`
  align-items: stretch;
  height: 100%;
`;

type EmailEditorProps = {
  isValid: boolean;
  isInvalid: boolean;
};

const getValidationBordersIsValidOrInvalid = ({ isValid, isInvalid }: EmailEditorProps) => {
  const status = Boolean(isValid || isInvalid);

  if (status) {
    return css`
      border-width: 1px;
      border-style: solid;
    `;
  }
};

const getValidationBordersIsValid = ({ isValid }: EmailEditorProps) =>
  isValid &&
  css`
    border-color: ${({ theme }) => theme.colors.success};
  `;

const getValidationBordersIsInvalid = ({ isInvalid }: EmailEditorProps) =>
  isInvalid &&
  css`
    border-color: ${({ theme }) => theme.colors.danger};
  `;

const getValidationBorders = css<EmailEditorProps>`
  ${getValidationBordersIsValidOrInvalid};
  ${getValidationBordersIsValid};
  ${getValidationBordersIsInvalid};
`;

const EmailEditorStyled = styled(ExEmailEditor)<EmailEditorProps>`
  flex-grow: 1;
  ${getValidationBorders}
`;

const minimalEmailBodyHtmlContentLength = 4;
const emailTemplateValidationSchema = yup.object({
  emailBodyHtmlContent: yup
    .string()
    .test('valid-link', ({ label }) => `${label} have invalid link(s)`, linkValidator)
    .test(
      'stripped-html',
      ({ label }) => `${label} must be at least ${minimalEmailBodyHtmlContentLength} characters`,
      validateStringLengthWithoutHtmlTags(minimalEmailBodyHtmlContentLength),
    )
    .label('Content')
    .required(),
  emailCategory: yup.mixed().required().label('Email Category'),
  emailSubject: yup.string().required().trim().label('Subject Title'),
  emailTitle: yup.string().required().trim().label('Email Template Title'),
  templateId: stringOptional,
  ccRecipients: yup
    .string()
    .optional()
    .test('valid separators', 'Please use a comma when separating multiple emails', emailSeparatorValidator)
    .test('valid emails', ({ label }) => `${label} have invalid emails`, emailListValidator)
    .label('Recipients in Cc'),
});

export const CompanyEmailTemplateFormModal = ({
  id,
  modalConfig,
  onClose,
  modalProps,
}: CompanyEmailTemplateFormModalProps) => {
  const dispatch = useAppDispatch();
  const emailTemplateCategories = useSelector(emailTemplateSelectors.emailTemplateCategories);
  const initialEmailBodyHtmlContentValue = modalProps?.emailTemplate?.emailBodyHtmlContent;

  const { register, errors, formState, watch, handleSubmit, control } = useForm<EmailTemplateFormModel>({
    defaultValues: {
      emailBodyHtmlContent: '',
      emailCategory: 1,
      emailSubject: '',
      emailTitle: '',
      ...modalProps?.emailTemplate,
    },
    resolver: yupResolver(emailTemplateValidationSchema),
    shouldFocusError: true,
  });

  const onSubmitClickHandler = async (emailTemplateFormData: Partial<EmailTemplateFormModel>) => {
    dispatch(
      exModalConfirmAction({
        id,
        modalResult: {
          emailTemplate: {
            ...emailTemplateFormData,
            ccRecipients: emailRecipientBuilder(emailTemplateFormData.ccRecipients),
          },
        },
      }),
    );
  };

  const submitButtonRef = React.useRef<null | HTMLInputElement>(null);

  const onCloseHandler: ExDialogProps['onClose'] = (isConfirm) => {
    if (isConfirmButtonPressed(isConfirm)) {
      submitButtonRef.current?.click();
    } else {
      onClose();
    }
  };

  const { isSubmitted } = formState;

  const emailBodyHtmlContent = watch().emailBodyHtmlContent;

  const emailCategory = watch('emailCategory');

  const isEmailCategoryDisabled = EmailCategory.CandidateNotifications === emailCategory;

  const emailCategoryOptions = useMemo(
    () =>
      isEmailCategoryDisabled
        ? emailTemplateCategories.filter((category) => category.value === EmailCategory.CandidateNotifications)
        : emailTemplateCategories.filter((category) => category.value !== EmailCategory.CandidateNotifications),
    [emailTemplateCategories, isEmailCategoryDisabled],
  );

  useEffect(() => {
    if (errors.emailBodyHtmlContent?.message) {
      dispatch(alertsEffects.showError({ message: errors.emailBodyHtmlContent?.message }));
    }
  }, [dispatch, errors]);

  return (
    <CompanyEmailTemplateFormModalStyled content={modalConfig?.content ?? {}} onClose={onCloseHandler}>
      <form onSubmit={handleSubmit(onSubmitClickHandler)} className="d-flex">
        <ExModalBodyStyled>
          <EmailContainer>
            <PreviewWrapper custom="6">
              <CompanyEmailTemplatePreview>
                <div
                  dangerouslySetInnerHTML={{
                    __html: emailBodyHtmlContent ?? '',
                  }}
                ></div>
              </CompanyEmailTemplatePreview>
            </PreviewWrapper>
            <ExColumn custom="6" flex column>
              <input ref={register} name="templateId" hidden />
              <input type="submit" hidden ref={submitButtonRef} />
              <FormInput
                inputRef={register}
                label="Email Template Title"
                required
                name="emailTitle"
                errors={errors?.emailTitle}
                validated={isSubmitted}
              />
              <Controller
                control={control}
                name="emailCategory"
                render={({ value, onChange }) => (
                  <FormSelect
                    label="Email Category"
                    required
                    name="emailCategory"
                    isSearchable={false}
                    errors={errors?.emailCategory}
                    validated={isSubmitted}
                    options={emailCategoryOptions}
                    value={value}
                    isDisabled={isEmailCategoryDisabled}
                    onChange={(v: Option) => onChange(v?.value)}
                  />
                )}
              />
              <FormInput
                inputRef={register}
                label="Subject Title"
                required
                name="emailSubject"
                errors={errors?.emailSubject}
                validated={isSubmitted}
              />
              <FormInput
                inputRef={register}
                label="Recipients in Cc (Optional)"
                name="ccRecipients"
                errors={errors?.ccRecipients}
                validated={isSubmitted}
                hint="You can configure the recipient of this email in the pipeline settings. In the field below you can enter one or more email addresses of additional recipients in CC. Enter them separated by commas."
              />
              <FormCard.InputLabel>
                Content <FormCard.InputLabelRequired>*</FormCard.InputLabelRequired>
              </FormCard.InputLabel>
              <Controller
                control={control}
                name="emailBodyHtmlContent"
                render={({ onChange, value }) => (
                  <EmailEditorStyled
                    initialValue={initialEmailBodyHtmlContentValue}
                    onChange={onChange}
                    value={value}
                    isInvalid={isSubmitted && Boolean(errors.emailBodyHtmlContent)}
                    isValid={isSubmitted && !errors.emailBodyHtmlContent}
                  />
                )}
              />
            </ExColumn>
          </EmailContainer>
        </ExModalBodyStyled>
      </form>
    </CompanyEmailTemplateFormModalStyled>
  );
};
