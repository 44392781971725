import React from 'react';
import styled from 'styled-components';

import { ExBadge } from 'components/ui/ExBadge';
import {
  BadgeTypes,
  CTX_REMAINING_BASIC_COMPONENT,
  useBadgeVariant,
  useJobDeadlineText,
} from 'components/ui/ExDeadlineBadge/hooks';

export interface RemainingProps {
  className?: string;
  deadline?: string | null;
  badgeType?: BadgeTypes;
}

const RemainingBasicComponent: React.FC<RemainingProps> = ({ className, deadline, badgeType = 'default' }) => {
  const { deadlineDifferenceText } = useJobDeadlineText(deadline, {
    context: CTX_REMAINING_BASIC_COMPONENT,
  });
  const badgeVariant = useBadgeVariant(deadline, badgeType);

  return (
    <ExBadge className={className} variant={badgeVariant}>
      {deadlineDifferenceText}
    </ExBadge>
  );
};

export const Remaining = styled(RemainingBasicComponent)`
  white-space: nowrap;
`;
