import React from 'react';
import styled from 'styled-components/macro';

import { ApplicantModalEducationView } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalEducation/ApplicantModalEducationView';
import { ApplicantModalWorkExperienceView } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalWorkExperience/ApplicantModalWorkExperienceView';

type ApplicantOverviewTabViewProps = {
  className?: string;
};

const ApplicantOverviewTabViewStyled = styled.div``;

const useApplicantOverviewTabViewState = ({ className }: ApplicantOverviewTabViewProps) => {
  return {
    className,
  } as const;
};

export const ApplicantOverviewTabView: React.FC<ApplicantOverviewTabViewProps> = (props) => {
  const { className } = useApplicantOverviewTabViewState(props);

  return (
    <ApplicantOverviewTabViewStyled className={className}>
      <ApplicantModalWorkExperienceView />
      <ApplicantModalEducationView />
    </ApplicantOverviewTabViewStyled>
  );
};
