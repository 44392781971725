import React from 'react';
import styled from 'styled-components';

import { IconView } from 'components/Icons/IconView';
import { ExDropDownCheckable } from 'components/ui/ExDropDownCheckable';
import { ExDropDownCheckableItemsProps } from 'components/ui/ExDropDownCheckable/ExDropDownCheckableProps';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { applicantFiltersActions, applicantFiltersSelectors } from 'store/ui/applicant-filters';

type ApplicantViewActionButtonsProps = {
  className?: string;
};

const ApplicantViewActionButtonsStyled = styled.div``;

const ExDropDownCheckableStyled = styled(ExDropDownCheckable)`
  margin-right: 10px;
`;

const useApplicantViewActionButtonsState = ({ className }: ApplicantViewActionButtonsProps) => {
  const dispatch = useAppDispatch();

  const showHideItems: ExDropDownCheckableItemsProps[] = useAppSelector(
    applicantFiltersSelectors.selectApplicantCardFilterItems,
  );

  const updateShowedCardInfo = (items: typeof showHideItems) => {
    const filters = items.reduce((acc, item) => ({ ...acc, [item.fieldName]: item.isChecked }), {});
    dispatch(applicantFiltersActions.updateFilters(filters));
  };

  return {
    className,
    showHideItems,
    updateShowedCardInfo,
  } as const;
};

export const ApplicantViewActionButtons: React.FC<ApplicantViewActionButtonsProps> = (props) => {
  const { className, showHideItems, updateShowedCardInfo } = useApplicantViewActionButtonsState(props);

  return (
    <ApplicantViewActionButtonsStyled className={className}>
      <ExDropDownCheckableStyled onChange={updateShowedCardInfo} items={showHideItems} prependIcon={IconView}>
        Show/Hide Data
      </ExDropDownCheckableStyled>
    </ApplicantViewActionButtonsStyled>
  );
};
