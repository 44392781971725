import React from 'react';
import styled from 'styled-components';

type EmptyLayoutProps = {
  className?: string;
  children: React.ReactNode;
};

export const EmptyLayout = ({ className, children }: EmptyLayoutProps) => {
  return <Layout className={className}>{children}</Layout>;
};

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
`;

export const EmptyLayoutFullHeight = styled(EmptyLayout)`
  flex-grow: 1;
`;
