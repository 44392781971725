import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { minimalJobDescriptionLength } from 'containers/JobForms/validators';

import { TextEditor } from 'components/Form/TextEditor';
import { StepFormBody } from 'components/StepForm';
import { useJobFormDescription } from 'utils/hooks/formsHooks/useFormDescription';
import { getTranslate } from 'utils/i18utils';

import { Job } from 'store/entities/jobs/models';

export interface JobDescriptionFormBodyProps extends StepFormBody {
  className?: string;
  formData?: Partial<Job>;
}

export const JobDescriptionFormBodyStyled = styled.div``;

const JobDescriptionDisclaimer = styled.p`
  padding: 5px 0;
  margin: 0;
`;

export const JobDescriptionFormBody: React.FC<JobDescriptionFormBodyProps> = ({ className, formData }) => {
  const { errors, formState } = useFormContext<Job>();
  const validated = formState.isSubmitted;
  const errorsByName = errors['description'];

  const {
    description: jobDescription,
    handlerChangeDescription,
    inputRef,
  } = useJobFormDescription({
    formData,
  });

  return (
    <JobDescriptionFormBodyStyled className={className}>
      <JobDescriptionDisclaimer className="mb-1">
        {getTranslate('text_must_be_at_least', {
          fieldName: 'Description',
          min: minimalJobDescriptionLength,
          count: minimalJobDescriptionLength,
        })}
      </JobDescriptionDisclaimer>
      <TextEditor
        onChange={handlerChangeDescription}
        value={jobDescription ?? ''}
        errors={errorsByName}
        validated={validated}
        inputRef={inputRef}
      />
      <JobDescriptionDisclaimer>{getTranslate('new_job.step_3.disclaimer')}</JobDescriptionDisclaimer>
    </JobDescriptionFormBodyStyled>
  );
};
