import { dashboardConfig } from 'config';
import { list } from 'config/list';

import { JobStatus } from 'model/api-enums.constants';

import { InitJobListsState, JobListState } from 'containers/JobLists/store/models';

import { SortDirections, ViewMode } from 'store/constants';
import { JobBelongsTo, SortByForJobList } from 'store/entities/jobs/models';

export const initialListState: JobListState = {
  countByStatus: {},
  fetched: false,
  filters: {},
  id: JobBelongsTo.main,
  items: [],
  pageCount: 0,
  pageNo: 0,
  totalItemsCount: 0,
  pageSize: list.pageSize,
  pagination: {},
  selectedItems: [],
  sortMode: {
    orderBy: SortByForJobList.JobName,
    orderDir: SortDirections.asc,
  },
  viewMode: list.viewMode,
};

export const initialState: InitJobListsState = {
  [JobBelongsTo.main]: { ...initialListState },
  [JobBelongsTo.dashboard]: {
    ...initialListState,
    filters: {
      deadlineNotPassed: true,
      status: JobStatus.open,
    },
    id: JobBelongsTo.dashboard,
    pageSize: dashboardConfig.pageSize,
    sortMode: {
      orderBy: SortByForJobList.Deadline,
      orderDir: SortDirections.asc,
    },
    viewMode: ViewMode.grid,
  },
  [JobBelongsTo.newJobAd]: {
    ...initialListState,
    filters: {
      ...initialListState,
      status: JobStatus.open,
    },
    id: JobBelongsTo.newJobAd,
  },
  [JobBelongsTo.newApplicant]: {
    ...initialListState,
    filters: {
      status: JobStatus.open,
    },
    id: JobBelongsTo.newApplicant,
  },
} as Record<JobListState['id'], JobListState>;
