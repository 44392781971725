export const UserEndpoints = {
  // GET
  GetCurrentUserFunc: '/api/company/{companyId}/user/me',

  // GET
  SearchUsersFunc: '/api/company/{companyId}/user',

  // POST
  CreateUserFunc: '/api/company/{companyId}/user',

  // GET
  GetUserFunc: '/api/company/{companyId}/user/{userId}',

  // PUT
  UpdateUserFunc: '/api/company/{companyId}/user/{userId}',

  // POST
  ActivateUserFunc: '/api/company/{companyId}/user/{userId}/activate',

  // POST
  InactivateUserFunc: '/api/company/{companyId}/user/{userId}/inactivate',

  // GET
  GetAutocompleteUsersFunc: '/api/company/{companyId}/user/autocomplete',

  // GET
  GetCurrentUserCompaniesFunc: '/api/company/my',
};
