import React from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

import { SourceEntity } from 'model';

import { CheckableList } from 'components/Form/CheckableList';
import { StepFormBody } from 'components/StepForm';

import { JobAd } from 'store/entities/job-ads';

export interface JobAdResponsibilitiesFormBodyProps extends StepFormBody {
  className?: string;
  formData?: Partial<JobAd>;
}

export const JobAdResponsibilitiesFormBodyStyled = styled.div``;

export const JobAdResponsibilitiesFormBody: React.FC<JobAdResponsibilitiesFormBodyProps> = ({
  className,
  formData,
}) => {
  const {
    field: { ref, onChange },
  } = useController({ name: 'responsibilities' });

  return (
    <JobAdResponsibilitiesFormBodyStyled className={className}>
      <CheckableList
        items={formData?.responsibilities ?? []}
        onChange={onChange}
        placeholder="Add new Job Ad responsibility"
        sourceEntity={SourceEntity.JobAd}
        name="responsibilities"
        inputRef={ref}
      />
    </JobAdResponsibilitiesFormBodyStyled>
  );
};
