import React from 'react';

import { ExButton } from 'components/ui/ExButton';
import { ExMultiselectFormBody } from 'components/ui/ExMultiselectFormBody';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { CompanyInfoCurrency } from 'store/company/company.models';
import type { CompanyInfo } from 'store/company/company.types';
import { useCurrencyQuerySelectors } from 'store/dictionary/hooks/useCurrencyQuerySelectors';

type CompanyCurrenciesFormBodyProps = {
  className?: string;
  onChange: (entityList: CompanyInfo['currencies']) => void;
  defaultValue: CompanyInfo['currencies'];
  toggleSelectAll: () => void;
  allSelected: boolean;
};

export const CompanyCurrenciesFormBody = ({
  className,
  onChange,
  defaultValue,
  toggleSelectAll,
  allSelected,
}: CompanyCurrenciesFormBodyProps) => {
  const { selectors: currenciesSelectors } = useCurrencyQuerySelectors();
  const currencies = useAppSelector(currenciesSelectors.selectAll).map(CompanyInfoCurrency);

  const loadEntitiesHandler = async () => {
    const payload = currencies;
    const isPayloadValid = Boolean(payload?.length);
    return isPayloadValid ? payload : [];
  };

  const selectAllButtonText = (allSelected ? 'Deselect' : 'Select') + ' All';

  return (
    <ExMultiselectFormBody
      className={className}
      defaultValue={defaultValue}
      getOptionLabelCallback={(option) => option.currencyFullName}
      getOptionValueCallback={(option) => option.currency}
      loadEntitiesHandler={loadEntitiesHandler}
      onChangeHandler={onChange}
      title="Search Currency"
      backspaceRemovesValue={false}
      headerActions={
        <ExButton variant="link" onClick={toggleSelectAll}>
          {selectAllButtonText}
        </ExButton>
      }
    />
  );
};
