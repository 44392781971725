import * as apiApplicant from 'api-endpoints/applicant';
import { PostApplicantFuncResponse, PostApplicantParams } from 'api-endpoints/applicant/models';
import * as apiTalentPool from 'api-endpoints/talent-pool';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import * as applicantFormSelectors from 'containers/ApplicantForm/ApplicantFormState/applicant.selectors';
import {
  applicantFormActions,
  formApplicantKey,
} from 'containers/ApplicantForm/ApplicantFormState/applicant-form.reducer';
import { candidateListActions } from 'containers/CandidateLists/store';
import { jobListActions } from 'containers/JobLists/store';
import { loaderActions } from 'containers/Loader/store';

import { startLoaderThunk, stopLoaderThunk } from 'modules/LoaderManager/redux';

import { documentsActions } from 'store/app-files/documents/documents.reducer';
import { resumesActions } from 'store/app-files/resumes/resumes.reducer';
import { Applicant } from 'store/entities/applicants/models';
import { CandidateBelongsTo } from 'store/entities/candidates/models';
import { JobBelongsTo } from 'store/entities/jobs/models';
import { TalentPool } from 'store/entities/talent-pools/models';
import { AppEffectParams, AppThunk } from 'store/types';

export const applicantSaveProcessing = 'applicantSaveProcessing';

export const saveApplicant =
  ({
    preloader,
    data,
  }: AppEffectParams<{ data?: Partial<Applicant> }>): AppThunk<
    Promise<{ message: string | undefined; storedData: PostApplicantFuncResponse }>
  > =>
  async (dispatch, getState) => {
    const formData = applicantFormSelectors.selectFormData(getState());
    if (preloader) {
      startLoaderThunk(dispatch, saveApplicant);
      dispatch(loaderActions.start(applicantSaveProcessing));
    }
    const postData = {
      ...formData,
      ...data,
    } as PostApplicantParams;
    const { message, data: storedData } = await apiApplicant.postApplicant(postData);
    if (message) {
      dispatch(alertsEffects.showError({ message }));
    } else {
      dispatch(applicantFormActions.saveForm({ formData: { ...postData, ...storedData } }));
    }
    if (preloader) {
      dispatch(loaderActions.stop(applicantSaveProcessing));
    }
    stopLoaderThunk(dispatch, saveApplicant);
    return {
      message,
      storedData: {
        ...storedData,
        candidateId: postData.candidateId,
        jobId: postData.jobId,
      },
    };
  };
saveApplicant.processing = applicantSaveProcessing;

const preSelectTalentPoolProcessing = 'preSelectTalentPoolProcessing';
export const preSelectTalentPool =
  ({
    talentPoolId,
    listId,
    preloader,
  }: AppEffectParams<{
    talentPoolId?: TalentPool['id'];
    listId?: CandidateBelongsTo;
  }>): AppThunk<Promise<void>> =>
  async (dispatch) => {
    if (!talentPoolId || !listId) {
      return;
    }
    if (preloader) {
      dispatch(loaderActions.start(preSelectTalentPoolProcessing));
    }
    const { message, data } = await apiTalentPool.getTalentPoolById({ id: talentPoolId });
    if (preloader) {
      dispatch(loaderActions.stop(preSelectTalentPoolProcessing));
    }
    if (message) {
      dispatch(alertsEffects.showError({ message }));
      return;
    }
    dispatch(
      candidateListActions.updateFilters({
        id: listId,
        filters: {
          talentPool: [{ value: data.id, label: data.name }],
        },
      }),
    );
  };
saveApplicant.processing = preSelectTalentPoolProcessing;

export function changeCandidate({
  newCandidateId,
  oldCandidateId,
}: AppEffectParams<{
  oldCandidateId: string | undefined;
  newCandidateId: string | undefined;
}>): AppThunk {
  return function (dispatch) {
    if (oldCandidateId && newCandidateId !== oldCandidateId) {
      dispatch(
        applicantFormActions.saveForm({
          formData: { documentId: undefined, resumeId: undefined, jobId: undefined },
        }),
      );
      dispatch(applicantFormActions.setState({ selectedDocuments: [], selectedResumes: [] }));
      dispatch(jobListActions.setItemSelect({ id: JobBelongsTo.newApplicant, selectedItemId: [] }));
      const listName = formApplicantKey + oldCandidateId;
      dispatch(resumesActions.setSelected({ selectedIds: [], listName }));
      dispatch(documentsActions.setSelected({ selectedIds: [], listName }));
    }
  };
}
