import React from 'react';
import styled from 'styled-components/macro';

import {
  ApplicantInfoLabel,
  IconJobStyled,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/ApplicantModalV3Components';
import { ApplicantModalInfoWrapper } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalV4Components';
import { useApplicantFromModal } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/hook/useApplicantFromModal';

type ApplicantModalAvailabilityInfoViewProps = {
  className?: string;
};

const ApplicantModalAvailabilityInfoViewStyled = styled(ApplicantModalInfoWrapper)``;

const useApplicantModalAvailabilityInfoViewState = ({ className }: ApplicantModalAvailabilityInfoViewProps) => {
  const applicant = useApplicantFromModal();
  const availability = applicant?.availability;

  return {
    className,
    availability,
  } as const;
};

export const ApplicantModalAvailabilityInfoView: React.FC<ApplicantModalAvailabilityInfoViewProps> = (props) => {
  const { className, availability } = useApplicantModalAvailabilityInfoViewState(props);

  return (
    <ApplicantModalAvailabilityInfoViewStyled className={className}>
      <IconJobStyled />
      <div>
        <div>{availability}</div>
        <ApplicantInfoLabel>Availability</ApplicantInfoLabel>
      </div>
    </ApplicantModalAvailabilityInfoViewStyled>
  );
};
