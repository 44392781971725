import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { list } from 'config/list';

import { InterviewOrderProperty, OrderDirection } from 'model/api-enums.constants';

import { EnumLiteralsOf } from 'utils/funcs';

import { UIKeys } from 'store/constants';

type InitialStateProps = {
  jobApplicantId?: null | string;
};

type ApplicantInterviewTabListUiSliceState = ReturnType<typeof initialState>;

export type ApplicantInterviewTabListFilters = ApplicantInterviewTabListUiSliceState['filters'];

const initialState = ({ jobApplicantId = null } = {} as InitialStateProps) => ({
  filters: {
    jobApplicantId,
    pageNo: 0,
    pageSize: list.pageSize,
    orderDirection: OrderDirection.Ascending,
    orderBy: InterviewOrderProperty.PublishedOn as EnumLiteralsOf<typeof InterviewOrderProperty>,
  },
});

export const applicantInterviewTabListUiSlice = createSlice({
  name: UIKeys.applicantInterviewTabList,
  initialState: initialState(),
  reducers: {
    updateState: (state, { payload }: PayloadAction<Partial<ApplicantInterviewTabListUiSliceState>>) =>
      Object.assign(state, payload),
    resetState: (state, { payload }: PayloadAction<InitialStateProps>) =>
      initialState({ jobApplicantId: payload.jobApplicantId }),
  },
});
