import React from 'react';
import { push } from 'connected-react-router';
import { Routes } from 'router';
import styled from 'styled-components';

import { ExButton } from 'components/ui/ExButton';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { useSelector } from 'store/rootSelectors';

export type ProfilePageProps = {
  className?: string;
};

export const ProfilePageStyled = styled.div``;

export const ProfilePage: React.FC<ProfilePageProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const reduxUser = useSelector((state) => state.auth.user);

  const logOutClickHandler: React.MouseEventHandler = () => {
    dispatch(push({ pathname: `/${Routes.logout}`, state: { local: true } }));
  };

  return (
    <ProfilePageStyled className={className}>
      <h2>ProfilePage</h2>
      <div>
        <pre>{reduxUser && JSON.stringify(reduxUser, null, 2)}</pre>
      </div>
      <div>
        <ExButton onClick={logOutClickHandler}>LogOut</ExButton>
      </div>
    </ProfilePageStyled>
  );
};
