import { AppRouterParams } from 'model/router';

export function createPath(routeParts: string[] | string, queryString = '') {
  const url = typeof routeParts === 'object' ? routeParts.join('/') : routeParts;
  return url + queryString;
}

export function replaceUrlParams(url: string, urlParams: AppRouterParams) {
  let result = url;
  Object.entries(urlParams).forEach(([key, value]) => {
    const regex = new RegExp(`:${key}`);
    const keyExists = url.match(regex);

    if (keyExists === null) {
      throw new Error(`Parameter ${key} is not exists in route ${url}`);
    }
    result = result.replace(regex, encodeURIComponent(value));
  });

  return result;
}

export function createRoute(routeParts: string[] | string, urlParams?: AppRouterParams, queryParams?: string): string {
  let url = createPath(routeParts);
  if (urlParams !== undefined) {
    url = replaceUrlParams(url, urlParams);
  }

  if (queryParams !== undefined) {
    url += queryParams;
  }

  return url;
}
