import React from 'react';

import * as fromBulkCreateCreationComponents from 'containers/BulkCreation/components';
import CreateNewCandidate from 'containers/Modals/ModalsContent/Candidate/CreateNewCandidateWizard';
import { HiringPipelineEditModal } from 'containers/Modals/ModalsContent/Company/HiringPipelineEditModal';
import { HiringPipelineEditNameModal } from 'containers/Modals/ModalsContent/Company/HiringPipelineEditNameModal';
import { HiringPipelineStageCreateModal } from 'containers/Modals/ModalsContent/Company/HiringPipelineStageCreateModal';
import { HiringPipelineStageRemoveModal } from 'containers/Modals/ModalsContent/Company/HiringPipelineStageRemoveModal';
import { JobTeamAddMemberModal } from 'containers/Modals/ModalsContent/Job/JobTeamAddMemberModal';
import { JobTeamEditModal } from 'containers/Modals/ModalsContent/Job/JobTeamEditModal';
import { JobTeamRemoveMemberModal } from 'containers/Modals/ModalsContent/Job/JobTeamRemoveMemberModal';
import { BulkAddCandidateToTalentPool } from 'containers/Modals/ModalsContent/TalentPool';
import { AddCandidateToTalentPool } from 'containers/Modals/ModalsContent/TalentPool/AddCandidateToTalentPool';
import { NewTalentPoolForWizard } from 'containers/Modals/ModalsContent/TalentPool/NewTalentPoolForWizard';
import * as fromModalWizardComponents from 'containers/Modals/ModalsContent/Wizard';

import { ExDialogContent } from 'components/ui/ExDialog';

import { ExModal } from 'store/modals/modals.interfaces';

export type ModalStepProps = {
  id: string;
  nextButtonHandler: (event?: React.MouseEvent) => void;
  prevButtonHandler: (event?: React.MouseEvent) => void;
  onCloseDialogHandler: (result?: boolean | null) => void;
  modalProps?: ExModal['modalProps'];
} & ExDialogContent;

type ModalsConfig = typeof modalsConfig;
export type ModalWizardType = keyof ModalsConfig;
export type ModalWizardName = keyof ModalsConfig;
export type ModalWizard<T extends ModalWizardName = ModalWizardName> = T extends ModalWizardName
  ? ModalsConfig[T]
  : never;
export type ModalWizardPageNames<T extends ModalWizardName = ModalWizardName> = T extends ModalWizardName
  ? keyof ModalWizard<T>
  : never;

export const modalsConfig = {
  jobChangeStatus: {
    pending: fromModalWizardComponents.PendingModal,
    confirm: fromModalWizardComponents.JobChangeStatusConfirm,
    selectJobType: fromModalWizardComponents.JobChangeStatusSelectJobType,
    suggestion: fromModalWizardComponents.JobChangeStatusSuggestion,
    selectTalentPool: fromModalWizardComponents.JobChangeStatusSelectTalentPool,
    createNewTalentPool: fromModalWizardComponents.JobChangeStatusCreateNewTalentPool,
    validation: fromModalWizardComponents.JobValidationErrors,
  },

  candidateAddToTalentPool: {
    addToTalentPool: fromModalWizardComponents.JobChangeStatusSelectTalentPool,
    createNewTalentPool: fromModalWizardComponents.JobChangeStatusCreateNewTalentPool,
  },

  qualificationTypeRemove: {
    pending: fromModalWizardComponents.qualificationTypeRemoveSteps.PendingModal,
    confirm: fromModalWizardComponents.qualificationTypeRemoveSteps.ConfirmModal,
    confirmWithQuestion: fromModalWizardComponents.qualificationTypeRemoveSteps.ConfirmModalWithQuestion,
    daxtraMapping: fromModalWizardComponents.qualificationTypeRemoveSteps.DaxtraMappingModal,
  },

  bulkCreation: {
    pending: fromModalWizardComponents.qualificationTypeRemoveSteps.PendingModal,
    createCandidates: fromBulkCreateCreationComponents.BulkCreateCandidates,
    createApplicants: fromBulkCreateCreationComponents.BulkCreateApplicants,
    createNewTalentPool: fromModalWizardComponents.JobChangeStatusCreateNewTalentPool,
    upload: fromBulkCreateCreationComponents.BulkUploading,
    success: fromBulkCreateCreationComponents.BulkSuccess,
  },

  hiringPipelineEdit: {
    hiringPipelineRename: HiringPipelineEditNameModal,
    hiringPipelineDuplicate: HiringPipelineEditNameModal,
    hiringPipelineAddNew: HiringPipelineEditNameModal,
    hiringPipelineEdit: HiringPipelineEditModal,
    hiringPipelineStageCreateModal: HiringPipelineStageCreateModal,
    hiringPipelineStageEditModal: HiringPipelineStageCreateModal,
    hiringPipelineStageRemoveModal: HiringPipelineStageRemoveModal,
    confirmPiplineMakeDefault: fromModalWizardComponents.RemoveConfirmModal,
    confirmPiplineDisable: fromModalWizardComponents.RemoveConfirmModal,
  },

  hiringPipelineDelete: {
    confirmHPD: fromModalWizardComponents.RemoveConfirmModal,
    selectHPD: fromModalWizardComponents.hiringPipelineDeleteSteps.HiringPipelineSelectModal,
  },

  emailTemplateDelete: {
    confirmETD: fromModalWizardComponents.RemoveConfirmModal,
    selectETD: fromModalWizardComponents.EmailTemplateSelectModal,
  },

  jobTeamEdit: {
    jobTeamView: JobTeamEditModal,
    jobTeamAddMember: JobTeamAddMemberModal,
    jobTeamRemoveMember: JobTeamRemoveMemberModal,
  },

  newCandidate: {
    pending: fromModalWizardComponents.qualificationTypeRemoveSteps.PendingModal,
    candidateForm: CreateNewCandidate,
    newTalentPoolForm: NewTalentPoolForWizard,
  },

  bulkAddToTalentTool: {
    pending: fromModalWizardComponents.PendingModal,
    selectTalentPoolForBulkAdd: BulkAddCandidateToTalentPool,
    createNewTalentPoolForBulkAdd: fromModalWizardComponents.JobChangeStatusCreateNewTalentPool,
  },

  bulkAddToTalentPool: {
    pending: fromModalWizardComponents.PendingModal,
    selectActionAddToTalentPool: AddCandidateToTalentPool,
    success: fromModalWizardComponents.PendingModal,
  },
};
