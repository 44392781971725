import React, { useCallback, useMemo } from 'react';

import { emailTemplateSelectors } from 'pages/Company/CompanyTabs/CompanyEmailTemplateTab';

import { TextEditor } from 'components/Form/TextEditor';
import { TextEditorProps } from 'components/Form/TextEditor/TextEditorProps';
import { getTranslate } from 'utils/i18utils';

import { useSelector } from 'store/rootSelectors';

import { ExEmailEditorConfig } from './ExEmailEditor.config';
import { addTinyMcePluginVariables } from './ExEmailVariableTinyMCEPlugin';

type EmailEditorProps = Omit<TextEditorProps, 'options'>;

export const ExEmailEditor: React.FC<EmailEditorProps> = ({ ...props }) => {
  const mergeFields = useSelector(emailTemplateSelectors.emailTemplateMergeFields);

  const menuItems = useCallback(
    (editor: any) =>
      mergeFields.map((fieldName) => ({
        type: 'menuitem',
        text: getTranslate(fieldName, { ns: 'emailTemplate' }),
        onAction: () => {
          editor.plugins.variables.addVariable(fieldName);
        },
      })),
    [mergeFields],
  );

  const contentStyle = useMemo(() => {
    return `.variable{cursor:default;background-color:#0B78FF;color:#FFF;padding:2px;border-radius:3px;font-weight: bold;font-style:normal;display:inline-block;}`;
  }, []);

  return (
    <TextEditor
      {...props}
      options={{
        ...ExEmailEditorConfig,
        content_style: contentStyle,
        variable_valid: [...mergeFields],
        setup(editor: any) {
          editor.ui.registry.addMenuButton('ExMergeFields', {
            text: 'Merge Fields',
            fetch: (callback: any) => {
              callback(menuItems(editor));
            },
          });

          addTinyMcePluginVariables(editor.editorManager);
        },
      }}
    />
  );
};
