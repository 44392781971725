import { useEffect } from 'react';

export const useKeyPress = (key: string, callback: Function) => {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.code === key) {
        const target = event.target as HTMLElement;
        const tagName = target.tagName.toLowerCase();

        if (!['textarea', 'input'].includes(tagName)) {
          callback();
        }
      }
    };

    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [callback, key]);
};
