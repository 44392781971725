export const jobs = {
  createDraft: 'jobs:createDraft',
  deleteDraft: 'jobs:deleteDraft',
  delete: 'jobs:delete',
  publishNew: 'jobs:publishNew',
  complete: 'jobs:complete ',
  cancel: 'jobs:cancel',
  downloadJobsReport: 'jobs:downloadJobsReport',
  view: 'jobs:view',
  edit: 'jobs:edit',
  hiringTeamAddMember: 'jobs:hiringTeam:addMember',
  hiringTeamRemoveMember: 'jobs:hiringTeam:removeMember',
  interviewTemplatesEdit: 'job:interviewTemplates:edit',
};

export const jobAds = {
  createDraftAd: 'jobAds:createDraftAd',
  edit: 'jobAds:edit',
  postAd: 'jobAds:postAd',
  rePostAd: 'jobAds:rePostAd',
  viewJobAd: 'jobAds:viewJobAd',
  stopJobAd: 'jobAds:stopJobAd',
  duplicateAd: 'jobAds:duplicate',
};

export const candidates = {
  create: 'candidates:createNewCandidate',
  editDetails: 'candidates:editCandidateDetails',
  editEducation: 'candidates:editCandidateEducation',
  editExperience: 'candidates:editCandidateExperience',
  editDocuments: 'candidates:editCandidateDocuments',
  editResumes: 'candidates:editCandidateResumes',
  view: 'candidates:view',
  viewCandidate: 'candidates:viewCandidate',
  addToTalentPool: 'candidates:addToTalentPool',
  bulkAddToTalentPool: 'candidates:bulkAddToTalentPool',
  removeFromTalentPool: 'candidates:removeFromTheTalentPool',
  comment: 'candidates:createCandidateComment',
};

export const applicants = {
  create: 'applicants:create',
  edit: 'applicants:edit',
  view: 'applicants:view',
  addToTalentPool: 'applicants:addToTalentPool',
};

export const jobBoards = {
  view: 'jobBoards:view',
  subscribe: 'jobBoards:subscribeToJobBoard',
  unsubscribe: 'jobBoards:unsubscribeFromJobBoard',
  edit: 'jobBoards:editSettings',
};

export const talentPools = {
  create: 'talentPools:create',
  edit: 'talentPools:edit',
  view: 'talentPools:view',
  remove: 'talentPools:remove',
  bulkRemoveCandidatesFromTalentPool: 'talentPools:bulkRemoveCandidatesFromTalentPool',
  actions: 'talentPools:actions',
};

export const placements = {
  view: 'placements:view',
  pushToExpedoHR: 'placements:pushToExpedoHR',
};

export const mainDashboard = {
  view: 'mainDashboard:view',
};

export const company = {
  view: 'company:view',
  create: 'company:createNewCompany',
  editSettings: 'company:editSettings',
  editPipeline: 'company:editPipeline',
  editPipelineStage: 'company:editPipelineStage',
  showIntegrationsTab: 'company:showIntegrationsTab',
};

export const users = {
  create: 'users:createNewUser',
  enable: 'users:enableUser',
  disable: 'users:disableUser',
};

export const interviews = {
  edit: 'interviews:edit',
  remove: 'interviews:remove',
  publish: 'interviews:publish',
};
