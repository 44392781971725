import { useMemo } from 'react';

import { ExBadgeVariant } from 'components/ui/ExBadge/ExBadgeProps';

export function useBadgeVariants<T = string | number>({
  status,
  variants,
}: {
  status: T | undefined;
  variants: Map<T, ExBadgeVariant>;
}) {
  const variant = useMemo(() => {
    const v = status !== undefined && variants.get(status);
    return v || ExBadgeVariant.default;
  }, [status, variants]);
  return { variant };
}
