import * as selectors from './applicant.selectors';
import * as effects from './applicant-effects';
import applicantFormSlice from './applicant-form.reducer';

export const applicantFormActions = { ...applicantFormSlice.actions };

export const applicantFormReducer = applicantFormSlice.reducer;

export const applicantFormSelectors = selectors;

export const applicantFormEffects = effects;

export default applicantFormReducer;
