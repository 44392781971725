import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import debounce from 'lodash/debounce';
import lodashGet from 'lodash/get';

import { AutocompleteResponse, CompanyPosition } from 'model';

import { FormSelect } from 'components/FormSelect';
import { ExVisible } from 'components/ui/ExVisible';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { companySelectors } from 'store/company/company.selectors';
import { useLazyCompanyPositionLoadMoreQuery } from 'store/dictionary/dictionary.api';
import { useCompanyPositionCategoriesQuerySelectors } from 'store/dictionary/hooks/useCompanyPositionCategoriesQuerySelectors';
import { useCompanyPositionQuerySelectors } from 'store/dictionary/hooks/useCompanyPositionQuerySelectors';

type PositionServiceFormProps = {
  categoryIdFieldName: string;
  categoryNameFieldName: string;
  positionIdFieldName: string;
  positionNameFieldName: string;
  categoryClassNames?: string;
  positionClassNames?: string;
  categoryFieldRequired?: boolean;
  positionFieldRequired?: boolean;
  categoryFieldDisabled?: boolean;
  positionFieldDisabled?: boolean;
  children?: never;
};

const usePositionServiceFormState = ({
  categoryIdFieldName,
  categoryNameFieldName,
  positionIdFieldName,
  positionNameFieldName,
  categoryClassNames,
  categoryFieldRequired,
  positionClassNames,
  positionFieldRequired,
  categoryFieldDisabled,
  positionFieldDisabled,
}: PositionServiceFormProps) => {
  const { setValue, register, formState, errors, trigger } = useFormContext();
  const isEnabledInHr = useAppSelector(companySelectors.selectIsEnabledInHr);

  const validated = formState.isSubmitted;

  const { selectors: positionCategoriesSelectors } = useCompanyPositionCategoriesQuerySelectors(undefined, {
    skip: isEnabledInHr,
  });

  const categories = useAppSelector(positionCategoriesSelectors.selectAll);

  const [positionSearchTerm, setPositionSearchTerm] = useState('');

  const selectedCategoryId = useWatch<string>({ name: categoryIdFieldName });
  const defaultPositionName = useWatch<string>({ name: positionNameFieldName });
  const defaultPositionId = useWatch<number>({ name: positionIdFieldName });

  const notCategoryAndDisabledHr = [!selectedCategoryId, !isEnabledInHr].every(Boolean);

  const positionPromise = useRef<Function | null>(null);

  const categoryIdParam = isEnabledInHr ? undefined : selectedCategoryId;

  const [lazyCompanyPositionLoadMoreQuery, { isFetching: isPositionLoadMoreLoading }] =
    useLazyCompanyPositionLoadMoreQuery();

  const {
    selectors: positionsSelectors,
    isFetching: isPositionQueryLoading,
    data,
  } = useCompanyPositionQuerySelectors(
    {
      categoryId: categoryIdParam,
      searchTerm: positionSearchTerm,
      pageNo: 0,
    },
    {
      skip: positionFieldDisabled || notCategoryAndDisabledHr,
    },
  );

  const isPositionLoading = isPositionQueryLoading || isPositionLoadMoreLoading;

  const dataRef = useRef(data);

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  const positionSearchTermRef = useRef(positionSearchTerm);

  useEffect(() => {
    positionSearchTermRef.current = positionSearchTerm;
  }, [positionSearchTerm]);

  const positions = useAppSelector(positionsSelectors.selectAll);

  useEffect(() => {
    if (typeof positionPromise.current === 'function' && positions && !isPositionLoading) {
      positionPromise.current(positions);
    }
  }, [positions, data, isPositionLoading]);

  const loadOptions = () => {
    return new Promise((res) => {
      positionPromise.current = res;
    });
  };

  const onMenuScrollToBottom = useCallback(() => {
    const pageNo = dataRef.current?.pageNo || 0;

    lazyCompanyPositionLoadMoreQuery({
      categoryId: categoryIdParam,
      searchTerm: positionSearchTermRef.current,
      pageNo: pageNo + 1,
    });
  }, [lazyCompanyPositionLoadMoreQuery, categoryIdParam]);

  const positionsOptions = useMemo(() => {
    const defaultPositions = [
      {
        positionId: defaultPositionId,
        positionName: defaultPositionName,
        categoryId: selectedCategoryId,
        categoryName: '',
      },
    ] as Array<CompanyPosition>;

    if ([positions.length === 0, isPositionLoading, defaultPositionId, defaultPositionName].every(Boolean)) {
      return defaultPositions;
    }

    return positionFieldDisabled ? defaultPositions : positions;
  }, [defaultPositionId, defaultPositionName, isPositionLoading, positionFieldDisabled, positions, selectedCategoryId]);

  const noOptionsPositionHandler = ({ inputValue }: { inputValue: string }) => {
    if (notCategoryAndDisabledHr) {
      return 'First select Position Category';
    }

    if (inputValue.length === 0) {
      return 'Start typing';
    }

    return 'No Options';
  };

  const onChangePositionCategoryHandler = (option?: AutocompleteResponse | null) => {
    setValue(categoryNameFieldName, option?.name, { shouldDirty: true });
    setValue(categoryIdFieldName, option?.id, { shouldDirty: true });
    // Reset slave field
    setValue(positionIdFieldName, undefined, { shouldValidate: validated });
    setValue(positionNameFieldName, null);
    // Trigger validation
    trigger([categoryNameFieldName, categoryIdFieldName]);
  };

  const onChangePositionCategory = (option: CompanyPosition) => {
    setPositionSearchTerm('');
    setValue(positionNameFieldName, option?.positionName, { shouldDirty: true });
    setValue(positionIdFieldName, option?.positionId, { shouldDirty: true });
    setValue(categoryNameFieldName, option?.categoryName, { shouldDirty: true });
    setValue(categoryIdFieldName, option?.categoryId, { shouldDirty: true });
    // Trigger validation
    trigger([positionNameFieldName, positionIdFieldName, categoryNameFieldName, categoryIdFieldName]);
  };

  const onInputChangePositionCategory = useMemo(
    () =>
      debounce((value: string) => {
        setPositionSearchTerm(value);
      }, 500),
    [],
  );

  const filterOption = useCallback((options) => {
    return options;
  }, []);

  return {
    categories,
    categoryClassNames,
    categoryFieldDisabled,
    categoryFieldRequired,
    categoryIdFieldName,
    categoryNameFieldName,
    errors,
    isPositionLoading,
    noOptionsPositionHandler,
    onChangePositionCategory,
    onChangePositionCategoryHandler,
    onInputChangePositionCategory,
    positionClassNames,
    positionFieldDisabled,
    positionFieldRequired,
    positionIdFieldName,
    positionNameFieldName,
    positionsOptions,
    register,
    validated,
    isEnabledInHr,
    loadOptions,
    onMenuScrollToBottom,
    filterOption,
  };
};

export const PositionServiceForm: React.FC<PositionServiceFormProps> = (props) => {
  const {
    categoryIdFieldName,
    categoryNameFieldName,
    positionIdFieldName,
    positionNameFieldName,
    categoryClassNames,
    categoryFieldRequired,
    positionClassNames,
    positionFieldRequired,
    categoryFieldDisabled,
    positionFieldDisabled,
    categories,
    validated,
    errors,
    noOptionsPositionHandler,
    isPositionLoading,
    register,
    positionsOptions,
    onChangePositionCategoryHandler,
    onChangePositionCategory,
    onInputChangePositionCategory,
    isEnabledInHr,
    loadOptions,
    onMenuScrollToBottom,
    filterOption,
  } = usePositionServiceFormState(props);

  return (
    <>
      <ExVisible visible={!isEnabledInHr}>
        <FormSelect
          name={categoryIdFieldName}
          className={categoryClassNames}
          options={categories}
          defaultOptions={categories}
          required={categoryFieldRequired}
          label="Position Category"
          placeholder="Position Category"
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          onChange={onChangePositionCategoryHandler}
          openMenuOnFocus
          isSearchable
          validated={validated}
          errors={lodashGet(errors, categoryIdFieldName)}
          isDisabled={categoryFieldDisabled}
        />
      </ExVisible>
      <FormSelect
        name={positionIdFieldName}
        options={positionsOptions}
        className={positionClassNames}
        required={positionFieldRequired}
        label="Position"
        placeholder="Position Name"
        getOptionLabel={(option) => option.positionName}
        getOptionValue={(option) => option.positionId}
        openMenuOnFocus
        isSearchable
        loadOptions={loadOptions}
        onChange={onChangePositionCategory}
        onInputChange={onInputChangePositionCategory}
        validated={validated}
        errors={lodashGet(errors, positionIdFieldName)}
        isLoading={isPositionLoading}
        noOptionsMessage={noOptionsPositionHandler}
        isDisabled={positionFieldDisabled}
        filterOption={filterOption}
        cacheOptions={false}
        onMenuScrollToBottom={onMenuScrollToBottom}
      />
      <input name={positionNameFieldName} ref={register} hidden />
      <input name={categoryNameFieldName} ref={register} hidden />
    </>
  );
};
