import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { JobApplicantsByStageType } from 'model';
import { JobStatus, PipelineStageType } from 'model/api-enums.constants';
import { AppRouterParams } from 'model/router';

import { useSinglePipeline } from 'pages/Jobs/Job/hooks/useSinglePipeline';

import { applicantListActions } from 'containers/ApplicantLists/store';
import { jobFormSelectors } from 'containers/JobForms/state';
import { modalsActions } from 'containers/Modals/store';

import { pickAndRename } from 'utils/funcs';
import { getUserPosition } from 'utils/hooks/auth';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { hiringPipelinesStagesSelectors } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.reducer';
import { hiringPipelineSelectors } from 'store/entities/hiring-pipelines/hiring-pipelines.reducer';
import { Job, jobsSelectors, JobTeamMember } from 'store/entities/jobs';
import { loadJobPage } from 'store/entities/jobs/job.actions';
import { useJobApplicantsPerPipelineStageFiguresQuery } from 'store/entities/jobs/jobs.api';
import { jobInterviewTabListUiSlice } from 'store/ui/jobs/job-interview-tab-list/job-interview-tab-list.reducer';

type JobAnalyticsView = {
  name: string;
  count: number;
};

export function getAnalyticsBySteps(jobApplicantsByStageType?: JobApplicantsByStageType[]) {
  const pipelineStageTypes: Array<PipelineStageType> = [
    PipelineStageType.New,
    PipelineStageType.Shortlisted,
    PipelineStageType.Interviewed,
    PipelineStageType.Screened,
    PipelineStageType.InOnboarding,
    PipelineStageType.Hired,
  ];

  return pipelineStageTypes.map((pipelineStageType) => {
    const applicantsPerStep = jobApplicantsByStageType || [];
    const applicantsCount = applicantsPerStep.find((applicants) => applicants.stageType === pipelineStageType);

    return {
      count: applicantsCount?.value ?? 0,
      name: PipelineStageType[pipelineStageType],
    };
  });
}

export function getAnalyticsObject(analytics: JobAnalyticsView[]) {
  return analytics.reduce(analyticsToObjectReducer, {} as Record<string, number>);
}

function analyticsToObjectReducer(acc: Record<string, number>, item: JobAnalyticsView) {
  acc[item.name] = item.count;
  return acc;
}

export function useJobAnalytics(jobId: Job['jobId']) {
  const { data } = useJobApplicantsPerPipelineStageFiguresQuery({ jobId });
  const jobApplicantsPerPipelineStageFigures = data?.jobApplicantsPerPipelineStageFigures;

  return jobApplicantsPerPipelineStageFigures?.map(({ value, ...rest }) => ({ count: value, ...rest })) ?? [];
}

export function useJobAnalyticsTotal(jobId: Job['jobId']) {
  const { data } = useJobApplicantsPerPipelineStageFiguresQuery({ jobId });
  const jobApplicantsPerPipelineStageFigures = data?.jobApplicantsPerPipelineStageFigures;

  return (
    jobApplicantsPerPipelineStageFigures?.reduce(
      (acc, { value }) => {
        acc.count += value;
        return acc;
      },
      { count: 0, name: 'Total' },
    ) ?? { count: 0, name: 'Total' }
  );
}

export function useJobAnalyticsAsObject(jobId: Job['jobId']) {
  const analytics = useJobAnalytics(jobId);

  return getAnalyticsObject(analytics);
}

export function useJobRedux(jobId?: Job['id']) {
  return useAppSelector(jobsSelectors.getById, jobId);
}

export function useJobFromRouter() {
  const { jobId } = useParams<AppRouterParams>();
  const job = useJobRedux(jobId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!job) {
      dispatch(loadJobPage({ jobId }));
    }
  }, [dispatch, job, jobId]);

  return job;
}

export function useJob(jobIdFromProps?: Job['id']) {
  const dispatch = useAppDispatch();
  const { jobId: jobIdFromRouterParams } = useParams<AppRouterParams>();

  const jobId = jobIdFromProps || jobIdFromRouterParams;

  const job = useJobRedux(jobId);
  const needToLoadEntity = (!job || !job.salary || !job.pipelineId) && jobId;

  useEffect(() => {
    dispatch(modalsActions.setJobId({ jobId: jobId ?? '' }));
    dispatch(jobInterviewTabListUiSlice.actions.resetState({ jobId: jobId ?? null }));
    if (needToLoadEntity) {
      dispatch(loadJobPage({ jobId }));
    }
    return () => {
      if (!needToLoadEntity) {
        dispatch(applicantListActions.resetState());
      }
    };
  }, [dispatch, jobId, needToLoadEntity]);

  return job;
}

export function useJobCountry(jobId?: Job['id']) {
  const job = useJob(jobId);
  const formData = useAppSelector(jobFormSelectors.selectFormData);

  return job?.countryHiringFrom ?? formData.countryHiringFrom;
}

export function useJobEmploymentType(jobId?: Job['id']) {
  const job = useJob(jobId);
  const formData = useAppSelector(jobFormSelectors.selectFormData);

  return job?.employmentType ?? formData.employmentType;
}

type UseTeamMemberPosition = {
  jobId: string;
  userId: string;
  member?: JobTeamMember;
};

export function useTeamMemberPosition({ jobId, userId, member }: UseTeamMemberPosition) {
  const teamMemberRedux = useAppSelector(jobsSelectors.selectTeamMemberById, { jobId, userId });
  const teamMember = member ?? teamMemberRedux;

  const position = getUserPosition(teamMember?.applicationRoles);

  return position?.applicationRoleName;
}

export function useIsJobActive(jobId: string) {
  const job = useJob(jobId);

  return job?.status === JobStatus.open;
}

export function useApplicantFiltersForJob(jobId?: string) {
  const { jobId: jobIdFromRouterParams } = useParams<AppRouterParams>();

  return useAppSelector(
    hiringPipelinesStagesSelectors.selectApplicantListFilterOptionsByJobId,
    jobId ?? jobIdFromRouterParams,
  );
}

export function useApplicantFiltersPipelineStagesForJob() {
  const { jobId: jobIdFromRouterParams } = useParams<AppRouterParams>();

  const pipelineId = useAppSelector(hiringPipelineSelectors.selectPipelineIdByJobId, jobIdFromRouterParams);

  useSinglePipeline({ pipelineId });

  const pipelineStages = useAppSelector(hiringPipelinesStagesSelectors.selectByPipelineIdSortedByOrder, pipelineId);

  return [
    {
      label: 'All Statuses',
      value: undefined,
    },
    ...pipelineStages.map(
      pickAndRename([
        ['name', 'label'],
        ['pipelineStageId', 'value'],
      ]),
    ),
  ];
}
