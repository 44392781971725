import { ExScreeningQuestion, Location } from 'model';
import { Frequency } from 'model/api-enums.constants';

import { AvailabilityObject, CandidateSocialNetwork } from 'store/entities/candidates/models';

export enum PublicUploadTypes {
  photo = 'photo',
  resume = 'resume',
  document = 'document',
  documentResume = 'documentResume',
}

export type PublicUploaderProps = {
  type: PublicUploadTypes;
  files: File[];
};

export type PublicApplicantForm = {
  companyId: string;
  jobId: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  socialNetworks: CandidateSocialNetwork[];
  salaryExpectationCurrency: string;
  salaryExpectationFrequency: Frequency;
  salaryExpectationAmount: number;
  jobApplicantAvailability: AvailabilityObject | null;
  location: Location;
  photoId: string;
  resumeId: string | null;
  documentId: string | null;
  screeningQuestions: Record<string, ExScreeningQuestion['answer']>;
  note: string;
};
