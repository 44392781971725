/* eslint-disable sonarjs/no-duplicate-string */
import { useCallback, useState } from 'react';

import { ScreeningQuestionViewProps } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionView/ScreeningQuestionViewProps';

import { modalsSelectors } from 'containers/Modals/store';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getTranslate } from 'utils/i18utils';

import { jobScreeningQuestionsActions } from 'store/entities/job-screening-questions';
import { useUpdateJobScreeningQuestionMutation } from 'store/entities/screening-question/screening-question.api';

export const useScreeningQuestionYesNoState = ({ className, editable, question }: ScreeningQuestionViewProps) => {
  const isLoading = false;
  const disabled = !editable || isLoading;

  const [answer, setAnswer] = useState(question?.answer);

  const labels = {
    yes: getTranslate('interviewTemplate.questionMandatory.yes'),
    no: getTranslate('interviewTemplate.questionMandatory.no'),
  };

  const onSubmit = useCallback((e) => {
    const newAnswer = e.target.value === 'true';
    setAnswer(newAnswer);
  }, []);

  return {
    className,
    disabled,
    inputName: question.itemId,
    question,
    labels,
    answer,
    onSubmit,
    isLoading,
  } as const;
};

export const useJobScreeningQuestionYesNoState = ({ className, editable, question }: ScreeningQuestionViewProps) => {
  const [updateJobScreeningQuestionMutation] = useUpdateJobScreeningQuestionMutation();

  const isLoading = false;
  const disabled = !editable || isLoading;

  const answer = question.isMandatory;
  const questionId = question.itemId;
  const questionTitle = question.questionTitle;

  const jobId = useAppSelector(modalsSelectors.modalJobId)!;

  const labels = {
    yes: getTranslate('interviewTemplate.questionMandatory.yes'),
    no: getTranslate('interviewTemplate.questionMandatory.no'),
  };

  const onSubmit = useCallback(
    (e) => {
      const newAnswer = e.target.value === 'true';

      updateJobScreeningQuestionMutation({
        jobId,
        questionId,
        questionTitle,
        isMandatory: newAnswer,
      });
    },
    [jobId, questionId, questionTitle, updateJobScreeningQuestionMutation],
  );

  const inputName = 'mandatory' + questionId;

  return {
    className,
    disabled,
    inputName,
    question,
    labels,
    answer,
    onSubmit,
    isLoading,
  } as const;
};

export const useCreateJobScreeningQuestionYesNoState = ({
  className,
  editable,
  question,
}: ScreeningQuestionViewProps) => {
  const dispatch = useAppDispatch();

  const isLoading = false;
  const disabled = !editable || isLoading;

  const answer = question.isMandatory;
  const questionId = question.itemId;

  const labels = {
    yes: getTranslate('interviewTemplate.questionMandatory.yes'),
    no: getTranslate('interviewTemplate.questionMandatory.no'),
  };

  const onSubmit = useCallback(
    (e) => {
      const newAnswer = e.target.value === 'true';

      dispatch(
        jobScreeningQuestionsActions.updateOne({
          id: questionId,
          changes: {
            isMandatory: newAnswer,
          },
        }),
      );
    },
    [dispatch, questionId],
  );

  const inputName = 'mandatory' + questionId;

  return {
    className,
    disabled,
    inputName,
    question,
    labels,
    answer,
    onSubmit,
    isLoading,
  } as const;
};
