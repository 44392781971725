import { createSlice } from '@reduxjs/toolkit';

import { WithRequired } from 'model/utils';

import { FormJobAdCaseReducer, formJobAdInitialState, JobAdFormState } from 'containers/JobAdForms/state/models';
import * as reducers from 'containers/JobAdForms/state/reducers/index';

import { JobAd } from 'store/entities/job-ads/models';

export const formJobAdKey = 'formJobAd';

type FormJobAdCaseReducers = {
  clearForm: typeof reducers.clearForm;
  toggleShowSteps: typeof reducers.toggleShowSteps;
  saveForm: typeof reducers.saveForm;
  setApiErrors: typeof reducers.setApiErrors;
  updateState: FormJobAdCaseReducer<Partial<JobAdFormState>>;
  updateAdditionFieldsViewByJobBoardId: FormJobAdCaseReducer<
    WithRequired<JobAd, 'jobBoardId' | 'jobAdId'> & {
      postSuccess: boolean;
      additionFieldView?: JobAd['additionalFieldsView'];
    }
  >;
};

const jobAdFormSlice = createSlice<JobAdFormState, FormJobAdCaseReducers>({
  initialState: formJobAdInitialState,
  name: formJobAdKey,
  reducers,
});

export const jobAdFormActions = jobAdFormSlice.actions;

export const jobAdFormReducer = jobAdFormSlice.reducer;

export default jobAdFormSlice;
