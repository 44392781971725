import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { minimalJobDescriptionLength } from 'containers/JobForms';

import { TextEditor } from 'components/Form/TextEditor';
import { StepFormBody } from 'components/StepForm';
import { useFormDescription } from 'utils/hooks/formsHooks/useFormDescription';
import { getTranslate } from 'utils/i18utils';

import { JobAd } from 'store/entities/job-ads/models';

export interface JobAdDescriptionFormBodyProps extends StepFormBody {
  className?: string;
  formData?: Partial<JobAd>;
}

export const JobAdDescriptionFormBodyStyled = styled.div``;
const JobAdDescriptionDisclaimer = styled.p`
  padding: 5px 0;
  margin: 0;
`;

export const JobAdDescriptionFormBody: React.FC<JobAdDescriptionFormBodyProps> = ({ className }) => {
  const { errors, formState } = useFormContext<JobAd>();
  const validated = formState.isSubmitted;
  const errorsByName = errors['description'];

  const { description: jobDescription, handlerChangeDescription, inputRef } = useFormDescription();

  return (
    <JobAdDescriptionFormBodyStyled className={className}>
      <JobAdDescriptionDisclaimer className="mb-1">
        {getTranslate('text_must_be_at_least', {
          fieldName: 'Description',
          min: minimalJobDescriptionLength,
          count: minimalJobDescriptionLength,
        })}
      </JobAdDescriptionDisclaimer>

      <TextEditor
        onChange={handlerChangeDescription}
        value={jobDescription ?? ''}
        errors={errorsByName}
        validated={validated}
        inputRef={inputRef}
      />
      <JobAdDescriptionDisclaimer>{getTranslate('new_job_ad.step_3.disclaimer')}</JobAdDescriptionDisclaimer>
    </JobAdDescriptionFormBodyStyled>
  );
};
