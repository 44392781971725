import type { Candidate } from 'store/entities/candidates/models';

export function computeCandidateName<T extends Candidate>(candidate?: T) {
  const name = candidate?.fullName;
  const lastName = candidate?.lastName ?? '';
  const firstName = candidate?.firstName ?? '';

  const computedName = name ?? `${firstName} ${lastName}`;

  return computedName.trim();
}
