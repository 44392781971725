import { nanoid } from '@reduxjs/toolkit';

import { ExQuestion } from 'model';

import { InterviewTemplateEditFormCaseReducer } from 'containers/InterviewTemplateForms/InterviewTemplateEditForm/state/InterviewTemplateEditForm.models';

import { interviewTemplateQuestionsAdapter } from 'store/entities/interview-template-questions/interview-template-questions.api';

const reducer: InterviewTemplateEditFormCaseReducer<ExQuestion> = (draft, { payload }) => {
  interviewTemplateQuestionsAdapter.upsertOne(draft.formData.questions, payload);
};

export const interviewTemplateEditFormUpdateQuestionReducer = {
  reducer,
  prepare: ({ questionId, ...newQuestion }: ExQuestion) => {
    const draftId = questionId === 'new' ? nanoid() : questionId;
    return {
      payload: {
        ...newQuestion,
        questionId: draftId,
      },
    };
  },
};
