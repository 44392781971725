import { createEntityAdapter } from '@reduxjs/toolkit';

import { providesList, transformListResponse } from 'api-client/axiosBaseQuery';
import { replaceParamsInUrl } from 'api-client/utils';
import * as fromCandidateDto from 'api-endpoints/candidate/candidate.dto';
import { CandidateEndpointsForApi } from 'api-endpoints/candidate/candidate.endpoints';

import { Candidate } from 'store/entities/candidates/models';
import { emptyApi } from 'store/entities/emptyApi';

export const candidateAdapter = createEntityAdapter<fromCandidateDto.SearchCandidatesListItem>({
  selectId: (candidate) => candidate.candidateId,
});

const candidatesApiInjected = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    candidate: builder.query<Candidate, Candidate['candidateId']>({
      queryFn: async (candidateId, bsApi, extra, baseQuery) => {
        const { data, error } = await baseQuery({
          method: 'GET',
          url: replaceParamsInUrl(CandidateEndpointsForApi.GetCandidateFunc, { candidateId }),
        });
        if (error) {
          return { error };
        }

        return {
          data: {
            ...(data as Candidate),
            id: candidateId,
            candidateId,
          },
        };
      },
    }),
    candidateList: builder.query<
      fromCandidateDto.SearchCandidatesFuncResponse,
      fromCandidateDto.SearchCandidatesFuncRequestParams
    >({
      query: (args) => ({
        method: 'POST',
        url: replaceParamsInUrl(CandidateEndpointsForApi.SearchCandidatesFunc, {}),
        data: args,
      }),
      transformResponse: transformListResponse(candidateAdapter),
    }),
    candidateListLoadMore: builder.query<
      fromCandidateDto.SearchCandidatesFuncResponse,
      fromCandidateDto.SearchCandidatesFuncRequestParams
    >({
      query: (args) => ({
        method: 'POST',
        url: replaceParamsInUrl(CandidateEndpointsForApi.SearchCandidatesFunc, {}),
        data: { ...args },
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch, getCacheEntry }) => {
        try {
          await queryFulfilled;

          const { data } = getCacheEntry();
          if (data) {
            dispatch(
              candidatesApiInjected.util.updateQueryData('candidateListLoadMore', { ...args, pageNo: 0 }, (draft) => {
                if (data.entities) {
                  candidateAdapter.upsertMany(draft, candidateAdapter.getSelectors().selectAll(data));
                }
                draft.pageCount = data.pageCount;
                draft.pageNo = data.pageNo;
              }),
            );
          }
        } catch (error) {}
      },
      transformResponse: transformListResponse(candidateAdapter),
    }),
  }),
});

export const candidatesApi = candidatesApiInjected.enhanceEndpoints({
  addTagTypes: ['Candidates'],
  endpoints: {
    candidate: {
      providesTags: (result) => (result ? [{ type: 'Candidates', id: result.candidateId }] : []),
    },
    candidateList: {
      providesTags: (result) => providesList(result?.ids, 'Candidates'),
    },
  },
});
