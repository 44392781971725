import { initialState } from 'containers/JobLists/store/initialState';

import { createListReducer } from 'utils/reducer/reducer-helper';

import { UIKeys } from 'store/constants';

export default createListReducer({
  initialState,
  name: UIKeys.jobLists,
  reducers: {},
});
