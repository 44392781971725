import * as candidateApi from 'api-endpoints/candidate';
import * as talentPoolApi from 'api-endpoints/talent-pool';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { candidateListActions, candidateListSelectors } from 'containers/CandidateLists/store/index';
import { mapCandidates } from 'containers/CandidateLists/store/mappers';
import { CandidateListState } from 'containers/CandidateLists/store/models';
import { loaderActions } from 'containers/Loader/store';

import { startLoaderThunk, stopLoaderThunk } from 'modules/LoaderManager/redux';
import { mapComplexListIdToMethod } from 'utils/list';

import { ViewMode } from 'store/constants';
import { candidatesActions } from 'store/entities/candidates';
import { CandidateBelongsTo } from 'store/entities/candidates/models';
import { RootState } from 'store/rootReducer';
import { AppDispatch } from 'store/types';

export const loadCandidatesTaskId = 'loadCandidatesTask';

const mapCandidateListId = mapComplexListIdToMethod({
  [CandidateBelongsTo.talentPool]: talentPoolApi.getTalentPoolCandidates,
  [CandidateBelongsTo.newApplicant]: candidateApi.getCandidates,
  [CandidateBelongsTo.main]: candidateApi.getCandidates,
});

export const loadCandidates =
  ({ listId }: { listId: CandidateListState['id'] }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    startLoaderThunk(dispatch, loadCandidates);
    const state = getState();
    const params = candidateListSelectors.getListParams(state, { id: listId });
    const belongsTo = params?.filters?.belongsTo;
    const apiMethod = mapCandidateListId(belongsTo || listId);
    if (!apiMethod) return false;
    dispatch(loaderActions.start(loadCandidatesTaskId));
    const { message, data } = await apiMethod(params);
    if (message) {
      dispatch(loaderActions.stop(loadCandidatesTaskId));
      return dispatch(alertsEffects.showError({ message }));
    }
    const { candidateIds, candidates } = mapCandidates(data?.items || []);
    dispatch(candidatesActions.upsert({ items: candidates }));
    dispatch(
      candidateListActions.fetchSuccess({
        id: listId,
        items: candidateIds,
        pageCount: data?.pageCount ?? 0,
        totalItemsCount: data?.totalItemsCount,
        filter: data?.filter,
      }),
    );
    dispatch(loaderActions.stop(loadCandidatesTaskId));
    stopLoaderThunk(dispatch, loadCandidates);
  };

export const changeViewMode =
  (listId: CandidateListState['id'], viewMode: ViewMode, pushHistory?: Function) => async (dispatch: AppDispatch) => {
    dispatch(candidateListActions.updateViewMode({ id: listId, viewMode }));
    dispatch(candidateListActions.clearItems({ id: listId }));
    if (typeof pushHistory === 'function') pushHistory();
  };

const mapCandidateAutocomplete = mapComplexListIdToMethod({
  [CandidateBelongsTo.talentPool]: talentPoolApi.talentPoolCandidateAutocomplete,
  [CandidateBelongsTo.main]: candidateApi.candidateAutocomplete,
  [CandidateBelongsTo.newApplicant]: candidateApi.candidateAutocomplete,
});

export const candidateAutocomplete =
  (listId: string) => (dispatch: AppDispatch, getState: () => RootState) => async (searchTerm: string) => {
    const state = getState();
    const params = candidateListSelectors.getListParams(state, { id: listId });
    const belongsTo = params?.filters?.belongsTo;
    const apiMethod = mapCandidateAutocomplete(belongsTo || listId);
    if (!apiMethod) return false;
    const { message, data } = await apiMethod(searchTerm);
    if (message) {
      dispatch(alertsEffects.showError({ message }));
      return [];
    }
    return data;
  };
