import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';

import { TabLineProps } from 'components/TabMenu/TabLine';
import { NavItem, TabMenuProps } from 'components/TabMenu/TabMenuProps';

function getActiveItem(navItems: NavItem[], path: string | undefined) {
  return navItems.find((e) => path === e.path) || null;
}

function getCurrentLocationPath(location: Location<unknown>) {
  return location.pathname.split('/').pop();
}

function getActiveElement(refs: Set<HTMLAnchorElement>, location: Location<unknown>): HTMLAnchorElement {
  const array = Array.from(refs);
  return array.find((e) => location.pathname.indexOf(e.pathname) > -1) || array[0];
}

/**
 * Returns default TabLine position.
 * Function wrapper needed to prevent pass object by reference.
 */
const getDefaultPosition = () => ({
  bottom: undefined,
  height: 0,
  left: 0,
  top: undefined,
  width: 0,
});

export function useTabLine({ navItems }: TabMenuProps) {
  const [, setCurrentTarget] = useState<HTMLElement | null>(null);
  const [activeItem, setActiveItem] = useState<NavItem | null>(null);
  const location = useLocation();

  const handlerSelectTab = useCallback((eventKey: string, e: SyntheticEvent<unknown>) => {
    const event = e as SyntheticEvent<HTMLAnchorElement>;

    /**
     * This hack is needed to update the hook state and run use effect
     */
    setCurrentTarget(event.currentTarget);
  }, []);

  useEffect(() => {
    const path = getCurrentLocationPath(location);
    const activeNavItem = getActiveItem(navItems, path);
    setActiveItem(activeNavItem);
  }, [location, navItems]);

  return { activeItem, handlerSelectTab };
}

export const useTabLinePosition = ({ navItems, vertical, parentRef }: TabMenuProps) => {
  const [position, setPosition] = useState<Partial<TabLineProps>>(getDefaultPosition());
  const location = useLocation();

  /**
   * This hack is needed to draw nav tab border after full page rendering
   */
  const firstPageRender = useRef(true);

  /**
   * List of TabMenu elements refs
   */
  const refs = useRef(new Set<HTMLAnchorElement>());

  const recalculateTabLinePosition = useCallback(() => {
    const element = getActiveElement(refs.current, location);
    const timeout = firstPageRender.current ? 1000 : 100;

    if (!element) {
      return;
    }
    // this code calculate the current position of TabLine
    return setTimeout(() => {
      const { offsetLeft, clientWidth, offsetTop, clientHeight } = element;
      if (!vertical) {
        setPosition({ bottom: 0, left: offsetLeft, width: clientWidth });
      } else {
        setPosition({ height: clientHeight, left: 0, top: offsetTop });
      }
      firstPageRender.current = false;
    }, timeout);
  }, [location, vertical]);

  /**
   * Subscribe to Nav menu resizing to recalculate tab line position.
   */
  useEffect(() => {
    const timers: any[] = [];

    const parentElementRef = parentRef?.current;
    // ResizeObserver require types from @types/resize-observer-browser
    const obs = new ResizeObserver(() => {
      const timer = recalculateTabLinePosition();
      timers.push(timer);
    });
    if (parentElementRef) {
      obs.observe(parentElementRef);
    }

    return () => {
      if (parentElementRef) {
        obs.unobserve(parentElementRef);
      }
      timers.forEach(clearTimeout);
    };
  }, [location, navItems, parentRef, vertical, recalculateTabLinePosition]);

  useEffect(() => {
    const timer = recalculateTabLinePosition();

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [location, vertical, navItems, refs, recalculateTabLinePosition]);

  /**
   * Generate an array of React Refs of menu item
   * @param element
   */
  const handlerRefs = useCallback((element: any) => {
    if (element) {
      refs.current.add(element);
    }
  }, []);

  return {
    position,
    handlerRefs,
  };
};
