import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as EditIcon } from 'assets/img/edit.svg';

import { JobAdAdditionalDetailsRowProps } from 'components/DetailsCard';
import { FormCard } from 'components/FormCard';

import {
  JobAdDetailsCard,
  JobAdDetailsEditText,
  JobAdDetailsHeaderWrapper,
  JobAdDetailsLabel,
  JobAdDetailsRow,
  JobAdDetailsTitle,
  RowComponent,
} from './JobAdAdditionalDetailsCardComponents';

export interface JobAdAdditionalDetailsCardProps {
  className?: string;
  rows: JobAdAdditionalDetailsRowProps[];
  title?: string;
  editUrl?: string;
  editAction?: (event: React.MouseEvent) => void;
  isCard?: boolean;
}

const JobAdAdditionalDetailsCard: React.FC<JobAdAdditionalDetailsCardProps> = ({
  className,
  rows,
  title,
  editUrl,
  editAction,
  isCard,
}): React.ReactElement => {
  return (
    <JobAdDetailsCard className={className}>
      <JobAdDetailsHeaderWrapper title={title}>
        {title && <JobAdDetailsTitle>{title}</JobAdDetailsTitle>}
        {editUrl && (
          <JobAdDetailsEditText>
            <Link to={`${editUrl}`} className="text-reset" onClick={editAction}>
              <EditIcon /> Edit
            </Link>
          </JobAdDetailsEditText>
        )}
      </JobAdDetailsHeaderWrapper>
      {isCard && (
        <div className="overflow-hidden">
          <FormCard.FormCardBodyDivider />
        </div>
      )}
      <div className="row">
        {rows.map((row, i) => (
          <JobAdDetailsRow key={row.label + '_' + row.value + '_' + i} className="col-md-12">
            <JobAdDetailsLabel>{row.label}</JobAdDetailsLabel>
            <div>
              <RowComponent row={row} />
            </div>
          </JobAdDetailsRow>
        ))}
      </div>
    </JobAdDetailsCard>
  );
};

export { JobAdAdditionalDetailsCard };
