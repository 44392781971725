import React from 'react';
import styled from 'styled-components/macro';

type EmptyStateProps = {
  className?: string;
  children?: React.ReactNode;
  icon?: JSX.Element;
  title?: string;
};

const EmptyStateStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmptyStateIcon = styled.div`
  margin-bottom: 17px;
`;

const EmptyStateTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  white-space: pre-line;
`;

const EmptyStateContent = styled.div`
  margin-top: 20px;
`;

const useEmptyStateState = ({ className, children, icon, title }: EmptyStateProps) => {
  return {
    className,
    children,
    icon,
    title,
  } as const;
};

export const EmptyState: React.FC<EmptyStateProps> = (props) => {
  const { className, children, icon, title } = useEmptyStateState(props);

  return (
    <EmptyStateStyled className={className}>
      {icon && <EmptyStateIcon>{icon}</EmptyStateIcon>}
      <EmptyStateTitle>{title}</EmptyStateTitle>
      {children && <EmptyStateContent>{children}</EmptyStateContent>}
    </EmptyStateStyled>
  );
};
