import { createSelector } from '@reduxjs/toolkit';

import { UIKeys } from 'store/constants';
import { RootState } from 'store/rootReducer';
import { uiDomain } from 'store/rootSelectors';

const domain = createSelector(uiDomain, (state) => state[UIKeys.modals]);

export const modalJobId = createSelector(domain, (state) => state.jobId);

export const modalJobAdId = createSelector(domain, (state) => state.jobAdId);

export const modalJobBoardId = createSelector(domain, (state) => state.jobBoardId);

export const modalTalentPoolId = createSelector(domain, (state) => state.talentPoolId);

export const modalCandidateId = createSelector(domain, (state) => state.candidateId);

export const modalEducationId = createSelector(domain, (state) => state.educationId);

export const modalWorkExperienceId = createSelector(domain, (state) => state.workExperienceId);
export const modalInterviewTemplateId = createSelector(domain, (state) => state.interviewTemplateId);
export const modalInterviewId = createSelector(domain, (state) => state.interviewId);
export const modalApplicantId = createSelector(domain, (state) => state.applicantId);

const modalIdFn = (_: RootState, { modalId }: { modalId?: string }) => modalId;

export const modalPayloads = createSelector(domain, (state) => state.payloads);

export const payloadByModalId = createSelector(modalPayloads, modalIdFn, (payloads, modalId) =>
  payloads.filter((payload) => payload.id === modalId).map((r) => ({ ...r.payload })),
);

export const payloadLast = createSelector(payloadByModalId, (payloads) => payloads[payloads.length - 1]);

export const isModalOpen = createSelector(
  domain,
  modalIdFn,
  (state, modalId) => !!modalId && state.modalType.includes(modalId),
);
