import talentPoolListSlice from './reducers';
import * as selectors from './selectors';

const { reducer, actions } = talentPoolListSlice;

export const talentPoolListActions = actions;

export const talentPoolListSelectors = selectors;

export default reducer;

export * from './models';
