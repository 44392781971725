import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { adapterHelper } from 'utils/reducer/adapter-helper';

import { EntityKeysNew } from 'store/constants';
import { CandidateWorkExperience } from 'store/entities/candidate-work-experience/candidate-work-experience.models';

export const candidateWorkExperiencesAdapter = createEntityAdapter<CandidateWorkExperience>({
  selectId: (item) => item.workExperienceId,
});

export const candidateWorkExperienceSlice = createSlice({
  name: EntityKeysNew.candidateWorkExperiences,
  initialState: candidateWorkExperiencesAdapter.getInitialState(),
  reducers: {
    ...adapterHelper(candidateWorkExperiencesAdapter),
  },
});
