import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Spinner } from 'components/Spinner';

import { loaderSelectors } from './store';

const SpinnerWrapper = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
  background-color: rgba(253, 206, 206, 0.1);
`;

export const Loader = () => {
  const show = useSelector(loaderSelectors.hasSomeActiveTask);
  return show ? (
    <SpinnerWrapper>
      <Spinner />
    </SpinnerWrapper>
  ) : null;
};
