import { call, put, putResolve, select, take, takeEvery } from 'redux-saga/effects';

import { alertsEffects, apiCallError } from 'containers/AlertManager/store/alert.actions';
import { createEditPipelineLoaderId, loaderActions } from 'containers/Loader/store';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { getTranslate } from 'utils/i18utils';
import { pluck } from 'utils/pluck';

import { HiringPipelineStagePrepareRemove } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.model';
import {
  hiringPipelinesStagesSelectors,
  hiringPipelineStageActions,
  HiringPipelineStagesReorderPayload,
} from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.reducer';
import type { HiringPipelineStage } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.types';
import {
  enhancedHiringPipelineApi,
  hiringPipelineStagesAdapter,
} from 'store/entities/hiring-pipelines/hiring-pipelines.api';
import { hiringPipelineActions } from 'store/entities/hiring-pipelines/hiring-pipelines.reducer';

function* hiringPipelineStageEditSaga({ payload }: { payload: HiringPipelineStage }) {
  const mutationUpdateStage = yield call(enhancedHiringPipelineApi.endpoints.updatePipelineStage.initiate, {
    pipelineId: payload.pipelineId,
    pipelineStageId: payload.pipelineStageId,
    data: payload,
  });
  yield putResolve(mutationUpdateStage);

  yield put(
    hiringPipelineStageActions.processingFinished({
      originalId: payload.pipelineStageId,
      returnedId: payload.pipelineStageId,
    }),
  );
}

function* hiringPipelineStageRemoveSaga({ payload }: { payload: HiringPipelineStagePrepareRemove }) {
  const mutationRemoveStage = yield call(enhancedHiringPipelineApi.endpoints.deletePipelineStage.initiate, {
    pipelineId: payload.pipelineId,
    pipelineStageId: payload.pipelineStageId,
  });
  const { error } = yield putResolve(mutationRemoveStage);
  const errorData = error?.data;

  if (!errorData) {
    yield put(hiringPipelineStageActions.processingFinished({}));
    yield put(alertsEffects.showSuccess({ message: getTranslate('company.hiringPipeLine.stageRemove.success') }));
  }
}

function* hiringPipelineStageCreateSaga({
  payload,
}: {
  payload: Pick<HiringPipelineStage, 'pipelineId' | 'name' | 'stageType' | 'order' | 'pipelineStageId'>;
}) {
  const mutationCreateStage = yield call(enhancedHiringPipelineApi.endpoints.createPipelineStage.initiate, {
    pipelineId: payload.pipelineId,
    data: payload,
  });
  const { data } = yield putResolve(mutationCreateStage);

  if (data) {
    yield put(
      hiringPipelineStageActions.processingFinished({
        originalId: payload.pipelineStageId,
        returnedId: data.pipelineStageId,
      }),
    );
    yield put(alertsEffects.showSuccess({ message: getTranslate('company.hiringPipeLine.stageCreate.success') }));
  }
}

function* hiringPipelineStageReorderSaga({ payload }: { payload: HiringPipelineStagesReorderPayload }) {
  const { source, destination, pipelineId } = payload;

  const editPipelineLoaderId = createEditPipelineLoaderId(pipelineId);

  yield put(loaderActions.start(editPipelineLoaderId));

  const pipelineStages: ReturnType<typeof hiringPipelinesStagesSelectors.selectByPipelineIdSortedByOrder> =
    yield select(hiringPipelinesStagesSelectors.selectByPipelineIdSortedByOrder, pipelineId);

  let sortedPipelineStages = [...pipelineStages];

  const pipelineStage = sortedPipelineStages.splice(source, 1);
  sortedPipelineStages.splice(destination, 0, ...pipelineStage);
  sortedPipelineStages = sortedPipelineStages.map((item, index) => ({ ...item, order: index }));

  const patchCollection = yield put(
    enhancedHiringPipelineApi.util.updateQueryData('searchPipeline', {}, (draft) => {
      hiringPipelineStagesAdapter.upsertMany(draft.pipelineStages, sortedPipelineStages);
    }) as any,
  );

  const pipelineStageIdsOrder = sortedPipelineStages.map(pluck('pipelineStageId'));

  yield put(hiringPipelineActions.reorder({ pipelineId, pipelineStageIdsOrder }));

  yield take(apiCallError);
  yield call(patchCollection.undo);
  yield put(loaderActions.stop(editPipelineLoaderId));
}

function* hiringPipelineStageAddActionSendEmailSaga(
  action: ReturnType<typeof hiringPipelineStageActions.addSendEmailAction.start>,
) {
  yield call(startLoader, action);

  const { payload } = action;

  const mutationAddSendEmail = yield call(enhancedHiringPipelineApi.endpoints.stageAddSendEmailAction.initiate, {
    pipelineId: payload.pipelineId,
    pipelineStageId: payload.pipelineStageId,
    data: payload,
  });
  yield putResolve(mutationAddSendEmail);

  yield call(stopLoader, action);
}

function* hiringPipelineStageRemoveActionSaga(
  action: ReturnType<typeof hiringPipelineStageActions.removeSendEmailAction.start>,
) {
  yield call(startLoader, action);

  const { payload } = action;

  const mutationDeleteSendEmail = yield call(enhancedHiringPipelineApi.endpoints.stageDeleteSendEmailAction.initiate, {
    pipelineId: payload.pipelineId,
    pipelineStageId: payload.pipelineStageId,
    pipelineStageActionId: payload.pipelineActionId,
  });
  yield putResolve(mutationDeleteSendEmail);

  yield call(stopLoader, action);
}

export function* hiringPipelineStageWorkers() {
  yield takeEvery(hiringPipelineStageActions.reorder, hiringPipelineStageReorderSaga);
  yield takeEvery(hiringPipelineStageActions.update.start, hiringPipelineStageEditSaga);
  yield takeEvery(hiringPipelineStageActions.remove.start, hiringPipelineStageRemoveSaga);
  yield takeEvery(hiringPipelineStageActions.create.start, hiringPipelineStageCreateSaga);
  yield takeEvery(hiringPipelineStageActions.addSendEmailAction.start, hiringPipelineStageAddActionSendEmailSaga);
  yield takeEvery(hiringPipelineStageActions.removeSendEmailAction.start, hiringPipelineStageRemoveActionSaga);
}
