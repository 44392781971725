import React, { useEffect, useRef } from 'react';

import { ExJobInterviewTemplate } from 'model';

import { ExMultiselectFormBody } from 'components/ui/ExMultiselectFormBody';
import { useInterviewTemplateSearch } from 'utils/hooks/InterviewTemplate/useInterviewTemplateSearch';
import { pluck } from 'utils/pluck';

type JobInterviewTemplateModalFormBodyProps = {
  className?: string;
  defaultValue: any;
  onChange: any;
};

const useJobInterviewTemplateModalFormBodyState = ({
  className,
  defaultValue,
  onChange,
}: JobInterviewTemplateModalFormBodyProps) => {
  const {
    interviewTemplatesOptions,
    isFetchingCurrentPage,
    onInputInterviewTemplatesChange,
    loadMoreInterviewTemplates,
  } = useInterviewTemplateSearch({ pageSize: 100 });

  const interviewTemplates = React.useMemo(
    () =>
      interviewTemplatesOptions.map((entity) => ({
        ...entity,
        interviewTemplateName: entity.name,
      })),
    [interviewTemplatesOptions],
  );

  const interviewTemplatesPromise = useRef<Function | null>(null);

  const loadEntitiesHandler = (searchTerm: string) => {
    onInputInterviewTemplatesChange(searchTerm);
    return new Promise<ExJobInterviewTemplate[]>((resolve) => {
      interviewTemplatesPromise.current = resolve;
    });
  };

  useEffect(() => {
    if (interviewTemplates && interviewTemplatesPromise.current instanceof Function) {
      interviewTemplatesPromise.current(interviewTemplates);
    }
  }, [interviewTemplates]);

  return {
    className,
    loadEntitiesHandler,
    defaultValue,
    onChange,
    loadMoreInterviewTemplates,
    isFetchingCurrentPage,
  } as const;
};

export const JobInterviewTemplateModalFormBody: React.FC<JobInterviewTemplateModalFormBodyProps> = (props) => {
  const { className, defaultValue, onChange, loadEntitiesHandler, loadMoreInterviewTemplates, isFetchingCurrentPage } =
    useJobInterviewTemplateModalFormBodyState(props);

  return (
    <ExMultiselectFormBody
      className={className}
      getOptionLabelCallback={pluck('interviewTemplateName')}
      getOptionValueCallback={pluck('interviewTemplateId')}
      defaultValue={defaultValue}
      onChangeHandler={onChange}
      title="Interview Template"
      loadEntitiesHandler={loadEntitiesHandler}
      onMenuScrollToBottom={loadMoreInterviewTemplates}
      isLoading={isFetchingCurrentPage}
      createFilterOuter={() => () => true}
    />
  );
};
