import styled from 'styled-components';

import { DialogBody, DialogContentComponent, DialogFooter } from 'components/Dialog';
import { ExDialog } from 'components/ui/ExDialog';

export const EditJobAdAdditionalFieldsModalStyled = styled(ExDialog)`
  max-width: 800px;
  width: 100%;
  ${DialogBody} {
    padding-left: 5px;
    margin-left: -5px;
    padding-right: 5px;
    margin-right: -5px;
  }
`;

export const DocumentPreviewContent = DialogContentComponent;

export const DocumentPreviewModalFooter = DialogFooter;
