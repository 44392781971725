import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { AppRouterParams } from 'model/router';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { ApplicantForm } from 'containers/ApplicantForm/ApplicantFormState/applicant-form.models';
import { JobList, JobListHeader, JobPositionRow } from 'containers/JobLists';
import { jobListActions, jobListSelectors } from 'containers/JobLists/store';

import { ColumnMap } from 'components/ListViewNew/ListViewProps';
import { Remaining } from 'components/Remaining';
import { StepComponent, StepDescription } from 'components/StepForm';
import { innerTablesStyled, TableListWrapper } from 'components/TableList/TableListComponents';
import { ExCard } from 'components/ui/ExCard';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { getTranslate } from 'utils/i18utils';

import { Applicant } from 'store/entities/applicants';
import { Columns, Job, JobBelongsTo, SortByForJobList } from 'store/entities/jobs';
import { RootState } from 'store/rootReducer';

export interface NewApplicantSelectJobStepProps extends StepComponent {
  className?: string;
  formData: ApplicantForm;
}

const columns: ColumnMap<Job, SortByForJobList, Columns> = {
  [Columns.position]: {
    label: 'Job Name',
    order: SortByForJobList.JobName,
    component: ({ item }) => <JobPositionRow jobId={item.jobId} preventLink />,
    style: { width: '50%' },
  },
  [Columns.deadline]: {
    label: 'End Date',
    order: SortByForJobList.Deadline,
    component: ({ item }) => <Remaining deadline={item.deadline} />,
    style: { width: '46%' },
  },
};

const JobListStyled = innerTablesStyled(JobList);

const NewApplicantSelectJobStep: React.FC<NewApplicantSelectJobStepProps> = ({ className, formData }) => {
  const [filtersInitialized, setFiltersInitialized] = useState(false);
  const { jobId, candidateId } = useParams<AppRouterParams>();
  const { register, errors, setValue } = useFormContext<Applicant>();
  const dispatch = useAppDispatch();
  const selectedItems = useSelector<RootState>((state) =>
    jobListSelectors.getSelected(state, { id: JobBelongsTo.newApplicant }),
  ) as Job['id'][];

  useEffect(() => {
    register('jobId');
    if (formData.candidateId) {
      dispatch(
        jobListActions.updateFilters({
          filters: { excludeForCandidateIds: [formData.candidateId] },
          id: JobBelongsTo.newApplicant,
        }),
      );
    }
    if (jobId && !formData.jobId && !candidateId) {
      dispatch(jobListActions.toggleItemRadio({ selectedItemId: jobId, id: JobBelongsTo.newApplicant }));
    }
    if (formData.jobId && !selectedItems?.length) {
      dispatch(
        jobListActions.toggleItemRadio({
          selectedItemId: formData.jobId,
          id: JobBelongsTo.newApplicant,
        }),
      );
    }
    setFiltersInitialized(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setValue('jobId', selectedItems[0]);
  }, [selectedItems]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const error = errors.jobId;
    if (error?.message) {
      dispatch(alertsEffects.showError({ message: error.message.toString() }));
    }
  }, [dispatch, errors]);

  if (!filtersInitialized) {
    return null;
  }
  return (
    <div className={className}>
      <StepDescription>
        <StepDescription.Title>Step 2: Select a Job</StepDescription.Title>
        <StepDescription.Description>{getTranslate('new_applicant.step_2.description')}</StepDescription.Description>
      </StepDescription>
      <ExCard title="Available Job">
        <JobListHeader listId={JobBelongsTo.newApplicant} selectable />
        <JobListStyled id={JobBelongsTo.newApplicant} columns={columns} selectable />
      </ExCard>
    </div>
  );
};

export default styled(NewApplicantSelectJobStep)`
  ${TableListWrapper} {
    padding: 0;
  }
`;
