import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useShowModal } from 'pages/Company/CompanyTabs/hooks/useShowModal';

import { ExModalBodyStyledWide } from 'containers/Modals/ModalsContent/Company/ExModalComponents';

import { ExDialogActionButton, ExDialogActions } from 'components/ui/ExDialog/ExDialogComponents';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { JobTeamMember } from 'store/entities/jobs';
import { jobTeamMemberPrepareCreate } from 'store/entities/jobs/job.actions';
import { wizardBackward } from 'store/modals/modals.actions';

import { JobTeamAddMemberFormBody } from './JobTeamAddMemberFormBody';

type JobTeamAddMemberModalProps = {
  className?: string;
  modalProps: { teamMembers: JobTeamMember[]; jobId: string };
  id: string;
};

const JobTeamAddMemberModalStyled = styled(ExModalBodyStyledWide)``;

const useJobTeamAddMemberModalState = ({
  id,
  className,
  modalProps: { teamMembers, jobId },
}: JobTeamAddMemberModalProps) => {
  const { clickHandler } = useShowModal();
  const dispatch = useAppDispatch();
  const [teamMembersModal, setTeamMembersModal] = useState(teamMembers);

  const onChangeFormBody = useCallback((teamMembersUpdated: JobTeamMember[]) => {
    setTeamMembersModal(teamMembersUpdated);
  }, []);

  const onBackHandler = clickHandler.bind(null, wizardBackward({ id }));
  const onConfirmHandler = useCallback(() => {
    dispatch(jobTeamMemberPrepareCreate({ jobId, teamMembers: teamMembersModal }));
  }, [dispatch, jobId, teamMembersModal]);

  return {
    className,
    defaultValue: teamMembersModal,
    onChange: onChangeFormBody,
    onBackHandler,
    onConfirmHandler,
  };
};

export const JobTeamAddMemberModal: React.FC<JobTeamAddMemberModalProps> = (props) => {
  const { className, defaultValue, onChange, onBackHandler, onConfirmHandler } = useJobTeamAddMemberModalState(props);

  return (
    <JobTeamAddMemberModalStyled className={className}>
      <JobTeamAddMemberFormBody onChange={onChange} defaultValue={defaultValue} />
      <ExDialogActions>
        <ExDialogActionButton variant="light" onClick={onBackHandler()}>
          Back
        </ExDialogActionButton>
        <ExDialogActionButton onClick={onConfirmHandler}>Add</ExDialogActionButton>
      </ExDialogActions>
    </JobTeamAddMemberModalStyled>
  );
};
