import { useEffect, useRef, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';

import { ModalsTypeKey } from 'containers/Modals/AppModalProps';
import { modalsActions, modalsSelectors } from 'containers/Modals/store';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { useSelector } from 'store/rootSelectors';

interface UseModalRedux<T> {
  (params: UseModalReduxParams): UseModalReduxReturn<T>;
}

interface UseModalReduxParams {
  id?: ModalsTypeKey | keyof typeof ModalsTypeKey;
}

interface UseModalReduxReturn<T> {
  modalId: string | null;
  show: () => Promise<T>;
  hide: () => void;
  resultLastPayload: T | undefined;
  resultPayloads: T[];
  isModalOpen: boolean;
}

export function useModalRedux<T = any, K extends UseModalRedux<T> = UseModalRedux<T>>({ id }: Parameters<K>[0]) {
  const dispatch = useAppDispatch();
  const [modalId, setModalId] = useState<string | null>(null);
  const isModalOpen = useSelector<boolean>((state) => modalsSelectors.isModalOpen(state, { modalId: id }));
  const resultLastPayload = useSelector<T>((state) => modalsSelectors.payloadLast(state, { modalId: id }));
  const resultPayloads = useSelector<T[]>((state) => modalsSelectors.payloadByModalId(state, { modalId: id }));
  const resolve = useRef<Function | null>(null);

  useEffect(() => {
    setModalId(id ?? nanoid());
  }, [id]);

  useEffect(() => {
    if (typeof resolve.current === 'function') {
      resolve.current(resultLastPayload);
    }
  }, [resultLastPayload]);

  const show = () => {
    if (id !== undefined && id !== null) {
      dispatch(modalsActions.showModal({ modalType: id }));
    }
    return new Promise<T>((res) => {
      resolve.current = res;
    });
  };

  const hide = () => {
    if (id !== undefined && id !== null) {
      dispatch(modalsActions.hideModalByIds({ modalType: [id] }));
    }
  };

  return {
    modalId,
    show,
    hide,
    resultLastPayload,
    isModalOpen,
    resultPayloads,
  };
}
