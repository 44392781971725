import { useEffect } from 'react';
import { useLayoutDispatch, useLayoutState } from 'layouts/layout-context/index';

export function useFragmentLayout() {
  const layoutDispatch = useLayoutDispatch();
  const layoutState = useLayoutState();

  useEffect(() => {
    const currentLayout = layoutState.layout;
    if (currentLayout !== 'fragment-layout') {
      layoutDispatch({ type: 'fragment-layout' });
      return () => {
        if (currentLayout !== 'app-layout') {
          layoutDispatch({ type: 'app-layout' });
        }
      };
    }
  }, [layoutDispatch, layoutState.layout]);
}
