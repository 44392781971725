import React, { useCallback } from 'react';
import { push } from 'connected-react-router';
import { ExRoutes } from 'router/routes';
import styled from 'styled-components/macro';

import { validationSchemaJobDuplicate } from 'containers/JobForms/validators/validationSchemaJobV2';

import { Alert } from 'components/Alert';
import { DialogProps } from 'components/Dialog';
import { Form } from 'components/Form';
import { FormInput } from 'components/FormInput';
import { ExButton } from 'components/ui/ExButton';
import { ExDialogTitle } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { Job } from 'store/entities/jobs';
import { useJobCopyMutation } from 'store/entities/jobs/jobs.api';

type JobDuplicateModalProps = {
  className?: string;
  onClose: DialogProps['onClose'];
  jobId?: Job['jobId'];
  jobName?: Job['jobName'];
  redirect?: boolean;
};

const JobDuplicateModalStyled = styled(Form)`
  padding: 0 50px 20px;
  max-width: 550px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const FooterButton = styled(ExButton)`
  margin-left: 7px;
  margin-right: 7px;
`;

const useJobDuplicateModalState = ({ className, onClose, jobId, jobName, redirect }: JobDuplicateModalProps) => {
  const dispatch = useAppDispatch();
  const [jobCopyMutation, { isLoading }] = useJobCopyMutation();

  const onSubmit = useCallback(
    async (formData) => {
      try {
        const { jobId: newJobId } = await jobCopyMutation({
          jobId,
          ...formData,
        }).unwrap();

        if (redirect) {
          dispatch(push(ExRoutes.jobPage({ jobId: newJobId })));
        } else {
          onClose(false);
        }
      } catch (e) {}
    },
    [dispatch, jobCopyMutation, jobId, onClose, redirect],
  );

  return {
    className,
    onSubmit,
    onClose,
    isLoading,
    jobName,
  } as const;
};

export const JobDuplicateModal: React.FC<JobDuplicateModalProps> = (props) => {
  const { className, onSubmit, onClose, isLoading, jobName } = useJobDuplicateModalState(props);

  return (
    <JobDuplicateModalStyled className={className} onSubmit={onSubmit} validationSchema={validationSchemaJobDuplicate}>
      <ExDialogTitle>Duplicate Job</ExDialogTitle>
      <Alert>
        Job duplication will not include: Job Ads, Job Applications and Comments. Job is saved in Draft status
      </Alert>
      <FormInput label="Job Name" name="jobName" defaultValue={jobName} required />
      <Footer>
        <FooterButton variant="light" onClick={() => onClose(false)}>
          Cancel
        </FooterButton>
        <FooterButton type="submit" variant="primary" isLoading={isLoading}>
          Save
        </FooterButton>
      </Footer>
    </JobDuplicateModalStyled>
  );
};
