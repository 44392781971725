import { useMemo } from 'react';
import * as yup from 'yup';

import { getCandidateAddressLine } from 'utils/list';

import { useCountryQuerySelectors } from 'store/dictionary/hooks/useCountryQuerySelectors';
import { Candidate, CandidateSocialNetwork, SocialNetwork } from 'store/entities/candidates/models';
import { useSelector } from 'store/rootSelectors';

const useCandidatePhone = (candidate: Candidate) => {
  return useMemo(() => candidate.phone || candidate.mobile || '--', [candidate.phone, candidate.mobile]);
};

const useCandidateEmail = (candidate: Candidate) => {
  return useMemo(() => candidate.email || '--', [candidate.email]);
};

const useCandidateAddressLine = (candidate: Candidate) => {
  const { selectors: countriesSelectors } = useCountryQuerySelectors();
  const candidateCountry = useSelector((state) => countriesSelectors.selectByExpedoCode2(state, candidate.country!));
  return getCandidateAddressLine(candidate, candidateCountry);
};

const useCandidateFullName = (candidate?: Candidate) => {
  if (!candidate) {
    return null;
  }

  if (candidate.fullName) {
    return candidate.fullName;
  }

  return [candidate.firstName, candidate.lastName].filter(Boolean).join(' ');
};

export const validationSchemaCandidateSocialNetworks: yup.SchemaOf<Array<CandidateSocialNetwork>> = yup.array().of(
  yup.object({
    type: yup
      .number()
      .oneOf(
        Object.values(SocialNetwork)
          .filter((value) => Number(value))
          .map((value) => Number(value)),
      )
      .defined(),
    url: yup.string().url().label('Link').defined(),
  }),
);

export { useCandidatePhone, useCandidateEmail, useCandidateAddressLine, useCandidateFullName };
