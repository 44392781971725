import React from 'react';
import styled from 'styled-components';

type ExPublicLayoutProps = {
  className?: string;
};

export const ExPublicContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  max-width: 1000px;
  margin: 0 auto;
`;

const ExPublicLayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
`;

export const ExPublicLayout: React.FC<ExPublicLayoutProps> = ({ children, className }) => {
  return <ExPublicLayoutStyled className={className}>{children}</ExPublicLayoutStyled>;
};
