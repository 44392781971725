import { createSlice } from '@reduxjs/toolkit';

import { formJobInitialState, JobFormState } from './models';
import * as reducers from './reducers';

export const formJobKey = 'formJob';

export const jobMergeFields = ['responsibilities', 'requirements'];

type FormJobCaseReducers = {
  loadFormJobSelectorsDataSuccess: typeof reducers.loadFormJobSelectorsDataSuccess;
  saveForm: typeof reducers.saveForm;
  toggleShowSteps: typeof reducers.toggleShowSteps;
  clearForm: typeof reducers.clearForm;
  setApiErrors: typeof reducers.setApiErrors;
  toggleIsDraftSavedSteps: typeof reducers.toggleIsDraftSavedSteps;
  update: typeof reducers.update;
};

const jobFormSlice = createSlice<JobFormState, FormJobCaseReducers>({
  name: formJobKey,
  initialState: formJobInitialState,
  reducers,
});

export const jobFormActions = jobFormSlice.actions;

export default jobFormSlice;
