import React from 'react';

import { apiConfig } from 'config/apiConfig';

import { AppFile } from 'model';

import { authSelectors } from 'store/auth/auth.selectors';
import { useSelector } from 'store/rootSelectors';

/**
 * This hook receive AppFile item and extract path to this file on the server.
 *
 * @param {AppFile} item
 * @returns {string} - path to file on server
 */
export const useFile = (item: AppFile): string => {
  const companyId = useSelector(authSelectors.selectCurrentCompanyId);
  const [file, setFile] = React.useState<string>('');

  React.useEffect(() => {
    const documentId = item?.resumePreviewFileId || item?.resumeId || item?.documentPreviewFileId || item?.documentId;
    if (documentId) {
      const fileLink = `${apiConfig.baseUrl}/api/company/${companyId}/file/${documentId}`;
      setFile(fileLink);
    }
  }, [item]); //eslint-disable-line

  return file;
};
