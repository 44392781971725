import { ExInterviewTemplateQuestion } from 'model';

import { InterviewTemplateEditFormCaseReducer } from 'containers/InterviewTemplateForms/InterviewTemplateEditForm/state/InterviewTemplateEditForm.models';

export const interviewTemplateEditFormReorderQuestionReducer: InterviewTemplateEditFormCaseReducer<
  { target: number; source: number } & Pick<ExInterviewTemplateQuestion, 'interviewTemplateQuestionId'>
> = (draft, { payload }) => {
  draft.formData.questions.ids.splice(payload.source, 1);
  draft.formData.questions.ids.splice(payload.target, 0, payload.interviewTemplateQuestionId);
};
