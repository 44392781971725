import React from 'react';
import styled from 'styled-components';

import { Spinner } from 'components/Spinner';

export interface ListTableViewPlaceholderProps {
  className?: string;
  loading: boolean | undefined;
  colsLength: number;
  error?: string;
}

const PlaceholderCell: React.FC<Pick<ListTableViewPlaceholderProps, 'error' | 'loading'>> = ({ loading, error }) => {
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <span className="text-danger">{error}</span>;
  }
  return <>No items</>;
};

const ListTableViewPlaceholder: React.FC<ListTableViewPlaceholderProps> = ({
  className,
  colsLength,
  loading,
  error,
}) => {
  return (
    <tr className={className}>
      <td colSpan={colsLength} className="text-center">
        <PlaceholderCell {...{ loading, error }} />
      </td>
    </tr>
  );
};

export default styled(ListTableViewPlaceholder)``;
