import { FormJobAdCaseReducer } from 'containers/JobAdForms/state/models';

import { JobAd } from 'store/entities/job-ads/models';

export const saveForm: FormJobAdCaseReducer<{ formData: Partial<JobAd> }> = (state, { payload }) => {
  state.formData = {
    ...state.formData,
    ...payload.formData,
  };
};
