import React from 'react';
import styled from 'styled-components/macro';

import { ApplicantModalSwitchMode } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalSwitchMode';
import { ApplicantModalWorkExperienceEdit } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalWorkExperience/ApplicantModalWorkExperienceEdit/ApplicantModalWorkExperienceEdit';
import { ApplicantModalWorkExperienceView } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalWorkExperience/ApplicantModalWorkExperienceView/ApplicantModalWorkExperienceView';

type ApplicantModalWorkExperienceProps = {
  className?: string;
};

const ApplicantModalWorkExperienceStyled = styled(ApplicantModalSwitchMode)`
  position: relative;
`;

const useApplicantModalWorkExperienceState = ({ className }: ApplicantModalWorkExperienceProps) => {
  return {
    className,
  } as const;
};

export const ApplicantModalWorkExperience: React.FC<ApplicantModalWorkExperienceProps> = (props) => {
  const { className } = useApplicantModalWorkExperienceState(props);

  return (
    <ApplicantModalWorkExperienceStyled
      className={className}
      viewComponent={<ApplicantModalWorkExperienceView />}
      editComponent={<ApplicantModalWorkExperienceEdit />}
    />
  );
};
