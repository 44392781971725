import { DefaultTheme, ThemedCssFunction } from 'styled-components';

import { DeviceSizes } from 'utils/hooks/useDeviceSize';

export type Columns = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | 'auto';

export type ThemedCssFunctionReturnType = ReturnType<ThemedCssFunction<DefaultTheme>>;

export type MediaCallback = (css: ThemedCssFunction<DefaultTheme>) => ThemedCssFunctionReturnType;

export type MediaCssProps = Partial<Record<DeviceSizes | 'custom', Columns | MediaCallback>>;

export const mediaQueriesForDeviceSize = {
  [DeviceSizes.extraSmall]: '@media (max-width: 575.98px)',
  [DeviceSizes.small]: '@media (min-width: 576px) and (max-width: 767.98px)',
  [DeviceSizes.medium]: '@media (min-width: 768px) and (max-width: 991.98px)',
  [DeviceSizes.large]: '@media (min-width: 992px) and (max-width: 1199.98px)',
  [DeviceSizes.extraLarge]: '@media (min-width: 1200px)',
};

export const NUMBER_OF_LAYOUT_COLUMNS = 12;
