import { createSelector } from '@reduxjs/toolkit';

import { ViewMode } from 'store/constants';
import { RootState } from 'store/rootReducer';

import {
  selectedTalentPoolCandidateListAdapter,
  talentPoolCandidateListUiSlice,
} from './talent-pools-candidate-list.reducer';

const domain = (state: RootState) => state.ui.talentPoolsUi[talentPoolCandidateListUiSlice.name];

const filters = createSelector(domain, (state) => state.filters);
const filtersForApi = createSelector(filters, (state) => {
  return {
    ...state,
  };
});

const viewMode = createSelector(domain, (state) => state.viewMode);
const selectedCandidatesState = createSelector(domain, (state) => state.selectedCandidates);
const isGridView = createSelector(viewMode, (state) => state === ViewMode.grid);

const entityAdapterSelectors = selectedTalentPoolCandidateListAdapter.getSelectors(selectedCandidatesState);

const isCandidateSelectedById = createSelector(entityAdapterSelectors.selectById, Boolean);

const isFiltersChanged = createSelector(domain, (state) => state.isFiltersChanged);

export const talentPoolCandidateListUiSelectors = {
  ...entityAdapterSelectors,
  filters,
  filtersForApi,
  isGridView,
  isCandidateSelectedById,
  viewMode,
  isFiltersChanged,
};
