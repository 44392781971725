import React, { lazy, ReactElement, Suspense } from 'react';
import styled from 'styled-components';

import { AppFile } from 'model';

import { DialogBody, DialogContentComponent, DialogFooter } from 'components/Dialog';
import { Spinner } from 'components/Spinner';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { useFile } from 'utils/hooks/useFile';

const PDFViewer = lazy(() => import(/* webpackChunkName: "pdf-viewer" */ 'components/PDFViewer/PDFViewer'));

const DocumentPreviewContent = DialogContentComponent;

const DocumentPreviewModalHeader = styled.div`
  padding: 5px 0;
`;
const DocumentPreviewModalBody = DialogBody;

const DocumentPreviewModalFooter = DialogFooter;

type DocumentPreviewModalContentProps = {
  item: AppFile;
  onClose: Function;
};

/**
 * Comntent of document preview modal
 *
 * @param {DocumentPreviewModalContentProps} {
 *   item - AppFile item,
 *   onClose - callback to hadndle modal closing,
 * }
 * @returns {ReactElement}
 */
const DocumentPreviewModalContent: React.FC<DocumentPreviewModalContentProps> = ({
  item,
  onClose,
}: DocumentPreviewModalContentProps): ReactElement => {
  const file = useFile(item);

  return (
    <DocumentPreviewContent>
      <DocumentPreviewModalHeader>
        <h3>{item.filename}</h3>
      </DocumentPreviewModalHeader>
      <DocumentPreviewModalBody>
        <Suspense fallback={<Spinner />}>
          <PDFViewer file={file} />
        </Suspense>
      </DocumentPreviewModalBody>
      <DocumentPreviewModalFooter>
        <ExButton variant="primary" className="ml-auto" onClick={() => onClose(true)}>
          Close
        </ExButton>
      </DocumentPreviewModalFooter>
    </DocumentPreviewContent>
  );
};

export { DocumentPreviewModalContent };
