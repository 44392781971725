import { AdditionalField, ApiValueForDynamicForm } from 'model';

import { JobAd } from 'store/entities/job-ads/models';
import { JobBoard } from 'store/entities/job-boards/models';

export const formBuilderTransformer = (
  jobBoardAdditionalFields: Required<JobBoard>['additionalFields'],
  additionalFieldsValues: Required<JobAd>['additionalFields'],
) => {
  const resultForView: Array<{
    displayName: string;
    displayValue: string;
  }> = jobBoardAdditionalFields.map((jobBoardField) => {
    const jobAdField = additionalFieldsValues.find((v) => v.key === jobBoardField.key);

    const findChildren = jobBoardField.options.find((vv) =>
      vv?.children?.length ? vv.children.some(compareValue(jobAdField)) : vv?.key === jobAdField?.value,
    );

    const findChildrenValue = findChildren?.children?.find(compareValue(jobAdField));

    return {
      displayName: jobBoardField.displayName,
      displayValue: findChildrenValue?.value ?? findChildren?.value ?? jobAdField?.value?.toString() ?? '',
    };
  });

  const resultForForm = jobBoardAdditionalFields.reduce<Record<string, { value: string; children: string }>>(
    (acc, jobBoardField) => {
      const jobAdField = additionalFieldsValues.find((v) => v.key === jobBoardField.key);

      const findChildren = jobBoardField.options.find((vv) => vv?.children?.some(compareValue(jobAdField)));

      const findChildrenValue = findChildren?.children?.find(compareValue(jobAdField));

      acc[jobBoardField.key] = {
        value: findChildren?.key || jobAdField?.value?.toString() || '',
        children: findChildrenValue?.key ?? '',
      };

      return acc;
    },
    {},
  );

  return {
    resultForView,
    resultForForm,
  };
};

const compareValue = (v: AdditionalField | undefined) =>
  !!v ? (c: ApiValueForDynamicForm) => c.key === v?.value : () => false;
