import { JobAdForJobResponseListItem } from 'api-endpoints/job-ad/models';

import { BasicModel } from 'utils/reducer/reducer-helper';

import { jobAdToState } from 'store/entities/job-ads/mappers';
import { JobAd } from 'store/entities/job-ads/models';

interface MapReturnValue<T extends BasicModel> {
  ids: Array<T['id']>;
  entities: Array<T>;
}

export const mapJobAds = (response: Array<JobAdForJobResponseListItem>): MapReturnValue<JobAd> => {
  return response.map(jobAdToState).reduce(
    (acc, { id, ...rest }) => ({
      ids: [...acc.ids, id],
      entities: [...acc.entities, { id, ...rest }],
    }),
    {
      ids: [],
      entities: [],
    } as MapReturnValue<JobAd>,
  );
};
