import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import isEqual from 'lodash/isEqual';

import { CheckableRow, CheckableRowBold } from 'components/Form/CheckableRow';
import { CheckableRowItem } from 'components/Form/CheckableRow/CheckableRowProps';
import { InputRow } from 'components/Form/InputRow';
import { usePrevious } from 'utils/hooks/usePrevious';
import { trimLeadAndTrailingSpaces } from 'utils/validator-helpers';

import { Job } from 'store/entities/jobs';

import {
  CheckableListBody,
  CheckableListContent,
  CheckableListItem,
  CheckableListItemAdd,
  CheckableListTitle,
} from './CheckableListComponents';
import { CheckableListProps } from './CheckableListProps';
import {
  checkableListActions,
  checkableListItemAdapter,
  checkableListReducer,
  checkableListSelectAll,
} from './reducer';

export const CheckableList: React.FC<CheckableListProps> = ({
  title,
  onChange,
  items,
  placeholder,
  variant = 'primary',
  disabled,
  maxListHeight,
  sourceEntity,
  className,
  name,
  inputRef,
  selectAllTitle,
  required,
}: CheckableListProps): React.ReactElement => {
  const checkableListBodyRef = useRef<HTMLUListElement | null>(null);
  const [checkableItems, dispatch] = useReducer(checkableListReducer, checkableListItemAdapter.getInitialState());

  const [selectAll, onSelectAll] = useState<CheckableRowItem>({
    id: 'selectAll',
    text: selectAllTitle || 'Select All',
    isEnabled: false,
  });

  const checkableItemsList = checkableListSelectAll(checkableItems);

  const isAllSelected = checkableItemsList.length > 0 && checkableItemsList.every(({ isEnabled }) => isEnabled);

  const isSelectAllDisabled = checkableItemsList.length < 1;

  useEffect(() => {
    onSelectAll((select) => {
      return {
        ...select,
        isEnabled: isAllSelected,
      };
    });
  }, [isAllSelected]);

  const selectAllHandler = (checkableItem: CheckableRowItem) => {
    const updatedCheckableItems = checkableItems.ids.map((id) => ({
      changes: { isEnabled: checkableItem.isEnabled },
      id,
    }));
    dispatch(checkableListActions.changeMany(updatedCheckableItems));
  };

  const methods = useFormContext<Job>();
  const { errors, formState } = { ...methods };
  const validated = formState?.isSubmitted;
  const errorsByName = name ? errors[name] : {};

  const prevCheckableItemsList = usePrevious(checkableItemsList);

  useEffect(() => {
    const withoutIds = items.some((item) => !Boolean(item.id));
    if (withoutIds) {
      return;
    }
    dispatch(checkableListActions.load(items));
  }, [items]);

  useEffect(() => {
    if (isEqual(prevCheckableItemsList, checkableItemsList)) {
      return;
    }

    if (typeof onChange === 'function') {
      onChange(checkableItemsList);
    }
    checkableListBodyRef?.current?.scrollTo({ top: checkableListBodyRef?.current?.scrollHeight });
  }, [checkableItemsList, prevCheckableItemsList, onChange]);

  const handleAddAction = (text: string) => {
    const preparedText = trimLeadAndTrailingSpaces(text);
    if (preparedText.length === 0) {
      return;
    }
    dispatch(checkableListActions.add({ sourceEntity, text }));
  };

  const onChangeHandler = (checkableRowItem: CheckableRowItem) =>
    dispatch(checkableListActions.change({ changes: { ...checkableRowItem }, id: checkableRowItem.id }));

  const onDeleteHandler = (itemId: CheckableRowItem['id']) => dispatch(checkableListActions.deleteItem(itemId));

  return (
    <CheckableListContent className={className}>
      {title && <CheckableListTitle>{title}</CheckableListTitle>}
      <CheckableListBody maxHeight={maxListHeight} ref={checkableListBodyRef}>
        {!disabled && (
          <CheckableListItem key={selectAll.id}>
            <CheckableRowBold
              item={selectAll}
              onChange={selectAllHandler}
              required={required}
              disabled={isSelectAllDisabled}
            />
          </CheckableListItem>
        )}
        {checkableItemsList.map((item: CheckableRowItem) => (
          <CheckableListItem key={item.id}>
            <CheckableRow
              item={item}
              variant={variant}
              onChange={onChangeHandler}
              onDelete={onDeleteHandler}
              disabled={disabled}
            />
          </CheckableListItem>
        ))}
      </CheckableListBody>
      {!disabled && (
        <CheckableListItemAdd>
          <InputRow
            onAdd={handleAddAction}
            placeholder={placeholder}
            errors={errorsByName}
            validated={validated}
            inputRef={inputRef}
          />
        </CheckableListItemAdd>
      )}
    </CheckableListContent>
  );
};
