import startCase from 'lodash/startCase';

import { ApplicationRole } from 'model/api-enums.constants';

import { EnumLiteralsOf } from 'utils/funcs';

type UserPosition = {
  applicationRole: EnumLiteralsOf<typeof ApplicationRole>;
  applicationRoleName: string;
};

/**
 * Function for convert ApplicationRole to ApplicationRoleName and ApplicationRole
 *
 * @export
 * @param {ApplicationRole} applicationRole
 * @returns {UserPosition | null}
 */
export function getUserPosition(
  applicationRole: EnumLiteralsOf<typeof ApplicationRole> | undefined,
): UserPosition | null {
  if (applicationRole === null || applicationRole === undefined) {
    return null;
  }
  const position = Object.entries(ApplicationRole).find(([, value]) => {
    return applicationRole === value;
  });

  if (!position) {
    return null;
  }

  return {
    applicationRole,
    applicationRoleName: startCase(position[0]),
  };
}
