import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { AvailabilityType } from 'model/api-enums.constants';

import { ApplicantForm } from 'containers/ApplicantForm/ApplicantFormState/applicant-form.models';

import { CustomCheckbox } from 'components/Form/CustomCheckbox';
import { FormDate } from 'components/FormDate';
import { FormSelect } from 'components/FormSelect';
import { ExSalaryInputWithCountry } from 'components/ui/ExSalaryInputWithCountry';
import { ExVisible } from 'components/ui/ExVisible';
import { useAvailabilityTypeOption } from 'utils/hooks/formsHooks/useAvailabilityTypeOption';
import { useFrequencyOptions } from 'utils/hooks/formsHooks/useFrequencyOptions';
import { useSeniorityLevelOptions } from 'utils/hooks/formsHooks/useSeniorityLevelOptions';

type ApplicantDetailsFormBodyProps = {};

const useApplicantDetailsFormBodyState = () => {
  const { register, errors, formState, watch, getValues, setValue } = useFormContext<ApplicantForm>();

  const validated = formState.isSubmitted;

  const frequencyOptions = useFrequencyOptions();
  const seniorityLevel = useSeniorityLevelOptions();
  const availabilityTypeOptions = useAvailabilityTypeOption();

  const availability = watch('jobApplicantAvailability');

  const isEditMode = !!watch('applicantId');

  const availabilityChosen = availability?.availabilityType === AvailabilityType.ActualDate;

  useEffect(() => {
    if (availability?.actualDate && availability?.availabilityType !== AvailabilityType.ActualDate) {
      setValue('jobApplicantAvailability', { availabilityType: availability?.availabilityType });
    }
  }, [availability?.actualDate, availability?.availabilityType, setValue]);

  const changeDoNotHire = (isChecked: boolean) => setValue('doNotHire', isChecked);
  const doNotHireValue = watch('doNotHire');
  useEffect(() => {
    register('doNotHire');
  }, [register]);

  return {
    frequencyOptions,
    errors,
    validated,
    register,
    seniorityLevel,
    availabilityChosen,
    availabilityTypeOptions,
    getValues,
    isEditMode,
    changeDoNotHire,
    doNotHireValue,
  };
};

export const ApplicantDetailsFormBody: React.FC<ApplicantDetailsFormBodyProps> = () => {
  const {
    frequencyOptions,
    errors,
    validated,
    register,
    seniorityLevel,
    availabilityChosen,
    availabilityTypeOptions,
    isEditMode,
    changeDoNotHire,
    doNotHireValue,
  } = useApplicantDetailsFormBodyState();

  return (
    <>
      <div className="row">
        <input name="jobId" ref={register} hidden />
        <input name="applicantId" ref={register} hidden />
        <input name="rating" type="number" ref={register} hidden defaultValue={0} />
        <ExVisible visible={isEditMode}>
          <div className="col-12 mb-3">
            <CustomCheckbox checked={doNotHireValue} onChange={changeDoNotHire}>
              Do not Hire
            </CustomCheckbox>
          </div>
        </ExVisible>
        <div className="w-100"></div>
        <FormSelect
          className="col-6"
          name="seniorityLevel"
          label="Seniority Level"
          placeholder=""
          errors={errors?.seniorityLevel}
          validated={validated}
          defaultOptions
          options={seniorityLevel}
          isClearable
          isSearchable={false}
        />
        <div className="w-100"></div>
        <FormSelect
          label="Availability"
          name="jobApplicantAvailability.availabilityType"
          className={availabilityChosen ? 'col-md-3' : 'col-md-6'}
          placeholder=""
          errors={errors?.jobApplicantAvailability?.['availabilityType']}
          validated={validated}
          options={availabilityTypeOptions}
          isSearchable={false}
          isClearable
        />
        {availabilityChosen && (
          <FormDate
            className="col-md-3"
            label="Specific Date"
            name="jobApplicantAvailability.actualDate"
            min={new Date()}
            defaultValue={new Date().toISOString().split('T')[0]}
            errors={errors?.jobApplicantAvailability?.['actualDate']}
            validated={validated}
          />
        )}
        <div className="w-100"></div>
        <ExSalaryInputWithCountry className="col-4" label="Salary Expectation" />
        <FormSelect
          className="col-4"
          label="Salary Expectation Frequency"
          placeholder=""
          name="salaryExpectationFrequency"
          errors={errors?.salaryExpectationFrequency}
          validated={validated}
          defaultOptions
          options={frequencyOptions}
          isClearable
          isSearchable={false}
        />
      </div>
    </>
  );
};
