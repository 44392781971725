import { useMemo } from 'react';
import { Selector } from 'react-redux';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { ExRoutes } from 'router/routes';

import { AppRouterParams } from 'model/router';

import { applicantListSelectors } from 'containers/ApplicantLists/store';

import { useAppSelector } from 'utils/hooks/useSelectors';

import { Applicant, ApplicantBelongsTo, applicantSelectors } from 'store/entities/applicants';
import { hiringPipelinesStagesSelectors } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.reducer';
import { jobsSelectors } from 'store/entities/jobs';
import { RootState } from 'store/rootReducer';
import { useSelector } from 'store/rootSelectors';

/**
 * This mapper handles all locations where used applicant ID
 */
const applicantLocationMapper = {
  jobApplicants: (applicant?: Applicant) =>
    ExRoutes.jobApplicantsModal({
      jobId: applicant?.jobId,
      applicantsViewTab: 'applicants',
      applicantId: applicant?.id,
    }),
  jobPipeline: (applicant?: Applicant) =>
    ExRoutes.jobApplicantsModal({
      jobId: applicant?.jobId,
      applicantsViewTab: 'pipeline',
      applicantId: applicant?.id,
    }),
  placements: (applicant?: Applicant) => ExRoutes.applicantModalForPlacements({ applicantId: applicant?.id }),
};

/**
 * All locations of applicant modal;
 */
export type ApplicantLocation = 'jobApplicants' | 'jobPipeline' | 'placements' | 'talentPools' | 'empty';

/**
 * Hook to use applicant modal location;
 *
 * @param {Applicant} applicant
 *
 * @returns {ApplicantLocation}
 */
export function useApplicantLocation(applicant?: Applicant): ApplicantLocation {
  const location = useLocation();

  // empty location to prevent null checking
  if (!applicant) {
    return 'empty';
  }

  /**
   * `talentPools` location here used to be as some kind of default location
   * because of talentPool application modal route used candidate ID instead of applicant ID.
   */
  const applicantLocation = Object.entries(applicantLocationMapper).find(([_, fn]) => {
    return matchPath(location.pathname, { path: fn(applicant) });
  }) || [
    'talentPools',
    () => {
      // do nothing
    },
  ];

  return applicantLocation[0] as ApplicantLocation;
}

/**
 * Specially for the applicant modal.
 * Hook to receive applicants list according to applicant modal location.
 *
 * @param {Applicant} applicant
 * @returns {Array<Applicant> | undefined}
 */
export function useApplicantsByLocation(applicant?: Applicant): Array<Applicant> {
  const applicantListItemsSelector = useMemo(() => applicantListSelectors.getApplicants, []);
  const pipelineStageItemsSelector = useMemo(() => jobsSelectors.getApplicantsByPipelineStageId, []);
  const { jobId } = useParams<AppRouterParams>();
  /**
   * This hack is needed because API doesn't returns jobId in applicant;
   */
  const applicantWithJobId = {
    ...applicant!,
    jobId: applicant?.jobId ?? jobId ?? '',
  };

  const applicantLocation = useApplicantLocation(applicantWithJobId);
  const applicantModalSelectors: Record<ApplicantLocation, Selector<RootState, Applicant[]>> = {
    jobApplicants: (state: RootState) => applicantListItemsSelector(state, { id: ApplicantBelongsTo.job }),
    jobPipeline: (state: RootState) =>
      pipelineStageItemsSelector(state, {
        id: applicantWithJobId?.jobId ?? '',
        pipelineStageId: applicantWithJobId?.pipelineStageId,
      }),
    placements: (state: RootState) => applicantListItemsSelector(state, { id: ApplicantBelongsTo.placements }),
    talentPools: (state: RootState) => applicantListItemsSelector(state, { id: ApplicantBelongsTo.candidate }),
    empty: () => [],
  };

  return useSelector(applicantModalSelectors[applicantLocation]);
}

export function useApplicantLockProps({ applicantId }) {
  const applicantPipelineStageId = useAppSelector(
    applicantSelectors.selectApplicantCurrentPipelineStageId,
    applicantId,
  );

  const applicantPipelineStage = useAppSelector(hiringPipelinesStagesSelectors.selectById, applicantPipelineStageId);

  const nonPassedInterviewTemplates = useAppSelector(
    applicantSelectors.selectApplicantNonPassedInterviewTemplates,
    applicantId,
  );

  const isApplicantLockVisible =
    applicantPipelineStage?.hasInterviewStageActions && !!nonPassedInterviewTemplates?.length;

  const applicantLockTemplateNames = nonPassedInterviewTemplates?.map((template) => template.name)?.join(', ');

  const applicantLockHintText = applicantLockTemplateNames
    ? `${applicantLockTemplateNames} must be completed`
    : 'The interview must be completed';

  return {
    isApplicantLockVisible,
    applicantLockHintText,
  };
}
