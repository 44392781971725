import { put, SagaReturnType, select } from 'redux-saga/effects';

import { getActiveJobAdsFunc } from 'api-endpoints/calendar';

import { calendarJobAdsActions } from 'containers/Calendar/state/calendar-job-ads/calendar-job-ads.actions';
import { calendarUiSelectors } from 'containers/Calendar/state/calendar-ui/calendar-ui.selectors';
import { CalendarEvent } from 'containers/Calendar/utils';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { invokeApiCall } from 'utils/sagas';

export function* calendarJobAdsFetchAction(action: ReturnType<typeof calendarJobAdsActions.calendarJobAdsFetchAction>) {
  yield startLoader(action);

  const period = yield select(calendarUiSelectors.selectViewTarget);
  const targetDate = yield select(calendarUiSelectors.selectTargetDate);

  const { data, message, errorData }: SagaReturnType<typeof getActiveJobAdsFunc> = yield invokeApiCall(
    getActiveJobAdsFunc,
    {
      params: {
        period,
        targetDate,
      },
    },
  );

  if (!errorData && !message && data) {
    const updatedData = data.map((event) => CalendarEvent(event, 'jobAd'));
    yield put(calendarJobAdsActions.setAll(updatedData));
  }

  yield stopLoader(action);
}
