import type { Country, FilterOption, Location } from 'model';

import { WithCancelToken } from 'api-endpoints/utils';

import type { AvailabilityType, CandidateSource } from 'model/api-enums.constants';

import type { KeywordSelectOption } from 'components/KeywordSelect/KeywordSelect';
import { BasicModel } from 'utils/reducer/reducer-helper';

import { Job } from 'store/entities/jobs/models';

export interface Candidate extends BasicModel {
  // get Candidates list
  addedDate?: string;
  candidateId: string;
  fullName?: string;
  // photoUrl: string;
  talentPoolId?: string;

  // get Candidate by id
  photoUrl?: string | null;
  photoId?: string | null;
  firstName?: string;
  lastName?: string;
  phone?: string | null;
  mobile?: string | null;
  email?: string;
  address?: string;
  availability?: string;
  socialNetworks?: CandidateSocialNetwork[];
  createdBy?: string;
  createdOn?: string; //Date
  resumeExtracted?: boolean;

  source?: string;

  candidateWorkExperience?: CandidateWorkExperience;

  documentId?: string;
  resumeId?: string;
  location?: Partial<Location> | null;
  locationFormatted?: string;

  candidateLocation?: Partial<Location> | null;
  candidateAvailability?: AvailabilityObject;
  specificDate?: string;
  country?: string;
  doNotHire?: boolean;
}

export interface AvailabilityObject {
  actualDate: string | null;
  availabilityType: AvailabilityType;
}

export interface AvailabilityBaseFilter {
  availabilityActualDate: string | null;
  availabilityType: AvailabilityType;
}

export interface CandidateWorkExperience {
  year: string;
  location: Location;
  companyName: string;
  positionName: string;
}

export interface CandidateSocialNetwork {
  type: SocialNetwork;
  url: string;
}

export enum SocialNetwork {
  Facebook = 1,
  Twitter,
  LinkedIn,
  Github,
  GooglePlus,
  ICQ,
  Youtube,
  Blog,
  Meebo,
  Myspace,
  Qq,
  Skype,
  Wechat,
  Weibo,
  Website,
  Xing,
  YahooMessenger,
  Url,
}

export enum CandidateIfoKey {
  document = 'document',
  resume = 'resume',
  applicant = 'applicant',
  changeNote = 'change-note',
}

export enum CandidateBelongsTo {
  main = 'main',
  talentPool = 'talentPool',
  newApplicant = 'newApplicant',
}

export enum CandidateFiltersNames {
  name = 'name',
  belongsTo = 'belongsTo',
  talentPool = 'talentPool',
  keywords = 'keywords',
  locations = 'locations',
  countries = 'countries',
  availabilities = 'availabilities',
  availabilityDate = 'availabilityDate',
  sources = 'sources',
  orderBy = 'orderBy',
  excludeJobIds = 'excludeJobIds',
  candidateIds = 'candidateIds',
  doNotHire = 'doNotHire',
}

export interface CandidateFilters {
  [CandidateFiltersNames.name]?: string;
  [CandidateFiltersNames.belongsTo]?: [CandidateBelongsTo, BasicModel['id']];
  [CandidateFiltersNames.keywords]?: KeywordSelectOption[];
  [CandidateFiltersNames.talentPool]?: FilterOption[];
  [CandidateFiltersNames.locations]?: Location[];
  [CandidateFiltersNames.countries]?: Country[];
  [CandidateFiltersNames.availabilities]?: AvailabilityType[];
  [CandidateFiltersNames.availabilityDate]?: string;
  [CandidateFiltersNames.sources]?: CandidateSource[];
  [CandidateFiltersNames.orderBy]?: string;
  [CandidateFiltersNames.doNotHire]?: boolean;
  [CandidateFiltersNames.excludeJobIds]?: Array<Job['id']>;
  [CandidateFiltersNames.candidateIds]?: Array<Candidate['id']>;
}

export enum CandidateListSortBy {
  name = 'name',
}

export enum Columns {
  tickHeader = 'tickHeader',
  tick = 'tick',
  name = 'name',
  source = 'source',
  appliedDate = 'appliedDate',
  actions = 'actions',
}

type CandidateUrlParams = {
  urlParams: {
    candidateId?: Candidate['id'];
  };
};

export type CandidateAvatarUploadRequestDto = WithCancelToken<CandidateUrlParams & { data: FormData }>;

export type CandidateAvatarRemoveRequestDto = WithCancelToken<CandidateUrlParams>;
