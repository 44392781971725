import React, { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

import { CandidateAvatar } from 'components/CandidateAvatar';
import { Divider } from 'components/Divider';
import { EmptyState } from 'components/EmptyState';
import { IconEmptyStateComments } from 'components/Icons/IconEmptyStateComments';
import { Spinner0 } from 'components/Spinner';
import { ExEmptyStateBody } from 'components/ui/ExEmptyState';
import { getUserInitials } from 'utils/getUserInitials';

import { useSelector } from 'store/rootSelectors';

import { ExCommentForm } from './ExCommentForm';
import type { ExCommentListProps } from './ExCommentListProps';

const CommentsWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
`;

const ExCommentFormStyled = styled(ExCommentForm)`
  flex-grow: 1;
`;

const ExCommentFormInList = styled.div`
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: flex-start;
`;

const ExCommentListStyled = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;

  ${Divider} {
    margin-top: 0;
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  place-content: center;
  place-items: center;
  width: 100%;
`;

const AvatarWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 20px;
  flex-shrink: 0;
`;

const ExEmptyStateBodyStyled = styled(ExEmptyStateBody)`
  height: 100%;
`;

const useExCommentListState = ({
  className,
  component: Component,
  selector,
  onCommentRead,
  onCommentAdd,
  commentIds,
  onLoadMore,
  hasMore,
  onCommentDelete,
  onCommentUpdate,
  onReadAll,
  isInitLoading,
}: ExCommentListProps) => {
  const commentsWrapperRef = useRef<HTMLDivElement>(null);

  const user = useSelector((state) => state.auth.user);
  const userAvatar = user?.picture;
  const userInitials = getUserInitials(`${user?.name || ''}`);

  /**
   * Providing parent ref for scroll, for details please see docs for
   * {@link https://github.com/danbovey/react-infinite-scroller#custom-parent-element react-infinite-scroller} Custom parent element
   */
  const getScrollParent = () => commentsWrapperRef.current;

  useEffect(
    () => () => {
      onReadAll();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    className,
    Component,
    commentsWrapperRef,
    selector,
    onCommentRead,
    onCommentAdd,
    commentIds,
    onLoadMore,
    hasMore,
    onDelete: onCommentDelete,
    onUpdate: onCommentUpdate,
    isInitLoading,
    getScrollParent,
    userAvatar,
    userInitials,
  } as const;
};

const title = 'There are no comments yet. Start collaborating in the comments box below.';

export const ExCommentList: React.FC<ExCommentListProps> = (props) => {
  const {
    className,
    Component,
    commentsWrapperRef,
    selector,
    onCommentRead,
    onCommentAdd,
    commentIds,
    onLoadMore,
    hasMore,
    onDelete,
    onUpdate,
    isInitLoading,
    getScrollParent,
    userAvatar,
    userInitials,
  } = useExCommentListState(props);

  return (
    <ExCommentListStyled className={className}>
      <CommentsWrapper ref={commentsWrapperRef}>
        {!isInitLoading && !commentIds.length ? (
          <ExEmptyStateBodyStyled>
            <EmptyState title={title} icon={<IconEmptyStateComments />} />
          </ExEmptyStateBodyStyled>
        ) : (
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={onLoadMore}
            hasMore={Boolean(hasMore)}
            loader={
              <SpinnerWrapper key="SpinnerWrapper">
                <Spinner0 />
              </SpinnerWrapper>
            }
            useWindow={false}
            getScrollParent={getScrollParent}
          >
            {commentIds.map((commentId) => (
              <Component
                key={commentId}
                commentId={commentId}
                selector={selector}
                onCommentRead={onCommentRead}
                onDelete={onDelete}
                onUpdate={onUpdate}
              />
            ))}
          </InfiniteScroll>
        )}
      </CommentsWrapper>
      <Divider />
      <ExCommentFormInList>
        <AvatarWrapper>
          <CandidateAvatar src={userAvatar} initials={userInitials} />
        </AvatarWrapper>
        <ExCommentFormStyled onSubmit={onCommentAdd} />
      </ExCommentFormInList>
    </ExCommentListStyled>
  );
};
