import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'api-client/axiosBaseQuery';

import { ApiResponseWithPagination, ExCommentWidget } from 'model';

const recentMentionsAdapter = createEntityAdapter<ExCommentWidget>({
  selectId: (mention) => mention.commentId,
});

export const recentMentionsApi = createApi({
  reducerPath: 'recentMentionsApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/company/{companyId}/comment/',
  }),
  tagTypes: ['RecentMentions'],
  endpoints: (build) => ({
    getRecentMentions: build.query<EntityState<ExCommentWidget>, void>({
      query: () => ({ url: 'mention', method: 'GET' }),
      transformResponse: (baseQueryReturnValue: ApiResponseWithPagination<ExCommentWidget>) =>
        recentMentionsAdapter.addMany(recentMentionsAdapter.getInitialState(), baseQueryReturnValue.items),
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: 'RecentMentions' as const, id })),
              { type: 'RecentMentions' as const, id: 'LIST' },
            ]
          : [{ type: 'RecentMentions' as const, id: 'LIST' }],
    }),
  }),
});

export const { useGetRecentMentionsQuery } = recentMentionsApi;
