import { JobItemResponse, JobListItemResponse } from 'api-endpoints/job';

import { Job } from 'store/entities/jobs';
import { jobToState } from 'store/entities/jobs/mappers';

interface MapJobReturnValue {
  jobIds: Array<Job['id']>;
  jobs: Array<Job>;
}

export const mapJobs = (response: Array<JobListItemResponse>): MapJobReturnValue => {
  return response.map(jobToState).reduce(
    (acc, job) => ({
      jobIds: [...acc.jobIds, job?.id],
      jobs: [...acc.jobs, job],
    }),
    {
      jobIds: [],
      jobs: [],
    } as MapJobReturnValue,
  );
};

export const mapJob = (response: JobItemResponse): Job => {
  return jobToState(response);
};
