import { talentPoolCandidatesApi } from 'api-endpoints/talent-pool-candidates/talent-pool-candidates.api';

import { createLookupReducer, InitialState } from 'utils/reducer/reducer-helper';

import { EntityKeys } from 'store/constants';
import { candidatesApi } from 'store/entities/candidates/candidates.api';
import { Candidate } from 'store/entities/candidates/models';

export const candidatesSlice = createLookupReducer({
  name: EntityKeys.candidates,
  initialState: {
    allIds: [],
    byId: {},
  } as InitialState<Candidate>,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(candidatesApi.endpoints.candidate.matchFulfilled, (draft, { payload, type }) => {
      candidatesSlice.caseReducers.upsertOne(draft, {
        payload: { item: { ...payload } },
        type,
      });
    });
    builder.addMatcher(
      talentPoolCandidatesApi.endpoints.talentPoolCandidateListLoadMore.matchFulfilled,
      (draft, { payload, type }) => {
        candidatesSlice.caseReducers.upsert(draft, {
          payload: { items: payload.ids.map((id) => ({ id: id as string, ...payload.entities[id]! })) },
          type,
        });
      },
    );
  },
});
