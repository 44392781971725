import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';

const selectLocation = createSelector(
  (state: RootState) => state.router,
  (state) => state.location,
);

export const routerSelectors = {
  selectLocation,
};
