import { currencyAdapter, useCurrencyQuery } from 'store/dictionary/dictionary.api';

export const useCurrencyQuerySelectors = () => {
  const query = useCurrencyQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  const adapterSelectors = currencyAdapter.getSelectors(() => query.data ?? currencyAdapter.getInitialState());

  const selectors = {
    ...adapterSelectors,
  };

  return {
    ...query,
    selectors,
  };
};
