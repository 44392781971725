import { createSelector, createSlice, OutputSelector, PayloadAction } from '@reduxjs/toolkit';

import { UIKeys } from 'store/constants';
import { Applicant, ApplicantCard, applicantHeadersKeys, applicantHeadersNames } from 'store/entities/applicants';
import { RootState } from 'store/rootReducer';

export type ApplicantsFilters = Partial<Record<keyof ApplicantCard, boolean | undefined>>;

const initialState: ApplicantsFilters = {
  salaryExpectation: true,
  availability: true,
  rating: true,
  lastRole: true,
  source: true,
  seniorityLevel: true,
  location: true,
  onboardingStatus: true,
};

const applicantFiltersSlice = createSlice({
  name: UIKeys.applicantFilters,
  initialState,
  reducers: {
    updateFilters(state, { payload }: PayloadAction<Partial<ApplicantsFilters>>) {
      state = {
        ...state,
        ...payload,
      };
      return state;
    },
  },
});

export const applicantFiltersReducer = applicantFiltersSlice.reducer;

export const applicantFiltersActions = applicantFiltersSlice.actions;

const domain = (state: RootState) => state.ui.applicantFilters;

type GeneratedSelectors = Record<
  keyof ApplicantsFilters,
  OutputSelector<RootState, boolean | undefined, (f: ApplicantsFilters) => boolean | undefined>
>;

const applicantFiltersFieldSelectors = Object.keys(initialState).reduce((acc, applicantFilter) => {
  acc[applicantFilter as keyof ApplicantsFilters] = createSelector(
    domain,
    (filters) => filters[applicantFilter as keyof ApplicantsFilters],
  );

  return acc;
}, {} as GeneratedSelectors);

const selectDisabled = createSelector(domain, (filters) => {
  const o = Object.entries(filters);
  const p = o.filter(([_key, value]) => value !== true);
  return Object.fromEntries(p) as ApplicantsFilters;
});

const selectEnabled = createSelector(domain, (filters) => {
  const o = Object.entries(filters);
  const p = o.filter(([_key, value]) => value === true);
  return Object.fromEntries(p) as ApplicantsFilters;
});

const selectDisabledKeys = createSelector(
  selectDisabled,
  (filters) => Object.keys(filters) as Partial<Array<keyof Applicant>>,
);

const selectEnabledKeys = createSelector(
  selectEnabled,
  (filters) => Object.keys(filters) as Partial<Array<keyof Applicant>>,
);

const selectApplicantCardFilterItems = createSelector(domain, (filters) => {
  return applicantHeadersKeys.map((fieldName) => ({
    fieldName,
    isChecked: filters[fieldName] ?? false,
    label: applicantHeadersNames[fieldName],
  }));
});

export const applicantFiltersSelectors = {
  selectAll: domain,
  ...applicantFiltersFieldSelectors,
  selectDisabled,
  selectDisabledKeys,
  selectEnabledKeys,
  selectApplicantCardFilterItems,
};
