import { AvatarEditorProps } from 'react-avatar-editor';

export enum AppImageEditorTypes {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  GIF = 'image/gif',
}

export interface AppImageEditorProps extends AvatarEditorProps {
  className?: string;
  onChange?: (file: File | null) => void;
  type?: AppImageEditorTypes;
  square?: boolean;
  bgcolor?: string | null;
  image: File;
}
