import * as yup from 'yup';
import debounce from 'lodash/debounce';

import { getJobByReferenceNumber } from 'api-endpoints/job';

import { loaderActions } from 'containers/Loader/store';

import { getGlobalDispatch } from 'utils/globalStore';

const storeDispatch = getGlobalDispatch();

const referenceNoUniqDebounceTaskId = 'yup_async_validator_reference_number';

const referenceNoUniqDebounce = debounce(
  async (searchTerm: string, jobId: string, resolve: (exists: boolean) => void) => {
    storeDispatch(loaderActions.start(referenceNoUniqDebounceTaskId));

    const { data, message } = await getJobByReferenceNumber({
      params: { referenceNo: searchTerm },
    });

    storeDispatch(loaderActions.stop(referenceNoUniqDebounceTaskId));

    if (message) {
      resolve(false);
    }
    if (!data?.exists) {
      resolve(true);
    }

    if (data?.exists) {
      resolve(data?.job?.id === jobId);
    }

    resolve(false);
  },
  300,
);

const referenceNoUniq: yup.TestConfig<string> = {
  name: 'referenceNoUniq',
  test(v) {
    if (!v) {
      return false;
    }
    if (v.length < 4 || v.length > 10) {
      return false;
    }

    const id: string = this.resolve(yup.ref('id'));

    return new Promise<boolean>((resolve) => referenceNoUniqDebounce(v, id, resolve));
  },
  message: ({ label }) => `${label} must be unique`,
  params: {},
  exclusive: false,
};

export const referenceNoValidator = yup
  .string()
  .label('Reference Number')
  .trim()
  .default('')
  .required()
  .min(4)
  .max(10)
  .test(referenceNoUniq);
