export enum Routes {
  home = '',
  new = 'new',
  jobs = 'jobs',
  jobInterviews = 'job-interviews',
  profile = 'profile',
  dashboard = 'dashboard',
  selectCompany = 'select-company',
  jobAds = 'job-ads',
  talentPools = 'talent-pools',
  applicationInbox = 'application-inbox',
  jobBoards = 'job-boards',
  placements = 'placements',
  company = 'company',
  forbidden403 = 'page-forbidden',

  // Params
  jobIdParam = ':jobId',
  jobBoardIdParam = ':jobBoardId',
  jobAdIdParam = ':jobAdId',
  talentPoolIdParam = ':talentPoolId',
  candidateIdParam = ':candidateId',
  applicantsViewTabParam = ':applicantsViewTab',
  applicantIdParam = ':applicantId',
  interviewTemplateIdParam = ':interviewTemplateId',
  interviewIdParam = ':interviewId',

  // Auth
  login = 'login',
  loginConfirm = 'login-confirm',
  logout = 'logout',
  logoutConfirm = 'logout-confirm',

  // Job
  jobOverview = 'overview',
  jobApplicants = 'applicants',
  jobPipeline = 'pipeline',
  jobSettings = 'settings',
  jobPositionDetails = 'position-details',
  jobFormStepDetails = 'details',
  jobFormStepSalary = 'salary',
  jobFormStepPositionDetails = 'position-details',
  jobFormStepReview = 'review',
  jobFormStepFinal = 'job-created',
  jobApplicantsView = 'applicants-view/applicants',
  jobPipelineView = 'applicants-view/pipeline',
  jobEdit = 'edit',
  jobCreate = 'create',
  jobPromote = 'promote',

  // Applicant
  applicant = 'applicant',
  applicants = 'applicants',
  applicantNew = 'new-applicant',
  applicantStepSelectCandidate = 'select-candidate',
  applicantStepSelectJob = 'select-job',
  applicantStepSelectResume = 'select-resume',
  applicantStepSelectCoverLetter = 'select-cover-letter',
  applicantStepScreeningQuestions = 'screening-questions',
  applicantStepDetails = 'applicant-details',
  applicantStepReview = 'review',
  applicantStepFinal = 'applicant-created',
  applicantCoverLetter = 'applicant-cover-letter',
  applicantApplications = 'applicant-applications',
  applicantInterviews = 'applicant-interviews',
  applicantOverview = 'applicant-overview',
  applicantResume = 'applicant-resume',
  applicantEducation = 'applicant-education',
  applicantComments = 'applicant-comments',
  applicantWorkExperience = 'applicant-work-experience',
  applicantsView = 'applicants-view',

  // Job Boards
  jobBoardsFormSelectBoard = 'select-board',
  jobBoardsFormSettings = 'settings',
  jobBoardsFormFinal = 'successful-subscribed',

  // Job Ad
  jobAdStatistics = 'ad-statistics',
  jobAdSettings = 'ad-settings',
  jobAdPositionDetails = 'ad-position-details',
  jobAdNew = 'new-job-ad',
  jobAdSelectJob = 'job',
  jobAdSelectJobBoards = 'select-job-boards',
  jobAdDetails = 'details',
  jobAdRequirements = 'requirements',
  jobAdReview = 'review',
  jobAdFinalPage = 'job-ad-posted',

  // Talent Pools
  talentPoolNew = 'new-talent-pool',
  talentPoolAddNewCandidates = 'add-new-candidates',

  // Candidates
  candidates = 'candidates',

  //Candidate
  candidateDetails = 'details',
  candidateResumes = 'resumes',
  candidateDocuments = 'documents',
  candidateJobApplications = 'job-applicants',
  candidateTalentPools = 'talent-pools',
  // New Candidate
  candidateNew = 'new-candidate',
  // candidateUpdate = 'update-candidate',
  candidateStepDetails = 'details',
  candidateStepEducation = 'education',
  candidateStepWorkExperience = 'work-experience',
  candidateStepDocuments = 'documents',
  candidateStepReview = 'review',
  candidateStepFinal = 'new-candidate-final',

  // Company
  companyDetails = 'company-details',
  companyDefaultValue = 'company-default-value',
  companyIntegrations = 'company-integrations',
  companyEmailTemplate = 'company-email-template',
  companyScreeningQuestions = 'company-screening-questions',
  companyInterviewTemplates = 'company-interview-templates',
  companyHiringTemplate = 'company-hiring-template',
  interview = 'interview',

  // Test for webpack issue;
}
