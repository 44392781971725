import { createAction } from '@reduxjs/toolkit';

import { ListState } from 'utils/reducer/reducer-helper';

import { Applicant, ApplicantFilters } from 'store/entities/applicants/models';

const APPLICANT_LIST_RESET_FILTERS = 'APPLICANT_LIST_RESET_FILTERS';

export const applicantListResetFiltersAction = createAction<{
  listId: ListState<Applicant, ApplicantFilters, any>['id'];
}>(APPLICANT_LIST_RESET_FILTERS);
