import React from 'react';

import {
  InterviewAction,
  InterviewPlaceholder,
} from 'pages/Jobs/Job/JobTabs/JobInterviews/components/InterviewPlaceholder';
import { applicantInterviewsTableHeaders } from 'pages/Jobs/Job/JobTabs/JobInterviews/InterviewTableColumnsConfig';

import { useInterviewsTabState } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/Tabs/InterviewsTab/hooks/useInterviewsTabState';
import { InterviewsTabProps } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/Tabs/InterviewsTab/InterviewsTabProps';
import {
  InterviewsActions,
  InterviewsTabStyled,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/Tabs/InterviewsTab/InterviewsTabStyles';

import { Spinner } from 'components/Spinner';
import { ExTable } from 'components/ui/ExTable';

const text = 'There are no interviews';
const btnText = 'Create New Interview';

export const InterviewsTab: React.FC<InterviewsTabProps> = (props) => {
  const { className, items, pageNo, pageCount, orderBy, orderDir, onPaginationClick, onSortClick, isFetching } =
    useInterviewsTabState(props);

  return (
    <InterviewsTabStyled className={className}>
      <InterviewsActions>
        <InterviewAction text="New Interview" />
      </InterviewsActions>
      <ExTable
        selectId={(item) => item.interviewId}
        items={items}
        headers={applicantInterviewsTableHeaders}
        pageCount={pageCount}
        pageNo={pageNo}
        orderBy={orderBy}
        isLoading={isFetching}
        orderDir={orderDir}
        onPaginationClick={onPaginationClick}
        onSortClick={onSortClick}
        componentLoader={<Spinner />}
        componentDataEmpty={<InterviewPlaceholder title={text} text={btnText} />}
      />
    </InterviewsTabStyled>
  );
};
