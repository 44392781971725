import { consoleWarnForDevEnv } from 'utils/consoleErrorForDevEnv';

type Split<S extends string, Delimiter extends string> = S extends `${infer Head}${Delimiter}${infer Tail}`
  ? [Head, ...Split<Tail, Delimiter>]
  : S extends Delimiter
  ? []
  : [S];
type IsParam<T> = T extends `{${infer U}}` ? U : never;

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer Element> ? IsParam<Element> : never;

type ParamsFromString<T extends string> = Record<ElementType<Split<T, '/'>>, string>;

/**
 *
 * @param urlString
 * @param urlParams
 * @returns
 */
export function replaceParamsInUrl<Params extends ParamsFromString<S>, S extends string>(
  urlString: S,
  urlParams: Params,
) {
  if (!urlParams) {
    return urlString;
  }

  let urlWithParams: string = urlString;
  Object.entries<string>(urlParams).forEach(([key, value]) => {
    if (value) {
      urlWithParams = urlWithParams.replace(`{${key}}`, encodeURIComponent(value));
    } else {
      consoleWarnForDevEnv(`urpParam: ${value} isn't provided.`);
    }
  });

  return urlWithParams;
}
