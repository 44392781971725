import styled from 'styled-components';

import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

export const DateRangePicker = styled.div<{ isOpen: boolean }>`
  position: absolute;
  color: inherit;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.neutral};
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100%;
  z-index: 3001;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 15px;
  line-height: 1em;

  @media (min-width: 564px) {
    width: auto;
  }

  &::before,
  &::after {
    position: absolute;
    display: inline-block;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
  }

  &::before {
    top: -7px;
    right: 9px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 6px solid ${(props) => props.theme.colors.neutral};
  }

  &::after {
    top: -6px;
    right: 10px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid ${(props) => props.theme.colors.white};
    border-left: 6px solid transparent;
  }
`;

export const DateRangePickerContentWrapper = styled.div`
  display: block;

  @media (min-width: 564px) {
    display: flex;
  }
`;

export const Ranges = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;

  > ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: visible;
    height: 217px;

    @media (min-width: 564px) {
      width: 140px;
    }

    > li {
      font-size: ${getThemeFontSize('small')};
      padding: 8px 12px;
      cursor: pointer;
      background-color: ${(props) => props.theme.colors.white};

      &:hover {
        background-color: ${(props) => props.theme.colors.light};
      }

      &.active {
        background-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.white};
      }

      &:last-child {
        position: sticky;
        bottom: 0;
      }
    }
  }
`;

type CalendarProps = {
  isOpen: boolean;
  left?: boolean;
  right?: boolean;
  single?: boolean;
};

export const Calendar = styled.div<CalendarProps>`
  max-width: 270px;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  ${(props) => props.left && 'padding: 8px 0 8px 8px;'};
  ${(props) => props.left && 'border-left: 1px solid #ddd;'};
  ${(props) => props.right && 'padding: 8px;'}
  ${(props) => props.single && 'border: none;'};
`;

export const CalendarTable = styled.div<{ left?: boolean }>`
  border: 1px solid ${(props) => props.theme.colors.white};
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.white};
  ${(props) => props.left && 'padding-right: 8px;'}
`;

export const BtnWrapper = styled.div<{ isApplyCancelButtonsVisible?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.isApplyCancelButtonsVisible ? 'flex-end' : 'center')};
  flex-grow: 1;
  border-top: 1px solid ${(props) => props.theme.colors.neutral};
  padding: 8px;

  > *:not(:last-child) {
    margin-right: 0.5em;
  }
`;

export const Tr = styled.tr``;

export const Th = styled.th`
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: ${getThemeFontSize('small')};
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;

  &.clickable {
    &:hover {
      background-color: ${(props) => props.theme.colors.neutral};
    }
  }
`;

export const TBody = styled.tbody``;

type TdProps = {
  isStartDate?: boolean;
  isEndDate?: boolean;
  isSelectedMonth?: boolean;
  isWithinInterval?: boolean;
  isHovered?: boolean;
};

export const Td = styled.td<TdProps>`
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: ${getThemeFontSize('small')};
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;

  &.in-range {
    background-color: ${(props) => props.theme.colors.primaryLight};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.light};
  }

  &.selected {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
  }

  &.start-date {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &.end-date {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &.off {
    color: ${(props) => props.theme.colors.neutral};
    background-color: transparent;
    cursor: default;
  }

  &.disabled {
    color: ${(props) => props.theme.colors.neutral};
    cursor: not-allowed;
    text-decoration: line-through;
  }
`;

export const THead = styled.thead``;

export const Table = styled.table`
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
`;

const Arrow = styled.span`
  border-color: ${(props) => props.theme.colors.dark};
  border-style: solid;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
`;

export const Prev = styled(Arrow)`
  transform: rotate(135deg);
`;
export const Next = styled(Arrow)`
  transform: rotate(-45deg);
`;

export const SelectedDate = styled.div`
  display: flex;
  align-items: center;
  font-size: ${getThemeFontSize('small')};
`;
