import React from 'react';
import { connect } from 'react-redux';
import styled, { keyframes } from 'styled-components';

import { Alert as AlertComponent } from 'containers/AlertManager/Alert';
import { Alert } from 'containers/AlertManager/store/models';
import { alertsActions } from 'containers/AlertManager/store/reducers';
import { alertsSelectors } from 'containers/AlertManager/store/selectors';

import { compose } from 'utils/funcs';

import { RootState } from 'store/rootReducer';

type AlertManagerProps = {
  alerts: Alert[];
  deleteAll: () => void;
  hasBlocking: boolean;
};

interface BackdropProps {
  open: boolean;
  onClose: Function;
  className?: string;
}

const BackdropPure: React.FC<BackdropProps> = ({ onClose, children, className }) => {
  const backdropRef = React.useRef<HTMLDivElement | null>(null);
  function handleClick() {
    typeof onClose === 'function' && onClose();
  }

  return (
    <div className={className} ref={backdropRef} onClick={handleClick}>
      {children}
    </div>
  );
};

const AnimationIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const Backdrop = styled(BackdropPure)`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  animation: ${AnimationIn};
  animation-duration: 0.3s;
`;

const AlertsWrapper = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  flex-direction: column;
  top: 0;
  display: flex;
  align-items: center;
  padding-top: 25px;
  z-index: 1070;
`;

const AlertManagerPure: React.FC<AlertManagerProps> = ({ alerts, deleteAll, hasBlocking }) => {
  const handleClose = () => {
    deleteAll();
  };

  return (
    <>
      <Backdrop open={hasBlocking} onClose={handleClose} />
      <AlertsWrapper>
        {alerts.length
          ? alerts.map((alert: Alert) => (
              <AlertComponent
                key={alert.id}
                className={alert.containerClassName}
                alertId={alert.id}
                variant={alert.type}
                message={alert.message}
                closable={alert.closable}
                isHtml={alert.isHtml}
                children={alert.children}
              />
            ))
          : null}
      </AlertsWrapper>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  alerts: alertsSelectors.getAll(state),
  hasBlocking: alertsSelectors.hasBlocking(state),
});

const mapDispatchToProps = {
  deleteAll: alertsActions.deleteAll,
};

const enhance = compose(React.memo, connect(mapStateToProps, mapDispatchToProps));

export const AlertManager = enhance(AlertManagerPure);
