import React, { useEffect } from 'react';
import styled from 'styled-components';

import { loaderSelectors } from 'containers/Loader/store';
import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import { modalsSelectors } from 'containers/Modals/store';
import { AddToTalentPoolFormBody } from 'containers/TalentPoolForms';
import {
  talentPoolFormActions,
  talentPoolFormEffects,
  talentPoolFormSelectors,
} from 'containers/TalentPoolForms/state';
import { loadTalentPools } from 'containers/TalentPoolLists/store/effects';

import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { DialogProps } from 'components/Dialog';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { ExDialog, ExDialogActions } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { TalentPoolBelongsTo, talentPoolSelectors } from 'store/entities/talent-pools';

export interface AddToTalentPoolProps extends AppModalContentProps {
  className?: string;
}

export const AddToTalentPool: React.FC<AddToTalentPoolProps> = ({ className, onClose }) => {
  const dispatch = useAppDispatch();

  const candidateTalentPools = useAppSelector(talentPoolFormSelectors.candidateTalentPoolIds);
  const isCandidateTalentPoolsLoading = useAppSelector(loaderSelectors.isTaskActive, {
    taskId: talentPoolFormEffects.loadCandidateTalentPools.processing,
  });
  const modalCandidateId = useAppSelector(modalsSelectors.modalCandidateId);
  const talentPoolIdsForSelect = useAppSelector(talentPoolFormSelectors.selectedTalentPoolsIds);

  const defaultValue = useAppSelector(talentPoolSelectors.getByIds, talentPoolIdsForSelect);

  const content: DialogProps['content'] = {
    title: 'Add to New Talent Pool',
    withTitle: true,
  };

  const onSubmitClickHandler: React.MouseEventHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (modalCandidateId) {
      const result = await dispatch(
        talentPoolFormEffects.updateCandidateTalentPools({ candidateId: modalCandidateId }),
      );
      if (!result) {
        return;
      }
      const success = await dispatch(loadTalentPools({ listId: TalentPoolBelongsTo.candidate }));
      if (!success) {
        return;
      }
      onClose();
    }
  };

  useEffect(() => {
    dispatch(
      talentPoolFormActions.setSelectedTalentPoolsIds({
        selectedTalentPoolsIds: candidateTalentPools,
      }),
    );
  }, [candidateTalentPools, dispatch]);

  useEffect(() => {
    if (modalCandidateId) {
      dispatch(
        talentPoolFormEffects.loadCandidateTalentPools({
          candidateId: modalCandidateId,
          preloader: true,
        }),
      );
    }

    return () => {
      dispatch(talentPoolFormActions.clearFormTalentPool({}));
    };
  }, [dispatch, modalCandidateId]);

  if (isCandidateTalentPoolsLoading) {
    return null;
  }

  return (
    <AddToTalentPoolStyled onClose={onClose} content={content} className={className}>
      <Body>
        <AddToTalentPoolFormBody defaultValue={defaultValue} />
        <ExDialogActions>
          <ExButton variant="light" onClick={onClose}>
            Cancel
          </ExButton>
          <ExLoaderHandlerWrapper>
            <ExButton className="ml-3" onClick={onSubmitClickHandler}>
              Save
            </ExButton>
          </ExLoaderHandlerWrapper>
        </ExDialogActions>
      </Body>
    </AddToTalentPoolStyled>
  );
};

const AddToTalentPoolStyled = styled(ExDialog)`
  width: 100%;
  margin: 0 auto;
  max-width: 500px;
  background-color: ${(props) => props.theme.colors.white};
`;

const Body = styled.div`
  width: 100%;
`;
