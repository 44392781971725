import React from 'react';
import { useLocation } from 'react-router';

import { ExTabMenuNavigationV2 } from 'components/TabMenu/TabMenuComponents';

import { ExTabMenuItemV2 } from './TabMenuItemV2';
import { NavItemV2, TabMenuPropsV2 } from './TabMenuProps';

const useTabMenuV2State = ({
  navItems,
  strictUrlMatching,
}: Pick<TabMenuPropsV2, 'navItems' | 'strictUrlMatching'>): NavItemV2 => {
  if (strictUrlMatching === undefined) {
    strictUrlMatching = true;
  }
  const location = useLocation();
  const matchString = strictUrlMatching ? location.pathname + location.search : location.pathname;
  return navItems.find((item) => matchString.endsWith(item.path)) || navItems[0];
};

const ExTabMenuV2Pure: React.FC<TabMenuPropsV2> = ({ className, navItems, strictUrlMatching }) => {
  const activeItem = useTabMenuV2State({ navItems, strictUrlMatching });

  return (
    <ExTabMenuNavigationV2 className={className} activeKey={activeItem?.path}>
      <>
        {navItems.map((nav) => (
          <React.Fragment key={nav.path}>
            <ExTabMenuItemV2 item={nav} active={activeItem === nav} />
          </React.Fragment>
        ))}
      </>
    </ExTabMenuNavigationV2>
  );
};

export const ExTabMenuV2 = React.memo(ExTabMenuV2Pure);
