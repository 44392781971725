import styled from 'styled-components/macro';

export const AlertStyled = styled.div`
  background: #fff3d6;
  border: 1px solid #fcb300;
  border-radius: 99px;
  padding: 8px 13px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.default};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.default};
    text-decoration: none;
  }
`;

export const AlertIcon = styled.div`
  flex-shrink: 0;
  margin-right: 7px;
`;

export const AlertText = styled.div``;
