import React, { useCallback, useMemo } from 'react';

import { uploadCandidateAvatar } from 'api-endpoints/candidate';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { modalsSelectors } from 'containers/Modals/store';

import { AppImagePickerDialog } from 'components/AppImagePickerDialog';
import { CandidateAvatarProps } from 'components/CandidateAvatar/CandidateAvatarTypes';
import { CandidateAvatarEditableComponent } from 'components/CandidateAvatar/components/CandidateAvatarEditableComponent';
import { CandidateAvatarEditableDropdown } from 'components/CandidateAvatar/components/CandidateAvatarEditableDropdown';
import { CandidateAvatarIcon } from 'components/CandidateAvatar/components/CandidateAvatarIcon';
import { makeChildren } from 'components/Dialog';
import { loaderManagerActions } from 'modules/LoaderManager/redux';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useModal } from 'utils/hooks/useModal';

import { candidateAvatarRemoveRequest } from 'store/entities/candidates/candidate.actions';
import { candidatesApi } from 'store/entities/candidates/candidates.api';
import { useSelector } from 'store/rootSelectors';

const LOADER_ACTION = 'uploadCandidateAvatar';

export const CandidateAvatarEditable: React.FC<CandidateAvatarProps> = (props) => {
  const dispatch = useAppDispatch();
  const candidateId = useSelector(modalsSelectors.modalCandidateId);
  const modal = useModal({
    initialOpen: false,
  });

  const uploadAvatarHandler = useCallback(
    async (file: File | null) => {
      dispatch(loaderManagerActions.start(LOADER_ACTION));

      if (!file || !candidateId) {
        return;
      }

      const formData = new FormData();
      formData.append('avatar', file, file?.name);
      const result = await uploadCandidateAvatar({
        urlParams: { candidateId },
        data: formData,
      });
      if (result.message) {
        dispatch(
          alertsEffects.apiCallError({
            message: result.message,
            errorData: result.errorData,
          }),
        );
        return;
      }
      dispatch(alertsEffects.showSuccess({ message: 'Avatar successfully uploaded.' }));
      dispatch(candidatesApi.util.invalidateTags([{ type: 'Candidates', id: candidateId }]));
      dispatch(loaderManagerActions.stop(LOADER_ACTION));
      modal.hide(null);
    },
    [candidateId, dispatch, modal],
  );

  const uploadModalContent = useMemo(() => {
    const onError = (message: string) => {
      dispatch(alertsEffects.showError({ message }));
    };

    return makeChildren(({ onClose }) => (
      <div className="w-100">
        <h4 className="mb-4 text-center">Upload Profile Photo</h4>
        <AppImagePickerDialog
          onSave={uploadAvatarHandler}
          onError={onError}
          onCancel={() => onClose(false)}
          loaderAction={LOADER_ACTION}
        />
      </div>
    ));
  }, [dispatch, uploadAvatarHandler]);

  const showUploadModal = useCallback(async () => {
    await modal.show({
      children: uploadModalContent,
    });
  }, [modal, uploadModalContent]);

  const showDeleteModal = useCallback(async () => {
    const result = await modal.show({
      message: `Confirm delete avatar image.`,
      title: 'Confirm Action',
      withActions: true,
      withTitle: true,
      buttonOkVariant: 'primary',
    });
    if (result && candidateId) {
      dispatch(candidateAvatarRemoveRequest({ urlParams: { candidateId } }));
    }
  }, [modal, candidateId, dispatch]);

  const menuItems = useMemo(() => {
    return [
      {
        label: 'Upload New Photo',
        action: showUploadModal,
      },
      {
        label: 'Delete Photo',
        action: showDeleteModal,
        variant: 'primary',
      },
    ];
  }, [showDeleteModal, showUploadModal]);

  const candidateHasAvatar = useMemo(() => {
    return Boolean(props.src);
  }, [props.src]);

  const candidateAvatarIconClickHandler = useCallback(() => {
    if (candidateHasAvatar) {
      return;
    }

    showUploadModal();
  }, [candidateHasAvatar, showUploadModal]);

  return (
    <CandidateAvatarEditableComponent {...props} candidateHasAvatar={candidateHasAvatar}>
      <CandidateAvatarEditableDropdown menuItems={menuItems}>
        <CandidateAvatarIcon candidateHasAvatar={candidateHasAvatar} onClick={candidateAvatarIconClickHandler} />
      </CandidateAvatarEditableDropdown>
    </CandidateAvatarEditableComponent>
  );
};
