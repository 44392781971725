import { ApplicationRole } from 'model/api-enums.constants';

import type { EnumLiteralsOf } from 'utils/funcs';

export const UserAPI = (dto: {}) => ({
  companyId: '' as string,
  userId: '' as string,
  applicationRoles: ApplicationRole.None as EnumLiteralsOf<typeof ApplicationRole>,
  timeZone: '' as string,
  name: '',
  pictureUrl: null as null | string,
  ...dto,
});
