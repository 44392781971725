import { createAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';

import { PipelineStageType } from 'model/api-enums.constants';
import { WithRequired } from 'model/utils';

import { adapterHelper } from 'utils/reducer/adapter-helper';

import { EntityKeysNew } from 'store/constants';
import { Applicant, ApplicantFilterOpt } from 'store/entities/applicants/models';
import { applicantSelectors } from 'store/entities/applicants/selectors';
import {
  HiringPipelineActions,
  HiringPipelineStagePrepareCreate,
  HiringPipelineStagePrepareEdit,
  HiringPipelineStagePrepareRemove,
} from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.model';
import type { HiringPipelineStage } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.types';
import { hiringPipelineStagesAdapter } from 'store/entities/hiring-pipelines/hiring-pipelines.api';
import { hiringPipelineSelectors } from 'store/entities/hiring-pipelines/hiring-pipelines.reducer';
import type { HiringPipeline } from 'store/entities/hiring-pipelines/hiring-pipelines.types';
import { jobsSelectors } from 'store/entities/jobs/selectors';
import { createActionApiCallBatch } from 'store/entities/qualification-type/qualification-type.model';
import { RootState } from 'store/rootReducer';

const hiringPipelinesStagesAdapter = createEntityAdapter<HiringPipelineStage>({
  selectId: (item) => item.pipelineStageId,
});

// UTILITIES ---------------------------------------------------------------------------------------
const getIds = (hiringPipelineStage: HiringPipelineStage): HiringPipelineStage['pipelineStageId'] =>
  hiringPipelineStage.pipelineStageId;

const filterByPipelineId = (pipelineId: HiringPipeline['pipelineId']) => (hiringPipelineStage: HiringPipelineStage) =>
  hiringPipelineStage.pipelineId === pipelineId;

function sortStageByOrder(stageA: HiringPipelineStage, stageB: HiringPipelineStage) {
  return stageA.order - stageB.order;
}

export const isValidStageType = (stageType: number): boolean => {
  if (typeof stageType !== 'number') {
    return false;
  }

  return true;
};

export const isValidStage = (hiringPipelineStage: HiringPipelineStage): boolean => {
  if (!hiringPipelineStage) {
    return false;
  }

  if (!isValidStageType(hiringPipelineStage?.stageType)) {
    return false;
  }

  return true;
};

export const isSystemStage = (hiringPipelineStage: HiringPipelineStage): boolean => {
  if (!isValidStage(hiringPipelineStage)) {
    return false;
  }

  return [
    PipelineStageType.New,
    PipelineStageType.ReadyToOnboard,
    PipelineStageType.InOnboarding,
    PipelineStageType.Hired,
    PipelineStageType.Declined,
    PipelineStageType.Withdrawn,
  ].includes(hiringPipelineStage.stageType);
};

export const isCustomStage = (hiringPipelineStage: HiringPipelineStage): boolean => {
  if (!isValidStage(hiringPipelineStage)) {
    return false;
  }

  return [
    PipelineStageType.Custom,
    PipelineStageType.Shortlisted,
    PipelineStageType.Interviewed,
    PipelineStageType.Screened,
    PipelineStageType.Negotiated,
  ].includes(hiringPipelineStage.stageType);
};

export const isRequiredCustomStage = (hiringPipelineStage: HiringPipelineStage): boolean => {
  if (!isValidStage(hiringPipelineStage)) {
    return false;
  }

  return [
    PipelineStageType.Custom,
    PipelineStageType.Shortlisted,
    PipelineStageType.Interviewed,
    PipelineStageType.Screened,
  ].includes(hiringPipelineStage.stageType);
};

export const isRemovableStage = (hiringPipelineStage: HiringPipelineStage): boolean =>
  isCustomStage(hiringPipelineStage) || hiringPipelineStage.stageType === PipelineStageType.Negotiated;

export const isCreatableStageByType = (stageType: number): boolean => {
  if (!isValidStageType(stageType)) {
    return false;
  }

  return [
    PipelineStageType.Shortlisted,
    PipelineStageType.Interviewed,
    PipelineStageType.Screened,
    PipelineStageType.Negotiated,
  ].includes(stageType);
};

export const isInterviewTemplateStage = (hiringPipelineStage: HiringPipelineStage): boolean => {
  if (!isValidStage(hiringPipelineStage)) {
    return false;
  }

  return ![
    PipelineStageType.New,
    PipelineStageType.ReadyToOnboard,
    PipelineStageType.InOnboarding,
    PipelineStageType.Hired,
    PipelineStageType.Declined,
    PipelineStageType.Withdrawn,
  ].includes(hiringPipelineStage.stageType);
};

export const isPossiblyRequiredStage = (hiringPipelineStage: HiringPipelineStage): boolean => {
  if (!isValidStage(hiringPipelineStage)) {
    return false;
  }

  return ![
    PipelineStageType.New,
    PipelineStageType.Hired,
    PipelineStageType.Declined,
    PipelineStageType.Withdrawn,
  ].includes(hiringPipelineStage.stageType);
};

//--------------------------------------------------------------------------------------------------

const hiringPipelinesStagesSlice = createSlice({
  initialState: hiringPipelinesStagesAdapter.getInitialState(),
  name: EntityKeysNew.hiringPipelineStage,
  reducers: {
    ...adapterHelper(hiringPipelinesStagesAdapter),
  },
});
// ACTIONS -----------------------------------------------------------------------------------------
const HIRING_PIPELINE_STAGE_REORDER = 'hiringPipelineStage/reorder';
const HIRING_PIPELINE_STAGE_REMOVE = `${EntityKeysNew.hiringPipelineStage}/remove`;
const HIRING_PIPELINE_STAGE_SHOW_REMOVE_MODAL = `${EntityKeysNew.hiringPipelineStage}/showRemoveModal`;
const HIRING_PIPELINE_STAGE_PREPARE_REMOVE = `${EntityKeysNew.hiringPipelineStage}/prepareRemove`;
const HIRING_PIPELINE_STAGE_EDIT = `${EntityKeysNew.hiringPipelineStage}/edit`;
const HIRING_PIPELINE_STAGE_SHOW_EDIT_MODAL = `${EntityKeysNew.hiringPipelineStage}/showEditModal`;
const HIRING_PIPELINE_STAGE_PREPARE_EDIT = `${EntityKeysNew.hiringPipelineStage}/prepareEdit`;
const HIRING_PIPELINE_STAGE_CREATE = `${EntityKeysNew.hiringPipelineStage}/create`;
const HIRING_PIPELINE_STAGE_SHOW_CREATE_MODAL = `${EntityKeysNew.hiringPipelineStage}/showCreateModal`;
const HIRING_PIPELINE_STAGE_PREPARE_CREATE = `${EntityKeysNew.hiringPipelineStage}/prepareCreate`;
const HIRING_PIPELINE_STAGE_UPDATE = `${EntityKeysNew.hiringPipelineStage}/update`;
const HIRING_PIPELINE_STAGE_PROCESSING_FINISHED = `${EntityKeysNew.hiringPipelineStage}/processingFinished`;
const HIRING_PIPELINE_STAGE_EMPTY_ACTION = `${EntityKeysNew.hiringPipelineStage}/emptyAction`;
const HIRING_PIPELINE_STAGE_ACTION_SEND_EMAIL_ADD = `${EntityKeysNew.hiringPipelineStage}/action/sendEmail/add`;
const HIRING_PIPELINE_STAGE_ACTION_SEND_EMAIL_REMOVE = `${EntityKeysNew.hiringPipelineStage}/action/sendEmail/remove`;
const HIRING_PIPELINE_STAGE_ACTION_FETCH = `${EntityKeysNew.hiringPipelineStage}/action/fetch`;

const processingFinished = createAction<{ originalId?: string; returnedId?: string }>(
  HIRING_PIPELINE_STAGE_PROCESSING_FINISHED,
);
const emptyAction = createAction(HIRING_PIPELINE_STAGE_EMPTY_ACTION);

const remove = createActionApiCallBatch<HiringPipelineStagePrepareRemove>(HIRING_PIPELINE_STAGE_REMOVE);
const update = createActionApiCallBatch<HiringPipelineStage>(HIRING_PIPELINE_STAGE_UPDATE);
const create =
  createActionApiCallBatch<
    Pick<HiringPipelineStage, 'pipelineId' | 'name' | 'stageType' | 'order' | 'pipelineStageId'>
  >(HIRING_PIPELINE_STAGE_CREATE);
export type HiringPipelineStagesReorderPayload = {
  source: number;
  destination: number;
  pipelineStageId: string;
  pipelineId: string;
};
const reorder = createAction<HiringPipelineStagesReorderPayload>(HIRING_PIPELINE_STAGE_REORDER);
const edit = createAction<HiringPipelineStagePrepareRemove>(HIRING_PIPELINE_STAGE_EDIT);
const showEditModal = createAction<HiringPipelineStagePrepareRemove>(HIRING_PIPELINE_STAGE_SHOW_EDIT_MODAL);
const prepareEdit = createAction<HiringPipelineStagePrepareEdit>(HIRING_PIPELINE_STAGE_PREPARE_EDIT);
const showRemoveModal = createAction<HiringPipelineStagePrepareRemove>(HIRING_PIPELINE_STAGE_SHOW_REMOVE_MODAL);
const prepareRemove = createAction<HiringPipelineStagePrepareRemove>(HIRING_PIPELINE_STAGE_PREPARE_REMOVE);

const showCreateModal = createAction<Pick<HiringPipelineStage, 'pipelineId'>>(HIRING_PIPELINE_STAGE_SHOW_CREATE_MODAL);
const prepareCreate = createAction<HiringPipelineStagePrepareCreate>(HIRING_PIPELINE_STAGE_PREPARE_CREATE);

const addSendEmailAction = createActionApiCallBatch<
  WithRequired<HiringPipelineActions, 'type' | 'actionItemId'> &
    Pick<HiringPipelineStage, 'pipelineId' | 'pipelineStageId'>
>(HIRING_PIPELINE_STAGE_ACTION_SEND_EMAIL_ADD);

const removeSendEmailAction = createActionApiCallBatch<
  WithRequired<HiringPipelineActions, 'pipelineActionId' | 'type' | 'actionItemId'> &
    Pick<HiringPipelineStage, 'pipelineId' | 'pipelineStageId'>
>(HIRING_PIPELINE_STAGE_ACTION_SEND_EMAIL_REMOVE);

const actionFetch = (suffix: string) => createAction(`${HIRING_PIPELINE_STAGE_ACTION_FETCH}/${suffix}`);

export const hiringPipelineStageActions = {
  ...hiringPipelinesStagesSlice.actions,
  actionFetch,
  addSendEmailAction,
  create,
  edit,
  emptyAction,
  prepareCreate,
  prepareEdit,
  prepareRemove,
  processingFinished,
  remove,
  removeSendEmailAction,
  reorder,
  showCreateModal,
  showEditModal,
  showRemoveModal,
  update,
};
//--------------------------------------------------------------------------------------------------

// REDUCER -----------------------------------------------------------------------------------------
export const hiringPipelinesStagesReducer = hiringPipelinesStagesSlice.reducer;

// SELECTORS ---------------------------------------------------------------------------------------

const searchPipelineStagesData = createSelector(hiringPipelineSelectors.searchPipelineResult, (result) => {
  return result?.data?.pipelineStages;
});

const hiringPipelinesStagesAdapterSelectors = hiringPipelineStagesAdapter.getSelectors(
  (state: RootState, ...props: any[]) => {
    const searchPipelineStagesState = searchPipelineStagesData(state);
    const getPipelineStages = hiringPipelineSelectors.selectGetPipelineQueryPipelineStages(state, props[0]);

    return searchPipelineStagesState ?? getPipelineStages ?? hiringPipelineStagesAdapter.getInitialState();
  },
);

const selectByPipelineId = createSelector(
  hiringPipelinesStagesAdapterSelectors.selectAll,
  (state: RootState, pipelineId: HiringPipeline['pipelineId'] | undefined) => pipelineId,
  (state, pipelineId) => {
    if (!pipelineId) {
      return [];
    }
    return state.filter(filterByPipelineId(pipelineId));
  },
);

const checkHasEnoughRequiredCustomStages = createSelector(selectByPipelineId, (stages) => {
  return stages.filter((stage) => isRequiredCustomStage(stage)).length > 1;
});

const checkHasMoreThanOneCustomStages = createSelector(selectByPipelineId, (stages) => {
  return stages.filter((stage) => isCustomStage(stage)).length > 1;
});

const selectByPipelineIdSortedByOrder = createSelector(selectByPipelineId, (state) =>
  [...state].sort(sortStageByOrder),
);

const selectIdsByPipelineId = createSelector(selectByPipelineId, (state) => {
  return state.map(getIds);
});

const selectIdsByPipelineIdSortedByOrder = createSelector(selectByPipelineId, (state) =>
  [...state].sort(sortStageByOrder).map(getIds),
);

const selectJobPipelineStages = createSelector(
  hiringPipelinesStagesAdapterSelectors.selectAll,
  hiringPipelineSelectors.selectJobPipeline,
  (stages, pipeline) => stages.filter((stage) => stage.pipelineId === pipeline?.pipelineId),
);

const selectPipelineIdByPipelineStageId = createSelector(
  hiringPipelinesStagesAdapterSelectors.selectById,
  (hiringPipelineStage) => hiringPipelineStage?.pipelineId,
);

const selectSimilarByPipelineStageId = createSelector(
  (state: RootState) => state,
  selectPipelineIdByPipelineStageId,
  (state, pipelineId) => {
    return selectByPipelineId(state, pipelineId ?? '');
  },
);

const selectJobPipelineStagesForPipelineTab = createSelector(selectJobPipelineStages, (stages) =>
  stages.filter((stage) =>
    [
      PipelineStageType.Shortlisted,
      PipelineStageType.Interviewed,
      PipelineStageType.Screened,
      PipelineStageType.Negotiated,
      PipelineStageType.ReadyToOnboard,
      PipelineStageType.InOnboarding,
      PipelineStageType.Hired,
      PipelineStageType.Declined,
      PipelineStageType.Withdrawn,
    ].includes(stage.stageType),
  ),
);

const applyApplicants = (stages: HiringPipelineStage[], applicants: Applicant[]) => {
  return stages.map((stage) => ({
    ...stage,
    applicants: applicants.filter((applicant) => applicant.pipelineStageId === stage.pipelineStageId),
  }));
};

const getWorkflowByJobId = createSelector(
  selectJobPipelineStagesForPipelineTab,
  (state: RootState, jobId: string) => jobsSelectors.getApplicants(state, { id: jobId }),
  applyApplicants,
);

const getWorkflowApplicantCountersByJobId = createSelector(
  selectJobPipelineStagesForPipelineTab,
  (state: RootState, jobId: string) => jobsSelectors.getApplicants(state, { id: jobId }),
  (stages: HiringPipelineStage[], applicants: Applicant[]) => {
    const applicantsList = stages.map((stage) => ({
      pipelineStageId: stage.pipelineStageId,
      applicants: applicants
        .filter((applicant) => applicant.pipelineStageId === stage.pipelineStageId)
        .map((applicant) => applicant.applicantId),
    }));
    const result = {};
    applicantsList.forEach((item) => {
      const totalApplicantsCount = item.applicants.length;
      item.applicants.forEach((applicant) => {
        const inListPosition = item.applicants.indexOf(applicant) + 1;
        result[applicant] = {
          inListPosition,
          totalApplicantsCount,
        };
      });
    });
    return result;
  },
);

const selectJobPipelineHiredStage = createSelector(selectJobPipelineStages, (stages) =>
  stages.find((stage) => stage.stageType === PipelineStageType.Hired),
);

const selectApplicantPipelineStages = createSelector(
  (state) => state,
  jobsSelectors.selectJobByApplicantId,
  (state, job) => {
    return selectJobPipelineStages(state, job?.jobId);
  },
);

const selectApplicantHiredStage = createSelector(selectApplicantPipelineStages, (stages) => {
  return stages.find((stage) => stage.stageType === PipelineStageType.Hired);
});

const selectApplicantPipelineStagesByApplicantPipelineId = createSelector(
  (state: RootState) => state,
  applicantSelectors.selectApplicantCurrentPipelineId,
  (state, applicantPipelineId) => {
    return selectByPipelineId(state, applicantPipelineId ?? '');
  },
);

const selectApplicantHiredStageByApplicantId = createSelector(
  selectApplicantPipelineStagesByApplicantPipelineId,
  (stages) => {
    return stages.find((stage) => stage.stageType === PipelineStageType.Hired);
  },
);

const selectIsApplicantOnHiredStage = createSelector(
  selectApplicantHiredStageByApplicantId,
  applicantSelectors.selectApplicantCurrentPipelineStageId,
  (hiredPipelineStage, applicantCurrentPipelineStageId) => {
    return hiredPipelineStage?.pipelineStageId === applicantCurrentPipelineStageId;
  },
);

const selectByJobIdSortedByOrder = createSelector(
  hiringPipelineSelectors.selectPipelineIdByJobId,
  (state: RootState) => state,
  (pipelineId, state) => selectByPipelineIdSortedByOrder(state, pipelineId),
);

/**
 * Here we select the second pipeline stage, because of first pipeline stage must always be NEW.
 */
const selectFirstPipelineStageAfterNew = createSelector(
  selectByJobIdSortedByOrder,
  (pipelineStages) => pipelineStages[1],
);

const selectDeclinedPipelineStage = createSelector(selectJobPipelineStages, (pipelineStages) =>
  pipelineStages.find((stage) => stage.stageType === PipelineStageType.Declined),
);

const selectHiredPipelineStageByJobId = createSelector(selectJobPipelineStages, (pipelineStages) =>
  pipelineStages.find((stage) => stage.stageType === PipelineStageType.Hired),
);

const selectNextButtonName = createSelector(selectFirstPipelineStageAfterNew, (pipelineStage) => pipelineStage?.name);

const selectHiringPipelineWithStages = createSelector(
  hiringPipelineSelectors.selectById,
  hiringPipelinesStagesAdapterSelectors.selectAll,
  (hiringPipeline, stages) => {
    if (!hiringPipeline) {
      return;
    }
    return {
      ...hiringPipeline,
      stages: [stages.filter((item) => item.pipelineId === hiringPipeline.pipelineId)],
    };
  },
);

/**
 * Group pipeline stages into three groups
 * first - New
 * custom - Custom (Shortlisted, Interviewed, Screened are kept for the backward compatibility), Negotiated
 * last - ReadyToOnboard, InOnboarding, Hired, Declined, Withdrawn
 */

const selectPipelineStageIdsByGroups = createSelector(selectByPipelineIdSortedByOrder, (hiringPipelines) => {
  return hiringPipelines.reduce((acc, pipelineStage) => {
    switch (true) {
      case [PipelineStageType.New].includes(pipelineStage.stageType):
        if (!acc['first']) {
          acc['first'] = [];
        }
        acc['first'].push(pipelineStage.pipelineStageId);
        break;

      case isCustomStage(pipelineStage):
        if (!acc['custom']) {
          acc['custom'] = [];
        }
        acc['custom'].push(pipelineStage.pipelineStageId);
        break;

      case isSystemStage(pipelineStage):
        if (!acc['last']) {
          acc['last'] = [];
        }
        acc['last'].push(pipelineStage.pipelineStageId);
        break;
      default:
        break;
    }

    return acc;
  }, {} as Record<'first' | 'custom' | 'last', string[]>);
});

const selectPipelineStagesOrderedByGroups = createSelector(
  selectPipelineStageIdsByGroups,
  hiringPipelinesStagesAdapterSelectors.selectEntities,
  (groupedIds, allStages) => {
    const orderedIds = [...groupedIds.first, ...groupedIds.custom, ...groupedIds.last];

    return orderedIds.reduce((acc: HiringPipelineStage[], pipelineStageId) => {
      const stage: HiringPipelineStage | undefined = allStages[pipelineStageId];

      if (stage) {
        acc.push(stage);
      }

      return acc;
    }, [] as HiringPipelineStage[]);
  },
);

const selectBadgeLabelByPipelineStageId = createSelector(
  hiringPipelinesStagesAdapterSelectors.selectById,
  (hiringPipelineStage) => hiringPipelineStage?.stageBadgeLabel ?? hiringPipelineStage?.name,
);

const selectIsNewStage = createSelector(
  hiringPipelinesStagesAdapterSelectors.selectById,
  (hiringPipelineStage) => hiringPipelineStage?.stageType === PipelineStageType.New,
);

const selectIsPipelineStageIsHiredStage = createSelector(
  hiringPipelinesStagesAdapterSelectors.selectById,
  (pipelineStage) => pipelineStage?.stageType === PipelineStageType.Hired,
);

const selectIsPipelineStageIsReadyToOnboardStage = createSelector(
  hiringPipelinesStagesAdapterSelectors.selectById,
  (pipelineStage) => pipelineStage?.stageType === PipelineStageType.ReadyToOnboard,
);

const applicantStageTypeOptions: Array<ApplicantFilterOpt<PipelineStageType | undefined>> = [
  {
    label: 'New Status',
    value: PipelineStageType.New,
  },
  {
    label: 'Declined Status',
    value: PipelineStageType.Declined,
  },
  {
    label: 'Withdrawn Status',
    value: PipelineStageType.Withdrawn,
  },
  {
    label: 'All Statuses',
    value: undefined,
  },
];

const selectApplicantListFilterOptionsByJobId = createSelector(selectJobPipelineStages, (pipelineStages) => {
  return applicantStageTypeOptions.map((option) => {
    const pipelineStage = pipelineStages.find((stage) => stage.stageType === option.value);

    if (!pipelineStage) {
      return option;
    }

    return {
      label: pipelineStage.name,
      value: option.value,
    };
  });
});

const selectHiringPipelineStageType = createSelector(
  hiringPipelinesStagesAdapterSelectors.selectById,
  (stage) => stage?.stageType,
);

const selectHiringPipelineStageInUse = createSelector(
  hiringPipelinesStagesAdapterSelectors.selectById,
  (stage) => stage?.inUse,
);

const selectHiringPipelineStageIsRequiredCustom = createSelector(
  hiringPipelinesStagesAdapterSelectors.selectById,
  (stage) => isRequiredCustomStage(stage!),
);

const selectLastCustomPipelineStage = createSelector(selectByPipelineIdSortedByOrder, (hiringPipelinesStages) => {
  return hiringPipelinesStages.find((stage, index, array) => isCustomStage(stage) && !isCustomStage(array[index + 1]));
});

export const hiringPipelinesStagesSelectors = {
  ...hiringPipelinesStagesAdapterSelectors,
  getWorkflowByJobId,
  getWorkflowApplicantCountersByJobId,
  selectApplicantHiredStage,
  selectApplicantListFilterOptionsByJobId,
  selectApplicantPipelineStages,
  selectBadgeLabelByPipelineStageId,
  selectByPipelineId,
  selectByPipelineIdSortedByOrder,
  selectDeclinedPipelineStage,
  selectFirstPipelineStageAfterNew,
  selectHiredPipelineStageByJobId,
  selectHiringPipelineStageType,
  selectHiringPipelineWithStages,
  selectIdsByPipelineId,
  selectIdsByPipelineIdSortedByOrder,
  selectIsApplicantOnHiredStage,
  selectIsNewStage,
  selectIsPipelineStageIsHiredStage,
  selectIsPipelineStageIsReadyToOnboardStage,
  selectJobPipelineHiredStage,
  selectJobPipelineStages,
  selectJobPipelineStagesForPipelineTab,
  selectNextButtonName,
  selectPipelineStageIdsByGroups,
  selectApplicantHiredStageByApplicantId,
  checkHasEnoughRequiredCustomStages,
  checkHasMoreThanOneCustomStages,
  selectHiringPipelineStageInUse,
  selectLastCustomPipelineStage,
  selectPipelineStagesOrderedByGroups,
  selectSimilarByPipelineStageId,
  selectHiringPipelineStageIsRequiredCustom,
};
