import { createSlice } from '@reduxjs/toolkit';

import {
  getInterviewTemplateEditFormInitialState,
  interviewTemplateEditFormSliceName,
} from 'containers/InterviewTemplateForms/InterviewTemplateEditForm/state/InterviewTemplateEditForm.initialState';

import { interviewTemplateQuestionsApi } from 'store/entities/interview-template-questions/interview-template-questions.api';

import * as fromInterviewTemplateEditFormReducers from './reducers';

export const interviewTemplateEditFormSlice = createSlice({
  name: interviewTemplateEditFormSliceName,
  initialState: getInterviewTemplateEditFormInitialState(),
  reducers: fromInterviewTemplateEditFormReducers,
  extraReducers: (builder) => {
    builder.addMatcher(
      interviewTemplateQuestionsApi.endpoints.interviewTemplate.matchFulfilled,
      (draft, { payload }) => {
        draft.initialData.interviewTemplate = payload;
        draft.formData.interviewTemplate = payload;
      },
    );
    builder.addMatcher(interviewTemplateQuestionsApi.endpoints.questionList.matchFulfilled, (draft, { payload }) => {
      draft.initialData.questions = payload;
      draft.formData.questions = payload;
    });
  },
});
