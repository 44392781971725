import { useWindowSize } from 'utils/hooks/useWindowSize';

const MINIMAL_COMPANY_DETAILS_CARD_HEIGHT = 170;
const MINIMUM_WINDOW_HEIGHT = 800;

export function useCompanyDetailsTabCardHeight() {
  const { height } = useWindowSize();
  const diffFromMinimumWindowHeight = height - MINIMUM_WINDOW_HEIGHT;

  if (diffFromMinimumWindowHeight <= 0) {
    return MINIMAL_COMPANY_DETAILS_CARD_HEIGHT;
  }

  return height / 4.7 + 0.2 * diffFromMinimumWindowHeight;
}
