import { css } from 'styled-components';
import map from 'lodash/fp/map';
import reduce from 'lodash/fp/reduce';
import toPairs from 'lodash/fp/toPairs';

import {
  Columns,
  MediaCallback,
  MediaCssProps,
  mediaQueriesForDeviceSize,
  NUMBER_OF_LAYOUT_COLUMNS,
  ThemedCssFunctionReturnType,
} from 'components/ui/ExLayout/ExLayoutProps';
import { DeviceSizes } from 'utils/hooks/useDeviceSize';

/**
 * This function "Bootstrap" 12 columns layout
 * It's possible to change layout step by changing NUMBER_OF_LAYOUT_COLUMNS
 * to any other value (preferred values is even)
 *
 * @param {Columns} columnWidth
 *
 * @returns {Number}
 */
function geFlexBasis(columnWidth: Columns) {
  const denominator = NUMBER_OF_LAYOUT_COLUMNS / Number(columnWidth);
  return (100 / denominator).toFixed(4);
}

/**
 * Get column with or returns specific styles for column with width 'auto'
 *
 * @param {Columns} value
 *
 * @returns {string}
 */
function getCssForValue(value: Columns) {
  let cssString;

  if (value === 'auto') {
    cssString = `
    flex-basis: auto;
    width: auto;
    flex-grow: 1;
    flex-shrink: 1
    `;
  } else {
    const flexBasis = geFlexBasis(value);
    cssString = `flex-basis:${flexBasis}%; max-width:${flexBasis}%;`;
  }

  return cssString;
}

/**
 * Helper to provide ability to pass styled css from JSX
 */
function wrapWithStyledCss() {
  const cssThemedFunction = css;
  return function (value: MediaCallback) {
    return value(cssThemedFunction);
  };
}

/**
 * Generate css string which looks like example below:
 *
 * ```
 * @example @media (min-width: ...px) and (max-width: ...px){
 *   ...cssString
 *   ...
 * }
 * ```
 *
 * @param {DeviceSizes} deviceSize
 * @param {string | ThemedCssFunctionReturnType} cssString
 *
 * @returns {string}
 */
function cssMediaWrapper(deviceSize: DeviceSizes, cssString: string | ThemedCssFunctionReturnType) {
  const mediaString = mediaQueriesForDeviceSize[deviceSize];
  if (!mediaString) {
    return `${cssString}`;
  }

  return `${mediaString}{${cssString}}`;
}

/**
 * Map of processors for different types of values.
 * Also undefined key is needed to avoid value exists check or try catch statement
 */
const valueTypeProcessors = {
  callback: wrapWithStyledCss(),
  size: getCssForValue,
  undefined: (_: any) => '',
};

/**
 * This function decide which type of value comes from JSX.
 *
 * @param {Columns | MediaCallback | undefined} value
 */
function getValueType(value?: Columns | MediaCallback) {
  if (!value) {
    return 'undefined';
  }

  if (typeof value === 'function') {
    return 'callback';
  }

  return 'size';
}

/**
 * Function for reduce array of strings to one string
 *
 * @param {string} acc
 * @param {string} cssString
 *
 * @returns {string}
 */
function appendStrings(acc: string, cssString: string): string {
  acc = acc + cssString;
  return acc;
}

/**
 * This function generate css for each of device sizes.
 *
 * @param {DeviceSizes} key
 * @param {Columns | MediaCallback | undefined} value
 */
function getMediaCssForSize([key, value]: [string, Columns | MediaCallback | undefined]): string {
  const valueType = getValueType(value);
  const valueProcessor = valueTypeProcessors[valueType];
  const cssString = valueProcessor(value);
  return cssMediaWrapper(key as DeviceSizes, cssString);
}

// General functions
const getKeyValuesFromObject = toPairs;

const mapGetMediaCssForSize = map(getMediaCssForSize);

const reduceStringsToMediaCssQuery = reduce(appendStrings, '');

function wrapMediaCssStringWithStyledCss(mediaCssString: string) {
  return css`
    ${mediaCssString}
  `;
}

/**
 * Main function of ExLayout which builds media query css.
 *
 * @param {MediaCssProps} props
 *
 * @returns {ThemedCssFunctionReturnType}
 */
export function buildMediaCss(props: MediaCssProps) {
  const sizesAndValues = getKeyValuesFromObject(props);
  const cssStrings = mapGetMediaCssForSize(sizesAndValues);
  const mediaCssStringUpdated = reduceStringsToMediaCssQuery(cssStrings);

  return wrapMediaCssStringWithStyledCss(mediaCssStringUpdated);
}
