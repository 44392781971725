import { Action, nanoid, ThunkDispatch } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';

import * as jobAdApi from 'api-endpoints/job-ad';
import { PostJobAdParams } from 'api-endpoints/job-ad/models';

import { AdditionalField } from 'model';
import { ErrorDTO, ValidationErrorCodes } from 'model/api-errors.constants';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { jobAdFormActions } from 'containers/JobAdForms/state/reducer';
import * as jobAdFormSelectors from 'containers/JobAdForms/state/selectors';
import { loaderActions } from 'containers/Loader/store';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { startLoaderThunk, stopLoaderThunk } from 'modules/LoaderManager/redux';
import { formBuilderTransformer } from 'utils/form-builder-transformer';
import { pickOrRename, removeNullAndUndefinedPropertiesFromObject } from 'utils/funcs';
import { getTranslate } from 'utils/i18utils';

import { jobAdActions } from 'store/entities/job-ads';
import { jobAdEffects } from 'store/entities/job-ads/job-ad.effects';
import { enhancedJobAdsApi, JOB_AD_TAG_TYPE } from 'store/entities/job-ads/job-ads.api';
import { JobAd } from 'store/entities/job-ads/models';
import { additionalFieldsTransformer } from 'store/entities/job-ads/utils';
import { JobBoard, jobBoardSelectors } from 'store/entities/job-boards';
import { exModalShowAction } from 'store/modals/modals.actions';
import { RootState } from 'store/rootReducer';
import { AppDispatch, AppEffectParams, AppThunk } from 'store/types';

export const jobAdSaveProcessing = 'jobAdSaveProcessing';

type PrepareJobAdPostDataProps = {
  formData: Partial<JobAd>;
  jobBoardId: string;
  dispatch: ThunkDispatch<RootState, unknown, Action>;
  getState: () => RootState;
};
function prepareJobAdPostData({ formData, jobBoardId }: PrepareJobAdPostDataProps) {
  return {
    additionalFields:
      formData.jobBoards?.[jobBoardId] &&
      (Object.entries(formData.jobBoards[jobBoardId]).map(additionalFieldsTransformer) as AdditionalField[]),
    description: formData.description,
    employmentType: formData.employmentType,
    jobAdLocation: formData.jobAdLocation,
    jobAdCountry: formData.jobAdCountry,
    jobBoardId,
    jobId: formData.jobId,
    requirements: formData.requirements,
    responsibilities: formData.responsibilities,
    sector: formData.sector,
    startDate: formData.startDate ? parseISO(formData.startDate).toISOString() : undefined,
    name: formData.jobAdName,
  };
}

type UpdateJobAdPartiallyProps = {
  jobAdId: string;
  jobBoardId: string;
  postData: PostJobAdParams;
  dispatch: ThunkDispatch<RootState, unknown, Action>;
  getState: () => RootState;
};
async function updateJobAdPartially({ jobAdId, jobBoardId, postData, dispatch, getState }: UpdateJobAdPartiallyProps) {
  const prevAdditionFieldsView = jobAdFormSelectors.selectAdditionFieldsView(getState());
  const { data, message, response } = await jobAdApi.putJobAd(jobAdId, postData);
  if (!message) {
    dispatch(jobAdActions.updateOne(data));
  }

  return {
    jobAdId: prevAdditionFieldsView[jobBoardId].jobAdId,
    jobBoardId,
    message,
    response,
  };
}

type CreateJobAdProps = {
  jobBoardId: string;
  postData: PostJobAdParams;
  dispatch: ThunkDispatch<RootState, unknown, Action>;
  getState: () => RootState;
};
async function createJobAd({ jobBoardId, postData, dispatch, getState }: CreateJobAdProps) {
  const { data, message, response } = await jobAdApi.createJobAd(postData);
  if (!message && data?.jobAdId) {
    const prevAdditionFieldsView = jobAdFormSelectors.selectAdditionFieldsView(getState());
    dispatch(
      jobAdFormActions.updateState({
        additionFieldsView: {
          ...prevAdditionFieldsView,
          [jobBoardId]: {
            ...prevAdditionFieldsView[jobBoardId],
            jobAdId: data.jobAdId,
          },
        },
      }),
    );
  }
  return {
    jobAdId: data?.jobAdId,
    jobBoardId,
    message,
    response,
  };
}

type GetJobAdIdProps = {
  jobBoardId: string;
  getState: () => RootState;
};
function getJobAdId({ jobBoardId, getState }: GetJobAdIdProps) {
  const prevAdditionFieldsView = jobAdFormSelectors.selectAdditionFieldsView(getState());

  return prevAdditionFieldsView[jobBoardId]?.jobAdId;
}

type JobBoardIdCreationResult = {
  jobBoardId: string;
  jobAdId?: string;
  message?: string;
  response?: AxiosResponse<ErrorDTO>;
};

export function showSingleErrorForJobAd({
  response,
  message = '',
  showModal,
  dispatch,
}: {
  response: AxiosResponse<ErrorDTO> | undefined;
  dispatch: ThunkDispatch<RootState, unknown, Action>;
  showModal?: boolean;
  message?: string;
}) {
  if (response?.data.validationErrorCodes) {
    dispatch(
      jobAdFormActions.setApiErrors({
        apiErrors: response?.data?.validationErrorCodes,
      }),
    );
    if (showModal) {
      dispatch(
        exModalShowAction({
          id: nanoid(),
          modalType: ModalsTypeKey.jobAdPostModal,
          modalConfig: {
            content: {
              title: 'Errors',
              withTitle: true,
              withActions: true,
              buttonOk: null,
              buttonCancel: 'Close',
            },
          },
        }),
      );
    } else {
      response?.data.validationErrorCodes.forEach(showErrorMessage(dispatch));
    }
  } else {
    dispatch(
      alertsEffects.showError({
        message,
      }),
    );
  }
}

type ShowErrorsProps = {
  results: JobBoardIdCreationResult[];
  dispatch: ThunkDispatch<RootState, unknown, Action>;
  getState: () => RootState;
};
function showErrors({ results, dispatch }: ShowErrorsProps) {
  const responses = results.map((r) => r.response).filter((e) => !!e);
  responses.forEach((response) => {
    showSingleErrorForJobAd({ dispatch, response });
  });
}

type UpdateJobAdStatusProps = {
  results: JobBoardIdCreationResult[];
  dispatch: ThunkDispatch<RootState, unknown, Action>;
  getState: () => RootState;
};

async function updateJobStatus({ results, dispatch }: UpdateJobAdStatusProps) {
  const ids = results
    .map((r) => ({
      jobAdId: r?.jobAdId,
      jobBoardId: r?.jobBoardId,
    }))
    .filter((e) => !!e.jobAdId);

  const postResults = await Promise.all(
    ids
      .filter((id) => !!id.jobAdId)
      .map(async ({ jobAdId, jobBoardId }) => {
        return {
          jobAdId,
          jobBoardId,
          success: await dispatch(
            jobAdEffects.updateJobAdStatus({
              action: 'post',
              jobAdId: jobAdId!,
              preloader: true,
            }),
          ),
        };
      }),
  );

  postResults.forEach(({ jobAdId, jobBoardId, success }) => {
    if (jobAdId && jobBoardId) {
      dispatch(jobAdFormActions.updateAdditionFieldsViewByJobBoardId({ jobAdId, jobBoardId, postSuccess: success }));
    }
  });
}

export const saveJobAd =
  ({ preloader, data, post }: AppEffectParams<{ data?: Partial<JobAd>; post?: boolean }>): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    startLoaderThunk(dispatch, saveJobAd);
    const formData = data || jobAdFormSelectors.selectFormData(getState());
    if (preloader) {
      dispatch(loaderActions.start(jobAdSaveProcessing));
    }
    const results = await Promise.all(
      formData.jobBoardIds!.map(async (jobBoardId) => {
        const jobAdId = getJobAdId({ getState, jobBoardId });
        const postData: PostJobAdParams = prepareJobAdPostData({ dispatch, formData, getState, jobBoardId });

        if (jobAdId) {
          return updateJobAdPartially({ dispatch, getState, jobAdId, jobBoardId, postData });
        }

        return createJobAd({ dispatch, getState, jobBoardId, postData });
      }),
    );

    dispatch(enhancedJobAdsApi.util.invalidateTags([{ type: JOB_AD_TAG_TYPE, id: 'LIST' }]));

    const messages = results.map((r) => r.message).filter((e) => !!e);
    const ids = results
      .map((r) => ({
        jobAdId: r?.jobAdId,
        jobBoardId: r?.jobBoardId,
      }))
      .filter((e) => !!e.jobAdId);

    if (messages.length) {
      showErrors({ dispatch, getState, results });
    } else {
      dispatch(jobAdFormActions.saveForm({ formData: { ...formData, jobAdId: ids[0].jobAdId } }));
    }

    if (preloader) {
      dispatch(loaderActions.stop(jobAdSaveProcessing));
    }
    if (post && !messages.length) {
      await updateJobStatus({ dispatch, getState, results });
      stopLoaderThunk(dispatch, saveJobAd);
      return !messages.length;
    }

    stopLoaderThunk(dispatch, saveJobAd);
    return !messages.length;
  };
saveJobAd.processing = jobAdSaveProcessing;

export const updateJobAd =
  ({
    preloader,
    jobAdId,
    data,
  }: AppEffectParams<{ data?: Partial<JobAd>; jobAdId: JobAd['id'] }>): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    const formData = jobAdFormSelectors.selectFormData(getState());

    /**
     * Start Loader Manager loader
     */
    startLoaderThunk(dispatch, updateJobAd);

    if (preloader) {
      dispatch(loaderActions.start(jobAdSaveProcessing));
    }

    const preparedFormData = pickOrRename({ ...formData, ...data }, [
      'additionalFields',
      'description',
      'employmentType',
      'jobAdId',
      'jobAdLocation',
      'jobAdCountry',
      'requirements',
      'responsibilities',
      'sector',
      'startDate',
      ['jobAdName', 'name'],
    ]);

    removeNullAndUndefinedPropertiesFromObject(preparedFormData);

    const { message, response } = await jobAdApi.putJobAd(jobAdId, preparedFormData);

    if (message) {
      showSingleErrorForJobAd({ dispatch, message, response });
    } else {
      dispatch(jobAdEffects.loadJobAd({ jobAdId }));
      const successMessage = getTranslate(`jobAdMessages.AdSettingsUpdateSuccess`);
      dispatch(
        alertsEffects.showSuccess({
          message: successMessage,
        }),
      );
    }
    if (preloader) {
      dispatch(loaderActions.stop(jobAdSaveProcessing));
    }

    stopLoaderThunk(dispatch, updateJobAd);
    return !message;
  };

export const additionalFieldsView =
  ({
    data,
    jobBoardId,
  }: AppEffectParams<{
    jobBoardId: JobBoard['jobBoardId'];
    data: Record<string, { value: string; children: string }>;
  }>): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const jobBoard = jobBoardSelectors.selectById(getState(), jobBoardId);
    const additionalFields = prepareAdditionalFieldsForServer(data);

    if (jobBoard?.additionalFields && additionalFields) {
      const { resultForView: additionFieldView } = formBuilderTransformer(jobBoard.additionalFields, additionalFields);
      const prevAdditionFieldsView = jobAdFormSelectors.selectAdditionFieldsView(getState());
      dispatch(
        jobAdFormActions.updateState({
          additionFieldsView: {
            ...prevAdditionFieldsView,
            [jobBoardId]: {
              ...prevAdditionFieldsView[jobBoardId],
              additionFieldView,
              jobBoardId,
              jobBoardName: jobBoard.name,
            },
          },
        }),
      );
    }
  };

export const prepareAdditionalFieldsForServer = (data: Record<string, { value: string; children: string }>) =>
  Object.entries(data).map(([key, v]) => ({
    key,
    value: v.children ? v.children : v.value,
    parent: v.children ? v.value : '',
  })) as Required<JobAd>['additionalFields'];

function showErrorMessage(dispatch: AppDispatch) {
  return (validationErrorCode: ValidationErrorCodes) => {
    const errorMessageOnUserLanguage = getTranslate(`jobAdValidationErrorCodes.${validationErrorCode}`);
    dispatch(
      alertsEffects.showError({
        message: errorMessageOnUserLanguage || 'Error',
      }),
    );
  };
}
