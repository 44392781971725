import React from 'react';

import { FormSelectMultiCheckbox, FormSelectMultiCheckboxProps } from 'components/FormSelectMultiCheckbox';
import { useEmploymentTypeOptions } from 'utils/hooks/formsHooks/useEmploymentTypeOptions';

import { JobsListUiSliceState } from 'store/ui/jobs/jobs-list/jobs-list.reducer';

type JobListEmploymentTypeProps = {
  className?: string;
  name: string;
  value: Array<{ value: string; label: string }>;
  onChange?: (filter: Partial<JobsListUiSliceState['filters']>) => void;
};

const useJobListEmploymentTypeState = ({ className, name, value, onChange }: JobListEmploymentTypeProps) => {
  const employmentType = useEmploymentTypeOptions();

  const onChangeInner: FormSelectMultiCheckboxProps<{ value: string; label: string }>['onChange'] = (newValue) => {
    onChange && onChange({ [name]: newValue || [] });
  };

  return {
    className,
    employmentType,
    name,
    value,
    onChangeInner,
  } as const;
};

export const JobListEmploymentType: React.FC<JobListEmploymentTypeProps> = (props) => {
  const { className, employmentType, value, onChangeInner } = useJobListEmploymentTypeState(props);

  return (
    <FormSelectMultiCheckbox
      value={value}
      className={className}
      options={employmentType}
      placeholder="Employment Type"
      onChange={onChangeInner}
    />
  );
};
