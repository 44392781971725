import React from 'react';
import styled from 'styled-components/macro';

import { ApplicantModalSwitchMode } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalSwitchMode';
import { ApplicantModalEducationEdit } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalEducation/ApplicantModalEducationEdit/ApplicantModalEducationEdit';
import { ApplicantModalEducationView } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalEducation/ApplicantModalEducationView/ApplicantModalEducationView';

type ApplicantModalEducationProps = {
  className?: string;
};

const ApplicantModalEducationStyled = styled(ApplicantModalSwitchMode)`
  position: relative;
`;

const useApplicantModalEducationState = ({ className }: ApplicantModalEducationProps) => {
  return {
    className,
  } as const;
};

export const ApplicantModalEducation: React.FC<ApplicantModalEducationProps> = (props) => {
  const { className } = useApplicantModalEducationState(props);

  return (
    <ApplicantModalEducationStyled
      className={className}
      viewComponent={<ApplicantModalEducationView />}
      editComponent={<ApplicantModalEducationEdit />}
    />
  );
};
