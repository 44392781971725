import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { JobValidationErrorCodes } from 'model/api-errors.constants';
import { KeyCodes } from 'model/keycodes';

import { useSinglePipeline } from 'pages/Jobs/Job/hooks/useSinglePipeline';

import { InterviewTemplates } from 'containers/JobForms/FormBody/JobDetailFormBody/JobDetailFormBodyComponents';
import { jobFormEffects, jobFormSelectors } from 'containers/JobForms/state';
import { loaderSelectors } from 'containers/Loader/store';
import { modalsSelectors } from 'containers/Modals/store';

import { FormCard } from 'components/FormCard';
import { FormDate } from 'components/FormDate';
import { FormInput } from 'components/FormInput';
import { FormSelect } from 'components/FormSelect';
import { FormToggleButtons } from 'components/FormToggleButtons';
import { LocationServiceForm } from 'components/LocationServiceForm';
import { PositionServiceForm } from 'components/PositionServiceForm';
import { Spinner } from 'components/Spinner';
import { StepFormBody } from 'components/StepForm';
import { ExVisible } from 'components/ui/ExVisible';
import { getEmploymentType } from 'utils/getEmploymentType';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { usePrevious } from 'utils/hooks/usePrevious';
import { useRestrictedKeyCodes } from 'utils/hooks/useRestrictedKeyCodes';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getTranslate } from 'utils/i18utils';

import { companySelectors } from 'store/company/company.selectors';
import {
  useCompanyGetPositionDetailsQuery,
  useCompanyPositionRequirementsQuery,
  useCompanyPositionResponsibilitiesQuery,
} from 'store/dictionary/dictionary.api';
import { useSearchPipelineQuery } from 'store/entities/hiring-pipelines/hiring-pipelines.api';
import {
  hiringPipelineActions,
  hiringPipelineSelectors,
} from 'store/entities/hiring-pipelines/hiring-pipelines.reducer';
import type { HiringPipeline } from 'store/entities/hiring-pipelines/hiring-pipelines.types';
import { Job } from 'store/entities/jobs/models';
import { jobsSelectors } from 'store/entities/jobs/selectors';

const CareerPageBlock = styled.div`
  margin-bottom: 1.5rem;
`;

const FormToggleButtonsCareerPage = styled(FormToggleButtons)`
  margin-bottom: 0;
`;

const CareerPageHint = styled(FormCard.InputLabelHint)`
  display: block;
  margin-top: 0.5rem;
`;

interface JobDetailFormBodyProps extends StepFormBody {
  className?: string;
  formData?: Partial<Job>;
}

const positionField = {
  positionId: 'position.positionId',
  categoryId: 'position.categoryId',
};

const usePositionOptionsForm = () => {
  const { setValue, watch, register } = useFormContext<Job>();

  const positionId = watch(positionField.positionId, '');

  const storedFormDataPositionId = useAppSelector(jobFormSelectors.selectFormDataPositionId);

  useEffect(() => {
    register('requirements');
    register('responsibilities');
  }, [register]);

  const { data: positionRequirementsData } = useCompanyPositionRequirementsQuery(
    { positionId },
    {
      refetchOnMountOrArgChange: false,
      skip: !positionId,
    },
  );

  const { data: positionResponsibilitiesData } = useCompanyPositionResponsibilitiesQuery(
    { positionId },
    {
      refetchOnMountOrArgChange: false,
      skip: !positionId,
    },
  );

  useEffect(() => {
    if (positionId === storedFormDataPositionId) {
      return;
    }

    if (positionRequirementsData?.length) {
      setValue(
        'requirements',
        positionRequirementsData.map((requirement) => ({
          ...requirement,
          text: requirement?.description ?? requirement.text,
          isEnabled: true,
        })),
      );
    }
  }, [positionRequirementsData, setValue, positionId, storedFormDataPositionId]);

  useEffect(() => {
    if (positionId === storedFormDataPositionId) {
      return;
    }

    if (positionResponsibilitiesData?.length) {
      setValue(
        'responsibilities',
        positionResponsibilitiesData.map((responsibility) => ({
          ...responsibility,
          text: responsibility?.description ?? responsibility.text,
          isEnabled: true,
        })),
      );
    }
  }, [positionResponsibilitiesData, setValue, positionId, storedFormDataPositionId]);
};

const useEmploymentTypeOptionsByPosition = () => {
  const { watch, setValue } = useFormContext<Job>();
  const positionId = watch(positionField.positionId, '');

  const { data } = useCompanyGetPositionDetailsQuery(
    { positionId },
    {
      refetchOnMountOrArgChange: false,
      skip: !positionId,
    },
  );

  const positionIdFromBE = data?.positionId;
  const prevPositionIdFromBE = usePrevious(positionIdFromBE) || positionIdFromBE;

  const descriptionByPosition = data?.description;

  useEffect(() => {
    if (positionIdFromBE !== prevPositionIdFromBE) {
      setValue('employmentType', '');

      if (descriptionByPosition) {
        setValue('description', descriptionByPosition);
      }
    }
  }, [descriptionByPosition, positionIdFromBE, prevPositionIdFromBE, setValue]);

  const employmentType = useMemo(() => {
    if (data?.employmentTypes) {
      return data?.employmentTypes.map(({ type }) => ({
        label: getEmploymentType(type),
        value: type,
      }));
    }

    return [];
  }, [data?.employmentTypes]);

  const noOptionsMessage = () => {
    if (!positionId) {
      return 'First select Position';
    }

    return 'No Options';
  };

  return {
    employmentType,
    noOptionsMessage,
  };
};

const useCompanyHiringPipelineOptions = ({ withoutPipelines, jobId }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (withoutPipelines) {
      return;
    }

    dispatch(hiringPipelineActions.setFilters({ isDisabled: false }));

    return () => {
      dispatch(hiringPipelineActions.setFilters({}));
    };
  }, [dispatch, withoutPipelines]);

  const pipelineFilters = useAppSelector(hiringPipelineSelectors.selectFilters);

  const skipQuery = withoutPipelines || pipelineFilters.isDisabled !== false;

  const { isFetching: isLoadingPipelines } = useSearchPipelineQuery(pipelineFilters, {
    refetchOnMountOrArgChange: false,
    skip: skipQuery,
  });

  const { isFetching: isLoadingSinglePipelines } = useSinglePipeline({ jobId });

  const companyHiringPipeline = useAppSelector(hiringPipelineSelectors.selectValidHiringPipelinesForJob);

  return {
    companyHiringPipeline,
    isLoadingPipelines: isLoadingPipelines || isLoadingSinglePipelines,
  };
};

const JobDetailFormBodyPure: React.FC<JobDetailFormBodyProps> = ({ disableField = [], isStep }) => {
  const { setValue, watch, register, errors, formState, setError } = useFormContext<Job>();

  usePositionOptionsForm();

  const validated = formState.isSubmitted;

  const selectApiErrors = useAppSelector(jobFormSelectors.selectApiErrors);

  const formData = useAppSelector(jobFormSelectors.selectFormData);

  useEffect(() => {
    if (formData.id) {
      setValue('id', formData.id);
    }
  }, [formData.id, setValue]);

  const loading = useAppSelector((state) =>
    loaderSelectors.isTaskActive(state, { taskId: jobFormEffects.loadSelectorsData }),
  );

  const { handlerKeyDown: handlerKeyDownVacancies } = useRestrictedKeyCodes({
    restrictedKeyCodes: [
      KeyCodes.KEY_E,
      KeyCodes.DASH,
      KeyCodes.EQUALS,
      KeyCodes.ADD,
      KeyCodes.SUBTRACT,
      KeyCodes.DECIMAL,
      KeyCodes.PERIOD,
    ],
  });

  useEffect(() => {
    if (!selectApiErrors?.length) {
      return;
    }

    const refNumberField: string[] = [
      JobValidationErrorCodes.ReferenceNumberEmpty,
      JobValidationErrorCodes.ReferenceNumberNotUnique,
      JobValidationErrorCodes.ReferenceNumberLess10Symbols,
    ];

    const jobTypeFiled: string[] = [JobValidationErrorCodes.JobTypeCannotBeExternal];

    if (selectApiErrors.find((e) => refNumberField.includes(e))) {
      setError('referenceNo', {
        type: 'notMatch',
        message: 'Please choose a different Reference Number',
      });
    }

    if (selectApiErrors.find((e) => jobTypeFiled.includes(e))) {
      setError('jobType', {
        type: 'manual',
        message: getTranslate('job.details.errors.deadlineInPast'),
      });
    }
  }, [selectApiErrors]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClear = () => {
    setValue('deadline', null);
  };

  const { employmentType, noOptionsMessage: noOptionsEmploymentTypeMessage } = useEmploymentTypeOptionsByPosition();

  const defaultDatePlaceholder = useAppSelector(companySelectors.getDefaultDateFormat)?.toUpperCase();

  const jobId = useAppSelector(modalsSelectors.modalJobId);

  const isJobTypeEditDisabled = useAppSelector(jobsSelectors.selectIsJobTypeEditDisabled, jobId);

  const isPipelineSelectDisabled = disableField.includes('pipelineId');

  const { companyHiringPipeline, isLoadingPipelines } = useCompanyHiringPipelineOptions({
    withoutPipelines: isPipelineSelectDisabled,
    jobId,
  });

  if (loading) {
    return (
      <div className="my-3">
        <Spinner />
      </div>
    );
  }

  const deadline = watch('deadline');

  const careerPageHint =
    jobId && isJobTypeEditDisabled
      ? 'This job cannot be removed from the Career Page while there is an active job ad.'
      : null;

  return (
    <>
      <div className="row">
        <input type="string" hidden name="id" ref={register} />
        <FormInput
          className="col-md-6"
          label="Job Name"
          name="jobName"
          required
          errors={errors.jobName}
          validated={validated}
          inputRef={register}
        />
        <FormInput
          className="col-md-6"
          label="Reference Number"
          name="referenceNo"
          required
          errors={errors.referenceNo}
          validated={validated}
          inputRef={register}
          hint="(This must be unique)"
        />
        <PositionServiceForm
          categoryIdFieldName={positionField.categoryId}
          categoryNameFieldName="position.category"
          positionIdFieldName={positionField.positionId}
          positionNameFieldName="position.name"
          categoryClassNames="col-md-6"
          positionClassNames="col-md-6"
          categoryFieldRequired={!disableField.includes(positionField.categoryId)}
          positionFieldRequired={!disableField.includes(positionField.positionId)}
          categoryFieldDisabled={disableField.includes(positionField.categoryId)}
          positionFieldDisabled={disableField.includes(positionField.positionId)}
        />
        <LocationServiceForm
          countryClassNames="col-md-6"
          locationClassNames="col-md-6"
          countryFieldName="countryHiringFrom"
          locationFieldName="jobLocation"
          countryFieldRequired
          locationFieldRequired
          countriesSource="companyCountry"
        />
        <FormInput
          type="number"
          className="col-md-6"
          label="Number of Vacancies"
          name="vacancies"
          errors={errors?.vacancies}
          validated={validated}
          inputRef={register}
          defaultValue="1"
          autoComplete="off"
          inputMode="numeric"
          onKeyDown={handlerKeyDownVacancies}
        />
        <FormDate
          className="col-md-6"
          label="End Date"
          name="deadline"
          min={new Date()}
          errors={errors?.deadline}
          validated={validated}
          inputRef={register}
          clearable={Boolean(deadline)}
          onClear={onClear}
          hint="(Leave empty to create ongoing job)"
          placeholder={defaultDatePlaceholder}
        />
        <FormSelect
          className="col-md-6"
          label="Employment type"
          name="employmentType"
          placeholder=""
          required
          options={employmentType}
          errors={errors?.employmentType}
          validated={validated}
          inputRef={register}
          defaultOptions={employmentType}
          getOptionLabel={(option: any) => option.label}
          getOptionValue={(option: any) => option.value}
          isSearchable={false}
          noOptionsMessage={noOptionsEmploymentTypeMessage}
        />
        <FormSelect
          className="col-md-6"
          label="Hiring Pipeline"
          name="pipelineId"
          placeholder=""
          required
          isDisabled={isPipelineSelectDisabled}
          isLoading={!isPipelineSelectDisabled && isLoadingPipelines}
          options={companyHiringPipeline}
          errors={errors?.pipelineId}
          validated={validated}
          inputRef={register}
          defaultOptions={companyHiringPipeline}
          getOptionLabel={(option: HiringPipeline) => option.name}
          getOptionValue={(option: HiringPipeline) => option.pipelineId}
          isSearchable={false}
          openMenuOnFocus
        />
        <CareerPageBlock className="col-md-6">
          <FormToggleButtonsCareerPage
            label="List on Career Page"
            name="listOnCareerPage"
            required
            disabled={disableField.includes('listOnCareerPage')}
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
            errors={errors?.listOnCareerPage}
            defaultValue={false}
          />
          {careerPageHint && <CareerPageHint>{careerPageHint}</CareerPageHint>}
        </CareerPageBlock>
        <ExVisible visible={!!isStep}>
          <InterviewTemplates />
        </ExVisible>
      </div>
    </>
  );
};

export const JobDetailFormBody = styled(JobDetailFormBodyPure)``;
