import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as GridView } from 'assets/img/grid-view.svg';
import { ReactComponent as TableView } from 'assets/img/table-view.svg';

import { ViewMode } from 'store/constants';

import { ListViewToggleProps } from './ListViewProps';

const ListViewToggle: React.FC<ListViewToggleProps> = ({ viewMode, changeViewMode, className }) => {
  const clickHandler = (clickedViewMode: ViewMode) => (e: React.MouseEvent) => {
    e.preventDefault();
    changeViewMode(clickedViewMode);
  };

  const isGridViewMode = viewMode === ViewMode.grid;

  return (
    <ListViewToggleStyled className={className}>
      <p>View: </p>
      <ViewModeStyled onClick={clickHandler(ViewMode.grid)} active={isGridViewMode}>
        <GridView />
      </ViewModeStyled>
      <ViewModeStyled onClick={clickHandler(ViewMode.table)} active={!isGridViewMode}>
        <TableView />
      </ViewModeStyled>
    </ListViewToggleStyled>
  );
};

export default styled(ListViewToggle)`
  p {
    margin-left: 8px;
    user-select: none;
    margin-bottom: 0;
  }
`;

const ListViewToggleStyled = styled.div`
  display: flex;
  flex-direction: row;
`;

const activeCss = css`
  svg path {
    fill: ${(props) => props.theme.colors.primary};
  }

  &:hover {
    svg path {
      fill: ${(props) => props.theme.colors.primary};
      stroke: ${(props) => props.theme.colors.primaryLight};
    }
  }
`;

const hoverCss = css`
  svg path {
    fill: transparent;
  }

  &:hover {
    svg path {
      fill: ${(props) => props.theme.colors.primaryLight};
    }
  }
`;

const ViewModeStyled = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-left: 8px;

  path {
    transition: all 0.3s ease-in-out;
  }

  ${(props) => (props.active ? activeCss : hoverCss)};
`;
