import React, { useCallback, useMemo, useRef } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { Option } from 'react-select/src/filters';
import { useAppRouterParams } from 'router/useAppRouterParams';
import styled from 'styled-components';

import { AvailabilityType } from 'model/api-enums.constants';

import { applicantListActions, applicantListSelectors } from 'containers/ApplicantLists/store';

import { Form, FormDate, FormSelect } from 'components';
import { FilterCheckbox, FilterTagSelect } from 'components/Filter';
import { FormCard } from 'components/FormCard';
import { FormMultiCheckbox } from 'components/FormMultiCheckbox';
import { KeywordSelect, KeywordSelectOption } from 'components/KeywordSelect/KeywordSelect';
import { ExFiltersSideModal } from 'components/ui/ExFiltersSideModal';
import { useAvailabilityTypeOption } from 'utils/hooks/formsHooks/useAvailabilityTypeOption';
import { useApplicantFiltersPipelineStagesForJob } from 'utils/hooks/job';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { ApplicantBelongsTo, ApplicantFiltersNames, Source } from 'store/entities/applicants';
import { jobsSelectors } from 'store/entities/jobs';

import { applicantListResetFiltersAction } from './store/applicant-lists.actions';

export type JobApplicantsFiltersProps = {
  listId: string;
  isOpen: boolean;
  closeModal: () => void;
  onSubmit?: () => void;
};

export const JobApplicantsFilters: React.FC<JobApplicantsFiltersProps> = ({ listId, isOpen, closeModal, onSubmit }) => {
  const formRef = useRef<any>();
  const filters = useAppSelector(applicantListSelectors.getFilters, { id: listId });

  const dispatch = useAppDispatch();

  const defaultValues = { ...filters };
  const onSubmitHandler = (data: Partial<typeof filters>) => {
    dispatch(
      applicantListActions.updateFilters({
        id: listId,
        filters: {
          ...data,
        },
      }),
    );
    onSubmit && onSubmit();
  };

  return (
    <ExFiltersSideModal
      isOpen={isOpen}
      closeModal={closeModal}
      onApplyFilters={() => {
        formRef.current();
      }}
      onClearFilters={() => {
        dispatch(applicantListResetFiltersAction({ listId }));
        onSubmit && onSubmit();
      }}
      title="Applicant Filter"
    >
      <Form
        defaultValues={defaultValues}
        onSubmit={onSubmitHandler}
        onSaveAsDraft={onSubmitHandler}
        saveAsDraftHandlerRef={formRef}
      >
        <FilterBody name="FilterBody" />
      </Form>
    </ExFiltersSideModal>
  );
};

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const sourceOptions = [
  { label: 'Other', value: Source.Other },
  { label: 'JobBoard', value: Source.JobBoard },
  { label: 'TalentPool', value: Source.TalentPool },
  { label: 'Website', value: Source.Website },
  { label: 'SocialMedia', value: Source.SocialMedia },
];

const FilterBody: React.FC<{ name?: string }> = () => {
  const { jobId } = useAppRouterParams();
  const previousEmployersOptions = useAppSelector(jobsSelectors.selectTopEmployersOptions, jobId);
  const educationsOptions = useAppSelector(jobsSelectors.selectTopEducationsOptions, jobId);

  const availabilitiesOptions = useAvailabilityTypeOption();
  const statusOptions = useApplicantFiltersPipelineStagesForJob();

  const handleKeywordChange = useCallback(
    (onChange: (...event: any[]) => void) => (changedKeywords: readonly KeywordSelectOption[]) => {
      const flattenKeywords = changedKeywords.map(({ value: keywordValue }) => keywordValue);
      onChange(flattenKeywords);
    },
    [],
  );

  const filters = useAppSelector(applicantListSelectors.getFilter, { id: ApplicantBelongsTo.job });

  const countries = useMemo(() => {
    if (!filters) {
      return [];
    }

    return filters.find((filter) => filter.name === 'countries')?.options || [];
  }, [filters]);

  const locations = useMemo(() => {
    if (!filters) {
      return [];
    }

    return filters.find((filter) => filter.name === 'locations')?.options || [];
  }, [filters]);

  return (
    <>
      <FormGroup>
        <FormCard.InputLabel>Keywords</FormCard.InputLabel>
        <Controller
          name={ApplicantFiltersNames.keywords}
          render={({ value, onChange }) => (
            <FilterTagSelect>
              <KeywordSelect
                onChange={handleKeywordChange(onChange)}
                value={value?.map((keyword: string) => ({ value: keyword, label: keyword }))}
              />
            </FilterTagSelect>
          )}
        />
      </FormGroup>

      <FormMultiCheckbox name="countries" options={countries} label="Country" />
      <FormMultiCheckbox name="locations" options={locations} label="Location" />

      <FormGroup>
        <FormCard.InputLabel>Availability</FormCard.InputLabel>
        {availabilitiesOptions.map((availability, index) => (
          <React.Fragment key={index}>
            <Controller
              key={index}
              name={`availabilities[${index}].availabilityType`}
              render={({ value, onChange }) => (
                <>
                  <FilterCheckbox label={availability.label} key={availability.value}>
                    <input
                      type="checkbox"
                      value={availability.value}
                      defaultChecked={value === availability.value}
                      onChange={(e) => onChange(e.target.checked ? availability.value : undefined)}
                    />
                  </FilterCheckbox>
                  {availability.value === AvailabilityType.ActualDate && value === AvailabilityType.ActualDate ? (
                    <FormDate name={`availabilities[${index}].availabilityActualDate`} noOverlay={true} />
                  ) : null}
                </>
              )}
            />
          </React.Fragment>
        ))}
      </FormGroup>
      <FormGroup>
        <FormCard.InputLabel>Source(s)</FormCard.InputLabel>
        <Controller
          name={ApplicantFiltersNames.sources}
          render={({ value, onChange }) => (
            <FilterTagSelect>
              <Select
                isMulti
                options={sourceOptions}
                defaultValue={sourceOptions?.filter((sourceOption) =>
                  (value as number[])?.includes(sourceOption.value),
                )}
                onChange={(selected) => {
                  onChange((selected as typeof sourceOptions)?.map(({ value: selectedValue }) => selectedValue));
                }}
              />
            </FilterTagSelect>
          )}
        />
      </FormGroup>
      <FormGroup>
        <FormCard.InputLabel htmlFor="pipelineStages">Status</FormCard.InputLabel>
        <Controller
          name={ApplicantFiltersNames.pipelineStages}
          render={({ value, onChange }) => (
            <>
              {statusOptions.map((status) => (
                <FilterCheckbox label={status.label} key={status.value || status.label}>
                  <input
                    type="radio"
                    name="pipelineStages"
                    defaultChecked={status.value === value}
                    value={status.value}
                    onChange={() => onChange(status.value)}
                  />
                </FilterCheckbox>
              ))}
            </>
          )}
        />
      </FormGroup>
      <Controller
        name={ApplicantFiltersNames.employer}
        render={({ value, onChange }) => (
          <FormSelect
            name={ApplicantFiltersNames.employer}
            label="Previous Employers"
            isClearable
            options={previousEmployersOptions}
            value={value}
            onChange={(option: Option) => {
              onChange(option?.value);
            }}
            menuPlacement={'auto'}
          />
        )}
      />
      <Controller
        name={ApplicantFiltersNames.education}
        render={({ value, onChange }) => (
          <FormSelect
            name={ApplicantFiltersNames.education}
            label="Education"
            isClearable
            options={educationsOptions}
            value={value}
            onChange={(option: Option) => {
              onChange(option?.value);
            }}
            menuPlacement={'auto'}
          />
        )}
      />
    </>
  );
};
