import React from 'react';
import styled from 'styled-components';

import { ListTableViewItemProps } from './ListViewProps';

const TableViewItem: React.FC<ListTableViewItemProps> = ({ item, columns, className }) => {
  return (
    <tr key={item.id} className={className}>
      {columns ? Object.values(columns).map((col: any, index) => <td key={index}>{col.component({ item })}</td>) : null}
    </tr>
  );
};

export default styled(TableViewItem)`
  .ref {
    font-size: 10px;
    font-weight: 700;
  }

  .column-title {
    color: inherit;
  }

  .column-action {
    border-left-width: 2px;
  }
`;
