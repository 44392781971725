import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { BadgeDoNotHireNoIcon } from 'components/Badge';
import { Ellipsis } from 'components/Ellipsis';
import { EllipsisWithTooltip } from 'components/EllipsisWithTooltip';
import { ExVisible } from 'components/ui/ExVisible';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

import { Applicant } from 'store/entities/applicants';

import { ApplicantStatusBadgeDinamicHiringPipeline } from './ApplicantStatusBadge';

const ApplicantJobApplicationRowNameWrapper = styled.div`
  flex: 0 0 auto;
`;

const NameWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const ApplicantJobApplicationRowName = styled(Link)`
  color: ${(props) => props.theme.colors.blackHeadings};
  margin-bottom: 0;
  width: 195px;
  font-weight: 600;
`;

const ApplicantJobApplicationRowRef = styled.span`
  font-size: ${getThemeFontSize('small')};
  font-weight: normal;
`;

const BadgeDoNotHireStyled = styled(BadgeDoNotHireNoIcon)`
  margin-left: 10px;
`;

const ApplicantStatusBadgeDinamicHiringPipelineStyled = styled(ApplicantStatusBadgeDinamicHiringPipeline)`
  margin-left: 10px;
`;

const ApplicantJobApplicationRowPure: React.FC<{ applicant: Applicant; className?: string }> = ({
  applicant,
  className,
}) => {
  const match = useRouteMatch();
  const modalPath = [match.url, applicant.jobId, 'applicant', applicant.id].join('/');
  return (
    <div className={className}>
      <NameWrapperStyled>
        <ApplicantJobApplicationRowNameWrapper>
          <Ellipsis className="ref" title={applicant.jobReference}>
            <ApplicantJobApplicationRowRef>Ref: {applicant.jobReference}</ApplicantJobApplicationRowRef>
          </Ellipsis>
          <EllipsisWithTooltip label={applicant.jobName as string}>
            <ApplicantJobApplicationRowName to={modalPath}>{applicant.jobName}</ApplicantJobApplicationRowName>
          </EllipsisWithTooltip>
        </ApplicantJobApplicationRowNameWrapper>
      </NameWrapperStyled>
      <ExVisible visible={!!applicant.doNotHire}>
        <BadgeDoNotHireStyled />
      </ExVisible>
      <ApplicantStatusBadgeDinamicHiringPipelineStyled applicantId={applicant.id} />
    </div>
  );
};

export const ApplicantJobApplicationRow = styled(ApplicantJobApplicationRowPure)`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
