import React from 'react';
import styled from 'styled-components/macro';

import { ApplicantFormScreeningQuestionsControlContainer } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ApplicantFormScreeningQuestionsControlContainer';
import { ScreeningQuestionViewProps } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionView/ScreeningQuestionViewProps';
import { useApplicationFormQuestionField } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/hooks/useCompanyScreeningQuestionsListState';

import { FormInput } from 'components/FormInput';

type ScreeningQuestionTextFieldProps = ScreeningQuestionViewProps & {
  className?: string;
};

const ScreeningQuestionTextFieldStyled = styled.div`
  width: 100%;

  .form-group {
    margin-bottom: 0;
  }
`;

const FormInputStyled = styled(FormInput)`
  input:disabled {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

const useScreeningQuestionTextFieldState = ({ className, editable, question }: ScreeningQuestionTextFieldProps) => {
  const name = question.itemId;

  const disabled = !editable;

  const answer = question?.answer?.toString() || '';

  return {
    className,
    disabled,
    name,
    answer,
  } as const;
};

export const ScreeningQuestionTextField: React.FC<ScreeningQuestionTextFieldProps> = (props) => {
  const { className, disabled, name, answer } = useScreeningQuestionTextFieldState(props);

  return (
    <ScreeningQuestionTextFieldStyled className={className}>
      <FormInputStyled label="" name={name} disabled={disabled} value={answer} />
    </ScreeningQuestionTextFieldStyled>
  );
};

const useApplicantFormScreeningQuestionTextFieldState = (props: ScreeningQuestionTextFieldProps) => {
  const { className } = props;

  const { fieldName, fieldErrors, disabled, isInvalid, register } = useApplicationFormQuestionField(props);

  return {
    className,
    disabled,
    fieldName,
    register,
    isInvalid,
    fieldErrors,
  } as const;
};

export const ApplicantFormScreeningQuestionTextField: React.FC<ScreeningQuestionTextFieldProps> = (props) => {
  const { className, disabled, fieldName, register, isInvalid, fieldErrors } =
    useApplicantFormScreeningQuestionTextFieldState(props);

  return (
    <ApplicantFormScreeningQuestionsControlContainer className={className} show={isInvalid} errors={fieldErrors}>
      <ScreeningQuestionTextFieldStyled>
        <FormInputStyled label="" name={fieldName} disabled={disabled} inputRef={register} />
      </ScreeningQuestionTextFieldStyled>
    </ApplicantFormScreeningQuestionsControlContainer>
  );
};
