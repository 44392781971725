import React from 'react';
import styled from 'styled-components';

import { DialogProps } from 'components/Dialog';
import { ExDialog } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { exModalConfirmAction } from 'store/modals/modals.actions';
import { ExModal } from 'store/modals/modals.interfaces';

type PublicModalConfirmTermsProps = {
  className?: string;
} & DialogProps &
  ExModal;

const PublicModalConfirmTermsStyled = styled(ExDialog)`
  text-align: center;
  white-space: pre-wrap;
  display: block;
`;

const usePublicModalConfirmTermsState = ({ id, className, onClose }: PublicModalConfirmTermsProps) => {
  const dispatch = useAppDispatch();
  const closeHandler = (isConfirm: boolean | null) => {
    if (isConfirm) {
      dispatch(exModalConfirmAction({ id }));
    } else {
      onClose(isConfirm);
    }
  };
  return {
    className,
    closeHandler,
  } as const;
};

export const PublicModalConfirmTerms: React.FC<PublicModalConfirmTermsProps> = (props) => {
  const { className, closeHandler } = usePublicModalConfirmTermsState(props);

  return (
    <PublicModalConfirmTermsStyled
      className={className}
      content={props.modalConfig?.content ?? {}}
      onClose={closeHandler}
    >
      <div>
        By clicking “Confirm” button, you agreed on our{' '}
        <a href="https://www.expedo.co/privacy-statement/" target="_blank" rel="noopener noreferrer">
          Terms &amp; Conditions
        </a>
        .
        <br />
        Your data will be used for the purpose of hiring process.
      </div>
    </PublicModalConfirmTermsStyled>
  );
};
