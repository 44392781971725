import React, { useCallback } from 'react';

import { applicantListActions } from 'containers/ApplicantLists/store';

import { TickBox } from 'components/ListView';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { Applicant } from 'store/entities/applicants';
import { hiringPipelinesStagesSelectors } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.reducer';
import { useSelector } from 'store/rootSelectors';

type ApplicantListTickBoxProps = {
  className?: string;
  selected: boolean;
  id: string;
  applicant: Applicant;
  multiselect?: boolean;
};

const useApplicantListTickBoxState = ({
  className,
  id,
  selected,
  applicant,
  multiselect,
}: ApplicantListTickBoxProps) => {
  const dispatch = useAppDispatch();

  const toggleItem = useCallback(
    () => dispatch(applicantListActions.toggleItemSelect({ id, selectedItemId: applicant.id })),
    [applicant.id, dispatch, id],
  );
  const toggleItemRadio = useCallback(
    () => dispatch(applicantListActions.toggleItemRadio({ id, selectedItemId: applicant.id })),
    [applicant.id, dispatch, id],
  );

  const handleSelect = useCallback(
    () => (multiselect ? toggleItem() : toggleItemRadio()),
    [multiselect, toggleItem, toggleItemRadio],
  );

  const isHired = useSelector((state) =>
    hiringPipelinesStagesSelectors.selectIsApplicantOnHiredStage(state, applicant.id, applicant.id),
  );

  return {
    className,
    selected,
    isHired,
    handleSelect,
  };
};

export const ApplicantListTickBox: React.FC<ApplicantListTickBoxProps> = (props) => {
  const { selected, isHired, handleSelect } = useApplicantListTickBoxState(props);

  return <TickBox selected={selected} preventAction={isHired} handleSelect={handleSelect} />;
};
