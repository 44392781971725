import { replace } from 'connected-react-router';
import { ExRoutes } from 'router/routes';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as fromCompanyApi from 'api-endpoints/company';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { fetchEntity, invokeApiCall, ReturnData, worker } from 'utils/sagas';

import { authActions } from 'store/auth';
import { authSelectors } from 'store/auth/auth.selectors';
import * as fromCompanyActions from 'store/company/company.actions';
import {
  changeDefaultCompanyCountry,
  companyUpdateCountriesSaga,
  companyUpdateDateFormatSaga,
  companyUpdateIndustriesSaga,
  removeCompanyCountry,
  removeCompanyIndustry,
} from 'store/company/company-sagas';
import { updateIntegrationsSaga } from 'store/company/company-sagas/update-integrations.saga';

import {
  changeDefaultCompanyCurrency,
  companyUpdateCurrenciesSaga,
  removeCompanyCurrency,
} from './company-sagas/update-currencies';
import { updateQualificationTypesMaps } from './company-sagas/update-qualification-types-maps.saga';
import { CompanyInfo } from './company.models';
import { companyActions } from '.';

// GET COMPANY INFO
function* loadCompanyInfoRequestSaga(action: ReturnType<typeof fromCompanyActions.loadCompanyInfoRequest>) {
  const companyId = yield select(authSelectors.selectCurrentCompanyId);

  yield startLoader(action);
  const { data, status }: ReturnData<typeof fromCompanyApi.getCompanyInfo> = yield invokeApiCall(
    fromCompanyApi.getCompanyInfo,
    {
      urlParams: { companyId },
    },
  );
  yield stopLoader(action);
  if (status === 200 && data) {
    yield put(companyActions.update({ info: CompanyInfo(data) }));
    return;
  }
  yield put(authActions.update({ companyId: null, loading: true }));
  yield put(replace(ExRoutes.selectCompanyPage()));
}
//------------------------------------------------------------------------------

// UPDATE COMPANY SETTINGS
const apiUpdateCompany = fetchEntity.bind(
  null,
  fromCompanyActions.updateCompanyEntity,
  fromCompanyApi.updateCompanyFunc,
);

const apiUpdateCompanyCancelable = worker.bind(null, fromCompanyActions.updateCompanyCancel, apiUpdateCompany);
//------------------------------------------------------------------------------

// SearchCommentsFunc
function* loadSearchComments(action: ReturnType<typeof fromCompanyActions.loadSearchComments>) {
  yield startLoader(action);
  const companyId = yield select(authSelectors.selectCurrentCompanyId);
  const { data, errorData, message } = yield invokeApiCall(fromCompanyApi.getSearchComments, {
    urlParams: { companyId },
  });
  if (!message && !errorData && data) {
    yield put(companyActions.update({ comments: data.items }));
  }
  yield stopLoader(action);
}
//------------------------------------------------------------------------------

// GetAutocompleteUsersFunc
function* loadCompanyAdminTeamMembers(action: ReturnType<typeof fromCompanyActions.loadCompanyAdminTeamMembers>) {
  yield startLoader(action);
  const companyId = yield select(authSelectors.selectCurrentCompanyId);

  const { data, errorData, message } = yield invokeApiCall(fromCompanyApi.getAdminTeamMembers, {
    urlParams: { companyId },
    params: { roles: action.payload.roles },
  });
  if (!message && !errorData && data) {
    yield put(companyActions.update({ adminTeamMembers: data }));
  }
  yield stopLoader(action);
}
//------------------------------------------------------------------------------

// UpdateCompanyCurrenciesFunc
const apiUpdateCompanyCurrencies = fetchEntity.bind(
  null,
  fromCompanyActions.updateCompanyEntity,
  fromCompanyApi.updateCompanyCurrenciesFunc,
);
const apiUpdateCompanyCurrenciesCancelable = worker.bind(
  null,
  fromCompanyActions.updateCompanyCancel,
  apiUpdateCompanyCurrencies,
);
//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
// UpdateCompanyCountriesFunc
const apiUpdateCompanyCountries = fetchEntity.bind(
  null,
  fromCompanyActions.updateCompanyEntity,
  fromCompanyApi.updateCompanyCountriesFunc,
);
const apiUpdateCompanyCountriesCancelable = worker.bind(
  null,
  fromCompanyActions.updateCompanyCancel,
  apiUpdateCompanyCountries,
);
//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
// UpdateCompanyIndustriesFunc
const apiUpdateCompanyIndustries = fetchEntity.bind(
  null,
  fromCompanyActions.updateCompanyEntity,
  fromCompanyApi.updateCompanyIndustriesFunc,
);
const apiUpdateCompanyIndustriesCancelable = worker.bind(
  null,
  fromCompanyActions.updateCompanyCancel,
  apiUpdateCompanyIndustries,
);
//------------------------------------------------------------------------------

export function* companyWorkers() {
  yield takeLatest(fromCompanyActions.loadCompanyInfoRequest, loadCompanyInfoRequestSaga);
  yield takeLatest(fromCompanyActions.changeDefaultCompanyCountry, changeDefaultCompanyCountry);
  yield takeLatest(fromCompanyActions.removeCompanyCountry, removeCompanyCountry);
  yield takeLatest(fromCompanyActions.updateCompanyRequest, apiUpdateCompanyCancelable);
  yield takeLatest(fromCompanyActions.updateCompanyCurrenciesRequest, apiUpdateCompanyCurrenciesCancelable);
  yield takeLatest(fromCompanyActions.updateCompanyCountriesRequest, apiUpdateCompanyCountriesCancelable);
  yield takeLatest(fromCompanyActions.updateCompanyIndustriesRequest, apiUpdateCompanyIndustriesCancelable);
  yield takeLatest(fromCompanyActions.companyUpdateCountries, companyUpdateCountriesSaga);
  yield takeLatest(fromCompanyActions.updateCompanyIndustries, companyUpdateIndustriesSaga);
  yield takeLatest(fromCompanyActions.removeCompanyIndustry, removeCompanyIndustry);
  yield takeLatest(fromCompanyActions.updateCompanyDefaultDateFormat, companyUpdateDateFormatSaga);
  yield takeLatest(fromCompanyActions.updateCompanyIntegrations, updateIntegrationsSaga);
  // Currencies
  yield takeLatest(fromCompanyActions.updateCompanyCurrencies, companyUpdateCurrenciesSaga);
  yield takeLatest(fromCompanyActions.changeDefaultCompanyCurrency, changeDefaultCompanyCurrency);
  yield takeLatest(fromCompanyActions.removeCompanyCurrency, removeCompanyCurrency);
  // Qualification Types Mapping
  yield takeLatest(fromCompanyActions.updateCompanyQualificationTypeMaps, updateQualificationTypesMaps);
  // SearchCommentsFunc
  yield takeLatest(fromCompanyActions.loadSearchComments, loadSearchComments);
  // GetAutocompleteUsersFunc
  yield takeLatest(fromCompanyActions.loadCompanyAdminTeamMembers, loadCompanyAdminTeamMembers);
}
