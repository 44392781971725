import React from 'react';

import { IconPlus } from 'components/Icons/IconPlus';
import { ExButton, ExButtonExtendedProps } from 'components/ui/ExButton';
import { getThemeColor } from 'utils/styled/getThemeColor';

export const ExAddNewButton: React.FC<ExButtonExtendedProps> = ({ variant = 'light', ...props }) => {
  /**
   * Code below is needed to assign plus icon inner color similar to the button background color.
   */
  const iconPlusColor = variant && variant !== 'link' ? getThemeColor(variant) : 'transparent';
  const PlusIcon = (iconProps) => <IconPlus $plusColor={iconPlusColor} {...iconProps} />;
  return <ExButton prependIcon={PlusIcon} variant={variant} {...props} />;
};
