import styled from 'styled-components/macro';

import {
  InterviewTableCellComponent,
  InterviewTableCellComponentProps,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/Tabs/InterviewsTab/InterviewsTabProps';

import { EllipsisWithTooltip } from 'components/EllipsisWithTooltip';

const InterviewTableApplicantNameStyled = styled.div``;

const useInterviewTableApplicantNameState = ({ item }: InterviewTableCellComponentProps) => {
  return {
    jobApplicantName: item?.jobApplicantName || '--',
  } as const;
};

export const InterviewTableApplicantName: InterviewTableCellComponent = (props) => {
  const { jobApplicantName } = useInterviewTableApplicantNameState(props);

  return (
    <InterviewTableApplicantNameStyled>
      <EllipsisWithTooltip label={jobApplicantName}>
        <div>{jobApplicantName}</div>
      </EllipsisWithTooltip>
    </InterviewTableApplicantNameStyled>
  );
};
