import { MouseEventHandler, useCallback, useRef, useState } from 'react';

type UseMouseHoverReturn<T> = [boolean, MouseEventHandler<T>, MouseEventHandler<T>];

function useMouseHover<T extends any>(): UseMouseHoverReturn<T> {
  const [isHovered, setIsHovered] = useState(false);
  const isOverElement = useRef(false);

  const handlerMouseOver = useCallback<MouseEventHandler<T>>(() => {
    if (!isOverElement.current) {
      setIsHovered(true);
      isOverElement.current = true;
    }
  }, []);

  const handlerMouseLeave = useCallback<MouseEventHandler<T>>(() => {
    if (isOverElement.current) {
      setIsHovered(false);
      isOverElement.current = false;
    }
  }, []);

  return [isHovered, handlerMouseOver, handlerMouseLeave];
}

export { useMouseHover };
