import { DefaultTheme } from 'styled-components';

const fontFamilyBase = 'var(--font-family-base)';
export const theme: DefaultTheme = {
  animations: {
    duration: 300,
  },
  border: {
    borderRadius: 'var(--border-radius)',
    borderRadiusSm: 'var(--border-radius-sm)',
    borderColor: 'var(--border-color)',
    borderColorOuter: 'var(--border-color-outer)',
    borderColorInner: 'var(--border-color-inner)',
  },
  colors: {
    blue: 'var(--blue)',
    primary: 'var(--primary)',
    primaryLight: 'var(--primary-light)',
    red: 'var(--red)',
    danger: 'var(--danger)',
    dangerLight: 'var(--danger-light)',
    success: 'var(--success)',
    successLight: 'var(--success-light)',
    green: 'var(--green)',
    greenLight: 'var(--green-light)',
    info: 'var(--info)',
    orange: 'var(--orange)',
    warning: 'var(--warning)',
    warningLight: 'var(--warning-light)',
    purple: 'var(--purple)',
    purpleLight: 'var(--purple-light)',
    default: 'var(--default)',
    defaultLight: 'var(--default-light)',
    blackParagraphs: 'var(--black-paragraphs)',
    blackHeadings: 'var(--black-headings)',
    dark: 'var(--dark)',
    grey: 'var(--grey)',
    gray: 'var(--gray)',
    grayDark: 'var(--gray-dark)',
    lightGray: 'var(--light-gray)',
    neutral: 'var(--neutral)',
    light: 'var(--light)',
    white: 'var(--white)',
    subtitle: 'var(--subtitle)',
    bodyBackgroundColor: 'var(--body-background-color)',
    draft: 'var(--draft)',
    draftLight: 'var(--draft-light)',
    inactive: 'var(--inactive)',

    new: 'var(--new)',
    newLight: 'var(--new-light)',
    shortlisted: 'var(--shortlisted)',
    shortlistedLight: 'var(--shortlisted-light)',
    interviewed: 'var(--interviewed)',
    interviewedLight: 'var(--interviewed-light)',
    screened: 'var(--screened)',
    screenedLight: 'var(--screened-light)',
    negotiated: 'var(--negotiated)',
    negotiatedLight: 'var(--negotiated-light)',
    readyToOnboard: 'var(--ready-to-onboard)',
    readyToOnboardLight: 'var(--ready-to-onboard-light)',
    inOnboarding: 'var(--in-onboarding)',
    inOnboardingLight: 'var(--in-onboarding-light)',
    hired: 'var(--hired)',
    hiredLight: 'var(--hired-light)',
    declined: 'var(--declined)',
    declinedLight: 'var(--declined-light)',
    withdrawn: 'var(--withdrawn)',
    withdrawnLight: 'var(--withdrawn-light)',
    badgeLightBg: 'var(--badge-light-bg)',
    badgeLightColor: 'var(--badge-light-color)',
  },
  fonts: {
    heading: {
      fontFamily: fontFamilyBase,
      fontSize: '24px',
      letterSpacing: '-0.5px',
      lineHeight: '156.3%',
      fontWeight: 600,
      color: 'black',
    },
    headingLg: {
      fontFamily: fontFamilyBase,
      fontSize: '32px',
      letterSpacing: '-0.6px',
      lineHeight: '156.3%',
      fontWeight: 600,
      color: 'black',
    },
    headingXLg: {
      fontFamily: fontFamilyBase,
      fontSize: '40px',
      letterSpacing: '-0.8px',
      lineHeight: '156.3%',
      fontWeight: 700,
      color: 'black',
    },
    headingXxLg: {
      fontFamily: fontFamilyBase,
      fontSize: '48px',
      letterSpacing: '-1px',
      lineHeight: '156.3%',
      fontWeight: 700,
      color: 'black',
    },
    regular: {
      fontFamily: fontFamilyBase,
      fontSize: '16px',
      letterSpacing: '-0.3px',
      lineHeight: '156.3%',
      fontWeight: 400,
      color: 'black',
    },
    regularSmall: {
      fontFamily: fontFamilyBase,
      fontSize: '14px',
      letterSpacing: '-0.3px',
      lineHeight: '156.3%',
      fontWeight: 400,
      color: 'black',
    },
    small: {
      fontFamily: fontFamilyBase,
      fontSize: '12px',
      letterSpacing: '-0.2px',
      lineHeight: '156.3%',
      fontWeight: 400,
      color: 'black',
    },
    xSmall: {
      fontFamily: fontFamilyBase,
      fontSize: '8px',
      letterSpacing: '-0.2px',
      lineHeight: '156.3%',
      fontWeight: 400,
      color: 'black',
    },
    headingBaseNormal: {
      fontFamily: fontFamilyBase,
      fontSize: '24px',
      lineHeight: '28px',
      letterSpacing: '-0.02em',
      fontWeight: 400,
      color: 'black',
    },
    headingBaseSemibold: {
      fontFamily: fontFamilyBase,
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 500,
      letterSpacing: '-0.02em',
      color: 'black',
    },
  },
  font: {
    base: fontFamilyBase,
  },
  fontSize: {
    default: '0.875rem', // html font size = 16px;
    cardHeading: '1rem',
    tableHeading: '0.875rem',
    small: '0.75rem',
    tableData: '0.875rem',
    large: '1.125rem',
    extraLarge: '2rem',
    extraSmall: '0.625rem',
    sidebarMenuItem: '0.9375rem',
    input: '0.875rem',
  },
  input: {
    borderColor: 'var(--input-border-color)',
  },
  chartColors: {
    backgroundColor: [
      '#0B78FF',
      '#47c144',
      '#ff6865',
      '#FCB300',
      '#1DA1C2',
      '#373737',
      '#7b57e0',
      '#ffc7f4',
      '#ffb699',
      '#a3ffdd',
      '#c7feff',
      '#fffaa3',
      '#ebb3ff',
      '#ac8aff',
      '#ffccd4',
      '#ff94f3',
      '#B6D8F2',
      '#F4CFDF',
      '#5784BA',
      '#CCD4BF',
      '#E7CBA9',
      '#EEBAB2',
      '#D0BCAC',
      '#BEB4C5',
      '#E6A57E',
      '#218B82',
      '#9AD9DB',
      '#C47482',
      '#AAD9CD',
      '#8DA47E',
      '#E8D595',
      '#B2B2B2',
      '#8EA4C8',
      '#7b68ee',
      '#483d8b',
      '#ffe4b5',
      '#e0ffff',
      '#ffc0cb',
    ],
    emptyColor: ['#F3F5F8'],
  },
};
