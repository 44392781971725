import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';

import { AppRouterParams } from 'model/router';

import { applicantModalSliceSelectors } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/store';

import { getApplicantModalCountInfo } from 'utils/getApplicantModalCountInfo';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

import { hiringPipelinesStagesSelectors } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.reducer';
import { useSelector } from 'store/rootSelectors';

type ApplicantModalTotalCountInfoProps = {
  className?: string;
};

const ApplicantModalTotalCountInfoStyled = styled.div`
  font-size: ${getThemeFontSize('small')};
  margin-bottom: 8px;
`;

const useApplicantModalTotalCountInfoState = ({ className }: ApplicantModalTotalCountInfoProps) => {
  const match = useRouteMatch<AppRouterParams>();
  const { jobId, applicantId, applicantsViewTab } = match.params;

  const applicantModalCountInfo = useAppSelector(applicantModalSliceSelectors.applicantModalCountInfo);
  const workflowApplicantCountersByJobId = useSelector((state) =>
    hiringPipelinesStagesSelectors.getWorkflowApplicantCountersByJobId(state, jobId!),
  );
  const workflowApplicantModalCountInfo = workflowApplicantCountersByJobId?.[applicantId!];

  const counterApplicants = getApplicantModalCountInfo(applicantModalCountInfo);
  const counterPipeline = getApplicantModalCountInfo(workflowApplicantModalCountInfo);

  const counter = {
    applicants: counterApplicants,
    pipeline: counterPipeline,
  }[applicantsViewTab!];

  return {
    className,
    counter,
  } as const;
};

export const ApplicantModalTotalCountInfo: React.FC<ApplicantModalTotalCountInfoProps> = (props) => {
  const { className, counter } = useApplicantModalTotalCountInfoState(props);

  return <ApplicantModalTotalCountInfoStyled className={className}>{counter}</ApplicantModalTotalCountInfoStyled>;
};
