/* eslint-disable sonarjs/no-duplicate-string */
export const TalentPoolEndpoints = {
  SearchTalentPoolsFunc: '/api/company/{companyId}/talent-pool',
  CreateTalentPoolFunc: '/api/company/{companyId}/talent-pool',
  GetTalentPoolFunc: '/api/company/{companyId}/talent-pool/{talentPoolId}',
  UpdateTalentPoolFunc: '/api/company/{companyId}/talent-pool/{talentPoolId}',
  DeleteTalentPoolFunc: '/api/company/{companyId}/talent-pool/{talentPoolId}',

  TalentPoolsAutocompleteFunc: '/api/company/{companyId}/talent-pool/autocomplete',
  GetTalentPoolsForCandidateFunc: '/api/company/{companyId}/talent-pool/for-candidate',
  BulkCreateTalentPoolCandidatesFromResumesFunc:
    '/api/company/{companyId}/talent-pool/{talentPoolId}/upload-candidates-from-resumes',
  BulkCreateCandidatesFromResumesFunc: '/api/company/{companyId}/upload-candidates-from-resumes',
};

export const TalentPoolEndpointsApi = {
  SearchTalentPoolsFunc: '/talent-pool',
  CreateTalentPoolFunc: '/talent-pool',
  GetTalentPoolFunc: '/talent-pool/{talentPoolId}',
  UpdateTalentPoolFunc: '/talent-pool/{talentPoolId}',
  DeleteTalentPoolFunc: '/talent-pool/{talentPoolId}',
  TalentPoolsAutocompleteFunc: '/talent-pool/autocomplete',
  GetTalentPoolsForCandidateFunc: '/talent-pool/for-candidate',
  BulkCreateTalentPoolCandidatesFromResumesFunc: '/talent-pool/{talentPoolId}/upload-candidates-from-resumes',
  BulkCreateCandidatesFromResumesFunc: '/upload-candidates-from-resumes',
  GetKeywordsFunc: '/talent-pool/{talentPoolId}/keyword',
} as const;
