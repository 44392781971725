import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { components, SingleValueProps } from 'react-select';

import { Currency } from 'model';
import { KeyCodes } from 'model/keycodes';

import { useCountriesMap } from 'pages/Company/CompanyTabs/CompanyDetailsTab/components/CompanyCurrencyCard';

import { FormCard } from 'components/FormCard';
import { FormTooltip } from 'components/FormTooltip';
import {
  ClearForm,
  ExSalaryInputWithCountryProps,
  ExSalaryInputWithCountryStyled,
  FormGroupStyled,
  FormSelectStyled,
  InputStyled,
  overrideStyles,
  reactCountryFlagStyles,
} from 'components/ui/ExSalaryInputWithCountry/ExComponentsInputWithCountry';
import { getApplicantFormattedCurrency } from 'utils/hooks/useCurrency';
import { ReactCountryFlagCdnUrl } from 'utils/hooks/useReactCountryFlagOptions';
import { useRestrictedKeyCodes } from 'utils/hooks/useRestrictedKeyCodes';

import { companySelectors } from 'store/company/company.selectors';
import { useSelector } from 'store/rootSelectors';

const useInputState = () => {
  const { watch } = useFormContext();

  const currencyName = 'salaryExpectationCurrency';
  const amountName = 'salaryExpectationAmount';

  const currencyValue = watch(currencyName);
  const amountValue = watch(amountName);

  const currencyValueFormatted = getApplicantFormattedCurrency({
    salaryExpectationAmount: amountValue,
    salaryExpectationCurrency: currencyValue,
  });

  return {
    currencyName,
    amountName,
    currencyValue,
    amountValue,
    currencyValueFormatted,
  };
};

const useExSalaryInputWithCountryState = ({ className, label, required }: ExSalaryInputWithCountryProps) => {
  const { errors, formState, setValue } = useFormContext();
  const validated = formState.isSubmitted;

  const target = useRef<any>();
  const currencyRef = useRef<any>();
  const { currencyName, amountName, currencyValue, amountValue } = useInputState();

  const currencies = useSelector(companySelectors.getCompanyCurrenciesSortedByName);

  const errorsByName = errors?.[amountName];
  const errorMessage = errorsByName?.message;
  const hasErrorMessage = Boolean(errorMessage);

  useEffect(() => {
    if (hasErrorMessage) {
      currencyRef.current.focus();
    }
  }, [hasErrorMessage]);

  const hasValue = currencyValue || amountValue;

  const onClear = useCallback(() => {
    setValue(currencyName, null);
    setValue(amountName, 0);
  }, [amountName, currencyName, setValue]);

  return {
    className,
    label,
    required,
    validated,
    currencyName,
    amountName,
    currencies,
    target,
    errorsByName,
    hasErrorMessage,
    onClear,
    hasValue,
    currencyRef,
  } as const;
};

const SingleValue = (singleValueProps: SingleValueProps<Currency>) => {
  const countriesMap = useCountriesMap();

  const item: Currency = singleValueProps.data;
  const countryCode = Object.entries(countriesMap).find(([_, v]) => v === item.currency)?.[0] ?? '';

  return (
    <components.SingleValue {...singleValueProps}>
      <ReactCountryFlag style={reactCountryFlagStyles} svg countryCode={countryCode} cdnUrl={ReactCountryFlagCdnUrl} />
    </components.SingleValue>
  );
};

const useAmountInput = (amountProps: ControllerRenderProps) => {
  const { formState, errors } = useFormContext();
  const validated = formState.isSubmitted;

  const { currencyValueFormatted, currencyName, amountName } = useInputState();
  const [isFocused, setFocused] = useState(false);

  const amountRef = useRef<any>();
  const amountInputErrors = !errors?.[currencyName] && errors?.[amountName];

  useEffect(() => {
    const hasErrorMessage = Boolean(amountInputErrors);
    if (hasErrorMessage) {
      setFocused(true);
      amountRef.current.focus();
    }
  }, [amountInputErrors]);

  const { handlerKeyDown } = useRestrictedKeyCodes({
    restrictedKeyCodes: [
      KeyCodes.KEY_E,
      KeyCodes.DASH,
      KeyCodes.EQUALS,
      KeyCodes.ADD,
      KeyCodes.SUBTRACT,
      KeyCodes.DECIMAL,
    ],
  });

  const onChange = useCallback(
    (e) => {
      amountProps.onChange(e);
    },
    [amountProps],
  );

  const onFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const onBlur = useCallback(() => {
    setFocused(false);
  }, []);

  const type = isFocused ? 'number' : 'text';
  const value = isFocused ? amountProps.value : currencyValueFormatted || amountProps.value;

  const showAmountInputErrors = isFocused && amountInputErrors;

  return (
    <>
      <InputStyled
        ref={amountRef}
        type={type}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        isInvalid={validated && !!amountInputErrors}
        onKeyDown={handlerKeyDown}
        errors={amountInputErrors}
      />
      <FormTooltip target={amountRef} show={showAmountInputErrors} errors={amountInputErrors} offset={[0, 2]} />
    </>
  );
};

export const ExSalaryInputWithCountry: React.FC<ExSalaryInputWithCountryProps> = (props) => {
  const {
    className,
    label,
    required,
    validated,
    currencyName,
    amountName,
    currencies,
    target,
    onClear,
    hasValue,
    currencyRef,
  } = useExSalaryInputWithCountryState(props);

  return (
    <FormGroupStyled ref={target} className={className}>
      <FormCard.InputLabel>
        {label} {required && <FormCard.InputLabelRequired>*</FormCard.InputLabelRequired>}
      </FormCard.InputLabel>
      <ExSalaryInputWithCountryStyled>
        <FormSelectStyled
          ref={currencyRef}
          name={currencyName}
          placeholder=""
          validated={validated}
          defaultOptions
          getOptionLabel={(option: Currency | null) => option?.currencyFullName}
          getOptionValue={(option: Currency | null) => option?.currency}
          options={currencies}
          components={{
            IndicatorSeparator: null,
            SingleValue,
            Placeholder: () => null,
          }}
          overrideStyles={overrideStyles}
          isSearchable={false}
          openMenuOnFocus
          tooltipOffset={[0, 2]}
        />
        <Controller name={amountName} render={useAmountInput} />
        <ClearForm show={hasValue} method={onClear} />
      </ExSalaryInputWithCountryStyled>
    </FormGroupStyled>
  );
};
