import { createSelector } from '@reduxjs/toolkit';

import { entitiesDomain } from 'store/rootSelectors';

import { candidateEducationsAdapter } from './candidate-education.reducer';

const domain = createSelector(entitiesDomain, (state) => state.candidateEducations);

const adapterSelectors = candidateEducationsAdapter.getSelectors(domain);

export const candidateEducationSelectors = {
  ...adapterSelectors,
  selectIsEducationListEmpty: createSelector(adapterSelectors.selectTotal, (total) => total === 0),
};
