import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { CalendarEventDto } from 'containers/Calendar/state/calendar.model';

import { adapterHelper } from 'utils/reducer/adapter-helper';

const calendarJobAdsAdapter = createEntityAdapter<CalendarEventDto>({
  selectId: (jobAd) => jobAd.id,
});

const calendarJobAdsInitialState = calendarJobAdsAdapter.getInitialState({});

const calendarJobAdsSlice = createSlice({
  name: 'jobAds-events',
  initialState: calendarJobAdsInitialState,
  reducers: {
    ...adapterHelper(calendarJobAdsAdapter),
  },
});

const calendarJobAdsReducer = calendarJobAdsSlice.reducer;

export { calendarJobAdsReducer, calendarJobAdsSlice, calendarJobAdsAdapter, calendarJobAdsInitialState };
