import React, { useEffect } from 'react';

export const useOnClickOutside = <R extends HTMLElement>(
  refs: React.RefObject<R | null> | Array<React.RefObject<R | null>>,
  handler: Function,
) => {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        // Do nothing if clicking ref's element or descendent elements
        if (Array.isArray(refs)) {
          if (refs.some(r => !r.current || r.current.contains(event.target as Node))) {
            return;
          }
        } else {
          if (!refs.current || refs.current.contains(event.target as Node)) {
            return;
          }
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [refs, handler],
  );
};
