import { combineReducers } from '@reduxjs/toolkit';

import { companyScreeningQuestionListUiSlice } from './company-screening-question-list/company-screening-question-list.reducer';
import { companyInterviewTemplateUiSlice } from './company-interview-template';
import { companyInterviewTemplateListUiSlice } from './company-interview-template-list';
import { companyScreeningQuestionUiSlice } from './company-screening-question';

export const companyUi = combineReducers({
  [companyInterviewTemplateUiSlice.name]: companyInterviewTemplateUiSlice.reducer,
  [companyInterviewTemplateListUiSlice.name]: companyInterviewTemplateListUiSlice.reducer,
  [companyScreeningQuestionUiSlice.name]: companyScreeningQuestionUiSlice.reducer,
  [companyScreeningQuestionListUiSlice.name]: companyScreeningQuestionListUiSlice.reducer,
});
