export * from './auth';
export * from './document-meta';
export * from './environment';
export * from './list';
export * from './date';
export * from './apiConfig';
export * from './dashboard';
export * from './tinymce';
export * from './images';

export const appVersion = process.env.REACT_APP_VERSION;
