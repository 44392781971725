import React from 'react';

import { JobAbilitiesModal } from 'containers/Modals/ModalsContent/Job/JobAbilitiesModal';
import { useJobAdAbilityItems } from 'containers/Modals/ModalsContent/Job/JobAbilitiesModal/hooks/useJobAdAbilityItems';

import { JobAdResponsibilitiesModalProps } from './JobAdResponsibilitiesModalProps';

export const JobAdResponsibilitiesModal: React.FC<JobAdResponsibilitiesModalProps> = ({ ...dialogProps }) => {
  const title = 'Job Ad Responsibilities';
  const { onSubmitValidation, items } = useJobAdAbilityItems({
    abilityEntity: 'responsibilities',
    validationSchemaLabel: title,
    onClose: dialogProps.onClose,
  });

  return <JobAbilitiesModal title={title} onSubmitValidation={onSubmitValidation} items={items} {...dialogProps} />;
};
