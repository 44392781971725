import { CandidateItemResponse } from 'api-endpoints/candidate';

import { candidateToState } from 'store/entities/candidates/mappers';
import { Candidate } from 'store/entities/candidates/models';

interface MapCandidatesReturnValue {
  candidateIds: Array<Candidate['id']>;
  candidates: Array<Candidate>;
}

export const mapCandidates = (response: Array<CandidateItemResponse>): MapCandidatesReturnValue => {
  return response.map(candidateToState).reduce(
    (acc, candidate) => ({
      candidateIds: [...acc.candidateIds, candidate.id],
      candidates: [...acc.candidates, candidate],
    }),
    {
      candidateIds: [],
      candidates: [],
    } as MapCandidatesReturnValue,
  );
};
