import { css } from 'styled-components/macro';

import { CalendarEventDto } from 'containers/Calendar/state/calendar.model';
import { CalendarJobAd } from 'containers/Calendar/state/calendar-job-ads/calendar-job-ads.model';
import { CalendarJob } from 'containers/Calendar/state/calendar-jobs/calendar-jobs.model';
import { FilterType } from 'containers/Calendar/state/calendar-ui/calendar-ui.model';

export const CalendarEvent = <T extends Partial<CalendarJobAd & CalendarJob>>(
  dto: T,
  type: FilterType,
): CalendarEventDto => {
  const isJob = type === 'job';

  return {
    id: isJob ? dto.jobId : dto.id,
    title: dto.jobName,
    jobName: dto.jobName,
    jobBoardName: dto.jobBoardName,
    start: dto.startDate,
    end: isJob ? dto.deadline : dto.expiryDate,
    type,
    allDay: true,
  } as CalendarEventDto;
};

export const getTitles = (type: FilterType) => {
  return {
    job: 'Jobs',
    jobAd: 'Job Ads',
  }[type];
};

export const getColorName = (type: FilterType) => {
  return {
    job: 'orange',
    jobAd: 'blue',
  }[type];
};

type getEventBgColorByTypeProps = {
  $type: FilterType;
};

export const getEventBgColorByType = ({ $type }: getEventBgColorByTypeProps) => {
  const color = getColorName($type);
  return css`
    background-color: ${({ theme }) => theme.colors[color]};
  `;
};
