import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';

import { JobBoardSettingsFormBody } from 'containers/JobBoardsForm/FormBody';
import { selectJobBoard } from 'containers/JobBoardsForm/state/selectors';

import { StepDescription } from 'components/StepForm/components/StepDescription/StepDescription';
import { StepComponent } from 'components/StepForm/StepFormProps';
import { ExCard } from 'components/ui/ExCard';
import { getTranslate } from 'utils/i18utils';

import { JobBoard } from 'store/entities/job-boards';
import { RootState } from 'store/rootReducer';

interface JobBoardSettingsStepProps extends StepComponent {
  className?: string;
}

const JobBoardSettingsStepPure: React.FC<JobBoardSettingsStepProps> = ({ className, ...rest }) => {
  const selectedJobBoard = useSelector<RootState>((state) => selectJobBoard(state)) as JobBoard;
  const selectedJobBoardName = selectedJobBoard.name;
  const getJobBoardCleanName = useCallback((name: string) => {
    const capCaseName = _.capitalize(name);
    return capCaseName.split(' ')[0];
  }, []);
  const jobBoardCleanName = getJobBoardCleanName(selectedJobBoardName);

  const getJobBoardSettingDescription = useCallback((name: string) => {
    switch (name) {
      case 'Linkedin':
        return (
          <>
            <p>
              Your company ID on LinkedIn can be found in the URL of your company page, e.g. linkedin.com/company/
              <strong>123456789</strong>. Please note, you must be logged in as a Linkedin account administrator to see
              this ID. Please see the complete instructions in the{' '}
              <a href="https://support.expedo.co/hc/en-au/articles/900004707883-Connect-to-your-Job-Boards">
                Connect to your Job Boards
              </a>{' '}
              help article.
            </p>
          </>
        );
      case 'Jora':
        return (
          <>
            <p>
              When you connect to Jora we will automatically send Jora your company name for the job ad so there is
              nothing else you need to do! For more information please visit the{' '}
              <a href="https://support.expedo.co/hc/en-au/articles/900004707883-Connect-to-your-Job-Boards">
                Connect to your Job Boards
              </a>{' '}
              help article.
            </p>
          </>
        );
      case 'Jobstreet':
        return (
          <>
            <p>
              You will need your User ID and Advertiser ID - as well as which country (Channel) your account belongs to.
              Please see the complete instructions in the{' '}
              <a href="https://support.expedo.co/hc/en-au/articles/900004707883-Connect-to-your-Job-Boards">
                Connect to your Job Boards
              </a>{' '}
              help article.
            </p>
          </>
        );
      case 'Indeed':
        return (
          <>
            <p>
              Please use your exact company name as shown in Indeed to connect to your Indeed account. Please see the
              complete instructions in the{' '}
              <a href="https://support.expedo.co/hc/en-au/articles/900004707883-Connect-to-your-Job-Boards">
                Connect to your Job Boards
              </a>{' '}
              help article.
            </p>
          </>
        );
      case 'Seek':
        return (
          <>
            <p>
              Please fill out the form on SEEK's site first:{' '}
              <a href="https://talent.seek.com.au/partners/link-your-software/">SEEK - Link your software</a>. Next you
              will use the last 9 characters of your Hirer ID for the Hirer ID field below. See the complete
              instructions in the{' '}
              <a href="https://support.expedo.co/hc/en-au/articles/900004707883-Connect-to-your-Job-Boards">
                Connect to your Job Boards
              </a>{' '}
              help article.
            </p>
          </>
        );
      default:
        return getTranslate('subscribe_to_job_board.step_2.description');
    }
  }, []);
  const jobBoardSettingDescription = getJobBoardSettingDescription(jobBoardCleanName);

  return (
    <section className={className}>
      <StepDescription>
        <StepDescription.Title>Step 2: {jobBoardCleanName} Settings</StepDescription.Title>
        <StepDescription.Description>{jobBoardSettingDescription}</StepDescription.Description>
      </StepDescription>
      <ExCard title={`${jobBoardCleanName} Settings`}>
        <JobBoardSettingsFormBody {...rest} />
      </ExCard>
    </section>
  );
};

export const JobBoardSettingsStep = styled(JobBoardSettingsStepPure)``;
