/* eslint-disable sonarjs/cognitive-complexity */
import { ExInterview } from 'model';
import { InterviewState } from 'model/api-enums.constants';

import { rbacConstants } from 'containers/Auth/AuthMatrix';
import { useRBAC } from 'containers/Auth/components/RBAC';

import { useAppSelector } from 'utils/hooks/useSelectors';

import { authSelectors } from 'store/auth/auth.selectors';

type InterviewActionsProps = {
  interview?: ExInterview;
};

const showEditButtonCheck = (
  isDraftState: boolean,
  isEditFeatureAllowed: boolean,
  isCurrentUserCreatorOrInterviewer: boolean,
) => {
  if (isDraftState) {
    return false;
  }

  if (isEditFeatureAllowed) {
    return true;
  }

  if (isCurrentUserCreatorOrInterviewer) {
    return true;
  }

  return false;
};

export const useInterviewActions = ({ interview }: InterviewActionsProps) => {
  const isEditFeatureAllowed = useRBAC(rbacConstants.interviews.edit);
  const isRemoveFeatureAllowed = useRBAC(rbacConstants.interviews.remove);
  const isPublishFeatureAllowed = useRBAC(rbacConstants.interviews.publish);

  const user = useAppSelector(authSelectors.apiUser);

  const isCurrentUserCreator = user?.userId === interview?.createdById;

  const isDraftState = interview !== undefined && interview?.state === InterviewState.Draft;

  const showPublishButton = isDraftState && (isPublishFeatureAllowed || isCurrentUserCreator);
  const showSettingsButton = false;
  const showCloseButton = interview !== undefined && (interview?.state === InterviewState.Saved || !showPublishButton);

  const showEditButton = showEditButtonCheck(isDraftState, isEditFeatureAllowed, isCurrentUserCreator);

  const showEditMenuButton = [showEditButton, isDraftState].some(Boolean);

  const showDeleteButton = (() => {
    if (isRemoveFeatureAllowed) {
      return true;
    }

    if (!isDraftState) {
      return false;
    }

    if ([isDraftState, isCurrentUserCreator, !interview?.wasPublished].every(Boolean)) {
      return true;
    }

    return false;
  })();

  const publishButtonTitle = interview?.wasPublished ? 'Re-publish' : 'Publish';

  return {
    showPublishButton,
    showSettingsButton,
    showCloseButton,
    showDeleteButton,
    showEditButton,
    showEditMenuButton,
    publishButtonTitle,
  };
};
