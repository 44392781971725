import { useCallback } from 'react';

import { ColumnMap } from 'components/ListView/ListViewProps';
import { TickBox } from 'components/ListView/ListViewTick';
import { tableHeadTickStyles } from 'components/TableList/TableListComponents';
import { BasicModel, ListState } from 'utils/reducer/reducer-helper';

export type UseTickColumnMap = {
  multiselect?: boolean;
  isAllSelected?: boolean;
  selectableHeaderDisabled?: boolean;
  preventAction?: boolean | Function;
  list: ListState<BasicModel>;
  id: string;
  toggleItem: Function;
  toggleItemRadio: Function;
  deselectAll?: Function;
  selectAll?: Function;
};

export const useTickColumn = ({
  selectableHeaderDisabled,
  isAllSelected,
  selectAll,
  deselectAll,
  list,
  multiselect,
  id,
  toggleItem,
  toggleItemRadio,
  preventAction,
}: UseTickColumnMap) => {
  const handleSelectTick = useCallback(
    (selectedItemId) =>
      multiselect ? () => toggleItem({ id, selectedItemId }) : () => toggleItemRadio({ id, selectedItemId }),
    [id, multiselect, toggleItem, toggleItemRadio],
  );

  const TickColumnTickBox = ({ item }) => (
    <TickBox
      selected={list.selectedItems.includes(item.id)}
      preventAction={typeof preventAction === 'function' ? preventAction(item) : preventAction}
      handleSelect={handleSelectTick(item.id)}
    />
  );

  const tickColumn: ColumnMap = {
    tick: {
      label: '',
      component: TickColumnTickBox,
      style: tableHeadTickStyles,
    },
  };

  const headerTickColumn: ColumnMap = {
    tickHeader: {
      label: '',
      component: () =>
        multiselect ? (
          <TickBox
            selected={isAllSelected ?? false}
            preventAction={selectableHeaderDisabled}
            handleSelect={() => (isAllSelected ? deselectAll && deselectAll({ id }) : selectAll && selectAll({ id }))}
          />
        ) : (
          <div></div>
        ),
      style: tableHeadTickStyles,
    },
  };

  return {
    tickColumn,
    headerTickColumn,
    TickColumnTickBox,
    handleSelectTick,
  };
};
