import {
  CreateCandidateEducationInnerRequestParams,
  CreateCandidateEducationRequestParams,
  UpdateCandidateEducationInnerRequestParams,
  UpdateCandidateEducationRequestParams,
} from 'store/entities/candidate-education/api/requests';

export const educationDateRequestMapper = ({
  data,
  ...rest
}: UpdateCandidateEducationInnerRequestParams | CreateCandidateEducationInnerRequestParams):
  | UpdateCandidateEducationRequestParams
  | CreateCandidateEducationRequestParams => {
  return {
    ...rest,
    data: {
      ...data,
    },
  };
};
