import type { CaseReducer, DeepPartial, PayloadAction } from '@reduxjs/toolkit';
import type { ExInterviewTemplateListItem, Position } from 'model';

import type { Job, JobTeamMember } from 'store/entities/jobs/models';

export interface JobFormState {
  formData: DeepPartial<Job>;
  validSteps: number[];
  showSteps: boolean;
  isDraftSaved: boolean;
  positions: Position[];
  apiErrors: string[];
  interviewTemplates: Array<ExInterviewTemplateListItem>;
  hiringManagers: JobTeamMember[];
  isAutosaving: boolean;
  isOpening: boolean;
}

export type FormJobCaseReducer<T> = CaseReducer<JobFormState, PayloadAction<T>>;

export const formJobInitialState: JobFormState = {
  validSteps: [],
  positions: [],
  showSteps: true,
  isDraftSaved: false,
  formData: {},
  interviewTemplates: [],
  apiErrors: [],
  hiringManagers: [],
  isAutosaving: false,
  isOpening: false,
};
