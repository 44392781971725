import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { ExEducation } from 'model';

import { adapterHelper } from 'utils/reducer/adapter-helper';

import { EntityKeysNew } from 'store/constants';

export const candidateEducationsAdapter = createEntityAdapter<ExEducation>({
  selectId: (item) => item.educationId,
});

export const candidateEducationsSlice = createSlice({
  name: EntityKeysNew.candidateEducations,
  initialState: candidateEducationsAdapter.getInitialState(),
  reducers: {
    ...adapterHelper(candidateEducationsAdapter),
  },
});
