import React from 'react';

import { CompanyInterviewTemplatesElementProps } from 'pages/Company/CompanyTabs/CompanyInterviewTemplatesTab/CompanyInterviewTemplatesTypes';

import { interviewTemplateEditFormSlice } from 'containers/InterviewTemplateForms/InterviewTemplateEditForm/state/InterviewTemplateEditForm.reducer';
import { interviewTemplateEditFormSelectors } from 'containers/InterviewTemplateForms/InterviewTemplateEditForm/state/InterviewTemplateEditForm.selectors';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useModal } from 'utils/hooks/useModal';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { useInterviewQuestionListQuery } from 'store/entities/interview-question/interview-question.api';
import { interviewTemplateQuestionDeleteModalShowAction } from 'store/entities/interview-template-questions/interview-template-questions.actions';
import { useQuestionListQuery } from 'store/entities/interview-template-questions/interview-template-questions.api';
import { companyInterviewTemplateUiSlice } from 'store/ui/company/company-interview-template/company interview-template.reducer';

export const useInterviewTemplatesElementState = ({
  className,
  forwardRef,
  interviewTemplateId,
  interviewTemplateQuestionId,
  editable = false,
  index,
  ...dndProps
}: CompanyInterviewTemplatesElementProps) => {
  const dispatch = useAppDispatch();
  const { data: question } = useQuestionListQuery(interviewTemplateId, {
    selectFromResult: ({ data, ...rest }) => ({
      data: data?.entities[interviewTemplateQuestionId],
      ...rest,
    }),
  });

  const hasInfo = Boolean(question?.additionalInformation);

  const onEditButtonClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    dispatch(companyInterviewTemplateUiSlice.actions.setEditQuestionId(interviewTemplateQuestionId));
  };

  const onDeleteButtonClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    dispatch(
      interviewTemplateQuestionDeleteModalShowAction({ questionId: interviewTemplateQuestionId, interviewTemplateId }),
    );
  };

  return {
    className,
    data: question,
    forwardRef,
    dndProps,
    hasInfo,
    interviewTemplateQuestionId,
    interviewTemplateId,
    onEditButtonClickHandler,
    onDeleteButtonClickHandler,
    editable,
    index,
  };
};

export const useInterviewElementState = ({
  className,
  interviewTemplateId,
  interviewTemplateQuestionId,
  editable = false,
  index,
}: CompanyInterviewTemplatesElementProps) => {
  const { data: question } = useInterviewQuestionListQuery(interviewTemplateId, {
    selectFromResult: ({ data, ...rest }) => ({
      data: data?.entities[interviewTemplateQuestionId]!,
      ...rest,
    }),
  });

  const hasInfo = Boolean(question?.additionalInformation);

  return {
    className,
    data: question,
    hasInfo,
    interviewTemplateId,
    interviewTemplateQuestionId,
    editable,
    index,
  };
};

export const useInterviewTemplatesElementForFormState = ({
  className,
  forwardRef,
  interviewTemplateId,
  interviewTemplateQuestionId,
  editable = false,
  index,
  ...dndProps
}: CompanyInterviewTemplatesElementProps) => {
  const dispatch = useAppDispatch();
  const question = useAppSelector(
    interviewTemplateEditFormSelectors.questionsFormSelectorsFromAdapter.selectById,
    interviewTemplateQuestionId,
  );

  const { show } = useModal({});

  const hasInfo = Boolean(question?.additionalInformation);

  const onEditButtonClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    dispatch(companyInterviewTemplateUiSlice.actions.setEditQuestionId(interviewTemplateQuestionId));
  };

  const onDeleteButtonClickHandler: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation();
    const result = await show({
      withTitle: true,
      title: 'Confirm Remove',
      withActions: true,
      buttonOk: 'Remove',
      buttonOkVariant: 'danger',
      message: `You confirm remove the question\n "${question?.questionText}"`,
    });
    if (result) {
      dispatch(
        interviewTemplateEditFormSlice.actions.interviewTemplateEditFormRemoveQuestionReducer(
          interviewTemplateQuestionId,
        ),
      );
    }
  };

  return {
    className,
    data: question,
    forwardRef,
    dndProps,
    hasInfo,
    interviewTemplateQuestionId,
    interviewTemplateId,
    onEditButtonClickHandler,
    onDeleteButtonClickHandler,
    editable,
    index,
  };
};
