import React from 'react';
import styled from 'styled-components/macro';

import { AppModalContentProps } from 'containers/Modals/AppModalProps';

import { CommentsWidgetItem } from 'components/CommentsWidgetItem';
import { ExDialog } from 'components/ui/ExDialog';
import { ExVisible } from 'components/ui/ExVisible';
import { useKeyPress } from 'utils/hooks/useKeyPress';
import { useNoScrollBodyOnMount } from 'utils/hooks/useNoScrollBody';

import { useGetRecentMentionsQuery } from 'store/entities/recent-mentions/recent-mention.api';

type MentionsModalProps = {
  className?: string;
} & AppModalContentProps;

const MentionsModalStyled = styled(ExDialog)``;

const useMentionsModalState = ({ className, onClose }: MentionsModalProps) => {
  useNoScrollBodyOnMount();

  useKeyPress('Escape', () => {
    if (onClose instanceof Function) {
      onClose();
    }
  });

  const { data: companyMentions } = useGetRecentMentionsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  return {
    className,
    companyMentions,
  } as const;
};

export const MentionsModal: React.FC<MentionsModalProps> = (props) => {
  const { className, companyMentions } = useMentionsModalState(props);

  return (
    <MentionsModalStyled
      className={className}
      content={{ title: 'Recent @ Mentions', withTitle: true }}
      onClose={props.onClose}
    >
      <div>
        <ExVisible visible={!!companyMentions}>
          {companyMentions?.ids.map((commentId) => (
            <CommentsWidgetItem key={commentId} comment={companyMentions.entities[commentId]!} isCommentLink />
          ))}
        </ExVisible>
      </div>
    </MentionsModalStyled>
  );
};
