import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { Form, FormSelect } from 'components';
import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { ExDialogActionButton, ExDialogActions, ExDialogFooter } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { useGetDefaultPipelineQuery } from 'store/entities/hiring-pipelines/hiring-pipelines.api';
import {
  hiringPipelineActions,
  hiringPipelineSelectors,
} from 'store/entities/hiring-pipelines/hiring-pipelines.reducer';
import { ModalStepProps } from 'store/modals/config';
import { exModalConfirmAction } from 'store/modals/modals.actions';
import { useSelector } from 'store/rootSelectors';

import { backupIdValidationSchema, ModalBodyStyled } from './remove-with-backup-id.shared';

const HiringPipelineSelectFormBody = ({ deletedPipelineId }: { name: string; deletedPipelineId: string }) => {
  const f = useFormContext();

  const pipelines = useSelector((state) =>
    hiringPipelineSelectors.selectPipelinesExcludeByIds(state, [deletedPipelineId]),
  );

  const selectOptions = pipelines.map((pipeline) => ({ label: pipeline.name, value: pipeline.pipelineId }));

  return (
    <FormSelect
      name="backupId"
      label="Select a new pipeline"
      options={selectOptions}
      validated={f.formState.isSubmitted}
      errors={f.errors['backupId']}
    />
  );
};

type HiringPipelineSelectModalProps = ModalStepProps & { modalProps: { deletedPipelineId: string } };

const useHiringPipelineSelectModalState = ({
  id,
  modalProps,
  onCloseDialogHandler,
}: HiringPipelineSelectModalProps) => {
  const { deletedPipelineId } = modalProps;

  const { data: defaultPipeline } = useGetDefaultPipelineQuery({});
  const defaultPipelineFromList = useAppSelector(hiringPipelineSelectors.selectDefaultPipeline);
  const defaultPipelineId = defaultPipeline?.pipelineId || defaultPipelineFromList?.pipelineId;

  const dispatch = useAppDispatch();

  const submitHandler = useCallback(
    (form: any) => {
      dispatch(exModalConfirmAction({ id, modalResult: form }));
    },
    [dispatch, id],
  );

  return { defaultPipelineId, deletedPipelineId, onCloseDialogHandler, submitHandler } as const;
};

const HiringPipelineSelectModal: React.FC<HiringPipelineSelectModalProps> = (props) => {
  const { submitHandler, defaultPipelineId, onCloseDialogHandler, deletedPipelineId } =
    useHiringPipelineSelectModalState(props);

  return (
    <ModalBodyStyled>
      <Form
        validationSchema={backupIdValidationSchema}
        onSubmit={submitHandler}
        defaultValues={{ backupId: defaultPipelineId }}
      >
        <HiringPipelineSelectFormBody name="pipelineIdBody" deletedPipelineId={deletedPipelineId} />
        <ExDialogFooter>
          <ExDialogActions>
            <ExDialogActionButton onClick={() => onCloseDialogHandler()} variant="light">
              Cancel
            </ExDialogActionButton>
            <ExLoaderHandlerWrapper action={hiringPipelineActions.remove}>
              <ExDialogActionButton className="ml-3" type="submit">
                Save
              </ExDialogActionButton>
            </ExLoaderHandlerWrapper>
          </ExDialogActions>
        </ExDialogFooter>
      </Form>
    </ModalBodyStyled>
  );
};

export const hiringPipelineDeleteSteps = {
  HiringPipelineSelectModal,
};
