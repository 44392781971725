import React from 'react';
import styled from 'styled-components';

type Props = {};
type State = { hasError: boolean };

const HeaderStyled = styled.h1`
  text-align: center;
`;

export class ExCatchErrorComponent extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    if (error.name === 'ChunkLoadError') {
      window.location.reload();
    }
    // You can also log the error to an error reporting service
    // console.error('Errors', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <HeaderStyled>Something went wrong.</HeaderStyled>;
    }

    return this.props.children;
  }
}
