import { PayloadAction } from '@reduxjs/toolkit';

import { PipelineStageType } from 'model/api-enums.constants';

import { initialState } from 'containers/ApplicantLists/store/initialState';

import { createListReducer, getCacheListID, ListState } from 'utils/reducer/reducer-helper';

import { UIKeys } from 'store/constants';
import { Applicant, ApplicantBelongsTo, ApplicantFilters } from 'store/entities/applicants/models';
import { enhancedPlacementApi } from 'store/entities/placements/placements.api';

export const applicantListsSlice = createListReducer({
  initialState,
  name: UIKeys.applicantLists,
  reducers: {
    selectAllNotHired: (
      state,
      action: PayloadAction<{
        id: ListState<Applicant, ApplicantFilters, any>['id'];
        applicants: Applicant[];
      }>,
    ) => {
      const { id, applicants } = action.payload;
      if (!id || !state[id]) return state;
      const cacheId = getCacheListID(state[id]);
      if (state[id].pagination[cacheId]?.items) {
        state[id].selectedItems = [
          ...applicants
            .filter((applicant) => applicant.stageType !== PipelineStageType.Hired)
            .map((applicant) => applicant.id),
        ];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(enhancedPlacementApi.endpoints.placementList.matchFulfilled, (draft, { payload, type }) => {
      const applicantsIds = payload.items.map((item) => item.applicantId);

      applicantListsSlice.caseReducers.fetchSuccess(draft, {
        payload: {
          id: ApplicantBelongsTo.placements,
          items: applicantsIds,
          pageCount: payload.pageCount,
          totalItemsCount: payload.totalItemsCount,
        },
        type,
      });
    });
  },
});

export const applicantListActions = applicantListsSlice.actions;
