import { css } from 'styled-components';

import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

export const CardNameCss = css`
  display: inline-block;
  color: ${(props) => props.theme.colors.blackHeadings};
  margin-bottom: 0;
  font-size: ${getThemeFontSize('cardHeading')};
  font-weight: ${(props) => props.theme.fonts.heading.fontWeight};
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export const CardDataLineCss = css`
  font-size: ${getThemeFontSize('small')};
  line-height: 16px;
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
`;
