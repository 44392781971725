import React from 'react';
import styled from 'styled-components/macro';

import { ApplicantFormScreeningQuestionsControlContainer } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ApplicantFormScreeningQuestionsControlContainer';
import {
  useApplicantFormScreeningQuestionCheckBoxState,
  useScreeningQuestionCheckBoxState,
} from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionCheckBox/hooks/useScreeningQuestionCheckBoxState';
import {
  ScreeningQuestionFormHiddenFocusInputStyled,
  ScreeningQuestionFormInputV2,
  ScreeningQuestionFormLabelV2,
  ScreeningQuestionFormList,
  ScreeningQuestionFormListItem,
} from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionForm/ScreeningQuestionForm';
import { ScreeningQuestionRadio } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionForm/ScreeningQuestionRadio';
import { ScreeningQuestionViewProps } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionView/ScreeningQuestionViewProps';

const ScreeningQuestionSelectBoxStyled = styled.div``;

export const ScreeningQuestionSelectBox: React.FC<ScreeningQuestionViewProps> = (props) => {
  const { className, disabled, options, name } = useScreeningQuestionCheckBoxState(props);

  return (
    <ScreeningQuestionSelectBoxStyled className={className}>
      <ScreeningQuestionFormList>
        {options.map((option, index) => (
          <ScreeningQuestionFormListItem key={index}>
            <ScreeningQuestionRadio
              label={option.value}
              name={name}
              id={`${name}_${option.value}`}
              disabled={disabled}
              value={option.value}
              checked={option.selected}
            />
          </ScreeningQuestionFormListItem>
        ))}
      </ScreeningQuestionFormList>
    </ScreeningQuestionSelectBoxStyled>
  );
};

export const ApplicantFormScreeningQuestionSelectBox: React.FC<ScreeningQuestionViewProps> = (props) => {
  const { className, disabled, fieldName, fieldNameValid, options, onChange, fieldErrors, isInvalid, register } =
    useApplicantFormScreeningQuestionCheckBoxState(props);

  return (
    <ApplicantFormScreeningQuestionsControlContainer className={className} show={isInvalid} errors={fieldErrors}>
      <ScreeningQuestionFormHiddenFocusInputStyled
        type="number"
        name={fieldNameValid}
        ref={register()}
        defaultValue={0}
        tabIndex={-1}
        readOnly
      />
      <ScreeningQuestionFormList>
        {options.map((option, index) => (
          <ScreeningQuestionFormListItem key={index}>
            <ScreeningQuestionFormInputV2
              type="radio"
              id={`${fieldName}_${option.value}`}
              name={fieldName}
              disabled={disabled}
              value={option.value}
              checked={option.selected}
              onChange={onChange}
            />
            <ScreeningQuestionFormLabelV2 htmlFor={`${fieldName}_${option.value}`} $disabled={disabled}>
              {option.value}&nbsp;
            </ScreeningQuestionFormLabelV2>
          </ScreeningQuestionFormListItem>
        ))}
      </ScreeningQuestionFormList>
    </ApplicantFormScreeningQuestionsControlContainer>
  );
};
