/* eslint-disable sonarjs/cognitive-complexity */
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

import { AUTH_CONFIG } from 'config';

import { consoleWarnForDevEnv } from 'utils/consoleErrorForDevEnv';

import { history } from 'store/history';

import { ApplicationName } from './auth.constants';

export class AuthorizeService {
  private _callbacks: any[] = [];
  private _nextSubscriptionId = 0;
  private _user: User | void | null | undefined = null;
  private _isAuthenticated = false;
  private userManager: UserManager | undefined;

  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.
  private _popUpDisabled = true;

  async isAuthenticated() {
    const user = await this.getUser();
    return !!user;
  }

  async getUser() {
    if (this._user && this._user.profile) {
      return this._user.profile;
    }

    await this.ensureUserManagerInitialized();
    const user = await this.userManager?.getUser();
    return user && user.profile;
  }

  async getAccessToken() {
    await this.ensureUserManagerInitialized();
    const user = await this.userManager?.getUser();
    return user && user.access_token;
  }

  async getUserManagerUser() {
    await this.ensureUserManagerInitialized();
    return this.userManager?.getUser();
  }

  // We try to authenticate the user in three different ways:
  // 1) We try to see if we can authenticate the user silently. This happens
  //    when the user is already logged in on the IdP and is done using a hidden iframe
  //    on the client.
  // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
  //    redirect flow.
  async signIn(state?: any) {
    await this.ensureUserManagerInitialized();
    try {
      const silentUser = await this.userManager?.signinSilent(this.createArguments());
      this.updateState(silentUser);
      return this.success(state);
    } catch (silentError) {
      // User might not be authenticated, fallback to popup authentication
      consoleWarnForDevEnv('Silent authentication error: ', silentError);

      try {
        if (this._popUpDisabled) {
          throw new Error(
            "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it.",
          );
        }

        const popUpUser = await this.userManager?.signinPopup(this.createArguments());
        this.updateState(popUpUser);
        return this.success(state);
      } catch (popUpError) {
        if (popUpError instanceof Error) {
          if (popUpError.message === 'Popup window closed') {
            // The user explicitly cancelled the login action by closing an opened popup.
            return this.error('The user closed the window.');
          } else if (!this._popUpDisabled) {
            consoleWarnForDevEnv('Popup authentication error: ', popUpError);
          }
        }

        // PopUps might be blocked by the user, fallback to redirect
        try {
          await this.userManager?.signinRedirect(this.createArguments(state));
          return this.redirect();
        } catch (redirectError) {
          if (typeof redirectError === 'string') {
            consoleWarnForDevEnv('Redirect authentication error: ', redirectError);
            return this.error(redirectError);
          }
        }
      }
    }
  }

  async completeSignIn(url?: string) {
    try {
      await this.ensureUserManagerInitialized();
      const user = await this.userManager?.signinCallback(url);
      this.updateState(user);
      return this.success(user && user.state);
    } catch (error) {
      consoleWarnForDevEnv('There was an error signing in: ', error);
      return this.error('There was an error signing in.');
    }
  }

  // We try to sign out the user in two different ways:
  // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
  //    post logout redirect flow.
  async signOut(state?: any) {
    await this.ensureUserManagerInitialized();
    try {
      if (this._popUpDisabled) {
        throw new Error(
          "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it.",
        );
      }

      await this.userManager?.signoutPopup(this.createArguments());
      this.updateState(null);
      return this.success(state);
    } catch (popupSignOutError) {
      consoleWarnForDevEnv('Popup signout error: ', popupSignOutError);
      try {
        // We check if the user is sign out on the server.
        const user = await this.userManager?.getUser();
        if (!!user) {
          await this.userManager?.signoutRedirect(this.createArguments(state));
          return this.redirect();
        }
        return this.redirect();
      } catch (redirectSignOutError) {
        if (typeof redirectSignOutError === 'string') {
          consoleWarnForDevEnv('Redirect signout error: ', redirectSignOutError);
          return this.error(redirectSignOutError);
        }
      }
    }
  }

  async completeSignOut(url?: string) {
    await this.ensureUserManagerInitialized();
    try {
      const response = await this.userManager?.signoutCallback(url);
      this.updateState(null);
      return this.success(response && (response as any).data);
    } catch (error) {
      if (typeof error === 'string') {
        consoleWarnForDevEnv(`There was an error trying to log out '${error}'.`);
        return this.error(error);
      }
    }
  }

  updateState(user: User | void | null | undefined) {
    this._user = user;
    this._isAuthenticated = !!this._user;
    this.notifySubscribers();
  }

  subscribe(callback: any) {
    this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
    return this._nextSubscriptionId - 1;
  }

  unsubscribe(subscriptionId: any) {
    const subscriptionIndex = this._callbacks
      .map((element, index) => (element.subscription === subscriptionId ? { found: true, index } : { found: false }))
      .filter((element) => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
    }

    this._callbacks.splice(subscriptionIndex[0].index!, 1);
  }

  notifySubscribers() {
    for (const item of this._callbacks) {
      const callback = item.callback;
      callback();
    }
  }

  createArguments(state?: any) {
    return { useReplaceToNavigate: true, state };
  }

  error(message: string) {
    return { status: AuthenticationResultStatus.Fail, message };
  }

  success(state: any) {
    return { status: AuthenticationResultStatus.Success, state };
  }

  redirect() {
    return { status: AuthenticationResultStatus.Redirect };
  }

  private async ensureUserManagerInitialized() {
    if (this.userManager !== undefined) {
      return;
    }

    const userStore = new WebStorageStateStore({
      prefix: ApplicationName,
    });

    const settings: UserManagerSettings = {
      ...AUTH_CONFIG,
      userStore,
      automaticSilentRenew: true,
      includeIdTokenInSilentRenew: true,
    };

    this.userManager = new UserManager(settings);

    await this.userManager.clearStaleState();

    this.userManager.events.addUserSignedOut(async () => {
      await this.userManager!.removeUser();
      this.updateState(undefined);
      history.push(`/`);
    });
  }

  static get instance() {
    return authService;
  }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
  Redirect: 'redirect',
  Success: 'success',
  Fail: 'fail',
};

export const QueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message',
};

export const LogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out',
};

export const LoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
  LoginFailed: 'login-failed',
  Profile: 'profile',
  Register: 'register',
};
