import React from 'react';
import styled from 'styled-components';

import { ReactComponent as PlusIcon } from 'assets/img/plus-bold.svg';

import { Badge } from 'components/Badge';

interface AddBadgeProps {
  className?: string;
  label?: string;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
}

const AddBadgePure: React.FC<AddBadgeProps> = ({ className, onClick, label = 'New' }) => {
  return (
    <Badge variant="default" className={className} onClick={onClick}>
      <PlusIcon className="mr-1" />
      {label}
    </Badge>
  );
};

export const AddBadge = styled(AddBadgePure)`
  font-size: 11px;
  display: flex;
  align-items: center;
  font-weight: normal;
  min-width: auto;
  cursor: pointer;
`;
