import { JobBoardItemResponse } from 'api-endpoints/job-board/models';

import { pipe } from 'utils/funcs';

import { JobBoard } from 'store/entities/job-boards/models';

const assignJobBoardModelKeys = (jobBoard: JobBoardItemResponse): JobBoard =>
  Object.assign(
    {
      id: '',
      name: '',
      logo: '',
      url: '',
    },
    jobBoard,
  );

const makeLogoUrl = (jobBoard: JobBoard): JobBoard => ({
  ...jobBoard,
  logoUrl: jobBoard.logo,
});

export const jobBoardToState = (jobBoard: JobBoardItemResponse): JobBoard =>
  pipe(jobBoard, assignJobBoardModelKeys, makeLogoUrl);
