import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';

const domainFormTalentPool = (state: RootState) => state.formTalentPool;

export const selectedTalentPoolsIds = createSelector(domainFormTalentPool, (state) => state.selectedTalentPoolsIds);

export const candidateTalentPoolIds = createSelector(domainFormTalentPool, (state) => state.candidateTalentPoolIds);

export const talentPoolIdsForSelect = createSelector(candidateTalentPoolIds, selectedTalentPoolsIds, (c, s) => [
  ...c,
  ...s,
]);

export const talentPoolApiErrorsSelector = createSelector(domainFormTalentPool, (state) => state.apiErrors);
