import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { ValidationErrorCodes } from 'model/api-errors.constants';

import { PublicJob } from 'pages/public/state/public-jobs/public-jobs.model';

import { adapterHelper } from 'utils/reducer/adapter-helper';

import { SortDirections } from 'store/constants';

const publicJobsAdapter = createEntityAdapter<PublicJob>({
  selectId: (job) => job.jobId,
});

export enum ConfirmationStatus {
  'none',
  'confirm',
  'withdraw',
}

type PublicJobBEFilter = {
  name: string;
  options: string[];
};

const publicJobsInitialState = publicJobsAdapter.getInitialState({
  currentJobId: null as null | PublicJob['jobId'],
  pageNo: 0,
  pageCount: 0,
  totalItemsCount: 0,
  pageSize: 0,
  filters: {
    employmentType: undefined,
    countryCode: undefined,
    locations: [],
    searchTerm: undefined,
    orderBy: undefined,
    orderDir: SortDirections.asc,
  } as Record<string, any>,
  filter: [] as PublicJobBEFilter[],
  loading: true,
  confirmationLoading: true,
  confirmationStatus: ConfirmationStatus.none,
  validationErrorCodes: null as null | ValidationErrorCodes[],
});

const publicJobsSlice = createSlice({
  name: 'publicJobs',
  initialState: publicJobsInitialState,
  reducers: {
    ...adapterHelper(publicJobsAdapter),
    updateState: (
      state,
      { payload }: PayloadAction<Partial<Omit<typeof publicJobsInitialState, 'ids' | 'entities'>>>,
    ) => ({ ...state, ...payload }),
    updateFilters: (state, { payload }: any) => {
      state.pageNo = 0;
      state.pageCount = 0;
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
  },
});

const publicJobsReducer = publicJobsSlice.reducer;

export { publicJobsReducer, publicJobsSlice, publicJobsAdapter, publicJobsInitialState };
