import * as yup from 'yup';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';

type useYupValidationCallbackSchema = yup.AnySchema;

/**
 *
 * @param {yup.Schema} schema
 * @param cb
 */
export function useYupValidationCallback<T>(schema: useYupValidationCallbackSchema, cb: (value: T) => void) {
  const dispatch = useAppDispatch();

  return async (value: T) => {
    try {
      await schema.validate(value);
      cb(value);
    } catch (err) {
      (err as yup.ValidationError).errors.forEach((error) => {
        dispatch(alertsEffects.showError({ message: error }));
      });
    }
  };
}
