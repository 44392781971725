/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ExRoutes } from 'router/routes';
import * as yup from 'yup';
import styled from 'styled-components/macro';

import { InterviewState } from 'model/api-enums.constants';

import type {
  ExInterviewForm,
  InterviewSettingsModalProps,
} from 'containers/Modals/ModalsContent/Interview/InterviewSettingsModal/InterviewSettingsModalProps';
import { modalsActions, modalsSelectors } from 'containers/Modals/store';

import { Form } from 'components/Form';
import { FormInput } from 'components/FormInput/FormInput';
import { FormSelect } from 'components/FormSelect';
import { Spinner } from 'components/Spinner';
import { ExDialog, ExDialogContent } from 'components/ui/ExDialog';
import { ExVisible } from 'components/ui/ExVisible';
import { loaderManagerActions } from 'modules/LoaderManager/redux';
import {
  getInterviewTemplateAsSelectOption,
  InterviewTemplateSelectOption,
} from 'utils/getInterviewTemplateAsSelectOption';
import { useWorkflow } from 'utils/hooks/hiringPipelineStage';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getTranslate } from 'utils/i18utils';

import { applicantApi } from 'store/entities/applicants/applicant.api';
import {
  useInterviewCreateMutation,
  useInterviewQuery,
  useInterviewUpdateMutation,
} from 'store/entities/interview/interview.api';
import { useJobInterviewTemplateListQuery } from 'store/entities/job-interview-template/job-interview-template.api';
import { jobsSelectors } from 'store/entities/jobs/selectors';

const interviewSchema = yup
  .object({
    jobApplicantId: yup.string().required().label('Select Applicant'),
    interviewTemplateId: yup.string().required().label('Select Interview Template'),
    name: yup.string().required().min(3).label('Name'),
  })
  .defined();

const InterviewSettingsModalStyled = styled(ExDialog)`
  padding-left: 75px;
  padding-right: 75px;

  ${Form} {
    width: 100%;
    max-width: 520px;
    flex-flow: row wrap;
  }
`;

const useInterviewSettingsModalState = ({ onClose }: InterviewSettingsModalProps) => {
  const dispatch = useAppDispatch();

  const interviewId = useAppSelector(modalsSelectors.modalInterviewId);
  const history = useHistory();

  const isEditForm = Boolean(interviewId);
  const { data: interview, isFetching: isFetchingInterview } = useInterviewQuery(interviewId ?? skipToken);
  const isPublished = interview?.state === InterviewState.Saved;

  const content: ExDialogContent = {
    title: isEditForm ? getTranslate('Edit Interview Settings') : getTranslate('New Interview Settings'),
    withTitle: true,
    withActions: true,
    buttonCancel: getTranslate('Cancel'),
    buttonOkProps: { disabled: isPublished },
    buttonOk: isEditForm ? getTranslate('Save') : getTranslate('Create'),
  };

  const formInputLabels = {
    jobApplicantId: getTranslate('Select Applicant'),
    interviewTemplateId: getTranslate('Select Interview Template'),
    name: getTranslate('Interview Name'),
  };
  const jobId = useAppSelector(modalsSelectors.modalJobId);
  const applicantId = useAppSelector(modalsSelectors.modalApplicantId);
  const isShowApplicantSelect = Boolean(!applicantId);

  const { data: interviewTemplates, isLoading: isInterviewTemplateListLoading } = useJobInterviewTemplateListQuery(
    jobId && !isEditForm ? { jobId, includePipelineTemplates: true } : skipToken,
  );

  const interviewTemplatesAsOptions =
    interviewTemplates?.map((template) => getInterviewTemplateAsSelectOption(template)) ?? [];

  const singleOptionForInterview = interviewTemplatesAsOptions?.find(
    (option) => option.interviewTemplateId === interview?.interviewTemplateId,
  ) || {
    interviewTemplateId: interview?.interviewTemplateId || '',
    interviewTemplateName: interview?.interviewTemplateName || '',
    isDisabled: false,
  };

  const interviewTemplatesOptions =
    isEditForm && interview?.interviewTemplateName ? [singleOptionForInterview] : interviewTemplatesAsOptions;

  const noInterviewTemplates = !isInterviewTemplateListLoading && interviewTemplates && interviewTemplates?.length < 1;
  const interviewTemplateSelectPlaceholder = noInterviewTemplates
    ? 'No published interview templates added to this job'
    : '';

  useWorkflow(jobId!);

  const applicants = useAppSelector(jobsSelectors.getApplicants, { id: jobId! });

  const defaultValues: Partial<ExInterviewForm> = {
    jobApplicantId: applicantId || undefined,
    ...interview,
  };

  const [addNewInterviewTemplateToJob, { isSuccess: isSuccessCreate }] = useInterviewCreateMutation();
  const [updateInterview, { isSuccess: isSuccessUpdate }] = useInterviewUpdateMutation();
  const isSuccess = [isSuccessCreate, isSuccessUpdate].some(Boolean);

  const submitHandler: SubmitHandler<ExInterviewForm> = async (data) => {
    const requestData = {
      additionalText: '',
      interviewTemplateId: data.interviewTemplateId,
      applicantId: data.jobApplicantId,
      name: data.name,
      state: data.state ?? InterviewState.Draft,
    };

    dispatch(loaderManagerActions.start('saveInterview'));
    if (interviewId) {
      await updateInterview({ ...requestData, jobApplicantId: requestData.applicantId, interviewId });
    } else {
      try {
        const { interviewId: createdInterviewId } = await addNewInterviewTemplateToJob(requestData).unwrap();
        if (jobId && data.jobApplicantId && createdInterviewId) {
          history.push(
            ExRoutes.applicantInterview({
              jobId,
              applicantId: data.jobApplicantId,
              interviewId: createdInterviewId,
            }),
          );
        }
      } catch (e) {
        // do nothing
      }
    }
    dispatch(loaderManagerActions.stop('saveInterview'));
  };

  const closeHandler = (result: boolean | null) => {
    if (result) {
      document.getElementById('interviewSettingsModal')?.click();
      return;
    }
    onClose();
  };

  const [getApplicantLazy] = applicantApi.useLazyGetApplicantQuery();

  useEffect(() => {
    if (isSuccess) {
      onClose();
      if ([applicantId, jobId].every(Boolean)) {
        getApplicantLazy({ applicantId: applicantId!, jobId: jobId! });
      }
    }
  }, [applicantId, dispatch, getApplicantLazy, isSuccess, jobId, onClose]);

  useEffect(() => {
    return () => {
      dispatch(modalsActions.setInterviewId({ interviewId: null }));
    };
  }, [dispatch]);

  return {
    content,
    closeHandler,
    submitHandler,
    formInputLabels,
    interviewTemplatesOptions,
    isInterviewTemplateListLoading,
    defaultValues,
    applicants,
    isFetchingInterview,
    isEditForm,
    isShowApplicantSelect,
    isPublished,
    noInterviewTemplates,
    interviewTemplateSelectPlaceholder,
  } as const;
};

export const InterviewSettingsModal: React.FC<InterviewSettingsModalProps> = (props) => {
  const {
    content,
    closeHandler,
    submitHandler,
    formInputLabels,
    interviewTemplatesOptions,
    isInterviewTemplateListLoading,
    defaultValues,
    applicants,
    isFetchingInterview,
    isEditForm,
    isShowApplicantSelect,
    isPublished,
    noInterviewTemplates,
    interviewTemplateSelectPlaceholder,
  } = useInterviewSettingsModalState(props);

  return (
    <InterviewSettingsModalStyled content={content} onClose={closeHandler}>
      <>
        <ExVisible visible={isFetchingInterview}>
          <Spinner />
        </ExVisible>
        <ExVisible visible={!isFetchingInterview}>
          <Form onSubmit={submitHandler} validationSchema={interviewSchema} defaultValues={defaultValues}>
            {!isShowApplicantSelect && <Form.InputHidden name="jobApplicantId" />}
            <ExVisible visible={isShowApplicantSelect}>
              <FormSelect
                className="col-12"
                required
                name="jobApplicantId"
                isSearchable={false}
                label={formInputLabels.jobApplicantId}
                options={applicants}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.applicantId}
                isDisabled={isPublished || isEditForm}
              />
            </ExVisible>
            <FormSelect
              className="col-12"
              required
              name="interviewTemplateId"
              label={formInputLabels.interviewTemplateId}
              placeholder={interviewTemplateSelectPlaceholder}
              options={interviewTemplatesOptions}
              isLoading={isInterviewTemplateListLoading}
              getOptionLabel={(option) => option.interviewTemplateName}
              getOptionValue={(option) => option.interviewTemplateId}
              isDisabled={isPublished || isEditForm || noInterviewTemplates}
              isOptionDisabled={(option: InterviewTemplateSelectOption) => option.isDisabled}
            />

            <FormInput
              className="col-12"
              required
              name="name"
              label={formInputLabels.name}
              readOnly={isPublished || isEditForm}
            />
            <input hidden type="submit" id="interviewSettingsModal" />
          </Form>
        </ExVisible>
      </>
    </InterviewSettingsModalStyled>
  );
};
