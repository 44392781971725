import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useFormContext } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';
import Select, { ActionMeta } from 'react-select';
import isEqual from 'lodash/isEqual';
import xorBy from 'lodash/xorBy';

import { FilterOption, Location } from 'model';
import { AvailabilityType, CandidateSource } from 'model/api-enums.constants';

import { DoNotHireCheckbox } from 'containers/CandidateLists/CandidatesFilters/DoNotHireCheckbox';
import { candidateListActions, candidateListSelectors } from 'containers/CandidateLists/store';
import { talentPoolAutocomplete } from 'containers/TalentPoolLists/store/effects';

import { FilterCheckbox, FilterTagSelect } from 'components/Filter';
import { FormCard } from 'components/FormCard';
import { FormDate } from 'components/FormDate';
import { FormMultiCheckbox } from 'components/FormMultiCheckbox';
import { FormTagSelect } from 'components/FormTagSelect';
import { useAppSelectStyles } from 'components/FormTagSelect/FormTagSelectStyles';
import { KeywordSelect, KeywordSelectOption } from 'components/KeywordSelect/KeywordSelect';
import { ExFiltersSideModal } from 'components/ui/ExFiltersSideModal';
import { ExVisible } from 'components/ui/ExVisible';
import { useAvailabilityTypeOption } from 'utils/hooks/formsHooks/useAvailabilityTypeOption';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { usePrevious } from 'utils/hooks/usePrevious';

import { CandidateFiltersNames } from 'store/entities/candidates/models';
import { RootState } from 'store/rootReducer';

type CandidatesFiltersProps = {
  listId: string;
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: () => void;
  showTalentPoolFilter?: boolean;
  talentPoolId?: string;
};

const sourceOptions: Array<FilterOption<CandidateSource>> = [
  { label: 'Other', value: CandidateSource.Other },
  { label: 'JobBoards', value: CandidateSource.JobBoards },
  { label: 'TalentPool', value: CandidateSource.TalentPool },
  { label: 'Website', value: CandidateSource.Website },
  { label: 'SocialMedia', value: CandidateSource.SocialMedia },
];

const COUNTRIES_FIELD = 'countries';

const CandidatesFiltersModal: React.FC<CandidatesFiltersProps & PropsFromRedux> = ({
  listId,
  isOpen,
  closeModal,
  onSubmit,
  filter,
  filters,
  updateFilters,
  clearFilters,
  showTalentPoolFilter,
  talentPoolId,
}) => {
  const dispatch = useAppDispatch();
  const styles = useAppSelectStyles(['control', 'multiValue', 'multiValueLabel', 'multiValueRemove', 'valueContainer']);
  const emptyState = {
    options: [] as Array<FilterOption>,
    values: [] as Array<any>,
  };
  const availabilitiesOptions = useAvailabilityTypeOption();

  type FilterKeys = keyof Pick<
    typeof filters,
    | CandidateFiltersNames.keywords
    | CandidateFiltersNames.availabilities
    | CandidateFiltersNames.availabilityDate
    | CandidateFiltersNames.talentPool
    | CandidateFiltersNames.sources
  >;

  type Error = {
    message: string;
  };

  type ErrorState = {
    validated: boolean;
    errors?: Error;
  };

  type Errors = {
    [key in FilterKeys]: ErrorState;
  };

  const emptyError = {
    validated: false,
  };

  const emptyErrorState: Record<FilterKeys, typeof emptyError> = {
    talentPool: emptyError,
    keywords: emptyError,
    sources: emptyError,
    availabilities: emptyError,
    availabilityDate: emptyError,
  };

  const initialFormState: Record<FilterKeys, typeof emptyState> = {
    keywords: emptyState,
    availabilities: {
      options: availabilitiesOptions,
      values: [],
    },
    availabilityDate: emptyState,
    talentPool: emptyState,
    sources: {
      options: sourceOptions,
      values: [],
    },
  };

  const [values, setValues] = useState<Record<string, typeof emptyState>>(initialFormState);
  const [locations, setLocations] = useState<Partial<Location>[]>([]);
  const [countries, setCountries] = useState([] as any);
  const [keywords, setKeywords] = useState<KeywordSelectOption[]>([]);
  const [talentPools, setTalentPools] = useState<FilterOption[]>([]);
  const [errors, setErrors] = useState<Errors>(emptyErrorState);
  const [doNotHire, setDoNotHire] = useState(true);

  const { watch } = useFormContext();
  const countriesValue = watch(COUNTRIES_FIELD);
  const countriesValuePrev = usePrevious(countriesValue) || countriesValue;

  useEffect(() => {
    if (isEqual(countriesValue, countriesValuePrev)) {
      return;
    }

    setCountries(countriesValue);
  }, [countriesValue, countriesValuePrev]);

  const countriesOptions = useMemo(() => {
    if (!filter) {
      return [];
    }

    return filter.find(({ name }) => name === 'countries')?.options || [];
  }, [filter]);

  useEffect(() => {
    const formFilters = Object.entries(filters).reduce((acc, [key, value]) => {
      if (value === undefined || !Object.keys(initialFormState).includes(key)) return acc;
      const arrayFilterValues = Array.isArray(value) ? value : [value];
      const formValues = arrayFilterValues.map(
        (filterValue) =>
          values[key].options.find((option: FilterOption) => option.value === filterValue) || {
            value: filterValue,
            label: filterValue,
          },
      );
      return {
        ...acc,
        [key]: {
          options: values[key].options,
          values: formValues,
        },
      };
    }, values);
    setValues(Object.assign(values, formFilters));
    setLocations(filters?.locations ?? []);
    setCountries(filters?.countries ?? []);
    setKeywords(filters?.keywords ?? []);
    setTalentPools(filters?.talentPool ?? []);
    setDoNotHire(filters.doNotHire === undefined || filters.doNotHire === null);
    // eslint-disable-next-line
  }, [filters, isOpen]);

  const handleSubmitForm: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const availabilityDate = values?.availabilityDate?.values[0]?.value;

    if (!availabilityDate && isSpecificDate) {
      setErrors({
        ...errors,
        [CandidateFiltersNames.availabilityDate]: {
          errors: {
            message: 'Please specify availability date',
          },
          validated: true,
        },
      });
      return;
    }

    updateFilters({
      id: listId,
      filters: {
        locations,
        countries,
        keywords,
        talentPool: talentPools,
        availabilities: values.availabilities.values?.map((option: FilterOption) => option.value),
        availabilityDate: availabilityDate && new Date(availabilityDate).toISOString(),
        sources: values.sources.values?.map((option: FilterOption) => option.value),
        doNotHire: doNotHire && null,
      },
    });
    onSubmit();
  };

  const dateRef = React.createRef<HTMLInputElement>();

  const handleChange = useCallback(
    (filterName: keyof typeof initialFormState) => (newValue: any) => {
      setValues({
        ...values,
        [filterName]: {
          ...values[filterName],
          values: newValue,
        },
      });
    },
    [values],
  );

  const changeTalentPoolHandler = (newValue: any, { action }: ActionMeta<any>) => {
    const changedTalentPools = Array.isArray(newValue) ? newValue : [newValue];
    setTalentPools(action === 'clear' ? [] : changedTalentPools);
  };

  const searchJobsByNameHandler = (searchTerm: string) =>
    dispatch(talentPoolAutocomplete(searchTerm)).then((data) => data?.map((i) => ({ value: i, label: i })));

  const isSpecificDate = useMemo(
    () => values.availabilities.values.some((option: FilterOption) => option.value === AvailabilityType.ActualDate),
    [values.availabilities.values],
  );

  const handleKeywordChange = useCallback((changedKeywords: readonly KeywordSelectOption[]) => {
    setKeywords([...changedKeywords]);
  }, []);

  const handleDateChange = (value: string | null) => {
    setErrors({
      ...errors,
      [CandidateFiltersNames.availabilityDate]: {
        validated: false,
      },
    });
    handleChange('availabilityDate' as FilterKeys)([{ value }]);
  };

  const clearFiltersHandler = () => {
    clearFilters({ id: listId });
    setValues(initialFormState);
    setKeywords([]);
    onSubmit();
  };

  return (
    <ExFiltersSideModal
      title="Candidate Filter"
      isOpen={isOpen}
      onClearFilters={clearFiltersHandler}
      onApplyFilters={handleSubmitForm}
      closeModal={closeModal}
    >
      <DoNotHireCheckbox {...{ checked: doNotHire, listId, onChange: setDoNotHire, talentPoolId, className: 'mb-4' }} />
      <Form.Group>
        <FormCard.InputLabel>Keywords</FormCard.InputLabel>
        <KeywordSelect onChange={handleKeywordChange} value={keywords} isCandidateList />
      </Form.Group>
      <ExVisible visible={Boolean(showTalentPoolFilter)}>
        <Form.Group>
          <FormTagSelect
            label="Talent Pool"
            overrideStyles={styles}
            isMulti
            cacheOptions
            isClearable
            name="talentPool"
            placeholder=""
            components={{
              Placeholder: () => null,
              IndicatorSeparator: null,
              DropdownIndicator: null,
            }}
            value={talentPools}
            onChange={changeTalentPoolHandler}
            loadOptions={searchJobsByNameHandler}
          />
        </Form.Group>
        <Form.Group>
          <FormCard.InputLabel htmlFor="sources">Source(s)</FormCard.InputLabel>
          <FilterTagSelect>
            <Select
              value={values.sources.values}
              isMulti
              options={sourceOptions}
              onChange={handleChange('sources' as FilterKeys)}
              name="sources"
            />
          </FilterTagSelect>
        </Form.Group>
      </ExVisible>
      <Form.Group>
        <FormMultiCheckbox name={COUNTRIES_FIELD} options={countriesOptions} label="Country" />
      </Form.Group>
      <Form.Group>
        <FormCard.InputLabel htmlFor="availabilities">Availability</FormCard.InputLabel>
        <div>
          {availabilitiesOptions.map((availability) => (
            <FilterCheckbox label={availability.label} key={availability.value}>
              <input
                type="checkbox"
                name={`availabilities.${availability.value}`}
                checked={values.availabilities.values?.some(
                  (option: FilterOption) => option.value === availability.value,
                )}
                onChange={() =>
                  handleChange('availabilities' as FilterKeys)(
                    xorBy(values.availabilities.values, [availability], 'value' as FilterOption['value']),
                  )
                }
              />
            </FilterCheckbox>
          ))}
        </div>
        {isSpecificDate && (
          <FormDate
            defaultValue={values.availabilityDate.values[0]?.value}
            name="availability-date"
            inputRef={dateRef}
            onChange={handleDateChange}
            errors={errors[CandidateFiltersNames.availabilityDate].errors}
            validated={errors[CandidateFiltersNames.availabilityDate].validated}
            noOverlay={true}
          />
        )}
      </Form.Group>
    </ExFiltersSideModal>
  );
};

const mapDispatch = {
  updateFilters: candidateListActions.updateFilters,
  clearFilters: candidateListActions.clearFilters,
};

const mapState = (state: RootState, own: CandidatesFiltersProps) => ({
  filter: candidateListSelectors.getFilter(state, own),
  filters: candidateListSelectors.getFilters(state, own),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const CandidatesFilters = connector(CandidatesFiltersModal);
