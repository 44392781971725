import { createAction } from '@reduxjs/toolkit';

import * as candidateApi from 'api-endpoints/candidate';

import { ExEducation } from 'model';
import { Unwrap } from 'model/utils';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { modalsActions } from 'containers/Modals/store';

import { createLoader, createRequestTypes } from 'utils/actions';
import { getTranslate } from 'utils/i18utils';

import {
  CreateCandidateEducationInnerRequestParams,
  DeleteCandidateEducationRequestParams,
  GetCandidateEducationRequestParams,
  UpdateCandidateEducationInnerRequestParams,
} from './api/requests';
import {
  CANDIDATE_EDUCATION_CREATE,
  CANDIDATE_EDUCATION_FETCH,
  CANDIDATE_EDUCATION_REMOVE,
  CANDIDATE_EDUCATION_UPDATE,
} from './candidate-education.constants';
import { candidateEducationsSlice } from './candidate-education.reducer';

//--------------------------------------------------------------------------------------------------
// GET CANDIDATE EDUCATIONS
const candidateEducationTypes = createRequestTypes(CANDIDATE_EDUCATION_FETCH);
export const candidateEducationRequest = createAction<GetCandidateEducationRequestParams>(candidateEducationTypes.LOAD);
export const candidateEducationCancel = createAction(candidateEducationTypes.CANCEL);
export const candidateEducationHandler = createLoader(
  {
    success: (data: Required<Unwrap<ReturnType<typeof candidateApi.getCandidateEducations>>['data']>) =>
      candidateEducationsSlice.actions.setAll(
        data.items.map((education) => ({ ...education, educationId: education.educationId })),
      ),
  },
  candidateEducationTypes,
);
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// POST CANDIDATE EDUCATIONS
const candidateEducationCreateTypes = createRequestTypes(CANDIDATE_EDUCATION_CREATE);
export const candidateEducationCreateRequest = createAction<CreateCandidateEducationInnerRequestParams>(
  candidateEducationCreateTypes.LOAD,
);
export const candidateEducationCreateCancel = createAction(candidateEducationCreateTypes.CANCEL);
export const candidateEducationCreateHandler = createLoader(
  {
    success: (
      data: Required<Unwrap<ReturnType<typeof candidateApi.createCandidateEducations>>['data']>,
      params: ReturnType<typeof candidateEducationCreateRequest>,
    ) => [
      candidateEducationsSlice.actions.upsertOne({ ...params.payload.data, ...data }),
      modalsActions.hideModal(),
      alertsEffects.showSuccess({ message: getTranslate('education_create_success') }),
      candidateEducationRequest({
        urlParams: { candidateId: params.payload.urlParams.candidateId },
      }),
    ],
  },
  candidateEducationCreateTypes,
);
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// PUT CANDIDATE EDUCATIONS
const candidateEducationUpdateTypes = createRequestTypes(CANDIDATE_EDUCATION_UPDATE);
export const candidateEducationUpdateRequest = createAction<UpdateCandidateEducationInnerRequestParams>(
  candidateEducationUpdateTypes.LOAD,
);
export const candidateEducationUpdateCancel = createAction(candidateEducationUpdateTypes.CANCEL);
export const candidateEducationUpdateHandler = createLoader(
  {
    success: (
      data: Required<Unwrap<ReturnType<typeof candidateApi.updateCandidateEducations>>['data']>,
      params: ReturnType<typeof candidateEducationUpdateRequest>,
    ) => {
      return [
        candidateEducationsSlice.actions.updateOne({
          id: params.payload.data.educationId,
          changes: { ...params.payload.data } as any,
        }),
        modalsActions.hideModal(),
        alertsEffects.showSuccess({ message: getTranslate('education_update_success') }),
        candidateEducationRequest({
          urlParams: { candidateId: params.payload.urlParams.candidateId },
        }),
      ];
    },
  },
  candidateEducationUpdateTypes,
);
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// DELETE CANDIDATE EDUCATIONS
const candidateEducationDeleteTypes = createRequestTypes(CANDIDATE_EDUCATION_REMOVE);
export const candidateEducationDeleteRequest = createAction<DeleteCandidateEducationRequestParams>(
  candidateEducationDeleteTypes.LOAD,
);
export const candidateEducationDeleteCancel = createAction(candidateEducationDeleteTypes.CANCEL);
export const candidateEducationDeleteHandler = createLoader(
  {
    success: (
      data: Required<Unwrap<ReturnType<typeof candidateApi.deleteCandidateEducations>>['data']>,
      params: ReturnType<typeof candidateEducationDeleteRequest>,
    ) => [
      candidateEducationsSlice.actions.removeOne(params.payload.urlParams.educationId!),
      alertsEffects.showSuccess({ message: getTranslate('education_delete_success') }),
    ],
  },
  candidateEducationDeleteTypes,
);
//--------------------------------------------------------------------------------------------------

export const candidateEducationCreate = createAction(CANDIDATE_EDUCATION_CREATE);
export const candidateEducationUpdate = createAction(CANDIDATE_EDUCATION_UPDATE);
export const candidateEducationRemove = createAction<ExEducation>(CANDIDATE_EDUCATION_REMOVE);
