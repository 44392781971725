import AvatarEditor from 'react-avatar-editor';
import styled from 'styled-components';

export const AppImageEditorStyled = styled(AvatarEditor)``;

export const AppImageScaleInput = styled.input`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
`;
