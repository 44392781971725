import React, { useEffect, useState } from 'react';

import { ListTableViewItem } from 'components/ListView';
import { ColumnMapCustom } from 'components/ListView/ListViewProps';
import { ListTableViewPlaceholder } from 'components/ListViewNew';

import { ExSortableTableContext, ExSortableTableList, renderHeader } from './components';
import { useEntitySort } from './hooks';

export const ExSortableTable = <T extends {}>({
  entityList,
  columns,
  onSort,
  sortMode: sortModeProps,
  setSortMode: setSortModeProps,
  loading,
  loadingErrorMessage,
}: {
  entityList: T[];
  columns: ColumnMapCustom<T>;
  loading?: boolean;
  loadingErrorMessage?: string;
  onSort?: (sortMode: any) => void;
  sortMode?: object;
  setSortMode?: any;
}) => {
  const [sortMode, setSortMode] = useState(sortModeProps ?? {});

  const sortedEntityList = useEntitySort(sortMode, entityList);

  useEffect(() => {
    onSort && onSort(sortMode);
  }, [sortMode, onSort]);

  const list = sortModeProps ? entityList : sortedEntityList;

  return (
    <ExSortableTableContext.Provider
      value={{
        sortMode: sortModeProps ?? sortMode,
        updateSort: setSortModeProps ?? setSortMode,
      }}
    >
      <ExSortableTableList header={renderHeader({ columns })}>
        {list.length ? (
          list.map((entity, index) => <ListTableViewItem key={index} item={entity as any} columns={columns} />)
        ) : (
          <ListTableViewPlaceholder
            colsLength={Object.keys(columns).length}
            error={loadingErrorMessage}
            loading={loading}
          />
        )}
      </ExSortableTableList>
    </ExSortableTableContext.Provider>
  );
};
