import { createAction } from '@reduxjs/toolkit';

import { CompanyInfoDto, CompanyUrlParams } from 'api-endpoints/company/models';

import { createLoader, createRequestTypes } from 'utils/actions';

import type { OnCost } from 'store/entities/onCost/onCost.types';
import { QualificationTypeModel } from 'store/entities/qualification-type';

import type { CompanyInfo } from './company.types';

const COMPANY_INFO_FETCH = 'company/info/fetch';
const COMPANY_UPDATE = 'company/update';
const COMPANY_DEFAULT_COUNTRY_UPDATE = 'company/defaultCountry/update';
const COMPANY_COUNTRY_LOAD = 'company/country/load';
const COMPANY_COUNTRY_REMOVE = 'company/country/remove';
const COMPANY_COUNTRY_UPDATE = 'company/country/update';
const COMPANY_INDUSTRY_LOAD = 'company/industry/load';
const COMPANY_INDUSTRY_UPDATE = 'company/industry/update';
const COMPANY_INDUSTRY_REMOVE = 'company/industry/remove';
const COMPANY_DATE_FORMAT_UPDATE = 'company/dateFormat/update';
const COMPANY_CURRENCY_LOAD = 'company/currency/load';
const COMPANY_CURRENCY_UPDATE = 'company/currency/update';
const COMPANY_CURRENCY_REMOVE = 'company/currency/remove';
const COMPANY_DEFAULT_CURRENCY_UPDATE = 'company/defaultCurrency/update';
const COMPANY_INTEGRATION_UPDATE = 'company/integration/update';
const COMPANY_ON_COST_UPDATE = 'company/onCst/update';
const COMPANY_ON_COST_REMOVE = 'company/onCost/remove';
const COMPANY_QUALIFICATION_TYPE_REMOVE = 'company/qualificationType/remove';
const COMPANY_QUALIFICATION_TYPE_MAP_REMOVE = 'company/qualificationType-map/remove';
const COMPANY_QUALIFICATION_TYPE_MAP_UPDATE = 'company/qualificationType/update';
const COMPANY_COMMENTS_FETCH = 'company/comments/fetch';
const COMPANY_ADMIN_TEAM_MEMBERS_FETCH = 'company/adminTeamMembers/fetch';

// GET COMPANY INFO
export const loadCompanyInfoRequest = createAction<CompanyUrlParams>(COMPANY_INFO_FETCH);
//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
// UPDATE COMPANY
const updateCompanyTypes = createRequestTypes(COMPANY_UPDATE);
export const updateCompanySuccess = createAction(updateCompanyTypes.SUCCESS);
export const updateCompanyFailure = createAction(updateCompanyTypes.FAILURE);
export const updateCompanyRequest = createAction<CompanyUrlParams & { data: Partial<CompanyInfoDto> }>(
  updateCompanyTypes.LOAD,
);
export const updateCompanyCurrenciesRequest = createAction<CompanyUrlParams & { data: Partial<CompanyInfoDto> }>(
  COMPANY_CURRENCY_LOAD,
);
export const updateCompanyCountriesRequest = createAction<CompanyUrlParams & { data: Partial<CompanyInfoDto> }>(
  COMPANY_COUNTRY_LOAD,
);
export const updateCompanyIndustriesRequest = createAction<CompanyUrlParams & { data: Partial<CompanyInfoDto> }>(
  COMPANY_INDUSTRY_LOAD,
);
export const updateCompanyCancel = createAction(updateCompanyTypes.CANCEL);
export const updateCompanyEntity = createLoader(
  {
    success: (_) => [updateCompanySuccess()],
    failure: (_) => [updateCompanyFailure()],
  },
  updateCompanyTypes,
);

//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
// UPDATE COMPANY COUNTRIES
type CompanyCountryId = CompanyInfo['countries'][number]['countryId'];

export const companyUpdateCountries = createAction(COMPANY_COUNTRY_UPDATE);

// CHANGE DEFAULT COMPANY COUNTRY
export const changeDefaultCompanyCountry = createAction<{
  countryId: CompanyCountryId;
}>(COMPANY_DEFAULT_COUNTRY_UPDATE);

// REMOVE COMPANY COUNTRY
export const removeCompanyCountry = createAction<{
  countryId: CompanyCountryId;
}>(COMPANY_COUNTRY_REMOVE);
//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
// UPDATE COMPANY CURRENCIES
type CompanyCurrencyId = CompanyInfo['currencies'][number]['id'];

export const updateCompanyCurrencies = createAction(COMPANY_CURRENCY_UPDATE);

// CHANGE DEFAULT COMPANY CURRENCY
export const changeDefaultCompanyCurrency = createAction<{
  currencyId: CompanyCurrencyId;
}>(COMPANY_DEFAULT_CURRENCY_UPDATE);

// REMOVE COMPANY CURRENCY
export const removeCompanyCurrency = createAction<{
  currencyId: CompanyCurrencyId;
}>(COMPANY_CURRENCY_REMOVE);
//------------------------------------------------------------------------------

// ------------------------------------------------------------------------------
// UPDATE COMPANY INDUSTRIES
type CompanyIndustryId = CompanyInfo['industries'][number]['industryId'];

export const updateCompanyIndustries = createAction(COMPANY_INDUSTRY_UPDATE);

// REMOVE COMPANY INDUSTRY
export const removeCompanyIndustry = createAction<{ industryId: CompanyIndustryId }>(COMPANY_INDUSTRY_REMOVE);
//------------------------------------------------------------------------------

// ------------------------------------------------------------------------------
// UPDATE COMPANY INDUSTRIES
export const updateCompanyDefaultDateFormat = createAction(COMPANY_DATE_FORMAT_UPDATE);
//------------------------------------------------------------------------------

// UPDATE COMPANY INTEGRATIONS
//------------------------------------------------------------------------------
export const updateCompanyIntegrations = createAction<{ integrationName: string }>(COMPANY_INTEGRATION_UPDATE);

//------------------------------------------------------------------------------

// UPDATE COMPANY ON_COST
export const updateCompanyOnCost = createAction<{
  onCostItem?: OnCost;
  action?: 'edit' | 'add-new';
}>(COMPANY_ON_COST_UPDATE);

//------------------------------------------------------------------------------
// REMOVE COMPANY ON_COST
export const removeCompanyOnCost = createAction<{
  onCostItem?: OnCost;
}>(COMPANY_ON_COST_REMOVE);
//------------------------------------------------------------------------------

// REMOVE COMPANY QUALIFICATION TYPES
export const removeCompanyQualificationType = createAction<{
  qualificationId: QualificationTypeModel['qualificationId'];
}>(COMPANY_QUALIFICATION_TYPE_REMOVE);
//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
// UPDATE COMPANY QUALIFICATION TYPES MAPS
export const updateCompanyQualificationTypeMaps = createAction(COMPANY_QUALIFICATION_TYPE_MAP_UPDATE);
//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
// REMOVE COMPANY QUALIFICATION TYPES MAPS
export const removeCompanyQualificationTypeMams = createAction<{ id: string }>(COMPANY_QUALIFICATION_TYPE_MAP_REMOVE);
//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
// COMPANY COMMENTS FETCH
export const loadSearchComments = createAction(COMPANY_COMMENTS_FETCH);
//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
// COMPANY COMPANY ADMIN TEAM MEMBERS FETCH
export const loadCompanyAdminTeamMembers = createAction<{ roles: string[] }>(COMPANY_ADMIN_TEAM_MEMBERS_FETCH);
//------------------------------------------------------------------------------
