import { useEffect } from 'react';

import { jobBoardListActions } from 'containers/JobBoardLists/store';
import { jobBoardFormActions, jobBoardFormSelectors } from 'containers/JobBoardsForm';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { jobBoardActions } from 'store/entities/job-boards';
import { useSelector } from 'store/rootSelectors';

export function useMountHook() {
  const dispatch = useAppDispatch();

  const selectedJobBoardId = useSelector(jobBoardFormSelectors.selectJobBoardId);

  useEffect(() => {
    if (selectedJobBoardId) {
      dispatch(jobBoardActions.get({ jobBoardId: selectedJobBoardId }));
    }
    return () => {
      dispatch(jobBoardFormActions.clearForm({}));
      dispatch(jobBoardListActions.resetState());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
