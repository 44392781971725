import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { ArrayElement } from 'model';

import { jobBoardFormSelectors } from 'containers/JobBoardsForm/state';
import { JobBoardSettingsForm } from 'containers/JobBoardsForm/state/models';

import { FormDate, FormInput, FormSelect } from 'components';
import { StepFormBody } from 'components/StepForm';
import { ExLoaderWrapper } from 'components/ui/ExLoaderWrapper';
import { getRequiredRules } from 'utils/getFormRules';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { jobBoardActions, JobBoardSettings } from 'store/entities/job-boards';
import { useSelector } from 'store/rootSelectors';

export interface JobBoardSettingsFormBodyProps extends StepFormBody {
  className?: string;
  formData?: JobBoardSettingsForm;
}

const useJobBoardSettingsFormBodyRender = () => {
  const { register, errors, formState, setValue } = useFormContext();
  const validated = formState.isSubmitted;

  const renderForm = useCallback(
    (fields?: JobBoardSettings[]) => {
      if (!fields?.length) {
        return null;
      }
      const result: JSX.Element[] = [];
      fields.forEach((field) => {
        switch (field.settingType) {
          case 'any':
          case 'number':
          case 'text':
          case 'select':
            if (field.values.length) {
              result.push(
                <FormSelect
                  placeholder={
                    field.values.find((option: ArrayElement<JobBoardSettings['values']>) => !option.key)?.value ?? ''
                  }
                  name={field.key}
                  label={field.displayName}
                  errors={errors?.postDuration}
                  validated={validated}
                  required={field.required}
                  rules={{
                    required: getRequiredRules(field),
                  }}
                  options={field.values}
                  getOptionLabel={(option: ArrayElement<JobBoardSettings['values']>) => option.value}
                  getOptionValue={(option: ArrayElement<JobBoardSettings['values']>) => option.key}
                  isOptionDisabled={(option: ArrayElement<JobBoardSettings['values']>) => !option.key}
                  openMenuOnFocus
                  defaultValue={field.value}
                  components={{
                    IndicatorSeparator: null,
                  }}
                />,
              );
            } else {
              result.push(
                <FormInput
                  name={field.key}
                  label={field.displayName}
                  type={field.settingType}
                  placeholder=""
                  required={field.required}
                  autoComplete="off"
                  errors={errors?.[field.key]}
                  validated={validated}
                  defaultValue={field.value}
                  inputRef={
                    register({
                      required: getRequiredRules(field),
                    }) as any
                  }
                  onBlur={(e: ChangeEvent<HTMLInputElement>) => setValue(field.key, e.target.value.trim())}
                />,
              );
            }
            break;
          case 'date':
            result.push(
              <FormDate
                name={field.key}
                label={field.displayName}
                required={field.required}
                rules={{
                  required: getRequiredRules(field),
                }}
                defaultValue={field.value}
              />,
            );
            break;
          default:
            result.push(
              <div className="mb-3">
                <b>{field.displayName}</b> has unknown field type: {field.settingType}
              </div>,
            );
            break;
        }
      });
      return result;
    },
    [errors, register, setValue, validated],
  );

  return {
    renderForm,
  };
};

const JobBoardSettingsFormBodyPure: React.FC<JobBoardSettingsFormBodyProps> = ({ className, formData }) => {
  const { register } = useFormContext();
  const { renderForm } = useJobBoardSettingsFormBodyRender();

  const jobBoard = useSelector(jobBoardFormSelectors.selectJobBoard);
  const jobSettings = jobBoard?.settings;
  const dispatch = useAppDispatch();
  const jobBoardId = formData?.jobBoardId;
  const loadSettings = useCallback(async () => {
    if (jobBoardId) {
      dispatch(jobBoardActions.getSettings({ jobBoardId }));
    }
  }, [dispatch, jobBoardId]);

  useEffect(() => {
    loadSettings();
  }, [loadSettings]);

  if (!jobSettings?.length) {
    return (
      <ExLoaderWrapper action={jobBoardActions.getSettings} style={{ marginBottom: '20px' }}>
        <div>There is no setup required to post to this job board.</div>
      </ExLoaderWrapper>
    );
  }

  return (
    <div className={className}>
      <input name="id" ref={register} hidden />
      <div className="row">
        {renderForm(jobSettings)?.map((item, i) => (
          <div className="col-12" key={i}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export const JobBoardSettingsFormBody = styled(JobBoardSettingsFormBodyPure)`
  line-height: 1;
`;
