import { getLocation, LOCATION_CHANGE, LocationChangeAction, push, replace } from 'connected-react-router';
import { Routes } from 'router';
import { all, cancel, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import * as fromApiEndpointsUser from 'api-endpoints/user';
import { UserCompany } from 'api-endpoints/user/user.model';

import { authGetUserAction } from 'containers/Auth/state/auth.actions';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { invokeApiCall, ReturnData } from 'utils/sagas';

import { loadCompanyInfoRequest } from 'store/company/company.actions';

import * as fromAuthAction from './auth.actions';
import * as authModel from './auth.model';
import { getCompanyFromQueryParams } from './auth.saga.helper';
import { authSelectors } from './auth.selectors';
import type { UserAPI } from './auth.type';

function* getCurrentUserSaga(action: ReturnType<typeof fromAuthAction.getCurrentAuthApiUserAction>) {
  yield startLoader(action);

  const companyId: ReturnType<typeof authSelectors.selectCurrentCompanyId> = yield select(
    authSelectors.selectCurrentCompanyId,
  );
  const currentApiUserCall = invokeApiCall(fromApiEndpointsUser.GetCurrentUserFunc, {
    urlParams: {
      companyId: companyId!,
    },
  });

  const [currentApiUser]: [ReturnData<typeof fromApiEndpointsUser.GetCurrentUserFunc>] = yield all([
    currentApiUserCall,
  ]);

  yield stopLoader(action);

  let userAPI: null | UserAPI = null;

  const { data: userAPIData, errorData: userAPIErrorData, message: userAPIMessage } = currentApiUser;
  if (!userAPIErrorData && !userAPIMessage && userAPIData) {
    userAPI = authModel.UserAPI(userAPIData);
  }

  yield put(fromAuthAction.authActions.update({ userAPI }));
}

function* getAuthUserCompaniesSaga(action: ReturnType<typeof fromAuthAction.getAuthUserCompaniesAction>) {
  yield startLoader(action);

  const userCompaniesResponseCall = invokeApiCall(fromApiEndpointsUser.getCurrentUserCompaniesFunc);

  const [userCompaniesResponse]: [ReturnData<typeof fromApiEndpointsUser.getCurrentUserCompaniesFunc>] = yield all([
    userCompaniesResponseCall,
  ]);

  yield stopLoader(action);

  let companies: UserCompany[] = [];

  const { data: dataCompanies, errorData: companyErrorData, message: companyMessage } = userCompaniesResponse;
  if (!companyErrorData && !companyMessage && dataCompanies) {
    companies = dataCompanies.companies;
  }

  yield all([
    put(fromAuthAction.authActions.update({ companies })),
    put(fromAuthAction.companyFromQueryParamsAction({ companies })),
  ]);
}

function* getCompanyFromQueryParamsSaga(action: ReturnType<typeof fromAuthAction.companyFromQueryParamsAction>) {
  const { companies } = action.payload;
  const company = getCompanyFromQueryParams(companies);

  if (company) {
    yield put(fromAuthAction.authActions.update({ company, companyId: company.id }));
  }
}

/**
 * Company switch saga
 *
 * @param {ReturnType<typeof fromAuthAction.authCompanySwitchAction>} action
 */
function* authCompanySwitchSaga(action: ReturnType<typeof fromAuthAction.authCompanySwitchAction>) {
  const companyId = action.payload.companyId;
  const redirectTo = action.payload.redirectTo;

  yield put(fromAuthAction.authActions.setLoading(true));

  yield put(fromAuthAction.authActions.update({ companyId }));

  if (redirectTo) {
    yield put(push({ pathname: redirectTo }));
  } else {
    yield delay(1000);
    window.location.href = '/';
  }
}

/**
 * Function for load user auth data
 *
 */
function* appInitDataSaga() {
  const companyId = yield select(authSelectors.selectCurrentCompanyId);

  yield all([
    put(loadCompanyInfoRequest({ urlParams: { companyId } })),
    put(fromAuthAction.getCurrentAuthApiUserAction()),
    put(authGetUserAction()),
    put(fromAuthAction.authActions.setLoading(false)),
  ]);
}

function* locationChangedWorker(action: LocationChangeAction) {
  if (action.payload.isFirstRendering) {
    const location = yield select(getLocation);
    const query = location.query;
    const companyId = query.companyId;
    if (companyId) {
      yield put(fromAuthAction.authActions.setLoading(true));
      yield put(fromAuthAction.authActions.update({ companyId }));
      yield put(replace({ search: '' }));
    }
  }
  yield put(fromAuthAction.authActions.authCompanyCancelWatcherAction);
}

function* authLogoutActionWorker() {
  yield put(push({ pathname: `/${Routes.logout}`, state: { local: true } }));
}

/* Auth Watcher Function */
function* authTaskWatcher() {
  yield takeEvery(LOCATION_CHANGE, locationChangedWorker);
}

export function* authSagaWorkers() {
  yield takeLatest(fromAuthAction.getCurrentAuthApiUserAction, getCurrentUserSaga);
  yield takeLatest(fromAuthAction.getAuthUserCompaniesAction, getAuthUserCompaniesSaga);
  yield takeLatest(fromAuthAction.companyFromQueryParamsAction, getCompanyFromQueryParamsSaga);
  yield takeLatest(fromAuthAction.authCompanySwitchAction, authCompanySwitchSaga);
  yield takeLatest(fromAuthAction.appInitDataAction, appInitDataSaga);

  // App logout clear store and redirect to logout page for logout from oidc
  yield takeLatest(fromAuthAction.authActions.authLogoutAction, authLogoutActionWorker);

  // Section for watch companyId in url
  const task = yield fork(authTaskWatcher);
  yield take(fromAuthAction.authActions.authCompanyCancelWatcherAction);
  yield cancel(task);
}
