import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';

import { AvailabilityType } from 'model/api-enums.constants';

import { ApplicantForm } from 'containers/ApplicantForm/ApplicantFormState/applicant-form.models';

import { FormDate } from 'components/FormDate';
import { FormSelect } from 'components/FormSelect';
import { useAvailabilityTypeOption } from 'utils/hooks/formsHooks/useAvailabilityTypeOption';

type ApplicantModalAvailabilityInfoEditProps = {
  className?: string;
};

const ApplicantModalAvailabilityInfoEditStyled = styled.div``;

const useApplicantModalAvailabilityInfoEditState = ({ className }: ApplicantModalAvailabilityInfoEditProps) => {
  const { errors, formState, watch } = useFormContext<ApplicantForm>();
  const validated = formState.isSubmitted;

  const availabilityTypeOptions = useAvailabilityTypeOption();
  const availability = watch('jobApplicantAvailability');
  const availabilityChosen = availability?.availabilityType === AvailabilityType.ActualDate;

  return {
    className,
    errors,
    validated,
    availabilityTypeOptions,
    availabilityChosen,
  } as const;
};

export const ApplicantModalAvailabilityInfoEdit: React.FC<ApplicantModalAvailabilityInfoEditProps> = (props) => {
  const { className, errors, validated, availabilityTypeOptions, availabilityChosen } =
    useApplicantModalAvailabilityInfoEditState(props);

  return (
    <ApplicantModalAvailabilityInfoEditStyled className={className}>
      <FormSelect
        label="Availability"
        name="jobApplicantAvailability.availabilityType"
        placeholder=""
        errors={errors?.jobApplicantAvailability?.['availabilityType']}
        validated={validated}
        options={availabilityTypeOptions}
        isSearchable={false}
        isClearable
      />
      {availabilityChosen && (
        <FormDate
          label="Specific Date"
          name="jobApplicantAvailability.actualDate"
          min={new Date()}
          defaultValue={new Date().toISOString().split('T')[0]}
          errors={errors?.jobApplicantAvailability?.['actualDate']}
          validated={validated}
        />
      )}
    </ApplicantModalAvailabilityInfoEditStyled>
  );
};
