import React, { useCallback, useMemo } from 'react';

import { InterviewTemplateState } from 'model/api-enums.constants';

import { EnumLiteralsOf } from 'utils/funcs';

import {
  useInterviewTemplateListLoadMoreQuery,
  useLazyInterviewTemplateListLoadMoreQuery,
} from 'store/entities/interview-template/interview-template.api';

export function useInterviewTemplateSearch({ pageSize }: { pageSize?: number }) {
  const [interviewsTemplateFilters, setInterviewsTemplateFilters] = React.useState<{
    pageNo: number;
    searchTerm?: string;
    state: EnumLiteralsOf<typeof InterviewTemplateState>;
    pageSize?: number;
  }>({
    pageNo: 0,
    searchTerm: undefined,
    state: InterviewTemplateState.Saved,
    pageSize,
  });

  const { data } = useInterviewTemplateListLoadMoreQuery({
    searchTerm: interviewsTemplateFilters.searchTerm,
    pageNo: 0,
    state: InterviewTemplateState.Saved,
    pageSize,
  });
  const interviewTemplatesOptions = useMemo(() => (data ? data.ids.map((id) => data.entities[id]!) : []), [data]);

  const [loadNextPage, { isFetching: isFetchingCurrentPage }] = useLazyInterviewTemplateListLoadMoreQuery();

  const loadMoreInterviewTemplates = useCallback(() => {
    if (!isFetchingCurrentPage) {
      const nextPageNo = interviewsTemplateFilters.pageNo + 1;
      const pageCount = data?.pageCount ?? 0;
      const newFilters = {
        ...interviewsTemplateFilters,
        pageNo: nextPageNo <= pageCount ? nextPageNo : pageCount,
      };

      setInterviewsTemplateFilters(newFilters);
      loadNextPage(newFilters);
    }
  }, [data?.pageCount, interviewsTemplateFilters, isFetchingCurrentPage, loadNextPage]);

  const onInputInterviewTemplatesChange = useCallback(
    (newSearchTerm: string) => {
      const newFilters = { ...interviewsTemplateFilters, searchTerm: newSearchTerm || undefined, pageNo: 0 };
      setInterviewsTemplateFilters(newFilters);
      loadNextPage(newFilters);
    },
    [interviewsTemplateFilters, loadNextPage],
  );

  return {
    interviewTemplatesOptions,
    onInputInterviewTemplatesChange,
    loadMoreInterviewTemplates,
    isFetchingCurrentPage,
  } as const;
}
