import React from 'react';
import styled, { DefaultTheme } from 'styled-components';

import { ReactComponent as CircleClose } from 'assets/img/circle-x.svg';

import { Badge } from 'components/Badge';
import { Ellipsis } from 'components/Ellipsis';
import { pluralize } from 'utils/pluralize';

export interface ListViewSelectedItemsProps<T = any> {
  className?: string;
  entityName: string;
  selectedEntities: Array<T>;
  handleItemRemove: (item: T) => any;
  getItemValue: (item: T) => string | undefined;
  getItemId: (item: T) => string;
}

const ListViewSelectedItems: React.FC<ListViewSelectedItemsProps> = ({
  className,
  entityName,
  selectedEntities,
  handleItemRemove,
  getItemValue,
  getItemId,
}) => {
  return (
    <div className={className}>
      Selected {pluralize(entityName, selectedEntities.length)}:
      {selectedEntities.map((item) => {
        const name = getItemValue(item);
        return (
          name && (
            <SelectedName variant="primary" key={getItemId(item)}>
              <Ellipsis>{name}</Ellipsis>
              <StyledCircleClose variant="white" onClick={handleItemRemove(item)} />
            </SelectedName>
          )
        );
      })}
    </div>
  );
};

export default styled(ListViewSelectedItems)`
  display: flex;
  flex-shrink: 0;
  min-width: 100px;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 20px;
  flex-wrap: nowrap;
  align-items: center;
  margin-right: auto;

  @media screen and (max-width: 1200px) {
    padding: 15px 0 0;
    order: 12;
    flex-basis: 100%;
  }
`;

const StyledCircleClose = styled(CircleClose)<{ variant: keyof DefaultTheme['colors'] }>`
  height: 11px;
  width: 11px;
  margin-left: 5px;
  cursor: pointer;
  flex-shrink: 0;
  fill: ${(props) => props.theme.colors[props.variant]};

  &:hover {
    fill: ${(props) => props.theme.colors['danger']};
  }
`;

const SelectedName = styled(Badge)`
  max-width: 200px;
  display: flex;
  margin-left: 0.5em;
`;
