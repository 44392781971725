import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Option } from 'react-select/src/filters';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled from 'styled-components';

import { IntegrationSettings } from 'api-endpoints/company/models';

import { ErrorDTO } from 'model/api-errors.constants';

import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import {
  ExModalBodyStyled,
  ExModalInputRow,
  ExModalLabel,
  ExModalRow,
  ExModalWrapperStyled,
} from 'containers/Modals/ModalsContent/Company/ExModalComponents';

import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { DialogProps } from 'components/Dialog';
import { FormTagSelect } from 'components/FormTagSelect';
import { Spinner } from 'components/Spinner';
import { ExButton } from 'components/ui/ExButton';
import { ExDialogActions } from 'components/ui/ExDialog';
import { ExSwitchButton } from 'components/ui/ExSwitchButton';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { updateCompanyIntegrations } from 'store/company/company.actions';
import { exModalConfirmAction } from 'store/modals/modals.actions';

type PrefetchDataType<T> = {
  loading: boolean;
  error: ErrorDTO | null;
  data: T | null;
};

type CompanyUpdateIntegrationsProps = {
  id: string;
  modalProps?: PrefetchDataType<IntegrationSettings>;
};

const CompanyUpdateIntegrationFormBody = styled.div`
  min-height: 100px;
`;

const syncNewApplicationsOptions = [
  {
    label: 'Sync data every 2 hours',
    value: '02:00:00',
  },
  {
    label: 'Sync data every 12 hours',
    value: '12:00:00',
  },
  {
    label: 'Sync data every 24 hours',
    value: '23:59:59',
  },
];

const syncNewPostJobAdOptions = [
  {
    label: 'After 2 minutes',
    value: '00:02:00',
  },
  {
    label: 'After 5 minutes',
    value: '00:05:00',
  },
  {
    label: 'After an hour',
    value: '01:00:00',
  },
];

const afterJobDeadlineLimitOptions = [
  {
    label: '30 days',
    value: '30.00:00:00',
  },
  {
    label: '60 days',
    value: '60.00:00:00',
  },
  {
    label: '90 days',
    value: '90.00:00:00',
  },
  {
    label: 'Until the ad is expired',
    value: null,
  },
];

type CompanyUpdateIntegrationsType = {
  blockDuplicateApplicationsEnabled: boolean;
  synchronization: {
    jobApplicantsForJobAdScheduler: string;
    postingJobAdScheduler: string;
    afterJobDeadlineLimit: string | null;
  };
};

const companyUpdateIntegrationsSchema: yup.SchemaOf<CompanyUpdateIntegrationsType> = yup.object().shape({
  blockDuplicateApplicationsEnabled: yup.boolean().required(),
  synchronization: yup.object().shape({
    jobApplicantsForJobAdScheduler: yup.string().required().trim().label('Schedule Sync Applications'),
    postingJobAdScheduler: yup.string().required().trim().label('Schedule Post New Job Ad'),
    afterJobDeadlineLimit: yup
      .string()
      .nullable()
      .defined()
      .trim()
      .label('Receive applications from job board after jobs are closed in'),
  }),
});

export const CompanyUpdateIntegrations = ({
  id,
  onClose,
  modalProps,
}: CompanyUpdateIntegrationsProps & AppModalContentProps) => {
  const dispatch = useAppDispatch();
  const { loading, data } = modalProps || {};

  const { handleSubmit, errors, formState, control, setValue } = useForm({
    resolver: yupResolver(companyUpdateIntegrationsSchema),
  });

  useEffect(() => {
    if (data) {
      setValue(
        'synchronization.afterJobDeadlineLimit',
        afterJobDeadlineLimitOptions.find((option) => option.value === data?.synchronization.afterJobDeadlineLimit)
          ?.value,
      );
      setValue(
        'synchronization.jobApplicantsForJobAdScheduler',
        syncNewApplicationsOptions.find(
          (option) => option.value === data?.synchronization.jobApplicantsForJobAdScheduler,
        )?.value,
      );
      setValue(
        'synchronization.postingJobAdScheduler',
        syncNewPostJobAdOptions.find((option) => option.value === data?.synchronization.postingJobAdScheduler)?.value,
      );
      setValue('blockDuplicateApplicationsEnabled', data.blockDuplicateApplicationsEnabled);
    }
  }, [data, setValue]);

  const content: DialogProps['content'] = {
    title: 'Idibu Settings',
    withTitle: true,
  };

  const onSubmitClickHandler = (formData: CompanyUpdateIntegrationsType) => {
    dispatch(
      exModalConfirmAction({
        id,
        modalResult: formData,
      }),
    );
  };

  return (
    <ExModalWrapperStyled onClose={onClose} content={content}>
      <ExModalBodyStyled>
        <CompanyUpdateIntegrationFormBody>
          {loading && <Spinner />}
          {!loading && data && (
            <form onSubmit={handleSubmit(onSubmitClickHandler)} id="idibu-settings">
              <ExModalRow>
                <ExModalLabel>Job Application Settings</ExModalLabel>
                <ExModalInputRow>
                  <Controller
                    name="blockDuplicateApplicationsEnabled"
                    control={control}
                    render={({ value, onChange }) => {
                      return <ExSwitchButton value={value} onChange={onChange} />;
                    }}
                  />
                  <span className="ml-3">Block duplicate applications</span>
                </ExModalInputRow>
              </ExModalRow>
              <ExModalRow>
                <ExModalLabel>Receive applications from job board after jobs are closed in</ExModalLabel>
                <Controller
                  name="synchronization.afterJobDeadlineLimit"
                  control={control}
                  render={({ value, onChange }) => {
                    return (
                      <FormTagSelect
                        placeholder=""
                        defaultOptions={afterJobDeadlineLimitOptions}
                        name="afterJobDeadlineLimit"
                        isMulti={false}
                        isClearable
                        value={afterJobDeadlineLimitOptions.find((option) => option.value === value)}
                        errors={errors.afterJobDeadlineLimit}
                        validated={formState.isSubmitted}
                        onChange={(afterJobDeadlineLimitOption: Option) =>
                          onChange(afterJobDeadlineLimitOption?.value || null)
                        }
                      />
                    );
                  }}
                />
              </ExModalRow>
              <ExModalRow>
                <ExModalLabel>Schedule Sync Applications</ExModalLabel>
                <Controller
                  name="synchronization.jobApplicantsForJobAdScheduler"
                  control={control}
                  render={({ value, onChange }) => {
                    return (
                      <FormTagSelect
                        placeholder="Select time interval"
                        defaultOptions={syncNewApplicationsOptions}
                        name="jobApplicantsForJobAdScheduler"
                        isMulti={false}
                        value={syncNewApplicationsOptions.find((option) => option.value === value)}
                        errors={errors.jobApplicantsForJobAdScheduler}
                        validated={formState.isSubmitted}
                        onChange={(syncNewApplicationsOption: Option) => onChange(syncNewApplicationsOption.value)}
                      />
                    );
                  }}
                />
              </ExModalRow>
              <ExModalRow>
                <ExModalLabel>Schedule Post New Job Ad</ExModalLabel>
                <Controller
                  name="synchronization.postingJobAdScheduler"
                  control={control}
                  render={({ value, onChange }) => {
                    return (
                      <FormTagSelect
                        placeholder="Select time interval"
                        defaultOptions={syncNewPostJobAdOptions}
                        value={syncNewPostJobAdOptions.find((option) => option.value === value)}
                        name="postingJobAdScheduler"
                        isMulti={false}
                        errors={errors.postingJobAdScheduler}
                        validated={formState.isSubmitted}
                        onChange={(syncNewPostJobAdOption: Option) => onChange(syncNewPostJobAdOption.value)}
                      />
                    );
                  }}
                />
              </ExModalRow>
            </form>
          )}
        </CompanyUpdateIntegrationFormBody>
        <ExDialogActions>
          <ExButton variant="light" onClick={onClose}>
            Cancel
          </ExButton>
          <ExLoaderHandlerWrapper action={updateCompanyIntegrations}>
            <ExButton className="ml-3" type="submit" form="idibu-settings">
              Save
            </ExButton>
          </ExLoaderHandlerWrapper>
        </ExDialogActions>
      </ExModalBodyStyled>
    </ExModalWrapperStyled>
  );
};
