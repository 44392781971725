import { createAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';

import * as fromCompanyHiringPipelinesApiModels from 'api-endpoints/company-hiring-pipelines/models';

import { fieldSorter } from 'utils/funcs';
import { adapterHelper } from 'utils/reducer/adapter-helper';

import { EntityKeysNew } from 'store/constants';
import { extendedHiringPipelineApi, hiringPipelineAdapter } from 'store/entities/hiring-pipelines/hiring-pipelines.api';
import type {
  HiringPipeline,
  HiringPipelineDefaultFilters,
} from 'store/entities/hiring-pipelines/hiring-pipelines.types';
import { jobsSelectors } from 'store/entities/jobs/selectors';
import { createActionApiCallBatch } from 'store/entities/qualification-type/qualification-type.model';
import { RootState } from 'store/rootReducer';
import { entitiesDomain } from 'store/rootSelectors';

const hiringPipelinesAdapter = createEntityAdapter<HiringPipeline>({
  selectId: (item) => item.pipelineId,
  sortComparer: fieldSorter([
    { prop: 'isDefault', direction: 'dsc' },
    { prop: 'isInUse', direction: 'dsc' },
    { prop: 'isValid', direction: 'dsc' },
    { prop: 'name', toLowerCase: true },
  ]),
});

const getDefaultFilters: () => HiringPipelineDefaultFilters = () => ({});

const hiringPipelinesSlice = createSlice({
  name: EntityKeysNew.hiringPipeline,
  initialState: hiringPipelinesAdapter.getInitialState({
    filters: getDefaultFilters(),
    singlePipelineId: '',
  }),
  reducers: {
    ...adapterHelper(hiringPipelinesAdapter),
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setSinglePipelineId(state, action) {
      state.singlePipelineId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (matchedAction) => {
        const removeQueryAction = extendedHiringPipelineApi.internalActions.removeQueryResult({} as any);

        return (
          matchedAction.type === removeQueryAction.type && matchedAction.payload?.queryCacheKey.includes('getPipeline')
        );
      },
      (draft) => {
        if (draft.singlePipelineId) {
          hiringPipelinesSlice.caseReducers.setSinglePipelineId(
            draft,
            hiringPipelinesSlice.actions.setSinglePipelineId(''),
          );
        }
      },
    );
  },
});

// ACTIONS -----------------------------------------------------------------------------------------
const HIRING_PIPELINE_REMOVE = `${EntityKeysNew.hiringPipeline}/remove`;
const HIRING_PIPELINE_CLONE = `${EntityKeysNew.hiringPipeline}/clone`;
const HIRING_PIPELINE_RENAME = `${EntityKeysNew.hiringPipeline}/rename`;
const HIRING_PIPELINE_EDIT = `${EntityKeysNew.hiringPipeline}/edit`;
const HIRING_PIPELINE_CREATE = `${EntityKeysNew.hiringPipeline}/create`;
const HIRING_PIPELINE_UPDATE = `${EntityKeysNew.hiringPipeline}/update`;
const HIRING_PIPELINE_ADD_NEW = `${EntityKeysNew.hiringPipeline}/addNew`;
const HIRING_PIPELINE_REORDER = `${hiringPipelinesSlice.name}/reorder`;
const HIRING_PIPELINE_MAKE_DEFAULT = `${EntityKeysNew.hiringPipeline}/default`;
const HIRING_PIPELINE_ENABLE = `${EntityKeysNew.hiringPipeline}/enable`;
const HIRING_PIPELINE_DISABLE = `${EntityKeysNew.hiringPipeline}/disable`;

const companyHiringPipelineRemove = createActionApiCallBatch<{ item: HiringPipeline }>(HIRING_PIPELINE_REMOVE);
const companyHiringPipelineClone = createActionApiCallBatch<{ item: HiringPipeline }>(HIRING_PIPELINE_CLONE);
const companyHiringPipelineUpdate =
  createActionApiCallBatch<fromCompanyHiringPipelinesApiModels.AssignPipelineStagesFuncParams>(HIRING_PIPELINE_UPDATE);
const addNew = createAction(HIRING_PIPELINE_ADD_NEW);
const create = createAction<Partial<HiringPipeline>>(HIRING_PIPELINE_CREATE);
const remove = createAction<HiringPipeline['pipelineId']>(HIRING_PIPELINE_REMOVE);
const rename = createAction<HiringPipeline['pipelineId']>(HIRING_PIPELINE_RENAME);
const clone = createAction<HiringPipeline['pipelineId']>(HIRING_PIPELINE_CLONE);
const edit = createAction<HiringPipeline['pipelineId']>(HIRING_PIPELINE_EDIT);
const reorder = createAction<{ pipelineId: string; pipelineStageIdsOrder: string[] }>(HIRING_PIPELINE_REORDER);
const makeDefault = createAction<HiringPipeline['pipelineId']>(HIRING_PIPELINE_MAKE_DEFAULT);
const enable = createAction<HiringPipeline['pipelineId']>(HIRING_PIPELINE_ENABLE);
const disable = createAction<HiringPipeline['pipelineId']>(HIRING_PIPELINE_DISABLE);

export const hiringPipelineActions = {
  ...hiringPipelinesSlice.actions,
  companyHiringPipelineRemove,
  companyHiringPipelineClone,
  companyHiringPipelineUpdate,
  addNew,
  create,
  remove,
  rename,
  clone,
  edit,
  reorder,
  makeDefault,
  enable,
  disable,
};

//--------------------------------------------------------------------------------------------------

// REDUCER -----------------------------------------------------------------------------------------
export const hiringPipelinesReducer = hiringPipelinesSlice.reducer;

// SELECTORS ---------------------------------------------------------------------------------------
const domain = createSelector(entitiesDomain, (state) => state[hiringPipelinesSlice.name]);

export const selectFilters = createSelector(domain, (state) => state.filters);

const getPipelineSelectorWithParams = createSelector(selectFilters, (filters) =>
  extendedHiringPipelineApi.endpoints.searchPipeline.select(filters),
);

const searchPipelineResult = createSelector(
  (state) => state,
  getPipelineSelectorWithParams,
  (state: any, selector) => {
    return selector(state);
  },
);

const searchPipelineData = createSelector(searchPipelineResult, (result) => {
  return result?.data?.pipeline;
});

const selectPipelineIdByJobId = createSelector(jobsSelectors.getById, (job) => {
  return job?.pipelineId;
});

const selectSinglePipelineId = createSelector(domain, (state) => state.singlePipelineId);

const selectPipelineIdForGetPipelineQuery = createSelector(
  selectSinglePipelineId,
  selectPipelineIdByJobId,
  (_, pipelineId) => pipelineId,
  (singlePipelineId, jobPipelineId, paramPipelineId) => {
    return jobPipelineId || singlePipelineId || paramPipelineId;
  },
);

const getPipelineQuerySelectorWithParams = createSelector(selectPipelineIdForGetPipelineQuery, (pipelineId) => {
  return extendedHiringPipelineApi.endpoints.getPipeline.select({ pipelineId });
});

const selectGetPipelineQuery = createSelector(
  (state) => state,
  getPipelineQuerySelectorWithParams,
  (state: any, selector) => {
    return selector(state);
  },
);

const selectGetPipelineQueryData = createSelector(selectGetPipelineQuery, (query: any) => {
  return query?.data;
});

const selectGetPipelineQueryPipeline = createSelector(selectGetPipelineQueryData, (data) => {
  return data?.pipeline;
});

const selectGetPipelineQueryPipelineStages = createSelector(selectGetPipelineQueryData, (data) => {
  return data?.pipelineStages;
});

const hiringPipelinesAdapterSelectors = hiringPipelineAdapter.getSelectors((state: RootState, ...props: any[]) => {
  const searchPipelineState = searchPipelineData(state);
  const getPipelineState = selectGetPipelineQueryPipeline(state, props[0]);

  return searchPipelineState ?? getPipelineState ?? hiringPipelineAdapter.getInitialState();
});

const checkIsDefaultPipeline = createSelector(hiringPipelinesAdapterSelectors.selectById, (state) => state?.isDefault);
const checkIsInUsePipeline = createSelector(hiringPipelinesAdapterSelectors.selectById, (state) => state?.isInUse);

const checkIsDefaultOrInUsePipeline = createSelector(
  [checkIsDefaultPipeline, checkIsInUsePipeline],
  (isDefault, isInUse) => isDefault || isInUse,
);

const checkIsDisabledPipeline = createSelector(
  hiringPipelinesAdapterSelectors.selectById,
  (state) => state?.isDisabled,
);

const selectPipelineByJobId = createSelector(
  (state: RootState) => state,
  selectPipelineIdByJobId,
  (state, pipelineId) => hiringPipelinesAdapterSelectors.selectById(state, pipelineId ?? ''),
);

const selectFirstStageButtonLabelByJobId = createSelector(
  selectPipelineByJobId,
  (pipeline) => pipeline?.firstStageButtonLabel || pipeline?.defaultFirstStageButtonLabel,
);

const selectFirstStageButtonLabelByPipelineId = createSelector(
  hiringPipelinesAdapterSelectors.selectById,
  (pipeline) => pipeline?.firstStageButtonLabel,
);

const selectDeclineButtonLabelByJobId = createSelector(
  selectPipelineByJobId,
  (pipeline) => pipeline?.declineButtonLabel || 'Decline',
);

const selectDeclineButtonLabelByPipelineId = createSelector(
  hiringPipelinesAdapterSelectors.selectById,
  (pipeline) => pipeline?.declineButtonLabel,
);

const selectJobPipeline = createSelector(
  hiringPipelinesAdapterSelectors.selectAll,
  jobsSelectors.getById,
  (pipelines, job) => pipelines.find((pipeline) => pipeline.pipelineId === job?.pipelineId),
);

const selectPipelinesExcludeByIds = createSelector(
  hiringPipelinesAdapterSelectors.selectAll,
  (state: RootState, pipelineIds: string[]) => pipelineIds,
  (pipelines, pipelineIdsToExclude) =>
    pipelines.filter((pipeline) => !pipelineIdsToExclude.includes(pipeline.pipelineId)),
);

const selectDefaultPipeline = createSelector(hiringPipelinesAdapterSelectors.selectAll, (pipelines) =>
  pipelines.find((pipeline) => pipeline.isDefault),
);

const selectValidHiringPipelinesForJob = createSelector(hiringPipelinesAdapterSelectors.selectAll, (pipelines) => {
  return pipelines
    .filter((pipeline) => pipeline.isValid)
    .sort(fieldSorter([{ prop: 'name', direction: 'asc', toLowerCase: true }]));
});

export const hiringPipelineSelectors = {
  ...hiringPipelinesAdapterSelectors,
  checkIsDefaultOrInUsePipeline,
  checkIsDefaultPipeline,
  checkIsInUsePipeline,
  selectPipelineIdByJobId,
  selectFirstStageButtonLabelByJobId,
  selectFirstStageButtonLabelByPipelineId,
  selectDeclineButtonLabelByJobId,
  selectDeclineButtonLabelByPipelineId,
  selectJobPipeline,
  selectPipelinesExcludeByIds,
  selectFilters,
  searchPipelineResult,
  selectDefaultPipeline,
  selectValidHiringPipelinesForJob,
  checkIsDisabledPipeline,

  selectSinglePipelineId,
  selectGetPipelineQuery,
  selectGetPipelineQueryData,
  selectGetPipelineQueryPipeline,
  selectGetPipelineQueryPipelineStages,
};
