import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ExRoutes } from 'router/routes';
import styled from 'styled-components';

import { AppRouterParams } from 'model/router';

import { Badge, BadgeDoNotHireNoIcon } from 'components/Badge';
import { CandidateAvatar } from 'components/CandidateAvatar';
import { CandidateNotEditedBadge } from 'components/CandidateSummary/CandidateSummary/components/CandidateNotEditedBadge';
import { EllipsisWithTooltip } from 'components/EllipsisWithTooltip';
import { ExVisible } from 'components/ui/ExVisible';
import { getUserInitials } from 'utils/getUserInitials';

import { Candidate } from 'store/entities/candidates/models';

const BadgeDoNotHireStyled = styled(BadgeDoNotHireNoIcon)`
  margin-right: 10px;
`;

interface CandidateCardProps<
  T extends Pick<Candidate, 'photoUrl' | 'fullName' | 'candidateId' | 'doNotHire' | 'resumeExtracted'> = Pick<
    Candidate,
    'photoUrl' | 'fullName' | 'candidateId' | 'doNotHire' | 'resumeExtracted'
  >,
> {
  className?: string;
  candidate: T;
  preventClick?: boolean;
}

const CandidateListCandidateRow: React.FC<CandidateCardProps> = ({ candidate, className, preventClick }) => {
  const { talentPoolId } = useParams<AppRouterParams>();

  const modalPath = useMemo(() => {
    return ExRoutes.talentPoolCandidatePage({ talentPoolId, candidateId: candidate.candidateId });
  }, [talentPoolId, candidate]);

  const userInitials = getUserInitials(`${candidate?.fullName || ''}`);

  return (
    <div className={className}>
      <div className="candidate-row__avatar">
        <CandidateAvatar src={candidate.photoUrl} initials={userInitials} />
      </div>
      <EllipsisWithTooltip label={candidate.fullName as string}>
        <>
          {React.createElement(
            !preventClick ? Link : 'span',
            { to: modalPath, className: 'candidate-row__name' },
            candidate.fullName,
          )}
          <ExVisible visible={!!candidate.doNotHire}>
            <BadgeDoNotHireStyled />
          </ExVisible>
          <ExVisible visible={candidate.resumeExtracted}>
            <CandidateNotEditedBadge />
          </ExVisible>
        </>
      </EllipsisWithTooltip>
    </div>
  );
};

export default styled(CandidateListCandidateRow)`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .candidate-row__avatar {
    margin-right: 12px;
    flex: 0 0 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
    border-radius: 100%;

    img {
      display: block;
      width: 100%;
      max-width: 100%;
      border-radius: 100%;
    }
  }

  .candidate-row__name {
    display: inline-block;
    color: ${(props) => props.theme.colors.blackHeadings};
    margin-bottom: 0;
    width: 195px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${Badge} {
    margin-left: auto;
    height: 16px;
    min-width: 43px;
    padding: 1px 8px;
  }
`;
