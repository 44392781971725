import styled from 'styled-components';

import { ExDialog } from 'components/ui/ExDialog';

export const EditJobAdDetailsModalStyled = styled(ExDialog)`
  max-width: 800px;
  width: 100%;
  form {
    width: 100%;
  }
`;
