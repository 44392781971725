import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import { rbacConstants } from 'containers/Auth/AuthMatrix';
import { RBAC } from 'containers/Auth/components/RBAC';

import { ListViewSelectedBar, ListViewSelectedItems } from 'components/ListView';
import ListViewToggle from 'components/ListView/ListViewToggle';
import { safariFlexBoxTweak } from 'utils/styled/common';

import { Candidate } from 'store/entities/candidates/models';
import { RootState } from 'store/rootReducer';

import CandidateListFilter from './CandidateListFilter/CandidateListFilter';
import { CandidateListComponentProps } from './CandidateListProps';
import { candidateListActions, candidateListSelectors } from './store';

const CandidateListHeader: React.FC<CandidateListComponentProps & PropsFromRedux> = ({
  listId,
  className,
  showTalentPoolFilter,
  selectedEntities,
  toggleItem,
}) => {
  const handleCandidateRemove = (candidate: Candidate) => () => {
    if (listId && candidate.id) {
      toggleItem({ id: listId, selectedItemId: candidate.id });
    }
  };

  return (
    <div className={className}>
      <RBAC feature={rbacConstants.candidates.view}>
        <CandidateListFilter listId={listId} showTalentPoolFilter={showTalentPoolFilter} />
      </RBAC>
      <ListViewSelectedItems
        entityName="candidate"
        handleItemRemove={handleCandidateRemove}
        selectedEntities={selectedEntities}
        getItemId={(item: Candidate) => item.id}
        getItemValue={(item: Candidate) => item.fullName || [item.firstName || '--', item.lastName || '--'].join(', ')}
      />
    </div>
  );
};

const mapDispatch = {
  selectAll: candidateListActions.selectAllItems,
  toggleItem: candidateListActions.toggleItemSelect,
  updateFilters: candidateListActions.updateFilters,
};

const mapState = (state: RootState, own: CandidateListComponentProps) => ({
  filters: candidateListSelectors.getFilters(state, own),
  selected: candidateListSelectors.getSelected(state, own),
  selectedEntities: candidateListSelectors.getSelectedEntities(state, own),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default styled(connector(CandidateListHeader))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  ${safariFlexBoxTweak};

  .list-filter-container {
    margin-bottom: 4px;
  }

  .align-right {
    display: flex;
    flex-direction: row;
    margin-left: 0;
    align-items: center;
  }

  ${ListViewSelectedBar} {
    margin-right: 15px;
  }

  ${ListViewToggle} {
    margin-left: 20px;
  }

  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;
