import { useCallback, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';

import { DropZoneErrors } from 'components/AppImagePickerDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

export function useBulkDropZone() {
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<File[]>([]);

  const isShowSelectedFiles = Boolean(files.length);

  const onRemoveFile = useCallback(
    (fileIndex) => {
      const newFiles = [...files];
      newFiles.splice(fileIndex, 1);
      setFiles(newFiles);
    },
    [files],
  );

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      fileRejections.forEach((fileRejection) => {
        fileRejection.errors.forEach((error) => {
          let message = error.message;
          if (error.code === DropZoneErrors.FILE_TOO_LARGE) {
            message = 'File is larger than 2mb';
          }
          dispatch(alertsEffects.showError({ message }));
        });
      });
    },
    [dispatch],
  );

  const onDrop = useCallback(
    (newAcceptedFiles) => {
      setFiles([...files, ...newAcceptedFiles]);
    },
    [files],
  );

  const { getRootProps, acceptedFiles, open, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: ['.pdf', '.doc', '.docx'],
    maxSize: 2e6,
    multiple: true,
    noClick: true,
    noKeyboard: true,
    onDrop,
    onDropRejected,
  });

  return {
    acceptedFiles,
    files,
    getInputProps,
    getRootProps,
    isDragAccept,
    isDragActive,
    isDragReject,
    isShowSelectedFiles,
    onRemoveFile,
    open,
  } as const;
}
