import type { HiringPipelineStage } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.types';

export const HiringPipeline = (dto: object) => ({
  pipelineId: '' as string,
  isDefault: false as boolean,
  isInUse: false as boolean,
  isInUseByJob: false as boolean,
  isValid: true as boolean,
  name: '' as string,
  stages: [] as HiringPipelineStage[],
  declineButtonLabel: null as string | null,
  defaultFirstStageButtonLabel: '' as string,
  firstStageButtonLabel: null as string | null,
  isDisabled: false as boolean,
  ...dto,
});
