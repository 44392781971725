import React from 'react';
import type { ExInterview, ExInterviewQuestion, ExInterviewTemplate, ExInterviewTemplateQuestion } from 'model';
import { useAppRouterParams } from 'router';
import styled from 'styled-components/macro';

import {
  CompanyInterviewTemplateElementStyled,
  ContentContainer,
  DnDContainer,
  getCollapsedContainerStyled,
  getExAccordionStyled,
  QuestionInfo,
  QuestionTitle,
} from 'pages/Company/CompanyTabs/CompanyInterviewTemplatesTab/CompanyInterviewTemplatesStyles';
import {
  useInterviewElementListState,
  useInterviewTemplateElementListFormState,
  useInterviewTemplateElementListState,
} from 'pages/Company/CompanyTabs/CompanyInterviewTemplatesTab/hooks/useCompanyInterviewTemplateElementListState';
import {
  useInterviewElementState,
  useInterviewTemplatesElementForFormState,
  useInterviewTemplatesElementState,
} from 'pages/Company/CompanyTabs/CompanyInterviewTemplatesTab/hooks/useCompanyInterviewTemplateElementState';

import { QuestionView } from 'components/InterviewTemplate/QuestionView';
import { Spinner } from 'components/Spinner';
import { ExCard } from 'components/ui/ExCard';
import { ExVisible } from 'components/ui/ExVisible';

const InterviewTemplatePreviewTemplateStyled = styled.div``;

const TitleContainer = styled.div`
  grid-column: 2 / 6;
  grid-row: 1;
`;

const ExCardInner = styled.div`
  ${getExAccordionStyled};
`;

const ContentContainerWrapper = styled.div`
  ${getCollapsedContainerStyled};
`;

const MandatoryElementStyled = styled.div`
  color: #e00;
  display: inline-block;
  margin-left: 4px;
`;

type MandatoryElementProps = {
  mandatory: boolean;
};

const MandatoryElement: React.FC<MandatoryElementProps> = ({ mandatory }) => {
  if (!mandatory) {
    return null;
  }

  return <MandatoryElementStyled>*</MandatoryElementStyled>;
};

type interviewContext = 'interview' | 'interviewTemplate';

type QuestionnairePreviewTemplateItemProps = {
  interviewTemplateId: ExInterview['interviewId'] | ExInterviewTemplate['interviewTemplateId'];
  interviewTemplateQuestionId:
    | ExInterviewQuestion['interviewQuestionId']
    | ExInterviewTemplateQuestion['interviewTemplateQuestionId'];
  editable?: boolean;
  index: number;
  context: interviewContext;
  className?: string;
};

const interviewTemplatePreviewTemplateItemStateList = {
  interview: useInterviewElementState,
  interviewTemplate: useInterviewTemplatesElementState,
  interviewTemplateForm: useInterviewTemplatesElementForFormState,
};

const InterviewTemplatePreviewTemplateItem: React.FC<QuestionnairePreviewTemplateItemProps> = (props) => {
  const interviewTemplatePreviewTemplateItemState = interviewTemplatePreviewTemplateItemStateList[props.context];

  const { interviewId } = useAppRouterParams();

  const { className, data, hasInfo, editable, index } = interviewTemplatePreviewTemplateItemState(props);

  if (!data) {
    return null;
  }

  const order = `${index + 1}.`;

  return (
    <CompanyInterviewTemplateElementStyled className={className}>
      <ExCard>
        <ExCardInner>
          <DnDContainer>
            <QuestionTitle>{order}</QuestionTitle>
          </DnDContainer>
          <TitleContainer>
            <QuestionTitle>
              {data.questionText}
              <MandatoryElement mandatory={!!data.isMandatory} />
            </QuestionTitle>
            <ExVisible visible={hasInfo}>
              <QuestionInfo>{data.additionalInformation}</QuestionInfo>
            </ExVisible>
          </TitleContainer>
          <ContentContainerWrapper>
            <ContentContainer>
              <QuestionView question={data} editable={editable} interviewId={interviewId} />
            </ContentContainer>
          </ContentContainerWrapper>
        </ExCardInner>
      </ExCard>
    </CompanyInterviewTemplateElementStyled>
  );
};

type applicantInterviewTemplateProps = {
  className?: string;
  editable?: boolean;
  context: interviewContext;
};

const interviewTemplatePreviewStateList = {
  interview: useInterviewElementListState,
  interviewTemplate: useInterviewTemplateElementListState,
  interviewTemplateForm: useInterviewTemplateElementListFormState,
};

export const InterviewTemplatePreview: React.FC<applicantInterviewTemplateProps> = ({
  className,
  editable,
  context,
}) => {
  const useInterviewTemplatePreviewState = interviewTemplatePreviewStateList[context];
  const { itemsIds, interviewTemplateId, isLoading } = useInterviewTemplatePreviewState();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <InterviewTemplatePreviewTemplateStyled className={className}>
      {itemsIds.map((itemId, index) => (
        <InterviewTemplatePreviewTemplateItem
          key={itemId}
          index={index}
          interviewTemplateQuestionId={itemId.toString()}
          interviewTemplateId={interviewTemplateId}
          editable={editable}
          context={context}
        />
      ))}
    </InterviewTemplatePreviewTemplateStyled>
  );
};
