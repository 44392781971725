const urlApplicantRoot = '/api/company/{companyId}/job/{jobId}/applicant/{applicantId}';

const urlCommentForJobApplicantFunc = `${urlApplicantRoot}/comment/{commentId}`;

export const ApplicantEndpoints = {
  // GET
  GetJobApplicantFunc: urlApplicantRoot,
  // POST
  GetJobApplicantOrderedInfoFunc: urlApplicantRoot,
  // PUT
  UpdateJobApplicantFunc: urlApplicantRoot,
  // POST
  AddCommentForJobApplicantFunc: `${urlApplicantRoot}/comment`,
  // GET
  SearchCommentsForJobApplicantFunc: `${urlApplicantRoot}/comment`,
  // GET
  GetCommentForJobApplicantFunc: urlCommentForJobApplicantFunc,
  // PUT
  UpdateCommentForJobApplicantFunc: urlCommentForJobApplicantFunc,
  // DELETE
  DeleteCommentForJobApplicantFunc: urlCommentForJobApplicantFunc,
  // POST
  ReadCommentsForJobApplicantFunc: `${urlApplicantRoot}/comment/read`,
  // POST
  ReadAllCommentForJobApplicantFunc: `${urlApplicantRoot}/comment/read-all`,
  // GET
  GetPreviousJobApplicationsNumberFunc: '/api/company/{companyId}/candidate/{candidateId}/exclude/{applicantId}/number',

  // GET JobApplicantEducation
  GetJobApplicantEducationFunc: `${urlApplicantRoot}/education`,
  // POST
  AddJobApplicantEducationRoute: `${urlApplicantRoot}/education`,
  // PUT
  UpdateJobApplicantEducationFunc: `${urlApplicantRoot}/education/{educationId}`,
  // DELETE
  DeleteJobApplicantEducationFunc: `${urlApplicantRoot}/education/{educationId}`,

  // GET ApplicantWorkExperience
  GetJobApplicantWorkExperienceFunc: `${urlApplicantRoot}/work-experience`,
  // POST
  AddJobApplicantWorkExperienceFunc: `${urlApplicantRoot}/work-experience`,
  // PUT
  UpdateJobApplicantWorkExperienceFunc: `${urlApplicantRoot}/work-experience/{workExperienceId}`,
  // DELETE
  DeleteJobApplicantWorkExperienceFunc: `${urlApplicantRoot}/work-experience/{workExperienceId}`,
  // PUT
  UpdateCustomSortOrderFunc: '/api/company/{companyId}/job/{jobId}/applicant-custom-sort-indexes',
} as const;

export const ApplicantEndpointsForApi = {
  // GET
  GetJobApplicantFunc: '/job/{jobId}/applicant/{applicantId}',
} as const;
