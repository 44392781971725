export const InterviewQuestionEndpoints = {
  // POST
  UploadInterviewQuestionFileFunc: '/interview/{interviewId}/question/{interviewQuestionId}/file',
  // DELETE
  DeleteInterviewQuestionFileFunc:
    '/interview/{interviewId}/question/{interviewQuestionId}/file/{interviewQuestionFileId}',
  // GET
  GetInterviewQuestionFunc: '/interview/{interviewId}/question/{interviewQuestionId}',
  // PUT
  UpdateInterviewQuestionFunc: '/interview/{interviewId}/question/{interviewQuestionId}',
  // GET
  GetInterviewQuestionsFunc: '/interview/{interviewId}/question',
} as const;
