import { createAction } from '@reduxjs/toolkit';

import { JobBoardListState } from 'containers/JobBoardLists/store';

import jobBoardSlice from './reducer';

export * from './mappers';
export * from './models';
export { jobBoardSelectors } from './selectors';

const JOB_BOARDS_GET = 'jobBoards/get';
const JOB_BOARDS_SETTINGS_GET = 'jobBoards/settings/get';
const JOB_BOARDS_ADDITIONAL_FIELDS_GET = 'jobBoards/additionalFields/get';
const JOB_BOARDS_SETTINGS_UNSUBSCRIBE = 'jobBoards/settings/unsubscribe';
const JOB_BOARDS_FETCH = 'jobBoards/fetch';

type GetAdditionalFieldsProps = {
  jobBoardId: string;
  options: {
    location?: string;
    country?: string;
    jobId?: string;
  };
};

const get = createAction<{ jobBoardId: string }>(JOB_BOARDS_GET);
const getSettings = createAction<{ jobBoardId: string }>(JOB_BOARDS_SETTINGS_GET);
const unsubscribeSettings = createAction<{ jobBoardId: string }>(JOB_BOARDS_SETTINGS_UNSUBSCRIBE);
const getAdditionalFields = createAction<GetAdditionalFieldsProps>(JOB_BOARDS_ADDITIONAL_FIELDS_GET);
const fetch = createAction<{ listId: JobBoardListState['id'] }>(JOB_BOARDS_FETCH);

export const jobBoardActions = {
  ...jobBoardSlice.actions,
  get,
  getSettings,
  unsubscribeSettings,
  getAdditionalFields,
  fetch,
};

export default jobBoardSlice.reducer;
