import { useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import { ExQuestion, ExQuestionChoices } from 'model';
import { QuestionType } from 'model/api-enums.constants';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';

import { QuestionViewProps } from 'components/InterviewTemplate/QuestionView/QuestionViewProps';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useDebounce } from 'utils/hooks/useDebounce';

import { useInterviewQuestionListQuery, useUpdateInterviewQuestionMutation } from 'store/entities/interview-question';

const MAX_CHECKBOX_CHOICES = 5;

export const useQuestionCheckBoxState = ({ className, editable, question, interviewId }: QuestionViewProps) => {
  const questionId = question.questionId;
  const { isFetching: isLoadingInterviewQuestionList } = useInterviewQuestionListQuery(interviewId ?? skipToken);
  const [updateInterviewQuestionMutation, { isLoading }] = useUpdateInterviewQuestionMutation();
  const disabled = !editable || isLoading || isLoadingInterviewQuestionList;

  const [updatedQuestion, setUpdatedQuestion] = useState<null | Partial<ExQuestion>>(null);
  const debouncedUpdatedQuestion = useDebounce(updatedQuestion, 1500);
  useEffect(() => {
    if (debouncedUpdatedQuestion && interviewId) {
      const { interviewQuestionFiles, ...clearUpdatedQuestion } = debouncedUpdatedQuestion;
      updateInterviewQuestionMutation({
        ...clearUpdatedQuestion,
        interviewQuestionId: questionId,
        interviewId,
      });
    }
  }, [debouncedUpdatedQuestion, interviewId, questionId, updateInterviewQuestionMutation]);

  const dispatch = useAppDispatch();

  const { choices: originalChoices = [], ...questionWoChoices } = question;
  const [choices, setChoices] = useState(originalChoices);

  useEffect(() => {
    setChoices(originalChoices);
  }, [originalChoices]);

  const selectedChoices = choices.filter((choice) => choice.selected).length;

  const isRadio = question.questionType === QuestionType.SelectBoxList;

  const onChange = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (selectedChoices >= MAX_CHECKBOX_CHOICES && checked) {
      dispatch(alertsEffects.showError({ message: `Maximum ${MAX_CHECKBOX_CHOICES} possible responses` }));
      return;
    }

    const choiceIndex = choices.findIndex((element) => element.value === value);

    if (choiceIndex > -1) {
      let updatedChoices = [...choices];
      if (isRadio) {
        updatedChoices = updatedChoices.map((choice, index) => {
          return {
            ...choice,
            selected: choiceIndex === index,
          };
        });
      } else {
        updatedChoices.splice(choiceIndex, 1, {
          value,
          selected: checked,
        });
      }
      setChoices(updatedChoices);
      onSubmit(updatedChoices);
    }
  };

  const onSubmit = (updatedChoices: ExQuestionChoices[]) => {
    const selectedChoicesStr = updatedChoices.filter((choice) => choice.selected).map((choice) => choice.value);

    const questionWChoices: any = {
      ...questionWoChoices,
      ...(isRadio && {
        choice: selectedChoicesStr[0],
      }),
      ...(!isRadio && {
        choices: selectedChoicesStr,
      }),
    };

    setUpdatedQuestion(questionWChoices);
  };

  return {
    className,
    disabled,
    choices,
    questionId,
    onChange,
    isLoading,
  } as const;
};
