const urlCommentForJobFunc = '/api/company/{companyId}/job/{jobId}/comment/{jobCommentId}';
export const JobEndpoints = {
  GetJobFunc: '/api/company/{companyId}/job/{jobId}',
  SearchJobsFunc: '/api/company/{companyId}/job',
  GetJobApplicantsPerPipelineStageFiguresForJobFunc:
    '/api/company/{companyId}/job/{jobId}/applicants-per-pipeline-stage-figures',
  GetJobApplicantsPerSourceFiguresForJobFunc: '/api/company/{companyId}/job/{jobId}/applicants-per-source-figures',
  GetJobApplicantsPerDayFiguresForJobFunc: '/api/company/{companyId}/job/{jobId}/applicants-per-day-figures',
  ChangeJobStatusJobFunc: '/api/company/{companyId}/job/{jobId}/{status}', // CompleteJobFunc, CancelJobFunc, OpenJobFunc
  PostJobFunc: '/api/company/{companyId}/job',
  PostOpenJobFunc: '/api/company/{companyId}/job-open',
  PutJobFunc: '/api/company/{companyId}/job/{jobId}',
  PutJobSalaryFunc: '/api/company/{companyId}/job/{jobId}/salary',
  ChangePipelineStageFunc: '/api/company/{companyId}/job/{jobId}/applicant/{applicantId}/change-stage/{stageId}',
  GetJobCountFunc: '/api/company/{companyId}/job/count',
  GetJobApplicantDetailsFunc: '/api/company/{companyId}/job/{jobId}/applicant/{applicantId}',
  BulkDeclineApplicantFunc: '/api/company/{companyId}/job/{jobId}/applicant/decline',
  ShortlistJobApplicantFunc: '/api/company/{companyId}/job/{jobId}/applicant/shortlist',

  // POST
  BulkUpdateApplicantPipelineStageFunc: '/api/company/{companyId}/job/{jobId}/applicant/stageType',
  JobsAutocompleteFunc: '/api/company/{companyId}/job/autocomplete',
  GetJobByReferenceNumberFunc: '/api/company/{companyId}/job/referenceNumber',
  JobBoardsForJobAutocompleteFunc: '/api/company/{companyId}/job/{jobId}/jobboards-autocomplete',
  SearchJobAdsForJobFunc: '/api/company/{companyId}/job/{jobId}/ad',
  SearchJobAdsForActiveJobsFunc: '/api/company/{companyId}/ad/active-job',
  SearchJobApplicantsFunc: '/api/company/{companyId}/job/{jobId}/applicant',
  GetApplicantsPerStageForJobBoardFunc: '/api/company/{companyId}/job/{jobId}/applicants-per-stage-for-job-board',
  GetApplicantsReceivedPerDayForJobFunc: '/api/company/{companyId}/job/{jobId}/applicants-received-per-day-for-job',
  GetJobDeadlineFunc: '/api/company/{companyId}/job/{jobId}/deadline',
  GetJobDaysOpenFunc: '/api/company/{companyId}/job/{jobId}/days-open',
  GetJobAcceptanceRateFunc: '/api/company/{companyId}/job/{jobId}/acceptance-rate',
  GetJobAverageTimeToHireFunc: '/api/company/{companyId}/job/{jobId}/average-time-to-hire',
  BulkApplyFromResumesFunc: '/api/company/{companyId}/job/{jobId}/apply-from-resumes',
  GetJobTeamFunc: '/api/company/{companyId}/job/{jobId}/team',
  AddMemberToJobTeamFunc: '/api/company/{companyId}/job/{jobId}/team/add-member',
  RemoveMemberFromJobTeamFunc: '/api/company/{companyId}/job/{jobId}/team/remove-member',
  // GET
  SearchCommentsForJobFunc: '/api/company/{companyId}/job/{jobId}/comment',
  // POST
  AddCommentForJobFunc: '/api/company/{companyId}/job/{jobId}/comment',
  // GET
  GetCommentForJobFunc: urlCommentForJobFunc,
  // PUT
  UpdateCommentForJobFunc: urlCommentForJobFunc,
  // DELETE
  DeleteCommentForJobFunc: urlCommentForJobFunc,
  // POST
  ReadCommentsForJobFunc: '/api/company/{companyId}/job/{jobId}/comment/read',
  // POST
  ReadAllCommentForJobFunc: '/api/company/{companyId}/job/{jobId}/comment/read-all',
  // POST
  BulkDownloadFilesFunc: '/api/company/{companyId}/job/{jobId}/file',
  //GET
  GetJobTeamMemberAutocompleteFunc: '/api/company/{companyId}/job/{jobId}/team/autocomplete',
  // PUT
  UpdateJobPipelineSettingsFunc: `/api/company/{companyId}/job/{jobId}/pipeline-settings`,
  // GET
  SearchJobChangeNotesFunc: `/api/company/{companyId}/job/{jobId}/change-note`,
};

const jobApiBase = '/job';
const jobItemApiBase = `${jobApiBase}/{jobId}`;

export const JobEndpointsForApi = {
  // GET
  SearchJobsFunc: jobApiBase,
  // GET
  GetJobCountFunc: `${jobApiBase}/count`,
  // DELETE
  DeleteJobFunc: jobItemApiBase,
  // POST
  CopyJobFunc: `${jobItemApiBase}/copy`,
  // GET
  GetJobApplicantsPerPipelineStageFiguresForJobFunc: `${jobItemApiBase}/applicants-per-pipeline-stage-figures`,
  // POST
  SearchJobApplicantsFunc: `${jobItemApiBase}/applicant`,
  GetApplicationPipelineStagesFunc: `${jobItemApiBase}/workflow`,
  // GET
  GetJobTeamFunc: `${jobItemApiBase}/team`,
  // GET
  GetJobTeamMemberAutocompleteFunc: `${jobItemApiBase}/team/autocomplete`,
  // GET
  GetJobFunc: jobItemApiBase,
} as const;
