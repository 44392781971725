import React, { useCallback, useMemo } from 'react';
import Transition from 'react-transition-group/Transition';
import styled from 'styled-components/macro';

import { CandidateAvatar } from 'components/CandidateAvatar/CandidateAvatar';
import { CandidateAvatarProps } from 'components/CandidateAvatar/CandidateAvatarTypes';
import { fadeInAnimation } from 'utils/styled/FadeTransition';

const CandidateAvatarStyled = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
`;

const CandidateUploadIconOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  transition: opacity 0.2s ease-out;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const CandidateUploadIconOverlayFade = fadeInAnimation(CandidateUploadIconOverlay);

const CandidateAvatarEditableComponent: React.FC<CandidateAvatarProps> = ({
  className,
  src,
  alt,
  children,
  candidateHasAvatar,
  initials,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  const onHoverHandler = useCallback((value) => () => setIsHovered(value), [setIsHovered]);

  const isEditable = useMemo(() => isHovered && candidateHasAvatar, [isHovered, candidateHasAvatar]);

  return (
    <CandidateAvatarStyled
      className={className}
      onMouseOver={onHoverHandler(true)}
      onMouseLeave={onHoverHandler(false)}
    >
      <CandidateAvatar src={src} alt={alt} initials={initials} />
      <Transition in={isHovered} timeout={100} unmountOnExit mountOnEnter>
        {(state) => (
          <CandidateUploadIconOverlayFade state={state}>
            {children && React.cloneElement(children, { disabled: !isEditable })}
          </CandidateUploadIconOverlayFade>
        )}
      </Transition>
    </CandidateAvatarStyled>
  );
};

export { CandidateAvatarEditableComponent };
