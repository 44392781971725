import React, { useCallback } from 'react';
import { useController } from 'react-hook-form';

import { ApplicantFormScreeningQuestionsControlContainer } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ApplicantFormScreeningQuestionsControlContainer';
import {
  ScreeningQuestionFormSwitchContainer,
  ScreeningQuestionFormSwitchLabelStyled,
  ScreeningQuestionFormSwitchOptionStyled,
  ScreeningQuestionFormSwitchRadioInputStyled,
} from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionForm/ScreeningQuestionForm';
import { ScreeningQuestionViewLoading } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionView/ScreeningQuestionViewLoading';
import { ScreeningQuestionViewProps } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionView/ScreeningQuestionViewProps';
import {
  useCreateJobScreeningQuestionYesNoState,
  useJobScreeningQuestionYesNoState,
  useScreeningQuestionYesNoState,
} from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionYesNo/hooks/useScreeningQuestionYesNoState';
import { useApplicationFormQuestionField } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/hooks/useCompanyScreeningQuestionsListState';

import { ReactComponent as IconNo } from 'assets/img/switch-no.svg';
import { ReactComponent as IconYes } from 'assets/img/switch-yes.svg';

import { FormRadioSwitch, FormRadioSwitchContainer } from 'components/FormRadio/FormRadioSwitch';
import { getTranslate } from 'utils/i18utils';

const ScreeningQuestionYesNoStateList = {
  default: useScreeningQuestionYesNoState,
  applicantModalScreeningQuestions: useScreeningQuestionYesNoState,
  jobScreeningQuestions: useJobScreeningQuestionYesNoState,
  createJobScreeningQuestions: useCreateJobScreeningQuestionYesNoState,
};

export const ScreeningQuestionYesNo: React.FC<ScreeningQuestionViewProps> = (props) => {
  const useComponentState = ScreeningQuestionYesNoStateList[props.context || 'default'];

  const { className, disabled, inputName, labels, answer, onSubmit, isLoading } = useComponentState(props);

  return (
    <>
      <ScreeningQuestionViewLoading isLoading={isLoading} />
      <FormRadioSwitchContainer className={className}>
        <FormRadioSwitch
          id={`${inputName}_yes`}
          name={inputName}
          label={labels.yes}
          disabled={disabled}
          value={'true'}
          checked={answer === true}
          onChange={onSubmit}
        />
        <FormRadioSwitch
          id={`${inputName}_no`}
          name={inputName}
          label={labels.no}
          disabled={disabled}
          value={'false'}
          checked={answer === false}
          onChange={onSubmit}
        />
      </FormRadioSwitchContainer>
    </>
  );
};

export const useApplicationFormScreeningQuestionYesNoState = (props: ScreeningQuestionViewProps) => {
  const { className } = props;

  const { fieldName, fieldErrors, disabled, isInvalid, register } = useApplicationFormQuestionField(props);

  const {
    field: { value: fieldValue, onChange: handlerChangeOption },
  } = useController({ name: fieldName });

  const yesNoOptions = [
    { value: true, label: getTranslate('interviewTemplate.questionMandatory.yes'), icon: IconYes },
    { value: false, label: getTranslate('interviewTemplate.questionMandatory.no'), icon: IconNo },
  ];

  const onChange = useCallback(
    (e) => {
      const { value } = e.target;

      handlerChangeOption(value === 'true');
    },
    [handlerChangeOption],
  );

  return {
    className,
    disabled,
    fieldName,
    fieldErrors,
    fieldValue,
    yesNoOptions,
    onChange,
    isInvalid,
    register,
  } as const;
};

export const ApplicantFormScreeningQuestionYesNo: React.FC<ScreeningQuestionViewProps> = (props) => {
  const { className, disabled, fieldName, fieldErrors, fieldValue, yesNoOptions, onChange, isInvalid, register } =
    useApplicationFormScreeningQuestionYesNoState(props);

  return (
    <ApplicantFormScreeningQuestionsControlContainer className={className} show={isInvalid} errors={fieldErrors}>
      <ScreeningQuestionFormSwitchContainer>
        {yesNoOptions.map((option, index) => (
          <ScreeningQuestionFormSwitchOptionStyled key={index}>
            <ScreeningQuestionFormSwitchRadioInputStyled
              type="radio"
              id={`${fieldName}_${option.value}`}
              name={fieldName}
              disabled={disabled}
              checked={option.value === fieldValue}
              value={option.value.toString()}
              onChange={onChange}
              ref={register()}
            />
            <ScreeningQuestionFormSwitchLabelStyled htmlFor={`${fieldName}_${option.value}`} $disabled={disabled}>
              <option.icon />
              {option.label}
            </ScreeningQuestionFormSwitchLabelStyled>
          </ScreeningQuestionFormSwitchOptionStyled>
        ))}
      </ScreeningQuestionFormSwitchContainer>
    </ApplicantFormScreeningQuestionsControlContainer>
  );
};
