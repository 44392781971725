import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

export const FormFileStyled = styled(Form.Group)``;

export const CloseButtonWrapper = styled.div`
  position: relative;

  input {
    padding-right: 30px;
    text-overflow: ellipsis;
  }
`;

export const CustomCloseButton = styled.button`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  padding: 5px;

  &:active,
  &:focus {
    outline: none;
  }
`;
