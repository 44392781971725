import { call, fork, join, put, SagaReturnType, select } from 'redux-saga/effects';

import { updateJobApplicantFunc } from 'api-endpoints/applicant';
import { getCandidateById, putCandidate } from 'api-endpoints/candidate';

import { candidates } from 'containers/Auth/AuthMatrix/authMatrix.constants';
import { updateApplicantModalAction } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/store/ApplicantModal.actions';
import { applicantModalSlice } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/store/ApplicantModal.reducer';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { invokeApiCall, ReturnData } from 'utils/sagas';

import { authSelectors } from 'store/auth/auth.selectors';
import { Applicant, applicantsActions } from 'store/entities/applicants';
import { utilsPrepareUpdateApplicantData } from 'store/entities/applicants/utils';
import { candidatesActions } from 'store/entities/candidates';
import { utilsPrepareUpdateCandidateData } from 'store/entities/candidates/candidate-sagas/utils';
import { candidateToState } from 'store/entities/candidates/mappers';
import { Candidate } from 'store/entities/candidates/models';

import { educationsUpdate, workExperiencesUpdate } from './utils';

export function* updateApplicantModalWorker(action: ReturnType<typeof updateApplicantModalAction>) {
  const formData = action.payload;
  const applicantId = action.payload.applicantId!;
  const candidateId = action.payload.candidateId!;
  const jobId = action.payload.jobId!;

  yield startLoader(action);

  const updateCandidate = yield select(authSelectors.isFeatureAllowed, { feature: candidates.editDetails });

  const educationTasks: SagaReturnType<typeof educationsUpdate> = yield fork(
    educationsUpdate,
    jobId,
    applicantId,
    formData.educations ?? [],
  );

  const workExperienceTasks: SagaReturnType<typeof workExperiencesUpdate> = yield fork(
    workExperiencesUpdate,
    jobId,
    applicantId,
    formData.workExperiences ?? [],
  );

  /**
   * Load candidate because API don't have patch methods;
   */
  const loadCandidateTask = updateCandidate ? yield fork(getCandidateById, candidateId) : null;

  /**
   * Update applicant data
   */
  const { data: applicantFormData }: SagaReturnType<typeof utilsPrepareUpdateApplicantData> = yield call(
    utilsPrepareUpdateApplicantData,
    applicantId,
    {
      ...(formData as Applicant),
    },
  );
  const updateApplicantTask = yield fork(invokeApiCall, updateJobApplicantFunc, {
    data: { ...applicantFormData, educations: [] } as Required<typeof applicantFormData>,
    urlParams: {
      applicantId,
      jobId,
    },
  });

  const tasks = [updateApplicantTask, educationTasks, workExperienceTasks];

  /**
   * Wait while candidate task end
   */
  if (updateCandidate) {
    const { data: candidateFromApi }: SagaReturnType<typeof getCandidateById> = yield join(loadCandidateTask);
    const candidate = candidateToState({ ...candidateFromApi, id: candidateId });

    yield put(candidatesActions.upsertOne({ item: candidate }));

    /**
     * Update Candidate Data;
     */
    const { data: candidateFormData }: SagaReturnType<typeof utilsPrepareUpdateCandidateData> = yield call(
      utilsPrepareUpdateCandidateData,
      candidateId,
      {
        ...(formData as Candidate),
      },
    );
    const putCandidateTask = yield fork(invokeApiCall, putCandidate, {
      ...(candidateFormData as Required<typeof candidateFormData>),
      candidateId,
    });
    tasks.push(putCandidateTask);
  }

  const result: [ReturnData<typeof updateJobApplicantFunc>, ReturnData<typeof putCandidate>] = yield join(tasks);

  const hasErrors = result.flat(2).some((item) => {
    if (item.errorData) {
      return true;
    }

    return false;
  });

  if (hasErrors) {
    const apiErrors: string[] = [];

    result.forEach((item) => {
      if (item.errorData && item.errorData.validationErrorCodes) {
        apiErrors.push(...item.errorData.validationErrorCodes);
      }
    });

    yield put(applicantModalSlice.actions.set({ apiErrors }));
  }

  if (!hasErrors) {
    yield put(applicantsActions.applicantsModalFetchAction({ applicantId, jobId }));
    yield put(applicantsActions.updateOne({ ...formData, id: applicantId }));
  }

  yield stopLoader(action);

  if (!hasErrors) {
    yield put(applicantModalSlice.actions.toggleEdit(false));
  }
}
