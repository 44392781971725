import React, { useCallback, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import { AppImageEditor } from 'components/AppImageEditor';
import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { Spacer } from 'components/Spacer';
import { ExButton } from 'components/ui/ExButton/ExButton';

import { AppDropZone, AppDropZonePlaceholder, AppImagePickerDialogStyled } from './AppImagePickerDialogComponents';
import { AppImagePickerDialogProps, DropZoneErrors } from './AppImagePickerDialogProps';

export const AppImagePickerDialog: React.FC<AppImagePickerDialogProps> = ({
  className,
  onCancel,
  image,
  file,
  onSave,
  onError,
  square,
  bgcolor,
  type,
  loaderAction = 'AppImagePickerDialogLoader',
}) => {
  const [croppedFile, setCroppedFile] = useState<File | null | string>(() => image ?? null);
  const [acceptedFile, setAcceptedFile] = useState<File | null>(() => file ?? null);

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      fileRejections.forEach((fileRejection) => {
        fileRejection.errors.forEach((error) => {
          let message = error.message;
          if (error.code === DropZoneErrors.FILE_TOO_LARGE) {
            message = 'File is larger than 2mb';
          }

          onError(message);
        });
      });
    },
    [onError],
  );

  const onDropAccepted = useCallback((files: File[]) => {
    setAcceptedFile(files[0]);
  }, []);

  const { getRootProps, acceptedFiles, open, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
    maxSize: 2e6,
    multiple: false,
    onDropRejected,
    onDropAccepted,
  });

  const saveClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof croppedFile === 'object') {
      onSave(croppedFile);
    }
  };

  return (
    <AppImagePickerDialogStyled className={className}>
      <AppDropZone
        {...getRootProps()}
        isImageSelected={!!acceptedFiles[0]}
        isDragActive={isDragActive}
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
      >
        {acceptedFile ? (
          <AppImageEditor
            image={acceptedFile}
            key={acceptedFile.name}
            onChange={setCroppedFile}
            square={square}
            bgcolor={bgcolor}
            type={type}
          />
        ) : (
          <AppDropZonePlaceholder onClick={() => open()}>
            <Spacer grow={5} />
            <span>Drag and drop files</span>
            <Spacer />
            <span>or</span>
            <Spacer />
            <ExButton variant="link">Browse</ExButton>
            <Spacer grow={5} />
          </AppDropZonePlaceholder>
        )}
      </AppDropZone>
      <input {...getInputProps()} />
      <div className="row pt-3">
        <div className="col-6">
          <ExButton onClick={onCancel} variant="light" block>
            Cancel
          </ExButton>
        </div>
        <div className="col-6">
          <ExLoaderHandlerWrapper action={{ type: loaderAction }}>
            <ExButton onClick={saveClickHandler} variant="success" block>
              Save
            </ExButton>
          </ExLoaderHandlerWrapper>
        </div>
      </div>
    </AppImagePickerDialogStyled>
  );
};
