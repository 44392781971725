import React from 'react';
import styled from 'styled-components/macro';

import { ApplicantModalSwitchMode } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalSwitchMode';

import { ApplicantModalSeniorityLevelInfoEdit } from './ApplicantModalSeniorityLevelInfoEdit';
import { ApplicantModalSeniorityLevelInfoView } from './ApplicantModalSeniorityLevelInfoView';

type ApplicantModalSeniorityLevelInfoProps = {
  className?: string;
};

const ApplicantModalSeniorityLevelInfoStyled = styled(ApplicantModalSwitchMode)``;

const useApplicantModalSeniorityLevelInfoState = ({ className }: ApplicantModalSeniorityLevelInfoProps) => {
  return {
    className,
  } as const;
};

export const ApplicantModalSeniorityLevelInfo: React.FC<ApplicantModalSeniorityLevelInfoProps> = (props) => {
  const { className } = useApplicantModalSeniorityLevelInfoState(props);

  return (
    <ApplicantModalSeniorityLevelInfoStyled
      className={className}
      editComponent={<ApplicantModalSeniorityLevelInfoEdit />}
      viewComponent={<ApplicantModalSeniorityLevelInfoView />}
    />
  );
};
