import { useMemo } from 'react';

import { PipelineStageNames, PipelineStageType } from 'model/api-enums.constants';

import { StringIsNumber } from 'utils/funcs';

import { isCreatableStageByType } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.reducer';

const pipelineStageTypeNumbers = Object.values(PipelineStageType).filter(StringIsNumber);

export const pipelineStageTypeNumbersWithoutCustom = pipelineStageTypeNumbers.filter(
  (pipelineStageType) => pipelineStageType !== PipelineStageType.Custom,
);

export function usePipelineStageTypeOptions() {
  return useMemo(() => pipelineStageTypeNumbers.map((value) => ({ label: PipelineStageNames[value], value })), []);
}

export function usePipelineStageTypeOptionsCustom() {
  return useMemo(
    () =>
      pipelineStageTypeNumbers
        .filter((value) =>
          [PipelineStageType.Shortlisted, PipelineStageType.Interviewed, PipelineStageType.Screened].includes(value),
        )
        .map((value) => ({ label: PipelineStageNames[value], value })),
    [],
  );
}

export function usePipelineStageTypeOptionsCreatable() {
  return useMemo(
    () =>
      pipelineStageTypeNumbers
        .filter((value) => isCreatableStageByType(value))
        .map((value) => ({ label: PipelineStageNames[value], value })),
    [],
  );
}
