import { createAction } from '@reduxjs/toolkit';

import {
  AlertActionPayload,
  AlertTypes,
  ApiCallErrorActionPayload,
  CancelableCountdownActionPayload,
} from 'containers/AlertManager/store/models';

export const showError = createAction<AlertActionPayload>(AlertTypes.error);
export const showSuccess = createAction<AlertActionPayload>(AlertTypes.success);
export const showWarning = createAction<AlertActionPayload>(AlertTypes.warning);
export const showInfo = createAction<AlertActionPayload>(AlertTypes.info);
export const apiCallError = createAction<ApiCallErrorActionPayload>(AlertTypes.apiCallError);
export const showCancelableCountdown = createAction<CancelableCountdownActionPayload>(AlertTypes.cancelableCountdown);

export const alertsEffects = {
  showError,
  showSuccess,
  showWarning,
  showInfo,
  apiCallError,
  showCancelableCountdown,
};
