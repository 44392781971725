import React from 'react';
import styled, { css } from 'styled-components';

import { getThemeColor } from 'utils/styled/getThemeColor';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

import { BadgeProps } from './BadgeProps';

const BadgePure: React.FC<BadgeProps> = ({ className, label, children, onClick }) => {
  return (
    <span className={className} onClick={onClick}>
      {children || label}
    </span>
  );
};

const BadgeBg = ({ variant }: BadgeProps) => {
  const colorVariant = `${variant}Light` as const;

  return css`
    background-color: ${getThemeColor(colorVariant)};
  `;
};

export const Badge = styled(BadgePure)`
  color: ${({ theme, variant }) => theme.colors[variant]};
  font-weight: 700;
  ${BadgeBg};
  padding: 0.3em 0.6em;
  font-size: ${getThemeFontSize('small')};
  line-height: 1;
  border-radius: ${({ theme }) => theme.border.borderRadius};
  min-width: 72px;
  text-align: center;
  display: block;
`;
