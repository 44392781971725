import { generateKeyName } from '@public/utils/generateKeyName';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { CompanyInfo } from 'store/company/company.types';

type PublicCompanyInfo = Pick<CompanyInfo, 'companyId' | 'logoUrl'>;

type QueryParameterForJob = {
  companyId: string;
  jobId: string;
};

type QueryParameter = QueryParameterForJob & {
  parameter: string;
  value: string | null;
};

const publicCompanyInitialState = {
  companyUrl: null as null | string,
  companyInfo: null as null | PublicCompanyInfo,
  loading: true,
  queryParameters: {} as Record<string, string | null>,
  isCareerPageJob: false as boolean,
};

const publicCompanySlice = createSlice({
  name: 'publicCompany',
  initialState: publicCompanyInitialState,
  reducers: {
    updateState: (state, { payload }: PayloadAction<Partial<typeof publicCompanyInitialState>>) => ({
      ...state,
      ...payload,
    }),
    setQueryParameter: (state, { payload }: PayloadAction<QueryParameter>) => {
      const key = generateKeyName({
        companyId: payload.companyId,
        jobId: payload.jobId,
        parameter: payload.parameter,
      });

      state.queryParameters = {
        ...state.queryParameters,
        [key]: payload.value,
      };
    },
  },
});

const publicCompanyReducer = publicCompanySlice.reducer;

export { publicCompanyReducer, publicCompanySlice, publicCompanyInitialState };
