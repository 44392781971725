import { createAsyncThunk } from '@reduxjs/toolkit';
import difference from 'lodash/difference';

import * as apiCandidate from 'api-endpoints/candidate';
import { CandidateTalentPool, getTalentPoolsForCandidateFunc } from 'api-endpoints/talent-pool/';
import { talentPoolCandidatesApi } from 'api-endpoints/talent-pool-candidates/talent-pool-candidates.api';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { loaderActions } from 'containers/Loader/store';
import { talentPoolFormActions } from 'containers/TalentPoolForms/state/talentPoolForms.reducer';
import * as talentPoolFormSelectors from 'containers/TalentPoolForms/state/talentPoolForms.selectors';
import { mapTalentPools } from 'containers/TalentPoolLists/store/mappers';

import { authSelectors } from 'store/auth/auth.selectors';
import { Candidate } from 'store/entities/candidates/models';
import { TalentPool, talentPoolActions, TalentPoolResponseUnion } from 'store/entities/talent-pools';
import { AppEffectParams, AppThunk, ThunkApiConfig } from 'store/types';

export const searchTalentPoolByName = createAsyncThunk<
  Array<Pick<TalentPool, 'id' | 'name'>>,
  AppEffectParams<{ searchTerm: string }>,
  ThunkApiConfig
>('[SEARCH]', async ({ searchTerm, preloader }, { dispatch, requestId, rejectWithValue }) => {
  if (preloader) {
    dispatch(loaderActions.start(requestId));
  }
  const { data, message } = await getTalentPoolsForCandidateFunc(searchTerm);
  if (message) {
    if (preloader) {
      dispatch(loaderActions.stop(requestId));
    }
    dispatch(alertsEffects.showError({ message }));
    return rejectWithValue(message);
  }
  if (preloader) {
    dispatch(loaderActions.stop(requestId));
  }
  return data || [];
});

export const addCandidateToTalentPools = createAsyncThunk<
  boolean,
  AppEffectParams<{ talentPoolIds: Array<TalentPool['id']>; candidateId: Candidate['id'] }>,
  ThunkApiConfig
>('[POST]', async ({ talentPoolIds, preloader, candidateId }, { dispatch, requestId, getState }) => {
  const companyId = authSelectors.selectCurrentCompanyId(getState());
  if (!companyId) {
    return false;
  }
  if (preloader) {
    dispatch(loaderActions.start(requestId));
  }
  const postData: apiCandidate.AddCandidateToTalentPoolsData = {
    candidateId,
    companyId,
    talentPoolIds,
  };
  const { errorData, message, response } = await apiCandidate.postAddCandidateToTalentPools(candidateId, postData);
  if (message) {
    if (preloader) {
      dispatch(loaderActions.stop(requestId));
    }
    dispatch(alertsEffects.apiCallError({ errorData, message, response }));
  } else {
    const invalidateTalentPools = talentPoolIds.map((id) => ({ type: 'TalentPool' as const, id }));

    dispatch(
      talentPoolCandidatesApi.util.invalidateTags([{ type: 'Candidates', id: 'LIST' }, ...invalidateTalentPools]),
    );
  }
  if (preloader) {
    dispatch(loaderActions.stop(requestId));
  }
  return !message;
});

export const loadCandidateTalentPoolsTaskId = 'loadCandidateTalentPoolsTaskId';

export function loadCandidateTalentPools({
  preloader,
  candidateId,
}: AppEffectParams<{ candidateId: Candidate['id'] }>): AppThunk {
  return async (dispatch) => {
    if (preloader) {
      dispatch(loaderActions.start(loadCandidateTalentPoolsTaskId));
    }
    async function loadTalentPool(candidateIdForLoad: Candidate['id'], pageNo = 0): Promise<CandidateTalentPool[]> {
      const { data: candidateTalentPools } = await apiCandidate.getCandidateTalentPools(candidateIdForLoad)({
        pageNo,
        pageSize: 18,
      });
      const results: CandidateTalentPool[] = [].concat(candidateTalentPools.items);
      if (candidateTalentPools.pageCount > 1 && pageNo === 0) {
        const promises = Array.from({ length: candidateTalentPools.pageCount - 1 }, (_, index) =>
          loadTalentPool(candidateIdForLoad, index + 1),
        );

        const promiseResults = await Promise.all(promises);
        promiseResults.forEach((i) => results.push(...i));
      }
      return results;
    }

    const data = await loadTalentPool(candidateId);
    dispatch(
      talentPoolFormActions.setCandidateTalentPoolsIds({
        candidateTalentPoolIds: data.map((i) => i.talentPoolId),
      }),
    );
    const { talentPools } = mapTalentPools((data as unknown as TalentPoolResponseUnion[]) || []);
    dispatch(talentPoolActions.upsert({ items: talentPools }));
    if (preloader) {
      dispatch(loaderActions.stop(loadCandidateTalentPoolsTaskId));
    }
  };
}
loadCandidateTalentPools.processing = loadCandidateTalentPoolsTaskId;

export function updateCandidateTalentPools({
  candidateId,
}: AppEffectParams<{
  candidateId: Candidate['id'];
}>): AppThunk<Promise<boolean>> {
  return async (dispatch, getState) => {
    const candidateTalentPools = talentPoolFormSelectors.candidateTalentPoolIds(getState());
    const selectedTalentPools = talentPoolFormSelectors.selectedTalentPoolsIds(getState());

    const res: Record<string, any> = {
      add: difference(selectedTalentPools, candidateTalentPools),
      del: difference(candidateTalentPools, selectedTalentPools),
    };

    let addPromise: Promise<any>;

    if (res.add.length) {
      addPromise = dispatch(
        addCandidateToTalentPools({
          candidateId,
          preloader: true,
          talentPoolIds: res.add,
        }),
      );
    } else {
      addPromise = Promise.resolve({ payload: true });
    }

    if (res.del.length) {
      res.del = res.del.map((talentPoolId: TalentPool['id']) =>
        dispatch(
          talentPoolCandidatesApi.endpoints.bulkRemoveCandidatesFromTalentPoolFunc.initiate({
            talentPoolId,
            candidateIds: [candidateId],
          }),
        ),
      );
    }
    const [addSuccess, ...restDel] = await Promise.all([addPromise, ...res.del]);

    if (restDel.some((del) => del.data) || addSuccess.payload) {
      dispatch(
        alertsEffects.showSuccess({
          message: "The candidate's Talent Pools were successfully updated",
        }),
      );
    }

    return !!addSuccess.payload;
  };
}
