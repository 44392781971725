import { Ability } from 'model';

import { requirementsValidationSchema } from 'containers/JobForms/validators';

import { useYupValidationCallback } from 'utils/hooks/useYupValidationCallback';

import { JobAd } from 'store/entities/job-ads';
import { Job } from 'store/entities/jobs';

export type UseAbilityItems = {
  abilityEntity: 'requirements' | 'responsibilities';
  validationSchemaLabel: string;
  modalId?: string;
};

type UseAbilityItemsExternal = {
  onSubmitValidationCallback: (entityId: string, data: Object) => void;
  entityId: string | null;
  entity?: Job | JobAd;
} & UseAbilityItems;

/**
 * Prepare ability items and submit callback
 *
 * @description This hook prepare ability items (responsibilities or requirements) which depends on ability entity prop
 *
 * @param abilityEntity
 * @param validationSchemaLabel
 * @param onSubmitValidationCallback
 * @param entityId
 * @param entity
 */
export function useAbilityItems({
  abilityEntity,
  validationSchemaLabel,
  onSubmitValidationCallback,
  entity,
  entityId,
}: UseAbilityItemsExternal) {
  const onSubmitValidation = useYupValidationCallback<Array<Ability>>(
    requirementsValidationSchema.label(validationSchemaLabel),
    async (preparedItems) => {
      const data = {
        ...entity,
        [abilityEntity]: preparedItems,
      };

      if (entityId && entity) {
        onSubmitValidationCallback(entityId, data);
      }
    },
  );

  const items = entity?.[abilityEntity];

  return { items, onSubmitValidation } as const;
}
