import { call, put } from 'redux-saga/effects';

import { basicPublicUrl } from 'pages/public/state/index';
import { publicCompanyActions } from 'pages/public/state/public-company/public-company.actions';

export function* publicCompanyGetWorker(action: ReturnType<typeof publicCompanyActions.publicCompanyGetAction>) {
  const companyId = action.payload;

  const url = `${basicPublicUrl}/api/company/${companyId}`;

  yield put(publicCompanyActions.updateState({ loading: true }));
  const res = yield call(fetch, url);
  const json = yield call([res, 'json']);

  yield put(publicCompanyActions.updateState({ companyInfo: json, loading: false }));
}
