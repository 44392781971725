import { put } from 'redux-saga/effects';

import * as jobApi from 'api-endpoints/job';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { invokeApiCall, ReturnData } from 'utils/sagas';

import { jobsActions } from 'store/entities/jobs/index';
import * as jobActions from 'store/entities/jobs/job.actions';
import { jobToState } from 'store/entities/jobs/mappers';

export function* loadJobWorker(action: ReturnType<typeof jobActions.loadJobPage>) {
  const jobId = action.payload.jobId;
  yield startLoader(action);
  if (jobId) {
    const { data }: ReturnData<typeof jobApi.getJob> = yield invokeApiCall(jobApi.getJob, {
      urlParams: { jobId },
    });
    if (data) {
      yield put(jobsActions.upsertOne({ item: jobToState(data) }));
    }
  }
  yield stopLoader(action);
}
