import styled from 'styled-components';

interface TickProps {
  hovered: boolean;
}

export default styled.div<TickProps>`
  position: relative;
  width: 29px;
  height: 26px;
  background: ${(props) => props.theme.colors.light};
  border-radius: 4px;
  opacity: ${(props) => (props.hovered ? 1 : 0)};
  transition: opacity 0.3s;
  cursor: pointer;

  &::before {
    position: absolute;
    top: 6px;
    left: 7px;
    content: '';
    background: ${(props) => props.theme.colors.subtitle};
    width: 14px;
    height: 14px;
    border-radius: 100%;
  }

  &::after {
    content: '';
    display: block;
    width: 5px;
    height: 8px;
    border-radius: 1px;
    border-bottom: 2px solid ${(props) => props.theme.colors.light};
    border-right: 2px solid ${(props) => props.theme.colors.light};
    transform: rotate(45deg) translate3d(14px, -2.5px, 0);
    transform-origin: center;
  }

  &.selected {
    opacity: 1;
    background: ${(props) => (props.hovered ? props.theme.colors.light : 'transparent')};

    &::before {
      background: ${(props) => props.theme.colors.primary};
    }
  }
`;
