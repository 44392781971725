const InterviewTemplateUrl = '/interview-template/{interviewTemplateId}';
export const InterviewTemplateEndpoints = {
  // GET
  SearchInterviewTemplatesFunc: '/interview-template/search',
  // POST
  CreateInterviewTemplateFunc: '/interview-template',
  // GET
  GetInterviewTemplateFunc: InterviewTemplateUrl,
  // PUT
  UpdateInterviewTemplateFunc: InterviewTemplateUrl,
  // DELETE
  DeleteInterviewTemplateFunc: InterviewTemplateUrl,
  // PUT
  UpdateInterviewTemplateQuestionsOrderFunc: `${InterviewTemplateUrl}/questions`,
} as const;
