import React from 'react';

import { FormRadioSwitch, FormRadioSwitchContainer } from 'components/FormRadio/FormRadioSwitch';
import { QuestionViewLoading } from 'components/InterviewTemplate/QuestionView/QuestionViewLoading';
import { QuestionViewProps } from 'components/InterviewTemplate/QuestionView/QuestionViewProps';
import { useQuestionYesNoState } from 'components/InterviewTemplate/QuestionYesNo/hooks/useQuestionYesNoState';

export const QuestionYesNo: React.FC<QuestionViewProps> = (props) => {
  const { className, disabled, interviewTemplateQuestionId, labels, answer, onSubmit, isLoading } =
    useQuestionYesNoState(props);

  return (
    <>
      <QuestionViewLoading isLoading={isLoading} />
      <FormRadioSwitchContainer className={className}>
        <FormRadioSwitch
          id={`${interviewTemplateQuestionId}_yes`}
          name={interviewTemplateQuestionId}
          label={labels.yes}
          disabled={disabled}
          value={'true'}
          checked={answer === true}
          onChange={onSubmit}
        />
        <FormRadioSwitch
          id={`${interviewTemplateQuestionId}_no`}
          name={interviewTemplateQuestionId}
          label={labels.no}
          disabled={disabled}
          value={'false'}
          checked={answer === false}
          onChange={onSubmit}
        />
      </FormRadioSwitchContainer>
    </>
  );
};
