import { getApplicantsPerSourceFigures } from 'api-endpoints/widgets';

import { fetchEntity, worker } from 'utils/sagas';

import * as fromCandidateSourcesActions from 'store/ui/widgets/candidate-sources/candidate-sources.actions';

//--------------------------------------------------------------------------------------------------
const apiRequestCandidateSourcesLoad = fetchEntity.bind(
  null,
  fromCandidateSourcesActions.loadCandidateSourcesHandler,
  getApplicantsPerSourceFigures,
);
//--------------------------------------------------------------------------------------------------
export const apiRequestCandidateSourcesLoadCancelable = worker.bind(
  null,
  fromCandidateSourcesActions.requestCandidateSourcesCancel,
  apiRequestCandidateSourcesLoad,
);
//--------------------------------------------------------------------------------------------------
