import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Alert } from 'containers/AlertManager/store/models';

import { UIKeys } from 'store/constants';

export const initialState = [] as Alert[];

const alertsSlice = createSlice({
  name: UIKeys.alertManager,
  initialState,
  reducers: {
    add(state, action: PayloadAction<Alert>) {
      const newAlert = action.payload;
      return newAlert.duplicate
        ? [...state, newAlert]
        : [...state.filter((alert) => alert.message !== newAlert.message), newAlert];
    },
    del(state, action: PayloadAction<Alert['id']>) {
      const alertId = action.payload;
      return state.filter((alert) => alert.id !== alertId);
    },
    deleteAll() {
      return initialState;
    },
  },
});

export const alertsReducer = alertsSlice.reducer;

export const alertsActions = alertsSlice.actions;

export default alertsSlice;
