import { all, call, spawn } from 'redux-saga/effects';

import { emailTemplateSagaWorkers } from 'pages/Company/CompanyTabs/CompanyEmailTemplateTab/state/company-email-template-tab.saga';
import { publicPageSagas } from 'pages/public/state/sagas/public-page.saga';

import * as alertSagas from 'containers/AlertManager/store/alert.sagas';
import * as applicantListSagas from 'containers/ApplicantLists/store/applicant-lists.sagas';
import * as authSagas from 'containers/Auth/state/auth.sagas';
import * as bulkCreationSagas from 'containers/BulkCreation/bulkCreation.saga';
import { calendarSagas } from 'containers/Calendar/state/calendar.sagas';
import * as jobBoardListSagas from 'containers/JobBoardLists/store/job-board.sagas';
import * as jobBoardFormSagas from 'containers/JobBoardsForm/state/jobBoardsForm.sagas';
import * as loaderSagas from 'containers/Loader/store/loader.sagas';
import { applicantModalSagas } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/store/ApplicantModal.sagas';

import * as historyManagerSagas from 'modules/HistoryManager/redux/saga';
import { consoleErrorForDevEnv } from 'utils/consoleErrorForDevEnv';

import * as authSagasApi from 'store/auth/auth.sagas';
import * as companySagas from 'store/company/company.sagas';
import * as applicantsSagas from 'store/entities/applicants/applicants.sagas';
import * as candidateEducationSagas from 'store/entities/candidate-education/candidate-education.sagas';
import * as candidateWorkExperienceSagas from 'store/entities/candidate-work-experience/candidate-work-experience.sagas';
import * as candidateSagas from 'store/entities/candidates/candidate.sagas';
import * as hiringPipelineStagesSagas from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.sagas';
import * as hiringPipelineSagas from 'store/entities/hiring-pipelines/hiring-pipelines.sagas';
import * as jobBoardsSagas from 'store/entities/job-boards/jobBoards.sagas';
import * as jobSagas from 'store/entities/jobs/job.sagas';
import * as onCostSagas from 'store/entities/onCost/onCost.sagas';
import { qualificationTypeSaga } from 'store/entities/qualification-type/qualification-type.sagas';
import * as talentPoolsSagas from 'store/entities/talent-pools/talent-pools.sagas';
import * as widgetsSagas from 'store/ui/widgets/widgets.sagas';

import { applicantCommentsWatcher } from './entities/applicant-comments';
import { interviewTemplateSagaWatcher } from './entities/interview-template/interview-template.saga';
import { questionSagaWatcher } from './entities/interview-template-questions/interview-template-questions.saga';
import { jobAdWatcher } from './entities/job-ads/job-ad.sagas';
import { jobCommentsWatcher } from './entities/job-comments/job-comments.saga';

export function* rootSaga() {
  const sagas = [
    ...Object.values(authSagas),
    ...Object.values(authSagasApi),
    ...Object.values(jobSagas),
    ...Object.values(talentPoolsSagas),
    ...Object.values(alertSagas),
    ...Object.values(loaderSagas),
    ...Object.values(candidateEducationSagas),
    ...Object.values(candidateWorkExperienceSagas),
    ...Object.values(candidateSagas),
    ...Object.values(widgetsSagas),
    ...Object.values(companySagas),
    ...Object.values(bulkCreationSagas),
    ...Object.values(hiringPipelineSagas),
    ...Object.values(hiringPipelineStagesSagas),
    ...Object.values(applicantListSagas),
    ...Object.values(jobBoardListSagas),
    ...Object.values(onCostSagas),
    ...Object.values(applicantsSagas),
    ...Object.values(jobBoardFormSagas),
    ...Object.values(jobBoardsSagas),
    ...Object.values(historyManagerSagas),
    applicantCommentsWatcher,
    jobCommentsWatcher,
    publicPageSagas,
    jobAdWatcher,
    applicantModalSagas,
    calendarSagas,
    questionSagaWatcher,
    interviewTemplateSagaWatcher,
  ];
  yield all([
    spawn(qualificationTypeSaga),
    spawn(emailTemplateSagaWorkers),
    ...sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            consoleErrorForDevEnv(e);
          }
        }
      }),
    ),
  ]);
}
