import React from 'react';

import { JobAbilitiesModal } from 'containers/Modals/ModalsContent/Job/JobAbilitiesModal';
import { useJobAbilityItems } from 'containers/Modals/ModalsContent/Job/JobAbilitiesModal/hooks/useJobAbilityItems';

import { JobRequirementsModalProps } from './JobRequirementsModalProps';

export const JobRequirementsModal: React.FC<JobRequirementsModalProps> = ({ ...dialogProps }) => {
  const title = 'Job Requirements';
  const { onSubmitValidation, items } = useJobAbilityItems({
    abilityEntity: 'requirements',
    validationSchemaLabel: title,
  });

  return <JobAbilitiesModal title={title} onSubmitValidation={onSubmitValidation} items={items} {...dialogProps} />;
};
