import { useCallback } from 'react';
import { useController } from 'react-hook-form';

import { ExScreeningQuestionOptions } from 'model';
import { QuestionType } from 'model/api-enums.constants';

import { ScreeningQuestionViewProps } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionView/ScreeningQuestionViewProps';
import { useApplicationFormQuestionField } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/hooks/useCompanyScreeningQuestionsListState';

export const useScreeningQuestionCheckBoxState = ({ className, editable, question }: ScreeningQuestionViewProps) => {
  const { itemId, answer, options } = question;

  const disabled = !editable;

  const name = itemId;

  const questionOptions = (answer as ExScreeningQuestionOptions[]) || options || [];

  return {
    className,
    disabled,
    options: questionOptions,
    name,
  } as const;
};

export const useApplicantFormScreeningQuestionCheckBoxState = (props: ScreeningQuestionViewProps) => {
  const { className, question } = props;

  const { fieldName, fieldNameValid, fieldErrors, validated, isInvalid, disabled, register, setValue } =
    useApplicationFormQuestionField(props);

  const {
    field: { value: fieldValue = [], onChange: handlerChangeOption },
  } = useController({ name: fieldName });

  const options = fieldValue;

  const isRadio = question.questionType === QuestionType.SelectBoxList;

  const onChange = useCallback(
    (e) => {
      const { value, checked } = e.target;

      const newValue = fieldValue.map((option) => {
        if (option.value === value) {
          return {
            value: option.value,
            selected: checked,
          };
        }

        if (isRadio && option.selected) {
          return {
            value: option.value,
            selected: false,
          };
        }

        return option;
      });

      handlerChangeOption(newValue);
      setValue(fieldNameValid, newValue.filter((item) => item?.selected).length);
    },
    [isRadio, fieldValue, handlerChangeOption, fieldNameValid, setValue],
  );

  return {
    className,
    disabled,
    fieldName,
    fieldNameValid,
    options,
    fieldErrors,
    onChange,
    validated,
    isInvalid,
    register,
  } as const;
};
