import React, { CSSProperties } from 'react';

import { PlacementApplicantStage } from 'model/api-enums.constants';

import { ListWrapperProps } from 'components/ListView/ListViewProps';
import { ColumnMap } from 'components/ListViewNew/ListViewProps';
import { ExHeaderProps } from 'components/ui/ExTable/ExTable.props';

import { Applicant, ApplicantFilterOpt, ApplicantTableRow, SortBy } from 'store/entities/applicants/models';
import { Job } from 'store/entities/jobs/models';

import { ApplicantListState } from './store';

export interface ApplicantListHeadProps {
  updateSort: (props: ApplicantListState['sortMode']) => void;
  sortMode: ApplicantListState['sortMode'];
}

export interface ApplicantListRenderHeaderProps extends ApplicantListHeadProps {
  columns: ColumnMap<Applicant, SortBy, Columns>;
}

export interface ApplicantListPaginationProps {
  updatePage: (props: ApplicantListState['pageNo']) => void;
  onMouseEnterPage?: (props: ApplicantListState['pageNo']) => void;
  pageCount: ApplicantListState['pageCount'];
  pageNo: ApplicantListState['pageNo'];
  disabled?: boolean;
}

export interface ApplicantListItemProps {
  item: Applicant;
}

export interface ApplicantListWrapperProps {
  header?: React.ReactNode;
  pagination?: React.ReactNode;
  infiniteScrollHandler?: () => void;
}

export interface ApplicantListProps {
  id: ApplicantListState['id'];
  columns: ColumnMap<Applicant, SortBy, Columns>;
  wrapper?: React.FC<ListWrapperProps>;
  children?: (props: ApplicantListItemProps) => React.ReactNode;
  renderHeader?: (props: ApplicantListHeadProps) => React.ReactNode;
  renderPagination?: (props: ApplicantListPaginationProps) => React.ReactNode;
  placeholder?: React.ReactElement;
  className?: string;
}

export interface ApplicantListV2Props<S> extends ApplicantListProps {
  headers: ExHeaderProps<ApplicantTableRow, S>[];
  jobId?: string;
  selectable?: boolean;
  multiselect?: boolean;
  selectableHeader?: boolean;
}

export enum Columns {
  tick = 'tick',
  tickHeader = 'tickHeader',
  candidate = 'candidate',
  onboardingStatus = 'onboardingStatus',
  appliedDate = 'appliedDate',
  source = 'source',
  status = 'status',
  actions = 'actions',
  deadline = 'deadline',
  jobName = 'jobName',
  employmentType = 'employmentType',
  location = 'location',
}

export interface ApplicantListTableViewItemProps {
  item: Applicant;
  columns: ColumnMap<Applicant, SortBy, Columns>;
  className?: string;
}

export interface ApplicantListTableViewHeaderProps extends ApplicantListHeadProps {
  className?: string;
  order?: SortBy;
  label: string;
  style?: CSSProperties;
}

export interface ApplicantListComponentProps {
  listId: ApplicantListState['id'];
  className?: string;
  stageTypeOptions?: Array<ApplicantFilterOpt<PlacementApplicantStage>>;
}

export interface JobApplicantListComponentProps {
  listId: ApplicantListState['id'];
  className?: string;
  job: Job;
}

export interface ApplicantBulkActionsProps {
  listId: ApplicantListState['id'];
  jobId: Job['id'];
}

export interface ApplicantActionButtonProps {
  applicant: Applicant;
  size?: 'sm' | 'lg';
  className?: string;
  dropDirection?: 'up' | 'left' | 'right' | 'down';
}

export interface ApplicantCardProps {
  className?: string;
  applicant: Applicant;
  selected: boolean;
  handleSelect: () => void;
  nameClickPath?: (applicant?: ApplicantCardProps['applicant']) => string;
  preventSelect?: boolean;
}
