export interface ErrorDTO {
  id: string;
  description: string;
  validationErrorCodes: ValidationErrorCodes[] | null;
}

export type ValidationErrorCodes =
  | CandidateValidationErrorCodes
  | CompanyValidationErrorCodes
  | JobApplicantValidationErrorCodes
  | JobValidationErrorCodes
  | PipelineValidationErrors
  | QualificationErrorCodes
  | TalentPoolValidationErrorCodes
  | JobBoardValidationErrorCodes
  | JobAdValidationErrorCodes
  | CompanyEmailTemplateValidationErrorCodes
  | UserValidationErrorCodes
  | InterviewTemplateValidationErrorCodes
  | InterviewValidationErrorCodes
  | InterviewQuestionsValidationErrorCodes
  | InterviewTemplateQuestionsValidationErrorCodes
  | PositionValidationErrors
  | JobScreeningValidationErrorCodes;

export enum CandidateValidationErrorCodes {
  FirstNameEmpty = '810eaf4f-f838-49e1-8746-4f2955e37604',
  LastNameEmpty = '3cd355b6-7c4d-430f-968b-6239477a9218',
  EmailEmpty = '4fcb44d0-097f-40a2-aee0-e1485fb0b4b6',
  EmailNotValid = '9d510262-a00d-4ccd-94ce-fece9dcdfd8b',
  EmailNotUnique = '08b92dee-a862-484c-b94e-e059123b4bb2',
  AttachmentFileTypeNotSupported = '1dbb75c7-1f95-422c-89a8-216f2b1ec9d4',
  AttachmentFileIsInUse = '6f4649c3-cc29-40c9-a3a9-4a39ce1cc14f',
  EducationNameEmpty = '53f69b8b-2ed9-49f9-9a6d-23898929274d',
  EducationNumberEmpty = 'e51058b2-283c-45bf-a1e2-40f4a58e1301',
  EducationOrganizationEmpty = '2a860817-776b-4c5a-be61-f31504eda47b',
  EducationIssueDateEmpty = '18d0a11e-5f46-42c9-84e4-0bbcc1951bd3',
  EducationQualificationEmpty = '705e7e1f-2a56-4fba-8dad-fc683617a143',
  WorkExperienceCompanyNameEmpty = 'eae8240a-2314-40b9-84ce-b8b3370a8571',
  WorkExperiencePositionEmpty = 'da5d2956-371b-4097-a810-2efd8c566c22',
  WorkExperienceStartDateEmpty = '47f7aeaa-b625-449b-9104-4de3937b1e77',
  WorkExperienceEndDateEmpty = '5bfe4af8-1c5f-41da-87e6-e118f67c0ff2',
  PhotoFileTypeNotSupported = '329e91c7-56d7-4251-91fc-373115f17e9f',
  PhotoFileMaxLengthExceeded = '3a80b49c-df23-44d4-8dd4-f7cd18541771',
  AttachmentFileMaxLengthExceeded = '6b75c95f-47e4-432b-9055-4601c395a2e2',
  EmptyFileName = '9d147a99-36f7-46bf-bba8-acdaebd2b7bd',
  DoNotHireCandidateCannotBeAddedToCustomTalentPool = 'b549a8fa-8ad4-4d0b-b9be-fe49c2894c7c',
  AllCandidatesAlreadyAdded = 'e5378933-fef5-4a43-b2f4-c01d9703433c',
}

export enum CompanyValidationErrorCodes {
  CompanyNameEmptyErrorCode = '585972d0-1669-4b58-8217-51b4cd580e81',
  CountriesEmptyErrorCode = '82d287bc-6a6c-4b9c-b62e-30f2a9197e19',
  CountriesHasNoMainErrorCode = '342cd82c-e207-4fed-88d2-aa916eff158f',
  CountryInvalidErrorCode = 'dd51307e-746f-4007-a0eb-caef4c80e1f1',
  CurrenciesRequiredErrorCode = '5ce1c74f-5799-447f-ae37-0e534030f539',
  CurrenciesHasNoMainErrorCode = '342cd82c-e207-4fed-88d2-aa916eff158f',
  CurrencyInvalidErrorCode = '37c1a341-ab75-4b0f-ae5a-42538ad1a8ee',
  IndustriesEmptyErrorCode = '16cfc15d-853c-40a4-8c4b-a4f492948dac',
  IndustriesInvalidErrorCode = 'ba0f85c0-c07c-4a16-8d15-6a69aa55880a',
  NotAllEmployeeTypesPresented = '66040720-4843-41ad-a507-0dbf1ef45ae5',
  IncorrectCountry = '2137f992-0aba-451a-bbd2-a62eb183a52b',
  CannotDeleteDefaultOnCost = 'd3a42587-98b9-414e-bec4-0a44dd12f23b',
  LogoFileTypeNotSupported = 'ecfa2094-f24d-401b-83e1-6af015a37fb5',
  LogoFileMaxLengthExceeded = 'aee108e6-27a2-4af4-a000-760bf4e5d290',
  EmptyFileName = '95199FDA-71C7-4A79-ACAD-023113F23D23',
  WrongContactsNumber = '70bb17af-da5e-4942-836f-38d078685b71',
  IdibuEmailTestNameNotAllowedErrorCode = 'd5b1be26-ed5b-4eef-81be-f2650360ecb8',
  IdibuUserNameAlreadyInUseErrorCode = '53344928-b5ee-414f-9516-574660624f60',
  IdibuClientNotCreatedErrorCode = '096c82fd-dc8f-4f74-a883-4114aa956eb2',
}

export enum JobApplicantValidationErrorCodes {
  ResumeEmpty = '15fecf29-8840-4338-b426-45cb13621d80',
  ResumeNotFound = '3f183be3-9736-4665-9a1e-8e160e3e7480',
  CandidateAppliedBefore = 'e2297333-9dcb-4dbb-9533-63d913c60a84',
  FirstNameEmpty = '8709c7ea-b4ec-4f04-a1a7-c626219b2690',
  LastNameEmpty = 'eee27abf-fa28-4708-91fa-ea946306c5ae',
  MobileEmpty = '0d66923c-b188-4fc7-8fd6-1c7ebcecc792',
  EmailEmpty = 'a2bf998a-2ff7-4f53-a1f7-3330f2f6a778',
  EmailNotValid = 'fd4b842a-e228-4dc6-bbff-aed76de5fc35',
  IncorrectSalaryAmount = '7c1cac07-4b41-4faa-a773-3e45b2e0ad97',
  IncorrectCurrency = 'bd5fe384-227a-4433-ba26-7e4cf340d2eb',
  IncorrectFrequency = 'd6aede7f-0568-4fa1-93f4-56706ce97d5c',
  AttachmentFileTypeNotSupported = '0fb8b123-848e-468d-8d50-187ab89b9fd0',
  PhotoFileTypeNotSupported = '68c586d5-6db1-490a-af10-d5f8651df564',
  PhotoFileMaxLengthExceeded = '0490ca57-aa91-49fd-bb43-d337a87f1732',
  AttachmentFileMaxLengthExceeded = '1d860897-d76b-432a-991d-929e11b77046',
  EmptyFileName = '8f3a860e-6d41-4267-aa0e-46bc8c0edb1d',
  ApplicantIsCreated = 'e58727e3-eb87-45a1-8bbc-cb9f6f2fa9ee',
  JobApplicantNotExists = '1e790353-579f-4c6d-ad23-7490ecffc3fb',
  PendingJobApplicantNotExists = '685eca9b-1ce9-4713-b9dc-b0148465c8f2',
  PendingJobApplicantAlreadyDeclined = '3b8b8c6f-ef76-4167-8702-adcfeefeee6c',
  WrongAvailabilityActualDateValue = '6165c926-47fa-432d-8bcf-9be800e7b2bb',
  ApplicantAlreadyExists = 'd6ddac95-0404-4914-8d0c-86a0aef84d81',
  ApplicantNotExists = 'c51adc22-3f0c-4c40-8caf-eceddc90bfd2',
  InvalidJobApplicantOnboardingEventCode = '021e614f-9f0d-4fc9-9b48-b94b1072fafa',
  WrongPipelineStage = 'dffcd1e3-0e1b-433f-9160-67ff8cabf895',
  InvalidOnboardingEventCode = 'a265c1d3-21f1-4615-bc72-99acce284f74',
  RequiredStagesUnpassed = 'e48ef2ff-7799-4b4a-80e3-36a464ba41ba',
  CurrentStageLocked = 'b11b3982-f929-4054-93de-1c176e4bf173',
  HasInterviewsToPass = 'f067ba93-ac95-4d88-ae33-e001964443fa',
}

export enum JobValidationErrorCodes {
  PositionEmpty = '69aae770-6323-4041-afb1-64ace28cffd3',
  PositionCategoryEmpty = 'ca02fbd8-4187-469b-8270-d3173b0066c8',
  PositionNameEmpty = '65eddeee-6355-492e-9e5e-7ad5cc1d0619',
  PositionNotFound = 'd7027310-1e57-463b-8685-d31848906a98',
  CurrencyNotAvailable = '34620242-d08d-46fc-a808-f72b2224bec4',
  SalaryEmpty = '42998b04-6e8b-4431-a97e-46123eb39782',
  SalaryRangeMaxLessMin = '4defadfa-418c-4308-9b9e-bd98fed88052',
  JobNameEmpty = 'c6fec9a9-43ea-4d5d-85ef-93c305a49eac',
  JobDescriptionMinLength = '9b724259-c44c-45f2-a644-05832da15bd4',
  ReferenceNumberEmpty = 'e1e35cef-77e3-4791-b234-4851855b1c01',
  ReferenceNumberLess10Symbols = '3a5cb13b-c046-4791-b3c5-eee7311d8004',
  ReferenceNumberNotUnique = 'dbbf76b0-70fb-4830-ad15-36020c0a18b6',
  VacancyNumberRangeInvalid = '4a0a186b-c7e2-476a-89b7-44b819d26b26',
  CountryFromEmpty = '3ee2ca41-2b2e-4d79-a696-9fa6e8cd0901',
  JobLocationIdEmpty = '0b2e0f6f-7751-4a20-9180-b9b78d66ca5c',
  PipelineChangedForNonDraft = '3f456448-1c06-4075-9905-b0d9cff2d5ba',
  IncorrectFrequency = 'a7199d45-6e50-4b11-90bb-4939191effcf',
  WrongJobType = '0ab0a6b0-b2f4-4d6f-8593-e2849493eef4',
  JobTypeCannotBeExternal = '8d67eede-091c-47c2-866e-34fe55b88132',
  JobIsNotExists = 'b327d797-bd4c-4af6-9c28-6a4691c61073',
  AtLeastOneInterviewTemplateMustBeSpecified = '13a0c7d9-187b-4bdf-932b-ff8ab9196df4',
  OneOrMoreInterviewTemplatesNotExists = '78151817-84f5-470f-bb41-a72b1a6ac4b1',
  EmptyReferenceNo = '2418820f-dc82-4f94-9e8d-9aa021409950',
  EmptyJobName = '77c833cb-b1e8-4845-b57f-b6714988b302',
  DisabledPipeline = 'b73b0b22-750b-4432-a50e-1abc754d594f',
  WrongExperienceValue = '6742751e-f126-47b5-83f7-f8e51fca27a4',
  WrongEducationValue = '43b6cac8-4e2c-4ece-bb9a-0735b771166d',
  WrongIndustryValue = 'ea1a625c-d04f-49e2-b8d2-7a25aa497377',
}

export enum PipelineValidationErrors {
  PipelineNameEmpty = 'fd76146b-3cd0-4c7d-958c-3251fea26967',
  PipelineNameNotUnique = '6e692096-f8fa-45ae-8f1a-7c3c7031cb1c',
  PipelineIsInUse = '5aac0e3f-f6ee-4f12-a7c1-70ecf347180b',
  PipelineIsDefault = 'b0780568-54cf-4ff6-a2f5-b8a81c36f954',
  PipelineStageNameEmpty = 'd1a2bdf4-7e4f-4652-9a98-b66e3e11e3ac',
  PipelineOrderMissStages = '99831686-7d57-4cf7-9c38-7c5d0325457f',
  BackupPipelineIdFromWrongCompany = '6990b6d6-0bea-4fc3-b7fa-614532d28d2b',
  NoBackupPipelineIdSpecified = 'f3be99b3-357a-4728-b38f-a6ed1e79875a',
  InvalidPipeline = 'e2532d61-6805-4d16-87a9-626828541b9d',
  InvalidPipelineId = '3e69d553-37be-429b-8897-8c760cb2bb04',
  PipelineMustHaveAllRequiredStages = '45d585e8-75fb-4152-a121-8e8660174d69',
  TypeChangeOfThisPipelineStageIsNotAllowed = '3f1c42d5-9ed3-4239-95ab-58b1d3679dee',
  PipelineMustHaveAtLeastOneOfSecondaryStage = '28f72c27-3452-44e6-904f-112c74d28abf',
  PipelineStageHasDependentApplicants = 'c5343c8b-1c92-404a-8005-47a5fe51fb3e',
  PipelineIsDisabled = '4b085096-1117-4581-8651-c49c2734eaa7',
}

export enum QualificationErrorCodes {
  QualificationNameEmpty = '9af009b3-63cb-4655-a65d-e4257c07b046',
  DaxtraQualificationNameEmpty = '42d66147-d4c5-4ed4-affe-2e611e8d1340',
  QualificationEmpty = '4bef1e0e-6d90-4c2b-b8af-bcf38902bd4a',
  DaxtraQualificationWithoutMapping = '70aaa506-ca73-4e72-acaf-a831c0e89d05',
}

export enum TalentPoolValidationErrorCodes {
  GeneralNameForbidden = 'ee66dbfa-d931-445b-8ed2-4ddcb3b12bc6',
  TalentPoolNameEmpty = '13becdd2-f6ee-40e8-a31c-6fd3128686ed',
  TalentPoolNameNotUnique = '17bf3213-84bd-4322-9ff9-cd1abf558bc8',
  TalentPoolIdEmpty = '8d4aba05-1236-4b64-bd89-feb660982b48',
  TalentPoolNotFound = 'aafaea39-6e7e-44ff-8b9a-27016b4a773e',
  PositionNotFound = 'ba6f9736-65b9-4fd4-9a87-6049b76bb138',
  PositionNameRequired = 'd5d20786-a0c8-4812-81c0-ab554bbfd73d',
  PositionCategoryRequired = '53cf5ca5-dbbb-450c-afce-b610612c1769',
  PositionIdRequired = '2b1b0a29-3e31-435f-adb0-dba8b03f5d12',
}

export enum JobBoardValidationErrorCodes {
  RequiredSettingMissedErrorCode = 'a99d0aea-a2f0-4618-aa40-5566a28f6118',
  PostingDurationMissedErrorCode = '5246707e-948c-497e-b27f-098efa1577c4',
  CountryInvalidErrorCode = '79fcb1b7-ff44-4ea5-b34b-2c0923c532d7',
  LocationInvalidErrorCode = '6b0df661-e5c6-488a-8a01-3cc58437da71',
}

export enum JobAdValidationErrorCodes {
  SectorInvalidErrorCode = 'd3e7ad3b-c318-43a4-8ae3-1cd0f7bfd909',
  LocationInvalidErrorCode = 'ba651508-52fb-4c76-ab93-b71b3fe39300',
  RequiredAdditionalFieldInvalidErrorCode = 'ed0bb80b-ee1c-4d2f-becb-6dbeef316f60',
}

export enum CompanyEmailTemplateValidationErrorCodes {
  BothTemplatesInUse = '381fd451-0683-4b03-8ca6-8282b4aa36e8',
  InvalidTemplateId = 'fc64e22b-9bc1-46ce-b378-ba970245597b',
  BackupTemplateIdFromWrongCompany = 'dd52e389-d7a9-44ce-a1e2-b314e5a38385',
  NoBackupTemplateIdSpecified = 'b6ef00d8-c9b9-4744-96e4-7de84cf81925',
}

export enum UserValidationErrorCodes {
  FirstNameEmptyErrorCode = '615d1488-f853-42a8-a8b5-33a8d7a666a9',
  LastNameEmptyErrorCode = 'fd550211-8780-4399-80da-fbad0c902761',
  EmailEmptyErrorCode = '6dc1a81b-4d5c-4046-9a70-c9b1ab9fafe0',
  EmailInvalidErrorCode = '0ad96fe7-1e5f-4261-8042-c320d6568a66',
  EmailUsedErrorCode = '2ef6098b-b0fd-4699-8050-8ce2f6f6edfc',
  ApplicationRoleInvalidErrorCode = '7df9b75c-36a8-409d-ab53-043618842c8d',
}

export enum InterviewTemplateValidationErrorCodes {
  /// <summary>A value that represents the error that occurs if an interview template is not exists.</summary>
  InterviewTemplateNotExists = '9ea86165-193c-48ee-b860-7ff2b41d8691',
  /// <summary>A value that represents the error that occurs if an interview template name is not unique.</summary>
  InterviewTemplateNameNotUnique = 'cd679cda-8ecf-4317-98a3-f9eff3dec76c',
  /// <summary>A value that represents the error that occurs if not all questions of interview template are listed in the order.</summary>
  AllInterviewTemplateQuestionsMustBeListedInOrder = '59e6b5d2-1ef7-40dd-a19c-205e00250cf4',
  /// <summary>A value that represents the error that occurs if there is an attempt to delete an interview template assigned to job.</summary>
  InterviewTemplateAssignedToJobCannonBeDeleted = '1942fdf3-8232-488c-9728-779e9a5665c6',
  /// <summary>A value that represents the error that occurs if there is an attempt to delete an interview template assigned to a pipeline stage.</summary>
  InterviewTemplateAssignedToPipelineStageCannotBeDeleted = '462df180-73b6-4a44-864f-c4469360df59',
  /// <summary>A value that represents the error that occurs if there is an attempt to edit an interview template assigned to a pipeline stage.</summary>
  InterviewTemplateAssignedToPipelineStageCannotBeEdited = 'a167c83c-d079-4961-a445-0773c559d9cc',
}

export enum InterviewValidationErrorCodes {
  /// <summary>A value that represents the error that occurs if an interview creation date is greater than current date.</summary>
  InterviewStartDateMustBeLessThanOrEqualToCurrentDate = 'feb1de55-010b-48cd-8c73-c4d4c4f3830c',
  /// <summary>A value that represents the error that occurs if an interview end date is less than start date.</summary>
  InterviewEndDateMustBeGreaterThanStartDate = '5aa0c193-02fd-4d59-b07c-bdc11773eecb',
  /// <summary>A value that represents the error that occurs if one or more interviewers are not exist.</summary>
  OneOrMoreInterviewersAreNotExist = 'eb7122ad-a20e-4bb4-8a33-6a46590a7cf9',
  /// <summary>A value that represents the error that occurs if one or more mandatory questions are not answered.</summary>
  AllMandatoryQuestionsMustBeAnswered = '9706b24c-1004-4aa2-bbd8-d32c94a16c75',
  /// <summary>A value that represents the error that occurs if operation is not permitted.</summary>
  OperationIsNotPermitted = '24631b91-0083-46fb-9cb4-2edf1a3b5bd0',
}

/// <summary>Provides a set of available error codes for an interview validation.</summary>
export enum InterviewQuestionsValidationErrorCodes {
  /// <summary>A value that represents the error that occurs if an interview question which is flagged as mandatory has also NA flag.</summary>
  EmptyMandatoryAnswer = '314d6810-1a52-4093-8aab-3cd7485881db',

  /// <summary>A value that represents the error that occurs if an answer is not valid.</summary>
  InvalidAnswer = '0f57a798-897f-4a17-9b15-79e35407f036',

  /// <summary>A value that represents the error that occurs if an answer is too long.</summary>
  TooLongAnswer = 'b04d184b-8613-4051-90ba-7a9d466adee3',

  /// <summary>A value that represents the error that occurs if the file name property is empty.</summary>
  EmptyFileName = 'f4da57b6-4b11-4ef9-9cc7-ded63ad42095',

  /// <summary>A value that represents the error that occurs if the file type is unsupported.</summary>
  UnsupportedFileType = 'e74bb387-b1e4-4ed6-b2b0-31ae6a68847e',

  /// <summary>A value that represents the error that occurs if the file size is too big.</summary>
  TooBigFileSize = 'c81a6891-48ef-422d-bc59-fef8defbcb27',
}

export enum InterviewTemplateQuestionsValidationErrorCodes {
  MandatoryInterviewTemplateQuestionMustHaveAnswer = 'bd384d7a-c9d1-4fe5-811e-e229254022ee',
}

export enum PositionValidationErrors {
  PositionNotExists = '78955a11-a734-469e-81ad-e5df0e76742c',
  PositionAlreadyExists = '60ed38df-8e2b-4abd-bb7b-b6b6bcea5dea',
  InvalidEmploymentTypes = '9b274bca-1154-4b77-9015-f322097abdcc',
  InvalidEmploymentTypeName = '759e82f8-7644-4b00-9e9a-583ac0ca9259',
  PositionCategoryNameIsEmpty = '01dd315f-760b-45de-9a32-7da314e73e36',
}

export enum JobScreeningValidationErrorCodes {
  QuestionsAreLocked = 'c06d608b-5889-4a79-b53d-189acc6eb753',
}
