import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import styled from 'styled-components';

import { JobAdStatus, JobType } from 'model/api-enums.constants';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { loaderSelectors } from 'containers/Loader/store';
import {
  AddToTalentPoolFormBody,
  NewTalentPoolFormBody,
  validationSchemaNewTalentPool,
} from 'containers/TalentPoolForms';
import { talentPoolFormActions, talentPoolFormSelectors } from 'containers/TalentPoolForms/state';
import { loadTalentPools } from 'containers/TalentPoolLists/store/effects';

import { ReactComponent as CircleCheck } from 'assets/img/circle-check.svg';

import { Form, FormSelect } from 'components';
import { ExLoaderHandlerWrapper, ExLoaderHandlerWrapperStrict } from 'components/Common/LoaderHandlerWrapper';
import { CustomCheckbox } from 'components/Form/CustomCheckbox';
import { ComponentSpinner, Spinner } from 'components/Spinner';
import { StepFormBody } from 'components/StepForm';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { ExDialogActions, ExDialogFooter } from 'components/ui/ExDialog';
import { ExVisible } from 'components/ui/ExVisible';
import { loaderThunkActionWrapper } from 'modules/LoaderManager/redux';
import { useJobTypeOptions } from 'utils/hooks/formsHooks/useJobTypeOptions';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { safariFlexBoxTweak } from 'utils/styled/common';

import { useGetJobAdsQuery } from 'store/entities/job-ads/job-ads.api';
import { JOB_STATUS_UPDATE } from 'store/entities/jobs/job.constants';
import { TalentPool, TalentPoolBelongsTo, talentPoolEffects, talentPoolSelectors } from 'store/entities/talent-pools';
import { modalsSlice } from 'store/modals';
import { ModalStepProps } from 'store/modals/config';
import { exModalConfirmAction, exModalPropsAction, updateWizardPage } from 'store/modals/modals.actions';
import { modalById, modalWizardTypeById } from 'store/modals/modals.selectors';
import { useSelector } from 'store/rootSelectors';

export const CircleCheckStyled = styled(CircleCheck)`
  --size: 50px;

  fill: ${(p) => p.theme.colors.success};
  width: var(--size);
  height: var(--size);
`;

export const ModalBodyStyled = styled.div`
  width: 100vw;
  max-width: 600px;
  height: 100%;
  max-height: 80vh;
  min-height: 100px;
  position: relative;
  ${safariFlexBoxTweak};

  ${NewTalentPoolFormBody} {
    max-width: none;
  }

  @media (max-width: 769.98px) {
    max-height: 100vh;
  }
`;

export const ModalBodyStyledNarrow = styled(ModalBodyStyled)`
  width: auto;
  max-width: none;
  min-width: 300px;
`;

export const ModalBodyStyledWide = styled(ModalBodyStyled)`
  max-width: 980px;
`;

const MessageStyled = styled.div`
  white-space: pre-wrap;
  text-align: center;
`;

const JobChangeStatusSuggestionStyled = styled.div`
  background-color: ${(p) => p.theme.colors.bodyBackgroundColor};
  padding: 20px;
  margin-top: 20px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const PendingModal = (_: ModalStepProps) => {
  return (
    <ModalBodyStyled>
      <Spinner />
    </ModalBodyStyled>
  );
};

export const JobChangeStatusConfirm: React.FC<ModalStepProps> = ({
  nextButtonHandler,
  message,
  onCloseDialogHandler,
  id,
  modalProps,
}) => {
  const dispatch = useAppDispatch();
  const selectedModal = useAppSelector(modalById, id);

  const [stopJobAds, setStopJobAds] = useState(false);
  const isCompleteAction = modalProps?.status === 'complete';

  const { data, isFetching } = useGetJobAdsQuery({
    belongsTo: ['jobId', modalProps?.jobId],
    listId: 'jobId',
    params: {
      pageSize: 1,
      statuses: JobAdStatus.Active,
    },
  });

  const hasItems = (data?.totalItemsCount || 0) > 0;

  useEffect(() => {
    if (hasItems) {
      setStopJobAds(true);
    }
  }, [hasItems]);

  const showStopCheckbox = isCompleteAction && hasItems;

  const needUpdateJobAds = useMemo(() => isCompleteAction && stopJobAds, [isCompleteAction, stopJobAds]);

  const changeStatusConfirm = () => {
    dispatch(
      modalsSlice.actions.updateModalProps({
        id,
        modalProps: {
          ...selectedModal?.modalProps,
          stopJobAds,
          needUpdateJobAds,
        },
      }),
    );
    nextButtonHandler();
  };

  return (
    <ModalBodyStyled>
      <MessageStyled dangerouslySetInnerHTML={{ __html: message ?? '' }} />
      <ExVisible visible={showStopCheckbox}>
        <CheckboxWrapper>
          <CustomCheckbox checked={stopJobAds} onChange={setStopJobAds}>
            Stop Job Ads from all Job Boards
          </CustomCheckbox>
        </CheckboxWrapper>
      </ExVisible>
      <ExDialogFooter>
        <ExDialogActions>
          <ExButton onClick={() => onCloseDialogHandler()} variant="light">
            Cancel
          </ExButton>
          <ExButton className="ml-3" onClick={changeStatusConfirm} disabled={isFetching}>
            Continue
          </ExButton>
        </ExDialogActions>
      </ExDialogFooter>
    </ModalBodyStyled>
  );
};

const selectJobTypeValidationSchema = yup.object().shape({
  jobType: yup.mixed().oneOf(Object.values(JobType)).label('Job Type').required(),
});

const JobTypeFormBody: React.FC<StepFormBody> = () => {
  const { errors, formState } = useFormContext<yup.TypeOf<typeof selectJobTypeValidationSchema>>();
  const jobTypeOptions = useJobTypeOptions();
  return (
    <FormSelect
      label="&nbsp;"
      options={jobTypeOptions}
      name="jobType"
      isSearchable={false}
      errors={errors?.jobType}
      validated={formState.isSubmitted}
      defaultValue={JobType.Unlisted}
    />
  );
};

export const JobChangeStatusSelectJobType: React.FC<ModalStepProps> = ({
  id,
  modalProps,
  onCloseDialogHandler,
  nextButtonHandler,
}) => {
  const dispatch = useAppDispatch();

  const submitHandler = useCallback(
    (formData) => {
      dispatch(exModalPropsAction({ id, modalProps: { ...modalProps, jobType: formData.jobType } }));
      nextButtonHandler();
    },
    [dispatch, id, modalProps, nextButtonHandler],
  );

  return (
    <ModalBodyStyled>
      <Form validationSchema={selectJobTypeValidationSchema} onSubmit={submitHandler}>
        <JobTypeFormBody />
        <ExDialogFooter>
          <ExDialogActions>
            <ExButton onClick={() => onCloseDialogHandler()} variant="light">
              Cancel
            </ExButton>
            <ExButton className="ml-3" type="submit">
              Continue
            </ExButton>
          </ExDialogActions>
        </ExDialogFooter>
      </Form>
    </ModalBodyStyled>
  );
};

export const JobChangeStatusSuggestion: React.FC<ModalStepProps> = ({
  nextButtonHandler,
  onCloseDialogHandler,
  message,
  title = 'You have completed this job',
}) => {
  const loading = useSelector((state) => loaderSelectors.isTaskActive(state, { taskId: JOB_STATUS_UPDATE }));
  if (loading) {
    return (
      <ModalBodyStyled>
        <ComponentSpinner />
      </ModalBodyStyled>
    );
  }

  return (
    <ModalBodyStyled>
      <MessageStyled>
        <CircleCheckStyled />
        <h2>{title}</h2>
      </MessageStyled>
      <JobChangeStatusSuggestionStyled>
        <MessageStyled>{message}</MessageStyled>
        <ExDialogFooter>
          <ExDialogActions>
            <ExButton variant="light" onClick={() => onCloseDialogHandler()}>
              No
            </ExButton>
            <ExButton className="ml-3" onClick={nextButtonHandler}>
              Yes
            </ExButton>
          </ExDialogActions>
        </ExDialogFooter>
      </JobChangeStatusSuggestionStyled>
    </ModalBodyStyled>
  );
};

export const JobChangeStatusSelectTalentPool: React.FC<ModalStepProps> = ({ id, onCloseDialogHandler }) => {
  const dispatch = useAppDispatch();
  const newTalentPoolAction = () => {
    dispatch(updateWizardPage({ id, page: 'createNewTalentPool' }));
  };
  const talentPoolIdsForSelect = useSelector(talentPoolFormSelectors.selectedTalentPoolsIds);
  const defaultValue = useSelector((state) => talentPoolSelectors.getByIds(state, talentPoolIdsForSelect));

  return (
    <ModalBodyStyled>
      <AddToTalentPoolFormBody defaultValue={defaultValue} newTalentPoolAction={newTalentPoolAction} />
      <ExDialogActions>
        <ExButton variant="light" onClick={() => onCloseDialogHandler()}>
          Cancel
        </ExButton>
        <ExLoaderHandlerWrapperStrict>
          <ExButton className="ml-3" onClick={() => dispatch(exModalConfirmAction({ id }))}>
            Save
          </ExButton>
        </ExLoaderHandlerWrapperStrict>
      </ExDialogActions>
    </ModalBodyStyled>
  );
};

export const JobChangeStatusCreateNewTalentPool: React.FC<ModalStepProps> = ({ id, nextButtonHandler }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useAppDispatch();
  const modalWizardType = useSelector((state) => modalWizardTypeById(state, id));

  const submitHandler = async (data: Partial<TalentPool>) => {
    const resultAction = await dispatch(talentPoolEffects.saveTalentPool({ formData: data, preloader: true }));
    if (talentPoolEffects.saveTalentPool.fulfilled.match(resultAction)) {
      const message = `Talent Pool "${data.name}" successfully created.`;

      const talentPoolId = resultAction.payload.item.id;
      dispatch(talentPoolFormActions.toggleSelectedTalentPoolId({ talentPoolId }));
      dispatch(exModalPropsAction({ id, modalResult: { talentPoolId } }));
      dispatch(alertsEffects.showSuccess({ message }));

      const listId =
        modalWizardType === 'candidateAddToTalentPool' ? TalentPoolBelongsTo.candidate : TalentPoolBelongsTo.main;
      dispatch(loadTalentPools({ listId }));
      nextButtonHandler();
    }
  };

  return (
    <ModalBodyStyled>
      <Form onSubmit={submitHandler} validationSchema={validationSchemaNewTalentPool}>
        <NewTalentPoolFormBody />
        <ExDialogActions>
          <ExButton variant="light" onClick={nextButtonHandler}>
            Cancel
          </ExButton>
          <ExLoaderHandlerWrapper action={loaderThunkActionWrapper(talentPoolEffects.saveTalentPool)}>
            <ExButton className="ml-3" type="submit">
              Save
            </ExButton>
          </ExLoaderHandlerWrapper>
        </ExDialogActions>
      </Form>
    </ModalBodyStyled>
  );
};

export const JobValidationErrors: React.FC<ModalStepProps> = ({ modalProps, onCloseDialogHandler }) => {
  const errors: string[] | undefined = modalProps?.errors;

  return (
    <ModalBodyStyled>
      <MessageStyled className="mb-3">Please solve the following issues in order to publish this job</MessageStyled>
      <ul>
        {errors?.map((error) => (
          <li key={error}>{error}</li>
        ))}
      </ul>
      <ExDialogActions>
        <ExButton onClick={() => onCloseDialogHandler()} variant="light">
          Close
        </ExButton>
      </ExDialogActions>
    </ModalBodyStyled>
  );
};
