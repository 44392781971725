import React from 'react';
import styled from 'styled-components/macro';

import { ApplicantLock } from 'containers/ApplicantLists/components/ApplicantLock';
import { ApplicantName } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/ApplicantModalV3Components';
import { useApplicantFromModal } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/hook/useApplicantFromModal';

import { Ellipsis } from 'components/Ellipsis';
import { useApplicantLockProps } from 'utils/hooks/applicant';

type ApplicantModalNameInfoViewProps = {
  className?: string;
};

const ApplicantModalNameInfoViewStyled = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ApplicantLockStyled = styled(ApplicantLock)`
  margin-left: 8px;
`;

const useApplicantModalNameInfoViewState = ({ className }: ApplicantModalNameInfoViewProps) => {
  const applicant = useApplicantFromModal();

  const fullName = applicant?.fullName;

  const { isApplicantLockVisible, applicantLockHintText } = useApplicantLockProps({
    applicantId: applicant?.applicantId,
  });

  return {
    className,
    fullName,
    isApplicantLockVisible,
    applicantLockHintText,
  } as const;
};

export const ApplicantModalNameInfoView: React.FC<ApplicantModalNameInfoViewProps> = (props) => {
  const { className, fullName, isApplicantLockVisible, applicantLockHintText } =
    useApplicantModalNameInfoViewState(props);

  return (
    <ApplicantModalNameInfoViewStyled className={className}>
      <ApplicantName>
        <Ellipsis title={fullName}>{fullName}</Ellipsis>
      </ApplicantName>
      <ApplicantLockStyled visible={isApplicantLockVisible} hint={applicantLockHintText} />
    </ApplicantModalNameInfoViewStyled>
  );
};
