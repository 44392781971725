export const getUserInitials = (fullName: string) => {
  const splittedFullName = fullName.split(' ').filter(Boolean);

  let initials = '';

  if (splittedFullName.length >= 2) {
    for (let i = 0; i < 2; i++) {
      initials += splittedFullName[i].charAt(0).toUpperCase();
    }
  }

  if (splittedFullName.length === 1) {
    const name = splittedFullName[0].substring(0, 2);
    initials = name.charAt(0).toUpperCase() + name.slice(1);
  }

  return initials || null;
};
