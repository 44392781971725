/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components/macro';
import lodashGet from 'lodash/get';
import startCase from 'lodash/startCase';

import { AdditionalField, SEEK_STAND_TYPE } from 'model';

import { ScreeningQuestionOptionalBadge } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/CompanyScreeningQuestionsComponents';

import { FormInput, FormSelect } from 'components';
import { ExAccordion, ExAccordionToggle } from 'components/ui/ExAccordion';
import { getFormValidatorRules } from 'utils/getFormRules';
import { getThemeColor } from 'utils/styled/getThemeColor';

import { JobBoard } from 'store/entities/job-boards';

type ParentFormSelectProps = {
  hidden: boolean;
};

const isParentFormSelectHidden = ({ hidden }: ParentFormSelectProps) =>
  hidden &&
  css`
    position: absolute;
    opacity: 0;
    z-index: -1;
  `;

const Subtext = styled.div<{ visible: boolean }>`
  color: ${getThemeColor('gray')};
  ${({ visible }) => !visible && 'display: none;'}
`;

const ParentFormSelect = styled(FormSelect)`
  ${isParentFormSelectHidden};
`;

interface JobAdAdditionalRequirementsFormBuilderProps {
  className?: string;
  jobBoard?: JobBoard;
  jobBoardId?: JobBoard['jobBoardId'];
}

const tooltipOffset: [number, number] = [0, -17];

const InputLabel: React.FC<{ field: AdditionalField; premium: boolean; showSubtext?: boolean; name?: string }> = ({
  field,
  premium,
  showSubtext = true,
  name,
}) => (
  <>
    <div>
      {name || field.displayName}
      <ScreeningQuestionOptionalBadge visible={premium} text="Standout & Premium Feature" />
      <ScreeningQuestionOptionalBadge visible={!field.required} />
    </div>
    <Subtext visible={showSubtext}>{field.subtext}</Subtext>
  </>
);

export const useJobAdAdditionalRequirementsFormBuilderRender = ({
  jobBoardId = '',
}: JobAdAdditionalRequirementsFormBuilderProps) => {
  const { register, watch, errors, formState } = useFormContext();

  const renderForm = useCallback(
    (fields?: AdditionalField[], disableFields?: boolean) =>
      fields?.flatMap((field) => {
        const isSubmitted = formState.isSubmitted;
        return getJobFormFieldComponents({ field, errors, isSubmitted, jobBoardId, register, watch }, disableFields);
      }) || [],
    [errors, jobBoardId, register, watch, formState.isSubmitted],
  );

  return {
    renderForm,
  };
};

const getJobFormFieldComponents = (
  { field, errors, isSubmitted, jobBoardId, register, watch },
  disableFields?: boolean,
) => {
  const result: JSX.Element[] = [];
  const fieldPrefixName = `jobBoards.${jobBoardId}.${field.key}`;

  const fieldName = `${fieldPrefixName}.value`;
  const fieldNameChildren = `${fieldPrefixName}.children`;

  const fieldValue = watch(fieldName);

  const fieldError = lodashGet(errors, fieldName);
  const fieldErrorChildren = lodashGet(errors, fieldNameChildren);

  const singleOptionValue = field?.options?.length === 1 ? field.options[0].key : '';

  const fieldDefaultValue = field.value || singleOptionValue || field.defaultValue?.key || '';

  const conditionalFieldKeys = ['SEEK_brand', 'SEEK_bpone', 'SEEK_bptwo', 'SEEK_bpthr'];
  const advertTypeFieldValue = watch(`jobBoards.${jobBoardId}.SEEK_stand.value`);
  const isFieldHidden =
    (!advertTypeFieldValue || advertTypeFieldValue === SEEK_STAND_TYPE.CLASSIC) &&
    conditionalFieldKeys.includes(field.key);

  if (field?.type === 'select' && !isFieldHidden) {
    result.push(
      <ParentFormSelect
        placeholder=""
        name={fieldName}
        label={<InputLabel field={field} premium={conditionalFieldKeys.includes(field.key)} />}
        errors={fieldError}
        validated={isSubmitted}
        rules={{
          ...getFormValidatorRules(field),
        }}
        options={field.options.map(({ key, value }) => ({ label: value, value: key }))}
        getOptionLabel={(option: any) => option.label}
        getOptionValue={(option: any) => option.value}
        tooltipOffset={tooltipOffset}
        defaultValue={fieldDefaultValue}
        clearWhenNotInOptions
        hidden={field.hidden}
        isClearable={!field.required}
        isDisabled={disableFields}
      />,
    );
    field?.options?.forEach((value) => {
      const isActiveOption = fieldValue ? fieldValue === value.key : fieldDefaultValue === value.key;

      const isHiddenField = isActiveOption && field.hidden;

      const selectedValue = field.childValue;

      if (value.children?.length && isActiveOption) {
        result.push(
          <div className={isHiddenField ? '' : 'pl-5'}>
            <FormSelect
              placeholder=""
              rules={{
                ...getFormValidatorRules(field),
              }}
              name={fieldNameChildren}
              errors={fieldErrorChildren}
              validated={isSubmitted}
              label={
                <InputLabel
                  field={field}
                  premium={conditionalFieldKeys.includes(field.key)}
                  name={isHiddenField ? field.displayName : value.value}
                />
              }
              options={value.children.map(({ key, value: optionLabel }) => ({
                label: startCase(optionLabel),
                value: key,
              }))}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
              tooltipOffset={tooltipOffset}
              defaultValue={selectedValue}
              clearWhenNotInOptions
              isClearable={!field.required}
              isDisabled={disableFields}
            />
          </div>,
        );
      }
    });
  } else {
    if (!isFieldHidden) {
      result.push(
        <>
          <FormInput
            key={field.key}
            name={fieldName}
            errors={fieldError}
            validated={isSubmitted}
            label={<InputLabel field={field} premium={conditionalFieldKeys.includes(field.key)} showSubtext={false} />}
            hint={field.subtext}
            placeholder=""
            autoComplete="off"
            inputRef={register({ ...getFormValidatorRules(field) }) as any}
            validator={field.validator}
            tooltipOffset={tooltipOffset}
            defaultValue={fieldDefaultValue}
            disabled={disableFields}
          />
          <input type="hidden" name={fieldNameChildren} value="" ref={register({ setValueAs: () => '' })} />
        </>,
      );
    }
  }

  return result;
};

const JobAdAdditionalRequirementsFormBuilder: React.FC<JobAdAdditionalRequirementsFormBuilderProps> = ({
  className,
  jobBoard,
}) => {
  const additionalInformationEventKey = jobBoard?.jobBoardId!;

  const additionalFields = jobBoard?.additionalFields;

  const additionalFieldsRequired = useMemo(() => additionalFields?.filter((item) => item.required), [additionalFields]);

  const additionalFieldsMemo = useMemo(() => additionalFields?.filter((item) => !item.required), [additionalFields]);

  const { renderForm } = useJobAdAdditionalRequirementsFormBuilderRender({
    jobBoardId: jobBoard?.jobBoardId,
  });

  return (
    <div className={className}>
      <div className="row">
        {renderForm(additionalFieldsRequired, true).map((item, i) => (
          <div className="col-12" key={i}>
            {item}
          </div>
        ))}
        {additionalFieldsMemo?.length ? (
          <ExAccordion className="col-12">
            <ExAccordionToggle eventKey={additionalInformationEventKey}>
              Additional Fields (optional)&nbsp;&nbsp;
            </ExAccordionToggle>
            <ExAccordion.Collapse className="row py-3" eventKey={additionalInformationEventKey}>
              <>
                {renderForm(additionalFieldsMemo, true).map((item, i) => (
                  <div className="col-12" key={i}>
                    {item}
                  </div>
                ))}
              </>
            </ExAccordion.Collapse>
          </ExAccordion>
        ) : null}
      </div>
    </div>
  );
};

export default styled(JobAdAdditionalRequirementsFormBuilder)`
  line-height: 1;
`;
