import React, { useEffect } from 'react';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { authActions } from 'store/auth/auth.actions';
import { authSelectors } from 'store/auth/auth.selectors';
import { getCompanyInfo } from 'store/company/company.selectors';
import { useSelector } from 'store/rootSelectors';

interface IState {
  isAuthenticated: boolean;
  loading: boolean;
}

const initialState: IState = {
  isAuthenticated: false,
  loading: true,
};

const reducer = (state: IState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case 'setIsAuthenticated':
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case 'loading':
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

interface AuthContextProps {
  state: Partial<ReturnType<typeof reducer>>;
}

const initAuthContext: AuthContextProps = {
  state: {},
};

const AuthContext = React.createContext(initAuthContext);

export const useAuth = () => React.useContext(AuthContext);

export const AuthContextProvider: React.FC = (props) => {
  const appDispatch = useAppDispatch();

  const companyInfo = useSelector(getCompanyInfo);
  const reduxUser = useSelector(authSelectors.authUser);
  const reduxUserApi = useSelector(authSelectors.apiUser);
  const reduxLoading = useSelector(authSelectors.authLoading);

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const setLoading = (isLoading: boolean) =>
    dispatch({
      type: 'loading',
      payload: isLoading,
    });

  const setIsAuthenticated = (isAuthenticated: boolean) =>
    dispatch({
      type: 'setIsAuthenticated',
      payload: isAuthenticated,
    });

  useEffect(() => {
    setLoading(reduxLoading);
  }, [reduxLoading]);

  useEffect(() => {
    if (reduxUser) {
      appDispatch(authActions.getAuthUserCompaniesAction());
    } else {
      setIsAuthenticated(false);
    }
  }, [appDispatch, reduxUser]);

  // This effect handles company info loading and then set auth to true
  useEffect(() => {
    if (companyInfo !== null && reduxUserApi !== null) {
      setIsAuthenticated(true);
    }
  }, [companyInfo, reduxUserApi]);

  const value: AuthContextProps = {
    state,
  };

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};
