import {
  CandidateTalentPool,
  TalentPoolItemResponse,
  TalentPoolListItemResponse,
} from 'api-endpoints/talent-pool/models';

import { pipe } from 'utils/funcs';

import { TalentPool } from 'store/entities/talent-pools/models';

export type TalentPoolResponseUnion = TalentPoolListItemResponse & TalentPoolItemResponse & CandidateTalentPool;

const CandidateTalentPoolToTalentPool = (talentPool: TalentPoolResponseUnion) =>
  Object.assign(talentPool, {
    id: talentPool.talentPoolId ?? talentPool.id,
    name: talentPool.name ?? talentPool.talentPoolName,
  });

export const talentPoolToState = (talentPool: TalentPoolResponseUnion): TalentPool =>
  pipe(talentPool, CandidateTalentPoolToTalentPool);
