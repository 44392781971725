import React, { useMemo } from 'react';

import { authSelectors } from 'store/auth/auth.selectors';
import { useSelector } from 'store/rootSelectors';

const useRBACState = (props: RBACProps) => {
  const access = useSelector((state) => authSelectors.isFeatureAllowed(state, props));

  const allowed = useMemo(() => {
    if (props.equalityFn) {
      return props.equalityFn();
    }
    return props.feature ? access : true;
  }, [access, props]);

  return { isFeatureNotAllowed: allowed === false };
};

type RBACProps = {
  feature?: string;
  equalityFn?: (...args: any[]) => boolean | undefined;
  component?: React.ElementType;
  fallbackComponent?: React.ElementType;
  children?: React.ReactNode;
};

export const RBAC: React.FC<RBACProps> = ({
  component: Component,
  fallbackComponent: FallbackComponent,
  children,
  ...props
}) => {
  const { isFeatureNotAllowed } = useRBACState(props);

  if (isFeatureNotAllowed) {
    return FallbackComponent ? <FallbackComponent /> : null;
  }

  if (Component) {
    return <Component />;
  }
  if (children) {
    return <>{children}</>;
  }

  return null;
};

/**
 *
 * @param feature - RBAC constant path
 */
export function useRBAC(feature: string): boolean {
  return useSelector((state) => authSelectors.isFeatureAllowed(state, { feature }));
}
