import React from 'react';
import styled, { css } from 'styled-components/macro';

import { IconStop } from 'components/Icons/IconStop';
import { ExBadge } from 'components/ui/ExBadge';
import { ExBadgeVariant } from 'components/ui/ExBadge/ExBadgeProps';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

type BadgeDoNotHireProps = {
  className?: string;
  size?: 'sm';
};

const IconStopStyled = styled(IconStop)`
  margin-right: 6px;
  width: 18px;
  height: 18px;
`;

const BadgeDoNotHireSmall = css`
  font-size: ${getThemeFontSize('extraSmall')};
  padding: 0.4em 0.8em;

  ${IconStopStyled} {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }
`;
const BadgeDoNotHireStyled = styled.div<{ $size: BadgeDoNotHireProps['size'] }>`
  font-size: ${getThemeFontSize('small')};
  color: #ee0000;
  font-weight: 600;
  padding: 7px 13px;
  border-radius: 99px;
  background: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  white-space: nowrap;
  line-height: 1;
  ${(props) => (props.$size === 'sm' ? BadgeDoNotHireSmall : undefined)}
`;

const useBadgeDoNotHireState = ({ className }: BadgeDoNotHireProps) => {
  return {
    className,
  } as const;
};

export const BadgeDoNotHire: React.FC<BadgeDoNotHireProps> = (props) => {
  const { className } = useBadgeDoNotHireState(props);

  return (
    <BadgeDoNotHireStyled className={className} $size={props.size}>
      <IconStopStyled />
      Do Not Hire
    </BadgeDoNotHireStyled>
  );
};

type BadgeDoNotHireNoIconProps = {
  className?: string;
};

const ExBadgeStyled = styled(ExBadge)`
  white-space: nowrap;
`;

export const BadgeDoNotHireNoIcon: React.FC<BadgeDoNotHireNoIconProps> = ({ className }) => {
  return (
    <ExBadgeStyled className={className} variant={ExBadgeVariant.danger}>
      DO NOT HIRE
    </ExBadgeStyled>
  );
};
