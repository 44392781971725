export const jobNameFieldName = 'jobName';

export const categoryIdFieldName = 'position.categoryId';

export const categoryNameFieldName = 'position.category';

export const positionIdFieldName = 'position.positionId';

export const positionNameFieldName = 'position.name';

export const descriptionFieldName = 'description';

export const responsibilitiesFieldName = 'responsibilities';

export const requirementsFieldName = 'requirements';

export const industryIdFieldName = 'industryId';
