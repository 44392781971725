import { createSelector } from '@reduxjs/toolkit';

import { publicJobsAdapter } from 'pages/public/state/public-jobs/public-jobs.reducer';
import { publicJobsDomain } from 'pages/public/state/rootSelectors';

import { SortDirections } from 'store/constants';

const selectPageNo = createSelector(publicJobsDomain, (state) => state.pageNo);
const selectPageCount = createSelector(publicJobsDomain, (state) => state.pageCount);
const selectTotalItemsCount = createSelector(publicJobsDomain, (state) => state.totalItemsCount);
const selectPageSize = createSelector(publicJobsDomain, (state) => state.pageSize);
const selectFilters = createSelector(publicJobsDomain, (state) => state.filters);
const selectBEFilter = createSelector(publicJobsDomain, (state) => state.filter);
const selectIsLoading = createSelector(publicJobsDomain, (state) => state.loading);
const selectIsConfirmationLoading = createSelector(publicJobsDomain, (state) => state.confirmationLoading);
const selectConfirmationStatus = createSelector(publicJobsDomain, (state) => state.confirmationStatus);
const selectValidationErrorCodes = createSelector(publicJobsDomain, (state) => state.validationErrorCodes);
const selectSortMode = createSelector(publicJobsDomain, (state) => ({
  orderBy: state.filters.orderBy,
  orderDir: state.filters.orderDir,
}));
const selectLocationFilter = createSelector(selectFilters, (filters) => {
  return filters.locations.map(({ value }) => value);
});

const selectListParams = createSelector(publicJobsDomain, selectLocationFilter, (state, locationFilter) => ({
  pageNo: state.pageNo,
  employmentType: state.filters.employmentType,
  countryCode: state.filters.countryCode,
  searchTerm: state.filters.searchTerm,
  ...(state.filters.orderBy && {
    orderBy: `${state.filters.orderBy}${state.filters.orderDir === SortDirections.desc ? ' desc' : ''}`,
  }),
  locations: locationFilter,
}));

const selectHasFilters = createSelector(selectFilters, (filters) =>
  Boolean(filters.searchTerm || filters.employmentType || filters.countryCode || filters.locations.length),
);

const selectLocationFormattedOptions = createSelector(selectBEFilter, (beFilter) => {
  return beFilter.find(({ name }) => name === 'locationFormatted')?.options || [];
});

const selectLocationFormattedOptionsForSelect = createSelector(
  selectLocationFormattedOptions,
  (locationFormattedOptions) => {
    return locationFormattedOptions.map((value) => ({
      value,
      label: value,
    }));
  },
);

const adapterSelectors = publicJobsAdapter.getSelectors(publicJobsDomain);

export const publicJobsSelectors = {
  ...adapterSelectors,
  selectPageNo,
  selectPageCount,
  selectTotalItemsCount,
  selectPageSize,
  selectFilters,
  selectListParams,
  selectIsLoading,
  selectIsConfirmationLoading,
  selectConfirmationStatus,
  selectValidationErrorCodes,
  selectSortMode,
  selectHasFilters,
  selectLocationFormattedOptions,
  selectLocationFormattedOptionsForSelect,
};
