import React from 'react';

import { ModalsTypeKey } from 'containers/Modals/AppModalProps';
import { useModalRedux } from 'containers/Modals/hooks/useModalRedux';
import { talentPoolFormActions, talentPoolFormEffects } from 'containers/TalentPoolForms/state';

import { ExButton } from 'components/ui/ExButton/ExButton';
import { ExMultiselectFormBody } from 'components/ui/ExMultiselectFormBody';
import { isFunction } from 'utils/funcs';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { sortByName } from 'utils/list';

import { talentPoolActions, talentPoolEffects } from 'store/entities/talent-pools';
import { TalentPool } from 'store/entities/talent-pools/models';

export interface AddToTalentPoolFormBodyProps {
  className?: string;
  defaultValue: TalentPool[];
  newTalentPoolAction?: () => void;
}

export const AddToTalentPoolFormBody: React.FC<AddToTalentPoolFormBodyProps> = ({
  className,
  defaultValue,
  newTalentPoolAction,
}) => {
  const dispatch = useAppDispatch();
  const { show } = useModalRedux<{ item: Pick<TalentPool, 'id'> }>({
    id: ModalsTypeKey.newTalentPool,
  });

  const talentPoolIdsOnChangeHandler = (talentPoolList: Array<TalentPool>) => {
    if (talentPoolList) {
      const selectedTalentPoolsIds = talentPoolList.map((i) => i.id);
      dispatch(talentPoolFormActions.setSelectedTalentPoolsIds({ selectedTalentPoolsIds }));
      dispatch(talentPoolActions.upsert({ items: talentPoolList }));
    } else {
      dispatch(talentPoolFormActions.setSelectedTalentPoolsIds({ selectedTalentPoolsIds: [] }));
    }
  };

  const newTalentPoolClickHandler: React.MouseEventHandler<HTMLAnchorElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (newTalentPoolAction && isFunction(newTalentPoolAction)) {
      newTalentPoolAction();
      return;
    }
    const result = await show();
    await dispatch(talentPoolEffects.loadTalentPool({ id: result.item.id }));
    dispatch(
      talentPoolFormActions.toggleSelectedTalentPoolId({
        talentPoolId: result.item.id,
      }),
    );
  };

  const loadEntitiesHandler = async (searchTerm: string) => {
    const result = await dispatch(talentPoolFormEffects.searchTalentPoolByName({ searchTerm }));
    const payload = result.payload as TalentPool[];
    const isPayloadValid = Boolean(payload?.length);
    return isPayloadValid ? sortByName(payload) : [];
  };

  return (
    <ExMultiselectFormBody
      className={className}
      defaultValue={defaultValue}
      getOptionLabelCallback={(option: TalentPool) => option.name}
      getOptionValueCallback={(option: TalentPool) => option.id}
      loadEntitiesHandler={loadEntitiesHandler}
      onChangeHandler={talentPoolIdsOnChangeHandler}
      backspaceRemovesValue={false}
      title="Search Talent Pool"
      headerActions={
        <ExButton variant="link" onClick={newTalentPoolClickHandler}>
          New Talent Pool
        </ExButton>
      }
    />
  );
};
