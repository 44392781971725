import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Country } from 'model';

import {
  FormSubtitle,
  WorkExperienceFormBodyStyled,
} from 'containers/WorkExperienceForm/WorkExperienceFormBody/WorkExperienceFormBodyComponents';
import {
  CurrentlyEmployed,
  WorkExperienceFormBodyProps,
} from 'containers/WorkExperienceForm/WorkExperienceFormBody/WorkExperienceFormBodyProps';

import { FormDate } from 'components/FormDate';
import { FormInput } from 'components/FormInput';
import { FormSelect } from 'components/FormSelect';
import { ExAccordion, ExAccordionCollapseContent, ExAccordionToggle } from 'components/ui/ExAccordion';
import { getActualDate } from 'utils/funcs';

import { useCountryQuerySelectors } from 'store/dictionary/hooks/useCountryQuerySelectors';
import { useSelector } from 'store/rootSelectors';

const workExperienceCurrentlyEmployed: CurrentlyEmployed[] = [
  {
    label: 'No',
    value: false,
  },
  {
    label: 'Yes',
    value: true,
  },
];

const WorkExperienceFormBodyState = <T extends Record<string, any>>({
  className,
  index,
  value,
  idFieldName,
  groupName,
}: WorkExperienceFormBodyProps<T>) => {
  const { watch, register, formState, errors: errorsContext, setValue } = useFormContext();

  const { selectors: countriesSelectors } = useCountryQuerySelectors();
  const countries = useSelector(countriesSelectors.selectAll);

  const getName = useCallback(
    (name: keyof T) => (index !== undefined ? `${groupName}[${index}].${name}` : (name as string)),
    [groupName, index],
  );

  const validated = formState.isSubmitted;

  const errors = useMemo(
    () => (index !== undefined ? errorsContext[groupName]?.[index] : errorsContext),
    [errorsContext, groupName, index],
  );

  const isCurrentlyEmployed = watch(getName('currentlyEmployed'));

  const startDate = getActualDate(value?.startDate);
  const endDate = getActualDate(value?.endDate);

  useEffect(() => {
    if (isCurrentlyEmployed) {
      setValue(getName('endDate'), undefined, { shouldValidate: validated });
    }
  }, [getName, isCurrentlyEmployed, setValue, validated]);

  return {
    getName,
    register,
    className,
    idFieldName,
    errors,
    validated,
    value,
    startDate,
    endDate,
    isCurrentlyEmployed,
    countries,
  } as const;
};

export const WorkExperienceFormBody = <T extends Record<string, any>>(props: WorkExperienceFormBodyProps<T>) => {
  const {
    getName,
    register,
    className,
    idFieldName,
    errors,
    validated,
    value,
    startDate,
    endDate,
    isCurrentlyEmployed,
    countries,
  } = WorkExperienceFormBodyState(props);

  return (
    <WorkExperienceFormBodyStyled className={`${className} row`}>
      <input ref={register} name={getName(idFieldName)} hidden />
      <FormInput
        className="col-12"
        label="Company Name"
        name={getName('companyName')}
        required
        errors={errors?.companyName}
        validated={validated}
        inputRef={register}
        defaultValue={value?.companyName ?? undefined}
      />
      <FormInput
        className="col-md-6"
        label="Position"
        name={getName('position')}
        required
        errors={errors?.position}
        validated={validated}
        inputRef={register}
        defaultValue={value?.position ?? undefined}
      />
      <FormSelect
        label="Currently Employed"
        name={getName('currentlyEmployed')}
        required
        className={'col-md-6'}
        placeholder=""
        errors={errors?.currentlyEmployed}
        validated={validated}
        inputRef={register}
        options={workExperienceCurrentlyEmployed}
        isSearchable={false}
      />
      <FormDate
        className="col-md-6"
        label="Start Date"
        name={getName('startDate')}
        required
        defaultValue={startDate ?? undefined}
        errors={errors?.startDate}
        validated={validated}
        inputRef={register}
      />
      <FormDate
        className="col-md-6"
        label="End Date"
        name={getName('endDate')}
        required
        defaultValue={endDate ?? undefined}
        errors={errors?.endDate}
        validated={validated}
        inputRef={register}
        disabled={isCurrentlyEmployed}
      />
      <ExAccordion className="col-12">
        <ExAccordionToggle eventKey="additional-information">
          Additional Information (optional)&nbsp;&nbsp;
        </ExAccordionToggle>
        <ExAccordion.Collapse eventKey="additional-information">
          <ExAccordionCollapseContent>
            <FormSubtitle>Company Details</FormSubtitle>
            <FormInput
              className="col-12"
              label="Address"
              name={getName('address')}
              errors={errors?.address}
              validated={validated}
              inputRef={register}
              defaultValue={value?.address ?? undefined}
            />
            <FormSelect
              isClearable
              label="Country"
              className="col-md-6"
              getOptionLabel={(option: Country) => option?.name}
              getOptionValue={(option: Country) => option?.code?.expedoCode2}
              maxMenuHeight={150}
              validated={validated}
              options={countries}
              isMulti={false}
              defaultOptions
              errors={errors?.country}
              components={{
                Placeholder: () => null,
                IndicatorSeparator: null,
              }}
              name={getName('country')}
            />
            <FormInput
              className="col-md-2"
              label="State"
              name={getName('state')}
              errors={errors?.state}
              validated={validated}
              inputRef={register}
              defaultValue={value?.state ?? undefined}
            />
            <FormInput
              className="col-md-2"
              label="City"
              name={getName('city')}
              errors={errors?.city}
              validated={validated}
              inputRef={register}
              defaultValue={value?.city ?? undefined}
            />
            <FormInput
              className="col-md-2"
              label="Postcode"
              name={getName('postcode')}
              errors={errors?.postcode}
              validated={validated}
              inputRef={register}
              defaultValue={value?.postcode ?? undefined}
            />
            <FormSubtitle>Reference Details</FormSubtitle>
            <FormInput
              className="col-12 col-md-6"
              label="Contact Name"
              name={getName('contactName')}
              errors={errors?.contactName}
              validated={validated}
              inputRef={register}
              defaultValue={value?.contactName ?? undefined}
            />
            <FormInput
              className="col-12 col-md-6"
              label="Contact Number"
              name={getName('contactNumber')}
              errors={errors?.contactNumber}
              validated={validated}
              inputRef={register}
              defaultValue={value?.contactNumber ?? undefined}
            />
            <FormInput
              className="col-12 col-md-6"
              label="Contact Email"
              name={getName('contactEmail')}
              errors={errors?.contactEmail}
              validated={validated}
              inputRef={register}
              defaultValue={value?.contactEmail ?? undefined}
            />
          </ExAccordionCollapseContent>
        </ExAccordion.Collapse>
      </ExAccordion>
    </WorkExperienceFormBodyStyled>
  );
};
