import React from 'react';
import styled, { keyframes } from 'styled-components';

import { AvatarsSlope } from 'components/Icons/AvatarSlope';

type ExCompanyBgImageProps = {
  className?: string;
};

const appears = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const ExCompanyBgImageStyled = styled(AvatarsSlope)`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  opacity: 0;
  animation-name: ${appears};
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 1s;
`;

export const ExCompanyBgImage: React.FC<ExCompanyBgImageProps> = ({ className }) => {
  return <ExCompanyBgImageStyled className={className} />;
};
