import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { Country } from 'model';

import { adapterHelper } from 'utils/reducer/adapter-helper';

const publicCountryAdapter = createEntityAdapter<Country>({
  selectId: (country) => country.countryId,
});

const publicCountryInitialState = publicCountryAdapter.getInitialState();

const publicCountrySlice = createSlice({
  name: 'publicCountry',
  initialState: publicCountryInitialState,
  reducers: {
    ...adapterHelper(publicCountryAdapter),
  },
});

const publicCountryReducer = publicCountrySlice.reducer;

export { publicCountrySlice, publicCountryReducer, publicCountryAdapter, publicCountryInitialState };
