import { AxiosResponse } from 'axios';

import { JobStatus } from 'model/api-enums.constants';

export const getJobActivitySummaryAnalyticsResponseMapper = async (response: AxiosResponse) => {
  const responseObject = response;
  if (!responseObject.config) {
    return responseObject;
  }
  const params = responseObject.config.urlParams;
  const data = responseObject.data;
  responseObject.data = { jobId: params?.jobId, ...data };

  return responseObject;
};

export const updateJobStatusResponseMapper = async (response: AxiosResponse) => {
  const responseObject = response;
  if (!responseObject.config) {
    return responseObject;
  }
  const params = responseObject.config.urlParams;
  const status = params?.status;
  let newStatus = JobStatus.draft;

  if (status === 'open') {
    newStatus = JobStatus.open;
  }

  if (status === 'cancel') {
    newStatus = JobStatus.cancelled;
  }

  if (status === 'complete') {
    newStatus = JobStatus.filled;
  }

  responseObject.data = { id: params?.jobId, status: newStatus };

  return responseObject;
};
