import styled, { css, DefaultTheme, ThemeProps } from 'styled-components';

import { ReactComponent as Icon } from 'assets/img/star.svg';

type IconStarProps = { $filled?: boolean; $readonly?: boolean; $isRemoveRating: boolean };

export const IconStar = styled(Icon)<IconStarProps>`
  fill: none;
  ${getStarColor}
`;

function getStarColor(props: ThemeProps<DefaultTheme> & IconStarProps) {
  const strokeColor = props.$filled ? getFillColor(props) : props.theme.colors.gray;
  const fillColor = props.$filled ? getFillColor(props) : 'transparent';

  const hoverCss = css`
    cursor: pointer;

    &:hover {
      path {
        transform: scale(1.1);
        transform-origin: center center;
      }
    }
  `;

  const computedHoverCss = props.$readonly ? css`` : hoverCss;

  return css`
    path {
      fill: ${fillColor};
      stroke: ${strokeColor};
      transition: transform 0.2s ease-in-out, fill 0.2s ease-in-out, stroke 0.2s ease-in-out;
    }

    ${computedHoverCss};
  `;
}

function getFillColor(props: ThemeProps<DefaultTheme> & IconStarProps) {
  if (props.$isRemoveRating) {
    return props.theme.colors.danger;
  }

  return props.theme.colors.orange;
}
