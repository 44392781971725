import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import styled from 'styled-components';
import { AnyAction } from 'redux';

import { CompanyInfoItem } from 'pages/Company/components/CompanyInfoItem';

import { MenuItem, Status } from 'components/ActionsDropdown';
import { Spacer } from 'components/Spacer';
import { ExActionButton } from 'components/ui/ExActionButton';
import { ExBadge } from 'components/ui/ExBadge';
import { ExBadgeVariant } from 'components/ui/ExBadge/ExBadgeProps';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useReactCountryFlagOptions } from 'utils/hooks/useReactCountryFlagOptions';

interface CompanyCardItemWithFlagProps {
  className?: string;
  text: string;
  countryCode: string;
  isMain: boolean;
  removeAction: AnyAction;
  setDefaultAction: AnyAction;
}

const CompanyCardItemWithFlagStyled = styled(CompanyInfoItem)``;
const CompanyCountryNameWrapper = styled.div`
  display: flex;
  overflow: hidden;
`;
const CompanyCardItemWithFlagName = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 5px;
`;
const ReactCountryFlagWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
  flex-shrink: 0;
`;
const reactCountryFlagStyles = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  display: 'block',
};

export const CompanyCardItemWithFlag: React.FC<CompanyCardItemWithFlagProps> = ({
  className,
  countryCode,
  isMain,
  text,
  removeAction,
  setDefaultAction,
}) => {
  const dispatch = useAppDispatch();

  const { ReactCountryFlagCdnUrl, onReactCountryFlagError } = useReactCountryFlagOptions();

  const menuItems: MenuItem[] = [
    {
      label: 'Make Default',
      variant: 'primary',
      action: () => dispatch(setDefaultAction),
      status: isMain ? Status.hidden : Status.visible,
    },
    {
      label: 'Remove',
      action: () => dispatch(removeAction),
      status: Status.visible,
    },
  ].filter(({ status }) => status !== Status.hidden);

  return (
    <CompanyCardItemWithFlagStyled className={className}>
      <CompanyCountryNameWrapper>
        <ReactCountryFlagWrapper>
          {!!reactCountryFlagStyles && (
            <ReactCountryFlag
              svg
              style={reactCountryFlagStyles}
              countryCode={countryCode}
              cdnUrl={ReactCountryFlagCdnUrl}
              onError={onReactCountryFlagError}
            />
          )}
        </ReactCountryFlagWrapper>
        <CompanyCardItemWithFlagName>{text}</CompanyCardItemWithFlagName>
      </CompanyCountryNameWrapper>
      <Spacer />
      {isMain ? (
        <ExBadge variant={ExBadgeVariant.default}>Default</ExBadge>
      ) : (
        <ExActionButton buttonId="ReactCountryFlag" menuItems={menuItems} />
      )}
    </CompanyCardItemWithFlagStyled>
  );
};
