import { createAction } from '@reduxjs/toolkit';

import { publicCompanySlice } from 'pages/public/state/public-company/public-company.reducer';

const publicCompanyGetAction = createAction<string>(`${publicCompanySlice.name}/get`);

export const publicCompanyActions = {
  ...publicCompanySlice.actions,
  publicCompanyGetAction,
};
