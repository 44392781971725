import React from 'react';

import { EllipsisPros } from 'components/Ellipsis/EllipsisProps';

export interface ExBadgeProps extends React.HtmlHTMLAttributes<HTMLSpanElement> {
  className?: string;
  variant: ExBadgeVariant;
}
export type ExBadgeEllipsisProps = ExBadgeProps & EllipsisPros;

export enum ExBadgeVariant {
  primary = 'primary',
  success = 'success',
  danger = 'danger',
  warning = 'warning',
  info = 'info',
  default = 'default',
  disabled = 'disabled',
  new = 'new',
  shortlisted = 'shortlisted',
  interviewed = 'interviewed',
  screened = 'screened',
  negotiated = 'negotiated',
  readyToOnboard = 'readyToOnboard',
  inOnboarding = 'inOnboarding',
  hired = 'hired',
  declined = 'declined',
  withdrawn = 'withdrawn',
  tabActive = 'tabActive',
  tabNotActive = 'tabNotActive',
  inactive = 'inactive',
  light = 'light',
}
