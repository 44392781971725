import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Routes } from 'router';
import { ExRoutes } from 'router/routes';
import styled from 'styled-components';
import _ from 'lodash';

import { jobBoardListSelectors } from 'containers/JobBoardLists/store';
import { jobBoardFormActions, jobBoardFormSelectors } from 'containers/JobBoardsForm';
import { selectJobBoard } from 'containers/JobBoardsForm/state/selectors';
import { JobBoardFinalPage } from 'containers/JobBoardsForm/Steps/JobBoardFinalPage';
import { useMountHook } from 'containers/Modals/ModalsContent/JobBoardsSubscribe/JobBoardsSubscribe/hooks/useMountHook';
import { initFormSteps } from 'containers/Modals/ModalsContent/JobBoardsSubscribe/JobBoardsSubscribe/Steps';

import { FormStepsPanel } from 'components/FormStepsPanel';
import { Step, StepForm, StepFormHeader, useSteps } from 'components/StepForm';
import { ExCreateEntityWrapper } from 'components/ui/ExCreateEntityWrapper';
import { useBackLinkState } from 'modules/HistoryManager/components/BackLink';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { JobBoard } from 'store/entities/job-boards';
import { RootState } from 'store/rootReducer';

type JobBoardsSubscribeProps = { onClose?: () => void; className?: string };

const JobBoardsSubscribe: React.FC<JobBoardsSubscribeProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const showSteps = useSelector(jobBoardFormSelectors.selectShowSteps);
  const defaultValues = useSelector(jobBoardFormSelectors.selectFormData);

  const { steps, updateSteps, isLastStep } = useSteps({ steps: initFormSteps });
  const selectedItems = useSelector<RootState, Array<JobBoard['jobBoardId']>>((state) =>
    jobBoardListSelectors.getSelected(state, { id: 'new' }),
  );
  const saveValidStep = useCallback(
    async (path: Step['path']) => {
      updateSteps(path);
      if (isLastStep) {
        dispatch(jobBoardFormActions.createSubscription({}));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLastStep],
  );

  const handlerSaveForm = useCallback(
    (formData: Partial<JobBoard>) => {
      dispatch(jobBoardFormActions.saveForm({ formData: { ...formData, jobBoardId: selectedItems[0] } }));
    },
    [selectedItems, dispatch],
  );

  const fallbackProps = { title: 'Job Boards', pathname: ExRoutes.jobBoardList() };
  const { pathname } = useBackLinkState({ routeName: 'jobBoardSubscribe', fallbackProps });

  const handlerClickClose: React.MouseEventHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(push(pathname));
  };

  useMountHook();

  const selectedJobBoard = useSelector<RootState>((state) => selectJobBoard(state)) as JobBoard;
  const selectedJobBoardName = selectedJobBoard?.name;
  const getJobBoardCleanName = useCallback((name: string) => {
    const capCaseName = _.capitalize(name);
    return capCaseName.split(' ')[0] ?? '';
  }, []);
  const jobBoardCleanName = getJobBoardCleanName(selectedJobBoardName);

  return (
    <ExCreateEntityWrapper className={className}>
      <StepFormHeader
        closeButtonText="Close"
        onClose={handlerClickClose}
        title={jobBoardCleanName ? `Subscribe to ${jobBoardCleanName}` : 'Subscribe to New Job Board'}
        routeName="jobBoardSubscribe"
        fallbackProps={fallbackProps}
      />
      <div className="form-with-steps">
        {showSteps ? <FormStepsPanel steps={steps} /> : null}
        <StepForm
          steps={steps}
          defaultValues={defaultValues}
          saveForm={handlerSaveForm}
          saveValidStep={saveValidStep}
          finalPageUrl={Routes.jobBoardsFormFinal}
          finalPage={JobBoardFinalPage}
        />
      </div>
    </ExCreateEntityWrapper>
  );
};

export default styled(JobBoardsSubscribe)``;
