import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { ExRoutes, ExRoutesNames, ExRoutesPathMap } from 'router/routes';
import styled from 'styled-components';

import { IconArrowLeft } from 'components/Icons/IconArrowLeft';
import { ExVisible } from 'components/ui/ExVisible';
import { selectEntityTitleByOrder, usePreviousHistoryEntity } from 'modules/HistoryManager/react/hooks';
import { historyManagerSelectors } from 'modules/HistoryManager/redux';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { authSelectors } from 'store/auth/auth.selectors';

export type BackLinkFallbackProps = {
  title?: string;
  pathname?: string;
};

const IconArrowLeftStyled = styled(IconArrowLeft)`
  margin-right: 5px;
`;

const BackLinkStyled = styled(Link)`
  display: inline-flex;
  align-items: center;
`;

export type BackLinkProps = {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  routeName?: ExRoutesNames;
  className?: string;
  fallbackProps?: BackLinkFallbackProps;
  fixedTitle?: string;
  fixedPathname?: string;
};

export const fallbackLinkToDashboard: BackLinkFallbackProps = {
  title: 'Dashboard',
  pathname: ExRoutes.dashboard(),
};

const fallbackLinkToOpenJobs: BackLinkFallbackProps = {
  title: ExRoutesPathMap.jobListOpen.selectTitle() || 'Open Jobs',
  pathname: ExRoutes.jobListOpen(),
};

const useBackLinkDefaultFallbackProps = () => {
  const isMainDashboardAvailableSelector = useAppSelector(authSelectors.isMainDashboardAvailableSelector);

  return isMainDashboardAvailableSelector ? fallbackLinkToDashboard : fallbackLinkToOpenJobs;
};

export function useBackLinkState({
  routeName: propsRouteName,
  className,
  fallbackProps,
  fixedTitle,
  fixedPathname,
  onClick,
}: BackLinkProps) {
  const dispatch = useAppDispatch();

  const currentRouteName = useAppSelector(historyManagerSelectors.selectCurrentRouteName);

  const routeName = propsRouteName || currentRouteName;

  const { title, pathname, entityTitles } = usePreviousHistoryEntity(routeName);

  const defaultFallbackProps = useBackLinkDefaultFallbackProps();
  const fallbackRoute = fallbackProps || defaultFallbackProps;

  const fallBackTitle = fallbackRoute?.title;
  const fallBackPathname = fallbackRoute?.pathname;

  const entityTitle = selectEntityTitleByOrder(entityTitles);

  const computedTitle = fixedTitle ? fixedTitle : entityTitle || title || fallBackTitle || '';
  const computedPathname = fixedPathname ? fixedPathname : pathname ?? fallBackPathname ?? '';

  const visible = Boolean(computedTitle);

  const linkClickHandler = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (onClick instanceof Function) {
        onClick(event);
      } else {
        event.preventDefault();

        dispatch(push(computedPathname));
      }
    },
    [dispatch, computedPathname, onClick],
  );

  return { className, visible, title: computedTitle, pathname: computedPathname, linkClickHandler };
}

/**
 * Link that contains back path of latest history entity in the historyManager.
 *
 * @param {BackLinkProps} props
 */
export const BackLink = (props: BackLinkProps) => {
  const { visible, className, linkClickHandler, title, pathname } = useBackLinkState(props);

  return (
    <ExVisible visible={visible}>
      <BackLinkStyled to={pathname} className={className} onClick={linkClickHandler}>
        <IconArrowLeftStyled />
        Back to {title}
      </BackLinkStyled>
    </ExVisible>
  );
};

export const BackLinkV2 = styled(BackLink)`
  font-weight: 600;
`;
