import React from 'react';

type ExVisibleProps = {
  className?: string;
  visible?: boolean;
};

export const ExVisible: React.FC<ExVisibleProps> = ({ children, visible = true }) => {
  if (!visible) {
    return null;
  }

  return <>{children}</>;
};
