import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Routes } from 'router/names';

import { AppRouterParams } from 'model/router';

import { AlertLink } from 'components/Alert';

import { applicantSelectors } from 'store/entities/applicants';
import { useSelector } from 'store/rootSelectors';

type ApplicantModalAlertProps = {
  className?: string;
};

const useApplicantModalAlertState = ({ className }: ApplicantModalAlertProps) => {
  const match = useRouteMatch<AppRouterParams>();
  const { applicantId } = match.params;

  const applicant = useSelector((state) => applicantSelectors.selectApplicantModalData(state, applicantId));

  const previousApplicationsNumber = applicant?.previousApplicationsNumber;
  const hasPreviousApplicationsNumber = Boolean(previousApplicationsNumber);

  const linkTo = match.url + '/' + Routes.applicantApplications;

  return {
    className,
    previousApplicationsNumber,
    hasPreviousApplicationsNumber,
    linkTo,
  } as const;
};

export const ApplicantModalAlert: React.FC<ApplicantModalAlertProps> = (props) => {
  const { className, previousApplicationsNumber, hasPreviousApplicationsNumber, linkTo } =
    useApplicantModalAlertState(props);

  if (!hasPreviousApplicationsNumber) {
    return null;
  }

  return (
    <AlertLink className={className} to={linkTo}>
      This candidate has been applied {previousApplicationsNumber} job applications in the past. View previous job
      applications.
    </AlertLink>
  );
};
