import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { PageSubTitleProps } from './PageSubTitleProps';

const PageSubTitle: React.FC<PageSubTitleProps> = ({ to, label, onClick, className }) => (
  <Link onClick={onClick} to={to} className={className}>
    {label}
  </Link>
);

export default styled(PageSubTitle)`
  font-weight: 600;
`;
