import React from 'react';
import { createPortal } from 'react-dom';
import styled, { DefaultTheme } from 'styled-components';

import { ReactComponent as Arrow } from 'assets/img/arrow.svg';

import {
  ExLoaderFormContextHandlerWrapper,
  ExLoaderHandlerWrapperStrict,
} from 'components/Common/LoaderHandlerWrapper';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { safariFlexBoxTweak } from 'utils/styled/common';

import { StepNavigationButtonsProps } from './StepFormProps';

// ArrowComponent;
const A: React.FC<{ className?: string; variant?: keyof DefaultTheme['colors'] }> = ({ className }) => (
  <Arrow className={className} />
);

const StyledArrow = styled(A)`
  fill: ${({ theme, variant }) => theme.colors[variant ?? 'white']};
`;

export const ArrowForward = styled(StyledArrow)`
  margin-left: 2rem;
`;

export const ArrowBackward = styled(StyledArrow)`
  margin-right: 2rem;
  transform: rotate(180deg);
`;

const StepNavigationButtonWrapper = styled.div`
  ${safariFlexBoxTweak};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: white;
  border-top: 1px solid var(--light);
  box-shadow: 2px -2px 5px rgba(0, 0, 0, 0.06);
  display: flex;
  z-index: 1040;
`;

const StepButtons: React.FC<StepNavigationButtonsProps> = ({
  onPrevStep,
  isFirstStep = false,

  prevButtonText = 'Go Back',
  prevButtonWithArrow = true,
  disableButtonPrev = false,
  prevButtonVariant = 'light' as const,
  prevButtonArrowVariant = 'default',

  nextButtonText = 'Continue',
  nextButtonType = 'submit',
  nextButtonClickHandler,
  nextButtonWithArrow = true,
  disableButtonNext = false,
  nextButtonVariant = 'success',
  nextButtonArrowVariant = 'white',
  formId,
}) => (
  <>
    {isFirstStep ? null : (
      <ExButton
        className="mr-3"
        form={formId}
        variant={prevButtonVariant}
        onClick={onPrevStep}
        disabled={disableButtonPrev}
      >
        {prevButtonWithArrow && <ArrowBackward variant={prevButtonArrowVariant} />} {prevButtonText}
      </ExButton>
    )}
    <ExLoaderFormContextHandlerWrapper>
      <ExLoaderHandlerWrapperStrict>
        <ExButton
          form={formId}
          variant={nextButtonVariant}
          type={nextButtonType}
          disabled={disableButtonNext}
          onClick={nextButtonClickHandler}
        >
          {nextButtonText} {nextButtonWithArrow && <ArrowForward variant={nextButtonArrowVariant} />}
        </ExButton>
      </ExLoaderHandlerWrapperStrict>
    </ExLoaderFormContextHandlerWrapper>
  </>
);

const StepNavigationButtonsForPageLayoutV2: React.FC<StepNavigationButtonsProps> = (props) => (
  <div className="ml-auto">
    <StepButtons {...props} />
  </div>
);

/*
 *
 * @param param0 StepNavigationButtonsProps
 */
export const StepNavigationButtons: React.FC<StepNavigationButtonsProps> = (props) => {
  if (props.isWithoutWrapper) {
    return <StepButtons {...props} />;
  }

  return createPortal(
    <StepNavigationButtonWrapper className={props.className}>
      <StepNavigationButtonsForPageLayoutV2 {...props} />
    </StepNavigationButtonWrapper>,
    document.body,
  );
};
