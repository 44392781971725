import { AxiosError } from 'axios';

import { ErrorDTO } from 'model/api-errors.constants';

export enum AlertTypes {
  error = 'error',
  warning = 'warning',
  info = 'info',
  success = 'success',
  apiCallError = 'apiCallError',
  cancelableCountdown = 'cancelableCountdown',
}

export interface Alert {
  id: string;
  message?: string;
  type: AlertTypes;
  blocking?: boolean;
  closable?: boolean;
  isHtml?: boolean;
  containerClassName?: string;
  duplicate?: boolean;
  children?: React.ReactNode;
}

export type AlertActionPayload = {
  message: string;
  blocking?: boolean;
  delay?: number;
  closable?: boolean;
  isHtml?: boolean;
  containerClassName?: string;
  duplicate?: boolean;
};

export type ApiCallErrorActionPayload = {
  message: string;
  errorData: ErrorDTO;
  response?: AxiosError<ErrorDTO>['response'];
  status?: number;
};

export type CancelableCountdownActionPayload = {
  message: string;
  cancelMessage: string;
  successMessage: string;
  duration: number;
  onCancel: () => void;
};
