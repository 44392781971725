import { useCallback } from 'react';

import { JobStatus } from 'model/api-enums.constants';

import { createDeleteDraftJobLoaderId, loaderActions } from 'containers/Loader/store';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useModal } from 'utils/hooks/useModal';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { authSelectors } from 'store/auth/auth.selectors';
import { useJobDeleteMutation } from 'store/entities/jobs/jobs.api';
import { Job } from 'store/entities/jobs/models';

type UseJobDeleteProps = {
  job: Job;
};

export const useJobDelete = ({ job }: UseJobDeleteProps) => {
  const { jobId, jobName, status } = job;

  const dispatch = useAppDispatch();

  const user = useAppSelector(authSelectors.apiUser);
  const isJobCreatedByUser = job?.createdById === user?.userId;

  const { show } = useModal({});

  const isUserCanDeleteJobSelector = useAppSelector(authSelectors.isUserCanDeleteJobSelector);
  const hideDeleteButton = (() => {
    if (status !== JobStatus.draft) {
      return true;
    }

    if (isJobCreatedByUser) {
      return false;
    }

    return !isUserCanDeleteJobSelector;
  })();

  const [jobDeleteMutation] = useJobDeleteMutation();

  const jobDelete = useCallback(async () => {
    const deleteDraftJobLoaderId = createDeleteDraftJobLoaderId(jobId);

    dispatch(loaderActions.start(deleteDraftJobLoaderId));

    await jobDeleteMutation({ jobId, jobName });

    dispatch(loaderActions.stop(deleteDraftJobLoaderId));
  }, [dispatch, jobDeleteMutation, jobId, jobName]);

  const jobDeleteModalConfirm = useCallback(async () => {
    return show({
      title: 'Delete Confirmation',
      withTitle: true,
      message: `Do you want to proceed with deleting job\n "${jobName}?"`,
      buttonOkVariant: 'danger',
      buttonOk: 'Delete',
      withActions: true,
    });
  }, [jobName, show]);

  const jobDeleteWithModal = useCallback(async () => {
    const result = await jobDeleteModalConfirm();

    if (result) {
      await jobDelete();
    }

    return result;
  }, [jobDelete, jobDeleteModalConfirm]);

  return {
    hideDeleteButton,
    jobDelete,
    jobDeleteWithModal,
  };
};
