import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import type { TypeOf } from 'yup';
import styled from 'styled-components';

import { jobAdFormActions } from 'containers/JobAdForms/state';
import { validationSchemaNewJobAdsSecondStep } from 'containers/JobAdForms/validators';
import { JobBoardInfoRow, JobBoardList, JobBoardListHeader } from 'containers/JobBoardLists';
import { jobBoardListSelectors } from 'containers/JobBoardLists/store';
import { useNewJobAdsContextFormUpdater } from 'containers/Modals/ModalsContent/JobAds/NewJobAds/NewJobAds.context';

import { ColumnMap } from 'components/ListViewNew/ListViewProps';
import { StepComponent, StepDescription } from 'components/StepForm';
import { innerTablesStyled } from 'components/TableList/TableListComponents';
import { ExCard } from 'components/ui/ExCard';
import { useFormShowAlertError } from 'utils/hooks/formsHooks/useFormShowAlertError';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getTranslate } from 'utils/i18utils';

import { Columns, JobBoard, jobBoardActions, JobBoardBelongsTo, SortBy } from 'store/entities/job-boards';

export interface NewJobAdSelectJobBoardStepProps extends StepComponent {
  className?: string;
}

const columns: ColumnMap<JobBoard, SortBy, Columns> = {
  [Columns.name]: {
    label: 'Job Board Name',
    component: ({ item }) => <JobBoardInfoRow jobBoard={item} withLink={false} />,
    order: SortBy.name,
    style: { width: '48%' },
  },
  [Columns.url]: {
    label: 'Website',
    component: ({ item }) => <span style={{ fontSize: '12px', lineHeight: '24px' }}>{item.url}</span>,
    order: SortBy.url,
    style: { width: '45%' },
  },
};

const JobBoardListStyled = innerTablesStyled(JobBoardList);

const NewJobAdSelectJobBoardStepPure: React.FC<NewJobAdSelectJobBoardStepProps> = ({ className }) => {
  const { register, setValue, getValues } = useFormContext<TypeOf<typeof validationSchemaNewJobAdsSecondStep>>();
  useNewJobAdsContextFormUpdater();
  useFormShowAlertError();
  const jobBoardIdsDefined = getValues('jobBoardIds');
  const dispatch = useAppDispatch();
  const selectedItems = useAppSelector(jobBoardListSelectors.getSelected, { id: JobBoardBelongsTo.newJobAd });

  useEffect(() => {
    register('jobBoardIds', { required: true });
    if (!jobBoardIdsDefined?.length) {
      dispatch(jobAdFormActions.updateState({ additionFieldsView: {}, apiErrors: [] }));
      dispatch(jobAdFormActions.saveForm({ formData: { jobBoards: {} } }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue('jobBoardIds', [...selectedItems]);
  }, [selectedItems, setValue]);

  useEffect(() => {
    if (selectedItems.length) {
      dispatch(jobBoardActions.updateMany(selectedItems.map((id) => ({ id, changes: { additionalFields: [] } }))));
    }
  }, [dispatch, selectedItems]);

  return (
    <div className={className}>
      <StepDescription>
        <StepDescription.Title>Step 2: Select a Job Board</StepDescription.Title>
        <StepDescription.Description>{getTranslate('new_job_ad.step_2.description')}</StepDescription.Description>
      </StepDescription>
      <ExCard title="Available Job Boards">
        <JobBoardListHeader listId={JobBoardBelongsTo.newJobAd} multiselect={true} />
        <JobBoardListStyled
          id={JobBoardBelongsTo.newJobAd}
          columns={columns}
          selectable
          multiselect
          preventClick
          selectableHeader
        />
      </ExCard>
    </div>
  );
};

export const NewJobAdSelectJobBoardStep = styled(NewJobAdSelectJobBoardStepPure)``;
