import uniqBy from 'lodash/uniqBy';
import { AnyAction } from 'redux';
import { all, call, put, PutEffect, putResolve, select } from 'redux-saga/effects';

import { PipelineStageType } from 'model/api-enums.constants';
import { WithRequired } from 'model/utils';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';

import {
  HiringPipelineStage,
  HiringPipelineStagePrepareEdit,
} from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.model';
import {
  hiringPipelinesStagesSelectors,
  hiringPipelineStageActions,
} from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.reducer';
import type * as hiringPipelineStagesTypes from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.types';
import {
  enhancedHiringPipelineApi,
  extendedHiringPipelineApi,
  hiringPipelineAdapter,
  hiringPipelineStagesAdapter,
} from 'store/entities/hiring-pipelines/hiring-pipelines.api';
import * as hiringPipelinesModel from 'store/entities/hiring-pipelines/hiring-pipelines.model';
import {
  hiringPipelineActions,
  hiringPipelineSelectors,
} from 'store/entities/hiring-pipelines/hiring-pipelines.reducer';
import { HiringPipeline } from 'store/entities/hiring-pipelines/hiring-pipelines.types';
import { ModalWizardPageNames } from 'store/modals/config';
import { exModalPropsAction, updateWizardPage } from 'store/modals/modals.actions';

export type EditSagaAction = {
  type: string;
  payload: WithRequired<Partial<hiringPipelineStagesTypes.HiringPipelineStage>, 'pipelineStageId'>;
};
export type EditSagaActionsMap<T = EditSagaAction> = Map<string, Array<T>>;

type GetResultActionFromActionPairProps = EditSagaAction | { type: string; payload: undefined };
type GoToStepWithModalIdProps = { modalId: string };
type GoToStepInnerFunctionProps = {
  pipelineStageId: string;
  hiringPipelineStages: hiringPipelineStagesTypes.HiringPipelineStage[];
};
type GoToFirstStepInnerFunctionProps = {
  pipelineId: string;
};

export type EditHiringPipelineActions = ReturnType<
  typeof hiringPipelineActions.edit | typeof hiringPipelineActions.clone | typeof hiringPipelineActions.addNew
>;

/**
 * This method choose which action we must invoke.
 *
 * @param {EditSagaAction} firstAction
 * @param {EditSagaAction} lastAction
 * @returns {EditSagaAction | {type: string, payload: undefined}}
 */
function getResultActionFromActionPair(
  firstAction: EditSagaAction,
  lastAction: EditSagaAction,
): GetResultActionFromActionPairProps {
  switch (true) {
    // `create` - `remove` - do nothing (emptyAction);
    case hiringPipelineStageActions.create.start.match(firstAction) &&
      hiringPipelineStageActions.remove.start.match(lastAction): {
      return hiringPipelineStageActions.emptyAction();
    }
    // `create` - `update` - `create` with the most actual payload;
    case hiringPipelineStageActions.create.start.match(firstAction) &&
      hiringPipelineStageActions.update.start.match(lastAction): {
      return {
        ...lastAction,
        type: firstAction.type,
      };
    }
    // !`create` - `any action` - `any action` with the most actual payload;
    case !hiringPipelineStageActions.create.start.match(firstAction): {
      return lastAction;
    }
    // actions are equal - `lastAction` with the most actual payload;
    case firstAction.type === lastAction.type: {
      return lastAction;
    }
    default: {
      return hiringPipelineStageActions.emptyAction();
    }
  }
}

/**
 * This method returns action that changes modal wizard to the first (main) page.
 * In this case we don't use wizardBackward because we must implicitly update
 * the `hiringPipelineStages` property.
 * @param {{modalId: string}} {modalId}
 * @returns
 */
export function goToEditCustomPipelinePage({ modalId }: GoToStepWithModalIdProps) {
  return function ({ pipelineId }: GoToFirstStepInnerFunctionProps) {
    return updateWizardPage({
      id: modalId,
      page: 'hiringPipelineEdit',
      modalConfig: {
        content: {
          withTitle: true,
          title: 'Edit Custom Pipeline',
        },
      },
      modalProps: { pipelineId },
    });
  };
}

/**
 * This method returns action that changes modal wizard to the 'Add New Stage' page
 *
 * @param {{ modalId: string }} { modalId }
 * @returns
 */
export function goToAddNewStage({ modalId }: GoToStepWithModalIdProps) {
  return function () {
    return updateWizardPage({
      id: modalId,
      page: 'hiringPipelineStageCreateModal',
      modalConfig: { content: { title: 'Add New Stage', withTitle: true } },
      modalProps: { hiringPipelineStage: undefined },
    });
  };
}

/**
 *  This method returns action that changes modal wizard to the 'Remove Stage' page
 *
 * @param {{ modalId: string }} { modalId }
 * @returns
 */
export function goToRemoveStage({ modalId }: GoToStepWithModalIdProps) {
  return function ({ pipelineStageId, hiringPipelineStages }: GoToStepInnerFunctionProps) {
    const hiringPipelineStage = hiringPipelineStages.find(
      (pipelineStage) => pipelineStage.pipelineStageId === pipelineStageId,
    );

    return updateWizardPage({
      id: modalId,
      page: 'hiringPipelineStageRemoveModal',
      modalConfig: { content: { title: 'Confirm Remove Stage', withTitle: true } },
      modalProps: { hiringPipelineStage },
    });
  };
}

/**
 * This method returns action that changes modal wizard to the 'Edit Stage' page
 *
 * @param {{ modalId: string }} { modalId }
 * @returns
 */
export function goToEditStage({ modalId }: GoToStepWithModalIdProps) {
  return function ({ pipelineStageId, hiringPipelineStages }: GoToStepInnerFunctionProps) {
    const hiringPipelineStage = hiringPipelineStages.find(
      (pipelineStage) => pipelineStage.pipelineStageId === pipelineStageId,
    );

    return updateWizardPage({
      id: modalId,
      page: 'hiringPipelineStageEditModal',
      modalConfig: {
        content: {
          title: `Edit ${hiringPipelineStage?.name} Stage`,
          withTitle: true,
        },
      },
      modalProps: {
        hiringPipelineStage: {
          ...hiringPipelineStage,
        },
      },
    });
  };
}

/**
 * Group pipeline actions by id.
 * In this method we use Map instead of object because Map avoids check of key exists.
 *
 * @param {EditSagaActionsMap} acc
 * @param {EditSagaAction} action
 * @returns {EditSagaActionsMap}
 */
export function groupPipelineActionsById(acc: EditSagaActionsMap, action: EditSagaAction): EditSagaActionsMap {
  const groupedActions = acc.get(action.payload?.pipelineStageId) || [];
  acc.set(action.payload?.pipelineStageId, [...groupedActions, action]);

  return acc;
}

/**
 * Function forms the object consist of two array:
```
{
    resultActions: 'actions array on top of decision of `getResultActionFromActionPair` function',
    resultActionsOfStageActions: `actions array related to hiringPipelineStageActions`
}
```
 * @param {EditSagaActionsMap} actionsGroupedByPipelineStageId
 * @returns
 */
export function getResultActions<Action extends EditSagaAction>(
  actionsGroupedByPipelineStageId: EditSagaActionsMap<Action>,
) {
  const resultActions: Array<PutEffect<EditSagaAction>> = [];

  actionsGroupedByPipelineStageId.forEach((editSagaActions) => {
    const firstAction = editSagaActions[0];
    const lastAction = editSagaActions[editSagaActions.length - 1];
    const resultAction = getResultActionFromActionPair(firstAction, lastAction);

    // Filter empty actions;
    if (hiringPipelineStageActions.emptyAction.match(resultAction)) {
      return;
    }

    resultActions.push(put(resultAction));
  });

  return resultActions;
}

/**
 * Function return first page for hiringPipelineEdit wizard
 *
 * @param action EditHiringPipelineActions
 * @returns string
 */
export function getEditModalPage(action: EditHiringPipelineActions): ModalWizardPageNames<'hiringPipelineEdit'> {
  switch (true) {
    case hiringPipelineActions.clone.match(action):
      return 'hiringPipelineDuplicate';
    case hiringPipelineActions.addNew.match(action):
      return 'hiringPipelineAddNew';
    default:
      return 'hiringPipelineEdit';
  }
}

/**
 * Function return modal title for hiringPipelineEdit wizard
 *
 * @param action EditHiringPipelineActions
 * @returns string
 */
export function getEditModalTitle(action: EditHiringPipelineActions): string {
  switch (true) {
    case hiringPipelineActions.clone.match(action):
      return 'Clone Custom Pipeline';
    case hiringPipelineActions.addNew.match(action):
      return 'Add Custom Pipeline';
    default:
      return 'Edit Custom Pipeline';
  }
}

/**
 * Based on action type return pipelineId for cloning
 *
 * @param {EditHiringPipelineActions} action
 * @returns pipelineId
 */
export function* getPipelineIdForCloning(action: EditHiringPipelineActions) {
  if (hiringPipelineActions.clone.match(action)) {
    return action.payload;
  }

  const selector = yield call(extendedHiringPipelineApi.endpoints.getDefaultPipeline.select, {});
  const { data: defaultHiringPipeline } = yield select(selector);
  const defaultHiringPipelineFromList = yield select(hiringPipelineSelectors.selectDefaultPipeline);

  return defaultHiringPipeline?.pipelineId || defaultHiringPipelineFromList?.pipelineId;
}

/**
 * Functions prepare actions of stage actions for api response
 *
 * @export
 * @param {string} pipelineId
 * @param {Array<{ originalId: string; returnedId: string }>} stageIds
 * @param {HiringPipelineStage[]} hiringPipelineStagesTemporary
 * @returns
 */
export function prepareActionsOfStageActions(
  pipelineId: string,
  stageIds: Array<{ originalId: string; returnedId: string }>,
  hiringPipelineStagesTemporary: hiringPipelineStagesTypes.HiringPipelineStage[],
) {
  const actionsForChange = stageIds.reduce<hiringPipelineStagesTypes.HiringPipelineStage[]>((acc, ids) => {
    const candidate = hiringPipelineStagesTemporary.find((temp) => temp.pipelineStageId === ids.originalId);
    if (candidate) {
      acc.push({
        ...candidate,
        pipelineStageId: ids.returnedId,
      });
    }
    return acc;
  }, []);

  const actions = actionsForChange.reduce<Array<AnyAction>>((acc, stage) => {
    const newActions = stage.actions?.map((action) => ({
      type: action?.type,
      actionItemId: action?.actionItemId,
      recipientType: action?.recipientType,
    }));

    const mutationAddActions = enhancedHiringPipelineApi.endpoints.addPipelineStageActionFunc.initiate({
      pipelineId,
      pipelineStageId: stage.pipelineStageId,
      data: {
        actions: newActions ?? [],
      },
    });

    acc.push(mutationAddActions as any);
    return acc;
  }, []);

  return actions.map((action) => put(action));
}

export type FirstStageButtonLabel = ReturnType<typeof hiringPipelineSelectors.selectFirstStageButtonLabelByPipelineId>;

export type DeclineButtonLabel = ReturnType<typeof hiringPipelineSelectors.selectDeclineButtonLabelByPipelineId>;

export function* getHiringPipelineStagesTemporary(pipelineId?: string) {
  const hiringPipelineStages: hiringPipelineStagesTypes.HiringPipelineStage[] = yield select(
    hiringPipelinesStagesSelectors.selectByPipelineIdSortedByOrder,
    pipelineId,
  );

  const firstStageButtonLabel: FirstStageButtonLabel = yield select(
    hiringPipelineSelectors.selectFirstStageButtonLabelByPipelineId,
    pipelineId!,
  );

  const declineButtonLabel: DeclineButtonLabel = yield select(
    hiringPipelineSelectors.selectDeclineButtonLabelByPipelineId,
    pipelineId!,
  );

  return hiringPipelineStages.map((stage) => {
    let stageButtonLabel: string | undefined | null;
    if (stage.order === 1) {
      stageButtonLabel = firstStageButtonLabel;
    }
    if (stage.stageType === PipelineStageType.Declined) {
      stageButtonLabel = declineButtonLabel;
    }
    return {
      ...stage,
      stageButtonLabel,
    };
  });
}

export function* newNameForHiringPipelineWorker(
  action: EditHiringPipelineActions,
  modalId: string,
  newPipelineName: string,
  newNameForHiringPipeline: ReturnType<typeof hiringPipelineActions.create>,
  hiringPipelineStagesTemporary: hiringPipelineStagesTypes.HiringPipelineStage[],
  pipelineId?: string,
) {
  const result: {
    pipelineId?: string;
    hiringPipelineStagesTemporary?: hiringPipelineStagesTypes.HiringPipelineStage[];
  } = {
    hiringPipelineStagesTemporary,
    pipelineId,
  };

  const pipelineIdForCloning = yield call(getPipelineIdForCloning, action);
  /**
   * In the current saga we must use ClonePipelineFunc because due to
   * requirements documentation new Custom Hiring Pipeline
   * must be based on the general (default) pipeline
   */
  yield startLoader(newNameForHiringPipeline);

  const mutationClone = yield call(enhancedHiringPipelineApi.endpoints.clonePipeline.initiate, {
    pipelineId: pipelineIdForCloning,
    data: { newPipelineName },
  });
  const { error, data } = yield putResolve(mutationClone);
  const errorData = error?.data;

  /**
   * If got error on api all when clone set errors and restart main saga flow
   */
  if (errorData) {
    yield put(exModalPropsAction({ id: modalId, modalProps: { errorData } } as any));
    yield call(stopLoader, newNameForHiringPipeline);
    return { ...result };
  }

  /**
   * Fetch created hiring pipeline
   */
  const mutationGetCloned = yield call(enhancedHiringPipelineApi.endpoints.getClonedPipeline.initiate, {
    pipelineId: data.pipelineId,
  });
  const { error: getError, data: storedPipeLine } = yield putResolve(mutationGetCloned);
  const getErrorData = getError?.data;

  if (getErrorData) {
    return { ...result };
  }

  result.pipelineId = data.pipelineId!;

  /**
   * set stages of created hiring pipeline for next wizard steps
   */
  result.hiringPipelineStagesTemporary = [
    ...storedPipeLine.stages
      .map(HiringPipelineStage)
      .map((storedStages: hiringPipelineStagesTypes.HiringPipelineStage) => {
        let stageButtonLabel: string | undefined | null;
        const {
          firstStageButtonLabel: storedFirstStageButtonLabel,
          defaultFirstStageButtonLabel: storedDefaultFirstStageButtonLabel,
          declineButtonLabel: storedDeclineButtonLabel,
        } = storedPipeLine as HiringPipeline;
        if (storedStages.order === 1) {
          stageButtonLabel = storedFirstStageButtonLabel ?? storedDefaultFirstStageButtonLabel;
        }
        if (storedStages.stageType === PipelineStageType.Declined) {
          stageButtonLabel = storedDeclineButtonLabel;
        }
        return {
          ...storedStages,
          stageButtonLabel,
        };
      }),
  ];

  const goToEditPageWithModalId = goToEditCustomPipelinePage({ modalId });

  yield all([
    // Putting Created hiring pipeline and stages to redux.
    put(
      enhancedHiringPipelineApi.util.updateQueryData('searchPipeline', {}, (draft) => {
        hiringPipelineAdapter.addOne(draft.pipeline, hiringPipelinesModel.HiringPipeline(storedPipeLine));
        hiringPipelineStagesAdapter.addMany(draft.pipelineStages, result.hiringPipelineStagesTemporary as any);
      }) as any,
    ),
    // Change modal wizard page to the Edit Custom Pipeline.
    put(
      goToEditPageWithModalId({
        pipelineId: result.pipelineId!,
      }),
    ),
    call(stopLoader, newNameForHiringPipeline),
  ]);

  // Restart the main saga flow
  return { ...result };
  // -------------------------------------------------------------------------------------------------
}

export function* prepareActionsForStage(
  pipelineStageId: string,
  pipelineId: string,
  hiringPipelineStages: hiringPipelineStagesTypes.HiringPipelineStage[],
  hiringPipelineStagesTemporary: hiringPipelineStagesTypes.HiringPipelineStage[],
) {
  const result = {
    hiringPipelineStagesTemporary,
  };

  if (hiringPipelineStages.some((hiringPipelineStage) => hiringPipelineStage.pipelineStageId === pipelineStageId)) {
    yield call(startLoader, hiringPipelineStageActions.actionFetch(pipelineStageId));

    const mutationGetActions = yield call(enhancedHiringPipelineApi.endpoints.stageGetActions.initiate, {
      pipelineId,
      pipelineStageId,
    });
    const { data: pipelineStageActions } = yield putResolve(mutationGetActions);

    if (pipelineStageActions?.length) {
      yield put(
        enhancedHiringPipelineApi.util.updateQueryData('searchPipeline', {}, (draft) => {
          hiringPipelineStagesAdapter.updateOne(draft.pipelineStages, {
            id: pipelineStageId,
            changes: { actions: pipelineStageActions },
          });
        }) as any,
      );

      result.hiringPipelineStagesTemporary = hiringPipelineStagesTemporary.map((stage) => {
        if (stage.pipelineStageId !== pipelineStageId) {
          return stage;
        }

        const newActions = [...(stage.actions ?? []), ...pipelineStageActions];

        return { ...stage, actions: uniqBy(newActions, 'pipelineActionId') };
      });
    }
  }

  yield call(stopLoader, hiringPipelineStageActions.actionFetch(pipelineStageId));

  return result.hiringPipelineStagesTemporary;
}

export function getPipelineButtonLabels(
  hiringPipelineStagesTemporary: hiringPipelineStagesTypes.HiringPipelineStage[],
) {
  return {
    firstStageButtonLabel: hiringPipelineStagesTemporary[1].stageButtonLabel ?? null,
    declineButtonLabel:
      hiringPipelineStagesTemporary.find((h) => h.stageType === PipelineStageType.Declined)?.stageButtonLabel ?? null,
  };
}

export function updateHiringPipelineStages(
  hiringPipelineStagesTemporary: hiringPipelineStagesTypes.HiringPipelineStage[],
  pipelineStageUpdated: HiringPipelineStagePrepareEdit,
) {
  return [...hiringPipelineStagesTemporary].map((pipelineStage) => {
    if (pipelineStage.pipelineStageId === pipelineStageUpdated.pipelineStageId) {
      return {
        ...pipelineStage,
        ...pipelineStageUpdated,
      };
    }

    return pipelineStage;
  });
}

export function* cannotRemovePipelineStage(pipelineId: string, pipelineStageId: string) {
  const isPipelineStagesInUse = yield select(
    hiringPipelinesStagesSelectors.selectHiringPipelineStageInUse,
    pipelineStageId,
  );

  const hasEnoughRequiredCustomStages = yield select(
    hiringPipelinesStagesSelectors.checkHasEnoughRequiredCustomStages,
    pipelineId,
  );

  const isPipelineStagesRequiredCustom = yield select(
    hiringPipelinesStagesSelectors.selectHiringPipelineStageIsRequiredCustom,
    pipelineStageId,
  );

  return isPipelineStagesInUse || (isPipelineStagesRequiredCustom && !hasEnoughRequiredCustomStages);
}
