import React from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import styled, { useTheme } from 'styled-components';

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 1040;

  &.modal-transition-enter {
    opacity: 0;
  }

  &.modal-transition-enter-active {
    transition: opacity ${(props) => props.theme.animations.duration}ms;
    opacity: 1;
  }

  &.modal-transition-exit {
    opacity: 1;
  }

  &.modal-transition-exit-active {
    transition: opacity ${(props) => props.theme.animations.duration}ms;
    opacity: 0;
  }
`;

export const ModalWrapper = styled.div`
  margin-left: auto;
  background-color: white;
  width: 100%;
  max-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: visible;

  .modal-transition-enter & {
    transform: translateX(100%);
  }

  .modal-transition-enter-active & {
    transition: transform ${(props) => props.theme.animations.duration}ms;
    transform: translateX(0);
  }

  .modal-transition-exit & {
    transform: translateX(0);
  }

  .modal-transition-exit-active & {
    transition: transform ${(props) => props.theme.animations.duration}ms;
    transform: translateX(100%);
  }
`;

export const ModalHeader = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.border.borderColor};
  padding: 22px 16px 18px;
  display: flex;
  align-items: center;
`;

export const ModalTitle = styled.div`
  font-weight: bold;
`;

export const ModalBody = styled.div`
  overflow-y: auto;
  overflow-x: visible;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 22px;
`;

export const ModalActionWrapper = styled.div`
  padding: 22px 16px 30px;
`;

export const ModalCSSTransition: React.FC<{ in: boolean }> = (props) => {
  const theme = useTheme();
  return (
    <CSSTransition
      in={props.in}
      className="modal-transition"
      classNames="modal-transition"
      unmountOnExit
      timeout={theme.animations.duration}
    >
      {props.children}
    </CSSTransition>
  );
};
