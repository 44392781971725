import * as yup from 'yup';
import { isPast, isToday, parse } from 'date-fns';

import {
  descriptionValidationSchema,
  requirementsValidationSchemaNotRequired,
  responsibilitiesValidationSchemaNotRequired,
} from 'containers/JobForms/validators';

import { emptyStringOrZeroToUndefined, emptyStringToUndefined } from 'utils/validator-helpers';

import { JobAd } from 'store/entities/job-ads/models';

const jobAdJobIdValidator = yup
  .string()
  .required(({ label }) => `${label} is required.`)
  .label('Job');

const jobAdJobBoardsIdValidator = yup
  .array()
  .of(yup.string().ensure())
  .required(({ label }) => `${label} is required.`)
  .min(1, ({ label }) => `${label} is required.`)
  .label('Job Boards');

export const validationSchemaNewJobAdsFirstStep: yup.SchemaOf<Pick<JobAd, 'jobId'>> = yup.object().shape({
  jobId: jobAdJobIdValidator,
});

export const validationSchemaNewJobAdsSecondStep: yup.SchemaOf<Pick<JobAd, 'jobBoardIds'>> = yup.object().shape({
  jobBoardIds: jobAdJobBoardsIdValidator,
});

export const validationSchemaNewJobAdDraft: yup.SchemaOf<Pick<JobAd, 'jobBoardIds' | 'jobId'>> = yup.object().shape({
  jobId: jobAdJobIdValidator,
  jobBoardIds: jobAdJobBoardsIdValidator,
});

const jobAdId = yup.string().notRequired();
const employmentType = yup.number().label('Employment type').required().transform(emptyStringToUndefined);
const sector = yup.number().required().label('Sector').transform(emptyStringOrZeroToUndefined);
const startDate = yup
  .string()
  .label('End Date')
  .when('id', {
    is: (v) => !v,
    then: yup
      .string()
      .transform(emptyStringOrZeroToUndefined)
      .test(
        'startDate',
        ({ label }) => `${label} cannot be in the past`,
        (ov) => {
          const date = parse(ov!, 'yyyy-MM-dd', new Date());
          return !isPast(date) || isToday(date);
        },
      )
      .transform(emptyStringOrZeroToUndefined),
    otherwise: yup.string().transform(emptyStringOrZeroToUndefined),
  });
const responsibilities = responsibilitiesValidationSchemaNotRequired.label('Job Ad Responsibilities').nullable();
const requirements = requirementsValidationSchemaNotRequired.label('Job Ad Requirements').nullable();
const description = descriptionValidationSchema.label('Job Ad Description');
const jobAdLocation = yup.object().nullable(true).label('Location').required();
const jobAdName = yup.string().required().label('Job Ad Name').transform(emptyStringOrZeroToUndefined);

const jobAdValidationObject = {
  id: jobAdId,
  employmentType,
  sector,
  country: yup.string().required().label('Country').nullable(true),
  jobAdLocation,
  startDate,
  description,
  requirements,
  responsibilities,
  jobAdName,
};

export const validationSchemaNewJobAdsThirdStep: yup.SchemaOf<
  Partial<
    Pick<
      JobAd,
      | 'id'
      | 'employmentType'
      | 'sector'
      | 'startDate'
      | 'jobAdLocation'
      | 'description'
      | 'responsibilities'
      | 'requirements'
      | 'jobAdName'
    >
  >
> = yup.object().shape(jobAdValidationObject);

export const editJobAdDetailsValidationSchema: yup.SchemaOf<
  Partial<
    Pick<
      JobAd,
      | 'id'
      | 'employmentType'
      | 'sector'
      | 'startDate'
      | 'jobAdLocation'
      | 'description'
      | 'responsibilities'
      | 'requirements'
      | 'jobAdName'
    >
  >
> = yup.object({ ...jobAdValidationObject });

export const validationSchemaPostJobAd: yup.SchemaOf<
  Pick<
    JobAd,
    | 'jobAdId'
    | 'sector'
    | 'startDate'
    | 'jobAdLocation'
    | 'employmentType'
    | 'description'
    | 'responsibilities'
    | 'requirements'
    | 'name'
  >
> = yup.object({
  jobAdId,
  sector,
  startDate,
  employmentType,
  responsibilities,
  requirements,
  description,
  jobAdLocation,
  name: jobAdName,
});

export const validationSchemaNewJobAds: yup.SchemaOf<Partial<Pick<JobAd, 'id'>>> = yup.object({
  id: yup.string().transform(emptyStringOrZeroToUndefined),
});

export const validationSchemaNewJobAdsSteps = validationSchemaNewJobAdsFirstStep
  .concat(validationSchemaNewJobAdsSecondStep)
  .concat(validationSchemaNewJobAdsThirdStep);
