import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import { AvailabilityType } from 'model/api-enums.constants';

import { FormDate } from 'components/FormDate';
import { FormInput } from 'components/FormInput';
import { FormSelect } from 'components/FormSelect';
import { LocationServiceForm } from 'components/LocationServiceForm';
import { ExMobileInputWithCountry } from 'components/ui/ExMobileInputWithCountry';
import { useAvailabilityTypeOption } from 'utils/hooks/formsHooks/useAvailabilityTypeOption';

import { Candidate, SocialNetwork } from 'store/entities/candidates/models';

const CandidateDetailsFormBody: React.FC<any> = (_) => {
  const { register, errors, watch, formState } = useFormContext<Candidate>();
  const validated = formState.isSubmitted;
  const availability = watch('candidateAvailability');
  const availabilityChosen = availability?.availabilityType === AvailabilityType.ActualDate;
  const availabilityTypeOptions = useAvailabilityTypeOption();

  useEffect(() => {
    register('doNotHire');
  }, [register]);

  const visibleSocialNetworks = [SocialNetwork.LinkedIn, SocialNetwork.Github, SocialNetwork.Website];

  return (
    <div className="row">
      <FormInput
        className="col-md-6"
        label="First Name"
        name="firstName"
        required
        errors={errors?.firstName}
        validated={validated}
        inputRef={register}
      />
      <FormInput
        className="col-md-6"
        label="Last Name"
        name="lastName"
        required
        errors={errors?.lastName}
        validated={validated}
        inputRef={register}
      />
      <FormInput
        className="col-md-6"
        label="Email"
        name="email"
        required
        errors={errors?.email}
        validated={validated}
        inputRef={register}
      />
      <FormSelect
        label="Availability"
        name="candidateAvailability.availabilityType"
        className={availabilityChosen ? 'col-md-3' : 'col-md-6'}
        placeholder=""
        errors={errors?.availability}
        validated={validated}
        inputRef={register}
        options={availabilityTypeOptions}
        isSearchable={false}
      />
      {availabilityChosen && (
        <FormDate
          className="col-md-3"
          label="Specific Date"
          name="candidateAvailability.actualDate"
          min={new Date()}
          errors={errors?.candidateAvailability?.actualDate}
          validated={validated}
          inputRef={register}
        />
      )}
      <ExMobileInputWithCountry className="col-md-6" name="phone" label="Phone" />
      <ExMobileInputWithCountry className="col-md-6" name="mobile" label="Mobile" />
      <FormInput
        className="col-md-12"
        label="Address"
        name="address"
        errors={errors?.address}
        validated={validated}
        inputRef={register}
      />
      <LocationServiceForm
        countryClassNames="col-md-6"
        locationClassNames="col-md-6"
        countryFieldName="country"
        locationFieldName="location"
      />
      {visibleSocialNetworks.map((socialNetwork) => (
        <div className="col-md-4" key={socialNetwork}>
          <FormInput
            className="d-none"
            label={SocialNetwork[socialNetwork]}
            placeholder="http://"
            name={`socialNetworks[${socialNetwork - 1}].type`}
            inputRef={register}
            defaultValue={socialNetwork}
            type="number"
          />
          <FormInput
            label={SocialNetwork[socialNetwork]}
            placeholder="http://"
            name={`socialNetworks[${socialNetwork - 1}].url`}
            inputRef={register}
            errors={get(errors, `socialNetworks[${socialNetwork - 1}].url`)}
            validated={validated}
          />
        </div>
      ))}
    </div>
  );
};

export default CandidateDetailsFormBody;
