import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import type { ExInterviewTemplate, ExInterviewTemplateQuestion, ExQuestion } from 'model';
import difference from 'lodash/difference';

import { providesList } from 'api-client/axiosBaseQuery';
import { ExAxiosBaseQuery } from 'api-client/axiosBaseQueryTypes';
import { replaceParamsInUrl } from 'api-client/utils';
import { InterviewTemplateEndpoints } from 'api-endpoints/interview-template/interview-template.endpoints';
import * as fromInterviewTemplateQuestion from 'api-endpoints/interview-template-question';

import { interviewTemplateEditFormSelectors } from 'containers/InterviewTemplateForms/InterviewTemplateEditForm/state/InterviewTemplateEditForm.selectors';

import { consoleErrorForDevEnv } from 'utils/consoleErrorForDevEnv';

import {
  INTERVIEW_TEMPLATE_TAG_TYPE,
  interviewTemplateApi,
} from 'store/entities/interview-template/interview-template.api';
import { RootState } from 'store/rootReducer';
import { companyInterviewTemplateUiSlice } from 'store/ui/company/company-interview-template';

const prepareChoicesFromApi = (choices: ExInterviewTemplateQuestion['choices']) =>
  choices?.map((choice) => {
    return { value: choice, selected: false };
  }) || [];

const transformQuestion = (
  question: fromInterviewTemplateQuestion.GetInterviewTemplateQuestionsFuncResponse[number],
): ExQuestion => {
  return {
    questionId: question.interviewTemplateQuestionId,
    questionText: question.questionText,
    questionType: question.questionType,
    choices: prepareChoicesFromApi(question.choices),
    interviewQuestionFiles: [],
    isMandatory: question.isMandatory,
    additionalInformation: question.additionalInformation,
  };
};

export const interviewTemplateQuestionsAdapter = createEntityAdapter<ExQuestion>({
  selectId: (item) => item.questionId,
});

const interviewTemplateQuestionsApiInject = interviewTemplateApi.injectEndpoints({
  endpoints: (builder) => ({
    updateDraftInterviewTemplate: builder.mutation<
      { success: boolean },
      { publish?: boolean; interviewTemplateId?: string }
    >({
      queryFn: async (arg, { getState }, meta, baseQuery) => {
        const error = { success: false };
        const rootState = getState() as RootState;
        const initialData = interviewTemplateEditFormSelectors.initialData(rootState);
        const formData = interviewTemplateEditFormSelectors.formData(rootState);

        const toApiTemplate = formData.interviewTemplate;
        const questionOrder = [...formData.questions.ids] as string[];

        const questionsIdsForRemove = difference(initialData.questions.ids, formData.questions.ids);
        const questionsIdsForAdd = difference(formData.questions.ids, initialData.questions.ids);
        const questionsIdsForUpdate = difference(formData.questions.ids, [
          ...questionsIdsForRemove,
          ...questionsIdsForAdd,
        ]);

        const promises: Record<string, ExAxiosBaseQuery<any>[]> = { add: [], remove: [], update: [] };

        questionsIdsForRemove.forEach((id) => {
          const promise = baseQuery({
            method: 'DELETE',
            url: replaceParamsInUrl(
              fromInterviewTemplateQuestion.InterviewTemplateQuestion.DeleteInterviewTemplateQuestionFunc,
              {
                interviewTemplateId: toApiTemplate?.interviewTemplateId!,
                interviewTemplateQuestionId: id as string,
              },
            ),
          });
          promises.remove.push(promise);
        });

        const indexesIds: number[] = [];
        questionsIdsForAdd.forEach((id) => {
          const question = formData.questions.entities[id]!;
          const idx = questionOrder.findIndex((i) => i === id);
          indexesIds.push(idx);
          const transformedChoices = question.choices?.map((choice) => {
            return choice.value;
          });
          const promise = baseQuery({
            method: 'POST',
            url: replaceParamsInUrl(
              fromInterviewTemplateQuestion.InterviewTemplateQuestion.AddInterviewTemplateQuestionFunc,
              {
                interviewTemplateId: toApiTemplate?.interviewTemplateId!,
              },
            ),
            data: {
              ...question,
              choices: transformedChoices,
            },
          });
          promises.add.push(promise);
        });

        questionsIdsForUpdate.forEach((id) => {
          const form = formData.questions.entities[id]!;
          const init = initialData.questions.entities[id]!;
          if (JSON.stringify(form) !== JSON.stringify(init)) {
            const transformedChoices = form.choices?.map((choice) => {
              return choice.value;
            });
            const promise = baseQuery({
              method: 'PUT',
              url: replaceParamsInUrl(
                fromInterviewTemplateQuestion.InterviewTemplateQuestion.UpdateInterviewTemplateQuestionFunc,
                {
                  interviewTemplateId: toApiTemplate?.interviewTemplateId!,
                  interviewTemplateQuestionId: id as string,
                },
              ),
              data: {
                ...form,
                choices: transformedChoices,
              },
            });
            promises.update.push(promise);
          }
        });
        const promisesResultRemoveAndUpdate = await Promise.all([...promises.remove, ...promises.update]);

        if (promisesResultRemoveAndUpdate.some((result) => !!result.error)) {
          return { error };
        }

        const promisesResultCreate = await Promise.all([...promises.add]);
        if (promisesResultCreate.some((result) => !!result.error)) {
          return { error };
        }

        promisesResultCreate.forEach(({ data }, index) => {
          const idx = indexesIds[index];
          questionOrder.splice(idx, 1, data.interviewTemplateQuestionId);
        });

        if (JSON.stringify(questionOrder) !== JSON.stringify(initialData.questions.ids)) {
          const resultOrderUpdate = await baseQuery({
            method: 'PUT',
            url: replaceParamsInUrl(InterviewTemplateEndpoints.UpdateInterviewTemplateQuestionsOrderFunc, {
              interviewTemplateId: toApiTemplate?.interviewTemplateId!,
            }),
            data: { questionOrder, interviewTemplateId: toApiTemplate?.interviewTemplateId! },
          });

          if (resultOrderUpdate.error) {
            return { error };
          }
        }

        const resultTemplateUpdate = await baseQuery({
          url: replaceParamsInUrl(InterviewTemplateEndpoints.UpdateInterviewTemplateFunc, {
            interviewTemplateId: toApiTemplate?.interviewTemplateId!,
          }),
          method: 'PUT',
          data: toApiTemplate,
        });

        if (resultTemplateUpdate.error) {
          return { error };
        }

        return { data: { success: true } };
      },
    }),
    deleteQuestion: builder.mutation<
      fromInterviewTemplateQuestion.DeleteInterviewTemplateQuestionFuncResponse,
      fromInterviewTemplateQuestion.DeleteInterviewTemplateQuestionFuncRequestParams
    >({
      query: (arg) => ({
        method: 'DELETE',
        url: replaceParamsInUrl(
          fromInterviewTemplateQuestion.InterviewTemplateQuestion.DeleteInterviewTemplateQuestionFunc,
          {
            interviewTemplateId: arg.interviewTemplateId,
            interviewTemplateQuestionId: arg.interviewTemplateQuestionId,
          },
        ),
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const patches = dispatch(
          interviewTemplateQuestionsApiInject.util.updateQueryData('questionList', arg.interviewTemplateId, (draft) => {
            interviewTemplateQuestionsAdapter.removeOne(draft, arg.interviewTemplateQuestionId);
          }),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patches.undo();
        }
      },
    }),
    questionList: builder.query<EntityState<ExQuestion>, ExInterviewTemplate['interviewTemplateId']>({
      query: (interviewTemplateId) => ({
        url: replaceParamsInUrl(
          fromInterviewTemplateQuestion.InterviewTemplateQuestion.GetInterviewTemplateQuestionsFunc,
          { interviewTemplateId },
        ),
        method: 'GET',
      }),
      transformResponse: (data: fromInterviewTemplateQuestion.GetInterviewTemplateQuestionsFuncResponse) => {
        const transformedData = data.map(transformQuestion);
        return interviewTemplateQuestionsAdapter.addMany(
          interviewTemplateQuestionsAdapter.getInitialState({}),
          transformedData,
        );
      },
    }),
    createQuestion: builder.mutation<
      fromInterviewTemplateQuestion.AddInterviewTemplateQuestionFuncResponse,
      fromInterviewTemplateQuestion.AddInterviewTemplateQuestionFuncRequestParams
    >({
      query: (arg) => ({
        method: 'POST',
        url: replaceParamsInUrl(
          fromInterviewTemplateQuestion.InterviewTemplateQuestion.AddInterviewTemplateQuestionFunc,
          {
            interviewTemplateId: arg.interviewTemplateId,
          },
        ),
        data: arg,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          const questionCreated = transformQuestion({ ...arg, ...data });
          dispatch(
            interviewTemplateQuestionsApiInject.util.updateQueryData(
              'questionList',
              arg.interviewTemplateId,
              (draft) => {
                interviewTemplateQuestionsAdapter.addOne(draft, questionCreated);
              },
            ),
          );
        } catch (error) {
          consoleErrorForDevEnv(error);
        }
      },
    }),
    updateQuestion: builder.mutation<
      void,
      fromInterviewTemplateQuestion.UpdateInterviewTemplateQuestionFuncRequestParams
    >({
      query: (arg) => ({
        method: 'PUT',
        url: replaceParamsInUrl(
          fromInterviewTemplateQuestion.InterviewTemplateQuestion.UpdateInterviewTemplateQuestionFunc,
          {
            interviewTemplateId: arg.interviewTemplateId,
            interviewTemplateQuestionId: arg.interviewTemplateQuestionId,
          },
        ),
        data: arg,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const patches = dispatch(
          interviewTemplateQuestionsApiInject.util.updateQueryData('questionList', arg.interviewTemplateId, (draft) => {
            interviewTemplateQuestionsAdapter.updateOne(draft, {
              id: arg.interviewTemplateQuestionId,
              changes: {
                ...arg,
                choices: prepareChoicesFromApi(arg.choices),
              },
            });
          }),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(companyInterviewTemplateUiSlice.actions.setEditQuestionId(arg.interviewTemplateQuestionId));
          patches.undo();
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const interviewTemplateQuestionsApi = interviewTemplateQuestionsApiInject.enhanceEndpoints({
  addTagTypes: ['Questions'],
  endpoints: {
    updateDraftInterviewTemplate: {
      invalidatesTags: (_, __, args) => [
        { type: 'Questions', id: 'LIST' },
        { type: INTERVIEW_TEMPLATE_TAG_TYPE, id: args.interviewTemplateId },
      ],
    },
    deleteQuestion: {
      invalidatesTags: (_, __, args) => [
        { type: INTERVIEW_TEMPLATE_TAG_TYPE, id: args.interviewTemplateId },
        { type: 'Questions', id: args.interviewTemplateQuestionId },
      ],
    },
    questionList: {
      providesTags: (result) => providesList(result?.ids, 'Questions'),
    },
    createQuestion: {
      invalidatesTags: (_, __, { interviewTemplateId }) => [
        { type: INTERVIEW_TEMPLATE_TAG_TYPE, id: interviewTemplateId },
        { type: 'Questions', id: 'LIST' },
      ],
    },
    updateQuestion: {
      invalidatesTags: (_, error, args) =>
        !error ? [{ type: 'Questions', id: args.interviewTemplateQuestionId }] : [],
    },
  },
});

export const { useQuestionListQuery, useUpdateDraftInterviewTemplateMutation } = interviewTemplateQuestionsApi;
