import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApplicantModalState } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/models';
import { createInitialState } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/store/ApplicantModal.initialState';

export const applicantModalSlice = createSlice({
  name: 'applicantModal',
  initialState: createInitialState(),
  reducers: {
    reset: () => createInitialState(),
    toggleEdit: (state, action: PayloadAction<ApplicantModalState['isEditMode'] | undefined>) => {
      state.isEditMode = action.payload ?? !state.isEditMode;
    },
    loadNextApplicant: (state, action: PayloadAction<'prev' | 'next'>) => {
      const direction = action.payload;
      if (direction === 'next') {
        const nextIndex = state.inListPosition + 1;
        state.inListPosition = nextIndex > state.totalApplicantsCount ? 1 : nextIndex;
      } else {
        const nextIndex = state.inListPosition - 1;
        state.inListPosition = nextIndex === 0 ? state.totalApplicantsCount : nextIndex;
      }
    },
    set: (
      state,
      action: PayloadAction<
        Partial<
          Pick<
            ApplicantModalState,
            | 'apiErrors'
            | 'totalApplicantsCount'
            | 'inListPosition'
            | 'currentApplicant'
            | 'currentApplicantId'
            | 'formState'
            | 'listId'
            | 'numberOfComments'
          >
        >
      >,
    ) => ({ ...state, ...action.payload }),
  },
});
