import type { CompanyInfo } from 'store/company/company.types';
import { Applicant } from 'store/entities/applicants';

type UseDownloadApplicantFileLinkParams = {
  baseUrl: string;
  companyId: CompanyInfo['companyId'] | null;
  applicantId: Applicant['applicantId'];
  fileId: string | null;
};

/**
 * Function generate link for download applicant file
 *
 * @export
 * @param {Partial<UseDownloadApplicantFileLinkParams>} {
 *   baseUrl,
 *   companyId,
 *   applicantId,
 *   fileId,
 * }
 * @return {string | null}  {string | null} Link for download file
 */
export function useDownloadApplicantFileLink(props: Partial<UseDownloadApplicantFileLinkParams>): string | null {
  if (Object.values(props).some((value) => value === null || value === undefined)) {
    return null;
  }

  const { baseUrl, companyId, applicantId, fileId } = props;

  return `${baseUrl}/api/company/${companyId}/applicant/${applicantId}/file/${fileId}`;
}
