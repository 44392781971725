import { list } from 'config/list';

import { JobBoardBelongsTo, JobBoardStatus } from 'store/entities/job-boards';

import { InitJobBoardListsState, JobBoardListState } from './models';

export const initialListState: Omit<JobBoardListState, 'id'> = {
  items: [],
  selectedItems: [],
  pageNo: 0,
  pageSize: list.pageSize,
  pageCount: 0,
  totalItemsCount: 0,
  sortMode: {},
  viewMode: list.viewMode,
  filters: {
    status: JobBoardStatus.subscribed,
  },
  fetched: false,
  pagination: {},
};

export const initialState: InitJobBoardListsState = {
  [JobBoardBelongsTo.main]: { id: JobBoardBelongsTo.main, ...initialListState },
  [JobBoardBelongsTo.newJobAd]: {
    ...initialListState,
    id: JobBoardBelongsTo.newJobAd,
    filters: {
      status: JobBoardStatus.subscribed,
    },
  },
  [JobBoardBelongsTo.new]: {
    ...initialListState,
    id: JobBoardBelongsTo.new,
    filters: {
      status: JobBoardStatus.unsubscribed,
    },
  },
} as Record<JobBoardListState['id'], JobBoardListState>;
