import { fork, put, take } from 'redux-saga/effects';

import { bulkApplyFromResumesFunc } from 'api-endpoints/job';

import { bulkUploadResumes } from 'containers/BulkCreation/bulkCreation.actions';
import { generateUploadMessages, prepareFormData, uploadResumes } from 'containers/BulkCreation/utils/utils.sagas';

import { invokeApiCall, invokeExModalWizard, prepareExModalChannel, ReturnData } from 'utils/sagas';

import { ModalWizardPageNames } from 'store/modals/config';
import { updateWizardPage } from 'store/modals/modals.actions';

export function* bulkCreationApplicantsSaga() {
  const { modalId, sagaChannel } = yield prepareExModalChannel();
  const page: ModalWizardPageNames<'bulkCreation'> = 'createApplicants';

  // Start modal wizard
  yield fork(invokeExModalWizard, {
    channel: sagaChannel,
    modalId,
    modalProps: {},
    modalConfig: {
      wizardType: 'bulkCreation',
      page,
      content: {
        title: 'Bulk Create Applicants',
        withTitle: true,
      },
    },
  });

  const upload: ReturnType<typeof bulkUploadResumes> = yield take(bulkUploadResumes);

  const { files, selectedJob } = upload.payload;
  const jobId = selectedJob?.jobId;

  const { channel, onUploadProgress, formData } = prepareFormData(files);

  yield fork(uploadResumes as any, channel, modalId);

  const { data, errorData, message }: ReturnData<typeof bulkApplyFromResumesFunc> = yield invokeApiCall(
    bulkApplyFromResumesFunc,
    {
      data: formData,
      urlParams: { jobId: jobId! },
      onUploadProgress,
    },
  );

  if (!errorData && !message && data) {
    const { errorsCount, messageSuccessUploaded, successPageMessage } = generateUploadMessages(
      data,
      files.length,
      'applicants',
    );

    const variant = Boolean(errorsCount) ? 'error' : 'success';

    yield put(
      updateWizardPage({
        id: modalId,
        page: 'success',
        modalConfig: { content: { withTitle: false } },
        modalProps: { ...data, message: successPageMessage, variant, messageSuccessUploaded },
      }),
    );
  }
}
