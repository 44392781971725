import { AxiosRequestConfig, CancelToken } from 'axios';
import { compose } from 'redux';

import { api } from 'api-client';

type BaseRequestParams = Pick<
  AxiosRequestConfig,
  'urlParams' | 'params' | 'data' | 'method' | 'transformRequest' | 'transformResponse' | 'baseURL'
>;

export function getApiMappedFunction<P, R, T>(
  apiCall: (p: P) => R,
  transformRequest: (p: P) => P,
  transformResponse: (p: R) => T,
) {
  return compose<P, ReturnType<typeof apiCall>, P, T>(transformResponse, apiCall, transformRequest);
}

type TokenObject = { cancelToken?: CancelToken };

export type WithCancelToken<T extends object = TokenObject> = T extends TokenObject ? TokenObject : T & TokenObject;

function createRequest<T>(config: AxiosRequestConfig) {
  return api().request<T>(config);
}

type CreateApiMethodParams = {
  url: string;
  method?: BaseRequestParams['method'];
  transformRequest?: BaseRequestParams['transformRequest'];
  transformResponse?: BaseRequestParams['transformResponse'];
  baseURL?: BaseRequestParams['baseURL'];
};

/**
 * Api method factory.
 * To create method we must pass required parameter {url}. All other params are not required.
 * This factory will returns function which will await for params that will spread into axios config.
 *
 * @param {string} url
 * @param {Method} method
 *
 * @returns {Function}
 */
export function createApiMethod<P extends BaseRequestParams, R>({ url, method }: CreateApiMethodParams) {
  return function (params?: P) {
    return createRequest<R>({
      url,
      ...params,
      method,
    });
  };
}
