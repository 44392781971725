import { createSelector } from '@reduxjs/toolkit';

import { authSelectors } from 'store/auth/auth.selectors';
import { companySelectors } from 'store/company/company.selectors';
import { entitiesDomain } from 'store/rootSelectors';

import { applicantCommentsAdapter, applicantCommentsSlice } from './applicant-comments.reducer';
import { makeExCommentView } from './applicant-comments.utils';

const domain = createSelector(entitiesDomain, (entities) => entities[applicantCommentsSlice.name]);

const applicantCommentsAdapterSelectors = applicantCommentsAdapter.getSelectors(domain);

const selectCurrent = createSelector(domain, ({ jobId, applicantId, lastLoadedPage, pageCount }) => ({
  applicantId,
  jobId,
  lastLoadedPage,
  pageCount,
}));

const selectCommentView = createSelector(
  applicantCommentsAdapterSelectors.selectById,
  authSelectors.apiUser,
  companySelectors.getDefaultDateFormat,
  makeExCommentView,
);

const isHasMore = createSelector(domain, ({ lastLoadedPage, pageCount }) => Boolean(lastLoadedPage < pageCount - 1));

export const applicantCommentsSelectors = {
  ...applicantCommentsAdapterSelectors,
  isHasMore,
  selectCommentView,
  selectCurrent,
};
