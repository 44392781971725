import React from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

import { SourceEntity } from 'model';

import { CheckableList } from 'components/Form/CheckableList';
import { StepFormBody } from 'components/StepForm';

import { Job } from 'store/entities/jobs';

export interface JobResponsibilitiesFormBodyProps extends StepFormBody {
  className?: string;
  formData?: Partial<Job>;
}

export const JobResponsibilitiesFormBodyStyled = styled.div``;

export const JobResponsibilitiesFormBody: React.FC<JobResponsibilitiesFormBodyProps> = ({
  className,
  formData,
  isStep,
}) => {
  const {
    field: { ref, onChange },
  } = useController({ name: 'responsibilities' });

  return (
    <JobResponsibilitiesFormBodyStyled className={className}>
      <CheckableList
        items={formData?.responsibilities ?? []}
        onChange={onChange}
        placeholder="Add new responsibility"
        variant="primary"
        disabled={!isStep}
        sourceEntity={SourceEntity.Job}
        name="responsibilities"
        inputRef={ref}
        selectAllTitle="Responsibilities"
        required
      />
    </JobResponsibilitiesFormBodyStyled>
  );
};
