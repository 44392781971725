import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';

import { api } from 'api-client';
import { ApplicantListResponseItem } from 'api-endpoints/applicant/models';
import { CandidateEndpoints } from 'api-endpoints/candidate/candidate.endpoints';
import {
  AddCandidateToTalentPoolsData,
  CandidateAvatarResponseDto,
  CandidateItemResponse,
  CandidateListItemResponse,
  EducationAttachmentDto,
  KeywordsRequestParams,
  PostCandidateParams,
  PutCandidateParams,
} from 'api-endpoints/candidate/models';
import { CandidateTalentPool } from 'api-endpoints/talent-pool/models';
import { createApiMethod, getApiMappedFunction } from 'api-endpoints/utils';

import { ApiResponseWithPagination, AutocompleteResponse, Location, SearchAutocompleteOptions } from 'model';
import { AvailabilityType } from 'model/api-enums.constants';
import { WithRequired } from 'model/utils';

import { ApplicantListParams } from 'containers/ApplicantLists/store/models';
import { CandidateListParams } from 'containers/CandidateLists/store/models';

import { requestMapperUtil, responseMapperUtil } from 'utils/api';
import { curry } from 'utils/funcs';
import { getGlobalGetState } from 'utils/globalStore';
import { createUrlWithParams } from 'utils/url';

import { CandidateInfoRequestParams } from 'store/app-files/appFiles.models';
import { DocumentFile } from 'store/app-files/documents/documents.models';
import { ResumeFile } from 'store/app-files/resumes/resumes.models';
import { authSelectors } from 'store/auth/auth.selectors';
import { SortDirections } from 'store/constants';
import { educationDateRequestMapper } from 'store/entities/candidate-education/api/request.mappers';
import {
  CreateCandidateEducationRequestParams,
  DeleteCandidateEducationRequestParams,
  GetCandidateEducationRequestParams,
  UpdateCandidateEducationRequestParams,
  UploadCandidateEducationAttachmentRequestParams,
} from 'store/entities/candidate-education/api/requests';
import {
  CandidateEducation,
  CandidateEducationCreateResponseDto,
  CandidateEducationDeleteResponseDto,
  CandidateEducationDto,
  CandidateEducationUpdateResponseDto,
} from 'store/entities/candidate-education/candidate-education.models';
import { workExperienceDateRequestMapper } from 'store/entities/candidate-work-experience/api/request.mappers';
import {
  CreateCandidateWorkExperienceRequestParams,
  GetCandidateWorkExperienceRequestParams,
  GetListCandidateWorkExperienceRequestParams,
  UpdateCandidateWorkExperienceRequestParams,
} from 'store/entities/candidate-work-experience/api/requests';
import { workExperienceResponseMapper } from 'store/entities/candidate-work-experience/api/response.mappers';
import {
  CandidateWorkExperience,
  CandidateWorkExperienceCreateResponseDto,
  CandidateWorkExperienceDto,
  CandidateWorkExperienceGetResponseDto,
  CandidateWorkExperienceUpdateResponseDto,
} from 'store/entities/candidate-work-experience/candidate-work-experience.models';
import {
  Candidate,
  CandidateAvatarRemoveRequestDto,
  CandidateAvatarUploadRequestDto,
  CandidateIfoKey,
} from 'store/entities/candidates/models';
import { JobTeamMember } from 'store/entities/jobs';

import {
  AddCommentForCandidateRequestParams,
  DeleteCommentForCandidateRequestParams,
  SearchCommentsForCandidateFuncResponse,
  SearchCommentsForCandidateRequestParams,
  UpdateCommentForCandidateRequestParams,
} from './candidate.dto';

export * from 'api-endpoints/candidate/models';

const basicUrl = '/api/company/{companyId}/candidate';
const createWithBasicUrl = createUrlWithParams(basicUrl);

export const getCandidates = ({ pageNo, pageSize, filters }: WithRequired<CandidateListParams, 'filters'>) => {
  const companyId = authSelectors.selectCurrentCompanyId(getGlobalGetState());

  return api().request<ApiResponseWithPagination<CandidateListItemResponse>>({
    method: 'POST',
    url: '/api/company/{companyId}/candidate/search',
    data: {
      companyId,
      pageNo,
      pageSize,
      ...(filters.keywords?.length && { keywords: filters.keywords.map(({ value }) => value) }),
      ...(filters.locations?.length && {
        locations: filters.locations.map((location: Location) => ({
          countryCode: location.countryCode,
          locationId: location.id,
        })),
      }),
      ...(filters.countries?.length && {
        countries: filters.countries,
      }),
      ...(filters.availabilities?.length && {
        availabilities: filters.availabilities.map((availability: AvailabilityType) => ({
          availabilityType: availability,
          ...(availability === AvailabilityType.ActualDate && {
            availabilityActualDate: filters.availabilityDate,
          }),
        })),
      }),
      ...(filters.talentPool?.length && {
        talentPoolNames: filters.talentPool.filter(Boolean).map((talentPool) => talentPool.label),
      }),
      ...(filters.name && {
        searchTerm: filters.name,
      }),
      ...(filters.sources?.length && { sources: filters.sources }),
      ...(filters.excludeJobIds?.length && { excludeJobIds: filters.excludeJobIds }),
      ...(filters.candidateIds?.length && { candidateIds: filters.candidateIds }),
    },
  });
};

export const getCandidatesAutocomplete = (searchTerm: string) =>
  api().request<AutocompleteResponse[]>({
    method: 'GET',
    url: '/api/company/{companyId}/candidate/autocomplete',
    params: {
      searchTerm,
    },
  });

export const postCandidates = (data: Partial<Candidate>) =>
  api().request<Candidate>({
    method: 'POST',
    url: basicUrl,
    data,
  });

export const getCandidateById = (candidateId: Candidate['id']) =>
  api().request<Candidate>({
    method: 'GET',
    url: basicUrl + '/' + candidateId,
  });

export const getCandidateInfoById = <T = ResumeFile | DocumentFile>(
  candidateId: Candidate['id'],
  key: CandidateIfoKey,
  params: CandidateInfoRequestParams,
  cancelToken?: CancelTokenSource,
) =>
  api().request<ApiResponseWithPagination<T>>({
    method: 'GET',
    url: [basicUrl, candidateId, key].join('/'),
    params,
    cancelToken: cancelToken?.token,
  });

export const uploadCandidateInfoById = (
  candidateId: Candidate['id'],
  key: CandidateIfoKey,
  data: FormData,
  cancelToken?: CancelTokenSource,
  config?: Partial<AxiosRequestConfig>,
) =>
  api().request<DocumentFile | ResumeFile>({
    ...config,
    method: 'POST',
    url: [basicUrl, candidateId, key].join('/'),
    data,
    cancelToken: cancelToken?.token,
  });

export const postAddCandidateToTalentPools = (
  candidateId: Candidate['id'],
  data: AddCandidateToTalentPoolsData,
  cancelToken?: CancelTokenSource,
  config?: Partial<AxiosRequestConfig>,
) =>
  api().request<void>({
    ...config,
    method: 'POST',
    url: [basicUrl, candidateId, 'talent-pool-assign'].join('/'),
    data,
    cancelToken: cancelToken?.token,
  });

export const getCandidateJobApplicants = curry(
  (candidateId: Candidate['id'], { pageNo, pageSize, sortMode }: ApplicantListParams) => {
    return api().get<ApiResponseWithPagination<ApplicantListResponseItem>>(`${basicUrl}/${candidateId}/applicant`, {
      params: {
        pageNo,
        pageSize,
        ...(sortMode?.orderBy && {
          orderBy: sortMode.orderBy,
        }),
        ...(sortMode?.orderDir && {
          orderDirection: sortMode.orderDir === SortDirections.desc ? 'Descending' : 'Ascending',
        }),
      },
    });
  },
);

export const getCandidateTalentPools = curry(
  (candidateId: Candidate['id'], { pageNo, pageSize, sortMode, filters }: ApplicantListParams) => {
    return api().get<ApiResponseWithPagination<CandidateTalentPool>>(`${basicUrl}/${candidateId}/talent-pools`, {
      params: {
        pageNo,
        pageSize,
        ...(sortMode?.orderBy && {
          orderBy: `${sortMode.orderBy}${sortMode.orderDir === SortDirections.desc ? ' descending' : ' ascending'}`,
        }),
        ...(filters?.name && { name: filters.name }),
      },
    });
  },
);

export const candidateAutocomplete = (searchTerm: string) =>
  api().request<SearchAutocompleteOptions>({
    method: 'GET',
    url: `/api/company/{companyId}/candidate/autocomplete`,
    params: { searchTerm },
  });

export const postCandidate = (data: PostCandidateParams) =>
  api().request<Pick<Candidate, 'candidateId'>>({
    method: 'POST',
    url: [basicUrl, data.candidateId, 'candidate'].join('/'),
    data,
  });

export const putCandidate = (data: PutCandidateParams) => {
  const payload = {
    candidateId: data.candidateId,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    candidateAvailability: data.candidateAvailability,
    ...(data.phone && { phone: data.phone }),
    ...(data.mobile && { mobile: data.mobile }),
    ...(data.address && { address: data.address }),
    ...(data.location && { candidateLocation: data.location }),
    ...(data.socialNetworks && { socialNetworks: data.socialNetworks }),
    ...(data.country && { country: data.country }),
    ...(data.keywords !== undefined && { keywords: data.keywords }),
    ...(data.doNotHire !== undefined && { doNotHire: data.doNotHire }),
  };
  return api().request<Pick<Candidate, 'candidateId'>>({
    method: 'PUT',
    url: [basicUrl, data.candidateId].join('/'),
    data: payload,
  });
};

export const saveCandidate = ({ data }: { data: Partial<Candidate> }) =>
  api().request<CandidateItemResponse>({
    url: basicUrl,
    method: 'POST',
    data,
  });

type DeleteCandidateResumeProps = {
  candidateId: Candidate['id'];
  resumeId: string;
  cancelToken?: CancelTokenSource;
  config?: Partial<AxiosRequestConfig>;
};

type DeleteCandidateDocumentProps = {
  candidateId: Candidate['id'];
  documentId: string;
  cancelToken?: CancelTokenSource;
  config?: Partial<AxiosRequestConfig>;
};

export const deleteCandidateResume = ({ candidateId, resumeId, cancelToken, config }: DeleteCandidateResumeProps) =>
  api().request<DocumentFile | ResumeFile>({
    ...config,
    method: 'DELETE',
    url: createWithBasicUrl([candidateId, 'resume', resumeId]),
    cancelToken: cancelToken?.token,
  });

export const deleteCandidateDocument = ({
  candidateId,
  documentId,
  cancelToken,
  config,
}: DeleteCandidateDocumentProps) =>
  api().request<DocumentFile | ResumeFile>({
    ...config,
    method: 'DELETE',
    url: createWithBasicUrl([candidateId, 'document', documentId]),
    cancelToken: cancelToken?.token,
  });

// ---------------------------------------------------------------------------------------------------------------------
export const getCandidateEducationsApiMethod = createApiMethod<
  GetCandidateEducationRequestParams,
  ApiResponseWithPagination<CandidateEducationDto>
>({
  url: CandidateEndpoints.GetCandidateEducationsFunc,
  method: 'GET',
});

export const getCandidateEducations = getApiMappedFunction<
  GetCandidateEducationRequestParams,
  ReturnType<typeof getCandidateEducationsApiMethod>,
  Promise<AxiosResponse<ApiResponseWithPagination<CandidateEducation>>>
>(getCandidateEducationsApiMethod, requestMapperUtil(), responseMapperUtil());

// ---------------------------------------------------------------------------------------------------------------------

// ---------------------------------------------------------------------------------------------------------------------
// CREATE CANDIDATE EDUCATION - CreateCandidateEducationFunc
const createCandidateEducationsApiMethod = createApiMethod<
  CreateCandidateEducationRequestParams,
  CandidateEducationCreateResponseDto
>({
  url: CandidateEndpoints.CreateCandidateEducationFunc,
  method: 'POST',
});

export const createCandidateEducations = getApiMappedFunction<
  CreateCandidateEducationRequestParams,
  ReturnType<typeof createCandidateEducationsApiMethod>,
  ReturnType<typeof createCandidateEducationsApiMethod>
>(createCandidateEducationsApiMethod, requestMapperUtil([educationDateRequestMapper]), responseMapperUtil());

// ---------------------------------------------------------------------------------------------------------------------

// ---------------------------------------------------------------------------------------------------------------------
// UPDATE CANDIDATE EDUCATION - UpdateCandidateEducationFunc
const updateCandidateEducationsApiMethod = createApiMethod<
  UpdateCandidateEducationRequestParams,
  CandidateEducationUpdateResponseDto
>({
  url: CandidateEndpoints.UpdateCandidateEducationFunc,
  method: 'PUT',
});

export const updateCandidateEducations = getApiMappedFunction<
  UpdateCandidateEducationRequestParams,
  ReturnType<typeof updateCandidateEducationsApiMethod>,
  ReturnType<typeof updateCandidateEducationsApiMethod>
>(updateCandidateEducationsApiMethod, requestMapperUtil(), responseMapperUtil());
// ---------------------------------------------------------------------------------------------------------------------

// ---------------------------------------------------------------------------------------------------------------------
// DELETE CANDIDATE EDUCATION - UpdateCandidateEducationFunc
const deleteCandidateEducationsApiMethod = createApiMethod<
  DeleteCandidateEducationRequestParams,
  CandidateEducationDeleteResponseDto
>({
  url: CandidateEndpoints.DeleteCandidateEducationFunc,
  method: 'DELETE',
});

export const deleteCandidateEducations = getApiMappedFunction<
  DeleteCandidateEducationRequestParams,
  ReturnType<typeof deleteCandidateEducationsApiMethod>,
  ReturnType<typeof deleteCandidateEducationsApiMethod>
>(deleteCandidateEducationsApiMethod, requestMapperUtil(), responseMapperUtil());
// ---------------------------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// GET CANDIDATE WORK EXPERIENCE LIST - GetCandidateWorkExperiencesFunc
export const getCandidateWorkExperiencesApiMethod = createApiMethod<
  GetCandidateWorkExperienceRequestParams,
  ApiResponseWithPagination<CandidateWorkExperienceDto>
>({
  url: CandidateEndpoints.GetCandidateWorkExperiencesFunc,
  method: 'GET',
});

export const getCandidateWorkExperienceList = getApiMappedFunction<
  GetListCandidateWorkExperienceRequestParams,
  ReturnType<typeof getCandidateWorkExperiencesApiMethod>,
  Promise<AxiosResponse<ApiResponseWithPagination<CandidateWorkExperience>>>
>(getCandidateWorkExperiencesApiMethod, requestMapperUtil(), responseMapperUtil([workExperienceResponseMapper]));

// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// CREATE CANDIDATE WORK EXPERIENCE - CreateCandidateWorkExperienceFunc
const createCandidateWorkExperienceApiMethod = createApiMethod<
  GetCandidateWorkExperienceRequestParams,
  CandidateWorkExperienceCreateResponseDto
>({
  url: CandidateEndpoints.CreateCandidateWorkExperienceFunc,
  method: 'POST',
});

export const createCandidateWorkExperience = getApiMappedFunction<
  CreateCandidateWorkExperienceRequestParams,
  ReturnType<typeof createCandidateWorkExperienceApiMethod>,
  ReturnType<typeof createCandidateWorkExperienceApiMethod>
>(createCandidateWorkExperienceApiMethod, requestMapperUtil([workExperienceDateRequestMapper]), responseMapperUtil());
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// UPDATE CANDIDATE WORK EXPERIENCE - UpdateCandidateWorkExperienceFunc
const updateCandidateWorkExperienceApiMethod = createApiMethod<
  UpdateCandidateWorkExperienceRequestParams,
  CandidateWorkExperienceUpdateResponseDto
>({
  url: CandidateEndpoints.UpdateCandidateWorkExperienceFunc,
  method: 'PUT',
});

export const updateCandidateWorkExperience = getApiMappedFunction<
  UpdateCandidateWorkExperienceRequestParams,
  ReturnType<typeof updateCandidateWorkExperienceApiMethod>,
  ReturnType<typeof updateCandidateWorkExperienceApiMethod>
>(updateCandidateWorkExperienceApiMethod, requestMapperUtil([workExperienceDateRequestMapper]), responseMapperUtil());
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// DELETE CANDIDATE WORK EXPERIENCE - DeleteCandidateWorkExperienceFunc
const deleteCandidateWorkExperienceApiMethod = createApiMethod<
  GetCandidateWorkExperienceRequestParams,
  CandidateWorkExperienceUpdateResponseDto
>({
  url: CandidateEndpoints.DeleteCandidateWorkExperienceFunc,
  method: 'DELETE',
});

export const deleteCandidateWorkExperience = getApiMappedFunction<
  GetCandidateWorkExperienceRequestParams,
  ReturnType<typeof deleteCandidateWorkExperienceApiMethod>,
  ReturnType<typeof deleteCandidateWorkExperienceApiMethod>
>(deleteCandidateWorkExperienceApiMethod, requestMapperUtil(), responseMapperUtil());
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// GET CANDIDATE WORK EXPERIENCE - GetCandidateWorkExperienceFunc
const getCandidateWorkExperienceApiMethod = createApiMethod<
  GetCandidateWorkExperienceRequestParams,
  CandidateWorkExperienceGetResponseDto
>({
  url: CandidateEndpoints.GetCandidateWorkExperienceFunc,
  method: 'GET',
});

export const getCandidateWorkExperience = getApiMappedFunction<
  GetCandidateWorkExperienceRequestParams,
  ReturnType<typeof getCandidateWorkExperienceApiMethod>,
  ReturnType<typeof getCandidateWorkExperienceApiMethod>
>(getCandidateWorkExperienceApiMethod, requestMapperUtil(), responseMapperUtil());
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// POST UPLOAD CANDIDATE AVATAR - UploadCandidatePhotoFunc
const uploadCandidateAvatarApiMethod = createApiMethod<CandidateAvatarUploadRequestDto, CandidateAvatarResponseDto>({
  url: CandidateEndpoints.UploadCandidatePhotoFunc,
  method: 'POST',
});

export const uploadCandidateAvatar = getApiMappedFunction<
  CandidateAvatarUploadRequestDto,
  ReturnType<typeof uploadCandidateAvatarApiMethod>,
  ReturnType<typeof uploadCandidateAvatarApiMethod>
>(uploadCandidateAvatarApiMethod, requestMapperUtil(), responseMapperUtil());
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// DELETE UPLOAD CANDIDATE AVATAR - UploadCandidatePhotoFunc
const deleteCandidateAvatarApiMethod = createApiMethod<CandidateAvatarRemoveRequestDto, void>({
  url: CandidateEndpoints.DeleteCandidatePhotoFunc,
  method: 'DELETE',
});

export const deleteCandidateAvatar = getApiMappedFunction<
  CandidateAvatarRemoveRequestDto,
  ReturnType<typeof deleteCandidateAvatarApiMethod>,
  ReturnType<typeof deleteCandidateAvatarApiMethod>
>(deleteCandidateAvatarApiMethod, requestMapperUtil(), responseMapperUtil());
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// POST UPLOAD CANDIDATE AVATAR - UploadCandidatePhotoFunc
const uploadCandidateAttachmentApiMethod = createApiMethod<
  UploadCandidateEducationAttachmentRequestParams,
  EducationAttachmentDto
>({
  url: CandidateEndpoints.UploadCandidateEducationAttachmentFunc,
  method: 'POST',
});

export const uploadCandidateAttachment = getApiMappedFunction<
  UploadCandidateEducationAttachmentRequestParams,
  ReturnType<typeof uploadCandidateAttachmentApiMethod>,
  ReturnType<typeof uploadCandidateAttachmentApiMethod>
>(uploadCandidateAttachmentApiMethod, requestMapperUtil(), responseMapperUtil());
// -------------------------------------------------------------------------------------------------

// GetTalentPoolKeywordsFunc
export const getTalentPoolKeywordsFunc = createApiMethod<
  { urlParams: { talentPoolId: string } } & KeywordsRequestParams,
  ApiResponseWithPagination<string>
>({
  url: CandidateEndpoints.GetTalentPoolKeywordsFunc,
});

// -------------------------------------------------------------------------------------------------
// GetJobKeywordsFunc
export const getJobKeywordsFunc = createApiMethod<
  { urlParams: { jobId: string } } & KeywordsRequestParams,
  ApiResponseWithPagination<string>
>({
  url: CandidateEndpoints.GetJobKeywordsFunc,
});
// -------------------------------------------------------------------------------------------------

// GET - SearchCommentsForCandidateFunc
export const getCommentsForCandidate = (data: SearchCommentsForCandidateRequestParams) =>
  api().request<SearchCommentsForCandidateFuncResponse>({
    data,
    method: 'GET',
    url: createWithBasicUrl([data.candidateId, 'comment']),
  });

// POST - AddCommentForCandidateFunc
export const addCommentForCandidate = (data: AddCommentForCandidateRequestParams) =>
  api().request<void>({
    data,
    method: 'POST',
    url: createWithBasicUrl([data.candidateId, 'comment']),
  });

// POST - UpdateCommentForCandidateFunc
export const updateCommentForCandidate = (data: UpdateCommentForCandidateRequestParams) =>
  api().request<void>({
    data,
    method: 'PUT',
    url: createWithBasicUrl([data.candidateId, 'comment', data.commentId]),
  });

// Delete - DeleteCommentForCandidateFunc
export const deleteCommentForCandidate = (data: DeleteCommentForCandidateRequestParams) =>
  api().request<void>({
    data,
    method: 'DELETE',
    url: createWithBasicUrl([data.candidateId, 'comment', data.commentId]),
  });

// Get - GetAutocompleteUsersFunc
export const getCompanyAdminTeamMembers = (roles: string[]) =>
  api().request<JobTeamMember[]>({
    params: {
      roles,
    },
    method: 'GET',
    url: createUrlWithParams('/api/company/{companyId}')(['user', 'autocomplete']),
  });
