import { PayloadAction } from '@reduxjs/toolkit';

import { initialState } from 'containers/JobAdLists/store/initialState';

import { createListReducer, ListState } from 'utils/reducer/reducer-helper';

import { UIKeys } from 'store/constants';
import { JobAd, JobAdFilters } from 'store/entities/job-ads/models';

const jobAdListSlice = createListReducer({
  name: UIKeys.jobAdLists,
  initialState,
  reducers: {
    selectAllToDelete: (
      state,
      action: PayloadAction<{
        id: ListState<JobAd, JobAdFilters, any>['id'];
      }>,
    ) => {
      const { id } = action.payload;

      if (!id || !state[id]) return state;

      state[id].selectedItems = state[id].canBeSelectedIds || [];
    },
    setCanBeSelectedIds: (
      state,
      action: PayloadAction<{
        id: ListState<JobAd, JobAdFilters, any>['id'];
        canBeSelectedIds?: string[];
        more?: boolean;
      }>,
    ) => {
      const { id, canBeSelectedIds = [], more = false } = action.payload;

      if (!id || !state[id]) return state;

      if (canBeSelectedIds) {
        state[id].canBeSelectedIds = more
          ? [...new Set((state[id].canBeSelectedIds ?? []).concat(canBeSelectedIds))]
          : canBeSelectedIds;
      }
    },
    filterCanBeSelectedIds: (
      state,
      action: PayloadAction<{
        id: ListState<JobAd, JobAdFilters, any>['id'];
        canBeSelectedIds?: string[];
      }>,
    ) => {
      const { id, canBeSelectedIds = [] } = action.payload;

      if (!id || !state[id] || !canBeSelectedIds?.length || !state[id].canBeSelectedIds?.length) return state;

      if (canBeSelectedIds) {
        state[id].canBeSelectedIds = (state[id].canBeSelectedIds ?? []).filter(
          (selectId) => !canBeSelectedIds.includes(selectId),
        );
      }
    },
    clearSortAndFilters: (
      state,
      action: PayloadAction<{
        id: ListState<JobAd, JobAdFilters, any>['id'];
      }>,
    ) => {
      const { id } = action.payload;

      if (!id || !state[id]) return state;

      state[id].sortMode = {};
      state[id].filters = {};
    },
    setSelectMode: (
      state,
      action: PayloadAction<{
        id: ListState<JobAd, JobAdFilters, any>['id'];
        selectable?: boolean;
        multiselect?: boolean;
      }>,
    ) => {
      const { id, selectable = false, multiselect = false } = action.payload;

      if (!id || !state[id]) return state;

      state[id].selectMode = {
        selectable,
        multiselect,
      };
    },
    clearSelectMode: (
      state,
      action: PayloadAction<{
        id: ListState<JobAd, JobAdFilters, any>['id'];
      }>,
    ) => {
      const { id } = action.payload;

      if (!id || !state[id]) return state;

      state[id].selectMode = undefined;
      state[id].selectedItems = [];
      state[id].canBeSelectedIds = [];
    },
    resetTableFilters: (
      state,
      action: PayloadAction<{
        id: ListState<JobAd, JobAdFilters, any>['id'];
      }>,
    ) => {
      const { id } = action.payload;

      if (!id || !state[id]) return state;

      state[id].filters = {
        ...state[id].filters,
        statuses: [],
        search: '',
        postedOnRange: undefined,
      };
    },
  },
});

export const jobAdListActions = jobAdListSlice.actions;

export const jobAdListReducer = jobAdListSlice.reducer;

export default jobAdListSlice;
