import { skipToken } from '@reduxjs/toolkit/dist/query';

import { ExInterviewTemplate } from 'model';

import { useAppSelector } from 'utils/hooks/useSelectors';

import {
  useInterviewTemplateListQuery,
  useInterviewTemplateQuery,
} from 'store/entities/interview-template/interview-template.api';
import { companyInterviewTemplateListUiSelectors } from 'store/ui/company/company-interview-template-list/company-interview-templates.selectors';

type useInterviewTemplateParams = {
  interviewTemplateId?: ExInterviewTemplate['interviewTemplateId'];
};

export function isExInterviewTemplate(interviewTemplate: any): interviewTemplate is ExInterviewTemplate {
  return 'createdBy' in interviewTemplate;
}

export function useInterviewTemplate({ interviewTemplateId }: useInterviewTemplateParams) {
  const filters = useAppSelector(companyInterviewTemplateListUiSelectors.filters);
  const { data: interviewTemplateFromList } = useInterviewTemplateListQuery(filters, {
    selectFromResult: ({ data, ...rest }) => ({ ...rest, data: data?.entities[interviewTemplateId!] }),
  });
  const { data: interviewTemplateById, ...restInterviewTemplate } = useInterviewTemplateQuery(
    interviewTemplateId ?? skipToken,
    {
      skip: !!interviewTemplateFromList,
    },
  );

  const interviewTemplate = interviewTemplateById ?? interviewTemplateFromList;

  return { data: interviewTemplate, ...restInterviewTemplate } as const;
}
