import React from 'react';
import styled from 'styled-components';

import { DocumentFileTypeIcon } from 'model';

import { ReactComponent as IconAttachment } from 'assets/img/attachment.svg';
import { ReactComponent as IconDoc } from 'assets/img/icon-doc.svg';
import { ReactComponent as IconPdf } from 'assets/img/icon-pdf.svg';

export interface FileExtensionProps {
  className?: string;
  fileExtension?: string;
}

const FileExtension: React.FC<FileExtensionProps> = ({ className, fileExtension = '--' }) => {
  const file = React.useMemo(
    () =>
      new Map<string, [string, React.FunctionComponent<React.SVGProps<SVGSVGElement>>]>()
        .set(DocumentFileTypeIcon.adobePdf, ['PDF', IconPdf])
        .set(DocumentFileTypeIcon.microsoftWord, ['Doc', IconDoc])
        .set(DocumentFileTypeIcon.microsoftWordOctetStream, ['Doc', IconDoc])
        .set(DocumentFileTypeIcon.microsoftWordMsword, ['Doc', IconDoc])
        .set(DocumentFileTypeIcon.rtf, ['RTF', IconAttachment])
        .set(DocumentFileTypeIcon.jpeg, ['Image', IconAttachment])
        .set(DocumentFileTypeIcon.png, ['Image', IconAttachment])
        .set(DocumentFileTypeIcon.txt, ['Text', IconAttachment])
        .set(DocumentFileTypeIcon.xls, ['XLS', IconAttachment])
        .set(DocumentFileTypeIcon.xlsx, ['XLSX', IconAttachment])
        .get(fileExtension),
    [fileExtension],
  );

  if (!file) {
    return (
      <div className={className}>
        <span className="file-type">{fileExtension}</span>
      </div>
    );
  }

  const Icon = file[1];
  return (
    <div className={className}>
      <Icon className="file-icon" /> <span className="file-type">{file[0]}</span>
    </div>
  );
};

export default styled(FileExtension)`
  display: flex;
  align-items: center;

  .file {
    &-icon {
      width: 16px;
      margin-right: 5px;
    }

    &-type {
      font-weight: 600;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;
