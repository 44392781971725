import React from 'react';
import styled from 'styled-components';

import { ExBadge } from 'components/ui/ExBadge';
import { ExBadgeVariant } from 'components/ui/ExBadge/ExBadgeProps';

type CandidateNotEditedBadgeProps = {
  className?: string;
};

const CandidateNotEditedBadgeStyled = styled(ExBadge)``;

const useCandidateNotEditedBadgeState = ({ className }: CandidateNotEditedBadgeProps) => {
  const variant = ExBadgeVariant.success;
  return {
    className,
    variant,
  } as const;
};

export const CandidateNotEditedBadge: React.FC<CandidateNotEditedBadgeProps> = (props) => {
  const { className, variant } = useCandidateNotEditedBadgeState(props);

  return <CandidateNotEditedBadgeStyled {...{ className, variant }}>Auto creation</CandidateNotEditedBadgeStyled>;
};
