import { ENTERED, TransitionStatus } from 'react-transition-group/Transition';
import styled, { AnyStyledComponent } from 'styled-components';

export const FadeTransition = styled.div<{ state: TransitionStatus }>`
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
`;
export const fadeInAnimation = (component: AnyStyledComponent | React.FC<any>, delay = 0.3) => {
  return styled(component)<{ state: TransitionStatus }>`
    transition: opacity ${delay}s ease-in-out;
    opacity: ${({ state }) => ([ENTERED].includes(state) ? 1 : 0)};
  `;
};

export const fadeOutAnimation = (component: AnyStyledComponent | React.FC<any>) => {
  return styled(component)<{ state: TransitionStatus }>`
    transition: opacity 0.3s ease-in-out;
    opacity: ${({ state }) => ([ENTERED].includes(state) ? 0 : 1)};
  `;
};
