import React from 'react';

import { MenuItem } from 'components/ActionsDropdown';
import { ExActionButton } from 'components/ui/ExActionButton';

type CreateAvatarEditableDropdownProps = {
  children: React.ReactNode;
  menuItems: MenuItem[];
  disabled?: boolean;
};

const CandidateAvatarEditableDropdown: React.FC<CreateAvatarEditableDropdownProps> = ({
  children,
  menuItems,
  disabled = false,
}) => {
  return (
    <ExActionButton
      buttonId="candidate-avatar-editable-dropdown"
      menuItems={menuItems}
      dropDirection="down"
      disabled={disabled}
    >
      {children}
    </ExActionButton>
  );
};

export { CandidateAvatarEditableDropdown };
