import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';

import { dateFormat } from 'config/date';

import { JobStatus } from 'model/api-enums.constants';

import { JobDetailFormBody } from 'containers/JobForms/FormBody/JobDetailFormBody';
import { jobFormActions } from 'containers/JobForms/state';
import { validationSchemaDetails } from 'containers/JobForms/validators/validationSchemaJobCreate';
import { Loader } from 'containers/Loader';
import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import { modalsSelectors } from 'containers/Modals/store';

import { ExLoaderFormContextHandlerWrapper, ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { DialogProps } from 'components/Dialog';
import { Form } from 'components/Form';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { ExDialog } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { Job, jobsSelectors } from 'store/entities/jobs';
import { jobDetailsEdit } from 'store/entities/jobs/job.actions';
import { exModalConfirmAction } from 'store/modals/modals.actions';
import { useSelector } from 'store/rootSelectors';

function useJobEditDetailsDisabledFields(jobId: string | null) {
  const defaultDisabledFields = ['position.categoryId', 'position.positionId', 'pipelineId', 'jobType'];

  const job = useAppSelector(jobsSelectors.getById, jobId);

  const isDraft = job?.status === JobStatus.draft;
  const isJobActive = job?.status === JobStatus.open;

  const isJobTypeEditDisabled = useAppSelector(jobsSelectors.selectIsJobTypeEditDisabled, jobId);

  return defaultDisabledFields.filter((field: string) => {
    if (field === 'pipelineId') {
      return !isDraft;
    } else if (field === 'jobType') {
      return isJobTypeEditDisabled || !isJobActive;
    }

    return true;
  });
}
export interface JobEditDetailsProps extends AppModalContentProps {
  className?: string;
}

const FormStyled = styled(Form)`
  overflow-x: hidden;
  overflow-y: visible;

  & > *:not(:last-child) {
    margin-bottom: 0;
  }
`;

const DialogActions = styled.div`
  display: flex;
  justify-content: center;
`;

const JobEditDetails: React.FC<any> = ({ id, className, onClose }) => {
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();
  const jobId = useSelector(modalsSelectors.modalJobId);
  const memo = useMemo(() => jobsSelectors.createGetById(), []);
  const job = useSelector((state) => memo(state, jobId!));
  const content: DialogProps['content'] = {
    title: 'Edit Job Details',
    withTitle: true,
  };

  const deadline = job?.deadline
    ? format(parseISO(job?.deadline), dateFormat.formatDateForDateInput, {
        weekStartsOn: 1,
      })
    : null;

  const defaultValues = { ...job!, deadline };

  const submitHandler = async (data: Partial<Job>) => {
    dispatch(
      exModalConfirmAction({
        id,
        modalResult: {
          data: {
            ...defaultValues,
            ...data,
          },
          urlParams: {
            jobId: jobId!,
          },
        },
      }),
    );
  };

  useEffect(() => {
    if (!job || !jobId) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [job, jobId]);

  useEffect(() => {
    return () => {
      dispatch(jobFormActions.clearForm({}));
    };
  }, [dispatch]);

  const disableField = useJobEditDetailsDisabledFields(jobId);

  if (!show) {
    return <Loader />;
  }

  return (
    <div className={className}>
      <ExDialog onClose={onClose} content={content}>
        <FormStyled
          onSubmit={submitHandler}
          validationSchema={validationSchemaDetails}
          defaultValues={defaultValues}
          className="w-100"
        >
          <JobDetailFormBody disableField={disableField} />
          <DialogActions>
            <ExButton variant="light" onClick={onClose}>
              Cancel
            </ExButton>
            <ExLoaderFormContextHandlerWrapper>
              <ExLoaderHandlerWrapper action={jobDetailsEdit}>
                <ExButton className="ml-3" variant="primary" type="submit">
                  Update
                </ExButton>
              </ExLoaderHandlerWrapper>
            </ExLoaderFormContextHandlerWrapper>
          </DialogActions>
        </FormStyled>
      </ExDialog>
    </div>
  );
};

export default styled(JobEditDetails)`
  max-width: 800px;
  width: 100%;

  .dialog-body {
    padding-bottom: 0;
  }
`;
