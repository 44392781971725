import './wdyr';
import './index.scss';
import './i18n';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { DirectoryServicesProvider } from '@expedo/directory-services';
import { Environment, FeatureProvider } from '@expedo/feature-flag';
import { RootStyle } from '@expedo/root-style';
import { GlobalNavQueryParam } from '@expedo/types';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConnectedRouter } from 'connected-react-router';
import { GlobalStyle } from 'globalStyle';
import { theme } from 'theme';
import { ThemeProvider } from 'styled-components';

import { apiConfig, environment } from 'config';
import { featureMap } from 'config/feature-flag';

import { apiConnect } from 'api-client';

import { AlertManager } from 'containers/AlertManager';
import { unregister } from 'containers/ServiceWorker/serviceWorker';

import store from 'store';
import { history } from 'store/history';

import App from './App';

apiConnect({ baseURL: apiConfig.baseUrl });

const appEnvironment = (process.env.REACT_APP_ENVIRONMENT as Environment) || 'dev';
const isDevEnv = appEnvironment === 'dev';

if (process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRINGS) {
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRINGS,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history },
      },
    },
  });
  appInsights.loadAppInsights();
  if (process.env.REACT_APP_APPLICATION_INSIGHTS_ROLE_NAME) {
    appInsights.addTelemetryInitializer((envelope) => {
      envelope.tags = envelope.tags || [];
      envelope.tags.push({ 'ai.cloud.role': process.env.REACT_APP_APPLICATION_INSIGHTS_ROLE_NAME });
    });
  }
  appInsights.trackPageView();
}

const gtmParams = {
  id: 'GTM-NR8ZR6K',
  dataLayerName: 'dataLayer',
  injectScript: isDevEnv,
};

unregister();

// Collect the initial url and its query params to consumed after the auth flow
const storeQueryParamsInSession = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const referrer = searchParams.get(GlobalNavQueryParam.Referrer);
  const dsPersonaId = searchParams.get(GlobalNavQueryParam.Persona);
  const dsCompanyId = searchParams.get(GlobalNavQueryParam.Company);

  referrer && sessionStorage.setItem(GlobalNavQueryParam.Referrer, referrer);
  dsPersonaId && sessionStorage.setItem(GlobalNavQueryParam.Persona, dsPersonaId);
  dsCompanyId && sessionStorage.setItem(GlobalNavQueryParam.Company, dsCompanyId);
};

storeQueryParamsInSession();
const client = new QueryClient();

async function main() {
  ReactDOM.render(
    <React.StrictMode>
      <DirectoryServicesProvider url={environment.dsUrl}>
        <QueryClientProvider client={client}>
          <FeatureProvider featureMap={featureMap} appContext="hire" environment={appEnvironment}>
            <GTMProvider state={gtmParams}>
              <Provider store={store}>
                <ConnectedRouter history={history}>
                  <Router history={history}>
                    <ThemeProvider theme={theme}>
                      <GlobalStyle />
                      <RootStyle />
                      <App />
                      <AlertManager className="alert-manager" />
                    </ThemeProvider>
                  </Router>
                </ConnectedRouter>
              </Provider>
            </GTMProvider>
          </FeatureProvider>
          <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
        </QueryClientProvider>
      </DirectoryServicesProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

main();
