import * as effects from 'containers/TalentPoolForms/state/talentPoolForm.effects';
import { talentPoolFormReducer } from 'containers/TalentPoolForms/state/talentPoolForms.reducer';
import * as selectors from 'containers/TalentPoolForms/state/talentPoolForms.selectors';

export { talentPoolFormActions } from 'containers/TalentPoolForms/state/talentPoolForms.reducer';

export const talentPoolFormSelectors = selectors;

export const talentPoolFormEffects = effects;

export default talentPoolFormReducer;
