import { nanoid } from '@reduxjs/toolkit';
import { all, call, fork, put, putResolve, select, take, takeEvery } from 'redux-saga/effects';

import * as jobAdApi from 'api-endpoints/job-ad';

import { jobAdFormActions, jobAdFormSelectors } from 'containers/JobAdForms/state';
import { validationSchemaPostJobAd } from 'containers/JobAdForms/validators';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { pickOrRename, removeNullAndUndefinedPropertiesFromObject } from 'utils/funcs';
import { invokeExModal, prepareExModalChannel } from 'utils/sagas';

import { authSelectors } from 'store/auth/auth.selectors';
import { jobAdEffects } from 'store/entities/job-ads/job-ad.effects';
import { jobAdSelectors } from 'store/entities/job-ads/selectors';
import { jobBoardSelectors } from 'store/entities/job-boards/selectors';
import { exModalHideAction, exModalShowAction } from 'store/modals/modals.actions';

import { jobAdActions } from './index';
import { enhancedJobAdsApi, JOB_AD_TAG_TYPE } from './job-ads.api';

function* postJobAdWorker(action: ReturnType<typeof jobAdActions.updateJobAdStatusAction>) {
  const jobAdId = action.payload.jobAdId;
  if (!jobAdId) {
    return;
  }

  yield startLoader(action);

  /**
   * We load all necessary data for validate job ad
   */
  yield putResolve(jobAdEffects.loadJobAd({ jobAdId, preloader: false }) as any);
  yield putResolve(jobAdEffects.prepareFormData({ jobAdId, preloader: false }) as any);
  yield putResolve(jobAdEffects.updateFormData({ jobAdId, preloader: false }) as any);
  const formJobAd: ReturnType<typeof jobAdFormSelectors.selectFormData> = yield select(
    jobAdFormSelectors.selectFormData,
  );
  const jobAd: ReturnType<typeof jobAdSelectors.getById> = yield select(jobAdSelectors.getById, jobAdId);
  const jobBoard: ReturnType<typeof jobBoardSelectors.selectById> = yield select(
    jobBoardSelectors.selectById,
    jobAd?.jobBoardId!,
  );
  const companyId: ReturnType<typeof authSelectors.selectCurrentCompanyId> = yield select(
    authSelectors.selectCurrentCompanyId,
  );
  const existedJobAd = jobAd ?? formJobAd;
  const jobAdRequestBody = { companyId: companyId!, ...existedJobAd, jobAdId };
  removeNullAndUndefinedPropertiesFromObject(jobAdRequestBody);
  /**
   * We validate job ad object by job ad form validator
   */
  const preparedFormData = pickOrRename(jobAdRequestBody, [
    'jobAdId',
    'sector',
    'startDate',
    'employmentType',
    'responsibilities',
    'requirements',
    'description',
    'jobAdLocation',
    'additionalFields',
    ['jobAdName', 'name'],
  ]);
  const isFormValid = validationSchemaPostJobAd.isValidSync(preparedFormData);
  const additionalFieldsValid =
    jobBoard?.additionalFields?.some((field) => !!field.required) && !!preparedFormData.additionalFields?.length;
  yield stopLoader(action);

  const { modalId, sagaChannel } = yield prepareExModalChannel();
  const modalErrorConfig = {
    id: nanoid(),
    modalType: ModalsTypeKey.jobAdPostModal,
    modalConfig: {
      content: {
        title: 'Errors',
        withTitle: true,
        withActions: true,
        buttonOk: null,
        buttonCancel: 'Close',
      },
    },
  };

  /**
   * If job ad is valid and additional fields not empty we make a request for post job ad
   */
  if (isFormValid && additionalFieldsValid) {
    yield fork(invokeExModal, {
      modalId,
      channel: sagaChannel,
      modalType: ModalsTypeKey.confirmModal,
      modalConfig: {
        content: {
          message: 'Confirm Post Job Ad',
          title: 'Confirm Action',
          withActions: true,
          withTitle: true,
        },
      },
    });
    const { cancel } = yield take(sagaChannel);
    if (cancel) {
      return;
    }
    yield put(exModalHideAction({ id: modalId }));
    yield startLoader(action);

    const { message, response } = yield call(jobAdApi.postJobAd, jobAdRequestBody);
    yield stopLoader(action);
    if (message) {
      /**
       *  If job ad not valid we show modal with error
       */
      yield all([
        put(
          jobAdFormActions.setApiErrors({
            apiErrors: response?.data?.validationErrorCodes,
          }),
        ),
        put(exModalShowAction(modalErrorConfig)),
      ]);
    } else {
      yield putResolve(jobAdEffects.loadJobAd({ jobAdId, preloader: false }) as any);
      yield put(enhancedJobAdsApi.util.invalidateTags([{ type: JOB_AD_TAG_TYPE, id: jobAdId }]));
    }
  } else {
    /**
     *  If job ad not valid we show modal with error
     */
    yield put(exModalShowAction(modalErrorConfig));
  }
}

export function* jobAdWatcher() {
  yield takeEvery(jobAdActions.updateJobAdStatusAction, postJobAdWorker);
}
