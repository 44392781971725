import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as EditIcon } from 'assets/img/edit.svg';

import { DetailsRowProps } from 'components/DetailsCard';
import { Ellipsis } from 'components/Ellipsis';
import { FormCard } from 'components/FormCard';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

export interface ReviewStepCardProps {
  className?: string;
  rows?: DetailsRowProps[];
  title?: string;
  editUrl?: string;
  editAction?: (event: React.MouseEvent) => void;
  isCard?: boolean;
}

const ReviewStepCardPure: React.FC<ReviewStepCardProps> = ({ className, rows, title, editUrl, editAction, isCard }) => {
  return (
    <div className={className}>
      <div className="header-wrapper">
        {title && <div className="title">{title}</div>}
        <div className="ml-auto edit-text">
          <Link to={`${editUrl}`} className="text-reset" onClick={editAction}>
            <EditIcon /> Edit
          </Link>
        </div>
      </div>
      {isCard && (
        <div className="overflow-hidden">
          <FormCard.FormCardBodyDivider />
        </div>
      )}
      {rows?.length ? (
        <div className="py-3 d-flex flex-column">
          {rows.map((row) => {
            const isNode = React.isValidElement(row.value);
            return (
              <div key={row.label} className="details-row">
                <div className="font-weight-bold mb-2">{row.label}</div>
                <div>
                  {isNode ? (
                    row.value
                  ) : (
                    <Ellipsis title={row.value?.toString()}>{row.value ?? <small>--</small>}</Ellipsis>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export const ReviewStepCard = styled(ReviewStepCardPure)`
  position: relative;

  .title {
    font-size: ${getThemeFontSize('large')};
    font-weight: bold;
  }

  > .header-wrapper {
    ${(props) => (!props.title ? 'float: right;' : undefined)};
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .details-row {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
`;
