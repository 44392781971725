import { industryAdapter, useIndustryQuery } from 'store/dictionary/dictionary.api';

export const useIndustryQuerySelectors = () => {
  const query = useIndustryQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  const adapterSelectors = industryAdapter.getSelectors(() => query.data ?? industryAdapter.getInitialState());

  const selectors = {
    ...adapterSelectors,
  };

  return {
    ...query,
    selectors,
  };
};
