import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { EmptyLayoutFullHeight } from 'layouts';

import { AppRouterParams } from 'model/router';

import { publicCompanyActions, publicCompanySelectors } from 'pages/public/state/public-company';

import { Spinner } from 'components/Spinner';
import { ExPublicLayout } from 'components/ui/ExPublicLayout';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

const PublicJobPage = lazy(() =>
  import(/* webpackChunkName: "public-job-page" */ './PublicJobPage').then((module) => ({
    default: module.PublicJobContainer,
  })),
);

const PublicJobsPageV2 = lazy(() =>
  import(/* webpackChunkName: "public-jobs-page" */ './PublicJobsPageV2').then((module) => ({
    default: module.PublicJobsPageV2,
  })),
);

type PublicPageProps = {
  children: never;
};

const usePublicPageState = () => {
  const dispatch = useAppDispatch();
  const match = useRouteMatch<AppRouterParams>();

  const companyId = useAppSelector(publicCompanySelectors.selectPublicCompanyId);
  const publicCompanyIsLoading = useAppSelector(publicCompanySelectors.selectPublicCompanyIsLoading);

  useEffect(() => {
    if (match.params.companyPublicUrl) {
      dispatch(publicCompanyActions.publicCompanyGetAction(match.params.companyPublicUrl));
    }
  }, [dispatch, match.params.companyPublicUrl]);

  return {
    match,
    companyId,
    publicCompanyIsLoading,
  } as const;
};

export const PublicPage: React.FC<PublicPageProps> = () => {
  const { match, companyId, publicCompanyIsLoading } = usePublicPageState();

  if (publicCompanyIsLoading) {
    return (
      <EmptyLayoutFullHeight>
        <Spinner />
      </EmptyLayoutFullHeight>
    );
  }

  if (!companyId) {
    return <div>Company not found</div>;
  }

  return (
    <ExPublicLayout>
      <Suspense
        fallback={
          <EmptyLayoutFullHeight>
            <Spinner />
          </EmptyLayoutFullHeight>
        }
      >
        <Switch>
          <Route exact path={match.path} component={PublicJobsPageV2} />
          <Route path={match.path + '/:jobId'} component={PublicJobPage} />
        </Switch>
      </Suspense>
    </ExPublicLayout>
  );
};
