import { ValidationErrorCodes } from 'model/api-errors.constants';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';

import { getTranslate } from 'utils/i18utils';

import { AppDispatch } from 'store/types';

export function showValidationErrorCodes(dispatch: AppDispatch, errors: ValidationErrorCodes[], message: string) {
  errors.forEach((validationErrorCode) => {
    const errorMessage = getTranslate(validationErrorCode, { ns: 'validationErrorCodes' });
    dispatch(
      alertsEffects.showError({
        message: errorMessage || message || 'Error',
      }),
    );
  });
}
