import { createAction } from '@reduxjs/toolkit';

import { calendarJobsSlice } from 'containers/Calendar/state/calendar-jobs/calendar-jobs.reducer';

const calendarJobsFetchAction = createAction(`${calendarJobsSlice.name}/fetch`);

export const calendarJobsActions = {
  ...calendarJobsSlice.actions,
  calendarJobsFetchAction,
};
