import React from 'react';
import styled from 'styled-components';

import { ReactComponent as FileIcon } from 'assets/img/file.svg';

type FileIconProps = {
  className?: string;
};
const FileIconComponent: React.FC<FileIconProps> = ({ ...props }) => <FileIcon {...props} />;

export const FileIconStyled = styled(FileIconComponent)`
  fill: none;
  flex: 0 0 auto;
`;
