import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import isEmpty from 'lodash/isEmpty';

import { tinymceApiKey } from 'config/tinymce';

import { defaultTextEditorOptions } from 'components/Form/TextEditor/config';
import {
  TextEditorStatusbar,
  TextEditorStatusbarTextContainer,
  TextEditorStatusbarWordCount,
  TextEditorWrapper,
} from 'components/Form/TextEditor/TextEditorComponents';
import { TextEditorProps } from 'components/Form/TextEditor/TextEditorProps';
import { useTextEditorOptions } from 'components/Form/TextEditor/utils';
import { FormTooltip } from 'components/FormTooltip';
import { Spinner } from 'components/Spinner';
import { pluralize } from 'utils/pluralize';
import { validateStringLengthWithoutHtmlTagsPrepare } from 'utils/validator-helpers';

/**
 * This is simple React-TiniMCE editor wrapper.
 * To extend or modify this component please use the official documentation.
 * https://www.tiny.cloud/docs/integrations/react/
 *
 * @param {TextEditorProps} { onChange, value, options }
 * @returns {React.ReactElement}
 */
const TextEditor: React.FC<TextEditorProps> = ({
  onChange,
  value,
  options,
  className,
  initialValue,
  errors,
  validated,
  inputRef,
  noOverlay,
  isDisabled,
}: TextEditorProps): React.ReactElement => {
  const [editorReady, setEditorReady] = useState<boolean>(false);
  const editorOptions = useTextEditorOptions(options);

  const valueLength = validateStringLengthWithoutHtmlTagsPrepare(value || '').length;
  const valueLengthTitle = pluralize('character', valueLength);

  const onInitHandler = (e: any, editor: any) => {
    setEditorReady(true);
    if (inputRef) {
      inputRef.current = editor;
    }
  };

  const [focus, setFocus] = useState(false);
  const [showError, setShowError] = useState(true);

  useEffect(() => {
    const hasError = Boolean(errors) || !isEmpty(errors);
    if (!hasError) {
      setShowError(false);
    } else {
      setShowError(true);
    }
  }, [errors]);

  const isInvalid = validated && !!errors;

  const target = useRef<any>();

  useEffect(() => {
    const { top } = target.current?.getBoundingClientRect();

    const bottomTreshold = window.innerHeight - 100;

    const isVisible = top > 0 && top < bottomTreshold;

    if (focus && !isVisible) {
      target.current.scrollIntoView({ block: 'center', inline: 'nearest' });
    }
  }, [focus]);

  return (
    <TextEditorWrapper
      ref={target}
      height={defaultTextEditorOptions.height}
      className={className}
      isInvalid={isInvalid}
    >
      <Editor
        initialValue={initialValue}
        apiKey={tinymceApiKey}
        value={value}
        init={editorOptions}
        onEditorChange={onChange}
        onInit={onInitHandler}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        disabled={isDisabled}
      />
      {editorReady && (
        <TextEditorStatusbar>
          <TextEditorStatusbarTextContainer>
            <TextEditorStatusbarWordCount>
              {valueLength} {valueLengthTitle}
            </TextEditorStatusbarWordCount>
          </TextEditorStatusbarTextContainer>
        </TextEditorStatusbar>
      )}
      {!editorReady && <Spinner />}
      <FormTooltip
        target={target}
        hideOverlay={noOverlay}
        show={focus && showError && !!validated}
        errors={errors}
        offset={[0, 0]}
      />
    </TextEditorWrapper>
  );
};

export { TextEditor };
