/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Select, { components, StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { MultiValueRemoveProps } from 'react-select/src/components/MultiValue';
import styled, { DefaultTheme, ThemeContext } from 'styled-components';

import { ReactComponent as CircleClose } from 'assets/img/circle-x.svg';

import { FormCard } from 'components/FormCard';
import type { OptionTypeBase } from 'components/FormSelect/FormSelectProps';
import { FormTooltip } from 'components/FormTooltip';
import { SpinnerSM } from 'components/Spinner';
import { ExVisible } from 'components/ui/ExVisible';

import { FormTagSelectProps } from './FormTagSelectProps';
import { useAppSelectStyles } from './FormTagSelectStyles';

export const StyledCircleClose = styled(CircleClose)<{ variant: keyof DefaultTheme['colors'] }>`
  height: 11px;
  width: 11px;
  margin-left: 5px;
  fill: ${(props) => props.theme.colors[props.variant]};

  &:hover {
    fill: ${(props) => props.theme.colors['danger']};
  }
`;

export const MultiValueRemove = <T extends OptionTypeBase>(props: MultiValueRemoveProps<T>) => {
  return (
    <components.MultiValueRemove {...props}>
      <StyledCircleClose variant="primary" />
    </components.MultiValueRemove>
  );
};

export const LoadingIndicator: React.FC = () => {
  return (
    <div className="mr-2">
      <SpinnerSM />
    </div>
  );
};

export const FormTagSelect = React.forwardRef(
  <T extends OptionTypeBase, IsMulti extends boolean>(
    {
      options,
      name,
      className,
      label,
      defaultValue,
      required,
      defaultOptions,
      loadOptions,
      isMulti,
      multiline,
      overrideStyles,
      components: componentsProps,
      errors,
      validated,
      menuPortalTarget = document.body,
      isCreatable = false,
      tooltipOffset: tooltipOffsetProps,
      noOverlay,
      ...rest
    }: FormTagSelectProps<T, IsMulti>,
    ref,
  ) => {
    const SelectComponent = isCreatable ? AsyncCreatableSelect : AsyncSelect;

    const [focus, setFocus] = useState(false);
    const [showError, setShowError] = useState(true);

    const isInvalid = validated && !!errors;

    const theme = useContext(ThemeContext);
    const appSharedStyles = useAppSelectStyles(['menuList', 'option', 'multiValueRemove']);
    const componentStyles: StylesConfig<T, IsMulti> = {
      control: (base) => {
        const validColor = theme.border.borderColor;
        const inValidColor = isInvalid ? theme.colors.danger : theme.border.borderColor;
        return {
          ...base,
          borderColor: `${errors === undefined ? validColor : inValidColor}`,
          borderRadius: '20px',
          boxShadow: 'none',
          '&:hover': {},
        };
      },
      multiValue: () => ({
        borderRadius: '50em',
        backgroundColor: `${theme.colors.primaryLight}`,
        color: `${theme.colors.primary}`,
        padding: '5px',
        margin: '4px',
        fontSize: '12px',
        display: 'flex',
        lineHeight: 1,
        overflow: 'hidden',
      }),
      multiValueLabel: () => ({
        color: `${theme.colors.primary}`,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 'bold',
      }),
      menuPortal: (base) => ({ ...base, zIndex: 1040 }),
      valueContainer: (base) => {
        return {
          ...base,
          minHeight: multiline ? '70px' : undefined,
          alignItems: 'center',
          color: `${theme.colors.gray}`,
        };
      },
    };
    const styles = { ...appSharedStyles, ...componentStyles, ...overrideStyles };

    const target = useRef<HTMLDivElement>(null);
    const handlerLoadOptions =
      typeof loadOptions !== 'undefined'
        ? loadOptions
        : () => {
            // do nothing
          };

    useEffect(() => {
      if (errors) {
        setShowError(true);
        return;
      }
      setShowError(false);
    }, [errors]);

    const tooltipOffset: [number, number] | undefined = tooltipOffsetProps ?? (!label ? [0, 0] : undefined);

    return (
      <Form.Group ref={target} className={className}>
        <ExVisible visible={Boolean(label)}>
          <FormCard.InputLabel htmlFor={name}>
            {label} {required && <FormCard.InputLabelRequired>*</FormCard.InputLabelRequired>}
          </FormCard.InputLabel>
        </ExVisible>
        <SelectComponent
          ref={ref}
          onFocus={() => setFocus(true)}
          styles={styles}
          isMulti={isMulti}
          options={options}
          defaultOptions={defaultOptions}
          loadOptions={handlerLoadOptions}
          components={{
            LoadingIndicator,
            MultiValueRemove,
            IndicatorSeparator: null,
            ...componentsProps,
          }}
          defaultValue={defaultValue}
          name={name}
          theme={(selectDefaultTheme) => ({
            ...selectDefaultTheme,
            colors: {
              ...selectDefaultTheme.colors,
              ...theme.colors,
            },
          })}
          menuPortalTarget={menuPortalTarget}
          {...rest}
          onBlur={(e) => {
            if (rest.onBlur instanceof Function) {
              rest.onBlur(e);
            }
            setFocus(false);
          }}
        />
        <FormTooltip
          hideOverlay={noOverlay}
          target={target}
          show={focus && showError}
          errors={errors}
          offset={tooltipOffset}
        />
      </Form.Group>
    );
  },
);

const useExSyncSelectState = <T extends OptionTypeBase, IsMulti extends boolean>(
  props: FormTagSelectProps<T, IsMulti>,
) => {
  const {
    label,
    multiline,
    overrideStyles,
    errors,
    validated,
    tooltipOffset: tooltipOffsetProps,
    onBlur,
    ...restProps
  } = props;

  const [focus, setFocus] = useState(false);

  const [showError, setShowError] = useState(true);

  const isInvalid = validated && !!errors;

  const theme = useContext(ThemeContext);

  const appSharedStyles = useAppSelectStyles(['menuList', 'option', 'multiValueRemove']);

  const componentStyles: StylesConfig<T, IsMulti> = {
    control: (base) => {
      const validColor = theme.border.borderColor;
      const inValidColor = isInvalid ? theme.colors.danger : theme.border.borderColor;
      return {
        ...base,
        borderColor: `${errors === undefined ? validColor : inValidColor}`,
        borderRadius: '20px',
        boxShadow: 'none',
        '&:hover': {},
      };
    },
    multiValue: () => ({
      borderRadius: '50em',
      backgroundColor: `${theme.colors.primaryLight}`,
      color: `${theme.colors.primary}`,
      padding: '5px',
      margin: '4px',
      fontSize: '12px',
      display: 'flex',
      lineHeight: 1,
      overflow: 'hidden',
    }),
    multiValueLabel: () => ({
      color: `${theme.colors.primary}`,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 'bold',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 1040 }),
    valueContainer: (base) => {
      return {
        ...base,
        minHeight: multiline ? '70px' : undefined,
        alignItems: 'center',
        color: `${theme.colors.gray}`,
      };
    },
  };

  const styles = { ...appSharedStyles, ...componentStyles, ...overrideStyles };

  const target = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errors) {
      setShowError(true);
      return;
    }
    setShowError(false);
  }, [errors]);

  const tooltipOffset: [number, number] | undefined = tooltipOffsetProps ?? (!label ? [0, 0] : undefined);

  const onFocusInner = useCallback(() => setFocus(true), []);

  const onBlurInner = useCallback(
    (e) => {
      if (onBlur instanceof Function) {
        onBlur(e);
      }
      setFocus(false);
    },
    [onBlur],
  );

  return {
    label,
    errors,
    ...restProps,
    focus,
    showError,
    theme,
    styles,
    target,
    tooltipOffset,
    onFocusInner,
    onBlurInner,
  };
};

export const ExSyncSelect = React.forwardRef(
  <T extends OptionTypeBase, IsMulti extends boolean>(props: FormTagSelectProps<T, IsMulti>, ref) => {
    const {
      label,
      errors,
      options,
      name,
      className,
      defaultValue,
      required,
      isMulti,
      components: componentsProps,
      menuPortalTarget = document.body,
      focus,
      showError,
      theme,
      styles,
      target,
      tooltipOffset,
      onFocusInner,
      onBlurInner,
      ...rest
    } = useExSyncSelectState(props);

    return (
      <Form.Group ref={target} className={className}>
        <ExVisible visible={Boolean(label)}>
          <FormCard.InputLabel htmlFor={name}>
            {label} {required && <FormCard.InputLabelRequired>*</FormCard.InputLabelRequired>}
          </FormCard.InputLabel>
        </ExVisible>
        <Select
          ref={ref}
          onFocus={onFocusInner}
          styles={styles}
          isMulti={isMulti}
          options={options}
          components={{
            LoadingIndicator,
            MultiValueRemove,
            IndicatorSeparator: null,
            ...componentsProps,
          }}
          defaultValue={defaultValue}
          name={name}
          theme={(selectDefaultTheme) => ({
            ...selectDefaultTheme,
            colors: {
              ...selectDefaultTheme.colors,
              ...theme.colors,
            },
          })}
          menuPortalTarget={menuPortalTarget}
          onBlur={onBlurInner}
          {...rest}
        />
        <FormTooltip target={target} show={focus && showError} errors={errors} offset={tooltipOffset} />
      </Form.Group>
    );
  },
);
