import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { compareDesc } from 'date-fns';

import { ExComment } from 'model';

import { adapterHelper } from 'utils/reducer/adapter-helper';

import { EntityKeysNew } from 'store/constants';
import { Applicant } from 'store/entities/applicants/models';
import { Job } from 'store/entities/jobs/models';

export const applicantCommentsAdapter = createEntityAdapter<ExComment>({
  selectId: (comment) => comment.commentId,
  sortComparer: (commentA, commentB) => {
    const a = new Date(commentA.createdOn).valueOf();
    const b = new Date(commentB.createdOn).valueOf();
    return compareDesc(a, b);
  },
});

const applicantCommentsInitialState = applicantCommentsAdapter.getInitialState({
  applicantId: null as null | Applicant['applicantId'],
  jobId: null as null | Job['jobId'],
  lastLoadedPage: 0,
  pageCount: 0,
});

export const applicantCommentsSlice = createSlice({
  name: EntityKeysNew.applicantComments,
  initialState: applicantCommentsInitialState,
  reducers: {
    ...adapterHelper(applicantCommentsAdapter),
    addOneAndReplace: (state, { payload }: PayloadAction<ExComment & { fakeId: string }>) => {
      const { fakeId, ...comment } = payload;
      applicantCommentsSlice.caseReducers.removeOne(state, fakeId);
      applicantCommentsSlice.caseReducers.addOne(state, comment);
    },
    setCurrentApplicantIdAndJobId: (
      state,
      { payload }: PayloadAction<Pick<Applicant, 'applicantId'> & Pick<Job, 'jobId'>>,
    ) => {
      state.applicantId = payload.applicantId;
      state.jobId = payload.jobId;
    },
    setPageCount: (state, { payload }: PayloadAction<{ pageCount: number }>) => {
      const { pageCount } = payload;
      state.pageCount = pageCount;
    },
    updatePage: (state, { payload }: PayloadAction<{ pageCount: number; lastLoadedPage: number }>) => {
      const { lastLoadedPage, pageCount } = payload;
      if (lastLoadedPage && lastLoadedPage < pageCount) {
        state.lastLoadedPage = lastLoadedPage;
      }
      state.pageCount = pageCount;
    },
    resetCurrent: () => applicantCommentsInitialState,
  },
});
