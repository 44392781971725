import React from 'react';

import { CompanyEmailTemplateWidget } from 'pages/Company/CompanyTabs/CompanyEmailTemplateTab/components/CompanyEmailTemplateWidget';
import { CompanyTabStyled } from 'pages/Company/CompanyTabs/styles/CompanyTabsStyles';

import { ExColumn } from 'components/ui/ExLayout';

export interface CompanyEmailTemplateTabProps {
  className?: string;
}

export const CompanyEmailTemplateTab: React.FC<CompanyEmailTemplateTabProps> = ({ className }) => {
  return (
    <CompanyTabStyled className={className}>
      <ExColumn custom="12">
        <CompanyEmailTemplateWidget />
      </ExColumn>
    </CompanyTabStyled>
  );
};
