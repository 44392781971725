import { matchPath, useLocation } from 'react-router-dom';
import { Location } from 'history';
import { ExRoutes } from 'router/routes';

const svgBgPages = [ExRoutes.dashboard()];

function filterMatchForLocation(location: Location<unknown>) {
  return function (path: string): boolean {
    const match = matchPath(location?.pathname, {
      path,
    });

    return match !== null;
  };
}

/**
 * This hook will use to decide need we use svg background.
 * Currently it is only Dashboard page;
 *
 * @returns boolean
 */
export function useSvgBackground(): boolean {
  const location = useLocation();

  const filter = filterMatchForLocation(location);

  return svgBgPages.some(filter);
}
