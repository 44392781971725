import { BasicModel } from 'utils/reducer/reducer-helper';

export interface TalentPool extends BasicModel {
  name: string;
  candidatesCount?: number;
  categoryId: string;
  categoryName: string;
  positionName: string;
  positionId: number;

  // Candidate added date
  addedDate?: string; //Date
}

export enum TalentPoolBelongsTo {
  main = 'main',
  candidate = 'candidate',
}

export enum TalentPoolFiltersNames {
  name = 'name',
  belongsTo = 'belongsTo',
}

export interface TalentPoolFilters {
  [TalentPoolFiltersNames.name]?: string;
  [TalentPoolFiltersNames.belongsTo]?: [TalentPoolBelongsTo, BasicModel['id']];
}

export enum SortBy {
  name = 'Name',
  positionName = 'PositionName',
  candidatesCount = 'CandidatesCount',
  talentPoolName = 'TalentPoolName',
  addedDate = 'AddedDate',
  fullName = 'FullName',
}

export enum Columns {
  tick = 'tick',
  name = 'name',
  positionName = 'positionName',
  candidatesCount = 'candidatesCount',
  addedDate = 'addedDate',
  actions = 'actions',
}
