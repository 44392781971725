import { all, call, put, select } from 'redux-saga/effects';

import * as talentPoolApi from 'api-endpoints/talent-pool';

import { Unwrap } from 'model/utils';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';
import { talentPoolListActions, talentPoolListSelectors } from 'containers/TalentPoolLists/store';

import { getTranslate } from 'utils/i18utils';
import { invokeApiCall } from 'utils/sagas';

import { talentPoolActions } from 'store/entities/talent-pools/index';
import { TalentPool } from 'store/entities/talent-pools/models';
import { talentPoolSelectors } from 'store/entities/talent-pools/selectors';
import { removeTalentPool } from 'store/entities/talent-pools/talent-pool.actions';
import { exModalHideAction } from 'store/modals/modals.actions';
import { ModalGeneralResult } from 'store/modals/modals.interfaces';
import { modalSagaWorker } from 'store/modals/modals.sagas';

export function* talentPoolsRemoveSaga({ payload }: ReturnType<typeof removeTalentPool>) {
  const talentPoolId = payload.id;
  const talentPool: TalentPool | undefined = yield select(talentPoolSelectors.getById, talentPoolId);
  if (!talentPool) {
    return;
  }

  let modalId: string | undefined;

  while (true) {
    const result: ModalGeneralResult = yield call(modalSagaWorker, {
      ...(modalId ? { id: modalId } : {}),
      modalType: ModalsTypeKey.confirmModal,
      modalConfig: {
        content: {
          message: `Confirm remove "${talentPool.name}".`,
          title: 'Confirm Action',
          withTitle: true,
          withActions: true,
        },
      },
    });
    if (result.cancel || !result.confirm) {
      break;
    }
    modalId = result.confirm.payload.id;

    const { errorData }: Unwrap<ReturnType<typeof talentPoolApi.removeTalentPool>> = yield call(
      invokeApiCall,
      talentPoolApi.removeTalentPool,
      {
        urlParams: { talentPoolId },
      },
    );

    if (!errorData) {
      yield all([
        put(exModalHideAction({ id: modalId })),
        put(
          alertsEffects.showSuccess({
            message: getTranslate('talentPool.remove.success'),
          }),
        ),
        put(talentPoolActions.deleteById({ id: talentPoolId })),
      ]);
      if (payload.listId) {
        const list = yield select(talentPoolListSelectors.getListById, { listId: payload.listId });
        yield put(
          talentPoolListActions.update({
            id: payload.listId,
            items: list.items.filter((id) => id !== talentPoolId),
            totalItemsCount: list.totalItemsCount - 1,
            pageCount: Math.ceil((list.totalItemsCount - 1) / list.pageSize),
          }),
        );
      }
      break;
    }
  }
}
