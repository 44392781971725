import React from 'react';
import styled from 'styled-components/macro';

import { ApplicantModalSwitchMode } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalSwitchMode';
import { ApplicantModalSalaryInfoEdit } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalSalaryInfo/ApplicantModalSalaryInfoEdit';
import { ApplicantModalSalaryInfoView } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalSalaryInfo/ApplicantModalSalaryInfoView';

type ApplicantModalSalaryInfoProps = {
  className?: string;
};

const ApplicantModalSalaryInfoStyled = styled(ApplicantModalSwitchMode)``;

const useApplicantModalSalaryInfoState = ({ className }: ApplicantModalSalaryInfoProps) => {
  return {
    className,
  } as const;
};

export const ApplicantModalSalaryInfo: React.FC<ApplicantModalSalaryInfoProps> = (props) => {
  const { className } = useApplicantModalSalaryInfoState(props);

  return (
    <ApplicantModalSalaryInfoStyled
      className={className}
      viewComponent={<ApplicantModalSalaryInfoView />}
      editComponent={<ApplicantModalSalaryInfoEdit />}
    />
  );
};
