import React, { useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import JobBoardListPlaceholder from 'containers/JobBoardLists/JobBoardListPlaceholder';
import { JobBoardListPropsPure, JobBoardListRenderHeaderProps } from 'containers/JobBoardLists/JobBoardListProps';
import { jobBoardListActions, jobBoardListSelectors, JobBoardListState } from 'containers/JobBoardLists/store';

import { ListTableViewHeader, ListTableViewItem, ListViewPagination } from 'components/ListView';
import { useTickColumn } from 'components/ListView/hooks/useTickColumn';
import { GetColumnProps } from 'components/ListView/ListViewProps';
import { Column } from 'components/ListViewNew/ListViewProps';
import { Spinner } from 'components/Spinner';
import { TableList } from 'components/TableList';
import { useTableListUpdatePageHandler } from 'components/TableList/hooks';
import { useTableFooterCounters } from 'components/ui/ExTable/ExTable.hook';
import { useLoaderSubscription } from 'modules/LoaderManager/react';
import { pipe } from 'utils/funcs';

import { Columns, JobBoard, jobBoardActions, SortBy } from 'store/entities/job-boards';
import { RootState } from 'store/rootReducer';

const getColumn =
  ({ col, updateSort, sortMode }: GetColumnProps) =>
  ({ label, order, style, component }: Column<JobBoard, SortBy>) => {
    if (col === Columns.tickHeader) {
      return (
        <th className="column-action" style={style} key={col}>
          {component({ item: {} as JobBoard })}
        </th>
      );
    }
    return (
      <ListTableViewHeader
        key={col}
        order={order}
        updateSort={updateSort}
        sortMode={sortMode}
        label={label}
        style={style}
      />
    );
  };

const renderHeader = ({ updateSort, sortMode, columns }: JobBoardListRenderHeaderProps) => {
  const cols = Object.keys(columns) as Array<Columns>;
  return <>{cols.map((col) => pipe(columns[col], getColumn({ col, updateSort, sortMode })))}</>;
};

const JobBoardListPure: React.FC<JobBoardListPropsPure & PropsFromRedux> = ({
  jobBoards,
  recentJobBoards,
  loadJobBoards,
  updatePage,
  id,
  sortMode,
  updateSortMode,
  list,
  columns,
  toggleItem,
  toggleItemRadio,
  selectable,
  multiselect,
  filters,
  selectAll,
  deselectAll,
  selectableHeader,
  selectableHeaderDisabled,
  className,
}) => {
  const { isLoading: isJobBoardListLoading } = useLoaderSubscription(jobBoardActions.fetch);

  const updateSort = (updatedSortMode: JobBoardListState['sortMode']) => {
    updateSortMode({ id, sortMode: updatedSortMode });
    loadJobBoards({ listId: id });
  };

  const updatePageHandler = useTableListUpdatePageHandler({
    updateTableView: updatePage,
    id,
    load: loadJobBoards,
  });

  const [cols, setCols] = useState(columns);

  useEffect(() => {
    const updateAndFetch = () => {
      updatePage({ id, pageNo: 0 });
      loadJobBoards({ listId: id });
    };

    updateAndFetch();
  }, [filters, id, loadJobBoards, updatePage]);

  const isAllSelected = useMemo(
    () => list.selectedItems.length === list.items.length,
    [list.selectedItems, list.items],
  );

  const { headerTickColumn, tickColumn } = useTickColumn({
    deselectAll,
    id,
    isAllSelected,
    list,
    multiselect,
    selectAll,
    selectableHeaderDisabled,
    toggleItem,
    toggleItemRadio,
  });

  useEffect(() => {
    const { actions, ...rest } = columns;
    if (selectable) {
      setCols({ ...tickColumn, ...(selectableHeader ? headerTickColumn : {}), ...rest });
    }
    // eslint-disable-next-line
  }, [list]);

  const listColumns = useMemo(() => {
    const { [Columns.tickHeader]: headerTickColumnRemove, ...restListColumns } = cols;

    return restListColumns;
  }, [cols]);

  const headerColumns = useMemo(() => {
    const { [Columns.tick]: tickColumnRemove, ...restHeaderColumns } = cols;

    return restHeaderColumns;
  }, [cols]);

  const jobBoardList = jobBoards.length ? jobBoards : recentJobBoards;
  const dataLength = jobBoardList.length;
  const noData = [!list.fetched, !dataLength].some(Boolean);

  const { tableFooterCountersText } = useTableFooterCounters({
    pageNo: list.pageNo,
    pageCount: list.pageCount,
    totalItemsCount: list.totalItemsCount,
    pageSize: list.pageSize,
    section: 'Job Boards',
  });

  if (noData && isJobBoardListLoading) {
    return <Spinner />;
  }

  if (list.fetched && list.pageCount === 0) {
    return <JobBoardListPlaceholder />;
  }

  return (
    <TableList.TableListWrapper isLoading={isJobBoardListLoading} dataLength={dataLength}>
      <TableList
        tableResponsive={false}
        className={className}
        header={renderHeader({ updateSort, sortMode, columns: headerColumns })}
        pagination={
          <ListViewPagination updatePage={updatePageHandler} pageNo={list.pageNo} pageCount={list.pageCount} />
        }
        tableFooterText={tableFooterCountersText}
        pageCount={list.pageCount}
      >
        {jobBoardList.map((jobBoard) => (
          <ListTableViewItem key={jobBoard.jobBoardId} item={jobBoard} columns={listColumns} />
        ))}
      </TableList>
    </TableList.TableListWrapper>
  );
};

const mapDispatch = {
  loadJobBoards: jobBoardActions.fetch,
  updatePage: jobBoardListActions.updatePage,
  updateSortMode: jobBoardListActions.updateSort,
  updateFilters: jobBoardListActions.updateFilters,
  toggleItem: jobBoardListActions.toggleItemSelect,
  toggleItemRadio: jobBoardListActions.toggleItemRadio,
  selectAll: jobBoardListActions.selectAllItems,
  deselectAll: jobBoardListActions.deselectAllItems,
};

const mapState = (state: RootState, own: JobBoardListPropsPure) => ({
  jobBoards: jobBoardListSelectors.getJobBoards(state, own),
  recentJobBoards: jobBoardListSelectors.getRecentJobBoards(state, own),
  sortMode: jobBoardListSelectors.getSortMode(state, own),
  filters: jobBoardListSelectors.getFilters(state, own),
  list: jobBoardListSelectors.getListById(state, own),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const JobBoardList = connector(JobBoardListPure);

export default JobBoardList;
