import { createSelector } from '@reduxjs/toolkit';

import { createDeepEqualSelector } from 'utils/reducer/selector-helper';

import { SortDirections } from 'store/constants';
import { SortByForJobList } from 'store/entities/jobs/models';
import { RootState } from 'store/rootReducer';
import { jobsListUiSlice } from 'store/ui/jobs/jobs-list/jobs-list.reducer';

const domain = (state: RootState) => state.ui.jobsUi[jobsListUiSlice.name];

const selectFilters = createSelector(domain, (state) => state.filters);

const selectJobsListParams = createDeepEqualSelector(selectFilters, (filters) => {
  let orderBy = '';

  if (filters.sortMode.orderBy) {
    orderBy = `${filters.sortMode.orderBy}`;
    orderBy += filters.sortMode.orderDir === SortDirections.desc ? ' desc' : '';
  }

  const jobLocations = filters.jobLocations.map((location) => location.code.expedoCode2);
  const employmentTypes = filters.employmentTypes.map(({ value }) => value);
  const jobBoards = filters.jobBoards.map((jobBoard) => jobBoard.id);

  return {
    orderBy,
    jobStatuses: filters.jobStatuses,
    pageSize: filters.pageSize,
    pageNo: filters.pageNo,
    searchTerm: filters.searchTerm,
    employmentTypes,
    jobLocations,
    jobBoards,
  };
});

const selectJobListFields = createSelector(domain, (state) => state.jobListFields);

const selectJobListFieldsKeys = createSelector(selectJobListFields, (jobListFields) => {
  return jobListFields.reduce(
    (acc, v) => ({
      ...acc,
      [v.fieldName]: v.isChecked,
    }),
    {},
  ) as Record<SortByForJobList, boolean>;
});

export const jobsListSelectors = {
  selectFilters,
  selectJobsListParams,
  selectJobListFields,
  selectJobListFieldsKeys,
};
