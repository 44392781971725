import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';

import { AppRouterParams } from 'model/router';

import { ApplicantFormScreeningQuestionsElement } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/CompanyScreeningQuestionsComponents';

import { ApplicantModalTitle } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalV4Components';

import { Spinner } from 'components/Spinner';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { applicantSelectors } from 'store/entities/applicants/selectors';
import {
  useSearchJobApplicantScreeningQuestionsQuery,
  useSearchJobScreeningQuestionsQuery,
} from 'store/entities/screening-question/screening-question.api';

type ApplicantModalScreeningQuestionsProps = {
  className?: string;
};

const ApplicantModalScreeningQuestionsStyled = styled.div`
  margin-bottom: 20px;
`;

const useApplicantModalScreeningQuestionsState = ({ className }: ApplicantModalScreeningQuestionsProps) => {
  const match = useRouteMatch<AppRouterParams>();

  const applicantId = match.params.applicantId!;

  const applicantJobId = useAppSelector(applicantSelectors.selectApplicantJobId, applicantId);

  const jobId = applicantJobId! || match.params.jobId!;

  const { data: jobScreeningQuestions, isFetching: isFetchingJobScreeningQuestions } =
    useSearchJobScreeningQuestionsQuery({ jobId });

  const hasJobScreeningQuestions = !!jobScreeningQuestions?.ids?.length;

  const { data: screeningQuestions = [], isFetching: isFetchingApplicantScreeningQuestions } =
    useSearchJobApplicantScreeningQuestionsQuery(
      {
        jobId,
        applicantId,
      },
      {
        skip: !hasJobScreeningQuestions,
      },
    );

  const hasApplicantScreeningQuestions = !!screeningQuestions?.length;

  const noApplicantAnswers = !isFetchingApplicantScreeningQuestions && !hasApplicantScreeningQuestions;

  const showApplicantAnswers = !isFetchingApplicantScreeningQuestions && hasApplicantScreeningQuestions;

  return {
    className,
    screeningQuestions,
    isFetchingJobScreeningQuestions,
    hasJobScreeningQuestions,
    isFetchingApplicantScreeningQuestions,
    noApplicantAnswers,
    showApplicantAnswers,
  } as const;
};

export const ApplicantModalScreeningQuestions: React.FC<ApplicantModalScreeningQuestionsProps> = (props) => {
  const {
    className,
    screeningQuestions,
    isFetchingJobScreeningQuestions,
    hasJobScreeningQuestions,
    isFetchingApplicantScreeningQuestions,
    noApplicantAnswers,
    showApplicantAnswers,
  } = useApplicantModalScreeningQuestionsState(props);

  if (isFetchingJobScreeningQuestions) {
    return <Spinner />;
  }

  if (!hasJobScreeningQuestions) {
    return null;
  }

  return (
    <ApplicantModalScreeningQuestionsStyled className={className}>
      <ApplicantModalTitle>Screening Questions</ApplicantModalTitle>
      {isFetchingApplicantScreeningQuestions && <Spinner />}
      {noApplicantAnswers && (
        <div>This candidate was added to the job so they have not answered the screening questions.</div>
      )}
      {showApplicantAnswers &&
        screeningQuestions.map((question, index) => (
          <ApplicantFormScreeningQuestionsElement
            key={question.itemId}
            question={question}
            index={index}
            context="applicantModalScreeningQuestions"
          />
        ))}
    </ApplicantModalScreeningQuestionsStyled>
  );
};
