import { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { AppRouterParams } from 'model/router';

/**
 * Hook return callback for creating path from root
 *
 * @export
 * @returns
 */
export function useRouteFromRoot() {
  const match = useRouteMatch<AppRouterParams>();

  // For Link
  const makePathFromRoot = useCallback(
    (path: string) => (path.startsWith('/') ? path : `${match.path}/${path}`),
    [match.path],
  );

  // For Router Path
  const makeUrlFromRoot = useCallback(
    (path: string) => (path.startsWith('/') ? path : `${match.url}/${path}`),
    [match.url],
  );

  return { makePathFromRoot, makeUrlFromRoot };
}
