import styled from 'styled-components/macro';

type ExSizedBoxProps = {
  // Height of gap
  height?: number;
  // Width of gap
  width?: number;
};

export const ExSizedBox = styled.div<ExSizedBoxProps>`
  height: ${(props) => (props.height ? props.height + 'px' : 'auto')};
  width: ${(props) => (props.width ? props.width + 'px' : 'auto')};
  flex-shrink: 0;
`;
