import { createAction } from '@reduxjs/toolkit';

import { ViewMode } from 'store/constants';

import { JobBoardListState } from './models';

const JOB_BOARD_LIST_RESET_FILTERS = 'jobBoardList/filters/reset';
const JOB_BOARD_LIST_CHANGE_VIEW_MODE = 'jobBoardList/viewMode/change';

export const jobBoardListResetFilters = createAction<{ listId: JobBoardListState['id'] }>(JOB_BOARD_LIST_RESET_FILTERS);
export const jobBoardListChangeViewMode = createAction<{ listId: JobBoardListState['id']; viewMode: ViewMode }>(
  JOB_BOARD_LIST_CHANGE_VIEW_MODE,
);
