import * as apiCandidate from 'api-endpoints/candidate';
import * as talentPoolApi from 'api-endpoints/talent-pool';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { loaderActions } from 'containers/Loader/store';
import { talentPoolListActions, talentPoolListSelectors } from 'containers/TalentPoolLists/store/index';
import { mapTalentPools } from 'containers/TalentPoolLists/store/mappers';
import { TalentPoolListState } from 'containers/TalentPoolLists/store/models';

import { startLoaderThunk, stopLoaderThunk } from 'modules/LoaderManager/redux';
import { mapComplexListIdToMethod } from 'utils/list';

import { ViewMode } from 'store/constants';
import { talentPoolActions } from 'store/entities/talent-pools';
import { TalentPoolBelongsTo } from 'store/entities/talent-pools/models';
import { RootState } from 'store/rootReducer';
import { AppDispatch } from 'store/types';

export const loadTalentPoolsTaskId = 'loadTalentPoolsTask';

const mapTalentPoolListId = mapComplexListIdToMethod({
  [TalentPoolBelongsTo.main]: talentPoolApi.getTalentPoolList,
  [TalentPoolBelongsTo.candidate]: apiCandidate.getCandidateTalentPools,
});

export const loadTalentPools =
  ({ listId }: { listId: TalentPoolListState['id'] }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    startLoaderThunk(dispatch, loadTalentPools);
    dispatch(loaderActions.start(loadTalentPoolsTaskId));
    const state = getState();
    const params = talentPoolListSelectors.getListParams(state, { listId });
    const belongsTo = params?.filters?.belongsTo;
    const apiMethod = mapTalentPoolListId(belongsTo || listId);
    if (!apiMethod) return false;
    const apiMethodParams = (belongsTo || listId) === TalentPoolBelongsTo.main ? { params } : params;
    const { message, data } = await apiMethod(apiMethodParams);
    if (message) {
      dispatch(loaderActions.stop(loadTalentPoolsTaskId));
      dispatch(alertsEffects.showError({ message }));
      return false;
    }
    const { talentPoolIds, talentPools } = mapTalentPools(data?.items || []);
    dispatch(talentPoolActions.upsert({ items: talentPools }));
    dispatch(
      talentPoolListActions.fetchSuccess({
        id: listId,
        items: talentPoolIds,
        pageCount: data?.pageCount,
        totalItemsCount: data?.totalItemsCount,
      }),
    );
    dispatch(loaderActions.stop(loadTalentPoolsTaskId));
    stopLoaderThunk(dispatch, loadTalentPools);
    return !message;
  };

export const changeViewMode =
  (listId: TalentPoolListState['id'], viewMode: ViewMode, pushHistory?: Function) => async (dispatch: AppDispatch) => {
    dispatch(talentPoolListActions.updateViewMode({ id: listId, viewMode }));
    dispatch(talentPoolListActions.clearItems({ id: listId }));
    if (typeof pushHistory === 'function') pushHistory();
  };

export const talentPoolAutocomplete = (searchTerm: string) => async (dispatch: AppDispatch) => {
  const { message, data } = await talentPoolApi.talentPoolAutocompleteFunc(searchTerm);
  if (message) {
    dispatch(alertsEffects.showError({ message }));
    return [];
  }
  return data;
};
