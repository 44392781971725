import React, { useMemo } from 'react';
import { SelectCallback } from 'react-bootstrap/helpers';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import { cancelDelayedPipelineStageAction } from 'api-endpoints/hiring-pipeline';

import { useSinglePipeline } from 'pages/Jobs/Job/hooks/useSinglePipeline';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { ApplicantBulkActionsProps, ApplicantListComponentProps } from 'containers/ApplicantLists/ApplicantListProps';
import { applicantListSelectors } from 'containers/ApplicantLists/store/index';
import { loaderSelectors } from 'containers/Loader/store';

import { MenuItem } from 'components/ActionsDropdown';
import { ExActionButton } from 'components/ui/ExActionButton';
import { ExDropdownButton } from 'components/ui/ExButton/ExDropdownButton';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useModal } from 'utils/hooks/useModal';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { hiringPipelinesStagesSelectors } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.reducer';
import { hiringPipelineSelectors } from 'store/entities/hiring-pipelines/hiring-pipelines.reducer';
import { jobBulkDownloadFilesAction } from 'store/entities/jobs/job.actions';
import { jobEffects } from 'store/entities/jobs/job.effects';
import { RootState } from 'store/rootReducer';

const JobActionButton: React.FC<ApplicantBulkActionsProps & PropsFromRedux> = ({
  listId,
  jobId,
  selected,
  bulkUpdateApplicantPipelineStage,
  isBulkActionTaskActive,
  jobBulkDownloadFiles,
}) => {
  const modal = useModal({
    initialOpen: false,
  });
  const dispatch = useAppDispatch();

  useSinglePipeline({ jobId });

  const declineButtonLabel =
    useAppSelector(hiringPipelineSelectors.selectDeclineButtonLabelByJobId, jobId) ?? 'Decline applications';

  const firstStageButtonLabel =
    useAppSelector(hiringPipelineSelectors.selectFirstStageButtonLabelByJobId, jobId) ?? 'Shortlist applications';

  const firstStage = useAppSelector(hiringPipelinesStagesSelectors.selectFirstPipelineStageAfterNew, jobId);

  const declinedStage = useAppSelector(hiringPipelinesStagesSelectors.selectDeclinedPipelineStage, jobId);

  const menuItems: MenuItem[] = useMemo(
    () => [
      {
        label: declineButtonLabel,
        action: async () => {
          const cancellationId = await bulkUpdateApplicantPipelineStage({
            listId,
            jobId,
            applicantIds: selected,
            pipelineStageId: declinedStage?.pipelineStageId || '',
          });
          if (declinedStage?.needConfirmationModal) {
            dispatch(
              alertsEffects.showCancelableCountdown({
                message: `Sending email to applicants confirming progress to "${declinedStage?.name}".\n Do you want to cancel this?`,
                cancelMessage: 'Email sending cancelled',
                successMessage: 'Email sent successfully',
                duration: 10000,
                onCancel: () => {
                  cancelDelayedPipelineStageAction({ urlParams: { cancellationId }, data: { cancellationId } });
                },
              }),
            );
          }
        },
      },
      {
        label: firstStageButtonLabel,
        action: async () => {
          const cancellationId = await bulkUpdateApplicantPipelineStage({
            listId,
            jobId,
            applicantIds: selected,
            pipelineStageId: firstStage.pipelineStageId,
          });
          if (firstStage.needConfirmationModal) {
            dispatch(
              alertsEffects.showCancelableCountdown({
                message: `Sending email to applicants confirming progress to "${firstStage.name}".\n Do you want to cancel this?`,
                cancelMessage: 'Email sending cancelled',
                successMessage: 'Email sent successfully',
                duration: 10000,
                onCancel: () => {
                  cancelDelayedPipelineStageAction({ urlParams: { cancellationId }, data: { cancellationId } });
                },
              }),
            );
          }
        },
        variant: 'success',
      },
      {
        label: 'Download resumes',
        action: () => jobBulkDownloadFiles({ urlParams: { jobId }, data: { jobApplicantIds: selected } }),
      },
    ],
    [
      declineButtonLabel,
      firstStageButtonLabel,
      bulkUpdateApplicantPipelineStage,
      listId,
      jobId,
      selected,
      declinedStage,
      dispatch,
      firstStage,
      jobBulkDownloadFiles,
    ],
  );

  const handlerAction: SelectCallback = async (eventKey, event) => {
    event.preventDefault();
    const result = await modal.show({
      title: 'Confirmation',
      message: `Are you sure you want to perform this task?`,
      withActions: true,
      withTitle: true,
      buttonOk: 'Continue',
    });
    if (result) {
      const selectedIndex = Number(eventKey);
      const action = menuItems[selectedIndex].action;
      if (typeof action === 'function') {
        action();
      }
    }
  };

  return selected.length ? (
    <ExActionButton
      buttonId="jobApplicantBulkActionsDropdown"
      disabled={isBulkActionTaskActive}
      handlerAction={handlerAction}
      menuItems={menuItems}
      dropDirection="down"
      alignRight={false}
    >
      <ExDropdownButton variant="light">Bulk Actions</ExDropdownButton>
    </ExActionButton>
  ) : null;
};

const mapDispatch = {
  jobBulkDownloadFiles: jobBulkDownloadFilesAction,
  bulkUpdateApplicantPipelineStage: jobEffects.bulkUpdateApplicantPipelineStage,
};

const mapState = (state: RootState, own: ApplicantListComponentProps) => ({
  isBulkActionTaskActive: loaderSelectors.isTaskActive(state, {
    taskId: jobEffects.bulkJobActionTaskId,
  }),
  selected: applicantListSelectors.getSelectedItems(state, { id: own.listId }),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const ApplicantBulkActions = styled(connector(JobActionButton))``;
