import { Canceler } from 'axios';
import type {
  ApiRequestParamsWithPaginationAndFilters,
  ApiResponseWithPagination,
  DocumentDownloadFormat,
  SearchAutocompleteOptions,
} from 'model';

import { JobEndpoints } from 'api-endpoints/job/endpoints';
import type * as models from 'api-endpoints/job/models';
import { createApiMethod, getApiMappedFunction } from 'api-endpoints/utils';

import type { JobStatus } from 'model/api-enums.constants';

import { JobListSpecState } from 'containers/JobLists/store/models';

import { requestMapperUtil, responseMapperUtil } from 'utils/api';
import { curry } from 'utils/funcs';

import * as requestMappers from 'store/entities/jobs/api/requestMappers';
import * as requests from 'store/entities/jobs/api/requests';
import {
  getJobActivitySummaryAnalyticsResponseMapper,
  updateJobStatusResponseMapper,
} from 'store/entities/jobs/api/responseMappers';
import * as responses from 'store/entities/jobs/api/responses';
import { Job } from 'store/entities/jobs/models';

export * from 'api-endpoints/job/models';

export const cancelGetJobs: Canceler | null = null;

const curryAdapter = (fn: Function) => (jobId: Job['id'], params: ApiRequestParamsWithPaginationAndFilters) => {
  const requestParams = {
    params,
    urlParams: { jobId },
  };

  return fn(requestParams);
};

// -------------------------------------------------------------------------------------------------
// GET JOBS - SearchJobsFunc;
export const getJobsApiMethod = createApiMethod<
  requests.JobListRequestParams,
  ApiResponseWithPagination<models.JobForList>
>({
  url: JobEndpoints.SearchJobsFunc,
});

export const getJobs = getApiMappedFunction<
  requests.JobListRequestParams,
  ReturnType<typeof getJobsApiMethod>,
  ReturnType<typeof getJobsApiMethod>
>(getJobsApiMethod, requestMapperUtil([requestMappers.getJobParamsMapper]), responseMapperUtil());
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// GET JOB - GetJobFunc
export const getJob = createApiMethod<requests.JobGetRequestParams, models.JobItemResponse & Job>({
  url: JobEndpoints.GetJobFunc,
});
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// GET JOB CANDIDATE SOURCE - GetJobApplicantsPerSourceFiguresForJobFunc
const getJobCandidateSourceAnalyticsApiMethod = createApiMethod<
  requests.JobGetRequestParams,
  Pick<Job, 'jobApplicantsPerSourceFigures'>
>({ url: JobEndpoints.GetJobApplicantsPerSourceFiguresForJobFunc });

export const getJobCandidateSourceAnalytics = getApiMappedFunction<
  requests.JobGetRequestParams,
  ReturnType<typeof getJobCandidateSourceAnalyticsApiMethod>,
  ReturnType<typeof getJobCandidateSourceAnalyticsApiMethod>
>(
  getJobCandidateSourceAnalyticsApiMethod,
  requestMapperUtil(),
  responseMapperUtil([getJobActivitySummaryAnalyticsResponseMapper]),
);
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// GET JOB APPLICANT ACTIVITY ANALYTICS - GetJobApplicantsPerDayFiguresForJobFunc
const getJobApplicationActivityAnalyticApiMethod = createApiMethod<
  requests.JobGetRequestParams,
  Pick<Job, 'jobApplicantsPerDayFigures'>
>({ url: JobEndpoints.GetJobApplicantsPerDayFiguresForJobFunc });

export const getJobApplicationActivityAnalytic = getApiMappedFunction<
  requests.JobGetRequestParams,
  ReturnType<typeof getJobApplicationActivityAnalyticApiMethod>,
  ReturnType<typeof getJobApplicationActivityAnalyticApiMethod>
>(
  getJobApplicationActivityAnalyticApiMethod,
  requestMapperUtil(),
  responseMapperUtil([getJobActivitySummaryAnalyticsResponseMapper]),
);
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
const updateJobStatusApiMethod = createApiMethod<requests.JobChangeStatusRequestParams, Pick<Job, 'id' | 'status'>>({
  method: 'POST',
  url: JobEndpoints.ChangeJobStatusJobFunc,
});

export const updateJobStatus = getApiMappedFunction<
  requests.JobChangeStatusRequestParams,
  ReturnType<typeof updateJobStatusApiMethod>,
  ReturnType<typeof updateJobStatusApiMethod>
>(updateJobStatusApiMethod, requestMapperUtil(), responseMapperUtil([updateJobStatusResponseMapper]));
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// POST CREATE JOB - PostJobFunc
const postJobApiMethod = createApiMethod<requests.JobPostRequestParams, Job>({
  method: 'POST',
  url: JobEndpoints.PostJobFunc,
});

export const postJob = getApiMappedFunction<
  requests.JobPostRequestParams,
  ReturnType<typeof postJobApiMethod>,
  ReturnType<typeof postJobApiMethod>
>(postJobApiMethod, requestMapperUtil([requestMappers.postJobParamsMapper]), responseMapperUtil());
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// POST CREATE JOB AS OPEN - PostOpenJobFunc
const postJobAsOpenApiMethod = createApiMethod<requests.JobPostRequestParams, Pick<Job, 'jobId'>>({
  method: 'POST',
  url: JobEndpoints.PostOpenJobFunc,
});

export const postJobAsOpen = getApiMappedFunction<
  requests.JobPostRequestParams,
  ReturnType<typeof postJobAsOpenApiMethod>,
  ReturnType<typeof postJobAsOpenApiMethod>
>(postJobAsOpenApiMethod, requestMapperUtil([requestMappers.postJobParamsMapper]), responseMapperUtil());
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
const putJobApiMethod = createApiMethod<requests.JobPutRequestParams, any>({
  method: 'PUT',
  url: JobEndpoints.PutJobFunc,
});

export const putJob = getApiMappedFunction<
  requests.JobPutRequestParams,
  ReturnType<typeof putJobApiMethod>,
  ReturnType<typeof putJobApiMethod>
>(putJobApiMethod, requestMapperUtil([requestMappers.putJobParamsMapper]), responseMapperUtil());
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
const putJobSalaryApiMethod = createApiMethod<
  requests.JobPutSalaryRequestParams,
  Partial<Job['salary']> & { id: Job['id'] }
>({
  method: 'PUT',
  url: JobEndpoints.PutJobSalaryFunc,
});

export const putJobSalary = getApiMappedFunction<
  requests.JobPutSalaryRequestParams,
  ReturnType<typeof putJobSalaryApiMethod>,
  ReturnType<typeof putJobSalaryApiMethod>
>(putJobSalaryApiMethod, requestMapperUtil([requestMappers.putJobSalaryParamsMapper]), responseMapperUtil());
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
const changeWorkflowStageApiMethod = createApiMethod<
  requests.JobChangeWorkflowRequest,
  responses.JobChangeWorkflowResponse
>({
  method: 'PUT',
  url: JobEndpoints.ChangePipelineStageFunc,
});

export const changeWorkflowStage = getApiMappedFunction<
  requests.JobChangeWorkflowRequest,
  ReturnType<typeof changeWorkflowStageApiMethod>,
  ReturnType<typeof changeWorkflowStageApiMethod>
>(changeWorkflowStageApiMethod, requestMapperUtil([requestMappers.changeWorkflowStageMapper]), responseMapperUtil());
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
export const getJobsCountByStatusMethod = createApiMethod<any, JobListSpecState>({
  url: JobEndpoints.GetJobCountFunc,
});

export const getJobsCountByStatus = getApiMappedFunction<
  any,
  ReturnType<typeof getJobsCountByStatusMethod>,
  ReturnType<typeof getJobsCountByStatusMethod>
>(getJobsCountByStatusMethod, requestMapperUtil([requestMappers.getJobsCountParamsMapper]), responseMapperUtil());
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
export const getJobsDownloadLink = (format: DocumentDownloadFormat, status: JobStatus) =>
  `${process.env.REACT_APP_API_BASE_URL}/api/export/jobs?exportType=${format}&jobStatuses=${status}`;
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
export const getJobApplicant = createApiMethod<requests.JobGetRequestParams, models.JobApplicantItem>({
  url: JobEndpoints.GetJobApplicantDetailsFunc,
});
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
export const bulkUpdateApplicantPipelineStageMethod = createApiMethod<
  requests.BulkUpdateApplicantPipelineStageRequestParams,
  responses.BulkUpdateApplicantPipelineStageResponse
>({
  method: 'POST',
  url: JobEndpoints.BulkUpdateApplicantPipelineStageFunc,
});

export const bulkUpdateApplicantPipelineStage = getApiMappedFunction<
  requests.BulkUpdateApplicantPipelineStageRequest,
  ReturnType<typeof bulkUpdateApplicantPipelineStageMethod>,
  ReturnType<typeof bulkUpdateApplicantPipelineStageMethod>
>(
  bulkUpdateApplicantPipelineStageMethod,
  requestMapperUtil([requestMappers.bulkUpdateApplicantPipelineStageRequestMapper]),
  responseMapperUtil(),
);
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
export const jobAutocomplete = createApiMethod<requests.JobAutocompleteRequestParams, SearchAutocompleteOptions>({
  url: JobEndpoints.JobsAutocompleteFunc,
});
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
export const getJobByReferenceNumber = createApiMethod<
  requests.GetJobByReferenceNumberRequestParams,
  models.JobByReferenceNumberResponse
>({ url: JobEndpoints.GetJobByReferenceNumberFunc });
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
const getJobAdsForJobApiMethod = createApiMethod<
  requests.GetJobAdsForJobRequestParams,
  ApiResponseWithPagination<models.JobAdForJobListItemResponse>
>({ url: JobEndpoints.SearchJobAdsForJobFunc });

const getJobAdsForJobMapped = getApiMappedFunction<
  requests.GetJobAdsForJobRequestParams,
  ReturnType<typeof getJobAdsForJobApiMethod>,
  ReturnType<typeof getJobAdsForJobApiMethod>
>(getJobAdsForJobApiMethod, requestMapperUtil([requestMappers.getJobAdsForJobMapper]), responseMapperUtil());

export const getJobAdsForJob = curry(curryAdapter(getJobAdsForJobMapped));
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------

const searchJobAdsForActiveJobsMethod = createApiMethod<
  requests.GetJobAdsForJobRequestParams,
  ApiResponseWithPagination<models.JobAdForJobListItemResponse>
>({
  url: JobEndpoints.SearchJobAdsForActiveJobsFunc,
});

const searchJobAdsForActiveJobsMapped = getApiMappedFunction<
  requests.GetJobAdsForJobRequestParams,
  ReturnType<typeof searchJobAdsForActiveJobsMethod>,
  ReturnType<typeof searchJobAdsForActiveJobsMethod>
>(searchJobAdsForActiveJobsMethod, requestMapperUtil([requestMappers.getJobAdsForJobMapper]), responseMapperUtil());

export const searchJobAdsForActiveJobs = curry(curryAdapter(searchJobAdsForActiveJobsMapped));

// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
export const jobJobBoardsAutocomplete = createApiMethod<
  requests.JobJobBoardAutocompleteRequestParams,
  SearchAutocompleteOptions
>({ url: JobEndpoints.JobBoardsForJobAutocompleteFunc });
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
const getJobApplicantsApiMethod = createApiMethod<
  requests.GetJobApplicantsForJobRequestParams,
  ApiResponseWithPagination<models.JobApplicantListItem>
>({
  method: 'POST',
  url: JobEndpoints.SearchJobApplicantsFunc,
});

const getJobApplicantsForJobMapped = getApiMappedFunction<
  requests.GetJobApplicantsForJobRequestParams,
  ReturnType<typeof getJobApplicantsApiMethod>,
  ReturnType<typeof getJobApplicantsApiMethod>
>(getJobApplicantsApiMethod, requestMapperUtil([requestMappers.getJobApplicantsForJobMapper]), responseMapperUtil());

export const getJobApplicantsForJob = curry(curryAdapter(getJobApplicantsForJobMapped));
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// GET GetApplicantsPerStageForJobBoardFunc
export const getApplicantsPerStageForJobBoardFunc = createApiMethod<
  requests.GetApplicantsPerStageForJobBoardFuncParams,
  requests.GetApplicantsPerStageForJobBoardFuncResponse
>({
  method: 'GET',
  url: JobEndpoints.GetApplicantsPerStageForJobBoardFunc,
});

// -------------------------------------------------------------------------------------------------
// GET GetApplicantsReceivedPerDayForJobFunc
export const getApplicantsReceivedPerDayForJobFunc = createApiMethod<
  requests.GetApplicantsRecievedPerDayForJobFuncParams,
  requests.GetApplicantsRecievedPerDayForJobFuncResponse
>({
  url: JobEndpoints.GetApplicantsReceivedPerDayForJobFunc,
});
// GetJobDeadlineFunc
export const getJobDeadlineFunc = createApiMethod<
  requests.GetJobDeadlineFuncParams,
  requests.GetJobDeadlineFuncResponse
>({
  url: JobEndpoints.GetJobDeadlineFunc,
});
// -------------------------------------------------------------------------------------------------

// GetJobDaysOpenFunc
export const getJobDaysOpenFunc = createApiMethod<
  requests.GetJobDaysOpenFuncParams,
  requests.GetJobDaysOpenFuncResponse
>({
  url: JobEndpoints.GetJobDaysOpenFunc,
});
// -------------------------------------------------------------------------------------------------

// GetJobAcceptanceRateFunc
export const getJobAcceptanceRateFunc = createApiMethod<requests.GetSmallWidgetParams, requests.GetSmallWidgetResponse>(
  {
    url: JobEndpoints.GetJobAcceptanceRateFunc,
  },
);
// -------------------------------------------------------------------------------------------------

// GetJobAverageTimeToHireFunc
export const getJobAverageTimeToHireFunc = createApiMethod<
  requests.GetSmallWidgetParams,
  requests.GetSmallWidgetResponse
>({
  url: JobEndpoints.GetJobAverageTimeToHireFunc,
});
// -------------------------------------------------------------------------------------------------

// GetJobAverageTimeToHireFunc
export const bulkApplyFromResumesFunc = createApiMethod<
  requests.BulkApplyFromResumesFuncParams,
  requests.BulkApplyFromResumesFuncResponse
>({
  method: 'POST',
  url: JobEndpoints.BulkApplyFromResumesFunc,
});
// -------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------------------
// GetJobTeamMemberAutocompleteFunc
export const getJobTeamMemberAutocompleteFunc = createApiMethod<
  models.GetJobTeamMemberAutocompleteFuncParams,
  models.GetJobTeamMemberAutocompleteFuncResponse
>({
  url: JobEndpoints.GetJobTeamMemberAutocompleteFunc,
});
//----------------------------------------------------------------------------------------------------------------------

// GetJobTeamFunc
export const getJobTeamFunc = createApiMethod<requests.JobGetRequestParams, Pick<Job, 'teamMembers'>>({
  url: JobEndpoints.GetJobTeamFunc,
});
// -------------------------------------------------------------------------------------------------

// AddMemberToJobTeamFunc
export const addMemberToJobTeamFunc = createApiMethod<requests.JobTeamMemberRequestParams, void>({
  method: 'POST',
  url: JobEndpoints.AddMemberToJobTeamFunc,
});
// -------------------------------------------------------------------------------------------------

// RemoveMemberFromJobTeamFunc
export const removeMemberFromJobTeamFunc = createApiMethod<requests.JobTeamMemberRequestParams, void>({
  method: 'POST',
  url: JobEndpoints.RemoveMemberFromJobTeamFunc,
});
// -------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------------------
// JOB COMMENTS START
// SearchCommentsForJobFunc
export const searchCommentsForJobFunc = createApiMethod<
  requests.SearchCommentsForJobFuncParams,
  requests.SearchCommentsForJobFuncResponse
>({
  url: JobEndpoints.SearchCommentsForJobFunc,
});
// AddCommentForJobFunc
export const addCommentForJobFunc = createApiMethod<
  requests.AddCommentForJobFuncParams,
  requests.AddCommentForJobFuncResponse
>({
  method: 'POST',
  url: JobEndpoints.AddCommentForJobFunc,
});
// GetCommentForJobFunc
export const getCommentForJobFunc = createApiMethod<
  requests.GetCommentForJobFuncParams,
  requests.GetCommentForJobFuncResponse
>({
  method: 'GET',
  url: JobEndpoints.GetCommentForJobFunc,
});
// UpdateCommentForJobFunc
export const updateCommentForJobFunc = createApiMethod<
  requests.UpdateCommentForJobFuncParams,
  requests.UpdateCommentForJobFuncResponse
>({
  method: 'PUT',
  url: JobEndpoints.UpdateCommentForJobFunc,
});
// DeleteCommentForJobFunc
export const deleteCommentForJobFunc = createApiMethod<
  requests.DeleteCommentForJobFuncParams,
  requests.DeleteCommentForJobFuncResponse
>({
  method: 'DELETE',
  url: JobEndpoints.DeleteCommentForJobFunc,
});
// ReadCommentsForJobFunc
export const readCommentsForJobFunc = createApiMethod<
  requests.ReadCommentsForJobFuncParams,
  requests.ReadCommentsForJobFuncResponse
>({
  method: 'POST',
  url: JobEndpoints.ReadCommentsForJobFunc,
});
// ReadAllCommentForJobFunc
export const readAllCommentForJobFunc = createApiMethod<
  requests.ReadAllCommentForJobFuncParams,
  requests.ReadAllCommentForJobFuncResponse
>({
  method: 'POST',
  url: JobEndpoints.ReadAllCommentForJobFunc,
});

// JOB COMMENTS END
// ---------------------------------------------------------------------------------------------------------------------

//BulkDownloadFilesFunc
export const bulkDownloadFilesFunc = createApiMethod<
  requests.BulkDownloadFilesFuncParams,
  requests.BulkDownloadFilesFuncResponse
>({
  method: 'POST',
  url: JobEndpoints.BulkDownloadFilesFunc,
});
// ---------------------------------------------------------------------------------------------------------------------

export const updateJobPipelineSettingsFunc = createApiMethod<
  requests.UpdateJobPipelineSettingsFuncParams,
  requests.UpdateJobPipelineSettingsFuncResponse
>({
  method: 'PUT',
  url: JobEndpoints.UpdateJobPipelineSettingsFunc,
});

export const getJobChangeNotes = createApiMethod<
  requests.SearchJobChangeNotesFuncRequest,
  requests.SearchJobChangeNotesFuncResponse
>({
  method: 'GET',
  url: JobEndpoints.SearchJobChangeNotesFunc,
});
