import React, { useCallback, useEffect, useRef, useState } from 'react';
import AvatarEditor, { AvatarEditorProps } from 'react-avatar-editor';

import { imagesConfig } from 'config';

import { WithRequired } from 'model/utils';

import { AppImageEditorStyled, AppImageScaleInput } from './AppImageEditorComponents';
import { AppImageEditorProps, AppImageEditorTypes } from './AppImageEditorProps';

const AVATAR_EDITOR_WIDTH = 160;

const getImageWidth = (square: boolean, aspectRatio: number) => {
  return square
    ? imagesConfig.AvatarUploadWidth
    : imagesConfig.AvatarUploadHeight * aspectRatio || imagesConfig.AvatarUploadWidth;
};

const getImageHeight = (square: boolean, aspectRatio: number) => {
  return square ? AVATAR_EDITOR_WIDTH : AVATAR_EDITOR_WIDTH / aspectRatio;
};

const getFileOrEmpty = (file: File) => {
  return file ?? '';
};

export const AppImageEditor: React.FC<AppImageEditorProps> = ({
  className,
  image,
  onChange,
  type = AppImageEditorTypes.JPG,
  square = true,
  bgcolor = '#fff',
  ...rest
}) => {
  const avatarEditor = useRef<AvatarEditor | null>(null);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0, aspectRatio: 0 });
  const isPng = type === AppImageEditorTypes.PNG;

  useEffect(() => {
    const img = new Image();

    img.onload = () => {
      const imgWidth = img.width;
      const imgHeight = img.height;
      const aspectRatio = imgWidth / imgHeight;

      setImageSize({
        width: imgWidth,
        height: imgHeight,
        aspectRatio,
      });

      setParams((s) => {
        return {
          ...s,
          height: getImageHeight(square, aspectRatio),
        };
      });
    };

    img.src = URL.createObjectURL(image);
  }, [image, square]);

  const fileType = type;
  const width = getImageWidth(square, imageSize.aspectRatio);
  const height = imagesConfig.AvatarUploadHeight;

  const toBlobCallback = useCallback<(blob: Blob | null) => void>(
    (blob) => {
      if (!blob) {
        return;
      }
      const file = new File([blob], 'newAvatar.png', { lastModified: Date.now(), type: fileType });
      if (typeof onChange === 'function') {
        onChange(file);
      }
    },
    [fileType, onChange],
  );

  const saveClickHandler = useCallback(() => {
    const dataURL = avatarEditor.current?.getImage().toDataURL();
    const img = new Image();
    img.height = height;
    img.width = width;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx || !dataURL) {
      return;
    }

    ctx.imageSmoothingQuality = 'high';

    img.onload = () => {
      canvas.height = height;
      canvas.width = width;

      if (bgcolor && !isPng) {
        ctx.fillStyle = bgcolor;
        ctx.fillRect(0, 0, width, height);
      }

      ctx.drawImage(img, 0, 0, width, height);
      canvas.toBlob(toBlobCallback, fileType, 1);
    };
    img.src = dataURL;
  }, [bgcolor, fileType, height, isPng, toBlobCallback, width]);

  const scaleChangeHandler: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const { value, name } = e.target;
      setParams((s) => ({ ...s, [name]: Number.isNaN(Number(value)) ? value : Number(value) }));
      setTimeout(() => {
        saveClickHandler();
      }, 0);
    },
    [saveClickHandler],
  );

  const [params, setParams] = useState(
    (): WithRequired<AvatarEditorProps, 'image'> => {
      return {
        width: AVATAR_EDITOR_WIDTH,
        height: AVATAR_EDITOR_WIDTH,
        scale: 1,
        border: 200 / 10,
        image: getFileOrEmpty(image),
        color: [0, 0, 0, 0.3],
      };
    },
  );

  if ([!imageSize.width, !imageSize.height].some(Boolean)) {
    return null;
  }

  return (
    <>
      <AppImageEditorStyled
        className={className}
        {...params}
        ref={avatarEditor}
        onPositionChange={saveClickHandler}
        onLoadSuccess={saveClickHandler}
        {...rest}
      />
      <AppImageScaleInput
        name="scale"
        type="range"
        min="1"
        max="3"
        step="0.2"
        onChange={scaleChangeHandler}
        defaultValue={params.scale}
      />
    </>
  );
};
