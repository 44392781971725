import React from 'react';
import { ThemeProvider } from 'styled-components/macro';

type ScreeningQuestionsThemeProps = {
  className?: string;
  children: any;
  theme: any;
};

export const ScreeningQuestionsTheme: React.FC<ScreeningQuestionsThemeProps> = ({ children, theme }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
