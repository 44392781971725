import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';

import { AppRouterParams } from 'model/router';

import { CandidateDetailsTabCardEmptyItem } from 'pages/Candidates/Candidate/CandidateDetailsTab/CandidateDetailsTabComponents';
import { CandidateWorkExperienceCardItemV4 } from 'pages/Candidates/Candidate/CandidateDetailsTab/components/CandidateWorkExperienceCardItem/CandidateWorkExperienceCardItemV4';

import { ApplicantModalTitle } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalV4Components';
import { applicantModalSliceSelectors } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/store';

import { Spinner } from 'components/Spinner';
import { useLoaderSubscription } from 'modules/LoaderManager/react';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { createByIdActionLoader } from 'utils/loaders/createByIdActionLoader';
import { customLoadAction } from 'utils/loaders/customLoadAction';

type ApplicantModalWorkExperienceViewProps = {
  className?: string;
};

const ApplicantModalWorkExperienceViewStyled = styled.div``;

const ApplicantModalWorkExperienceViewItemsWrapperStyled = styled.div`
  margin-bottom: 20px;

  & > div:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

const useApplicantModalWorkExperienceViewState = ({ className }: ApplicantModalWorkExperienceViewProps) => {
  const match = useRouteMatch<AppRouterParams>();
  const { applicantId } = match.params;

  const workExperienceIds = useAppSelector(applicantModalSliceSelectors.selectWorkExperienceIdsForCurrentApplicant);
  const hasWorkExperience = Boolean(workExperienceIds.length);

  const { isLoading } = useLoaderSubscription(
    createByIdActionLoader(customLoadAction({ type: 'loadWorkExperience', id: applicantId! })),
  );

  const hasItems = !isLoading && hasWorkExperience;
  const isEmpty = !isLoading && !hasItems;

  return {
    className,
    workExperienceIds,
    hasItems,
    isLoading,
    isEmpty,
  } as const;
};

export const ApplicantModalWorkExperienceView: React.FC<ApplicantModalWorkExperienceViewProps> = (props) => {
  const { className, workExperienceIds, hasItems, isLoading, isEmpty } =
    useApplicantModalWorkExperienceViewState(props);

  return (
    <ApplicantModalWorkExperienceViewStyled className={className}>
      <ApplicantModalTitle>Work Experience</ApplicantModalTitle>
      <ApplicantModalWorkExperienceViewItemsWrapperStyled>
        {isLoading && <Spinner />}
        {hasItems &&
          workExperienceIds.map((workExperience) => (
            <CandidateWorkExperienceCardItemV4
              key={workExperience}
              workExperienceId={workExperience}
              isEditable={false}
            />
          ))}
        {isEmpty && <CandidateDetailsTabCardEmptyItem>Please refer to Resume</CandidateDetailsTabCardEmptyItem>}
      </ApplicantModalWorkExperienceViewItemsWrapperStyled>
    </ApplicantModalWorkExperienceViewStyled>
  );
};
