import { createAction } from '@reduxjs/toolkit';

import {
  PublicUploaderProps,
  PublicUploadTypes,
} from 'pages/public/state/public-new-applicant-form/public-new-applicant-form.model';
import { publicNewApplicantFormSlice } from 'pages/public/state/public-new-applicant-form/public-new-applicant-form.reducer';

const publicNewApplicantFormSubmitAction = createAction<{ jobId: string }>(
  `${publicNewApplicantFormSlice.name}/submit`,
);

const formUploadAction = createAction<PublicUploaderProps>(`${publicNewApplicantFormSlice.name}/upload`);
const formRemoveUploadAction = createAction<{ type: PublicUploadTypes }>(
  `${publicNewApplicantFormSlice.name}/remove-upload`,
);

const publicNewApplicantFormCloseAction = createAction<{ backPath: string }>(
  `${publicNewApplicantFormSlice.name}/close-form`,
);

export const publicNewApplicantFormActions = {
  ...publicNewApplicantFormSlice.actions,
  publicNewApplicantFormSubmitAction,
  formUploadAction,
  formRemoveUploadAction,
  publicNewApplicantFormCloseAction,
};
