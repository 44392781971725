import React, { useEffect, useState } from 'react';
import { useAppRouterParams } from 'router/useAppRouterParams';
import styled, { css } from 'styled-components/macro';

import {
  ApplicantModalBodyWrapper,
  ApplicantModalForm,
  ApplicantModalInfo,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalV4Components';
import { applicantModalSlice } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/store';
import { modalsActions } from 'containers/Modals/store';

import { ApplicantInfoSectionView } from 'components/ApplicantSideModal/components/ApplicantInfoSectionView';
import { ApplicantModalBodyView } from 'components/ApplicantSideModal/components/ApplicantModalBodyView/ApplicantModalBodyView';
import { ModalSide } from 'components/ModalSide';
import { ModalWrapper } from 'components/ModalSide/ModalSideComponents';
import { ExVisible } from 'components/ui/ExVisible';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useNoScrollBodyWhen } from 'utils/hooks/useNoScrollBody';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { applicantsActions, applicantSelectors } from 'store/entities/applicants';

import { ApplicantSideModalViewBtn } from './components/ApplicantSideModalViewBtn';

type ApplicantSideModalProps = {
  className?: string;
};

const ApplicantSideModalStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const ApplicantSideModalViewBtnStyled = styled(ApplicantSideModalViewBtn)`
  position: fixed;
  top: 107px;
  right: 0;
`;

type ApplicantModalSideProps = {
  minHeight?: number;
};

const applicantModalSideMinHeight = ({ minHeight }: ApplicantModalSideProps) =>
  minHeight &&
  css`
    min-height: ${minHeight}px;
  `;

const ApplicantModalSide = styled(ModalSide)<ApplicantModalSideProps>`
  ${applicantModalSideMinHeight};

  &.modal-transition-enter {
    opacity: 1;
  }

  &.modal-transition-exit-active {
    opacity: 1;
  }

  ${ModalWrapper} {
    position: relative;
    overflow-y: visible;
    max-width: 90%;
    height: auto;
    top: 77px;
  }
`;

const ApplicantSideModalViewBtnModalStyled = styled(ApplicantSideModalViewBtn)`
  position: absolute;
  right: 100%;
`;

const ApplicantModalFormStyled = styled(ApplicantModalForm)`
  max-height: 80vh;
`;

const useApplicantSideModalState = ({ className }: ApplicantSideModalProps) => {
  const [sideModalIsOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();

  const { applicantId, jobId: paramsJobId } = useAppRouterParams();

  const applicant = useAppSelector(applicantSelectors.selectApplicantModalData, applicantId);
  const jobId = applicant?.jobId ?? paramsJobId;

  useEffect(() => {
    if (applicantId && jobId) {
      dispatch(applicantsActions.applicantsModalFetchAction({ applicantId, jobId }));
    }
  }, [applicantId, dispatch, jobId]);

  const toggleModalSide = () => {
    setIsOpen(!sideModalIsOpen);
  };

  useEffect(() => {
    if (applicantId) {
      dispatch(applicantModalSlice.actions.set({ currentApplicantId: applicantId }));
      dispatch(modalsActions.setApplicantId({ applicantId }));
    }
    return () => {
      dispatch(applicantModalSlice.actions.set({ currentApplicantId: null }));
      dispatch(modalsActions.setApplicantId({ applicantId: null }));
    };
  }, [applicantId, dispatch]);

  useNoScrollBodyWhen(sideModalIsOpen);

  return {
    className,
    sideModalIsOpen,
    toggleModalSide,
  } as const;
};

export const ApplicantSideModal: React.FC<ApplicantSideModalProps> = (props) => {
  const { className, sideModalIsOpen, toggleModalSide } = useApplicantSideModalState(props);

  return (
    <ApplicantSideModalStyled className={className}>
      <ExVisible visible={!sideModalIsOpen}>
        <ApplicantSideModalViewBtnStyled title={'View Applicant Profile'} onClick={toggleModalSide} />
      </ExVisible>
      <ModalSide.ModalCSSTransition in={sideModalIsOpen}>
        <ApplicantModalSide onClose={toggleModalSide}>
          <ApplicantSideModalViewBtnModalStyled title={'View Applicant Profile'} onClick={toggleModalSide} />
          <ApplicantModalFormStyled as="div">
            <ApplicantModalInfo>
              <ApplicantInfoSectionView />
            </ApplicantModalInfo>
            <ApplicantModalBodyWrapper>
              <ApplicantModalBodyView />
            </ApplicantModalBodyWrapper>
          </ApplicantModalFormStyled>
        </ApplicantModalSide>
      </ModalSide.ModalCSSTransition>
    </ApplicantSideModalStyled>
  );
};
