import React from 'react';
import styled from 'styled-components/macro';
import { isDate } from 'date-fns';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { dateFormat } from 'config';

import {
  InterviewTableCellComponent,
  InterviewTableCellComponentProps,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/Tabs/InterviewsTab/InterviewsTabProps';

const InterviewTableDateStyled = styled.div``;

const useInterviewTableDateState = ({ item }: InterviewTableCellComponentProps) => {
  const startDate = item.publishedOn;

  if (!startDate) {
    return {
      date: '--',
    };
  }

  const parsedStartDate = isDate(startDate) ? (startDate as unknown as Date) : parseISO(startDate);

  const formattedStartDate = format(parsedStartDate, dateFormat.formatDateForApplicantTable);

  return {
    date: formattedStartDate,
  } as const;
};

export const InterviewTableDate: InterviewTableCellComponent = (props) => {
  const { date } = useInterviewTableDateState(props);

  return <InterviewTableDateStyled>{date}</InterviewTableDateStyled>;
};
