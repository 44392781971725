import { createSelector } from '@reduxjs/toolkit';
import { format, parseISO } from 'date-fns';

import { computeCandidateName } from 'utils/candidate';
import { isDateGuard } from 'utils/hooks/dateTime';
import { getCandidateAddressLine } from 'utils/list';
import { createLookupSelectors } from 'utils/reducer/selector-helper';

import { companySelectors } from 'store/company/company.selectors';
import { EntityKeys } from 'store/constants';
import { countriesSelectors } from 'store/dictionary/country';
import { Candidate, SocialNetwork } from 'store/entities/candidates/models';
import { RootState } from 'store/rootReducer';

const lookupSelectors = createLookupSelectors<Candidate>(EntityKeys.candidates);
const selectSocialNetworkById = createSelector(
  lookupSelectors.getById,
  (state) => (state as Candidate)?.socialNetworks,
);
const selectById = lookupSelectors.getById;

const selectCandidateAvatar = createSelector(selectById, (candidate) => candidate?.photoUrl);

const selectCandidateIsResumeExtracted = createSelector(selectById, (candidate) => candidate?.resumeExtracted);

const selectCandidateFullName = createSelector(selectById, computeCandidateName);

const selectAppliedOnFormatted = createSelector(
  selectById,
  companySelectors.getDefaultDateFormat,
  (candidate, dateFormat) => {
    const value = candidate?.addedDate;
    if (!value) {
      return '--';
    }

    const dateToFormat = isDateGuard(value) ? value : parseISO(value);

    return format(dateToFormat, dateFormat);
  },
);

const selectCandidateTalentPoolCardById = createSelector(
  selectById,
  selectCandidateFullName,
  selectAppliedOnFormatted,
  selectCandidateAvatar,
  selectCandidateIsResumeExtracted,
  (candidate, fullName, addedOn, avatar, candidateIsResumeExtracted) => {
    return {
      id: candidate?.id,
      candidateId: candidate?.id,
      avatar,
      fullName,
      addedOn,
      resumeExtracted: candidateIsResumeExtracted,
    };
  },
);

const selectCandidatePhone = createSelector(selectById, (candidate) => {
  return candidate?.mobile;
});

const selectCandidateEmail = createSelector(selectById, (candidate) => {
  return candidate?.email;
});

const selectCandidateFullAddress = createSelector(
  selectById,
  (state: RootState) => state,
  (candidate, state) => {
    const candidateCountry = countriesSelectors.selectById(state, candidate?.country ?? 'AU');

    return getCandidateAddressLine(candidate, candidateCountry);
  },
);

const CANDIDATE_SUMMARY_NETWORKS_SHOW_COUNT = 3;

const selectCandidateNetworksWithCounterById = createSelector(selectSocialNetworkById, (socialNetworks) => {
  const existedNetworks = socialNetworks?.filter((network) => Boolean(network.url)) ?? [];
  const networksToShow = existedNetworks?.splice(0, CANDIDATE_SUMMARY_NETWORKS_SHOW_COUNT);
  const restNetworksCount = existedNetworks.length;
  const networks = networksToShow.map((socialNetwork) => ({
    ...socialNetwork,
    icon: SocialNetwork[socialNetwork.type],
    title: SocialNetwork[socialNetwork.type],
  }));

  return {
    networks,
    restNetworksCount,
  };
});

const selectCandidateAvatarWithFullName = createSelector(
  selectById,
  selectCandidateAvatar,
  selectCandidateFullName,
  (candidate, candidateAvatar, candidateFullName) => {
    return {
      avatar: candidateAvatar,
      fullName: candidateFullName,
    };
  },
);

const selectCandidateSummaryById = createSelector(
  selectById,
  selectCandidateAvatarWithFullName,
  selectCandidatePhone,
  selectCandidateEmail,
  selectCandidateFullAddress,
  selectCandidateNetworksWithCounterById,
  selectCandidateIsResumeExtracted,
  (
    candidate,
    candidateAvatarWithFullName,
    candidatePhone,
    candidateEmail,
    candidateFullAddress,
    candidateNetworksWithCounterById,
    candidateIsResumeExtracted,
  ) => {
    return {
      candidateId: candidate?.id,
      resumeExtracted: candidateIsResumeExtracted,
      avatar: candidateAvatarWithFullName.avatar,
      fullName: candidateAvatarWithFullName.fullName,
      mobile: candidatePhone,
      email: candidateEmail,
      address: candidateFullAddress,
      socialNetworks: candidateNetworksWithCounterById,
      doNotHire: candidate?.doNotHire,
    };
  },
);

export const candidatesSelectors = {
  ...lookupSelectors,
  selectById,
  selectSocialNetworkById,
  selectCandidateTalentPoolCardById,
  selectCandidateAvatar,
  selectAppliedOnFormatted,
  selectCandidateFullName,
  selectCandidateSummaryById,
};
