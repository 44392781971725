import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import uniqBy from 'lodash/uniqBy';

import { jobFormActions } from 'containers/JobForms/state';
import { selectInterviewTemplatesSelected } from 'containers/JobForms/state/selectors';

import { FormSelect } from 'components/FormSelect';
import { useInterviewTemplateSearch } from 'utils/hooks/InterviewTemplate';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

export const InterviewTemplates = () => {
  const { setValue, errors, formState } = useFormContext();
  const validated = formState.isSubmitted;
  const dispatch = useAppDispatch();

  const {
    interviewTemplatesOptions,
    isFetchingCurrentPage,
    loadMoreInterviewTemplates,
    onInputInterviewTemplatesChange,
  } = useInterviewTemplateSearch({ pageSize: 100 });

  const interviewTemplatesSelected = useAppSelector(selectInterviewTemplatesSelected) || [];

  const uniqInterviewTemplatesOptions = uniqBy(
    [...interviewTemplatesSelected, ...interviewTemplatesOptions],
    'interviewTemplateId',
  );

  const onInterviewTemplatesChange = useCallback<(s: string[]) => void>(
    (selected) => {
      const selectedInterviewTemplatesEntities = uniqInterviewTemplatesOptions.filter(({ interviewTemplateId }) =>
        selected?.includes(interviewTemplateId),
      );
      dispatch(jobFormActions.update({ interviewTemplates: selectedInterviewTemplatesEntities }));
      setValue('interviewTemplates', selected);
    },
    [dispatch, uniqInterviewTemplatesOptions, setValue],
  );

  return (
    <FormSelect
      className="col-md-6"
      label="Interview Template"
      name="interviewTemplates"
      placeholder=""
      onInputChange={onInputInterviewTemplatesChange}
      options={uniqInterviewTemplatesOptions}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.interviewTemplateId}
      errors={errors?.interviewTemplates}
      validated={validated}
      isMulti={true}
      onChange={onInterviewTemplatesChange}
      onMenuScrollToBottom={loadMoreInterviewTemplates}
      isLoading={isFetchingCurrentPage}
    />
  );
};
