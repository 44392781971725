import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { delay, put, race, take, takeEvery } from 'redux-saga/effects';

import { apiConfig } from 'config';

import { ActionEnum } from 'utils/actions';

import { loaderActions } from './index';

function checkType(regex: RegExp[]) {
  return function(action: any) {
    return regex.some(rx => rx.test(action.type));
  };
}

type ActionWithPreloader = PayloadAction<{ preloader?: boolean | string }>;

function getTaskId(action: ActionWithPreloader, actionStatus: ActionEnum[]) {
  const type = action.type;
  let id = type;
  if (typeof action?.payload?.preloader === 'string') {
    id = action?.payload?.preloader;
  } else {
    const currentActionStatus = actionStatus.find(status => type.lastIndexOf('_' + status) > -1);
    if (currentActionStatus) {
      const idx = type.lastIndexOf('_' + currentActionStatus);
      id = type.substring(0, idx);
    }
  }
  return id;
}

const checkFinally = checkType([/_FINALLY$/]);

const takeFinally = (id: string) => (action: AnyAction) =>
  checkFinally(action) && getTaskId(action as ActionWithPreloader, [ActionEnum.FINALLY]) === id;

function* workerStartLoader(action: PayloadAction<{ preloader?: boolean | string }>) {
  const id = getTaskId(action, [ActionEnum.LOAD, ActionEnum.REQUEST]);
  yield put(loaderActions.start(id));
  yield race({
    finally: take(takeFinally(id)),
    timeout: delay(apiConfig.apiTimeout),
  });
  yield put(loaderActions.stop(id));
}

export function* loaderSagas() {
  yield takeEvery(checkType([/_REQUEST$/, /_LOAD$/]), workerStartLoader);
}
