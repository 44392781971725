import { modalSlice } from './reducers';
import * as selectors from './selectors';

const { actions, reducer } = modalSlice;

export const modalsActions = actions;

export const modalsReducer = modalSlice.reducer;

export const modalsSelectors = selectors;

export default reducer;
