import { useCallback, useEffect, useState } from 'react';
import { addDays, format, getWeeksInMonth, isSameDay, isSameMonth, isWithinInterval, startOfMonth } from 'date-fns';

import { CalendarDay } from 'components/DateRangePicker/DateRangePickerProps';

export const useCalendar = (dual = false) => {
  const [month, setMonth] = useState<Date>(new Date());
  const [start, setStart] = useState<Date | null>(null);
  const [end, setEnd] = useState<Date | null>(null);
  const [calendar, setCalendar] = useState<Array<CalendarDay[]>>([]);

  const onHoverDay = (day?: CalendarDay['date']) => {
    const hoveredArray = calendar.map((w) =>
      w.map((e) => {
        e.isWithinInterval = !!day && !!start && e.date > start && e.date < day;
        e.isHovered = !!day && isSameDay(e.date, day);
        return e;
      }),
    );
    setCalendar(hoveredArray);
  };
  const createDay = useCallback(
    (week: number, weekDate: number): CalendarDay => {
      const monthStart = startOfMonth(month);
      const w = weekDate + 1;
      const prevMonth = monthStart.getDay() || 7;
      const add = w - prevMonth + week * 7;
      const date = addDays(monthStart, add);
      const sameMonth = isSameMonth(date, month);
      return {
        date,
        isEndDate: end && sameMonth ? isSameDay(date, end) : false,
        isHovered: false,
        isSelectedMonth: sameMonth,
        isStartDate: start && sameMonth ? isSameDay(date, start) : false,
        isWithinInterval:
          start && end && sameMonth
            ? isWithinInterval(date, {
                end,
                start,
              })
            : false,
        text: format(date, 'd'),
      };
    },
    [end, month, start],
  );
  useEffect(() => {
    setCalendar(
      Array.from(
        {
          length: dual
            ? 6
            : getWeeksInMonth(month, {
                weekStartsOn: 1,
              }),
        },
        (_, i) => Array.from({ length: 7 }, (__, z) => createDay(i, z)),
      ),
    );
  }, [createDay]); // eslint-disable-line react-hooks/exhaustive-deps
  return {
    calendar,
    month,
    onHoverDay,
    setEnd,
    setMonth,
    setStart,
  };
};
