import { ApplicantModalFormFormData } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/models';

import { Applicant } from 'store/entities/applicants/models';

export const createInitialState = () =>
  ({
    isEditMode: false as boolean,
    apiErrors: [] as string[],
    currentApplicantId: null as null | Applicant['applicantId'],
    currentApplicant: null as null | ApplicantModalFormFormData,
    formState: {} as Partial<ApplicantModalFormFormData>,
    state: 'idle' as 'idle' | 'processing',
    totalApplicantsCount: 0 as number,
    inListPosition: 0 as number,
    listId: null,
    numberOfComments: 0 as number,
  } as const);
