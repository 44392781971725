import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import JobListPlaceholder from 'containers/JobLists/JobListPlaceholder';
import { JobListPropsPure, JobListRenderHeaderProps } from 'containers/JobLists/JobListProps';
import {
  jobListActions,
  jobListEffects,
  jobListSelectors,
  JobListState,
  JobListUriParams,
} from 'containers/JobLists/store';
import { loaderSelectors } from 'containers/Loader/store';

import { ListTableViewHeader, ListTableViewItem, ListViewPagination } from 'components/ListView';
import { useTickColumn } from 'components/ListView/hooks/useTickColumn';
import { GetColumnProps } from 'components/ListView/ListViewProps';
import { Column } from 'components/ListViewNew/ListViewProps';
import { Spinner } from 'components/Spinner';
import { TableList } from 'components/TableList';
import { useTableListUpdatePageHandler } from 'components/TableList/hooks';
import { useTableFooterCounters } from 'components/ui/ExTable/ExTable.hook';
import { useLoaderSubscription } from 'modules/LoaderManager/react';
import { pipe } from 'utils/funcs';
import { getAnalyticsBySteps, getAnalyticsObject } from 'utils/hooks/job';
import { useNavigation } from 'utils/hooks/useNavigation';

import { ViewMode } from 'store/constants';
import { Columns, Job, SortByForJobList } from 'store/entities/jobs';
import { loadJobsPage } from 'store/entities/jobs/job.actions';
import { RootState } from 'store/rootReducer';

const getColumn =
  ({ col, updateSort, sortMode }: GetColumnProps) =>
  ({ label, order, style }: Column<Job, SortByForJobList>) =>
    (
      <ListTableViewHeader
        key={col}
        order={order}
        updateSort={updateSort}
        sortMode={sortMode}
        label={label}
        style={style}
      />
    );

const renderHeader = ({ updateSort, sortMode, columns }: JobListRenderHeaderProps) => {
  const cols = Object.keys(columns) as Array<Columns>;
  return <>{cols.map((col) => pipe(columns[col], getColumn({ col, sortMode, updateSort })))}</>;
};

type JobListProps = JobListPropsPure & PropsFromRedux;

const useJobListState = ({
  jobs,
  recentJobs,
  loadJobs,
  updatePage,
  updateFilters,
  id,
  sortMode,
  updateSortMode,
  isJobListLoading,
  list,
  columns,
  filters,
  selectable,
  multiselect,
  toggleItem,
  toggleItemRadio,
  className,
}: JobListProps) => {
  const { parseSearch } = useNavigation();
  const { status }: Partial<JobListUriParams> = parseSearch();
  const [cols, setCols] = React.useState(columns);
  const { isLoading } = useLoaderSubscription(loadJobsPage);

  const { tickColumn } = useTickColumn({
    id,
    list,
    multiselect,
    toggleItem,
    toggleItemRadio,
  });

  useEffect(() => {
    const { action, ...rest } = columns;
    if (selectable) {
      setCols({ ...tickColumn, ...rest });
    }
    // eslint-disable-next-line
  }, [list]);

  const updateSort = (sortModeForUpdate: JobListState['sortMode']) => {
    updateSortMode({ id, sortMode: sortModeForUpdate });
    loadJobs({ listId: id });
  };

  const updatePageHandler = useTableListUpdatePageHandler({
    updateTableView: updatePage,
    id,
    load: loadJobs,
    loadArgs: { cancel: list.viewMode !== ViewMode.grid },
  });

  useEffect(() => {
    if (id === 'main') {
      updateFilters({ filters: { status }, id });
    }
    // eslint-disable-next-line
  }, [status]);

  useEffect(() => {
    const updateAndFetch = () => {
      loadJobs({ listId: id });
    };
    updatePage({ id, pageNo: 0 });
    updateAndFetch();
  }, [filters, id, loadJobs, updatePage]);

  const jobsWithApplicantsCount = jobs.map((job) => {
    try {
      const analytics = getAnalyticsBySteps(job.jobApplicantsByStageType);
      const applicantsCount = getAnalyticsObject(analytics);

      return {
        ...job,
        applicantsCount,
      };
    } catch (error) {
      return { ...job };
    }
  });

  const jobList = jobsWithApplicantsCount.length ? jobsWithApplicantsCount : recentJobs;
  const dataLength = jobList.length;

  const { tableFooterCountersText } = useTableFooterCounters({
    pageNo: list.pageNo,
    pageCount: list.pageCount,
    totalItemsCount: list.totalItemsCount,
    pageSize: list.pageSize,
    section: 'Jobs',
  });

  return {
    sortMode,
    isJobListLoading,
    list,
    className,
    dataLength,
    isLoading,
    cols,
    updateSort,
    updatePageHandler,
    jobList,
    tableFooterCountersText,
  };
};

const JobListPure: React.FC<JobListProps> = (props) => {
  const {
    sortMode,
    isJobListLoading,
    list,
    className,
    dataLength,
    isLoading,
    cols,
    updateSort,
    updatePageHandler,
    jobList,
    tableFooterCountersText,
  } = useJobListState(props);

  if (isLoading) {
    return <Spinner />;
  }

  if (!list.fetched) {
    return <div />;
  }

  if (list.fetched && list.pageCount === 0) {
    return <JobListPlaceholder />;
  }

  return (
    <TableList.TableListWrapper isLoading={isJobListLoading} dataLength={dataLength}>
      <TableList
        className={className}
        header={renderHeader({ columns: cols, sortMode, updateSort })}
        pagination={
          <ListViewPagination
            updatePage={updatePageHandler}
            pageNo={list.pageNo}
            pageCount={list.pageCount}
            disabled={isJobListLoading}
          />
        }
        tableFooterText={tableFooterCountersText}
        pageCount={list.pageCount}
      >
        {jobList.map((job) => (
          <ListTableViewItem key={job.id} item={job} columns={cols} />
        ))}
      </TableList>
    </TableList.TableListWrapper>
  );
};

const mapDispatch = {
  loadJobs: loadJobsPage,
  toggleItem: jobListActions.toggleItemSelect,
  toggleItemRadio: jobListActions.toggleItemRadio,
  updateFilters: jobListActions.updateFilters,
  updatePage: jobListActions.updatePage,
  updateSortMode: jobListActions.updateSort,
};

const mapState = (state: RootState, own: JobListPropsPure) => ({
  filters: jobListSelectors.getFilters(state, own),
  isJobListLoading: loaderSelectors.isTaskActive(state, { taskId: jobListEffects.loadJobsTaskId }),
  jobs: jobListSelectors.getJobs(state, own),
  list: jobListSelectors.getListById(state, own),
  recentJobs: jobListSelectors.getRecentJobs(state, own),
  sortMode: jobListSelectors.getSortMode(state, own),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const JobList = connector(JobListPure);

export default styled(JobList)``;
