import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';

import { providesList } from 'api-client/axiosBaseQuery';
import { replaceParamsInUrl } from 'api-client/utils';
import * as fromInterviewQuestionDto from 'api-endpoints/interview-question/interview-question.dto';
import { InterviewQuestionEndpoints } from 'api-endpoints/interview-question/interview-question.endpoints';

import {
  ExInterview,
  ExInterviewQuestion,
  ExInterviewQuestionFile,
  ExInterviewQuestionFileResponse,
  ExQuestion,
  ExQuestionFileUpload,
} from 'model';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { alertsActions } from 'containers/AlertManager/store/reducers';
import { interviewTemplateEditFormSelectors } from 'containers/InterviewTemplateForms/InterviewTemplateEditForm/state/InterviewTemplateEditForm.selectors';

import { getSuccessToastForEntityAction } from 'utils/i18utils';

import { emptyApi } from 'store/entities/emptyApi';
import { extendedApiByInterview } from 'store/entities/interview/interview.api';
import { RootState } from 'store/rootReducer';

const transformQuestionFileFromApi = (questionFile: ExInterviewQuestionFileResponse): ExInterviewQuestionFile => {
  return {
    interviewQuestionFileId: questionFile.interviewQuestionFileId,
    filename: questionFile.interviewQuestionFileName,
    filetype: questionFile.interviewQuestionFileType,
  };
};

const transformQuestion = (
  question: fromInterviewQuestionDto.GetInterviewQuestionsFuncResponse[number],
): ExQuestion => {
  return {
    questionId: question.interviewQuestionId,
    questionText: question.questionText,
    questionType: question.questionType,
    answer: question.answer,
    choices: question.choices ?? [],
    interviewQuestionFiles: question.interviewQuestionFiles?.map(transformQuestionFileFromApi) ?? [],
    additionalInformation: question.additionalInformation,
    isMandatory: question.isMandatory,
  };
};

const interviewQuestionAdapter = createEntityAdapter<ExQuestion>({
  selectId: (item) => item.questionId,
});

export const extendedApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    interviewQuestionList: builder.query<EntityState<ExQuestion>, ExInterview['interviewId']>({
      query: (interviewId) => ({
        url: replaceParamsInUrl(InterviewQuestionEndpoints.GetInterviewQuestionsFunc, { interviewId }),
        method: 'GET',
      }),
      transformResponse: (data: fromInterviewQuestionDto.GetInterviewQuestionsFuncResponse) => {
        const transformedData = data.map(transformQuestion);
        return interviewQuestionAdapter.addMany(interviewQuestionAdapter.getInitialState(), transformedData as any);
      },
    }),
    interviewQuestion: builder.query<ExInterviewQuestion, { interviewQuestionId: string; interviewId: string }>({
      query: ({ interviewQuestionId, interviewId }) => ({
        url: replaceParamsInUrl(InterviewQuestionEndpoints.GetInterviewQuestionFunc, {
          interviewId,
          interviewQuestionId,
        }),
        method: 'GET',
      }),
    }),
    updateInterviewQuestion: builder.mutation<
      fromInterviewQuestionDto.UpdateInterviewQuestionFuncResponse,
      fromInterviewQuestionDto.UpdateInterviewQuestionFuncRequestParams
    >({
      query: (args) => ({
        url: replaceParamsInUrl(InterviewQuestionEndpoints.UpdateInterviewQuestionFunc, {
          interviewId: args.interviewId,
          interviewQuestionId: args.interviewQuestionId,
        }),
        method: 'PUT',
        data: { ...args },
      }),
      onQueryStarted: async ({ interviewQuestionFiles, ...args }, { dispatch, queryFulfilled, getState }) => {
        const toCacheFromForm = interviewTemplateEditFormSelectors.questionsFormSelectorsFromAdapter.selectById(
          getState() as RootState,
          args.interviewId,
        );
        const patches = dispatch(
          extendedApi.util.updateQueryData(
            'interviewQuestion',
            { interviewId: args.interviewId, interviewQuestionId: args.interviewQuestionId },
            (draft) => Object.assign(draft, toCacheFromForm),
          ),
        );
        const patchesForList = dispatch(
          extendedApi.util.updateQueryData('interviewQuestionList', args.interviewId, (draft) => {
            if (toCacheFromForm) {
              interviewQuestionAdapter.upsertOne(draft, toCacheFromForm);
            }
          }),
        );
        try {
          await queryFulfilled;
          dispatch(alertsActions.deleteAll());

          dispatch(
            extendedApiByInterview.util.updateQueryData('interview', args.interviewId, (draft) =>
              Object.assign(draft, { updatedOn: new Date().toISOString() }),
            ),
          );
        } catch (e) {
          patches.undo();
          patchesForList.undo();
        }
      },
    }),
    questionFileUpload: builder.mutation<void, ExQuestionFileUpload>({
      query: (arg) => ({
        method: 'POST',
        url: replaceParamsInUrl(InterviewQuestionEndpoints.UploadInterviewQuestionFileFunc, {
          interviewId: arg.interviewId,
          interviewQuestionId: arg.interviewQuestionId,
        }),
        data: arg.data,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          const message = getSuccessToastForEntityAction({
            name: arg.questionText || '',
            action: 'updated',
            entityName: 'interviewQuestion',
          });
          dispatch(alertsEffects.showSuccess({ message }));
        } catch (error) {}
      },
    }),
    deleteQuestionFile: builder.mutation<
      fromInterviewQuestionDto.DeleteInterviewQuestionFileFuncResponse,
      fromInterviewQuestionDto.DeleteInterviewQuestionFileFuncRequestParams & Pick<ExQuestion, 'questionText'>
    >({
      queryFn: async (arg, { dispatch }, __, baseQuery) => {
        const { error }: any = await baseQuery({
          method: 'DELETE',
          url: replaceParamsInUrl(InterviewQuestionEndpoints.DeleteInterviewQuestionFileFunc, {
            interviewId: arg.interviewId,
            interviewQuestionId: arg.interviewQuestionId,
            interviewQuestionFileId: arg.interviewQuestionFileId,
          }),
        });

        if (error) {
          return { ...error };
        }

        const message = getSuccessToastForEntityAction({
          name: arg.questionText,
          action: 'deleted',
          entityName: 'interviewQuestion',
        });
        dispatch(alertsEffects.showSuccess({ message }));

        return {
          data: undefined,
        };
      },
    }),
  }),
});

const enhancedApi = extendedApi.enhanceEndpoints({
  addTagTypes: ['InterviewQuestion'],
  endpoints: {
    interviewQuestionList: {
      providesTags: (result) => providesList(result?.ids, 'InterviewQuestion'),
    },
    interviewQuestion: {
      providesTags: (_, __, { interviewQuestionId }) => [{ type: 'InterviewQuestion', id: interviewQuestionId }],
    },
    updateInterviewQuestion: {
      invalidatesTags: (_, __, { interviewQuestionId }) => [{ type: 'InterviewQuestion', id: interviewQuestionId }],
    },
    questionFileUpload: {
      invalidatesTags: (_, __, { interviewQuestionId }) => [{ type: 'InterviewQuestion', id: interviewQuestionId }],
    },
    deleteQuestionFile: {
      invalidatesTags: (_, __, { interviewQuestionId }) => [{ type: 'InterviewQuestion', id: interviewQuestionId }],
    },
  },
});

export const {
  useInterviewQuestionListQuery,
  useUpdateInterviewQuestionMutation,
  useQuestionFileUploadMutation,
  useDeleteQuestionFileMutation,
} = enhancedApi;
