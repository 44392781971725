import React from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

import Calendar from 'assets/img/calendar.svg';

type Props = {
  className?: string;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  value: string;
  onClear?: () => void;
  clearable?: boolean;
  isDisabled?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
  placeholder?: string;
};

type Ref = HTMLDivElement;

const CloseButtonWrapper = styled.div`
  position: relative;
`;

const CustomCloseButton = styled.button`
  position: absolute;
  top: 47%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 998;
  padding: 5px;
  border: none;
  background: none;
  font-size: 1.4rem;

  &:active,
  &:focus {
    outline: none;
  }
`;

const Placeholder = styled.span`
  color: var(--gray);
`;

const DateRangePickerInput = React.forwardRef<Ref, Props>(
  ({ className, onClick, value, onClear, clearable, isDisabled, isInvalid, placeholder }, ref) => {
    const handleOnClose = (e: React.MouseEvent<HTMLButtonElement, Event>) => {
      e.preventDefault();
      e.stopPropagation();
      onClear && onClear();
    };

    return (
      <div ref={ref} onClick={onClick} className={className}>
        <CloseButtonWrapper>
          <Form.Control readOnly={isDisabled} isInvalid={!isDisabled && isInvalid} as="div">
            {value ? value : <Placeholder>{placeholder}</Placeholder>}
          </Form.Control>
          {clearable && onClear && (
            <CustomCloseButton type="button" className="close" aria-label="Close" onClick={handleOnClose}>
              <span aria-hidden="true">&times;</span>
            </CustomCloseButton>
          )}
        </CloseButtonWrapper>
      </div>
    );
  },
);

export default styled(DateRangePickerInput)`
  position: relative;
  cursor: ${(props) => !props.isDisabled && 'pointer'};

  .form-control {
    padding-left: 42px;
    border-radius: 99px;

    &::before {
      color: transparent;
      background: none;
      display: block;
      content: url('${Calendar}');
      width: 18px;
      height: 20px;
      position: absolute;
      top: 9px;
      left: 14px;
    }
  }
`;
