import React from 'react';
import { DropzoneState } from 'react-dropzone';
import { components, OptionProps } from 'react-select';
import styled from 'styled-components';

import { ReactComponent as UploadResumeIcon } from 'assets/img/upload-resume-icon.svg';

import { AppDropZone, AppDropZonePlaceholder } from 'components/AppImagePickerDialog';
import { Spacer } from 'components/Spacer';
import { ExButton } from 'components/ui/ExButton';
import { ExDialogActionButton, ExDialogActions, ExDialogFooter } from 'components/ui/ExDialog';

import { Job } from 'store/entities/jobs';

import { BulkItemFile } from './BulkItemFile';

const AppDropZonePlaceholderResumeStyled = styled(AppDropZonePlaceholder)`
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor};
  border-color: ${(props) => props.theme.border.borderColor};
  min-height: 200px;
`;

const UploadResumeIconStyled = styled(UploadResumeIcon)`
  width: 40px;
  height: 40px;
  fill: none;
`;

const SelectedFilesWrapper = styled.div`
  margin-bottom: 16px;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: none;
`;

export const AppDropZoneResumeStyled = styled(AppDropZone)`
  width: auto;
`;

export const AppDropZonePlaceholderResume: React.FC<{ open: Function }> = ({ open }) => (
  <AppDropZonePlaceholderResumeStyled onClick={() => open()}>
    <Spacer grow={5} />
    <UploadResumeIconStyled />
    <Spacer grow={2} />
    <span>Drag and drop file here </span>
    <Spacer />
    <span>or</span>
    <Spacer />
    <ExButton variant="light">Browse File</ExButton>
    <Spacer grow={5} />
  </AppDropZonePlaceholderResumeStyled>
);

export const BulkCreateSelectedFiles: React.FC<{ files: File[]; onRemoveFile: Function; open: Function }> = ({
  files,
  onRemoveFile,
  open,
}) => (
  <>
    <SelectedFilesWrapper>
      {files.map((file, index) => (
        <BulkItemFile key={index} fileName={file.name} fileId={`${index}`} onRemove={() => onRemoveFile(index)} />
      ))}
    </SelectedFilesWrapper>
    <ExButton onClick={() => open()} variant="light">
      Browse File
    </ExButton>
  </>
);

export const BulkCreateSelectOptionWithReference: React.FC<OptionProps<Job, false>> = (props) => {
  const item: Job = props.data;
  return (
    <components.Option {...props}>
      <div>
        <div>{item.jobName}</div>
        <div>
          <b>ReferenceNo: </b>
          {item.referenceNo}
        </div>
      </div>
    </components.Option>
  );
};

export const BulkCreateModalFooter = ({
  onClose,
  isImageSelected,
  isShowSelectedFiles,
  open,
  onContinue,
  files,
  isDragAccept,
  isDragActive,
  isDragReject,
  getInputProps,
  getRootProps,
  onRemoveFile,
}: {
  onClose: React.MouseEventHandler;
  onContinue: React.MouseEventHandler;
  files: File[];
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
  isImageSelected: boolean;
  isShowSelectedFiles: boolean;
  getRootProps: DropzoneState['getRootProps'];
  getInputProps: DropzoneState['getInputProps'];
  open: () => void;
  onRemoveFile: (fileIndex: number) => void;
}) => {
  return (
    <>
      <AppDropZoneResumeStyled
        {...getRootProps()}
        isImageSelected={isImageSelected}
        isDragActive={isDragActive}
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
      >
        {isShowSelectedFiles ? (
          <BulkCreateSelectedFiles files={files} onRemoveFile={onRemoveFile} open={open} />
        ) : (
          <AppDropZonePlaceholderResume open={open} />
        )}
      </AppDropZoneResumeStyled>
      <input {...getInputProps()} />
      <ExDialogFooter>
        <ExDialogActions>
          <ExDialogActionButton onClick={onClose} variant="light">
            Cancel
          </ExDialogActionButton>
          <ExDialogActionButton onClick={onContinue}>Continue</ExDialogActionButton>
        </ExDialogActions>
      </ExDialogFooter>
    </>
  );
};
