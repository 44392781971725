import React from 'react';

import { useScreeningQuestionViewComponent } from './hooks/useScreeningQuestionViewComponent';
import { ScreeningQuestionViewProps } from './ScreeningQuestionViewProps';

export const ScreeningQuestionView: React.FC<ScreeningQuestionViewProps> = (props) => {
  const type = props.question.questionType;
  const context = props?.context;

  const ScreeningQuestionViewComponent = useScreeningQuestionViewComponent(type, context);

  if (!ScreeningQuestionViewComponent) {
    return null;
  }

  return <ScreeningQuestionViewComponent {...props} />;
};
