import React, { useCallback } from 'react';
import styled from 'styled-components/macro';

import { ExScreeningQuestion } from 'model';

import { CompanyScreeningQuestionHeader } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/CompanyScreeningQuestionsComponents';
import { CompanyScreeningQuestionsList } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/CompanyScreeningQuestionsList';
import {
  ScrollWrapper,
  ScrollWrapperInner,
} from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/CompanyScreeningQuestionsStyles';
import { ScreeningQuestionFormSelect } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionFormSelect';
import { ScreeningQuestionsTheme } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionsTheme';

import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import { ExModalBodyStyled, ExModalWrapperStyled } from 'containers/Modals/ModalsContent/Company/ExModalComponents';
import { modalsSelectors } from 'containers/Modals/store';

import { DialogProps } from 'components/Dialog';
import { SpinnerSM } from 'components/Spinner';
import { ExVisible } from 'components/ui/ExVisible';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { useAddJobScreeningQuestionMutation } from 'store/entities/screening-question/screening-question.api';

const JobScreeningQuestionsModalStyled = styled(ExModalWrapperStyled)`
  max-width: 750px;
`;

const JobScreeningQuestionsModalForm = styled.div`
  display: flex;
  align-items: center;
`;

type JobScreeningQuestionsModalProps = {
  className?: string;
} & AppModalContentProps;

const useJobScreeningQuestionsModalState = ({ className, onClose }: JobScreeningQuestionsModalProps) => {
  const jobId = useAppSelector(modalsSelectors.modalJobId)!;
  const [addJobScreeningQuestionMutation, { isLoading }] = useAddJobScreeningQuestionMutation();

  const content: DialogProps['content'] = {
    title: 'Update Screening Questions',
    withTitle: true,
  };

  const onChange = useCallback(
    (selectedQuestion: ExScreeningQuestion) => {
      addJobScreeningQuestionMutation({
        jobId,
        data: {
          questions: [selectedQuestion],
        },
      });
    },
    [addJobScreeningQuestionMutation, jobId],
  );

  const customClassName = className ? `${className} disable-animation` : 'disable-animation';

  return {
    className: customClassName,
    onClose,
    content,
    onChange,
    isLoading,
  } as const;
};

const theme = {
  gridSize: {
    column1: '25px',
    column2: 'auto',
    column3: '150px',
    column4: '140px',
    column5: '70px',
  },
};

export const JobScreeningQuestionsModal: React.FC<JobScreeningQuestionsModalProps> = (props) => {
  const { className, onClose, content, onChange, isLoading } = useJobScreeningQuestionsModalState(props);

  return (
    <JobScreeningQuestionsModalStyled className={className} onClose={onClose} content={content}>
      <ExModalBodyStyled>
        <JobScreeningQuestionsModalForm>
          <ScreeningQuestionFormSelect onChange={onChange} label="Add Screening Question" />
          <ExVisible visible={isLoading}>
            <SpinnerSM />
          </ExVisible>
        </JobScreeningQuestionsModalForm>
        <ScrollWrapper>
          <ScrollWrapperInner>
            <ScreeningQuestionsTheme theme={theme}>
              <CompanyScreeningQuestionHeader />
              <CompanyScreeningQuestionsList context={'jobScreeningQuestions'} />
            </ScreeningQuestionsTheme>
          </ScrollWrapperInner>
        </ScrollWrapper>
      </ExModalBodyStyled>
    </JobScreeningQuestionsModalStyled>
  );
};
