import { createSlice } from '@reduxjs/toolkit';

import { SearchAutocomplete } from 'containers/CandidateForm/CandidateFormFormBody/CreateNewCandidateFormBody';
import {
  CandidateForm,
  CandidateFormState,
  FormCandidateCaseReducer,
} from 'containers/CandidateForm/CandidateFormState/candidate-form.models';

import { DocumentFile } from 'store/app-files/documents/documents.models';

export const formCandidateKey = 'formCandidate';

export const formCandidateDocsKey = 'formCandidateDocs';

export const formCandidateInitialState: CandidateFormState = {
  validSteps: [],
  showSteps: true,
  formData: {},
  selectedCandidateId: null,
  selectedDocuments: [],
  selectedResumes: [],
  apiErrors: [],
};

const resetState: FormCandidateCaseReducer<any> = (state) => ({
  ...state,
  ...formCandidateInitialState,
});

const addUploadedDocument: FormCandidateCaseReducer<DocumentFile> = (state, { payload }) => {
  state.selectedDocuments.push(payload);
};

const saveForm: FormCandidateCaseReducer<{
  formData: Partial<CandidateForm & { talentPools: Array<SearchAutocomplete> }>;
}> = (state, { payload }) => {
  state.formData = {
    ...state.formData,
    ...payload.formData,
  };
};

const setApiErrors: FormCandidateCaseReducer<{ apiErrors: string[] }> = (state, { payload }) => {
  state.apiErrors = payload.apiErrors;
};

const setState: FormCandidateCaseReducer<Partial<CandidateFormState>> = (state, { payload }) => ({
  ...state,
  ...payload,
});

const toggleShowSteps: FormCandidateCaseReducer<{ showSteps: boolean }> = (state, { payload }) => ({
  ...state,
  ...payload,
});

type FormCandidateCaseReducers = {
  resetState: typeof resetState;
  toggleShowSteps: typeof toggleShowSteps;
  saveForm: typeof saveForm;
  setState: typeof setState;
  addUploadedDocument: typeof addUploadedDocument;
  setApiErrors: typeof setApiErrors;
};

const candidateFormSlice = createSlice<CandidateFormState, FormCandidateCaseReducers>({
  name: formCandidateKey,
  initialState: formCandidateInitialState,
  reducers: {
    resetState,
    toggleShowSteps,
    saveForm,
    setState,
    addUploadedDocument,
    setApiErrors,
  },
});

export const candidateFormActions = candidateFormSlice.actions;

export const candidateFormReducer = candidateFormSlice.reducer;

export default candidateFormSlice;
