import { replaceParamsInUrl } from 'api-client/utils';
import { ApplicantEndpointsForApi } from 'api-endpoints/applicant/applicant.endpoints';
import * as fromApiEndpointsApplicantModel from 'api-endpoints/applicant/models';

import { emptyApi } from 'store/entities/emptyApi';

const extendedApplicantApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getApplicant: builder.query<
      fromApiEndpointsApplicantModel.GetJobApplicantFuncResponse,
      fromApiEndpointsApplicantModel.GetJobApplicantFuncRequestParams
    >({
      query: (args) => ({ method: 'GET', url: replaceParamsInUrl(ApplicantEndpointsForApi.GetJobApplicantFunc, args) }),
    }),
  }),
});

export const applicantApi = extendedApplicantApi.enhanceEndpoints({
  addTagTypes: ['Applicant'],
  endpoints: {
    getApplicant: {
      providesTags: (data) => (data?.applicantId ? [{ type: 'Applicant' as const, id: data.applicantId }] : []),
    },
  },
});
