import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { CardDataLineCss, CardNameCss } from 'components/ui/ExCard/components';

export const CandidateCardName = styled(Link)`
  ${CardNameCss};
  padding-right: 1px;
  width: 100%;
`;

export const CandidateCardAvatar = styled.div`
  margin-right: 14px;
  flex: 0 0 52px;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 100%;
  justify-content: center;

  img {
    width: 100%;
    max-width: 100%;
  }
`;

export const CandidateInnerCard = styled.div`
  display: flex;
  flex-direction: row;

  .candidate-card__info {
    p {
      font-weight: 700;
      margin-bottom: 0;
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .candidate-card__data-line {
    ${CardDataLineCss};
    color: ${(props) => props.theme.colors.blackHeadings};
    padding-left: 4px;

    dt {
      margin-right: 4px;
    }

    dd {
      flex: 1 0 100%;
      margin-bottom: 0;
    }
  }
`;
