export const JOB_GET = 'job/get';
export const JOB_FETCH = 'job/fetch';
export const JOB_STATUS_UPDATE = 'job/status/update';
export const JOB_ACTIVE_SUMMARY_FETCH = 'job/activeSummary/fetch';
export const JOB_ACTIVITY_ANALYTIC_FETCH = 'job/activityAnalytic/fetch';
export const JOB_CANDIDATE_SOURCE_FETCH = 'job/candidateSource/fetch';
export const JOB_CREATE = 'job/create';
export const JOB_UPDATE = 'job/update';
export const JOB_SALARY_UPDATE = 'job/salary/update';

export const JOB_GET_APPLICANTS_PER_STAGE_FOR_JOB_BOARD = 'job/getApplicantsPerStageForJobBoard/get';
export const JOB_GET_APPLICANTS_PER_STAGE_FOR_JOB_BOARD_CHART_READY = 'job/getApplicantsPerStageForJobBoardChartReady';
export const JOB_SOURCE_PER_DAY_FETCH = 'job/sourcePerDay/fetch';
export const JOB_DEADLINE_FETCH = 'job/deadline/fetch';
export const JOB_DAYS_OPEN_FETCH = 'job/daysOpen/fetch';
export const JOB_ACCEPTANCE_RATE_FETCH = 'job/acceptanceRate/fetch';
export const JOB_AVERAGE_TIME_TO_HIRE_FETCH = 'job/averageTimeToHire/fetch';

export const JOB_SALARY_EDIT = 'job/salary/edit';
export const JOB_DETAILS_EDIT = 'job/details/edit';
export const JOB_DESCRIPTION_EDIT = 'job/description/edit';
export const JOB_RESPONSIBILITIES_EDIT = 'job/responsibilities/edit';
export const JOB_REQUIREMENTS_EDIT = 'job/requirements/edit';

export const JOB_TEAM_FETCH = ' job/team/fetch';
export const JOB_TEAM_ADD_MEMBER = ' job/team/addMember';
export const JOB_TEAM_REMOVE_MEMBER = ' job/team/removeMember';
export const JOB_TEAM_EDIT = 'job/team/edit';

export const JOB_TEAM_MEMBER_PREPARE_CREATE = 'job/team/member/prepareCreate';
export const JOB_TEAM_MEMBER_CREATE = 'job/team/member/create';
export const JOB_TEAM_MEMBER_PREPARE_REMOVE = 'job/team/member/prepareRemove';
export const JOB_TEAM_MEMBER_REMOVE = 'job/team/member/remove';

export const loadJobWorkflowTaskId = 'loadJobWorkflowTaskId';
