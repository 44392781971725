import React, { HtmlHTMLAttributes } from 'react';
import styled from 'styled-components';

import { FormChildrenInputProps } from 'components/Form/FormProps';

type InputHiddenProps = Omit<HtmlHTMLAttributes<HTMLInputElement>, 'hidden'> &
  FormChildrenInputProps & {
    children?: never;
    type?: string;
  };

const InputHiddenStyled = styled.input.attrs({ hidden: true })``;

const useInputHiddenState = ({ name, control, errors, inputRef, setValue, validated, ...rest }: InputHiddenProps) => {
  return {
    name,
    inputRef,
    rest,
  } as const;
};

export const InputHidden: React.FC<InputHiddenProps> = (props) => {
  const { name, inputRef, rest } = useInputHiddenState(props);

  return <InputHiddenStyled name={name} ref={inputRef} {...rest} />;
};
