import { generatePath } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { default as H } from 'history';
import qs from 'qs';
import get from 'lodash/get';
import merge from 'lodash/merge';
import set from 'lodash/set';

interface PushParams {
  persistSearch?: boolean;
  // search keys to persist
  searchKeys?: Array<string>;
  search?: SearchParams;
  params?: { [paramName: string]: string | number | boolean | undefined };
}

interface ParseSearchParams {
  ignoreQueryPrefix: boolean;
}

type SearchParams = Record<string, any>;

export const useNavigation = () => {
  const location = useLocation();
  const history = useHistory();
  const parseSearch = (config: ParseSearchParams = { ignoreQueryPrefix: true }) => {
    return qs.parse(location.search, config);
  };
  const generateSearchParams = (path: H.Path, config?: PushParams) => {
    const search = {};
    const locationSearchParams = parseSearch();
    if (config?.persistSearch) {
      merge(search, locationSearchParams);
    }
    if (config?.searchKeys) {
      config.searchKeys.forEach((key) => {
        const searchValue = get(locationSearchParams, key);
        merge(search, { ...(searchValue && { [key]: get(locationSearchParams, key) }) });
      });
    }
    if (config?.search) {
      merge(search, locationSearchParams, config.search);
    }
    return qs.stringify(search, { addQueryPrefix: true });
  };
  const upsertSearch = (search: SearchParams, locationSearchParams: SearchParams) => {
    Object.entries(search).forEach(([key, value]) => set(locationSearchParams, key, value));
    return locationSearchParams;
  };
  const generatePathWithSearch = (pattern: string, config?: PushParams) => {
    const pathString = generatePath(pattern, config?.params || {});
    const search = generateSearchParams(pathString, config);
    return `${pathString}${search}`;
  };
  const getSearchParamValue = (key: string) => parseSearch()?.[key];
  return {
    parseSearch,
    upsertSearch,
    getSearchParamValue,
    generatePath: generatePathWithSearch,
    push({ path = location.pathname, state, config }: { path?: H.Path; state?: H.LocationState; config?: PushParams }) {
      const pathWithSearch = generatePathWithSearch(path, config);
      history.push(pathWithSearch, state);
    },
  };
};
