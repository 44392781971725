import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';

import { jobInterviewTabListUiSlice } from './job-interview-tab-list.reducer';

const domain = (state: RootState) => state.ui.jobsUi[jobInterviewTabListUiSlice.name];

const filters = createSelector(domain, (state) => state.filters);

export const jobInterviewTabListSelectors = {
  filters,
};
