import { useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppRouterParams } from 'router/useAppRouterParams';
import styled from 'styled-components/macro';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { IconCheck } from 'components/Icons/IconCheck';

import { useInterviewQuery } from 'store/entities/interview';

const InterviewUpdatedStyled = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

const InterviewUpdatedIcon = styled.div`
  margin-right: 7px;
`;

const InterviewUpdatedTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
`;

const InterviewUpdatedDesc = styled.div`
  font-size: 8px;
  line-height: 11px;
`;

export const InterviewUpdated = () => {
  const params = useAppRouterParams();
  const { interviewId } = params;

  const [show, setShow] = useState(false);

  const { data: interview } = useInterviewQuery(interviewId ?? skipToken);
  const { updatedOn } = { ...interview };

  const TIMEOUT = 3000;

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined;

    if (updatedOn) {
      setShow(true);
      timer = setTimeout(() => {
        setShow(false);
      }, TIMEOUT);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [updatedOn]);

  const formatedDate = updatedOn ? format(parseISO(updatedOn), "dd/MM/yyyy 'at' hh.mmaaa") : null;

  if (!show || !updatedOn) {
    return null;
  }

  return (
    <InterviewUpdatedStyled>
      <InterviewUpdatedIcon>
        <IconCheck />
      </InterviewUpdatedIcon>
      <div>
        <InterviewUpdatedTitle>Updated</InterviewUpdatedTitle>
        <InterviewUpdatedDesc>Last save on {formatedDate}</InterviewUpdatedDesc>
      </div>
    </InterviewUpdatedStyled>
  );
};
