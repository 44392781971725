import styled, { css, DefaultTheme } from 'styled-components';

import { ExBadgeProps, ExBadgeVariant } from 'components/ui/ExBadge/ExBadgeProps';
import { pickAndRename } from 'utils/funcs';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

export const ExBadgeStyled = styled.span<ExBadgeProps>`
  ${getBadgeColors};
  padding: 0.4em 0.8em;
  border-radius: 2em;
  text-transform: uppercase;
  font-size: ${getThemeFontSize('extraSmall')};
  text-align: center;
  font-weight: bold;
  line-height: 1;
  display: inline-block;
`;

export const ExBadgeEllipsisStyled = styled(ExBadgeStyled)`
  display: inline-block;
`;

const badgeColorsMap: Record<ExBadgeVariant, (colors: DefaultTheme['colors']) => { bgColor: string; color: string }> = {
  [ExBadgeVariant.new]: pickAndRename([
    ['primary', 'bgColor'],
    ['white', 'color'],
  ]),
  [ExBadgeVariant.primary]: pickAndRename([
    ['primary', 'bgColor'],
    ['white', 'color'],
  ]),
  [ExBadgeVariant.success]: pickAndRename([
    ['success', 'bgColor'],
    ['white', 'color'],
  ]),
  [ExBadgeVariant.danger]: pickAndRename([
    ['danger', 'bgColor'],
    ['white', 'color'],
  ]),
  [ExBadgeVariant.warning]: pickAndRename([
    ['warning', 'bgColor'],
    ['white', 'color'],
  ]),
  [ExBadgeVariant.tabActive]: pickAndRename([
    ['warning', 'bgColor'],
    ['white', 'color'],
  ]),
  [ExBadgeVariant.info]: pickAndRename([
    ['info', 'bgColor'],
    ['white', 'color'],
  ]),
  [ExBadgeVariant.shortlisted]: pickAndRename([
    ['shortlisted', 'bgColor'],
    ['white', 'color'],
  ]),
  [ExBadgeVariant.interviewed]: pickAndRename([
    ['interviewed', 'bgColor'],
    ['white', 'color'],
  ]),
  [ExBadgeVariant.screened]: pickAndRename([
    ['screened', 'bgColor'],
    ['white', 'color'],
  ]),
  [ExBadgeVariant.negotiated]: pickAndRename([
    ['negotiated', 'bgColor'],
    ['white', 'color'],
  ]),
  [ExBadgeVariant.readyToOnboard]: pickAndRename([
    ['readyToOnboard', 'bgColor'],
    ['white', 'color'],
  ]),
  [ExBadgeVariant.inOnboarding]: pickAndRename([
    ['inOnboarding', 'bgColor'],
    ['white', 'color'],
  ]),
  [ExBadgeVariant.hired]: pickAndRename([
    ['hired', 'bgColor'],
    ['white', 'color'],
  ]),
  [ExBadgeVariant.declined]: pickAndRename([
    ['declined', 'bgColor'],
    ['white', 'color'],
  ]),
  [ExBadgeVariant.withdrawn]: pickAndRename([
    ['withdrawn', 'bgColor'],
    ['white', 'color'],
  ]),
  [ExBadgeVariant.disabled]: pickAndRename([
    ['default', 'bgColor'],
    ['grey', 'color'],
  ]),
  [ExBadgeVariant.tabNotActive]: pickAndRename([
    ['neutral', 'bgColor'],
    ['dark', 'color'],
  ]),
  [ExBadgeVariant.tabNotActive]: pickAndRename([
    ['neutral', 'bgColor'],
    ['dark', 'color'],
  ]),
  [ExBadgeVariant.default]: pickAndRename([
    ['defaultLight', 'bgColor'],
    ['blackHeadings', 'color'],
  ]),
  [ExBadgeVariant.inactive]: pickAndRename([
    ['inactive', 'bgColor'],
    ['white', 'color'],
  ]),
  [ExBadgeVariant.light]: pickAndRename([
    ['badgeLightBg', 'bgColor'],
    ['badgeLightColor', 'color'],
  ]),
};

function getBadgeColors({ variant, theme }: ExBadgeProps & { theme: DefaultTheme }) {
  const getBadgeColorFunction = variant in badgeColorsMap ? badgeColorsMap[variant] : badgeColorsMap.default;
  const cssArguments = getBadgeColorFunction(theme.colors);

  return getCss(cssArguments);
}

function getCss({ bgColor, color }: Record<'color' | 'bgColor', string>) {
  return css`
    background-color: ${bgColor};
    color: ${color};
  `;
}
