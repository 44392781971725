import React from 'react';
import { Redirect } from 'react-router-dom';
import { ExRoutes } from 'router/routes';

import { ExCompanyBgImage } from 'components/ui/ExCompanyBgImage';

import { authSelectors } from 'store/auth/auth.selectors';
import { useSelector } from 'store/rootSelectors';

import { SelectCompanyPageCompaniesLIst } from './components/SelectCompanyPageCompaniesLIst';
import { ExpedoLogoImage, SelectCompanyPageStyled } from './components/SelectCompanyPageComponentsStyled';

type SelectCompanyPageProps = {};

const useSelectCompanyPageState = () => {
  const currentCompanyId = useSelector(authSelectors.selectCurrentCompanyId);

  return {
    currentCompanyId,
  } as const;
};

export const SelectCompanyPage: React.FC<SelectCompanyPageProps> = () => {
  const { currentCompanyId } = useSelectCompanyPageState();

  if (currentCompanyId) {
    return <Redirect to={{ pathname: ExRoutes.dashboard() }} />;
  }

  return (
    <SelectCompanyPageStyled>
      <ExpedoLogoImage />
      <SelectCompanyPageCompaniesLIst />
      <ExCompanyBgImage />
    </SelectCompanyPageStyled>
  );
};
