import { curry } from 'utils/funcs';

export type ReverseMap<K, T> = {
  [S in keyof K]: keyof T;
};

export type KeyMap<TT, KK, T = Partial<TT>, K = Partial<KK>> = {
  [S in keyof T]: keyof KeyMap<K, T>;
};

export class TwoWayMap<T, K> {
  map: KeyMap<T, K>;
  reverseMap: KeyMap<K, T>;

  constructor(map: KeyMap<T, K>) {
    this.map = map;
    const reverseMap = {} as KeyMap<K, T>;
    for (const key in map) {
      const value = map[key];
      reverseMap[value] = key;
    }
    this.reverseMap = reverseMap;
  }

  get(key: keyof KeyMap<T, K>): keyof K {
    return this.map[key];
  }

  revGet(key: keyof KeyMap<K, T>): keyof T {
    return this.reverseMap[key];
  }
}

const toStateFunction = <R, T>(mapper: TwoWayMap<R, T>, response: R): T => {
  return Object.entries(response).reduce(
    (entity, [key, value]) => ({
      ...entity,
      ...(mapper.get(key as keyof R) && {
        [mapper.get(key as keyof R)]: value,
      }),
    }),
    {} as T,
  );
};

export const toStateKeys = curry(toStateFunction);

const toApiEntityFunction = <R, T>(mapper: TwoWayMap<R, T>, storeEntity: T): R => {
  return Object.entries(storeEntity).reduce(
    (state, [key, value]) => ({
      ...state,
      [mapper.revGet(key as keyof T)]: value,
    }),
    {} as R,
  );
};

export const toEntityKeys = curry(toApiEntityFunction);
