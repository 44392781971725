import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';

import { applicantInterviewTabListUiSlice } from './applicant-interview-tab-list.reducer';

const domain = (state: RootState) => state.ui.applicantsUi[applicantInterviewTabListUiSlice.name];

const filters = createSelector(domain, (state) => state.filters);

export const applicantInterviewTabListSelectors = {
  filters,
};
