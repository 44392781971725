import { PayloadAction } from '@reduxjs/toolkit';
import { Task } from 'redux-saga';
import { call, cancel, fork, put, select, take, takeLatest } from 'redux-saga/effects';

import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import * as fromCandidateActions from 'store/entities/candidates/candidate.actions';
import { modalsSlice } from 'store/modals';
import * as fromModalActions from 'store/modals/modals.actions';
import * as fromModalConstants from 'store/modals/modals.constants';
import { ExModal } from 'store/modals/modals.interfaces';
import { modalSagaWorker } from 'store/modals/modals.sagas';
import { modalById } from 'store/modals/modals.selectors';

function* onStepChangeBackgroundActions() {
  let task: Task | undefined;
  while (true) {
    yield take(fromModalConstants.MODAL_PAGE_UPDATE);

    // Cancel ongoing process if exists
    if (task && task.isRunning()) {
      yield cancel(task);
    }
  }
}

function* handleTransition() {
  while (true) {
    const { payload, type }: PayloadAction<fromModalActions.WizardNavigation> = yield take([
      fromModalConstants.MODAL_WIZARD_FORWARD,
      fromModalConstants.MODAL_WIZARD_BACKWARD,
      fromModalConstants.MODAL_WIZARD_ERROR,
      fromModalConstants.MODAL_HIDE,
      fromModalConstants.MODAL_CANCEL,
    ]);

    const id = payload.id;

    if (!id) {
      break;
    }

    const { modalConfig }: ExModal = yield select(modalById, id);
    const { page } = modalConfig || {};

    switch (page) {
      case 'createCandidates':
      case 'candidateForm':
        if (type === fromModalConstants.MODAL_HIDE) {
          yield put(
            fromModalActions.exModalPropsAction({
              id: ID,
              isOpen: true,
            }),
          );
        }

        if (type === fromModalConstants.MODAL_CANCEL) {
          yield put(
            modalsSlice.actions.removeModal({
              id: ID,
            }),
          );
        }
        break;
      case 'selectActionAddToTalentPool':
      case 'success':
      case 'newTalentPoolForm':
      case 'createNewTalentPool':
        if (type === fromModalConstants.MODAL_CANCEL) {
          yield put(
            modalsSlice.actions.removeModal({
              id: ID,
            }),
          );
        }
        break;
      default:
        break;
    }
  }
}

const ID = 'ADD_TO_TALENT_POOL_WIZARD';

function* AddToTalentPoolWizard() {
  const result = yield call(modalSagaWorker, {
    id: ID,
    modalType: ModalsTypeKey.wizard,
    modalConfig: {
      wizardType: 'bulkAddToTalentPool',
      page: 'selectActionAddToTalentPool',
      content: {
        title: 'Add to New Talent Pool',
        withTitle: true,
      },
    },
    modalProps: {},
  });

  if (result.cancel) {
    return;
  }

  const modalId = result.confirm.payload.id;

  yield put(fromModalActions.exModalHideAction({ id: modalId }));
}

export function* addToTalentPoolWizardSagas() {
  yield fork(handleTransition);
  yield fork(onStepChangeBackgroundActions);
}

export function* takeAddToTalentPoolWizardActions() {
  yield takeLatest(fromCandidateActions.addToTalentPoolWizardAction, AddToTalentPoolWizard);
}
