import * as candidateApi from 'api-endpoints/candidate';
import * as jobApi from 'api-endpoints/job';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { mapApplicant, mapApplicants } from 'containers/ApplicantLists/store/mappers';
import { ApplicantListState } from 'containers/ApplicantLists/store/models';
import { applicantListActions } from 'containers/ApplicantLists/store/reducers';
import { getListParams } from 'containers/ApplicantLists/store/selectors';
import { loaderActions } from 'containers/Loader/store';

import { startLoaderThunk, stopLoaderThunk } from 'modules/LoaderManager/redux';
import { mapComplexListIdToMethod } from 'utils/list';

import { ViewMode } from 'store/constants';
import { applicantsActions } from 'store/entities/applicants/index';
import { Applicant, ApplicantBelongsTo, ApplicantFilters } from 'store/entities/applicants/models';
import { Job } from 'store/entities/jobs/models';
import { AppDispatch, AppEffectParams, AppThunk } from 'store/types';

export const changeViewMode =
  (listId: ApplicantListState['id'], viewMode: ViewMode) => async (dispatch: AppDispatch) => {
    dispatch(applicantListActions.updateViewMode({ id: listId, viewMode }));
    dispatch(applicantListActions.updatePage({ id: listId, pageNo: 0 }));
    dispatch(applicantListActions.clearItems({ id: listId }));
  };

export const loadApplicantsTaskId = 'loadApplicantsTaskId';

const mapApplicantListId = mapComplexListIdToMethod({
  [ApplicantBelongsTo.candidate]: candidateApi.getCandidateJobApplicants,
  [ApplicantBelongsTo.job]: jobApi.getJobApplicantsForJob,
});

type LoadApplicantsProps = {
  listId: ApplicantListState['id'];
  belongsTo?: ApplicantFilters['belongsTo'];
};

export const loadApplicants =
  ({ listId, preloader, belongsTo: belongsToFromProps }: AppEffectParams<LoadApplicantsProps>): AppThunk =>
  async (dispatch, getState) => {
    startLoaderThunk(dispatch, loadApplicants);
    const state = getState();
    const params = getListParams(state, { id: listId });
    const belongsTo = belongsToFromProps || params?.filters?.belongsTo;
    const apiMethod = mapApplicantListId(belongsTo || listId);
    if (!apiMethod) return false;
    if (preloader) {
      dispatch(loaderActions.start(loadApplicantsTaskId));
    }
    const { message, data } = await apiMethod(params);
    stopLoaderThunk(dispatch, loadApplicants);
    if (message) {
      if (preloader) {
        dispatch(loaderActions.stop(loadApplicantsTaskId));
      }
      return dispatch(alertsEffects.showError({ message }));
    }
    const { applicantsIds, applicants } = mapApplicants(data?.items ?? []);
    dispatch(applicantsActions.upsert({ items: applicants }));
    dispatch(
      applicantListActions.fetchSuccess({
        id: listId,
        items: applicantsIds,
        pageCount: data?.pageCount,
        totalItemsCount: data?.totalItemsCount,
      }),
    );
    dispatch(applicantListActions.update({ id: listId, filter: data?.filter }));
    if (preloader) {
      dispatch(loaderActions.stop(loadApplicantsTaskId));
    }
  };
loadApplicants.processing = loadApplicantsTaskId;

export const loadApplicant =
  ({
    jobId,
    applicantId,
    preloader,
  }: AppEffectParams<{
    applicantId: Applicant['id'];
    jobId: Job['id'];
  }>): AppThunk =>
  async (dispatch) => {
    startLoaderThunk(dispatch, loadApplicant);
    if (preloader) {
      dispatch(loaderActions.startSilent(loadApplicant.loadTaskId));
    } else {
      dispatch(loaderActions.start(loadApplicant.loadTaskId));
    }
    const { message, data } = await jobApi.getJobApplicant({ urlParams: { applicantId, jobId } });
    if (message) {
      dispatch(loaderActions.stop(loadApplicant.loadTaskId));
      stopLoaderThunk(dispatch, loadApplicant);
      return dispatch(alertsEffects.showError({ message }));
    }
    dispatch(applicantsActions.upsertOne({ item: { ...mapApplicant({ ...data, applicantId }), jobId } }));
    dispatch(loaderActions.stop(loadApplicant.loadTaskId));
    stopLoaderThunk(dispatch, loadApplicant);
  };
loadApplicant.loadTaskId = 'loadApplicantTaskId';
