import { DateTimeDto } from 'model';
import { DateTimeType } from 'model/api-enums.constants';

import {
  CreateCandidateWorkExperienceInnerRequestParams,
  CreateCandidateWorkExperienceRequestParams,
  UpdateCandidateWorkExperienceInnerRequestParams,
  UpdateCandidateWorkExperienceRequestParams,
} from 'store/entities/candidate-work-experience/api/requests';

function getEndDateTimeDTO(dateTime: string | null): DateTimeDto {
  const actualDate = dateTime === '' ? null : dateTime;

  return {
    dateTimeType: actualDate === null ? DateTimeType.Present : DateTimeType.ActualDate,
    actualDate,
  };
}

function getStartDateTimeDTO(dateTime: string): DateTimeDto {
  return {
    dateTimeType: DateTimeType.ActualDate,
    actualDate: dateTime,
  };
}

export const workExperienceDateRequestMapper = ({
  data,
  ...rest
}: UpdateCandidateWorkExperienceInnerRequestParams | CreateCandidateWorkExperienceInnerRequestParams):
  | UpdateCandidateWorkExperienceRequestParams
  | CreateCandidateWorkExperienceRequestParams => {
  const { endDate, startDate, ...restData } = data;

  return {
    ...rest,
    data: {
      ...restData,
      endDate: getEndDateTimeDTO(endDate),
      startDate: getStartDateTimeDTO(startDate),
    },
  };
};
