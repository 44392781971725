import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { CompanyInfoDto } from 'api-endpoints/company/models';

import { Currency, ExCommentWidget } from 'model';

import { JobTeamMember } from 'store/entities/jobs';
import { TalentPool } from 'store/entities/talent-pools';

import type * as companyTypes from './company.types';

export const CompanyInfo = <T extends CompanyInfoDto = CompanyInfoDto>(dto: T) => ({
  companyPublicUrl: dto.companyId as string | undefined,
  abn: null as null | string,
  registeredNumber: null as string | null,
  companyLogoUrl: dto.logoUrl ?? 'https://via.placeholder.com/150x150',
  ...dto,
  currencies: dto.currencies.map(({ currencyId, ...currency }) => ({
    ...currency,
    currencyId,
    id: currencyId,
  })),
});

export type DaxtraQualificationMapping = {
  daxtraQualificationName: string;
  qualificationId: string;
};

export type DaxtraQualification = {
  mapping: DaxtraQualificationMapping[];
  qualifications: string[];
};

export interface CompanyState {
  info: companyTypes.CompanyInfo | null;
  daxtra: DaxtraQualification | null;
  comments: ExCommentWidget[];
  adminTeamMembers: JobTeamMember[];
}

export interface GeneralTalentPool extends TalentPool {}

export const CompanyInfoCurrency = (currency: Currency) => ({
  main: false as boolean,
  currencyId: currency.id,
  ...currency,
});

export type CompanyReducers<T> = CaseReducer<CompanyState, PayloadAction<T>>;
