// @ts-check
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import apiStatusCodes from './lang/apiStatusCodes.js';
import emailTemplate from './lang/email-template-en.json';
import en from './lang/en';
import enPublicPages from './lang/enPublicPages';
import fr from './lang/fr';
import validationErrorCodes from './lang/validation-error-codes.js';

export const defaultNS = 'translations';
export const resources = {
  en: {
    translations: en,
    emailTemplate,
    apiStatusCodes,
    validationErrorCodes,
    enPublicPages,
  },
  fr: {
    translations: fr,
  },
} as const;

i18n.use(initReactI18next).init({
  // we init with resources
  lng: 'en',
  resources,
  fallbackLng: 'en',
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations', 'emailTemplate', 'apiStatusCodes', 'validationErrorCodes', 'enPublicPages'],
  defaultNS,

  interpolation: {
    escapeValue: false,
  },
});
