import { ExInterviewTemplate, ExJobInterviewTemplate } from 'model';
import { InterviewTemplateState } from 'model/api-enums.constants';

export interface InterviewTemplateSelectOption {
  interviewTemplateId: ExInterviewTemplate['interviewTemplateId'];
  interviewTemplateName: ExInterviewTemplate['name'];
  isDisabled: boolean;
}

export const getInterviewTemplateAsSelectOption = (
  interviewTemplate: Partial<ExInterviewTemplate> & Partial<ExJobInterviewTemplate>,
): InterviewTemplateSelectOption => {
  const isDraft = interviewTemplate.state === InterviewTemplateState.Draft;
  const name = interviewTemplate.name || interviewTemplate.interviewTemplateName;
  const label = `${name}${isDraft ? ' - In Draft' : ''}`;

  return {
    interviewTemplateId: interviewTemplate.interviewTemplateId!,
    interviewTemplateName: label,
    isDisabled: isDraft,
  };
};
