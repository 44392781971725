import React, { useEffect, useState } from 'react';

import { descriptionValidationSchema, minimalJobDescriptionLength } from 'containers/JobForms/validators';
import { JobDescriptionModalStyled } from 'containers/Modals/ModalsContent/Job/JobDescriptionModal/JobDescriptionModalComponents';
import { JobDescriptionModalProps } from 'containers/Modals/ModalsContent/Job/JobDescriptionModal/JobDescriptionModalProps';
import { modalsSelectors } from 'containers/Modals/store';

import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { DialogContentComponent, DialogProps } from 'components/Dialog';
import { TextEditor } from 'components/Form/TextEditor';
import { ExDialogActionButton, ExDialogActions, ExDialogFooter } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useNoScrollBodyOnMount } from 'utils/hooks/useNoScrollBody';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { useYupValidationCallback } from 'utils/hooks/useYupValidationCallback';
import { getTranslate } from 'utils/i18utils';

import { jobsSelectors } from 'store/entities/jobs';
import { jobDescriptionEdit } from 'store/entities/jobs/job.actions';
import { exModalConfirmAction } from 'store/modals/modals.actions';

export const JobDescriptionModal: React.FC<JobDescriptionModalProps> = ({ id, className, onClose }) => {
  const dispatch = useAppDispatch();
  const [description, setDescription] = useState('');
  const jobId = useAppSelector(modalsSelectors.modalJobId);
  const job = useAppSelector(jobsSelectors.getById, jobId);

  const onChange = (newJobDescription: string) => setDescription(newJobDescription);

  const onSubmitValidation = useYupValidationCallback<string>(descriptionValidationSchema, () => {
    if (jobId && job) {
      dispatch(
        exModalConfirmAction({
          id,
          modalResult: {
            data: { ...job, description },
            urlParams: {
              jobId,
            },
          },
        }),
      );
    }
  });

  const submitHandler: React.MouseEventHandler<HTMLDivElement> = async () => {
    await onSubmitValidation(description);
  };

  const onCloseClickHandler: React.MouseEventHandler<HTMLDivElement> = () => {
    onClose(false);
  };

  useEffect(() => {
    if (job?.description) {
      setDescription(job?.description);
    }
  }, [job]);

  const content: DialogProps['content'] = {
    title: 'Edit Job Description',
    withTitle: true,
  };

  useNoScrollBodyOnMount();

  return (
    <JobDescriptionModalStyled onClose={onClose} content={content} className={className}>
      <DialogContentComponent>
        <div className="mb-2">
          {getTranslate('text_must_be_at_least', {
            fieldName: 'Description',
            min: minimalJobDescriptionLength,
            count: minimalJobDescriptionLength,
          })}
        </div>
        <TextEditor onChange={onChange} value={description} />
        <ExDialogFooter>
          <ExDialogActions>
            <ExDialogActionButton variant="light" onClick={onCloseClickHandler}>
              Cancel
            </ExDialogActionButton>
            <ExLoaderHandlerWrapper action={jobDescriptionEdit}>
              <ExDialogActionButton className="ml-3" variant="primary" onClick={submitHandler}>
                Update
              </ExDialogActionButton>
            </ExLoaderHandlerWrapper>
          </ExDialogActions>
        </ExDialogFooter>
      </DialogContentComponent>
    </JobDescriptionModalStyled>
  );
};
