import { Applicant } from 'src/store/entities/applicants';

import { replaceParamsInUrl } from 'api-client/utils';
import { PlacementEndpoints } from 'api-endpoints/placement/endpoints';

import { ApiResponseWithPagination } from 'model';

import { emptyApi } from 'store/entities/emptyApi';

const extendedPlacementApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    placementList: builder.query<ApiResponseWithPagination<Applicant>, any>({
      query: (args) => ({
        url: replaceParamsInUrl(PlacementEndpoints.SearchPlacementApplicantsFunc, {}),
        method: 'GET',
        params: {
          ...args,
        },
      }),
    }),
  }),
});

export const enhancedPlacementApi = extendedPlacementApi.enhanceEndpoints({
  addTagTypes: ['Placements'],
  endpoints: {
    placementList: {
      providesTags: () => [{ type: 'Placements', id: 'LIST' }],
    },
  },
});

export const { usePlacementListQuery } = enhancedPlacementApi;
