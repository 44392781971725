import { imagesConfig } from 'config';

import {
  ApplicantAdditionalInfo,
  JobBoardLogo,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/ApplicantModalV3Components';
import { ApplicantContactsItem } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalV4Components';
import { useApplicantFromModal } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/hook/useApplicantFromModal';

import { useDateDefaultFormatFunction } from 'utils/hooks/dateTime';

type ApplicantAdditionalInfoViewProps = {
  className?: string;
};

const useApplicantAdditionalInfoViewState = ({ className }: ApplicantAdditionalInfoViewProps) => {
  const applicant = useApplicantFromModal();
  const { formatDateToDefault } = useDateDefaultFormatFunction();

  return {
    className,
    applicant,
    formatDateToDefault,
  } as const;
};

export const ApplicantAdditionalInfoView: React.FC<ApplicantAdditionalInfoViewProps> = (props) => {
  const { className, applicant, formatDateToDefault } = useApplicantAdditionalInfoViewState(props);

  if (!applicant) {
    return null;
  }

  return (
    <ApplicantAdditionalInfo className={className}>
      <ApplicantContactsItem>
        Applied on {applicant.appliedOn && formatDateToDefault(applicant.appliedOn)}
      </ApplicantContactsItem>
      <ApplicantContactsItem>
        {applicant.jobBoardLogo ? (
          <JobBoardLogo
            alt={applicant.sourceName}
            src={`${imagesConfig.idibuImageBasicUrl}${applicant.jobBoardLogo}`}
          />
        ) : (
          applicant.sourceName
        )}
      </ApplicantContactsItem>
    </ApplicantAdditionalInfo>
  );
};
