import React, { useEffect } from 'react';
import styled from 'styled-components';

import { ModalsTypeKey } from 'containers/Modals/AppModalProps';
import { ExModalBodyStyled, ExModalBodyStyledWide } from 'containers/Modals/ModalsContent/Company/ExModalComponents';
import { NewTalentPoolFormBody, validationSchemaNewTalentPool } from 'containers/TalentPoolForms';
import { talentPoolFormActions } from 'containers/TalentPoolForms/state';

import { Form } from 'components';
import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { ExDialogActions, ExDialogProps } from 'components/ui/ExDialog';
import { loaderThunkActionWrapper } from 'modules/LoaderManager/redux';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { TalentPool, talentPoolEffects } from 'store/entities/talent-pools';
import { wizardBackward } from 'store/modals/modals.actions';
import { ExModal } from 'store/modals/modals.interfaces';

import { useSaveTalentPool } from './hooks/useSaveTalentPool';

type NewTalentPoolProps = ExModal & ExDialogProps;

const FormStyled = styled(Form)`
  & > *:not(:last-child) {
    margin-bottom: 0;
  }
`;

const useNewTalentPoolForWizardState = ({ id }: NewTalentPoolProps) => {
  const dispatch = useAppDispatch();
  const isMountedRef = React.useRef(true);
  const [submitting, setSubmitting] = React.useState(false);
  const [data, setData] = React.useState<Partial<TalentPool> | null>(null);

  const defaultValues = {};

  const onBackHandler = () => {
    dispatch(wizardBackward({ id }));
  };

  const submitHandler = React.useCallback((talentPoolFormData: Partial<TalentPool>) => {
    if (isMountedRef.current) {
      setData(talentPoolFormData);
    }
  }, []);

  const message = data ? `Talent Pool "${data.name}" successfully created.` : '';
  const modalId = ModalsTypeKey.newTalentPool;
  useSaveTalentPool({ data, submitting, setSubmitting, message, id: modalId });

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      dispatch(talentPoolFormActions.updateState({ apiErrors: [] }));
    };
  }, []); // eslint-disable-line

  return {
    submitHandler,
    defaultValues,
    onBackHandler,
  };
};

export const NewTalentPoolForWizard: React.FC<NewTalentPoolProps> = (props) => {
  const { defaultValues, onBackHandler, submitHandler } = useNewTalentPoolForWizardState(props);

  return (
    <ExModalBodyStyledWide>
      <ExModalBodyStyled>
        <FormStyled
          onSubmit={submitHandler}
          validationSchema={validationSchemaNewTalentPool}
          defaultValues={defaultValues}
        >
          <NewTalentPoolFormBody />
          <ExDialogActions>
            <ExButton variant="light" onClick={onBackHandler}>
              Cancel
            </ExButton>
            <ExLoaderHandlerWrapper action={loaderThunkActionWrapper(talentPoolEffects.saveTalentPool)}>
              <ExButton className="ml-3" variant="primary" type="submit">
                Save
              </ExButton>
            </ExLoaderHandlerWrapper>
          </ExDialogActions>
        </FormStyled>
      </ExModalBodyStyled>
    </ExModalBodyStyledWide>
  );
};
