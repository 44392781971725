/* eslint-disable sonarjs/no-identical-functions */
import React, { useCallback, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { useAppRouterParams } from 'router';
import styled from 'styled-components/macro';

import { apiConfig } from 'config/apiConfig';

import { ExScreeningQuestion } from 'model';

import { ApplicantFormScreeningQuestionsControlContainer } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ApplicantFormScreeningQuestionsControlContainer';
import { ScreeningQuestionFormHiddenFocusInputStyled } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionForm/ScreeningQuestionForm';
import { ScreeningQuestionViewLoading } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionView/ScreeningQuestionViewLoading';
import { ScreeningQuestionViewProps } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionView/ScreeningQuestionViewProps';
import { useApplicationFormQuestionField } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/hooks/useCompanyScreeningQuestionsListState';

import { AppFileCard, InputFile } from 'components/AppFileCard/AppFileCard';
import { FileExtension } from 'components/FileExtension';
import { FileUploader } from 'components/FileUploader';
import { IconAddFiles } from 'components/Icons/IconAddFiles';
import { UploadButton } from 'components/UploadButton';
import { FileValidationRules } from 'utils/files';
import { useDownloadFile } from 'utils/hooks/useDownloadFile';
import { useOnChangeFilewithValidation } from 'utils/hooks/useFileInputValidation';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { authSelectors } from 'store/auth/auth.selectors';
import { screeningQuestionPublicApi } from 'store/entities/screening-question/screening-question.api';

type ScreeningQuestionFileUploadProps = ScreeningQuestionViewProps & {
  className?: string;
};

type ScreeningQuestionFileUploadStyledProps = {
  disabled?: boolean;
};

const ScreeningQuestionFileUploadStyled = styled(UploadButton)<ScreeningQuestionFileUploadStyledProps>`
  border: 1px dashed #ccc;
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  width: 364px;
  height: 72px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  margin-right: 10px;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  margin-top: 10px;
`;

const ScreeningQuestionFileUploadIcon = styled.div`
  margin-right: 10px;
`;

const ScreeningQuestionFileUploadColorLabel = styled.span`
  color: #0b78ff;
`;

const ScreeningQuestionFileUploadWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: -10px;
`;

const ScreeningQuestionAppFileCardStyled = styled.div`
  position: relative;
  margin-right: 10px;
  margin-top: 10px;
`;

const ScreeningQuestionViewLoadingStyled = styled(ScreeningQuestionViewLoading)`
  right: 5px;
  top: 5px;
`;

const ScreeningQuestionNoFileMessage = styled.div`
  margin-top: 10px;
`;

const AppFileCardStyled = styled(AppFileCard)`
  margin: 0;
  height: 72px;
  width: 200px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  ${FileExtension} {
    margin: 0;
  }
`;

type ScreeningQuestionAppFileCardProps = {
  item: InputFile;
  question: ExScreeningQuestion;
  onDelete?: () => void;
};

const ScreeningQuestionAppFileCard: React.FC<ScreeningQuestionAppFileCardProps> = ({ item }) => {
  const companyId = useAppSelector(authSelectors.selectCurrentCompanyId);
  const downloadFile = useDownloadFile();

  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    setIsLoading(true);
    const path = [apiConfig.baseUrl, 'api/company', companyId, 'file', item.fileId].join('/');
    await downloadFile({ fileName: item.filename, path });
    setIsLoading(false);
  };

  return (
    <ScreeningQuestionAppFileCardStyled>
      <ScreeningQuestionViewLoadingStyled isLoading={isLoading} />
      <AppFileCardStyled item={item} onClick={onClick} isDownloadable={!isLoading} />
    </ScreeningQuestionAppFileCardStyled>
  );
};

const useScreeningQuestionFileUploadState = ({
  className,
  editable,
  question,
  context,
}: ScreeningQuestionFileUploadProps) => {
  const { answer } = question;

  const questionFiles = useMemo(() => {
    return Array.isArray(answer) && answer?.length > 0
      ? answer.map((file) => ({
          fileId: file.fileId,
          filename: file.fileName,
          filetype: file.fileType,
        }))
      : [];
  }, [answer]);

  const hasFiles = questionFiles?.length > 0;

  const showNoFileMsg = context === 'applicantModalScreeningQuestions';
  const showNoFileComponent = !hasFiles && !showNoFileMsg;
  const showNoFileText = !hasFiles && showNoFileMsg;

  const disabled = !editable;

  return {
    className,
    disabled,
    questionFiles,
    question,
    hasFiles,
    showNoFileComponent,
    showNoFileText,
  } as const;
};

export const ScreeningQuestionFileUpload: React.FC<ScreeningQuestionFileUploadProps> = (props) => {
  const { className, disabled, questionFiles, question, hasFiles, showNoFileComponent, showNoFileText } =
    useScreeningQuestionFileUploadState(props);

  return (
    <ScreeningQuestionFileUploadWrapper>
      {showNoFileComponent && (
        <ScreeningQuestionFileUploadStyled
          className={className}
          disabled={disabled}
          accept={allowedFiles}
          onChange={() => {}}
        >
          <ScreeningQuestionFileUploadIcon>
            <IconAddFiles />
          </ScreeningQuestionFileUploadIcon>
          <div>
            <ScreeningQuestionFileUploadColorLabel>Browse</ScreeningQuestionFileUploadColorLabel> your files
          </div>
        </ScreeningQuestionFileUploadStyled>
      )}
      {showNoFileText && <ScreeningQuestionNoFileMessage>No files attached</ScreeningQuestionNoFileMessage>}
      {hasFiles &&
        questionFiles.map((questionFile) => (
          <ScreeningQuestionAppFileCard key={questionFile.fileId} item={questionFile} question={question} />
        ))}
    </ScreeningQuestionFileUploadWrapper>
  );
};

const allowedFiles = '.pdf,.doc,.docx,.rtf,.txt,.jpg,.jpeg,.png,.xls,.xlsx';

const fileValidationOptions: FileValidationRules = [
  {
    type: 'type',
    rule: allowedFiles,
  },
  {
    type: 'size',
    rule: 100_000_000,
  },
];

const useApplicantFormScreeningQuestionFileUploadState = (props: ScreeningQuestionFileUploadProps) => {
  const { className } = props;
  const { companyPublicUrl } = useAppRouterParams();

  const { fieldName, fieldNameValid, fieldErrors, validated, isInvalid, disabled, register, setValue } =
    useApplicationFormQuestionField(props);

  const {
    field: { value: fieldValue, onChange: handlerChangeField },
  } = useController({ name: fieldName });

  const [questionFileUpload, { isLoading }] =
    screeningQuestionPublicApi.endpoints.uploadPendingJobApplicantFileAnswerFunc.useMutation();

  const disabledInput = disabled || isLoading;

  const questionFiles = useMemo(() => {
    return Array.isArray(fieldValue) ? fieldValue : [];
  }, [fieldValue]);

  const uploadHandler = useCallback(
    async (files: File[]) => {
      if (disabledInput || !files) {
        return;
      }

      const formData = new FormData();

      files.forEach((file) => formData.append('document', file, file.name));

      try {
        const result = await questionFileUpload({
          data: formData,
          companyId: companyPublicUrl,
        }).unwrap();

        if (result) {
          const newFiles = questionFiles.filter((item) => item.fileId !== result.fileId).concat(result);

          handlerChangeField(newFiles);
          setValue(fieldNameValid, newFiles.length);
        }
      } catch (e) {}
    },
    [disabledInput, questionFileUpload, handlerChangeField, questionFiles, fieldNameValid, setValue, companyPublicUrl],
  );

  const { onChangeFileWithValidation } = useOnChangeFilewithValidation({
    options: fileValidationOptions,
    withToasts: true,
    onSuccess: uploadHandler,
  });

  const onClickFile = useCallback(() => {}, []);

  const onDeleteFile = useCallback(
    (file) => {
      const newFiles = questionFiles.filter((item) => item.fileId !== file.fileId);

      handlerChangeField(newFiles);
      setValue(fieldNameValid, newFiles.length > 0);
    },
    [handlerChangeField, questionFiles, fieldNameValid, setValue],
  );

  return {
    className,
    disabled: disabledInput,
    questionFiles,
    isLoading,
    onChangeFileWithValidation,
    onClickFile,
    onDeleteFile,
    validated,
    fieldErrors,
    isInvalid,
    fieldNameValid,
    register,
  } as const;
};

export const ApplicantFormScreeningQuestionFileUpload: React.FC<ScreeningQuestionFileUploadProps> = (props) => {
  const {
    className,
    disabled,
    questionFiles,
    isLoading,
    onChangeFileWithValidation,
    onClickFile,
    onDeleteFile,
    fieldErrors,
    isInvalid,
    fieldNameValid,
    register,
  } = useApplicantFormScreeningQuestionFileUploadState(props);

  return (
    <ApplicantFormScreeningQuestionsControlContainer className={className} show={isInvalid} errors={fieldErrors}>
      <ScreeningQuestionFormHiddenFocusInputStyled
        type="number"
        name={fieldNameValid}
        ref={register()}
        defaultValue={0}
        tabIndex={-1}
        readOnly
      />
      <FileUploader
        accept={allowedFiles}
        disabled={disabled}
        onChange={onChangeFileWithValidation}
        files={questionFiles}
        onClick={onClickFile}
        onDelete={onDeleteFile}
        isLoading={isLoading}
        multiple={true}
      />
    </ApplicantFormScreeningQuestionsControlContainer>
  );
};
