import { createAction } from '@reduxjs/toolkit';

import { calendarJobAdsSlice } from 'containers/Calendar/state/calendar-job-ads/calendar-job-ads.reducer';

const calendarJobAdsFetchAction = createAction(`${calendarJobAdsSlice.name}/fetch`);

export const calendarJobAdsActions = {
  ...calendarJobAdsSlice.actions,
  calendarJobAdsFetchAction,
};
