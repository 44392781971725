import styled from 'styled-components';

import { DetailsRowProps } from 'components/DetailsCard';
import { Ellipsis } from 'components/Ellipsis';
import { ExCard, ExCardWithoutShadow } from 'components/ui/ExCard';
import { ExCardBodyStyled } from 'components/ui/ExCard/ExCardComponents';

export const JobSettingsCardRedesignStyled = styled(ExCard)`
  height: 100%;

  ${ExCardBodyStyled} {
    flex-grow: 1;
  }

  ${ExCardWithoutShadow} {
    height: 100%;
  }
`;

export const JobSettingsCardRedesignLabel = styled.div`
  font-weight: bold;
  padding-right: 20px;
  display: table-cell;
`;

export const JobSettingsCardRedesignValue = styled.div`
  display: table-cell;
`;

export const JobSettingsCardRedesignRow = styled.div`
  display: table-row;

  &:not(:last-child) {
    ${JobSettingsCardRedesignLabel},
    ${JobSettingsCardRedesignValue} {
      padding-bottom: 8px;
    }
  }
`;

type JobSettingsCardContentProps = {
  rows: DetailsRowProps[];
  className?: string;
};

export const JobSettingsCardContent: React.FC<JobSettingsCardContentProps> = ({ rows, className }) => {
  return (
    <ExCardWithoutShadow className={className}>
      {rows.map((row) => (
        <JobSettingsCardRedesignRow key={row.label}>
          <JobSettingsCardRedesignLabel>{row.label}:</JobSettingsCardRedesignLabel>
          {row.component ? (
            row.component()
          ) : (
            <JobSettingsCardRedesignValue>
              <Ellipsis title={row.value?.toString()}>{row.value?.toString() || '--'}</Ellipsis>
            </JobSettingsCardRedesignValue>
          )}
        </JobSettingsCardRedesignRow>
      ))}
    </ExCardWithoutShadow>
  );
};
