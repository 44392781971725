import { PaginationHookArgument } from 'components/ListView/ListViewProps';

/**
 * Custom hook for management pagination button statuses.
 *
 * @description Hook takes current page number and total pages and returns object of statuses for navigation buttons.
 * @param   {PaginationHookArgument} { pageCount, pageNo } current page and total page
 *
 * @return  {DD}                 object of statuses for navigation buttons
 */
export function usePaginationControls({ pageNo, pageCount }: PaginationHookArgument) {
  const isNextPageDisabled = pageCount === pageNo + 1;
  const isPrevPageDisabled = pageNo === 0;
  const nextPage = pageNo + 1;
  const prevPage = pageNo - 1;

  return {
    isNextPageDisabled,
    isPrevPageDisabled,
    nextPage,
    prevPage,
  };
}
