import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { publicJobsSelectors } from 'pages/public/state/public-jobs/public-jobs.selectors';

import { Ellipsis } from 'components/Ellipsis';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

import { Job, jobsSelectors } from 'store/entities/jobs';

export const JobPositionRowRef = styled.span`
  font-weight: 400;
  font-size: ${getThemeFontSize('small')};
`;

export const JobPositionRowName = styled.span`
  font-weight: 600;
`;

export const LinkStyled = styled(Link)`
  color: inherit;
`;

type JobPositionRowProps = {
  jobId: Job['jobId'];
  preventLink?: boolean;
  to?: string;
  isPublic?: boolean;
};

const JobPositionRow: React.FC<JobPositionRowProps> = ({ jobId, preventLink, to, isPublic = false }) => {
  const publicJob = useAppSelector(publicJobsSelectors.selectById, jobId);
  const mainJob = useAppSelector(jobsSelectors.getById, jobId);
  const job = isPublic ? publicJob : mainJob;

  if (!job) {
    return null;
  }

  return (
    <>
      <Ellipsis className="ref" title={job.referenceNo}>
        <JobPositionRowRef>Ref: {job.referenceNo}</JobPositionRowRef>
      </Ellipsis>
      {!preventLink && to ? (
        <LinkStyled to={to} className="column-title">
          <JobPositionRowName>
            <Ellipsis title={job.jobName}>{job.jobName}</Ellipsis>
          </JobPositionRowName>
        </LinkStyled>
      ) : (
        <span className="column-title">
          <Ellipsis title={job.jobName}>{job.jobName}</Ellipsis>
        </span>
      )}
    </>
  );
};

export default JobPositionRow;
