import { applyMockAdapter } from 'api-mocks';
import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import qs from 'qs';

import { apiConfig } from 'config/apiConfig';

import { replaceParamsInUrl } from 'api-client/utils';

import authService from 'containers/Auth/state/AuthenticationService';

import { consoleWarnForDevEnv } from 'utils/consoleErrorForDevEnv';
import { getGlobalGetState } from 'utils/globalStore';

import { authSelectors } from 'store/auth/auth.selectors';
import { history } from 'store/history';

axios.defaults.baseURL = apiConfig.baseUrl;

// Any status code that lie within the range of 2xx cause this function to trigger
// Do something with response data
const onSuccess = (response: AxiosResponse) => {
  if (
    response.status === 204 &&
    !response.data &&
    response.config.data &&
    !isInstanceOfFormData(response.config.data)
  ) {
    response.data = JSON.parse(response.config.data);
  }
  if (typeof response.data === 'string' && response.data.startsWith('{')) {
    try {
      response.data = JSON.parse(response.data);
    } catch {
      // JSON syntax error, return string data
    }
  }
  return response;
};

function isInstanceOfFormData(object?: any): boolean {
  return object instanceof FormData;
}

// Any status codes that falls outside the range of 2xx cause this function to trigger
// Do something with response error
const onError = async (error: unknown) => {
  if (axios.isCancel(error)) {
    return { message: error.message, cancelled: true };
  }

  if (!(error instanceof AxiosError)) {
    return { message: (error as any).message || 'Something happened in setting up the request' };
  }

  if (error.response) {
    let { description } = error.response.data;

    if (error.response.status === 503) {
      description = 'Service Unavailable';
    }

    // Handle Unauthorized response
    if (error.response.status === 401) {
      // Set description for user alert
      description = 'Unauthorized';
      // If we reach max retry tries we skip next block and show user message
      if (error.config && !error.config?._retry) {
        // Temporary store config from previous request
        const newConfig = error.config;
        // Set field for indicate that request is retried
        newConfig._retry = true;
        // Try authenticate user
        await authService.signIn();
        // Get token from AuthorizeService
        const token = await authService.getAccessToken();
        // Update token in headers
        newConfig.headers = { ...error.config.headers } as AxiosHeaders;
        newConfig.headers['Authorization'] = `Bearer ${token ?? ''}`;
        // Trying to make a request with a new token
        return api().request(newConfig);
      }
      // Redirect user to home page if can`t re-authorize user
      history.push('/');
    }

    if (error.response.status === 404) {
      description = 'Not Found';
    }

    return {
      message: description || 'Internal Server Error',
      response: error.response,
      errorData: error.response.data,
      status: error.response.status,
    };
  } else if (error.request) {
    return { message: 'The request was made but no response was received' };
  } else {
    return { message: error.message || 'Something happened in setting up the request' };
  }
};

type ApiInstance = AxiosInstance | null;

let connection: ApiInstance = null;

interface AxiosInstanceDefaults {
  headers?: any;
  baseURL?: string;
  companyId?: string;
}

export const apiConnect = (options: AxiosInstanceDefaults): AxiosInstance => {
  const axiosWithMockAdapter = applyMockAdapter(axios);

  connection = axiosWithMockAdapter.create({
    timeout: apiConfig.apiTimeout,
    ...(options.baseURL && { baseURL: options.baseURL }),
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
  connection.defaults = {
    ...connection.defaults,
    ...(options.headers && { headers: options.headers }),
  };
  connection.interceptors.response.use(onSuccess, onError);

  connection.interceptors.request.use(RequestInterceptors);

  return connection;
};

function axiosConfigUrlParametersParser(config: AxiosRequestConfig): AxiosRequestConfig {
  if (!config.url) {
    return config;
  }

  if (config.urlParams) {
    config.url = replaceParamsInUrl(config.url, config.urlParams);
  }

  return config;
}

function axiosConfigUrlCompanyParameterParser(config: AxiosRequestConfig): AxiosRequestConfig {
  if (!config.url) {
    return config;
  }

  const companyId = authSelectors.selectCurrentCompanyId(getGlobalGetState());

  if (companyId) {
    config.url = config.url?.replace('{companyId}', companyId);
  }

  return config;
}

async function axiosAuthorization(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  const token = await authService.getAccessToken();
  const isFlagUrl = config.url ? config.url.indexOf('flags') > -1 : false;
  if (!isFlagUrl) {
    config.headers = { ...config.headers };
    config.headers['Authorization'] = `Bearer ${token ?? ''}`;
  }

  return config;
}

async function RequestInterceptors(config: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig<any>> {
  let newConfig = config as AxiosRequestConfig;
  newConfig = await axiosAuthorization(newConfig);
  newConfig = axiosConfigUrlCompanyParameterParser(newConfig);
  newConfig = axiosConfigUrlParametersParser(newConfig);

  return newConfig as InternalAxiosRequestConfig<any>;
}

export const api = () => {
  if (connection != null) {
    return connection;
  } else {
    consoleWarnForDevEnv('Connection is not initialized yet. Will be initialized with default options');
    return axios.create();
  }
};
