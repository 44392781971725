import { createSelector } from '@reduxjs/toolkit';

import { publicCompanyInitialState } from './public-company/public-company.reducer';
import { publicCountryInitialState } from './public-country/public-country.reducer';
import { publicCurrencyInitialState } from './public-currency/public-currency.reducer';
import { publicJobsInitialState } from './public-jobs/public-jobs.reducer';
import { publicNewApplicantFormInitialState } from './public-new-applicant-form/public-new-applicant-form.reducer';

export interface PublicState {
  publicJobs: typeof publicJobsInitialState;
  publicCompany: typeof publicCompanyInitialState;
  publicNewApplicantForm: ReturnType<typeof publicNewApplicantFormInitialState>;
  publicCountry: typeof publicCountryInitialState;
  publicCurrency: typeof publicCurrencyInitialState;
}

const publicDomain = (state: any): PublicState => state.publicPage;

export const publicJobsDomain = createSelector(publicDomain, (domain) => domain.publicJobs);
export const publicCompanyDomain = createSelector(publicDomain, (domain) => domain.publicCompany);
export const publicCountryDomain = createSelector(publicDomain, (domain) => domain.publicCountry);
export const publicCurrencyDomain = createSelector(publicDomain, (domain) => domain.publicCurrency);
export const publicNewApplicantFormDomain = createSelector(publicDomain, (domain) => domain.publicNewApplicantForm);
