import React from 'react';
import styled, { css } from 'styled-components';

import { ExBadge } from 'components/ui/ExBadge';
import { ExBadgeVariant } from 'components/ui/ExBadge/ExBadgeProps';
import { ExCardProps } from 'components/ui/ExCard/ExCardProps';
import { ExVisible } from 'components/ui/ExVisible';
import { HoveredBoxShadowCard } from 'utils/styled/common';
import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

export const exCardStyles = css`
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${getThemeBorderProps('borderRadius')};
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ExCardStyled = styled.div`
  ${exCardStyles};

  & & {
    background-color: ${({ theme }) => theme.colors.bodyBackgroundColor};
    border-radius: ${getThemeBorderProps('borderRadius')};
    box-shadow: none;
  }
`;

export const ExCardHeaderStyled = styled.div`
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  align-items: center;
  min-height: 51px;
`;

export const ExCardHeaderTitleStyled = styled.div`
  flex: 1 0 auto;
  font-size: ${getThemeFontSize('cardHeading')};
  color: ${({ theme }) => theme.colors.blackHeadings};
`;

const ExCardHeaderTitleWithBadgeStyled = styled(ExCardHeaderTitleStyled)`
  display: flex;
  align-items: center;
`;

const ExBadgeStyled = styled(ExBadge)`
  padding: 3px 8px;
  margin-left: 10px;
`;

export const ExCardHeaderActionsStyled = styled.div`
  display: flex;
  justify-content: space-between;

  > *:not(:first-child) {
    margin-left: 1rem;
  }
`;

type ExCardBodyStyledProps = Pick<ExCardProps, 'bodyMaxHeight'>;

const ExCardBodyStyledMaxHeight = ({ bodyMaxHeight }: ExCardBodyStyledProps) => {
  const maxHeightValue = typeof bodyMaxHeight === 'number' ? `${bodyMaxHeight}px` : bodyMaxHeight;

  return css`
    max-height: ${maxHeightValue};
  `;
};

export const ExCardBodyStyled = styled.div<ExCardBodyStyledProps>`
  flex-grow: 1;
  overflow: ${({ bodyMaxHeight }) => (bodyMaxHeight ? 'auto' : 'visible')};
  ${ExCardBodyStyledMaxHeight};
`;

export const ExCardFooterStyled = styled.div``;

export const ExCardTitle = styled.div`
  display: flex;
  font-size: ${getThemeFontSize('cardHeading')};
`;

export const HoverableExCardStyled = HoveredBoxShadowCard(styled.div`
  cursor: pointer;
`);

type ExCardHeaderWithBadgeProps = {
  title: string;
  value?: string | number;
};

export const ExCardHeaderWithBadge: React.FC<ExCardHeaderWithBadgeProps> = ({ title, value }) => {
  const hasValue = Boolean(value);

  return (
    <ExCardHeaderTitleWithBadgeStyled>
      <span>{title}</span>
      <ExVisible visible={hasValue}>
        <ExBadgeStyled variant={ExBadgeVariant.primary}>{value}</ExBadgeStyled>
      </ExVisible>
    </ExCardHeaderTitleWithBadgeStyled>
  );
};
