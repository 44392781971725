import styled from 'styled-components/macro';

import {
  InterviewTableCellComponent,
  InterviewTableCellComponentProps,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/Tabs/InterviewsTab/InterviewsTabProps';

import { EllipsisWithTooltip } from 'components/EllipsisWithTooltip';

const InterviewTableInterviewTemplateStyled = styled.div``;

const useInterviewTableInterviewTemplateState = ({ item }: InterviewTableCellComponentProps) => {
  return {
    interviewTemplateName: item?.interviewTemplateName || '--',
  } as const;
};

export const InterviewTableInterviewTemplate: InterviewTableCellComponent = (props) => {
  const { interviewTemplateName } = useInterviewTableInterviewTemplateState(props);

  return (
    <InterviewTableInterviewTemplateStyled>
      <EllipsisWithTooltip label={interviewTemplateName}>
        <div>{interviewTemplateName}</div>
      </EllipsisWithTooltip>
    </InterviewTableInterviewTemplateStyled>
  );
};
