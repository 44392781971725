import { EntityState } from '@reduxjs/toolkit';
import type { ExInterviewTemplate, ExQuestion } from 'model';

export const interviewTemplateEditFormSliceName = 'formInterviewTemplateWithQuestions';

export const interviewTemplateEditFormInitialState = {
  initialData: {
    interviewTemplate: null as null | ExInterviewTemplate,
    questions: {
      entities: {},
      ids: [],
    } as EntityState<ExQuestion>,
  },
  formData: {
    interviewTemplate: null as null | ExInterviewTemplate,
    questions: {
      entities: {},
      ids: [],
    } as EntityState<ExQuestion>,
  },
};
export const getInterviewTemplateEditFormInitialState = () => ({ ...interviewTemplateEditFormInitialState });
