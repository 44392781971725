import { Ability, AdditionalField, Country, Location, PostingDuration } from 'model';
import { EmploymentType, JobAdStatus, JobStatus } from 'model/api-enums.constants';

import { BasicModel } from 'utils/reducer/reducer-helper';

import { JobBoard } from 'store/entities/job-boards/models';
import {
  Job,
  JobApplicantsPerDayFigures,
  JobApplicantsPerPipelineStageFigures,
  Salary,
} from 'store/entities/jobs/models';

export const jobAdStatusLabels = {
  [JobAdStatus.Draft]: 'Draft',
  [JobAdStatus.Pending]: 'Pending',
  [JobAdStatus.Active]: 'Active',
  [JobAdStatus.Inactive]: 'Inactive',
  [JobAdStatus.Error]: 'Error',
  [JobAdStatus.Expired]: 'Expired',
  [JobAdStatus.Closed]: 'Closed',
  [JobAdStatus.Open]: 'Open',
  [JobAdStatus.Stopping]: 'Stopping',
  [JobAdStatus.Stopped]: 'Stopped',
  [JobAdStatus.CancelledBySystem]: 'Unsuccessful',
};

export interface JobAdLog {
  createdBy: string;
  createdOn: string;
  type: string;
  changeNoteId: string;
  name: string;
  previousStatus?: JobAdStatus;
  newStatus?: JobAdStatus;
  jobAdReference: string;
  description?: {
    errors?: string[];
    warnings?: string[];
  };
}

export interface JobAd extends BasicModel {
  applications?: number;
  postedOn: string;
  employmentType?: EmploymentType;

  // GetJobAdFunc
  jobLocation?: Location;
  locationFormatted?: string;
  expiryDate?: string | null; //Date
  status?: JobAdStatus;
  additionalFields?: AdditionalField[];
  additionalFieldsView?: AdditionalFieldView[];

  // JobAd list, SearchJobAdsForJobFunc
  jobBoardId?: string;
  jobBoardName?: string;
  sector?: number;
  jobBoardImage?: string;
  jobName?: string;
  jobReference?: string;
  jobStatus?: JobStatus;

  // SearchJobAdsForJobBoardFunc
  jobAdId?: string;

  // JobAd form
  jobId?: Job['id'];
  companyId?: Job['id'];
  jobBoardIds?: Array<JobBoard['jobBoardId']>;
  country?: string | null;
  jobAdLocation?: Partial<Location> | null;
  jobAdCountry?: Country | null;
  content?: string;
  jobBoards?: Record<string, Record<string, { value: string; children: string }>>;
  startDate?: string; //Date
  externalLink?: string | null;
  canRepost?: boolean;
  salary?: Salary;
  name?: string | null;
  jobAdName?: string | null;

  // Statistic
  jobApplicantsPerPipelineStageFigures?: JobApplicantsPerPipelineStageFigures[];
  jobApplicantsPerDayFigures?: JobApplicantsPerDayFigures[];

  //Logs
  logs?: JobAdLog[];
  description?: string;
  responsibilities?: Array<Ability> | null;
  requirements?: Array<Ability> | null;
  postingDuration: PostingDuration | null;
  jobAdReference?: string;
  postedAdIsStale?: boolean;
  jobBoardAllowsUpdates?: boolean;
  useCustomApplicationForm?: boolean;
}

export interface AdditionalFieldView {
  displayName: string;
  displayValue: string;
}

export enum SortBy {
  jobName = 'JobName',
  jobBoardName = 'JobBoardName',
  applicants = 'Applicants',
  expiryDate = 'ExpiryDate',
  status = 'Status',
  jobAdName = 'JobAdName',
}

export enum JobAdFiltersNames {
  search = 'search',
  statuses = 'statuses',
  belongsTo = 'belongsTo',
  postedOnRange = 'postedOnRange',
}

export interface JobAdFilterOpt<P> {
  value: P;
  label: string;
}

export enum JobAdBelongsTo {
  jobBoard = 'jobBoardId',
  job = 'jobId',
  company = 'companyId',
}

export interface JobAdFilters {
  [JobAdFiltersNames.search]?: string;
  [JobAdFiltersNames.statuses]?: JobAdFilterOpt<JobAdStatus>['value'][];
  [JobAdFiltersNames.belongsTo]?: [JobAdBelongsTo, BasicModel['id']];
  [JobAdFiltersNames.postedOnRange]?: [string, string];
}
