import React, { useCallback, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import { JobBoardListFiltersBar } from 'containers/JobBoardLists/JobBoardListFiltersBar';

import { ExSelectedBar } from 'components/ui/ExSelectedBar';
import { ExVisible } from 'components/ui/ExVisible';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { safariFlexBoxTweak } from 'utils/styled/common';

import { RootState } from 'store/rootReducer';

import { jobBoardListResetFilters } from './store/job-board.actions';
import { JobBoardListComponentProps } from './JobBoardListProps';
import { jobBoardListActions, jobBoardListSelectors } from './store';

const ExSelectedBarStyled = styled(ExSelectedBar)`
  margin-left: 10px;
`;

const JobBoardListHeader: React.FC<JobBoardListComponentProps & PropsFromRedux> = ({
  listId,
  className,
  selected,
  selectAll,
  deselectAll,
  selectedEntities,
  multiselect,
}) => {
  const dispatch = useAppDispatch();

  const isAllSelected = useMemo(
    () => selectedEntities.length === selected.length && selected.length !== 0,
    [selectedEntities, selected],
  );

  const handleSelectAll = useCallback(() => {
    selectAll({ id: listId });
  }, [listId, selectAll]);

  const handleDeselectAll = useCallback(() => {
    deselectAll({ id: listId });
  }, [deselectAll, listId]);

  const handleClearFilters = useCallback(() => {
    dispatch(jobBoardListResetFilters({ listId }));
    handleDeselectAll();
  }, [dispatch, handleDeselectAll, listId]);

  const selectedCount = selected.length;

  const showSelected = Boolean(multiselect) && selectedCount > 0;

  return (
    <div className={className}>
      <JobBoardListFiltersBar listId={listId} />
      <ExVisible visible={showSelected}>
        <ExSelectedBarStyled
          selectedCount={selectedCount}
          handleSelectAll={handleSelectAll}
          handleDeselectAll={handleDeselectAll}
          isAllSelected={isAllSelected}
          className="mr-3"
          handleClearFilters={handleClearFilters}
        />
      </ExVisible>
    </div>
  );
};

const mapDispatch = {
  selectAll: jobBoardListActions.selectAllItems,
  deselectAll: jobBoardListActions.deselectAllItems,
};

const mapState = (state: RootState, own: JobBoardListComponentProps) => ({
  selected: jobBoardListSelectors.getSelected(state, { id: own.listId }),
  selectedEntities: jobBoardListSelectors.getJobBoards(state, { id: own.listId }),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default styled(connector(JobBoardListHeader))`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  ${safariFlexBoxTweak};

  .list-filter-container {
    margin-bottom: 4px;
  }
`;
