import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from 'router';
import styled from 'styled-components';

import {
  applicantFormActions,
  applicantFormEffects,
  applicantFormSelectors,
} from 'containers/ApplicantForm/ApplicantFormState';
import { candidateListActions } from 'containers/CandidateLists/store';
import { jobListActions } from 'containers/JobLists/store';
import { loaderSelectors } from 'containers/Loader/store';

import { ReactComponent as Image } from 'assets/img/successful-subscribed-page.svg';

import { ArrowForward, StepComponent } from 'components/StepForm';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';

import { CandidateBelongsTo } from 'store/entities/candidates/models';
import { JobBelongsTo } from 'store/entities/jobs';
import { RootState } from 'store/rootReducer';

export interface ApplicantFormFinalPageProps extends StepComponent {
  className?: string;
}

const ApplicantFormFinalPage: React.FC<ApplicantFormFinalPageProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const formData = useSelector(applicantFormSelectors.selectFormData);

  useEffect(() => {
    dispatch(applicantFormActions.toggleShowSteps({ showSteps: false }));
    return () => {
      dispatch(applicantFormActions.toggleShowSteps({ showSteps: true }));
      dispatch(applicantFormActions.resetState({}));
      dispatch(jobListActions.resetListState({ id: JobBelongsTo.newApplicant }));
      dispatch(candidateListActions.resetListState({ id: CandidateBelongsTo.newApplicant }));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loading = useSelector<RootState>((state) =>
    loaderSelectors.isTaskActive(state, {
      taskId: applicantFormEffects.saveApplicant.processing,
    }),
  );

  useEffect(() => {
    if (!loading && !formData.jobApplicantId) {
      history.push(Routes.applicantStepSelectCandidate);
    }
  }, [loading, formData.jobApplicantId, formData, history]);

  const linkToJobDetails = ['', Routes.jobs, formData.jobId, Routes.jobApplicantsView].join('/');
  const linkToCandidate = ['', Routes.jobs, formData.jobId, Routes.candidates, formData.candidateId].join('/');

  return (
    <div className={className}>
      <h3 className="mb-5">New job application created</h3>
      <div className="mb-3">
        <Image />
      </div>
      <div className="message">You have completed the steps required to create new job application.</div>
      <h4 className="mb-4">What’s Next?</h4>
      <div className="d-flex justify-content-center">
        <div className="col-6 text-right p-0">
          <ExButton variant="light" forwardedAs={Link} to={linkToJobDetails}>
            View Job Details {<ArrowForward className="ml-3" variant="dark" />}
          </ExButton>
        </div>
        <div className="col-6 text-left pr-0">
          <ExButton variant="success" forwardedAs={Link} to={linkToCandidate}>
            View Candidate {<ArrowForward />}
          </ExButton>
        </div>
      </div>
    </div>
  );
};

export default styled(ApplicantFormFinalPage)`
  margin: 4% auto 0;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 792px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 473px;
  border-radius: ${getThemeBorderProps('borderRadius')};
  border: 1px solid ${(props) => props.theme.border.borderColor};
  background: white;

  .message {
    font-size: 20px;
    margin-bottom: 60px;
  }
`;
