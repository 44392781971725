import React from 'react';
import { Link } from 'react-router-dom';
import { ExRoutes } from 'router/routes';
import styled from 'styled-components/macro';

import { ApplicantViewActionButtons } from 'pages/Jobs/Job/components/ApplicantViewActionButtons';
import { JobApplicantQuickStatusFilter } from 'pages/Jobs/Job/components/JobApplicantQuickStatusFilter';

import { ApplicantBulkActions } from 'containers/ApplicantLists/ApplicantBulkActions';
import { ApplicantListFilter } from 'containers/ApplicantLists/ApplicantListFilter';
import { ApplicantListFilterButtons } from 'containers/ApplicantLists/ApplicantListFilterButtons';
import {
  HeaderColLeft,
  HeaderColRight,
  HeaderRow,
  HeaderRowMb,
} from 'containers/ApplicantLists/ApplicantListHeader/ApplicantListHeader.components';
import { ApplicantListSelectedBarV2 } from 'containers/ApplicantLists/ApplicantListSelectedBar/ApplicantListSelectedBarV2';
import { applicantListSelectors, ApplicantListState } from 'containers/ApplicantLists/store';

import { ExAddNewButton } from 'components/ui/ExAddNewButton/ExAddNewButton';
import { ExVisible } from 'components/ui/ExVisible';
import { useIsJobActive, useJob } from 'utils/hooks/job';

import { useSelector } from 'store/rootSelectors';

type ApplicantListHeaderV2Props = {
  listId: ApplicantListState['id'];
  className?: string;
  jobId: string;
};

const ApplicantListHeaderV2Styled = styled.div`
  margin-bottom: 20px;
`;

const ApplicantListSelectedBarStyled = styled(ApplicantListSelectedBarV2)`
  margin-left: 10px;
`;

const useApplicantListHeaderV2State = ({ className, listId, jobId }: ApplicantListHeaderV2Props) => {
  const job = useJob(jobId);
  const isSelected = useSelector((state) => applicantListSelectors.selectIsSelected(state, { id: listId }));
  const isActive = useIsJobActive(jobId);
  const newApplicantRoute = ExRoutes.applicantNewFromJob({ jobId });

  return {
    className,
    listId,
    job,
    isSelected,
    isActive,
    jobId,
    newApplicantRoute,
  };
};

export const ApplicantListHeaderV2: React.FC<ApplicantListHeaderV2Props> = (props) => {
  const { className, isSelected, listId, jobId, isActive, newApplicantRoute } = useApplicantListHeaderV2State(props);

  return (
    <ApplicantListHeaderV2Styled className={className}>
      <HeaderRowMb>
        <HeaderColLeft>
          <JobApplicantQuickStatusFilter />
        </HeaderColLeft>
        <HeaderColRight>
          <ApplicantListFilterButtons listId={listId} />
          <ExVisible visible={isActive}>
            <ExAddNewButton variant="primary" size="sm" forwardedAs={Link} to={newApplicantRoute}>
              New Applicant
            </ExAddNewButton>
          </ExVisible>
        </HeaderColRight>
      </HeaderRowMb>
      <HeaderRow>
        <HeaderColLeft>
          <ExVisible visible={!isSelected}>
            <ApplicantListFilter listId={listId} />
          </ExVisible>
          <ExVisible visible={isSelected}>
            <ApplicantBulkActions listId={listId} jobId={jobId} />
            <ApplicantListSelectedBarStyled listId={listId} />
          </ExVisible>
        </HeaderColLeft>
        <HeaderColRight>
          <ApplicantViewActionButtons />
        </HeaderColRight>
      </HeaderRow>
    </ApplicantListHeaderV2Styled>
  );
};
