import { Action } from 'redux';
import { all } from 'redux-saga/effects';

import { createPutActions } from 'modules/LoaderManager/utils';

import { loaderManagerActions } from './redux';

/**
 * Start loaders for one or multiple actions
 *
 * @param {(Action<string> | Array<Action<string>>)} action
 */
export function* startLoader(action: Action<string> | Array<Action<string>>) {
  /**
   * Call helper function for prepare array of loaders puts effects
   */
  const actions = createPutActions(action, loaderManagerActions.start);

  yield all(actions);
}

/**
 * Stop loaders for one or multiple actions
 *
 * @param {(Action<string> | Array<Action<string>>)} action
 */
export function* stopLoader(action: Action<string> | Array<Action<string>>) {
  /**
   * Call helper function for prepare array of loaders puts effects
   */
  const actions = createPutActions(action, loaderManagerActions.stop);

  yield all(actions);
}
