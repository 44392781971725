import styled, { css } from 'styled-components/macro';

import { PUBLIC_PAGE_MAX_WIDTH, PUBLIC_PAGE_MIN_WIDTH } from 'pages/public/components/PublicPageStyles';

import { Form } from 'components/Form';
import { ExButton } from 'components/ui/ExButton';
import { ExCard } from 'components/ui/ExCard';

export const PublicNewApplicantFormStyled = styled(Form)`
  > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  input.form-control.is-invalid,
  textarea.form-control.is-invalid {
    /* bootstrap styles */
    padding: 0.55rem 0.8125rem !important;
  }

  @media (min-width: ${PUBLIC_PAGE_MIN_WIDTH}) {
    > * {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
`;

export const PublicNewApplicantFormResumeSection = styled(ExCard)`
  padding: 24px;
  gap: 32px;
  background: #ffffff;
  border: 1px solid #0b78ff;
  border-radius: 8px;
`;

export const PublicNewApplicantFormResumeSectionTitleStyled = styled.div`
  display: flex;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #404968;
`;

export const PublicNewApplicantFormResumeSectionSubTitleStyled = styled.div`
  display: flex;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #7d89b0;
`;

export const PublicNewApplicantFormResumeSectionTitleWrapperStyled = styled.div`
  ${PublicNewApplicantFormResumeSectionSubTitleStyled} {
    margin-top: 8px;
  }
`;

export const PublicNewApplicantFormSection = styled(ExCard)`
  padding: 32px 24px;
  gap: 32px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 2px rgba(93, 107, 152, 0.02);
  border-radius: 8px;

  @media (min-width: ${PUBLIC_PAGE_MIN_WIDTH}) {
    padding: 32px;
  }
`;

export const PublicNewApplicantFormSectionTitleStyled = styled.div`
  display: flex;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  color: #404968;
`;

export const PublicNewApplicantFormSectionSubTitleStyled = styled.div`
  display: flex;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #7d89b0;
`;

export const PublicNewApplicantFormSectionTitleWrapperStyled = styled.div`
  margin-bottom: 32px;

  ${PublicNewApplicantFormSectionSubTitleStyled} {
    margin-top: 8px;
  }
`;

export const PublicCandidateDetailsFormBodyV2Container = styled.div`
  & .form-group {
    margin-bottom: 0;
  }

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }

  label {
    margin-bottom: 8px;
    min-height: 20px;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    color: #404968;
  }
`;

export const AvaliabilityContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;

  @media (max-width: ${PUBLIC_PAGE_MAX_WIDTH}) {
    & > *:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

const baseButtonStyles = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border-radius: 80px;
  white-space: nowrap;
`;

export const UploadParseResumeButton = styled(ExButton)`
  ${baseButtonStyles};

  display: flex;
  place-content: center;
  place-items: center;
  margin-top: 20px;
  padding: 8px 24px;
  gap: 8px;
  width: 100%;
  height: 48px;

  @media (min-width: ${PUBLIC_PAGE_MIN_WIDTH}) {
    margin-top: 0;
    margin-left: auto;
    max-width: 190px;
  }
`;

export const OptionalBadgeStyled = styled.span`
  display: inline-block;
  vertical-align: top;

  max-height: 20px;
  margin-left: 8px;
  padding: 2px 8px;
  gap: 8px;

  background: #f9f9fb;
  border-radius: 4px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  color: #5d6b98;
`;

const CoverLetterSwitchContainerActiveStyles = ({ isActive }) =>
  isActive &&
  css`
    margin-bottom: 32px;
  `;

export const CoverLetterSwitchContainer = styled.div`
  display: flex;
  align-items: stretch;

  ${CoverLetterSwitchContainerActiveStyles}
`;

const CoverLetterSwitchButtonActiveStyles = ({ isActive }) =>
  isActive &&
  css`
    background: #f5faff;
    color: #0b78ff;
  `;

export const CoverLetterSwitchButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 8px;
  gap: 10px;

  width: 100%;
  max-width: 180px;
  min-height: 40px;

  background: #ffffff;

  border: 1px solid rgba(0, 0, 0, 0.08);

  &:first-child {
    border-radius: 8px 0px 0px 8px;
  }

  &:last-child {
    border-radius: 0px 8px 8px 0px;
  }

  cursor: pointer;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  color: #404968;

  ${CoverLetterSwitchButtonActiveStyles}

  svg {
    path {
      fill: currentColor;
    }
  }

  @media (max-width: 440px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const ApplicantFormFooterSection = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${PUBLIC_PAGE_MIN_WIDTH}) {
    margin-top: 40px;
    flex-direction: row;
    padding: 0 16px;
  }
`;

export const ApplicantFormFooterAction = styled.div`
  margin-bottom: 24px;

  @media (min-width: ${PUBLIC_PAGE_MIN_WIDTH}) {
    order: 2;
    margin-bottom: 0;
  }
`;

export const ApplicantFormFooterActionButton = styled(ExButton)`
  ${baseButtonStyles};
  height: 56px;
  padding: 18px 32px;
`;

export const ApplicantFormFooterText = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 20px;

  @media (min-width: ${PUBLIC_PAGE_MIN_WIDTH}) {
    text-align: left;
    order: 1;
    flex-grow: 1;
    margin-right: 24px;
  }
`;

export const PublicResultBackButton = styled(ExButton)`
  ${baseButtonStyles};
  height: 40px;
  padding: 10px 24px;
`;
