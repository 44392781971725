import { call, fork, race, select, take, takeEvery } from 'redux-saga/effects';

import { modalsActions, modalsSelectors } from 'containers/Modals/store';

import { invokeExModalWizard, prepareExModalChannel } from 'utils/sagas';

import * as fromCandidateActions from 'store/entities/candidates/candidate.actions';
import { talentPoolSelectors } from 'store/entities/talent-pools';
import { createTalentPool } from 'store/entities/talent-pools/talent-pool.actions';
import { ModalWizardPageNames } from 'store/modals/config';
import { exModalHideAction, wizardBackward } from 'store/modals/modals.actions';
import { modalWizardPageById } from 'store/modals/modals.selectors';

import { candidateFormCreateStep, newTalentPoolFormCreateStep } from './utils';

function* createCandidateSaga() {
  const { modalId, sagaChannel } = yield prepareExModalChannel();
  const title = 'Create new Candidate';

  const currentTalentPoolId: ReturnType<typeof modalsSelectors.modalTalentPoolId> = yield select(
    modalsSelectors.modalTalentPoolId,
  );
  const currentTalentPool: ReturnType<typeof talentPoolSelectors.getById> = yield select(
    talentPoolSelectors.getById,
    currentTalentPoolId,
  );

  yield fork(invokeExModalWizard, {
    modalId,
    channel: sagaChannel,
    modalConfig: {
      wizardType: 'newCandidate',
      page: 'candidateForm',
      content: { title, withTitle: true },
    },
    modalProps: {
      formData: {
        talentPools: [...(currentTalentPool ? [currentTalentPool] : [])],
      },
    },
  });

  while (sagaChannel) {
    const raceResult = yield race({
      newTalentPool: take(createTalentPool),
      createCandidateFormData: take(fromCandidateActions.candidateCreate),
      newTalentPoolSuccessAction: take(modalsActions.saveResult),
      hideModal: take(exModalHideAction),
      sagaChannel: take(sagaChannel),
      back: take(wizardBackward),
    });

    if (raceResult?.sagaChannel?.cancel) {
      return;
    }

    const page: ModalWizardPageNames<'newCandidate'> = yield select(modalWizardPageById, modalId);

    switch (page) {
      case 'candidateForm':
        const result = yield call(candidateFormCreateStep, {
          raceResult,
          modalId,
          currentTalentPoolId,
        });
        if (result.continue) {
          continue;
        }
        return;
      case 'newTalentPoolForm':
        yield call(newTalentPoolFormCreateStep, { raceResult, modalId, title });
        continue;

      default:
        return;
    }
  }
}

export function* candidateCreateWorker() {
  yield takeEvery(fromCandidateActions.candidateCreateOpenModal, createCandidateSaga);
}
