import React, { useCallback, useRef, useState } from 'react';
import { EntityId, ParametricSelector } from '@reduxjs/toolkit';
import styled from 'styled-components/macro';

import { ExCommentView } from 'model';

import { CandidateAvatar } from 'components/CandidateAvatar';
import { Ellipsis } from 'components/Ellipsis';
import { IconCloseStyled } from 'components/Icons/IconClose';
import { IconEdit } from 'components/Icons/IconEdit';
import { Spinner } from 'components/Spinner';
import { ExBadge, ExBadgeEllipsis } from 'components/ui/ExBadge';
import { ExBadgeVariant } from 'components/ui/ExBadge/ExBadgeProps';
import { ExButton } from 'components/ui/ExButton';
import { ExTooltip } from 'components/ui/ExTooltip';
import { ExVisible } from 'components/ui/ExVisible';
import { useLoaderSubscription } from 'modules/LoaderManager/react';
import { getUserInitials } from 'utils/getUserInitials';
import { useMouseHover } from 'utils/hooks/useMouseHover';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getThemeColor } from 'utils/styled/getThemeColor';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

import { applicantCommentsSelectors } from 'store/entities/applicant-comments';
import { createDirectCommentActionLoader } from 'store/entities/applicant-comments/applicant-comments.utils';
import { jobCommentsSelectors } from 'store/entities/job-comments/job-comments.selectors';
import { RootState } from 'store/rootReducer';

import { ExCommentForm } from './ExCommentForm';
import type { CommentDeleteHandler, CommentReadHandler, CommentUpdateHandler } from './ExCommentListProps';

export type ExCommentItemProps = {
  className?: string;
  onCommentRead: CommentReadHandler;
  commentId: string;
  onDelete: CommentDeleteHandler;
  selector: ParametricSelector<RootState, EntityId, ExCommentView | null>;
  onUpdate: CommentUpdateHandler;
};

export const ExCommentItemStyled = styled.div`
  display: flex;
  padding: 20px 20px 40px;
  width: 100%;
  max-width: 100%;
  position: relative;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const AvatarSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

const AvatarSectionWide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 240px;
`;

const CommentSection = styled.div`
  position: relative;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
`;

const CommentHeader = styled.div`
  font-size: ${getThemeFontSize('small')};
  color: ${getThemeColor('gray')};
  padding-bottom: 5px;
  align-self: stretch;
`;

const CommentHeaderWide = styled.div`
  font-size: ${getThemeFontSize('small')};
  color: ${getThemeColor('gray')};
  padding-top: 10px;
`;

export const CommentEditedHint = styled.i`
  display: inline-block;
  margin-left: 10px;
  color: ${getThemeColor('gray')};
  font-size: ${getThemeFontSize('small')};
`;

const FullName = styled.h5<{ isRemoved?: boolean }>`
  color: ${getThemeColor('blackHeadings')};
  font-size: ${getThemeFontSize('default')};
  font-weight: 700;
  margin-bottom: 5px;
  position: relative;
  display: flex;
  justify-content: space-between;

  > ${Ellipsis} {
    ${(props) => props.isRemoved && 'opacity: 0.5;'}
  }
`;

const CreatedOn = styled.p`
  margin: 0;
`;

const AvatarWrapper = styled.div<{ isRemoved?: boolean }>`
  width: 40px;
  height: 40px;
  ${(props) => props.isRemoved && 'opacity: 0.5;'}
`;

const CommentBody = styled.div`
  flex: 1 0 auto;
  margin-bottom: 10px;
`;

export const Comment = styled.span`
  font-family: inherit;
  padding: 0;
  white-space: pre-wrap;
  font-size: inherit;
  margin: 0;

  .user {
    background-color: #fae359;
    padding: 0.2em 0.1em;
    color: #0b78ff;
    text-align: center;
    line-height: 1;
    align-self: center;
    display: inline-block;
  }
`;

const ActionButtons = styled.div`
  position: absolute;
  top: 100%;
  display: flex;
  align-items: center;

  & > button {
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
`;

const ActionButton = styled(ExButton)`
  padding: 0.275rem 0.55rem !important;
`;

const EditForm = styled(ExCommentForm)`
  margin-bottom: 10px;
  width: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.white};
  opacity: 0.5;
  display: flex;
  place-content: center;
  place-items: center;
`;

const useExCommentItemState = ({ className, commentId, selector, onDelete, onUpdate }: ExCommentItemProps) => {
  const commentView = useAppSelector(selector, commentId);

  const [isEditing, setIsEditing] = useState(false);
  const toggleEditing = useCallback(() => {
    setIsEditing(!isEditing);
  }, [isEditing]);

  const showForm = isEditing;
  const showComment = !isEditing;

  const [isHovered, handlerMouseOver, handlerMouseLeave] = useMouseHover<HTMLDivElement>();
  const commentRef = useRef<HTMLDivElement>(null);

  const userInitials = getUserInitials(`${commentView?.userFullName || ''}`);

  const onDeleteHandler = useCallback(() => {
    onDelete(commentId);
  }, [commentId, onDelete]);

  const onUpdateHandler: CommentUpdateHandler = useCallback(
    (param) => {
      onUpdate({ ...param, commentId });
      toggleEditing();
    },
    [commentId, onUpdate, toggleEditing],
  );

  const directCommentActionLoader = createDirectCommentActionLoader({ payload: { commentId } });

  const { isLoading } = useLoaderSubscription(directCommentActionLoader);

  return {
    className,
    commentId,
    commentRef,
    createdOn: commentView?.createdOn,
    updatedHint: commentView?.updatedHint,
    isEdited: commentView?.isEdited,
    fullName: commentView?.userFullName,
    handlerMouseLeave,
    handlerMouseOver,
    isEditable: isHovered && commentView?.isEditable,
    isEditing,
    isLoading,
    isNew: !commentView?.read,
    isRemoved: commentView?.isRemoved,
    onDelete: onDeleteHandler,
    onUpdate: onUpdateHandler,
    showComment,
    showForm,
    text: commentView?.text,
    textView: commentView?.textView,
    toggleEditing,
    userAvatar: commentView?.userAvatar,
    userInitials,
  } as const;
};

export const ExCommentItem: React.FC<ExCommentItemProps> = (props) => {
  const {
    className,
    fullName,
    createdOn,
    isEdited,
    updatedHint,
    text,
    textView,
    userAvatar,
    commentRef,
    isRemoved,
    isNew,
    handlerMouseLeave,
    handlerMouseOver,
    onDelete,
    isLoading,
    isEditing,
    toggleEditing,
    onUpdate,
    showForm,
    showComment,
    isEditable,
    userInitials,
  } = useExCommentItemState(props);

  return (
    <ExCommentItemStyled
      className={className}
      ref={commentRef}
      onMouseOver={handlerMouseOver}
      onMouseLeave={handlerMouseLeave}
    >
      <AvatarSection>
        <AvatarWrapper isRemoved={isRemoved}>
          <CandidateAvatar src={userAvatar} initials={userInitials} />
        </AvatarWrapper>
      </AvatarSection>
      <ExVisible visible={showForm}>
        <CommentSection>
          <EditForm onSubmit={onUpdate} text={text} isEdit={isEditing} onCancel={toggleEditing} />
        </CommentSection>
      </ExVisible>
      <ExVisible visible={showComment}>
        <CommentSection>
          <CommentHeader>
            <FullName isRemoved={isRemoved}>
              <Ellipsis title={fullName}>{fullName}</Ellipsis>
              <ExVisible visible={isRemoved}>
                <ExBadgeEllipsis title="User Removed" variant={ExBadgeVariant.default}>
                  User Removed
                </ExBadgeEllipsis>
              </ExVisible>
              <ExVisible visible={isNew}>
                <ExBadge variant={ExBadgeVariant.primary}>NEW</ExBadge>
              </ExVisible>
            </FullName>
            <CreatedOn>{createdOn}</CreatedOn>
          </CommentHeader>
          <CommentBody>
            <Comment dangerouslySetInnerHTML={{ __html: textView ?? '' }} />
            <ExVisible visible={isEdited}>
              <ExTooltip text={updatedHint} tooltipPosition="bottom" variant="white">
                <CommentEditedHint>Edited</CommentEditedHint>
              </ExTooltip>
            </ExVisible>
          </CommentBody>
          <ExVisible visible={isEditable}>
            <ActionButtons>
              <ActionButton prependIcon={IconEdit} variant="light" onClick={toggleEditing}>
                Edit
              </ActionButton>
              <ActionButton prependIcon={IconCloseStyled} variant="light" onClick={onDelete}>
                Delete
              </ActionButton>
            </ActionButtons>
          </ExVisible>
        </CommentSection>
      </ExVisible>
      <ExVisible visible={isLoading}>
        <Overlay>
          <Spinner />
        </Overlay>
      </ExVisible>
    </ExCommentItemStyled>
  );
};

/**
 * This ExComment component is designed to be used in the ApplicantModal comments tab or other place with the lot of horizontal space
 */
export const ExCommentItemWide: React.FC<ExCommentItemProps> = (props) => {
  const { className, fullName, createdOn, text, userAvatar, userInitials } = useExCommentItemState(props);

  return (
    <ExCommentItemStyled className={className}>
      <AvatarSectionWide>
        <AvatarWrapper>
          <CandidateAvatar src={userAvatar} initials={userInitials} />
        </AvatarWrapper>
        <CommentHeaderWide>
          <FullName>
            <Ellipsis>{fullName}</Ellipsis>
          </FullName>
          <CreatedOn>{createdOn}</CreatedOn>
        </CommentHeaderWide>
      </AvatarSectionWide>
      <CommentSection>
        <CommentBody>
          <Comment>{text}</Comment>
        </CommentBody>
      </CommentSection>
    </ExCommentItemStyled>
  );
};

export type ExCommentItemWithSelectorProps = Omit<ExCommentItemProps, 'selector'>;

export const ExCommentApplicant = (props: ExCommentItemWithSelectorProps) => (
  <ExCommentItem {...props} selector={applicantCommentsSelectors.selectCommentView} />
);
export const ExCommentJob = (props: ExCommentItemWithSelectorProps) => (
  <ExCommentItem {...props} selector={jobCommentsSelectors.selectCommentView} />
);
