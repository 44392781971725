import styled, { css } from 'styled-components/macro';

import { CheckableRowStyled } from 'components/Form/CheckableRow/CheckableRowComponents';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

const checkableListItemCss = css`
  list-style: none;
  line-height: 30px;
`;

const CheckableListContent = styled.div``;

const CheckableListTitle = styled.div`
  font-size: ${getThemeFontSize('large')};
  margin-bottom: 1rem;
  font-weight: bold;
`;

type CheckableListBodyProps = {
  maxHeight?: number;
};

const CheckableListBodyMaxHeight = ({ maxHeight }: CheckableListBodyProps) => {
  const maxHeightValue = maxHeight ? `${maxHeight}px` : 'none';

  return css`
    --max-height: ${maxHeightValue};
  `;
};

const CheckableListBody = styled.ul<CheckableListBodyProps>`
  ${CheckableListBodyMaxHeight};
  padding-left: 0;
  margin-bottom: 0;
  overflow-y: auto;
  max-height: var(--max-height);
`;

const CheckableListItem = styled.li`
  ${checkableListItemCss};

  ${CheckableRowStyled} {
    padding-right: 32px;
  }
`;

const CheckableListItemAdd = styled.div`
  ${checkableListItemCss};
  padding-left: 0;
  padding-right: 32px;
  padding-top: 5px;
`;

export { CheckableListBody, CheckableListContent, CheckableListItem, CheckableListTitle, CheckableListItemAdd };
