import React from 'react';
import { SelectCallback } from 'react-bootstrap/helpers';
import { TransitionStatus } from 'react-transition-group/Transition';
import styled from 'styled-components';

import { ReactComponent as More } from 'assets/img/more.svg';

import { ActionsDropdown, DropdownProps, GlobalStyleExpedoDropdownMenu, MenuItem } from 'components/ActionsDropdown';

const ButtonToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0;

  svg {
    width: 22px;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.light};
  }
`;

export const ExActionButtonToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 32px;
  height: 28px;
  cursor: pointer;
  margin: 0;

  svg {
    width: 22px;
  }
`;

export type ExActionButtonProps = DropdownProps & {
  menuItems: MenuItem[];
  handlerAction?: SelectCallback;
  buttonId: string;
  withButton?: boolean;
};

const ExActionButton: React.FC<ExActionButtonProps> = ({
  handlerAction,
  menuItems,
  buttonId = 'dropdown',
  children,
  ...rest
}) => {
  const handlerActionInner: SelectCallback = async (eventKey, event) => {
    event.stopPropagation();
    const selectedIndex = Number(eventKey);
    const action = menuItems[selectedIndex].action;

    if (typeof action === 'function') {
      event.preventDefault();
      action(menuItems[selectedIndex].label);
    }
  };

  if (!menuItems.length) {
    return null;
  }

  return (
    <ActionsDropdown onSelect={handlerAction || handlerActionInner} menuItems={menuItems} buttonId={buttonId} {...rest}>
      {!children ? (
        <ButtonToggle>
          <More />
        </ButtonToggle>
      ) : (
        children
      )}
    </ActionsDropdown>
  );
};

const ExActionButtonWithGlobalStyleForPortal: React.FC<{
  state: TransitionStatus;
}> = ({ state, children }) => (
  <>
    <GlobalStyleExpedoDropdownMenu state={state} />
    {children}
  </>
);

const ExActionButtonTransparentToggle = styled(ExActionButton)`
  ${ExActionButtonToggle} {
    background-color: transparent;
  }
`;

export { ExActionButton, ExActionButtonWithGlobalStyleForPortal, ExActionButtonTransparentToggle };
