import { useAppRouterParams } from 'router';

import { useAppSelector } from 'utils/hooks/useSelectors';

import { jobsSelectors } from 'store/entities/jobs';

export function useJobTeamMembersForComments() {
  const { jobId } = useAppRouterParams();
  return useAppSelector(jobsSelectors.selectTeamMembersForComments, jobId);
}
