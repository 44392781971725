import React, { Dispatch, SetStateAction } from 'react';

interface ApplicantContextProps {
  loading: boolean;
  downloadLink: string | null;
  setDownloadLink: Dispatch<SetStateAction<string | null>>;
}

const initialContext = {
  downloadLink: null,
  loading: true,
  setDownloadLink: () => {
    // do nothing
  },
};

export const ApplicantContext = React.createContext<ApplicantContextProps>(initialContext);
