import { put, SagaReturnType, select } from 'redux-saga/effects';

import { getOpenJobsFunc } from 'api-endpoints/calendar';

import { calendarJobsActions } from 'containers/Calendar/state/calendar-jobs/calendar-jobs.actions';
import { calendarUiSelectors } from 'containers/Calendar/state/calendar-ui/calendar-ui.selectors';
import { CalendarEvent } from 'containers/Calendar/utils';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { invokeApiCall } from 'utils/sagas';

export function* calendarJobsFetchAction(action: ReturnType<typeof calendarJobsActions.calendarJobsFetchAction>) {
  yield startLoader(action);

  const period = yield select(calendarUiSelectors.selectViewTarget);
  const targetDate = yield select(calendarUiSelectors.selectTargetDate);

  const { data, message, errorData }: SagaReturnType<typeof getOpenJobsFunc> = yield invokeApiCall(getOpenJobsFunc, {
    params: {
      period,
      targetDate,
    },
  });

  if (!errorData && !message && data) {
    const updatedData = data.map((event) => CalendarEvent(event, 'job'));
    yield put(calendarJobsActions.setAll(updatedData));
  }

  yield stopLoader(action);
}
