import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionToggleProps, useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import styled from 'styled-components';

import { ExAccordionCollapseIconStyled } from 'components/Icons/AccordionCollapseIcon';

export const ExAccordionToggleStyled = styled.div`
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
`;

export const ExAccordionStyled = styled.div`
  width: 100%;
`;

export const ExAccordionCollapseContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
`;

export const ExAccordionCollapseContentColumn = styled(ExAccordionCollapseContent)`
  flex-direction: column;
`;

export const ExAccordionToggle: React.FC<AccordionToggleProps> = ({ className, children, eventKey }) => {
  const [panelIndex, setPanelIndex] = useState('');

  const isCurrentEventKey = panelIndex === eventKey;

  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    setPanelIndex(isCurrentEventKey ? '' : eventKey);
  });

  return (
    <ExAccordionToggleStyled className={className} onClick={decoratedOnClick}>
      {children}
      <ExAccordionCollapseIconStyled active={isCurrentEventKey} />
    </ExAccordionToggleStyled>
  );
};

export const ExAccordion = Accordion;
