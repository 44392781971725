import { createAction } from '@reduxjs/toolkit';

import { Job } from 'store/entities/jobs';

import { publicJobsSlice } from './public-jobs.reducer';

const publicJobsFetchAction = createAction<{ companyPublicUrl: string }>(`${publicJobsSlice.name}/fetch`);

const publicJobsGetAction = createAction<Pick<Job, 'jobId'>>(`${publicJobsSlice.name}/get`);

const confirmPendingJobAction = createAction<{ jobId: string; applicantId: string }>(`${publicJobsSlice.name}/confirm`);

const withdrawPendingJobAction = createAction<{ applicantId: string }>(`${publicJobsSlice.name}/withdraw`);

export const publicJobsActions = {
  ...publicJobsSlice.actions,
  publicJobsFetchAction,
  publicJobsGetAction,
  confirmPendingJobAction,
  withdrawPendingJobAction,
};
