import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';

import { isErrorHasFieldMessageTypeOfString } from 'components/Form/utils/errorHasFieldMessageTyepofString';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

export function useFormShowAlertError() {
  const dispatch = useAppDispatch();
  const { errors } = useFormContext();
  useEffect(() => {
    const errorsArr = Object.values(errors);
    if (errorsArr.length) {
      errorsArr.filter(isErrorHasFieldMessageTypeOfString).forEach((error) => {
        dispatch(alertsEffects.showError({ message: error.message }));
      });
    }
  }, [dispatch, errors]);
}
