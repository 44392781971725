import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Action } from 'redux';

import { LoaderEntity } from 'modules/LoaderManager/model';
import { loaderManagerActions, loaderManagerSelectors } from 'modules/LoaderManager/redux';
import { createLoaderEntity, getActionName } from 'modules/LoaderManager/utils';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

export function useLoaderManager(id: string) {
  const dispatch = useAppDispatch();
  const isSomethingLoading = useAppSelector(loaderManagerSelectors.selectIsSomeProcessing);
  const isLoading = useAppSelector(loaderManagerSelectors.selectIsLoadingById, id);
  const stateById = useAppSelector(loaderManagerSelectors.selectStateById, id);
  const add = useCallback(
    (loaderEntity: LoaderEntity) => dispatch(loaderManagerActions.upsertOne(loaderEntity)),
    [dispatch],
  );
  const start = useCallback(() => dispatch(loaderManagerActions.start(id)), [dispatch, id]);
  const stop = useCallback(() => dispatch(loaderManagerActions.stop(id)), [dispatch, id]);
  const clear = useCallback(() => dispatch(loaderManagerActions.removeAll()), [dispatch]);
  const reset = useCallback(() => dispatch(loaderManagerActions.reset()), [dispatch]);
  const remove = useCallback(() => dispatch(loaderManagerActions.removeOne(id)), [dispatch, id]);

  useEffect(() => {
    const loaderEntity = createLoaderEntity(id, stateById);
    add(loaderEntity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => ({
      add,
      clear,
      isLoading,
      isSomethingLoading,
      remove,
      reset,
      start,
      stop,
    }),
    [add, clear, isLoading, isSomethingLoading, remove, reset, start, stop],
  );
}

export function useLoaderSubscription(action: Action<string>) {
  const loaderEntityId = getActionName(action);
  const loaderManager = useLoaderManager(loaderEntityId);
  const actionRef = useRef(action);

  const startLoader = useCallback(() => {
    loaderManager.start();
  }, [loaderManager]);

  const stopLoader = useCallback(() => {
    loaderManager.stop();
  }, [loaderManager]);

  useEffect(() => {
    return () => {
      loaderManager.remove();
    };
    /**
     * The hack below is needed to prevent early loader releases.
     * These issues causes not so often but I think next solution is better than `setTimeout`
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { action: actionRef.current, isLoading: loaderManager.isLoading, startLoader, stopLoader } as const;
}

export function useLoaderThunkSubscription(action: Function) {
  return useLoaderSubscription({ type: action.name });
}
