/**
 * Default options for React-TinyMCE Editor.
 * Full options specification you can find by links below:
 *
 * https://www.tiny.cloud/docs/integrations/react/
 * https://www.tiny.cloud/docs/advanced/editor-control-identifiers/
 */

import { TextEditorToolbarExtensions } from 'components/Form/TextEditor/TextEditorProps';

const defaultTextEditorOptions = {
  height: 400,
  menubar: false,
  plugins: ['advlist', 'link', 'code', 'image', 'table', 'wordcount', 'lists', 'paste'],
  toolbar: 'undo redo bold italic numlist bullist alignleft aligncenter alignjustify link',
  branding: false,
  // theme: 'modern', // to use this theme we must include api key.
  relative_urls: false,
  remove_script_host: false,
  convert_urls: false,
  default_link_target: '_blank',
  link_default_protocol: 'http',
  target_list: false,
  resize: false,
  statusbar: false,
};

const contentStyle =
  '.variable{ cursor: default; background-color: #0B78FF; color: #FFF; padding: 2px; border-radius: 3px; font-weight: bold; font-style: normal; display: inline-block; }.variable.empty{ text-decoration: line-through; background-color: #EEEEEE; color: #373737; }';

const defaultJobDescriptionTextEditorOptions = {
  ...defaultTextEditorOptions,
  plugins: [...defaultTextEditorOptions.plugins, 'variables'],
  toolbar: [
    defaultTextEditorOptions.toolbar,
    '|',
    'ExMergeResponsibilities',
    'ExMergeRequirements',
  ] as TextEditorToolbarExtensions[],
  content_style: contentStyle,
  toolbar_mode: 'wrap',
};

export { defaultTextEditorOptions, defaultJobDescriptionTextEditorOptions };
