import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { useShowModal } from 'pages/Company/CompanyTabs/hooks/useShowModal';
import { CompanyInfoItemClickable } from 'pages/Company/components/CompanyInfoItem';

import { rbacConstants } from 'containers/Auth/AuthMatrix';
import { ExModalBodyStyled, ExModalBodyStyledWide } from 'containers/Modals/ModalsContent/Company/ExModalComponents';

import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { ExAccordion, ExAccordionCollapseContentColumn, ExAccordionToggle } from 'components/ui/ExAccordion';
import { ExDialogActionButton, ExDialogActionsWithPadding, ExDialogProps } from 'components/ui/ExDialog';
import { useIsJobActive } from 'utils/hooks/job';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { authSelectors } from 'store/auth/auth.selectors';
import { JobTeamMember as JobTeamMemberType } from 'store/entities/jobs';
import { jobTeamAddMemberModal, jobTeamEdit } from 'store/entities/jobs/job.actions';
import { exModalConfirmAction, exModalHideAction } from 'store/modals/modals.actions';
import { useSelector } from 'store/rootSelectors';

import { JobTeamMember } from './JobTeamMember';

type JobTeamEditModalProps = {
  className?: string;
  id: string;
  modalProps: { teamMembers: JobTeamMemberType[]; jobId: string };
} & ExDialogProps;

const JobTeamEditModalStyled = styled(ExModalBodyStyledWide)``;

const useJobTeamEditModalState = ({ id, className, modalProps }: JobTeamEditModalProps) => {
  const { clickHandler } = useShowModal();
  const dispatch = useAppDispatch();
  const { teamMembers, jobId } = modalProps;
  const activeMembers = teamMembers.filter((teamMember) => !teamMember.isRemoved);
  const removedMembers = teamMembers.filter((teamMember) => teamMember.isRemoved);
  const removedMembersExists = removedMembers.length !== 0;
  const isJobActive = useIsJobActive(jobId);
  const addNewMember = useCallback(() => dispatch(jobTeamAddMemberModal()), [dispatch]);
  const isAllowed = useSelector((state) =>
    authSelectors.isFeatureAllowed(state, { feature: rbacConstants.jobs.hiringTeamAddMember }),
  );
  const isAddButtonVisible = useMemo(() => isJobActive && isAllowed, [isJobActive, isAllowed]);

  const closeModalHandler = clickHandler(exModalHideAction({ id }));
  const confirmModalHandler = clickHandler(exModalConfirmAction({ id }));
  return {
    className,
    activeMembers,
    removedMembers,
    jobId,
    removedMembersExists,
    addNewMember,
    isJobActive,
    closeModalHandler,
    confirmModalHandler,
    isAddButtonVisible,
  };
};

export const JobTeamEditModal: React.FC<JobTeamEditModalProps> = (props) => {
  const {
    className,
    activeMembers,
    removedMembers,
    jobId,
    removedMembersExists,
    addNewMember,
    isJobActive,
    closeModalHandler,
    confirmModalHandler,
    isAddButtonVisible,
  } = useJobTeamEditModalState(props);

  return (
    <JobTeamEditModalStyled className={className}>
      <ExModalBodyStyled>
        {activeMembers.map((member) => (
          <JobTeamMember
            key={member.userId}
            member={member}
            userId={member.userId}
            jobId={jobId}
            isRemovable={isJobActive}
          />
        ))}
        {isAddButtonVisible && (
          <CompanyInfoItemClickable onClick={addNewMember}>
            <b>+ Add New Member</b>
          </CompanyInfoItemClickable>
        )}
        {removedMembersExists && (
          <ExAccordion>
            <ExAccordionToggle eventKey="hiring-team-removed-members">Removed Members&nbsp;&nbsp;</ExAccordionToggle>
            <ExAccordion.Collapse eventKey="hiring-team-removed-members">
              <ExAccordionCollapseContentColumn>
                {removedMembers.map((member) => (
                  <JobTeamMember
                    key={member.userId}
                    member={member}
                    userId={member.userId}
                    jobId={jobId}
                    isRemovable={false}
                  />
                ))}
              </ExAccordionCollapseContentColumn>
            </ExAccordion.Collapse>
          </ExAccordion>
        )}
        <ExDialogActionsWithPadding>
          <ExDialogActionButton variant="light" onClick={closeModalHandler}>
            Cancel
          </ExDialogActionButton>
          <ExLoaderHandlerWrapper action={jobTeamEdit}>
            <ExDialogActionButton variant="primary" onClick={confirmModalHandler}>
              Save
            </ExDialogActionButton>
          </ExLoaderHandlerWrapper>
        </ExDialogActionsWithPadding>
      </ExModalBodyStyled>
    </JobTeamEditModalStyled>
  );
};
