import { Columns } from 'containers/ApplicantLists/ApplicantListProps';

import { ColumnMap } from 'components/ListViewNew/ListViewProps';
import { Remaining } from 'components/Remaining';
import { TableCellDate } from 'components/TableCells/TableCellDate';

import { Applicant, SortBy } from 'store/entities/applicants';

import { ApplicantJobApplicationRow } from './ApplicantJobApplicationRow';

const columns: ColumnMap<Applicant, SortBy, Columns> = {
  [Columns.candidate]: {
    label: 'Job Name',
    order: SortBy.jobName,
    component: ({ item }) => <ApplicantJobApplicationRow applicant={item} />,
    style: { width: '56%' },
  },
  [Columns.appliedDate]: {
    label: 'Applied Date',
    order: SortBy.applied,
    component: ({ item }) => <TableCellDate dateString={item.appliedOn} />,
    style: { width: '20%' },
  },
  [Columns.deadline]: {
    label: 'End Date',
    order: SortBy.deadline,
    component: ({ item }) => <Remaining deadline={item?.deadline} badgeType="jobAd" />,
  },
};

export { columns };
