import { Action, combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';

import { publicReducers } from 'pages/public/state';

import applicantFormReducer from 'containers/ApplicantForm/ApplicantFormState';
import { formApplicantKey } from 'containers/ApplicantForm/ApplicantFormState/applicant-form.reducer';
import { authLogoutAction } from 'containers/Auth/state/auth.actions';
import { calendarReducers } from 'containers/Calendar/state/calendar.reducers';
import candidateFormReducer from 'containers/CandidateForm/CandidateFormState';
import { formCandidateKey } from 'containers/CandidateForm/CandidateFormState/candidate-form.reducer';
import { interviewTemplateEditFormSlice } from 'containers/InterviewTemplateForms';
import jobAdFormReducer from 'containers/JobAdForms/state';
import { formJobAdKey } from 'containers/JobAdForms/state/reducer';
import jobBoardFormReducer from 'containers/JobBoardsForm/state';
import { formJobBoardKey } from 'containers/JobBoardsForm/state/reducer';
import jobFormReducer from 'containers/JobForms/state';
import { formJobKey } from 'containers/JobForms/state/reducer';
import { applicantModalSlice } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/store/ApplicantModal.reducer';
import talentPoolFormReducer from 'containers/TalentPoolForms/state';
import { talentPoolFormKey } from 'containers/TalentPoolForms/state/talentPoolForms.reducer';

import { historyManagerReducer } from 'modules/HistoryManager/redux';
import { loaderManagerReducer } from 'modules/LoaderManager/redux';

import { authReducer, authReducerName } from 'store/auth';
import { uiReducers } from 'store/ui/uiReducers';

import { appFilesReducers } from './app-files/appFiles.reducer';
import { emptyApi, emptyApiGlobal, emptyApiPublic } from './entities/emptyApi';
import { entitiesReducer } from './entities/entitiesReducer';
import { interviewTemplateApi } from './entities/interview-template';
import { recentMentionsApi } from './entities/recent-mentions';
import companyReducer from './company';
import { AppFilesKeys, CompanyKeys, DictionaryKeys } from './constants';
import { dictionariesReducers } from './dictionary';
import { dsReducer, dsReducerName } from './directory-service';
import { modalsSlice } from './modals';

export const appReducers = (h: any) =>
  combineReducers({
    router: connectRouter(h),
    entities: entitiesReducer,
    ui: uiReducers,
    forms: combineReducers({
      [applicantModalSlice.name]: applicantModalSlice.reducer,
      [interviewTemplateEditFormSlice.name]: interviewTemplateEditFormSlice.reducer,
    }),
    [authReducerName]: authReducer,
    [formJobKey]: jobFormReducer,
    [formJobBoardKey]: jobBoardFormReducer,
    [formJobAdKey]: jobAdFormReducer,
    [formApplicantKey]: applicantFormReducer,
    [formCandidateKey]: candidateFormReducer,
    [talentPoolFormKey]: talentPoolFormReducer,
    [DictionaryKeys.root]: dictionariesReducers,
    [CompanyKeys.root]: companyReducer,
    [AppFilesKeys.root]: appFilesReducers,
    [modalsSlice.name]: modalsSlice.reducer,
    loaderManager: loaderManagerReducer,
    historyManager: historyManagerReducer,
    publicPage: publicReducers,
    calendar: calendarReducers,
    [recentMentionsApi.reducerPath]: recentMentionsApi.reducer,
    [interviewTemplateApi.reducerPath]: interviewTemplateApi.reducer,
    [emptyApi.reducerPath]: emptyApi.reducer,
    [emptyApiGlobal.reducerPath]: emptyApiGlobal.reducer,
    [emptyApiPublic.reducerPath]: emptyApiPublic.reducer,
    [dsReducerName]: dsReducer,
  });

export const rootReducer = (h: any) => (store: RootState | undefined, action: Action) => {
  if (authLogoutAction.match(action)) {
    store = undefined;
  }
  return appReducers(h)(store, action);
};

export interface RootState extends ReturnType<ReturnType<typeof appReducers>> {}
