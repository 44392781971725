import React, { useState } from 'react';

import { apiConfig } from 'config/apiConfig';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { rbacConstants } from 'containers/Auth/AuthMatrix';
import { RBAC, useRBAC } from 'containers/Auth/components/RBAC';
import { bulkCreateCandidateAnnNewTalentPool, bulkUploadResumes } from 'containers/BulkCreation/bulkCreation.actions';
import { BulkCreateModalFooter } from 'containers/BulkCreation/components/BulkSharedComponents';
import { useBulkDropZone } from 'containers/BulkCreation/utils';
import { ModalBodyStyled } from 'containers/Modals/ModalsContent/Wizard';
import { talentPoolFormEffects } from 'containers/TalentPoolForms/state';

import { FormCard } from 'components/FormCard';
import { FormSelect } from 'components/FormSelect';
import { ExButton } from 'components/ui/ExButton';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useReactSelectDebounceHandler } from 'utils/hooks/useReactSelectDebounce';
import { sortByName } from 'utils/list';

import { TalentPool } from 'store/entities/talent-pools/models';
import { ModalStepProps } from 'store/modals/config';
import { exModalHideAction } from 'store/modals/modals.actions';

const useBulkCreateCandidatesState = ({ id, modalProps }: ModalStepProps) => {
  const dispatch = useAppDispatch();

  const onCloseDialogHandler = () => {
    dispatch(exModalHideAction({ id }));
  };

  const [selectedTalentPool, setSelectedTalentPool] = useState<TalentPool | null>(
    modalProps?.selectedTalentPool ?? null,
  );

  const noOptionsLocationHandler = ({ inputValue }: { inputValue: string }) => {
    if (inputValue.length === 0) {
      return 'Start typing';
    }
    return 'No Options';
  };

  const loadEntitiesHandler = async (searchTerm: string) => {
    if (!searchTerm) {
      return;
    }
    const result = await dispatch(talentPoolFormEffects.searchTalentPoolByName({ searchTerm }));
    const payload = result.payload as TalentPool[];
    const isPayloadValid = Boolean(payload?.length);
    return isPayloadValid ? sortByName(payload) : [];
  };

  const debouncedLoadOptions = useReactSelectDebounceHandler({
    asyncLoader: loadEntitiesHandler,
    delay: apiConfig.debounceTime,
  });

  const isAddToTalentPoolAllowed = useRBAC(rbacConstants.candidates.addToTalentPool);

  const { files, ...dropZoneMethods } = useBulkDropZone();

  const continueClickHandler: React.MouseEventHandler = () => {
    let message = '';
    if (files.length === 0) {
      message = 'Select at least on file.';
    }
    if (!selectedTalentPool && isAddToTalentPoolAllowed) {
      message = 'Select Talent Pool.';
    }
    if (message) {
      dispatch(alertsEffects.showError({ message }));
      return;
    }

    if (selectedTalentPool || !isAddToTalentPoolAllowed) {
      dispatch(
        bulkUploadResumes({
          files,
          selectedTalentPool: selectedTalentPool ?? undefined,
        }),
      );
    }
  };

  const addNeTalentPoolClickHandler = () => {
    dispatch(bulkCreateCandidateAnnNewTalentPool({ selectedTalentPool }));
  };

  return {
    ...dropZoneMethods,
    files,
    continueClickHandler,
    loadEntitiesHandler: debouncedLoadOptions,
    selectedTalentPool,
    setSelectedTalentPool,
    onCloseDialogHandler,
    noOptionsLocationHandler,
    addNeTalentPoolClickHandler,
  };
};

export const BulkCreateCandidates: React.FC<ModalStepProps> = (props) => {
  const {
    continueClickHandler,
    loadEntitiesHandler,
    selectedTalentPool,
    setSelectedTalentPool,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
    files,
    acceptedFiles,
    getRootProps,
    onRemoveFile,
    onCloseDialogHandler,
    getInputProps,
    noOptionsLocationHandler,
    addNeTalentPoolClickHandler,
    isShowSelectedFiles,
  } = useBulkCreateCandidatesState(props);

  return (
    <ModalBodyStyled>
      <div className="d-flex">
        <RBAC feature={rbacConstants.candidates.addToTalentPool}>
          <FormCard.InputLabel>
            Select Talent Pool <FormCard.InputLabelRequired>*</FormCard.InputLabelRequired>
          </FormCard.InputLabel>
        </RBAC>
        <RBAC feature={rbacConstants.talentPools.create}>
          <ExButton className="ml-auto" variant="link" onClick={addNeTalentPoolClickHandler}>
            New Talent Pool
          </ExButton>
        </RBAC>
      </div>
      <RBAC feature={rbacConstants.candidates.addToTalentPool}>
        <FormSelect
          required
          name="talentPool"
          loadOptions={loadEntitiesHandler}
          noOptionsMessage={noOptionsLocationHandler}
          getOptionLabel={(option: TalentPool) => option.name}
          getOptionValue={(option: TalentPool) => option.id}
          defaultValue={selectedTalentPool as any}
          onChange={(selected: TalentPool) => setSelectedTalentPool(selected)}
        />
      </RBAC>
      <BulkCreateModalFooter
        {...{
          files,
          onClose: () => onCloseDialogHandler(),
          onContinue: continueClickHandler,
          getInputProps,
          isDragAccept,
          getRootProps,
          isDragActive,
          isDragReject,
          isShowSelectedFiles,
          onRemoveFile,
          isImageSelected: !!acceptedFiles[0],
          open,
        }}
      />
    </ModalBodyStyled>
  );
};
