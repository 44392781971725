import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { ExCommentApplicant } from 'components/ui/ExComments';
import { ExCommentList } from 'components/ui/ExComments/ExCommentList';
import type {
  CommentAddHandler,
  CommentDeleteHandler,
  CommentReadHandler,
  CommentUpdateHandler,
} from 'components/ui/ExComments/ExCommentListProps';
import { useLoaderSubscription } from 'modules/LoaderManager/react';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { applicantCommentsActions, applicantCommentsSelectors } from 'store/entities/applicant-comments';
import { useSelector } from 'store/rootSelectors';

import { ApplicantTabProps } from './ApplicantTabProps';

const ApplicantCommentTabStyled = styled.div`
  &.applicant-tab {
    overflow: visible;
    padding-left: 0;
    padding-right: 0;
  }
`;

const ExCommentApplicantStyled = styled(ExCommentApplicant)`
  &:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.border.borderColor};
  }
`;

const useApplicantCommentTabState = ({ className, item }: ApplicantTabProps) => {
  const dispatch = useAppDispatch();
  const commentsIds = useSelector(applicantCommentsSelectors.selectIds) as string[];
  const isHasMore = useSelector(applicantCommentsSelectors.isHasMore);

  useEffect(() => {
    const { jobId, applicantId } = item || {};
    if (applicantId && jobId) {
      dispatch(applicantCommentsActions.applicantCommentsStartWatcherTask());
    }
    return () => {
      dispatch(applicantCommentsActions.applicantCommentsStopWatcherTask());
    };
  }, [dispatch, item]);

  const onCommentAdd: CommentAddHandler = (payload) =>
    dispatch(applicantCommentsActions.applicantCommentsCreate(payload));

  const onCommentRead: CommentReadHandler = (commentId) =>
    dispatch(applicantCommentsActions.applicantCommentsRead({ comments: [commentId] }));

  const onReadAll = () =>
    dispatch(applicantCommentsActions.applicantCommentsReadAll({ jobId: item?.jobId, applicantId: item?.applicantId }));

  const onDelete: CommentDeleteHandler = useCallback(
    (commentId: string) => {
      dispatch(applicantCommentsActions.applicantCommentsRemove({ commentId }));
    },
    [dispatch],
  );

  const onUpdate: CommentUpdateHandler = useCallback(
    (payload) => {
      dispatch(applicantCommentsActions.applicantCommentsUpdate(payload));
    },
    [dispatch],
  );

  const { isLoading: isInitLoading } = useLoaderSubscription(applicantCommentsActions.applicantCommentsFetch);
  const { isLoading } = useLoaderSubscription(applicantCommentsActions.applicantCommentsFetchMore);

  const loadMoreHandler = useCallback(() => {
    dispatch(applicantCommentsActions.applicantCommentsFetchMore());
  }, [dispatch]);

  const hasMore = isInitLoading || (isHasMore && !isLoading);

  return {
    className,
    commentsIds,
    onCommentAdd,
    loadMoreHandler,
    onDelete,
    onUpdate,
    onCommentRead,
    onReadAll,
    hasMore,
    isInitLoading,
  } as const;
};

export const ApplicantCommentTab: React.FC<ApplicantTabProps> = (props) => {
  const {
    className,
    commentsIds,
    onCommentAdd,
    loadMoreHandler,
    onDelete,
    onUpdate,
    onCommentRead,
    onReadAll,
    hasMore,
    isInitLoading,
  } = useApplicantCommentTabState(props);

  return (
    <ApplicantCommentTabStyled className={className}>
      <ExCommentList
        component={ExCommentApplicantStyled}
        selector={applicantCommentsSelectors.selectCommentView}
        commentIds={commentsIds}
        onCommentAdd={onCommentAdd}
        onCommentDelete={onDelete}
        onCommentUpdate={onUpdate}
        onCommentRead={onCommentRead}
        onLoadMore={loadMoreHandler}
        onReadAll={onReadAll}
        hasMore={hasMore}
        isInitLoading={isInitLoading}
      />
    </ApplicantCommentTabStyled>
  );
};
