import type { AppView } from 'react-big-calendar';
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Filters } from 'containers/Calendar/state/calendar-ui/calendar-ui.model';

import { adapterHelper } from 'utils/reducer/adapter-helper';

const calendarUiAdapter = createEntityAdapter();

const calendarUiInitialState = calendarUiAdapter.getInitialState({
  view: 'month' as AppView,
  date: new Date().toISOString(),
  filters: { job: true, jobAd: true } as Filters,
});

const calendarUiSlice = createSlice({
  name: 'ui',
  initialState: calendarUiInitialState,
  reducers: {
    ...adapterHelper(calendarUiAdapter),
    updateState: (state, { payload }: PayloadAction<Partial<typeof calendarUiInitialState>>) => ({
      ...state,
      ...payload,
    }),
  },
});

const calendarUiReducer = calendarUiSlice.reducer;

export { calendarUiReducer, calendarUiSlice, calendarUiAdapter, calendarUiInitialState };
