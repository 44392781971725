import React from 'react';
import Table from 'react-bootstrap/Table';
import styled from 'styled-components';

import { listTableStyles } from 'components/TableList/TableListStyles';
import { TableFooterText } from 'components/ui/ExTable/ExTable.components';

import { PaginationWrapper, TableListWrapper } from './TableListComponents';
import { TableListComponents, TableListProps } from './TableListProps';

const TableWrapper = styled.div``;

const TableList: React.FC<TableListProps & { tableResponsive?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | boolean }> &
  TableListComponents = ({
  children,
  header,
  pagination,
  className,
  tableResponsive = 'lg',
  tableFooterText,
  pageCount,
}) => {
  const showPagination = Boolean(pageCount);

  return (
    <>
      <TableWrapper className={className}>
        <Table className="list-table" responsive={tableResponsive}>
          <thead>
            <tr>{header || null}</tr>
          </thead>
          <tbody>{children}</tbody>
        </Table>
      </TableWrapper>
      {showPagination && (
        <PaginationWrapper>
          {tableFooterText && <TableFooterText>{tableFooterText}</TableFooterText>}
          {pagination}
        </PaginationWrapper>
      )}
    </>
  );
};

// TableListComponents
TableList.TableListWrapper = TableListWrapper;

export default styled(TableList)`
  .list-table {
    ${listTableStyles};
  }
`;
