import React, { useContext, useEffect, useRef, useState } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { Provider } from 'react-redux';
import styled, { ThemeContext, ThemeProvider } from 'styled-components';

import { modalStyle, modalWrapperStyle, modalWrapperStyleProps } from 'containers/Modals/modals.styled';

import { DialogContent } from 'components/Dialog/DialogProps';
import { ExDialog } from 'components/ui/ExDialog/ExDialog';
import { getTranslate } from 'utils/i18utils';

import store from 'store';

export const useModal = ({ initialOpen = false, component = ExDialog, hideBackDrop = false, zIndex = 1040 }: any) => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const [result, setResult] = useState();
  const [content, setContent] = useState<Partial<DialogContent>>();
  const rootModalRef = useRef<HTMLDivElement>(document.createElement('div'));
  const modalRef = useRef<HTMLDivElement>(null);
  const resolve = useRef<Function | null>(null);
  const theme = useContext(ThemeContext);

  const closeModal = (closeModalResult: any) => {
    setResult(closeModalResult);
    setIsOpen(false);
  };

  useEffect(() => {
    const root = rootModalRef.current;
    document.body.appendChild(root);
    return () => {
      root.remove();
    };
  }, []);

  useEffect(() => {
    const modal = () =>
      render(
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Modal ref={modalRef} hideBackDrop={hideBackDrop} zIndex={zIndex}>
              {React.createElement(component, {
                content,
                onClose: closeModal,
                theme,
              })}
            </Modal>
          </ThemeProvider>
        </Provider>,
        rootModalRef.current,
      );
    if (isOpen) {
      modal();
    } else {
      unmountComponentAtNode(rootModalRef.current);
    }
  }, [component, isOpen, content, theme, hideBackDrop, zIndex]);

  useEffect(() => {
    if (!isOpen) {
      resolve.current !== null && resolve.current(result);
    }
  }, [result, isOpen]);

  const show = (props: Partial<DialogContent>): Promise<boolean | null> => {
    const showContent: Partial<DialogContent> = {
      buttonCancel: getTranslate('common.buttonCancel'),
      buttonOk: getTranslate('common.buttonOk'),
      message: getTranslate('dialogDefaults.message'),
      title: getTranslate('dialogDefaults.title'),
      ...props,
    };
    setContent(showContent);
    setIsOpen(true);
    return new Promise((res) => {
      resolve.current = res;
    });
  };

  const hide = (hideResult: boolean | null) => {
    closeModal(hideResult);
  };

  return {
    hide,
    isOpen,
    result,
    show,
  };
};

const Modal = styled.div<modalWrapperStyleProps>`
  ${modalWrapperStyle}

  > div {
    ${modalStyle}
  }
`;
