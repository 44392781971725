import React from 'react';
import styled from 'styled-components';

import { JobDetailFormBody } from 'containers/JobForms/FormBody/JobDetailFormBody/JobDetailFormBody';

import { StepComponent, StepDescription } from 'components/StepForm';
import { ExCard } from 'components/ui/ExCard';
import { getTranslate } from 'utils/i18utils';

export interface JobDetailStepProps extends StepComponent {
  className?: string;
}

const JobDetailStep: React.FC<JobDetailStepProps> = ({ className, ...rest }) => {
  return (
    <section className={className}>
      <StepDescription>
        <StepDescription.Title>Step 1: Setup Job Details</StepDescription.Title>
        <StepDescription.Description>{getTranslate('new_job.step_1.description')}</StepDescription.Description>
      </StepDescription>
      <ExCard title="Basic Information">
        <JobDetailFormBody {...rest} isStep />
      </ExCard>
    </section>
  );
};

export default styled(JobDetailStep)``;
