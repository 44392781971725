import React from 'react';
import styled from 'styled-components/macro';

import { CustomCheckbox } from 'components/Form/CustomCheckbox';
import { ExVisible } from 'components/ui/ExVisible';

import { CandidateBelongsTo } from 'store/entities/candidates/models';

type DoNotHireCheckboxProps = {
  className?: string;
  checked: boolean;
  onChange: (checked: DoNotHireCheckboxProps['checked']) => void;
  talentPoolId?: string;
  listId: string;
};

const DoNotHireCheckboxStyled = styled.div``;

const useDoNotHireCheckboxState = ({ className, checked, onChange, listId }: DoNotHireCheckboxProps) => {
  const isDoNotHireFieldVisible = listId !== CandidateBelongsTo.newApplicant;

  return {
    className,
    checked,
    onChange,
    isDoNotHireFieldVisible,
  } as const;
};

export const DoNotHireCheckbox = (props: DoNotHireCheckboxProps) => {
  const { checked, onChange, isDoNotHireFieldVisible, className } = useDoNotHireCheckboxState(props);

  return (
    <ExVisible visible={isDoNotHireFieldVisible}>
      <DoNotHireCheckboxStyled className={className}>
        <CustomCheckbox checked={checked} onChange={onChange}>
          Show do not hire candidates
        </CustomCheckbox>
      </DoNotHireCheckboxStyled>
    </ExVisible>
  );
};
