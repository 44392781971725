import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { findInRoutesMap } from 'router/routes';
import { put, takeEvery } from 'redux-saga/effects';

import { historyManagerActions } from './redux';

function* routerLocationChangeListener(action: LocationChangeAction<{ preventHistoryPush?: boolean }>) {
  const { location, isFirstRendering } = action.payload;

  const currentPath = location.search ? `${location.pathname}${location.search}` : location.pathname;
  const routeMetaData = findInRoutesMap(currentPath);

  if (!routeMetaData) {
    return;
  }

  const { routeName, preventHistoryPush } = routeMetaData;

  yield put(historyManagerActions.setCurrentRouteName(routeName));

  if (location.state?.preventHistoryPush || preventHistoryPush) {
    return;
  }

  const historyEntity = {
    location,
    routeName,
    isFirstRendering,
  };

  yield put(historyManagerActions.push(historyEntity));
}

export function* historyManagerSaga() {
  yield takeEvery(LOCATION_CHANGE, routerLocationChangeListener);
}
