import React from 'react';
import styled from 'styled-components';

import { ReactComponent as IconChevron } from 'assets/img/redesign/icons/chevron.svg';

/**
 * This weird styled component needed to solve the next StyledComponents issue -
 * https://github.com/styled-components/styled-components/issues/1198
 */
export const ExAccordionCollapseIconStyled = styled(({ active, ...props }) => <IconChevron {...props} />)`
  fill: none;
  transform: ${props => (props.active ? 'rotate(0)' : 'rotate(180deg)')};
  transition: transform 0.2s ease-out;
`;
