import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ExRoutes } from 'router/routes';

import { JobAdListNewButton } from 'containers/JobAdLists/JobAdListNewButton';

import { EmptyState } from 'components/EmptyState';
import { IconEmptyStateSuitcase } from 'components/Icons/IconEmptyStateSuitcase';
import { TableEmptyRowLoaderStyle } from 'components/TableList/TableListComponents';
import { ExButton } from 'components/ui/ExButton';
import { ExVisible } from 'components/ui/ExVisible';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getPrefixId } from 'utils/list';

import { updateJobStatusWithModalAction } from 'store/entities/jobs/job.actions';
import { jobsSelectors } from 'store/entities/jobs/selectors';

const defaultTitle = 'There are no jobs ads for this job yet';
const btnText = 'Create New Job Ads';

const noJobsAds = 'There are no Job Ads in your Job Board';

type JobAdListPlaceholderProps = {
  jobId?: string;
  listId?: string;
  draftTitle?: string;
};

const JobAdListPlaceholder: React.FC<JobAdListPlaceholderProps> = ({
  jobId,
  listId = '',
  draftTitle = 'To create Job Ads, you need to open the job first. \nOpen this job?',
}) => {
  const dispatch = useAppDispatch();

  const isJobStatusActive = useAppSelector(jobsSelectors.selectIsJobStatusOpenByJobId, jobId);
  const isJobStatusDraft = useAppSelector(jobsSelectors.selectIsJobStatusDraftByJobId, jobId);
  const job = useAppSelector(jobsSelectors.getById, jobId);
  const status = job?.status;

  const isJobBoardsPage = getPrefixId(listId) === 'jobBoardId';

  const history = useHistory();

  const onActivateHandle = useCallback(() => {
    if (jobId && isJobStatusDraft) {
      history.push(ExRoutes.jobEdit({ jobId }));

      return;
    }

    if (jobId && status !== undefined) {
      dispatch(updateJobStatusWithModalAction({ jobId, status: 'open', jobStatus: status }));
    }
  }, [dispatch, status, jobId, isJobStatusDraft, history]);

  const title = isJobStatusActive ? defaultTitle : draftTitle;

  if (isJobBoardsPage) {
    return (
      <TableEmptyRowLoaderStyle>
        <EmptyState title={noJobsAds} icon={<IconEmptyStateSuitcase />} />
      </TableEmptyRowLoaderStyle>
    );
  }

  return (
    <TableEmptyRowLoaderStyle>
      <EmptyState title={title} icon={<IconEmptyStateSuitcase />}>
        <ExVisible visible={isJobStatusActive}>
          <JobAdListNewButton btnText={btnText} jobId={jobId} />
        </ExVisible>
        <ExVisible visible={!isJobStatusActive}>
          <ExButton onClick={onActivateHandle}>Open Job</ExButton>
        </ExVisible>
      </EmptyState>
    </TableEmptyRowLoaderStyle>
  );
};

export default JobAdListPlaceholder;
