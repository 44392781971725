import styled from 'styled-components/macro';

import searchIcon from 'assets/img/search-999.svg';

import { FormInput } from 'components/FormInput';

export const SearchFormInput = styled(FormInput)`
  margin-bottom: 0;
  position: relative;

  &::after {
    position: absolute;
    content: '';
    width: 16px;
    height: 16px;
    background-image: url(${searchIcon});
    background-repeat: no-repeat;
    top: 12px;
    left: 12px;
    pointer-events: none;
  }

  .form-control {
    padding-left: 40px;
  }
`;
