import styled from 'styled-components';

export const CardAction = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  display: flex;
`;

export const CardActionLeft = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  display: flex;
`;
