import { ApplicationRole } from 'model/api-enums.constants';

import { useJob } from 'utils/hooks/job';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { authSelectors } from 'store/auth/auth.selectors';
import { Job } from 'store/entities/jobs/models';

export function useIsAuthUserCanEditJob(jobId: Job['jobId']) {
  const job = useJob(jobId);
  const hasAccess = useAppSelector(authSelectors.isUserCanEditJobSelector);

  const apiUser = useAppSelector(authSelectors.apiUser);

  const isLineManagerCanEditJob =
    apiUser?.applicationRoles === ApplicationRole.Manager && apiUser?.userId === job?.createdById;

  return {
    hasAccess: hasAccess || isLineManagerCanEditJob,
  };
}
