import React, { useEffect, useState } from 'react';
import { SubmitHandler, useFormContext, UseFormMethods } from 'react-hook-form';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { push } from 'connected-react-router';
import type { ExInterviewTemplate } from 'model';
import { ExRoutes } from 'router/routes';
import * as yup from 'yup';
import styled from 'styled-components/macro';

import type { ErrorDTO } from 'model/api-errors.constants';

import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import { modalsActions, modalsSelectors } from 'containers/Modals/store';

import { Form } from 'components';
import { DialogContent } from 'components/Dialog';
import { FormInput } from 'components/FormInput/FormInput';
import { ExButton } from 'components/ui/ExButton';
import { ExDialog } from 'components/ui/ExDialog';
import { ExVisible } from 'components/ui/ExVisible';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getTranslate } from 'utils/i18utils';

import {
  useInterviewTemplateCreateMutation,
  useInterviewTemplateQuery,
  useInterviewTemplateUpdateMutation,
} from 'store/entities/interview-template';

type InterviewTemplateSettingsModalProps = AppModalContentProps;

const questionCreateSchema = yup.object({
  name: yup.string().required().label('Name').trim(),
});

const InterviewTemplateSettingsModalStyled = styled(ExDialog)`
  padding-left: 100px;
  padding-right: 100px;

  ${Form} {
    max-width: 500px;
  }
`;

const useInterviewTemplateSettingsModalState = ({ onClose }: InterviewTemplateSettingsModalProps) => {
  const dispatch = useAppDispatch();

  const description =
    'Give this template a new unique name. Include engagement type or positions in the name to give it a clear difference.';
  const interviewTemplateId = useAppSelector(modalsSelectors.modalInterviewTemplateId);
  const isEditForm = Boolean(interviewTemplateId);
  const content: DialogContent = {
    withTitle: true,
    title: isEditForm ? 'Update Interview Template' : 'Create New Interview Template',
    buttonCancel: isEditForm ? 'Cancel' : null,
    buttonOk: isEditForm ? 'Update' : 'Create Now',
  };

  const { data: interviewTemplate } = useInterviewTemplateQuery(interviewTemplateId ?? skipToken);

  const defaultValues = {
    name: '',
    ...interviewTemplate,
  };

  const [errors, setError] = useState<UseFormMethods['errors']>();

  const [createInterviewTemplate, { isLoading: isCreating, isSuccess: isSuccessCreated }] =
    useInterviewTemplateCreateMutation();
  const [updateInterviewTemplate, { isLoading: isUpdating, isSuccess: isSuccessUpdated }] =
    useInterviewTemplateUpdateMutation();

  const isSuccess = [isSuccessCreated, isSuccessUpdated].some(Boolean);

  const isLoading = isCreating || isUpdating;

  const onSubmitHandler: SubmitHandler<ExInterviewTemplate> = async (data) => {
    try {
      if (interviewTemplateId) {
        await updateInterviewTemplate({ ...data, interviewTemplateId }).unwrap();
      } else {
        const { interviewTemplateId: createdInterviewTemplateId } = await createInterviewTemplate(data).unwrap();
        if (createdInterviewTemplateId) {
          dispatch(push(ExRoutes.interviewTemplateEdit({ interviewTemplateId: createdInterviewTemplateId })));
        }
      }
    } catch (error) {
      const errorDto = error as { data: ErrorDTO };
      errorDto.data.validationErrorCodes?.forEach((validationErrorCode) => {
        const validationErrorMsg = getTranslate(validationErrorCode, { ns: 'validationErrorCodes' });
        setError({ name: 'name', message: validationErrorMsg, type: 'manual' });
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  useEffect(() => {
    return () => {
      dispatch(modalsActions.setInterviewTemplateId({ interviewTemplateId: null }));
    };
  }, [dispatch]);

  return {
    content,
    onClose,
    description,
    defaultValues,
    onSubmitHandler,
    isLoading,
    errors,
  } as const;
};

export const InterviewTemplateSettingsModal: React.FC<InterviewTemplateSettingsModalProps> = (props) => {
  const { content, description, defaultValues, onSubmitHandler, isLoading, errors } =
    useInterviewTemplateSettingsModalState(props);

  return (
    <InterviewTemplateSettingsModalStyled content={content} onClose={props.onClose}>
      <Form defaultValues={defaultValues} onSubmit={onSubmitHandler} validationSchema={questionCreateSchema}>
        <ContextError errors={errors} />
        <div className="text-center">{description}</div>
        <FormInput className="px-2" name="name" label=" " />
        <div className="text-center">
          <ExVisible visible={!!content.buttonCancel}>
            <ExButton variant="light" className="mr-3" onClick={props.onClose}>
              {content.buttonCancel}
            </ExButton>
          </ExVisible>
          <ExButton isLoading={isLoading} disabled={isLoading} type="submit">
            {content.buttonOk}
          </ExButton>
        </div>
      </Form>
    </InterviewTemplateSettingsModalStyled>
  );
};

const ContextError = ({ errors }: UseFormMethods['errors']) => {
  const { setError } = useFormContext();

  useEffect(() => {
    if (errors) {
      setError(errors.name, { message: errors.message, type: errors.type });
    }
  }, [errors, setError]);

  return null;
};
