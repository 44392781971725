import { createSelector } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';

import {
  jobScreeningQuestionsEntityAdapter,
  jobScreeningQuestionsSlice,
} from 'store/entities/job-screening-questions/job-screening-questions.reducer';
import { screeningQuestionApi } from 'store/entities/screening-question';
import { entitiesDomain } from 'store/rootSelectors';

const domain = createSelector(entitiesDomain, (entities) => entities[jobScreeningQuestionsSlice.name]);

const jobScreeningQuestionsEntityAdapterSelectors = jobScreeningQuestionsEntityAdapter.getSelectors(domain);

const selectState = createSelector(domain, (state) => state);

const getJobScreeningQuestionsWithParams = createSelector(
  (_, jobId) => jobId,
  (jobId: string) => {
    return screeningQuestionApi.endpoints.searchJobScreeningQuestions.select({ jobId });
  },
);

const searchJobScreeningQuestionsResult = createSelector(
  (state) => state,
  getJobScreeningQuestionsWithParams,
  (state: any, selector) => {
    return selector(state);
  },
);

const searchJobScreeningQuestionsData = createSelector(searchJobScreeningQuestionsResult, (result) => {
  return result?.data;
});

const selectIsScreeningQuestionsNotChanged = createSelector(
  searchJobScreeningQuestionsData,
  selectState,
  (jobQuestions = jobScreeningQuestionsEntityAdapter.getInitialState(), formQuestions) => {
    return isEqual(jobQuestions?.ids, formQuestions?.ids) && isEqual(jobQuestions?.entities, formQuestions?.entities);
  },
);

export const jobScreeningQuestionsSelectors = {
  ...jobScreeningQuestionsEntityAdapterSelectors,
  selectState,
  selectIsScreeningQuestionsNotChanged,
};
