export const CANDIDATE_AVATAR_UPLOAD = 'candidate/avatar/upload';
export const CANDIDATE_AVATAR_REMOVE = 'candidate/avatar/remove';
export const CANDIDATE_ADD_TO_TALENT_POOL = 'candidate/addToTalentPool';
export const CANDIDATE_BULK_ADD_TO_TALENT_POOL = 'candidate/bulkAddToTalentPool';
export const CANDIDATE_CREATE = 'candidate/create';

export const CREATE_NEW_CANDIDATE_MODAL = 'candidate/create/openModal';
export const CREATE_NEW_CANDIDATE = 'candidate/create';

export const ADD_TO_TALENT_POOL_WIZARD = 'candidate/addToTalentPoolWizard';
