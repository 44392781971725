import { createApiMethod, getApiMappedFunction } from 'api-endpoints/utils';
import { WidgetsEndpoints } from 'api-endpoints/widgets/widgets.endpoints';

import { requestMapperUtil, responseMapperUtil } from 'utils/api';

import { ApplicantsPerSourceFigures } from 'store/ui/widgets/candidate-sources/candidate-sources.model';
import * as fromWidgetsRequests from 'store/ui/widgets/widgets.requests';

//--------------------------------------------------------------------------------------------------
// GET APPLICANTS PER SOURCE FIGURES
const getApplicantsPerSourceFiguresApiMethod = createApiMethod<
  fromWidgetsRequests.ApplicantsPerSourceFiguresRequestParams,
  ApplicantsPerSourceFigures
>({
  url: WidgetsEndpoints.GetApplicantsPerSourceFiguresFunc,
});

export const getApplicantsPerSourceFigures = getApiMappedFunction<
  fromWidgetsRequests.ApplicantsPerSourceFiguresRequestParams,
  ReturnType<typeof getApplicantsPerSourceFiguresApiMethod>,
  ReturnType<typeof getApplicantsPerSourceFiguresApiMethod>
>(getApplicantsPerSourceFiguresApiMethod, requestMapperUtil(), responseMapperUtil());
//--------------------------------------------------------------------------------------------------
