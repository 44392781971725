import React from 'react';
import styled from 'styled-components';

import { DialogProps } from 'components/Dialog';
import { ExDialog } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { exModalHideAction } from 'store/modals/modals.actions';
import { ExModal } from 'store/modals/modals.interfaces';

type JobAdPostModalProps = {
  className?: string;
} & DialogProps &
  ExModal;

const JobAdPostModalStyled = styled(ExDialog)``;

const ExWrappedMessage = styled.span`
  text-align: center;
  white-space: pre-wrap;
`;

const useJobAdPostModalState = ({ id, className }: JobAdPostModalProps) => {
  const dispatch = useAppDispatch();
  const closeHandler = () => dispatch(exModalHideAction({ id }));
  return {
    className,
    closeHandler,
  } as const;
};

export const JobAdPostModal: React.FC<JobAdPostModalProps> = (props) => {
  const { className, closeHandler } = useJobAdPostModalState(props);

  return (
    <JobAdPostModalStyled onClose={closeHandler} className={className} content={props.modalConfig?.content ?? {}}>
      <ExWrappedMessage>
        There’re some missing required fields, <br />
        please fill in those fields in <br />
        <strong>Position Details and Ad Settings tabs</strong>.
      </ExWrappedMessage>
    </JobAdPostModalStyled>
  );
};
