import React from 'react';
import styled from 'styled-components/macro';

import { talentPoolCandidatesApi } from 'api-endpoints/talent-pool-candidates/talent-pool-candidates.api';

import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import {
  Body,
  CandidateAvatarStyled,
  CandidatesWrapper,
  ExSizedBoxBottomStyled,
  ExSizedBoxTopStyled,
  Message,
} from 'containers/Modals/ModalsContent/TalentPool/TalentPool.styles';

import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { DialogProps } from 'components/Dialog/DialogProps';
import { ExDialog, ExDialogActionButton, ExDialogActions } from 'components/ui/ExDialog';
import { ExTooltip } from 'components/ui/ExTooltip';
import { getUserInitials } from 'utils/getUserInitials';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useNoScrollBodyWhen } from 'utils/hooks/useNoScrollBody';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { pluck } from 'utils/pluck';
import { getThemeColor } from 'utils/styled/getThemeColor';

import { candidatesListUiSlice } from 'store/ui/candidates/candidates-list/candidates-list.reducer';
import { candidatesListUISelectors } from 'store/ui/candidates/candidates-list/candidates-list.selectors';

type BulkDoNotHireCandidatesProps = AppModalContentProps;

const AddToTalentPoolStyled = styled(ExDialog)`
  width: 100%;
  margin: 0 auto;
  max-width: 550px;
  max-height: 95vh;
  ${Message},
  ${CandidatesWrapper} {
    background-color: ${getThemeColor('bodyBackgroundColor')};
  }
`;

export const BulkDoNotHireCandidates: React.FC<BulkDoNotHireCandidatesProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const selectEntities = useAppSelector(candidatesListUISelectors.selectAll);

  const content: DialogProps['content'] = {
    title: 'Do Not Hire',
    withTitle: true,
  };

  const [setDoNotHireCandidates, { isLoading }] = talentPoolCandidatesApi.useSetDoNotHireCandidatesFuncMutation();

  const onSubmitClickHandler = async () => {
    const candidateIds = selectEntities.map(pluck('candidateId'));

    await setDoNotHireCandidates({
      candidateIds,
    });

    onClose();
    dispatch(candidatesListUiSlice.actions.deselectMany(candidateIds ?? []));
  };

  useNoScrollBodyWhen(true);

  return (
    <AddToTalentPoolStyled onClose={onClose} content={content}>
      <Body>
        <Message>You are about to set "Do Not Hire" for these candidates.</Message>
        <CandidatesWrapper>
          <ExSizedBoxTopStyled height={10} />
          {selectEntities.map((item) => (
            <ExTooltip key={item.candidateId} text={item.fullName} tooltipPosition="top" variant="white">
              <div>
                <CandidateAvatarStyled src={item.photoUrl} initials={getUserInitials(item.fullName)} />
              </div>
            </ExTooltip>
          ))}
          <ExSizedBoxBottomStyled height={10} />
        </CandidatesWrapper>
        <ExDialogActions>
          <ExDialogActionButton variant="light" onClick={onClose} disabled={isLoading}>
            Cancel
          </ExDialogActionButton>
          <ExLoaderHandlerWrapper isLoading={isLoading}>
            <ExDialogActionButton className="ml-3" onClick={onSubmitClickHandler} disabled={isLoading}>
              Confirm
            </ExDialogActionButton>
          </ExLoaderHandlerWrapper>
        </ExDialogActions>
      </Body>
    </AddToTalentPoolStyled>
  );
};
