import Overlay from 'react-bootstrap/Overlay';
import { UseFormMethods } from 'react-hook-form';
import styled from 'styled-components';

import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';

const ErrorTooltip = styled.div`
  background-color: ${({ theme }) => theme.colors.danger};
  color: ${({ theme }) => theme.colors.white};
  padding: 2px 10px;
  border-radius: ${getThemeBorderProps('borderRadius')};
  z-index: 1040;
`;

type FormTooltipProps = {
  hideOverlay?: boolean;
  target: React.RefObject<HTMLElement>;
  show: boolean;
  errors?: UseFormMethods['errors'];
  offset?: [number, number];
};

export const FormTooltip: React.FC<FormTooltipProps> = ({ hideOverlay, target, show, errors, offset = [10, -24] }) => {
  if (hideOverlay) {
    return null;
  }

  return (
    <Overlay
      target={target.current}
      show={show}
      placement="top-start"
      popperConfig={{
        modifiers: [{ name: 'offset', options: { offset } }],
      }}
    >
      {({ ...props }: any) => <ErrorTooltip {...props}>{errors?.message}</ErrorTooltip>}
    </Overlay>
  );
};
