import { Action } from 'redux';
import { call, put, select } from 'redux-saga/effects';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { getTranslate } from 'utils/i18utils';

import * as fromCompanyActions from 'store/company/company.actions';
import { companySelectors } from 'store/company/company.selectors';
import type { CompanyInfo } from 'store/company/company.types';
import {
  companyUpdateConfirmModal,
  isDefaultInList,
  isListEmpty,
  makeDefaultFirstItemOrOther,
  prepareRequestData,
  updateCompanyUtil,
} from 'store/company/company.utils';
import { ModalGeneralResult, ModalResult } from 'store/modals/modals.interfaces';
import { modalSagaWorker } from 'store/modals/modals.sagas';

const successMessagePath = 'company.countries.update.success';

export function* companyUpdateCountriesSaga(action: Action) {
  /**
   * This variable needs to store modal id in case of network error to restart
   * saga with existed modal instead of rendering the new one.
   */
  let modalId;

  while (true) {
    const result: ModalGeneralResult = yield call(modalSagaWorker, {
      modalType: ModalsTypeKey.companyUpdateCountries,
      ...(modalId ? { id: modalId } : {}),
    });

    if (result.cancel || !result.confirm) {
      return;
    }

    modalId = result.confirm.payload.id!;

    const confirmPayload = result.confirm.payload as ModalResult<{ entities: CompanyInfo['countries'] }>;

    yield call(startLoader, action);

    const entities = confirmPayload.modalResult?.entities ?? [];

    const countriesListIsEmpty = yield call(isListEmpty, entities);

    /**
     * If countries list is empty will show alert and restart saga
     */
    if (countriesListIsEmpty) {
      yield put(
        alertsEffects.showWarning({
          message: getTranslate('company.countries.update.emptyCountryList'),
        }),
      );
      yield call(stopLoader, action);
      continue;
    }

    let success = false;

    const entitiesHasDefaultItem = isDefaultInList(entities, 'main');

    const requestData: CompanyInfo | null = yield call(
      prepareRequestData,
      'countries',
      {
        mapFunction: !entitiesHasDefaultItem ? makeDefaultFirstItemOrOther : undefined,
      },
      entities,
    );

    if (requestData) {
      success = yield call(updateCompanyUtil, modalId, requestData, successMessagePath, 'countries');
    }

    yield call(stopLoader, action);
    if (success) {
      break;
    }
  }
}

export function* changeDefaultCompanyCountry(
  action: ReturnType<typeof fromCompanyActions.changeDefaultCompanyCountry>,
) {
  let modalId: string | undefined;

  while (true) {
    const result: ModalGeneralResult = yield call(
      companyUpdateConfirmModal,
      modalId,
      'You confirm change Default Country?',
    );
    if (result.cancel || !result.confirm) {
      return;
    }

    yield call(startLoader, action);
    modalId = result.confirm.payload.id!;
    const { countryId } = action.payload;
    let success = false;

    const newCompanyInfo: CompanyInfo | null = yield call(prepareRequestData, 'countries', {
      mapFunction: (currency) => ({ ...currency, main: currency.countryId === countryId }),
    });

    if (newCompanyInfo) {
      success = yield call(updateCompanyUtil, modalId, newCompanyInfo, successMessagePath, 'countries');
    }
    yield call(stopLoader, action);
    if (success) {
      break;
    }
  }
}

export function* removeCompanyCountry(action: ReturnType<typeof fromCompanyActions.removeCompanyCountry>) {
  const company: ReturnType<typeof companySelectors.getCompanyInfo> = yield select(companySelectors.getCompanyInfo);
  if (!company) {
    return;
  }
  let modalId: string | undefined;

  while (true) {
    const result: ModalGeneralResult = yield call(companyUpdateConfirmModal, modalId, 'You confirm remove Country?');
    if (result.cancel || !result.confirm) {
      return;
    }
    yield call(startLoader, action);
    modalId = result.confirm.payload.id!;

    const { countryId } = action.payload;

    let success = false;

    const newCompanyInfo: CompanyInfo | null = yield call(prepareRequestData, 'countries', {
      filterFunction: (country) => country.countryId !== countryId,
    });
    if (newCompanyInfo) {
      success = yield call(updateCompanyUtil, modalId, newCompanyInfo, successMessagePath, 'countries');
    }

    yield call(stopLoader, action);
    if (success) {
      break;
    }
  }
}
