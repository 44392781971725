import uniqBy from 'lodash/uniqBy';
import { buffers, channel } from 'redux-saga';
import { all, call, fork, put, PutEffect, race, SagaReturnType, select, take } from 'redux-saga/effects';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { getTranslate } from 'utils/i18utils';
import { exDiffBy } from 'utils/list';
import { invokeExModalWizard, prepareExModalChannel, PrepareResultActions, prepareResultActions } from 'utils/sagas';

import {
  jobTeamAddMemberModal,
  jobTeamEdit,
  jobTeamFetch,
  jobTeamMemberCreate,
  jobTeamMemberPrepareCreate,
  jobTeamMemberPrepareRemove,
  jobTeamMemberProcessingFinished,
  jobTeamMemberRemove,
  jobTeamRemoveMemberModal,
} from 'store/entities/jobs/job.actions';
import { JobTeamMember } from 'store/entities/jobs/models';
import { jobsSelectors } from 'store/entities/jobs/selectors';
import { exModalHideAction, updateWizardPage, wizardBackward } from 'store/modals/modals.actions';
import { ModalGeneralResult } from 'store/modals/modals.interfaces';
import { exEmptyAction } from 'store/rootActions';

type GetResultActionFromActionPairProps = JobTeamAction | { type: string; payload: undefined };
/**
 * This method choose which action we must invoke.
 *
 * @param {JobTeamAction} firstAction
 * @param {JobTeamAction} lastAction
 * @returns {JobTeamAction | {type: string, payload: undefined}}
 */
function getResultActionFromActionPair(
  firstAction: JobTeamAction,
  lastAction: JobTeamAction,
): GetResultActionFromActionPairProps {
  switch (true) {
    // `create` - `remove` - do nothing (emptyAction);
    case jobTeamMemberCreate.match(firstAction) && jobTeamMemberRemove.match(lastAction): {
      return exEmptyAction();
    }
    // !`create` - `any action` - `any action` with the most actual payload;
    case !jobTeamMemberCreate.match(firstAction): {
      return lastAction;
    }
    // actions are equal - `lastAction` with the most actual payload;
    case firstAction.type === lastAction.type: {
      return lastAction;
    }
    default: {
      return exEmptyAction();
    }
  }
}

/**
 * Function forms the object consist of two array:
```
{
    resultActions: 'actions array on top of decision of `getResultActionFromActionPair` function',
    resultActionsOfStageActions: `actions array related to hiringPipelineStageActions`
}
```
 * @param {EditSagaActionsMap} groupedActions
 * @returns
 */
function getResultActions(groupedActions: JobTeamActionMap) {
  const resultActions: Array<PutEffect<JobTeamAction | { type: string }>> = [];

  // Filter incoming actions leave only remove, create, update related to Pipeline Stage
  const stageActions = Array.from(groupedActions, (item) => item[1]).map((actions) =>
    actions.filter((action) => [jobTeamMemberCreate.type, jobTeamMemberRemove.type].includes(action.type)),
  );

  stageActions.forEach((editSagaActions) => {
    const firstAction = editSagaActions[0];
    const lastAction = editSagaActions[editSagaActions.length - 1];
    const resultAction = getResultActionFromActionPair(firstAction, lastAction);

    // Filter empty actions;
    if (exEmptyAction.match(resultAction)) {
      return;
    }

    resultActions.push(put(resultAction));
  });

  return resultActions;
}

type JobTeamAction = { type: string; payload: { jobId: string; userId: string } };
type JobTeamActionMap = Map<string, Array<JobTeamAction>>;
/**
 * Group pipeline actions by id.
 * In this method we use Map instead of object because Map avoids check of key exists.
 *
 * @param {JobTeamActionMap} acc
 * @param {JobTeamAction} action
 * @returns {JobTeamActionMap}
 */
export function groupJobTeamActionsById(acc: JobTeamActionMap, action: JobTeamAction): JobTeamActionMap {
  const groupedActions = acc.get(action.payload.userId) || [];
  acc.set(action.payload.userId, [...groupedActions, action]);

  return acc;
}

type GoToStepWithModalIdProps = { modalId: string };

function goToJobAddMemberStage({ modalId }: GoToStepWithModalIdProps) {
  return function ({ jobId, teamMembers }: { jobId: string; teamMembers: JobTeamMember[] }) {
    return updateWizardPage({
      id: modalId,
      modalConfig: {
        content: {
          title: 'Add New Members',
          withTitle: true,
        },
      },
      modalProps: { jobId, teamMembers },
      page: 'jobTeamAddMember',
    });
  };
}

type RemoveMemberStage = {
  teamMember: JobTeamMember;
  jobId: string;
};

function goToJobRemoveMemberStage({ modalId }: GoToStepWithModalIdProps) {
  return function ({ teamMember, jobId }: RemoveMemberStage) {
    return updateWizardPage({
      id: modalId,
      modalConfig: {
        content: {
          title: 'Confirm Action',
          withTitle: true,
        },
      },
      modalProps: { jobId, teamMember },
      page: 'jobTeamRemoveMember',
    });
  };
}

function goToJobTeamEditStage({ modalId }: GoToStepWithModalIdProps) {
  return function ({ jobId, teamMembers }: { jobId: string; teamMembers: JobTeamMember[] }) {
    return updateWizardPage({
      id: modalId,
      modalConfig: {
        content: {
          title: 'Hiring Team',
          withTitle: true,
        },
      },
      modalProps: { jobId, teamMembers },
      page: 'jobTeamView',
    });
  };
}

function* jobTeamEditWorkerAddMemberModal({
  teamMembersTemporary,
  modalId,
  jobId,
  actionsChannel,
  goToJobTeamEditStageWithModalId,
}) {
  // ADD NEW MEMBER MODAL FLOW

  /**
   * To prevent unnecessary members in add modal we must physically remove `isRemoved` member from the array;
   */
  const teamMembersActive = teamMembersTemporary.filter(({ isRemoved }) => !isRemoved);
  const goToJobAddMemberStageWithModalId = goToJobAddMemberStage({ modalId });

  yield put(goToJobAddMemberStageWithModalId({ jobId, teamMembers: teamMembersActive }));

  const { prepareCreate }: { prepareCreate: ReturnType<typeof jobTeamMemberPrepareCreate> } = yield race({
    back: take(wizardBackward),
    prepareCreate: take(jobTeamMemberPrepareCreate),
  });

  /**
   * If user have added the new team to the member we do the next:
   *
   * 1. Create an array of members are not existed in the `prepareCreate`
   * 2. Create an array of members are added.
   * 3. Mark members from stage 1 as removed;
   * 4. Merge members form `prepareCreate` with existed teamMembersTemporary;
   * 5. Put the related action of stage 1 to the action channel;
   * 6. Put the related action of stage 2 to the action channel;
   * 7. Mark members from 2 stage as added;
   */
  if (prepareCreate) {
    const teamMembersForCreate = prepareCreate.payload.teamMembers;
    const teamMembersTemporaryActive = teamMembersTemporary.filter(
      (teamMemberTemporary) => !teamMemberTemporary.isRemoved,
    );

    // 1.
    const removedMembers = exDiffBy(teamMembersTemporaryActive, teamMembersForCreate, 'id');

    // 2.
    const addedMembers = exDiffBy(teamMembersForCreate, teamMembersTemporaryActive, 'id');

    // 3.
    teamMembersTemporary = teamMembersTemporary.map((temporaryMember) => {
      const isInRemoved = removedMembers.some((removedMember) => removedMember.id === temporaryMember.id);
      if (isInRemoved) {
        return {
          ...temporaryMember,
          isRemoved: true,
        };
      }
      return temporaryMember;
    });

    // 4.
    teamMembersTemporary = uniqBy([...teamMembersTemporary, ...teamMembersForCreate], 'id');

    // 5.
    removedMembers.forEach((removedMember) => {
      actionsChannel.put(jobTeamMemberRemove({ jobId, userId: removedMember.userId }));
    });

    // 6.
    addedMembers.forEach((removedMember) => {
      actionsChannel.put(jobTeamMemberCreate({ jobId, userId: removedMember.userId }));
    });

    // 7.
    teamMembersTemporary = teamMembersTemporary.map((temporaryMember) => {
      const isInRemoved = addedMembers.some((removedMember) => removedMember.id === temporaryMember.id);
      if (isInRemoved) {
        return {
          ...temporaryMember,
          isRemoved: false,
        };
      }
      return temporaryMember;
    });
  }

  // Change modal wizard page to the Job Edit Modal view
  yield put(goToJobTeamEditStageWithModalId({ jobId, teamMembers: teamMembersTemporary }));

  return teamMembersTemporary;
}

const prepareTeamMembersTemporary = (teamMemberTemporary, userIdForRemove) => {
  const id = teamMemberTemporary.userId ?? teamMemberTemporary.id;
  if (id === userIdForRemove) {
    return {
      ...teamMemberTemporary,
      isRemoved: true,
    };
  }

  return teamMemberTemporary;
};

export function* jobTeamEditWorker(action: ReturnType<typeof jobTeamEdit>) {
  const { jobId } = action.payload;
  const { modalId, sagaChannel } = yield prepareExModalChannel();

  const teamMembers: JobTeamMember[] = yield select(jobsSelectors.selectTeamMembersNotAdmins, jobId);

  let teamMembersTemporary = [...teamMembers];

  yield fork(invokeExModalWizard, {
    channel: sagaChannel,
    modalConfig: {
      content: {
        title: 'Hiring Team',
        withTitle: true,
      },
      page: 'jobTeamView',
      wizardType: 'jobTeamEdit',
    },
    modalId,
    modalProps: { jobId, teamMembers },
  });

  // Prepare channel and buffer for actions
  const actionsChannelBuffer = buffers.expanding();
  const actionsChannel = yield channel(actionsChannelBuffer);

  // Run the main saga flow
  while (true) {
    /**
     * Wait for action from user. Result - is the `Cancel` or `Save` button reaction.
     */
    const { jobTeamRemoveMemberModalResult, jobTeamAddMemberModalResult, result } = yield race({
      jobTeamAddMemberModalResult: take(jobTeamAddMemberModal),
      jobTeamRemoveMemberModalResult: take(jobTeamRemoveMemberModal),
      result: take(sagaChannel),
    });

    const goToJobRemoveMemberStageWithModalId = goToJobRemoveMemberStage({ modalId });
    const goToJobTeamEditStageWithModalId = goToJobTeamEditStage({ modalId });

    switch (true) {
      case Boolean(jobTeamAddMemberModalResult):
        teamMembersTemporary = yield call(jobTeamEditWorkerAddMemberModal, {
          teamMembersTemporary,
          modalId,
          jobId,
          actionsChannel,
          goToJobTeamEditStageWithModalId,
        });
        continue;

      case Boolean(jobTeamRemoveMemberModalResult):
        // REMOVE MEMBER MODAL FLOW
        const { userId } = jobTeamRemoveMemberModalResult.payload;
        const teamMember = teamMembersTemporary.find((teamMemberTemp) => teamMemberTemp.userId === userId);

        if (!teamMember) {
          continue;
        }
        yield put(goToJobRemoveMemberStageWithModalId({ jobId, teamMember }));

        // Wait for user interaction
        const { prepareRemove }: { prepareRemove: ReturnType<typeof jobTeamMemberPrepareRemove> } = yield race({
          back: take(wizardBackward),
          prepareRemove: take(jobTeamMemberPrepareRemove),
        });

        /**
         * If user have removed job team member we do the next:
         *
         * 1. Remove teamMember from teamMemberTemporary;
         * 2. Put the related action into the action channel;
         */
        if (prepareRemove) {
          const userIdForRemove = prepareRemove.payload.userId;

          // 1.
          teamMembersTemporary = teamMembersTemporary.map((teamMemberTemporary) => {
            return prepareTeamMembersTemporary(teamMemberTemporary, userIdForRemove);
          });

          // 2.
          actionsChannel.put(jobTeamMemberRemove(prepareRemove.payload));
        }

        // Change modal wizard page to the Job Edit Modal view
        yield put(goToJobTeamEditStageWithModalId({ jobId, teamMembers: teamMembersTemporary }));

        continue;

      case Boolean(result):
        //  If user click `Cancel` of `Save` button - break the switch and resume saga flow.
        break;
      default:
        break;
    }

    const { confirm, cancel }: ModalGeneralResult = result;
    // If user click `Cancel` button - close the modal window and break the saga flow.
    if ([cancel, !confirm].some(Boolean)) {
      yield put(exModalHideAction({ id: modalId }));
      return;
    }

    yield call(startLoader, action);

    // Take all actions from the actionsChannel
    // Group actions by id and decide which action must be invoked.
    const resultActions: SagaReturnType<PrepareResultActions<JobTeamAction>> = yield call(
      prepareResultActions,
      actionsChannel,
      groupJobTeamActionsById,
      getResultActions,
    );

    yield all(resultActions);

    const resultActionsLength = resultActions.length;

    // Waiting for all actions are running in the background;
    for (let i = 0; i < resultActionsLength; i++) {
      yield take(jobTeamMemberProcessingFinished);
    }

    yield all([
      put(exModalHideAction({ id: modalId })),
      call(stopLoader, action),
      put(jobTeamFetch({ urlParams: { jobId } })),
      put(alertsEffects.showSuccess({ message: getTranslate('job.team.update.success') })),
    ]);

    // The very end of the saga
    return;
  }
}
