import { createEntityAdapter } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import type { ExInterviewTemplate, ExInterviewTemplateListItem } from 'model';

import {
  axiosBaseQuery,
  EntityStateWithPagination,
  providesList,
  transformListResponse,
} from 'api-client/axiosBaseQuery';
import type { ExAxiosBaseQuery } from 'api-client/axiosBaseQueryTypes';
import { replaceParamsInUrl } from 'api-client/utils';
import {
  InterviewTemplateEndpoints,
  SearchInterviewTemplatesFuncRequestParams,
  UpdateInterviewTemplateFuncRequestParams,
} from 'api-endpoints/interview-template';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';

import { orderDirectionWithSpace } from 'utils/api';
import { getSuccessToastForEntityAction } from 'utils/i18utils';

import { RootState } from 'store/rootReducer';
import { companyInterviewTemplateListUiSelectors } from 'store/ui/company/company-interview-template-list';

export const INTERVIEW_TEMPLATE_TAG_TYPE = 'InterviewTemplate';

const interviewTemplateAdapter = createEntityAdapter<ExInterviewTemplateListItem>({
  selectId: (item) => item.interviewTemplateId,
});

const interviewTemplateListQuery = ({
  orderBy,
  orderDirection,
  ...args
}: SearchInterviewTemplatesFuncRequestParams) => ({
  url: replaceParamsInUrl(InterviewTemplateEndpoints.SearchInterviewTemplatesFunc, {}),
  method: 'GET',
  params: {
    ...args,
    ...orderDirectionWithSpace({ newSort: { orderBy, orderDirection } }),
  },
});

export const interviewTemplateApi = createApi({
  reducerPath: 'interview-template',
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/company/{companyId}',
  }),
  tagTypes: [INTERVIEW_TEMPLATE_TAG_TYPE],
  endpoints: (build) => ({
    interviewTemplateList: build.query<
      EntityStateWithPagination<ExInterviewTemplateListItem>,
      SearchInterviewTemplatesFuncRequestParams
    >({
      query: interviewTemplateListQuery,
      transformResponse: transformListResponse(interviewTemplateAdapter),
      providesTags: (data) => providesList(data?.ids, INTERVIEW_TEMPLATE_TAG_TYPE),
    }),
    interviewTemplateListLoadMore: build.query<
      EntityStateWithPagination<ExInterviewTemplateListItem>,
      SearchInterviewTemplatesFuncRequestParams
    >({
      query: interviewTemplateListQuery,
      onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            interviewTemplateApi.util.updateQueryData(
              'interviewTemplateListLoadMore',
              { searchTerm: arg.searchTerm, pageNo: 0, state: arg.state, pageSize: arg.pageSize },
              (draft) => {
                interviewTemplateAdapter.upsertMany(
                  draft,
                  data.ids.map((id) => data.entities[id]!),
                );
                draft.pageCount = data.pageCount;
              },
            ),
          );
        } catch (error) {}
      },
      transformResponse: transformListResponse(interviewTemplateAdapter),
      providesTags: (data) => providesList(data?.ids, INTERVIEW_TEMPLATE_TAG_TYPE),
    }),
    interviewTemplate: build.query<ExInterviewTemplate, ExInterviewTemplate['interviewTemplateId']>({
      query: (interviewTemplateId) => ({
        url: replaceParamsInUrl(InterviewTemplateEndpoints.GetInterviewTemplateFunc, { interviewTemplateId }),
        method: 'GET',
      }),
      providesTags: (_, __, id) => [{ type: INTERVIEW_TEMPLATE_TAG_TYPE, id }],
    }),
    interviewTemplateCreate: build.mutation<
      Pick<ExInterviewTemplate, 'interviewTemplateId'>,
      Omit<ExInterviewTemplate, 'interviewTemplateId'>
    >({
      queryFn: (arg, _, __, baseQuery) => {
        return baseQuery({
          url: replaceParamsInUrl(InterviewTemplateEndpoints.CreateInterviewTemplateFunc, {}),
          method: 'POST',
          data: arg,
        }) as ExAxiosBaseQuery<ExInterviewTemplate>;
      },
      invalidatesTags: (_, error) => {
        if (error) {
          return [];
        }

        return [{ type: INTERVIEW_TEMPLATE_TAG_TYPE, id: 'LIST' }];
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          const message = getSuccessToastForEntityAction({
            name: arg.name,
            action: 'created',
            entityName: 'interviewTemplate',
          });
          dispatch(alertsEffects.showSuccess({ message }));
        } catch (error) {}
      },
    }),
    interviewTemplateUpdate: build.mutation<void, UpdateInterviewTemplateFuncRequestParams>({
      query: (arg) => ({
        url: replaceParamsInUrl(InterviewTemplateEndpoints.UpdateInterviewTemplateFunc, {
          interviewTemplateId: arg.interviewTemplateId,
        }),
        method: 'PUT',
        data: arg,
      }),
      invalidatesTags: (_, error, arg) =>
        !error ? [{ type: INTERVIEW_TEMPLATE_TAG_TYPE, id: arg.interviewTemplateId }] : [],
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const patches = dispatch(
          interviewTemplateApi.util.updateQueryData('interviewTemplate', arg.interviewTemplateId, (draft) =>
            Object.assign(draft, arg),
          ),
        );
        try {
          await queryFulfilled;
          const message = getSuccessToastForEntityAction({
            name: arg.name,
            action: 'updated',
            entityName: 'interviewTemplate',
          });
          dispatch(alertsEffects.showSuccess({ message }));
        } catch (error) {
          patches.undo();
        }
      },
    }),
    interviewTemplateDelete: build.mutation<void, ExInterviewTemplateListItem>({
      query: ({ interviewTemplateId }) => ({
        url: replaceParamsInUrl(InterviewTemplateEndpoints.DeleteInterviewTemplateFunc, { interviewTemplateId }),
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { interviewTemplateId }) => [
        { type: INTERVIEW_TEMPLATE_TAG_TYPE, id: interviewTemplateId },
      ],
      onQueryStarted: async ({ name, interviewTemplateId }, { dispatch, queryFulfilled, getState }) => {
        const filters = companyInterviewTemplateListUiSelectors.filters(getState() as RootState);
        const patches = dispatch(
          interviewTemplateApi.util.updateQueryData('interviewTemplateList', filters, (draft) => {
            interviewTemplateAdapter.removeOne(draft, interviewTemplateId);
          }),
        );

        try {
          await queryFulfilled;
          const message = getSuccessToastForEntityAction({
            name,
            action: 'deleted',
            entityName: 'interviewTemplate',
          });
          dispatch(alertsEffects.showSuccess({ message }));
        } catch (error) {
          patches.undo();
        }
      },
    }),
  }),
});

export const {
  useInterviewTemplateListQuery,

  useLazyInterviewTemplateListQuery,
  useInterviewTemplateListLoadMoreQuery,
  useLazyInterviewTemplateListLoadMoreQuery,
  useInterviewTemplateQuery,
  useInterviewTemplateCreateMutation,
  useInterviewTemplateUpdateMutation,
  useInterviewTemplateDeleteMutation,
} = interviewTemplateApi;
