import * as React from 'react';
import styled, { css } from 'styled-components';

interface PaginationProps {
  className?: string;
}

interface PaginationItemProps {
  className?: string;
  active: boolean;
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  onMouseEnter: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const Item: React.FC<PaginationItemProps> = ({ className, children, onClick, onMouseEnter }) => {
  return (
    <li className={className}>
      <a role="button" onClick={onClick} onMouseEnter={onMouseEnter} href="#pagination" className="pagination-link">
        {children}
      </a>
    </li>
  );
};

const PaginationItem = styled(Item)<{ disabled?: boolean }>`
  border: none;
  background-color: transparent;
  height: 34px;
  ${(props) => props.disabled && 'pointer-events: none'}

  .pagination-link {
    position: relative;
    display: block;
    padding: 8px 13px 9px;
    margin-left: -1px;
    line-height: 1.25;
    height: 100%;
    color: ${(props) => (props.active ? props.theme.colors.primary : props.theme.colors.gray)};
    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};

    &:hover {
      text-decoration: none;
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

const PaginationIconCss = css<{ disabled?: boolean }>`
  pointer-events: none;
  position: absolute;
  top: 14px;
  right: 12px;
  content: '';
  width: 8px;
  height: 8px;
  border-style: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  border-color: ${(props) => (props.disabled ? props.theme.colors.gray : props.theme.colors.primary)};
`;

const PaginationNext = styled(PaginationItem)`
  width: 36px;
  position: relative;

  &::after {
    ${PaginationIconCss};
    transform: rotate(-45deg);
  }
`;

const PaginationPrev = styled(PaginationItem)`
  position: relative;
  width: 36px;

  &::after {
    ${PaginationIconCss};
    transform: rotate(-225deg);
  }
`;

class Pagination extends React.Component<PaginationProps> {
  static Item = PaginationItem;
  static Prev = PaginationPrev;
  static Next = PaginationNext;
  render() {
    return <ul className={this.props.className}>{this.props.children}</ul>;
  }
}

export default styled(Pagination)`
  display: inline-flex;
  flex-direction: row;
  list-style: none;
  border: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  align-self: flex-start;
  background: #fff;
  z-index: 1;
`;
