import React, { HtmlHTMLAttributes } from 'react';
import styled, { css } from 'styled-components/macro';

type FormRadioSwitchProps = {
  className?: string;
  id: string;
  name: string;
  value?: string;
  checked?: boolean;
  label: string;
  disabled?: boolean;
  slotAfterLabel?: any;
  onChange?: HtmlHTMLAttributes<HTMLInputElement>['onChange'];
  labelClassName?: string;
};

const FormRadioSwitchStyled = styled.div`
  display: flex;
`;

type FormRadioLabelStyledProps = {
  $disabled?: boolean;
};

const FormRadioLabelChecked = css`
  background: #0b78ff;
  color: #fff;
`;

const formRadioLabelStyledDisabled = ({ $disabled: disabled }: FormRadioLabelStyledProps) =>
  disabled &&
  css`
    opacity: 0.65;
  `;

const FormRadioLabelStyled = styled.label<FormRadioLabelStyledProps>`
  border-radius: 99px;
  color: #000;
  height: 100%;
  padding: 10px 20px;
  margin: 0;
  cursor: ${(props) => (props.$disabled ? 'auto' : 'pointer')};
  display: flex;
  align-items: center;

  &.is-checked {
    ${FormRadioLabelChecked}
  }

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    transition: background-color 0.2s ease-in-out;
    background-color: ${({ theme }) => theme.colors.primary};
  }

  ${formRadioLabelStyledDisabled};
`;

const FormRadioInputStyled = styled.input`
  opacity: 0;
  position: absolute;
  z-index: 1;

  &:checked ~ ${FormRadioLabelStyled} {
    ${FormRadioLabelChecked}
  }
`;

const SlotAfterLabelStyled = styled.div`
  margin-left: 10px;
`;

const useFormRadioSwitchState = ({
  className,
  id,
  name,
  value,
  checked,
  label,
  disabled,
  slotAfterLabel,
  onChange,
  labelClassName,
}: FormRadioSwitchProps) => {
  return {
    className,
    id,
    name,
    value,
    checked,
    label,
    disabled,
    slotAfterLabel,
    onChange,
    labelClassName,
  } as const;
};

export const FormRadioSwitch: React.FC<FormRadioSwitchProps> = (props) => {
  const { className, id, name, value, checked, label, disabled, slotAfterLabel, onChange, labelClassName } =
    useFormRadioSwitchState(props);

  return (
    <FormRadioSwitchStyled className={className}>
      <FormRadioInputStyled
        type="radio"
        value={value}
        checked={checked}
        id={id}
        name={name}
        disabled={disabled}
        onChange={onChange}
      />
      <FormRadioLabelStyled htmlFor={id} $disabled={disabled} className={labelClassName}>
        {label}
        {slotAfterLabel && <SlotAfterLabelStyled>{slotAfterLabel}</SlotAfterLabelStyled>}
      </FormRadioLabelStyled>
    </FormRadioSwitchStyled>
  );
};

export const FormRadioSwitchContainer = styled.div`
  background: #eeeeee;
  border-radius: 99px;
  display: inline-flex;
  overflow: hidden;
`;
