import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { DeepPartial } from 'redux';

import { JobAd } from 'store/entities/job-ads/models';
import { JobBoard } from 'store/entities/job-boards/models';
import { Job } from 'store/entities/jobs/models';

export interface JobAdFormState {
  validSteps: number[];
  showSteps: boolean;
  formData: DeepPartial<JobAd>;
  selectedBoardIds: Array<JobBoard['jobBoardId']>;
  selectedJobId: Job['id'] | null;
  apiErrors: string[];
  additionFieldsView: Record<
    JobBoard['jobBoardId'],
    {
      jobBoardId: JobBoard['jobBoardId'];
      jobBoardName: JobBoard['name'];
      additionFieldView: JobAd['additionalFieldsView'];
      postSuccess?: boolean;
      jobAdId?: JobAd['jobAdId'];
    }
  >;
}

export type FormJobAdCaseReducer<T> = CaseReducer<JobAdFormState, PayloadAction<T>>;

export const formJobAdInitialState: JobAdFormState = {
  additionFieldsView: {},
  apiErrors: [],
  formData: {},
  selectedBoardIds: [],
  selectedJobId: null,
  showSteps: true,
  validSteps: [],
};
