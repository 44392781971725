import React, { useCallback, useState } from 'react';
import { useAppRouterParams } from 'router';
import styled from 'styled-components/macro';

import { apiConfig } from 'config/apiConfig';

import { ExInterviewQuestionFile, ExQuestion } from 'model';

import { AppFileCard } from 'components/AppFileCard/AppFileCard';
import { FileExtension } from 'components/FileExtension';
import { IconAddFiles } from 'components/Icons/IconAddFiles';
import { QuestionViewLoading } from 'components/InterviewTemplate/QuestionView/QuestionViewLoading';
import { QuestionViewProps } from 'components/InterviewTemplate/QuestionView/QuestionViewProps';
import { UploadButton } from 'components/UploadButton';
import { FileValidationRules } from 'utils/files';
import { useDownloadFile } from 'utils/hooks/useDownloadFile';
import { useOnChangeFilewithValidation } from 'utils/hooks/useFileInputValidation';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { authSelectors } from 'store/auth/auth.selectors';
import {
  useDeleteQuestionFileMutation,
  useQuestionFileUploadMutation,
} from 'store/entities/interview-question/interview-question.api';

type QuestionFileUploadProps = QuestionViewProps & {
  className?: string;
};

type QuestionFileUploadStyledProps = {
  disabled?: boolean;
};

const QuestionFileUploadStyled = styled(UploadButton)<QuestionFileUploadStyledProps>`
  border: 1px dashed #ccc;
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  width: 364px;
  height: 72px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  margin-right: 10px;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  margin-top: 10px;
`;

const QuestionFileUploadIcon = styled.div`
  margin-right: 10px;
`;

const QuestionFileUploadColorLabel = styled.span`
  color: #0b78ff;
`;

const QuestionFileUploadWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: -10px;
`;

const QuestionAppFileCardStyled = styled.div`
  position: relative;
  margin-right: 10px;
  margin-top: 10px;
`;

const QuestionViewLoadingStyled = styled(QuestionViewLoading)`
  right: 5px;
  top: 5px;
`;

const AppFileCardStyled = styled(AppFileCard)`
  margin: 0;
  height: 72px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  ${FileExtension} {
    margin: 0;
  }
`;

type QuestionAppFileCardProps = {
  item: ExInterviewQuestionFile;
  question: ExQuestion;
};

const QuestionAppFileCard: React.FC<QuestionAppFileCardProps> = ({ item, question }) => {
  const companyId = useAppSelector(authSelectors.selectCurrentCompanyId);
  const downloadFile = useDownloadFile();
  const { interviewId } = useAppRouterParams();
  const [deleteQuestionFile] = useDeleteQuestionFileMutation();

  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    setIsLoading(true);
    const path = [apiConfig.baseUrl, 'api/company', companyId, 'file', item.interviewQuestionFileId].join('/');
    await downloadFile({ fileName: item.filename, path });
    setIsLoading(false);
  };

  const onDelete = async () => {
    setIsLoading(true);
    await deleteQuestionFile({
      interviewId: interviewId!,
      interviewQuestionId: question.questionId,
      interviewQuestionFileId: item.interviewQuestionFileId,
      questionText: question.questionText,
    });
    setIsLoading(false);
  };

  return (
    <QuestionAppFileCardStyled>
      <QuestionViewLoadingStyled isLoading={isLoading} />
      <AppFileCardStyled item={item} onClick={onClick} isDownloadable={!isLoading} onDelete={onDelete} />
    </QuestionAppFileCardStyled>
  );
};

const allowedFiles = '.pdf,.doc,.docx,.rtf,.txt,.jpg,.jpeg,.png,.xls,.xlsx';

const fileValidationOptions: FileValidationRules = [
  {
    type: 'type',
    rule: allowedFiles,
  },
  {
    type: 'size',
    rule: 100000000,
  },
];

const useQuestionFileUploadState = ({ className, editable, question }: QuestionFileUploadProps) => {
  const { interviewId } = useAppRouterParams();
  const [questionFileUpload, { isLoading }] = useQuestionFileUploadMutation();
  const { questionId, questionText, interviewQuestionFiles: questionFiles = [] } = question;

  const disabled = !editable || isLoading;

  const uploadHandler = useCallback(
    async (files: File[]) => {
      if (disabled || !files) {
        return;
      }

      const formData = new FormData();
      files.forEach((file) => formData.append('document', file, file.name));

      await questionFileUpload({
        interviewId: interviewId!,
        interviewQuestionId: questionId,
        questionText,
        data: formData,
      });
    },
    [disabled, questionFileUpload, interviewId, questionId, questionText],
  );

  const { onChangeFileWithValidation } = useOnChangeFilewithValidation({
    options: fileValidationOptions,
    withToasts: true,
    onSuccess: uploadHandler,
  });

  return {
    className,
    disabled,
    questionFiles,
    isLoading,
    question,
    onChangeFileWithValidation,
  } as const;
};

const QuestionFileUploadEnabled: React.FC<QuestionFileUploadProps> = (props) => {
  const { className, disabled, questionFiles, isLoading, question, onChangeFileWithValidation } =
    useQuestionFileUploadState(props);

  return (
    <QuestionFileUploadWrapper>
      <QuestionViewLoading isLoading={isLoading} />
      <QuestionFileUploadStyled
        className={className}
        onChange={onChangeFileWithValidation}
        disabled={disabled}
        accept={allowedFiles}
      >
        <QuestionFileUploadIcon>
          <IconAddFiles />
        </QuestionFileUploadIcon>
        <div>
          <QuestionFileUploadColorLabel>Browse</QuestionFileUploadColorLabel> your files
        </div>
      </QuestionFileUploadStyled>
      {questionFiles.map((questionFile) => (
        <QuestionAppFileCard key={questionFile.interviewQuestionFileId} item={questionFile} question={question} />
      ))}
    </QuestionFileUploadWrapper>
  );
};

const QuestionFileUploadDisabled: React.FC<QuestionFileUploadProps> = (props) => (
  <QuestionFileUploadWrapper>
    <QuestionFileUploadStyled className={props.className} onChange={() => {}} disabled={true}>
      <QuestionFileUploadIcon>
        <IconAddFiles />
      </QuestionFileUploadIcon>
      <div>
        <QuestionFileUploadColorLabel>Browse</QuestionFileUploadColorLabel> your files
      </div>
    </QuestionFileUploadStyled>
  </QuestionFileUploadWrapper>
);

export const QuestionFileUpload: React.FC<QuestionFileUploadProps> = (props) =>
  props.editable ? <QuestionFileUploadEnabled {...props} /> : <QuestionFileUploadDisabled {...props} />;
