import React, { useCallback } from 'react';

import { EmptyState } from 'components/EmptyState';
import { IconEmptyStateGrid } from 'components/Icons/IconEmptyStateGrid';
import { TableEmptyRowLoaderStyle } from 'components/TableList/TableListComponents';
import { ExAddNewButton } from 'components/ui/ExAddNewButton/ExAddNewButton';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { candidateCreateOpenModal } from 'store/entities/candidates/candidate.actions';

const title = 'There are no candidate to display';
const btnText = 'Add New Candidate';

export const CandidateListPlaceholder: React.FC = () => {
  const dispatch = useAppDispatch();

  const onClick = useCallback(() => {
    dispatch(candidateCreateOpenModal());
  }, [dispatch]);

  return (
    <TableEmptyRowLoaderStyle>
      <EmptyState title={title} icon={<IconEmptyStateGrid />}>
        <ExAddNewButton variant="primary" size="sm" onClick={onClick}>
          {btnText}
        </ExAddNewButton>
      </EmptyState>
    </TableEmptyRowLoaderStyle>
  );
};
