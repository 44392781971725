import { Action, nanoid } from '@reduxjs/toolkit';
import apiStatusCodes from 'lang/apiStatusCodes';
import { all, delay, put, takeEvery } from 'redux-saga/effects';

import {
  apiCallError,
  showCancelableCountdown,
  showError,
  showInfo,
  showSuccess,
  showWarning,
} from 'containers/AlertManager/store/alert.actions';
import {
  Alert,
  AlertTypes,
  ApiCallErrorActionPayload,
  CancelableCountdownActionPayload,
} from 'containers/AlertManager/store/models';
import { alertsActions } from 'containers/AlertManager/store/reducers';

import { CancelableCountdown } from 'components/CancelableCountdown';
import { getTranslate } from 'utils/i18utils';

function* show(type: AlertTypes, action: ReturnType<typeof showError>) {
  const {
    payload: {
      message,
      blocking,
      containerClassName,
      delay: delayMs = 3000,
      closable = false,
      isHtml = false,
      duplicate = false,
    },
  } = action;

  const alertEnriched: Alert = {
    blocking: !!blocking,
    id: nanoid(),
    message: message || AlertTypes[type],
    type,
    closable,
    isHtml,
    containerClassName,
    duplicate,
  };

  yield put(alertsActions.add(alertEnriched));
  if (!blocking) {
    yield delay(delayMs);
    yield put(alertsActions.del(alertEnriched.id));
  }
}

const showErrorBind = show.bind(null, AlertTypes.error);
const showSuccessBind = show.bind(null, AlertTypes.success);
const showWarningBind = show.bind(null, AlertTypes.warning);
const showInfoBind = show.bind(null, AlertTypes.info);

function* apiErrorWorker(action: { payload: ApiCallErrorActionPayload; type: string }) {
  const validationErrorCodes = action.payload.errorData?.validationErrorCodes;
  const { status: statusCode } = action.payload ?? {};

  if (
    statusCode === 401 ||
    statusCode === 503 ||
    action.payload.message === apiStatusCodes['401'] ||
    action.payload.message === apiStatusCodes['503']
  ) {
    return;
  }

  const statusMessage = statusCode && getTranslate([`apiStatusCodes:${statusCode}`], { defaultValue: '' });
  const message = statusMessage || action.payload.message;

  const actionTypeFunction = showError;

  if (validationErrorCodes) {
    const errorMessages = validationErrorCodes.map(function (validationErrorCode) {
      const validationErrorMessage = getTranslate(validationErrorCode, { ns: 'validationErrorCodes' });
      return actionTypeFunction({ message: validationErrorMessage || message || 'Error' });
    });
    yield all(errorMessages.map((errorMessage: Action) => put(errorMessage)));
  } else if (message) {
    yield put(actionTypeFunction({ message }));
  }
}

function* cancelableCountdownWorker(action: { payload: CancelableCountdownActionPayload; type: string }) {
  const id = nanoid();
  const countdownAlert: Alert = {
    id,
    type: AlertTypes.cancelableCountdown,
    closable: false,
    children: <CancelableCountdown id={id} {...action.payload} />,
  };

  yield put(alertsActions.add(countdownAlert));
}

export function* alertWorkers() {
  yield takeEvery(apiCallError, apiErrorWorker);
  yield takeEvery(showError, showErrorBind);
  yield takeEvery(showSuccess, showSuccessBind);
  yield takeEvery(showWarning, showWarningBind);
  yield takeEvery(showInfo, showInfoBind);
  yield takeEvery(showCancelableCountdown, cancelableCountdownWorker);
}
