import { call, put } from 'redux-saga/effects';

import { basicPublicUrl } from 'pages/public/state/index';
import { publicCurrencyActions } from 'pages/public/state/public-currency/public-currency.actions';

export function* publicCurrencyFetchAction() {
  const url = `${basicPublicUrl}/api/dictionary/currency`;

  const response = yield call(fetch, url);
  const json = yield call([response, 'json']);

  yield put(publicCurrencyActions.setAll(json));
}
