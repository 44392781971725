import { createSelector } from '@reduxjs/toolkit';

import { basicPublicUrl } from 'pages/public/state/index';
import { publicCompanySelectors } from 'pages/public/state/public-company/public-company.selectors';
import { PublicUploadTypes } from 'pages/public/state/public-new-applicant-form/public-new-applicant-form.model';
import { publicNewApplicantFormDomain } from 'pages/public/state/rootSelectors';

import { SocialNetwork } from 'store/entities/candidates/models';

const publicNewApplicantFormSelector = createSelector(publicNewApplicantFormDomain, (state) => state.formData);
const publicNewApplicantFormApiErrors = createSelector(publicNewApplicantFormDomain, (state) => state.apiErrors);
const selectUploadedPhoto = createSelector(publicNewApplicantFormDomain, (state) => state[PublicUploadTypes.photo]);
const selectUploadedDocumentResume = createSelector(
  publicNewApplicantFormDomain,
  (state) => state[PublicUploadTypes.documentResume],
);
const selectUploadedResume = createSelector(publicNewApplicantFormDomain, (state) => state[PublicUploadTypes.resume]);
const selectUploadedDocument = createSelector(
  publicNewApplicantFormDomain,
  (state) => state[PublicUploadTypes.document],
);

const selectPublicUploadTypesConfig = createSelector(publicCompanySelectors.selectPublicCompanyId, (companyId) => {
  return {
    [PublicUploadTypes.photo]: {
      url: `${basicPublicUrl}/api/company/${companyId}/applicant/photo`,
      field: 'photoId',
      fileField: 'photo',
      successMsg: 'Photo successfully uploaded',
    },
    [PublicUploadTypes.resume]: {
      url: `${basicPublicUrl}/api/company/${companyId}/applicant/resume`,
      field: 'resumeId',
      fileField: 'resume',
      successMsg: 'Resume successfully uploaded',
    },
    [PublicUploadTypes.documentResume]: {
      url: `${basicPublicUrl}/api/company/${companyId}/applicant/document`,
      field: 'resumeId',
      fileField: 'resume',
      successMsg: 'Resume successfully uploaded',
    },
    [PublicUploadTypes.document]: {
      url: `${basicPublicUrl}/api/company/${companyId}/applicant/document`,
      field: 'documentId',
      fileField: 'document',
      successMsg: 'Cover letter successfully uploaded',
    },
  };
});

const requiredSocialNetwork = [SocialNetwork.LinkedIn];

const selectSocialNetworksTypes = createSelector(
  publicNewApplicantFormSelector,
  (formData) => formData?.socialNetworks?.map((social) => social.type) || [],
);

const selectSocialNetworks = createSelector(selectSocialNetworksTypes, (socialNetworks) => {
  const result: number[] = [];
  Object.values(SocialNetwork)
    .filter((value) => Number(value))
    .map((socialNetwork) => Number(socialNetwork))
    .forEach((social) => {
      if (requiredSocialNetwork.includes(social)) {
        result.push(social);
        return;
      }

      if (socialNetworks.includes(social)) {
        result.push(social);
      }
    });
  return result;
});

const publicNewApplicantJobScreeningQuestionsSelector = createSelector(
  publicNewApplicantFormDomain,
  (state) => state.jobScreeningQuestions,
);
const publicNewApplicantFormScreeningQuestionsSelector = createSelector(
  publicNewApplicantFormSelector,
  (state) => state.screeningQuestions,
);

const publicNewApplicantJobScreeningQuestionsWithAnswersSelector = createSelector(
  publicNewApplicantJobScreeningQuestionsSelector,
  publicNewApplicantFormScreeningQuestionsSelector,
  (questions, answers) => {
    return questions.map((item) => ({
      ...item,
      answer: answers[item.itemId] ?? null,
    }));
  },
);

const publicNewApplicantProcessing = createSelector(publicNewApplicantFormDomain, (state) => state.processing);

const publicNewApplicantResumeParsing = createSelector(publicNewApplicantFormDomain, (state) => state.resumeParsing);

const selectSocialNetworksLinkedIn = createSelector(selectSocialNetworks, (socialNetworkTypes) => {
  return socialNetworkTypes.filter((type) => type === SocialNetwork.LinkedIn);
});

export const publicNewApplicantFormSelectors = {
  publicNewApplicantFormSelector,
  publicNewApplicantFormApiErrors,
  selectUploadedPhoto,
  selectUploadedResume,
  selectUploadedDocument,
  selectPublicUploadTypesConfig,
  selectSocialNetworks,
  publicNewApplicantJobScreeningQuestionsSelector,
  publicNewApplicantFormScreeningQuestionsSelector,
  publicNewApplicantJobScreeningQuestionsWithAnswersSelector,
  selectUploadedDocumentResume,
  publicNewApplicantProcessing,
  publicNewApplicantResumeParsing,
  selectSocialNetworksLinkedIn,
};
