import { api } from 'api-client';
import { JobBoardsEndpoints } from 'api-endpoints/job-board/endpoints';
import { JobAdForJobBoardListItemResponse, JobBoardListItemResponse } from 'api-endpoints/job-board/models';
import { createApiMethod } from 'api-endpoints/utils';

import {
  ApiRequestParamsWithPaginationAndFilters,
  ApiResponseWithPagination,
  ApiValueForDynamicForm,
  SearchAutocompleteOptions,
} from 'model';

import { curry } from 'utils/funcs';
import { createUrlWithParams } from 'utils/url';

import { SortDirections } from 'store/constants';
import { JobAdLog } from 'store/entities/job-ads';
import { JobBoard, JobBoardSettings } from 'store/entities/job-boards';

export * from 'api-endpoints/job-board/models';

const basicUrl = '/api/company/{companyId}/job-board';
const createWithBasicUrl = createUrlWithParams(basicUrl);

type JobBoardsWithIdRequest = { urlParams: { jobBoardId: string } };

type GetJobBoardAdditionalFieldsFuncRequest = JobBoardsWithIdRequest & {
  params: {
    location?: string;
    jobId?: string;
  };
};

type GetJobBoardsRequest = {
  params: {
    pageNo?: number;
    pageSize?: number;
    searchTerm?: string;
    countryCodes?: string[];
    industries?: string[];
    orderBy?: string;
    orderDir?: 'Descending' | 'Ascending';
  };
};

export const getJobBoardsSubscribed = createApiMethod<
  GetJobBoardsRequest,
  ApiResponseWithPagination<JobBoardListItemResponse>
>({
  url: JobBoardsEndpoints.SearchSubscribedJobBoardsFunc,
});

export const getJobBoardsUnsubscribed = createApiMethod<
  GetJobBoardsRequest,
  ApiResponseWithPagination<JobBoardListItemResponse>
>({
  url: JobBoardsEndpoints.SearchUnsubscribedJobBoardsFunc,
});

export const getJobBoard = createApiMethod<JobBoardsWithIdRequest, JobBoard>({
  url: JobBoardsEndpoints.GetJobBoardFunc,
});

export const getJobBoardSettings = createApiMethod<JobBoardsWithIdRequest, Pick<JobBoard, 'settings'>>({
  url: JobBoardsEndpoints.GetJobBoardSettingsFunc,
});

export const unSubscribeJobBoard = createApiMethod<JobBoardsWithIdRequest, void>({
  url: JobBoardsEndpoints.UnsubscribeJobBoardFunc,
  method: 'POST',
});

export type JobBoardSettingsRequestPayload = JobBoardsWithIdRequest & { data: { settings: ApiValueForDynamicForm[] } };

export const putJobBoardSettings = createApiMethod<JobBoardSettingsRequestPayload, JobBoardSettings>({
  url: JobBoardsEndpoints.UpdateJobBoardSettingsFunc,
  method: 'PUT',
});

export const postJobBoardSettings = createApiMethod<JobBoardSettingsRequestPayload, JobBoardSettings>({
  url: JobBoardsEndpoints.SubscribeJobBoardFunc,
  method: 'POST',
});

export const getJobAdsForJobBoard = curry(
  (
    jobBoardId: JobBoard['jobBoardId'],
    { pageSize, pageNo, sortMode, filters }: ApiRequestParamsWithPaginationAndFilters,
  ) => {
    return api().request<ApiResponseWithPagination<JobAdForJobBoardListItemResponse>>({
      method: 'GET',
      url: createWithBasicUrl([jobBoardId, 'ad']),
      params: {
        pageNo,
        pageSize,
        ...(sortMode?.orderBy && {
          orderBy: sortMode.orderBy,
          orderDirection: sortMode.orderDir === SortDirections.desc ? 'Descending' : 'Ascending',
        }),
        ...(filters.statuses?.length && { statuses: filters.statuses }),
        ...(filters.postedOnRange?.length &&
          filters.postedOnRange[0] &&
          filters.postedOnRange[1] && {
            postedAfter: filters.postedOnRange[0],
            postedBefore: filters.postedOnRange[1],
          }),
        ...(filters.search && { searchTerm: filters.search }),
      },
    });
  },
);

export const jobBoardAutocomplete = createApiMethod<
  { params: { searchTerm: string; isSubscribed?: boolean } },
  SearchAutocompleteOptions
>({
  url: JobBoardsEndpoints.JobBoardsAutocompleteFunc,
});

export const getJobBoardAdditionalFields = createApiMethod<
  GetJobBoardAdditionalFieldsFuncRequest,
  Pick<JobBoard, 'additionalFields'>
>({
  url: JobBoardsEndpoints.GetJobBoardAdditionalFieldsFunc,
});

type SearchJobBoardChangeNotesFuncRequest = {
  urlParams: { companyJobBoardId: string };
  params: { pageNo: number };
};

type SearchJobBoardChangeNotesFuncResponse = {
  items: JobAdLog[];
  pageNo: number;
  pageCount: number;
  pageSize: number;
  totalItemsCount: number;
};

export const getJobBoardChangeNotes = createApiMethod<
  SearchJobBoardChangeNotesFuncRequest,
  SearchJobBoardChangeNotesFuncResponse
>({
  url: JobBoardsEndpoints.SearchJobBoardChangeNotesFunc,
});
