import React from 'react';
import styled from 'styled-components/macro';

import { SalaryBudgetStatus } from 'model/api-enums.constants';

import { loaderActions, loaderSelectors } from 'containers/Loader/store';

import { CandidateAvatar } from 'components/CandidateAvatar';
import { IconCurrencyDollar } from 'components/Icons/IconCurrencyDollar';
import { IconSeniorityLevel } from 'components/Icons/IconSeniorityLevel';
import { IconJob } from 'components/Icons/sidebarMenu/IconJob';
import { ExTabMenuNavigationV2, TabMenuNavigation } from 'components/TabMenu/TabMenuComponents';
import { ExActionButton } from 'components/ui/ExActionButton';
import { ExBadge } from 'components/ui/ExBadge';
import { ExBadgeStyled } from 'components/ui/ExBadge/ExBadgeComponents';
import { ExBadgeVariant } from 'components/ui/ExBadge/ExBadgeProps';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { ExVisible } from 'components/ui/ExVisible';
import type { EnumLiteralsOf } from 'utils/funcs';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useDownloadFile } from 'utils/hooks/useDownloadFile';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getThemeColor } from 'utils/styled/getThemeColor';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

const DownloadLink = styled(ExButton)`
  position: absolute !important;
  transform: translateY(50%);
  right: 20px;
  top: 0;
  z-index: 1;
`;

export const DownloadLinkComponent: React.FC<{ downloadLink: string | null }> = ({ downloadLink }) => {
  const downloadFile = useDownloadFile();
  const dispatch = useAppDispatch();

  const downloadHandler: React.MouseEventHandler = async (e) => {
    e.preventDefault();
    dispatch(loaderActions.start('downloadHandler'));

    if (downloadLink) {
      await downloadFile({ path: downloadLink });
    }

    dispatch(loaderActions.stop('downloadHandler'));
  };

  const isDownloading = useAppSelector(loaderSelectors.isTaskActive, { taskId: 'downloadHandler' });

  return (
    <ExVisible visible={Boolean(downloadFile)}>
      <DownloadLink variant="light" onClick={downloadHandler} isLoading={isDownloading}>
        Download file
      </DownloadLink>
    </ExVisible>
  );
};

export const CandidateAvatarStyled = styled(CandidateAvatar)`
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
`;

export const JobBoardLogo = styled.img`
  height: 30px;
  object-fit: contain;
`;

export const ApplicantModalActionButton = styled(ExActionButton)`
  position: absolute;
  top: 15px;
  right: 10px;
`;

export const ApplicantStatusBadgeWrapper = styled.div`
  padding: 0 0 10px;

  ${ExBadgeStyled} {
    padding: 0.4em 0.8em;
  }

  & > :not(:first-child) {
    margin-left: 0.5rem;
  }
`;

export const ApplicantsTabMenuSection = styled.div`
  width: 100%;
  border-color: ${(props) => props.theme.border.borderColor};
  padding: 20px 20px 0;

  ${TabMenuNavigation} {
    margin-bottom: 0;
  }

  ${ExTabMenuNavigationV2} {
    justify-content: center;
  }
`;

export const ApplicantSection = styled.div`
  background-color: #f0f0f0;
  border-right: 1px solid ${(props) => props.theme.border.borderColor};
  flex: 0 0 260px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  font-weight: normal;
  position: relative;
`;

export const ApplicantSectionDivider = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.white};
  margin: 15px -10px;
`;

export const ApplicantInfoSectionItem = styled.div`
  padding: 0 20px;
`;

export const ApplicantInfo = styled(ApplicantInfoSectionItem)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 0;
`;

export const ActionButton = styled(ExButton)`
  flex: 1 1 50%;
  margin: 5px;
`;

export const ApplicantName = styled.h3`
  text-align: center;
  font-size: ${getThemeFontSize('large')};
  font-weight: bold;
  margin-bottom: 0;
`;

export const ApplicantWorkExperience = styled.div`
  text-align: center;
  font-size: ${getThemeFontSize('default')};
  margin-bottom: 20px;
`;

export const ApplicantWorkExperiencePosition = styled.div`
  font-weight: bold;
`;

export const ApplicantContacts = styled(ApplicantInfoSectionItem)``;

export const ApplicantContactsItem = styled.p`
  margin: 0;

  &:not(:first-child) {
    margin-top: 10px;
  }
`;

export const ApplicantContactsItemLabel = styled.span`
  font-weight: bold;
  display: inline-block;
  width: 20px;
`;

export const ApplicantAdditionalInfo = styled(ApplicantInfoSectionItem)``;

export const TabContentWrapper = styled.div`
  height: calc(100% - 85px);
  backface-visibility: hidden;
  position: relative;
  flex-grow: 1;

  .applicant-tab {
    overflow: auto;
    padding-right: 20px;
    padding-left: 20px;
    min-height: 200px;
    height: 100%;
  }
`;

export const ApplicantInfoLabel = styled.div`
  color: ${getThemeColor('gray')};
`;

export const IconCurrencyDollarStyled = styled(IconCurrencyDollar)``;

export const IconSeniorityLevelStyled = styled(IconSeniorityLevel)``;

export const IconJobStyled = styled(IconJob)`
  margin-left: -8px;
`;

export const ApplicantModalInfoWrapper = styled.div`
  position: relative;
  padding: 0 20px;
  display: flex;

  & + & {
    margin-top: 20px;
  }

  ${IconCurrencyDollarStyled},
  ${IconSeniorityLevelStyled},
  ${IconJobStyled} {
    color: ${getThemeColor('grayDark')};
    margin-right: 20px;
  }
`;

export const OnBudgetBadge = ({
  salaryBudgetStatus,
}: {
  salaryBudgetStatus?: EnumLiteralsOf<typeof SalaryBudgetStatus>;
}) => {
  const label = salaryBudgetStatus === SalaryBudgetStatus.OnBudget ? 'OnBudget' : 'OverBudget';
  const variant = salaryBudgetStatus === SalaryBudgetStatus.OnBudget ? ExBadgeVariant.success : ExBadgeVariant.warning;

  return <ExBadge variant={variant} children={label} />;
};
