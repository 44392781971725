import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';

import { dateFormat } from 'config';

import { workExperienceSchema } from 'containers/CandidateForm';
import { EditCandidateWorkExperienceModalStyled } from 'containers/Modals/ModalsContent/Candidate/EditCandidateWorkExperienceModal/EditCandidateWorkExperienceModalComponents';
import { EditCandidateWorkExperienceModalProps } from 'containers/Modals/ModalsContent/Candidate/EditCandidateWorkExperienceModal/EditCandidateWorkExperienceModalProps';
import { modalsSelectors } from 'containers/Modals/store';
import { WorkExperienceFormBody } from 'containers/WorkExperienceForm';

import { Form } from 'components';
import { ExLoaderHandlerWrapperStrict } from 'components/Common/LoaderHandlerWrapper';
import { DialogProps } from 'components/Dialog';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { getActualDate } from 'utils/funcs';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { CandidateWorkExperience } from 'store/entities/candidate-work-experience/candidate-work-experience.models';
import { candidateWorkExperienceSelectors } from 'store/entities/candidate-work-experience/candidate-work-experience.selectors';
import { exModalConfirmAction } from 'store/modals/modals.actions';
import { useSelector } from 'store/rootSelectors';

const ExButtonStyled = styled(ExButton)`
  &:last-child {
    margin-left: 10px;
  }
`;

const EditCandidateWorkExperienceModalFooter = styled.div`
  display: flex;
  justify-content: center;
`;

const EditCandidateWorkExperienceModalForm = styled(Form)`
  width: 100%;
`;

export const EditCandidateWorkExperienceModal: React.FC<EditCandidateWorkExperienceModalProps> = ({
  className,
  onClose,
  id,
}) => {
  const dispatch = useAppDispatch();
  const candidateId = useSelector(modalsSelectors.modalCandidateId);
  const workExperienceId = useSelector(modalsSelectors.modalWorkExperienceId);
  const workExperience = useSelector((state) => candidateWorkExperienceSelectors.selectById(state, workExperienceId!));
  const content: DialogProps['content'] = {
    title: `${workExperienceId ? 'Edit' : 'Create'} Work Experience`,
    withTitle: true,
  };

  const transformToInputDate = useCallback(
    (date: string | undefined | null) =>
      date
        ? format(parseISO(date), dateFormat.formatDateForDateInput, {
            weekStartsOn: 1,
          })
        : null,
    [],
  );

  const defaultValues: Record<string, any> = useMemo(
    () => ({
      ...workExperience,
      startDate: transformToInputDate(getActualDate(workExperience?.startDate)),
      endDate: transformToInputDate(getActualDate(workExperience?.endDate)),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const submitHandler = async (formData: Partial<CandidateWorkExperience>) => {
    const params = {
      data: formData as CandidateWorkExperience,
      urlParams: { workExperienceId: workExperienceId!, candidateId: candidateId! },
    };
    dispatch(exModalConfirmAction({ id, modalResult: params }));
  };

  return (
    <EditCandidateWorkExperienceModalStyled className={className} onClose={onClose} content={content}>
      <EditCandidateWorkExperienceModalForm
        defaultValues={defaultValues}
        onSubmit={submitHandler}
        validationSchema={workExperienceSchema}
      >
        <FormGroup name="candidateEducation" />
        <EditCandidateWorkExperienceModalFooter>
          <ExButtonStyled variant="light" onClick={() => onClose(false)} type="button">
            Cancel
          </ExButtonStyled>
          <ExLoaderHandlerWrapperStrict>
            <ExButtonStyled variant="primary" type="submit">
              {workExperienceId ? 'Update' : 'Create'}
            </ExButtonStyled>
          </ExLoaderHandlerWrapperStrict>
        </EditCandidateWorkExperienceModalFooter>
      </EditCandidateWorkExperienceModalForm>
    </EditCandidateWorkExperienceModalStyled>
  );
};

const FormGroup: React.FC<{ name?: string }> = () => (
  <WorkExperienceFormBody idFieldName="workExperienceId" groupName="candidateWorkExperience" />
);
