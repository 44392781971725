import React from 'react';
import styled from 'styled-components';

import { FormCheckboxProps } from './FormCheckboxProps';

const FormCheckboxPure: React.FC<FormCheckboxProps> = ({
  children,
  name,
  validated,
  className,
  id,
  inputRef,
  errors,
  label,
  ...inputProps
}) => {
  const validationClassName = !errors ? 'is-valid' : 'is-invalid';
  return (
    <div className={`${className} custom-control custom-checkbox`}>
      <input
        ref={inputRef}
        type="checkbox"
        id={id || name}
        name={name}
        className={`custom-control-input ${validated ? validationClassName : ''}`}
        {...inputProps}
      />
      <label className="custom-control-label" htmlFor={id || name}>
        {label || children}
      </label>
    </div>
  );
};

export const FormCheckbox = styled(FormCheckboxPure)`
  .custom-control-input {
    ~ .custom-control-label {
      &::before {
        background-color: ${(props) => props.theme.colors.light};
      }
    }

    &:checked {
      ~ .custom-control-label {
        &::after {
          content: '';
          display: block;
          width: 5px;
          height: 8px;
          border-radius: 1px;
          border-bottom: 2px solid ${(props) => props.theme.colors.white};
          border-right: 2px solid ${(props) => props.theme.colors.white};
          transform: rotate(45deg) translate3d(5px, -2px, 0);
          transform-origin: center;
          background-image: none;
        }

        &::before {
          background: ${(props) => props.theme.colors.primary};
          background-image: none;
          width: 14px;
          height: 14px;
          border-radius: 100%;
        }
      }
    }

    &:disabled {
      ~ .custom-control-label {
        color: ${(props) => props.theme.colors.gray};

        &::before {
          border-color: ${(props) => props.theme.colors.light};
          background-color: ${(props) => props.theme.colors.light};
        }
      }
    }
  }

  .custom-control-label {
    line-height: 1.5rem;

    &::before {
      border-color: ${(props) => (props.variant ? props.theme.colors[props.variant] : props.theme.input.borderColor)};
    }
  }
`;
