import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import validationErrorCodes from 'lang/validation-error-codes';
import styled from 'styled-components/macro';

import { InterviewQuestionsValidationErrorCodes } from 'model/api-errors.constants';

import { FormControlContainerWithErrorMessage } from 'components/Form/FormControlContainerWithErrorMessage';
import { FormInput } from 'components/FormInput';
import { QuestionViewLoading } from 'components/InterviewTemplate/QuestionView/QuestionViewLoading';
import { QuestionViewProps } from 'components/InterviewTemplate/QuestionView/QuestionViewProps';
import { getTranslate } from 'utils/i18utils';

import { useUpdateInterviewQuestionMutation } from 'store/entities/interview-question/interview-question.api';

type QuestionTextFieldProps = QuestionViewProps & {
  className?: string;
};

const QuestionTextFieldStyled = styled.div`
  width: 100%;

  .form-group {
    margin-bottom: 0;
  }
`;

const FormInputStyled = styled(FormInput)`
  input:disabled {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

const MAX_LENGTH = 255;

const useQuestionTextFieldState = ({ className, editable, question, interviewId }: QuestionTextFieldProps) => {
  const name = question.questionId;
  const { register, setError, errors } = useFormContext();
  const fieldError = errors[name];

  const [updateInterviewQuestionMutation, { isLoading }] = useUpdateInterviewQuestionMutation();
  const disabled = !editable || isLoading;

  const onBlur = useCallback(
    (event) => {
      if (interviewId) {
        const { interviewQuestionFiles, ...questionForUpdate } = question;
        updateInterviewQuestionMutation({
          ...questionForUpdate,
          answer: event.target.value,
          interviewQuestionId: question.questionId,
          interviewId,
        }).then((result) => {
          const { error } = result as { error?: { data: { validationErrorCodes: string[] } } };
          if (error) {
            const message = error.data.validationErrorCodes
              .map((code) => getTranslate(code, { ns: 'validationErrorCodes' }))
              .join(', ');
            setError(name, { message });
          } else {
            setError(name, {});
          }
        });
      }
    },
    [interviewId, question, updateInterviewQuestionMutation, setError, name],
  );

  return {
    className,
    disabled,
    isLoading,
    name,
    onBlur,
    register,
    fieldError,
  } as const;
};

export const QuestionTextField: React.FC<QuestionTextFieldProps> = (props) => {
  const { className, disabled, isLoading, name, onBlur, register, fieldError } = useQuestionTextFieldState(props);

  return (
    <QuestionTextFieldStyled className={className}>
      <QuestionViewLoading isLoading={isLoading} />
      <FormControlContainerWithErrorMessage show={!!fieldError} errors={fieldError}>
        <FormInputStyled
          label=""
          name={name}
          disabled={disabled}
          onBlur={onBlur}
          inputRef={
            register({
              required: props.question.isMandatory
                ? validationErrorCodes[InterviewQuestionsValidationErrorCodes.EmptyMandatoryAnswer]
                : false,
              maxLength: MAX_LENGTH,
            }) as any
          }
        />
      </FormControlContainerWithErrorMessage>
    </QuestionTextFieldStyled>
  );
};
