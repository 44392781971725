import { createAction } from '@reduxjs/toolkit';

import { JobBoardSettingsForm } from './models';
import jobBoardFormSlice from './reducer';
import * as selectors from './selectors';

const JOB_BOARD_FORM_SAVE_SUBSCRIPTION = 'jobBoardForm/subscription/save';
const JOB_BOARD_FORM_CREATE_SUBSCRIPTION = 'jobBoardForm/subscription/create';
const JOB_BOARD_FORM_UPDATE_SUBSCRIPTION = 'jobBoardForm/subscription/update';
const saveSubscription = createAction<{ data?: Partial<JobBoardSettingsForm> }>(JOB_BOARD_FORM_SAVE_SUBSCRIPTION);
const createSubscription = createAction<{ data?: Partial<JobBoardSettingsForm> }>(JOB_BOARD_FORM_CREATE_SUBSCRIPTION);
const updateSubscription = createAction<{ data?: Partial<JobBoardSettingsForm> }>(JOB_BOARD_FORM_UPDATE_SUBSCRIPTION);

export const jobBoardFormActions = {
  ...jobBoardFormSlice.actions,
  saveSubscription,
  createSubscription,
  updateSubscription,
};

export const jobBoardFormReducer = jobBoardFormSlice.reducer;

export const jobBoardFormSelectors = selectors;

export default jobBoardFormReducer;
