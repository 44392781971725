import styled from 'styled-components/macro';

import { Table, Tbody } from 'components/ui/ExTable/ExTable.components';

export const InterviewsTabStyled = styled.div`
  padding-bottom: 30px;

  ${Table} {
    height: auto;
  }

  ${Tbody} {
    border-radius: ${({ theme }) => theme.border.borderRadiusSm};
  }
`;

export const InterviewsActions = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
`;
