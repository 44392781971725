import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { CompanyScreeningQuestionsElement } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/CompanyScreeningQuestionsComponents';
import {
  CompanyScreeningQuestionElementDraggableWrapper,
  ScreeningQuestionsLine,
  ScreeningQuestionsLineContent,
} from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/CompanyScreeningQuestionsStyles';
import {
  useCompanyScreeningQuestionsListState,
  useCreateJobScreeningQuestionsListState,
  useJobScreeningQuestionsListState,
} from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/hooks/useCompanyScreeningQuestionsListState';

import { Spinner0 } from 'components/Spinner';
import { ExVisible } from 'components/ui/ExVisible';

const companyScreeningQuestionsList = {
  companyScreeningQuestions: useCompanyScreeningQuestionsListState,
  jobScreeningQuestions: useJobScreeningQuestionsListState,
  createJobScreeningQuestions: useCreateJobScreeningQuestionsListState,
};

type CompanyScreeningQuestionsListProps = {
  context: keyof typeof companyScreeningQuestionsList;
};

export const CompanyScreeningQuestionsList = ({ context }: CompanyScreeningQuestionsListProps) => {
  const useComponentState = companyScreeningQuestionsList[context];
  const { itemsIds, entities, hasItemsIds, isFetching, noItemsIds, onDragEnd, isDragDisabled } = useComponentState();

  return (
    <>
      <ExVisible visible={isFetching}>
        <ScreeningQuestionsLine>
          <ScreeningQuestionsLineContent>
            <Spinner0 />
          </ScreeningQuestionsLineContent>
        </ScreeningQuestionsLine>
      </ExVisible>
      <ExVisible visible={noItemsIds}>
        <ScreeningQuestionsLine>
          <ScreeningQuestionsLineContent>There are no questions to display</ScreeningQuestionsLineContent>
        </ScreeningQuestionsLine>
      </ExVisible>
      <ExVisible visible={hasItemsIds && !isFetching}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={'screening-questions'}>
            {(provided) => (
              <CompanyScreeningQuestionElementDraggableWrapper {...provided.droppableProps} ref={provided.innerRef}>
                {itemsIds.map((id, index) => {
                  const item = entities[id]!;

                  return (
                    <Draggable
                      key={item.itemId}
                      draggableId={item.itemId}
                      index={index}
                      isDragDisabled={isDragDisabled}
                    >
                      {(providedItem, snapshotItem) => (
                        <CompanyScreeningQuestionsElement
                          index={index}
                          forwardRef={providedItem.innerRef}
                          isDragging={snapshotItem.isDragging}
                          draggableProps={providedItem.draggableProps}
                          dragHandleProps={providedItem.dragHandleProps}
                          question={item}
                          context={context}
                          isDragDisabled={isDragDisabled}
                        />
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </CompanyScreeningQuestionElementDraggableWrapper>
            )}
          </Droppable>
        </DragDropContext>
      </ExVisible>
    </>
  );
};
