import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useAppRouterParams } from 'router';
import styled from 'styled-components/macro';

import { PipelineStageType } from 'model/api-enums.constants';

import { applicantListActions, applicantListSelectors } from 'containers/ApplicantLists/store';

import { FormToggleButtons } from 'components/FormToggleButtons';
import { ExBadge } from 'components/ui/ExBadge';
import { ExBadgeVariant } from 'components/ui/ExBadge/ExBadgeProps';
import { useJobAnalytics, useJobAnalyticsTotal } from 'utils/hooks/job';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { ApplicantBelongsTo } from 'store/entities/applicants/models';
import { hiringPipelinesStagesSelectors } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.reducer';

type JobApplicantQuickStatusFilterProps = {
  className?: string;
};

const JobApplicantQuickStatusFilterStyled = styled(FormToggleButtons)`
  margin-bottom: 0;
`;

const useJobApplicantQuickStatusFilterState = ({ className }: JobApplicantQuickStatusFilterProps) => {
  const params = useAppRouterParams();
  const jobId = params.jobId!;
  const dispatch = useAppDispatch();

  const listId = ApplicantBelongsTo.job;

  const { pipelineStages = '' } = useAppSelector(applicantListSelectors.getFilters, { id: listId });

  const { control, watch, setValue } = useForm({
    defaultValues: {
      pipelineStageId: pipelineStages,
    },
  });

  useEffect(() => {
    setValue('pipelineStageId', pipelineStages);
  }, [pipelineStages, setValue]);

  const pipelineStageId = watch('pipelineStageId');

  useEffect(() => {
    const value = pipelineStageId || undefined;

    dispatch(applicantListActions.updatePage({ id: listId, pageNo: 0 }));
    dispatch(applicantListActions.updateFilters({ id: listId, filters: { pipelineStages: value } }));
  }, [dispatch, listId, pipelineStageId]);

  const analytics = useJobAnalytics(jobId);
  const analyticsTotal = useJobAnalyticsTotal(jobId);

  const nextStageAfter = useAppSelector(hiringPipelinesStagesSelectors.selectFirstPipelineStageAfterNew, jobId);

  const allStageCount = analyticsTotal.count;
  const newStage = analytics.find((analytic) => analytic.stageType === PipelineStageType.New);

  const nextStageLabel = nextStageAfter?.name;
  const nextStage = analytics.find((analytic) => analytic.stageType === nextStageAfter?.stageType);

  const currentStageNotMain = useMemo(() => {
    if (!pipelineStageId) {
      return undefined;
    }

    const current = analytics.find((analytic) => analytic.pipelineStageId === pipelineStageId);

    if (
      !current ||
      current.pipelineStageId === newStage?.pipelineStageId ||
      current.pipelineStageId === nextStage?.pipelineStageId
    ) {
      return undefined;
    }

    return current;
  }, [pipelineStageId, analytics, newStage?.pipelineStageId, nextStage?.pipelineStageId]);

  const allTabCurrentCount = useMemo(() => {
    return currentStageNotMain ? currentStageNotMain?.count : allStageCount;
  }, [currentStageNotMain, allStageCount]);

  const newValue: string = newStage?.pipelineStageId || (pipelineStages as string);

  const options = [
    {
      label: 'All',
      value: '',
      slotAfterLabel: <ExBadge variant={ExBadgeVariant.tabActive}>{allTabCurrentCount}</ExBadge>,
      labelClassName: currentStageNotMain?.pipelineStageId ? 'is-checked' : '',
    },
    {
      label: 'New',
      value: newValue,
      slotAfterLabel: <ExBadge variant={ExBadgeVariant.tabActive}>{newStage?.count}</ExBadge>,
    },
    ...(nextStage
      ? [
          {
            label: nextStageLabel,
            value: nextStage.pipelineStageId,
            slotAfterLabel: <ExBadge variant={ExBadgeVariant.tabActive}>{nextStage.count}</ExBadge>,
          },
        ]
      : []),
  ];

  return {
    className,
    options,
    control,
  } as const;
};

export const JobApplicantQuickStatusFilter: React.FC<JobApplicantQuickStatusFilterProps> = (props) => {
  const { className, options, control } = useJobApplicantQuickStatusFilterState(props);

  return (
    <JobApplicantQuickStatusFilterStyled
      className={className}
      control={control}
      name={'pipelineStageId'}
      options={options}
    />
  );
};
