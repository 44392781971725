import { createSelector } from '@reduxjs/toolkit';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';

import {
  BasicModel,
  getCacheListID,
  InitialState,
  initListState,
  ListParams,
  ListState,
  SortMode,
} from 'utils/reducer/reducer-helper';

import { EntityKeys } from 'store/constants';
import { RootState } from 'store/rootReducer';
import { entitiesDomain } from 'store/rootSelectors';

export const createLookupSelectors = <T>(entityName: EntityKeys) => {
  function stateById(state: InitialState<BasicModel>, id?: string | null) {
    if (!state.byId || !id) {
      return undefined;
    }
    return state.byId[id] as unknown as T | undefined;
  }
  const getEntityStore = createSelector(entitiesDomain, (state) => state[entityName]);

  const getList = createSelector(getEntityStore, (state) => {
    if (state.allIds.length === 0) {
      return [];
    }

    return state?.allIds?.map((id: BasicModel['id']) => state.byId && state.byId[id]) as unknown as T[];
  });

  const getLookup = createSelector<RootState, any, Record<string, T>>(getEntityStore, (state) => state.byId);

  const createGetById = () => createSelector(getEntityStore, (_: RootState, id: BasicModel['id']) => id, stateById);

  const createGetByIds = () =>
    createSelector(
      getEntityStore,
      (_: RootState, ids: Array<BasicModel['id']>) => ids,
      (state, ids) => {
        return state.byId ? ids.map((id) => state.byId[id]) : [];
      },
    );

  const getById = createSelector(
    getEntityStore,
    (_: RootState, id: BasicModel['id'] | null | undefined) => id,
    stateById,
  );
  const getByIds = createSelector(
    getEntityStore,
    (_: RootState, ids: Array<BasicModel['id']>) => ids,
    (state, ids) => {
      return state.byId
        ? (ids.reduce<BasicModel[]>(
            (acc, id) => (state.byId[id] ? [...acc, state.byId[id]] : acc),
            [],
          ) as unknown as Array<T>)
        : ([] as Array<T>);
    },
  );

  return {
    getList,
    getLookup,
    createGetById,
    getById,
    createGetByIds,
    getByIds,
    getEntityStore,
  };
};

export function createListSelectors<T extends BasicModel, S, F>(
  entityName: EntityKeys,
  domain: (root: RootState) => Record<string, ListState<T, S, F>>,
) {
  type ComponentOwnProps = object & { id?: string; listId?: string };
  const lookupSelectors = createLookupSelectors<T>(entityName);
  type ReversDict<D> = D[keyof D];
  type Domain = (root: RootState) => Record<string, ListState<T, S, F>>;

  type GetListById = (state: RootState, own: ComponentOwnProps) => ReversDict<ReturnType<Domain>>;
  const getListById: GetListById = createSelector(
    domain,
    (_: RootState, own: ComponentOwnProps) => own.id || own.listId,
    (lists, listId) => (listId && lists[listId]) || initListState,
  );

  type GetFilters = (state: RootState, own: ComponentOwnProps) => F;
  const getFilters: GetFilters = createSelector(getListById, (list) => list.filters);

  type GetSortMode = (state: RootState, own: ComponentOwnProps) => SortMode<S>;
  const getSortMode: GetSortMode = createSelector(getListById, (list) => list.sortMode);

  type GetItems = (state: RootState, own: ComponentOwnProps) => T[];
  const getItems: GetItems = createSelector(domain, getListById, lookupSelectors.getLookup, (lists, list, entities) => {
    const cachedList = lists[list.id]?.pagination[getCacheListID(list)];
    return (
      cachedList?.items.reduce<T[]>(
        (acc, entityId) => (entities && entities[entityId] ? [...acc, entities[entityId]] : acc),
        [],
      ) || []
    );
  });

  type GetRecentItems = (state: RootState, own: ComponentOwnProps) => T[];
  const getRecentItems: GetRecentItems = createSelector(getListById, lookupSelectors.getLookup, (list, entities) => {
    return (
      list?.items?.reduce<T[]>((acc, jobId) => (entities && entities[jobId] ? [...acc, entities[jobId]] : acc), []) ||
      []
    );
  });

  type GetListParams = (state: RootState, own: ComponentOwnProps) => ListParams<T, S, F>;
  const getListParams: GetListParams = createSelector(getListById, (list) => ({
    id: list.id,
    viewMode: list.viewMode,
    sortMode: list.sortMode,
    pageNo: list.pageNo,
    pageSize: list.pageSize,
    filters: list.filters,
  }));

  type GetViewMode = (state: RootState, own: ComponentOwnProps) => ReturnType<GetListById>['viewMode'];
  const getViewMode: GetViewMode = createSelector(getListParams, (list) => list.viewMode);

  type GetSelected = (state: RootState, own: ComponentOwnProps) => Array<T['id']>;
  const getSelected: GetSelected = createSelector(getListById, (list) => list.selectedItems ?? []);

  type GetSelectedEntities = (state: RootState, own: ComponentOwnProps) => T[];
  const getSelectedEntities: GetSelectedEntities = createSelector(
    getSelected,
    lookupSelectors.getLookup,
    (ids, entities) =>
      ids.reduce<T[]>((acc, entityId) => (entities && entities[entityId] ? [...acc, entities[entityId]] : acc), []),
  );

  return {
    getListById,
    getFilters,
    getSortMode,
    getItems,
    getRecentItems,
    getListParams,
    getViewMode,
    getSelected,
    getSelectedEntities,
  };
}

export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);
