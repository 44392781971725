import { createSelector } from '@reduxjs/toolkit';

import { ApplicationRole } from 'model/api-enums.constants';

import { rbacConstants } from 'containers/Auth/AuthMatrix';

import type { EnumLiteralsOf } from 'utils/funcs';
import { getUserPosition } from 'utils/hooks/auth';

import { RootState } from 'store/rootReducer';

const authDomain = (state: RootState) => state.auth;

// Selector for select auth loading status
const authLoading = createSelector(authDomain, (state) => state?.loading);

// Selector for select auth loading status
const selectCurrentCompanyId = createSelector(authDomain, (state) => state?.companyId);

// Not sure why the company object is never set, so need to find it manually from the list
const selectCurrentCompany = createSelector(authDomain, (state) =>
  state?.companies.find((c) => c.id === state?.companyId),
);

// Selector for select user from identity server
const authUser = createSelector(authDomain, (state) => state?.user);

// Selector for select user from API server
const apiUser = createSelector(authDomain, (state) => state?.userAPI);

// Selector for select user role
const authUserRole = createSelector(apiUser, (state) => state?.applicationRoles ?? 0);

const selectPermissionMatrix = createSelector(authDomain, (state) => state?.permissionMatrix);

// Selector for select user allowed features
const selectAllowedFeatures = createSelector(authUserRole, selectPermissionMatrix, (userRole, matrix) => {
  if (userRole === null || userRole === undefined || !matrix) {
    return false;
  }

  const role = Object.values(ApplicationRole).filter((authUserRoleValue) => authUserRoleValue === userRole);
  const roleType = role[0];

  if (roleType) {
    return matrix[roleType];
  }

  return false;
});

// Selector to check if the feature is allowed to the user
const isFeatureAllowed = createSelector(
  selectAllowedFeatures,
  (_: RootState, { feature, data }: { feature?: string; data?: any }) => ({ data, feature }),
  (allowedFeatures, { feature }) => {
    if (!allowedFeatures || !feature) {
      return false;
    }

    const staticPermissions = allowedFeatures.static;
    if (staticPermissions?.includes(feature)) {
      return true;
    }

    return false;
  },
);

// Named selectors ---------------------------------------------------------------------------------

const createNamedSelectorFunc = (feature: string) =>
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return isFeatureAllowed(state, { feature });
    },
  );

const isUserCanPushToExpedoHRSelector = createNamedSelectorFunc(rbacConstants.placements.pushToExpedoHR);

const isMainDashboardAvailableSelector = createNamedSelectorFunc(rbacConstants.mainDashboard.view);

const isUserCanPostJobAdSelector = createNamedSelectorFunc(rbacConstants.jobAds.postAd);

const isUserCanRePostJobAdSelector = createNamedSelectorFunc(rbacConstants.jobAds.rePostAd);

const isUserCanPublishJobSelector = createNamedSelectorFunc(rbacConstants.jobs.publishNew);

const isUserCanCancelJobSelector = createNamedSelectorFunc(rbacConstants.jobs.cancel);

const isUserCanCompleteJobSelector = createNamedSelectorFunc(rbacConstants.jobs.complete);

const isUserCanDeleteJobSelector = createNamedSelectorFunc(rbacConstants.jobs.delete);

const isUserCanSelectCandidateFoRemoveSelector = createNamedSelectorFunc(
  rbacConstants.talentPools.bulkRemoveCandidatesFromTalentPool,
);

const isUserCanEditJobSelector = createNamedSelectorFunc(rbacConstants.jobs.edit);

const isUserCanEditJobAdSelector = createNamedSelectorFunc(rbacConstants.jobAds.edit);

const selectAvailableCompanyList = createSelector(authDomain, selectCurrentCompanyId, (state, currentCompanyId) =>
  state.companies.filter((company) => company.id !== currentCompanyId),
);

const selectAvailableCompanyListView = createSelector(selectAvailableCompanyList, (companies) =>
  companies.map((company) => ({
    ...company,
    applicationRoleName: getUserPosition(company.applicationRoles)?.applicationRoleName,
  })),
);

const selectCompanyList = createSelector(authDomain, (state) => state.companies);

const selectCompanyListLookup = createSelector(selectCompanyList, (companies) =>
  Object.fromEntries(companies.map((company) => [company.id, company])),
);

const selectIsUserHasAvailableCompany = createSelector(selectCompanyList, (companies) => companies.length > 0);

const createAuthUserSelectors = (userRole: EnumLiteralsOf<typeof ApplicationRole>) =>
  createSelector(selectAvailableCompanyList, (companies) =>
    companies
      .filter((company) => company.applicationRoles === userRole)
      .map((company) => ({
        ...company,
        applicationRoleName: getUserPosition(company.applicationRoles)?.applicationRoleName,
      })),
  );

/**
 * Generate selector for Application roles
 */
type SelectAuthUserSelectorsByRoles = Record<keyof typeof ApplicationRole, ReturnType<typeof createAuthUserSelectors>>;
const selectAuthUserSelectorsByRoles = Object.entries(ApplicationRole).reduce<SelectAuthUserSelectorsByRoles>(
  (acc, [roleName, roleValue]) => {
    const field = roleName as keyof typeof ApplicationRole;
    acc[field] = createAuthUserSelectors(roleValue);
    return acc;
  },
  {} as SelectAuthUserSelectorsByRoles,
);

/**
 * Selector group available companies by user role
 */
const selectCompaniesCombined = createSelector(
  selectAuthUserSelectorsByRoles.Admin,
  selectAuthUserSelectorsByRoles.Manager,
  selectAuthUserSelectorsByRoles.Recruiter,
  selectAuthUserSelectorsByRoles.SuperAdmin,
  (Admin, Manager, Recruiter, SuperAdmin) => ({
    Admin,
    Manager,
    Recruiter,
    SuperAdmin,
  }),
);

export const authSelectors = {
  apiUser,
  authLoading,
  authUser,
  isFeatureAllowed,
  isMainDashboardAvailableSelector,
  isUserCanCancelJobSelector,
  isUserCanCompleteJobSelector,
  isUserCanDeleteJobSelector,
  isUserCanEditJobAdSelector,
  isUserCanEditJobSelector,
  isUserCanPostJobAdSelector,
  isUserCanPublishJobSelector,
  isUserCanPushToExpedoHRSelector,
  isUserCanRePostJobAdSelector,
  isUserCanSelectCandidateFoRemoveSelector,
  selectAvailableCompanyList,
  selectCompanyList,
  selectCompanyListLookup,
  selectAvailableCompanyListView,
  selectAllowedFeatures,
  selectCompaniesCombined,
  selectCurrentCompanyId,
  selectCurrentCompany,
  selectIsUserHasAvailableCompany,
};
