import {
  useCountryQuery,
  useCurrencyQuery,
  useEducationQuery,
  useExperienceQuery,
  useIndustryQuery,
  useSectorQuery,
} from 'store/dictionary/dictionary.api';

export const PreFetch = () => {
  useCountryQuery(undefined);
  useCurrencyQuery(undefined);
  useIndustryQuery(undefined);
  useSectorQuery(undefined);
  useExperienceQuery(undefined);
  useEducationQuery(undefined);

  return null;
};
