import React, { useCallback } from 'react';

import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import { ExModalBodyStyled, ExModalWrapperStyled } from 'containers/Modals/ModalsContent/Company/ExModalComponents';
import { useEntitiesStateWithToggle } from 'containers/Modals/ModalsContent/Company/hooks';
import { JobInterviewTemplateModalFormBody } from 'containers/Modals/ModalsContent/InterviewTemplate/JobInterviewTemplateModalFormBody';
import { modalsSelectors } from 'containers/Modals/store';

import { DialogProps } from 'components/Dialog';
import { ExDialogActionButton, ExDialogActions } from 'components/ui/ExDialog';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { useInterviewTemplateListQuery } from 'store/entities/interview-template/interview-template.api';
import {
  useJobInterviewTemplateListQuery,
  useUpdateJobInterviewTemplateMutation,
} from 'store/entities/job-interview-template/job-interview-template.api';

type JobInterviewTemplateModalProps = {
  className?: string;
} & AppModalContentProps;

const useJobInterviewTemplateModalState = ({ className, onClose }: JobInterviewTemplateModalProps) => {
  const { data: interviewTemplates = [] } = useInterviewTemplateListQuery(
    {
      pageNo: 0,
    },
    {
      selectFromResult: ({ data, ...rest }) => ({
        ...rest,
        data: data?.ids.map((id) => {
          const entity = data.entities[id]!;
          return {
            ...entity,
            interviewTemplateName: entity.name,
          };
        }),
      }),
    },
  );

  const jobId = useAppSelector(modalsSelectors.modalJobId)!;

  const { data: jobInterviewTemplateList = [] } = useJobInterviewTemplateListQuery({
    jobId,
  });

  const [updateJobInterviewTemplate, { isLoading }] = useUpdateJobInterviewTemplateMutation();

  const { entities, setEntities } = useEntitiesStateWithToggle(jobInterviewTemplateList, interviewTemplates);

  const content: DialogProps['content'] = {
    title: 'Update Interview Template',
    withTitle: true,
  };

  const updateJobInterviewTemplateHandler = useCallback(async () => {
    await updateJobInterviewTemplate({ jobId, entities });
    onClose();
  }, [entities, jobId, onClose, updateJobInterviewTemplate]);

  return {
    className,
    onClose,
    content,
    entities,
    setEntities,
    updateJobInterviewTemplateHandler,
    isLoading,
  } as const;
};

export const JobInterviewTemplateModal: React.FC<JobInterviewTemplateModalProps> = (props) => {
  const { className, onClose, content, entities, setEntities, updateJobInterviewTemplateHandler, isLoading } =
    useJobInterviewTemplateModalState(props);

  return (
    <ExModalWrapperStyled className={className} onClose={onClose} content={content}>
      <ExModalBodyStyled>
        <JobInterviewTemplateModalFormBody onChange={setEntities} defaultValue={entities} />
        <ExDialogActions>
          <ExDialogActionButton variant="light" onClick={onClose}>
            Cancel
          </ExDialogActionButton>
          <ExDialogActionButton className="ml-3" onClick={updateJobInterviewTemplateHandler} isLoading={isLoading}>
            Update
          </ExDialogActionButton>
        </ExDialogActions>
      </ExModalBodyStyled>
    </ExModalWrapperStyled>
  );
};
