/* eslint-disable sonarjs/cognitive-complexity */
import { Action, DeepPartial, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { ExRoutes } from 'router/routes';
import difference from 'lodash/difference';
import pick from 'lodash/pick';
import {
  all,
  call,
  delay,
  fork,
  put,
  putResolve,
  SagaReturnType,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import * as jobApi from 'api-endpoints/job';

import { JobAdStatus, JobStatus, JobType } from 'model/api-enums.constants';
import { Unwrap } from 'model/utils';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { updateJobAd } from 'containers/JobAdForms/state/effects';
import { jobFormActions, jobFormSelectors } from 'containers/JobForms/state';
import { jobListSelectors } from 'containers/JobLists/store';
import { loaderActions } from 'containers/Loader/store';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { replaceMergeFieldsWithValues } from 'components/Form/TextEditor/utils';
import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { consoleErrorForDevEnv } from 'utils/consoleErrorForDevEnv';
import { getFileNameFromHeaders } from 'utils/getFileNameFromHeaders';
import { getTranslate } from 'utils/i18utils';
import { ListParams } from 'utils/reducer/reducer-helper';
import { fetchEntity, invokeApiCall, invokeExModal, prepareExModalChannel, ReturnData, worker } from 'utils/sagas';
import { saveFile } from 'utils/saveFile';

import { authSelectors } from 'store/auth/auth.selectors';
import { JobAd, jobAdSelectors } from 'store/entities/job-ads';
import { enhancedJobInterviewTemplateApi } from 'store/entities/job-interview-template/job-interview-template.api';
import { jobScreeningQuestionsSelectors } from 'store/entities/job-screening-questions/job-screening-questions.selectors';
import { jobsActions } from 'store/entities/jobs';
import { JobPutRequestParams, JobPutSalaryRequestParams } from 'store/entities/jobs/api/requests';
import * as jobActions from 'store/entities/jobs/job.actions';
import { JOB_GET_APPLICANTS_PER_STAGE_FOR_JOB_BOARD_CHART_READY } from 'store/entities/jobs/job.constants';
import * as fromJobSagas from 'store/entities/jobs/job-sagas';
import { loadJobWorker } from 'store/entities/jobs/job-sagas/job-load.saga';
import { jobTeamEditWorker } from 'store/entities/jobs/job-sagas/job-team-edit-saga';
import { Job, JobBelongsTo, JobFilters, SortByForJobList } from 'store/entities/jobs/models';
import { jobsSelectors } from 'store/entities/jobs/selectors';
import { screeningQuestionApi } from 'store/entities/screening-question/screening-question.api';
import { exModalHideAction } from 'store/modals/modals.actions';
import { ModalGeneralResult } from 'store/modals/modals.interfaces';

import { enhancedJobApi, JOB_TEAM_TAG_TYPE } from './jobs.api';

const apiJobs = fetchEntity.bind(null, jobActions.jobEntities, jobApi.getJobs);
const apiJobActivityAnalytics = fetchEntity.bind(
  null,
  jobActions.jobActivityAnalyticsHandler,
  jobApi.getJobApplicationActivityAnalytic,
);
const apiUpdateJobStatus = fetchEntity.bind(null, jobActions.updateJobStatusResultHandlers, jobApi.updateJobStatus);
const apiJobCandidateSource = fetchEntity.bind(
  null,
  jobActions.jobCandidateSourceHandler,
  jobApi.getJobCandidateSourceAnalytics,
);
const apiJobUpdate = fetchEntity.bind(null, jobActions.jobUpdateHandler, jobApi.putJob);

//--------------------------------------------------------------------------------------------------

const loadJobsCancelable = worker.bind(null, jobActions.loadJobSPageCancel, apiJobs);
const updateJobsCancelable = worker.bind(null, jobActions.postJobStatusCancel, apiUpdateJobStatus);
const apiJobActivityAnalyticsCancelable = worker.bind(
  null,
  jobActions.jobActivityAnalyticsCancel,
  apiJobActivityAnalytics,
);
const apiJobCandidateSourceCancelable = worker.bind(null, jobActions.jobCandidateSourceCancel, apiJobCandidateSource);
const apiJobUpdateCancelable = worker.bind(null, jobActions.jobUpdateCancel, apiJobUpdate);

function* loadJobsWorker(action: ReturnType<typeof jobActions.loadJobsPage>) {
  yield call(startLoader, action);
  const listId = action.payload.listId;
  const params: ListParams<Job, SortByForJobList, JobFilters> = yield select(jobListSelectors.getListParams, {
    listId,
  });

  if (params.id === JobBelongsTo.dashboard) {
    delete params.filters.pipelineId;
  }

  yield call(loadJobsCancelable, { params, preloader: action.payload.preloader });
}

function* replaceJobScreeningQuestionForJob(jobId?: Job['id']) {
  if (!jobId) {
    return;
  }

  const isQuestionsLocked: boolean = yield select(jobsSelectors.selectIsQuestionsLocked, jobId);

  if (isQuestionsLocked) {
    return;
  }

  const isScreeningQuestionsNotChanged: ReturnType<
    typeof jobScreeningQuestionsSelectors.selectIsScreeningQuestionsNotChanged
  > = yield select(jobScreeningQuestionsSelectors.selectIsScreeningQuestionsNotChanged, jobId);

  if (isScreeningQuestionsNotChanged) {
    return;
  }

  const questions: ReturnType<typeof jobScreeningQuestionsSelectors.selectAll> = yield select(
    jobScreeningQuestionsSelectors.selectAll,
  );

  const mutationAdd = yield call(screeningQuestionApi.endpoints.replaceJobScreeningQuestion.initiate, {
    jobId,
    data: { questions },
  });
  yield putResolve(mutationAdd);
}

function* updateInterviewTemplatesForJob({ jobId, isQuiet }: { jobId?: Job['id']; isQuiet?: boolean }) {
  if (!jobId) {
    return;
  }

  const jobInterviewTemplateListThunk = yield call(
    enhancedJobInterviewTemplateApi.endpoints.jobInterviewTemplateList.initiate,
    { jobId },
    { subscribe: false },
  );

  yield putResolve(jobInterviewTemplateListThunk);

  const formData: ReturnType<typeof jobFormSelectors.selectFormData> = yield select(jobFormSelectors.selectFormData);

  const updateJobInterviewTemplateThunk = yield call(
    enhancedJobInterviewTemplateApi.endpoints.updateJobInterviewTemplate.initiate,
    {
      jobId,
      entities:
        formData.interviewTemplates?.map((id) => ({
          interviewTemplateId: id,
        })) || [],
      isQuiet,
    },
  );

  yield putResolve(updateJobInterviewTemplateThunk);
}

function* updateHiringManagersForJob(jobId?: Job['id']) {
  if (!jobId) {
    return;
  }

  const jobLineManagersNotRemoved: ReturnType<typeof jobsSelectors.selectJobLineManagersNotRemoved> = yield select(
    jobsSelectors.selectJobLineManagersNotRemoved,
    jobId,
  );

  const jobLineManagerNotRemovedIds = jobLineManagersNotRemoved?.map((member) => member.userId);

  const hiringManagers: ReturnType<typeof jobFormSelectors.selectHiringManagersSelected> = yield select(
    jobFormSelectors.selectHiringManagersSelected,
  );

  const hiringManagersIds = hiringManagers?.map((member) => member.userId);

  const addHiringManagerIds = difference(hiringManagersIds, jobLineManagerNotRemovedIds);
  const removeHiringManangerIds = difference(jobLineManagerNotRemovedIds, hiringManagersIds);

  if (!addHiringManagerIds.length && !removeHiringManangerIds.length) {
    return;
  }

  const actions: any[] = [];

  addHiringManagerIds.forEach((userId) => {
    actions.push(
      invokeApiCall(jobApi.addMemberToJobTeamFunc, {
        urlParams: { jobId },
        data: { jobId, userId },
      }),
    );
  });

  removeHiringManangerIds.forEach((userId) => {
    actions.push(
      invokeApiCall(jobApi.removeMemberFromJobTeamFunc, {
        urlParams: { jobId },
        data: { jobId, userId },
      }),
    );
  });

  yield all(actions);

  yield putResolve(enhancedJobApi.util.invalidateTags([{ type: JOB_TEAM_TAG_TYPE, id: 'LIST' }]));
}

function* prepareUpdateJobData(restData: DeepPartial<Job>, jobId: Job['id']) {
  const job: SagaReturnType<typeof jobsSelectors.getById> = yield select(jobsSelectors.getById, jobId);

  const data = pick<DeepPartial<Job>, jobApi.PutJobFields>({ ...job, ...restData }, [
    'id',
    'jobId',
    'jobType',
    'jobName',
    'referenceNo',
    'position',
    'employmentType',
    'vacancies',
    'countryHiringFrom',
    'jobLocation',
    'description',
    'requirements',
    'responsibilities',
    'industryId',
    'experience',
    'education',
    'salary',
    'hideSalary',
    'pipelineId',
    'listOnCareerPage',
  ]);

  data.description = replaceMergeFieldsWithValues(data?.description);

  data.vacancies = Number(data?.vacancies);

  const salaryMin = Number(data?.salary?.moneyRange?.minimum) || null;
  const salaryMax = Number(data?.salary?.moneyRange?.maximum) || null;

  data.salary = data?.salary
    ? {
        ...data.salary,
        moneyRange: {
          minimum: salaryMin,
          maximum: salaryMax,
        },
      }
    : undefined;

  return {
    data,
    oldData: job,
  };
}

function* saveJobWorker({ asOpen, userCanPublishJob }: { asOpen?: boolean; userCanPublishJob: boolean }) {
  const formData: ReturnType<typeof jobFormSelectors.selectFormData> = yield select(jobFormSelectors.selectFormData);

  const { data: requestData }: SagaReturnType<typeof prepareUpdateJobData> = yield call(
    prepareUpdateJobData,
    formData,
    formData.jobId,
  );

  const { errorData, message }: ReturnData<typeof jobApi.putJob> = yield call(invokeApiCall, jobApi.putJob, {
    urlParams: { jobId: formData.jobId },
    data: requestData,
  });

  if ([errorData, message].some(Boolean)) {
    if (errorData.validationErrorCodes) {
      yield put(jobFormActions.setApiErrors({ apiErrors: errorData.validationErrorCodes }));
    }

    throw new Error(message);
  }

  const { data } = yield call(invokeApiCall, jobApi.getJob, { urlParams: { jobId: formData.jobId } });

  yield all([
    put(jobsActions.updateOne({ id: data.jobId, ...data })),
    put(jobActions.loadJobPage({ jobId: data.jobId })),
  ]);

  if (asOpen && userCanPublishJob) {
    const resultInvokeApiCallUpdateJobStatus: ReturnData<typeof jobApi.updateJobStatus> = yield call(
      invokeApiCall,
      jobApi.updateJobStatus,
      {
        urlParams: { jobId: formData.jobId, status: 'open' },
        data: { jobType: JobType.Unlisted },
      },
    );

    if ([resultInvokeApiCallUpdateJobStatus.errorData, resultInvokeApiCallUpdateJobStatus.message].some(Boolean)) {
      throw new Error(resultInvokeApiCallUpdateJobStatus.message);
    }

    yield put(jobsActions.upsertOne({ item: { id: formData.jobId, jobId: formData.jobId, status: JobStatus.open } }));
  }
}

function* updateJobAds(jobId) {
  const jobAdsToUpdate = yield select(jobAdSelectors.jobAdsForJobIdSelectorByStatus, {
    jobId,
    status: JobAdStatus.Active,
  });
  const job = yield select(jobsSelectors.getById, jobId);
  const adData: Partial<JobAd> = {
    jobAdLocation: job.jobLocation,
    description: job.descriptionRendered,
    jobName: job.jobName,
  };

  jobAdsToUpdate.forEach((jobAd) => {
    updateJobAd({ data: adData, jobAdId: jobAd.id });
  });
}

function* createJobWorker(action: ReturnType<typeof jobActions.jobCreateRequest>) {
  const { asOpen, isOpenEdit, isAutosave } = action.payload;

  yield call(startLoader, action);

  if (isAutosave) {
    yield put(jobFormActions.update({ isAutosaving: true }));
  }

  if (asOpen || isOpenEdit) {
    yield put(jobFormActions.update({ isOpening: true }));
  }

  const formData: ReturnType<typeof jobFormSelectors.selectFormData> = yield select(jobFormSelectors.selectFormData);

  const userCanPublishJob: ReturnType<typeof authSelectors.isUserCanPublishJobSelector> = yield select(
    authSelectors.isUserCanPublishJobSelector,
  );

  try {
    if (formData.jobId) {
      yield call(saveJobWorker, { asOpen, userCanPublishJob });

      yield all([
        // update interview templates
        call(updateInterviewTemplatesForJob, { jobId: formData.jobId, isQuiet: true }),
        // update hiring managers
        call(updateHiringManagersForJob, formData.jobId),
        // update screening questions
        call(replaceJobScreeningQuestionForJob, formData.jobId),
        // update ads (but do not publish to idibu)
        call(updateJobAds, formData.jobId),
      ]);
    } else if (asOpen && userCanPublishJob) {
      /**
       * Here we try to create the job and mark it as open
       */
      // probably impossible scenario for current implementation
    } else {
      /**
       * Here we create job as draft
       */
      const { data: createDraftData }: SagaReturnType<typeof prepareUpdateJobData> = yield call(
        prepareUpdateJobData,
        formData,
        formData.jobId,
      );

      const { data, errorData, message }: ReturnData<typeof jobApi.postJob> = yield call(
        invokeApiCall,
        jobApi.postJob,
        {
          data: createDraftData,
        },
      );

      if ([errorData, message].some(Boolean)) {
        if (errorData.validationErrorCodes) {
          yield put(jobFormActions.setApiErrors({ apiErrors: errorData.validationErrorCodes }));
        }

        throw new Error(message);
      }
      /**
       * Here we merge jobId was returned from API and existed form data.
       * This is needed to save jobId into the form and open job with existed id.
       */

      yield all([
        put(
          jobFormActions.saveForm({
            form: {
              id: data?.jobId,
              jobId: data?.jobId,
            },
          }),
        ),
        put(jobsActions.updateOne({ id: data?.jobId, ...data })),
        putResolve(jobActions.loadJobPage({ jobId: data?.jobId })),
      ]);

      yield all([
        // update interview templates
        call(updateInterviewTemplatesForJob, { jobId: data?.jobId, isQuiet: true }),
        // update hiring managers
        call(updateHiringManagersForJob, data?.jobId),
        // update screening questions
        call(replaceJobScreeningQuestionForJob, data?.jobId),
      ]);
    }

    /**
     * If we try to open job we must go to the job promote tab
     */
    if (asOpen && formData.jobId) {
      yield put(push(ExRoutes.jobJobAds({ jobId: formData.jobId })));
    }

    if (isAutosave) {
      yield put(jobFormActions.toggleIsDraftSavedSteps({ isDraftSaved: true }));
    }

    if (!isAutosave) {
      yield put(alertsEffects.showSuccess({ message: 'Job successfully saved' }));
    }
  } catch (e) {
    consoleErrorForDevEnv(e);
    return;
  } finally {
    yield call(stopLoader, action);

    if (isAutosave) {
      yield put(jobFormActions.update({ isAutosaving: false }));
    }

    if (asOpen || isOpenEdit) {
      yield put(jobFormActions.update({ isOpening: false }));
    }
  }
}

function* updateJobWorker(action: ReturnType<typeof jobActions.jobUpdateRequest>) {
  yield call(startLoader, action);

  const { data }: SagaReturnType<typeof prepareUpdateJobData> = yield call(
    prepareUpdateJobData,
    action.payload.data,
    action.payload.data.id!,
  );
  yield call(apiJobUpdateCancelable, { urlParams: { jobId: data.jobId! }, data });

  yield call(stopLoader, action);
}

function* getApplicantsPerStageForJobBoardFuncSaga(
  action: ReturnType<typeof jobActions.getApplicantsPerStageForJobBoardAction.start>,
) {
  yield put(loaderActions.start(JOB_GET_APPLICANTS_PER_STAGE_FOR_JOB_BOARD_CHART_READY));

  const jobId = action.payload.jobId;

  const { data, errorData, message }: Unwrap<ReturnType<typeof jobApi.getApplicantsPerStageForJobBoardFunc>> =
    yield invokeApiCall(jobApi.getApplicantsPerStageForJobBoardFunc, { urlParams: { jobId } });

  if (!errorData && !message) {
    yield all([put(jobsActions.upsertOne({ item: { id: jobId, ...data } }))]);
  }
  yield delay(1000);
  yield put(loaderActions.stop(JOB_GET_APPLICANTS_PER_STAGE_FOR_JOB_BOARD_CHART_READY));
}

function* getApplicantsRecievedPerDayForJobFuncSaga(
  action: ReturnType<typeof jobActions.getApplicantsRecievedPerDayForJobFuncAction.start>,
) {
  const { jobId } = action.payload;
  const { data, errorData, message }: ReturnData<typeof jobApi.getApplicantsReceivedPerDayForJobFunc> =
    yield invokeApiCall(
      jobApi.getApplicantsReceivedPerDayForJobFunc,
      { urlParams: { jobId } },
      { action: jobActions.getApplicantsRecievedPerDayForJobFuncAction as any },
    );

  if (!errorData && !message) {
    yield put(jobsActions.upsertOne({ item: { id: jobId, ...data } }));
  }
}
function* getJobDeadlineFuncSaga(action: ReturnType<typeof jobActions.getJobDeadlineFuncAction.start>) {
  const { jobId } = action.payload;
  const { data, errorData, message }: Unwrap<ReturnType<typeof jobApi.getJobDeadlineFunc>> = yield invokeApiCall(
    jobApi.getJobDeadlineFunc,
    {
      urlParams: { jobId },
    },
    { action: jobActions.getJobDeadlineFuncAction as any },
  );
  const daysToDeadline = data.data.Value;

  if (!errorData && !message) {
    yield all([put(jobsActions.upsertOne({ item: { id: jobId, daysToDeadline } }))]);
  }
}

function* getJobDaysOpenFuncSaga(action: ReturnType<typeof jobActions.getJobDeadlineFuncAction.start>) {
  const { jobId } = action.payload;
  const { data, errorData, message }: Unwrap<ReturnType<typeof jobApi.getJobDaysOpenFunc>> = yield invokeApiCall(
    jobApi.getJobDaysOpenFunc,
    {
      urlParams: { jobId },
    },
    { action: jobActions.getJobDaysOpenFuncAction as any },
  );

  const daysOpen = data.data.Value;

  if (!errorData && !message) {
    yield all([put(jobsActions.upsertOne({ item: { id: jobId, daysOpen } }))]);
  }
}

function* getJobAcceptanceRateFuncSaga(action: ReturnType<typeof jobActions.getJobAcceptanceRateFuncAction.start>) {
  const { jobId } = action.payload;
  const { data, errorData, message }: ReturnData<typeof jobApi.getJobAcceptanceRateFunc> = yield invokeApiCall(
    jobApi.getJobAcceptanceRateFunc,
    {
      urlParams: { jobId },
    },
    { action: jobActions.getJobAcceptanceRateFuncAction as any },
  );

  if (!errorData && !message && data) {
    const acceptanceRate = data.data.Value;
    yield all([put(jobsActions.upsertOne({ item: { id: jobId, acceptanceRate } }))]);
  }
}

function* getJobAverageTimeToHireFuncSaga(
  action: ReturnType<typeof jobActions.getJobAverageTimeToHireFuncAction.start>,
) {
  const { jobId } = action.payload;
  const { data, errorData, message }: ReturnData<typeof jobApi.getJobAverageTimeToHireFunc> = yield invokeApiCall(
    jobApi.getJobAverageTimeToHireFunc,
    {
      urlParams: { jobId },
    },
    { action: jobActions.getJobAverageTimeToHireFuncAction as any },
  );

  if (!errorData && !message && data) {
    const averageTimeToHire = data.data.Value;
    yield all([put(jobsActions.upsertOne({ item: { id: jobId, averageTimeToHire } }))]);
  }
}

function* jobSalaryEditWorker(action: Action) {
  const { modalId, sagaChannel } = yield prepareExModalChannel();
  yield fork(invokeExModal as any, {
    channel: sagaChannel,
    modalId,
    modalType: ModalsTypeKey.editJobSalary,
  });

  while (true) {
    /**
     * We stop loader on the start of saga to prevent loader stuck on internal server errors;
     */
    yield call(stopLoader, action);

    const { confirm, cancel }: ModalGeneralResult = yield take(sagaChannel);

    if (cancel || !confirm) {
      yield put(exModalHideAction({ id: modalId }));
      return;
    }
    yield call(startLoader, action);
    const requestPayload = confirm.payload.modalResult as JobPutSalaryRequestParams;

    const { data, errorData, message }: ReturnData<typeof jobApi.putJobSalary> = yield invokeApiCall(
      jobApi.putJobSalary,
      requestPayload,
    );

    /**
     * Restart edit flow due to backend errors;
     */
    if (errorData || message || !requestPayload) {
      continue;
    }

    yield all([
      put(exModalHideAction({ id: modalId })),
      put(jobsActions.updateOne({ ...data, salary: requestPayload.data } as any)),
      put(alertsEffects.showSuccess({ message: getTranslate('job.salary.update.success') })),
    ]);

    yield call(stopLoader, action);
    break;
  }
}

function* jobEditWorker(action: PayloadAction<ModalsTypeKey>) {
  const successMessagePaths = {
    [ModalsTypeKey.editJobRequirements]: 'job.requirements.update.success',
    [ModalsTypeKey.editJobResponsibilities]: 'job.responsibilities.update.success',
    [ModalsTypeKey.editJobDescription]: 'job.description.update.success',
    [ModalsTypeKey.editJobDetails]: 'job.details.update.success',
  };
  const successMessagePath = successMessagePaths[action.payload];

  const { modalId, sagaChannel } = yield prepareExModalChannel();
  yield fork(invokeExModal as any, {
    channel: sagaChannel,
    modalId,
    modalType: action.payload,
  });

  while (true) {
    /**
     * We stop loader on the start of saga to prevent loader stuck on internal server errors;
     */
    yield call(stopLoader, action);

    const { confirm, cancel }: ModalGeneralResult<JobPutRequestParams> = yield take(sagaChannel);

    if (cancel || !confirm) {
      yield put(exModalHideAction({ id: modalId }));
      return;
    }

    const requestPayload = confirm.payload.modalResult!;

    const { data: requestData }: SagaReturnType<typeof prepareUpdateJobData> = yield call(
      prepareUpdateJobData,
      requestPayload.data,
      requestPayload.urlParams.jobId,
    );

    yield call(startLoader, action);

    yield put(jobFormActions.saveForm({ form: requestPayload.data }));

    const { data, errorData, message }: ReturnData<typeof jobApi.putJob> = yield invokeApiCall(jobApi.putJob, {
      ...requestPayload,
      data: requestData,
    });

    if (errorData) {
      yield put(jobFormActions.setApiErrors({ apiErrors: errorData.validationErrorCodes }));
    }

    yield call(stopLoader, action);

    /**
     * Stop edit flow;
     */
    if (!errorData && !message) {
      yield all([
        put(exModalHideAction({ id: modalId })),
        put(jobsActions.updateOne({ id: data.jobId, ...data })),
        put(jobActions.loadJobPage({ jobId: data.jobId })),
        put(alertsEffects.showSuccess({ message: getTranslate(successMessagePath) })),
      ]);
      break;
    }
  }
}

function* jobTeamFetchWorker(action: ReturnType<typeof jobActions.jobTeamFetch>) {
  yield call(startLoader, action);
  const requestPayload = action.payload;
  const { data, errorData, message }: ReturnData<typeof jobApi.getJobTeamFunc> = yield invokeApiCall(
    jobApi.getJobTeamFunc,
    requestPayload,
  );

  if ([errorData, message].some(Boolean)) {
    return;
  }

  const updatePayload: Partial<Job> = { id: requestPayload.urlParams.jobId, ...data };

  yield put(jobsActions.updateOne(updatePayload));
  yield call(stopLoader, action);
}

function* jobTeamMemberCreateWorker(action: ReturnType<typeof jobActions.jobTeamMemberCreate>) {
  const requestPayload = action.payload;
  const requestParams = { urlParams: requestPayload, data: requestPayload };
  yield invokeApiCall(jobApi.addMemberToJobTeamFunc, requestParams);

  yield put(jobActions.jobTeamMemberProcessingFinished());
}

function* jobTeamMemberRemoveWorker(action: ReturnType<typeof jobActions.jobTeamMemberRemove>) {
  const requestPayload = action.payload;
  const requestParams = { urlParams: requestPayload, data: requestPayload };
  yield invokeApiCall(jobApi.removeMemberFromJobTeamFunc, requestParams);

  yield put(jobActions.jobTeamMemberProcessingFinished());
}

function* jobBulkDownloadFilesWorker(action: ReturnType<typeof jobActions.jobBulkDownloadFilesAction>) {
  const requestParams = action.payload;
  const { data, headers }: { data: Blob; headers: any } = yield call(jobApi.bulkDownloadFilesFunc as any, {
    ...requestParams,
    responseType: 'blob',
  });
  const fileName = yield call(getFileNameFromHeaders, headers);
  saveFile(data, fileName);
}

export function* jobWorkers() {
  yield takeLatest(jobActions.loadJobPage, loadJobWorker);
  /**
   * takeEvery used instead of takeLatest because there an issue on the job card list view.
   *
   * If user will refresh the browser on the job list page takeLatest cancels the first API call
   * and the user will see list jobs started from the second page
   */
  yield takeEvery(jobActions.loadJobsPage, loadJobsWorker);
  yield takeLatest(jobActions.updateJobStatusAction, updateJobsCancelable);
  yield takeLatest(jobActions.jobActivityAnalyticsRequest, apiJobActivityAnalyticsCancelable);
  yield takeLatest(jobActions.jobCandidateSourceRequest, apiJobCandidateSourceCancelable);
  yield takeLatest(jobActions.jobCreateRequest, createJobWorker);
  yield takeLatest(jobActions.jobUpdateRequest, updateJobWorker);
  yield takeLatest(jobActions.getApplicantsPerStageForJobBoardAction.start, getApplicantsPerStageForJobBoardFuncSaga);
  yield takeLatest(
    jobActions.getApplicantsRecievedPerDayForJobFuncAction.start,
    getApplicantsRecievedPerDayForJobFuncSaga,
  );
  yield takeLatest(jobActions.getJobDeadlineFuncAction.start, getJobDeadlineFuncSaga);
  yield takeLatest(jobActions.getJobDaysOpenFuncAction.start, getJobDaysOpenFuncSaga);
  yield takeLatest(jobActions.getJobAcceptanceRateFuncAction.start, getJobAcceptanceRateFuncSaga);
  yield takeLatest(jobActions.getJobAverageTimeToHireFuncAction.start, getJobAverageTimeToHireFuncSaga);
  yield takeEvery(jobActions.jobSalaryEdit, jobSalaryEditWorker);
  yield takeEvery(
    [
      jobActions.jobDetailsEdit,
      jobActions.jobDescriptionEdit,
      jobActions.jobRequirementsEdit,
      jobActions.jobResponsibilitiesEdit,
    ],
    jobEditWorker,
  );
  yield takeEvery(jobActions.jobTeamFetch, jobTeamFetchWorker);
  yield takeEvery(jobActions.jobTeamEdit, jobTeamEditWorker);
  yield takeEvery(jobActions.jobTeamMemberCreate, jobTeamMemberCreateWorker);
  yield takeEvery(jobActions.jobTeamMemberRemove, jobTeamMemberRemoveWorker);
  yield takeEvery(jobActions.jobBulkDownloadFilesAction, jobBulkDownloadFilesWorker);
  const sagas = [...Object.values(fromJobSagas)];
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            consoleErrorForDevEnv(e);
          }
        }
      }),
    ),
  );
}
