import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { unwrapResult } from '@reduxjs/toolkit';
import styled from 'styled-components';

import { AppFile } from 'model';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { applicantFormActions } from 'containers/ApplicantForm/ApplicantFormState';
import { ApplicantForm } from 'containers/ApplicantForm/ApplicantFormState/applicant-form.models';
import { formApplicantKey } from 'containers/ApplicantForm/ApplicantFormState/applicant-form.reducer';
import { loaderSelectors } from 'containers/Loader/store';

import { AppFileCard } from 'components/AppFileCard';
import { AppFileCardLoading } from 'components/AppFileCard/AppFileCardLoading';
import { AppFileCardNew } from 'components/AppFileCard/AppFileCardNew';
import { StepComponent, StepDescription } from 'components/StepForm';
import { ExCard } from 'components/ui/ExCard';
import { FILE_INPUT_ACCEPT_RESUME, fileValidationRuleTypeResume } from 'utils/files';
import { isResumeFile } from 'utils/funcs';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useOnChangeFilewithValidation } from 'utils/hooks/useFileInputValidation';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getTranslate } from 'utils/i18utils';

import { resumesSelectors } from 'store/app-files/resumes/index';
import {
  createResumeFetchLoaderId,
  createResumeUploadLoaderId,
  loadResumeFiles,
  uploadResumeFiles,
} from 'store/app-files/resumes/resumes.effects';
import { resumesActions } from 'store/app-files/resumes/resumes.reducer';
import { Applicant } from 'store/entities/applicants';

export interface NewApplicantSelectJobStepProps extends StepComponent {
  className?: string;
  formData: ApplicantForm;
}

const fileValidationOptions = [fileValidationRuleTypeResume];

const NewApplicantSelectResumeStep: React.FC<NewApplicantSelectJobStepProps> = ({ className, formData }) => {
  const { register, errors, setValue, formState } = useFormContext<Required<Pick<Applicant, 'resumeId'>>>();
  const candidateId = formData.candidateId;
  const listName = formApplicantKey + candidateId;
  const dispatch = useAppDispatch();

  const memoSelectedItemsIds = useMemo(() => resumesSelectors.selectedItemsIds(), []);
  const selectedIds = useAppSelector(memoSelectedItemsIds, listName);

  const memoPageEntitiesWithSelected = useMemo(() => resumesSelectors.pageEntitiesWithSelected(), []);
  const candidateResumes = useAppSelector(memoPageEntitiesWithSelected, listName);

  const isResumeFetching = useAppSelector(loaderSelectors.isTaskActive, {
    taskId: createResumeFetchLoaderId(candidateId),
  });

  const isResumeUploading = useAppSelector(loaderSelectors.isTaskActive, {
    taskId: createResumeUploadLoaderId(candidateId),
  });

  const isResumeLoading = isResumeFetching || isResumeUploading;

  const setSelected = useCallback(
    (id: string | undefined, resume?: AppFile) => {
      dispatch(resumesActions.setSelected({ listName, selectedIds: id }));
      if (resume && isResumeFile(resume)) {
        dispatch(applicantFormActions.setState({ selectedResumes: [resume] }));
      }
    },
    [dispatch, listName],
  );

  useEffect(() => {
    register('resumeId');
    if (candidateId) {
      dispatch(
        loadResumeFiles({
          candidateId,
          listName,
          pageParams: {
            pageSize: 100,
          },
          preloader: true,
        }),
      );
    }
    if (formData.resumeId && !selectedIds?.length) {
      setSelected(formData.resumeId);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onItemClick = (item: AppFile) => {
    if (isResumeFile(item)) {
      setSelected(item.resumeId, item);
    }
  };

  const uploadHandler = useCallback(
    async (files: File[]) => {
      if (candidateId) {
        const formWithFiles = new FormData();
        files.forEach((file) => formWithFiles.append('resume', file, file.name));
        const resultAction = await dispatch(
          uploadResumeFiles({ candidateId, formData: formWithFiles, preloader: true }),
        );
        const success = unwrapResult(resultAction);
        if (typeof success !== 'boolean') {
          const resumeId = success.resumeId;
          setSelected(resumeId, success);
          dispatch(alertsEffects.showSuccess({ message: 'Resume successfully uploaded' }));
          dispatch(
            loadResumeFiles({
              candidateId,
              listName,
              pageParams: {
                pageSize: 100,
              },
              preloader: true,
            }),
          );
        }
      }
    },
    [dispatch, candidateId, listName, setSelected],
  );

  const { onChangeFileWithValidation } = useOnChangeFilewithValidation({
    options: fileValidationOptions,
    withToasts: true,
    onSuccess: uploadHandler,
  });

  useEffect(() => {
    if (selectedIds) {
      setValue('resumeId', selectedIds[0], { shouldValidate: formState.isSubmitted });
    }
  }, [selectedIds]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const error = errors.resumeId;
    if (error?.message) {
      dispatch(alertsEffects.showError({ message: error.message.toString() }));
    }
  }, [dispatch, errors]);

  return (
    <div className={className}>
      <StepDescription>
        <StepDescription.Title>Step 3: Resumes</StepDescription.Title>
        <StepDescription.Description>{getTranslate('new_applicant.step_3.description')}</StepDescription.Description>
      </StepDescription>
      <ExCard title="Resumes">
        <div className="row">
          <div className="col-auto">
            <AppFileCardNew
              accept={FILE_INPUT_ACCEPT_RESUME}
              disabled={isResumeLoading}
              onChange={onChangeFileWithValidation}
            />
          </div>
          {isResumeLoading && (
            <div className="col-auto">
              <AppFileCardLoading />
            </div>
          )}
          {!isResumeLoading &&
            candidateResumes?.map((resume) => (
              <div className="col-auto" key={resume.resumeId}>
                <AppFileCard item={resume} onClick={onItemClick} isCheckable={true} />
              </div>
            ))}
        </div>
      </ExCard>
    </div>
  );
};

export default styled(NewApplicantSelectResumeStep)``;
