import { createSelector } from '@reduxjs/toolkit';

import { candidatesSelectors } from 'store/entities/candidates/selectors';
import { jobsSelectors } from 'store/entities/jobs/selectors';
import { RootState } from 'store/rootReducer';

export const domain = (state: RootState) => state.formApplicant;

export const selectFormData = createSelector(domain, (state) => state.formData);

export const selectJobId = createSelector(selectFormData, (formData) => formData.jobId);

export const selectJob = createSelector(selectJobId, jobsSelectors.getLookup, (jobId, entities) => {
  if (jobId && entities) {
    return entities[jobId];
  }
});

export const selectCandidateId = createSelector(selectFormData, (formData) => formData.candidateId);

export const selectCandidate = createSelector(
  selectCandidateId,
  candidatesSelectors.getLookup,
  (candidateId, entities) => {
    if (candidateId && entities) {
      return entities[candidateId];
    }
  },
);

export const selectValidSteps = createSelector(domain, (state) => state.validSteps);

export const selectShowSteps = createSelector(domain, (state) => state.showSteps);

export const selectReviewStep = createSelector(domain, (state) => ({
  resumes: state.selectedResumes,
  documents: state.selectedDocuments,
}));
