import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from 'router';
import styled from 'styled-components';

import { jobFormActions, jobFormEffects, jobFormSelectors } from 'containers/JobForms/state';
import { loaderSelectors } from 'containers/Loader/store';

import { ReactComponent as Image } from 'assets/img/new-job-final-page.svg';

import { ArrowForward, StepComponent } from 'components/StepForm';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';

import { RootState } from 'store/rootReducer';

export interface JobFinalPageProps extends StepComponent {
  className?: string;
}

const JobFinalPage: React.FC<JobFinalPageProps> = ({ className }) => {
  const formData = useSelector(jobFormSelectors.selectFormData);
  useEffect(() => {
    dispatch(jobFormActions.toggleShowSteps({ showSteps: false }));
    return () => {
      dispatch(jobFormActions.toggleShowSteps({ showSteps: true }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const loading = useSelector<RootState>((state) =>
    loaderSelectors.isTaskActive(state, { taskId: jobFormEffects.saveJobAsDraftProcess }),
  );
  useEffect(() => {
    if (!loading && !formData.jobId) {
      history.push(Routes.jobFormStepDetails);
    }
  }, [loading, formData.jobId, formData, history]);
  return (
    <div className={className}>
      <h3 className="mb-3">Job Created</h3>
      <div>
        <Image />
      </div>
      <div className="message">You have completed the steps required to create a new job.</div>
      <h4 className="mb-4">What’s Next?</h4>
      <div className="d-flex justify-content-center">
        <ExButton variant="success" forwardedAs={Link} to={`/${Routes.jobs}/${formData.jobId}/overview`}>
          View Job {<ArrowForward />}
        </ExButton>
        <ExButton
          variant="success"
          className="ml-5"
          forwardedAs={Link}
          to={`/${Routes.jobBoards}/${Routes.jobAdNew}/${Routes.jobAdSelectJob}`}
        >
          Create Job Ad {<ArrowForward />}
        </ExButton>
      </div>
    </div>
  );
};

export default styled(JobFinalPage)`
  margin: 4% auto 0;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 792px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 473px;
  border-radius: ${getThemeBorderProps('borderRadius')};
  border: 1px solid ${(props) => props.theme.border.borderColor};
  background: white;

  .message {
    font-size: 20px;
    margin-bottom: 60px;
  }
`;
