import { createAction } from '@reduxjs/toolkit';

import { UserCompany } from 'api-endpoints/user/user.model';

import { authReducerName, authSliceActions } from './auth.reducer';

const getCurrentAuthApiUser = 'currentAuthApiUser/get';
const companyFromQueryParams = 'currentCompanyFromQueryParams/get';

const authLogout = `${authReducerName}/logout`;
const getAuthUserCompanies = `${authReducerName}/companies/get`;
const authCompanySwitch = `${authReducerName}/company/switch`;
const authCompanyCancelWatcher = `${authReducerName}/company/switch/cancel`;
const appInitData = `${authReducerName}/init`;

export const appInitDataAction = createAction(appInitData);

export const getCurrentAuthApiUserAction = createAction(getCurrentAuthApiUser);

export const companyFromQueryParamsAction = createAction<{ companies: UserCompany[] }>(companyFromQueryParams);

export const authCompanyCancelWatcherAction = createAction(authCompanyCancelWatcher);

export const getAuthUserCompaniesAction = createAction(getAuthUserCompanies);

export const authLogoutAction = createAction(authLogout);

export const authCompanySwitchAction =
  createAction<{ companyId: UserCompany['id'] | null; redirectTo?: string }>(authCompanySwitch);

export const authActions = {
  ...authSliceActions,
  getCurrentAuthApiUserAction,
  companyFromQueryParamsAction,
  getAuthUserCompaniesAction,
  authCompanySwitchAction,
  appInitData,
  appInitDataAction,
  authCompanyCancelWatcherAction,
  authLogoutAction,
};
