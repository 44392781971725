import {
  ActionCreatorWithPreparedPayload,
  EntityAdapter,
  EntityId,
  EntityState,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import xor from 'lodash/xor';

import { ApiResponseWithPagination } from 'model';
import { LoadPaginationParams } from 'model/reducers.models';

import { PaginationState } from 'store/app-files/appFiles.models';
import { AppEffectParams } from 'store/types';

const helperPending = <
  T,
  Q,
  S extends EntityState<T> & PaginationState<T, Q>,
  A extends ReturnType<
    ActionCreatorWithPreparedPayload<
      [string, AppEffectParams<LoadPaginationParams<Q>>],
      undefined,
      string,
      never,
      {
        arg: AppEffectParams<LoadPaginationParams<Q>>;
        requestId: string;
      }
    >
  >,
>(
  state: S,
  action: A,
) => {
  type ReversDict<Dictionary> = Dictionary[keyof Dictionary];
  type MostRecent = ReversDict<PaginationState<T, Q>['pagination']['lists']>['mostRecent'];
  const { meta } = action;
  const requestId = meta.requestId;
  const { pageParams = {}, queryParams = {}, listName = 'default' } = meta.arg;
  const pParams = {
    pageNo: state.pagination.defaultPageNo,
    pageSize: state.pagination.defaultPageSize,
    ...pageParams,
  };
  const pageId = JSON.stringify(pParams);
  const queryId = JSON.stringify(queryParams);

  const mostRecent: MostRecent = {
    pageId,
    pageParams: pParams,
    queriedAt: Date.now(),
    query: queryParams,
    queryId,
    queryParams: {
      ...queryParams,
    },
  };
  if (!state.pagination.lists[listName]) {
    state.pagination.lists[listName] = {};
  }

  delete state.pagination.lists[listName].error;
  state.pagination.lists[listName].mostRecent = mostRecent;
  state.pagination.lists[listName].loading = true;
  state.pagination.requests[requestId] = mostRecent;
};

const helperRejected = <
  T,
  Q,
  S extends EntityState<T> & PaginationState<T, Q>,
  A extends ReturnType<
    ActionCreatorWithPreparedPayload<
      [Error | null, string, AppEffectParams<LoadPaginationParams<Q>>, undefined?],
      string | undefined,
      string,
      SerializedError,
      {
        arg: AppEffectParams<LoadPaginationParams<Q>>;
        requestId: string;
        aborted: boolean;
        condition: boolean;
      }
    >
  >,
>(
  state: S,
  action: A,
) => {
  type ReversDict<Dictionary> = Dictionary[keyof Dictionary];
  type MostRecent = ReversDict<PaginationState<T, Q>['pagination']['lists']>['mostRecent'];
  const { payload, meta } = action;
  const { requestId, condition } = meta;
  const { pageParams = {}, queryParams = {}, listName = 'default' } = meta.arg;

  if (!state.pagination.lists[listName]) {
    state.pagination.lists[listName] = {};
  }

  if (condition) {
    const pParams = {
      pageNo: state.pagination.defaultPageNo,
      pageSize: state.pagination.defaultPageSize,
      ...pageParams,
    };
    const pageId = JSON.stringify(pParams);
    const queryId = JSON.stringify(queryParams);

    const mostRecent: MostRecent = {
      pageId,
      pageParams: pParams,
      queriedAt: Date.now(),
      query: queryParams,
      queryId,
      queryParams: {
        ...queryParams,
      },
    };
    state.pagination.lists[listName].mostRecent = mostRecent;
  }

  delete state.pagination.requests[requestId];
  state.pagination.lists[listName].loading = false;
  state.pagination.lists[listName].error = payload;
};

const helperFulfilled =
  <
    T,
    Q,
    S extends EntityState<T> & PaginationState<T, Q>,
    A extends ReturnType<
      ActionCreatorWithPreparedPayload<
        [ApiResponseWithPagination<T>, string, AppEffectParams<LoadPaginationParams<Q>>],
        ApiResponseWithPagination<T>,
        string,
        never,
        {
          arg: AppEffectParams<LoadPaginationParams<Q>>;
          requestId: string;
        }
      >
    >,
  >(
    adapter: EntityAdapter<T>,
  ) =>
  (state: S, action: A) => {
    const queriedAt = Date.now();
    const { payload, meta } = action;
    const requestId = meta.requestId;
    const { items, pageCount, pageNo, pageSize, totalItemsCount } = payload;
    const { listName = 'default' } = meta.arg;
    const requestList = state.pagination.requests[requestId];
    const { queryId, pageId, pageParams, queryParams } = requestList;

    state.pagination.lists[listName].mostRecent = requestList;

    if (!state.pagination.lists[listName][queryId]) {
      state.pagination.lists[listName][queryId] = {};
    }
    state.pagination.lists[listName][queryId].queryParams = queryParams;

    if (!state.pagination.lists[listName][queryId][pageId]) {
      state.pagination.lists[listName][queryId][pageId] = {};
    }
    state.pagination.lists[listName][queryId][pageId].pageCount = pageCount;
    state.pagination.lists[listName][queryId][pageId].totalItemsCount = totalItemsCount;
    state.pagination.lists[listName][queryId][pageId].pageParams = {
      ...pageParams,
      pageNo,
      pageSize,
    };
    state.pagination.lists[listName][queryId][pageId].queriedAt = queriedAt;
    state.pagination.lists[listName][queryId][pageId].ids = items.map(adapter.selectId);

    adapter.upsertMany<any>(state, items);

    delete state.pagination.requests[requestId];
    state.pagination.lists[listName].loading = false;
  };

const reducers = <T>(adapter: EntityAdapter<T>) => ({
  addMany: adapter['addMany'],
  addOne: adapter['addOne'],
  removeAll: adapter['removeAll'],
  removeMany: adapter['removeMany'],
  removeOne: adapter['removeOne'],
  setAll: adapter['setAll'],
  setSelected,
  updateMany: adapter['updateMany'],
  updateOne: adapter['updateOne'],
  upsertMany: adapter['upsertMany'],
  upsertOne: adapter['upsertOne'],
});

export const setSelected = <
  T,
  Q,
  S extends EntityState<T> & PaginationState<T, Q>,
  P extends PayloadAction<{
    multi?: boolean;
    listName?: string;
    selectedIds?: EntityId | EntityId[];
  }>,
>(
  state: S,
  action: P,
) => {
  const { listName = 'default', multi, selectedIds } = action.payload;
  if (!state.pagination.lists[listName]) {
    state.pagination.lists[listName] = {};
  }
  if (!selectedIds) {
    return;
  }
  if (Array.isArray(selectedIds) && selectedIds.length === 0) {
    state.pagination.lists[listName].selectedIds = [];
  }
  if (multi || (Array.isArray(selectedIds) && selectedIds.length > 2)) {
    state.pagination.lists[listName].selectedIds = xor(
      state.pagination.lists[listName].selectedIds,
      Array.isArray(selectedIds) ? selectedIds : [selectedIds],
    );
  } else {
    if (typeof selectedIds === 'string') {
      const candidate: string[] = [];
      if (!state.pagination.lists[listName].selectedIds?.includes(selectedIds)) {
        candidate.push(selectedIds);
      }
      state.pagination.lists[listName].selectedIds = candidate;
    }
  }
};

export const paginationHelper = {
  fulfilled: helperFulfilled,
  pending: helperPending,
  reducers,
  rejected: helperRejected,
};
