import * as jobApi from 'api-endpoints/job';
import * as jobBoardApi from 'api-endpoints/job-board';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { mapJobAds } from 'containers/JobAdLists/store/mappers';
import { JobAdListState } from 'containers/JobAdLists/store/models';
import { jobAdListActions } from 'containers/JobAdLists/store/reducers';
import * as jobAdListSelectors from 'containers/JobAdLists/store/selectors';
import { jobListSelectors } from 'containers/JobLists/store';
import { loaderActions } from 'containers/Loader/store';

import { startLoaderThunk, stopLoaderThunk } from 'modules/LoaderManager/redux';
import { mapComplexListIdToMethod } from 'utils/list';

import { ViewMode } from 'store/constants';
import { jobAdActions } from 'store/entities/job-ads';
import { JobAdBelongsTo } from 'store/entities/job-ads/models';
import { JobBelongsTo } from 'store/entities/jobs/models';
import { RootState } from 'store/rootReducer';
import { AppDispatch } from 'store/types';

export const loadJobAdsTaskId = 'loadJobAdsTask';

const mapJobAdListId = mapComplexListIdToMethod({
  [JobAdBelongsTo.jobBoard]: jobBoardApi.getJobAdsForJobBoard,
  [JobAdBelongsTo.job]: jobApi.getJobAdsForJob,
  [JobAdBelongsTo.company]: jobApi.searchJobAdsForActiveJobs,
});

export const loadJobAds =
  ({ listId }: { listId: JobAdListState['id'] }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    startLoaderThunk(dispatch, loadJobAds);
    const state = getState();
    const params = jobAdListSelectors.getListParams(state, { id: listId });
    const belongsTo = params?.filters?.belongsTo;
    const apiMethod = mapJobAdListId(belongsTo || listId);
    if (!apiMethod) return false;
    dispatch(loaderActions.start(loadJobAdsTaskId));
    const { message, data } = await apiMethod(params);
    if (message) {
      dispatch(loaderActions.stop(loadJobAdsTaskId));
      return dispatch(alertsEffects.showError({ message }));
    }
    const { ids, entities } = mapJobAds(data?.items || []);
    dispatch(
      jobAdActions.upsert({ items: entities.map((ad) => ({ [belongsTo?.[0] ?? 'jobId']: belongsTo?.[1], ...ad })) }),
    );
    dispatch(
      jobAdListActions.fetchSuccess({
        id: listId,
        items: ids,
        pageCount: data?.pageCount,
        totalItemsCount: data?.totalItemsCount,
      }),
    );
    dispatch(loaderActions.stop(loadJobAdsTaskId));
    stopLoaderThunk(dispatch, loadJobAds);
  };

export const changeViewMode = (listId: JobAdListState['id'], viewMode: ViewMode) => async (dispatch: AppDispatch) => {
  dispatch(jobAdListActions.updateViewMode({ id: listId, viewMode }));
  dispatch(jobAdListActions.clearItems({ id: listId }));
};

export const jobAutocompleteForJobAds =
  () => (dispatch: AppDispatch, getState: () => RootState) => async (searchTerm: string) => {
    const jobStatus = jobListSelectors.getFilters(getState(), { listId: JobBelongsTo.newJobAd })?.status;
    const { message, data } = await jobApi.jobAutocomplete({ params: { searchTerm, jobStatus } });
    if (message) {
      return dispatch(alertsEffects.showError({ message }));
    }
    return data;
  };
