import { EntityId } from '@reduxjs/toolkit';
import toLower from 'lodash/toLower';

import { Country } from 'model';

import { getLocation } from 'utils/getLocation';

import { Candidate } from 'store/entities/candidates/models';

export const generateComplexListId = (prefix: string, id: string): string => `${prefix}::${id}`;

export const mapComplexListIdToMethod =
  (mapper: Record<string, Function>) =>
  (belongsToOrId: string | Array<string>): Function | false => {
    const params = typeof belongsToOrId === 'string' ? belongsToOrId.split('::') : belongsToOrId;

    if (params.length === 2) {
      return mapper[params[0]](params[1]);
    }

    return params.length === 1 ? mapper[params[0]] : false;
  };

export const getListId = (belongsToOrId: string | Array<string>): string => {
  const params = typeof belongsToOrId === 'string' ? belongsToOrId.split('::') : belongsToOrId;
  return params[1];
};

export const getPrefixId = (belongsToOrId: string | Array<string>): string => {
  const params = typeof belongsToOrId === 'string' ? belongsToOrId.split('::') : belongsToOrId;
  return params[0];
};

/**
 * Function that get object value by existed prop;
 *
 * @param obj
 * @param key
 */
export function prop<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

export const sortByField =
  <K extends string>(fieldName: K) =>
  <T extends Record<string, any>>(payload: T[]) => {
    return [...payload].sort((a, b) => (toLower(prop(a, fieldName)) > toLower(prop(b, fieldName)) ? 1 : -1));
  };

export const sortByName = sortByField('name');
export const sortByCurrency = sortByField('currency');

interface IsAbleToBeMain {
  main: boolean;
}

export const sortByMain = <T extends IsAbleToBeMain>(payload: T[]) => {
  return [...payload].sort((a, b) => {
    switch (true) {
      case a.main === b.main:
        return 0;
      case a.main !== b.main:
        return a.main ? -1 : 1;
      default:
        return 0;
    }
  });
};

export const getCandidateAddressLine = (candidate?: Candidate, candidateCountry?: Country): string => {
  return getLocation({
    location: candidate?.candidateLocation,
    country: candidateCountry,
    candidate,
    locationString: candidate?.locationFormatted,
  });
};

export const removeId = <P extends { id: EntityId } = any>({ id, ...rest }: P) => ({ ...rest });

/**
 * Function that returns symmetric difference of arrays of records by field that described by path
 *
 * @template T
 * @template P
 * @param {P} a
 * @param {P} b
 * @param {string} path
 * @returns {P}
 */
export function exXorBy<T extends { [key in keyof T]: any } = { [key: string]: any }>(
  a: T[],
  b: T[],
  path: keyof T,
): T[] {
  const result = a.reduce<T[]>((acc, aItem) => {
    const item = b.find((bItem) => bItem[path] === aItem[path]);
    if (item) {
      acc.push(item);
    }

    return acc;
  }, []);

  return result.filter(Boolean);
}

/**
 * Function that returns difference of arrays of records by field that described by path
 *
 * @export
 * @template T
 * @template P
 * @param {P} a
 * @param {P} b
 * @param {string} path
 * @returns {P}
 */
export function exDiffBy<T extends { [key in keyof T]: any } = { [key: string]: any }>(
  a: T[],
  b: T[],
  path: keyof T,
): T[] {
  const result = a.reduce<T[]>((acc, aItem) => {
    const item = b.find((bItem) => bItem[path] === aItem[path]);

    if (!item) {
      acc.push(aItem);
    }

    return acc;
  }, []);

  return result.filter(Boolean);
}
