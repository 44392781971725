import { createSelector } from '@reduxjs/toolkit';

import { JobAdStatus } from 'model/api-enums.constants';

import { createDeepEqualSelector, createLookupSelectors } from 'utils/reducer/selector-helper';

import { EntityKeys } from 'store/constants';
import { JobAd } from 'store/entities/job-ads/models';
import { RootState } from 'store/rootReducer';

const jobAdSelectorsCreateLookupSelectors = createLookupSelectors<JobAd>(EntityKeys.jobAds);

type JobAdFilterFields = 'jobId' | 'companyId';

const jobAdsForJobIdSelector = createDeepEqualSelector(
  jobAdSelectorsCreateLookupSelectors.getList,
  (_: RootState, props: Pick<JobAd, JobAdFilterFields>) =>
    Object.entries({ jobId: props.jobId, companyId: props.companyId }).find(([_k, v]) => !!v) as
      | [JobAdFilterFields, JobAd[JobAdFilterFields]]
      | undefined,
  (ads, props) => {
    if (!props) {
      return ads;
    }
    const [key, value] = props;
    return ads.filter((ad) => ad[key] === value);
  },
);

const jobAdsForJobIdSelectorByStatus = createSelector(
  jobAdsForJobIdSelector,
  (_: RootState, props: Required<Pick<JobAd, 'status'>>) => props.status,
  (ads, status) => {
    return {
      [JobAdStatus.Active]: ads.filter((ad) => ad.status === JobAdStatus.Active),
      [JobAdStatus.Draft]: ads.filter((ad) => ad.status === JobAdStatus.Draft),
      [JobAdStatus.Expired]: ads.filter((ad) => ad.status === JobAdStatus.Expired),
      [JobAdStatus.Error]: ads.filter((ad) => ad.status === JobAdStatus.Error),
      [JobAdStatus.Inactive]: ads.filter((ad) => ad.status === JobAdStatus.Inactive),
      [JobAdStatus.Pending]: ads.filter((ad) => ad.status === JobAdStatus.Pending),
    }[status];
  },
);

const jobAdsForDashboardSelectorByStatus = createSelector(jobAdsForJobIdSelectorByStatus, (ads) => ads.slice(0.3));

export const jobAdSelectors = {
  ...jobAdSelectorsCreateLookupSelectors,
  jobAdsForJobIdSelector,
  jobAdsForJobIdSelectorByStatus,
  jobAdsForDashboardSelectorByStatus,
};
