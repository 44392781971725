import {
  companyPositionAdapter,
  CompanyPositionArgs,
  useCompanyPositionLoadMoreQuery,
} from 'store/dictionary/dictionary.api';

type PositionQueryOptionsProps = {};

export const useCompanyPositionQuerySelectors = (args?: CompanyPositionArgs, options?: PositionQueryOptionsProps) => {
  const query = useCompanyPositionLoadMoreQuery(args, {
    refetchOnMountOrArgChange: false,
    ...options,
  });

  const adapterSelectors = companyPositionAdapter.getSelectors(
    () => query.data ?? companyPositionAdapter.getInitialState(),
  );

  const selectors = {
    ...adapterSelectors,
  };

  return {
    ...query,
    selectors,
  };
};
