import { lazy, ReactNode, Suspense } from 'react';
import { useFeatureFlag } from '@expedo/feature-flag';
import { globalPageAnimation } from 'globalStyle';
import styled from 'styled-components';

import { Features } from 'config/feature-flag';

import { Loader } from 'containers/Loader';

import { Spinner } from 'components/Spinner';
import { useSvgBackground } from 'utils/hooks/useSvgBackground';

const TopBarLegacy = lazy(() => import(/* webpackChunkName: "ex-top-bar-legacy" */ 'components/TopBar/TopBar'));

const GlobalNav = lazy(() => import(/* webpackChunkName: "ex-global-nav" */ 'components/GlobalNav/GlobalNav'));

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  min-height: 100%;
  padding: 24px 30px 0;

  &[data-svg-bg='true'] {
    background-size: cover;
  }

  &[data-global-nav='true'] {
    overflow: hidden;
    padding: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  min-height: 100%;
  flex-grow: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-bottom: 24px;
  flex: 1;
  overflow: hidden;

  &[data-global-nav='true'] {
    padding: 24px;
    max-height: calc(100vh - 80px);
    overflow-y: auto;
  }

  > *:not(${Spinner}):not(.disable-animation) {
    ${globalPageAnimation}
  }
`;

export interface AppLayoutV2Props {
  children: ReactNode;
  topLeft: ReactNode;
}

export const AppLayoutV2 = ({ children, topLeft }: AppLayoutV2Props) => {
  const svgBg = useSvgBackground();
  const { isEnabled } = useFeatureFlag();

  const TopBar = isEnabled(Features.GlobalNav) ? GlobalNav : TopBarLegacy;

  return (
    <Layout data-svg-bg={svgBg} data-global-nav={isEnabled(Features.GlobalNav)}>
      <Suspense fallback={<Loader />}>
        <TopBar />
      </Suspense>
      <ContentWrapper>
        {topLeft}
        <Content id="app-layout-content" data-global-nav={isEnabled(Features.GlobalNav)}>
          {children}
        </Content>
      </ContentWrapper>
    </Layout>
  );
};
