import qs from 'qs';
import { all, call, put, select } from 'redux-saga/effects';

import { ErrorDTO } from 'model/api-errors.constants';

import { basicPublicUrl } from 'pages/public/state';
import { publicCompanySelectors } from 'pages/public/state/public-company';
import { publicJobsActions } from 'pages/public/state/public-jobs/public-jobs.actions';
import { PublicJob } from 'pages/public/state/public-jobs/public-jobs.model';
import { ConfirmationStatus } from 'pages/public/state/public-jobs/public-jobs.reducer';
import { publicJobsSelectors } from 'pages/public/state/public-jobs/public-jobs.selectors';
import { fetcher } from 'pages/public/utils';

export function* publicJobsFetchAction(action: ReturnType<typeof publicJobsActions.publicJobsFetchAction>) {
  yield put(publicJobsActions.updateState({ loading: true }));
  const companyId = action.payload.companyPublicUrl;

  const listParams = yield select(publicJobsSelectors.selectListParams);
  const queryParams = qs.stringify(listParams, { arrayFormat: 'repeat' });

  const url = `${basicPublicUrl}/api/company/${companyId}/job?${queryParams}`;

  const response = yield call(fetch, url);
  const json = yield call([response, 'json']);

  const { items: jobs = [], pageCount = 0, pageNo = 0, totalItemsCount = 0, pageSize = 0, filter } = json;

  yield all([
    put(publicJobsActions.setAll(jobs)),
    put(publicJobsActions.updateState({ pageCount, pageNo, totalItemsCount, pageSize, loading: false, filter })),
  ]);
}

export function* publicJobsGetAction(action: ReturnType<typeof publicJobsActions.publicJobsGetAction>) {
  const jobId = action.payload.jobId;
  if (!jobId) {
    return;
  }
  yield put(publicJobsActions.updateState({ loading: true }));

  const companyId = yield select(publicCompanySelectors.selectPublicCompanyId);

  const url = `${basicPublicUrl}/api/company/${companyId}/job/${jobId}`;

  const response = yield call(fetch, url);
  if (response.ok) {
    const json = yield call([response, 'json']);

    const job: PublicJob = json;

    yield put(publicJobsActions.upsertOne({ ...job, jobId }));
  } else {
    yield put(publicJobsActions.removeOne(jobId));
  }
  yield put(publicJobsActions.updateState({ loading: false }));
}

export function* confirmPendingJobAction(action: ReturnType<typeof publicJobsActions.confirmPendingJobAction>) {
  const { jobId, applicantId } = action.payload;
  const companyId = yield select(publicCompanySelectors.selectPublicCompanyId);
  yield put(publicJobsActions.updateState({ confirmationLoading: true }));

  const url = `${basicPublicUrl}/api/company/${companyId}/job/${jobId}/applicant/${applicantId}`;
  const body = JSON.stringify({
    companyId,
    applicantId,
    jobId,
  });

  try {
    yield call(fetcher, url, { method: 'POST', body });
    yield put(
      publicJobsActions.updateState({ confirmationStatus: ConfirmationStatus.confirm, validationErrorCodes: null }),
    );
  } catch (e) {
    const error = e as ErrorDTO;
    yield put(
      publicJobsActions.updateState({
        confirmationStatus: ConfirmationStatus.none,
        validationErrorCodes: error?.validationErrorCodes,
      }),
    );
  }

  yield put(publicJobsActions.updateState({ confirmationLoading: false }));
}

export function* withdrawPendingJobAction(action: ReturnType<typeof publicJobsActions.withdrawPendingJobAction>) {
  const { applicantId } = action.payload;
  const companyId = yield select(publicCompanySelectors.selectPublicCompanyId);
  yield put(publicJobsActions.updateState({ confirmationLoading: true }));

  const url = `${basicPublicUrl}/api/company/${companyId}/applicant/${applicantId}`;

  try {
    yield call(fetcher, url, { method: 'DELETE' });
    yield put(
      publicJobsActions.updateState({ confirmationStatus: ConfirmationStatus.withdraw, validationErrorCodes: null }),
    );
  } catch (e) {
    const error = e as ErrorDTO;
    yield put(
      publicJobsActions.updateState({
        confirmationStatus: ConfirmationStatus.none,
        validationErrorCodes: error?.validationErrorCodes,
      }),
    );
  }

  yield put(publicJobsActions.updateState({ confirmationLoading: false }));
}
