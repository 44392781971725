import React, { useCallback, useEffect, useState } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { push } from 'connected-react-router';
import { ExRoutes } from 'router/routes';
import { useAppRouterParams } from 'router/useAppRouterParams';
import styled from 'styled-components/macro';

import { InterviewState, QuestionType } from 'model/api-enums.constants';

import {
  ActionButtonsWrapper,
  CompanyInterviewTemplateBody,
  CompanyInterviewTemplatesStyled,
  PageTitleStyled,
} from 'pages/Company/CompanyTabs/CompanyInterviewTemplatesTab/CompanyInterviewTemplatesStyles';
import { InterviewTemplatePreview } from 'pages/Company/CompanyTabs/CompanyInterviewTemplatesTab/components/InterviewTemplatePreview';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { InterviewStatus } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/Tabs/InterviewsTab/components/InterviewStatus';

import { InterviewUpdated } from 'components/ApplicantInterview/components/InterviewQuestionnaireTemplate/InterviewUpdated';
import { useInterviewActions } from 'components/ApplicantInterview/hooks/useInterviewActions';
import { StepFormPageHeader } from 'components/PageHeader';
import { Logo, LogoWrapper } from 'components/StepForm/components/StepFormHeader/StepFormHeaderComponents';
import { ExButton } from 'components/ui/ExButton';
import { ExVisible } from 'components/ui/ExVisible';
import { BackLink } from 'modules/HistoryManager/components/BackLink';
import { usePreviousHistoryEntity } from 'modules/HistoryManager/react/hooks';
import { useInterviewChangeStatus } from 'utils/hooks/interview/useInterviewChangeStatus';
import { useInterviewRemove } from 'utils/hooks/interview/useInterviewRemove';
import { useInterviewSettingsModal } from 'utils/hooks/interview/useInterviewSettingsModal';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { companySelectors } from 'store/company/company.selectors';
import { useInterviewQuery } from 'store/entities/interview';
import { useInterviewQuestionListQuery } from 'store/entities/interview-question';

const InterviewStatusStyled = styled(InterviewStatus)`
  vertical-align: middle;
  margin-left: 20px;
`;

type InterviewQuestionnaireTemplateProps = {
  className?: string;
};

const useInterviewQuestionnaireTemplateState = ({ className }: InterviewQuestionnaireTemplateProps) => {
  const dispatch = useAppDispatch();
  const params = useAppRouterParams();
  const { applicantId, jobId, interviewId } = params;

  const { data: interview } = useInterviewQuery(interviewId ?? skipToken);
  const [removeInterview, { isSuccess: isSuccessRemove }] = useInterviewRemove({ interviewId });
  const [openInterviewSettingsModal] = useInterviewSettingsModal({ interviewId });
  const [changeInterviewStatusInterviewWithConfirmModal] = useInterviewChangeStatus({ interviewId });

  const {
    showPublishButton,
    showEditButton,
    showSettingsButton,
    showCloseButton,
    showDeleteButton,
    publishButtonTitle,
  } = useInterviewActions({
    interview,
  });

  const titleText = interview?.name;

  const isInterviewEditable = interview?.state === InterviewState.Draft && showPublishButton;

  const logoUrl = useAppSelector(companySelectors.selectCompanyLogo);

  const candidateLink = ExRoutes.applicantInterviewsView({ applicantId, jobId });

  const { title, pathname } = usePreviousHistoryEntity('applicantInterview');

  const backPath = {
    title: title || 'Candidate Profile',
    pathname: pathname || candidateLink,
  };

  const onDeleteInterviewButtonClickHandler: React.MouseEventHandler<HTMLButtonElement> = removeInterview;

  useEffect(() => {
    if (isSuccessRemove) {
      dispatch(push(backPath.pathname));
    }
  }, [backPath.pathname, dispatch, isSuccessRemove]);

  const onSaveAsDraftInterviewButtonClickHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
    changeInterviewStatusInterviewWithConfirmModal(InterviewState.Draft);
  };

  const onEditInterviewSettingsButtonClickHandler: React.MouseEventHandler<HTMLButtonElement> =
    openInterviewSettingsModal;

  const { data, isLoading } = useInterviewQuestionListQuery(interviewId || '');
  const [questionsDefaultValues, setQDV] = useState<DefaultValues<Record<string, string>> | undefined>(undefined);

  const methods = useForm({
    defaultValues: questionsDefaultValues,
    reValidateMode: 'onBlur',
    shouldFocusError: false,
    shouldUnregister: false,
  });

  const onPublishInterviewButtonClickHandler: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    methods.handleSubmit(
      () => {
        changeInterviewStatusInterviewWithConfirmModal(InterviewState.Saved);
      },
      (errors) => {
        for (const [name, error] of Object.entries(errors)) {
          error && methods.setError(name, error);
        }
        dispatch(alertsEffects.showError({ message: 'Please correct the errors below' }));
      },
    )();
  }, [methods, dispatch, changeInterviewStatusInterviewWithConfirmModal]);

  useEffect(() => {
    if (!isLoading && data?.entities && questionsDefaultValues === undefined) {
      const parsedQDV = {} as DefaultValues<Record<string, string>>;
      Object.values(data?.entities)
        .filter(
          (question) =>
            question !== undefined &&
            ([QuestionType.TextField, QuestionType.TextArea] as any).includes(question.questionType),
        )
        .forEach((question) => {
          parsedQDV[question!.questionId] = question!.answer?.toString();
        });
      setQDV(parsedQDV);
      methods.reset();
    }
  }, [isLoading, data, setQDV, methods, questionsDefaultValues]);

  return {
    className,
    titleText,
    logoUrl,
    backPath,
    onDeleteInterviewButtonClickHandler,
    onSaveAsDraftInterviewButtonClickHandler,
    onPublishInterviewButtonClickHandler,
    onEditInterviewSettingsButtonClickHandler,
    showPublishButton,
    isInterviewEditable,
    showSettingsButton,
    showCloseButton,
    showDeleteButton,
    showEditButton,
    publishButtonTitle,
    interview,
    methods,
  } as const;
};

export const InterviewQuestionnaireTemplate: React.FC<InterviewQuestionnaireTemplateProps> = (props) => {
  const {
    className,
    titleText,
    logoUrl,
    backPath,
    onDeleteInterviewButtonClickHandler,
    onSaveAsDraftInterviewButtonClickHandler,
    onPublishInterviewButtonClickHandler,
    onEditInterviewSettingsButtonClickHandler,
    showPublishButton,
    isInterviewEditable,
    showSettingsButton,
    showCloseButton,
    showEditButton,
    showDeleteButton,
    publishButtonTitle,
    interview,
    methods,
  } = useInterviewQuestionnaireTemplateState(props);

  if (!interview) {
    return null;
  }

  return (
    <CompanyInterviewTemplatesStyled className={className}>
      <StepFormPageHeader>
        <div className="d-flex">
          <LogoWrapper>
            <Logo src={logoUrl} alt="Logo" className="logo" />
          </LogoWrapper>
          <div>
            <div>
              <BackLink routeName={'applicantInterview'} fallbackProps={backPath} />
            </div>
            <PageTitleStyled className="d-flex">
              {titleText}
              <InterviewStatusStyled item={interview} />
            </PageTitleStyled>
          </div>
        </div>
        <ActionButtonsWrapper>
          <InterviewUpdated />
          <ExVisible visible={showDeleteButton}>
            <ExButton variant="danger" onClick={onDeleteInterviewButtonClickHandler}>
              Delete
            </ExButton>
          </ExVisible>
          <ExVisible visible={showEditButton}>
            <ExButton variant="light" onClick={onSaveAsDraftInterviewButtonClickHandler}>
              Edit
            </ExButton>
          </ExVisible>
          <ExVisible visible={showPublishButton}>
            <ExButton variant="primary" onClick={onPublishInterviewButtonClickHandler}>
              {publishButtonTitle}
            </ExButton>
          </ExVisible>
          <ExVisible visible={showSettingsButton}>
            <ExButton variant="light" onClick={onEditInterviewSettingsButtonClickHandler}>
              Interview Settings
            </ExButton>
          </ExVisible>
          <ExVisible visible={showCloseButton}>
            <ExButton variant="light" forwardedAs={Link} to={backPath.pathname}>
              Close
            </ExButton>
          </ExVisible>
        </ActionButtonsWrapper>
      </StepFormPageHeader>
      <CompanyInterviewTemplateBody>
        <FormProvider {...methods}>
          <InterviewTemplatePreview editable={isInterviewEditable} context="interview" />
        </FormProvider>
      </CompanyInterviewTemplateBody>
    </CompanyInterviewTemplatesStyled>
  );
};
