import { getI18n } from 'react-i18next';
import isNil from 'lodash/isNil';

import { Frequency } from 'model/api-enums.constants';

import { getFrequencyNames } from 'utils/hooks/formsHooks/useFrequencyOptions';

import { Salary } from 'store/entities/jobs/models';

interface UseCurrencyOptions extends Intl.NumberFormatOptions {}

export function getCurrencyFormatter(locale: string, options?: UseCurrencyOptions) {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    ...options,
    currency: options?.currency && options.currency.length <= 3 ? options.currency : 'USD',
    style: 'currency',
  });
}

function useNumberFormatter(options: UseCurrencyOptions) {
  const i18n = getI18n();

  return getCurrencyFormatter(i18n?.language || navigator.language, options);
}

export function currencyToLocaleStringCallback(options: UseCurrencyOptions, formatter: Intl.NumberFormat) {
  return function (amount?: number) {
    if (amount === null || amount === undefined) {
      return '';
    }
    if (isNaN(Number(amount))) {
      return amount;
    }
    if (!options.currency) {
      return amount;
    }
    return formatter.format(amount);
  };
}

function makeStringFromSalaryRangeCallback(
  currencyToLocaleString: ReturnType<typeof currencyToLocaleStringCallback>,
  delimiter: string,
  short?: boolean,
  shortFrequency?: boolean,
) {
  return function (salary?: Salary) {
    const minimum = salary?.moneyRange?.minimum;
    const maximum = salary?.moneyRange?.maximum;
    const frequency = salary?.frequency;

    const isEmptyMinimum = minimum === undefined || minimum === null;
    const isEmptyMaximum = maximum === undefined || maximum === null;

    let result = '';

    if (isEmptyMinimum || isEmptyMaximum) {
      result += `-- ${delimiter} --`;
      if (frequency) {
        result += ' ';
        result += getFrequencyNames(frequency, shortFrequency);
      }
    } else {
      result += currencyToLocaleString(minimum);
      result += ' ';
      result += delimiter;
      result += ' ';
      result += currencyToLocaleString(maximum);
      if (!short) {
        if (frequency) {
          result += ' ';
          result += getFrequencyNames(frequency, shortFrequency);
        } else {
          result += ' ';
          result += '--';
        }
      }
    }

    return result;
  };
}

export const useCurrency = (options: UseCurrencyOptions, delimiter = 'to', short = false, shortFrequency = false) => {
  const formatter = useNumberFormatter(options);

  const currencyToLocaleString = currencyToLocaleStringCallback(options, formatter);

  const makeStringFromSalaryRange = makeStringFromSalaryRangeCallback(
    currencyToLocaleString,
    delimiter,
    short,
    shortFrequency,
  );

  return {
    currencyToLocaleString,
    makeStringFromSalaryRange,
  };
};

type ApplicantFormattedCurrencyProps = {
  salaryExpectationCurrency?: string | null;
  salaryExpectationAmount?: number | null;
};

type ApplicantFormattedCurrencyWithFrequencyProps = {
  salaryExpectationFrequency?: Frequency | null;
  formattedCurrency: string | number | null;
};

export const getApplicantFormattedCurrency = ({
  salaryExpectationCurrency,
  salaryExpectationAmount,
}: ApplicantFormattedCurrencyProps) => {
  const i18n = getI18n();
  const currency = salaryExpectationCurrency ?? undefined;
  const value = salaryExpectationAmount ?? 0;

  if (isNil(currency) || isNil(value)) {
    return null;
  }

  const currencyFormatter = getCurrencyFormatter(i18n.language || navigator.language, { currency });
  const currencyFormatterCallback = currencyToLocaleStringCallback({ currency }, currencyFormatter);

  return currencyFormatterCallback(value);
};

export const getApplicantFormattedCurrencyWithFrequency = ({
  salaryExpectationFrequency,
  formattedCurrency,
}: ApplicantFormattedCurrencyWithFrequencyProps) => {
  if (isNil(salaryExpectationFrequency) || isNil(formattedCurrency)) {
    return '--';
  }

  const frequency = getFrequencyNames(salaryExpectationFrequency);

  return `${formattedCurrency} (${frequency})`;
};
