import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { list } from 'config';

import { JobBoardForJobs } from 'api-endpoints/job-board/job-board.dto';

import { Country } from 'model';
import { JobStatus } from 'model/api-enums.constants';

import { ExDropDownCheckableItemsProps } from 'components/ui/ExDropDownCheckable/ExDropDownCheckableProps';

import { SortDirections, UIKeys } from 'store/constants';
import { SortByForJobList } from 'store/entities/jobs';

export type JobsListUiSliceState = ReturnType<typeof initialState>;

interface JobListFieldsProps extends ExDropDownCheckableItemsProps {
  label: SortByForJobList;
}

const initialFilters = () => ({
  pageNo: 0,
  pageSize: list.pageSize,
  sortMode: {
    orderBy: SortByForJobList.JobName,
    orderDir: SortDirections.asc,
  },
  viewMode: list.viewMode,
  jobStatuses: 'active' as JobStatus | string | undefined,
  searchTerm: '',
  employmentTypes: [] as Array<{ label: string; value: string }>,
  jobBoards: [] as JobBoardForJobs[],
  jobLocations: [] as Country[],
});

const initialState = () => ({
  filters: initialFilters(),
  jobListFields: [
    { label: 'Employment Type', isChecked: true, fieldName: SortByForJobList.EmploymentType },
    { label: 'New Applicants', isChecked: true, fieldName: SortByForJobList.NewApplicants },
    { label: 'Total Applicants', isChecked: true, fieldName: SortByForJobList.TotalApplicants },
    { label: 'Open Position', isChecked: true, fieldName: SortByForJobList.OpenVacancies },
    { label: 'Job Boards', isChecked: true, fieldName: SortByForJobList.JobBoards },
    { label: 'Salary', isChecked: true, fieldName: SortByForJobList.Salary },
    { label: 'Job Location', isChecked: true, fieldName: SortByForJobList.JobLocation },
  ] as JobListFieldsProps[],
});

export const jobsListUiSlice = createSlice({
  name: UIKeys.jobsList,
  initialState: initialState(),
  reducers: {
    updateState: (draft, { payload }: PayloadAction<Partial<JobsListUiSliceState>>) => Object.assign(draft, payload),
    updateFilters: (draft, { payload }: PayloadAction<Partial<JobsListUiSliceState['filters']>>) => {
      draft.filters = {
        ...draft.filters,
        ...payload,
      };
    },
    updateJobListFields: (draft, { payload }: PayloadAction<JobsListUiSliceState['jobListFields']>) => {
      draft.jobListFields = [...payload];
    },
    resetState: () => initialState(),
    resetFilters: (draft) => {
      draft.filters = {
        ...initialFilters(),
        jobStatuses: draft.filters.jobStatuses,
        viewMode: draft.filters.viewMode,
      };
    },
  },
});
