import { nanoid } from '@reduxjs/toolkit';
import { buffers, Channel, channel } from 'redux-saga';
import { all, call, fork, put, race, select, take } from 'redux-saga/effects';

import { getCompanyIdibuSettings, updateCompanyIdibuSettings } from 'api-endpoints/company';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { getTranslate } from 'utils/i18utils';
import { invokeApiCall } from 'utils/sagas';

import { updateCompanyIntegrations } from 'store/company/company.actions';
import { companySelectors } from 'store/company/company.selectors';
import type { CompanyInfo } from 'store/company/company.types';
import {
  exModalCancelAction,
  exModalConfirmAction,
  exModalHideAction,
  exModalPropsAction,
} from 'store/modals/modals.actions';
import { modalSagaWorker } from 'store/modals/modals.sagas';

function* preFetchData(modalId: string) {
  yield put(
    exModalPropsAction({
      id: modalId,
      isOpen: true,
      modalProps: { data: null, error: null, loading: true },
      modalType: ModalsTypeKey.companyUpdateIntegrations,
    }),
  );

  const { data, errorData } = yield call(invokeApiCall, getCompanyIdibuSettings, {});

  yield put(
    exModalPropsAction({
      id: modalId,
      isOpen: true,
      modalProps: { data: data ?? null, error: errorData ?? null, loading: false },
      modalType: ModalsTypeKey.companyUpdateIntegrations,
    }),
  );
}

function* invokeModal(modalChannel: Channel<any>, modalId: string) {
  yield fork(modalSagaWorker, {
    modalType: ModalsTypeKey.companyUpdateIntegrations,
    ...(modalId ? { id: modalId } : {}),
  });

  while (true) {
    const { cancel, confirm } = yield race({
      cancel: take(exModalCancelAction.type),
      confirm: take(exModalConfirmAction.type),
    });
    yield put(modalChannel, { cancel, confirm });
  }
}

export function* updateIntegrationsSaga(action: {
  type: string;
  payload: Parameters<typeof updateCompanyIntegrations>[0];
}) {
  /**
   * This variable needs to store modal id in case of network error to restart
   * saga with existed modal instead of rendering the new one.
   */
  const modalId = nanoid();
  const modalChannel = yield channel(buffers.none());
  yield fork(preFetchData as any, modalId);
  yield fork(invokeModal as any, modalChannel, modalId);

  while (true) {
    yield fork(invokeModal as any, modalChannel, modalId);
    yield fork(preFetchData as any, modalId);

    const result = yield take(modalChannel);

    if (result.cancel) {
      yield put(exModalHideAction({ id: modalId }));
      return;
    }

    yield call(startLoader, action);

    const companyInfo: CompanyInfo = yield select(companySelectors.getCompanyInfo);
    const companyId: string = companyInfo['companyId'];

    const requestData = result.confirm.payload.modalResult;

    const { data } = yield invokeApiCall(updateCompanyIdibuSettings, {
      data: requestData,
      urlParams: { companyId },
    });

    if (data) {
      yield all([
        put(exModalHideAction({ id: modalId })),
        put(
          alertsEffects.showSuccess({
            message: getTranslate('company.integrations.update.success'),
          }),
        ),
        call(stopLoader, action),
      ]);
      break;
    }
    yield call(stopLoader, action);
  }
}
