import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';

import { EntityStateWithPagination, transformListResponse } from 'api-client/axiosBaseQuery';
import { AxiosBaseQueryFnArgs } from 'api-client/axiosBaseQueryTypes';
import { replaceParamsInUrl } from 'api-client/utils';
import { ScreeningQuestionEndpointsForApi } from 'api-endpoints/screening-question/endpoints';

import { ApiResponseWithPagination, ExScreeningQuestion } from 'model';

import { publicNewApplicantFormActions } from 'pages/public/state/public-new-applicant-form';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';

import { getSuccessToastForEntityAction } from 'utils/i18utils';

import { emptyApi, emptyApiPublic } from 'store/entities/emptyApi';
import { ScreeningQuestionFilterSearchName } from 'store/ui/company/company-screening-question-list/company-screening-question-list.reducer';

export const SCREENING_QUESTIONS_TAG_TYPE = 'ScreeningQuestions';

export const JOB_SCREENING_QUESTIONS_TAG_TYPE = 'JobScreeningQuestions';

export const PUBLIC_JOB_SCREENING_QUESTIONS_TAG_TYPE = 'PublicJobScreeningQuestions';

export const JOB_APPLICANT_SCREENING_QUESTIONS_TAG_TYPE = 'JobApplicantScreeningQuestions';

type ScreeningQuestionId = {
  questionId: string;
};

type ScreeningQuestionTitle = {
  questionTitle: string;
};

type SearchScreeningQuestion = {
  pageNo: number;
  [ScreeningQuestionFilterSearchName]?: string;
  pageSize?: number;
  orderBy?: string;
};

type SearchJobScreeningQuestions = {
  jobId: string;
};

type PublicSearchJobScreeningQuestions = SearchJobScreeningQuestions & {
  companyId: string;
};

type SearchJobApplicantScreeningQuestions = {
  jobId: string;
  applicantId: string;
};

type ScreeningQuestionUpdateMutation = ScreeningQuestionId & {
  data: Partial<ExScreeningQuestion>;
};

type JobScreeningQuestionId = {
  jobId: string;
  questionId?: string;
};

type JobScreeningQuestionAddMutationQuestion = {
  jobScreeningQuestionId: string;
};

type JobScreeningQuestionAddMutation = JobScreeningQuestionId & {
  data: { questions: Partial<ExScreeningQuestion>[] };
};

type JobScreeningQuestionsReorderMutation = JobScreeningQuestionId & {
  data: { questions: JobScreeningQuestionAddMutationQuestion[] };
};

type JobScreeningQuestionUpdateMutation = Required<JobScreeningQuestionId> &
  ScreeningQuestionTitle & {
    isMandatory: ExScreeningQuestion['isMandatory'];
  };

const screeningQuestionAdapter = createEntityAdapter<ExScreeningQuestion>({
  selectId: (item) => item.itemId,
});

const jobScreeningQuestionAdapter = createEntityAdapter<ExScreeningQuestion>({
  selectId: (item) => item.itemId,
});

const searchScreeningQuestionQueryFunc = (args: Partial<SearchScreeningQuestion>): AxiosBaseQueryFnArgs => ({
  url: replaceParamsInUrl(ScreeningQuestionEndpointsForApi.SearchScreeningQuestionFunc, {}),
  method: 'GET',
  params: { ...args },
});

const screeningQuestionMapper = (question: ExScreeningQuestion) => ({
  ...question,
  itemId: question.applicantScreeningQuestionId || question.jobScreeningQuestionId || question.questionId || '',
});

const screeningQuestionsMapper = (questions: ExScreeningQuestion[]) => {
  return questions.map(screeningQuestionMapper);
};

export const screeningQuestionApiInjected = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    // JOB ---
    searchJobScreeningQuestions: builder.query<EntityState<ExScreeningQuestion>, SearchJobScreeningQuestions>({
      query: ({ jobId, ...args }) => ({
        url: replaceParamsInUrl(ScreeningQuestionEndpointsForApi.SearchJobScreeningQuestionsFunc, { jobId }),
        method: 'GET',
        params: { ...args },
      }),
      transformResponse: (data: ExScreeningQuestion[]) => {
        return jobScreeningQuestionAdapter.addMany(
          jobScreeningQuestionAdapter.getInitialState({}),
          screeningQuestionsMapper(data),
        );
      },
    }),
    addJobScreeningQuestion: builder.mutation<void, JobScreeningQuestionAddMutation>({
      query: ({ jobId, data }) => ({
        url: replaceParamsInUrl(ScreeningQuestionEndpointsForApi.AddJobScreeningQuestionFunc, { jobId }),
        method: 'POST',
        data,
      }),
      onQueryStarted: async ({ data: { questions } }, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          if (questions.length === 1) {
            const message = getSuccessToastForEntityAction({
              name: questions[0].questionTitle!,
              action: 'added',
              entityName: 'screeningQuestion',
            });
            dispatch(alertsEffects.showSuccess({ message }));
          }
        } catch (error) {}
      },
    }),
    replaceJobScreeningQuestion: builder.mutation<void, JobScreeningQuestionAddMutation>({
      query: ({ jobId, data }) => ({
        url: replaceParamsInUrl(ScreeningQuestionEndpointsForApi.ReplaceJobScreeningQuestionsFunc, { jobId }),
        method: 'POST',
        data,
      }),
    }),
    updateJobScreeningQuestion: builder.mutation<void, JobScreeningQuestionUpdateMutation>({
      query: ({ jobId, questionId, questionTitle: _questionTitle, ...data }) => ({
        url: replaceParamsInUrl(ScreeningQuestionEndpointsForApi.UpdateJobScreeningQuestion, { jobId, questionId }),
        method: 'PUT',
        data,
      }),
      onQueryStarted: async ({ jobId, questionId, questionTitle, ...data }, { queryFulfilled, dispatch }) => {
        const patches = dispatch(
          screeningQuestionApiInjected.util.updateQueryData('searchJobScreeningQuestions', { jobId }, (draft) => {
            jobScreeningQuestionAdapter.updateOne(draft, { id: questionId, changes: { ...data } });
          }),
        );

        try {
          await queryFulfilled;
          const message = getSuccessToastForEntityAction({
            name: questionTitle,
            action: 'updated',
            entityName: 'screeningQuestion',
          });
          dispatch(alertsEffects.showSuccess({ message }));
        } catch (e) {
          patches.undo();
        }
      },
    }),
    removeJobScreeningQuestion: builder.mutation<void, Required<JobScreeningQuestionId> & ScreeningQuestionTitle>({
      query: ({ jobId, questionId }) => ({
        url: replaceParamsInUrl(ScreeningQuestionEndpointsForApi.RemoveJobScreeningQuestionFunc, { jobId, questionId }),
        method: 'DELETE',
      }),
      onQueryStarted: async ({ jobId, questionId, questionTitle }, { queryFulfilled, dispatch }) => {
        const patches = dispatch(
          screeningQuestionApiInjected.util.updateQueryData('searchJobScreeningQuestions', { jobId }, (draft) => {
            jobScreeningQuestionAdapter.removeOne(draft, questionId);
          }),
        );

        try {
          await queryFulfilled;
          const message = getSuccessToastForEntityAction({
            name: questionTitle,
            action: 'deleted',
            entityName: 'screeningQuestion',
          });
          dispatch(alertsEffects.showSuccess({ message }));
        } catch (e) {
          patches.undo();
        }
      },
    }),
    reorderJobScreeningQuestions: builder.mutation<void, JobScreeningQuestionsReorderMutation>({
      query: ({ jobId, data }) => ({
        url: replaceParamsInUrl(ScreeningQuestionEndpointsForApi.ReorderJobScreeningQuestionsFunc, { jobId }),
        method: 'POST',
        data,
      }),
      onQueryStarted: async ({ jobId, data }, { queryFulfilled, dispatch }) => {
        const ids = data.questions.map(({ jobScreeningQuestionId }) => jobScreeningQuestionId);

        const patches = dispatch(
          screeningQuestionApiInjected.util.updateQueryData('searchJobScreeningQuestions', { jobId }, (draft) => {
            draft.ids = ids;
          }),
        );

        try {
          await queryFulfilled;
          const message = getSuccessToastForEntityAction({
            action: 'updated',
            entityName: 'screeningQuestions',
          });
          dispatch(alertsEffects.showSuccess({ message }));
        } catch (e) {
          patches.undo();
        }
      },
    }),
    searchJobApplicantScreeningQuestions: builder.query<ExScreeningQuestion[], SearchJobApplicantScreeningQuestions>({
      query: ({ jobId, applicantId, ...args }) => ({
        url: replaceParamsInUrl(ScreeningQuestionEndpointsForApi.SearchJobApplicantScreeningQuestionsFunc, {
          jobId,
          applicantId,
        }),
        method: 'GET',
        params: { ...args },
      }),
      transformResponse: (data: ExScreeningQuestion[]) => screeningQuestionsMapper(data),
    }),
    // JOB END ---
    searchScreeningQuestion: builder.query<
      EntityStateWithPagination<ExScreeningQuestion>,
      Partial<SearchScreeningQuestion>
    >({
      query: searchScreeningQuestionQueryFunc,
      transformResponse: (data: ApiResponseWithPagination<ExScreeningQuestion>) => {
        data.items = screeningQuestionsMapper(data.items);
        return transformListResponse(screeningQuestionAdapter)(data);
      },
    }),
    searchScreeningQuestionLoadMore: builder.query<
      EntityStateWithPagination<ExScreeningQuestion>,
      Partial<SearchScreeningQuestion>
    >({
      query: searchScreeningQuestionQueryFunc,
      transformResponse: (data: ApiResponseWithPagination<ExScreeningQuestion>) => {
        data.items = screeningQuestionsMapper(data.items);
        return transformListResponse(screeningQuestionAdapter)(data);
      },
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            screeningQuestionApiInjected.util.updateQueryData(
              'searchScreeningQuestionLoadMore',
              { ...args, pageNo: 0 },
              (draft) => {
                screeningQuestionAdapter.upsertMany(
                  draft,
                  data.ids.map((id) => data.entities[id]!),
                );
                draft.pageCount = data.pageCount;
              },
            ),
          );
        } catch (error) {}
      },
    }),
    getScreeningQuestion: builder.query<ExScreeningQuestion, ScreeningQuestionId>({
      query: ({ questionId }) => ({
        url: replaceParamsInUrl(ScreeningQuestionEndpointsForApi.GetScreeningQuestionFunc, { questionId }),
        method: 'GET',
      }),
      transformResponse: (data: ExScreeningQuestion) => screeningQuestionMapper(data),
    }),
    addScreeningQuestion: builder.mutation<ScreeningQuestionId, ScreeningQuestionUpdateMutation>({
      query: ({ data }) => ({
        url: replaceParamsInUrl(ScreeningQuestionEndpointsForApi.AddScreeningQuestionFunc, {}),
        method: 'POST',
        data,
      }),
      onQueryStarted: async ({ data: { questionTitle } }, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          const message = getSuccessToastForEntityAction({
            name: questionTitle!,
            action: 'created',
            entityName: 'screeningQuestion',
          });
          dispatch(alertsEffects.showSuccess({ message }));
        } catch (error) {}
      },
    }),
    updateScreeningQuestion: builder.mutation<void, ScreeningQuestionUpdateMutation>({
      query: ({ questionId, data }) => ({
        url: replaceParamsInUrl(ScreeningQuestionEndpointsForApi.UpdateScreeningQuestionFunc, { questionId }),
        method: 'PUT',
        data,
      }),
      onQueryStarted: async ({ data: { questionTitle } }, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          const message = getSuccessToastForEntityAction({
            name: questionTitle!,
            action: 'updated',
            entityName: 'screeningQuestion',
          });
          dispatch(alertsEffects.showSuccess({ message }));
        } catch (error) {}
      },
    }),
    deleteScreeningQuestion: builder.mutation<void, ScreeningQuestionId & ScreeningQuestionTitle>({
      query: ({ questionId }) => ({
        url: replaceParamsInUrl(ScreeningQuestionEndpointsForApi.DeleteScreeningFunc, { questionId }),
        method: 'DELETE',
      }),
      onQueryStarted: async ({ questionTitle }, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          const message = getSuccessToastForEntityAction({
            name: questionTitle,
            action: 'deleted',
            entityName: 'screeningQuestion',
          });
          dispatch(alertsEffects.showSuccess({ message }));
        } catch (error) {}
      },
    }),
  }),
});

export const screeningQuestionApi = screeningQuestionApiInjected.enhanceEndpoints({
  addTagTypes: [
    SCREENING_QUESTIONS_TAG_TYPE,
    JOB_SCREENING_QUESTIONS_TAG_TYPE,
    JOB_APPLICANT_SCREENING_QUESTIONS_TAG_TYPE,
  ],
  endpoints: {
    searchScreeningQuestion: {
      providesTags: () => {
        return [{ type: SCREENING_QUESTIONS_TAG_TYPE, id: 'LIST' }];
      },
    },
    getScreeningQuestion: {
      providesTags: (_, __, { questionId }) => {
        return [{ type: SCREENING_QUESTIONS_TAG_TYPE, id: questionId }];
      },
    },
    addScreeningQuestion: {
      invalidatesTags: () => {
        return [{ type: SCREENING_QUESTIONS_TAG_TYPE, id: 'LIST' }];
      },
    },
    updateScreeningQuestion: {
      invalidatesTags: (_, __, { questionId }) => {
        return [
          { type: SCREENING_QUESTIONS_TAG_TYPE, id: 'LIST' },
          { type: SCREENING_QUESTIONS_TAG_TYPE, id: questionId },
        ];
      },
    },
    deleteScreeningQuestion: {
      invalidatesTags: () => {
        return [{ type: SCREENING_QUESTIONS_TAG_TYPE, id: 'LIST' }];
      },
    },
    // JOB ---
    searchJobScreeningQuestions: {
      providesTags: () => {
        return [{ type: JOB_SCREENING_QUESTIONS_TAG_TYPE, id: 'LIST' }];
      },
    },
    addJobScreeningQuestion: {
      invalidatesTags: () => {
        return [{ type: JOB_SCREENING_QUESTIONS_TAG_TYPE, id: 'LIST' }];
      },
    },
    replaceJobScreeningQuestion: {
      invalidatesTags: () => {
        return [{ type: JOB_SCREENING_QUESTIONS_TAG_TYPE, id: 'LIST' }];
      },
    },
    updateJobScreeningQuestion: {
      invalidatesTags: () => {
        return [{ type: JOB_SCREENING_QUESTIONS_TAG_TYPE, id: 'LIST' }];
      },
    },
    removeJobScreeningQuestion: {
      invalidatesTags: () => {
        return [{ type: JOB_SCREENING_QUESTIONS_TAG_TYPE, id: 'LIST' }];
      },
    },
    reorderJobScreeningQuestions: {
      invalidatesTags: () => {
        return [{ type: JOB_SCREENING_QUESTIONS_TAG_TYPE, id: 'LIST' }];
      },
    },
    searchJobApplicantScreeningQuestions: {
      providesTags: () => {
        return [{ type: JOB_APPLICANT_SCREENING_QUESTIONS_TAG_TYPE, id: 'LIST' }];
      },
    },
  },
});

export const {
  useSearchJobScreeningQuestionsQuery,
  useSearchScreeningQuestionQuery,
  useSearchScreeningQuestionLoadMoreQuery,
  useLazySearchScreeningQuestionLoadMoreQuery,
  useGetScreeningQuestionQuery,
  useAddScreeningQuestionMutation,
  useUpdateScreeningQuestionMutation,
  useDeleteScreeningQuestionMutation,
  useUpdateJobScreeningQuestionMutation,
  useRemoveJobScreeningQuestionMutation,
  useAddJobScreeningQuestionMutation,
  useReplaceJobScreeningQuestionMutation,
  useReorderJobScreeningQuestionsMutation,
  useSearchJobApplicantScreeningQuestionsQuery,
} = screeningQuestionApi;

export const publicJobScreeningQuestionAdapter = createEntityAdapter<ExScreeningQuestion>({
  selectId: (item) => item.itemId,
});

export const screeningQuestionPublicApiInjected = emptyApiPublic.injectEndpoints({
  endpoints: (builder) => ({
    searchPublicJobScreeningQuestions: builder.query<
      EntityState<ExScreeningQuestion>,
      PublicSearchJobScreeningQuestions
    >({
      query: ({ companyId, jobId }) => ({
        url: replaceParamsInUrl(ScreeningQuestionEndpointsForApi.SearchPublicJobScreeningQuestionsFunc, {
          companyId,
          jobId,
        }),
        method: 'GET',
      }),
      transformResponse: (response: ExScreeningQuestion[]) => {
        return publicJobScreeningQuestionAdapter.addMany(
          publicJobScreeningQuestionAdapter.getInitialState({}),
          screeningQuestionsMapper(response),
        );
      },
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;

          const questions = publicJobScreeningQuestionAdapter.getSelectors().selectAll(data);

          dispatch(publicNewApplicantFormActions.setJobScreeningQuestions(questions));
        } catch (error) {}
      },
    }),
    uploadPendingJobApplicantFileAnswerFunc: builder.mutation({
      query: ({ companyId, data }) => ({
        url: replaceParamsInUrl(ScreeningQuestionEndpointsForApi.UploadPendingJobApplicantFileAnswerFunc, {
          companyId,
        }),
        method: 'POST',
        data,
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;

          dispatch(alertsEffects.showSuccess({ message: 'File successfully uploaded' }));
        } catch (error) {}
      },
    }),
  }),
});

export const screeningQuestionPublicApi = screeningQuestionPublicApiInjected.enhanceEndpoints({
  addTagTypes: [PUBLIC_JOB_SCREENING_QUESTIONS_TAG_TYPE],
  endpoints: {
    searchPublicJobScreeningQuestions: {
      providesTags: () => {
        return [{ type: PUBLIC_JOB_SCREENING_QUESTIONS_TAG_TYPE, id: 'LIST' }];
      },
    },
  },
});
