import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from 'router';
import styled from 'styled-components';

import { SourceEntity } from 'model';

import { ApplicantFormScreeningQuestionsList } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/CompanyScreeningQuestionsComponents';
import { JobDetailsItemKeys } from 'pages/Jobs/Job/hooks/useJobDetails';
import { useSinglePipeline } from 'pages/Jobs/Job/hooks/useSinglePipeline';

import { RowComponent } from 'containers/JobAdForms/JobAdAdditionalDetailsCard/JobAdAdditionalDetailsCardComponents';

import { DetailsRowProps } from 'components/DetailsCard';
import { CheckableList } from 'components/Form/CheckableList';
import { JobInterviewTemplatesReview } from 'components/JobInterviewTemplates/JobInterviewTemplatesReview';
import { JobSettingsCardRedesign } from 'components/JobSettingsCardRedesign';
import { StepComponent, StepDescription } from 'components/StepForm';
import { ExCard, ExCardWithoutShadow } from 'components/ui/ExCard';
import { ExCardEditButton } from 'components/ui/ExCard/components/ExCardEditButton';
import { useJobDeadlineText } from 'components/ui/ExDeadlineBadge/hooks';
import { ExColumn, ExRow } from 'components/ui/ExLayout';
import { getEmploymentType } from 'utils/getEmploymentType';
import { getLocation } from 'utils/getLocation';
import { useJobListOnCareerPage } from 'utils/hooks/formsHooks/useJobTypeOptions';
import { useSalaryInfoRows } from 'utils/hooks/salary';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getTranslate } from 'utils/i18utils';

import { companySelectors } from 'store/company/company.selectors';
import { useCountryQuerySelectors } from 'store/dictionary/hooks/useCountryQuerySelectors';
import { hiringPipelineSelectors } from 'store/entities/hiring-pipelines/hiring-pipelines.reducer';
import { jobScreeningQuestionsSelectors } from 'store/entities/job-screening-questions';
import { Job } from 'store/entities/jobs';

export interface JobReviewStepProps extends StepComponent {
  className?: string;
  formData: Partial<Job>;
}

const ExCardStyled = styled(ExCard)`
  height: 100%;
`;

const JobReviewStepStyled = styled.div`
  ${ExRow} {
    padding-top: 0;
    margin-bottom: 10px;
  }
`;

const JobSalaryReview = styled(JobSettingsCardRedesign)`
  height: auto;
  margin-bottom: 20px;
`;

const salaryRowsFields: JobDetailsItemKeys[] = ['currency', 'salaryFrom', 'salaryTo', 'salaryFrequency'];

const JobReviewStep: React.FC<JobReviewStepProps> = ({ formData }) => {
  const { push } = useHistory();
  const newJob = formData;

  useSinglePipeline({ pipelineId: newJob.pipelineId });

  const hiringPipeline = useAppSelector(hiringPipelineSelectors.selectById, newJob?.pipelineId);

  const { selectors: countriesSelectors } = useCountryQuerySelectors();
  const country = useAppSelector(countriesSelectors.selectByExpedoCode2, newJob.countryHiringFrom!);
  const jobType = useJobListOnCareerPage(newJob?.jobType);
  const isEnabledInHr = useAppSelector(companySelectors.selectIsEnabledInHr);

  const { deadlineDateOrDefaultText } = useJobDeadlineText(newJob.deadline);

  const jobDetailsFields: DetailsRowProps[] = [
    {
      label: 'Job Name',
      value: newJob?.jobName,
    },
    {
      label: 'Position',
      value: newJob?.position?.name,
    },
    ...(!isEnabledInHr
      ? [
          {
            label: 'Position Category',
            value: newJob?.position?.category,
          },
        ]
      : []),
    {
      label: 'Reference Number',
      value: newJob?.referenceNo,
    },
    {
      label: 'Job Country',
      value: country ? country.name : '--',
    },
    {
      label: 'Job Location',
      value: getLocation({ location: newJob.jobLocation, locationString: newJob.locationFormatted }),
    },
    {
      label: 'Number of Vacancies',
      value: newJob?.vacancies,
    },
    {
      label: 'End Date',
      value: deadlineDateOrDefaultText,
    },
    {
      label: 'Employment type',
      value: getEmploymentType(newJob?.employmentType),
    },
    {
      label: 'Hiring Pipeline',
      value: hiringPipeline?.name,
    },
    {
      label: 'List on Career Page',
      value: jobType?.label,
    },
  ];

  const jobSalaryFields = useSalaryInfoRows(newJob.salary!);
  const salaryRows = salaryRowsFields.map((key) => jobSalaryFields[key]);

  const editClickHandler = useCallback(
    (path): React.MouseEventHandler =>
      () =>
        push(path),
    [push],
  );

  const jobScreeningQuestions = useAppSelector(jobScreeningQuestionsSelectors.selectAll);

  return (
    <JobReviewStepStyled>
      <StepDescription>
        <StepDescription.Title>Step 4: Review your New Job</StepDescription.Title>
        <StepDescription.Description>{getTranslate('new_job.step_4.description')}</StepDescription.Description>
      </StepDescription>
      <ExRow>
        <ExColumn extraSmall="12" small="12" medium="12" large="6" extraLarge="6">
          <JobSettingsCardRedesign
            rows={jobDetailsFields}
            title="Job Detail"
            editAction={editClickHandler(Routes.jobFormStepDetails)}
          />
        </ExColumn>
        <ExColumn extraSmall="12" small="12" medium="12" large="6" extraLarge="6">
          <JobSalaryReview rows={salaryRows} title="Salary" editAction={editClickHandler(Routes.jobFormStepSalary)} />
          <JobInterviewTemplatesReview />
        </ExColumn>
      </ExRow>
      <ExRow>
        <ExColumn>
          <ExCard
            title="Job Description"
            headerActions={
              <ExCardEditButton onClick={editClickHandler(Routes.jobPositionDetails)}>Edit</ExCardEditButton>
            }
          >
            <ExCardWithoutShadow>
              <RowComponent row={{ label: 'description', value: formData.description ?? '', html: true }} />
            </ExCardWithoutShadow>
          </ExCard>
        </ExColumn>
      </ExRow>
      <ExRow>
        <ExColumn extraLarge="6">
          <ExCardStyled
            title="Responsibilities"
            headerActions={
              <ExCardEditButton onClick={editClickHandler(Routes.jobPositionDetails)}>Edit</ExCardEditButton>
            }
          >
            <ExCardWithoutShadow>
              <CheckableList items={formData.responsibilities ?? []} disabled sourceEntity={SourceEntity.Job} />
            </ExCardWithoutShadow>
          </ExCardStyled>
        </ExColumn>
        <ExColumn extraLarge="6">
          <ExCardStyled
            title="Requirements"
            headerActions={
              <ExCardEditButton onClick={editClickHandler(Routes.jobPositionDetails)}>Edit</ExCardEditButton>
            }
          >
            <ExCardWithoutShadow>
              <CheckableList items={formData.requirements ?? []} disabled sourceEntity={SourceEntity.Job} />
            </ExCardWithoutShadow>
          </ExCardStyled>
        </ExColumn>
      </ExRow>
      <ExRow>
        <ExColumn>
          <ExCardStyled
            title="Screening Questions"
            headerActions={
              <ExCardEditButton onClick={editClickHandler(Routes.jobPositionDetails)}>Edit</ExCardEditButton>
            }
          >
            <ApplicantFormScreeningQuestionsList screeningQuestions={jobScreeningQuestions} />
          </ExCardStyled>
        </ExColumn>
      </ExRow>
    </JobReviewStepStyled>
  );
};
export default JobReviewStep;
