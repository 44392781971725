import {
  EnumJobActivityType,
  InterviewState,
  InterviewTemplateState,
  JobAdStatus,
  JobStatus,
  PipelineStageType,
} from 'model/api-enums.constants';

import { ExBadgeVariant } from 'components/ui/ExBadge/ExBadgeProps';

export const jobAdStatusBadgeVariants = new Map([
  [JobAdStatus.Pending, ExBadgeVariant.warning],
  [JobAdStatus.Active, ExBadgeVariant.success],
  [JobAdStatus.Expired, ExBadgeVariant.danger],
  [JobAdStatus.Error, ExBadgeVariant.danger],
  [JobAdStatus.Draft, ExBadgeVariant.default],
  [JobAdStatus.Inactive, ExBadgeVariant.disabled],
  [JobAdStatus.Stopping, ExBadgeVariant.warning],
  [JobAdStatus.Stopped, ExBadgeVariant.danger],
  [JobAdStatus.CancelledBySystem, ExBadgeVariant.danger],
]);

export const applicantsStatusBadgeVariants = new Map([
  [PipelineStageType.New, ExBadgeVariant.new],
  [PipelineStageType.Shortlisted, ExBadgeVariant.shortlisted],
  [PipelineStageType.Interviewed, ExBadgeVariant.interviewed],
  [PipelineStageType.Screened, ExBadgeVariant.screened],
  [PipelineStageType.Negotiated, ExBadgeVariant.negotiated],
  [PipelineStageType.ReadyToOnboard, ExBadgeVariant.readyToOnboard],
  [PipelineStageType.InOnboarding, ExBadgeVariant.inOnboarding],
  [PipelineStageType.Hired, ExBadgeVariant.hired],
  [PipelineStageType.Declined, ExBadgeVariant.declined],
  [PipelineStageType.Withdrawn, ExBadgeVariant.withdrawn],
  [PipelineStageType.Custom, ExBadgeVariant.warning],
]);

export const jobAdDeadlineBadgeVariants = new Map([
  [ExBadgeVariant.default, ExBadgeVariant.primary],
  [ExBadgeVariant.warning, ExBadgeVariant.warning],
  [ExBadgeVariant.danger, ExBadgeVariant.danger],
  [ExBadgeVariant.primary, ExBadgeVariant.primary],
]);

export const jobStatusBadgeVariants = new Map([
  [JobStatus.open, ExBadgeVariant.success],
  [JobStatus.cancelled, ExBadgeVariant.warning],
  [JobStatus.draft, ExBadgeVariant.default],
  [JobStatus.filled, ExBadgeVariant.success],
]);

export const openJobsWidgetStatusBadgeVariants = new Map([
  [EnumJobActivityType.open, ExBadgeVariant.primary],
  [EnumJobActivityType.expiringSoon, ExBadgeVariant.warning],
  [EnumJobActivityType.recentlyExpired, ExBadgeVariant.danger],
]);

export const openJobsWidgetStatusTextVariants = new Map([
  [EnumJobActivityType.open, ExBadgeVariant.default],
  [EnumJobActivityType.expiringSoon, ExBadgeVariant.warning],
  [EnumJobActivityType.recentlyExpired, ExBadgeVariant.danger],
  [EnumJobActivityType.ongoing, ExBadgeVariant.primary],
]);

export const listOnCareerPageBadgeVariants = new Map([
  [false, ExBadgeVariant.default],
  [true, ExBadgeVariant.default],
]);

export const interviewTemplateStatusBadgeVariants = new Map([
  [InterviewTemplateState.Saved, ExBadgeVariant.success],
  [InterviewTemplateState.Draft, ExBadgeVariant.inactive],
]);

export const interviewStatusBadgeVariants = new Map([
  [InterviewState.Saved, ExBadgeVariant.success],
  [InterviewState.Draft, ExBadgeVariant.inactive],
]);
