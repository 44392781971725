import { all, call, fork, join, put, SagaReturnType, select } from 'redux-saga/effects';

import {
  getJobApplicantEducationFunc,
  getJobApplicantOrderedInfoFunc,
  getJobApplicantWorkExperienceFunc,
} from 'api-endpoints/applicant';

import { applicantListActions, applicantListSelectors } from 'containers/ApplicantLists/store';
import { mapApplicant } from 'containers/ApplicantLists/store/mappers';
import { applicantModalSlice } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/store';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { createByIdActionLoader } from 'utils/loaders/createByIdActionLoader';
import { customLoadAction } from 'utils/loaders/customLoadAction';
import { invokeApiCall, ReturnData } from 'utils/sagas';

import { applicantsActions } from 'store/entities/applicants';
import { ApplicantBelongsTo } from 'store/entities/applicants/models';
import { candidateEducationsSlice } from 'store/entities/candidate-education/candidate-education.reducer';
import { candidateWorkExperienceSlice } from 'store/entities/candidate-work-experience/candidate-work-experience.reducer';
import { getJobApplicantsForApplicantModal } from 'store/entities/jobs/api/requestMappers';

function* loadEducation(jobId: string, applicantId: string) {
  const loader = createByIdActionLoader(customLoadAction({ type: 'loadEducation', id: applicantId }));
  yield startLoader(loader);

  const { data: educations }: ReturnData<typeof getJobApplicantEducationFunc> = yield call(
    invokeApiCall,
    getJobApplicantEducationFunc,
    {
      urlParams: { jobId, applicantId },
    },
  );

  if (educations) {
    yield put(
      candidateEducationsSlice.actions.upsertMany(
        educations.map(({ educationId, ...education }) => ({
          educationId,
          applicantId,
          ...education,
        })),
      ),
    );
  }

  yield stopLoader(loader);
}

function* loadWorkExperience(jobId: string, applicantId: string, candidateId: string) {
  const loader = createByIdActionLoader(customLoadAction({ type: 'loadWorkExperience', id: applicantId }));
  yield startLoader(loader);

  const { data: workExperiences }: ReturnData<typeof getJobApplicantWorkExperienceFunc> = yield call(
    invokeApiCall,
    getJobApplicantWorkExperienceFunc,
    {
      urlParams: { jobId, applicantId },
      params: {
        orderBy: 'AdvancedStartDate',
        orderDirection: 'Ascending',
      },
    },
  );

  if (workExperiences) {
    const ids = workExperiences.map(({ workExperienceId }) => workExperienceId) as string[];
    yield put(candidateWorkExperienceSlice.actions.removeMany(ids));

    yield put(
      candidateWorkExperienceSlice.actions.upsertMany(
        workExperiences.map(({ workExperienceId, ...workExperience }) => ({
          ...workExperience,
          workExperienceId: workExperienceId!,
          applicantId,
          candidateId,
        })),
      ),
    );
  }

  yield stopLoader(loader);
}
export function* applicantsModalFetchWorker(action: ReturnType<typeof applicantsActions.applicantsModalFetchAction>) {
  const { applicantId, jobId } = action.payload;

  if ([!applicantId, !jobId].some(Boolean)) {
    return;
  }
  yield startLoader(action);

  const listParams: SagaReturnType<typeof applicantListSelectors.getListParams> = yield select(
    applicantListSelectors.getListParams,
    { id: ApplicantBelongsTo.job },
  );

  const preparedListParams: SagaReturnType<typeof getJobApplicantsForApplicantModal> = yield call(
    getJobApplicantsForApplicantModal,
    { params: listParams },
  );

  const response: ReturnData<typeof getJobApplicantOrderedInfoFunc> = yield invokeApiCall(
    getJobApplicantOrderedInfoFunc,
    {
      urlParams: { applicantId, jobId },
      data: preparedListParams.params,
    },
  );

  if (!response.data) {
    yield stopLoader(action);
    return;
  }

  const {
    currentApplicant,
    inListPosition,
    nextApplicant,
    pageNo,
    prevApplicant,
    totalApplicantsCount,
    numberOfComments,
  } = response.data;

  const currentApplicantEducationTask = yield fork(loadEducation, jobId, applicantId);
  const currentApplicantWorkExperienceTask = yield fork(
    loadWorkExperience,
    jobId,
    applicantId,
    currentApplicant.candidateId,
  );

  if (nextApplicant) {
    yield put(
      applicantsActions.upsertOne({
        item: { ...nextApplicant, id: nextApplicant.applicantId },
      }),
    );
  }

  if (prevApplicant) {
    yield put(
      applicantsActions.upsertOne({
        item: { ...prevApplicant, id: prevApplicant.applicantId },
      }),
    );
  }

  const applicant = mapApplicant(currentApplicant);

  yield all([
    put(applicantListActions.updatePage({ id: ApplicantBelongsTo.job, pageNo })),
    put(applicantModalSlice.actions.set({ totalApplicantsCount, inListPosition, numberOfComments })),
    put(
      applicantsActions.upsertOne({
        item: { ...applicant },
      }),
    ),
  ]);

  yield join(currentApplicantEducationTask);
  yield join(currentApplicantWorkExperienceTask);
  yield stopLoader(action);

  const preloadTasks: any[] = [];
  if (nextApplicant?.applicantId) {
    preloadTasks.push(yield fork(loadEducation, jobId, nextApplicant.applicantId));
    preloadTasks.push(yield fork(loadWorkExperience, jobId, nextApplicant.applicantId, nextApplicant.candidateId));
  }

  if (prevApplicant?.applicantId) {
    preloadTasks.push(yield fork(loadEducation, jobId, prevApplicant.applicantId));
    preloadTasks.push(yield fork(loadWorkExperience, jobId, prevApplicant.applicantId, prevApplicant.candidateId));
  }

  yield join(preloadTasks);
}

export function* updateApplicantAfterStageChange({ applicantId, jobId, stageId }) {
  if ([!applicantId, !jobId, !stageId].some(Boolean)) {
    return;
  }

  const listParams: SagaReturnType<typeof applicantListSelectors.getListParams> = yield select(
    applicantListSelectors.getListParams,
    { id: ApplicantBelongsTo.job },
  );

  const response: ReturnData<typeof getJobApplicantOrderedInfoFunc> = yield invokeApiCall(
    getJobApplicantOrderedInfoFunc,
    {
      urlParams: { applicantId, jobId },
      data: {
        ...listParams,
        pipelineStages: [stageId],
      },
    },
  );

  if (!response.data) {
    return;
  }

  const { currentApplicant } = response.data;

  const applicant = mapApplicant(currentApplicant);

  yield put(applicantsActions.upsertOne({ item: { ...applicant } }));
}
