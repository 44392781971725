import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from 'router';
import styled from 'styled-components';

import { JobAdAdditionalDetailsCard } from 'containers/JobAdForms/JobAdAdditionalDetailsCard';
import { JobAdDetailsCard } from 'containers/JobAdForms/JobAdDetailsCard';
import { jobAdFormEffects, jobAdFormSelectors } from 'containers/JobAdForms/state';
import { JobAdFormState } from 'containers/JobAdForms/state/models';
import {
  NewJobAdContextForm,
  NewJobAdsContext,
} from 'containers/Modals/ModalsContent/JobAds/NewJobAds/NewJobAds.context';

import { DetailsRowProps, JobAdAdditionalDetailsRowProps } from 'components/DetailsCard';
import { FormCard } from 'components/FormCard';
import { StepComponent, StepDescription } from 'components/StepForm';
import { ExCard } from 'components/ui/ExCard';
import { ExCardHeader } from 'components/ui/ExCard/components';
import { getEmploymentType } from 'utils/getEmploymentType';
import { getLocation } from 'utils/getLocation';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useCurrency } from 'utils/hooks/useCurrency';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getTranslate } from 'utils/i18utils';

import { useCountryQuerySelectors } from 'store/dictionary/hooks/useCountryQuerySelectors';
import { useCurrencyQuerySelectors } from 'store/dictionary/hooks/useCurrencyQuerySelectors';
import { useSectorQuerySelectors } from 'store/dictionary/hooks/useSectorQuerySelectors';
import { JobAd } from 'store/entities/job-ads/models';
import { JobBoard, jobBoardSelectors } from 'store/entities/job-boards';
import { jobsSelectors } from 'store/entities/jobs';

export interface NewJobAdReviewStepProps extends StepComponent {
  className?: string;
}

const ExCardStyled = styled(ExCard)`
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const NewJobAdReviewStepPure: React.FC<NewJobAdReviewStepProps> = ({ className, formData }) => {
  const dispatch = useAppDispatch();
  const jobAd = formData as JobAd;
  const jobId = jobAd.jobId;
  const job = useAppSelector(jobsSelectors.getById, jobId);
  const jobBoardIds = jobAd.jobBoardIds ?? [];
  const jobBoards = useAppSelector(jobBoardSelectors.selectByIds, jobBoardIds);
  const additionFieldsView = useAppSelector(jobAdFormSelectors.selectAdditionFieldsView);
  const history = useHistory();
  const { selectors: sectorsSelectors } = useSectorQuerySelectors();
  const sector = useAppSelector(sectorsSelectors.selectById, jobAd?.sector);

  const { changeForm } = useContext(NewJobAdsContext);

  useEffect(() => {
    changeForm(formData as NewJobAdContextForm);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { selectors: countriesSelectors } = useCountryQuerySelectors();
  const country = useAppSelector(countriesSelectors.selectByExpedoCode2, job?.countryHiringFrom!);

  const { selectors: currenciesSelectors } = useCurrencyQuerySelectors();
  const currency = useAppSelector(currenciesSelectors.selectById, job?.salary?.currency);

  const { makeStringFromSalaryRange } = useCurrency({
    currency: currency?.currency,
  });

  const jobDetails: DetailsRowProps[] = [
    { label: 'Job Name', value: job?.jobName },
    { label: 'Reference Number', value: job?.referenceNo },
    {
      label: 'Employment Type',
      value: getEmploymentType(job?.employmentType),
    },
    {
      label: 'Job Location',
      value: getLocation({ location: job?.jobLocation, country, locationString: job?.locationFormatted }),
    },
    { label: 'Salary', value: makeStringFromSalaryRange(job?.salary) },
    { label: 'Currency', value: job?.salary?.currency },
  ];

  const jobBoardsDetails: DetailsRowProps[] = jobBoards
    .filter((e) => e)
    .map(({ name }) => ({ label: 'Job Board', value: name }));

  const jobAdDetails: DetailsRowProps[] = [
    { label: 'Job Ad Name', value: jobAd.jobAdName || '--' },
    { label: 'Sector', value: sector?.name },
    {
      label: 'Employment type',
      value: getEmploymentType(jobAd.employmentType),
    },
    { label: 'Country', value: jobAd.jobAdLocation?.country },
    {
      label: 'Job Ad Location',
      value: getLocation({ location: jobAd.jobAdLocation, country, locationString: jobAd.locationFormatted }),
    },
    {
      label: 'Start Date',
      value: jobAd.startDate,
    },
  ];

  const jobAdAdditionalDetails: JobAdAdditionalDetailsRowProps[] = [
    {
      label: 'Job Ad Description',
      value: jobAd.description,
      html: true,
    },
  ];

  useEffect(() => {
    jobBoards.forEach((jobBoard) => {
      if (jobAd.jobBoards?.[jobBoard.jobBoardId]) {
        dispatch(
          jobAdFormEffects.additionalFieldsView({
            data: jobAd.jobBoards[jobBoard.jobBoardId],
            jobBoardId: jobBoard.jobBoardId,
          }),
        );
      }
    });
  }, [dispatch, jobAd.jobBoards, jobBoards]);

  const navigateTo = (url: string) => () => {
    history.push(url);
  };

  return (
    <div className={className}>
      <StepDescription>
        <StepDescription.Title>Step 5: Review</StepDescription.Title>
        <StepDescription.Description>{getTranslate('new_job_ad.step_5.description')}</StepDescription.Description>
      </StepDescription>
      <ExCardStyled header={<ExCardHeader title="Job Details" onClick={navigateTo(Routes.jobAdSelectJob)} />}>
        <JobAdDetailsCard rows={jobDetails} />
      </ExCardStyled>
      <ExCardStyled header={<ExCardHeader title="Job Boards" onClick={navigateTo(Routes.jobAdSelectJobBoards)} />}>
        <JobAdDetailsCard rows={jobBoardsDetails} />
      </ExCardStyled>
      <ExCardStyled header={<ExCardHeader title="Job Ads Details" onClick={navigateTo(Routes.jobAdDetails)} />}>
        <JobAdDetailsCard rows={jobAdDetails} />
        <JobAdAdditionalDetailsCard rows={jobAdAdditionalDetails} />
      </ExCardStyled>
      <ExCardStyled
        header={<ExCardHeader title="Additional Requirements" onClick={navigateTo(Routes.jobAdRequirements)} />}
      >
        {jobBoards.map((jobBoard, i) => (
          <AdditionFieldsByBoard
            key={jobBoard?.jobBoardId + '_' + i}
            jobBoardId={jobBoard?.jobBoardId}
            additionFieldsView={additionFieldsView}
            showDivider={i !== jobBoards.length - 1}
          />
        ))}
      </ExCardStyled>
    </div>
  );
};

const JobAdDetailsCardStyled = styled(JobAdDetailsCard)`
  margin: 0;
`;

export const AdditionFieldsByBoard: React.FC<{
  additionFieldsView: JobAdFormState['additionFieldsView'];
  jobBoardId: JobBoard['jobBoardId'];
  showDivider: boolean;
}> = ({ jobBoardId, additionFieldsView }) => {
  if (!additionFieldsView[jobBoardId]) {
    return null;
  }
  const jobAdAdditionGroupOne: DetailsRowProps[] =
    additionFieldsView[jobBoardId].additionFieldView
      ?.filter((i) => i.displayValue)
      .map((i) => ({
        label: i.displayName,
        value: i.displayValue,
      })) || [];
  return (
    <div>
      {jobAdAdditionGroupOne.length > 0 && (
        <JobAdDetailsCardStyled title={`${additionFieldsView[jobBoardId].jobBoardName}`} rows={jobAdAdditionGroupOne} />
      )}
    </div>
  );
};

export const NewJobAdReviewStep = styled(NewJobAdReviewStepPure)`
  ${FormCard.FormCardBodyDivider} {
    margin: 0 -20px;
  }
`;
