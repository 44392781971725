import { PayloadAction } from '@reduxjs/toolkit';
import { ValidationError } from 'yup';
import { Task } from 'redux-saga';
import { all, call, cancel, fork, put, SagaReturnType, select, take, takeLatest } from 'redux-saga/effects';

import * as applicantApi from 'api-endpoints/applicant';

import { JobApplicantsByStage } from 'model';
import { JobStatus, JobType, PipelineStageType } from 'model/api-enums.constants';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { validationSchemaJobV2 } from 'containers/JobForms/validators/validationSchemaJobV2';
import { loaderActions } from 'containers/Loader/store';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';
import { talentPoolFormSelectors } from 'containers/TalentPoolForms/state';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { getTranslate } from 'utils/i18utils';

import { enhancedJobAdsApi } from 'store/entities/job-ads/job-ads.api';
import * as fromJobActions from 'store/entities/jobs/job.actions';
import { loadJobWorker } from 'store/entities/jobs/job-sagas/job-load.saga';
import { enhancedJobApi } from 'store/entities/jobs/jobs.api';
import { Job } from 'store/entities/jobs/models';
import { jobsSelectors } from 'store/entities/jobs/selectors';
import * as fromModalActions from 'store/modals/modals.actions';
import * as fromModalConstants from 'store/modals/modals.constants';
import { ExModal } from 'store/modals/modals.interfaces';
import { modalSagaWorker } from 'store/modals/modals.sagas';
import { modalById } from 'store/modals/modals.selectors';

const getAllJobErrors = async (innerJob?: Job) => {
  try {
    await validationSchemaJobV2.validate(innerJob, { abortEarly: false });
  } catch (e) {
    return (e as ValidationError).errors || [];
  }

  return [];
};

const validateJob = async (job?: Job) => {
  return validationSchemaJobV2.isValid(job);
};

function* jobChangeStatusModal(action: ReturnType<typeof fromJobActions.updateJobStatusWithModalAction>) {
  const { payload } = action;
  const { jobId, status, listId, jobStatus } = payload;

  const job: Job | undefined = yield select(jobsSelectors.getById, jobId);

  yield call(startLoader, action);

  const result = yield call(modalSagaWorker, {
    modalType: ModalsTypeKey.wizard,
    modalConfig: {
      wizardType: 'jobChangeStatus',
      page: 'confirm',
      content: {
        title: 'Confirm Action',
        withTitle: true,
      },
    },
    modalProps: {
      jobId,
      status,
      jobStatus,
    },
  });

  if (result.cancel) {
    yield call(stopLoader, action);
    return;
  }

  const modalId = result.confirm.payload.id;

  if (status === 'open') {
    yield call(stopLoader, action);

    const modalResult = result.confirm.payload.modalResult || {};
    if (modalResult.jobType === undefined) {
      modalResult.jobType = job?.jobType || JobType.Unlisted;
    }
    yield put(
      fromJobActions.updateJobStatusAction({
        urlParams: {
          jobId,
          status,
        },
        data: {
          ...modalResult,
          listOnCareerPage: modalResult.jobType === JobType.External,
        },
        listId,
      }),
    );

    yield take(fromJobActions.jobUpdateStatusTypes.FINALLY);
    yield put(fromModalActions.exModalHideAction({ id: modalId }));
    return;
  }

  const talentPoolIds = yield select(talentPoolFormSelectors.selectedTalentPoolsIds);
  const postData = {
    jobId,
    talentPoolIds,
  };
  yield put(loaderActions.start(fromJobActions.updateJobStatusWithModalAction.type));
  const { message } = yield call(applicantApi.postAddToTalentPool, {
    data: postData,
    urlParams: { jobId },
  });
  yield put(loaderActions.stop(fromJobActions.updateJobStatusWithModalAction.type));

  if (message) {
    yield put(alertsEffects.showError({ message }));
  } else {
    const successMessage = 'Candidates successfully added.';
    yield put(alertsEffects.showSuccess({ message: successMessage }));
  }

  yield call(stopLoader, action);
  yield put(fromModalActions.exModalHideAction({ id: modalId }));
}

function* showJobErrors({ job, id }) {
  const errors: SagaReturnType<typeof getAllJobErrors> = yield call(getAllJobErrors, job);

  if (errors.length) {
    yield put(
      fromModalActions.updateWizardPage({
        id,
        modalProps: {
          errors,
        },
        page: 'validation',
      }),
    );
  }
}

function* handleTransitionConfirm({ modalProps, id }) {
  if (modalProps?.status !== 'open') {
    yield put(fromModalActions.updateWizardPage({ id, page: 'suggestion' }));
    return;
  }

  if (modalProps?.jobStatus !== undefined && ![JobStatus.cancelled, JobStatus.draft].includes(modalProps.jobStatus)) {
    yield all([
      put(fromModalActions.updateWizardPage({ id, page: 'pending', modalConfig: { content: { withTitle: false } } })),
      put(fromModalActions.exModalConfirmAction({ id })),
    ]);
    return;
  }

  yield put(fromModalActions.updateWizardPage({ id, page: 'selectJobType' }));
}

function* handleTransitionSelectJobType(id: string) {
  const modal: ExModal = yield select(modalById, id);

  // Because validation need to make API calls we change modal step to step with loader
  yield put(fromModalActions.updateWizardPage({ id, page: 'pending', modalConfig: { content: { withTitle: false } } }));

  yield call(loadJobWorker, { payload: { jobId: modal.modalProps?.jobId }, type: 'getJobIfNeeded' });
  const job: ReturnType<typeof jobsSelectors.getById> = yield select(jobsSelectors.getById, modal.modalProps?.jobId);

  // Check is job valid
  const isJobValid = yield call(validateJob, job);
  if (!isJobValid) {
    // If the job is not valid the next function redirect a user to a step for showing errors
    yield showJobErrors({ job, id });
    return;
  }
  yield put(fromModalActions.exModalConfirmAction({ id, modalResult: { jobType: modal.modalProps?.jobType } }));
}

function* handleTransition() {
  while (true) {
    const { type, payload }: PayloadAction<fromModalActions.WizardNavigation> = yield take([
      fromModalConstants.MODAL_WIZARD_FORWARD,
      fromModalConstants.MODAL_WIZARD_BACKWARD,
      fromModalConstants.MODAL_WIZARD_ERROR,
    ]);
    const id = payload.id;
    if (!id) {
      break;
    }
    const { modalConfig, modalProps }: ExModal = yield select(modalById, id);
    const { page } = modalConfig || {};
    if (type === fromModalConstants.MODAL_WIZARD_FORWARD) {
      switch (page) {
        case 'confirm':
          yield call(handleTransitionConfirm, { modalProps, id });
          break;
        case 'createNewTalentPool':
        case 'suggestion':
          yield put(fromModalActions.updateWizardPage({ id, page: 'selectTalentPool' }));
          break;
        case 'selectJobType':
          yield call(handleTransitionSelectJobType, id);
          break;
      }
    }
  }
}

function* prepareConfirmMessage(id: string) {
  const modal: ExModal = yield select(modalById, id);
  const { modalProps } = modal;
  const { status, jobId } = modalProps || {};

  const keyPath = 'jobs_change_status.confirm';

  const job: ReturnType<typeof jobsSelectors.getById> = yield select(jobsSelectors.getById, jobId);
  const selector = yield call(enhancedJobApi.endpoints.jobApplicantsPerPipelineStageFigures.select, { jobId });
  const { data } = yield select(selector);
  const jobApplicantsPerPipelineStageFigures = (job?.jobApplicantsByStage ||
    data?.jobApplicantsPerPipelineStageFigures ||
    []) as JobApplicantsByStage[];
  const inOnboardingStage = jobApplicantsPerPipelineStageFigures.find(
    (stage) => stage.stageType === PipelineStageType.InOnboarding,
  );
  const countOfHired = inOnboardingStage?.value ?? 0;
  const jobName = job?.jobName && job.jobName.length > 70 ? job.jobName?.slice(0, 70) + '...' : job?.jobName;

  const interpolateParams = {
    countOfHired,
    jobName,
    status,
  };

  const messageConfirm = getTranslate(keyPath, interpolateParams);

  let message = messageConfirm;

  if (status === 'complete') {
    const messageComplete = getTranslate('jobs_change_status.complete', interpolateParams);
    message = `${messageComplete} ${messageConfirm}`;
  }

  yield put(
    fromModalActions.exModalPropsAction({
      ...modal,
      modalConfig: {
        ...modal.modalConfig,
        content: {
          ...modal.modalConfig?.content,
          message,
          status,
        },
      },
    }),
  );
}

/**
 * Function get separator for the message.
 * If message item is last returns '' - the empty string,
 * if just before last returns ' and ',
 * in other cases return ', '
 *
 * Resulted message will be looks like example below:
 *
 * @example
 *
 * 'There are 0 Shortlisted, 0 Screened, 0 Withdrawn, 1 Offered, 0 Interviewed and 1 New Candidates in this job'
 *
 * @param {number} index
 * @param {number} length
 * @returns {string}
 */
function getMessageSeparator(index: number, length: number): string {
  switch (true) {
    case index === length - 2:
      return ' and ';
    case index === length - 1:
      return '';
    default:
      return ', ';
  }
}

const filterHiredAndDeclined = (stage: JobApplicantsByStage) =>
  ![PipelineStageType.Hired, PipelineStageType.Declined].includes(stage.stageType);

function createApplicantsPerStageMessage(
  jobApplicantsPerPipelineStageFigures: Job['jobApplicantsPerPipelineStageFigures'],
) {
  // Filter Hired and Declined stages;
  const stages = jobApplicantsPerPipelineStageFigures?.filter(filterHiredAndDeclined);

  let message = stages?.reduce((acc, stage, index, allStages) => {
    const stepMessage = acc + `${stage.value} ${stage.name}`;

    return stepMessage + getMessageSeparator(index, allStages.length);
  }, 'There are ');

  message += ` Candidates in this job.\n Would you like to move them to a Talent Pool(s)?`;

  return message;
}

function* suggestionPage(id: string) {
  const modal: ExModal = yield select(modalById, id);
  const jobId = modal.modalProps?.jobId;
  const status = modal.modalProps?.status;
  const listId = modal.modalProps?.listId;
  const stopJobAds = modal.modalProps?.stopJobAds;
  const needUpdateJobAds = modal.modalProps?.needUpdateJobAds;

  if (stopJobAds) {
    const thunk = yield call(enhancedJobAdsApi.endpoints.stopJobAdsForJob.initiate, {
      jobId,
      data: {
        jobBoardIds: null,
      },
      needUpdateJobAds,
    });
    yield put(thunk);
  }

  yield put(
    fromJobActions.updateJobStatusAction({
      urlParams: {
        jobId,
        status,
      },
      listId,
    }),
  );
  if (modal.modalProps?.status && !['complete', 'cancel'].includes(modal.modalProps.status)) {
    return yield put(fromModalActions.exModalConfirmAction({ id }));
  }
  const job: Job | undefined = yield select(jobsSelectors.getById, jobId);

  const selector = yield call(enhancedJobApi.endpoints.jobApplicantsPerPipelineStageFigures.select, { jobId });
  const { data } = yield select(selector);
  const jobApplicantsPerPipelineStageFigures = (job?.jobApplicantsByStage ||
    data?.jobApplicantsPerPipelineStageFigures ||
    []) as JobApplicantsByStage[];

  if (!jobApplicantsPerPipelineStageFigures) {
    yield put(fromModalActions.exModalCancelAction({ id }));
    return;
  }

  const stages = Object.values(jobApplicantsPerPipelineStageFigures);
  // Filter Hired and Declined stages;
  const filteredStages = stages.filter(filterHiredAndDeclined);
  const isEveryStagesAreEmpty = filteredStages.every((stage) => stage.value === 0);

  // If every stage contains 0 applicants - skip this modal wizard step
  if (isEveryStagesAreEmpty) {
    yield put(fromModalActions.exModalCancelAction({ id }));
    return;
  }

  const message = yield call(createApplicantsPerStageMessage, jobApplicantsPerPipelineStageFigures);

  const title = {
    cancel: 'You have cancelled this job',
    complete: 'You have completed this job',
  }[status];

  yield put(
    fromModalActions.exModalPropsAction({
      ...modal,
      modalConfig: {
        ...modal.modalConfig,
        content: {
          ...modal.modalConfig?.content,
          withTitle: false,
          title,
          message,
        },
      },
    }),
  );
}

function* prepareStepSelectTalentPool(id: string) {
  const modal: ExModal = yield select(modalById, id);

  yield stopLoader(fromJobActions.updateJobStatusWithModalAction);
  yield put(
    fromModalActions.exModalPropsAction({
      ...modal,
      modalConfig: {
        ...modal.modalConfig,
        content: {
          ...modal.modalConfig?.content,
          withTitle: true,
          title: 'Add to new Talent Pool',
        },
      },
    }),
  );
}

function* createNewTalentPool(id: string) {
  const modal: ExModal = yield select(modalById, id);
  yield put(
    fromModalActions.exModalPropsAction({
      ...modal,
      modalConfig: {
        ...modal.modalConfig,
        content: {
          ...modal.modalConfig?.content,
          withTitle: true,
          title: 'Create new Talent Pool',
        },
      },
    }),
  );
}

function* selectJobType(id: string) {
  const modal: ExModal = yield select(modalById, id);
  yield put(
    fromModalActions.exModalPropsAction({
      ...modal,
      modalConfig: {
        ...modal.modalConfig,
        content: {
          ...modal.modalConfig?.content,
          withTitle: true,
          title: 'Select Job Type',
        },
      },
    }),
  );
}

function* validation(id: string) {
  const modal: ExModal = yield select(modalById, id);
  yield put(
    fromModalActions.exModalPropsAction({
      ...modal,
      modalConfig: {
        ...modal.modalConfig,
        content: {
          ...modal.modalConfig?.content,
          withTitle: true,
          title: 'Job validation',
        },
      },
    }),
  );
}

function* onStepChangeBackgroundActions() {
  let task: Task | undefined;
  while (true) {
    const {
      payload: { id, page },
    }: PayloadAction<fromModalActions.UpdateWizardPage> = yield take(fromModalConstants.MODAL_PAGE_UPDATE);

    // Cancel ongoing process if exists
    if (task && task.isRunning()) {
      yield cancel(task);
    }

    switch (page) {
      case 'confirm':
        task = yield fork(prepareConfirmMessage, id);
        break;
      case 'selectJobType':
        task = yield fork(selectJobType, id);
        break;
      case 'suggestion':
        task = yield fork(suggestionPage, id);
        break;
      case 'selectTalentPool':
        task = yield fork(prepareStepSelectTalentPool, id);
        break;
      case 'createNewTalentPool':
        task = yield fork(createNewTalentPool, id);
        break;
      case 'validation':
        task = yield fork(validation, id);
        break;
    }
  }
}

export function* jobChangeStatusSagas() {
  yield fork(handleTransition);
  yield fork(onStepChangeBackgroundActions);
}

export function* takeJobModalActions() {
  yield takeLatest(fromJobActions.updateJobStatusWithModalAction, jobChangeStatusModal);
}
