import React from 'react';
import { useSelector } from 'react-redux';

import { jobBoardFormSelectors } from 'containers/JobBoardsForm';

import { SubTitleItem } from 'components/FormStepsPanel';

export const SelectedJobBoard: React.FC = () => {
  const jobBoard = useSelector(jobBoardFormSelectors.selectJobBoard);
  return <SubTitleItem>{jobBoard?.name}</SubTitleItem>;
};
