export enum WidgetReducers {
  RecentApplicants = 'RecentApplicants',
  RecentPlacements = 'RecentPlacements',
  HiringActivities = 'HiringActivities',
  CandidateSources = 'CandidateSources',
  PipelineActivities = 'PipelineActivities',
  TalentPools = 'TalentPools',
}

export const WIDGET_CANDIDATE_SOURCE_FETCH = 'widget/candidate/source/fetch';
