import React, { RefObject } from 'react';

import { ListViewPagination } from 'components/ListView';

import {
  PaginationWrapper,
  Table,
  TableDataRow,
  TableFooterText,
  TableRowDataEmpty,
  TableRowError,
  TableRowLoader,
  TableRowOverlayWithLoader,
  TableWrapper,
  Tbody,
  Thead,
  TheadRow,
} from './ExTable.components';
import { useExTableState } from './ExTable.hook';
import { ExTableProps, TableDataProps } from './ExTable.props';

type ExTablePaginationProps = {
  pageCount: number;
  tableFooterText?: string;
  pageNo: number;
  onPaginationClick?: (clickedPage: number) => void;
  tableRef?: RefObject<HTMLTableElement>;
  scrollToOffset?: number;
  onMouseEnterPage?: (clickedPage: number) => void;
};

export const ExTablePagination: React.FC<ExTablePaginationProps> = ({
  pageCount,
  tableFooterText,
  pageNo,
  onPaginationClick,
  tableRef,
  scrollToOffset = 0,
  onMouseEnterPage,
}) => {
  const showPagination = Boolean(pageCount);

  const paginationClickHandler = (pageNumber: number) => {
    if (onPaginationClick) {
      onPaginationClick(pageNumber);
      if (tableRef?.current) {
        const boundingClientRect = tableRef.current.getBoundingClientRect();
        const scrollY = window.scrollY;
        const posY = (boundingClientRect.top || 0) + scrollY - 100 - scrollToOffset;
        window.scrollTo({ top: Math.max(0, posY), behavior: 'smooth' });
      }
    }
  };

  if (!showPagination) {
    return null;
  }

  return (
    <PaginationWrapper>
      {tableFooterText && <TableFooterText>{tableFooterText}</TableFooterText>}
      <ListViewPagination
        pageCount={pageCount}
        pageNo={pageNo}
        updatePage={paginationClickHandler}
        onMouseEnterPage={onMouseEnterPage}
      />
    </PaginationWrapper>
  );
};

export const ExTable = <T extends {}, S>({
  componentLoader: ComponentLoader,
  componentError: ComponentError,
  componentDataEmpty: ComponentDataEmpty,
  onMouseEnterPage,
  ...props
}: ExTableProps<T, S>) => {
  const {
    data,
    minCellWidth,
    pageCount,
    pageNo,
    headers,
    sortClickHandler,
    showLoadingRow,
    isError,
    columnsCount,
    showLoadingOverlay,
    tbodyRef,
    tableRef,
    scrolled,
    tableFooterText,
  } = useExTableState(props);

  return (
    <>
      <TableWrapper scrolled={scrolled}>
        <Table minCellWidth={minCellWidth} ref={tableRef}>
          <Thead>
            <TheadRow headers={headers} onSortClick={sortClickHandler} />
          </Thead>
          <Tbody ref={tbodyRef} scrolled={scrolled}>
            <TableData
              columnsCount={columnsCount}
              data={data}
              headers={headers}
              isError={isError}
              isLoading={showLoadingRow}
              componentError={ComponentError}
              componentLoader={ComponentLoader}
              componentDataEmpty={ComponentDataEmpty}
            />
          </Tbody>
        </Table>
      </TableWrapper>
      {showLoadingOverlay && <TableRowOverlayWithLoader component={ComponentLoader} />}
      <ExTablePagination
        pageCount={pageCount}
        tableFooterText={tableFooterText}
        pageNo={pageNo}
        onPaginationClick={props.onPaginationClick}
        tableRef={tableRef}
        onMouseEnterPage={onMouseEnterPage}
      />
    </>
  );
};

const TableData = <T extends {}, S>({
  isLoading,
  isError,
  data,
  columnsCount,
  headers,
  componentError,
  componentLoader,
  componentDataEmpty,
}: TableDataProps<T, S>) => {
  if (isLoading) {
    return <TableRowLoader columnsCount={columnsCount} component={componentLoader} />;
  }

  if (isError) {
    return <TableRowError columnsCount={columnsCount} component={componentError} />;
  }

  if (data.length === 0) {
    return <TableRowDataEmpty columnsCount={columnsCount} component={componentDataEmpty} />;
  }

  return (
    <>
      {data.map((row) => (
        <TableDataRow key={row.id} row={row} headers={headers} />
      ))}
    </>
  );
};
