import { createAction } from '@reduxjs/toolkit';

import { createLoader, createRequestTypes } from 'utils/actions';

import { ApplicantsPerSourceFigures } from 'store/ui/widgets/candidate-sources/candidate-sources.model';
import { WIDGET_CANDIDATE_SOURCE_FETCH } from 'store/ui/widgets/widgets.constants';

export const candidateSourcesTypes = createRequestTypes(WIDGET_CANDIDATE_SOURCE_FETCH);
export const requestCandidateSourcesLoad = createAction(candidateSourcesTypes.LOAD);
export const requestCandidateSourcesCancel = createAction(candidateSourcesTypes.CANCEL);
export const requestCandidateSourcesSuccess = createAction<ApplicantsPerSourceFigures>(candidateSourcesTypes.SUCCESS);

export const loadCandidateSourcesHandler = createLoader(
  {
    success: (data: ApplicantsPerSourceFigures) => requestCandidateSourcesSuccess(data),
  },
  candidateSourcesTypes,
);
