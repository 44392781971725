import { createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';

import { list } from 'config';

import type { SearchTalentPoolCandidatesAsyncRequest } from 'api-endpoints/talent-pool-candidates';

import { WithRequired } from 'model/utils';

import { UIKeys, ViewMode } from 'store/constants';

export type SelectedCandidates = {
  photoUrl: string;
  candidateId: EntityId;
  fullName: string;
};

export const selectedTalentPoolCandidateListAdapter = createEntityAdapter<SelectedCandidates>({
  selectId: (model) => model.candidateId,
});

type TalentPoolCandidateListUiSlice = ReturnType<typeof getInitialState>;

const getDefaultFilters = () =>
  ({
    pageSize: list.pageSize,
    pageNo: 0,
  } as WithRequired<SearchTalentPoolCandidatesAsyncRequest, 'pageSize' | 'pageNo'>);

const getInitialState = () => ({
  filters: getDefaultFilters(),
  isFiltersChanged: false,
  viewMode: list.viewMode,
  selectedCandidates: selectedTalentPoolCandidateListAdapter.getInitialState(),
});

export const talentPoolCandidateListUiSlice = createSlice({
  name: UIKeys.talentPoolCandidateList,
  initialState: getInitialState(),
  reducers: {
    updateFilters: (draft, { payload }: PayloadAction<Partial<TalentPoolCandidateListUiSlice['filters']>>) => {
      Object.assign(draft.filters, payload);
      draft.isFiltersChanged =
        JSON.stringify(Object.assign(draft.filters, payload)) !== JSON.stringify(getDefaultFilters());
    },
    toggleSelect: (draft, { payload }: PayloadAction<SelectedCandidates>) => {
      if (draft.selectedCandidates.entities[payload.candidateId]) {
        selectedTalentPoolCandidateListAdapter.removeOne(
          draft.selectedCandidates,
          selectedTalentPoolCandidateListAdapter.selectId(payload),
        );
      } else {
        selectedTalentPoolCandidateListAdapter.addOne(draft.selectedCandidates, payload);
      }
    },
    setSelected: (draft, { payload }: PayloadAction<Record<SelectedCandidates['candidateId'], SelectedCandidates>>) => {
      selectedTalentPoolCandidateListAdapter.setMany(draft.selectedCandidates, payload);
    },
    deselectMany: (draft, { payload }: PayloadAction<EntityId[]>) => {
      selectedTalentPoolCandidateListAdapter.removeMany(draft.selectedCandidates, payload);
    },
    clearSelected: (draft) => {
      selectedTalentPoolCandidateListAdapter.removeAll(draft.selectedCandidates);
    },
    changeViewMode: (draft, { payload }: PayloadAction<{ viewMode: ViewMode }>) => {
      draft.viewMode = payload.viewMode;
      draft.filters.pageNo = 0;
    },
    resetFilters: (draft) => {
      draft.isFiltersChanged = false;
      draft.filters = getDefaultFilters();
    },
    resetState: getInitialState,
  },
});
