import React from 'react';

import { Ellipsis } from 'components/Ellipsis';

import { ExBadgeEllipsisStyled, ExBadgeStyled } from './ExBadgeComponents';
import { ExBadgeEllipsisProps, ExBadgeProps } from './ExBadgeProps';

export const ExBadge: React.FC<ExBadgeProps> = ({ className, ...rest }) => (
  <ExBadgeStyled className={className} {...rest} />
);

export const ExBadgeEllipsis: React.FC<ExBadgeEllipsisProps> = ({ children, clamp, ...props }) => (
  <ExBadgeEllipsisStyled {...props} as="div">
    <Ellipsis clamp={clamp}>{children}</Ellipsis>
  </ExBadgeEllipsisStyled>
);
