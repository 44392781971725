import { createSelector } from '@reduxjs/toolkit';

import { publicCompanyDomain } from 'pages/public/state/rootSelectors';

const selectPublicCompanyId = createSelector(publicCompanyDomain, (state) => state.companyInfo?.companyId);
const selectPublicCompanyLogoUrl = createSelector(
  publicCompanyDomain,
  (state) => state.companyInfo?.logoUrl ?? undefined,
);
const selectPublicCompanyIsLoading = createSelector(publicCompanyDomain, (state) => state.loading);

const selectQueryParameters = createSelector(publicCompanyDomain, (state) => state.queryParameters);

const selectIsCareerPageJob = createSelector(publicCompanyDomain, (state) => state.isCareerPageJob);

export const publicCompanySelectors = {
  selectPublicCompanyId,
  selectPublicCompanyLogoUrl,
  selectPublicCompanyIsLoading,
  selectQueryParameters,
  selectIsCareerPageJob,
};
