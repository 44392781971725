import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as Sort } from 'assets/img/redesign/icons/sort-default.svg';

import { ExHeaderInnerProps } from './ExTable.props';
import { Cell } from './ExTable.utils';

type CellProps = { sticky?: boolean; stickyEnd?: boolean; left?: number; right?: number; width?: number };

const SortStyled = styled(Sort)<{ sort?: 'Ascending' | 'Descending' }>`
  flex-shrink: 0;
  cursor: pointer;

  .arrow-asc {
    stroke: ${(props) => (props.sort === 'Ascending' ? '#555555' : '#BBBBBB')};
  }

  .arrow-desc {
    stroke: ${(props) => (props.sort === 'Descending' ? '#555555' : '#BBBBBB')};
  }
`;
export const SortIcon = <S extends 'Ascending' | 'Descending' = 'Ascending' | 'Descending'>(
  props: { sort?: S } & React.HTMLAttributes<SVGSVGElement>,
) => <SortStyled {...props} />;

export const ThInnerContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${SortStyled} {
    margin-left: 16px;
  }
`;

export const Th = styled.th<CellProps>`
  padding: 0 15px 15px;
  background-color: ${(props) => props.theme.colors.white};
  top: 0;
  position: sticky;
  ${(props) => isSticky(props)};
  z-index: 2;
`;

/**
 * Function for add styles for sticky columns and headers
 *
 * @param {CellProps} props
 * @returns css
 */
function isSticky(props: CellProps) {
  const { sticky, stickyEnd, left, right } = props;
  if (!sticky && !stickyEnd) {
    return undefined;
  }

  if (typeof left === 'number') {
    return css`
      position: sticky;
      left: ${left}px;
      z-index: 1;
    `;
  }

  if (typeof right === 'number') {
    return css`
      position: sticky;
      right: ${right}px;
    `;
  }
}

export const Td = styled.td<CellProps>`
  border-top: 1px solid ${(props) => props.theme.border.borderColor};
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor};
  ${(props) => isSticky(props)}
`;

const cssHack = css`
  display: table;
  width: 100%;
  table-layout: fixed;
`;

export const Tr = styled.tr`
  ${Td} {
    padding: 15px;
  }
`;

export const Thead = styled.thead`
  ${cssHack}

  ${Th}:nth-child(1) {
    z-index: 3;
  }
`;

const TbodyScrolled = ({ scrolled }: ScrolledProps) =>
  scrolled &&
  css`
    overflow: auto;
  `;

export const Tbody = styled.tbody`
  display: block;
  height: calc(100% - 70px);
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor};
  ${TbodyScrolled}

  ${Tr} {
    ${cssHack}
  }

  ${Td} {
    &:nth-child(1) {
      z-index: 3;
    }
  }
`;

export const Table = styled.table<{ minCellWidth?: number }>`
  width: 100%;
  min-width: 800px;
  border-collapse: collapse;

  ${Td} {
    min-width: ${(props) => (props.minCellWidth ? props.minCellWidth + 'px' : 'auto')};
  }

  ${Th} {
    min-width: ${(props) => (props.minCellWidth ? props.minCellWidth + 'px' : 'auto')};
  }

  > ${Tbody} {
    > ${Tr} {
      &.expandable {
        > ${Td} {
          padding: 0 15px;
          border-top-width: 0;
          transform: scaleY(0);
          line-height: 0;
          transition: padding 0.4s linear, line-height 0.4s linear, opacity 0.3s linear, transform 0.4s linear;
          transition: all 0.3s ease-in-out;
          overflow: hidden;
          opacity: 0;
        }

        &.expanded {
          > ${Td} {
            padding: 15px;
            line-height: 1.5;
            border-top-width: 1px;
            opacity: 1;
            transform: scaleY(1);
          }
        }
      }
    }
  }
`;

type ScrolledProps = {
  scrolled?: boolean;
};

const TableWrapperScrolled = ({ scrolled }: ScrolledProps) =>
  scrolled &&
  css`
    overflow-x: auto;
  `;

export const TableWrapper = styled.div<ScrolledProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  ${TableWrapperScrolled};
`;

export const PaginationWrapper = styled.div`
  padding-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-basis: 34px;
`;

export const TableFooterText = styled.div`
  position: absolute;
  left: 0;
  line-height: 34px;
`;

const Overlay = styled.div`
  @keyframes table-loading {
    from {
      background-color: #fff0;
    }

    to {
      background-color: #ffffff40;
    }
  }

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  place-content: center;
  place-items: center;
  background-color: #ffffff40;
  z-index: 3;
  animation: table-loading 1s;
`;

const RowWithFallback =
  (ComponentFallback?: JSX.Element) =>
  ({ columnsCount, component: Component }: { component?: JSX.Element; columnsCount: number }) =>
    (
      <Tr>
        <Td colSpan={columnsCount}>{React.isValidElement(Component) ? Component : ComponentFallback}</Td>
      </Tr>
    );

export const TableRowLoader = RowWithFallback(<div>Loading...</div>);

export const TableRowDataEmpty = RowWithFallback(<div>No data</div>);

export const TableRowError = RowWithFallback(<div>Error</div>);

export const TableRowOverlayWithLoader = ({ component: Component }: { component?: JSX.Element }) => (
  <Overlay>{React.isValidElement(Component) ? Component : <div>Loading...</div>}</Overlay>
);

export const TheadRow = <T extends {}, S>({
  headers,
  onSortClick,
}: {
  headers: Array<ExHeaderInnerProps<T, S>>;
  onSortClick: (header: ExHeaderInnerProps<T, S>) => React.MouseEventHandler;
}) => (
  <Tr>
    {headers.map((header, index) => {
      const Component = header.headerComponent;
      return (
        <Th key={header.label + index} {...header} style={header.css}>
          {header.customHeader && Component && Component()}
          {!header.customHeader && (
            <ThInnerContentWrapper>
              <>
                {header.label}
                {header.isSortable && <SortIcon sort={header?.sortDir} onClick={onSortClick(header)} />}
              </>
            </ThInnerContentWrapper>
          )}
        </Th>
      );
    })}
  </Tr>
);

export const TableDataRow = <T extends {}, S>({
  headers,
  row,
}: {
  row: T;
  headers: Array<ExHeaderInnerProps<T, S>>;
}) => (
  <Tr>
    {headers.map((cell, index) => {
      return (
        <Td
          style={cell.css}
          sticky={cell.sticky}
          stickyEnd={cell.stickyEnd}
          left={cell.left}
          right={cell.right}
          key={cell.label + index}
          width={cell.width}
        >
          <Cell cell={cell} row={row} />
        </Td>
      );
    })}
  </Tr>
);
