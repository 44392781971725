import { all, call, fork, put, putResolve, race, select, take, takeLatest } from 'redux-saga/effects';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { createEditPipelineLoaderId, loaderActions } from 'containers/Loader/store';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { getTranslate } from 'utils/i18utils';
import { invokeExModal, invokeExModalWizard, prepareExModalChannel } from 'utils/sagas';

import {
  exModalConfirmAction,
  exModalHideAction,
  exModalPropsAction,
  updateWizardPage,
  wizardBackward,
  wizardForward,
} from 'store/modals/modals.actions';
import { ModalGeneralResult } from 'store/modals/modals.interfaces';

import { editHiringPipelineSaga } from './sagas/hiring-piplines-edit/hiring-pipelines-edit.saga';
import { enhancedHiringPipelineApi, HIRING_PIPELINE_TAG_TYPE } from './hiring-pipelines.api';
import { hiringPipelineActions, hiringPipelineSelectors } from './hiring-pipelines.reducer';

// REMOVE SAGA
function* removeHiringPipelineSaga(action: ReturnType<typeof hiringPipelineActions.remove>) {
  const hiringPipeline = yield select(hiringPipelineSelectors.selectById, action.payload);

  const { modalId, sagaChannel } = yield prepareExModalChannel();

  // Start modal wizard
  yield fork(invokeExModalWizard, {
    channel: sagaChannel,
    modalId,
    modalProps: {},
    modalConfig: {
      wizardType: 'hiringPipelineDelete',
      page: 'confirmHPD',
      content: {
        title: 'Remove Hiring Pipeline',
        message: `You confirm remove ${hiringPipeline.name} pipeline?`,
        withTitle: true,
      },
    },
  });

  while (true) {
    let backupId;

    const { forward, cancel } = yield race({
      forward: take(wizardForward),
      cancel: take(wizardBackward),
    });

    if (!forward || cancel) {
      return;
    }

    if (hiringPipeline.isInUseByJob) {
      yield put(
        updateWizardPage({
          id: modalId,
          page: 'selectHPD',
          modalConfig: {
            content: {
              title: 'Select New Pipeline',
              withTitle: true,
            },
          },
          modalProps: {
            deletedPipelineId: hiringPipeline.pipelineId,
          },
        }),
      );

      const { confirm: submit, cancel: close }: ModalGeneralResult = yield race({
        cancel: take(wizardBackward),
        confirm: take(exModalConfirmAction),
      });

      if (!submit || close) {
        return;
      }

      backupId = submit.payload.modalResult?.backupId;
    }

    yield call(startLoader, action);

    const mutationDelete = yield call(enhancedHiringPipelineApi.endpoints.deletePipeline.initiate, {
      pipelineId: action.payload,
      params: { backupId },
    });
    const { error } = yield putResolve(mutationDelete);
    const errorData = error?.data;

    if (!errorData) {
      yield all([put(alertsEffects.showSuccess({ message: getTranslate('company.hiringPipeLine.remove.success') }))]);
    }

    yield put(exModalHideAction({ id: modalId }));
    yield call(stopLoader, action);
  }
}
//---------------------------------------------------------------------------------------------------------

// RENAME SAGA
function* renameHiringPipelineSaga(action: ReturnType<typeof hiringPipelineActions.rename>) {
  const { modalId, sagaChannel } = yield prepareExModalChannel();

  const pipelineId = action.payload;
  const hiringPipeline = yield select(hiringPipelineSelectors.selectById, pipelineId);

  yield fork(invokeExModalWizard as any, {
    channel: sagaChannel,
    modalId,
    modalProps: { pipelineId, hiringPipeline },
    modalConfig: {
      wizardType: 'hiringPipelineEdit',
      page: 'hiringPipelineRename',
      content: {
        withTitle: true,
        title: 'Rename Custom Pipeline',
      },
    },
  });

  while (true) {
    const { confirm, cancel } = yield race({
      cancel: take(wizardBackward),
      confirm: take(hiringPipelineActions.create),
    });

    if ([cancel, !confirm].some(Boolean)) {
      yield put(exModalHideAction({ id: modalId }));
      return;
    }

    yield call(startLoader, action);
    const name = confirm.payload.name;
    const mutationRename = yield call(enhancedHiringPipelineApi.endpoints.updatePipeline.initiate, {
      pipelineId: action.payload,
      data: { name },
    });
    const { error } = yield putResolve(mutationRename);
    const errorData = error?.data;

    if (!errorData) {
      yield all([
        put(alertsEffects.showSuccess({ message: getTranslate('company.hiringPipeLine.rename.success') })),
        put(exModalHideAction({ id: modalId })),
        call(stopLoader, action),
      ]);
      return;
    }

    yield call(stopLoader, action);
    yield put(exModalPropsAction({ id: modalId, modalProps: { errorData } } as any));
  }
}
//---------------------------------------------------------------------------------------------------------

// REORDER
function* hiringPipelineReorderWorker(action: ReturnType<typeof hiringPipelineActions.reorder>) {
  const { pipelineId, pipelineStageIdsOrder } = action.payload;

  const editPipelineLoaderId = createEditPipelineLoaderId(pipelineId);
  yield put(loaderActions.start(editPipelineLoaderId));

  const mutationReorder = yield call(enhancedHiringPipelineApi.endpoints.updatePipelineStagesOrder.initiate, {
    pipelineId,
    data: { pipelineStageIdsOrder },
  });
  yield putResolve(mutationReorder);
  yield put(enhancedHiringPipelineApi.util.invalidateTags([{ type: HIRING_PIPELINE_TAG_TYPE, id: pipelineId }]));

  yield put(loaderActions.stop(editPipelineLoaderId));
}
// --------------------------------------------------------------------------------------------------------

// MAKE DEFAULT SAGA
function* makeDefaultHiringPipelineSaga(action: ReturnType<typeof hiringPipelineActions.makeDefault>) {
  const { modalId, sagaChannel } = yield prepareExModalChannel();

  const pipelineId = action.payload;
  const hiringPipeline = yield select(hiringPipelineSelectors.selectById, pipelineId);

  yield fork(invokeExModal, {
    channel: sagaChannel,
    modalId,
    modalType: ModalsTypeKey.confirmModal,
    modalConfig: {
      content: {
        buttonOk: 'Confirm',
        buttonOkVariant: 'primary',
        title: 'Make Default Pipeline',
        message: `Are you sure you want to make ${hiringPipeline.name} default pipeline?`,
        withActions: true,
        withTitle: true,
      },
    },
  });

  const { confirm, cancel }: ModalGeneralResult = yield take(sagaChannel);

  if (cancel || !confirm) {
    return;
  }

  yield call(startLoader, action);

  const mutationDefault = yield call(enhancedHiringPipelineApi.endpoints.setDefaultPipeline.initiate, {
    pipelineId,
  });
  const { error } = yield putResolve(mutationDefault);
  const errorData = error?.data;

  if (!errorData) {
    yield put(alertsEffects.showSuccess({ message: getTranslate('company.hiringPipeLine.setDefault.success') }));
  }

  yield put(exModalHideAction({ id: modalId }));
  yield call(stopLoader, action);
}
//---------------------------------------------------------------------------------------------------------

// ENABLE SAGA
function* enableHiringPipelineSaga(action: ReturnType<typeof hiringPipelineActions.enable>) {
  const { modalId, sagaChannel } = yield prepareExModalChannel();

  const pipelineId = action.payload;
  const hiringPipeline = yield select(hiringPipelineSelectors.selectById, pipelineId);

  yield fork(invokeExModal, {
    channel: sagaChannel,
    modalId,
    modalType: ModalsTypeKey.confirmModal,
    modalConfig: {
      content: {
        buttonOk: 'Confirm',
        buttonOkVariant: 'primary',
        title: 'Enable Pipeline',
        message: `Are you sure you want to enable ${hiringPipeline.name}?`,
        withActions: true,
        withTitle: true,
      },
    },
  });

  const { confirm, cancel }: ModalGeneralResult = yield take(sagaChannel);

  if (cancel || !confirm) {
    return;
  }

  yield call(startLoader, action);

  const mutationEnable = yield call(enhancedHiringPipelineApi.endpoints.enablePipeline.initiate, {
    pipelineId,
  });
  const { error } = yield putResolve(mutationEnable);
  const errorData = error?.data;

  if (!errorData) {
    yield put(alertsEffects.showSuccess({ message: getTranslate('company.hiringPipeLine.enable.success') }));
  }

  yield put(exModalHideAction({ id: modalId }));
  yield call(stopLoader, action);
}
//---------------------------------------------------------------------------------------------------------

// DISABLE SAGA
function* disableHiringPipelineSaga(action: ReturnType<typeof hiringPipelineActions.disable>) {
  const { modalId, sagaChannel } = yield prepareExModalChannel();

  const pipelineId = action.payload;
  const hiringPipeline = yield select(hiringPipelineSelectors.selectById, pipelineId);

  yield fork(invokeExModal, {
    channel: sagaChannel,
    modalId,
    modalType: ModalsTypeKey.confirmModal,
    modalConfig: {
      content: {
        buttonOk: 'Confirm',
        buttonOkVariant: 'primary',
        title: 'Disable Pipeline',
        message: `Are you sure you want to disable ${hiringPipeline.name}?`,
        withActions: true,
        withTitle: true,
      },
    },
  });

  const { confirm, cancel }: ModalGeneralResult = yield take(sagaChannel);

  if (cancel || !confirm) {
    return;
  }

  yield call(startLoader, action);

  const mutationDisable = yield call(enhancedHiringPipelineApi.endpoints.disablePipeline.initiate, {
    pipelineId,
  });
  const { error } = yield putResolve(mutationDisable);
  const errorData = error?.data;

  if (!errorData) {
    yield put(alertsEffects.showSuccess({ message: getTranslate('company.hiringPipeLine.disable.success') }));
  }

  yield put(exModalHideAction({ id: modalId }));
  yield call(stopLoader, action);
}
//---------------------------------------------------------------------------------------------------------

export function* hiringPipelineWorker() {
  yield takeLatest(hiringPipelineActions.remove, removeHiringPipelineSaga);
  yield takeLatest(hiringPipelineActions.rename, renameHiringPipelineSaga);
  yield takeLatest(
    [hiringPipelineActions.edit, hiringPipelineActions.addNew, hiringPipelineActions.clone],
    editHiringPipelineSaga,
  );
  yield takeLatest(hiringPipelineActions.reorder, hiringPipelineReorderWorker);
  yield takeLatest(hiringPipelineActions.makeDefault, makeDefaultHiringPipelineSaga);
  yield takeLatest(hiringPipelineActions.enable, enableHiringPipelineSaga);
  yield takeLatest(hiringPipelineActions.disable, disableHiringPipelineSaga);
}
