import { Action, AnyAction, nanoid } from '@reduxjs/toolkit';
import { buffers, Channel, channel } from 'redux-saga';
import { all, call, fork, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { getCompanyDaxtraQualificationsFunc } from 'api-endpoints/company';
import * as fromApiQualificationType from 'api-endpoints/qualification-type';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { loaderActions } from 'containers/Loader/store';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { withoutLoader } from 'modules/LoaderManager/utils';
import { getTranslate } from 'utils/i18utils';
import { invokeApiCall } from 'utils/sagas';

import { authSelectors } from 'store/auth/auth.selectors';
import { companyActions } from 'store/company';
import { DaxtraQualificationMapping } from 'store/company/company.models';
import { companySelectors } from 'store/company/company.selectors';
import {
  qualificationTypeActions,
  QualificationTypeMappingDTO,
  QualificationTypeModel,
  qualificationTypeSelectors,
} from 'store/entities/qualification-type/qualification-type.model';
import { modalsConfig } from 'store/modals/config';
import { exModalHideAction, exModalPropsAction, updateWizardPage, wizardForward } from 'store/modals/modals.actions';
import { ExModal, ModalGeneralResult, ModalResult } from 'store/modals/modals.interfaces';
import { modalSagaWorker } from 'store/modals/modals.sagas';
import { modalById } from 'store/modals/modals.selectors';

// SAGA REMOVE------------------------------------------------------------------

function checkIsQualificationTypeMapped(
  data: DaxtraQualificationMapping[],
  qualificationId: QualificationTypeModel['qualificationId'],
) {
  return data.filter((mappingItem) => mappingItem.qualificationId === qualificationId);
}

function* checkIsQualificationTypeMappedWorker(qualificationId: QualificationTypeModel['qualificationId']) {
  const data = yield select(companySelectors.getCompanyDaxtraMapping);
  const mapped = checkIsQualificationTypeMapped(data, qualificationId);

  return mapped.length > 0 ? mapped : undefined;
}

function* invokeModalWizard(modalChannel: Channel<any>, modalId: string, qualificationId: string, page: string) {
  const result: ModalGeneralResult = yield call(modalSagaWorker as any, {
    id: modalId,
    modalConfig: {
      content: {
        title: 'Confirm Action',
        withTitle: true,
      },
      page,
      wizardType: 'qualificationTypeRemove',
    },
    modalProps: {
      qualificationId,
    },
    modalType: ModalsTypeKey.wizard,
  });

  yield put(modalChannel, result);
}

function* removeQualificationType(qualificationId: QualificationTypeModel['qualificationId']) {
  return yield call(invokeApiCall, fromApiQualificationType.removeQualificationTypeApiCall, {
    urlParams: { qualificationId },
  });
}

function* changePage(
  page: keyof typeof modalsConfig['qualificationTypeRemove'],
  modalId: string,
  modalConfig?: ExModal['modalConfig'],
  modalProps?: ExModal['modalProps'],
) {
  const modal: ReturnType<typeof modalById> = yield select(modalById, modalId);

  if (!modal) {
    return;
  }

  yield put(
    exModalPropsAction({
      ...modal,
      modalConfig: {
        ...modal.modalConfig,
        ...(modalConfig || ({} as ExModal['modalConfig'])),
        page,
      },
      modalProps: {
        ...modal.modalProps,
        ...modalProps,
      },
    }),
  );
}

function* checkIsLastQualificationType() {
  const qualificationsTypeTotal = yield select(qualificationTypeSelectors.selectTotal);

  return qualificationsTypeTotal === 1;
}

export function* qualificationTypeRemoveWorker(
  action: ReturnType<typeof qualificationTypeActions.qualificationTypeRemove>,
) {
  const { qualificationId } = action.payload;
  const modalId = nanoid();
  const modalChannel = yield channel(buffers.none());
  const page = 'pending';

  const isLastQualificationType = yield call(checkIsLastQualificationType);

  if (isLastQualificationType) {
    yield all([
      put(alertsEffects.showWarning({ message: "You can't remove the last qualification type" })),
      put(exModalHideAction({ id: modalId })),
    ]);
    return;
  }

  yield put(withoutLoader(qualificationTypeActions.qualificationTypeFetch.start({ silent: true })));

  yield fork(invokeModalWizard as any, modalChannel, modalId, qualificationId, page);

  yield take('qualificationType/prefetch/success');

  const qualificationTypeMapping: DaxtraQualificationMapping[] | undefined = yield call(
    checkIsQualificationTypeMappedWorker,
    qualificationId,
  );

  if (qualificationTypeMapping) {
    yield put(updateWizardPage({ id: modalId, page: 'confirmWithQuestion' }));
  } else {
    yield put(updateWizardPage({ id: modalId, page: 'confirm' }));
  }

  let modalResult = yield race({
    nextStep: take(wizardForward),
    result: take(modalChannel),
  });

  if (modalResult.result?.confirm) {
    yield call(startLoader, action);
    yield call(removeQualificationType, qualificationId);
    yield all([
      put(withoutLoader(qualificationTypeActions.qualificationTypeFetch.start({ silent: true }))),
      put(exModalHideAction({ id: modalId })),
      put(
        alertsEffects.showSuccess({
          message: getTranslate('company.qualificationTypes.remove.success'),
        }),
      ),
      call(stopLoader, action),
    ]);
  }

  if (modalResult.nextStep) {
    yield put(updateWizardPage({ id: modalId, page: 'pending' }));
    yield put(qualificationTypeActions.qualificationTypeFetch.start({ silent: true }));
    yield call(
      changePage,
      'daxtraMapping',
      modalId,
      { content: { title: 'Qualification Mapping', withTitle: true } },
      {
        daxtraQualifications: qualificationTypeMapping?.map(
          (daxtraQualification) => daxtraQualification.daxtraQualificationName,
        ),
        qualificationId,
      },
    );
  }

  modalResult = yield race({
    nextStep: take(wizardForward),
    result: take(modalChannel),
  });
  const existedDaxtraMapping = yield select((state) =>
    companySelectors.getCompanyDaxtraMappingExcludeById(state, qualificationId),
  );
  if (modalResult.result?.confirm) {
    const { items }: { items: QualificationTypeMappingDTO[] } = modalResult.result.confirm.payload.modalResult;

    const { errorData } = yield call(invokeApiCall, fromApiQualificationType.assignDaxtraMappingApiCall, {
      data: {
        items: [...existedDaxtraMapping, ...items],
      },
    });
    if (!errorData) {
      yield put(
        alertsEffects.showSuccess({
          message: getTranslate('company.qualificationTypesMaps.update.success'),
        }),
      );
      yield put(qualificationTypeActions.qualificationTypeFetch.start({ silent: true }));
      yield call(removeQualificationType, qualificationId);
      yield put(
        alertsEffects.showSuccess({
          message: getTranslate('company.qualificationTypes.remove.success'),
        }),
      );
    }
  }

  yield all([
    put(qualificationTypeActions.qualificationTypeFetch.start({ silent: true })),
    put(exModalHideAction({ id: modalId })),
  ]);
}

// END SAGA REMOVE--------------------------------------------------------------

// SAGA FETCH ------------------------------------------------------------------
export function* qualificationTypeFetchWorker(action: Action) {
  yield call(startLoader, action);

  const result = yield all([
    call(
      invokeApiCall,
      fromApiQualificationType.getQualificationTypes,
      {},
      { action: qualificationTypeActions.qualificationTypeFetch },
    ),
    call(
      invokeApiCall,
      fromApiQualificationType.getDaxtraMappingApiCall,
      {},
      { action: qualificationTypeActions.qualificationTypeFetch },
    ),
    call(
      invokeApiCall,
      getCompanyDaxtraQualificationsFunc,
      {},
      { action: qualificationTypeActions.qualificationTypeFetch },
    ),
  ]);

  const dataForEffects = result.map((resultItem: { data: any; errorData: any }) => resultItem.data);

  yield put(qualificationTypeActions.setAll(dataForEffects[0]));
  yield put(
    companyActions.update({
      daxtra: {
        mapping: dataForEffects[1]?.items,
        qualifications: dataForEffects[2],
      },
    }),
  );

  yield call(stopLoader, action);
  yield put({ type: 'qualificationType/prefetch/success' });
}

// END SAGA FETCH --------------------------------------------------------------

// SAGA CREATE -----------------------------------------------------------------
function* invokeModal(modalChannel: Channel<any>, modalId: string, modalType: ModalsTypeKey) {
  const result: ModalGeneralResult = yield call(modalSagaWorker, {
    modalType,
    ...(modalId ? { id: modalId } : {}),
  });

  yield put(modalChannel, result);
}

function* prepareRequestData(payload: ModalResult['modalResult']) {
  const companyId = yield select(authSelectors.selectCurrentCompanyId);

  return {
    data: { ...payload },
    urlParams: {
      companyId,
      qualificationId: payload?.qualificationId,
    },
  };
}

export function* qualificationTypeCreateWorker(
  action: ReturnType<typeof qualificationTypeActions.qualificationTypeCreate>,
) {
  const modalId = nanoid();
  const sagaChannel = yield channel(buffers.none());
  while (true) {
    yield fork(invokeModal as any, sagaChannel, modalId, ModalsTypeKey.companyUpdateQualificationTypes);

    const result: ModalGeneralResult = yield take(sagaChannel);

    if (result.cancel || !result.confirm) {
      return;
    }
    yield call(startLoader, action);
    const requestData = yield call(prepareRequestData, result.confirm.payload.modalResult);
    const { errorData } = yield call(
      invokeApiCall as any,
      fromApiQualificationType.createQualificationTypeApiCall,
      requestData,
    );

    if (!errorData) {
      yield all([
        put(exModalHideAction({ id: modalId })),
        put(
          alertsEffects.showSuccess({
            message: getTranslate('company.qualificationTypes.update.success'),
          }),
        ),
        put(withoutLoader(qualificationTypeActions.qualificationTypeFetch.start({ silent: true }))),
      ]);
      yield call(stopLoader, action);
      break;
    }
  }
}
// END SAGA CREATE -------------------------------------------------------------

// SAGA EDIT -----------------------------------------------------------------
type QualificationTypeUpdateAction = ReturnType<typeof qualificationTypeActions.qualificationTypeUpdate.start>;
export function* qualificationTypeEditWorker(action: QualificationTypeUpdateAction) {
  const modalId = nanoid();
  const qualificationType: ReturnType<typeof qualificationTypeSelectors.selectById> = yield select(
    qualificationTypeSelectors.selectById,
    action.payload.qualificationId,
  );

  while (true) {
    const result: ModalGeneralResult = yield call(modalSagaWorker, {
      id: modalId,
      modalConfig: {
        content: { title: 'Edit Qualification Type' },
      },
      modalProps: { qualificationType },
      modalType: ModalsTypeKey.companyUpdateQualificationTypes,
    });
    if (result.cancel || !result.confirm) {
      continue;
    }
    yield call(startLoader, action);
    const requestData = yield call(prepareRequestData, result.confirm.payload.modalResult);

    const { errorData, message } = yield call(
      invokeApiCall,
      fromApiQualificationType.updateQualificationTypeApiCall,
      requestData,
    );
    if (!errorData && !message) {
      yield all([
        put(exModalHideAction({ id: modalId })),
        put(
          alertsEffects.showSuccess({
            message: getTranslate('company.qualificationTypes.update.success'),
          }),
        ),
        put(qualificationTypeActions.qualificationTypeFetch.start({ silent: true })),
        call(stopLoader, action),
      ]);
      break;
    }
    yield call(stopLoader, action);
  }
}

// END SAGA EDIT -------------------------------------------------------------

function* exPreloaderWrapperWorker({ type, payload }: AnyAction & { silent?: boolean }) {
  if (payload?.silent) {
    return;
  }
  const actionType = type.replace(/\/request$/, '');
  yield put(loaderActions.start(actionType));
  yield race([
    take((action: AnyAction) => /.*\/success$/.test(action.type)),
    take((action: AnyAction) => /.*\/failure$/.test(action.type)),
    take((action: AnyAction) => /.*\/fulfilled$/.test(action.type)),
    take((action: AnyAction) => /.*\/rejected$/.test(action.type)),
  ]);
  yield put(loaderActions.stop(actionType));
}

// SAGA ------------------------------------------------------------------------
export function* qualificationTypeSaga() {
  yield takeLatest(qualificationTypeActions.qualificationTypeRemove, qualificationTypeRemoveWorker);
  yield takeLatest(qualificationTypeActions.qualificationTypeFetch.start, qualificationTypeFetchWorker);
  yield takeLatest(qualificationTypeActions.qualificationTypeCreate, qualificationTypeCreateWorker);
  yield takeEvery((action: AnyAction) => /.*\/request$/.test(action.type), exPreloaderWrapperWorker);
  yield takeLatest(qualificationTypeActions.qualificationTypeUpdate.start, qualificationTypeEditWorker);
}
//------------------------------------------------------------------------------
