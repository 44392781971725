import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';

import { Country, FilterOption } from 'model';

import { jobBoardListActions, jobBoardListSelectors, JobBoardListState } from 'containers/JobBoardLists/store';

import { FormCard } from 'components/FormCard';
import { FormTagSelect } from 'components/FormTagSelect';
import { ExFiltersSideModal } from 'components/ui/ExFiltersSideModal';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { useLazyCompanyCountryQuery } from 'store/dictionary/dictionary.api';
import { useQueryForLazy } from 'store/dictionary/hooks/useQueryForLazy';
import { JobBoardFiltersNames } from 'store/entities/job-boards';
import { useSelector } from 'store/rootSelectors';

import { jobBoardListResetFilters } from './store/job-board.actions';

type JobBoardListFiltersModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  listId: JobBoardListState['id'];
};

const emptyState = {
  options: [] as Array<FilterOption>,
  values: [] as Array<any>,
};

export const JobBoardListFiltersModal: React.FC<JobBoardListFiltersModalProps> = ({ isOpen, closeModal, listId }) => {
  const dispatch = useAppDispatch();
  const filters = useSelector((state) => jobBoardListSelectors.getFilters(state, { id: listId }));
  type FilterKeys = keyof Omit<
    typeof filters,
    JobBoardFiltersNames.belongsTo | JobBoardFiltersNames.name | JobBoardFiltersNames.status
  >;
  const initialFormState: Record<FilterKeys, typeof emptyState> = {
    countryCodes: {
      options: [],
      values: [],
    },
  };
  const [values, setValues] = React.useState<Record<FilterKeys, typeof emptyState>>(initialFormState);

  useEffect(() => {
    if (!filters.countryCodes?.length) {
      setValues((prevState) => ({
        ...prevState,
        countryCodes: { ...prevState.countryCodes, values: [] },
      }));
    }
  }, [filters.countryCodes]);

  const handleChange = (filterName: keyof typeof initialFormState) => (newValue: any) => {
    setValues({
      ...values,
      [filterName]: {
        ...values[filterName],
        values: newValue,
      },
    });
  };

  const applyFiltersHandler = () => {
    const filtersUpdated = {
      countryCodes: values.countryCodes.values,
    };
    dispatch(jobBoardListActions.updateFilters({ filters: filtersUpdated, id: listId }));
    closeModal();
  };

  const clearFiltersHandler = () => {
    dispatch(jobBoardListResetFilters({ listId }));
    closeModal();
  };

  const { isSuccess: isCompanyCountrySuccess, loader: companyCountryLoader } =
    useQueryForLazy(useLazyCompanyCountryQuery);

  return (
    <ExFiltersSideModal
      isOpen={isOpen}
      closeModal={closeModal}
      onApplyFilters={applyFiltersHandler}
      onClearFilters={clearFiltersHandler}
      title="Job Boards Filters"
    >
      <Form>
        <Form.Group>
          {isCompanyCountrySuccess && (
            <>
              <FormCard.InputLabel htmlFor="job-board-countries">Countries</FormCard.InputLabel>
              <FormTagSelect
                name="job-board-countries"
                isMulti={true}
                cacheOptions
                defaultOptions
                loadOptions={companyCountryLoader}
                getOptionLabel={(option: Country) => option.name}
                getOptionValue={(option: Country) => option.countryId}
                maxMenuHeight={250}
                closeMenuOnSelect={false}
                onChange={handleChange('countryCodes' as FilterKeys)}
                defaultValue={values.countryCodes.values}
              />
            </>
          )}
        </Form.Group>
      </Form>
    </ExFiltersSideModal>
  );
};
