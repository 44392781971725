import styled from 'styled-components';

import { ActionButton } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/ApplicantModalV3Components';

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 22px;
  padding-right: 22px;
`;

export const ActionButtonStyled = styled(ActionButton)`
  margin: 0 10px;
  white-space: nowrap;
  min-width: 120px;
  flex: auto;
  width: auto;
  flex-grow: 0;

  & + .btn-block {
    margin-top: 0;
  }
`;

export const ApplicantModalFooterStyled = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;
