import { createAction } from '@reduxjs/toolkit';

import { ExComment } from 'model';

import type { CommentAddHandlerParams, CommentUpdateHandlerParams } from 'components/ui/ExComments/ExCommentListProps';

import { Job } from 'store/entities/jobs';

import { jobCommentsSlice } from './job-comments.reducer';

const JOB_COMMENTS_GET = `${jobCommentsSlice.name}/get`;
const JOB_COMMENTS_FETCH = `${jobCommentsSlice.name}/fetch`;
const JOB_COMMENTS_START_WATCHER_TASK = `${jobCommentsSlice.name}/fetch/watcher/start`;
const JOB_COMMENTS_STOP_WATCHER_TASK = `${jobCommentsSlice.name}/fetch/watcher/stop`;
const JOB_COMMENTS_FETCH_MORE = `${jobCommentsSlice.name}/fetch/more`;
const JOB_COMMENTS_CREATE = `${jobCommentsSlice.name}/create`;
const JOB_COMMENTS_UPDATE = `${jobCommentsSlice.name}/update`;
const JOB_COMMENTS_REMOVE = `${jobCommentsSlice.name}/remove`;
const JOB_COMMENTS_READ = `${jobCommentsSlice.name}/read`;
const JOB_COMMENTS_READ_ALL = `${jobCommentsSlice.name}/read/all`;

const jobCommentGet = createAction<Pick<Job, 'jobId'> & Pick<ExComment, 'commentId'>>(JOB_COMMENTS_GET);

const jobCommentsFetch = createAction<Pick<Job, 'jobId'>>(JOB_COMMENTS_FETCH);

const jobCommentsStartWatcherTask = createAction(JOB_COMMENTS_START_WATCHER_TASK);
const jobCommentsSopWatcherTask = createAction(JOB_COMMENTS_STOP_WATCHER_TASK);

const jobCommentsFetchMore = createAction(JOB_COMMENTS_FETCH_MORE);

const jobCommentsCreate = createAction<CommentAddHandlerParams>(JOB_COMMENTS_CREATE);

const jobCommentsUpdate = createAction<CommentUpdateHandlerParams>(JOB_COMMENTS_UPDATE);

const jobCommentsRemove = createAction<{
  commentId: Required<ExComment>['commentId'];
}>(JOB_COMMENTS_REMOVE);

const jobCommentsRead = createAction<{
  comments: Array<Required<ExComment>['commentId']>;
}>(JOB_COMMENTS_READ);

const jobCommentsReadAll = createAction<Partial<Pick<Job, 'jobId'>>>(JOB_COMMENTS_READ_ALL);

export const jobCommentsActions = {
  ...jobCommentsSlice.actions,
  jobCommentGet,
  jobCommentsFetch,
  jobCommentsCreate,
  jobCommentsUpdate,
  jobCommentsRemove,
  jobCommentsRead,
  jobCommentsReadAll,
  jobCommentsFetchMore,
  jobCommentsStartWatcherTask,
  jobCommentsSopWatcherTask,
};
