import React from 'react';
import styled from 'styled-components/macro';

import { CandidateAvatar } from 'components/CandidateAvatar';
import { Ellipsis } from 'components/Ellipsis';
import { getUserInitials } from 'utils/getUserInitials';
import { getUserPosition } from 'utils/hooks/auth';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { companySelectors } from 'store/company/company.selectors';
import { jobsSelectors } from 'store/entities/jobs/selectors';

type ExCommentDropdownItemProps = {
  userId: string;
  jobId?: string;
  commentTarget?: 'JOB' | 'CANDIDATE';
};

const ExCommentDropdownItemStyled = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 6px 14px;
  width: 100%;
`;

const ExCommentDropdownItemAvatar = styled.div`
  width: 36px;
  height: 36px;
  margin-right: 15px;
  flex-shrink: 0;
`;

const ExCommentDropdownItemName = styled.div``;

const ExCommentDropdownItemSubtitle = styled.div`
  color: #c3c3c3;
  font-size: 10px;
`;

const useExCommentDropdownItemState = ({ jobId, userId, commentTarget = 'JOB' }: ExCommentDropdownItemProps) => {
  const teamMemberForJob = useAppSelector(jobsSelectors.selectTeamMemberById, { jobId, userId });
  const teamMemberForCandidate = useAppSelector(companySelectors.selectAdminTeamMemberById, { userId });
  const teamMember = commentTarget === 'JOB' ? teamMemberForJob : teamMemberForCandidate;

  const hasTeamMember = Boolean(teamMember);

  const name = teamMember?.name;
  const avatar = teamMember?.photoUrl;

  const position = getUserPosition(teamMember?.applicationRoles);
  const role = position?.applicationRoleName;
  const userInitials = getUserInitials(`${name}`);

  return {
    hasTeamMember,
    name,
    avatar,
    role,
    userInitials,
  };
};

export const ExCommentDropdownItem: React.FC<ExCommentDropdownItemProps> = (props) => {
  const { hasTeamMember, name, avatar, role, userInitials } = useExCommentDropdownItemState(props);

  if (!hasTeamMember) {
    return null;
  }

  return (
    <ExCommentDropdownItemStyled>
      <ExCommentDropdownItemAvatar>
        <CandidateAvatar src={avatar} initials={userInitials} />
      </ExCommentDropdownItemAvatar>
      <ExCommentDropdownItemName>
        <Ellipsis>{name}</Ellipsis>
        <ExCommentDropdownItemSubtitle>
          <Ellipsis>{role}</Ellipsis>
        </ExCommentDropdownItemSubtitle>
      </ExCommentDropdownItemName>
    </ExCommentDropdownItemStyled>
  );
};
