import { createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';

import { list } from 'config/list';

import type { SearchCandidatesFuncRequestParams } from 'api-endpoints/candidate/candidate.dto';

import { OrderDirection } from 'model/api-enums.constants';
import { WithRequired } from 'model/utils';

import { UIKeys, ViewMode } from 'store/constants';

type SelectedCandidates = {
  photoUrl: string;
  candidateId: EntityId;
  fullName: string;
  status: string;
};

export const selectedCandidatesAdapter = createEntityAdapter<SelectedCandidates>({
  selectId: (model) => model.candidateId,
});

const getDefaultFilters = () =>
  ({
    pageSize: list.pageSize,
    pageNo: 0,
    searchTerm: '',
    orderBy: 'FullName',
    doNotHire: false,
    orderDirection: OrderDirection.Ascending,
  } as WithRequired<
    SearchCandidatesFuncRequestParams,
    'pageNo' | 'pageSize' | 'searchTerm' | 'orderBy' | 'orderDirection' | 'doNotHire'
  >);

const getCandidateListUiInitialState = () => ({
  filters: getDefaultFilters(),
  isFiltersChanged: false,
  viewMode: list.viewMode,
  selectedCandidates: selectedCandidatesAdapter.getInitialState(),
});

export const candidatesListUiSlice = createSlice({
  name: UIKeys.candidateLists,
  initialState: getCandidateListUiInitialState(),
  reducers: {
    updateSorting: (
      draft,
      {
        payload,
      }: PayloadAction<
        Partial<Pick<ReturnType<typeof getCandidateListUiInitialState>['filters'], 'orderBy' | 'orderDirection'>>
      >,
    ) => {
      draft.filters.pageNo = 0;
      Object.assign(draft.filters, payload);
    },
    updateFilters: (
      draft,
      { payload }: PayloadAction<Partial<ReturnType<typeof getCandidateListUiInitialState>['filters']>>,
    ) => {
      Object.assign(draft.filters, payload);
      draft.isFiltersChanged =
        JSON.stringify(Object.assign(draft.filters, payload)) !== JSON.stringify(getDefaultFilters());
    },
    toggleSelect: (draft, { payload }: PayloadAction<SelectedCandidates>) => {
      if (draft.selectedCandidates.entities[payload.candidateId]) {
        selectedCandidatesAdapter.removeOne(draft.selectedCandidates, selectedCandidatesAdapter.selectId(payload));
      } else {
        selectedCandidatesAdapter.addOne(draft.selectedCandidates, payload);
      }
    },
    setSelected: (draft, { payload }: PayloadAction<Record<SelectedCandidates['candidateId'], SelectedCandidates>>) => {
      selectedCandidatesAdapter.setMany(draft.selectedCandidates, payload);
    },
    deselectMany: (draft, { payload }: PayloadAction<EntityId[]>) => {
      selectedCandidatesAdapter.removeMany(draft.selectedCandidates, payload);
    },
    clearSelected: (draft) => {
      selectedCandidatesAdapter.removeAll(draft.selectedCandidates);
    },
    changeViewMode: (draft, { payload }: PayloadAction<{ viewMode: ViewMode }>) => {
      draft.viewMode = payload.viewMode;
      draft.filters.pageNo = 0;
    },
    resetFilters: (draft) => {
      draft.isFiltersChanged = false;
      draft.filters = getDefaultFilters();
    },
  },
});
