import React from 'react';
import styled from 'styled-components/macro';

import { SpinnerSM } from 'components/Spinner';

type QuestionViewLoadingProps = {
  className?: string;
  isLoading?: boolean;
};

const QuestionViewLoadingStyled = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const QuestionViewLoading: React.FC<QuestionViewLoadingProps> = ({ className, isLoading }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <QuestionViewLoadingStyled className={className}>
      <SpinnerSM />
    </QuestionViewLoadingStyled>
  );
};
