import { JobApplicantItem, JobApplicantListItem } from 'api-endpoints/job';

import { Applicant, applicantToState } from 'store/entities/applicants';

interface MapApplicantsReturnValue {
  applicantsIds: Array<Applicant['id']>;
  applicants: Array<Applicant>;
}

type ApplicantResponse = JobApplicantListItem | JobApplicantItem;

export const mapApplicants = (response: Array<ApplicantResponse>): MapApplicantsReturnValue => {
  return response.map(applicantToState).reduce(
    (acc, applicant) => ({
      applicantsIds: [...acc.applicantsIds, applicant?.id],
      applicants: [...acc.applicants, applicant],
    }),
    {
      applicantsIds: [],
      applicants: [],
    } as MapApplicantsReturnValue,
  );
};

export const mapApplicant = (response: ApplicantResponse): Applicant => {
  return applicantToState(response);
};
