import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as SortAsc } from 'assets/img/redesign/icons/sort-ascending.svg';
import { ReactComponent as Sort } from 'assets/img/redesign/icons/sort-default.svg';
import { ReactComponent as SortDesc } from 'assets/img/redesign/icons/sort-descending.svg';

import { ExVisible } from 'components/ui/ExVisible';
import { safariFlexBoxTweak } from 'utils/styled/common';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

import { SortDirections } from 'store/constants';

import { ListTableViewHeaderProps } from './ListViewProps';

const columnActionsCss = css`
  border-left-width: 2px;
  width: 30px;
`;

const ListTableViewTableHeader = styled.th<{ isAction: boolean; order?: string | number }>`
  ${(props) => props.isAction && columnActionsCss};
  ${(props) => props.order && 'cursor: pointer;'}
`;

const ListTableViewTableItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ListTableViewTableLabel = styled.div`
  font-weight: 600;
  font-size: ${getThemeFontSize('tableHeading')};
`;

const ListTableViewTableSortOrder = styled.div`
  padding: 0 5px;
  margin-right: -10px;
  cursor: pointer;
  user-select: none;
  ${safariFlexBoxTweak};

  svg {
    display: inline-block;
  }
`;

const ListTableViewHeader: React.FC<ListTableViewHeaderProps> = ({
  order,
  updateSort,
  label,
  className,
  style,
  orderDirection,
  orderBy,
}) => {
  const clickHandler = () => order && updateSort(order);
  const isAction = label === 'Action';
  const isOrderDirectionAsc = orderDirection === SortDirections.asc;

  return (
    <ListTableViewTableHeader
      className={className}
      style={style}
      onClick={clickHandler}
      isAction={isAction}
      order={order}
    >
      <ListTableViewTableItem>
        <ListTableViewTableLabel>{label}</ListTableViewTableLabel>
        <ExVisible visible={!!order}>
          {order === orderBy ? (
            <ListTableViewTableSortOrder>
              {isOrderDirectionAsc && <SortAsc />}
              {!isOrderDirectionAsc && <SortDesc />}
            </ListTableViewTableSortOrder>
          ) : (
            <ListTableViewTableSortOrder>
              <Sort />
            </ListTableViewTableSortOrder>
          )}
        </ExVisible>
      </ListTableViewTableItem>
    </ListTableViewTableHeader>
  );
};

export default styled(ListTableViewHeader)``;
