import React, { lazy } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAuth } from 'auth';

import { ExCatchErrorComponent } from 'components/ExCatchErrorComponent';

import { authSelectors } from 'store/auth/auth.selectors';
import { useSelector } from 'store/rootSelectors';

const Forbidden403 = lazy(() => import('pages/Forbidden403').then((module) => ({ default: module.Forbidden403 })));

type RouterRBACProps = RouteProps & {
  feature?: string;
  redirect?: string;
};

const useRouterRBACState = (props: Omit<RouterRBACProps, 'component'>) => {
  const {
    state: { isAuthenticated, loading: isAuthLoading },
  } = useAuth();

  const access = useSelector((state) => authSelectors.isFeatureAllowed(state, { feature: props.feature }));

  const hasAccess = props.feature ? access : true;

  const isForbidden = hasAccess === false;

  return {
    isAuthLoading,
    isAuthenticated,
    isForbidden,
  } as const;
};

export const RouteRBAC: React.FC<RouterRBACProps> = ({ component: Component, children, ...props }) => {
  const { isAuthenticated, isForbidden, isAuthLoading } = useRouterRBACState(props);

  if (!isAuthenticated && !isAuthLoading) {
    return <Redirect to="/" />;
  } else if (isForbidden && !!props.redirect) {
    return <Redirect to={props.redirect} />;
  } else if (isForbidden) {
    return <Forbidden403 />;
  }

  if (Component) {
    return (
      <Route
        {...props}
        render={(routeProps) => (
          <ExCatchErrorComponent>
            <Component {...routeProps} />
          </ExCatchErrorComponent>
        )}
      />
    );
  }

  return (
    <Route {...props}>
      <ExCatchErrorComponent>{children}</ExCatchErrorComponent>
    </Route>
  );
};
