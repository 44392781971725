import {
  InterviewTableCellComponent,
  InterviewTableCellComponentProps,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/Tabs/InterviewsTab/InterviewsTabProps';

import { ExBadge, useBadgeVariants } from 'components/ui/ExBadge';
import { interviewStatusBadgeVariants } from 'components/ui/ExBadge/config';
import { getTranslate } from 'utils/i18utils';

const useInterviewStatusState = ({ item: interview, className }: InterviewTableCellComponentProps) => {
  const status = interview.state;

  const { variant } = useBadgeVariants({ status, variants: interviewStatusBadgeVariants });
  const badgeText = getTranslate(`interview.status.${status}` as any);

  return {
    className,
    variant,
    badgeText,
  } as const;
};

export const InterviewStatus: InterviewTableCellComponent = (props) => {
  const { className, variant, badgeText } = useInterviewStatusState(props);

  return (
    <ExBadge className={className} variant={variant}>
      {badgeText}
    </ExBadge>
  );
};
