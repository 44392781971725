import React from 'react';
import { Link } from 'react-router-dom';
import { ExRoutes } from 'router/routes';

import { EmptyState } from 'components/EmptyState';
import { IconEmptyStateSuitcase } from 'components/Icons/IconEmptyStateSuitcase';
import { TableEmptyRowLoaderStyle } from 'components/TableList/TableListComponents';
import { ExAddNewButton } from 'components/ui/ExAddNewButton/ExAddNewButton';

const title = 'There are no jobs to display.';
const btnText = 'Create New Job';
const btnLink = ExRoutes.jobCreate();

const JobListPlaceholder: React.FC = () => (
  <TableEmptyRowLoaderStyle>
    <EmptyState title={title} icon={<IconEmptyStateSuitcase />}>
      <ExAddNewButton variant="primary" size="sm" forwardedAs={Link} to={btnLink}>
        {btnText}
      </ExAddNewButton>
    </EmptyState>
  </TableEmptyRowLoaderStyle>
);

export default JobListPlaceholder;
