import React from 'react';
import { AppLayout, AppLayoutProps } from 'layouts/AppLayout';
import { EmptyLayout } from 'layouts/EmptyLayout';
import { LayoutProvider, useLayoutState } from 'layouts/layout-context';

type DynamicLayoutProps = AppLayoutProps;

const Layouts = { 'app-layout': AppLayout, 'empty-layout': EmptyLayout, 'fragment-layout': React.Fragment };

const useDynamicLayoutState = ({ children, ...layoutProps }: DynamicLayoutProps) => {
  const { layout } = useLayoutState();

  return {
    layout,
    layoutProps: layout === 'fragment-layout' ? { children } : { children, ...layoutProps },
  } as const;
};

const DynamicLayoutPure: React.FC<DynamicLayoutProps> = (props) => {
  const { layout, layoutProps } = useDynamicLayoutState(props);

  return React.createElement(Layouts[layout], layoutProps as DynamicLayoutProps);
};

export const DynamicLayout: React.FC<DynamicLayoutProps> = (props) => (
  <LayoutProvider>
    <DynamicLayoutPure {...props} />
  </LayoutProvider>
);
