import { Persona, PersonaExtended } from '@expedo/types';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

// New Directory service store to ensure we can get the DS persona from different screens
// Since the Persona is a concept that doesn't exist in hire, it has been challenging to
// set the correct persona. Hence, this store will be responsible for feeding the correct
// persona to the primary nav.

type DsPayload = Partial<ReturnType<typeof initialState>>;

const initialState = () => ({
  dsPersona: null as Persona | PersonaExtended | null,
});

const { actions, reducer, name } = createSlice({
  name: 'directoryService',
  initialState: initialState(),
  reducers: {
    update: (state, { payload }: PayloadAction<DsPayload>) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

const dsDomain = (state: any) => state[name];
const selectDsPersona = createSelector(dsDomain, (ds) => ds.dsPersona);

export const dsReducerName = name;
export const dsActions = actions;
export const dsReducer = reducer;

export const dsSelectors = { dsDomain, selectDsPersona };
