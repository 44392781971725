import { JobItemResponse, JobListItemResponse } from 'api-endpoints/job/models';

import { pipe } from 'utils/funcs';
import { KeyMap, toStateKeys, TwoWayMap } from 'utils/key-mapper';

import { Job } from 'store/entities/jobs/models';

type JobItem = JobItemResponse | JobListItemResponse;

const jobItemMapper: KeyMap<JobItem, Job> = {
  jobId: 'id',
};

const mapToModelKeys = toStateKeys(new TwoWayMap(jobItemMapper));

const mapModelKeys = (job: JobItem): Job => {
  return Object.assign(job, mapToModelKeys(job));
};

export const jobToState = (job: JobItem): Job => pipe(job, mapModelKeys);
