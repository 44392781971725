import { Routes } from 'router';
import pick from 'lodash/pick';
import { all, call, put, putResolve, SagaReturnType, select } from 'redux-saga/effects';

import * as candidateApi from 'api-endpoints/candidate';
import { talentPoolCandidatesApi } from 'api-endpoints/talent-pool-candidates/talent-pool-candidates.api';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { candidateListActions } from 'containers/CandidateLists/store';
import { loadCandidates } from 'containers/CandidateLists/store/effects';
import { modalsActions } from 'containers/Modals/store';
import { talentPoolFormEffects } from 'containers/TalentPoolForms/state';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { getTranslate } from 'utils/i18utils';
import { generateComplexListId } from 'utils/list';
import { pluck } from 'utils/pluck';
import { invokeApiCall } from 'utils/sagas';

import * as fromCandidateActions from 'store/entities/candidates/candidate.actions';
import { candidatesApi } from 'store/entities/candidates/candidates.api';
import { Candidate, CandidateBelongsTo } from 'store/entities/candidates/models';
import { candidatesSelectors } from 'store/entities/candidates/selectors';
import { exModalCancelAction, exModalHideAction, updateWizardPage } from 'store/modals/modals.actions';
import { modalById } from 'store/modals/modals.selectors';
import { routerSelectors } from 'store/router';

export function getActionsDependsOnCurrentRoute(path: string, candidateId: string, currentTalentPoolId: string) {
  const candidateListId = generateComplexListId(CandidateBelongsTo.talentPool, currentTalentPoolId);

  const actions = {
    newApplicant: [
      putResolve(
        candidateListActions.setItemSelect({
          id: CandidateBelongsTo.newApplicant,
          selectedItemId: candidateId,
        }),
      ),
      putResolve(candidatesApi.endpoints.candidate.initiate(candidateId) as any),
    ],
    talentPool: [putResolve(loadCandidates({ listId: candidateListId }) as any)],
  };

  if (path.split('/').includes(Routes.applicantStepSelectCandidate)) {
    return actions.newApplicant;
  }

  if (currentTalentPoolId) {
    return actions.talentPool;
  }

  return [];
}

export function* utilsPrepareUpdateCandidateData<T extends Candidate>(
  candidateId: Candidate['candidateId'],
  updateData: Partial<T>,
) {
  const candidate: SagaReturnType<typeof candidatesSelectors.getById> = yield select(
    candidatesSelectors.getById,
    candidateId,
  );

  return {
    data: pick({ ...candidate, ...updateData, candidateId }, [
      'candidateId',
      'firstName',
      'lastName',
      'email',
      'phone',
      'mobile',
      'address',
      //location?
      'socialNetworks',
      'country',
      'candidateAvailability',
      'keywords',
      'doNotHire',
      'candidateLocation',
    ]),
    oldData: candidate,
  };
}

export function* candidateFormCreateStep({ raceResult, modalId, currentTalentPoolId }) {
  if (raceResult.back) {
    yield put(exModalHideAction({ id: modalId }));
    return { continue: false };
  }
  if (raceResult.newTalentPool) {
    yield put(
      updateWizardPage({
        id: modalId,
        page: 'newTalentPoolForm',
        modalConfig: { content: { title: 'Create New Talent Pool', withTitle: true } },
      }),
    );
    return { continue: true };
  }

  if (raceResult.createCandidateFormData) {
    const createCandidateFormData: ReturnType<typeof fromCandidateActions.candidateCreate> =
      raceResult.createCandidateFormData;

    yield call(startLoader, { type: 'createCandidateFormData' });

    const postDate = {
      email: createCandidateFormData.payload.email,
      firstName: createCandidateFormData.payload.firstName,
      lastName: createCandidateFormData.payload.lastName,
    };

    const {
      errorData,
      message,
      data: storedData,
    } = yield invokeApiCall(candidateApi.saveCandidate, {
      data: postDate,
    });

    if (message || errorData || storedData?.validationErrorCodes?.length) {
      yield call(stopLoader, { type: 'createCandidateFormData' });

      return { continue: true };
    }

    const talentPoolIds = createCandidateFormData.payload?.talentPools?.length
      ? createCandidateFormData.payload.talentPools.map(pluck('id'))
      : [];
    if (talentPoolIds.length && 'candidateId' in storedData) {
      yield putResolve(
        talentPoolFormEffects.addCandidateToTalentPools({
          candidateId: storedData?.candidateId!,
          talentPoolIds,
          preloader: true,
        }) as any,
      );
    }

    const invalidateTalentPools = talentPoolIds.map((id) => ({ type: 'TalentPool' as const, id }));

    yield put(
      talentPoolCandidatesApi.util.invalidateTags([
        { type: 'Candidates', id: 'LIST' },
        { type: 'TalentPoolCandidates', id: 'LIST' },
        ...invalidateTalentPools,
      ]),
    );

    const path: ReturnType<typeof routerSelectors.selectLocation> = yield select(routerSelectors.selectLocation);

    const nextActions = getActionsDependsOnCurrentRoute(path.pathname, storedData.candidateId, currentTalentPoolId);

    yield all([
      ...nextActions,
      put(alertsEffects.showSuccess({ message: getTranslate('candidate.create.success') })),
      put(exModalCancelAction({ id: modalId })),
    ]);
    return { continue: false };
  }
  return { continue: false };
}

export function* newTalentPoolFormCreateStep({ raceResult, modalId, title }) {
  if (raceResult.back) {
    yield put(
      updateWizardPage({
        id: modalId,
        page: 'candidateForm',
        modalConfig: { content: { title, withTitle: true } },
      }),
    );
  }
  if (raceResult.newTalentPoolSuccessAction) {
    const newTalentPoolSuccessAction: ReturnType<typeof modalsActions.saveResult> =
      raceResult.newTalentPoolSuccessAction;
    const modalData: ReturnType<typeof modalById> = yield select(modalById, modalId);
    const talentPools = [
      ...(modalData?.modalProps?.formData.talentPools ?? []),
      newTalentPoolSuccessAction.payload.payload.item,
    ];

    yield put(
      updateWizardPage({
        id: modalId,
        page: 'candidateForm',
        modalConfig: { content: { title, withTitle: true } },
        modalProps: {
          formData: {
            ...modalData?.modalProps?.formData,
            talentPools,
          },
        },
      }),
    );
  }
}
