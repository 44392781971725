import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { IconStar } from 'components/Icons/IconStar';

type ExRatingStarsProps = {
  className?: string;
  rating: number;
  onChange?: (rating: number) => void;
  readonly?: boolean;
  children?: never;
};

const ExRatingStarsStyled = styled.div`
  display: flex;
  align-items: center;
`;

const useExRatingStarsState = ({ className, rating = 0, onChange, readonly }: ExRatingStarsProps) => {
  const [state, setState] = useState({ rating, removeRating: false });

  const ratingArray = useMemo(() => Array.from({ length: 5 }, (v, k) => k < state.rating), [state.rating]);
  const changeFunc = onChange instanceof Function ? onChange : () => undefined;
  const onStarClick =
    (index: number): React.MouseEventHandler =>
    () => {
      if (readonly) {
        return;
      }
      changeFunc(state.removeRating ? 0 : index);
    };

  const onRatingOver = useCallback(
    (number: number) => () =>
      setState((prev) => {
        return { ...prev, rating: number, removeRating: number === rating };
      }),
    [rating],
  );

  const onMouseLeave = () => setState((prev) => ({ ...prev, rating, removeRating: false }));

  const computedRatingOver = readonly ? () => undefined : onRatingOver;

  useEffect(() => {
    setState((prev) => ({ ...prev, rating }));
  }, [rating]);

  return {
    className,
    ratingArray,
    removeRating: state.removeRating,
    onStarClick,
    onRatingOver: computedRatingOver,
    onMouseLeave,
    readonly,
  } as const;
};

export const ExRatingStars: React.FC<ExRatingStarsProps> = (props) => {
  const { className, onStarClick, ratingArray, onRatingOver, onMouseLeave, readonly, removeRating } =
    useExRatingStarsState(props);

  return (
    <ExRatingStarsStyled className={className} onMouseLeave={onMouseLeave}>
      {ratingArray.map((rating, index) => (
        <IconStar
          $readonly={readonly}
          $isRemoveRating={removeRating}
          key={index}
          $filled={rating}
          onClick={onStarClick(index + 1)}
          onMouseOver={onRatingOver(index + 1)}
          onMouseEnter={onRatingOver(index + 1)}
        />
      ))}
    </ExRatingStarsStyled>
  );
};
