import { PublicJob } from 'pages/public/state/public-jobs';

export const fetcher = (url: string, config: any) => {
  return new Promise((resolve, reject) => {
    fetch(url, config).then((response) => {
      if (response.ok) {
        if (response.status === 204) {
          resolve({});
          return;
        }

        response.json().then((res) => {
          resolve(res);
        });
        return;
      }

      response.json().then((err) => {
        reject(err);
      });
    });
  });
};

export const getPublicJobLocation = (job: PublicJob) => {
  const { locationFormatted } = job;

  return locationFormatted;
};
