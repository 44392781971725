import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UIKeys } from 'store/constants';
import { initialState } from 'store/initialState';

const documentMetaSlice = createSlice({
  name: UIKeys.documentMeta,
  initialState: initialState.ui.documentMeta,
  reducers: {
    setDocumentTitle(state, { payload }: PayloadAction<{ title: string }>) {
      state.documentTitle = payload.title;
    },
  },
});

export const documentMetaActions = documentMetaSlice.actions;

export const documentMetaReducer = documentMetaSlice.reducer;
