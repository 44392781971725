import React from 'react';
import styled from 'styled-components';

import { JobAdDescriptionFormBody, JobAdGeneralFormBody } from 'containers/JobAdForms/FormBody';
import { useNewJobAdsContextFormUpdater } from 'containers/Modals/ModalsContent/JobAds/NewJobAds/NewJobAds.context';

import { StepComponent, StepDescription } from 'components/StepForm';
import { ExCard } from 'components/ui/ExCard';
import { getTranslate } from 'utils/i18utils';

export interface NewJobAdJobAdDetailsStepProps extends StepComponent {
  className?: string;
}

const NewJobAdJobAdDetailsStepPure: React.FC<NewJobAdJobAdDetailsStepProps> = ({
  className,
  onPrevStep,
  isStep,
  ...rest
}) => {
  useNewJobAdsContextFormUpdater();

  return (
    <div className={className}>
      <StepDescription>
        <StepDescription.Title>Step 3: Required Details</StepDescription.Title>
        <StepDescription.Description>{getTranslate('new_job_ad.step_3.description')}</StepDescription.Description>
      </StepDescription>
      <ExCard title="Job Ad Details" className="mb-3">
        <JobAdGeneralFormBody isStep {...rest} />
      </ExCard>
      <ExCard title="Description" className="mb-3">
        <JobAdDescriptionFormBody {...rest} />
      </ExCard>
    </div>
  );
};

export const NewJobAdJobAdDetailsStep = styled(NewJobAdJobAdDetailsStepPure)``;
