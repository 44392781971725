import { useCallback } from 'react';
import { push } from 'connected-react-router';
import { ExRoutes } from 'router/routes';

import { ExInterviewTemplate, ExInterviewTemplateListItem } from 'model';
import { InterviewTemplateState } from 'model/api-enums.constants';

import { useInterviewTemplate } from 'utils/hooks/InterviewTemplate/useInterviewTemplate';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useModal } from 'utils/hooks/useModal';
import { getTranslate } from 'utils/i18utils';

import { useInterviewTemplateUpdateMutation } from 'store/entities/interview-template/interview-template.api';

type useInterviewTemplateChangeStatusParams = {
  interviewTemplateId?: ExInterviewTemplate['interviewTemplateId'];
};

export function isInterviewTemplateInDraftStatus<T extends ExInterviewTemplate | ExInterviewTemplateListItem>(
  template: T,
) {
  return template.state === InterviewTemplateState.Draft;
}

export function useEditInterviewTemplate({ interviewTemplateId }: useInterviewTemplateChangeStatusParams) {
  const dispatch = useAppDispatch();

  const { show } = useModal({});

  const { data: interviewTemplate } = useInterviewTemplate({ interviewTemplateId });

  const [updateInterviewTemplate, updateMeta] = useInterviewTemplateUpdateMutation();

  const changeInterviewTemplateStatusInterviewWithConfirmModal = useCallback(async () => {
    if (!interviewTemplate) {
      return;
    }

    const isTrueInterviewTemplateInDraftStatus = isInterviewTemplateInDraftStatus(interviewTemplate);

    let message = '';
    const action = interviewTemplate?.isUsed
      ? getTranslate('interviewTemplate.removeMessage.actionRePublish')
      : getTranslate('interviewTemplate.removeMessage.actionPublish');

    if (interviewTemplate.isUsed && !isTrueInterviewTemplateInDraftStatus) {
      message += getTranslate('interviewTemplate.removeMessage.inUse');
    }
    if (!isTrueInterviewTemplateInDraftStatus) {
      message += ` ${getTranslate('interviewTemplate.removeMessage.statusToDraft')}\n `;
    }

    message += ` ${getTranslate('interviewTemplate.removeMessage.needPublish', { replace: { action } })}`;

    const result = await show({
      title: `Edit Interview Template`,
      withTitle: true,
      message,
      buttonOkVariant: 'primary',
      buttonOk: isTrueInterviewTemplateInDraftStatus ? 'Edit' : 'Edit Anyway',
      withActions: true,
    });

    if (result) {
      if (!isTrueInterviewTemplateInDraftStatus) {
        await updateInterviewTemplate({
          name: interviewTemplate.name,
          interviewTemplateId: interviewTemplate.interviewTemplateId,
          state: InterviewTemplateState.Draft,
        });
      }

      dispatch(push(ExRoutes.interviewTemplateEdit({ interviewTemplateId: interviewTemplate.interviewTemplateId })));
    }
  }, [dispatch, interviewTemplate, show, updateInterviewTemplate]);

  return [changeInterviewTemplateStatusInterviewWithConfirmModal, updateMeta] as const;
}
