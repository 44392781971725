import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { useCompanyGetPositionDetailsQuery } from 'store/dictionary/dictionary.api';

export function useFormDescription() {
  const {
    field: { value: description, ref: inputRef, onChange: handlerChangeDescription },
  } = useController({ name: 'description' });

  return {
    description,
    handlerChangeDescription,
    inputRef,
  };
}

export function useJobFormDescription({ formData }: any) {
  const { setValue, watch } = useFormContext();

  const positionId = watch('position.positionId', '');

  const { data } = useCompanyGetPositionDetailsQuery(
    { positionId },
    {
      refetchOnMountOrArgChange: false,
      skip: !positionId,
    },
  );

  const descriptionByPosition = data?.description;

  const {
    field: { value: description, ref: inputRef, onChange: handlerChangeDescription },
  } = useController({ name: 'description' });

  useEffect(() => {
    if (!formData?.description && descriptionByPosition) {
      setValue('description', descriptionByPosition);
    }
  }, [descriptionByPosition, formData?.description, setValue]);

  return {
    description,
    handlerChangeDescription,
    inputRef,
  };
}
