import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';

import { list } from 'config';

import * as fromJobApi from 'api-endpoints/job';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { invokeApiCall, ReturnData } from 'utils/sagas';

import {
  createDirectCommentActionLoader,
  createTemporaryComment,
  makeCommentRead,
  removeCommentConfirmModal,
} from 'store/entities/applicant-comments/applicant-comments.utils';

import { jobCommentsActions } from './job-comments.actions';
import { jobCommentsSelectors } from './job-comments.selectors';

function* fetchWorker(pageNo: number, jobId: string) {
  const { data, errorData, message }: ReturnData<typeof fromJobApi.searchCommentsForJobFunc> = yield invokeApiCall(
    fromJobApi.searchCommentsForJobFunc,
    {
      params: {
        pageNo,
        pageSize: list.pageSize,
      },
      urlParams: {
        jobId,
      },
    },
  );

  if ([errorData, message].some(Boolean)) {
    return;
  }

  if (data) {
    yield all([
      put(jobCommentsActions.upsertMany(data.items)),
      put(jobCommentsActions.setPageCount({ pageCount: data?.pageCount })),
    ]);
  }

  return data;
}

function* jobCommentGetWorker(action: ReturnType<typeof jobCommentsActions.jobCommentGet>) {
  const directCommentActionLoader = createDirectCommentActionLoader(action);

  const loaderActions = [action, directCommentActionLoader];
  yield startLoader(loaderActions);

  const { jobId, commentId } = action.payload;

  const { data, errorData, message }: ReturnData<typeof fromJobApi.getCommentForJobFunc> = yield call(
    fromJobApi.getCommentForJobFunc,
    {
      urlParams: { jobCommentId: commentId, jobId },
    },
  );

  if ([errorData, message].some(Boolean)) {
    yield stopLoader(loaderActions);
    return;
  }

  if (data) {
    yield put(jobCommentsActions.upsertOne(data));
  }

  yield stopLoader(loaderActions);
}

function* jobCommentsFetchWorker(action: ReturnType<typeof jobCommentsActions.jobCommentsFetch>) {
  yield startLoader(action);

  const { jobId } = action.payload;

  yield put(jobCommentsActions.setCurrentJobId({ jobId }));

  const pageForLoad = 0;

  const data = yield call(fetchWorker, pageForLoad, jobId);

  yield put(jobCommentsActions.updatePage({ lastLoadedPage: pageForLoad, pageCount: data?.pageCount }));

  yield stopLoader(action);
}

function* jobCommentsFetchMoreWorker(action: ReturnType<typeof jobCommentsActions.jobCommentsFetchMore>) {
  const { jobId, lastLoadedPage, pageCount }: ReturnType<typeof jobCommentsSelectors.selectCurrent> = yield select(
    jobCommentsSelectors.selectCurrent,
  );

  if (!jobId) {
    return;
  }

  yield startLoader(action);

  yield put(jobCommentsActions.setCurrentJobId({ jobId }));

  const pageForLoad = lastLoadedPage === pageCount - 1 ? lastLoadedPage : lastLoadedPage + 1;

  const data = yield call(fetchWorker, pageForLoad, jobId);

  yield put(jobCommentsActions.updatePage({ lastLoadedPage: pageForLoad, pageCount: data?.pageCount }));

  yield stopLoader(action);
}

function* jobCommentsCreateWorker(action: ReturnType<typeof jobCommentsActions.jobCommentsCreate>) {
  const { jobId }: ReturnType<typeof jobCommentsSelectors.selectCurrent> = yield select(
    jobCommentsSelectors.selectCurrent,
  );

  if (!jobId) {
    return;
  }

  const data = {
    ...action.payload,
    jobId,
  };

  const { temporaryComment, temporaryCommentCommentId } = yield createTemporaryComment(data);

  yield put(jobCommentsActions.addOne(temporaryComment));

  const directCommentActionLoader = createDirectCommentActionLoader({
    payload: {
      commentId: temporaryCommentCommentId,
    },
  });
  const loaderActions = [action, directCommentActionLoader];
  yield startLoader(loaderActions);

  const {
    data: savedData,
    errorData,
    message,
  }: ReturnData<typeof fromJobApi.addCommentForJobFunc> = yield invokeApiCall(fromJobApi.addCommentForJobFunc, {
    data,
    urlParams: {
      jobId,
    },
  });

  yield stopLoader(loaderActions);

  if ([errorData, message].some(Boolean)) {
    return undefined;
  }

  if (savedData) {
    yield put(
      jobCommentsActions.addOneAndReplace({
        ...temporaryComment,
        commentId: savedData.jobCommentId,
        fakeId: temporaryCommentCommentId,
      }),
    );
  }
}

function* jobCommentsUpdateWorker(action: ReturnType<typeof jobCommentsActions.jobCommentsUpdate>) {
  const { jobId }: ReturnType<typeof jobCommentsSelectors.selectCurrent> = yield select(
    jobCommentsSelectors.selectCurrent,
  );

  const directCommentActionLoader = createDirectCommentActionLoader(action);
  const loaderActions = [action, directCommentActionLoader];
  yield startLoader(loaderActions);

  const { commentId } = action.payload;

  if (!jobId) {
    return;
  }

  const data = { ...action.payload, jobCommentId: commentId, jobId };

  const { errorData, message }: ReturnData<typeof fromJobApi.updateCommentForJobFunc> = yield invokeApiCall(
    fromJobApi.updateCommentForJobFunc,
    {
      data,
      urlParams: {
        jobCommentId: commentId,
        jobId,
      },
    },
  );

  if ([errorData, message].some(Boolean)) {
    yield stopLoader(loaderActions);
    return undefined;
  }

  yield call(jobCommentGetWorker, jobCommentsActions.jobCommentGet({ commentId, jobId }));

  yield stopLoader(loaderActions);
}

function* jobCommentsRemoveWorker(action: ReturnType<typeof jobCommentsActions.jobCommentsRemove>) {
  const { jobId }: ReturnType<typeof jobCommentsSelectors.selectCurrent> = yield select(
    jobCommentsSelectors.selectCurrent,
  );

  if (!jobId) {
    return;
  }
  const directCommentActionLoader = createDirectCommentActionLoader(action);
  const loaderActions = [action, directCommentActionLoader];

  const { commentId } = action.payload;

  /**
   * Waiting confirmation from user for remove comment
   */
  const confirmed: boolean = yield call(removeCommentConfirmModal);
  if (!confirmed) {
    return;
  }
  yield startLoader(loaderActions);

  const { errorData, message }: ReturnData<typeof fromJobApi.deleteCommentForJobFunc> = yield invokeApiCall(
    fromJobApi.deleteCommentForJobFunc,
    {
      urlParams: {
        jobCommentId: commentId,
        jobId,
      },
    },
  );
  yield stopLoader(loaderActions);

  if ([errorData, message].some(Boolean)) {
    return undefined;
  }

  yield put(jobCommentsActions.removeOne(commentId));
}

function* jobCommentsReadWorker(action: ReturnType<typeof jobCommentsActions.jobCommentsRead>) {
  const { jobId }: ReturnType<typeof jobCommentsSelectors.selectCurrent> = yield select(
    jobCommentsSelectors.selectCurrent,
  );

  if (!jobId) {
    return;
  }

  yield startLoader(action);

  const data = { ...action.payload, jobId };

  const { errorData, message }: ReturnData<typeof fromJobApi.readCommentsForJobFunc> = yield invokeApiCall(
    fromJobApi.readCommentsForJobFunc,
    {
      data,
      urlParams: {
        jobId,
      },
    },
  );
  yield stopLoader(action);

  if ([errorData, message].some(Boolean)) {
    return undefined;
  }

  const updatedComments = data.comments.map(makeCommentRead);

  yield put(jobCommentsActions.updateMany(updatedComments));

  return updatedComments;
}

function* jobCommentsReadAllWorker(action: ReturnType<typeof jobCommentsActions.jobCommentsReadAll>) {
  const selectCurrent: ReturnType<typeof jobCommentsSelectors.selectCurrent> = yield select(
    jobCommentsSelectors.selectCurrent,
  );

  const jobId = selectCurrent.jobId ?? action.payload.jobId;

  if (!jobId) {
    return;
  }

  yield startLoader(action);

  const data = { jobId };

  const { errorData, message }: ReturnData<typeof fromJobApi.readAllCommentForJobFunc> = yield invokeApiCall(
    fromJobApi.readAllCommentForJobFunc,
    {
      data,
      urlParams: {
        jobId,
      },
    },
  );
  yield stopLoader(action);

  if ([errorData, message].some(Boolean)) {
    return undefined;
  }

  const ids: ReturnType<typeof jobCommentsSelectors.selectIds> = yield select(jobCommentsSelectors.selectIds);

  const updatedComments = ids.map(makeCommentRead);

  yield put(jobCommentsActions.updateMany(updatedComments));

  return updatedComments;
}

export function* jobCommentsWatcher() {
  yield takeEvery(jobCommentsActions.jobCommentGet, jobCommentGetWorker);
  yield takeLatest(jobCommentsActions.jobCommentsFetch, jobCommentsFetchWorker);
  yield takeLeading(jobCommentsActions.jobCommentsFetchMore, jobCommentsFetchMoreWorker);
  yield takeEvery(jobCommentsActions.jobCommentsCreate, jobCommentsCreateWorker);
  yield takeEvery(jobCommentsActions.jobCommentsUpdate, jobCommentsUpdateWorker);
  yield takeEvery(jobCommentsActions.jobCommentsRemove, jobCommentsRemoveWorker);
  yield takeEvery(jobCommentsActions.jobCommentsRead, jobCommentsReadWorker);
  yield takeLatest(jobCommentsActions.jobCommentsReadAll, jobCommentsReadAllWorker);
  yield fork(pollTaskWatcher);
}

/* Worker Function */
function* pollTask() {
  yield delay(30000);
  while (true) {
    try {
      const { jobId }: ReturnType<typeof jobCommentsSelectors.selectCurrent> = yield select(
        jobCommentsSelectors.selectCurrent,
      );

      if (!jobId) {
        yield put(jobCommentsActions.jobCommentsSopWatcherTask());
        return;
      }
      // Fetching posts at regular interval 4 seconds.
      yield call(fetchWorker, 0, jobId);

      yield race([delay(60000), take(jobCommentsActions.addOneAndReplace)]);
    } catch (err) {
      // Once the polling has encountered an error,
      // it should be stopped immediately
      yield put(jobCommentsActions.jobCommentsSopWatcherTask());
    }
  }
}
/* Watcher Function */
function* pollTaskWatcher() {
  while (true) {
    yield take(jobCommentsActions.jobCommentsStartWatcherTask);
    yield race([call(pollTask), take(jobCommentsActions.jobCommentsSopWatcherTask)]);
  }
}
