import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styled, { css, DefaultTheme } from 'styled-components/macro';

import { ConditionalWrapper } from 'components/ConditionalWrapper';

type ExSwitchButtonProps = {
  value: boolean;
  variant?: keyof DefaultTheme['colors'];
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  hint?: string | null;
};

const exSwitchButtonWidth = 40;
const exSwitchButtonHeight = 24;

type ExSwitchButtonBodyProps = Pick<ExSwitchButtonProps, 'disabled'>;

const exSwitchButtonBodyDisabled = ({ disabled }: ExSwitchButtonBodyProps) =>
  disabled &&
  css`
    opacity: 0.65;
  `;

const ExSwitchButtonBody = styled.div<ExSwitchButtonBodyProps>`
  display: inline-flex;
  flex-shrink: 0;
  width: ${exSwitchButtonWidth}px;
  height: ${exSwitchButtonHeight}px;
  border-radius: 100px;
  background-color: ${(props) => props.theme.colors.light};
  overflow: hidden;
  position: relative;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${exSwitchButtonBodyDisabled};
`;

const ExSwitchButtonSwitch = styled.div<{
  variant: ExSwitchButtonProps['variant'];
  isActive: boolean;
}>`
  background-color: ${(props) => (props.isActive ? props.theme.colors[props.variant!] : 'transparent')};
  position: absolute;
  border-radius: 100px;
  width: ${exSwitchButtonWidth}px;
  height: ${exSwitchButtonHeight}px;
  transition: background-color 0.3s ease-out;
`;

type ExSwitchButtonDotProps = {
  isActive: boolean;
};

const getExSwitchButtonDotActive = ({ isActive }: ExSwitchButtonDotProps) => css`
  ${isActive ? 'right' : 'left'}: ${exSwitchButtonHeight / 6}px;
`;

const ExSwitchButtonDot = styled.div<ExSwitchButtonDotProps>`
  position: absolute;
  width: ${(exSwitchButtonHeight * 2) / 3}px;
  height: ${(exSwitchButtonHeight * 2) / 3}px;
  ${getExSwitchButtonDotActive};
  top: 50%;
  border-radius: 100px;
  background-color: ${(props) => props.theme.colors.white};
  transform: translateY(-50%);
  transition: right 0.3s ease-out;
`;

export const ExSwitchButton = ({
  value,
  onChange = () => {
    // do nothing
  },
  variant = 'success',
  disabled,
  hint,
}: ExSwitchButtonProps) => {
  const toggleActive = () => {
    if (disabled) {
      return;
    }
    const newValue = !value;
    onChange(newValue);
  };

  const hasHint = Boolean(hint);

  return (
    <ConditionalWrapper
      condition={hasHint}
      wrapper={(children) => (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip className="expedo-tooltip" id="tooltip">
              {hint}
            </Tooltip>
          }
        >
          {children}
        </OverlayTrigger>
      )}
    >
      <ExSwitchButtonBody onClick={toggleActive} disabled={disabled}>
        <ExSwitchButtonSwitch variant={variant} isActive={value}>
          <ExSwitchButtonDot isActive={value} />
        </ExSwitchButtonSwitch>
      </ExSwitchButtonBody>
    </ConditionalWrapper>
  );
};
