import { createSelector } from '@reduxjs/toolkit';

import { UIKeys } from 'store/constants';
import { uiDomain } from 'store/rootSelectors';

export const domain = createSelector(uiDomain, (state) => state[UIKeys.alertManager]);

export const getAll = createSelector(domain, (state) => state);

export const hasBlocking = createSelector(domain, (state) => state.some((alert) => alert.blocking));

export const alertsSelectors = {
  domain,
  getAll,
  hasBlocking,
};
