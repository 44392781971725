import { useRouteMatch } from 'react-router-dom';

import { AppRouterParams } from 'model/router';

import { useAppSelector } from 'utils/hooks/useSelectors';

import { applicantSelectors } from 'store/entities/applicants';

export const useApplicantFromModal = () => {
  const match = useRouteMatch<AppRouterParams>();
  const { applicantId } = match.params;
  return useAppSelector(applicantSelectors.selectApplicantModalData, applicantId);
};
