import React from 'react';
import styled from 'styled-components/macro';

import { ExEducation } from 'model';

import { Ellipsis } from 'components/Ellipsis';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';
import { getThemeColor } from 'utils/styled/getThemeColor';

import { candidateEducationSelectors } from 'store/entities/candidate-education/candidate-education.selectors';

import { EducationCardItemForApplicantModalV4Props } from './EducationCardItemProps';
import { useEducationDateRange } from './hooks';

export const EducationCardItemForApplicantModalV4Styled = styled.div`
  background-color: ${getThemeColor('bodyBackgroundColor')};
  border-radius: ${getThemeBorderProps('borderRadius')};
  padding: 20px;
  line-height: 1.6;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const EllipsisTitle = styled(Ellipsis)`
  flex-grow: 1;
`;

const Date = styled.div`
  flex-shrink: 0;
  margin-left: 10px;
`;

const EducationCardItemForApplicantModalV4State = ({
  className,
  educationId,
}: EducationCardItemForApplicantModalV4Props) => {
  const education = useAppSelector(candidateEducationSelectors.selectById, educationId) as ExEducation;

  const dateRange = useEducationDateRange(education);

  return {
    className,
    education,
    dateRange,
  } as const;
};

export const EducationCardItemForApplicantModalV4: React.FC<EducationCardItemForApplicantModalV4Props> = (props) => {
  const { className, education, dateRange } = EducationCardItemForApplicantModalV4State(props);

  return (
    <EducationCardItemForApplicantModalV4Styled className={className}>
      <TitleWrapper>
        <EllipsisTitle>
          <strong>{education.organization}</strong>
        </EllipsisTitle>
        <Date>{dateRange}</Date>
      </TitleWrapper>
      <Ellipsis>{education.name}</Ellipsis>
    </EducationCardItemForApplicantModalV4Styled>
  );
};
