import { Link } from 'react-router-dom';
import { useAppRouterParams } from 'router';
import { ExRoutes } from 'router/routes';

import { CompanyInterviewTemplatesTableTitleStyled } from 'pages/Company/CompanyTabs/CompanyInterviewTemplatesTab/CompanyInterviewTemplatesStyles';

import { InterviewTableCellComponent } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/Tabs/InterviewsTab/InterviewsTabProps';

import { EllipsisWithTooltip } from 'components/EllipsisWithTooltip';

export const InterviewTitle: InterviewTableCellComponent = ({ item }) => {
  const { jobId } = useAppRouterParams();

  const interviewName = item.name || '--';

  return (
    <EllipsisWithTooltip label={interviewName}>
      <CompanyInterviewTemplatesTableTitleStyled
        as={Link}
        to={ExRoutes.applicantInterview({
          jobId,
          applicantId: item.jobApplicantId,
          interviewId: item.interviewId,
        })}
        className="text-reset"
      >
        {interviewName}
      </CompanyInterviewTemplatesTableTitleStyled>
    </EllipsisWithTooltip>
  );
};
