import { combineReducers } from '@reduxjs/toolkit';

import { alertsReducer } from 'containers/AlertManager/store/reducers';
import applicantListReducer from 'containers/ApplicantLists/store';
import candidateListReducer from 'containers/CandidateLists/store';
import jobAdListReducer from 'containers/JobAdLists/store';
import jobBoardListReducer from 'containers/JobBoardLists/store';
import jobListReducer from 'containers/JobLists/store';
import loaderReducer from 'containers/Loader/store';
import { modalsReducer } from 'containers/Modals/store';
import talentPoolListReducer from 'containers/TalentPoolLists/store';

import { UIKeys } from 'store/constants';
import { applicantFiltersReducer } from 'store/ui/applicant-filters/applicantFilters.reducer';
import { applicantsUi } from 'store/ui/applicants';
import { candidatesUi } from 'store/ui/candidates';
import { companyUi } from 'store/ui/company';
import { documentMetaReducer } from 'store/ui/document-meta';
import { jobsUi } from 'store/ui/jobs';
import { talentPoolsUi } from 'store/ui/talent-pools';
import { tipsReducer } from 'store/ui/tips/tips.reducer';
import { widgetsReducers } from 'store/ui/widgets';

export const uiReducers = combineReducers({
  [UIKeys.jobLists]: jobListReducer,
  [UIKeys.documentMeta]: documentMetaReducer,
  [UIKeys.loader]: loaderReducer,
  [UIKeys.modals]: modalsReducer,
  [UIKeys.alertManager]: alertsReducer,
  [UIKeys.applicantLists]: applicantListReducer,
  [UIKeys.jobBoardLists]: jobBoardListReducer,
  [UIKeys.jobAdLists]: jobAdListReducer,
  [UIKeys.talentPoolLists]: talentPoolListReducer,
  [UIKeys.candidateLists]: candidateListReducer,
  [UIKeys.widgets]: widgetsReducers,
  [UIKeys.applicantFilters]: applicantFiltersReducer,
  [UIKeys.tips]: tipsReducer,
  companyUi,
  jobsUi,
  applicantsUi,
  talentPoolsUi,
  candidatesUi,
});
