import React, { useEffect, useState } from 'react';

import { jobAdFormEffects } from 'containers/JobAdForms/state';
import { descriptionValidationSchema, minimalJobDescriptionLength } from 'containers/JobForms/validators';
import { modalsSelectors } from 'containers/Modals/store';

import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { DialogContentComponent, DialogProps } from 'components/Dialog';
import { TextEditor } from 'components/Form/TextEditor';
import { ExDialogActionButton, ExDialogActions, ExDialogFooter } from 'components/ui/ExDialog';
import { loaderThunkActionWrapper } from 'modules/LoaderManager/redux';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { useYupValidationCallback } from 'utils/hooks/useYupValidationCallback';
import { getTranslate } from 'utils/i18utils';

import { jobAdSelectors } from 'store/entities/job-ads';

import { JobAdDescriptionModalStyled } from './JobAdDescriptionModalComponents';
import { JobAdDescriptionModalProps } from './JobAdDescriptionModalProps';

export const JobAdDescriptionModal: React.FC<JobAdDescriptionModalProps> = ({ className, onClose }) => {
  const [description, setDescription] = useState('');
  const dispatch = useAppDispatch();
  const jobAdId = useAppSelector(modalsSelectors.modalJobAdId);
  const jobAd = useAppSelector<typeof jobAdSelectors.getById>(jobAdSelectors.getById, jobAdId);

  const onChange = (newJobAdDescription: string) => {
    setDescription(newJobAdDescription);
  };

  const onSubmitValidation = useYupValidationCallback<string>(
    descriptionValidationSchema.label('Job Ad Description'),
    async () => {
      const data = {
        ...jobAd,
        description,
      };

      if (jobAdId) {
        await dispatch(
          jobAdFormEffects.updateJobAd({
            data,
            jobAdId,
            preloader: true,
          }),
        );
        onClose(true);
      }
    },
  );

  const submitHandler: React.MouseEventHandler = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    await onSubmitValidation(description);
  };

  useEffect(() => {
    if (jobAd?.description) {
      setDescription(jobAd.description);
    }
  }, [jobAd]);

  const content: DialogProps['content'] = {
    title: 'Edit Job Ad Description',
    withTitle: true,
  };

  return (
    <JobAdDescriptionModalStyled onClose={onClose} content={content} className={className}>
      <DialogContentComponent>
        <div className="mb-2">
          {getTranslate('text_must_be_at_least', {
            fieldName: 'Description',
            min: minimalJobDescriptionLength,
            count: minimalJobDescriptionLength,
          })}
        </div>
        <TextEditor onChange={onChange} value={description} className="w-100" />
        <ExDialogFooter>
          <ExDialogActions>
            <ExDialogActionButton variant="light" onClick={() => onClose(false)}>
              Cancel
            </ExDialogActionButton>
            <ExLoaderHandlerWrapper action={loaderThunkActionWrapper(jobAdFormEffects.updateJobAd)}>
              <ExDialogActionButton className="ml-3" variant="primary" onClick={submitHandler}>
                Update
              </ExDialogActionButton>
            </ExLoaderHandlerWrapper>
          </ExDialogActions>
        </ExDialogFooter>
      </DialogContentComponent>
    </JobAdDescriptionModalStyled>
  );
};
