import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { nanoid } from '@reduxjs/toolkit';

import { Job } from 'store/entities/jobs';

import Step from './Step';
import { StepNavigationButtons } from './StepFormButtons';
import { Step as StepProps, StepFormProps } from './StepFormProps';

const StepForm: React.FC<StepFormProps> = ({
  steps,
  saveForm,
  defaultValues,
  saveValidStep,
  onSaveAsDraft,
  saveAsDraftHandlerRef,
  finalPage,
  finalPageUrl,
  formOptions,
  children,
  onValidationError,
  validationContext,
}) => {
  const [currentStep, setCurrentStep] = useState<StepProps['path']>();

  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const onSubmit = (data: Partial<Job>, e: SyntheticEvent) => {
    saveForm(data);
    typeof saveValidStep === 'function' && currentStep && saveValidStep(currentStep);
    if (e) {
      handlerNextStep(true);
    }
  };

  const formId = useMemo(() => nanoid(), []);

  const findStepIdx = (path: string) => steps.findIndex((step) => `${match.url}/${step.path}` === path);

  const stepId = findStepIdx(`${match.url}/${currentStep}`);

  const handlerNextStep = (isForward?: boolean) => {
    let idx = findStepIdx(`${match.url}/${currentStep}`);

    if (isForward) {
      idx = idx === steps.length - 1 ? idx : idx + 1;
    } else {
      idx = idx === 0 ? idx : idx - 1;
    }

    const stepPath = steps[idx].path;

    setCurrentStep(stepPath);

    if (stepPath) {
      history.push({ pathname: stepPath });
    }
  };

  const handlePrevStep = () => handlerNextStep();

  useEffect(() => {
    const idx = findStepIdx(location.pathname);

    if (idx > -1) {
      setCurrentStep(steps[idx].path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const validationSchema = steps[stepId]?.schema;
  const isDraftValidation = steps[stepId]?.isDraftValidation;

  return (
    <Step
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      onSaveAsDraft={onSaveAsDraft}
      saveAsDraftHandlerRef={saveAsDraftHandlerRef}
      id={formId}
      formOptions={formOptions}
      isDraftValidation={isDraftValidation}
      onError={onValidationError}
    >
      {validationContext}
      <Switch>
        {steps.map((step) => (
          <Route
            key={step.path}
            path={`${match.path}/${step.path}`}
            render={() => (
              <>
                <step.component
                  name={step.label}
                  isStep
                  onPrevStep={handlePrevStep}
                  formData={defaultValues}
                  stepNumber={step.stepNumber}
                />
                <StepNavigationButtons
                  {...step.navButtonsProps}
                  onPrevStep={handlePrevStep}
                  isFirstStep={step.stepNumber === 1}
                  formId={formId}
                />
                {children}
              </>
            )}
          />
        ))}
        {finalPage && finalPageUrl && <Route path={`${match.url}/${finalPageUrl}`} component={finalPage} />}
        <Redirect from="*" to={`${match.url}/${steps[0].path}`} />
      </Switch>
    </Step>
  );
};

export default StepForm;
