import styled from 'styled-components';

import { ReactComponent as IconChevron } from 'assets/img/redesign/icons/chevron.svg';

export const WorkExperienceFormBodyStyled = styled.div``;

export const FormSubtitle = styled.div`
  font-style: normal;
  font-weight: bold;
  padding: 15px 10px;
  width: 100%;
`;

export const AccordionCollapseIcon = styled(IconChevron)<{ active: boolean }>`
  fill: none;
  transform: ${(props) => (props.active ? 'rotate(0)' : 'rotate(180deg)')};
  transition: transform 0.2s ease-out;
`;
