import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';

import { jobAdFormSelectors } from 'containers/JobAdForms/state';

import { ReactComponent as ArrowAccordion } from 'assets/img/ArrowAccordion.svg';

import { FormCard } from 'components/FormCard';
import { SpinnerSM } from 'components/Spinner';
import { ExCard } from 'components/ui/ExCard';
import { useLoaderSubscription } from 'modules/LoaderManager/react';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { usePrevious } from 'utils/hooks/usePrevious';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';

import { JobAd } from 'store/entities/job-ads';
import { JobBoard, jobBoardActions } from 'store/entities/job-boards';

import JobAdAdditionalRequirementsFormBuilder from './JobAdAdditionalRequirementsFormBuilder';

const StyledArrowAccordion = styled(ArrowAccordion)`
  --size: 12px;

  width: var(--size);
  margin-right: 0.5rem;
  transition: transform 0.3s ease;

  &.active {
    transform: rotate(90deg);
  }
`;

export interface JobAdAdditionalRequirementsFormBodyProps {
  className?: string;
}

const JobAdAdditionalRequirementsFormBody: React.FC<JobAdAdditionalRequirementsFormBodyProps> = ({ className }) => {
  const { errors, getValues } = useFormContext<JobAd>();
  const dispatch = useAppDispatch();

  const jobBoardIds = useAppSelector(jobAdFormSelectors.selectFormDataJobBoardsIds);
  const selectedJobBoards = useAppSelector(jobAdFormSelectors.selectSelectedJobBoards);
  const { isLoading } = useLoaderSubscription(jobBoardActions.getAdditionalFields);

  const values = getValues();
  const location = values?.jobAdLocation?.placeName;
  const country = values?.jobAdCountry?.name;

  const previousJobBoardIds = usePrevious(jobBoardIds);

  const isSameJobBoardIds = isEqual(jobBoardIds, previousJobBoardIds);

  useEffect(() => {
    if (isSameJobBoardIds) {
      return;
    }

    if (jobBoardIds?.length) {
      jobBoardIds.forEach((jobBoardId) => {
        dispatch(jobBoardActions.getAdditionalFields({ jobBoardId, options: { location, country } }));
      });
    }
  }, [dispatch, jobBoardIds, location, country, isSameJobBoardIds]);

  const [active, setActive] = useState('');
  const changeActiveHandler = (jobBoardId: JobBoard['jobBoardId']) => {
    setActive((prev) => (prev === jobBoardId ? '' : jobBoardId));
  };

  useEffect(() => {
    const firstJobBoardId = selectedJobBoards?.[0]?.jobBoardId;
    if (firstJobBoardId && selectedJobBoards?.length === jobBoardIds?.length) {
      setActive(firstJobBoardId);
    }
  }, [jobBoardIds, selectedJobBoards]);

  const isInViewport = (elem: HTMLElement | null) => {
    if (!elem) {
      return false;
    }
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  useEffect(() => {
    const boardWithError = Object.keys(errors?.jobBoards ?? {})[0];
    if (boardWithError) {
      const errorFields = (errors.jobBoards as any)?.[boardWithError];
      const firsErrorFieldId = Object.keys(errorFields)[0];
      const firsErrorField = errorFields[firsErrorFieldId];
      const element = document.getElementById(firsErrorField?.value?.ref?.id || firsErrorField?.children?.ref?.id);
      requestAnimationFrame(() => {
        setTimeout(() => {
          if (!isInViewport(element)) {
            element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }, 300);
        element?.focus({ preventScroll: true });
      });
      setActive(boardWithError);
    }
  }, [errors]);

  return (
    <div className={className}>
      <Accordion defaultActiveKey={jobBoardIds?.[0] ?? ''} activeKey={active} className="mb-3">
        {selectedJobBoards?.map((jobBoard) => (
          <Card key={jobBoard.jobBoardId} className="board-fields">
            <Accordion.Toggle
              as={FormCard.Title}
              eventKey={jobBoard.jobBoardId}
              onClick={() => changeActiveHandler(jobBoard.jobBoardId)}
            >
              <StyledArrowAccordion className={active === jobBoard.jobBoardId ? 'active' : ''} />{' '}
              {jobBoard.name ? jobBoard.name + ' additional requirements' : <SpinnerSM />}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={jobBoard.jobBoardId}>
              <ExCard>
                {!isLoading && !!jobBoard.additionalFields?.length ? (
                  <JobAdAdditionalRequirementsFormBuilder jobBoard={jobBoard} />
                ) : (
                  <SpinnerSM />
                )}
              </ExCard>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </div>
  );
};

export default styled(JobAdAdditionalRequirementsFormBody)`
  ${FormCard.Title} {
    cursor: pointer;
    border: none;
  }

  .board-fields {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
    border: none;
  }

  .board-fields.card {
    margin-bottom: 1rem;
    border-radius: 8px;
    border-bottom-width: 1px solid ${getThemeBorderProps('borderColor')};
  }
`;
