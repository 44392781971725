import React from 'react';

import { Industry } from 'model';

import { ExButton } from 'components/ui/ExButton';
import { ExMultiselectFormBody } from 'components/ui/ExMultiselectFormBody';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { useIndustryQuerySelectors } from 'store/dictionary/hooks/useIndustryQuerySelectors';

type CompanyIndustriesFormBodyProps = {
  className?: string;
  onChange: (entityList: Array<Industry>) => void;
  defaultValue: Array<Industry>;
  toggleSelectAll: () => void;
  allSelected: boolean;
};

export const CompanyIndustriesFormBody = ({
  className,
  onChange,
  defaultValue,
  toggleSelectAll,
  allSelected,
}: CompanyIndustriesFormBodyProps) => {
  const { selectors: industriesSelectors } = useIndustryQuerySelectors();
  const industries = useAppSelector(industriesSelectors.selectAll);

  const selectAllButtonText = (allSelected ? 'Deselect' : 'Select') + ' All';

  return (
    <ExMultiselectFormBody
      className={className}
      defaultValue={defaultValue}
      getOptionLabelCallback={(option: Industry) => option.name}
      getOptionValueCallback={(option: Industry) => option.industryId}
      loadEntitiesHandler={() => Promise.resolve(industries)}
      onChangeHandler={onChange}
      title="Search Industry"
      backspaceRemovesValue={false}
      headerActions={
        <ExButton variant="link" onClick={toggleSelectAll}>
          {selectAllButtonText}
        </ExButton>
      }
    />
  );
};
