import { JobAd } from 'store/entities/job-ads/models';
import { JobBoard } from 'store/entities/job-boards/models';

export const getFieldsForJobAdFormData = (entity): Partial<JobAd> => {
  const data = {
    country: entity?.jobAdCountry?.code.expedoCode2 || entity?.jobLocation?.countryCode || undefined,
    description: entity?.descriptionRendered || entity?.description || undefined,
    employmentType: entity?.employmentType || undefined,
    jobAdCountry: entity?.jobAdCountry || undefined,
    jobAdLocation: entity?.jobAdLocation || entity?.jobLocation || undefined,
    jobAdName: entity?.name || entity?.jobAdName || entity?.jobName || undefined,
    jobBoardId: entity?.jobBoardId || undefined,
    jobBoardIds: entity?.jobBoardId ? [entity?.jobBoardId] : [],
    jobBoards: {},
    jobId: entity?.jobId || undefined,
    requirements: entity?.requirements || [],
    responsibilities: entity?.responsibilities || [],
    sector: entity?.sector || undefined,
    startDate: entity?.startDate || undefined,
    id: undefined,
  };

  return Object.entries(data).reduce((acc, [key, value]) => {
    if (value) {
      acc[key] = value;
    }

    return acc;
  }, {});
};

const AF_COUNTRY = 'AF_COUNTRY';
const AF_STATE = 'AF_STATE';
const AF_CITY = 'AF_CITY';
const AF_LOCATION = 'AF_LOCATION';

type AdditionalFieldsDefaultValueObject = {
  [AF_COUNTRY]?: string | number | null;
  [AF_STATE]?: string | number | null;
  [AF_CITY]?: string | number | null;
  [AF_LOCATION]?: string | number | null;
};

export const getAdditionalFieldsDefaultValuesFromJobAd = (
  jobAd: Partial<JobAd>,
): AdditionalFieldsDefaultValueObject => {
  const countryName = jobAd?.jobAdCountry?.name || '';
  const cityName = jobAd?.jobAdLocation?.placeName || '';

  return {
    [AF_COUNTRY]: countryName,
    [AF_STATE]: countryName,
    [AF_CITY]: cityName,
    [AF_LOCATION]: countryName,
  };
};

export const getAdditionalFieldsDefaultValues = ({
  country,
  location,
}: {
  country?: string;
  location?: string;
}): AdditionalFieldsDefaultValueObject => {
  return {
    [AF_COUNTRY]: country,
    [AF_STATE]: country,
    [AF_CITY]: location,
    [AF_LOCATION]: country,
  };
};

const fieldKeyToFieldNameMapper = {
  indeed_city: AF_CITY,
  indeed_country: AF_COUNTRY,
  indeed_state: AF_STATE,
  jora_city: AF_CITY,
  jora_country: AF_COUNTRY,
  jora_state: AF_STATE,
  jobstreet_loc: AF_LOCATION,
  seek_loc: AF_LOCATION,
};

const getAdditionalFieldNameByKey = (key: string) => {
  return fieldKeyToFieldNameMapper[key.toLowerCase()];
};

export const mapAdditionalFieldsWithDefaultValues = (
  jobBoardAdditionalFields: Required<JobBoard>['additionalFields'],
  additionalFieldsDefaultValues: AdditionalFieldsDefaultValueObject,
) => {
  if (!jobBoardAdditionalFields || !jobBoardAdditionalFields?.length) {
    return [];
  }

  return jobBoardAdditionalFields.map((jobBoardField) => {
    const additionalFieldName = getAdditionalFieldNameByKey(jobBoardField.key);

    const additionalFieldValue = additionalFieldsDefaultValues[additionalFieldName];

    if (!additionalFieldValue) {
      return jobBoardField;
    }

    if (jobBoardField.type === 'select' && !jobBoardField?.options?.length) {
      return jobBoardField;
    }

    if (!jobBoardField?.options?.length) {
      return {
        ...jobBoardField,
        value: additionalFieldValue,
      };
    }

    const findOption = jobBoardField.options.find((option) => option?.value === additionalFieldValue);

    return {
      ...jobBoardField,
      value: findOption?.key || null,
      hidden: additionalFieldName === AF_STATE && !!findOption?.key,
    };
  });
};
