import React, { useEffect } from 'react';
import { ExRoutes } from 'router/routes';
import startCase from 'lodash/startCase';

import { UserCompany } from 'api-endpoints/user/user.model';

import { useLogOutClickHandler } from 'containers/Auth/Hooks';
import { authGetUserAction } from 'containers/Auth/state/auth.actions';

import { Ellipsis } from 'components';
import { CandidateAvatar } from 'components/CandidateAvatar';
import { Spinner } from 'components/Spinner';
import { ExButton } from 'components/ui/ExButton';
import { ExCard } from 'components/ui/ExCard';
import { ExColumn } from 'components/ui/ExLayout';
import { MediaCallback } from 'components/ui/ExLayout/ExLayoutProps';
import { ExSizedBox } from 'components/ui/ExSizedBox';
import { ExVisible } from 'components/ui/ExVisible';
import { useLoaderSubscription } from 'modules/LoaderManager/react';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { authActions } from 'store/auth';
import { authSelectors } from 'store/auth/auth.selectors';
import { useSelector } from 'store/rootSelectors';

import {
  CardTitle,
  CompanyCardItem,
  CompanyImageWrapper,
  CompanyName,
  ExRowStyled,
  ListTitle,
  SelectCompanyPageStyled,
} from './SelectCompanyPageComponentsStyled';

type SelectCompanyPageCompaniesLIstProps = {
  className?: string;
};

const columnCustomCss: MediaCallback = (css) => css`
  flex-basis: 50%;
  flex-grow: 1;
`;

const useSelectCompanyPageCompaniesLIstState = ({ className }: SelectCompanyPageCompaniesLIstProps) => {
  const dispatch = useAppDispatch();

  const isUserHasAvailableCompany = useSelector(authSelectors.selectIsUserHasAvailableCompany);
  const companiesCombined = useSelector(authSelectors.selectCompaniesCombined);
  const { isLoading: isCompaniesLoading } = useLoaderSubscription(authActions.getAuthUserCompaniesAction);

  const companyClickHandler =
    (companyId: UserCompany['id']): React.MouseEventHandler =>
    () => {
      dispatch(authActions.authCompanySwitchAction({ companyId, redirectTo: ExRoutes.dashboard() }));
    };

  const logoutClickHandler = useLogOutClickHandler();

  useEffect(() => {
    dispatch(authGetUserAction());
    dispatch(authActions.getAuthUserCompaniesAction());
  }, [dispatch]);

  return {
    className,
    companiesCombined,
    companyClickHandler,
    isCompaniesLoading,
    isUserHasAvailableCompany,
    logoutClickHandler,
  } as const;
};

export const SelectCompanyPageCompaniesLIst: React.FC<SelectCompanyPageCompaniesLIstProps> = (props) => {
  const { companiesCombined, companyClickHandler, isUserHasAvailableCompany, isCompaniesLoading, logoutClickHandler } =
    useSelectCompanyPageCompaniesLIstState(props);

  if (isCompaniesLoading) {
    return <Spinner />;
  }

  if (!isUserHasAvailableCompany) {
    return (
      <SelectCompanyPageStyled>
        <ListTitle>Your sign-in was successful but you don’t have permission to access this module.</ListTitle>
        <ExSizedBox height={24} />
        <ExButton onClick={logoutClickHandler}>Log Out</ExButton>
      </SelectCompanyPageStyled>
    );
  }

  return (
    <>
      <ListTitle>Select Company</ListTitle>
      <ExRowStyled>
        {Object.entries(companiesCombined).map(([roleLabel, companies]) => (
          <ExVisible visible={!!companies.length} key={roleLabel}>
            <ExColumn large={columnCustomCss} extraLarge={columnCustomCss} medium={columnCustomCss}>
              <ExCard>
                <CardTitle>{startCase(roleLabel)}</CardTitle>
                {companies.map((company) => (
                  <CompanyCardItem key={company.id} onClick={companyClickHandler(company.id)}>
                    <CompanyImageWrapper>
                      <CandidateAvatar src={company.logoUrl} />
                    </CompanyImageWrapper>
                    <div>
                      <CompanyName>
                        <Ellipsis title={company.name}>{company.name}</Ellipsis>
                      </CompanyName>
                      <div>
                        <Ellipsis title={company.applicationRoleName}>{company.applicationRoleName}</Ellipsis>
                      </div>
                    </div>
                  </CompanyCardItem>
                ))}
              </ExCard>
            </ExColumn>
          </ExVisible>
        ))}
      </ExRowStyled>
    </>
  );
};
