import React from 'react';

import {
  ApplicantInfo,
  ApplicantModalActionButton,
  CandidateAvatarStyled,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/ApplicantModalV3Components';
import {
  ApplicantSectionDivider,
  ApplicantSectionStyled,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalV4Components';
import { ApplicantInfoSectionProps } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalV4Props';
import { ApplicantAdditionalInfoView } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantAdditionalInfo/ApplicantAdditionalInfoView';
import { ApplicantModalAvailabilityInfo } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalAvailabilityInfo';
import { ApplicantModalContactsInfo } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalContactsInfo';
import { ApplicantModalNameInfo } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalNameInfo';
import { ApplicantModalSalaryInfo } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalSalaryInfo';
import { ApplicantModalSeniorityLevelInfo } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalSeniorityLevelInfo';
import { ApplicantModalStatusInfo } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalStatusInfo';
import { ApplicantModalTotalCountInfo } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalTotalCountInfo/ApplicantModalTotalCountInfo';
import { useApplicantInfoSectionState } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/hook/useApplicantInfoSectionState';

import { ExVisible } from 'components/ui/ExVisible';

export const ApplicantInfoSection: React.FC<ApplicantInfoSectionProps> = (props) => {
  const { className, moreOptionsMenuItems, applicant, userInitials, showApplicantModalCounter } =
    useApplicantInfoSectionState(props);

  if (!applicant) {
    return null;
  }

  return (
    <ApplicantSectionStyled className={className}>
      <ApplicantModalActionButton dropDirection="down" menuItems={moreOptionsMenuItems} buttonId="applicant-modal-id" />
      <ApplicantInfo>
        <CandidateAvatarStyled src={applicant.photoUrl} alt={applicant.name ?? ''} initials={userInitials} />
        <ApplicantModalNameInfo />
        <ExVisible visible={showApplicantModalCounter}>
          <ApplicantModalTotalCountInfo />
        </ExVisible>
        <ApplicantModalStatusInfo />
      </ApplicantInfo>
      <ApplicantSectionDivider />
      <ApplicantModalSalaryInfo />
      <ApplicantModalSeniorityLevelInfo />
      <ApplicantModalAvailabilityInfo />
      <ApplicantSectionDivider />
      <ApplicantModalContactsInfo />
      <ApplicantSectionDivider />
      <ApplicantAdditionalInfoView />
    </ApplicantSectionStyled>
  );
};
