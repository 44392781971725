import * as yup from 'yup';
import { isBefore, parseISO } from 'date-fns';

import { DateTimeDto } from 'model';

import { validationSchemaAvailability } from 'containers/ApplicantForm/ApplicantFormValidators';

import { validationSchemaCandidateSocialNetworks } from 'utils/hooks/candidate';
import {
  isBeforeTodayTest,
  nullToUndefined,
  stringNullable,
  stringOptional,
  stringRequired,
  validationSchemaDateTimeDto,
} from 'utils/validator-helpers';

import { AvailabilityObject, Candidate } from 'store/entities/candidates/models';

const isAfterStartDateTest = (startDate: DateTimeDto): yup.TestConfig<string | null | undefined> => ({
  name: 'isAfterStartDateTest',
  test(value) {
    const startActualDate = startDate?.actualDate;
    if (!value || !startActualDate) {
      return true;
    }
    const startDateParsed = parseISO(startActualDate);
    const endDate = parseISO(value);

    return isBefore(startDateParsed, endDate);
  },
  message: ({ label }) => `${label} should not be prior Start Date`,
});

export const educationSchema = yup.object({
  name: stringRequired.label('Name'),
  number: stringRequired.label('Number'),
  organization: stringRequired.label('Organization'),
  educationId: yup.string().notRequired().optional(),
  educationAttachmentId: stringNullable,
  qualificationId: yup.string().nullable(true).default(null),
  candidateId: yup.string().notRequired(),
  startDate: validationSchemaDateTimeDto.shape({
    actualDate: yup.string().nullable(true).default(null).label('Start Date'),
  }),
  expiryDate: validationSchemaDateTimeDto.when(
    'startDate',
    (startDate: DateTimeDto, schema: yup.SchemaOf<DateTimeDto>) => {
      return schema.shape({
        actualDate: yup
          .string()
          .nullable(true)
          .default(null)
          .label('Expiry Date')
          .test(isAfterStartDateTest(startDate)),
      });
    },
  ),
  issueDate: validationSchemaDateTimeDto.when(
    'startDate',
    (startDate: DateTimeDto, schema: yup.SchemaOf<DateTimeDto>) => {
      return schema.shape({
        actualDate: validationSchemaDateTimeDto.fields.actualDate
          .label('Issue Date')
          .test(isAfterStartDateTest(startDate)),
      });
    },
  ),
  issuingAuthorityCity: stringNullable,
  country: stringNullable,
  companyId: stringOptional,
});

export const workExperienceSchema = yup.object().shape({
  companyName: stringRequired.label('Company Name'),
  position: stringRequired.label('Position'),
  currentlyEmployed: yup
    .boolean()
    .required()
    .typeError(({ label }) => `${label} is a required.`)
    .label('Currently Employed'),
  startDate: stringRequired.label('Start Date').test(isBeforeTodayTest).transform(nullToUndefined),
  endDate: yup
    .string()
    .label('End Date')
    .when('currentlyEmployed', {
      is: (currentlyEmployed: boolean) => !currentlyEmployed,
      then: (schema) =>
        schema.required().test({
          test(v) {
            const startDate: string | null | undefined = this.resolve(yup.ref('startDate'));
            if ([!startDate, !v].some(Boolean)) {
              return false;
            }
            const startDateParsed = parseISO(startDate!);
            const endDate = parseISO(v!);
            return isBefore(startDateParsed, endDate);
          },
          name: 'isBeforeStartDate',
          message: ({ label }) => `${label} should not be prior Start Date`,
        }),
    })
    .transform(nullToUndefined),
  address: stringNullable,
  country: stringNullable,
  state: stringNullable,
  city: stringNullable,
  postcode: stringNullable,
  contactName: stringNullable,
  contactNumber: stringNullable,
  contactEmail: yup.string().email().label('Contact Email'),
});

export const baseCandidateValidationSchema: yup.SchemaOf<
  Pick<Candidate, 'firstName' | 'lastName' | 'email' | 'mobile'>
> = yup.object({
  firstName: yup.string().label('First Name').trim().required(),
  lastName: yup.string().label('Last Name').trim().required(),
  email: yup.string().label('Email').email().required(),
  mobile: yup.string().max(15).nullable(true).default(null),
});

export const validationSchemaNewCandidateFirstStep = yup
  .object({
    location: yup.object().label('Location').nullable(true),
    socialNetworks: validationSchemaCandidateSocialNetworks,
    phone: yup.string().max(15).nullable(true).default(null),
  })
  .concat(baseCandidateValidationSchema);

export const validationSchemaEditCandidateDetails = yup
  .object({
    candidateAvailability: yup.lazy<yup.SchemaOf<AvailabilityObject>>((value) => {
      if (value?.availabilityType === null) {
        return yup
          .mixed()
          .nullable(true)
          .default(null)
          .transform(() => null);
      }

      return validationSchemaAvailability.nullable();
    }),
  })
  .concat(validationSchemaNewCandidateFirstStep);

export const validationSchemaNewCandidateSecondStep = yup.object().shape({});

export const validationSchemaNewCandidateThirdStep = yup.object().shape({});
export const validationSchemaNewCandidateFourthStep = yup.object().shape({});

export const validationSchemaNewCandidate = validationSchemaNewCandidateFirstStep
  .concat(validationSchemaNewCandidateSecondStep)
  .concat(validationSchemaNewCandidateThirdStep)
  .concat(validationSchemaNewCandidateFourthStep);
