import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { jobFormEffects } from 'containers/JobForms/state';
import { loaderSelectors } from 'containers/Loader/store';
import { talentPoolFormSelectors } from 'containers/TalentPoolForms/state';

import { FormInput } from 'components/FormInput';
import { PositionServiceForm } from 'components/PositionServiceForm';
import { Spinner } from 'components/Spinner';
import { StepFormBody } from 'components/StepForm';
import { getTranslate } from 'utils/i18utils';

import { TalentPool } from 'store/entities/talent-pools/models';
import { RootState } from 'store/rootReducer';

export interface NewTalentPoolFormBodyProps extends StepFormBody {
  className?: string;
}

const NewTalentPoolFormBody: React.FC<NewTalentPoolFormBodyProps> = ({ className, disableField = [] }) => {
  const { register, formState, errors, setError } = useFormContext<Partial<TalentPool>>();

  const apiErrors = useSelector(talentPoolFormSelectors.talentPoolApiErrorsSelector);

  const loading = useSelector<RootState>((state) =>
    loaderSelectors.isTaskActive(state, { taskId: jobFormEffects.loadSelectorsData }),
  );

  useEffect(() => {
    apiErrors.forEach((error) => {
      const message = getTranslate(error, { ns: 'validationErrorCodes' });
      setError('name', { message, type: 'server' });
    });
  }, [apiErrors, setError]);

  if (loading) {
    return (
      <div className="my-3">
        <Spinner />
      </div>
    );
  }

  return (
    <div className={className}>
      <input hidden name="id" ref={register} />
      <FormInput
        placeholder=""
        className="col-12"
        label="Talent Pool Name"
        hint="(This must be unique)"
        name="name"
        required
        autoComplete="off"
        errors={errors?.name}
        validated={formState.isSubmitted}
        inputRef={register}
        autoFocus
      />
      <PositionServiceForm
        categoryIdFieldName="categoryId"
        categoryNameFieldName="categoryName"
        positionIdFieldName="positionId"
        positionNameFieldName="positionName"
        categoryClassNames="col-12"
        positionClassNames="col-12"
        categoryFieldRequired={!disableField.includes('categoryId')}
        positionFieldRequired={!disableField.includes('positionId')}
        categoryFieldDisabled={disableField.includes('categoryId')}
        positionFieldDisabled={disableField.includes('positionId')}
      />
    </div>
  );
};

export default styled(NewTalentPoolFormBody)``;
