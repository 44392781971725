import React from 'react';
import { components, createFilter, SingleValueProps, StylesConfig } from 'react-select';
import { MultiValueRemoveProps } from 'react-select/src/components/MultiValue';
import styled from 'styled-components';

import { apiConfig } from 'config';

import { FormCard } from 'components/FormCard';
import { FormTagSelect, StyledCircleClose } from 'components/FormTagSelect';
import { useMultiselectReactSelectStyles } from 'utils/hooks/useMultiselectReactSelectStyles';
import { useReactSelectDebounceHandler } from 'utils/hooks/useReactSelectDebounce';

const ExMultiselectFormBodyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ExMultiselectFormBodyInputLabel = styled(FormCard.InputLabel)`
  display: flex;
`;

const ExMultiselectFormBodyHeaderActions = styled.div`
  margin-left: auto;
  padding: 0;
`;

const ExMultiselect = styled(FormTagSelect)`
  max-height: 600px;
  margin-bottom: 0.5rem;
`;

type ExMultiselectFormBodyProps<T> = {
  handlerLoadOptions?: (searchTerm: string, callBack: (options: Array<T>) => void) => void;
  loadEntitiesHandler: (searchTerm: string) => Promise<T[]>;
  defaultValue: Array<T>;
  overrideStyles?: StylesConfig<T, true>;
  onChangeHandler: (entityList: Array<T>) => void;
  unRemovedValuesFilter?: (entity: SingleValueProps<T>['data']) => boolean;
  className?: string;
  title: string;
  headerActions?: React.ReactChild;
  createFilterOuter?: typeof createFilter;
  backspaceRemovesValue?: boolean;
  isLoading?: boolean;
  onMenuScrollToBottom?: (event: React.SyntheticEvent<HTMLElement, Event>) => void;
  noOptionsMessage?: (obj: { inputValue: string }) => string | null;
};

type ExMultiselectReactOptionsMapper<T> = {
  getOptionValueCallback: (option: T) => any;
  getOptionLabelCallback: (option: T) => any;
};

function generateHtmlIdFromString(value: string) {
  return value.toLowerCase().split(' ').join('-');
}

export const ExMultiselectFormBody = <T extends Object>({
  defaultValue,
  onChangeHandler,
  unRemovedValuesFilter,
  getOptionLabelCallback,
  getOptionValueCallback,
  title,
  headerActions,
  loadEntitiesHandler,
  createFilterOuter,
  backspaceRemovesValue,
  onMenuScrollToBottom,
  isLoading,
  noOptionsMessage,
}: ExMultiselectFormBodyProps<T> & ExMultiselectReactOptionsMapper<T>) => {
  const overrideStyles = useMultiselectReactSelectStyles();

  const internalOnChangeHandler = (entityList: Array<T>) => {
    onChangeHandler(entityList ?? []);
  };

  const formGroupId = generateHtmlIdFromString(title);

  const debouncedLoadOptions = useReactSelectDebounceHandler({
    asyncLoader: loadEntitiesHandler,
    delay: apiConfig.debounceTime,
  });

  return (
    <ExMultiselectFormBodyWrapper>
      <ExMultiselectFormBodyInputLabel htmlFor={formGroupId}>
        {title}
        <ExMultiselectFormBodyHeaderActions>{headerActions}</ExMultiselectFormBodyHeaderActions>
      </ExMultiselectFormBodyInputLabel>
      <ExMultiselect
        className="ex-multiselect-body-list"
        isMulti
        name={formGroupId}
        menuIsOpen
        loadOptions={debouncedLoadOptions}
        cacheOptions
        defaultOptions
        value={defaultValue}
        autoFocus
        backspaceRemovesValue={backspaceRemovesValue}
        isClearable={false}
        getOptionValue={getOptionValueCallback}
        getOptionLabel={getOptionLabelCallback}
        maxMenuHeight={250}
        minMenuHeight={250}
        menuPortalTarget={false}
        multiline
        overrideStyles={overrideStyles}
        noOptionsMessage={noOptionsMessage}
        onChange={internalOnChangeHandler}
        onMenuScrollToBottom={onMenuScrollToBottom}
        isLoading={isLoading}
        filterOption={createFilterOuter ?? createFilter(null)}
        components={{
          Placeholder: () => null,
          IndicatorSeparator: null,
          DropdownIndicator: null,
          MultiValueRemove: (props: MultiValueRemoveProps<T>) => {
            if (unRemovedValuesFilter && unRemovedValuesFilter(props.data)) {
              return null;
            }
            return (
              <components.MultiValueRemove {...props}>
                <StyledCircleClose variant="primary" />
              </components.MultiValueRemove>
            );
          },
        }}
      />
    </ExMultiselectFormBodyWrapper>
  );
};
