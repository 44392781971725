import * as jobApi from 'api-endpoints/job';

import { JobApplicantValidationErrorCodes } from 'model/api-errors.constants';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { applicantListEffects } from 'containers/ApplicantLists/store';
import { loadApplicants } from 'containers/ApplicantLists/store/effects';
import { loaderActions } from 'containers/Loader/store';

import { getTranslate } from 'utils/i18utils';

import { Applicant, ApplicantBelongsTo, applicantSelectors } from 'store/entities/applicants';
import { HiringPipelineStage } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.types';
import { Job, jobsActions } from 'store/entities/jobs';
import { loadJobWorkflowTaskId } from 'store/entities/jobs/job.constants';
import { enhancedJobApi } from 'store/entities/jobs/jobs.api';
import { enhancedPlacementApi } from 'store/entities/placements/placements.api';
import { AppDispatch, AppEffectParams, AppThunk } from 'store/types';

export const loadJobCandidateSourceTaskId = 'loadJobCandidateSourceTaskId';

export const loadJobCandidateSource =
  ({ preloader, jobId }: AppEffectParams & { jobId: Job['id'] }): AppThunk =>
  async (dispatch) => {
    if (preloader) {
      dispatch(loaderActions.start(loadJobCandidateSourceTaskId));
    }
    const { message, data } = await jobApi.getJobCandidateSourceAnalytics({ urlParams: { jobId } });
    if (message) {
      if (preloader) {
        dispatch(loaderActions.stop(loadJobCandidateSourceTaskId));
      }
      return dispatch(alertsEffects.showError({ message }));
    }
    dispatch(jobsActions.updateOne({ id: jobId, ...data }));
    if (preloader) {
      dispatch(loaderActions.stop(loadJobCandidateSourceTaskId));
    }
  };

export const loadJobApplicationActivityAnalyticsTaskId = 'loadJobApplicationActivityAnalyticsTaskId';

export const loadApplicationActivityAnalytics =
  ({ preloader, jobId }: AppEffectParams & { jobId: Job['id'] }): AppThunk =>
  async (dispatch) => {
    if (preloader) {
      dispatch(loaderActions.start(loadJobApplicationActivityAnalyticsTaskId));
    }
    const { message, data } = await jobApi.getJobApplicationActivityAnalytic({
      urlParams: { jobId },
    });
    if (message) {
      if (preloader) {
        dispatch(loaderActions.stop(loadJobApplicationActivityAnalyticsTaskId));
      }
      return dispatch(alertsEffects.showError({ message }));
    }
    dispatch(jobsActions.upsertOne({ item: { id: jobId, ...data } as Job }));
    if (preloader) {
      dispatch(loaderActions.stop(loadJobApplicationActivityAnalyticsTaskId));
    }
  };

type ChangeWorkflowStageProps = {
  jobId: Job['id'];
  applicantId: Applicant['id'];
  stageId: string;
  requiredStageBetween: HiringPipelineStage | null;
};

export const changeWorkflowStage =
  ({ jobId, applicantId, stageId, requiredStageBetween }: ChangeWorkflowStageProps) =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(loaderActions.start(loadJobWorkflowTaskId));
    const { message, errorData, data } = await jobApi.changeWorkflowStage({
      data: { applicantId, stageId },
      urlParams: { applicantId, jobId, stageId },
    });

    if (errorData?.validationErrorCodes?.includes(JobApplicantValidationErrorCodes.HasInterviewsToPass)) {
      const nonPassedInterviewTemplates = applicantSelectors.selectApplicantNonPassedInterviewTemplates(
        getState(),
        applicantId,
      );

      const requiredInterviewTemplateName = nonPassedInterviewTemplates?.[0]?.name;
      const requiredInterviewTemplateErrorMessage = requiredInterviewTemplateName
        ? `${requiredInterviewTemplateName} must be completed first.`
        : 'You need to complete the interview first.';

      dispatch(alertsEffects.showError({ message: requiredInterviewTemplateErrorMessage }));
    } else if (errorData?.validationErrorCodes?.includes(JobApplicantValidationErrorCodes.RequiredStagesUnpassed)) {
      const requiredStageName = requiredStageBetween?.name;
      const requiredStageErrorMessage = requiredStageName
        ? `${requiredStageName} is a required step.`
        : 'You need to pass required stage.';

      dispatch(alertsEffects.showError({ message: requiredStageErrorMessage }));
    } else if (message) {
      dispatch(alertsEffects.showError({ message }));
    }

    dispatch(applicantListEffects.loadApplicants({ listId: ApplicantBelongsTo.job }));
    dispatch(applicantListEffects.loadApplicants({ listId: ApplicantBelongsTo.candidate }));
    dispatch(enhancedJobApi.util.invalidateTags([{ type: 'JobApplicantsPerPipelineStageFigures', id: jobId }]));
    dispatch(enhancedPlacementApi.util.invalidateTags([{ type: 'Placements', id: 'LIST' }]));
    dispatch(enhancedJobApi.util.invalidateTags([{ type: 'JobPipelines', id: jobId }]));
    dispatch(loaderActions.stop(loadJobWorkflowTaskId));
    return { message, ...data };
  };

export const bulkJobActionTaskId = 'bulkJobActionTaskId';

export const bulkUpdateApplicantPipelineStage =
  ({
    listId,
    jobId,
    applicantIds,
    pipelineStageId,
  }: {
    listId: string;
    jobId: Job['id'];
    applicantIds: Applicant['id'][];
    pipelineStageId: string;
  }) =>
  async (dispatch: AppDispatch) => {
    dispatch(loaderActions.start(bulkJobActionTaskId));
    const { data } = await jobApi.bulkUpdateApplicantPipelineStageMethod({
      data: { jobApplicantIds: applicantIds, pipelineStageId },
      urlParams: { jobId },
    });

    const applicantsIsInvalid = data.applicants.filter((applicant) => !applicant.validationResult.isValid);
    const hasErrors = Boolean(applicantsIsInvalid.length);

    const errorCodes = new Set();

    applicantsIsInvalid.forEach((applicant) => {
      const errors: string[] = applicant.validationResult.errors;
      errors.forEach((error) => errorCodes.add(error));
    });

    const errorCodesMsg = [...errorCodes].map((validationErrorCode: any) =>
      getTranslate(validationErrorCode, { ns: 'validationErrorCodes' }),
    );

    if (hasErrors) {
      let errorMsg = `Some applicants can't be moved`;
      if (errorCodesMsg.length) {
        errorMsg += `: ` + errorCodesMsg.join(', ');
      }

      dispatch(alertsEffects.showError({ message: errorMsg }));
    }

    dispatch(loadApplicants({ listId, preloader: true }));
    dispatch(loaderActions.stop(bulkJobActionTaskId));

    return data.pipelineStageActionCancellationId;
  };
