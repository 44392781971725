import { matchPath } from 'react-router-dom';

export const isApplicantsModalPath = (pathname: string) => {
  return matchPath<{ applicantsModalTab?: string }>(pathname, {
    path: '/jobs/:jobId/applicants-view/:applicantsViewTab/:applicantId/:applicantsModalTab',
  });
};

export function compareByApplicantId<T extends { applicantId?: string }>(applicantId: string | null) {
  return function (item: T) {
    return item.applicantId === applicantId;
  };
}
