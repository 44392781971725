import { useMemo } from 'react';

import { Frequency, FrequencyNames } from 'model/api-enums.constants';

import { StringIsNumber } from 'utils/funcs';

export const getFrequencyNames = (value: string | Frequency, short?: boolean) => {
  return short ? `p.${FrequencyNames[value][0].toLowerCase()}.` : `Per ${FrequencyNames[value]}`;
};

export function useFrequencyOptions(short?: boolean) {
  return useMemo(
    () =>
      Object.entries(Frequency)
        .filter((e) => StringIsNumber(e[1]))
        .map(([_, value]) => ({ value, label: getFrequencyNames(value, short) })),
    [short],
  );
}
