import get from 'lodash/get';
import merge from 'lodash/merge';
import set from 'lodash/set';

import { consoleErrorForDevEnv } from 'utils/consoleErrorForDevEnv';

export function loadStateFrom<State extends Record<string, any>>(storage: Storage, key: string, initialState: State) {
  try {
    const serializedState = storage.getItem(key);
    if (serializedState === null) {
      return initialState;
    }
    const state = {};
    merge(state, initialState, JSON.parse(serializedState));

    return state;
  } catch (err) {
    consoleErrorForDevEnv('LOAD STATE ERR', err);
    return undefined;
  }
}

export function saveStateTo<State extends Record<string, any>>(
  storage: Storage,
  key: string,
  state: State,
  branches: string[],
) {
  try {
    const stateToStore = branches.reduce((acc, branch) => set(acc, branch, get(state, branch)), {});
    storage.setItem(key, JSON.stringify(stateToStore));
  } catch (err) {
    consoleErrorForDevEnv('SAVE STATE ERR', err);
    // ignore write errors
  }
}
