import { documentMeta } from 'config/document-meta';

import { AppDispatch } from 'store/types';
import { documentMetaActions } from 'store/ui/document-meta/reducer';

export const setDocumentTitle =
  ({ title }: { title?: string | null }) =>
  async (dispatch: AppDispatch) => {
    let appTitle = title || documentMeta.title;
    if (title && documentMeta.title) {
      appTitle += ` | ${documentMeta.title}`;
    }
    document.title = appTitle;
    dispatch(documentMetaActions.setDocumentTitle({ title: appTitle }));
  };
