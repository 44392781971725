const {
  REACT_APP_AUTH0_DOMAIN = '',
  REACT_APP_AUTH0_CLIENTID = '',
  REACT_APP_AUTH0_AUDIENCE = '',
  REACT_APP_AUTH_AUTHORITY = '',
  REACT_APP_AUTH_CLIENT_ID = '',
  REACT_APP_AUTH_REDIRECT_URI,
  REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI,
  REACT_APP_AUTH_RESPONSE_TYPE = 'code',
  REACT_APP_AUTH_SCOPE = 'openid profile email address standard_api IdentityServerApi',
  REACT_APP_AUTH_AUTOMATIC_SILENT_RENEW = true,
} = process.env;

export const auth0Config = {
  domain: REACT_APP_AUTH0_DOMAIN,
  clientId: REACT_APP_AUTH0_CLIENTID,
  audience: REACT_APP_AUTH0_AUDIENCE,
};

const baseUrlPort = window.location.port ? `:${window.location.port}` : '';
const baseUrl = `${window.location.protocol}//${window.location.hostname}${baseUrlPort}/`;

export const AUTH_CONFIG = {
  authority: REACT_APP_AUTH_AUTHORITY,
  client_id: REACT_APP_AUTH_CLIENT_ID,
  redirect_uri: REACT_APP_AUTH_REDIRECT_URI ?? baseUrl + 'login-confirm',
  post_logout_redirect_uri: REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI ?? baseUrl + 'logout-confirm',
  response_type: REACT_APP_AUTH_RESPONSE_TYPE,
  scope: REACT_APP_AUTH_SCOPE,
  automaticSilentRenew: Boolean(REACT_APP_AUTH_AUTOMATIC_SILENT_RENEW),
};
