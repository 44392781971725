import { ApplicationRole } from 'model/api-enums.constants';

import * as fromAuthMatrixConstants from './authMatrix.constants';

export const rbacConstants = {
  ...fromAuthMatrixConstants,
};

const rulesForUsers = [rbacConstants.users.create, rbacConstants.users.disable, rbacConstants.users.enable];

const rulesForCompany = [
  rbacConstants.company.view,
  rbacConstants.company.editSettings,
  rbacConstants.company.editPipeline,
  rbacConstants.company.editPipelineStage,
];

const rulesForJob = [
  rbacConstants.jobs.createDraft,
  rbacConstants.jobs.deleteDraft,
  rbacConstants.jobs.delete,
  rbacConstants.jobs.publishNew,
  rbacConstants.jobs.complete,
  rbacConstants.jobs.cancel,
  rbacConstants.jobs.downloadJobsReport,
  rbacConstants.jobs.view,
  rbacConstants.jobs.edit,
  rbacConstants.jobs.hiringTeamAddMember,
  rbacConstants.jobs.hiringTeamRemoveMember,
];

const rulesForJobAd = [
  rbacConstants.jobAds.createDraftAd,
  rbacConstants.jobAds.postAd,
  rbacConstants.jobAds.edit,
  rbacConstants.jobAds.rePostAd,
  rbacConstants.jobAds.viewJobAd,
  rbacConstants.jobAds.stopJobAd,
  rbacConstants.jobAds.duplicateAd,
];

const rulesForCandidates = [
  rbacConstants.candidates.create,
  rbacConstants.candidates.editDetails,
  rbacConstants.candidates.editEducation,
  rbacConstants.candidates.editExperience,
  rbacConstants.candidates.editDocuments,
  rbacConstants.candidates.editResumes,
  rbacConstants.candidates.view,
  rbacConstants.candidates.viewCandidate,
  rbacConstants.candidates.addToTalentPool,
  rbacConstants.candidates.bulkAddToTalentPool,
  rbacConstants.candidates.removeFromTalentPool,
  rbacConstants.candidates.comment,
];

const rulesForApplicant = [
  rbacConstants.applicants.view,
  rbacConstants.applicants.edit,
  rbacConstants.applicants.create,
  rbacConstants.applicants.addToTalentPool,
];

const rulesForJobBoards = [
  rbacConstants.jobBoards.view,
  rbacConstants.jobBoards.subscribe,
  rbacConstants.jobBoards.unsubscribe,
  rbacConstants.jobBoards.edit,
];

const rulesForTalentPools = [
  rbacConstants.talentPools.create,
  rbacConstants.talentPools.edit,
  rbacConstants.talentPools.view,
  rbacConstants.talentPools.remove,
  rbacConstants.talentPools.bulkRemoveCandidatesFromTalentPool,
  rbacConstants.talentPools.actions,
];

const rulesForPlacements = [rbacConstants.placements.view, rbacConstants.placements.pushToExpedoHR];

const rulesForInterview = [
  rbacConstants.interviews.edit,
  rbacConstants.interviews.remove,
  rbacConstants.interviews.publish,
];

export const permissionMatrix = {
  [ApplicationRole.SuperAdmin]: {
    static: [
      // start --- rules for company
      ...rulesForCompany,
      rbacConstants.company.create,
      rbacConstants.company.showIntegrationsTab,
      // end --- rules for company

      // start --- rules for job
      ...rulesForJob,
      rbacConstants.jobs.interviewTemplatesEdit,
      // end --- rules for job

      ...rulesForUsers,
      ...rulesForJobAd,
      ...rulesForCandidates,
      ...rulesForApplicant,
      ...rulesForJobBoards,
      ...rulesForTalentPools,
      ...rulesForPlacements,
      ...rulesForInterview,

      // start --- rules for mainDashboard
      rbacConstants.mainDashboard.view,
      // end --- rules for mainDashboard
    ],
  },
  [ApplicationRole.Admin]: {
    static: [
      // start --- rules for company
      ...rulesForCompany,
      // end --- rules for company

      // start --- rules for job
      ...rulesForJob,
      rbacConstants.jobs.interviewTemplatesEdit,
      // end --- rules for job

      ...rulesForUsers,
      ...rulesForJobAd,
      ...rulesForCandidates,
      ...rulesForApplicant,
      ...rulesForJobBoards,
      ...rulesForTalentPools,
      ...rulesForPlacements,
      ...rulesForInterview,

      // start --- rules for mainDashboard
      rbacConstants.mainDashboard.view,
      // end --- rules for mainDashboard
    ],
  },
  [ApplicationRole.Recruiter]: {
    static: [
      // start --- rules for company
      // end --- rules for company

      // start --- rules for users
      // end --- rules for users

      ...rulesForJob,
      ...rulesForJobAd,
      ...rulesForCandidates,
      ...rulesForApplicant,

      // start --- rules for jobBoards
      // end --- rules for jobBoards

      ...rulesForTalentPools,
      ...rulesForPlacements,
      ...rulesForInterview,

      // start --- rules for mainDashboard
      rbacConstants.mainDashboard.view,
      // end --- rules for mainDashboard
    ],
  },
  [ApplicationRole.Manager]: {
    static: [
      // start --- rules for company
      // end --- rules for company

      // start --- rules for users
      // end --- rules for users

      // start --- rules for Job
      rbacConstants.jobs.createDraft,
      rbacConstants.jobs.view,
      rbacConstants.jobs.hiringTeamRemoveMember,
      // end --- rules for Job

      // start --- rules for Job Ad
      rbacConstants.jobAds.viewJobAd,
      // end --- rules for Job Ad

      // start --- rules for candidates
      rbacConstants.candidates.create,
      rbacConstants.candidates.viewCandidate,
      // end --- rules for candidates

      // start --- rules for applicant
      rbacConstants.applicants.view,
      rbacConstants.applicants.edit,
      rbacConstants.applicants.create,
      // end --- rules for applicant

      // start --- rules for jobBoards
      // end --- rules for jobBoards

      // start --- rules for placements
      rbacConstants.placements.view,
      // end --- rules for placements

      ...rulesForInterview,

      // start --- rules for mainDashboard
      rbacConstants.mainDashboard.view,
      // end --- rules for mainDashboard
    ],
  },
};
