import * as effects from './effects';
import jobFormSlice from './reducer';
import * as selectors from './selectors';

export const jobFormActions = { ...jobFormSlice.actions };

export const jobFormReducer = jobFormSlice.reducer;

export const jobFormSelectors = selectors;

export const jobFormEffects = effects;

export default jobFormReducer;
