import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { deleteCandidateResume, getCandidateInfoById, uploadCandidateInfoById } from 'api-endpoints/candidate';

import { ApiResponseWithPagination, AppFile } from 'model';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { loaderActions } from 'containers/Loader/store';

import { isResumeFile } from 'utils/funcs';

import { showValidationErrorCodes } from 'store/app-files/apFiles.effects';
import { ResumeFile, ResumeFilesParams } from 'store/app-files/resumes/resumes.models';
import { resumesActions } from 'store/app-files/resumes/resumes.reducer';
import { Candidate, CandidateIfoKey } from 'store/entities/candidates/models';
import { AppEffectParams, ThunkApiConfig } from 'store/types';

const RESUME_FETCH = 'resume/fetch';
const RESUME_UPLOAD = 'resume/upload';
const RESUME_REMOVE = 'resume/remove';

export const createResumeFetchLoaderId = (candidateId = '') => `${RESUME_FETCH}/${candidateId}`;

export const createResumeUploadLoaderId = (candidateId = '') => `${RESUME_UPLOAD}/${candidateId}`;

export const uploadResumeFiles = createAsyncThunk<
  boolean | AppFile,
  AppEffectParams<{ formData: FormData; candidateId: Candidate['id'] }>,
  ThunkApiConfig
>(RESUME_UPLOAD, async ({ preloader, candidateId, formData }, { dispatch, signal }) => {
  const source = axios.CancelToken.source();
  const loaderId = createResumeUploadLoaderId(candidateId);
  if (preloader) {
    dispatch(loaderActions.start(loaderId));
  }
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const { message, response, data } = await uploadCandidateInfoById(
    candidateId,
    CandidateIfoKey.resume,
    formData,
    source,
  );

  if (preloader) {
    dispatch(loaderActions.stop(loaderId));
  }
  if (message) {
    if (response?.data.validationErrorCodes) {
      showValidationErrorCodes(dispatch, response.data.validationErrorCodes, message);
    } else {
      dispatch(alertsEffects.showError({ message }));
    }
  }
  return !message ? data : false;
});

export const loadResumeFiles = createAsyncThunk<
  ApiResponseWithPagination<ResumeFile>,
  AppEffectParams<ResumeFilesParams>,
  ThunkApiConfig
>(RESUME_FETCH, async ({ preloader, candidateId }, { dispatch, signal, requestId, rejectWithValue, getState }) => {
  const { pageParams, query } = getState().appFiles.resumes.pagination.requests[requestId] || {};
  const requestParams = { ...pageParams, ...query };

  const source = axios.CancelToken.source();
  const loaderId = createResumeFetchLoaderId(candidateId);
  if (preloader) {
    dispatch(loaderActions.start(loaderId));
  }
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const { data, message } = await getCandidateInfoById<ResumeFile>(
    candidateId,
    CandidateIfoKey.resume,
    requestParams,
    source,
  );
  if (preloader) {
    dispatch(loaderActions.stop(loaderId));
  }
  if (message) {
    dispatch(
      alertsEffects.showError({
        message,
      }),
    );
    return rejectWithValue(message);
  }
  return data;
});

export const deleteResumeFile = createAsyncThunk<{ success: boolean; data: AppFile }, any, ThunkApiConfig>(
  RESUME_REMOVE,
  async ({ preloader, candidateId, documentId }, { dispatch, signal, requestId }) => {
    const source = axios.CancelToken.source();
    if (preloader) {
      dispatch(loaderActions.start(requestId));
    }
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    const { message, response, data } = await deleteCandidateResume({
      cancelToken: source,
      candidateId,
      resumeId: documentId,
    });

    if (preloader) {
      dispatch(loaderActions.stop(requestId));
    }

    if (message) {
      if (response?.data.validationErrorCodes) {
        showValidationErrorCodes(dispatch, response.data.validationErrorCodes, message);
      } else {
        dispatch(alertsEffects.showError({ message }));
      }

      return { data, success: false };
    }

    if (isResumeFile(data)) {
      dispatch(resumesActions.upsertOne(data));
    }
    return { data, success: true };
  },
);
