import React from 'react';
import styled from 'styled-components/macro';

import { ApplicantModalEducation } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalEducation/ApplicantModalEducation';
import { ApplicantModalScreeningQuestions } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalScreeningQuestions';
import { ApplicantModalWorkExperience } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalWorkExperience/ApplicantModalWorkExperience';

type ApplicantOverviewTabProps = {
  className?: string;
};

const ApplicantOverviewTabStyled = styled.div``;

const useApplicantOverviewTabState = ({ className }: ApplicantOverviewTabProps) => {
  return {
    className,
  } as const;
};

export const ApplicantOverviewTab: React.FC<ApplicantOverviewTabProps> = (props) => {
  const { className } = useApplicantOverviewTabState(props);

  return (
    <ApplicantOverviewTabStyled className={className}>
      <ApplicantModalScreeningQuestions />
      <ApplicantModalWorkExperience />
      <ApplicantModalEducation />
    </ApplicantOverviewTabStyled>
  );
};
