import type { Country, Location } from 'model';
import memoize from 'lodash/memoize';

import type { Candidate } from 'store/entities/candidates/models';

type getLocationProps = {
  location?: Partial<Location> | null;
  country?: Country;
  locationString?: string;
  candidate?: Candidate;
};

const getLocationPure = ({ location, country, locationString, candidate }: getLocationProps) => {
  if (locationString) {
    return locationString;
  }

  const customLocationString = [
    candidate?.address,
    location?.placeName,
    location?.county ?? location?.region,
    country?.name ?? location?.country,
  ]
    .filter(Boolean)
    .join(', ');

  if (!customLocationString) {
    return '--';
  }

  return customLocationString;
};

export const getLocation = memoize(getLocationPure, (props) => JSON.stringify(props));
