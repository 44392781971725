import { createSelector } from '@reduxjs/toolkit';

import { compareByApplicantId } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/utils';

import { pluck } from 'utils/pluck';

import { candidateEducationSelectors } from 'store/entities/candidate-education/candidate-education.selectors';
import { candidateWorkExperienceSelectors } from 'store/entities/candidate-work-experience/candidate-work-experience.selectors';
import { RootState } from 'store/rootReducer';

const domain = (root: RootState) => root.forms.applicantModal;

const selectIsApplicantModalInEditMode = createSelector(domain, (state) => state.isEditMode);
const selectFormState = createSelector(domain, (state) => state.formState);
const selectCurrentApplicantId = createSelector(domain, (state) => state.currentApplicantId);
const selectApiErrors = createSelector(domain, (state) => state.apiErrors);

const applicantModalCountInfo = createSelector(domain, (state) => {
  return {
    inListPosition: state.inListPosition,
    totalApplicantsCount: state.totalApplicantsCount,
  };
});

const selectWorkExperiencesForCurrentApplicant = createSelector(
  selectCurrentApplicantId,
  candidateWorkExperienceSelectors.selectAll,
  (applicantId, workExperiences) => {
    return workExperiences.filter(compareByApplicantId(applicantId));
  },
);

const selectPreviousWorkExperienceForCurrentApplicant = createSelector(
  selectWorkExperiencesForCurrentApplicant,
  (workExperiences) => workExperiences[0],
);

const selectWorkExperienceIdsForCurrentApplicant = createSelector(
  selectWorkExperiencesForCurrentApplicant,
  (workExperiences) => workExperiences.map(pluck('workExperienceId')),
);

const selectEducationsForCurrentApplicant = createSelector(
  selectCurrentApplicantId,
  candidateEducationSelectors.selectAll,
  (applicantId, educations) => {
    return educations.filter(compareByApplicantId(applicantId));
  },
);

const selectEducationIdsForCurrentApplicant = createSelector(selectEducationsForCurrentApplicant, (educations) =>
  educations.map(pluck('educationId')),
);

const selectApplicantModalNumberOfComments = createSelector(domain, (state) => {
  return state.numberOfComments;
});

export const applicantModalSliceSelectors = {
  selectIsApplicantModalInEditMode,
  selectFormState,
  selectCurrentApplicantId,
  selectApiErrors,
  applicantModalCountInfo,
  selectWorkExperiencesForCurrentApplicant,
  selectWorkExperienceIdsForCurrentApplicant,
  selectEducationsForCurrentApplicant,
  selectEducationIdsForCurrentApplicant,
  selectPreviousWorkExperienceForCurrentApplicant,
  selectApplicantModalNumberOfComments,
};
