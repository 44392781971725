import { createSelector } from '@reduxjs/toolkit';

import { authSelectors } from 'store/auth/auth.selectors';
import { companySelectors } from 'store/company/company.selectors';
import { makeExCommentView } from 'store/entities/applicant-comments/applicant-comments.utils';
import { entitiesDomain } from 'store/rootSelectors';

import { jobCommentsAdapter, jobCommentsSlice } from './job-comments.reducer';

const domain = createSelector(entitiesDomain, (entities) => entities[jobCommentsSlice.name]);

const jobCommentsAdapterSelectors = jobCommentsAdapter.getSelectors(domain);

const selectCurrent = createSelector(domain, ({ jobId, lastLoadedPage, pageCount }) => ({
  jobId,
  lastLoadedPage,
  pageCount,
}));

const isHasMore = createSelector(domain, ({ lastLoadedPage, pageCount }) => Boolean(lastLoadedPage < pageCount - 1));

const selectCommentView = createSelector(
  jobCommentsAdapterSelectors.selectById,
  authSelectors.apiUser,
  companySelectors.getDefaultDateFormat,
  makeExCommentView,
);

export const jobCommentsSelectors = {
  ...jobCommentsAdapterSelectors,
  isHasMore,
  selectCommentView,
  selectCurrent,
};
