import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Option } from 'react-select/src/filters';
import styled from 'styled-components';

import { Currency } from 'model';
import { Frequency } from 'model/api-enums.constants';
import { KeyCodes } from 'model/keycodes';

import { jobFormEffects } from 'containers/JobForms/state';
import { loaderSelectors } from 'containers/Loader/store';

import { FormInput } from 'components/FormInput/FormInput';
import { FormSelect } from 'components/FormSelect';
import { Spinner } from 'components/Spinner';
import { StepFormBody } from 'components/StepForm/StepFormProps';
import { useFrequencyOptions } from 'utils/hooks/formsHooks/useFrequencyOptions';
import { useRestrictedKeyCodes } from 'utils/hooks/useRestrictedKeyCodes';

import { companySelectors } from 'store/company/company.selectors';
import { Job } from 'store/entities/jobs/models';
import { useSelector } from 'store/rootSelectors';

interface JobSalaryFormBodyProps extends StepFormBody {
  className?: string;
  jobId?: Job['id'];
}

const salaryMinimumPath = 'salary.moneyRange.minimum';
const salaryMaximumPath = 'salary.moneyRange.maximum';

function useJobSalaryFormBodyState() {
  const { setValue, formState, control, getValues, register, errors } = useFormContext<Job>();
  const currencies = useSelector(companySelectors.getCompanyCurrenciesSortedByName);

  const validated = formState.isSubmitted;
  const loading = useSelector((state) =>
    loaderSelectors.isTaskActive(state, { taskId: jobFormEffects.loadSelectorsData }),
  );

  const defaultCurrency = useSelector(companySelectors.getDefaultCurrency);

  const { handlerKeyDown: handlerKeyDownSalaryRange } = useRestrictedKeyCodes({
    restrictedKeyCodes: [KeyCodes.KEY_E, KeyCodes.DASH, KeyCodes.EQUALS, KeyCodes.ADD, KeyCodes.SUBTRACT],
  });

  const onChangeHandler = React.useCallback(() => {
    const salaryFrom: string | undefined = control.getValues(salaryMinimumPath);
    const salaryTo: string | undefined = control.getValues(salaryMaximumPath);
    if (salaryFrom === '' || Number(salaryFrom) < 0) {
      setValue(salaryMaximumPath, '', { shouldValidate: formState.isSubmitted });
    }
    if (Number(salaryTo) <= Number(salaryFrom)) {
      setValue(salaryMaximumPath, salaryFrom, { shouldValidate: formState.isSubmitted });
    }
  }, [control, formState.isSubmitted, setValue]);

  const frequencies = useFrequencyOptions();

  const defaultSalary = useMemo(
    () => currencies.find((currency) => currency.currency === defaultCurrency),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const defaultFrequency = useMemo(
    () => frequencies.find((frequency) => frequency.value === Frequency.Annually),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    onChangeHandler();
    const formData = getValues();
    setValue('salary.currency', formData.salary?.currency ?? defaultSalary?.currency);
    setValue('salary.frequency', formData.salary?.frequency ?? defaultFrequency?.value);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    errors,
    validated,
    currencies,
    frequencies,
    register,
    onChangeHandler,
    handlerKeyDownSalaryRange,
  } as const;
}

const JobSalaryFormBody: React.FC<JobSalaryFormBodyProps> = () => {
  const { loading, errors, validated, currencies, frequencies, register, handlerKeyDownSalaryRange, onChangeHandler } =
    useJobSalaryFormBodyState();

  if (loading) {
    return (
      <div className="my-3">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="row">
        <FormSelect
          className="col-6"
          label="Currency"
          placeholder=""
          required
          name="salary.currency"
          errors={errors?.salary?.currency}
          validated={validated}
          defaultOptions
          getOptionLabel={(option: Currency) => option.currencyFullName}
          getOptionValue={(option: Currency) => option.currency}
          options={currencies}
        />
      </div>
      <div className="row">
        <FormInput
          className="col-4"
          label="Salary From"
          name="salary.moneyRange.minimum"
          required
          onChange={onChangeHandler}
          errors={errors?.salary?.moneyRange?.minimum}
          validated={validated}
          inputRef={register}
          type="number"
          onKeyDown={handlerKeyDownSalaryRange}
        />
        <FormInput
          className="col-4"
          label="Salary To"
          name="salary.moneyRange.maximum"
          required
          errors={errors?.salary?.moneyRange?.maximum}
          validated={validated}
          inputRef={register}
          type="number"
          onKeyDown={handlerKeyDownSalaryRange}
        />
        <FormSelect
          className="col-4"
          label="Salary Frequency"
          placeholder=""
          name="salary.frequency"
          required
          errors={errors?.salary?.frequency}
          validated={validated}
          defaultOptions
          getOptionLabel={(option: Option) => option.label}
          getOptionValue={(option: Option) => option.value}
          options={frequencies}
        />
      </div>
    </>
  );
};

export default styled(JobSalaryFormBody)``;
