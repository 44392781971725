import React from 'react';
import styled from 'styled-components';

import { useCalculatePages } from 'components/ListView/hooks/useCalculatePages';
import { Pagination } from 'components/Pagination';

import { usePaginationControls } from './hooks/usePaginationControl';
import { ListViewPaginationProps } from './ListViewProps';

const ListViewPagination: React.FC<ListViewPaginationProps & { className?: string }> = ({
  updatePage,
  onMouseEnterPage,
  pageCount,
  pageNo,
  className,
}) => {
  const handlePageChange = (destinationPageNumber: number) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    updatePage(destinationPageNumber);
  };

  const onMouseEnterPageHandle = (pageNumber: number) => () => {
    if (onMouseEnterPage) {
      onMouseEnterPage(pageNumber);
    }
  };

  const pages = useCalculatePages({ pageNo, pageCount });

  const { isNextPageDisabled, isPrevPageDisabled, nextPage, prevPage } = usePaginationControls({
    pageNo,
    pageCount,
  });

  return (
    <>
      {pageCount > 1 ? (
        <Pagination className={className}>
          <Pagination.Prev
            active={false}
            onClick={handlePageChange(prevPage)}
            onMouseEnter={onMouseEnterPageHandle(prevPage)}
            disabled={isPrevPageDisabled}
          />
          {pages.map((page) => (
            <Pagination.Item
              key={page}
              active={page === pageNo}
              onClick={handlePageChange(page)}
              onMouseEnter={onMouseEnterPageHandle(page)}
            >
              {page + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            active={false}
            onClick={handlePageChange(nextPage)}
            onMouseEnter={onMouseEnterPageHandle(nextPage)}
            disabled={isNextPageDisabled}
          />
        </Pagination>
      ) : null}
    </>
  );
};

export const ListViewPaginationCentered = styled(ListViewPagination)`
  width: 100%;
  justify-content: center;
`;

export default ListViewPagination;
