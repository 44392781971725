import { createAction } from '@reduxjs/toolkit';

import { TalentPool } from 'store/entities/talent-pools';

import { BulkUploadResumes } from './bulkCreation.model';

const BULK_CREATION_CANDIDATE = 'BULK_CREATION_CANDIDATE';
const BULK_CREATION_CANDIDATE_ADD_NEW_TALENT_POOL = `${BULK_CREATION_CANDIDATE}/ADD_NEW_TALENT_POOL`;
const BULK_CREATION_APPLICANTS = 'BULK_CREATION_APPLICANTS';
const UPLOAD_RESUME = 'UPLOAD_RESUME';

export const bulkCreateCandidate = createAction<{ talentPoolId?: TalentPool['id'] } | undefined>(
  BULK_CREATION_CANDIDATE,
);
export const bulkCreateCandidateAnnNewTalentPool = createAction<{ selectedTalentPool: TalentPool | null }>(
  BULK_CREATION_CANDIDATE_ADD_NEW_TALENT_POOL,
);
export const bulkCreateApplicants = createAction(BULK_CREATION_APPLICANTS);
export const bulkUploadResumes = createAction<BulkUploadResumes>(UPLOAD_RESUME);
