import { SortDirections } from 'store/constants';
import { TalentPoolListRequestParams } from 'store/entities/talent-pools/api/talent-pool.requests';

export const talentPoolSearchTermMapper = ({
  params: { filters, ...restParams },
  ...restRequest
}: TalentPoolListRequestParams): TalentPoolListRequestParams => {
  return {
    params: {
      filters,
      ...restParams,
      ...(filters?.name && { searchTerm: filters.name }),
    },
    ...restRequest,
  };
};

export const talentPoolOrderMapper = ({ params, ...restRequest }: TalentPoolListRequestParams) => {
  return {
    params: {
      ...params,
      ...(params.sortMode?.orderBy && {
        orderBy: `${params.sortMode.orderBy}${params.sortMode.orderDir === SortDirections.desc ? ' descending' : ''}`,
      }),
    },
    ...restRequest,
  };
};

export const talentPoolParamsMapper = ({
  params: { sortMode, filters, pageNo, pageSize },
  ...restRequest
}: TalentPoolListRequestParams) => {
  return {
    ...restRequest,
    params: {
      pageNo,
      pageSize,
      ...(filters?.name && { searchTerm: filters.name }),
      ...(sortMode?.orderBy && {
        orderBy: `${sortMode.orderBy}${sortMode.orderDir === SortDirections.desc ? ' descending' : ''}`,
      }),
    },
  };
};
