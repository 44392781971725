import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { ExRoutes } from 'router/routes';
import styled from 'styled-components';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import parseISO from 'date-fns/parseISO';

import { ExCommentWidget, VIEW_MODE } from 'model';

import { CandidateCommentCardActionButton } from 'pages/Candidates/Candidate/CandidateDetailsTab/components/CandidateCommentCardActionButton';
import {
  WidgetItem,
  WidgetItemContent,
  WidgetItemContentWrapper,
  WidgetItemMiddle,
  WidgetItemSubTitle,
  WidgetItemTagLine,
  WidgetItemTitle,
  WidgetItemTitleWrapper,
} from 'pages/Dashboard/components/WidgetItem/WidgetItemComponents';

import { ExCommentForm } from 'components/ui/ExComments/ExCommentForm';
import { Comment, CommentEditedHint } from 'components/ui/ExComments/ExCommentItem';
import { CommentDeleteHandler, CommentUpdateHandler } from 'components/ui/ExComments/ExCommentListProps';
import { ExTooltip } from 'components/ui/ExTooltip';
import { ExVisible } from 'components/ui/ExVisible';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { authSelectors } from 'store/auth/auth.selectors';
import { makeCommentTextWithMentionHtml } from 'store/entities/applicant-comments/applicant-comments.utils';

type CommentsWidgetItemProps = {
  className?: string;
  comment: ExCommentWidget;
  editable?: boolean;
  viewMode?: VIEW_MODE;
  activeComment?: ExCommentWidget | null;
  isCommentLink?: boolean;
  commentTarget?: 'JOB' | 'CANDIDATE';
  onViewModeChange?: (viewMode: VIEW_MODE, comment?: ExCommentWidget) => void;
  onEdit?: (comment: ExCommentWidget) => void;
  onCommentDelete?: CommentDeleteHandler;
  onCancel?: () => void;
  onCommentUpdate?: CommentUpdateHandler;
};
type MentionedInProps = { linkToJob: string; applicantName: string | undefined; jobName: string | undefined };

const MentionedIn = ({ linkToJob, applicantName, jobName }: MentionedInProps) => {
  const dispatch = useAppDispatch();
  const onLinkClickHandler: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.button === 2) {
      return;
    }
    if (e.metaKey || e.ctrlKey || e.button === 1) {
      window.open(linkToJob, '_blank', 'noopener,noreferrer');
    } else {
      dispatch(push(linkToJob));
    }
  };

  if (applicantName) {
    return (
      <>
        <span onMouseDown={onLinkClickHandler}>{jobName ?? '--'}</span>
      </>
    );
  }

  return <>{jobName ?? '--'}</>;
};

const useCommentsWidgetItemState = (props: CommentsWidgetItemProps) => {
  const {
    className,
    comment,
    editable,
    viewMode,
    activeComment,
    isCommentLink,
    commentTarget,
    onViewModeChange: handleViewModeChange,
    onCommentDelete: handleCommentDelete,
    onCancel: handleCancel,
    onCommentUpdate: handleCommentUpdate,
  } = props;

  const currentUser = useAppSelector(authSelectors.apiUser);
  const user = comment.mentionedBy || comment.createdBy;
  const date = comment.mentionOn ?? comment.lastModifiedOn ?? comment.createdOn;
  const jobId = comment?.job?.jobId ?? comment.jobId;
  const jobName = comment?.job?.name;
  const applicantName = comment?.applicant?.name;
  const applicantId = comment?.applicant?.applicantId ?? comment.applicantId;
  const candidateId = comment?.candidate?.candidateId ?? comment.candidateId;
  const candidateName = comment?.candidate?.name;
  const isCommentEdited = comment?.lastModifiedOn !== null && comment?.lastModifiedOn !== comment?.createdOn;

  const showMentioned = Boolean(comment.mentionedBy);

  const userName = user.userName;
  const text = makeCommentTextWithMentionHtml(comment.text);
  const formattedDate = date ? formatDistanceToNow(parseISO(date), { addSuffix: true }) : null;

  const getCommentLink = () => {
    if (!!candidateId) return ExRoutes.candidatePage({ candidateId });
    if (!!applicantId) return ExRoutes.jobApplicantsModal({ applicantId, jobId, applicantsViewTab: 'applicants' });
    return ExRoutes.jobComments({ jobId });
  };
  const linkTo = getCommentLink();

  const linkToJob = ExRoutes.jobComments({ jobId });

  const renderContent = () => (
    <WidgetItemContentWrapper>
      <WidgetItemMiddle>
        <ExVisible visible={comment.commentId !== activeComment?.commentId}>
          <ExVisible visible={showMentioned}>
            <WidgetItemTagLine>{`${userName} mentioned you`}</WidgetItemTagLine>
          </ExVisible>
          <WidgetItemTitleWrapper>
            <ExVisible visible={!!isCommentLink}>
              {(!!candidateName || !!applicantName) && (
                <WidgetItemTitle>{`Candidate: ${candidateName ?? applicantName}`}</WidgetItemTitle>
              )}
              {!candidateName && (
                <WidgetItemTitle>
                  {`Job: `}
                  <MentionedIn applicantName={applicantName} jobName={jobName} linkToJob={linkToJob} />
                </WidgetItemTitle>
              )}
            </ExVisible>
            <WidgetItemSubTitle>{`Left by ${userName} ${formattedDate}`}</WidgetItemSubTitle>
          </WidgetItemTitleWrapper>
          <WidgetItemContent>
            <Comment dangerouslySetInnerHTML={{ __html: text ?? '' }} />
            <ExVisible visible={isCommentEdited}>
              <ExTooltip tooltipPosition="bottom" variant="white">
                <CommentEditedHint>Edited</CommentEditedHint>
              </ExTooltip>
            </ExVisible>
          </WidgetItemContent>
        </ExVisible>

        <ExVisible
          visible={!!editable && viewMode === VIEW_MODE.EDIT && comment.commentId === activeComment?.commentId}
        >
          <ExCommentForm
            isEdit
            commentId={comment.commentId}
            text={activeComment?.text}
            commentTarget={commentTarget}
            onCancel={handleCancel}
            onSubmit={handleCommentUpdate}
          />
        </ExVisible>
      </WidgetItemMiddle>

      <ExVisible
        visible={!!editable && viewMode === VIEW_MODE.VIEW && comment.createdBy.userId === currentUser?.userId}
      >
        <CandidateCommentCardActionButtonStyled
          onEdit={() => handleViewModeChange && handleViewModeChange(VIEW_MODE.EDIT, comment)}
          onDelete={() => handleCommentDelete && handleCommentDelete(comment.commentId)}
        />
      </ExVisible>
    </WidgetItemContentWrapper>
  );

  return {
    className,
    linkTo,
    userName,
    formattedDate,
    text,
    showMentioned,
    jobName,
    applicantName,
    linkToJob,
    comment,
    editable,
    viewMode,
    activeComment,
    isCommentLink,
    handleViewModeChange,
    handleCancel,
    handleCommentUpdate,
    handleCommentDelete,
    renderContent,
  } as const;
};

const CandidateCommentCardActionButtonStyled = styled(CandidateCommentCardActionButton)`
  position: absolute;
  top: 10px;
  right: 20px;
`;

export const CommentsWidgetItem: React.FC<CommentsWidgetItemProps> = (props) => {
  const { className, linkTo, isCommentLink, renderContent } = useCommentsWidgetItemState(props);

  return !!isCommentLink ? (
    <WidgetItem className={className} as={Link} to={linkTo}>
      {renderContent()}
    </WidgetItem>
  ) : (
    <WidgetItem className={className}>{renderContent()}</WidgetItem>
  );
};
