import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';

import { adapterHelper } from 'utils/reducer/adapter-helper';

import { EntityKeysNew } from 'store/constants';
import { createActionApiCallBatch } from 'store/entities/qualification-type/qualification-type.model';
import { RootState } from 'store/rootReducer';
import { entitiesDomain } from 'store/rootSelectors';

import type { OnCost } from './onCost.types';

const onCostAdapter = createEntityAdapter<OnCost>();

export const onCostSlice = createSlice({
  name: EntityKeysNew.onCost,
  initialState: onCostAdapter.getInitialState(),
  reducers: {
    ...adapterHelper(onCostAdapter),
  },
});

// SELECTORS -------------------------------------------------------------------
const domain = createSelector(entitiesDomain, (state) => state[onCostSlice.name]);
const onCostAdapterSelectors = onCostAdapter.getSelectors(domain);
const selectByCountryCode = createSelector(
  onCostAdapterSelectors.selectAll,
  (state: RootState, countryCode: string) => countryCode,
  (state, countryCode) => state.find((onCost) => onCost.country?.code === countryCode),
);

const selectDefault = createSelector(onCostAdapterSelectors.selectAll, (state) =>
  state.find((onCost) => onCost.isDefault),
);

const selectByCountryCodeOrDefault = createSelector(
  selectByCountryCode,
  selectDefault,
  (byCountryCode, defaultOnCost) => byCountryCode ?? defaultOnCost,
);
export const onCostSelectors = {
  ...onCostAdapterSelectors,
  selectByCountryCode,
  selectByCountryCodeOrDefault,
  selectDefault,
};

// ACTIONS ---------------------------------------------------------------------
const ON_COST_FETCH = 'on-cost/fetch';
const ON_COST_GET = 'on-cost/get';
const ON_COST_CREATE = 'on-cost/create';
const ON_COST_REMOVE = 'on-cost/remove';
const ON_COST_UPDATE = 'on-cost/update';

const onCostFetch = createActionApiCallBatch(ON_COST_FETCH);
const onCostGET = createActionApiCallBatch<Pick<OnCost, 'onCostId'>>(ON_COST_GET);
const onCostCreate = createActionApiCallBatch(ON_COST_CREATE);
const onCostRemove = createActionApiCallBatch<{ onCostItem: OnCost }>(ON_COST_REMOVE);
const onCostUpdate = createActionApiCallBatch<{ onCostItem: OnCost }>(ON_COST_UPDATE);

export const onCostActions = {
  ...onCostSlice.actions,
  onCostFetch,
  onCostGET,
  onCostCreate,
  onCostRemove,
  onCostUpdate,
};
