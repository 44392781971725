import { createApiMethod } from 'api-endpoints/utils';

import { UserEndpoints } from './user.endpoints';
import * as fromUserModel from './user.model';

//--------------------------------------------------------------------------------------------------
// GetCurrentUserFunc
export const GetCurrentUserFunc = createApiMethod<
  fromUserModel.GetCurrentUserFuncParams,
  fromUserModel.GetCurrentUserFuncResponse
>({
  url: UserEndpoints.GetCurrentUserFunc,
});

//--------------------------------------------------------------------------------------------------
// SearchUsersFunc
export const SearchUsersFunc = createApiMethod<
  fromUserModel.SearchUsersFuncParams,
  fromUserModel.SearchUsersFuncResponse
>({
  url: UserEndpoints.SearchUsersFunc,
});

//--------------------------------------------------------------------------------------------------
// CreateUserFunc
export const CreateUserFunc = createApiMethod<fromUserModel.CreateUserFuncParams, fromUserModel.CreateUserFuncResponse>(
  {
    url: UserEndpoints.CreateUserFunc,
  },
);

//--------------------------------------------------------------------------------------------------
// GetUserFunc
export const GetUserFunc = createApiMethod<fromUserModel.GetUserFuncParams, fromUserModel.GetUserFuncResponse>({
  url: UserEndpoints.GetUserFunc,
});

//--------------------------------------------------------------------------------------------------
// UpdateUserFunc
export const UpdateUserFunc = createApiMethod<fromUserModel.UpdateUserFuncParams, fromUserModel.UpdateUserFuncResponse>(
  {
    url: UserEndpoints.UpdateUserFunc,
  },
);

//--------------------------------------------------------------------------------------------------
// ActivateUserFunc
export const ActivateUserFunc = createApiMethod<
  fromUserModel.ActivateUserFuncParams,
  fromUserModel.ActivateUserFuncResponse
>({
  url: UserEndpoints.ActivateUserFunc,
});

//--------------------------------------------------------------------------------------------------
// InactivateUserFunc
export const InactivateUserFunc = createApiMethod<
  fromUserModel.InactivateUserFuncParams,
  fromUserModel.InactivateUserFuncResponse
>({
  url: UserEndpoints.InactivateUserFunc,
});

export const getAutocompleteUserFunc = createApiMethod<
  fromUserModel.GetAutocompleteUserFuncParams,
  fromUserModel.GetAutocompleteUserFuncResponse
>({
  url: UserEndpoints.GetAutocompleteUsersFunc,
});

//--------------------------------------------------------------------------------------------------

// GetCurrentUserCompaniesFunc
export const getCurrentUserCompaniesFunc = createApiMethod<
  fromUserModel.GetCurrentUserCompaniesFuncParams,
  fromUserModel.GetCurrentUserCompaniesFuncResponse
>({
  url: UserEndpoints.GetCurrentUserCompaniesFunc,
});
//--------------------------------------------------------------------------------------------------
