import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as SortAsc } from 'assets/img/redesign/icons/sort-ascending.svg';
import { ReactComponent as Sort } from 'assets/img/redesign/icons/sort-default.svg';
import { ReactComponent as SortDesc } from 'assets/img/redesign/icons/sort-descending.svg';

import { ExVisible } from 'components/ui/ExVisible';
import { safariFlexBoxTweak } from 'utils/styled/common';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

import { SortDirections } from 'store/constants';

import { ListTableViewHeaderProps } from './ListViewProps';

const columnActionsCss = css`
  border-left-width: 2px;
  width: 30px;
`;

const ListTableViewTableHeader = styled.th<{ isAction: boolean }>`
  ${(props) => props.isAction && columnActionsCss}
`;

const ListTableViewTableItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ListTableViewTableLabel = styled.div`
  font-weight: 600;
  font-size: ${getThemeFontSize('tableHeading')};
`;

const ListTableViewTableSortOrder = styled.div`
  padding: 0 5px;
  margin-right: -10px;
  cursor: pointer;
  user-select: none;
  ${safariFlexBoxTweak};

  svg {
    display: inline-block;
  }
`;

const ListTableViewHeader: React.FC<ListTableViewHeaderProps> = ({
  order,
  sortMode,
  updateSort,
  label,
  className,
  style,
}) => {
  const { orderBy, orderDir } = sortMode;
  const isAction = label === 'Action';
  const isSameOrder = order === orderBy;
  const isOrderAsc = orderDir === SortDirections.asc;

  return (
    <ListTableViewTableHeader className={className} isAction={isAction} style={style}>
      <ListTableViewTableItem className="header-item">
        <ListTableViewTableLabel>{label}</ListTableViewTableLabel>
        <ExVisible visible={!!order}>
          <ExVisible visible={isSameOrder}>
            <ExVisible visible={isOrderAsc}>
              <ListTableViewTableSortOrder className="sort-toggle" onClick={() => updateSort({})}>
                <SortAsc />
              </ListTableViewTableSortOrder>
            </ExVisible>
            <ExVisible visible={!isOrderAsc}>
              <ListTableViewTableSortOrder
                className="sort-toggle"
                onClick={() => updateSort({ orderBy: order as any, orderDir: SortDirections.asc })}
              >
                <SortDesc />
              </ListTableViewTableSortOrder>
            </ExVisible>
          </ExVisible>
          <ExVisible visible={!isSameOrder}>
            <ListTableViewTableSortOrder
              className="sort-toggle"
              onClick={() => updateSort({ orderBy: order as any, orderDir: SortDirections.desc })}
            >
              <Sort />
            </ListTableViewTableSortOrder>
          </ExVisible>
        </ExVisible>
      </ListTableViewTableItem>
    </ListTableViewTableHeader>
  );
};

export const ListTableViewHeaderContextWrapper = ({
  context,
  ...rest
}: Omit<ListTableViewHeaderProps, 'sortMode' | 'updateSort'> & {
  context: React.Context<{ sortMode?: any; updateSort?: any }>;
}) => {
  const { sortMode, updateSort } = useContext(context);

  return <ListTableViewHeader {...rest} sortMode={sortMode} updateSort={updateSort} />;
};

export default styled(ListTableViewHeader)``;
