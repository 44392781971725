import styled, { css, DefaultTheme } from 'styled-components';

import { ReactComponent as CircleCheck } from 'assets/img/circle-check.svg';

const basicStyledCircleStyles = css`
  height: 18px;
  width: 18px;
  margin-right: 14px;
  flex-shrink: 0;
  display: inline-block;
`;

const StyledCircleCheck = styled(CircleCheck)<{ variant: keyof DefaultTheme['colors'] }>`
  ${basicStyledCircleStyles};
  fill: ${props => props.theme.colors[props.variant]};
`;

const StyledCircle = styled.span<{ variant: keyof DefaultTheme['colors'] }>`
  ${basicStyledCircleStyles};
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors[props.variant]};
`;

const CustomCheckboxStyled = styled.span<{ disabled?: boolean }>`
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
`;

export { StyledCircleCheck, StyledCircle, CustomCheckboxStyled };
