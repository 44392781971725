import { AxiosStatic } from 'axios';
import MockAdapter from 'axios-mock-adapter';

import { ExInterview } from 'model';

const interviews: ExInterview[] = [];

function registerMocks(mock: MockAdapter) {
  // This code line handles all requests that not match registered mocks
  // and pass them through

  mock.onGet(/\/applicant\/.+\/interviews/).reply(() => {
    return [
      200,
      {
        items: interviews,
        pageCount: 1,
        pageNo: 0,
        pageSize: 18,
      },
    ];
  });

  mock.onAny().passThrough();
}

export function applyMockAdapter(axios: AxiosStatic) {
  const mock = new MockAdapter(axios, { delayResponse: 500 });

  registerMocks(mock);

  return axios;
}
