import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';

const domain = (state: RootState) => state.formCandidate;

const selectFormData = createSelector(domain, (state) => state.formData);

const selectValidSteps = createSelector(domain, (state) => state.validSteps);

const selectShowSteps = createSelector(domain, (state) => state.showSteps);

const selectReviewStep = createSelector(domain, (state) => ({
  resumes: state.selectedResumes,
  documents: state.selectedDocuments,
}));

export const candidateFormSelectors = {
  domain,
  selectFormData,
  selectValidSteps,
  selectShowSteps,
  selectReviewStep,
};
