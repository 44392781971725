import * as effects from './effects';
import jobListsSlice from './reducers';
import * as selectors from './selectors';

const { reducer, actions } = jobListsSlice;

export const jobListActions = actions;

export const jobListSelectors = selectors;

export const jobListEffects = effects;

export default reducer;

export * from './models';
