import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppFile, ExScreeningQuestion } from 'model';
import { JobApplicantValidationErrorCodes } from 'model/api-errors.constants';

import { PublicState } from 'pages/public/state/rootSelectors';

import { SocialNetwork } from 'store/entities/candidates/models';

import { PublicApplicantForm, PublicUploadTypes } from './public-new-applicant-form.model';

export const publicApplicantFormDefaultParsedData = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  socialNetworks: [
    {
      type: SocialNetwork.LinkedIn,
      url: '',
    },
  ],
};

const publicNewApplicantFormInitialState = () => ({
  [PublicUploadTypes.photo]: null as null | AppFile,
  [PublicUploadTypes.resume]: null as null | AppFile,
  [PublicUploadTypes.document]: null as null | AppFile,
  formData: {
    ...publicApplicantFormDefaultParsedData,
    jobId: '',
    jobApplicantAvailability: null,
    resumeId: null,
    documentId: null,
    screeningQuestions: {},
    note: '',
  } as PublicApplicantForm,
  apiErrors: [] as JobApplicantValidationErrorCodes[],
  processing: false,
  resumeParsing: false,
  jobScreeningQuestions: [] as ExScreeningQuestion[],
});

const publicNewApplicantFormSlice = createSlice({
  name: 'publicNewApplicantForm',
  initialState: publicNewApplicantFormInitialState(),
  reducers: {
    setProcessing: (state, { payload }: PayloadAction<PublicState['publicNewApplicantForm']['processing']>) => {
      state.processing = payload;
    },
    setApiErrors: (state, { payload }: PayloadAction<PublicState['publicNewApplicantForm']['apiErrors']>) => {
      state.apiErrors = payload;
    },
    updateForm: (state, { payload }: PayloadAction<Partial<PublicApplicantForm>>) => {
      state.formData = {
        ...state.formData,
        ...payload,
      };
    },
    resetFormState: () => publicNewApplicantFormInitialState(),
    setUploaded: (state, { payload }: PayloadAction<{ type: PublicUploadTypes; response: any }>) => {
      state[payload.type] = payload.response;
    },
    setJobScreeningQuestions: (state, { payload = [] }) => {
      state.jobScreeningQuestions = payload;
    },
    setResumeParsing: (state, { payload }: PayloadAction<PublicState['publicNewApplicantForm']['resumeParsing']>) => {
      state.resumeParsing = payload;
    },
  },
});

const publicNewApplicantFormReducer = publicNewApplicantFormSlice.reducer;

export { publicNewApplicantFormSlice, publicNewApplicantFormReducer, publicNewApplicantFormInitialState };
