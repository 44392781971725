import React, { useCallback } from 'react';
import styled from 'styled-components';

import { CandidateListComponentProps } from 'containers/CandidateLists/CandidateListProps';
import { CandidatesFilters } from 'containers/CandidateLists/CandidatesFilters';
import { candidateListActions, candidateListSelectors } from 'containers/CandidateLists/store';
import { checkIsFiltersSelected } from 'containers/JobBoardLists/store/selectors';

import { TableSearchInput } from 'components/TableSearchInput';
import { ExFilterButton } from 'components/ui/ExButton/ExFilterButton';
import { ExFilterClearButton } from 'components/ui/ExButton/ExFilterClearButton';
import { ExVisible } from 'components/ui/ExVisible';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useListFiltersModalOpenState } from 'utils/hooks/useListFiltersModalOpenState';
import { useAppSelector } from 'utils/hooks/useSelectors';

const ExFilterClearButtonStyled = styled(ExFilterClearButton)`
  margin-left: 10px;
`;

const ExFilterButtonStyled = styled(ExFilterButton)`
  margin-left: 10px;
`;

const CandidateListFilter: React.FC<CandidateListComponentProps> = ({
  listId,
  className,
  showTalentPoolFilter,
  talentPoolId,
}) => {
  const dispatch = useAppDispatch();

  const { handlerClickFilters, handlerToggleFilters, isFiltersOpen } = useListFiltersModalOpenState();

  const handleClearFilters = () => {
    dispatch(
      candidateListActions.updateFilters({
        id: listId,
        filters: {
          name: undefined,
          locations: [],
          countries: [],
          keywords: [],
          talentPool: [],
          availabilities: [],
          availabilityDate: undefined,
          sources: [],
        },
      }),
    );
  };

  const filters = useAppSelector(candidateListSelectors.getFilters, { listId });

  const isFilterSelected = checkIsFiltersSelected(
    filters,
    ([key, values]) => !['orderBy'].includes(key) && values?.length > 0,
  );

  const tableSearchFieldName = 'name';
  const tableSearchValue = filters[tableSearchFieldName] || '';

  const onTableSearchChange = useCallback(
    (search, fieldName) => {
      dispatch(candidateListActions.updateFilters({ id: listId, filters: { [fieldName]: search } }));
    },
    [dispatch, listId],
  );

  return (
    <div className={className}>
      <TableSearchInput
        name={tableSearchFieldName}
        placeholder="Search Candidates"
        value={tableSearchValue}
        onChange={onTableSearchChange}
        onChangeDelay={500}
        searchHint="Start typing a Candidate Name"
      />
      <ExFilterButtonStyled onClick={handlerClickFilters} />
      <ExVisible visible={isFilterSelected}>
        <ExFilterClearButtonStyled onClick={handleClearFilters} variant="link">
          Clear
        </ExFilterClearButtonStyled>
      </ExVisible>
      <CandidatesFilters
        listId={listId}
        isOpen={isFiltersOpen}
        closeModal={handlerToggleFilters}
        onSubmit={handlerToggleFilters}
        showTalentPoolFilter={showTalentPoolFilter}
        talentPoolId={talentPoolId}
      />
    </div>
  );
};

export default styled(CandidateListFilter)`
  display: flex;

  .list-filter-search {
    width: 205px;

    .applicant-status-select {
      width: 100%;
      margin-bottom: 0;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
`;
