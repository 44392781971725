import { createSelector } from '@reduxjs/toolkit';

import { getCacheListID, initListState } from 'utils/reducer/reducer-helper';

import { UIKeys, ViewMode } from 'store/constants';
import { jobAdSelectors } from 'store/entities/job-ads';
import { RootState } from 'store/rootReducer';
import { uiDomain } from 'store/rootSelectors';

export const domain = createSelector(uiDomain, (state) => state[UIKeys.jobAdLists]);

export const getListById = createSelector(
  domain,
  (_: RootState, props: { id: string }) => props.id,
  (lists, listId) => lists?.[listId] || initListState,
);

export const getFilters = createSelector(getListById, (list) => list?.filters);

export const getSortMode = createSelector(getListById, (list) => list?.sortMode);

export const getJobAds = createSelector(domain, getListById, jobAdSelectors.getLookup, (lists, list, entities) => {
  const cachedList = lists[list.id]?.pagination[getCacheListID(list)];
  return (
    cachedList?.items.reduce(
      (acc: Array<any>, entityId: any) => (entities && entities[entityId] ? [...acc, entities[entityId]] : acc),
      [],
    ) || []
  );
});

export const getRecentJobAds = createSelector(getListById, jobAdSelectors.getLookup, (list, entities) => {
  return (
    list?.items.reduce(
      (acc: Array<any>, entityId: any) => (entities && entities[entityId] ? [...acc, entities[entityId]] : acc),
      [],
    ) || []
  );
});

export const getListParams = createSelector(getListById, (list) => ({
  viewMode: list.viewMode,
  sortMode: list.sortMode,
  pageNo: list.pageNo,
  pageSize: list.pageSize,
  filters: list.filters,
}));

export const getViewMode = createSelector(getListById, (list) => list?.viewMode);

export const getIsTableView = createSelector(getListById, (list) => list?.viewMode === ViewMode.table);

export const getIsGridView = createSelector(getListById, (list) => list?.viewMode === ViewMode.grid);

export const getSelected = createSelector(getListById, (list) => list?.selectedItems ?? []);

export const isFiltersChanged = createSelector(getFilters, (listFilters) => {
  if (!listFilters) {
    return false;
  }

  let result = false;

  if (listFilters.statuses && listFilters.statuses.length > 0) {
    result = true;
  }

  if (listFilters.search && listFilters.search.length > 0) {
    result = true;
  }

  if (listFilters.postedOnRange && listFilters.postedOnRange.length > 0) {
    result = true;
  }

  return result;
});

export const getCanBeSelectedIds = createSelector(getListById, (list) => list?.canBeSelectedIds ?? []);

export const getBelongsTo = createSelector(getFilters, (listFilters) => listFilters?.belongsTo);

export const getSelectMode = createSelector(getListById, (list) => list?.selectMode);

export const getIsSelectable = createSelector(getSelectMode, (selectMode) => selectMode?.selectable ?? false);

export const getIsMultiselect = createSelector(getSelectMode, (selectMode) => selectMode?.multiselect ?? false);
