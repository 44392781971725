import { AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';
import pick from 'lodash/pick';
import { DeepPartial } from 'redux';

import { postJob, postJobAsOpen, putJob, PutJobFields, updateJobStatus } from 'api-endpoints/job';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { jobFormActions } from 'containers/JobForms/state/reducer';
import * as jobFormSelectors from 'containers/JobForms/state/selectors';
import { jobListEffects } from 'containers/JobLists/store';
import { loaderActions } from 'containers/Loader/store';

import { showValidationErrorCodes } from 'store/app-files/apFiles.effects';
import { Job, jobsSelectors } from 'store/entities/jobs';
import { AppEffectParams, AppThunk } from 'store/types';

export const loadSelectorsData = 'loadSelectorsData';
export const saveJobAsDraftProcess = 'saveJobAsDraftProcess';
export const jobSaveProcessing = 'jobSaveProcessing';

export const saveJobAsDraft =
  ({ asOpen }: AppEffectParams<{ asOpen?: boolean }>): AppThunk<Promise<boolean | AxiosResponse['response']>> =>
  async (dispatch, getState) => {
    dispatch(loaderActions.start(saveJobAsDraftProcess));
    const formData = jobFormSelectors.selectFormData(getState());
    let res: AxiosResponse<any>;

    if (formData.jobId) {
      const job = jobsSelectors.getById(getState(), formData.jobId);
      const data = pick<DeepPartial<Job>, PutJobFields>({ ...job, ...formData }, [
        'jobId',
        'jobName',
        'referenceNo',
        'jobLocation',
        'vacancies',
        'deadline',
        'position',
        'countryHiringFrom',
        'employmentType',
        'listOnCareerPage',
        'description',
        'requirements',
        'responsibilities',
      ]);
      res = await putJob({ data, urlParams: { jobId: formData.jobId } });
    } else if (asOpen) {
      res = await postJobAsOpen({ data: formData });
    } else {
      res = await postJob({ data: formData });
    }

    if (!res.message) {
      dispatch(jobListEffects.getJobsCountByStatus('main'));
    }
    if (!res.message && formData.jobId && asOpen) {
      res = await updateJobStatus({ urlParams: { jobId: formData.jobId, status: 'publish' } });
      if (res.message) {
        dispatch(
          alertsEffects.showError({
            message: res.message || 'Error',
          }),
        );
      }
    }
    if (res.message) {
      dispatch(loaderActions.stop(saveJobAsDraftProcess));
      if (res.response?.data.validationErrorCodes) {
        dispatch(jobFormActions.setApiErrors({ apiErrors: res.response?.data?.validationErrorCodes }));
        showValidationErrorCodes(dispatch, res.response?.data.validationErrorCodes, res.message);
      }
      return false;
    }
    const form = {
      ...formData,
      ...res.data,
      ...(res.data.deadline && {
        deadline: parseISO(res.data.deadline).toISOString(),
      }),
    };
    dispatch(jobFormActions.saveForm({ form }));
    dispatch(jobFormActions.toggleIsDraftSavedSteps({ isDraftSaved: true }));
    dispatch(loaderActions.stop(saveJobAsDraftProcess));
    if (!asOpen) {
      dispatch(alertsEffects.showSuccess({ message: 'Job successfully saved' }));
    }
    return true;
  };
