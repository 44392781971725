import { createAction } from '@reduxjs/toolkit';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { SearchAutocomplete } from 'containers/CandidateForm/CandidateFormFormBody/CreateNewCandidateFormBody';

import { createLoader, createRequestTypes } from 'utils/actions';

import * as fromCandidateConstants from './candidate.constants';
import * as fromCandidateModels from './models';
import { candidatesActions } from '.';

//----------------------------------------------------------------------------------------------------------------------
// Upload avatar
const candidateAvatarUploadTypes = createRequestTypes(fromCandidateConstants.CANDIDATE_AVATAR_UPLOAD);
export const candidateAvatarUploadRequest = createAction<fromCandidateModels.CandidateAvatarUploadRequestDto>(
  candidateAvatarUploadTypes.REQUEST,
);
export const candidateAvatarUploadCancel = createAction(candidateAvatarUploadTypes.CANCEL);
//----------------------------------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------------------------------
// Remove avatar
const candidateAvatarRemoveTypes = createRequestTypes(fromCandidateConstants.CANDIDATE_AVATAR_REMOVE);
export const candidateAvatarRemoveRequest = createAction<fromCandidateModels.CandidateAvatarRemoveRequestDto>(
  candidateAvatarRemoveTypes.REQUEST,
);
export const candidateAvatarRemoveCancel = createAction(candidateAvatarRemoveTypes.CANCEL);
export const candidateRemoveAvatarHandler = createLoader(
  {
    success: (_: void, params) => [
      candidatesActions.upsertOne({
        item: { id: params.payload.urlParams.candidateId, photoId: null, photoUrl: null },
      }),
      alertsEffects.showInfo({ message: 'Avatar successfully removed.' }),
    ],
  },
  candidateAvatarRemoveTypes,
);
//----------------------------------------------------------------------------------------------------------------------

//------------------------------------------------------------------------------
// Add to talent pool

export const candidateAddToTalentPool = createAction<{ id: fromCandidateModels.Candidate['id'] }>(
  fromCandidateConstants.CANDIDATE_ADD_TO_TALENT_POOL,
);

//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
// Bulk Add to talent pool

export const bulkAddToTalentPoolAction = createAction(fromCandidateConstants.CANDIDATE_BULK_ADD_TO_TALENT_POOL);

//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
// Create candidate

export const candidateCreateOpenModal = createAction(fromCandidateConstants.CREATE_NEW_CANDIDATE_MODAL);
export const candidateCreate = createAction<
  Partial<fromCandidateModels.Candidate & { talentPools: Array<SearchAutocomplete> }>
>(fromCandidateConstants.CREATE_NEW_CANDIDATE);

//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
// Add To Talent Pool Wizard

export const addToTalentPoolWizardAction = createAction(fromCandidateConstants.ADD_TO_TALENT_POOL_WIZARD);

//------------------------------------------------------------------------------
