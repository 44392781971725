import React from 'react';
import styled from 'styled-components/macro';

import {
  ApplicantWorkExperience,
  ApplicantWorkExperiencePosition,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/ApplicantModalV3Components';
import {
  ApplicantRatingStars,
  useApplicantRating,
  useApplicantWorkExperience,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalStatusInfo/ApplicantModalStatusInfoComponents';

import { Ellipsis } from 'components/Ellipsis';
import { ExVisible } from 'components/ui/ExVisible';

type ApplicantModalStatusInfoEditProps = {
  className?: string;
};

const ApplicantModalStatusInfoEditStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ApplicantModalStatusInfoEdit: React.FC<ApplicantModalStatusInfoEditProps> = ({ className }) => {
  const { applicantRating, ratingChangeClickHandler } = useApplicantRating();
  const { hasWorkExperience, workExperiencePosition, workExperienceCompanyName, dates } = useApplicantWorkExperience();

  return (
    <ApplicantModalStatusInfoEditStyled className={className}>
      <ApplicantRatingStars rating={applicantRating} onChange={ratingChangeClickHandler} />
      <ExVisible visible={hasWorkExperience}>
        <ApplicantWorkExperience>
          <ApplicantWorkExperiencePosition>{workExperiencePosition}</ApplicantWorkExperiencePosition>
          <Ellipsis title={workExperienceCompanyName ?? ''}>{workExperienceCompanyName}</Ellipsis>
          <div>({dates})</div>
        </ApplicantWorkExperience>
      </ExVisible>
    </ApplicantModalStatusInfoEditStyled>
  );
};
