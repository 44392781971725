import styled from 'styled-components';

import { ExCard } from 'components/ui/ExCard';
import { ExCardHeaderStyled } from 'components/ui/ExCard/ExCardComponents';

const CandidateDetailsTabContent = styled.div`
  line-height: 2;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  flex: 1 1 auto;
`;

const CandidateDetailsTabCard = styled(ExCard)<{ empty?: boolean }>`
  ${ExCardHeaderStyled} {
    padding-bottom: ${(props) => (props.empty ? '0' : '20px')};
  }
`;

export const CandidateDetailsTabCardEmptyItem = styled.div``;

export { CandidateDetailsTabContent, CandidateDetailsTabCard };
