import styled from 'styled-components';

import { PageHeader, PageSubTitle, PageTitle } from 'components/PageHeader';

export const StepFormHeaderStyledV2 = styled(PageHeader)`
  padding: 1rem 0;
  margin-bottom: 1.75rem;
  line-height: 1;

  ${PageSubTitle} {
    font-weight: 600;
  }

  ${PageTitle} {
    margin-bottom: 0;
    font-weight: 600;
  }
`;

export const StepFormHeaderStyled = styled(PageHeader)`
  padding: 1rem 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 1.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.light};
  line-height: 1;
  top: 0;
  position: absolute;
  left: 0;
  right: 0;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);

  ${PageSubTitle} {
    font-weight: 600;
  }

  ${PageTitle} {
    margin-bottom: 0;
    font-weight: 600;
  }
`;

export const LogoWrapper = styled.div`
  max-width: 142px;
  max-height: 42px;
  align-self: center;
  display: flex;
  margin-right: 1rem;
`;
export const Logo = styled.img`
  object-fit: contain;
  width: 100%;
`;
export const DividerVertical = styled.div`
  background-color: ${({ theme }) => theme.colors.light};
  width: 1px;
  margin-right: 1rem;
`;
