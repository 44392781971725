import { Action } from 'redux';

import { ThunkActionType } from 'modules/LoaderManager/model';
import { getActionName } from 'modules/LoaderManager/utils';

import { AppDispatch } from 'store/types';

import { loaderManagerActions } from './redux';

export function loaderThunkActionWrapper(action: ThunkActionType): Action<string> {
  const loaderEntityId = getActionName(action);

  return { type: loaderEntityId };
}

export function startLoaderThunk(dispatch: AppDispatch, action: ThunkActionType) {
  const loaderEntityId = getActionName(action);

  dispatch(loaderManagerActions.start(loaderEntityId));
}

export function stopLoaderThunk(dispatch: AppDispatch, action: ThunkActionType) {
  const loaderEntityId = getActionName(action);

  dispatch(loaderManagerActions.stop(loaderEntityId));
}
