import React from 'react';
import styled from 'styled-components/macro';

import { PipelineStageNames } from 'model/api-enums.constants';

import { ExBadge } from 'components/ui/ExBadge';
import { applicantsStatusBadgeVariants } from 'components/ui/ExBadge/config';
import { ExBadgeVariant } from 'components/ui/ExBadge/ExBadgeProps';
import { useBadgeVariants } from 'components/ui/ExBadge/hooks/useJobAdBadgeVariants';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { applicantSelectors } from 'store/entities/applicants';
import { Applicant } from 'store/entities/applicants/models';

const ExBadgeStyled = styled(ExBadge)`
  white-space: nowrap;
`;

interface ApplicantStatusBadgeProps {
  item: Applicant;
  className?: string;
}

const ApplicantStatusBadgePure: React.FC<ApplicantStatusBadgeProps> = ({ item, className }) => {
  const { variant } = useBadgeVariants({
    status: item.stageType,
    variants: applicantsStatusBadgeVariants,
  });
  const badgeText = PipelineStageNames[item.stageType]?.toUpperCase() ?? '';

  return (
    <ExBadge className={className} variant={variant}>
      {badgeText}
    </ExBadge>
  );
};

export const ApplicantStatusBadge = styled(ApplicantStatusBadgePure)`
  text-transform: capitalize;
`;

type ApplicantStatusBadgeDinamicHiringPipelineProps = {
  applicantId: Applicant['applicantId'];
  className?: string;
};

function useApplicantStatusBadgeDinamicHiringPipelineStage({
  applicantId,
  className,
}: ApplicantStatusBadgeDinamicHiringPipelineProps) {
  const applicant = useAppSelector(applicantSelectors.getById, applicantId);

  /**
   * 'primary' color is hardcoded according to the project requirements.
   */
  return {
    pipelineStageName: applicant?.onboardingStatus ?? applicant?.pipelineStageName ?? applicant?.stageName,
    badgeVariant: ExBadgeVariant.primary,
    className,
  };
}

export const ApplicantStatusBadgeDinamicHiringPipeline: React.FC<ApplicantStatusBadgeDinamicHiringPipelineProps> = (
  props,
) => {
  const { className, badgeVariant, pipelineStageName } = useApplicantStatusBadgeDinamicHiringPipelineStage(props);

  return (
    <ExBadgeStyled className={className} variant={badgeVariant} title={pipelineStageName}>
      {pipelineStageName}
    </ExBadgeStyled>
  );
};
