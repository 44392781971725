import React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import styled from 'styled-components';

const FilterCheckboxPure: React.FC<any> = ({ children, className, label }) => {
  const id = React.useMemo(() => nanoid(), []);
  return (
    <div className={className}>
      {React.cloneElement(children, {
        id,
      })}
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
export const FilterCheckbox = styled(FilterCheckboxPure)`
  label {
    padding-left: 22px;
    position: relative;
    user-select: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    line-height: 191.32%;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 6px;
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background-color: ${(props) => props.theme.colors.light};
      border-color: ${(props) => props.theme.colors.light};
    }
  }

  input {
    position: absolute;
    left: -99999px;

    &:checked {
      ~ label {
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 6px;
          width: 5px;
          height: 8px;
          border-radius: 1px;
          border-bottom: 2px solid ${(props) => props.theme.colors.white};
          border-right: 2px solid ${(props) => props.theme.colors.white};
          transform: rotate(45deg) translate3d(5px, -2px, 0);
          transform-origin: center;
        }

        &::before {
          background: ${(props) => props.theme.colors.primary};
          border-color: ${(props) => props.theme.colors.primary};
        }
      }
    }
  }
`;
