import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';

import { AppRouterParams } from 'model/router';

import { Ellipsis } from 'components';
import { CandidateAvatar } from 'components/CandidateAvatar';
import { CandidateNotEditedBadge } from 'components/CandidateSummary/CandidateSummary/components/CandidateNotEditedBadge';
import { TickBox } from 'components/ListView';
import { ExCardHoverable } from 'components/ui/ExCard';
import { ExVisible } from 'components/ui/ExVisible';
import { getUserInitials } from 'utils/getUserInitials';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

import { candidatesSelectors } from 'store/entities/candidates';
import { Candidate } from 'store/entities/candidates/models';

export const CandidateCardTick = styled(TickBox)`
  position: absolute;
  top: 20px;
  left: 15px;
`;

type CandidateCardForTalentPoolProps = {
  candidate: Candidate;
  candidateId?: string;
  selected: boolean;
  handleSelect: React.MouseEventHandler<HTMLDivElement>;
  selectable?: boolean;
  className?: string;
};

const AvatarContainer = styled.div`
  margin: 0 auto;
  width: 60px;
  height: 60px;
`;

const CandidateCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
`;

const CandidateNotEditedBadgeWrapper = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

const CandidateFullName = styled(Link)`
  text-align: center;
  font-size: ${getThemeFontSize('small')};
  font-weight: 700;
  width: 100%;
  max-width: 100%;
  color: inherit;

  &:hover {
    color: inherit;
  }
`;

const CandidateAppliedOnSection = styled.div`
  text-align: center;
  margin-bottom: 0.5rem;
`;

const AppliedOnLabel = styled.p`
  font-style: italic;
  font-size: ${getThemeFontSize('extraSmall')};
  margin-bottom: 0;
`;

const AppliedOnValue = styled.p`
  margin-bottom: 0;
`;

const useCandidateCardForTalentPoolState = ({
  className,
  candidateId,
  selected,
  handleSelect,
  selectable,
}: CandidateCardForTalentPoolProps) => {
  const candidateCard = useAppSelector(candidatesSelectors.selectCandidateTalentPoolCardById, candidateId);

  const { url } = useRouteMatch<AppRouterParams>();
  const modalPath = `${url}/${candidateId}`;
  const isCardSelectable = Boolean(selectable);

  const userInitials = getUserInitials(`${candidateCard?.fullName || ''}`);

  return {
    className,
    isCardSelectable,
    selected,
    handleSelect,
    candidateCard,
    modalPath,
    userInitials,
  };
};

export const CandidateCardForTalentPool: React.FC<CandidateCardForTalentPoolProps> = (props) => {
  const { className, isCardSelectable, modalPath, selected, handleSelect, candidateCard, userInitials } =
    useCandidateCardForTalentPoolState(props);

  return (
    <ExCardHoverable className={className} entity={candidateCard}>
      <ExVisible visible={isCardSelectable}>
        <CandidateCardTick selected={selected} handleSelect={handleSelect} />
      </ExVisible>
      <AvatarContainer>
        <CandidateAvatar src={candidateCard.avatar} initials={userInitials} />
      </AvatarContainer>
      <CandidateCardContent>
        <CandidateFullName to={modalPath}>
          <Ellipsis title={candidateCard.fullName}>{candidateCard.fullName}</Ellipsis>
        </CandidateFullName>
        <CandidateNotEditedBadgeWrapper>
          <ExVisible visible={candidateCard.resumeExtracted}>
            <CandidateNotEditedBadge />
          </ExVisible>
        </CandidateNotEditedBadgeWrapper>
        <CandidateAppliedOnSection>
          <AppliedOnLabel>Added On</AppliedOnLabel>
          <AppliedOnValue>{candidateCard.addedOn}</AppliedOnValue>
        </CandidateAppliedOnSection>
      </CandidateCardContent>
    </ExCardHoverable>
  );
};
