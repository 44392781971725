import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';

import { EntityStateWithPagination, transformListResponse } from 'api-client/axiosBaseQuery';
import { replaceParamsInUrl } from 'api-client/utils';
import { DictionaryEndpointsForApi } from 'api-endpoints/dictionary/endpoints';

import {
  AutocompleteResponse,
  CompanyPosition,
  Country,
  Currency,
  Industry,
  Position,
  PositionDetails,
  Requirement,
  Responsibility,
  Sector,
} from 'model';

import { emptyApi, emptyApiGlobal } from 'store/entities/emptyApi';

export const companyCountryAdapter = createEntityAdapter<Country>({
  selectId: (country) => country.countryId,
});

export const countryAdapter = createEntityAdapter<Country>({
  selectId: (country) => country.countryId,
});

export const currencyAdapter = createEntityAdapter<Currency>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: (currency) => currency.currency,
  // Keep the "all IDs" array sorted based on book titles
  sortComparer: (a, b) => a.currencyFullName.localeCompare(b.currencyFullName),
});

export const industryAdapter = createEntityAdapter<Industry>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: (industry) => industry.industryId,
  // Keep the "all IDs" array sorted based on book titles
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const companyIndustryAdapter = createEntityAdapter<Industry>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: (industry) => industry.industryId,
  // Keep the "all IDs" array sorted based on book titles
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const sectorAdapter = createEntityAdapter<Sector>({
  selectId: (sector) => sector.id,
});

export const positionCategoriesAdapter = createEntityAdapter<AutocompleteResponse>();

export const companyPositionCategoriesAdapter = createEntityAdapter<AutocompleteResponse>();

export const positionAdapter = createEntityAdapter<Position>();

export const companyPositionAdapter = createEntityAdapter<CompanyPosition>({
  selectId: (position) => position.positionId,
  sortComparer: (a, b) => a.positionName.localeCompare(b.positionName),
});

export type PositionArgs = {
  categoryId?: string;
};

export type CompanyPositionArgs = {
  categoryId?: string;
  searchTerm?: string;
  pageNo?: number;
};

export type CompanyPositionAbilityArgs = {
  positionId: string;
};

const MAX_SECONDS = 0b1111111111111111111111111111111 / 1000; // 2^31 - 1 in seconds because of setTimeout limitation

const extendedDictionaryApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    companyCountry: builder.query<EntityState<Country>, void>({
      query: () => ({
        url: replaceParamsInUrl(DictionaryEndpointsForApi.SearchCountriesForCompanyFunc, {}),
        method: 'GET',
      }),
      transformResponse(data: Country[]) {
        return companyCountryAdapter.setAll(companyCountryAdapter.getInitialState(), data);
      },
      keepUnusedDataFor: MAX_SECONDS,
    }),
    companyIndustry: builder.query<EntityState<Industry>, void>({
      query: () => ({
        url: replaceParamsInUrl(DictionaryEndpointsForApi.SearchIndustriesForCompanyFunc, {}),
        method: 'GET',
      }),
      transformResponse(data: Industry[]) {
        return companyIndustryAdapter.setAll(companyIndustryAdapter.getInitialState(), data);
      },
      keepUnusedDataFor: MAX_SECONDS,
    }),
    companyPositionCategories: builder.query<EntityState<AutocompleteResponse>, void>({
      query: () => ({
        url: replaceParamsInUrl(DictionaryEndpointsForApi.GetCompanyPositionCategoriesFunc, {}),
        method: 'GET',
      }),
      transformResponse(data: AutocompleteResponse[]) {
        return companyPositionCategoriesAdapter.setAll(companyPositionCategoriesAdapter.getInitialState(), data);
      },
      keepUnusedDataFor: MAX_SECONDS,
    }),
    companyPosition: builder.query<EntityStateWithPagination<CompanyPosition>, CompanyPositionArgs | undefined>({
      query: (args) => ({
        method: 'GET',
        url: replaceParamsInUrl(DictionaryEndpointsForApi.GetCompanyPositionsFunc, {}),
        params: { ...args },
      }),
      transformResponse: transformListResponse(companyPositionAdapter),
      keepUnusedDataFor: MAX_SECONDS,
    }),
    companyPositionLoadMore: builder.query<EntityStateWithPagination<CompanyPosition>, CompanyPositionArgs | undefined>(
      {
        query: (args) => ({
          url: replaceParamsInUrl(DictionaryEndpointsForApi.GetCompanyPositionsFunc, {}),
          method: 'GET',
          params: { ...args },
        }),
        onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
          try {
            const { data } = await queryFulfilled;

            dispatch(
              extendedDictionaryApi.util.updateQueryData(
                'companyPositionLoadMore',
                { ...arg, pageNo: 0, searchTerm: '' },
                (draft) => {
                  companyPositionAdapter.upsertMany(
                    draft,
                    data.ids.map((id) => data.entities[id]!),
                  );
                  draft.pageCount = data.pageCount;
                  draft.pageNo = data.pageNo;
                },
              ),
            );
          } catch (error) {}
        },
        transformResponse: transformListResponse(companyPositionAdapter),
        keepUnusedDataFor: MAX_SECONDS,
      },
    ),
    companyPositionRequirements: builder.query<Requirement[], CompanyPositionAbilityArgs>({
      query: (args) => ({
        url: replaceParamsInUrl(DictionaryEndpointsForApi.GetPositionRequirementsFunc, {
          positionId: args.positionId,
        }),
        method: 'GET',
      }),
    }),
    companyPositionResponsibilities: builder.query<Responsibility[], CompanyPositionAbilityArgs>({
      query: (args) => ({
        url: replaceParamsInUrl(DictionaryEndpointsForApi.GetPositionResponsibilitiesFunc, {
          positionId: args.positionId,
        }),
        method: 'GET',
      }),
    }),
    companyGetPositionDetails: builder.query<PositionDetails, CompanyPositionAbilityArgs>({
      query: (args) => ({
        url: replaceParamsInUrl(DictionaryEndpointsForApi.GetPositionDetailsFunc, {
          positionId: args.positionId,
        }),
        method: 'GET',
      }),
    }),
  }),
});

const extendedDictionaryApiGlobal = emptyApiGlobal.injectEndpoints({
  endpoints: (builder) => ({
    country: builder.query<EntityState<Country>, void>({
      query: () => ({
        url: replaceParamsInUrl(DictionaryEndpointsForApi.SearchCountriesFunc, {}),
        method: 'GET',
      }),
      transformResponse(data: Country[]) {
        return countryAdapter.setAll(countryAdapter.getInitialState(), data);
      },
      keepUnusedDataFor: MAX_SECONDS,
    }),
    currency: builder.query<EntityState<Currency>, void>({
      query: () => ({
        url: replaceParamsInUrl(DictionaryEndpointsForApi.SearchCurrenciesFunc, {}),
        method: 'GET',
      }),
      transformResponse(data: Currency[]) {
        return currencyAdapter.setAll(currencyAdapter.getInitialState(), data);
      },
      keepUnusedDataFor: MAX_SECONDS,
    }),
    industry: builder.query<EntityState<Industry>, void>({
      query: () => ({
        url: replaceParamsInUrl(DictionaryEndpointsForApi.SearchIndustriesFunc, {}),
        method: 'GET',
      }),
      transformResponse(data: Industry[]) {
        return industryAdapter.setAll(industryAdapter.getInitialState(), data);
      },
      keepUnusedDataFor: MAX_SECONDS,
    }),
    sector: builder.query<EntityState<Sector>, void>({
      query: () => ({
        url: replaceParamsInUrl(DictionaryEndpointsForApi.SearchSectorsFunc, {}),
        method: 'GET',
      }),
      transformResponse(data: Sector[]) {
        return sectorAdapter.setAll(sectorAdapter.getInitialState(), data);
      },
      keepUnusedDataFor: MAX_SECONDS,
    }),
    positionCategories: builder.query<EntityState<AutocompleteResponse>, void>({
      query: () => ({
        url: replaceParamsInUrl(DictionaryEndpointsForApi.SearchCategoriesFunc, {}),
        method: 'GET',
      }),
      transformResponse(data: AutocompleteResponse[]) {
        return positionCategoriesAdapter.setAll(positionCategoriesAdapter.getInitialState(), data);
      },
      keepUnusedDataFor: MAX_SECONDS,
    }),
    position: builder.query<EntityState<Position>, PositionArgs | undefined>({
      query: (args) => ({
        url: replaceParamsInUrl(DictionaryEndpointsForApi.SearchPositionsFunc, {}),
        method: 'GET',
        params: { ...args },
      }),
      transformResponse(data: Position[]) {
        return positionAdapter.setAll(positionAdapter.getInitialState(), data);
      },
      keepUnusedDataFor: MAX_SECONDS,
    }),
    experience: builder.query<Array<{ id: string; name: string }>, void>({
      query: () => ({
        url: replaceParamsInUrl(DictionaryEndpointsForApi.SearchExperienceFunc, {}),
        method: 'GET',
      }),
      keepUnusedDataFor: MAX_SECONDS,
    }),
    education: builder.query<Array<{ id: string; name: string }>, void>({
      query: () => ({
        url: replaceParamsInUrl(DictionaryEndpointsForApi.SearchEducationFunc, {}),
        method: 'GET',
      }),
      keepUnusedDataFor: MAX_SECONDS,
    }),
  }),
});

export const enhancedDictionaryApi = extendedDictionaryApi.enhanceEndpoints({
  addTagTypes: ['CompanyCountry', 'CompanyIndustry', 'CompanyPositionCategories', 'CompanyPosition'],
  endpoints: {
    companyCountry: {
      providesTags: () => [{ type: 'CompanyCountry', id: 'LIST' }],
    },
    companyIndustry: {
      providesTags: () => [{ type: 'CompanyIndustry', id: 'LIST' }],
    },
    companyPositionCategories: {
      providesTags: () => [{ type: 'CompanyPositionCategories', id: 'LIST' }],
    },
    companyPosition: {
      providesTags: () => [{ type: 'CompanyPosition', id: 'LIST' }],
    },
  },
});

export const enhancedDictionaryApiGlobal = extendedDictionaryApiGlobal.enhanceEndpoints({
  addTagTypes: [
    'Country',
    'Currency',
    'Industry',
    'Sector',
    'PositionCategories',
    'Position',
    'Experience',
    'Education',
  ],
  endpoints: {
    country: {
      providesTags: () => [{ type: 'Country', id: 'LIST' }],
    },
    currency: {
      providesTags: () => [{ type: 'Currency', id: 'LIST' }],
    },
    industry: {
      providesTags: () => [{ type: 'Industry', id: 'LIST' }],
    },
    sector: {
      providesTags: () => [{ type: 'Sector', id: 'LIST' }],
    },
    positionCategories: {
      providesTags: () => [{ type: 'PositionCategories', id: 'LIST' }],
    },
    position: {
      providesTags: () => [{ type: 'Position', id: 'LIST' }],
    },
    experience: {
      providesTags: () => [{ type: 'Experience', id: 'LIST' }],
    },
    education: {
      providesTags: () => [{ type: 'Education', id: 'LIST' }],
    },
  },
});

export const {
  useCompanyCountryQuery,
  useLazyCompanyCountryQuery,
  useCompanyIndustryQuery,
  useLazyCompanyIndustryQuery,
  useCompanyPositionCategoriesQuery,
  useCompanyPositionQuery,
  useCompanyPositionLoadMoreQuery,
  useLazyCompanyPositionLoadMoreQuery,
  useCompanyPositionRequirementsQuery,
  useCompanyPositionResponsibilitiesQuery,
  useCompanyGetPositionDetailsQuery,
} = enhancedDictionaryApi;

export const {
  useCountryQuery,
  useCurrencyQuery,
  useIndustryQuery,
  useSectorQuery,
  usePositionCategoriesQuery,
  usePositionQuery,
  useExperienceQuery,
  useEducationQuery,
} = enhancedDictionaryApiGlobal;
