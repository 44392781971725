import React from 'react';
import styled from 'styled-components';

import { ModalBodyStyled } from 'containers/Modals/ModalsContent/Wizard';

import { ComponentSpinner } from 'components/Spinner';
import { ExColumn, ExRow } from 'components/ui/ExLayout';
import { ExProgress } from 'components/ui/ExProgress';

import { ModalStepProps } from 'store/modals/config';

const BulkUploadingText = styled.div``;

export const BulkUploading: React.FC<ModalStepProps> = ({ modalProps }) => {
  const { uploadValue = 0, uploadText = '', isUploadComplete = false } = modalProps ?? {};

  return (
    <ModalBodyStyled>
      <ExRow>
        <ExColumn>{isUploadComplete ? <ComponentSpinner /> : <ExProgress value={uploadValue} />}</ExColumn>
        <ExColumn>
          <BulkUploadingText>{uploadText}</BulkUploadingText>
        </ExColumn>
      </ExRow>
    </ModalBodyStyled>
  );
};
