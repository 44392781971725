import { createSelector } from '@reduxjs/toolkit';

import { UIKeys } from 'store/constants';
import { uiDomain } from 'store/rootSelectors';

export const domain = createSelector(uiDomain, (state) => state[UIKeys.loader]);

export const hasSomeActiveTask = createSelector(domain, (state) => !!state.filter((task) => !task.silent).length);
export const isTaskActive = createSelector(
  domain,
  (_: any, { taskId }: { taskId: string }) => taskId,
  (state, taskId) => !!state.find((task) => task.id === taskId),
);

export const isTaskActiveMake = (taskId: string) =>
  createSelector(domain, (state) => !!state.find((task) => task.id === taskId));

export const hasSimilarTaskActive = createSelector(
  domain,
  (_: any, { taskId }: { taskId: string }) => taskId,
  (state, taskId) => state.some((task) => task.id.includes(taskId)),
);
