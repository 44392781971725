import React, { useEffect } from 'react';
import styled from 'styled-components';

import { PageHeaderProps } from 'components/PageHeader/PageHeaderProps';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { setDocumentTitle } from 'store/ui/document-meta';

const PageHeaderPure: React.FC<PageHeaderProps> = ({ pageTitle, className, children }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setDocumentTitle({ title: pageTitle }));
    return () => {
      dispatch(setDocumentTitle({}));
    };
  }, [dispatch, pageTitle]);

  if (!children) {
    return null;
  }

  return <div className={className}>{children}</div>;
};

export const PageHeader = styled(PageHeaderPure)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-shrink: 0;

  @media (max-width: 991.98px) {
    padding-left: 20px;
  }
`;

export const StepFormPageHeader = styled(PageHeader)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
  position: sticky;
  top: 0;
  padding: 12px 20px;
  z-index: 1;
  margin: -24px -24px 1.75rem;
  align-items: center;
`;
