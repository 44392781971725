import * as apiCandidate from 'api-endpoints/candidate';
import { CandidateItemResponse, PostCandidateParams } from 'api-endpoints/candidate/models';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { SearchAutocomplete } from 'containers/CandidateForm/CandidateFormFormBody/CreateNewCandidateFormBody';
import { candidateFormSelectors } from 'containers/CandidateForm/CandidateFormState/candidate.selectors';
import { candidateFormActions } from 'containers/CandidateForm/CandidateFormState/candidate-form.reducer';
import { loaderActions } from 'containers/Loader/store';
import { talentPoolFormEffects } from 'containers/TalentPoolForms/state';

import { startLoaderThunk, stopLoaderThunk } from 'modules/LoaderManager/redux';
import { getTranslate } from 'utils/i18utils';

import { Candidate } from 'store/entities/candidates/models';
import { AppEffectParams, AppThunk } from 'store/types';

export const candidateSaveProcessing = 'candidateSaveProcessing';

export const saveCandidate =
  ({
    preloader,
    data,
  }: AppEffectParams<{
    data: Partial<Candidate & { talentPools: Array<SearchAutocomplete> }>;
  }>): AppThunk<Promise<boolean | CandidateItemResponse>> =>
  async (dispatch, getState) => {
    const formData = candidateFormSelectors.selectFormData(getState());
    if (preloader) {
      startLoaderThunk(dispatch, saveCandidate);
      dispatch(loaderActions.start(candidateSaveProcessing));
    }
    const postData = {
      ...formData,
      ...data,
    } as PostCandidateParams;
    const {
      message,
      data: storedData,
      response,
    } = await apiCandidate.saveCandidate({
      data: postData,
    });
    if (preloader) {
      dispatch(loaderActions.stop(candidateSaveProcessing));
      stopLoaderThunk(dispatch, saveCandidate);
    }
    if (message) {
      if (response?.data.validationErrorCodes) {
        dispatch(candidateFormActions.setApiErrors({ apiErrors: response?.data?.validationErrorCodes }));
        response?.data.validationErrorCodes.forEach((validationErrorCode) => {
          const errorMessageOnUserLanguage = getTranslate(validationErrorCode, { ns: 'validationErrorCodes' });
          dispatch(
            alertsEffects.showError({
              message: errorMessageOnUserLanguage || message || 'Error',
            }),
          );
        });
      } else {
        dispatch(alertsEffects.showError({ message }));
      }
      return false;
    } else {
      const talentPoolIds = data?.talentPools?.length
        ? data.talentPools.filter((tp) => tp.id !== 'General').map((tp) => tp.id)
        : [];
      if (talentPoolIds.length && 'candidateId' in storedData) {
        await dispatch(
          talentPoolFormEffects.addCandidateToTalentPools({
            candidateId: storedData.candidateId,
            preloader: true,
            talentPoolIds,
          }),
        );
      }
      const customMessage = `Candidate successfully created`;
      dispatch(alertsEffects.showSuccess({ message: customMessage }));
      dispatch(candidateFormActions.saveForm({ formData: { ...postData, ...storedData } }));
      return storedData;
    }
  };

export const updateCandidate =
  ({ data }: AppEffectParams<{ data?: Partial<Candidate> }>): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    startLoaderThunk(dispatch, updateCandidate);
    const formData = candidateFormSelectors.selectFormData(getState());

    const putDataUnfiltered = {
      ...formData,
      ...data,
    } as apiCandidate.PutCandidateParams;

    const putData = {
      ...putDataUnfiltered,
      candidateAvailability:
        putDataUnfiltered.candidateAvailability?.availabilityType === null
          ? undefined
          : putDataUnfiltered.candidateAvailability,
      socialNetworks: putDataUnfiltered.socialNetworks?.filter((socialNetwork) => Boolean(socialNetwork.url)),
    };

    const { message, data: storedData } = await apiCandidate.putCandidate(putData);
    if (message) {
      dispatch(alertsEffects.showError({ message }));
    } else {
      const successMessage = 'Candidate was successfully updated.';
      dispatch(alertsEffects.showSuccess({ message: successMessage }));
      dispatch(candidateFormActions.saveForm({ formData: { ...putData, ...storedData } }));
    }
    stopLoaderThunk(dispatch, updateCandidate);

    return !message;
  };

saveCandidate.processing = candidateSaveProcessing;
