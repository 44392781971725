import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components/macro';

import { ExButton } from 'components/ui/ExButton';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { isTouchDeviceCheck } from 'utils/isTouchDevice';

import { useSelector } from 'store/rootSelectors';
import { tipsActions, tipsSelectors } from 'store/ui/tips';

type ApplicantModalTipProps = {
  className?: string;
  show?: boolean;
};

const TRANSITION_DURATION_MS = 200;

const showApplicantModalTipStyled = ({ show }: ApplicantModalTipProps) =>
  show &&
  css`
    transform: translateX(-50%) translateY(0%);
  `;

const ApplicantModalTipStyled = styled.div<ApplicantModalTipProps>`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  padding: 10px 18px;
  background: #fff;
  border: 1px solid #eee;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.06);
  font-size: 12px;
  line-height: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: transform ${TRANSITION_DURATION_MS}ms ease-in-out;
  ${showApplicantModalTipStyled}
`;

const TipText = styled.div`
  white-space: nowrap;
`;

const TipTextTitle = styled.strong`
  margin-right: 4px;
`;

const TipButton = styled(ExButton)`
  flex-shrink: 0;
  margin-left: 20px;
  white-space: nowrap;
`;

const useApplicantModalTipState = ({ className }: ApplicantModalTipProps) => {
  const dispatch = useAppDispatch();

  const isApplicantModalKeyboardTipShow = useSelector(tipsSelectors.selectIsApplicantModalKeyboardTipShow);
  const [isShow, setShow] = useState(isApplicantModalKeyboardTipShow);

  const isTouchDevice = isTouchDeviceCheck();
  const showApplicantModalTip = !isTouchDevice && isApplicantModalKeyboardTipShow;

  const clickHandler = useCallback(() => {
    setShow(false);

    setTimeout(() => {
      dispatch(tipsActions.updateTips({ applicantModalKeyboard: false }));
    }, TRANSITION_DURATION_MS);
  }, [dispatch]);

  return {
    className,
    isShow,
    clickHandler,
    showApplicantModalTip,
  } as const;
};

export const ApplicantModalTip: React.FC<ApplicantModalTipProps> = (props) => {
  const { className, isShow, clickHandler, showApplicantModalTip } = useApplicantModalTipState(props);

  if (!showApplicantModalTip) {
    return null;
  }

  return (
    <ApplicantModalTipStyled className={className} show={isShow}>
      <TipText>
        <TipTextTitle>Pro tip:</TipTextTitle>
        Press{' '}
        <strong>
          <i>Y</i>
        </strong>{' '}
        to move applicant to the pipeline and{' '}
        <strong>
          <i>N</i>
        </strong>{' '}
        to decline. Press{' '}
        <strong>
          <i>&lt;</i>
        </strong>{' '}
        or{' '}
        <strong>
          <i>&gt;</i>
        </strong>{' '}
        to move browse Prev/Next Applicant.
      </TipText>
      <TipButton variant="light" size="sm" onClick={clickHandler}>
        Got it!
      </TipButton>
    </ApplicantModalTipStyled>
  );
};
