import { dateFormat } from 'config/date';

/// <summary>Represents a sets of available types of an attachment.</summary>
export enum AttachmentType {
  /// <summary>A value that indicates that an attachment is CV.</summary>
  Cv = 1,
  /// <summary>A value that indicates that an attachment is a letter.</summary>
  Letter,
  /// <summary>A value that indicates that an attachment is a document.</summary>
  Document,
}

/// <summary>Represents a sets of available types of an attachment.</summary>
export enum AvailabilityType {
  /// <summary>A value that indicates that a candidate/applicant will be available by the actual date.</summary>
  ActualDate,
  /// <summary>A value that indicates that a candidate/applicant will be available immediately.</summary>
  Immediate,
  /// <summary>A value that indicates that a candidate/applicant will be available in one week.</summary>
  InOneWeek,
  /// <summary>A value that indicates that a candidate/applicant will be available in two weeks.</summary>
  InTwoWeeks,
  /// <summary>A value that indicates that a candidate/applicant will be available in four weeks.</summary>
  InFourWeeks,
}

/// <summary>Represents a set of available social networks for a candidate profile.</summary>
export enum CandidateSocialNetworkType {
  /// <summary>A value that indicates that a social network is Facebook.</summary>
  Facebook = 1,
  /// <summary>A value that indicates that a social network is Twitter.</summary>
  Twitter,
  /// <summary>A value that indicates that a social network is LinkedId.</summary>
  LinkedIn,
}

/// <summary>Represents a set of sources where a candidate is gotten from.</summary>
export enum CandidateSource {
  /// <summary>A value that indicates that a candidate is gotten from other sources.</summary>
  Other,
  /// <summary>A value that indicates that a candidate is gotten from job boards.</summary>
  JobBoards,
  /// <summary>A value that indicates that a candidate is gotten from a talent pool.</summary>
  TalentPool,
  /// <summary>A value that indicates that a candidate is gotten from a website.</summary>
  Website,
  /// <summary>A value that indicates that a candidate is gotten from social media.</summary>
  SocialMedia,
}

/// <summary>Represents a set of available action for a check list item.</summary>
export enum CheckListItemAction {
  /// <summary>A value that indicates that they selected an item.</summary>
  Checked = 1,

  /// <summary>A value that indicates that they unselected an item.</summary>
  Unchecked = 2,

  /// <summary>A value that indicates that they added an item.</summary>
  Added = 3,

  /// <summary>A value that indicates that they removed an item.</summary>
  Removed = 4,
}

/// <summary>Represents a sets of available types of an employee.</summary>
export enum EmploymentType {
  /// <summary>A value that it is an permanent full-time employee.</summary>
  PermanentFullTime = 1,
  /// <summary>A value that it is an permanent part-time employee.</summary>
  PermanentPartTime = 2,
  ///
  Casual = 3,
  ///
  Contract = 4,
}

const EmploymentTypeNames: Record<EmploymentType, string> = {
  [EmploymentType.PermanentFullTime]: 'Full time',
  [EmploymentType.PermanentPartTime]: 'Part time',
  [EmploymentType.Casual]: 'Casual',
  [EmploymentType.Contract]: 'Contract',
} as const;

Object.freeze(EmploymentTypeNames);

export { EmploymentTypeNames };

/// <summary>
/// Represents sets of supported exporting file formats
/// </summary>
export enum ExportType {
  /// <summary>
  /// A value indicate reporting file format - Microsoft Excel file
  /// </summary>
  MicrosoftExcel,
  /// <summary>
  /// A value indicate reporting file format - Adobe PDF file
  /// </summary>
  AdobePdf,
}

/// <summary>Gets/sets a set of available frequencies which a salary can be paid.</summary>
export enum Frequency {
  /// <summary>A value that indicates that a salary is paid with an hourly frequency.</summary>
  Hourly = 1,
  /// <summary>A value that indicates that a salary is paid with an daily frequency.</summary>
  Daily,
  /// <summary>A value that indicates that a salary is paid with an weekly frequency.</summary>
  Weekly,
  /// <summary>A value that indicates that a salary is paid with an monthly frequency.</summary>
  Monthly,
  /// <summary>A value that indicates that a salary is paid with an annually frequency.</summary>
  Annually,
}

const FrequencyNames: Record<Frequency, string> = {
  [Frequency.Hourly]: 'Hour',
  [Frequency.Daily]: 'Day',
  [Frequency.Weekly]: 'Week',
  [Frequency.Monthly]: 'Month',
  [Frequency.Annually]: 'Annum',
} as const;

Object.freeze(FrequencyNames);

export { FrequencyNames };

/// <summary>Represents a set of available statuses of a job ad.</summary>
export enum JobAdStatus {
  /// <summary>A value that indicates that an ad is auto saved by the system or saved as draft by the user, and hasn’t been posted to an integrated job board(s).</summary>
  Draft = 0,
  /// <summary>A value that indicates that an ad is published to the integration system and it waits an response..</summary>
  Pending = 1,
  /// <summary>A value that indicates that an ad is already published to an integrated job board(s) and its expiry date > today.</summary>
  Active = 2,
  /// <summary>A value that indicates that it is an inactive job.</summary>
  Inactive = 3,
  /// <summary>A value that indicates that there is an error in the response from the integration.</summary>
  Error = 4,
  /// <summary>A value that indicates that an ad is already published to an integrated job board(s) and its expiry date < today..</summary>
  Expired = 5,
  /// <summary>A value that indicates that an ad is closed.</summary>
  Closed = 6,
  /// <summary>A value that indicates that an ad is open.</summary>
  Open = 7,
  /// <summary>A value which indicates that an ad is in the process of being stopped from the integration system.</summary>
  Stopping = 8,
  /// <summary>A value which indicates that an ad is stopped.</summary>
  Stopped = 9,
  /// <summary>A value which indicates that an ad is cancelled or lost on Idibu's end.</summary>
  CancelledBySystem = 10,
}

/// <summary>
/// Gets/sets job applicant status / pipeline stage
/// </summary>
export enum JobApplicantFilterStatus {
  /// <summary>
  /// A value that indicates the applicant in New status
  /// </summary>
  New = 0,
  /// <summary>
  /// A value that indicates the applicant in Declined status
  /// </summary>
  Declined = 6,
  /// <summary>
  /// A value that indicates the applicant in Withdrawn status
  /// </summary>
  Withdrawn = 7,
}

/// <summary>Represents a set of available statuses of a job.</summary>
export enum JobStatus {
  /// <summary>A value that indicates that they just created a job.</summary>
  draft,
  /// <summary>A value that indicates that it is an open job.</summary>
  open,
  /// <summary>A value that indicates that it is a cancelled job.</summary>
  cancelled,
  /// <summary>A value that indicates that it is a filled job.</summary>
  filled,
}

export enum EnumJobActivityType {
  open,
  expiringSoon,
  recentlyExpired,
  ongoing,
}

/// <summary>Represents a sets of available location sources.</summary>
export enum LocationSource {
  /// <summary>A value that it is gathered from Idibu.</summary>
  Idibu = 1,
}

/// <summary>Represents a set of available directions of an order.</summary>
export enum OrderDirection {
  /// <summary>A value that represents a ascending direction.</summary>
  Ascending = 1,
  /// <summary>A value that represents a descending direction.</summary>
  Descending = 2,
}

export enum PipelineStageType {
  New = 0,
  Shortlisted = 1,
  Interviewed = 2,
  Screened = 3,
  InOnboarding = 4,
  Hired = 5,
  Declined = 6,
  Withdrawn = 7,
  Custom = 8,
  Negotiated = 9,
  ReadyToOnboard = 10,
}

const PipelineStageNames: Record<PipelineStageType, string> = {
  [PipelineStageType.New]: 'New',
  [PipelineStageType.Shortlisted]: 'Shortlisted',
  [PipelineStageType.Interviewed]: 'Interviewed',
  [PipelineStageType.Screened]: 'Screened',
  [PipelineStageType.Negotiated]: 'Negotiated',
  [PipelineStageType.ReadyToOnboard]: 'Ready to Onboard',
  [PipelineStageType.InOnboarding]: 'In Onboarding',
  [PipelineStageType.Hired]: 'Hired',
  [PipelineStageType.Declined]: 'Declined',
  [PipelineStageType.Withdrawn]: 'Withdrawn',
  [PipelineStageType.Custom]: 'Custom',
} as const;

Object.freeze(PipelineStageNames);

export { PipelineStageNames };

export enum PlacementApplicantStage {
  All = 0,
  Hired = 1,
  ReadyToOnboard = 2,
  InOnboarding = 3,
}

export enum SendStatus {
  /// <summary>A value that indicates that a message has no status.</summary>
  None,
  /// <summary>A value that indicates that a message was tried to send with an error.</summary>
  Failure,
  /// <summary>A value that indicates that a message has been sent successfully.</summary>
  Success,
}

/// <summary>Represents a set of available types for a template.</summary>
export enum TemplateType {
  /// <summary>A value that indicates that a template is an email.</summary>
  Email = 1,
  /// <summary>A value that indicates that a template is an SMS.</summary>
  Sms = 2,
  /// <summary>A value that indicates that a template is a letter.</summary>
  Letter = 3,
}

/// <summary>Represents a set of available orders for candidate attachments.</summary>
export enum CandidateAttachmentOrderProperty {
  /// <summary>A value that represents an order of candidate attachments by the filename property.</summary>
  Filename = 1,
  /// <summary>A value that represents an order of candidate attachments by the actor property.</summary>
  Actor,
  /// <summary>A value that represents an order of candidate attachments by the filetype property.</summary>
  Filetype,
  /// <summary>A value that represents an order of candidate attachments by the uploaded date property.</summary>
  UploadedDate,
}

export enum CandidateJobApplicantOrderProperty {
  JobName = 1,
  Deadline = 2,
}

/// <summary>Represents a set of properties to order a collection of candidates.</summary>
export enum CandidateOrderProperty {
  /// <summary>A value that indicates that it orders a collection of candidate by the name property.</summary>
  Name = 1,
}

/// <summary>Represents a set of properties it can order by.</summary>
export enum JobAdsForJobBoardOrderProperty {
  /// <summary>A value that represents the order by the job board name property.</summary>
  JobBoardName,
  /// <summary>A value that represents the order by the applicants number property.</summary>
  Applicants,
  /// <summary>A value that represents the order by the expiry date property.</summary>
  ExpiryDate,
  /// <summary>A value that represents the order by the status property.</summary>
  Status,
}

/// <summary>Represents a set of available properties to order companies.</summary>
export enum CompanyOrderProperty {
  /// <summary>A value that indicates that it orders companies by the name property.</summary>
  Name = 1,
  /// <summary>A value that indicates that it orders companies by the country property.</summary>
  Country = 2,
}

export enum CandidateWorkExperienceOrderProperty {
  /// <summary>
  /// A value that represents an order of candidate education by the name property.
  /// </summary>
  Position = 1,

  /// <summary>
  /// A value that represents an order of candidate education by the start date property.
  /// </summary>
  StartDate = 2,

  /// <summary>
  /// A value that represents an order of candidate education by the end date property.
  /// </summary>
  EndDate = 3,

  /// <summary>
  /// A value that represents an order of candidate education by advanced algorythm based on start date property
  /// </summary>
  AdvancedStartDate = 4,
}

/**
 * public enum CompanyDateFormat : byte
 * {
 *    <summary>A value that indicates that they selected DD/MM/YYYY.</summary>
 *    Ddmmyyyy = 0,
 *    <summary>A value that indicates that they selected MM/DD/YYYY.</summary>
 *    Mmddyyyy = 1
 * }
 */
const CompanyDateFormat = {
  Ddmmyyyy: 0,
  Mmddyyyy: 1,
} as const;

Object.freeze(CompanyDateFormat);

export { CompanyDateFormat };

export const CompanyDateFormatValues = [dateFormat.formatDateForView, 'MM/dd/yyyy'] as const;

/// <summary>
/// Represents a set of properties to order a collection of pipeline entities.</summary>
/// </summary>
// public enum PipelineOrderProperty {
/// <summary>
/// A value that indicates that it orders a collection of entities by the name property.
/// </summary>
// Name = 1,
// }
const PipelineOrderProperty = {
  Name: 1,
} as const;

Object.freeze(PipelineOrderProperty);

export { PipelineOrderProperty };

//  public enum EmailCategory
// {
/// <summary>
/// A value that indicates that an email category is a pipeline notification
/// </summary>
// PipelineNotifications = 1,
// }
const EmailCategory = {
  PipelineNotifications: 1,
  CandidateNotifications: 2,
} as const;

Object.freeze(EmailCategory);

export { EmailCategory };

// public enum DateTimeType {
/// <summary>
/// A value that indicates actual date
/// </summary>
// ActualDate = 1,
/// <summary>
/// A value that indicates present date
/// </summary>
// Present = 2,
/// <summary>
/// A value that indicates not available date
/// </summary>
// NotAvailable = 3,
/// <summary>
/// A value that indicates not known date
/// </summary>
// NotKnown = 4,
// }
const DateTimeType = {
  ActualDate: 1,
  Present: 2,
  NotAvailable: 3,
  NotKnown: 4,
} as const;

Object.freeze(DateTimeType);

export { DateTimeType };
/// <summary>
/// Represents a set of available seniority levels
/// </summary>
// public enum SeniorityLevel {
/// <summary>
/// A value that indicates that seniority level is Executive
/// </summary>
// Executive = 1,
/// <summary>
/// A value that indicates that seniority level is Director
/// </summary>
// Director = 2,
/// <summary>
/// A value that indicates that seniority level is Mid-senior level
/// </summary>
// MidSenior = 3,
/// <summary>
/// A value that indicates that seniority level is Associate
/// </summary>
// Associate = 4,
/// <summary>
/// A value that indicates that seniority level is Entry-level
/// </summary>
// EntryLevel = 5,
/// <summary>
/// A value that indicates that seniority level is Internship
/// </summary>
// Internship = 6,
/// <summary>
/// A value that indicates that seniority level is Not Applicable
/// </summary>
// NotApplicable = 7,
// }
const SeniorityLevel = {
  Executive: 1,
  Director: 2,
  MidSenior: 3,
  Associate: 4,
  EntryLevel: 5,
  Internship: 6,
  NotApplicable: 7,
} as const;

Object.freeze(SeniorityLevel);

export { SeniorityLevel };

const SalaryBudgetStatus = {
  OnBudget: 1,
  OverBudget: 2,
  NotCompared: 3,
} as const;

Object.freeze(SalaryBudgetStatus);

export { SalaryBudgetStatus };

/// <summary>Provides a set of available roles of a user for the app.</summary>
// [Flags]
// public enum ApplicationRole : byte
// {
/// <summary>A value that indicates that no role assigns to a user.</summary>
// None = 0,
/// <summary>A value that indicates that the super admin role assigns to a user.</summary>
// SuperAdmin = 1,
/// <summary>A value that indicates that the admin role assigns to a user.</summary>
// Admin = 2,
/// <summary>A value that indicates that the manager role assigns to a user.</summary>
// Manager = 4,
/// <summary>A value that indicates that the line manager role assigns to a user.</summary>
// LineManager = 8,
// }
const ApplicationRole = {
  None: 0,
  SuperAdmin: 1,
  Admin: 2,
  Recruiter: 4,
  Manager: 8,
} as const;

Object.freeze(ApplicationRole);

export { ApplicationRole };

/// <summary>Represents a set of available job types.</summary>
// public enum JobType : byte
/// <summary>A value that indicates that a job type is unlisted.</summary>
// Unlisted = 1,
/// <summary>A value that indicates that a job type is external.</summary>
// External = 2,
const JobType = {
  Unlisted: 1,
  External: 2,
} as const;

Object.freeze(JobType);

export { JobType };

// namespace Expedo.Recruitment.Entities.Enums
/// <summary> Represents a set of properties to order a collection of pipeline stages.</summary>
const PipelineStageOrderType = {
  /// <summary>A value that used to skip ordering by stage id or stage type</summary>
  Default: 0,

  /// <summary>A value that is used to order by the id of pipeline stage.</summary>
  ByStageId: 1,

  /// <summary>A value that is used to order by the type of pipeline stage.</summary>
  ByStageType: 2,
} as const;

Object.freeze(PipelineStageOrderType);

export { PipelineStageOrderType };

const QuestionType = {
  YesNo: 1,
  TextField: 2,
  TextArea: 3,
  FileUpload: 4,
  SelectBoxList: 5,
  CheckBoxList: 6,
} as const;

Object.freeze(QuestionType);

export { QuestionType };

const QuestionTypeNames = {
  [QuestionType.YesNo]: 'Yes/No',
  [QuestionType.TextField]: 'Text Field',
  [QuestionType.TextArea]: 'Text Area',
  [QuestionType.FileUpload]: 'File Upload',
  [QuestionType.SelectBoxList]: 'Select Box',
  [QuestionType.CheckBoxList]: 'Checkbox',
} as const;

Object.freeze(QuestionTypeNames);

export { QuestionTypeNames };

const InterviewType = {
  InPerson: 0,
  Online: 1,
  Phone: 2,
} as const;

Object.freeze(InterviewType);

export { InterviewType };

const InterviewState = {
  Draft: 1,
  Saved: 2,
} as const;

Object.freeze(InterviewState);

export { InterviewState };

const InterviewTemplateState = {
  Draft: 1,
  Saved: 2,
} as const;

Object.freeze(InterviewTemplateState);

export { InterviewTemplateState };

/// <summary>Represents a set of available interview templates order properties.</summary>
const InterviewOrderProperty = {
  /// <summary>A value that indicates that an order property is <see cref="Expedo.Recruitment.Entities.DatabaseModels.Interview.InterviewEntity.Name"/></summary>
  Name: 1,
  /// <summary>A value that indicates that an order property is <see cref="Expedo.Recruitment.Entities.DatabaseModels.Interview.InterviewEntity.JobApplicantName"/></summary>
  JobApplicantName: 2,
  /// <summary>A value that indicates that an order property is <see cref="Expedo.Recruitment.Entities.DatabaseModels.Interview.InterviewEntity.State"/></summary>
  State: 4,
  /// <summary>A value that indicates that an order property is <see cref="Expedo.Recruitment.Entities.DatabaseModels.Interview.InterviewEntity.PublishedOn"/></summary>
  PublishedOn: 5,
} as const;

Object.freeze(InterviewOrderProperty);

export { InterviewOrderProperty };

const PipelineEmailRecipientType = {
  Candidate: 0,
  Recruiter: 1,
  HiringManager: 2,
} as const;

Object.freeze(PipelineEmailRecipientType);

export { PipelineEmailRecipientType };
