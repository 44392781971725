import React from 'react';
import { createPortal } from 'react-dom';
import { Transition } from 'react-transition-group';

import { ExTooltipBody } from 'components/ui/ExTooltip/ExTooltipComponents';
import { ExTooltipProps } from 'components/ui/ExTooltip/types';
import { useMouseHoverRef } from 'utils/hooks/useMouseHoverRef';
import { fadeInAnimation } from 'utils/styled/FadeTransition';

const ExTooltipFade = fadeInAnimation(ExTooltipBody);

export const ExTooltip = ({
  children,
  text,
  variant = 'dark',
  tooltipPosition = 'top',
}: ExTooltipProps & { children: React.ReactNode }) => {
  const [cbRef, value, ref] = useMouseHoverRef();
  const coords = ref.current?.getBoundingClientRect();

  return (
    <>
      {React.isValidElement(children) && React.cloneElement(children, { ref: cbRef })}
      {createPortal(
        <Transition in={value} timeout={100} mountOnEnter unmountOnExit nodeRef={ref}>
          {(state) => (
            <ExTooltipFade
              state={state}
              hovered={value}
              variant={variant}
              tooltipPosition={tooltipPosition}
              $coords={coords}
            >
              {text}
            </ExTooltipFade>
          )}
        </Transition>,
        document.body,
      )}
    </>
  );
};
