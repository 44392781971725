export enum EntityKeys {
  jobs = 'jobs',
  applicants = 'applicants',
  candidates = 'candidates',
  jobAds = 'jobAds',
  talentPools = 'talentPools',
}

export enum EntityKeysNew {
  candidateEducations = 'candidateEducations',
  candidateWorkExperiences = 'candidateWorkExperiences',
  qualificationTypes = 'qualificationTypes',
  hiringPipeline = 'hiringPipeline',
  hiringPipelineStage = 'hiringPipelineStage',
  onCost = 'onCost',
  emailTemplate = 'emailTemplate',
  jobBoards = 'jobBoards',
  applicantComments = 'applicantComments',
  jobComments = 'jobComments',
  jobScreeningQuestions = 'jobScreeningQuestions',
}

export enum DictionaryKeys {
  root = 'dictionaries',
  currency = 'currency',
  country = 'country',
  location = 'location',
  sector = 'sector',
  companyCountry = 'companyCountry',
  industries = 'industries',
  positions = 'positions',
  positionCategory = 'category',
}

export enum CompanyKeys {
  root = 'company',
  generalTalentPool = 'generalTalentPool',
}

export enum AppFilesKeys {
  root = 'appFiles',
  resumes = 'resumes',
  documents = 'documents',
}

export enum UIKeys {
  jobLists = 'jobLists',
  jobsList = 'jobsList',
  applicantLists = 'applicantLists',
  jobBoardLists = 'jobBoardLists',
  jobAdLists = 'jobAdLists',
  talentPoolLists = 'talentPoolLists',
  candidateLists = 'candidateLists',
  documentMeta = 'documentMeta',
  loader = 'loader',
  modals = 'modals',
  alertManager = 'alertManager',
  widgets = 'widgets',
  applicantFilters = 'applicantFilters',
  tips = 'tips',
  companyInterviewTemplate = 'companyInterviewTemplate',
  companyInterviewTemplateList = 'companyInterviewTemplateList',
  companyScreeningQuestion = 'companyScreeningQuestion',
  companyScreeningQuestionList = 'companyScreeningQuestionList',
  jobInterviewTabList = 'jobInterviewTabList',
  applicantInterviewTabList = 'applicantInterviewTabList',
  talentPoolCandidateList = 'talentPoolCandidateList',
}

export enum SortDirections {
  asc = 'Ascending',
  desc = 'Descending',
}

export enum ViewMode {
  grid,
  table,
}
