import { useEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ExRoutes } from 'router/routes';
import isEqual from 'lodash/isEqual';

import { PipelineStageType } from 'model/api-enums.constants';

import { ApplicantListProps, ApplicantListV2Props } from 'containers/ApplicantLists/ApplicantListProps';
import { ApplicantListTickBox } from 'containers/ApplicantLists/components/ApplicantListTickBox';
import { applicantListActions, applicantListEffects, applicantListSelectors } from 'containers/ApplicantLists/store';
import JobAdListPlaceholder from 'containers/JobAdLists/JobAdListPlaceholder';
import { loaderSelectors } from 'containers/Loader/store';

import { TickBox } from 'components/ListView';
import { Spinner } from 'components/Spinner';
import { useTableListUpdatePageHandler } from 'components/TableList/hooks';
import { ExTableCardWrapper } from 'components/ui/ExCard';
import { ExTable } from 'components/ui/ExTable';
import { useTableFooterCounters } from 'components/ui/ExTable/ExTable.hook';
import { ExHeaderProps } from 'components/ui/ExTable/ExTable.props';
import { prepareDataForSorting } from 'components/ui/ExTable/ExTable.utils';
import { useLoaderSubscription } from 'modules/LoaderManager/react';
import { loaderThunkActionWrapper } from 'modules/LoaderManager/redux';
import { usePrevious } from 'utils/hooks/usePrevious';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { ApplicantTableRow, SortBy } from 'store/entities/applicants';
import { jobsSelectors } from 'store/entities/jobs';
import { RootState } from 'store/rootReducer';

import { ApplicantListPlaceholder } from './ApplicantListPlaceholder';

type ApplicantListPureProps<S> = ApplicantListV2Props<S> & PropsFromRedux;

const useApplicantListState = <S extends SortBy>({
  applicants,
  loadApplicants,
  updatePage,
  id,
  updateSortMode,
  list,
  filters,
  selectable,
  multiselect,
  initList,
  selectAll,
  deselectAll,
  headers,
  jobId,
}: ApplicantListPureProps<S>) => {
  const updateSort = (sortMode: { orderBy?: string | number }) => {
    const preparedSortMode = prepareDataForSorting({ listSortMode: list.sortMode, newOrderBy: sortMode.orderBy });
    updateSortMode({ id, sortMode: preparedSortMode });
    loadApplicants({ listId: id });
  };

  const updatePageHandler = useTableListUpdatePageHandler({
    updateTableView: updatePage,
    id,
    load: loadApplicants,
    loadArgs: {
      preloader: true,
    },
  });

  const { isLoading: isLoadingApplicants } = useLoaderSubscription(
    loaderThunkActionWrapper(applicantListEffects.loadApplicants),
  );

  useEffect(() => {
    initList({ id });
  }, []); // eslint-disable-line

  const prevDeps = usePrevious(filters);

  useEffect(() => {
    if (!isEqual(prevDeps, filters)) {
      loadApplicants({ listId: id, belongsTo: filters?.belongsTo });
    }
  }, [filters, id, loadApplicants, prevDeps]);

  const isAllSelected = useMemo(
    () =>
      list.selectedItems.length ===
        list.items.filter((item) => {
          const applicant = applicants.find(({ id: applicantId }) => applicantId === item);

          return applicant?.profile.stageType !== PipelineStageType.Hired;
        }).length && list.items.length !== 0,
    [list.selectedItems.length, list.items, applicants],
  );

  const { selectedItems } = list;
  const additionalHeaders = useRef<ExHeaderProps<ApplicantTableRow, S>>();
  const [he, setHe] = useState<ExHeaderProps<ApplicantTableRow, S>[]>();

  const applicantProfiles = applicants.map((applicant) => applicant.profile);
  useEffect(() => {
    const tick: ExHeaderProps<ApplicantTableRow, S> = {
      label: '',
      component: ({ item }) => {
        return (
          <ApplicantListTickBox
            applicant={item.profile}
            selected={selectedItems.includes(item.id)}
            id={id}
            multiselect={multiselect}
          />
        );
      },
      headerComponent: () => (
        <TickBox
          selected={isAllSelected}
          handleSelect={() => (isAllSelected ? deselectAll({ id }) : selectAll({ id, applicants: applicantProfiles }))}
        />
      ),
      css: { width: '50px', padding: '0' },
      width: 50,
      customHeader: true,
    };

    if (selectable) {
      setHe([tick, ...headers]);
    }
    additionalHeaders.current = tick;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems, headers]);

  const isShowSpinner = (!list.fetched || !applicants.length) && isLoadingApplicants;
  const isShowPlaceholder = list.fetched && list.pageCount === 0;

  const { tableFooterCountersText } = useTableFooterCounters({
    pageNo: list.pageNo,
    pageCount: list.pageCount,
    totalItemsCount: list.totalItemsCount,
    pageSize: list.pageSize,
    section: 'Applicants',
  });

  const isJobStatusOpen = useAppSelector(jobsSelectors.selectIsJobStatusOpenByJobId, jobId);
  const isFilterSelected = useAppSelector(applicantListSelectors.isApplicantFiltersInJobChanged, { id });

  const placeholderMsg = isFilterSelected
    ? 'There are no applicants for current filters'
    : 'There are no applicants in your job';

  return {
    applicants,
    list,
    jobId,
    isLoadingApplicants,
    updateSort,
    updatePageHandler,
    he,
    isShowSpinner,
    isShowPlaceholder,
    tableFooterCountersText,
    isJobStatusOpen,
    placeholderMsg,
  };
};

const draftTitle = 'To add New Applicant, you need to open the job first. \nOpen this job?';

const ApplicantListPure = <S extends SortBy>(props: ApplicantListPureProps<S>) => {
  const {
    applicants,
    list,
    jobId,
    isLoadingApplicants,
    updateSort,
    updatePageHandler,
    he,
    isShowSpinner,
    isShowPlaceholder,
    tableFooterCountersText,
    isJobStatusOpen,
    placeholderMsg,
  } = useApplicantListState(props);

  if (isShowSpinner) {
    return <Spinner />;
  }

  if (!list.fetched) {
    return <div />;
  }

  if (!isJobStatusOpen && isShowPlaceholder) {
    return <JobAdListPlaceholder jobId={jobId} draftTitle={draftTitle} />;
  }

  if (isShowPlaceholder) {
    return <ApplicantListPlaceholder title={placeholderMsg} link={ExRoutes.applicantNewFromJob({ jobId })} />;
  }

  return (
    <ExTableCardWrapper>
      <ExTable
        items={applicants}
        selectId={(item) => item.profile.applicantId}
        pageCount={list.pageCount}
        pageNo={list.pageNo}
        isLoading={isLoadingApplicants}
        orderDir={list.sortMode.orderDir}
        orderBy={list.sortMode.orderBy}
        onSortClick={updateSort}
        onPaginationClick={updatePageHandler}
        headers={he ?? []}
        componentLoader={<Spinner />}
        tableFooterText={tableFooterCountersText}
      />
    </ExTableCardWrapper>
  );
};

const mapDispatch = {
  initList: applicantListActions.init,
  loadApplicants: applicantListEffects.loadApplicants,
  updatePage: applicantListActions.updatePage,
  updateSortMode: applicantListActions.updateSort,
  selectAll: applicantListActions.selectAllNotHired,
  deselectAll: applicantListActions.deselectAllItems,
};

const mapState = (state: RootState, own: ApplicantListProps) => ({
  applicants: applicantListSelectors.selectApplicantsForJobTable(state, own),
  sortMode: applicantListSelectors.getSortMode(state, own),
  filters: applicantListSelectors.getFilters(state, own),
  list: applicantListSelectors.getListById(state, own),
  isApplicantListLoading: loaderSelectors.isTaskActive(state, {
    taskId: applicantListEffects.loadApplicantsTaskId,
  }),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const ApplicantListV2 = connector(ApplicantListPure);
