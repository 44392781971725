import { useMemo } from 'react';
import { format, parseISO } from 'date-fns';

import { useDateDefaultFormatFunction } from 'utils/hooks/dateTime';
import { isSameYearsFirstMonthsAndDays, isSameYearsMonthsFirstDays } from 'utils/hooks/useDuration';

import { useCountryQuerySelectors } from 'store/dictionary/hooks/useCountryQuerySelectors';
import { CandidateWorkExperience } from 'store/entities/candidate-work-experience/candidate-work-experience.models';
import { useSelector } from 'store/rootSelectors';

function useWorkExperienceDates(startDate: string | null, endDate: string | null, isPresent: boolean): string {
  const { formatDateToDefault } = useDateDefaultFormatFunction();

  const startDateParsed = startDate === null ? new Date() : parseISO(startDate);
  const endDateParsed = endDate === null ? new Date() : parseISO(endDate);

  const startDateToShow = startDateParsed.toISOString();
  const endDateToShow = endDateParsed.toISOString();

  if (isSameYearsFirstMonthsAndDays(startDateParsed, endDateParsed)) {
    return format(startDateParsed, 'yyyy');
  }

  if (isSameYearsMonthsFirstDays(startDateParsed, endDateParsed)) {
    return format(startDateParsed, 'LL/yyyy');
  }

  const startDateFormatted = formatDateToDefault(startDateToShow);
  const endDateFormatted = isPresent ? 'present' : formatDateToDefault(endDateToShow);

  return [startDateFormatted, endDateFormatted].join(' - ');
}

function useWorkExperienceAddress(workExperience: CandidateWorkExperience | undefined): string {
  const { selectors: countriesSelectors } = useCountryQuerySelectors();
  const countryFromDictionary = useSelector((state) =>
    countriesSelectors.selectById(state, workExperience?.country || ''),
  );

  return useMemo(() => {
    const city = workExperience?.city;
    const country = countryFromDictionary?.name;

    return [city, country].filter(Boolean).join(', ');
  }, [workExperience, countryFromDictionary]);
}

export { useWorkExperienceAddress, useWorkExperienceDates };
