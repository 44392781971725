import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';

import { candidates } from 'containers/Auth/AuthMatrix/authMatrix.constants';
import { useRBAC } from 'containers/Auth/components/RBAC';

import { FormInput } from 'components/FormInput';
import { ExMobileInputWithCountry } from 'components/ui/ExMobileInputWithCountry';

import { Candidate } from 'store/entities/candidates/models';

type ApplicantModalContactsInfoEditProps = {
  className?: string;
};

const ApplicantModalContactsInfoEditStyled = styled.div``;

const useApplicantModalContactsInfoEditState = ({ className }: ApplicantModalContactsInfoEditProps) => {
  const { errors, formState, register } = useFormContext<Candidate>();
  const disabled = !useRBAC(candidates.editDetails);
  const validated = formState.isSubmitted;

  return {
    className,
    errors,
    register,
    validated,
    disabled,
  } as const;
};

export const ApplicantModalContactsInfoEdit: React.FC<ApplicantModalContactsInfoEditProps> = (props) => {
  const { className, errors, register, validated, disabled } = useApplicantModalContactsInfoEditState(props);

  return (
    <ApplicantModalContactsInfoEditStyled className={className}>
      <FormInput
        label="Email"
        name="email"
        required={!disabled}
        errors={errors?.email}
        validated={validated}
        inputRef={register}
        disabled={disabled}
      />
      <ExMobileInputWithCountry name="mobile" label="Mobile" disabled={disabled} />
    </ApplicantModalContactsInfoEditStyled>
  );
};
