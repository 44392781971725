import { createAction } from '@reduxjs/toolkit';

import { publicCurrencySlice } from './public-currency.reducer';

const publicCurrencyFetchAction = createAction(`${publicCurrencySlice.name}/fetch`);

export const publicCurrencyActions = {
  ...publicCurrencySlice.actions,
  publicCurrencyFetchAction,
};
