import React, { lazy, Suspense, useEffect } from 'react';

import { JobListForJobsPageProps } from 'containers/JobLists/JobListProps';
import { JobListUriParams } from 'containers/JobLists/store/models';

import { EmptyPageLoader } from 'components/EmptyPageLoader';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useNavigation } from 'utils/hooks/useNavigation';

import { jobsListUiSlice } from 'store/ui/jobs/jobs-list/jobs-list.reducer';

const JobListTable = lazy(
  () => import(/* webpackChunkName: "job-list-table" */ 'containers/JobLists/components/JobListTable'),
);

const useJobListForJobsPageState = () => {
  const { parseSearch } = useNavigation();
  const { status }: Partial<JobListUriParams> = parseSearch();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      jobsListUiSlice.actions.updateFilters({
        pageNo: 0,
        jobStatuses: status,
      }),
    );
  }, [dispatch, status]);
};

export const JobListForJobsPage: React.FC<JobListForJobsPageProps> = ({ headers }) => {
  useJobListForJobsPageState();

  return (
    <Suspense fallback={<EmptyPageLoader />}>
      <JobListTable headers={headers} />
    </Suspense>
  );
};
