import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';

import { dateFormat } from 'config/date';

import { CandidateSource } from 'model/api-enums.constants';
import { AppRouterParams } from 'model/router';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { applicantFormEffects } from 'containers/ApplicantForm/ApplicantFormState';
import { ApplicantForm } from 'containers/ApplicantForm/ApplicantFormState/applicant-form.models';
import { rbacConstants } from 'containers/Auth/AuthMatrix';
import { RBAC } from 'containers/Auth/components/RBAC';
import { CandidateInfoRow, CandidateList, CandidateListHeaderForNewApplicant } from 'containers/CandidateLists';
import { candidateListActions, candidateListSelectors } from 'containers/CandidateLists/store';

import { AddBadge } from 'components/AddBadge';
import { ColumnMap } from 'components/ListViewNew/ListViewProps';
import { StepComponent, StepDescription } from 'components/StepForm';
import { innerTablesStyled, TableListWrapper } from 'components/TableList/TableListComponents';
import { ExCard } from 'components/ui/ExCard';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { getTranslate } from 'utils/i18utils';

import { Applicant } from 'store/entities/applicants';
import { candidateCreateOpenModal } from 'store/entities/candidates/candidate.actions';
import { Candidate, CandidateBelongsTo, CandidateListSortBy, Columns } from 'store/entities/candidates/models';
import { RootState } from 'store/rootReducer';

export interface NewApplicantSelectCandidateProps extends StepComponent {
  className?: string;
  formData: ApplicantForm;
}

const columns: ColumnMap<Candidate, CandidateListSortBy, Columns> = {
  [Columns.name]: {
    label: 'Candidate',
    component: ({ item }) => (
      <div>
        <CandidateInfoRow candidate={item} preventClick />
      </div>
    ),
    style: { width: '48%' },
  },
  [Columns.appliedDate]: {
    label: 'Created at',
    component: ({ item }) => (
      <span>{item.createdOn ? format(parseISO(item.createdOn), dateFormat.formatDateForApplicantTable) : '--'}</span>
    ),
    style: { width: '20%' },
  },
  [Columns.source]: {
    label: 'Source',
    component: ({ item }) => <span>{CandidateSource[item.source as keyof typeof CandidateSource]}</span>,
    style: { width: '25%' },
  },
};

const CandidateListStyled = innerTablesStyled(CandidateList);

const NewApplicantSelectCandidate: React.FC<NewApplicantSelectCandidateProps> = ({ className, formData }) => {
  const [filtersInitialized, setFiltersInitialized] = useState(false);
  const { candidateId, jobId } = useParams<AppRouterParams>();
  const candidateListId = CandidateBelongsTo.newApplicant;
  const { register, errors, setValue, getValues } = useFormContext<Applicant>();
  const dispatch = useAppDispatch();
  const selectedItems = useSelector<RootState>((state) =>
    candidateListSelectors.getSelected(state, { id: candidateListId }),
  ) as Candidate['id'][];

  useEffect(() => {
    register('candidateId');
    const cId = formData.candidateId || candidateId;
    if (cId) {
      dispatch(
        candidateListActions.toggleItemRadio({
          selectedItemId: cId,
          id: candidateListId,
        }),
      );
    }
    if (jobId) {
      dispatch(
        candidateListActions.updateFilters({
          filters: { excludeJobIds: [jobId] },
          id: CandidateBelongsTo.newApplicant,
        }),
      );
    }
    setFiltersInitialized(true);
    return () => {
      dispatch(
        applicantFormEffects.changeCandidate({
          newCandidateId: getValues('candidateId'),
          oldCandidateId: formData.candidateId,
        }),
      );
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setValue('candidateId', selectedItems[0]);
  }, [selectedItems]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const error = errors.candidateId;
    if (error?.message) {
      dispatch(alertsEffects.showError({ message: error.message.toString() }));
    }
  }, [dispatch, errors]);

  const addClickHandler: React.MouseEventHandler<HTMLSpanElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(candidateCreateOpenModal());
  };

  if (!filtersInitialized) {
    return null;
  }
  return (
    <div className={className}>
      <StepDescription>
        <StepDescription.Title>Step 1: Select Candidate</StepDescription.Title>
        <StepDescription.Description>{getTranslate('new_applicant.step_1.description')}</StepDescription.Description>
      </StepDescription>
      <ExCard
        header={
          <div className="d-flex">
            Candidates
            <RBAC feature={rbacConstants.candidates.create}>
              <AddBadge className="ml-3" onClick={addClickHandler} label="New" />
            </RBAC>
          </div>
        }
      >
        <CandidateListHeaderForNewApplicant listId={candidateListId} showTalentPoolFilter />
        <RBAC feature={rbacConstants.candidates.view}>
          <CandidateListStyled id={candidateListId} columns={columns} selectable />
        </RBAC>
      </ExCard>
    </div>
  );
};

export default styled(NewApplicantSelectCandidate)`
  ${TableListWrapper} {
    padding: 0;
  }
`;
