import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAuth } from 'auth';

import { EmptyPageSpinnerWrapper, Spinner } from 'components/Spinner';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { authActions } from 'store/auth/auth.actions';
import { authSelectors } from 'store/auth/auth.selectors';
import { useSelector } from 'store/rootSelectors';

import { ExRoutes } from './routes';

export const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const dispatch = useAppDispatch();

  const currentCompanyId = useSelector(authSelectors.selectCurrentCompanyId);

  const {
    state: { isAuthenticated, loading },
  } = useAuth();

  useEffect(() => {
    if (currentCompanyId) {
      dispatch(authActions.appInitDataAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!currentCompanyId) {
    return <Redirect to={{ pathname: ExRoutes.selectCompanyPage() }} />;
  }

  if (loading || !isAuthenticated) {
    return (
      <EmptyPageSpinnerWrapper>
        <Spinner />
      </EmptyPageSpinnerWrapper>
    );
  }

  if (!isAuthenticated && !loading) {
    return null;
  }

  return <Route {...rest} render={() => children} />;
};
