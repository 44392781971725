import styled from 'styled-components';

import { Coords, ExTooltipProps } from 'components/ui/ExTooltip/types';
import {
  getArrowPositionForTooltip,
  getTooltipBorder,
  getTooltipPositionForTooltip,
  getTooltipTextColor,
} from 'components/ui/ExTooltip/utils';

export const ExTooltipBody = styled.span<ExTooltipProps & { $coords: Coords }>`
  background-color: ${(props) => props.theme.colors[props.variant!]};
  padding: 2px 10px;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  ${(props) => getTooltipTextColor(props.variant!)};
  font-weight: normal;
  font-size: inherit;
  position: absolute;
  white-space: nowrap;
  text-align: center;
  ${(props) => getTooltipPositionForTooltip(props.tooltipPosition, props.$coords)};
  z-index: 1800;
  box-shadow: 0 9px 50px rgba(0, 0, 0, 0.1);

  &::before {
    content: ' ';
    background-color: ${(props) => props.theme.colors[props.variant!]};
    width: 10px;
    ${(props) => getTooltipBorder(props.tooltipPosition)}
    height: 10px;
    position: absolute;
    transform: rotate(45deg);
    ${(props) => getArrowPositionForTooltip(props.tooltipPosition)}
  }
`;
