import { createAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';

import { WithCancelToken } from 'api-endpoints/utils';

import { adapterHelper } from 'utils/reducer/adapter-helper';

import { EntityKeysNew } from 'store/constants';
import { RootState } from 'store/rootReducer';
import { entitiesDomain } from 'store/rootSelectors';

// MODEL -----------------------------------------------------------------------
export type QualificationTypeModel = {
  qualificationId: string;
  qualificationName: string;
};

export type QualificationTypeUrlParams = {
  urlParams: {
    qualificationId: QualificationTypeModel['qualificationId'];
  };
};

export type QualificationTypeRequestParams = WithCancelToken<QualificationTypeUrlParams>;
export type QualificationTypeCreateRequestParams = WithCancelToken<
  QualificationTypeUrlParams & { data: Omit<QualificationTypeModel, 'qualificationId'> }
>;

export type QualificationTypeUpdateRequestParams = WithCancelToken<
  QualificationTypeUrlParams & { data: QualificationTypeModel }
>;

export type QualificationTypeMappingDTO = {
  qualificationId: QualificationTypeModel['qualificationId'];
  daxtraQualificationName: string;
};

export type QualificationTypeMappingResponse = { items: Array<QualificationTypeMappingDTO> };

export type QualificationTypeAssignDaxtraRequestParams = {
  data: { items: Array<QualificationTypeMappingDTO> };
};
export type DaxtraQualificationsDTO = {
  daxtraQualificationName: string;
};

export type DaxtraQualificationsResponse = { items: Array<DaxtraQualificationsDTO> };
//------------------------------------------------------------------------------

// ADAPTER ---------------------------------------------------------------------
const qualificationTypeEntityAdapter = createEntityAdapter<QualificationTypeModel>({
  selectId: (item) => item.qualificationId,
});
//------------------------------------------------------------------------------

// SLICE -----------------------------------------------------------------------
export const qualificationTypeSlice = createSlice({
  name: EntityKeysNew.qualificationTypes,
  initialState: qualificationTypeEntityAdapter.getInitialState(),
  reducers: {
    ...adapterHelper(qualificationTypeEntityAdapter),
  },
});
//------------------------------------------------------------------------------

// REDUCER ---------------------------------------------------------------------
export const qualificationTypeReducer = qualificationTypeSlice.reducer;
//------------------------------------------------------------------------------

// SELECTORS -------------------------------------------------------------------
const domain = createSelector(entitiesDomain, (state) => state.qualificationTypes);
const adapterSelectors = qualificationTypeEntityAdapter.getSelectors(domain);
const selectExcludeId = createSelector(
  adapterSelectors.selectAll,
  (state: RootState, qualificationId: QualificationTypeModel['qualificationId']) => qualificationId,
  (state, qualificationId) =>
    state.filter((qualificationType) => qualificationType.qualificationId !== qualificationId),
);
const selectNameById = createSelector(
  adapterSelectors.selectById,
  (qualificationType) => qualificationType?.qualificationName,
);

export const qualificationTypeSelectors = {
  ...adapterSelectors,
  selectExcludeId,
  selectNameById,
};
//------------------------------------------------------------------------------

// ACTIONS ---------------------------------------------------------------------
const QUALIFICATION_TYPE_FETCH = 'qualificationType/fetch';
const QUALIFICATION_TYPE_CREATE = 'qualificationType/create';
const QUALIFICATION_TYPE_REMOVE = 'qualificationType/remove';
const QUALIFICATION_TYPE_UPDATE = 'qualificationType/update';

export type ActionApiCallBatchTypes = 'start' | 'pending' | 'success' | 'failure';
export type SilentApiCall = { silent?: boolean };
export function createActionApiCallBatch<T extends {}>(actionType: string) {
  return {
    start: createAction<T & SilentApiCall>(actionType + '/request'),
    pending: createAction(actionType + '/pending'),
    success: createAction(actionType + '/success'),
    failure: createAction(actionType + '/failure'),
  };
}

const qualificationTypeRemove = createAction<{
  qualificationId: QualificationTypeModel['qualificationId'];
}>(QUALIFICATION_TYPE_REMOVE);
const qualificationTypeFetch = createActionApiCallBatch(QUALIFICATION_TYPE_FETCH);

const qualificationTypeCreate = createAction(QUALIFICATION_TYPE_CREATE);

const qualificationTypeUpdate =
  createActionApiCallBatch<Pick<QualificationTypeModel, 'qualificationId'>>(QUALIFICATION_TYPE_UPDATE);

export const qualificationTypeActions = {
  ...qualificationTypeSlice.actions,
  qualificationTypeRemove,
  qualificationTypeFetch,
  qualificationTypeCreate,
  qualificationTypeUpdate,
};

//------------------------------------------------------------------------------
