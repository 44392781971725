import { useCallback } from 'react';

import { ExInterviewTemplate } from 'model';

import { ModalsTypeKey } from 'containers/Modals/AppModalProps';
import { modalsActions } from 'containers/Modals/store';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';

type useInterviewTemplateSettingsModalParams = {
  interviewTemplateId?: ExInterviewTemplate['interviewTemplateId'];
};

export function useInterviewTemplateSettingsModal({ interviewTemplateId }: useInterviewTemplateSettingsModalParams) {
  const dispatch = useAppDispatch();

  const openInterviewSettingsModal = useCallback(() => {
    if (interviewTemplateId) {
      dispatch(modalsActions.setInterviewTemplateId({ interviewTemplateId }));
    }
    dispatch(modalsActions.showModal({ modalType: ModalsTypeKey.interviewTemplateSettingModal }));
  }, [dispatch, interviewTemplateId]);
  return [openInterviewSettingsModal] as const;
}
