import { TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';

import { RootState } from 'store/rootReducer';

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const entitiesDomain = (state: RootState) => state.entities;

export const dictionariesDomain = (state: RootState) => state.dictionaries;

export const uiDomain = (state: RootState) => state.ui;

export const authDomain = (state: RootState) => state.auth;

export const companyDomain = (state: RootState) => state.company;
