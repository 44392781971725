import { UseFormMethods } from 'react-hook-form';
import styled from 'styled-components/macro';

import { ReactComponent as IconAlert } from 'assets/img/triangle-alert.svg';

type FormControlErrorMessageProps = {
  show: boolean;
  withIcon?: boolean;
  errors?: UseFormMethods['errors'];
};

const ErrorMessageField = styled('div')`
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const ErrorMessageText = styled('span')`
  width: 100%;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: #d92d20;
`;

export const FormControlErrorMessage = ({ show, withIcon, errors }: FormControlErrorMessageProps) => {
  if (!show) {
    return null;
  }

  return (
    <ErrorMessageField>
      {withIcon && <IconAlert />}
      <ErrorMessageText title={errors?.message}>{errors?.message}</ErrorMessageText>
    </ErrorMessageField>
  );
};

type FormControlContainerWithErrorMessageProps = {
  className?: string;
  children?: any;
} & FormControlErrorMessageProps;

const ControlContainer = styled('div')`
  position: relative;
  padding-bottom: 32px;

  ${ErrorMessageField} {
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(8px);

    padding-left: inherit;
    padding-right: inherit;
    overflow: hidden;
  }

  & .form-group {
    margin-bottom: 0;
  }
`;

export const FormControlContainerWithErrorMessage = ({
  className,
  children,
  show,
  withIcon,
  errors,
}: FormControlContainerWithErrorMessageProps) => {
  return (
    <ControlContainer className={className}>
      {children}
      <FormControlErrorMessage show={show} withIcon={withIcon} errors={errors} />
    </ControlContainer>
  );
};
