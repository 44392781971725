import { createSelector } from 'reselect';

import { ViewMode } from 'store/constants';
import { RootState } from 'store/rootReducer';

import { candidatesListUiSlice, selectedCandidatesAdapter } from './candidates-list.reducer';

const domain = (root: RootState) => root.ui.candidatesUi[candidatesListUiSlice.name];

const filters = createSelector(domain, (state) => state.filters);

const filtersForApi = createSelector(filters, (state) => {
  const searchTerm = state.searchTerm.trim();

  const doNotHire = !state.doNotHire ? false : undefined;

  return {
    ...state,
    searchTerm: searchTerm === '' ? undefined : searchTerm,
    doNotHire,
  };
});
const filtersTalentPoolNames = createSelector(filters, (state) =>
  state.talentPoolNames?.map((name) => ({ label: name, value: name })),
);
const viewMode = createSelector(domain, (state) => state.viewMode);

const isGridView = createSelector(viewMode, (state) => state === ViewMode.grid);
const selectedCandidatesState = createSelector(domain, (state) => state.selectedCandidates);

const entityAdapterSelectors = selectedCandidatesAdapter.getSelectors(selectedCandidatesState);

const isCandidateSelectedById = createSelector(entityAdapterSelectors.selectById, Boolean);

const isFiltersChanged = createSelector(domain, (state) => state.isFiltersChanged);
export const candidatesListUISelectors = {
  ...entityAdapterSelectors,
  isCandidateSelectedById,
  filters,
  viewMode,
  isGridView,
  filtersTalentPoolNames,
  filtersForApi,
  isFiltersChanged,
};
