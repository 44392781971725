import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ExScreeningQuestion } from 'model';

import { UIKeys } from 'store/constants';

type CompanyScreeningQuestionUiSliceState = ReturnType<typeof initialState>;

const initialState = () => ({
  editQuestionId: null as null | ExScreeningQuestion['questionId'],
});

export const companyScreeningQuestionUiSlice = createSlice({
  name: UIKeys.companyScreeningQuestion,
  initialState: initialState(),
  reducers: {
    setEditQuestionId: (state, { payload }: PayloadAction<CompanyScreeningQuestionUiSliceState['editQuestionId']>) => {
      state.editQuestionId = payload;
    },
  },
});
