import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { JobBoardInfoRow, JobBoardList, JobBoardListHeader } from 'containers/JobBoardLists';
import { jobBoardListActions, jobBoardListSelectors } from 'containers/JobBoardLists/store';
import { jobBoardFormActions } from 'containers/JobBoardsForm/state';
import { JobBoardSettingsForm } from 'containers/JobBoardsForm/state/models';

import { ColumnMap } from 'components/ListViewNew/ListViewProps';
import { StepComponent, StepDescription } from 'components/StepForm';
import { innerTablesStyled, TableListWrapper } from 'components/TableList/TableListComponents';
import { ExCard } from 'components/ui/ExCard';
import { useFormShowAlertError } from 'utils/hooks/formsHooks/useFormShowAlertError';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { getTranslate } from 'utils/i18utils';

import { Columns, JobBoard, JobBoardBelongsTo, SortBy } from 'store/entities/job-boards/models';
import { RootState } from 'store/rootReducer';

interface JobBoardSelectStepProps extends StepComponent {
  className?: string;
  formData: JobBoardSettingsForm;
}

const columns: ColumnMap<JobBoard, SortBy, Columns> = {
  [Columns.name]: {
    label: 'Job Board Name',
    component: ({ item }) => <JobBoardInfoRow jobBoard={item} withLink={false} />,
    order: SortBy.name,
    style: { width: '48%' },
  },
  [Columns.url]: {
    label: 'Website',
    component: ({ item }) => <span style={{ fontSize: '12px', lineHeight: '24px' }}>{item.url}</span>,
    order: SortBy.url,
    style: { width: '45%' },
  },
};

const JobBoardListStyled = innerTablesStyled(JobBoardList);

const JobBoardSelectStepPure: React.FC<JobBoardSelectStepProps> = ({ className, formData }) => {
  const dispatch = useAppDispatch();
  const { register, setValue, getValues, reset } = useFormContext<JobBoardSettingsForm>();
  useFormShowAlertError();
  const selectedItems = useSelector<RootState>((state) =>
    jobBoardListSelectors.getSelected(state, { id: 'new' }),
  ) as JobBoard['jobBoardId'][];

  const values = getValues();

  useEffect(() => {
    register('id', { required: 'Job Board is required' });
    const jobBoardId = formData.jobBoardId;
    if (jobBoardId) {
      dispatch(
        jobBoardListActions.setItemSelect({
          selectedItemId: jobBoardId,
          id: JobBoardBelongsTo.new,
        }),
      );
    }
    return () => {
      const newJobBoardId: string | undefined = getValues('id');

      if (jobBoardId && newJobBoardId !== jobBoardId) {
        dispatch(jobBoardFormActions.clearForm({}));

        if (newJobBoardId) {
          const newValues = { ...values };
          Object.keys(newValues).forEach((key) => (newValues[key] = undefined));

          dispatch(
            jobBoardFormActions.saveForm({
              formData: {
                jobBoardId: newJobBoardId,
              },
            }),
          );

          reset({
            ...newValues,
            id: newJobBoardId,
            jobBoardId: newJobBoardId,
          } as any);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectedItem = selectedItems[0];
    setValue('id', selectedItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  return (
    <section className={className}>
      <StepDescription>
        <StepDescription.Title>Step 1: Select a Job Board</StepDescription.Title>
        <StepDescription.Description>
          {getTranslate('subscribe_to_job_board.step_1.description')}
        </StepDescription.Description>
      </StepDescription>
      <ExCard title="Available Job Boards">
        <JobBoardListHeader listId={JobBoardBelongsTo.new} />
        <JobBoardListStyled
          id={JobBoardBelongsTo.new}
          columns={columns}
          selectable
          preventClick
          selectableHeader
          selectableHeaderDisabled
        />
      </ExCard>
    </section>
  );
};

export const JobBoardSelectStep = styled(JobBoardSelectStepPure)`
  ${TableListWrapper} {
    padding: 0;
  }
`;
