import React from 'react';
import styled from 'styled-components';

import { useDateDefaultFormatFunction } from 'utils/hooks/dateTime';

export type DateInTableCellProps = {
  dateString?: string | Date;
};

export const TableCellAppliedDateStyled = styled.div``;

export const TableCellDate: React.FC<DateInTableCellProps> = ({ dateString }) => {
  const { formatDateToDefault } = useDateDefaultFormatFunction();

  return <TableCellAppliedDateStyled>{dateString ? formatDateToDefault(dateString) : '--'}</TableCellAppliedDateStyled>;
};
