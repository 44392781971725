import { AxiosResponse } from 'axios';

import { getCandidateWorkExperiencesApiMethod } from 'api-endpoints/candidate';

import { ApiResponseWithPagination } from 'model';

import { CandidateWorkExperience } from 'store/entities/candidate-work-experience/candidate-work-experience.models';

export async function workExperienceResponseMapper(
  data: ReturnType<typeof getCandidateWorkExperiencesApiMethod>,
): Promise<AxiosResponse<ApiResponseWithPagination<CandidateWorkExperience>>> {
  const response = await data;

  const newItems = response.data.items.map((item) => {
    return {
      ...item,
      endDate: item.endDate.actualDate,
      startDate: item.startDate.actualDate ?? '',
    };
  });

  return {
    ...response,
    data: {
      ...response.data,
      items: newItems as any,
    },
  };
}
