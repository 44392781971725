import get from 'lodash/get';

import { Ellipsis } from 'components/Ellipsis';
import { ExHeaderInnerProps, ExHeaderProps, TableFooterCountersProps } from 'components/ui/ExTable/ExTable.props';
import { isFunction } from 'utils/funcs';
import { pluralize } from 'utils/pluralize';

export type HasField<T> = T extends { field: string } ? T : never;
export const hasField = <T extends {}>(value: T): value is HasField<T> => 'field' in value;

export type HasPath<T> = T extends { path: string } ? T : never;
export const hasPath = <T extends {}>(value: T): value is HasPath<T> => 'path' in value;

export type HasComponent<T> = T extends { component: (...args: any[]) => JSX.Element } ? T : never;
export const hasComponent = <T extends {}>(value: T): value is HasComponent<T> =>
  'component' in value && isFunction((value as HasComponent<T>).component);

export const Cell = <T extends { [x: string]: any }, S>({
  cell,
  row,
}: {
  cell: ExHeaderProps<T, S>;
  row: T;
}): JSX.Element => {
  const clamp = cell.clamp ?? 2;
  if (hasField(cell)) {
    const title = row[cell.field] ?? '--';
    return <Ellipsis {...{ clamp, title }}>{title}</Ellipsis>;
  }

  if (hasPath(cell)) {
    const title = get(row, cell.path, '--');
    return <Ellipsis {...{ clamp, title }}>{title}</Ellipsis>;
  }

  if (hasComponent(cell)) {
    return cell.component({ item: row });
  }

  return <>{'--'}</>;
};

type SortMode<S = string | number, D = 'Ascending' | 'Descending'> = {
  orderBy?: S;
  orderDir?: D;
};

type ASC = typeof DEFAULT_ASCENDING_DIRECTION;
type DSC = typeof DEFAULT_DESCENDING_DIRECTION;

const DEFAULT_ASCENDING_DIRECTION = 'Ascending';
const DEFAULT_DESCENDING_DIRECTION = 'Descending';

function getDefaultDirectionAscending<DirectionAscending = 'Ascending'>(directionAscending?: DirectionAscending) {
  return directionAscending !== undefined ? directionAscending : DEFAULT_ASCENDING_DIRECTION;
}

function getDefaultDirectionDescending<DirectionDescending>(directionDescending?: DirectionDescending) {
  return directionDescending !== undefined ? directionDescending : DEFAULT_DESCENDING_DIRECTION;
}

/**
 * Function prepare the sort order direction and order sort from ExTable data for API
 * @export
 * @template S
 * @template DirectionAscending
 * @template DirectionDescending
 * @template DirectionDescendingIn
 * @param {({
 *   listSortMode: SortMode<S, 'Ascending' | 'Descending'>;
 *   newOrderBy?: S;
 *   directionAscending?: DirectionAscending;
 *   directionDescending?: DirectionDescending;
 *   directionDescendingIn?: DirectionDescendingIn;
 * })} {
 *   listSortMode,
 *   newOrderBy,
 *   directionAscending,
 *   directionDescending,
 *   directionDescendingIn
 * }
 * @return {SortMode<S, DirectionAscending|DirectionDescending>}
 */
export function prepareDataForSorting<
  S,
  DirectionAscending = ASC,
  DirectionDescending = DSC,
  DirectionAscendingIn = ASC,
  DirectionDescendingIn = DSC,
>({
  listSortMode,
  newOrderBy,
  directionAscending,
  directionDescending,
  directionDescendingIn,
}: {
  listSortMode: SortMode<S, DirectionAscendingIn | DirectionDescendingIn>;
  newOrderBy?: S;
  directionAscending?: DirectionAscending;
  directionDescending?: DirectionDescending;
  directionDescendingIn?: DirectionDescendingIn;
}): SortMode<S, DirectionAscending | DirectionDescending> {
  const directionAscendingOut = getDefaultDirectionAscending(directionAscending) as DirectionAscending;
  const directionDescendingOut = getDefaultDirectionDescending(directionDescending) as DirectionDescending;
  const directionDescendingInDefault = getDefaultDirectionDescending(directionDescendingIn) as DirectionDescendingIn;

  const listOrderBy = listSortMode.orderBy;
  const listOrderDir = listSortMode.orderDir;
  let orderBy = newOrderBy;
  let orderDirOut: DirectionAscending | DirectionDescending | undefined;

  if (listOrderBy === orderBy) {
    if (listOrderDir === directionDescendingInDefault) {
      orderDirOut = undefined;
    } else {
      orderDirOut = listOrderDir === undefined ? directionAscendingOut : directionDescendingOut;
    }
  } else {
    orderDirOut = directionAscendingOut;
  }

  if (orderDirOut === undefined) {
    orderBy = undefined;
  }

  return { orderBy, orderDir: orderDirOut } as const;
}

/**
 * Function transform incoming order direction to ExTableOrderDirection `Ascending` or `Descending`
 * @template DirectionAscending
 * @template DirectionDescending
 * @param {({
 *   orderDirection?: DirectionAscending | DirectionDescending;
 *   Ascending: DirectionAscending;
 *   Descending: DirectionDescending;
 * })} {
 *   Ascending
 *   Descending
 *   orderDirection
 * }
 * @returns {'Ascending'|'Descending'|undefined} sorting required for the table
 */
export function toExTableSortDirection<DirectionAscending, DirectionDescending>({
  Ascending,
  Descending,
  orderDirection,
}: {
  orderDirection?: DirectionAscending | DirectionDescending;
  Ascending: DirectionAscending;
  Descending: DirectionDescending;
}): 'Ascending' | 'Descending' | undefined {
  if (orderDirection === Ascending) {
    return 'Ascending';
  } else if (orderDirection === Descending) {
    return 'Descending';
  }
}

/**
 * Function add needed params for stick columns left or right
 *
 * @export
 * @param {(number | undefined)} minCellWidth
 * @param {('left' | 'right')} [direction='left']
 * @returns
 */
export function addSticky(minCellWidth: number | undefined, direction: 'left' | 'right' = 'left') {
  return function <T extends {}, S>(
    acc: { result: Array<ExHeaderInnerProps<T, S>>; offset: number },
    item: ExHeaderInnerProps<T, S>,
  ) {
    const stickyDirection = direction === 'left' ? 'sticky' : 'stickyEnd';

    if (item[stickyDirection]) {
      item[direction] = acc.offset + (minCellWidth ?? 0);
      acc.offset += (item[direction] ?? 0) + (item.width ?? 0);
    }
    acc.result.push(item);
    return acc;
  };
}

export function addStickyInitialValue() {
  return { result: [], offset: 0 };
}

export const getTableCounters = ({
  pageNo = 0,
  pageSize = 0,
  totalItemsCount = 0,
  section = '',
}: TableFooterCountersProps) => {
  const firstItemOnPage = pageSize * pageNo + 1;
  const lastItemOnPage = Math.min(pageSize * (pageNo + 1), totalItemsCount);
  const pluralSection = pluralize(section, totalItemsCount);

  return `Showing ${firstItemOnPage} to ${lastItemOnPage} of ${totalItemsCount} ${pluralSection}`;
};
