import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Modal from 'containers/Modals/Modal';
import { modalsActions } from 'containers/Modals/store';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { useSelector } from 'store/rootSelectors';

export interface AppModalProps {
  className?: string;
  backPath?: string;
  isCandidateView?: boolean;
}

const AppModal: React.FC<AppModalProps> = ({ children, ...props }) => {
  const dispatch = useAppDispatch();
  const modalTypes = useSelector((state) => state.ui.modals.modalType);
  const location = useLocation();

  useEffect(() => {
    const modalCandidate = new URLSearchParams(location.search);
    const modalType = modalCandidate.get('modal');
    if (modalType && !modalTypes.includes(modalType)) {
      dispatch(modalsActions.showModal({ modalType }));
    }
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  if (modalTypes.length === 0 && !children) {
    return null;
  }

  return modalTypes?.length ? (
    <>
      {children && <Modal {...props} children={children} />}
      {!children &&
        modalTypes.map((modalType, i) => (
          <Modal
            {...props}
            modalType={modalType}
            key={modalType + '_' + i}
            hideBackDrop={i !== modalTypes.length - 1}
          />
        ))}
    </>
  ) : (
    <Modal {...props} children={children} />
  );
};

export default styled(AppModal)``;
