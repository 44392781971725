import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ExInterviewTemplateQuestion } from 'model';

import { UIKeys } from 'store/constants';

type CompanyInterviewTemplateUiSliceState = ReturnType<typeof initialState>;

const initialState = () => ({
  editQuestionId: null as null | ExInterviewTemplateQuestion['interviewTemplateQuestionId'],
});

export const companyInterviewTemplateUiSlice = createSlice({
  name: UIKeys.companyInterviewTemplate,
  initialState: initialState(),
  reducers: {
    setEditQuestionId: (state, { payload }: PayloadAction<CompanyInterviewTemplateUiSliceState['editQuestionId']>) => {
      state.editQuestionId = payload;
    },
  },
});
