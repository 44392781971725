import { combineReducers } from 'redux';

import { documentsReducer } from 'store/app-files/documents/documents.reducer';
import { resumesReducer } from 'store/app-files/resumes/resumes.reducer';
import { AppFilesKeys } from 'store/constants';

export const appFilesReducers = combineReducers({
  [AppFilesKeys.resumes]: resumesReducer,
  [AppFilesKeys.documents]: documentsReducer,
});
