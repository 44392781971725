import React, { useCallback, useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import styled from 'styled-components/macro';

import { rbacConstants } from 'containers/Auth/AuthMatrix';
import { RBAC } from 'containers/Auth/components/RBAC';
import { EducationFormBody } from 'containers/EducationForm';

import { ExAddNewButton } from 'components/ui/ExAddNewButton/ExAddNewButton';
import { ExButton } from 'components/ui/ExButton';
import { ExCard } from 'components/ui/ExCard';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';
import { getThemeColor } from 'utils/styled/getThemeColor';

import { qualificationTypeActions } from 'store/entities/qualification-type';

type ApplicantModalEducationEditProps = {
  className?: string;
};

const ApplicantModalEducationEditStyled = styled(ExCard)`
  padding: 0;
  box-shadow: none;

  > div:nth-of-type(2) > div {
    background-color: ${getThemeColor('bodyBackgroundColor')};
    padding: 20px;
    border-radius: ${getThemeBorderProps('borderRadius')};
    margin-bottom: 10px;
  }
`;

const useApplicantModalEducationEditState = ({ className }: ApplicantModalEducationEditProps) => {
  const dispatch = useAppDispatch();
  const { fields, remove, append } = useFieldArray({
    name: 'educations',
    keyName: 'educationId',
  });

  const addHandler = () => append({});

  const removeHandler = useCallback((index: number) => () => remove(index), [remove]);

  useEffect(() => {
    dispatch(qualificationTypeActions.qualificationTypeFetch.start({}));
  }, [dispatch]);

  return {
    className,
    fields,
    removeHandler,
    addHandler,
  } as const;
};

export const ApplicantModalEducationEdit: React.FC<ApplicantModalEducationEditProps> = (props) => {
  const { className, addHandler, fields, removeHandler } = useApplicantModalEducationEditState(props);

  return (
    <ApplicantModalEducationEditStyled
      className={className}
      title="Education"
      headerActions={
        <RBAC feature={rbacConstants.candidates.editExperience}>
          <ExAddNewButton onClick={addHandler}>Add New</ExAddNewButton>
        </RBAC>
      }
    >
      {fields.map((field, index) => (
        <div key={field.educationId}>
          <EducationFormBody index={index} value={field} groupName="educations" idFieldName={'educationId'} />
          <div className="w-100"></div>
          <ExButton onClick={removeHandler(index)}>Remove</ExButton>
        </div>
      ))}
    </ApplicantModalEducationEditStyled>
  );
};
