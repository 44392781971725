import { Action } from 'redux';
import { call, put, select } from 'redux-saga/effects';

import { Industry } from 'model';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { getTranslate } from 'utils/i18utils';

import * as fromCompanyActions from 'store/company/company.actions';
import { companySelectors } from 'store/company/company.selectors';
import type { CompanyInfo } from 'store/company/company.types';
import {
  companyUpdateConfirmModal,
  isListEmpty,
  prepareRequestData,
  updateCompanyUtil,
} from 'store/company/company.utils';
import { ModalGeneralResult } from 'store/modals/modals.interfaces';
import { modalSagaWorker } from 'store/modals/modals.sagas';

const successMessagePath = 'company.industries.update.success';

export function* companyUpdateIndustriesSaga(action: Action) {
  /**
   * This variable needs to store modal id in case of network error to restart
   * saga with existed modal instead of rendering the new one.
   */
  let modalId;

  while (true) {
    const result: ModalGeneralResult = yield call(modalSagaWorker, {
      modalType: ModalsTypeKey.companyUpdateIndustries,
      ...(modalId ? { id: modalId } : {}),
    });

    if (result.cancel || !result.confirm) {
      return;
    }

    modalId = result.confirm.payload.id!;

    const confirmPayload = result.confirm.payload;

    const entities = confirmPayload.modalResult as CompanyInfo['countries'];

    const industriesListIsEmpty = yield call(isListEmpty, entities);

    /**
     * If industry list is empty will show alert and restart saga
     */
    if (industriesListIsEmpty) {
      yield put(
        alertsEffects.showWarning({
          message: getTranslate('company.industries.update.listEmpty'),
        }),
      );
      continue;
    }

    yield call(startLoader, action);
    const industries = confirmPayload.modalResult as Industry[];
    const requestData = {
      industries,
    };

    const success = yield call(updateCompanyUtil, modalId, requestData, successMessagePath, 'industries');

    yield call(stopLoader, action);
    if (success) {
      break;
    }
  }
}

export function* removeCompanyIndustry(action: ReturnType<typeof fromCompanyActions.removeCompanyIndustry>) {
  const company: ReturnType<typeof companySelectors.getCompanyInfo> = yield select(companySelectors.getCompanyInfo);
  if (!company) {
    return;
  }
  let modalId: string | undefined;

  if (company.industries.length === 1) {
    yield call(modalSagaWorker, {
      modalType: ModalsTypeKey.confirmModal,
      modalConfig: {
        content: {
          message: 'The last industry can`t be removed.',
          title: 'Information',
          withTitle: true,
          withActions: true,
          buttonOk: null,
          buttonCancel: 'Close',
        },
      },
    });
    return;
  }

  while (true) {
    const result: ModalGeneralResult = yield call(companyUpdateConfirmModal, modalId, 'You confirm remove Industry?');
    if (result.cancel || !result.confirm) {
      return;
    }

    yield call(startLoader, action);
    modalId = result.confirm.payload.id!;

    const { industryId } = action.payload;

    let success = false;

    const newCompanyInfo: CompanyInfo | null = yield call(prepareRequestData, 'industries', {
      filterFunction: (currency) => currency.industryId !== industryId,
    });

    if (newCompanyInfo) {
      success = yield call(updateCompanyUtil, modalId, newCompanyInfo, successMessagePath, 'industries');
    }

    yield call(stopLoader, action);
    if (success) {
      break;
    }
  }
}
