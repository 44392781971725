import { list } from 'config/list';

import { CandidateBelongsTo } from 'store/entities/candidates/models';

import { CandidateListState, InitCandidateListsState } from './models';

export const initialListState: CandidateListState = {
  id: CandidateBelongsTo.main, // this list in not in use
  items: [],
  selectedItems: [],
  pageNo: 0,
  pageSize: list.pageSize,
  pageCount: 0,
  totalItemsCount: 0,
  sortMode: {},
  viewMode: list.viewMode,
  filters: {},
  filter: [],
  fetched: false,
  pagination: {},
};

// candidate talentPool list generates dynamicly

export const initialState: InitCandidateListsState = {
  [CandidateBelongsTo.newApplicant]: {
    ...initialListState,
    id: CandidateBelongsTo.newApplicant,
  },
} as Record<CandidateListState['id'], CandidateListState>;
