import * as yup from 'yup';

import { AvailabilityType, Frequency, SeniorityLevel } from 'model/api-enums.constants';

import { EnumLiteralsOf } from 'utils/funcs';
import { nullToUndefined } from 'utils/validator-helpers';

import { Applicant } from 'store/entities/applicants/models';
import { AvailabilityObject } from 'store/entities/candidates/models';

export const validationSchemaNewApplicantFirstStep: yup.SchemaOf<Pick<Applicant, 'candidateId'>> = yup.object({
  candidateId: yup
    .string()
    .required(({ label }) => `${label} is required.`)
    .label('Candidate'),
});
export const validationSchemaNewApplicantSecondStep: yup.SchemaOf<Pick<Applicant, 'jobId'>> = yup.object({
  jobId: yup
    .string()
    .required(({ label }) => `${label} is required.`)
    .label('Job'),
});

export const validationSchemaNewApplicantThirdStep: yup.SchemaOf<Pick<Applicant, 'resumeId'>> = yup.object({
  resumeId: yup
    .string()
    .required(({ label }) => `${label} is required.`)
    .label('Resumes'),
});
export const validationSchemaNewApplicantFourthStep: yup.SchemaOf<Pick<Applicant, 'documentId'>> = yup.object({
  documentId: yup.string().label('Documents'),
});

export const validationAvailability: yup.SchemaOf<AvailabilityType> = yup
  .mixed()
  .oneOf(
    Object.values(AvailabilityType).filter((value) => !Number.isNaN(Number(value))) as number[],
    `Please select a value from the options.`,
  )
  .transform(nullToUndefined);

export const validationSchemaAvailability: yup.SchemaOf<AvailabilityObject> = yup.object({
  actualDate: yup
    .mixed()
    .label('Specific Date')
    .when('availabilityType', {
      is: (v) => v === AvailabilityType.ActualDate,
      then: yup
        .string()
        .required()
        .typeError(({ label }) => `${label} is required.`),
      otherwise: yup.string().nullable(true),
    })
    .default(null),
  availabilityType: yup
    .mixed<AvailabilityType>()
    .oneOf(
      [...(Object.values(AvailabilityType).filter((value) => !Number.isNaN(Number(value))) as number[]), null],
      `Please select a value from the options.`,
    )
    .default(null),
});

function applicationSalaryValidation(this: any, value: any) {
  const fields = [
    this.parent.salaryExpectationCurrency,
    this.parent.salaryExpectationAmount,
    this.parent.salaryExpectationFrequency,
  ];

  // Validation passed if all fields are empty
  if (!value && fields.every((field) => !field)) {
    return true;
  }

  return Boolean(value);
}

const salaryFieldsMsg = 'Fill all salary fields or leave it empty';

const salaryExpectationCurrency = yup
  .string()
  .default(null)
  .nullable(true)
  .test('salaryCurrencyValidator', 'Fill currency field or leave all Salary fields empty', applicationSalaryValidation);

const salaryExpectationAmount = yup
  .number()
  .label('Salary Expectation Amount')
  .min(0)
  .max(999999999, ({ label }) => `${label} should be less than 999,999,999 or empty`)
  .typeError(({ type }) => `Only ${type} are accepted`)
  .test('salaryAmountValidator', 'Fill currency field or leave all Salary fields empty', applicationSalaryValidation)
  .defined()
  .default(0)
  .transform((v: number, ov: string) => {
    return isNaN(v) && ov.trim() === '' ? 0 : v;
  });

const salaryExpectationFrequency = yup
  .mixed<Frequency | null>()
  .oneOf(
    [...(Object.values(Frequency).filter((value) => !Number.isNaN(Number(value))) as number[]), null],
    `Please select a value from the options.`,
  )
  .default(null)
  .nullable(true)
  .test('salaryValidator', salaryFieldsMsg, applicationSalaryValidation);

type ValidationSchemaNewApplicantFiveStep = yup.SchemaOf<
  Pick<
    Applicant,
    'rating' | 'seniorityLevel' | 'salaryExpectationAmount' | 'salaryExpectationFrequency' | 'salaryExpectationCurrency'
  >
>;
export const validationSchemaNewApplicantFiveStep: ValidationSchemaNewApplicantFiveStep = yup.object({
  rating: yup.number(),
  seniorityLevel: yup
    .mixed<EnumLiteralsOf<typeof SeniorityLevel> | null>()
    .default(null)
    .oneOf([...Object.values(SeniorityLevel), null], `Please select a value from the options.`),
  salaryExpectationCurrency,
  salaryExpectationAmount,
  salaryExpectationFrequency,
  jobApplicantAvailability: yup.lazy<yup.SchemaOf<AvailabilityObject>>((value) => {
    if (!!value && value.availabilityType !== null && value.availabilityType !== undefined) {
      return validationSchemaAvailability.nullable(true);
    }
    return validationSchemaAvailability
      .nullable(true)
      .default(null)
      .transform(() => null);
  }),
  doNotHire: yup.boolean().default(false),
});

export const validationSchemaNewApplicant = validationSchemaNewApplicantFirstStep
  .concat(validationSchemaNewApplicantSecondStep)
  .concat(validationSchemaNewApplicantThirdStep)
  .concat(validationSchemaNewApplicantFourthStep)
  .concat(validationSchemaNewApplicantFiveStep);
