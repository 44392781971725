import { put, takeEvery } from 'redux-saga/effects';

import { JobApplicantFilterStatus } from 'model/api-enums.constants';

import * as fromApplicantListActions from './applicant-lists.actions';
import { applicantListActions } from '.';

function* applicantListsRestFiltersWorker({
  payload,
}: ReturnType<typeof fromApplicantListActions.applicantListResetFiltersAction>) {
  const id = payload.listId;
  yield put(
    applicantListActions.updateFilters({
      id,
      filters: {
        status: JobApplicantFilterStatus.New,
        pipelineStages: '',
        keywords: [],
        sources: [],
        countries: [],
        locations: [],
        availabilities: [],
        employer: undefined,
        education: undefined,
        searchTerm: undefined,
      },
    }),
  );
}

export function* applicantListsSagas() {
  yield takeEvery(fromApplicantListActions.applicantListResetFiltersAction, applicantListsRestFiltersWorker);
}
