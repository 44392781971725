import { list } from 'config/list';

import { JobAdBelongsTo } from 'store/entities/job-ads';

import { InitJobAdListsState, JobAdListState } from './models';

export const initialListState: Omit<JobAdListState, 'id'> = {
  items: [],
  selectedItems: [],
  canBeSelectedIds: [],
  selectMode: undefined,
  pageNo: 0,
  pageSize: list.pageSize,
  pageCount: 0,
  totalItemsCount: 0,
  sortMode: {},
  viewMode: list.viewMode,
  filters: {},
  fetched: false,
  pagination: {},
};

export const initialState: InitJobAdListsState = {
  [JobAdBelongsTo.jobBoard]: { id: JobAdBelongsTo.jobBoard, ...initialListState },
  [JobAdBelongsTo.job]: { id: JobAdBelongsTo.job, ...initialListState },
  [JobAdBelongsTo.company]: { id: JobAdBelongsTo.company, ...initialListState, pageSize: 3 },
} as Record<JobAdListState['id'], JobAdListState>;
