import React from 'react';

import { RBAC } from 'containers/Auth/components/RBAC';

import { Spinner } from 'components/Spinner';
import { ExCardEditButton } from 'components/ui/ExCard/components/ExCardEditButton';
import { ExCardTitle } from 'components/ui/ExCard/ExCardComponents';
import { ExVisible } from 'components/ui/ExVisible';

import { JobSettingsCardContent, JobSettingsCardRedesignStyled } from './JobSettingsCardComponents';
import { JobSettingsCardRedesignProps } from './JobSettingsCardRedesignProps';

export const JobSettingsCardRedesign: React.FC<JobSettingsCardRedesignProps> = ({
  className,
  editAction,
  title,
  rows,
  isAuthUserCanEditJob,
  isLoading = false,
}) => {
  return (
    <JobSettingsCardRedesignStyled
      header={
        <>
          <ExCardTitle>{title}</ExCardTitle>
          <RBAC equalityFn={() => isAuthUserCanEditJob}>
            <ExCardEditButton disabled={isLoading} isLoading={isLoading} onClick={editAction}>
              Edit
            </ExCardEditButton>
          </RBAC>
        </>
      }
      className={className}
    >
      <ExVisible visible={isLoading}>
        <Spinner />
      </ExVisible>
      <ExVisible visible={!isLoading}>
        <JobSettingsCardContent rows={rows} />
      </ExVisible>
    </JobSettingsCardRedesignStyled>
  );
};
