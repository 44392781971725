import * as fromStepDescriptionComponents from 'components/StepForm/components/StepDescription/StepDescriptionComponents';
import { StepDescriptionWithComponents } from 'components/StepForm/components/StepDescription/StepDescriptionProps';

export const StepDescription: StepDescriptionWithComponents = ({ children, ...props }) => {
  return (
    <fromStepDescriptionComponents.StepDescriptionStyled {...props}>
      {children}
    </fromStepDescriptionComponents.StepDescriptionStyled>
  );
};

StepDescription.Description = fromStepDescriptionComponents.Description;
StepDescription.Title = fromStepDescriptionComponents.Title;
