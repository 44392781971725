const screening = '/screening';
const screeningById = '/screening/{questionId}';
const jobByIdScreening = '/job/{jobId}/screening';
const jobByIdScreeningReplace = '/job/{jobId}/screening/replace';
const jobByIdScreeningOrder = '/job/{jobId}/screening/order';
const jobByIdScreeningById = '/job/{jobId}/screening/{questionId}';

export const ScreeningQuestionEndpointsForApi = {
  SearchJobScreeningQuestionsFunc: jobByIdScreening,
  AddJobScreeningQuestionFunc: jobByIdScreening,
  ReplaceJobScreeningQuestionsFunc: jobByIdScreeningReplace,
  UpdateJobScreeningQuestion: jobByIdScreeningById,
  RemoveJobScreeningQuestionFunc: jobByIdScreeningById,
  ReorderJobScreeningQuestionsFunc: jobByIdScreeningOrder,
  SearchScreeningQuestionFunc: screening,
  AddScreeningQuestionFunc: screening,
  GetScreeningQuestionFunc: screeningById,
  UpdateScreeningQuestionFunc: screeningById,
  DeleteScreeningFunc: screeningById,
  SearchJobApplicantScreeningQuestionsFunc: '/job/{jobId}/applicant/{applicantId}/screening',
  SearchPublicJobScreeningQuestionsFunc: '/{companyId}/job/{jobId}/screening',
  UploadPendingJobApplicantFileAnswerFunc: '/{companyId}/applicant/fileAnswer',
} as const;
