import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { JobBoard } from 'store/entities/job-boards/models';

export interface JobBoardFormState {
  validSteps: number[];
  showSteps: boolean;
  formData: Partial<JobBoardSettingsForm>;
  selectedBoardId: JobBoard['jobBoardId'] | null;
}

export type FormJobBoardCaseReducer<T> = CaseReducer<JobBoardFormState, PayloadAction<T>>;

export interface JobBoardSettingsForm extends Partial<Pick<JobBoard, 'jobBoardId' | 'settings'>> {}

export const formJobBoardInitialState: JobBoardFormState = {
  validSteps: [],
  showSteps: true,
  formData: {},
  selectedBoardId: null,
};
