import { useContext } from 'react';
import { StylesConfig } from 'react-select/src/styles';
import { ThemeContext } from 'styled-components';

type SelectSharedStyles =
  | 'menuList'
  | 'control'
  | 'multiValue'
  | 'multiValueLabel'
  | 'multiValueRemove'
  | 'valueContainer'
  | 'option'
  | 'placeholder'
  | 'input';

export function useAppSelectStyles<Keys extends SelectSharedStyles>(
  styles: Array<Keys>,
): Pick<StylesConfig<any, any>, Keys> {
  const theme = useContext(ThemeContext);
  const sharedStylesConfig: StylesConfig<any, any> = {
    menuList: (base) => ({
      ...base,
      maxHeight: '200px',
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: '4px',
    }),
    control: (base) => ({
      ...base,
      borderColor: `${theme.border.borderColor}`,
      borderRadius: '20px',
      fontSize: '14px',
      color: `${theme.colors.primary}`,
      padding: '0',
    }),
    multiValue: () => ({
      borderRadius: '34px',
      backgroundColor: `${theme.colors.primaryLight}`,
      color: `${theme.colors.primary}`,
      padding: '5px 6px',
      margin: '4px',
      fontSize: '12px',
      display: 'flex',
      lineHeight: 1,
    }),
    multiValueLabel: () => ({
      color: `${theme.colors.primary}`,
      fontWeight: 'bold',
    }),
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { display: 'none' } : {};
    },
    valueContainer: (base) => ({
      ...base,
      minHeight: '70px',
      alignItems: 'flex-start',
      padding: '8px 4px',
    }),
    option: (base) => ({
      ...base,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '14px',
      padding: '3px 20px',
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: '14px',
      color: theme.colors.dark,
    }),
    input: (base) => ({
      ...base,
      lineHeight: '1',
    }),
  };
  return styles.reduce<StylesConfig<any, any>>((acc, key) => {
    if (key in sharedStylesConfig) {
      acc[key] = sharedStylesConfig[key];
    }

    return acc;
  }, {});
}
