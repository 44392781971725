import React, { useCallback } from 'react';
import styled from 'styled-components';

import { ExScreeningQuestion } from 'model';

import { CompanyScreeningQuestionHeader } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/CompanyScreeningQuestionsComponents';
import { CompanyScreeningQuestionsList } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/CompanyScreeningQuestionsList';
import {
  ScrollWrapper,
  ScrollWrapperInner,
} from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/CompanyScreeningQuestionsStyles';
import { ScreeningQuestionFormSelect } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionFormSelect';
import { ScreeningQuestionsTheme } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionsTheme';

import { StepFormBody } from 'components/StepForm';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { jobScreeningQuestionsActions } from 'store/entities/job-screening-questions';
import { Job } from 'store/entities/jobs';

export interface JobScreeningQuestionsFormBodyProps extends StepFormBody {
  className?: string;
  formData?: Partial<Job>;
}

export const JobScreeningQuestionsFormBodyStyled = styled.div``;

export const JobScreeningQuestionsFormBody: React.FC<JobScreeningQuestionsFormBodyProps> = ({ className }) => {
  const dispatch = useAppDispatch();

  const theme = {
    gridSize: {
      column1: '25px',
      column2: 'auto',
      column3: '150px',
      column4: '140px',
      column5: '70px',
    },
  };

  const onChange = useCallback(
    (selectedQuestion: ExScreeningQuestion) => {
      dispatch(jobScreeningQuestionsActions.addOne(selectedQuestion));
    },
    [dispatch],
  );

  return (
    <JobScreeningQuestionsFormBodyStyled className={className}>
      <ScreeningQuestionFormSelect onChange={onChange} />
      <ScrollWrapper>
        <ScrollWrapperInner>
          <ScreeningQuestionsTheme theme={theme}>
            <CompanyScreeningQuestionHeader />
            <CompanyScreeningQuestionsList context={'createJobScreeningQuestions'} />
          </ScreeningQuestionsTheme>
        </ScrollWrapperInner>
      </ScrollWrapper>
    </JobScreeningQuestionsFormBodyStyled>
  );
};
