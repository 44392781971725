import { parseISO } from 'date-fns';

import { JobAdResponse } from 'api-endpoints/job-ad/models';

import { AdditionalField } from 'model';

import { pipe } from 'utils/funcs';
import { KeyMap, toStateKeys, TwoWayMap } from 'utils/key-mapper';

import { JobAd } from 'store/entities/job-ads/models';
import { additionalFieldsTransformer } from 'store/entities/job-ads/utils';

const jobAdItemMapper: KeyMap<JobAdResponse, JobAd> = {
  jobAdId: 'id',
  name: 'jobAdName',
};

export const mapToBasicModel = toStateKeys(new TwoWayMap(jobAdItemMapper));

const assignBasicModelKey = (jobAd: JobAd): JobAd => {
  return Object.assign(jobAd, mapToBasicModel(jobAd));
};

const assignCountry = (jobAd: JobAd): JobAd => {
  const country = jobAd.jobAdLocation?.countryCode;
  return Object.assign(jobAd, {
    country,
  });
};

const assignLogo = (jobAdDto: JobAdResponse): Partial<JobAd> => {
  const jobBoardImage = 'jobBoardLogo' in jobAdDto ? jobAdDto.jobBoardLogo : undefined;
  return { ...jobAdDto, jobBoardImage };
};

export const jobAdToState = (jobAd: JobAdResponse & { id?: string }): JobAd =>
  pipe(jobAd, assignLogo, assignBasicModelKey, assignCountry);

export const jobAdParamsAdditionalFieldsMapper = (data: Partial<JobAd>): Partial<JobAd> => {
  return {
    ...data,
    additionalFields:
      data && data.jobBoardId && data.jobBoards
        ? (Object.entries(data.jobBoards?.[data.jobBoardId]).map(additionalFieldsTransformer) as AdditionalField[])
        : [],
  };
};

export const jobAdParamsStartDateMapper = (data: Partial<JobAd>): Partial<JobAd> => {
  return {
    ...data,
    startDate: data.startDate ? parseISO(data.startDate).toISOString() : undefined,
  };
};
