import { Dictionary, EntityAdapter, EntityId } from '@reduxjs/toolkit';

export const adapterHelper = <T>(adapter: EntityAdapter<T>) => ({
  addOne: adapter['addOne'],
  addMany: adapter['addMany'],
  setAll: adapter['setAll'],
  removeOne: adapter['removeOne'],
  removeMany: adapter['removeMany'],
  removeAll: adapter['removeAll'],
  updateOne: adapter['updateOne'],
  updateMany: adapter['updateMany'],
  upsertOne: adapter['upsertOne'],
  upsertMany: adapter['upsertMany'],
});
export const selectIds = (_: any, ids: EntityId[]) => ids;
const selectById = <T extends {}>(entities: Dictionary<T>, id: EntityId) => entities[id];
export const selectByIds = <T extends {}>(entities: Dictionary<T>, ids: EntityId[]) =>
  ids.map(id => selectById(entities, id)).filter(entity => entity !== undefined) as T[];
