import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from 'router';
import { ExRoutes } from 'router/routes';
import styled from 'styled-components';

import { jobAdFormActions, jobAdFormEffects, jobAdFormSelectors } from 'containers/JobAdForms/state';
import { jobBoardListActions } from 'containers/JobBoardLists/store';
import { jobListActions } from 'containers/JobLists/store';
import { loaderSelectors } from 'containers/Loader/store';

import { ReactComponent as Image } from 'assets/img/successful-subscribed-page.svg';

import { ArrowForward, StepComponent } from 'components/StepForm';
import { ExBadge } from 'components/ui/ExBadge';
import { ExBadgeVariant } from 'components/ui/ExBadge/ExBadgeProps';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';

import { JobBoardBelongsTo } from 'store/entities/job-boards';
import { JobBelongsTo } from 'store/entities/jobs';

export interface NewJobAdFinalPageProps extends StepComponent {
  className?: string;
}

const NewJobAdFinalPagePure: React.FC<NewJobAdFinalPageProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const formData = useAppSelector(jobAdFormSelectors.selectFormData);
  const jobBoardIds = formData.jobBoardIds ?? [];
  const additionFieldsView = useAppSelector(jobAdFormSelectors.selectAdditionFieldsView);

  useEffect(() => {
    dispatch(jobAdFormActions.toggleShowSteps({ showSteps: false }));
    dispatch(jobAdFormActions.saveForm({ formData: { jobId: undefined } }));
    return () => {
      dispatch(jobAdFormActions.clearForm({}));
      dispatch(jobAdFormActions.toggleShowSteps({ showSteps: true }));
      dispatch(jobBoardListActions.setItemSelect({ selectedItemId: [], id: JobBoardBelongsTo.newJobAd }));
      dispatch(jobListActions.setItemSelect({ selectedItemId: [], id: JobBelongsTo.newJobAd }));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loading = useAppSelector(loaderSelectors.isTaskActive, {
    taskId: jobAdFormEffects.saveJobAd.processing,
  });

  useEffect(() => {
    if (!loading && !formData.jobAdId) {
      history.push(Routes.jobBoardsFormSelectBoard);
    }
  }, [loading, formData.id, formData, history]);

  return (
    <div className={className}>
      <h3 className="mb-5">New job ads posted</h3>
      <div className="mb-3">
        <Image />
      </div>
      <div className="message">
        You have completed the steps required to post a new job ad. <br />
        This job will also be visible on the company Career Page.
      </div>
      <div className="mb-5">
        {jobBoardIds
          .filter((id) => !!additionFieldsView[id])
          .map((jobBoard) => (
            <div className="row" key={jobBoard}>
              <div className="col-6 text-right">{additionFieldsView[jobBoard].jobBoardName}</div>
              <div className="col-auto text-left">
                {additionFieldsView[jobBoard].postSuccess ? (
                  <ExBadge variant={ExBadgeVariant.success}>Posted</ExBadge>
                ) : (
                  <span>
                    Saved as draft. Try from{' '}
                    <Link
                      className="text-reset"
                      to={[
                        Routes.home,
                        Routes.jobBoards,
                        jobBoard,
                        Routes.jobAds,
                        additionFieldsView[jobBoard].jobAdId,
                        Routes.jobAdStatistics,
                      ].join('/')}
                    >
                      Job Ad page
                    </Link>
                    .
                  </span>
                )}
              </div>
            </div>
          ))}
      </div>
      <h4 className="mb-4">What’s Next?</h4>
      <div className="d-flex justify-content-center">
        <div className="col-6 text-right p-0">
          <ExButton variant="light" forwardedAs={Link} to={['', Routes.dashboard].join('/')}>
            Go to dashboard {<ArrowForward className="ml-3" variant="dark" />}
          </ExButton>
        </div>
        <div className="col-6 text-left pr-0">
          <ExButton variant="success" forwardedAs={Link} to={ExRoutes.jobAdNew()}>
            Create Job Ad {<ArrowForward />}
          </ExButton>
        </div>
      </div>
    </div>
  );
};

export const NewJobAdFinalPage = styled(NewJobAdFinalPagePure)`
  margin: 4% auto 0;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 792px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 473px;
  border-radius: ${getThemeBorderProps('borderRadius')};
  border: 1px solid ${(props) => props.theme.border.borderColor};
  background: white;

  .message {
    font-size: 20px;
    margin-bottom: 20px;
  }
`;
