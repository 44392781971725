import React from 'react';
import styled from 'styled-components';

import DashedBorder from 'assets/img/dashed-border.svg';
import { ReactComponent as UploadIcon } from 'assets/img/upload.svg';

import { UploadButton } from 'components/UploadButton';
import { UploadButtonProps } from 'components/UploadButton/UploadButton';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

import { AppFileCardBasic } from './AppFileCardBasic';

const StyledUploadIcon = styled(UploadIcon)`
  width: 42px;
  height: 28px;
  color: #ccc;
`;

interface AppFileCardNewProps {
  className?: string;
  onChange: UploadButtonProps['onChange'];
  accept?: UploadButtonProps['accept'];
  disabled?: UploadButtonProps['disabled'];
}

const AppFileCardNewBase: React.FC<AppFileCardNewProps> = ({ className, onChange, accept, disabled }) => {
  return (
    <UploadButton onChange={onChange} accept={accept} disabled={disabled}>
      <AppFileCardBasic className={className}>
        <StyledUploadIcon />
        <div className="label-upload">Upload</div>
      </AppFileCardBasic>
    </UploadButton>
  );
};

export const AppFileCardNew = styled(AppFileCardNewBase)`
  .label-upload {
    font-weight: 800;
    font-size: ${getThemeFontSize('large')};
  }

  ${StyledUploadIcon} {
    margin-bottom: 20px;
  }

  border: none;
  background-image: url(${DashedBorder});
`;
