import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { CompanyEmailTemplateFormModal } from 'pages/Company/CompanyTabs/CompanyEmailTemplateTab/components/CompanyEmailTemplateFormModal';

import { modalStyle, modalWrapperStyle } from 'containers/Modals/modals.styled';
import { EditCandidateDetails } from 'containers/Modals/ModalsContent/Candidate';
import { EditCandidateEducationModal } from 'containers/Modals/ModalsContent/Candidate/EditCandidateEducationModal';
import { EditCandidateWorkExperienceModal } from 'containers/Modals/ModalsContent/Candidate/EditCandidateWorkExperienceModal';
import { CompanyUpdateCountries } from 'containers/Modals/ModalsContent/Company';
import { CompanyUpdateIndustries } from 'containers/Modals/ModalsContent/Company/CompanyUpdateIndustries';
import { CompanyUpdateIntegrations } from 'containers/Modals/ModalsContent/Company/CompanyUpdateIntegrations';
import { CompanyUpdateQualificationTypes } from 'containers/Modals/ModalsContent/Company/CompanyUpdateQualificationTypes';
import { ConfirmModal } from 'containers/Modals/ModalsContent/ConfirmModal';
import { JobInterviewTemplateModal } from 'containers/Modals/ModalsContent/InterviewTemplate/JobInterviewTemplateModal';
import { JobEditDetails, JobEditSalary } from 'containers/Modals/ModalsContent/Job';
import { JobDescriptionModal } from 'containers/Modals/ModalsContent/Job/JobDescriptionModal';
import { JobRequirementsModal } from 'containers/Modals/ModalsContent/Job/JobRequirementsModal/JobRequirementsModal';
import { JobResponsibilitiesModal } from 'containers/Modals/ModalsContent/Job/JobResponsibilitiesModal';
import { EditJobAdAdditionalFieldsModal } from 'containers/Modals/ModalsContent/JobAds/EditJobAdAdditionalFieldsModal';
import { EditJobBoardSettings } from 'containers/Modals/ModalsContent/JobBoardsSubscribe';
import { PublicModalConfirmTerms } from 'containers/Modals/ModalsContent/PublicModals/PublicModalConfirmTerms';
import {
  BulkAddCandidateToTalentPool,
  EditTalentPool,
  NewTalentPool,
} from 'containers/Modals/ModalsContent/TalentPool';
import { AddToTalentPool } from 'containers/Modals/ModalsContent/TalentPool/AddToTalentPool';
import { BulkDoNotHireCandidates } from 'containers/Modals/ModalsContent/TalentPool/BulkDoNotHireCandidates';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useNavigation } from 'utils/hooks/useNavigation';

import { exModalCancelAction } from 'store/modals/modals.actions';
import { ExModal } from 'store/modals/modals.interfaces';

import { ApplicantConfirmMoveModal } from './ModalsContent/Applicant/ApplicantConfirmMoveModal';
import { ApplicantDetailsEdit } from './ModalsContent/Applicant/ApplicantDetailsEdit';
import { ApplicantModalV4 } from './ModalsContent/Applicant/ApplicantViewV4';
import { CompanyUpdateCurrencies } from './ModalsContent/Company/CompanyUpdateCurrencies';
import { CompanyUpdateDateTime } from './ModalsContent/Company/CompanyUpdateDateTime';
import { CompanyUpdateOnCostModal } from './ModalsContent/Company/CompanyUpdateOnCostModal';
import { CompanyUpdateQualificationTypesMaps } from './ModalsContent/Company/CompanyUpdateQualificationTypesMaps';
import { HiringPipelineEditModal } from './ModalsContent/Company/HiringPipelineEditModal';
import { HiringPipelineEditNameModal } from './ModalsContent/Company/HiringPipelineEditNameModal';
import { HiringPipelineStageCreateModal } from './ModalsContent/Company/HiringPipelineStageCreateModal';
import { HiringPipelineStageRemoveModal } from './ModalsContent/Company/HiringPipelineStageRemoveModal';
import { InterviewSettingsModal } from './ModalsContent/Interview';
import { InterviewTemplateSettingsModal } from './ModalsContent/InterviewTemplate/InterviewTemplateSettingsModal';
import { JobTeamAddMemberModal } from './ModalsContent/Job/JobTeamAddMemberModal';
import { JobTeamEditModal } from './ModalsContent/Job/JobTeamEditModal';
import { JobTeamRemoveMemberModal } from './ModalsContent/Job/JobTeamRemoveMemberModal';
import { EditJobAdDetailsModal } from './ModalsContent/JobAds/EditJobAdDetailsModal';
import { JobAdDescriptionModal } from './ModalsContent/JobAds/JobAdDescriptionModal';
import { JobAdPostModal } from './ModalsContent/JobAds/JobAdPostModal';
import { JobAdRequirementsModal } from './ModalsContent/JobAds/JobAdRequirementsModal';
import { JobAdResponsibilitiesModal } from './ModalsContent/JobAds/JobAdResponsibilitiesModal';
import { MentionsModal } from './ModalsContent/MentionsModal';
import { JobScreeningQuestionsModal } from './ModalsContent/ScreeningQuestions';
import { Wizard } from './ModalsContent/Wizard/Wizard';
import { AppModalProps, ModalsTypeKey } from './AppModalProps';
import { modalsActions } from './store';

const ModalsType = {
  [ModalsTypeKey.addToTalentPool]: AddToTalentPool,
  [ModalsTypeKey.newTalentPool]: NewTalentPool,
  [ModalsTypeKey.editTalentPool]: EditTalentPool,
  [ModalsTypeKey.editJobDetails]: JobEditDetails,
  [ModalsTypeKey.editJobDescription]: JobDescriptionModal,
  [ModalsTypeKey.editJobResponsibilities]: JobResponsibilitiesModal,
  [ModalsTypeKey.editJobRequirements]: JobRequirementsModal,
  [ModalsTypeKey.editJobSalary]: JobEditSalary,
  [ModalsTypeKey.editJobBoardSettings]: EditJobBoardSettings,
  [ModalsTypeKey.editCandidateDetails]: EditCandidateDetails,
  [ModalsTypeKey.editCandidateEducation]: EditCandidateEducationModal,
  [ModalsTypeKey.editCandidateWorkExperience]: EditCandidateWorkExperienceModal,
  [ModalsTypeKey.editJobAdDetails]: EditJobAdDetailsModal,
  [ModalsTypeKey.editJobAdSalary]: JobEditSalary,
  [ModalsTypeKey.editJobAdAdditionalFields]: EditJobAdAdditionalFieldsModal,
  [ModalsTypeKey.editJobAdResponsibilities]: JobAdResponsibilitiesModal,
  [ModalsTypeKey.editJobAdRequirements]: JobAdRequirementsModal,
  [ModalsTypeKey.editJobAdDescription]: JobAdDescriptionModal,
  [ModalsTypeKey.confirmModal]: ConfirmModal,
  [ModalsTypeKey.wizard]: Wizard,
  [ModalsTypeKey.companyUpdateCountries]: CompanyUpdateCountries,
  [ModalsTypeKey.companyUpdateCurrencies]: CompanyUpdateCurrencies,
  [ModalsTypeKey.companyUpdateIndustries]: CompanyUpdateIndustries,
  [ModalsTypeKey.companyUpdateDateTime]: CompanyUpdateDateTime,
  [ModalsTypeKey.companyUpdateIntegrations]: CompanyUpdateIntegrations,
  [ModalsTypeKey.companyUpdateQualificationTypes]: CompanyUpdateQualificationTypes,
  [ModalsTypeKey.companyUpdateQualificationTypesMaps]: CompanyUpdateQualificationTypesMaps,
  [ModalsTypeKey.companyUpdateOnCost]: CompanyUpdateOnCostModal,
  [ModalsTypeKey.hiringPipelineEditName]: HiringPipelineEditNameModal,
  [ModalsTypeKey.hiringPipelineEdit]: HiringPipelineEditModal,
  [ModalsTypeKey.hiringPipelineStageCreateModal]: HiringPipelineStageCreateModal,
  [ModalsTypeKey.hiringPipelineStageRemoveModal]: HiringPipelineStageRemoveModal,
  [ModalsTypeKey.companyEmailTemplateFormModal]: CompanyEmailTemplateFormModal,
  [ModalsTypeKey.applicantViewModal]: ApplicantModalV4,
  [ModalsTypeKey.applicantDetailsEditModal]: ApplicantDetailsEdit,
  [ModalsTypeKey.jobTeamEditModal]: JobTeamEditModal,
  [ModalsTypeKey.jobTeamAddMemberModal]: JobTeamAddMemberModal,
  [ModalsTypeKey.jobTeamRemoveMemberModal]: JobTeamRemoveMemberModal,
  [ModalsTypeKey.jobAdPostModal]: JobAdPostModal,
  [ModalsTypeKey.publicModalConfirmTerms]: PublicModalConfirmTerms,
  [ModalsTypeKey.mentionsModal]: MentionsModal,
  [ModalsTypeKey.interviewTemplateSettingModal]: InterviewTemplateSettingsModal,
  [ModalsTypeKey.newInterviewSettingsModal]: InterviewSettingsModal,
  [ModalsTypeKey.updateJobInterviewTemplateModal]: JobInterviewTemplateModal,
  [ModalsTypeKey.bulkAddCandidateToTalentPool]: BulkAddCandidateToTalentPool,
  [ModalsTypeKey.bulkDoNotHireCandidates]: BulkDoNotHireCandidates,
  [ModalsTypeKey.applicantConfirmMoveModal]: ApplicantConfirmMoveModal,
  [ModalsTypeKey.jobScreeningQuestionsModal]: JobScreeningQuestionsModal,
};

const Modal: React.FC<AppModalProps> = ({ className, children, backPath, modalType, ...rest }) => {
  const modalRootRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();

  const { push } = useNavigation();

  const closeModal = () => {
    push({ path: backPath as string, config: { searchKeys: ['status'] } });
    dispatch(exModalCancelAction({ ...(rest as ExModal) }));
    dispatch(modalsActions.hideModal());
  };

  if (!modalType && !children) {
    return null;
  }

  const modal = children || ModalsType[modalType as ModalsTypeKey];

  if (!modal) {
    return null;
  }
  return createPortal(
    <div ref={modalRootRef} className={className}>
      {React.createElement(modal, { onClose: closeModal, modalType, ...rest })}
    </div>,
    document.body,
  );
};

export default styled(Modal)`
  ${modalWrapperStyle};

  > div {
    ${modalStyle};
  }
`;
