import { createSelector } from '@reduxjs/toolkit';

import { UIKeys } from 'store/constants';
import { uiDomain } from 'store/rootSelectors';

import { TipVariants } from './tips.reducer';

const domain = createSelector(uiDomain, (state) => state[UIKeys.tips]);

const selectIsApplicantModalKeyboardTipShow = createSelector(
  domain,
  (state) => state[TipVariants.applicantModalKeyboard],
);

const selectIsInterviewEditPopupShow = createSelector(domain, (state) => state[TipVariants.interviewEditPopup]);

export const tipsSelectors = {
  selectIsApplicantModalKeyboardTipShow,
  selectIsInterviewEditPopupShow,
};
