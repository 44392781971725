import React, { useMemo } from 'react';
import styled from 'styled-components';

import { CandidateAvatarProps } from 'components/CandidateAvatar/CandidateAvatarTypes';

const CandidateAvatarImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  position: relative;
  z-index: 5;
`;

const CANDIDATE_AVATAR_PLACEHOLDER = '/img/person-placeholder.svg';

export const CandidateAvatarImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

const CandidateAvatarInitials = styled.svg`
  background: #eee;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-weight: 600;
  border-radius: 50%;
  color: ${(props) => props.theme.colors.blackHeadings};
`;

function useCandidateAvatarState({ className, src, alt, title, initials }: CandidateAvatarProps) {
  const candidateHasAvatar = useMemo(() => {
    return Boolean(src);
  }, [src]);

  const candidateAvatar = useMemo(() => {
    if (candidateHasAvatar) {
      return src;
    }

    if (initials) {
      return null;
    }

    return CANDIDATE_AVATAR_PLACEHOLDER;
  }, [candidateHasAvatar, initials, src]) as string;

  return { alt, title, className, candidateAvatar, initials } as const;
}

export const CandidateAvatar: React.FC<CandidateAvatarProps> = (props) => {
  const { className, title, alt, candidateAvatar, initials } = useCandidateAvatarState(props);

  return (
    <CandidateAvatarImageWrapper className={className}>
      {candidateAvatar && <CandidateAvatarImage src={candidateAvatar} alt={alt} title={title} />}
      {initials && !candidateAvatar && (
        <CandidateAvatarInitials viewBox="0 0 40 40">
          <text x="20" y="22" dominantBaseline="middle" textAnchor="middle">
            {initials}
          </text>
        </CandidateAvatarInitials>
      )}
    </CandidateAvatarImageWrapper>
  );
};
