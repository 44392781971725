import { useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import { applicantFormActions, applicantFormSelectors } from 'containers/ApplicantForm/ApplicantFormState';
import { formApplicantKey } from 'containers/ApplicantForm/ApplicantFormState/applicant-form.reducer';
import { candidateListActions } from 'containers/CandidateLists/store';
import { jobListActions } from 'containers/JobLists/store';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { documentsActions } from 'store/app-files/documents/documents.reducer';
import { resumesActions } from 'store/app-files/resumes/resumes.reducer';
import { candidatesApi } from 'store/entities/candidates/candidates.api';
import { CandidateBelongsTo } from 'store/entities/candidates/models';
import { loadJobPage } from 'store/entities/jobs/job.actions';
import { JobBelongsTo } from 'store/entities/jobs/models';
import { useSelector } from 'store/rootSelectors';

export function useMountHook() {
  const dispatch = useAppDispatch();
  const formData = useSelector(applicantFormSelectors.selectFormData);

  candidatesApi.endpoints.candidate.useQuery(formData.candidateId ?? skipToken);

  useEffect(() => {
    const { jobId } = formData ?? {};
    if (jobId) {
      dispatch(loadJobPage({ jobId }));
    }

    return () => {
      dispatch(applicantFormActions.resetState({}));
      dispatch(jobListActions.resetListState({ id: JobBelongsTo.newApplicant }));
      dispatch(candidateListActions.resetListState({ id: CandidateBelongsTo.newApplicant }));
      if (formData.candidateId) {
        const listName = formApplicantKey + formData.candidateId;
        dispatch(resumesActions.setSelected({ selectedIds: [], listName }));
        dispatch(documentsActions.setSelected({ selectedIds: [], listName }));
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
