import React, { useRef } from 'react';
import styled from 'styled-components';

export interface UploadButtonProps {
  onChange: (files: File[]) => void;
  className?: string;
  multiple?: boolean;
  accept?: string;
  disabled?: boolean;
}

const UploadButton: React.FC<UploadButtonProps> = ({
  className,
  onChange,
  multiple,
  children,
  accept = '.pdf,.doc,.docx',
  disabled = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handlerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    inputRef.current?.click();
  };
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files?.length) {
      const postFiles = Array.from(files);
      onChange(postFiles);
    }
    e.target.value = '';
  };
  return (
    <div onClick={handlerClick} className={className}>
      <input
        ref={inputRef}
        hidden
        type="file"
        onChange={onChangeHandler}
        multiple={multiple}
        accept={accept}
        disabled={disabled}
      />
      {children}
    </div>
  );
};

export default styled(UploadButton)``;
