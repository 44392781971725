import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { format } from 'date-fns';

import { dateFormat } from 'config/date';

import { Sector } from 'model';

import { getJobAdEditConfigByJobBoardName } from 'containers/JobAdForms/jobAdEditConfig';
import { jobAdFormSelectors } from 'containers/JobAdForms/state';

import { FormDate } from 'components/FormDate';
import { FormInput } from 'components/FormInput';
import { FormSelect } from 'components/FormSelect';
import { LocationServiceForm } from 'components/LocationServiceForm';
import { StepFormBody } from 'components/StepForm';
import { ExVisible } from 'components/ui/ExVisible';
import { useEmploymentTypeOptions } from 'utils/hooks/formsHooks/useEmploymentTypeOptions';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { useCountryQuerySelectors } from 'store/dictionary/hooks/useCountryQuerySelectors';
import { useSectorQuerySelectors } from 'store/dictionary/hooks/useSectorQuerySelectors';
import { JobAd } from 'store/entities/job-ads';
import { JobBoard, jobBoardActions, jobBoardSelectors } from 'store/entities/job-boards';

export interface JobAdGeneralFormBodyProps extends StepFormBody {
  className?: string;
  formData?: Partial<JobAd>;
}

const JobAdGeneralFormBody: React.FC<JobAdGeneralFormBodyProps> = ({ isStep }) => {
  const { getValues, setValue, errors, register, formState, watch } = useFormContext<JobAd>();
  const validated = formState.isSubmitted;
  const { selectors: sectorsSelectors } = useSectorQuerySelectors();
  const sectors = useAppSelector(sectorsSelectors.selectAll);
  const selectedJobBoards = useAppSelector(jobAdFormSelectors.selectSelectedJobBoards);

  const formJobBoardId = getValues('jobBoardId');
  const jobBoard = useAppSelector(jobBoardSelectors.selectById, formJobBoardId);
  const controlClassNames = isStep ? 'col-lg-6 col-xl-4' : 'col-md-6';

  const country = watch('country') || '';

  const { selectors: countriesSelectors } = useCountryQuerySelectors();
  const countryByExpedoCode2 = useAppSelector(countriesSelectors.selectByExpedoCode2, country);

  const jobBoardsWithPostingDuration = useMemo(() => {
    const jobBoards = jobBoard ? [jobBoard] : selectedJobBoards;

    return jobBoards.filter((board) => {
      const config = getJobAdEditConfigByJobBoardName(board?.name);

      return config?.showPostingDuration;
    });
  }, [jobBoard, selectedJobBoards]);

  useEffect(() => {
    const id = getValues('id');
    if (!id) {
      setValue('startDate', format(new Date(), dateFormat.formatDateForDateInput));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { jobAdName, jobName } = getValues(['jobAdName', 'jobName']);

    if (!jobAdName && jobName) {
      setValue('jobAdName', jobName);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    register('jobAdCountry');
    setValue('jobAdCountry', countryByExpedoCode2);
  }, [register, country, setValue, countryByExpedoCode2]);

  const employmentType = useEmploymentTypeOptions();

  return (
    <>
      <div className="row">
        <input type="string" hidden name="id" ref={register} />
        <input type="string" hidden name="description" ref={register} />
        <input type="string" hidden name="jobBoardId" ref={register} />

        <FormInput
          className={controlClassNames}
          label="Job Ad Name"
          name="jobAdName"
          required
          errors={errors.jobAdName}
          validated={validated}
          inputRef={register}
        />
        <FormSelect
          className={controlClassNames}
          label="Sector"
          name="sector"
          placeholder=""
          required
          options={sectors}
          errors={errors?.sector}
          validated={validated}
          inputRef={register}
          defaultOptions={sectors}
          getOptionLabel={(option: Sector) => option.name}
          getOptionValue={(option: Sector) => Number(option.id)}
        />
        <FormSelect
          className={controlClassNames}
          label="Employment type"
          name="employmentType"
          placeholder=""
          errors={errors?.employmentType}
          validated={validated}
          inputRef={register}
          required
          options={employmentType}
          defaultOptions
          isSearchable={false}
        />
        <LocationServiceForm
          countryClassNames={controlClassNames}
          locationClassNames={controlClassNames}
          countryFieldName="country"
          locationFieldName="jobAdLocation"
          countryFieldRequired
          locationFieldRequired
          countriesSource="companyCountry"
          isDisabled
        />
        <FormDate
          className={controlClassNames}
          label="Start Date"
          name="startDate"
          min={new Date()}
          errors={errors?.startDate}
          validated={validated}
          inputRef={register}
        />
        {jobBoardsWithPostingDuration?.map((jb) => (
          <PostingDurationField key={jb.jobBoardId} controlClassNames={controlClassNames} jobBoardId={jb.jobBoardId} />
        ))}
      </div>
    </>
  );
};

const PostingDurationField = ({
  jobBoardId,
  controlClassNames,
}: {
  jobBoardId: JobBoard['jobBoardId'];
  controlClassNames: string;
}) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (jobBoardId) {
      dispatch(jobBoardActions.get({ jobBoardId }));
    }
  }, [dispatch, jobBoardId]);
  const jobBoard = useAppSelector(jobBoardSelectors.selectById, jobBoardId);

  if (
    !jobBoard?.postingDuration?.title ||
    jobBoard.postingDuration.value === null ||
    jobBoard.postingDuration.value === undefined
  ) {
    return null;
  }
  const value = {
    key: jobBoard.postingDuration.value,
    value: jobBoard.postingDuration.title,
  };

  const showField = !!value.value;

  return (
    <ExVisible visible={showField}>
      <FormSelect
        className={controlClassNames}
        label={`Posting Duration: ${jobBoard?.name}`}
        options={[value]}
        getOptionLabel={(option) => option.value}
        getOptionValue={(option) => option.key}
        name={`postingDuration[${jobBoardId}]`}
        value={value.key}
        isDisabled
      />
    </ExVisible>
  );
};

export default styled(JobAdGeneralFormBody)``;
