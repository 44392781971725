import { compose, PayloadAction } from '@reduxjs/toolkit';

import { OrderDirection } from 'model/api-enums.constants';

export * from 'utils/api/api.sort.utils';

const payloadMapper = (params: any | PayloadAction) => {
  if (params && params.hasOwnProperty('type')) {
    return params.payload;
  }

  return params;
};

export function requestMapperUtil<R>(mappers?: Function[]) {
  let mappersForComposition: Function[] = [payloadMapper];
  if (mappers) {
    mappersForComposition = [...mappers, ...mappersForComposition];
  }

  return compose<R>(...mappersForComposition);
}

export function responseMapperUtil<R>(mappers?: Function[]) {
  let mappersForComposition: Function[] = [];
  if (mappers) {
    mappersForComposition = [...mappers, ...mappersForComposition];
  }

  return compose<R>(...mappersForComposition);
}

export function findNewOrderDirection<F extends { orderBy: string | number; orderDirection: OrderDirection }>(
  filters: F,
  arg: Pick<F, 'orderBy'>,
) {
  return filters.orderBy === arg.orderBy && filters.orderDirection === OrderDirection.Ascending
    ? OrderDirection.Descending
    : OrderDirection.Ascending;
}
