import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as EditIcon } from 'assets/img/edit.svg';

import { DetailsRowProps } from 'components/DetailsCard';
import { Ellipsis } from 'components/Ellipsis';
import { FormCard } from 'components/FormCard';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

export interface JobAdDetailsCardProps {
  className?: string;
  rows: DetailsRowProps[];
  title?: string;
  editUrl?: string;
  editAction?: (event: React.MouseEvent) => void;
  isCard?: boolean;
}

const JobAdDetailsCard: React.FC<JobAdDetailsCardProps> = ({ className, rows, title, editUrl, editAction, isCard }) => {
  return (
    <div className={className}>
      <div className="header-wrapper">
        {title && <div className="title">{title}</div>}
        {editUrl && (
          <div className="ml-auto edit-text">
            <Link to={`${editUrl}`} className="text-reset" onClick={editAction}>
              <EditIcon /> Edit
            </Link>
          </div>
        )}
      </div>
      {isCard && (
        <div className="overflow-hidden">
          <FormCard.FormCardBodyDivider />
        </div>
      )}
      <div className="row">
        {rows.map((row, i) => (
          <div key={row.label + '_' + row.value + '_' + i} className="col-md-4 details-row">
            <div className="font-weight-bold mb-2">{row.label}</div>
            <div>
              <Ellipsis title={row.value?.toString()}>{row.value || <small>--</small>}</Ellipsis>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default styled(JobAdDetailsCard)`
  position: relative;

  .title {
    font-size: ${getThemeFontSize('cardHeading')};
    font-weight: bold;
  }

  > .header-wrapper {
    ${(props) => (!props.title ? 'float: right;' : undefined)};
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .details-row {
    margin-bottom: 1.5rem;
  }
`;
