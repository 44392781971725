import { useCallback } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

import { ExInterview } from 'model';

import { useModal } from 'utils/hooks/useModal';

import { useInterviewQuery, useInterviewRemoveMutation } from 'store/entities/interview/interview.api';

type useInterviewRemoveParams = {
  interview?: ExInterview;
  interviewId?: ExInterview['interviewId'];
};

export function useInterviewRemove({ interview, interviewId }: useInterviewRemoveParams) {
  const { data } = useInterviewQuery(interviewId ?? skipToken, { skip: !!interview });
  const [removeInterview, removeMeta] = useInterviewRemoveMutation();
  const { show } = useModal({});
  const removeInterviewWithConfirmModal = useCallback(async () => {
    const interviewForRemove = interview ?? data;

    if (!interviewForRemove) {
      return;
    }
    const result = await show({
      title: 'Confirm Remove',
      withTitle: true,
      message: `You confirm remove the interview\n "${interviewForRemove.name}?"`,
      buttonOkVariant: 'danger',
      buttonOk: 'Remove',
      withActions: true,
    });

    if (result) {
      removeInterview(interviewForRemove);
    }
  }, [data, interview, removeInterview, show]);

  return [removeInterviewWithConfirmModal, removeMeta] as const;
}
