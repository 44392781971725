import React from 'react';
import { useSelector } from 'react-redux';

import { applicantFormSelectors } from 'containers/ApplicantForm';

import { SubTitleItem } from 'components/FormStepsPanel';
import { useCandidateFullName } from 'utils/hooks/candidate';

import { Candidate } from 'store/entities/candidates/models';

export const SelectedJob: React.FC = () => {
  const selectedJob = useSelector(applicantFormSelectors.selectJob);
  return <SubTitleItem>{selectedJob?.jobName}</SubTitleItem>;
};

export const SelectedCandidate: React.FC = () => {
  const selectedCandidate = useSelector(applicantFormSelectors.selectCandidate);
  const candidateFullName = useCandidateFullName(selectedCandidate || ({} as Candidate));

  return <SubTitleItem>{candidateFullName}</SubTitleItem>;
};
