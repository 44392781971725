import * as jobAdApi from 'api-endpoints/job-ad';
import * as apiJobBoard from 'api-endpoints/job-board';
import { getJobBoardAdditionalFieldsFuncTransformer } from 'api-endpoints/job-board/transformer';

import { ApiResponseWithPagination } from 'model';
import { WithRequired } from 'model/utils';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { jobAdFormSelectors } from 'containers/JobAdForms/state';
import { showSingleErrorForJobAd } from 'containers/JobAdForms/state/effects';
import { loaderActions } from 'containers/Loader/store';

import { startLoaderThunk, stopLoaderThunk } from 'modules/LoaderManager/redux';
import { formBuilderTransformer } from 'utils/form-builder-transformer';
import { removeNullAndUndefinedPropertiesFromObject } from 'utils/funcs';

import { authSelectors } from 'store/auth/auth.selectors';
import { JobBoard, jobBoardActions, jobBoardSelectors } from 'store/entities/job-boards';
import { AppEffectParams, AppThunk } from 'store/types';

import { jobAdActions } from './index';
import { enhancedJobAdsApi, JOB_AD_TAG_TYPE } from './job-ads.api';
import { jobAdToState } from './mappers';
import { JobAd, JobAdLog } from './models';
import { jobAdSelectors } from './selectors';

export const loadJobAdTaskId = 'loadJobAdTaskId';

export const loadJobAd =
  ({ jobAdId, preloader }: AppEffectParams<{ jobAdId: JobAd['id'] }>): AppThunk<Promise<any>> =>
  async (dispatch) => {
    if (preloader) {
      dispatch(loaderActions.start(loadJobAdTaskId));
    }
    const { data, message } = await jobAdApi.getJobAd(jobAdId);
    if (message) {
      if (preloader) {
        dispatch(loaderActions.stop(loadJobAdTaskId));
      }
      return dispatch(alertsEffects.showError({ message }));
    }
    const preparedJobAd = jobAdToState({ ...data, id: jobAdId });
    dispatch(jobAdActions.upsertOne({ item: preparedJobAd }));
    if (preloader) {
      dispatch(loaderActions.stop(loadJobAdTaskId));
    }
  };

export const loadApplicantsPerPipelineStageFiguresTaskId = 'loadApplicantsPerPipelineStageFiguresTaskId';

export const loadApplicantsPerPipelineStageFigures =
  ({ jobAdId, preloader }: AppEffectParams<{ jobAdId: JobAd['id'] }>): AppThunk =>
  async (dispatch) => {
    startLoaderThunk(dispatch, loadApplicantsPerPipelineStageFigures);
    if (preloader) {
      dispatch(loaderActions.start(loadApplicantsPerPipelineStageFiguresTaskId));
    }
    const { data, message } = await jobAdApi.getJobAdApplicantsPerPipelineStageFigures(jobAdId);
    if (message) {
      if (preloader) {
        dispatch(loaderActions.stop(loadApplicantsPerPipelineStageFiguresTaskId));
      }
      return dispatch(alertsEffects.showError({ message }));
    }
    dispatch(
      jobAdActions.upsertOne({
        item: {
          ...data,
          id: jobAdId,
        },
      }),
    );
    if (preloader) {
      dispatch(loaderActions.stop(loadApplicantsPerPipelineStageFiguresTaskId));
    }
    stopLoaderThunk(dispatch, loadApplicantsPerPipelineStageFigures);
  };

export const loadApplicantsPerDayFiguresTaskId = 'loadApplicantsPerDayFiguresTaskId';

export const loadApplicantsPerDayFigures =
  ({ jobAdId, preloader }: AppEffectParams<{ jobAdId: JobAd['id'] }>): AppThunk =>
  async (dispatch) => {
    startLoaderThunk(dispatch, loadApplicantsPerDayFigures);
    if (preloader) {
      dispatch(loaderActions.start(loadApplicantsPerDayFiguresTaskId));
    }
    const { data, message } = await jobAdApi.getJobAdApplicantsPerDayFigures(jobAdId);
    if (message) {
      if (preloader) {
        dispatch(loaderActions.stop(loadApplicantsPerDayFiguresTaskId));
      }
      return dispatch(alertsEffects.showError({ message }));
    }

    dispatch(jobAdActions.upsertOne({ item: { ...data, id: jobAdId } }));
    if (preloader) {
      dispatch(loaderActions.stop(loadApplicantsPerDayFiguresTaskId));
    }
    stopLoaderThunk(dispatch, loadApplicantsPerDayFigures);
  };

export const updateJobAdStatus =
  ({
    jobAdId,
    action,
    preloader,
  }: AppEffectParams<{
    jobAdId: JobAd['id'];
    action: 'post' | 'cancel' | 'repost' | 'logs';
  }>): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    if (preloader) {
      startLoaderThunk(dispatch, updateJobAdStatus);
    }

    const apiMethod = {
      cancel: jobAdApi.cancelJobAd,
      logs: jobAdApi.logsJobAd,
      post: jobAdApi.postJobAd,
      repost: jobAdApi.repostJobAd,
    };
    await dispatch(loadJobAd({ jobAdId, preloader: false }));
    await dispatch(prepareFormData({ jobAdId, preloader: false }));
    await dispatch(updateFormData({ jobAdId, preloader: false }));
    const formJobAd = jobAdFormSelectors.selectFormData(getState());
    const jobAd = jobAdSelectors.getById(getState(), jobAdId) as WithRequired<JobAd, 'jobAdId'>;

    const companyId = authSelectors.selectCurrentCompanyId(getState());

    if (!companyId) {
      return false;
    }

    const existedJobAd = jobAd ?? formJobAd;
    const jobAdRequestBody = {
      companyId,
      jobAdId,
      ...(action === 'post' ? existedJobAd : {}),
    };

    removeNullAndUndefinedPropertiesFromObject(jobAdRequestBody);

    const { message, data, response } = await apiMethod[action](jobAdRequestBody);
    if (preloader) {
      stopLoaderThunk(dispatch, updateJobAdStatus);
    }
    if (message) {
      showSingleErrorForJobAd({ dispatch, response, showModal: true });
    } else if (!['cancel', 'logs'].includes(action)) {
      if (action === 'repost') {
        dispatch(alertsEffects.showSuccess({ message: 'Job ad has been updated' }));
      }
      dispatch(loadJobAd({ jobAdId, preloader: true }));
      dispatch(enhancedJobAdsApi.util.invalidateTags([{ type: JOB_AD_TAG_TYPE, id: jobAdId }]));
    } else if (action === 'cancel') {
      dispatch(enhancedJobAdsApi.util.invalidateTags([{ type: JOB_AD_TAG_TYPE, id: jobAdId }]));
    } else if (action === 'logs' && data && data.items) {
      dispatch(jobAdActions.upsertOne({ item: { id: jobAdId, logs: data.items } }));
    }
    return !message;
  };

export const jobAdLogsStatusTaskId = 'updateJobAdStatusTaskId';

export const getLogs =
  ({
    jobAdId,
    pageNo,
    preloader,
  }: AppEffectParams<{ jobAdId: JobAd['id']; pageNo: number }>): AppThunk<
    Promise<ApiResponseWithPagination<JobAdLog> | undefined>
  > =>
  async (dispatch, getState) => {
    const companyId = authSelectors.selectCurrentCompanyId(getState());

    if (!companyId) {
      return;
    }

    if (preloader) {
      dispatch(loaderActions.start(jobAdLogsStatusTaskId));
    }

    const { message, data } = await jobAdApi.logsJobAd({
      companyId,
      jobAdId,
      params: {
        pageNo,
        pageSize: 18,
      },
    });

    if (message) {
      dispatch(alertsEffects.showError({ message }));
    }

    if (preloader) {
      dispatch(loaderActions.stop(jobAdLogsStatusTaskId));
    }

    return data;
  };

export const prepareFormDataTaskId = 'prepareFormDataTaskId';

export const prepareFormData =
  ({ preloader, jobAdId }: AppEffectParams<{ jobAdId: JobAd['id'] }>): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    if (preloader) {
      dispatch(loaderActions.start(prepareFormDataTaskId));
    } else {
      dispatch(loaderActions.startSilent(prepareFormDataTaskId));
    }
    const jobAd = jobAdSelectors.getById(getState(), jobAdId) as JobAd;
    const location = jobAd?.jobAdLocation?.placeName;
    const jobId = jobAd?.jobId;

    const { data: pureData, message } = await apiJobBoard.getJobBoardAdditionalFields({
      urlParams: { jobBoardId: jobAd.jobBoardId! },
      params: { location, jobId },
    });

    const data = getJobBoardAdditionalFieldsFuncTransformer(pureData);

    if (message) {
      return;
    }

    if (data?.additionalFields && jobAd.additionalFields) {
      const { resultForForm, resultForView } = formBuilderTransformer(data.additionalFields, jobAd.additionalFields);

      dispatch(
        jobAdActions.updateOne({
          ...jobAd,
          additionalFieldsView: resultForView,
          jobBoards: {
            [jobAd.jobBoardId!]: resultForForm,
          },
        }),
      );
      dispatch(
        jobBoardActions.updateOne({
          changes: { ...data },
          id: jobAd.jobBoardId!,
        }),
      );
    }
    dispatch(loaderActions.stop(prepareFormDataTaskId));
  };

export const updateFormData =
  ({ jobAdId }: AppEffectParams<{ jobAdId: JobAd['id'] }>): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const jobAd = jobAdSelectors.getById(getState(), jobAdId) as JobAd;
    const jobBoard = jobBoardSelectors.selectById(getState(), jobAd.jobBoardId!) as JobBoard;

    if (jobBoard?.additionalFields && jobAd?.additionalFields) {
      const { resultForForm, resultForView } = formBuilderTransformer(
        jobBoard.additionalFields,
        jobAd.additionalFields,
      );

      dispatch(
        jobAdActions.updateOne({
          ...jobAd,
          additionalFieldsView: resultForView,
          jobBoards: {
            [jobAd.jobBoardId!]: resultForForm,
          },
        }),
      );
      dispatch(
        jobBoardActions.updateOne({
          changes: { ...jobBoard },
          id: jobAd.jobBoardId!,
        }),
      );
    }
  };
