import React, { useState } from 'react';

import { apiConfig } from 'config';

import { getJobsApiMethod } from 'api-endpoints/job';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { bulkUploadResumes } from 'containers/BulkCreation/bulkCreation.actions';
import { useBulkDropZone } from 'containers/BulkCreation/utils';
import { ModalBodyStyled } from 'containers/Modals/ModalsContent/Wizard';

import { FormSelect } from 'components';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useReactSelectDebounceHandler } from 'utils/hooks/useReactSelectDebounce';

import { Job } from 'store/entities/jobs';
import { ModalStepProps } from 'store/modals/config';

import { BulkCreateModalFooter, BulkCreateSelectOptionWithReference } from './BulkSharedComponents';

const useBulkCreateApplicantsState = ({ onCloseDialogHandler }: ModalStepProps) => {
  const dispatch = useAppDispatch();

  const [selectedJob, setSelectedJob] = useState<Job | null>(null);

  const noOptionsLocationHandler = ({ inputValue }: { inputValue: string }) => {
    if (inputValue.length === 0) {
      return 'Start typing';
    }
    if (inputValue.length < 3) {
      return 'Enter at least three characters';
    }
    return 'No Options';
  };

  const loadEntitiesHandler = async (searchTerm: string) => {
    if (!searchTerm || searchTerm?.length < 3) {
      return;
    }

    const result = await getJobsApiMethod({
      params: {
        searchTerm,
        jobStatuses: 'open',
        pageSize: 100,
      } as any,
    });

    const payload = result.data?.items;
    const isPayloadValid = Boolean(payload?.length);
    return isPayloadValid ? payload : [];
  };

  const debouncedLoadOptions = useReactSelectDebounceHandler({
    asyncLoader: loadEntitiesHandler,
    delay: apiConfig.debounceTime,
  });

  const { files, ...dropZoneMethods } = useBulkDropZone();

  const continueClickHandler: React.MouseEventHandler = () => {
    let message = '';
    if (files.length === 0) {
      message = 'Select at least on file.';
    }
    if (!selectedJob) {
      message = 'Select Job.';
    }
    if (message) {
      dispatch(alertsEffects.showError({ message }));
      return;
    }
    if (selectedJob) {
      dispatch(
        bulkUploadResumes({
          files,
          selectedJob,
        }),
      );
    }
  };

  return {
    ...dropZoneMethods,
    files,
    continueClickHandler,
    loadEntitiesHandler: debouncedLoadOptions,
    selectedJob,
    setSelectedJob,
    onCloseDialogHandler,
    noOptionsLocationHandler,
  };
};

export const BulkCreateApplicants: React.FC<ModalStepProps> = (props) => {
  const {
    continueClickHandler,
    loadEntitiesHandler,
    selectedJob,
    setSelectedJob,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
    files,
    acceptedFiles,
    getRootProps,
    onRemoveFile,
    onCloseDialogHandler,
    getInputProps,
    noOptionsLocationHandler,
    isShowSelectedFiles,
  } = useBulkCreateApplicantsState(props);

  return (
    <ModalBodyStyled>
      <FormSelect
        label="Select an open Job"
        name="job"
        required
        loadOptions={loadEntitiesHandler}
        noOptionsMessage={noOptionsLocationHandler}
        getOptionLabel={(option: Job) => option.jobName}
        getOptionValue={(option: Job) => option.jobId}
        defaultValue={selectedJob as any}
        onChange={(selected: Job) => setSelectedJob(selected)}
        components={{
          Option: BulkCreateSelectOptionWithReference,
          Placeholder: () => null,
          IndicatorSeparator: null,
        }}
      />
      <BulkCreateModalFooter
        {...{
          files,
          onClose: () => onCloseDialogHandler(),
          onContinue: continueClickHandler,
          getInputProps,
          isDragAccept,
          getRootProps,
          isDragActive,
          isDragReject,
          isShowSelectedFiles,
          onRemoveFile,
          isImageSelected: !!acceptedFiles[0],
          open,
        }}
      />
    </ModalBodyStyled>
  );
};
