import { FilterType } from 'containers/Calendar/state/calendar-ui/calendar-ui.model';

export enum CalendarTargetDate {
  Week = 1,
  Month = 2,
  Year = 3,
}

export type CalendarEventDto = {
  id: string;
  title: string;
  jobName: string;
  jobBoardName?: string;
  start: string;
  end: string;
  type: FilterType;
  allDay: boolean;
};
