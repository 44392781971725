import { ExInterviewTemplate } from 'model';

import { InterviewTemplateEditFormCaseReducer } from 'containers/InterviewTemplateForms/InterviewTemplateEditForm/state/InterviewTemplateEditForm.models';

export const interviewTemplateEditFormUpdateTemplateReducer: InterviewTemplateEditFormCaseReducer<
  Partial<ExInterviewTemplate>
> = (draft, { payload }) => {
  if (draft.formData.interviewTemplate) {
    Object.assign(draft.formData.interviewTemplate, payload);
  }
};
