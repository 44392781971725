import React, { useEffect } from 'react';
import { useFragmentLayout } from 'layouts';
import { useAppRouterParams } from 'router';
import styled from 'styled-components/macro';

import { modalsActions } from 'containers/Modals/store';

import { InterviewQuestionnaireTemplate } from 'components/ApplicantInterview/components/InterviewQuestionnaireTemplate';
import { ApplicantSideModal } from 'components/ApplicantSideModal';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

type ApplicantInterviewProps = {
  className?: string;
};

const ApplicantInterviewStyled = styled.div``;

const useApplicantInterviewState = ({ className }: ApplicantInterviewProps) => {
  const params = useAppRouterParams();
  const jobId = params.jobId!;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(modalsActions.setJobId({ jobId }));
  }, [dispatch, jobId]);

  return {
    className,
  } as const;
};

export const ApplicantInterview: React.FC<ApplicantInterviewProps> = (props) => {
  const { className } = useApplicantInterviewState(props);
  useFragmentLayout();

  return (
    <ApplicantInterviewStyled className={className}>
      <ApplicantSideModal />
      <InterviewQuestionnaireTemplate />
    </ApplicantInterviewStyled>
  );
};
