import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import styled from 'styled-components';

import { WithRequired } from 'model/utils';

import { DialogContent, DialogProps } from 'components/Dialog';
import { ExDialog } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { modalsConfig, ModalStepProps } from 'store/modals/config';
import { exModalCancelAction, exModalConfirmAction, wizardBackward, wizardForward } from 'store/modals/modals.actions';
import { ExModal } from 'store/modals/modals.interfaces';

export interface WizardProps extends WithRequired<ExModal, 'id'>, Omit<DialogProps, 'content'> {}

const WizardStyled = styled(ExDialog)`
  .fade-enter {
    opacity: 0;
    transform: scale(0);
  }

  .fade-exit {
    opacity: 1;
    transform: scale(1);
  }

  .fade-enter-active {
    opacity: 1;
    transform: scale(1);
  }

  .fade-exit-active {
    opacity: 0;
    transform: scale(0);
  }

  .fade-enter-active,
  .fade-exit-active {
    transition: all 300ms ease-in-out;
  }
`;

export const Wizard: React.FC<WizardProps> = ({ id, modalConfig, modalProps }) => {
  const dispatch = useAppDispatch();

  const cancelButtonClickHandler = (_?: React.MouseEvent) => {
    dispatch(wizardBackward({ id }));
  };

  const okButtonClickHandler = (_?: React.MouseEvent) => {
    dispatch(wizardForward({ id }));
  };

  const onCloseDialogHandler = (result?: boolean | null) => {
    if (result) {
      dispatch(
        exModalConfirmAction({
          id,
          modalResult: { reason: 'User click Confirm' },
        }),
      );
    } else {
      dispatch(exModalCancelAction({ id, modalResult: { reason: 'User Click Close' } }));
    }
  };

  const { page, wizardType, content } = modalConfig || {};

  if (!page || !wizardType) {
    return null;
  }

  const modalType = modalsConfig[wizardType];
  if (!isValidPageName(modalType, page)) {
    return null;
  }
  const Page: any = modalType[page];
  const dialogContent: DialogContent = {
    ...content,
  };

  if (!isValidPage(Page)) {
    return null;
  }

  return (
    <WizardStyled content={dialogContent} onClose={onCloseDialogHandler}>
      <SwitchTransition>
        <CSSTransition
          addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
          classNames="fade"
          key={page}
        >
          <Page
            id={id}
            nextButtonHandler={okButtonClickHandler}
            prevButtonHandler={cancelButtonClickHandler}
            onCloseDialogHandler={onCloseDialogHandler}
            modalProps={modalProps}
            {...dialogContent}
          />
        </CSSTransition>
      </SwitchTransition>
    </WizardStyled>
  );
};

function isValidPageName<T>(config: any, page: any): page is keyof T {
  return config.hasOwnProperty(page);
}
type Page<T> = T extends React.FC ? React.FC<ModalStepProps> : React.FC;
function isValidPage<T extends React.FC>(page: any): page is Page<T> {
  return !!page;
}
