import React, { lazy, Suspense } from 'react';

import { apiConfig } from 'config';

import { DownloadLinkComponent } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/ApplicantModalV3Components';
import { ApplicantTabProps } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/Tabs/ApplicantTabProps';
import { useDownloadApplicantFileLink } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/hook';

import { Spinner } from 'components/Spinner';
import { isPdfFileType } from 'utils/files';

import { authSelectors } from 'store/auth/auth.selectors';
import { useSelector } from 'store/rootSelectors';

const PDFViewer = lazy(() => import(/* webpackChunkName: "pdf-viewer" */ 'components/PDFViewer/PDFViewer'));

export const ApplicantCoverLetterTab: React.FC<ApplicantTabProps> = ({ className, item }) => {
  const companyId = useSelector(authSelectors.selectCurrentCompanyId);

  const applicantId = item?.applicantId;

  const baseUrl = apiConfig.baseUrl;

  const note = item?.note;

  const errorMsg = 'Cover letter cannot be previewed. Please download cover letter to view details.';

  const downloadFileLink = useDownloadApplicantFileLink({
    applicantId,
    companyId,
    fileId: item?.documentId,
    baseUrl,
  });

  const previewFileLink = useDownloadApplicantFileLink({
    applicantId,
    companyId,
    fileId: isPdfFileType(item?.documentFileType) ? item?.documentId : item?.coverLetterPreviewFileId,
    baseUrl,
  });

  if (!note && !downloadFileLink) {
    return (
      <div className={className}>
        <span>No cover letter provided.</span>
      </div>
    );
  }

  if (note) {
    return (
      <div className={className}>
        <span>{note}</span>
      </div>
    );
  }

  return (
    <div className={className}>
      <Suspense fallback={<Spinner />}>
        <DownloadLinkComponent downloadLink={downloadFileLink} />
        {previewFileLink ? <PDFViewer file={previewFileLink} error={errorMsg} /> : <span>{errorMsg}</span>}
      </Suspense>
    </div>
  );
};
