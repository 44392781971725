import InputGroup from 'react-bootstrap/InputGroup';
import styled from 'styled-components';

const InputGroupStyled = styled(InputGroup)`
  padding-left: 32px;

  .form-control {
    border-top-left-radius: 99px;
    border-bottom-left-radius: 99px;
  }

  .input-group-text {
    border-top-right-radius: 99px;
    border-bottom-right-radius: 99px;
  }
`;

const InputGroupTextStyled = styled(InputGroupStyled.Text)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.blue};
  border-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
`;

export { InputGroupStyled, InputGroupTextStyled };
