import { combineReducers } from '@reduxjs/toolkit';

import {
  calendarJobAdsReducer,
  calendarJobAdsSlice,
} from 'containers/Calendar/state/calendar-job-ads/calendar-job-ads.reducer';
import { calendarJobsReducer, calendarJobsSlice } from 'containers/Calendar/state/calendar-jobs/calendar-jobs.reducer';
import { calendarUiReducer, calendarUiSlice } from 'containers/Calendar/state/calendar-ui/calendar-ui.reducer';

export const calendarReducers = combineReducers({
  [calendarUiSlice.name]: calendarUiReducer,
  [calendarJobsSlice.name]: calendarJobsReducer,
  [calendarJobAdsSlice.name]: calendarJobAdsReducer,
});
