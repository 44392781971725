import type {
  Ability,
  Analytics,
  ExInterviewTemplate,
  JobApplicantsByStage,
  JobApplicantsByStageType,
  Location,
  Position,
} from 'model';

import { JobForList, PostedJobBoard } from 'api-endpoints/job/models';

import type {
  ApplicationRole,
  EmploymentType,
  Frequency,
  JobStatus,
  JobType,
  PipelineStageType,
} from 'model/api-enums.constants';

import type { EnumLiteralsOf } from 'utils/funcs';
import { totalReducerObjectValue } from 'utils/funcs';
import type { BasicModel } from 'utils/reducer/reducer-helper';

import { Applicant } from 'store/entities/applicants/models';
import { Candidate } from 'store/entities/candidates/models';
import type { HiringPipeline } from 'store/entities/hiring-pipelines/hiring-pipelines.types';

export interface User {
  id: string;
  userId: string;
  name: string;
  applicationRoles: EnumLiteralsOf<typeof ApplicationRole>;
  photoUrl: string;
}

export interface JobTeamMember extends User {
  isRemoved: boolean;
  photo?: string | null;
}

export const JobRedesign = (dto: Partial<JobForList> | Partial<Job> | undefined) => ({
  deadline: dto?.deadline ?? null,
  employmentType: null,
  jobId: null,
  jobLocation: null,
  locationFormatted: dto?.locationFormatted,
  jobName: null,
  postedJobBoards: null,
  referenceNo: null,
  salary: null,
  status: null,
  newApplicants: dto?.newApplicants,
  totalApplicants: dto?.totalApplicants,
  openVacancies: dto?.openVacancies,
  ...dto,
  jobApplicantsByStage: dto?.jobApplicantsByStage ?? [],
  jobApplicantsByStageType: dto?.jobApplicantsByStageType ?? [],
  jobApplicantsByStageTotal: dto?.jobApplicantsByStageType?.reduce(totalReducerObjectValue, 0) ?? 0,
});

export type JobTopItems = { key: string; count: number };

type JobVacanciesData = {
  totalPositions: number;
  openPositions: number;
};

export interface Job extends BasicModel {
  jobId: string;
  description: null | string;
  descriptionRendered: string | null;
  jobName: string;
  referenceNo: string;
  deadline: string | null;

  position?: JobPosition;
  jobLocation?: Partial<Location> | null;
  locationFormatted?: string;
  vacancies: number | null;
  salary?: Salary;
  responsibilities?: Array<Ability>;
  requirements?: Array<Ability>;

  jobApplicantsByStage?: JobApplicantsByStage[];
  jobApplicantsByStageType: JobApplicantsByStageType[];
  employmentType: EmploymentType;
  pipelineId: HiringPipeline['pipelineId'];
  status: JobStatus;
  countryHiringFrom?: string | null;
  createdOn?: string;
  createdBy?: string | null;
  createdById?: string | null;
  applicantsCount?: Record<keyof typeof PipelineStageType, number>;
  jobApplicantsPerSourceFigures?: JobApplicantsPerSourceFigures;
  jobApplicantsPerDayFigures?: JobApplicantsPerDayFigures[];
  jobApplicantsPerPipelineStageFigures?: JobApplicantsByStage[];
  summaryPerSource?: SummaryPerSourceElement[];
  summaryPerDay?: SummaryPerDayElement[];
  daysOpen?: number;
  daysToDeadline?: number;
  acceptanceRate?: number;
  averageTimeToHire?: number;
  teamMembers?: JobTeamMember[];
  applicantsIds?: Array<Applicant['id']>;
  jobType: EnumLiteralsOf<typeof JobType>;
  topKeywords?: JobTopItems[];
  topEmployers?: JobTopItems[];
  topEducations?: JobTopItems[];
  totalApplicants: number;
  newApplicants: number;
  openVacancies: number;
  postedJobBoards?: PostedJobBoard[];
  jobVacanciesData: JobVacanciesData;
  listOnCareerPage: boolean;

  isQuestionsLocked: boolean;

  isEditable?: boolean;

  industryId: string | null;

  experience: string | null;

  education: string | null;

  hideSalary: boolean;

  interviewTemplates?: Array<ExInterviewTemplate['interviewTemplateId']>;

  hiringManagers?: Array<JobTeamMember['userId']>;

  pipelineSortPreference?: PipelineSortMode;
}

export type PipelineSortMode = {
  by: PipelineSortBy;
  direction: PipelineSortDirection;
};

export enum PipelineSortBy {
  DATE = 'ApplicationDate',
  CUSTOM = 'Custom',
}

export enum PipelineSortDirection {
  DESC = 'Descending',
  ASC = 'Ascending',
}

type JobPosition = Required<
  Pick<Position, 'name' | 'category'> & { categoryId: string | null; positionId: number | null }
>;

export type JobApplicantsPerSources = {
  count: number;
  jobBoardLogo: string | null;
  sourceName: string;
};

type JobApplicantsPerSource = {
  sourceType: number;
  sources: JobApplicantsPerSources[];
};

export type JobApplicantsPerSourceFigures = JobApplicantsPerSource[];

export type JobApplicantsPerDayFigures = Analytics;

export type JobApplicantsPerPipelineStageFigures = JobApplicantsByStage;

export interface SummaryPerSourceElement {
  key: string;
  value: JobApplicantsByStage[];
}

interface SummaryPerDayElement {
  key: string;
  value: Analytics[];
}

export interface Creator {
  createdBy: string;
  createdOn: string;
}

export interface Salary {
  currency: string;
  frequency: Frequency;
  moneyRange: MoneyRange | undefined;
}

export interface Approval {
  approvedBy: string;
  approvedByName: string;
  ApprovalTimestamp: string;
}

export interface MoneyRange {
  minimum: number | null;
  maximum: number | null;
}

export interface JobFilterOpt<P> {
  value: P;
  label: string;
}

export enum JobBelongsTo {
  main = 'main',
  newJobAd = 'newJobAd',
  newApplicant = 'newApplicant',
  dashboard = 'dashboard',
}

export enum JobFiltersNames {
  status = 'status',
  location = 'location',
  search = 'search',
  belongsTo = 'belongsTo',
  pipelineId = 'pipelineId',
  deadline = 'deadline',
}

export interface JobFilters {
  [JobFiltersNames.status]?: JobFilterOpt<JobStatus>['value'];
  [JobFiltersNames.location]?: JobFilterOpt<string>['value'];
  [JobFiltersNames.search]?: string;
  [JobFiltersNames.belongsTo]?: [JobBelongsTo, BasicModel['id']];
  [JobFiltersNames.pipelineId]?: string;
  deadlineNotPassed?: boolean;
  excludeForCandidateIds?: Array<Candidate['id']>;
  orderBy?: string;
  [JobFiltersNames.deadline]?: {
    start?: string;
    end?: string;
  };
}

export interface JobFiltersOptions {
  [JobFiltersNames.status]: Array<JobFilterOpt<JobStatus>>;
  [JobFiltersNames.location]: Array<JobFilterOpt<string>>;
}

export type JobFiltersValues = JobStatus | string;

export enum SortByForJobList {
  JobName = 'JobName',
  EmploymentType = 'EmploymentType',
  TotalApplicants = 'TotalApplicants',
  NewApplicants = 'NewApplicants',
  OpenVacancies = 'OpenVacancies',
  JobBoards = 'JobBoards',
  Salary = 'Salary',
  JobLocation = 'Country',
  Deadline = 'Deadline',
}

export enum Columns {
  tick = 'tick',
  position = 'position',
  applied = 'applied',
  deadline = 'deadline',
  action = 'action',
}

export type JobForm = Pick<
  Job,
  | 'jobId'
  | 'referenceNo'
  | 'status'
  | 'jobName'
  | 'vacancies'
  | 'countryHiringFrom'
  | 'jobLocation'
  | 'description'
  | 'responsibilities'
  | 'requirements'
  | 'industryId'
  | 'experience'
  | 'education'
  | 'hideSalary'
  | 'pipelineId'
  | 'interviewTemplates'
  | 'hiringManagers'
> & {
  id?: string;
  position: {
    categoryId: string | null;
    positionId: number | string | null;
    category?: string | null;
    name?: string | null;
  };
  employmentType: EmploymentType | null;
  salary: {
    currency: string;
    frequency: Frequency;
    moneyRange?: {
      minimum?: number | string | null;
      maximum?: number | string | null;
    };
  };
};

export type JobFormData = Partial<JobForm>;
