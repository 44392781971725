import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { ApplicantListPlaceholder } from 'containers/ApplicantLists/ApplicantList/ApplicantListPlaceholder';
import {
  ApplicantListHeadProps,
  ApplicantListProps,
  ApplicantListRenderHeaderProps,
  Columns,
} from 'containers/ApplicantLists/ApplicantListProps';
import {
  applicantListActions,
  applicantListEffects,
  applicantListSelectors,
  ApplicantListState,
} from 'containers/ApplicantLists/store';
import { loaderSelectors } from 'containers/Loader/store';

import { ListTableViewHeader, ListTableViewItem, ListViewPagination } from 'components/ListView';
import { Column } from 'components/ListView/ListViewProps';
import { Spinner } from 'components/Spinner';
import { TableList } from 'components/TableList';
import { useTableListUpdatePageHandler } from 'components/TableList/hooks';
import { ExCardTableWrapper } from 'components/TableList/TableListComponents';
import { useTableFooterCounters } from 'components/ui/ExTable/ExTable.hook';
import { useLoaderSubscription } from 'modules/LoaderManager/react/hooks';
import { loaderThunkActionWrapper } from 'modules/LoaderManager/redux';
import { pipe } from 'utils/funcs';
import { usePrevious } from 'utils/hooks/usePrevious';

import { Applicant } from 'store/entities/applicants/models';
import { RootState } from 'store/rootReducer';

const getColumn =
  ({ col, updateSort, sortMode }: ApplicantListHeadProps & { col: string }) =>
  ({ label, order, style, component }: Column) => {
    if (col === Columns.tickHeader) {
      return (
        <th className="column-action" style={style} key={col}>
          {component({ item: {} as Applicant })}
        </th>
      );
    }
    return (
      <ListTableViewHeader
        key={col}
        order={order}
        updateSort={updateSort}
        sortMode={sortMode}
        label={label}
        style={style}
      />
    );
  };

const renderHeader = ({ updateSort, sortMode, columns }: ApplicantListRenderHeaderProps) => {
  const cols = Object.keys(columns) as Array<Columns>;
  return <>{cols.map((col) => pipe(columns[col], getColumn({ col, sortMode, updateSort })))}</>;
};

type ApplicantListPureProps = ApplicantListProps & PropsFromRedux;

const useApplicantListPureState = ({
  applicants,
  recentApplicants,
  loadApplicants,
  updatePage,
  id,
  sortMode,
  updateSortMode,
  list,
  filters,
  columns,
  initList,
  className,
}: ApplicantListPureProps) => {
  const updateSort = (sortModeForUpdate: ApplicantListState['sortMode']) => {
    updateSortMode({ id, sortMode: sortModeForUpdate });
    loadApplicants({ listId: id });
  };

  const { isLoading: isLoadingApplicants } = useLoaderSubscription(
    loaderThunkActionWrapper(applicantListEffects.loadApplicants),
  );

  const updatePageHandler = useTableListUpdatePageHandler({
    updateTableView: updatePage,
    id,
    load: loadApplicants,
    loadArgs: {
      preloader: true,
    },
  });

  useEffect(() => {
    initList({ id });
  }, []); // eslint-disable-line

  const prevDeps = usePrevious(filters);

  useEffect(() => {
    if (!isEqual(prevDeps, filters)) {
      loadApplicants({ listId: id });
    }
  }, [filters, id, loadApplicants, prevDeps]);

  const applicantList = applicants.length !== 0 ? applicants : recentApplicants;
  const dataLength = applicantList.length;

  const isShowSpinner = (!list.fetched || !dataLength) && isLoadingApplicants;

  const { tableFooterCountersText } = useTableFooterCounters({
    pageNo: list.pageNo,
    pageCount: list.pageCount,
    totalItemsCount: list.totalItemsCount,
    pageSize: list.pageSize,
    section: 'Candidates',
  });

  return {
    sortMode,
    list,
    className,
    dataLength,
    isLoadingApplicants,
    columns,
    updateSort,
    updatePageHandler,
    applicantList,
    isShowSpinner,
    tableFooterCountersText,
  };
};

const ApplicantListPure: React.FC<ApplicantListPureProps> = (props) => {
  const {
    sortMode,
    list,
    className,
    dataLength,
    isLoadingApplicants,
    columns,
    updateSort,
    updatePageHandler,
    applicantList,
    isShowSpinner,
    tableFooterCountersText,
  } = useApplicantListPureState(props);

  if (isShowSpinner) {
    return <Spinner />;
  }

  if (!list.fetched) {
    return <div />;
  }

  if (list.fetched && list.pageCount === 0) {
    return <ApplicantListPlaceholder />;
  }

  return (
    <ExCardTableWrapper>
      <TableList.TableListWrapper isLoading={isLoadingApplicants} dataLength={dataLength}>
        <TableList
          className={className}
          header={renderHeader({ columns, sortMode, updateSort })}
          pagination={
            <ListViewPagination
              updatePage={updatePageHandler}
              pageNo={list.pageNo}
              pageCount={list.pageCount}
              disabled={isLoadingApplicants}
            />
          }
          tableFooterText={tableFooterCountersText}
          pageCount={list.pageCount}
        >
          {applicantList.map((applicant: Applicant) => (
            <ListTableViewItem key={applicant.id} item={applicant} columns={columns} />
          ))}
        </TableList>
      </TableList.TableListWrapper>
    </ExCardTableWrapper>
  );
};

const mapDispatch = {
  cleanupList: applicantListActions.cleanupList,
  initList: applicantListActions.init,
  loadApplicants: applicantListEffects.loadApplicants,
  updatePage: applicantListActions.updatePage,
  updateSortMode: applicantListActions.updateSort,
};

const mapState = (state: RootState, own: ApplicantListProps) => ({
  applicants: applicantListSelectors.getApplicants(state, own),
  filters: applicantListSelectors.getFilters(state, own),
  isApplicantListLoading: loaderSelectors.isTaskActive(state, {
    taskId: applicantListEffects.loadApplicantsTaskId,
  }),
  list: applicantListSelectors.getListById(state, own),
  recentApplicants: applicantListSelectors.getRecentApplicants(state, own),
  sortMode: applicantListSelectors.getSortMode(state, own),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const ApplicantList = connector(ApplicantListPure);
