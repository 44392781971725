import { push } from 'connected-react-router';
import { all, call, fork, put, select, take } from 'redux-saga/effects';

import { ErrorDTO } from 'model/api-errors.constants';

import { filesToFormData, uploader } from 'pages/public/PublicNewApplication/utils/uploader';
import { publicNewApplicantFormActions } from 'pages/public/state/public-new-applicant-form/public-new-applicant-form.actions';
import { PublicUploadTypes } from 'pages/public/state/public-new-applicant-form/public-new-applicant-form.model';
import { publicApplicantFormDefaultParsedData } from 'pages/public/state/public-new-applicant-form/public-new-applicant-form.reducer';
import { publicNewApplicantFormSelectors } from 'pages/public/state/public-new-applicant-form/public-new-applicant-form.selectors';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { getTranslate } from 'utils/i18utils';
import { invokeExModal, prepareExModalChannel } from 'utils/sagas';

import { SocialNetwork } from 'store/entities/candidates/models';
import { exModalHideAction } from 'store/modals/modals.actions';
import { ModalGeneralResult } from 'store/modals/modals.interfaces';

export function* publicNewApplicantFormUploadAction(
  action: ReturnType<typeof publicNewApplicantFormActions.formUploadAction>,
) {
  const { type, files } = action.payload;
  const formData = filesToFormData(files);

  const publicUploadTypesConfig = yield select(publicNewApplicantFormSelectors.selectPublicUploadTypesConfig);
  const { jobId } = yield select(publicNewApplicantFormSelectors.publicNewApplicantFormSelector);
  const { url, field, fileField, successMsg } = publicUploadTypesConfig[type];

  try {
    if (type === PublicUploadTypes.resume) {
      yield put(publicNewApplicantFormActions.setResumeParsing(true));
    }

    const [pureResponse]: [any] = yield all([
      call(uploader, { url, formData }),
      put(
        publicNewApplicantFormActions.setUploaded({
          type: fileField,
          response: {
            filename: '',
            filetype: '',
            processing: true,
          },
        }),
      ),
    ]);
    const response = {
      ...pureResponse,
      filename: pureResponse.filename || (pureResponse.fileName !== null ? pureResponse.fileName : undefined),
      filetype: pureResponse.filetype || (pureResponse.fileType !== null ? pureResponse.fileType : undefined),
    };

    yield put(publicNewApplicantFormActions.updateForm({ [field]: response['fileId'] }));

    if (type === PublicUploadTypes.resume) {
      let socialNetworks = response.parsedData?.socialNetworks?.filter(
        (network) => network.type === SocialNetwork.LinkedIn,
      );
      socialNetworks =
        socialNetworks?.length > 0 ? socialNetworks : publicApplicantFormDefaultParsedData.socialNetworks;

      const data = {
        ...publicApplicantFormDefaultParsedData,
        ...response.parsedData,
        socialNetworks,
        jobId,
        resumeId: response.fileId,
      };
      yield put(publicNewApplicantFormActions.updateForm(data));
    }

    yield put(publicNewApplicantFormActions.setUploaded({ type: fileField, response }));
    yield put(publicNewApplicantFormActions.setResumeParsing(false));

    yield put(alertsEffects.showSuccess({ message: successMsg }));
  } catch (e) {
    const error = e as ErrorDTO;
    yield put(publicNewApplicantFormActions.updateForm({ [field]: null }));
    yield put(publicNewApplicantFormActions.setUploaded({ type: fileField, response: null }));
    yield put(publicNewApplicantFormActions.setResumeParsing(false));

    const validationErrorCodes = error?.validationErrorCodes;
    if (validationErrorCodes) {
      yield all(
        validationErrorCodes.map((validationErrorCode: string) => {
          const validationErrorMessage = getTranslate(validationErrorCode, {
            ns: 'validationErrorCodes',
          });
          return put(alertsEffects.showError({ message: validationErrorMessage || 'Error' }));
        }),
      );
    } else {
      yield put(alertsEffects.showError({ message: 'Error' }));
    }
  }
}

export function* publicNewApplicantFormRemoveUploadAction(
  action: ReturnType<typeof publicNewApplicantFormActions.formRemoveUploadAction>,
) {
  const { type } = action.payload;

  const publicUploadTypesConfig = yield select(publicNewApplicantFormSelectors.selectPublicUploadTypesConfig);
  const { field, fileField } = publicUploadTypesConfig[type];

  yield put(publicNewApplicantFormActions.updateForm({ [field]: null }));
  yield put(publicNewApplicantFormActions.setUploaded({ type: fileField, response: null }));
}

export function* publicNewApplicantFormCloseWorker(
  action: ReturnType<typeof publicNewApplicantFormActions.publicNewApplicantFormCloseAction>,
) {
  const { modalId, sagaChannel } = yield prepareExModalChannel();
  const message = getTranslate('confirm_close_form', { ns: 'enPublicPages' });
  yield fork(
    invokeExModal as any,
    {
      channel: sagaChannel,
      modalId,
      modalType: ModalsTypeKey.confirmModal,
      modalConfig: {
        content: {
          title: 'Confirmation',
          message,
          withActions: true,
          withTitle: true,
          buttonOk: 'Close',
        },
      },
    },
    { modalId, sagaChannel },
  );

  const { confirm, cancel }: ModalGeneralResult = yield take(sagaChannel);
  if ([cancel, !confirm].some(Boolean)) {
    return;
  }
  yield put(push(action.payload.backPath));
  yield put(exModalHideAction({ id: modalId }));
}
