import React, { DependencyList } from 'react';

import { KeyCodes } from 'model/keycodes';

type UseRestrictedKeyCodesParams = {
  restrictedKeyCodes: Array<KeyCodes>;
  deps?: DependencyList;
};

type UseRestrictedKeyCodes = (params: UseRestrictedKeyCodesParams) => UseRestrictedKeyCodesReturnType;

type UseRestrictedKeyCodesReturnType = {
  restrictedKeyCodes: Set<KeyCodes>;
  handlerKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
};

/**
 * Custom hook, preventing keypress for provided key codes.
 *
 * @param {UseRestrictedKeyCodesParams} { restrictedKeyCodes, deps }
 * @returns {UseRestrictedKeyCodesReturnType} { restrictedKeyCodes, handlerKeyDown }
 */
export const useRestrictedKeyCodes: UseRestrictedKeyCodes = ({ restrictedKeyCodes, deps }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const keyCodes = React.useMemo(() => new Set(restrictedKeyCodes), deps ?? []);

  const handlerKeyDown: React.KeyboardEventHandler<HTMLInputElement> = React.useCallback(
    (e) => {
      if (keyCodes.has(e.code as KeyCodes)) {
        e.preventDefault();
      }
    },
    [keyCodes],
  );
  return {
    handlerKeyDown,
    restrictedKeyCodes: keyCodes,
  };
};
