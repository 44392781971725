import {
  createSlice,
  CreateSliceOptions,
  Draft,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import xor from 'lodash/xor';

import { list } from 'config/list';

import { WithRequired } from 'model/utils';

import { SortDirections, ViewMode } from 'store/constants';

export interface BasicModel {
  id: string;
}

export interface InitialState<T extends BasicModel, K extends string | number = string | number> {
  allIds: Array<T['id']>;
  byId: Record<K, T>;
}

export const createLookupReducer = <T extends BasicModel, Reducers extends SliceCaseReducers<InitialState<T>>>({
  name = '',
  initialState,
  reducers,
  extraReducers,
}: {
  name: string;
  initialState: InitialState<T>;
  reducers: ValidateSliceCaseReducers<InitialState<T>, Reducers>;
  extraReducers?: CreateSliceOptions<
    InitialState<T>,
    ValidateSliceCaseReducers<InitialState<T>, Reducers>
  >['extraReducers'];
}) => {
  const r = createSlice({
    name,
    initialState,
    reducers: {
      load(state: InitialState<T>, { payload }: PayloadAction<{ items: T[] }>) {
        state.byId = {
          ...payload.items.reduce((acc, entity) => ({ ...acc, [entity.id]: entity }), {}),
        };
        state.allIds = payload.items.map(({ id }) => id);
      },
      loadOne(state: InitialState<T>, { payload }: PayloadAction<{ item: T }>) {
        if (!payload.item.id) return state;
        const { id } = state.byId[payload.item.id] || {};
        if (!id) return state;
        state.byId[id] = payload.item;
        state.allIds.push(id);
      },
      upsert(state: InitialState<T>, { payload }: PayloadAction<{ items: T[] }>) {
        state.byId = {
          ...payload.items.reduce(
            (acc, entity) => ({
              ...acc,
              [entity.id]: { ...state.byId[entity.id], ...entity },
            }),
            state.byId,
          ),
        };
        state.allIds = payload.items.reduce(
          (acc, item) => [...acc, ...(acc.includes(item.id) ? [] : [item.id])],
          state.allIds,
        );
      },
      upsertOne(state: InitialState<T>, { payload }: PayloadAction<{ item: WithRequired<T, 'id'> }>) {
        if (!payload.item.id) {
          return state;
        }
        const { id, ...item } = state.byId[payload.item.id] || {};
        if (id) {
          state.byId[id] = Object.assign(item, payload.item as T);
        } else {
          state.byId[payload.item.id] = payload.item as T;
          state.allIds.push(payload.item.id);
        }
      },
      updateOne(state: InitialState<T>, { payload }: PayloadAction<Partial<T>>) {
        if (!payload.id) return state;
        const { id, ...item } = state.byId[payload.id] || {};
        if (!id) return state;
        state.byId[id] = Object.assign(item, payload as T);
      },
      deleteById(state: InitialState<T>, { payload }: PayloadAction<{ id: T['id'] }>) {
        if (typeof r.caseReducers.deleteByIds === 'function') {
          r.caseReducers.deleteByIds(state, { payload: { ids: [payload.id] } });
        }
      },
      deleteByIds(state: InitialState<T>, { payload }: PayloadAction<{ ids: Array<T['id']> }>) {
        let didMutate = false;
        payload.ids.forEach((key) => {
          if (key in state.byId) {
            delete state.byId[key];
            didMutate = true;
          }
        });
        if (didMutate) {
          state.allIds = state.allIds.filter((id) => id in state.byId);
        }
      },
      ...reducers,
    },
    extraReducers,
  });
  return r;
};

export interface SortMode<S> {
  orderBy?: S;
  orderDir?: SortDirections;
}

export type ListParams<T extends BasicModel, S, F> = Pick<
  ListState<T, S, F>,
  'id' | 'pageNo' | 'pageSize' | 'sortMode' | 'viewMode' | 'filters' | 'orderBy'
>;

type ListStateFilterItemOption = {
  key: string;
  value: string;
};

type ListStateFilterItem = {
  name: string;
  options: ListStateFilterItemOption[];
};

export type ListStateFilter = ListStateFilterItem[];

export interface ListState<T extends BasicModel, S = string, F = object> extends ListPaginationProps {
  id: string;
  items: Array<T['id']>;
  selectedItems: Array<T['id']>;
  canBeSelectedIds?: Array<T['id']>;
  selectMode?: {
    selectable?: boolean;
    multiselect?: boolean;
  };
  sortMode: SortMode<S>;
  viewMode: ViewMode;
  filters: F;
  filter?: ListStateFilter;
  fetched: boolean;
  pagination: Record<string, { items: Array<T['id']> }>;
  orderBy?: string;
}

interface ListPaginationProps {
  pageNo: number;
  pageSize: number;
  pageCount: number;
  totalItemsCount: number;
}

export const getCacheListID = <T extends BasicModel, S, F>({
  id,
  sortMode,
  viewMode,
  pageNo,
  filters,
}: ListParams<T, S, F>) => {
  return JSON.stringify({
    id,
    sortMode,
    viewMode,
    ...(viewMode === ViewMode.table && { pageNo }),
    filters,
  });
};

export type InitialStateList<LS> = Record<string, LS>;

export const initListState: ListState<any, any, any> = {
  id: '',
  items: [],
  selectedItems: [],
  pageNo: 0,
  pageSize: list.pageSize,
  pageCount: 0,
  totalItemsCount: 0,
  sortMode: {},
  viewMode: list.viewMode,
  filters: {},
  fetched: false,
  pagination: {},
};

export const createListReducer = <
  T extends BasicModel,
  S,
  F extends object,
  LS extends ListState<T, S, F>,
  ReducerInitialState extends InitialStateList<LS>,
  Reducers extends SliceCaseReducers<ReducerInitialState>,
>({
  name = '',
  initialState,
  reducers,
  extraReducers,
}: {
  name: string;
  initialState: ReducerInitialState;
  reducers: ValidateSliceCaseReducers<ReducerInitialState, Reducers>;
  extraReducers?: CreateSliceOptions<
    ReducerInitialState,
    ValidateSliceCaseReducers<ReducerInitialState, Reducers>
  >['extraReducers'];
}) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      init(state, action: PayloadAction<Partial<LS>>) {
        const { id } = action.payload;
        if (!id) return state;
        state[id] = state[id] || Object.assign({}, initListState, action.payload);
      },
      fetchSuccess(state, action: PayloadAction<Partial<LS>>) {
        const { id } = action.payload;
        if (!id || !state[id]) return state;

        state[id] = Object.assign(state[id], action.payload, { fetched: true });
        const cacheId = getCacheListID(state[id]);
        const payloadItems = action.payload?.items || ([] as LS['items']);

        const cachedItemsInPagination = state[id].pagination[cacheId as LS['id']]?.items || ([] as T['id'][]);
        const mergedItemsInPagination = payloadItems.reduce<typeof cachedItemsInPagination>(
          (acc, itemId) => [
            ...acc,
            ...(cachedItemsInPagination.includes(itemId as Draft<T['id']>) ? [] : [itemId as Draft<T['id']>]),
          ],
          [...cachedItemsInPagination],
        );
        state[id].pagination = {
          ...state[id].pagination,
          [cacheId as LS['id']]: {
            items:
              state[id].viewMode === ViewMode.table || state[id].pageNo === 0
                ? (payloadItems as Draft<T['id']>[])
                : mergedItemsInPagination,
          },
        };
      },
      update(state, action: PayloadAction<Partial<LS>>) {
        const { id } = action.payload;
        if (!id || !state[id]) return state;
        state[id] = Object.assign(state[id], action.payload);
      },
      updateSort(state, action: PayloadAction<{ id: LS['id']; sortMode: Partial<S> }>) {
        const { id, sortMode } = action.payload;
        if (!id || !state[id]) return state;
        if (!isEqual(sortMode, state[id].sortMode)) {
          state[id].sortMode = sortMode;
          state[id].pageNo = 0;
        }
      },
      updatePage(
        state,
        action: PayloadAction<{
          id: LS['id'];
          pageNo: ListParams<T, S, F>['pageNo'];
        }>,
      ) {
        const { id, pageNo } = action.payload;
        if (!id || !state[id]) return state;
        if (pageNo !== state[id].pageNo && (pageNo <= state[id].pageCount - 1 || !state[id].fetched)) {
          state[id].pageNo = pageNo;
        }
      },
      updatePageInfinite(
        state,
        action: PayloadAction<{
          id: LS['id'];
        }>,
      ) {
        const { id } = action.payload;
        if (!id || !state[id]) return state;
        if (state[id].pageNo < state[id].pageCount - 1) {
          state[id].pageNo = state[id].pageNo + 1;
        }
      },
      updatePageSize(
        state,
        action: PayloadAction<{
          id: LS['id'];
          pageSize?: ListParams<T, S, F>['pageSize'];
        }>,
      ) {
        const { id, pageSize } = action.payload;
        if (!id || !state[id]) return state;
        state[id].pageSize = pageSize !== undefined ? pageSize : state[id].pageSize;
      },
      updateViewMode(state, action: PayloadAction<{ id: LS['id']; viewMode: ViewMode }>) {
        const { id, viewMode } = action.payload;
        if (!id || !state[id]) return state;
        if (viewMode !== state[id].viewMode) {
          state[id].viewMode = viewMode;
          state[id].pageNo = 0;
        }
      },
      clearItems(state, action: PayloadAction<{ id: LS['id'] }>) {
        const { id } = action.payload;
        if (!id || !state[id]) return state;
        state[id].items = [];
      },
      cleanupList(state, action: PayloadAction<{ id: LS['id'] }>) {
        const { id } = action.payload;
        if (!id || !state[id]) return state;
        state[id].items = [];
        state[id].selectedItems = [];
        state[id].fetched = false;
      },
      resetState() {
        return initialState;
      },
      resetListState(state, action: PayloadAction<{ id: LS['id'] }>) {
        const { id } = action.payload;
        if (!id || !state[id]) return state;
        state[id] =
          (initialState[id] as Draft<ReducerInitialState>[LS['id']]) ||
          Object.assign({}, initListState, action.payload);
      },
      updateFilters(state, action: PayloadAction<{ id: LS['id']; filters: Partial<F> }>) {
        const { id, filters } = action.payload;
        if (!id || !state[id]) return state;
        if (!isEqual(filters, state[id].filters)) {
          state[id].filters = Object.assign(state[id].filters, filters);
          state[id].pageNo = 0;
        }
      },
      clearFilters(state, action: PayloadAction<{ id: LS['id'] }>) {
        const { id } = action.payload;
        const initFilters = (initialState[id]?.filters ?? {}) as Draft<F>;
        state[id].filters = initFilters;
        state[id].pageNo = 0;
      },
      replaceFilters(state, action: PayloadAction<{ id: LS['id']; filters: Partial<F> }>) {
        const { id, filters } = action.payload;
        if (!id || !state[id]) return state;
        if (!isEqual(filters, state[id].filters)) {
          state[id].filters = Object.assign(initialState[id].filters, filters) as Draft<F>;
          state[id].pageNo = 0;
        }
      },
      toggleItemSelect(state, action: PayloadAction<{ id: LS['id']; selectedItemId: T['id'] }>) {
        const { id, selectedItemId } = action.payload;
        if (!id || !state[id]) return state;
        state[id].selectedItems = xor(state[id].selectedItems, [selectedItemId as Draft<T['id']>]);
      },
      setItemSelect(state, action: PayloadAction<{ id: LS['id']; selectedItemId: Array<T['id']> | T['id'] }>) {
        const { id, selectedItemId } = action.payload;
        if (!id || !state[id]) return state;
        state[id].selectedItems = (Array.isArray(selectedItemId) ? selectedItemId : [selectedItemId]) as Array<
          Draft<T['id']>
        >;
      },
      selectAllItems(state, action: PayloadAction<{ id: LS['id'] }>) {
        const { id } = action.payload;
        if (!id || !state[id]) return state;
        const cacheId = getCacheListID(state[id]);
        if (state[id].pagination[cacheId]?.items) {
          state[id].selectedItems = [...state[id].pagination[cacheId].items];
        }
      },
      deselectAllItems(state, action: PayloadAction<{ id: LS['id'] }>) {
        const { id } = action.payload;
        if (!id || !state[id]) return state;
        state[id].selectedItems = [];
      },
      toggleItemRadio(state, action: PayloadAction<{ id: LS['id']; selectedItemId: T['id'] }>) {
        const { id, selectedItemId } = action.payload;
        if (!id || !state[id]) return state;
        state[id].selectedItems = state[id].selectedItems.includes(selectedItemId as Draft<T['id']>)
          ? []
          : ([selectedItemId] as Draft<T['id']>[]);
      },
      ...reducers,
    },
    extraReducers,
  });
};
