import { createAction } from '@reduxjs/toolkit';

import * as candidateApi from 'api-endpoints/candidate';

import { Unwrap } from 'model/utils';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';

import { loaderManagerActions } from 'modules/LoaderManager/redux';
import { createLoader, createRequestTypes } from 'utils/actions';
import { getTranslate } from 'utils/i18utils';

import {
  CreateCandidateWorkExperienceInnerRequestParams,
  DeleteCandidateWorkExperienceRequestParams,
  GetListCandidateWorkExperienceRequestParams,
  UpdateCandidateWorkExperienceInnerRequestParams,
} from 'store/entities/candidate-work-experience/api/requests';
import {
  CANDIDATE_WORK_EXPERIENCE_CREATE,
  CANDIDATE_WORK_EXPERIENCE_FETCH,
  CANDIDATE_WORK_EXPERIENCE_GET,
  CANDIDATE_WORK_EXPERIENCE_REMOVE,
  CANDIDATE_WORK_EXPERIENCE_UPDATE,
} from 'store/entities/candidate-work-experience/candidate-work-experience.constants';
import { candidateWorkExperienceSlice } from 'store/entities/candidate-work-experience/candidate-work-experience.reducer';

import { CandidateWorkExperience } from './candidate-work-experience.models';

//--------------------------------------------------------------------------------------------------
// GET CANDIDATE WORK EXPERIENCES
export const candidateWorkExperienceGetListTypes = createRequestTypes(CANDIDATE_WORK_EXPERIENCE_FETCH);
export const candidateWorkExperienceGetListRequest = createAction<GetListCandidateWorkExperienceRequestParams>(
  candidateWorkExperienceGetListTypes.LOAD,
);
export const candidateWorkExperienceGetListCancel = createAction(candidateWorkExperienceGetListTypes.CANCEL);
export const candidateWorkExperienceGetListHandler = createLoader(
  {
    success: (data: Required<Unwrap<ReturnType<typeof candidateApi.getCandidateWorkExperienceList>>>['data']) => [
      candidateWorkExperienceSlice.actions.setAll(data.items),
      loaderManagerActions.stop(candidateWorkExperienceGetListTypes.LOAD),
    ],
  },
  candidateWorkExperienceGetListTypes,
);
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// CREATE CANDIDATE WORK EXPERIENCE
const candidateWorkExperienceCreateTypes = createRequestTypes(CANDIDATE_WORK_EXPERIENCE_CREATE);
export const candidateWorkExperienceCreateRequest = createAction<CreateCandidateWorkExperienceInnerRequestParams>(
  candidateWorkExperienceCreateTypes.LOAD,
);
export const candidateWorkExperienceCreateCancel = createAction(candidateWorkExperienceCreateTypes.CANCEL);
export const candidateWorkExperienceCreateHandler = createLoader(
  {
    success: (
      data: Required<Unwrap<ReturnType<typeof candidateApi.createCandidateWorkExperience>>['data']>,
      params: ReturnType<typeof candidateWorkExperienceCreateRequest>,
    ) => {
      const newCandidateWorkExperience = { ...params.payload.data, ...data };
      return [
        candidateWorkExperienceSlice.actions.addOne(newCandidateWorkExperience as any),
        alertsEffects.showSuccess({ message: getTranslate('work_experience_create_success') }),
        candidateWorkExperienceGetListRequest({
          urlParams: { candidateId: params.payload.urlParams.candidateId },
          params: { orderBy: 'AdvancedStartDate', orderDirection: 'Ascending' },
        }),
      ];
    },
  },
  candidateWorkExperienceCreateTypes,
);
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// UPDATE CANDIDATE WORK EXPERIENCE
const candidateWorkExperienceUpdateTypes = createRequestTypes(CANDIDATE_WORK_EXPERIENCE_UPDATE);
export const candidateWorkExperienceUpdateRequest = createAction<UpdateCandidateWorkExperienceInnerRequestParams>(
  candidateWorkExperienceUpdateTypes.LOAD,
);
export const candidateWorkExperienceUpdateCancel = createAction(candidateWorkExperienceUpdateTypes.CANCEL);
export const candidateWorkExperienceUpdateHandler = createLoader(
  {
    success: (
      data: Required<Unwrap<ReturnType<typeof candidateApi.updateCandidateWorkExperience>>['data']>,
      params: ReturnType<typeof candidateWorkExperienceUpdateRequest>,
    ) => {
      const updatedCandidateWorkExperience = { ...params.payload.data };
      return [
        candidateWorkExperienceSlice.actions.upsertOne(updatedCandidateWorkExperience as any),
        alertsEffects.showSuccess({ message: getTranslate('work_experience_update_success') }),
        candidateWorkExperienceGetListRequest({
          urlParams: { candidateId: params.payload.urlParams.candidateId },
          params: { orderBy: 'AdvancedStartDate', orderDirection: 'Ascending' },
        }),
      ];
    },
  },
  candidateWorkExperienceUpdateTypes,
);
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// DELETE CANDIDATE WORK EXPERIENCE
const candidateWorkExperienceDeleteTypes = createRequestTypes(CANDIDATE_WORK_EXPERIENCE_REMOVE);
export const candidateWorkExperienceDeleteRequest = createAction<DeleteCandidateWorkExperienceRequestParams>(
  candidateWorkExperienceDeleteTypes.LOAD,
);
export const candidateWorkExperienceDeleteCancel = createAction(candidateWorkExperienceDeleteTypes.CANCEL);
export const candidateWorkExperienceDeleteHandler = createLoader(
  {
    success: (
      data: Required<Unwrap<ReturnType<typeof candidateApi.deleteCandidateWorkExperience>>['data']>,
      params: ReturnType<typeof candidateWorkExperienceDeleteRequest>,
    ) => {
      return [
        candidateWorkExperienceSlice.actions.removeOne(params.payload.urlParams.workExperienceId!),
        alertsEffects.showSuccess({ message: getTranslate('work_experience_delete_success') }),
      ];
    },
  },
  candidateWorkExperienceDeleteTypes,
);
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// GET CANDIDATE WORK EXPERIENCE
const candidateWorkExperienceGetTypes = createRequestTypes(CANDIDATE_WORK_EXPERIENCE_GET);
export const candidateWorkExperienceGetRequest = createAction<DeleteCandidateWorkExperienceRequestParams>(
  candidateWorkExperienceGetTypes.LOAD,
);
export const candidateWorkExperienceGetCancel = createAction(candidateWorkExperienceGetTypes.CANCEL);
export const candidateWorkExperienceGetHandler = createLoader(
  {
    success: (data: Required<Unwrap<ReturnType<typeof candidateApi.getCandidateWorkExperience>>['data']>) => {
      return candidateWorkExperienceSlice.actions.upsertOne(data);
    },
  },
  candidateWorkExperienceGetTypes,
);
//--------------------------------------------------------------------------------------------------

export const candidateWorkExperienceCreate = createAction(CANDIDATE_WORK_EXPERIENCE_CREATE);
export const candidateWorkExperienceUpdate = createAction(CANDIDATE_WORK_EXPERIENCE_UPDATE);
export const candidateWorkExperienceRemove = createAction<CandidateWorkExperience>(CANDIDATE_WORK_EXPERIENCE_REMOVE);
