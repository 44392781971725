import React, { useCallback } from 'react';

import { applicantListActions, applicantListSelectors, ApplicantListState } from 'containers/ApplicantLists/store';
import { applicantListResetFiltersAction } from 'containers/ApplicantLists/store/applicant-lists.actions';

import { ExSelectedBar } from 'components/ui/ExSelectedBar';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { useSelector } from 'store/rootSelectors';

type ApplicantListSelectedBarV2Props = {
  listId: ApplicantListState['id'];
  className?: string;
};

const useApplicantListSelectedBarV2State = ({ className, listId }: ApplicantListSelectedBarV2Props) => {
  const dispatch = useAppDispatch();
  const handleSelectAll = useCallback(() => {
    dispatch(applicantListActions.selectAllItems({ id: listId }));
  }, [dispatch, listId]);

  const handleDeselectAll = useCallback(() => {
    dispatch(applicantListActions.deselectAllItems({ id: listId }));
  }, [dispatch, listId]);

  const isAllSelected = useSelector((state) => applicantListSelectors.selectIsAllSelected(state, { id: listId }));
  const selectedCount = useSelector((state) => applicantListSelectors.selectSelectedLength(state, { id: listId }));
  const handleClearFilters = useCallback(() => {
    dispatch(applicantListResetFiltersAction({ listId }));
    handleDeselectAll();
  }, [dispatch, handleDeselectAll, listId]);

  return {
    className,
    handleSelectAll,
    handleDeselectAll,
    isAllSelected,
    selectedCount,
    handleClearFilters,
  } as const;
};

export const ApplicantListSelectedBarV2: React.FC<ApplicantListSelectedBarV2Props> = (props) => {
  const { className, handleSelectAll, handleDeselectAll, isAllSelected, selectedCount, handleClearFilters } =
    useApplicantListSelectedBarV2State(props);

  return (
    <ExSelectedBar
      className={className}
      selectedCount={selectedCount}
      isAllSelected={isAllSelected}
      handleDeselectAll={handleDeselectAll}
      handleSelectAll={handleSelectAll}
      handleClearFilters={handleClearFilters}
    />
  );
};
