import { Action, ActionCreator, AnyAction } from 'redux';
import { put, PutEffect } from 'redux-saga/effects';

import { LoaderEntity, ThunkActionType } from './model';

/**
 * Thus `actionCreator` has static property `type` we can use it to generate loader name (id)
 *
 * @param action
 */
function getActionNameFromThunkOrActionCreator(action: ThunkActionType) {
  /**
   * This hack is needed to pass ts checker.
   * Any function (self written thunk or createAsyncThunk) have a `name` field
   * and actionCreator have a `type` field. But I don't understand how to explain this to ts compiler :)
   */
  const castedAction = (action as unknown) as any;

  return castedAction.type ?? castedAction.name;
}

/**
 * Get action name.
 * I general this function is only needed because we have several middleware implementations in the project.
 * First of all it needed to handle `thunks` because thunk does not have `type` property and in this case
 * loader name will be based on the thunk function name.
 *
 * @param action
 */
export function getActionName(action: Action<string> | ThunkActionType): string {
  return typeof action === 'function' ? getActionNameFromThunkOrActionCreator(action) : action.type;
}

/**
 * Create default (idle) loader entity.
 *
 * @param {string} id
 */
export function createIdleLoaderEntity(id: LoaderEntity['id']): LoaderEntity {
  return {
    id,
    state: 'idle',
  };
}

/**
 * Create default (idle) loader entity.
 *
 * @param {string} id
 */
export function createLoaderEntity(id: LoaderEntity['id'], state: 'idle' | 'processing' = 'idle'): LoaderEntity {
  return {
    id,
    state,
  };
}

/**
 * Check is action has silent flag.
 *
 * @param {AnyAction} action
 */
export function isActionSilent(action: AnyAction) {
  return action?.meta?.silent === true;
}

/**
 * Create actions with put effect.
 *
 * @param {AnyAction|AnyAction[]} action
 * @param {ActionCreator<Action<string>>} actionCreator
 */
export function createPutActions(
  action: Action<string> | Array<Action<string>>,
  actionCreator: ActionCreator<Action<string>>,
): PutEffect<Action<string>>[] {
  let actions: Array<Action<string>> = [];
  if (Array.isArray(action)) {
    actions = createActions(action, actionCreator);
  } else {
    actions = createActions([action], actionCreator);
  }
  return actions.map(act => put(act));
}

/**
 * Filter silent actions.
 *
 * @param {AnyAction[]} action
 * @param {ActionCreator<Action<string>>} actionCreator
 */
function createActions(actions: Array<Action<string>>, actionCreator: ActionCreator<Action<string>>) {
  return actions.reduce<Array<Action<string>>>((acc, action) => {
    if (!isActionSilent(action)) {
      const loaderEntityId = getActionName(action);
      acc.push(actionCreator(loaderEntityId));
    }
    return acc;
  }, []);
}

/**
 * Merge meta field with silent: true to the action object.
 * Silent value is read by startLoader methods and needed to prevent loader start.
 *
 * @param {AnyAction} action
 */
export function withoutLoader(action: AnyAction): AnyAction {
  return {
    ...action,
    meta: {
      silent: true,
    },
  };
}
