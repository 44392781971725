import React from 'react';
import styled from 'styled-components';

import { ExFilterClearButton } from './ExButton/ExFilterClearButton';
import { ExButton } from './ExButton';
import { ExVisible } from './ExVisible';

type ExSelectedBarProps = {
  className?: string;
  selectedCount: number;
  isAllSelected: boolean;
  handleSelectAll: () => void;
  handleDeselectAll: () => void;
  handleClearFilters: () => void;
};

const ExSelectedBarStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin-bottom: 0;
  }
`;

const Divider = styled.p`
  content: '';
  padding-left: 8px;
  padding-right: 8px;
  color: ${(props) => props.theme.colors.dark};
`;

const SelectHandler = styled(ExButton)``;

const Count = styled.p`
  font-weight: bold;
  margin: 0;
  padding: 0.375rem 0.75rem;
`;

const useExSelectedBarState = ({
  className,
  selectedCount,
  isAllSelected,
  handleDeselectAll,
  handleSelectAll,
  handleClearFilters,
}: ExSelectedBarProps) => {
  return {
    className,
    selectedCount,
    isAllSelected,
    handleDeselectAll,
    handleSelectAll,
    handleClearFilters,
  };
};

export const ExSelectedBar: React.FC<ExSelectedBarProps> = (props) => {
  const { className, selectedCount, isAllSelected, handleDeselectAll, handleSelectAll, handleClearFilters } =
    useExSelectedBarState(props);

  return (
    <ExSelectedBarStyled className={className}>
      <Count>{selectedCount} Selected</Count>
      <Divider>&#124;</Divider>
      <ExVisible visible={!isAllSelected}>
        <SelectHandler variant="link" onClick={handleSelectAll}>
          Select All
        </SelectHandler>
      </ExVisible>
      <ExVisible visible={isAllSelected}>
        <SelectHandler variant="link" onClick={handleDeselectAll}>
          Deselect All
        </SelectHandler>
      </ExVisible>
      <Divider>&#124;</Divider>
      <ExFilterClearButton variant="link" onClick={handleClearFilters}>
        Clear
      </ExFilterClearButton>
    </ExSelectedBarStyled>
  );
};
