import React from 'react';
import styled from 'styled-components/macro';

import { ApplicantContacts } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/ApplicantModalV3Components';
import {
  ApplicantContactsItem,
  ApplicantContactsItemLabel,
  ApplicantContactsItemValue,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalV4Components';
import { useApplicantFromModal } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/hook/useApplicantFromModal';

type ApplicantModalContactsInfoViewProps = {
  className?: string;
};

const ApplicantModalContactsInfoViewStyled = styled.div``;

const useApplicantModalContactsInfoViewState = ({ className }: ApplicantModalContactsInfoViewProps) => {
  const applicant = useApplicantFromModal();

  const mobile = applicant?.mobile || undefined;
  const email = applicant?.email || undefined;

  return {
    className,
    mobile,
    email,
  } as const;
};

export const ApplicantModalContactsInfoView: React.FC<ApplicantModalContactsInfoViewProps> = (props) => {
  const { className, mobile, email } = useApplicantModalContactsInfoViewState(props);

  return (
    <ApplicantModalContactsInfoViewStyled className={className}>
      <ApplicantContacts>
        <ApplicantContactsItem>
          <ApplicantContactsItemLabel>M</ApplicantContactsItemLabel>
          <ApplicantContactsItemValue title={mobile}>{mobile}</ApplicantContactsItemValue>
        </ApplicantContactsItem>
        <ApplicantContactsItem>
          <ApplicantContactsItemLabel>E</ApplicantContactsItemLabel>
          <ApplicantContactsItemValue title={email}>{email}</ApplicantContactsItemValue>
        </ApplicantContactsItem>
      </ApplicantContacts>
    </ApplicantModalContactsInfoViewStyled>
  );
};
