import React, { useCallback, useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components/macro';
import { format, parseISO } from 'date-fns';

import { dateFormat } from 'config';

import { DateRangePicker, DateRangePickerProps } from 'components/DateRangePicker';
import { FormCard } from 'components/FormCard';
import { FormTooltip } from 'components/FormTooltip';
import { getThemeColor } from 'utils/styled/getThemeColor';

import { FormDateProps } from './FormDateProps';

const disabledDateRangePicker = ({ isDisabled }: DateRangePickerProps) =>
  isDisabled &&
  css`
    .form-control {
      color: ${getThemeColor('gray')};
    }

    .close {
      display: none;
    }
  `;

const DateRangePickerStyled = styled(DateRangePicker)`
  ${disabledDateRangePicker};
`;

export const FormDate: React.FC<FormDateProps> = ({
  name,
  className,
  errors,
  label,
  required,
  validated,
  defaultValue,
  noOverlay,
  onClear,
  clearable,
  min,
  onChange: onChangeExternal,
  disabled,
  hint,
  placeholder,
}) => {
  const methods = useFormContext();
  const [focus, setFocus] = useState(false);
  const [showError, setShowError] = useState(true);
  useEffect(() => {
    if (errors) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [errors]);
  const target = useRef<HTMLDivElement>(null);
  const targetInput = useRef<HTMLDivElement>(null);

  const clearHandler = React.useCallback(() => {
    if (disabled) {
      return;
    }

    if (onClear instanceof Function) {
      onClear();
    } else {
      methods.setValue(name, null);
    }
  }, [disabled, methods, name, onClear]);

  const dataPIcker = useCallback(
    ({ value, onChange }: Partial<Omit<ControllerRenderProps, 'onChange'>> & Pick<FormDateProps, 'onChange'>) => {
      return (
        <DateRangePickerStyled
          ref={targetInput}
          isDisabled={disabled}
          clearable={clearable && Boolean(value)}
          validated={validated}
          onCancel={() => setFocus(false)}
          onFocus={() => setFocus(true)}
          errors={errors}
          onClear={clearHandler}
          isRange={false}
          min={min}
          value={typeof value === 'string' ? parseISO(value) : null}
          onApply={(range) => {
            const deadLine = range[0]
              ? format(range[0], dateFormat.formatDateForDateInput, {
                  weekStartsOn: 1,
                })
              : null;
            onChange && onChange(deadLine);
            onChangeExternal && onChangeExternal(deadLine);
            setFocus(false);
          }}
          placeholder={placeholder}
        />
      );
    },
    [clearHandler, clearable, disabled, errors, min, onChangeExternal, validated, placeholder],
  );

  return (
    <Form.Group ref={target} className={className}>
      {label && (
        <FormCard.InputLabel htmlFor={name}>
          {label} {required && <FormCard.InputLabelRequired>*</FormCard.InputLabelRequired>}
          {hint && <FormCard.InputLabelHint>{` ${hint}`}</FormCard.InputLabelHint>}
        </FormCard.InputLabel>
      )}
      {methods ? (
        <Controller
          name={name}
          onFocus={() => {
            setFocus(true);

            targetInput.current?.scrollIntoView();
          }}
          defaultValue={defaultValue}
          render={dataPIcker}
        />
      ) : (
        dataPIcker({
          value: defaultValue,
          onChange: onChangeExternal,
        })
      )}
      <FormTooltip hideOverlay={noOverlay} target={target} show={focus && showError} errors={errors} />
    </Form.Group>
  );
};

export default styled(FormDate)`
  position: relative;
`;
