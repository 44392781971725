import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { jobBoardFormActions, JobBoardSettingsFormBody } from 'containers/JobBoardsForm';
import { JobBoardSettingsForm } from 'containers/JobBoardsForm/state/models';
import { modalsActions, modalsSelectors } from 'containers/Modals/store';

import { Form } from 'components';
import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { DialogProps } from 'components/Dialog';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { ExDialog } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { jobBoardActions } from 'store/entities/job-boards';

const FormStyled = styled(Form)`
  & > *:not(:last-child) {
    margin-bottom: 0;
  }
`;

const JobBoardSettingsFormBodyStyled = styled(JobBoardSettingsFormBody)`
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;

  & .col-12:last-child .form-group {
    margin-bottom: 0;
  }
`;

const JobBoardSettingsFormFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const EditJobBoardSettings: React.FC<{ className?: string; onClose: () => void }> = ({ className, onClose }) => {
  const dispatch = useAppDispatch();
  const jobBoardId = useSelector(modalsSelectors.modalJobBoardId);
  const content: DialogProps['content'] = {
    title: 'Editing Job Board Settings',
    withTitle: true,
  };

  const submitHandler = useCallback(
    (data: Partial<JobBoardSettingsForm>) => {
      dispatch(jobBoardFormActions.updateSubscription({ data }));
    },
    [dispatch],
  );

  useEffect(
    () => {
      if (jobBoardId) {
        dispatch(jobBoardActions.updateOne({ id: jobBoardId, changes: { settings: undefined } }));
        dispatch(jobBoardFormActions.saveForm({ formData: { jobBoardId } }));
        dispatch(jobBoardActions.getSettings({ jobBoardId }));
      }
      return () => {
        dispatch(modalsActions.setJobBoardId({ jobBoardId: null }));
        dispatch(jobBoardFormActions.clearForm({}));
        if (jobBoardId) {
          dispatch(jobBoardActions.updateOne({ id: jobBoardId, changes: { settings: undefined } }));
        }
      };
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const defaultValues = {
    id: jobBoardId,
  };

  return (
    <div className={className}>
      <ExDialog onClose={onClose} content={content}>
        <FormStyled onSubmit={submitHandler} defaultValues={defaultValues} className="w-100">
          <JobBoardSettingsFormBodyStyled disableField={[]} />
          <JobBoardSettingsFormFooter>
            <ExButton variant="light" onClick={onClose}>
              Cancel
            </ExButton>
            <ExLoaderHandlerWrapper action={jobBoardFormActions.updateSubscription}>
              <ExButton className="ml-3" variant="primary" type="submit">
                Update
              </ExButton>
            </ExLoaderHandlerWrapper>
          </JobBoardSettingsFormFooter>
        </FormStyled>
      </ExDialog>
    </div>
  );
};

export default styled(EditJobBoardSettings)`
  max-width: 800px;
  width: 100%;
  bottom: 0;
`;
