import { isAnyOf } from '@reduxjs/toolkit';

import { createLookupReducer, InitialState } from 'utils/reducer/reducer-helper';

import { EntityKeys } from 'store/constants';
import { enhancedJobApi } from 'store/entities/jobs/jobs.api';
import { Job, PipelineSortMode } from 'store/entities/jobs/models';

const jobsSlice = createLookupReducer({
  name: EntityKeys.jobs,
  initialState: {
    allIds: [],
    byId: {},
  } as InitialState<Job>,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        enhancedJobApi.endpoints.jobsList.matchFulfilled,
        enhancedJobApi.endpoints.jobsListLoadMore.matchFulfilled,
      ),
      (draft, { payload, type }) => {
        jobsSlice.caseReducers.upsert(draft, {
          payload: {
            items: payload.ids.map((id) => ({ ...payload.entities[id]!, id: id as string })),
          },
          type,
        });
      },
    );

    builder.addMatcher(enhancedJobApi.endpoints.jobPipelines.matchFulfilled, (draft, { payload, type, meta }) => {
      const jobId = meta.arg.originalArgs.jobId;
      const applicantsIds = payload.applicants.map((item) => item.applicantId);
      const pipelineSortPreference: PipelineSortMode | undefined =
        payload.sortPreference.applicantOrderBy !== null && payload.sortPreference.applicantOrderDirection !== null
          ? {
              by: payload.sortPreference.applicantOrderBy,
              direction: payload.sortPreference.applicantOrderDirection,
            }
          : undefined;

      jobsSlice.caseReducers.updateOne(draft, {
        payload: {
          id: jobId,
          applicantsIds,
          pipelineSortPreference,
        },
        type,
      });
    });
  },
});

export default jobsSlice;
