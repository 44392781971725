import { createLookupReducer, InitialState } from 'utils/reducer/reducer-helper';

import { EntityKeys } from 'store/constants';
import { loadTalentPool, saveTalentPool } from 'store/entities/talent-pools/effects';
import { TalentPool } from 'store/entities/talent-pools/models';
import { talentPoolsApi } from 'store/entities/talent-pools/talent-pools.api';

export const talentPoolSlice = createLookupReducer({
  extraReducers: (builder) => {
    builder.addCase(saveTalentPool.fulfilled, (state, action) => {
      talentPoolSlice.caseReducers.upsertOne(state, action);
    });
    builder.addCase(loadTalentPool.fulfilled, (state, action) => {
      talentPoolSlice.caseReducers.upsertOne(state, action);
    });
    builder.addMatcher(talentPoolsApi.endpoints.talentPool.matchFulfilled, (draft, action) => {
      talentPoolSlice.caseReducers.upsertOne(draft, { type: action.type, payload: { item: action.payload } });
    });
  },
  initialState: {
    allIds: [],
    byId: {},
  } as InitialState<TalentPool>,
  name: EntityKeys.talentPools,
  reducers: {},
});
