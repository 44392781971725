import React, { useCallback } from 'react';
import styled from 'styled-components/macro';

import { JobApplicantsFilters } from 'containers/ApplicantLists/JobApplicantsFilters';

import { ExFilterButton } from 'components/ui/ExButton/ExFilterButton';
import { ExFilterClearButton } from 'components/ui/ExButton/ExFilterClearButton';
import { ExVisible } from 'components/ui/ExVisible';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useListFiltersModalOpenState } from 'utils/hooks/useListFiltersModalOpenState';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { applicantListResetFiltersAction } from './store/applicant-lists.actions';
import { applicantListSelectors, ApplicantListState } from './store';

type ApplicantListFilterButtonsProps = {
  className?: string;
  listId: ApplicantListState['id'];
};

const ApplicantListFilterButtonsStyled = styled.div``;

const ExFilterButtonStyled = styled(ExFilterButton)`
  margin-right: 10px;
`;

const ExFilterClearButtonStyled = styled(ExFilterClearButton)`
  margin-right: 10px;
`;

const useApplicantListFilterButtonsState = ({ className, listId }: ApplicantListFilterButtonsProps) => {
  const dispatch = useAppDispatch();

  const { handlerClickFilters, handlerToggleFilters, isFiltersOpen, onSubmit } = useListFiltersModalOpenState();

  const handleClearFilters = useCallback(() => {
    dispatch(applicantListResetFiltersAction({ listId }));
  }, [dispatch, listId]);

  const isFilterSelected = useAppSelector(applicantListSelectors.isApplicantFiltersInJobChanged, { id: listId });

  return {
    className,
    listId,
    isFilterSelected,
    handlerClickFilters,
    handlerToggleFilters,
    isFiltersOpen,
    onSubmit,
    handleClearFilters,
  } as const;
};

export const ApplicantListFilterButtons: React.FC<ApplicantListFilterButtonsProps> = (props) => {
  const {
    className,
    listId,
    isFilterSelected,
    handlerClickFilters,
    handlerToggleFilters,
    isFiltersOpen,
    onSubmit,
    handleClearFilters,
  } = useApplicantListFilterButtonsState(props);

  return (
    <ApplicantListFilterButtonsStyled className={className}>
      <ExVisible visible={isFilterSelected}>
        <ExFilterClearButtonStyled variant="link" onClick={handleClearFilters}>
          Clear
        </ExFilterClearButtonStyled>
      </ExVisible>
      <ExFilterButtonStyled onClick={handlerClickFilters} />

      <JobApplicantsFilters
        listId={listId}
        isOpen={isFiltersOpen}
        closeModal={handlerToggleFilters}
        onSubmit={onSubmit}
      />
    </ApplicantListFilterButtonsStyled>
  );
};
