import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { compareDesc } from 'date-fns';

import { ExComment } from 'model';

import { adapterHelper } from 'utils/reducer/adapter-helper';

import { EntityKeysNew } from 'store/constants';
import { Job } from 'store/entities/jobs/models';

export const jobCommentsAdapter = createEntityAdapter<ExComment>({
  selectId: (comment) => comment.commentId,
  sortComparer: (commentA, commentB) => {
    const a = new Date(commentA.createdOn).valueOf();
    const b = new Date(commentB.createdOn).valueOf();
    return compareDesc(a, b);
  },
});

const jobCommentsInitialState = jobCommentsAdapter.getInitialState({
  jobId: null as null | Job['jobId'],
  lastLoadedPage: 0,
  pageCount: 0,
});

export const jobCommentsSlice = createSlice({
  name: EntityKeysNew.jobComments,
  initialState: jobCommentsInitialState,
  reducers: {
    ...adapterHelper(jobCommentsAdapter),
    addOneAndReplace: (state, { payload }: PayloadAction<ExComment & { fakeId: string }>) => {
      const { fakeId, ...comment } = payload;
      jobCommentsSlice.caseReducers.removeOne(state, fakeId);
      jobCommentsSlice.caseReducers.addOne(state, comment);
    },

    setCurrentJobId: (state, { payload }: PayloadAction<Pick<Job, 'jobId'>>) => ({ ...state, ...payload }),
    setPageCount: (state, { payload }: PayloadAction<{ pageCount: number }>) => {
      const { pageCount } = payload;
      state.pageCount = pageCount;
    },
    updatePage: (state, { payload }: PayloadAction<{ pageCount: number; lastLoadedPage: number }>) => {
      const { lastLoadedPage, pageCount } = payload;
      if (lastLoadedPage && lastLoadedPage < pageCount) {
        state.lastLoadedPage = lastLoadedPage;
      }
      state.pageCount = pageCount;
    },
    resetCurrent: () => jobCommentsInitialState,
  },
});
