import { sectorAdapter, useSectorQuery } from 'store/dictionary/dictionary.api';

export const useSectorQuerySelectors = () => {
  const query = useSectorQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  const adapterSelectors = sectorAdapter.getSelectors(() => query.data ?? sectorAdapter.getInitialState());

  const selectors = {
    ...adapterSelectors,
  };

  return {
    ...query,
    selectors,
  };
};
