import styled, { css } from 'styled-components/macro';

import { PageTitle } from 'components/PageHeader';
import { ExAccordion, ExAccordionToggle } from 'components/ui/ExAccordion';
import { ExCard } from 'components/ui/ExCard';

export const CompanyScreeningQuestionsTabStyled = styled(ExCard)``;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  & > :nth-child(n) {
    &:not(:first-child) {
      margin-left: 16px;
    }
  }
`;

export const CompanyScreeningQuestionsTableTitleStyled = styled.div`
  font-weight: 600;
`;

export const CompanyScreeningQuestionsStyled = styled.div`
  padding: 24px;
  min-height: 100%;
`;

export const PageTitleStyled = styled(PageTitle)`
  font-size: 24px;
  flex-shrink: 0;
`;

export const grid = ({
  theme: {
    gridSize: { column1, column2, column3, column4, column5 },
  },
}) => {
  return css`
    display: grid;
    grid-template-columns: ${column1} ${column2} ${column3} ${column4} ${column5};
    column-gap: 10px;
    align-items: center;
  `;
};

export const CompanyScreeningQuestionBody = styled.div`
  max-width: 1050px;
  margin: 0 auto;
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  height: 53px;
`;
export const HeaderLink = styled.div`
  flex-grow: 1;
`;

export const CompanyScreeningQuestionElementDraggableWrapper = styled.div``;

export const ScreeningQuestionElementCard = styled(ExCard)`
  border-radius: 0 !important;
  background-color: ${({ theme }) => theme.colors.bodyBackgroundColor};
`;

export const ApplicantFormScreeningQuestionElementCard = styled(ExCard)`
  background-color: ${({ theme }) => theme.colors.bodyBackgroundColor};
`;

export const CompanyScreeningQuestionElementStyled = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border.borderColor};

  &:last-child {
    border-bottom: 0;

    ${ScreeningQuestionElementCard} {
      border-bottom-left-radius: ${({ theme }) => theme.border.borderRadius} !important;
      border-bottom-right-radius: ${({ theme }) => theme.border.borderRadius} !important;
    }
  }
`;

export const ApplicantFormScreeningQuestionElementStyled = styled.div`
  border-radius: ${({ theme }) => theme.border.borderRadiusSm};

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const CompanyScreeningQuestionHeaderStyled = styled.div`
  ${grid};
  padding: 15px;
  font-weight: 600;
  background-color: ${({ theme }) => theme.colors.bodyBackgroundColor};
  border-top-left-radius: ${({ theme }) => theme.border.borderRadius};
  border-top-right-radius: ${({ theme }) => theme.border.borderRadius};
  border-bottom: 1px solid ${({ theme }) => theme.border.borderColor};
`;

export const ScreeningQuestionsLine = styled.div`
  ${grid};
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.bodyBackgroundColor};
`;

export const ScreeningQuestionsLineContent = styled.div`
  grid-column: 2 / 6;
`;

export const getExAccordionStyled = css`
  ${grid};
  grid-template-rows: auto auto;
`;

export const ExAccordionStyled = styled(ExAccordion)`
  ${getExAccordionStyled};
`;

export const DnDButton = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding-top: 2px;
`;

export const DnDContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
`;

export const ScreeningQuestionContainer = styled.div`
  grid-column: 2;
  grid-row: 1;
`;

export const TypeContainer = styled.div`
  grid-column: 3;
  grid-row: 1;
`;

export const MandatoryContainer = styled.div`
  grid-column: 4;
  grid-row: 1;
`;

export const ActionsContainer = styled.div`
  grid-column: 5;
  grid-row: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const actionButton = css`
  border: 0;
  background: none;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  * {
    cursor: inherit;
  }
`;

export const EditActionButton = styled.button.attrs({ type: 'button' })`
  ${actionButton};
`;

export const DeleteActionButton = styled.button.attrs({ type: 'button' })`
  ${actionButton};
`;

export const ExAccordionToggleStyled = styled(ExAccordionToggle)`
  ${actionButton};
`;

export const getCollapsedContainerStyled = css`
  grid-column: 2 / 6;
  grid-row: 2;
`;

export const CollapsedContainer = styled(ExAccordion.Collapse)`
  ${getCollapsedContainerStyled};
`;

export const ScreeningQuestionTitle = styled.div`
  font-weight: 600;
`;

export const ScreeningQuestionInfo = styled.div`
  margin-top: 4px;
`;

export const ContentContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;
`;

export const ScrollWrapper = styled.div`
  overflow-x: auto;
`;

export const ScrollWrapperInner = styled.div`
  min-width: 650px;
`;

export const ApplicantFormScreeningQuestionElementV2Styled = styled.div`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const ApplicationFormScreeningQuestionTitleV2 = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 10px;

  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: #404968;
`;

export const ApplicationFormScreeningQuestionViewWrapperV2 = styled.div``;
