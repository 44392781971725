import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'api-client/axiosBaseQuery';

export const emptyApi = createApi({
  reducerPath: 'api',
  keepUnusedDataFor: 30,
  refetchOnMountOrArgChange: 15,
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/company/{companyId}',
  }),
  endpoints: () => ({}),
});

export const emptyApiGlobal = createApi({
  reducerPath: 'apiGlobal',
  keepUnusedDataFor: 30,
  baseQuery: axiosBaseQuery({
    baseUrl: '/api',
  }),
  endpoints: () => ({}),
});

export const emptyApiPublic = createApi({
  reducerPath: 'apiPublic',
  keepUnusedDataFor: 30,
  baseQuery: axiosBaseQuery({
    baseUrl: '/public/api/company',
  }),
  endpoints: () => ({}),
});
