export const CompanyEndpoints = {
  SearchCompaniesFunc: '/api/company',
  CompanyInfo: '/api/my/company', // MOCK ENDPOINT. Such path don't exists on Swagger.
  CompanySettings: '/api/company/{companyId}/setting', // MOCK ENDPOINT. Such path don't exists on Swagger.
  GetCompanyFunc: '/api/company/{companyId}',
  UpdateCompanyFunc: '/api/company/{companyId}',
  UpdateCompanyCurrenciesFunc: '/api/company/{companyId}/currencies',
  UpdateCompanyCountriesFunc: '/api/company/{companyId}/countries',
  UpdateCompanyIndustriesFunc: '/api/company/{companyId}/industries',
  GetCompanyIdibuSettingsFunc: '/api/company/{companyId}/idibu',
  UpdateCompanyIdibuSettingsFunc: '/api/company/{companyId}/idibu',
  SearchQualificationsFunc: '/api/company/{companyId}/qualification',
  UpdateQualificationFunc: '/api/company/{companyId}/qualification/{qualificationId}',
  AvailableQualificationsFunc: '/api/company/{companyId}/qualification/available',
  DaxtraQualificationsFunc: '/api/company/{companyId}/qualification/daxtra-qualifications',
  AssignDaxtraMappingFunc: '/api/company/{companyId}/qualification/assign-daxtra-mapping',
  UploadCompanyLogoFunc: '/api/company/{companyId}/logo',
  SearchCommentsFunc: '/api/company/{companyId}/comment',
  SearchMentionsFunc: '/api/company/{companyId}/comment/mention',
  GetAutocompleteUsersFunc: '/api/company/{companyId}/user/autocomplete',
};
