import { createSlice } from '@reduxjs/toolkit';

import { WidgetReducers } from 'store/ui/widgets/widgets.constants';

import * as fromCandidateSourcesActions from './candidate-sources.actions';
import { ApplicantsPerSourceFigures } from './candidate-sources.model';

interface CandidateSourcesInitState extends ApplicantsPerSourceFigures {}

const initialState: CandidateSourcesInitState = {
  applicantsPerSourceFigures: [],
};

export const candidateSourcesSlice = createSlice({
  name: WidgetReducers.CandidateSources,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fromCandidateSourcesActions.requestCandidateSourcesSuccess, (state, action) => {
      state.applicantsPerSourceFigures = action.payload.applicantsPerSourceFigures;
    });
  },
});
