import React from 'react';
import styled from 'styled-components/macro';

import { ModalSide } from 'components/ModalSide';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { ExCloseButton } from 'components/ui/ExButton/ExCloseButton';
import { useNoScrollBodyWhen } from 'utils/hooks/useNoScrollBody';

const CloseButton = styled(ExCloseButton)`
  margin-left: auto;
`;

type ExSideModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  onApplyFilters: React.MouseEventHandler<HTMLButtonElement>;
  onClearFilters: () => void;
  title: string;
};

export const ExFiltersSideModal: React.FC<ExSideModalProps> = ({
  isOpen,
  closeModal,
  onApplyFilters,
  title,
  children,
  onClearFilters,
}) => {
  useNoScrollBodyWhen(isOpen);

  return (
    <ModalSide.ModalCSSTransition in={isOpen}>
      <ModalSide title={title}>
        <ModalSide.ModalHeader>
          <ModalSide.ModalTitle>{title}</ModalSide.ModalTitle>
          <CloseButton onClick={closeModal} />
        </ModalSide.ModalHeader>
        <ModalSide.ModalBody>{children}</ModalSide.ModalBody>
        <ModalSide.ModalActionWrapper>
          <ExButton block variant="light" onClick={onClearFilters}>
            Clear Filters
          </ExButton>
          <ExButton block onClick={onApplyFilters}>
            Apply Filters
          </ExButton>
        </ModalSide.ModalActionWrapper>
      </ModalSide>
    </ModalSide.ModalCSSTransition>
  );
};
