import { createApiMethod } from 'api-endpoints/utils';

import { CalendarEndpoints } from './calendar.endpoints';
import type * as fromCalendarApiModels from './calendar-api.models';

// GetOpenJobsFunc
export const getOpenJobsFunc = createApiMethod<
  fromCalendarApiModels.GetOpenJobsFuncParams,
  fromCalendarApiModels.GetOpenJobsFuncResponse
>({
  url: CalendarEndpoints.getOpenJobsFunc,
  method: 'GET',
});

// GetActiveJobAdsFunc
export const getActiveJobAdsFunc = createApiMethod<
  fromCalendarApiModels.GetActiveJobAdsFuncParams,
  fromCalendarApiModels.GetActiveJobAdsFuncResponse
>({
  url: CalendarEndpoints.getActiveJobAdsFunc,
  method: 'GET',
});
