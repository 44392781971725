import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';

import { companyScreeningQuestionUiSlice } from './company-screening-question.reducer';

const domain = (state: RootState) => state.ui.companyUi[companyScreeningQuestionUiSlice.name];

const selectQuestionId = createSelector(domain, (state) => state.editQuestionId);

const isSideModalOpen = createSelector(selectQuestionId, Boolean);

const isNewQuestionId = createSelector(selectQuestionId, (editQuestionId) => editQuestionId === 'new');

export const companyScreeningQuestionUiSelectors = {
  selectQuestionId,
  isSideModalOpen,
  isNewQuestionId,
};
