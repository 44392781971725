import { providesList } from 'api-client/axiosBaseQuery';
import { replaceParamsInUrl } from 'api-client/utils';
import { TalentPoolEndpointsApi } from 'api-endpoints/talent-pool/endpoints';
import * as fromApiTalentPoolDto from 'api-endpoints/talent-pool/talent-pool.dto';

import { pluck } from 'utils/pluck';

import { emptyApi } from 'store/entities/emptyApi';

const talentPoolsApiInjected = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    talentPool: build.query<
      fromApiTalentPoolDto.GetTalentPoolFuncResponse,
      fromApiTalentPoolDto.GetTalentPoolFuncParams
    >({
      query: (args) => ({
        url: replaceParamsInUrl(TalentPoolEndpointsApi.GetTalentPoolFunc, args),
        method: 'GET',
      }),
    }),
    talentPoolCreate: build.mutation<
      fromApiTalentPoolDto.CreateTalentPoolFuncResponse,
      fromApiTalentPoolDto.CreateTalentPoolFuncParams
    >({
      query: (args) => ({
        url: replaceParamsInUrl(TalentPoolEndpointsApi.CreateTalentPoolFunc, {}),
        method: 'POST',
        data: args,
      }),
    }),
    talentPoolList: build.query<
      fromApiTalentPoolDto.SearchTalentPoolsFuncResponse,
      fromApiTalentPoolDto.SearchTalentPoolsFuncParams
    >({
      query: (args) => ({
        url: replaceParamsInUrl(TalentPoolEndpointsApi.SearchTalentPoolsFunc, {}),
        method: 'GET',
        params: args,
      }),
    }),
    talentPoolAutocomplete: build.query<
      fromApiTalentPoolDto.TalentPoolsAutocompleteFuncResponse,
      fromApiTalentPoolDto.TalentPoolsAutocompleteFuncParams
    >({
      query: (args) => ({
        method: 'GET',
        url: replaceParamsInUrl(TalentPoolEndpointsApi.TalentPoolsAutocompleteFunc, {}),
        params: args,
      }),
    }),
    getKeywordsFunc: build.query<
      fromApiTalentPoolDto.GetKeywordsFuncResponse,
      fromApiTalentPoolDto.GetKeywordsFuncParams
    >({
      query: ({ talentPoolId, ...params }) => ({
        url: replaceParamsInUrl(TalentPoolEndpointsApi.GetKeywordsFunc, { talentPoolId }),
        params,
        method: 'GET',
      }),
    }),
  }),
});

export const talentPoolsApi = talentPoolsApiInjected.enhanceEndpoints({
  addTagTypes: ['TalentPool'],
  endpoints: {
    talentPool: {
      providesTags: (res, err, { talentPoolId }) => [{ type: 'TalentPool', id: talentPoolId }],
    },
    talentPoolCreate: {
      invalidatesTags: (_, error) => (!error ? [{ type: 'TalentPool', id: 'LIST' }] : []),
    },
    talentPoolList: {
      providesTags: (res) => providesList(res?.items.map(pluck('id')), 'TalentPool'),
    },
  },
});

export const { useTalentPoolQuery } = talentPoolsApi;
