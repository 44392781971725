import React from 'react';
import styled from 'styled-components';

import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';

type ExProgressProps = {
  className?: string;
  value?: number;
};

const ExProgressStyled = styled.div`
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.border.borderColor};
  border-radius: ${getThemeBorderProps('borderRadius')};
  height: 12px;
  width: 100%;
  overflow: hidden;
`;

const ExProgressStyledBar = styled.div<{ value: number }>`
  width: ${(props) => props.value}%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.dark};
  border-radius: ${getThemeBorderProps('borderRadius')};
  transition: width 0.3s linear;
`;

export const ExProgress: React.FC<ExProgressProps> = ({ className, value = 0 }) => {
  return (
    <ExProgressStyled className={className}>
      <ExProgressStyledBar value={value} />
    </ExProgressStyled>
  );
};
