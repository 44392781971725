import { Routes } from 'router/names';

import {
  NewApplicantReviewStep,
  NewApplicantSelectCandidate,
  NewApplicantSelectJobStep,
  NewApplicantSelectResumeStep,
  validationSchemaNewApplicant,
  validationSchemaNewApplicantFirstStep,
  validationSchemaNewApplicantFiveStep,
  validationSchemaNewApplicantFourthStep,
  validationSchemaNewApplicantSecondStep,
  validationSchemaNewApplicantThirdStep,
} from 'containers/ApplicantForm';
import NewApplicantDetailsStep from 'containers/ApplicantForm/ApplicantFormSteps/NewApplicantDetailsStep';
import NewApplicantSelectCoverLetterStep from 'containers/ApplicantForm/ApplicantFormSteps/NewApplicantSelectCoverLetterStep';

import { Step } from 'components/StepForm';

import { SelectedCandidate, SelectedJob } from './ApplicantNewComponents';

const initFormSteps: Step[] = [
  {
    stepNumber: 1,
    label: 'Select Candidate',
    component: NewApplicantSelectCandidate,
    path: Routes.applicantStepSelectCandidate,
    schema: validationSchemaNewApplicantFirstStep,
    renderSubTitle: SelectedCandidate,
  },
  {
    stepNumber: 2,
    label: 'Select Job',
    component: NewApplicantSelectJobStep,
    path: Routes.applicantStepSelectJob,
    schema: validationSchemaNewApplicantSecondStep,
    disabled: true,
    renderSubTitle: SelectedJob,
  },
  {
    stepNumber: 3,
    label: 'Resume',
    component: NewApplicantSelectResumeStep,
    path: Routes.applicantStepSelectResume,
    schema: validationSchemaNewApplicantThirdStep,
    disabled: true,
  },
  {
    stepNumber: 4,
    label: 'Cover Letter',
    component: NewApplicantSelectCoverLetterStep,
    path: Routes.applicantStepSelectCoverLetter,
    schema: validationSchemaNewApplicantFourthStep,
    disabled: true,
  },
];

export const initFormStepsV1: Step[] = [
  ...initFormSteps,
  {
    stepNumber: 5,
    label: 'Review',
    component: NewApplicantReviewStep,
    path: Routes.applicantStepReview,
    disabled: true,
    navButtonsProps: {
      nextButtonText: 'Create Application',
    },
  },
];

export const initFormStepsV2: Step[] = [
  ...initFormSteps,
  {
    stepNumber: 5,
    label: 'Application details',
    component: NewApplicantDetailsStep,
    path: Routes.applicantStepDetails,
    schema: validationSchemaNewApplicantFiveStep,
    disabled: true,
  },
  {
    stepNumber: 6,
    label: 'Review',
    component: NewApplicantReviewStep,
    path: Routes.applicantStepReview,
    schema: validationSchemaNewApplicant,
    disabled: true,
    navButtonsProps: {
      nextButtonText: 'Create Application',
    },
  },
];
