import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { uploadCandidateAttachment } from 'api-endpoints/candidate';

import { Country } from 'model';
import { DateTimeType } from 'model/api-enums.constants';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { loaderActions, loaderSelectors } from 'containers/Loader/store';
import { modalsSelectors } from 'containers/Modals/store';

import { FormDate, FormInput, FormSelect } from 'components';
import { FormFile } from 'components/FormFile';
import { FILE_INPUT_ACCEPT_DOCUMENT, fileValidationRuleTypeResume } from 'utils/files';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useOnChangeFilewithValidation } from 'utils/hooks/useFileInputValidation';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getTranslate } from 'utils/i18utils';

import { useCountryQuerySelectors } from 'store/dictionary/hooks/useCountryQuerySelectors';
import { QualificationTypeModel, qualificationTypeSelectors } from 'store/entities/qualification-type';

import { EducationFormBodyStyled } from './EducationFormBodyComponents';
import { EducationFormBodyProps } from './EducationFormBodyProps';

const fileValidationOptions = [fileValidationRuleTypeResume];

const EducationFormBodyState = <T extends Record<string, any>>({
  className,
  index,
  value,
  groupName,
  idFieldName,
}: EducationFormBodyProps<T>) => {
  const dispatch = useAppDispatch();
  const { setValue, watch, register, formState, errors: errorsContext } = useFormContext();
  const [fileName, setFileName] = useState<string | undefined | null>(value?.educationAttachmentFileName);
  const { selectors: countriesSelectors } = useCountryQuerySelectors();

  const countries = useAppSelector(countriesSelectors.selectAll);

  const qualificationTypesList = useAppSelector(qualificationTypeSelectors.selectAll);

  const getName = useCallback(
    (name: keyof T) => (index !== undefined ? `${groupName}[${index}].${name}` : (name as string)),
    [groupName, index],
  );

  const educationAttachmentId = watch('educationAttachmentId');

  const [educationAttachmentDefaultValue, setEducationAttachmentDefaultValue] = useState(
    educationAttachmentId || value?.educationAttachmentId,
  );

  const validated = formState.isSubmitted;

  const errors = useMemo(
    () => (index !== undefined ? errorsContext[groupName]?.[index] : errorsContext),
    [errorsContext, groupName, index],
  );

  const onClearValue = useCallback(
    (name: string) => () => {
      setEducationAttachmentDefaultValue(null);
      setValue(name, null);
    },
    [setValue],
  );

  const onClearFile = useCallback(() => {
    onClearValue(getName('educationAttachmentId'))();
    setFileName(undefined);
  }, [onClearValue, setFileName, getName]);

  const candidateId = useAppSelector(modalsSelectors.modalCandidateId);

  const uploadHandler = useCallback(
    async (file: File[]) => {
      const formData = new FormData();
      formData.append('uploadCandidateAttachment', file[0], file[0].name);
      dispatch(loaderActions.start('educationAttachmentId'));
      const result = await uploadCandidateAttachment({
        data: formData,
        urlParams: { candidateId: candidateId! },
      });
      dispatch(loaderActions.stop('educationAttachmentId'));
      if (!result.message) {
        setFileName(result.data?.filename);
        setValue(getName('educationAttachmentId'), result.data.educationAttachmentId);
        setEducationAttachmentDefaultValue(result.data.educationAttachmentId);
        return;
      }
      const validationErrorCodes = result.response?.data.validationErrorCodes;
      validationErrorCodes?.forEach(function (validationErrorCode: string) {
        const validationErrorMessage = getTranslate(validationErrorCode, { ns: 'validationErrorCodes' });
        dispatch(alertsEffects.showError({ message: validationErrorMessage || result.message || 'Error' }));
      });
    },
    [dispatch, candidateId, getName, setValue],
  );

  const { onChangeFileWithValidation } = useOnChangeFilewithValidation({
    options: fileValidationOptions,
    withToasts: true,
    onSuccess: uploadHandler,
  });

  const isDocumentUploading = useAppSelector(loaderSelectors.isTaskActive, {
    taskId: 'educationAttachmentId',
  });

  const startDateFieldName = useMemo(() => getName('startDate.actualDate'), [getName]);
  const expiryDateFieldName = useMemo(() => getName('expiryDate.actualDate'), [getName]);

  return {
    getName,
    className,
    errors,
    register,
    value,
    validated,
    countries,
    setValue,
    onClearFile,
    watch,
    onClearValue,
    qualificationTypesList,
    onChangeFileWithValidation,
    fileName,
    educationAttachmentDefaultValue,
    idFieldName,
    startDateFieldName,
    expiryDateFieldName,
    isDocumentUploading,
  } as const;
};

export const EducationFormBody = <T extends Record<string, any>>(props: EducationFormBodyProps<T>) => {
  const {
    getName,
    className,
    errors,
    register,
    value,
    validated,
    countries,
    setValue,
    onClearFile,
    watch,
    onClearValue,
    qualificationTypesList,
    onChangeFileWithValidation,
    fileName,
    educationAttachmentDefaultValue,
    idFieldName,
    startDateFieldName,
    expiryDateFieldName,
    isDocumentUploading,
  } = EducationFormBodyState(props);

  return (
    <EducationFormBodyStyled className={`${className} row`}>
      <input ref={register} name={getName(idFieldName)} hidden defaultValue={watch(getName(idFieldName))} />
      <FormInput
        className="col-md-6"
        label="Name"
        name={getName('name')}
        required
        errors={errors?.name}
        validated={validated}
        inputRef={register({ required: 'Name is required' }) as any}
        defaultValue={value?.name}
      />
      <FormInput
        className="col-md-6"
        label="Number"
        name={getName('number')}
        required
        errors={errors?.number}
        validated={validated}
        inputRef={register({ required: 'Number is required' }) as any}
        defaultValue={value?.number}
      />
      <FormInput
        className="col-md-6"
        label="Organization"
        name={getName('organization')}
        required
        errors={errors?.organization}
        validated={validated}
        inputRef={register({ required: 'Organization is required' }) as any}
        defaultValue={value?.organization}
      />
      <FormSelect
        isClearable
        label="Country"
        name={getName('country')}
        className="col-md-6"
        getOptionLabel={(option: Country) => option.name}
        getOptionValue={(option: Country) => option?.code?.expedoCode2}
        maxMenuHeight={150}
        validated={validated}
        options={countries}
        isMulti={false}
        defaultOptions
        errors={errors?.country}
        components={{
          Placeholder: () => null,
          IndicatorSeparator: null,
        }}
        onChange={(selected: Country) => {
          setValue(getName('country'), selected?.code?.expedoCode2 ?? null);
        }}
      />
      <FormInput
        className="col-md-6"
        label="Issuing Authority City"
        name={getName('issuingAuthorityCity')}
        errors={errors?.issuingAuthorityCity}
        validated={validated}
        inputRef={register}
        defaultValue={value?.issuingAuthorityCity ?? undefined}
      />
      <FormSelect
        label="Qualification Type"
        name={getName('qualificationId')}
        className={'col-md-6'}
        placeholder=""
        errors={errors?.qualificationType}
        validated={validated}
        inputRef={register}
        options={qualificationTypesList}
        getOptionLabel={(option: QualificationTypeModel) => option.qualificationName}
        getOptionValue={(option: QualificationTypeModel) => option.qualificationId}
      />
      <input
        type="text"
        ref={register({ valueAsNumber: true })}
        name={getName('startDate.dateTimeType')}
        hidden
        defaultValue={DateTimeType.ActualDate}
      />
      <FormDate
        className="col-md-6"
        label="Start Date"
        name={startDateFieldName}
        errors={errors?.startDate?.actualDate}
        validated={validated}
        inputRef={register}
        clearable={watch(startDateFieldName)}
        onClear={onClearValue(startDateFieldName)}
      />
      <input
        type="text"
        ref={register({ valueAsNumber: true })}
        name={getName('issueDate.dateTimeType')}
        hidden
        defaultValue={DateTimeType.ActualDate}
      />
      <FormDate
        className="col-md-6"
        label="Issue Date"
        required
        name={getName('issueDate.actualDate')}
        errors={errors?.issueDate?.actualDate}
        validated={validated}
        inputRef={register}
      />
      <input
        type="text"
        ref={register({ valueAsNumber: true })}
        name={getName('expiryDate.dateTimeType')}
        hidden
        defaultValue={DateTimeType.ActualDate}
      />
      <FormDate
        className="col-md-6"
        label="Expiry Date"
        name={expiryDateFieldName}
        errors={errors?.expiryDate?.actualDate}
        validated={validated}
        inputRef={register}
        clearable={watch(expiryDateFieldName)}
        onClear={onClearValue(expiryDateFieldName)}
      />
      <FormFile
        className="col-md-6"
        label="File Upload"
        name={getName('educationAttachmentId')}
        errors={errors?.educationAttachmentId}
        validated={validated}
        inputRef={register()}
        defaultValue={educationAttachmentDefaultValue}
        onFileChange={onChangeFileWithValidation}
        clearable={watch(getName('educationAttachmentId'))}
        onClear={onClearFile}
        overrideDefaultValue={fileName}
        accept={FILE_INPUT_ACCEPT_DOCUMENT}
        isLoading={isDocumentUploading}
      />
    </EducationFormBodyStyled>
  );
};
