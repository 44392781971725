import { PayloadAction } from '@reduxjs/toolkit';
import { Task } from 'redux-saga';
import { all, call, cancel, fork, put, putResolve, select, take, takeLatest } from 'redux-saga/effects';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { loaderActions } from 'containers/Loader/store';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';
import {
  talentPoolFormActions,
  talentPoolFormEffects,
  talentPoolFormSelectors,
} from 'containers/TalentPoolForms/state';
import { loadTalentPools } from 'containers/TalentPoolLists/store/effects';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { getTranslate } from 'utils/i18utils';

import * as fromCandidateActions from 'store/entities/candidates/candidate.actions';
import { Candidate } from 'store/entities/candidates/models';
import { candidatesSelectors } from 'store/entities/candidates/selectors';
import * as fromJobActions from 'store/entities/jobs/job.actions';
import { TalentPoolBelongsTo } from 'store/entities/talent-pools/models';
import * as fromModalActions from 'store/modals/modals.actions';
import * as fromModalConstants from 'store/modals/modals.constants';
import { ExModal } from 'store/modals/modals.interfaces';
import { modalSagaWorker } from 'store/modals/modals.sagas';
import { modalById } from 'store/modals/modals.selectors';

function* onStepChangeBackgroundActions() {
  let task: Task | undefined;
  while (true) {
    const {
      payload: { id, page },
    }: PayloadAction<fromModalActions.UpdateWizardPage> = yield take(fromModalConstants.MODAL_PAGE_UPDATE);

    // Cancel ongoing process if exists
    if (task && task.isRunning()) {
      yield cancel(task);
    }

    switch (page) {
      case 'addToTalentPool':
        task = yield fork(prepareStepSelectTalentPool, id);
        break;
      case 'createNewTalentPool':
        task = yield fork(createNewTalentPool, id);
        break;
    }
  }
}

function* createNewTalentPool(id: string) {
  const modal: ExModal = yield select(modalById, id);
  yield put(
    fromModalActions.exModalPropsAction({
      ...modal,
      modalConfig: {
        ...modal.modalConfig,
        content: {
          ...modal.modalConfig?.content,
          withTitle: true,
          title: 'Create new Talent Pool',
        },
      },
    }),
  );
}

function* loadCandidateTalentPools(candidateId: Candidate['id'], isInit = false) {
  yield putResolve(
    talentPoolFormEffects.loadCandidateTalentPools({
      candidateId,
      preloader: true,
    }) as any,
  );

  const candidateTalentPools: ReturnType<typeof talentPoolFormSelectors.candidateTalentPoolIds> = yield select(
    talentPoolFormSelectors.candidateTalentPoolIds,
  );
  const selectedTalentPoolsIds: ReturnType<typeof talentPoolFormSelectors.selectedTalentPoolsIds> = yield select(
    talentPoolFormSelectors.selectedTalentPoolsIds,
  );

  yield put(
    talentPoolFormActions.updateState({
      selectedTalentPoolsIds: Array.from(new Set([...selectedTalentPoolsIds, ...(isInit ? candidateTalentPools : [])])),
      candidateTalentPoolIds: [...candidateTalentPools],
      apiErrors: [],
    }),
  );
}

function* prepareStepSelectTalentPool(id: string) {
  const modal: ExModal = yield select(modalById, id);

  if (!modal.modalProps?.id) {
    return;
  }

  yield loadCandidateTalentPools(modal.modalProps?.id);

  yield put(
    fromModalActions.exModalPropsAction({
      ...modal,
      modalConfig: {
        ...modal.modalConfig,
        content: {
          ...modal.modalConfig?.content,
          withTitle: true,
          title: 'Add to new Talent Pool',
        },
      },
    }),
  );
}

function* handleTransition() {
  while (true) {
    const { payload }: PayloadAction<fromModalActions.WizardNavigation> = yield take([
      fromModalConstants.MODAL_WIZARD_FORWARD,
      fromModalConstants.MODAL_WIZARD_BACKWARD,
      fromModalConstants.MODAL_WIZARD_ERROR,
    ]);
    const id = payload.id;
    if (!id) {
      break;
    }
    const { modalConfig }: ExModal = yield select(modalById, id);
    const { page } = modalConfig || {};

    if (page === 'createNewTalentPool') {
      yield put(fromModalActions.updateWizardPage({ id, page: 'addToTalentPool' }));
    }
  }
}

function* candidateAddToTalentPoolSaga(action: { type: string; payload: { id: Candidate['id'] } }) {
  const candidateId = action.payload.id;

  const candidate: ReturnType<typeof candidatesSelectors.getById> = yield select(
    candidatesSelectors.getById,
    candidateId,
  );

  /**
   * If candidate has flag do not hire we show warning and prevent show modal add to talent pool;
   */
  // START doNotHire
  if (candidate?.doNotHire) {
    const candidateMarkedAsDoNotHireText = getTranslate('candidate.candidate_marked_as_do_not_hire');

    yield put(
      alertsEffects.showError({
        message: candidateMarkedAsDoNotHireText,
        isHtml: true,
        delay: 5000,
        containerClassName: 'text-center',
      }),
    );
    return;
  }
  // END doNotHire

  yield loadCandidateTalentPools(candidateId, true);

  const result = yield call(modalSagaWorker, {
    modalType: ModalsTypeKey.wizard,
    modalConfig: {
      wizardType: 'candidateAddToTalentPool',
      page: 'addToTalentPool',
      content: {
        title: 'Add To Talent Pool',
        withTitle: true,
      },
    },
    modalProps: {
      id: candidateId,
    },
  });

  if (result.cancel) {
    yield put(talentPoolFormActions.clearFormTalentPool({}));
    return;
  }

  const modalId = result.confirm.payload.id;

  yield put(loaderActions.start(fromJobActions.updateJobStatusWithModalAction.type));
  yield call(startLoader, action);
  const { message } = yield putResolve(talentPoolFormEffects.updateCandidateTalentPools({ candidateId }) as any);

  yield put(loaderActions.stop(fromJobActions.updateJobStatusWithModalAction.type));

  if (message) {
    yield put(alertsEffects.showError({ message }));
  } else {
    yield putResolve(loadTalentPools({ listId: TalentPoolBelongsTo.candidate }) as any);
  }
  yield all([
    put(fromModalActions.exModalHideAction({ id: modalId })),
    put(talentPoolFormActions.clearFormTalentPool({})),
    call(stopLoader, action),
  ]);
}

export function* candidateAddToTalentPoolSagas() {
  yield fork(handleTransition);
  yield fork(onStepChangeBackgroundActions);
}

export function* takeJobModalActions() {
  yield takeLatest(fromCandidateActions.candidateAddToTalentPool, candidateAddToTalentPoolSaga);
}
