import React from 'react';
import styled from 'styled-components';

import { fullscreenModalStyleV2 } from 'containers/Modals/modals.styled';

type ExCreateEntityWrapperProps = {
  className?: string;
  children: React.ReactNode;
};

const ExCreateEntityWrapperStyledV2 = styled.div`
  ${fullscreenModalStyleV2};
`;

const useExCreateEntityWrapperState = ({ className, children }: ExCreateEntityWrapperProps) => {
  const Wrapper = ExCreateEntityWrapperStyledV2;

  return {
    className,
    Wrapper,
    children,
  };
};

export const ExCreateEntityWrapper: React.FC<ExCreateEntityWrapperProps> = (props) => {
  const { className, Wrapper, children } = useExCreateEntityWrapperState(props);

  return <Wrapper className={className}>{children}</Wrapper>;
};
