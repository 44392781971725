import React from 'react';
import { globalPageAnimation } from 'globalStyle';
import styled, { AnyStyledComponent, css } from 'styled-components';

import { Spinner } from 'components/Spinner';
import { innerTableStyles } from 'components/TableList/TableListStyles';
import { ExCard } from 'components/ui/ExCard';
import { exCardStyles } from 'components/ui/ExCard/ExCardComponents';
import { TableRowOverlayWithLoader } from 'components/ui/ExTable/ExTable.components';

import { TableListWrapperProps } from './TableListProps';

// TableListWrapper
const TLW: React.FC<TableListWrapperProps> = ({ children, className, isLoading, dataLength }) => {
  const showLoading = Boolean(isLoading && !dataLength);
  const showLoadingOverlay = Boolean(isLoading && dataLength);

  return (
    <div className={className}>
      {children}
      {showLoading && <Spinner />}
      {showLoadingOverlay && <TableRowOverlayWithLoader component={<Spinner />} />}
    </div>
  );
};

export const TableListWrapper = styled(TLW)`
  display: flex;
  flex-direction: column;
`;

export const tableHeadTickStyles = { width: '1%', minWidth: '80px' };

const exCardTableOverflowCss = css`
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ExCardTable = styled.div`
  ${exCardStyles};
  ${globalPageAnimation}
  ${exCardTableOverflowCss};
  padding-top: 0;
`;

export const ExCardTableWithHeader = styled(ExCard)`
  .list-table thead th {
    top: -20px !important;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-basis: 34px;
  position: relative;

  &:not(:empty) {
    margin-top: 15px;
  }
`;

export const innerTablesStyled = (component: AnyStyledComponent | React.FC<any>) => styled(component)`
  .list-table {
    ${innerTableStyles};
  }
`;

export const scrollableTableBody = (component: AnyStyledComponent | React.FC<any>, maxHeight = 300) => styled(
  component,
)`
  tbody {
    max-height: ${maxHeight}px;
    overflow: auto;
    display: block;

    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }

  thead {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
`;

export const TableEmptyRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
`;

export const TableEmptyRowLoaderStyle = styled(TableEmptyRow)`
  line-height: 32px;
  padding-bottom: 20px;
`;

type ExCardTableWrapperProps = {
  className?: string;
};

export const ExCardTableWrapper: React.FC<ExCardTableWrapperProps> = ({ children, className }) => {
  return <ExCardTable className={className}>{children}</ExCardTable>;
};
