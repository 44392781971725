import { all, call, fork, put, putResolve, race, SagaReturnType, select, take } from 'redux-saga/effects';

import {
  bulkCreateCandidatesFromResumesFunc,
  bulkCreateTalentPoolCandidatesFromResumesFunc,
} from 'api-endpoints/talent-pool';
import { talentPoolCandidatesApi } from 'api-endpoints/talent-pool-candidates';

import { rbacConstants } from 'containers/Auth/AuthMatrix';
import {
  bulkCreateCandidate,
  bulkCreateCandidateAnnNewTalentPool,
  bulkUploadResumes,
} from 'containers/BulkCreation/bulkCreation.actions';
import { generateUploadMessages, prepareFormData, uploadResumes } from 'containers/BulkCreation/utils/utils.sagas';
import { talentPoolFormActions, talentPoolFormSelectors } from 'containers/TalentPoolForms/state';

import { invokeApiCall, invokeExModalWizard, prepareExModalChannel, ReturnData } from 'utils/sagas';

import { authSelectors } from 'store/auth/auth.selectors';
import { talentPoolActions, talentPoolEffects, talentPoolSelectors } from 'store/entities/talent-pools';
import { ModalWizardPageNames } from 'store/modals/config';
import { updateWizardPage, wizardForward } from 'store/modals/modals.actions';
import { ModalGeneralResult } from 'store/modals/modals.interfaces';
import { modalWizardPageById } from 'store/modals/modals.selectors';

const titleToTalentPool = 'Bulk Upload Resumes to Talent Pool';
const title = 'Bulk Upload Resumes';

function* createNewTalentPoolModal({ modalId, selectedTalentPool }) {
  const selectedTalentPoolsIds: ReturnType<typeof talentPoolFormSelectors.selectedTalentPoolsIds> = yield select(
    talentPoolFormSelectors.selectedTalentPoolsIds,
  );

  const isAddToTalentPoolAllowed = yield select(authSelectors.isFeatureAllowed, {
    feature: rbacConstants.candidates.addToTalentPool,
  });

  yield put(
    updateWizardPage({
      id: modalId,
      page: 'pending',
      modalConfig: {
        content: {
          title: isAddToTalentPoolAllowed ? titleToTalentPool : title,
          withTitle: true,
        },
      },
    }),
  );

  const selectedTalentPoolId = selectedTalentPoolsIds[0];
  if (selectedTalentPoolId) {
    const result = yield putResolve(talentPoolEffects.loadTalentPool({ id: selectedTalentPoolId }) as any);
    selectedTalentPool = result.payload.item ?? selectedTalentPool;
    yield all([
      put(talentPoolFormActions.clearFormTalentPool({})),
      put(talentPoolActions.loadOne({ item: selectedTalentPool })),
    ]);
  }
  yield put(
    updateWizardPage({
      id: modalId,
      page: 'createCandidates',
      modalProps: {
        selectedTalentPool,
      },
    }),
  );
}

function* createCandidatesModal({ formData, talentPoolId, onUploadProgress, files, modalId }) {
  const method = talentPoolId ? bulkCreateTalentPoolCandidatesFromResumesFunc : bulkCreateCandidatesFromResumesFunc;

  const { data, errorData, message }: ReturnData<typeof bulkCreateTalentPoolCandidatesFromResumesFunc> =
    yield invokeApiCall(method, {
      data: formData,
      urlParams: { talentPoolId },
      onUploadProgress,
    });

  if (!errorData && !message && data) {
    const { errorsCount, messageSuccessUploaded, successPageMessage } = generateUploadMessages(
      data,
      files.length,
      'candidates',
    );

    const variant = Boolean(errorsCount) ? 'error' : 'success';

    yield put(
      updateWizardPage({
        id: modalId,
        page: 'success',
        modalConfig: { content: { withTitle: false } },
        modalProps: { ...data, message: successPageMessage, variant, messageSuccessUploaded },
      }),
    );

    const hasAddedCandidates = files.length - errorsCount > 0;

    if (hasAddedCandidates) {
      yield put(talentPoolCandidatesApi.util.invalidateTags([{ type: 'TalentPoolCandidates', id: 'LIST' }]));
    }
  }
}

export function* bulkCreationCandidateSaga(action: ReturnType<typeof bulkCreateCandidate>) {
  const { modalId, sagaChannel } = yield prepareExModalChannel();
  const page: ModalWizardPageNames<'bulkCreation'> = 'createCandidates';

  const talentPoolIdFromPayload = action.payload?.talentPoolId;

  const modalTalentPool: SagaReturnType<typeof talentPoolSelectors.getById> = yield select(
    talentPoolSelectors.getById,
    talentPoolIdFromPayload,
  );

  let selectedTalentPool = modalTalentPool;

  const isAddToTalentPoolAllowed = yield select(authSelectors.isFeatureAllowed, {
    feature: rbacConstants.candidates.addToTalentPool,
  });

  // Start modal wizard
  yield fork(invokeExModalWizard, {
    channel: sagaChannel,
    modalId,
    modalProps: {
      selectedTalentPool,
    },
    modalConfig: {
      wizardType: 'bulkCreation',
      page,
      content: {
        title: isAddToTalentPoolAllowed ? titleToTalentPool : title,
        withTitle: true,
      },
    },
  });

  while (sagaChannel) {
    const takeResult: {
      upload?: ReturnType<typeof bulkUploadResumes>;
      result?: ModalGeneralResult;
      addNewTalentPool?: ReturnType<typeof bulkCreateCandidateAnnNewTalentPool>;
      wizardForward?: ReturnType<typeof wizardForward>;
    } = yield race({
      upload: take(bulkUploadResumes),
      result: take(sagaChannel),
      addNewTalentPool: take(bulkCreateCandidateAnnNewTalentPool),
      wizardForward: take(wizardForward),
    });

    if (takeResult?.result?.cancel) {
      return;
    }

    const modalPage: ModalWizardPageNames<'bulkCreation'> = yield select(modalWizardPageById, modalId);

    switch (modalPage) {
      case 'createCandidates': {
        if (Boolean(takeResult?.addNewTalentPool)) {
          selectedTalentPool = takeResult.addNewTalentPool?.payload?.selectedTalentPool ?? selectedTalentPool;
          yield put(
            updateWizardPage({
              id: modalId,
              page: 'createNewTalentPool',
            }),
          );
          continue;
        }

        if (Boolean(takeResult?.upload)) {
          const files = takeResult?.upload?.payload?.files ?? [];
          selectedTalentPool = takeResult?.upload?.payload?.selectedTalentPool ?? selectedTalentPool;
          const talentPoolId = selectedTalentPool?.id;
          const { channel, onUploadProgress, formData } = prepareFormData(files);

          yield fork(uploadResumes as any, channel, modalId);
          yield call(createCandidatesModal, { formData, talentPoolId, onUploadProgress, files, modalId });
          break;
        }
        break;
      }
      case 'createNewTalentPool': {
        yield call(createNewTalentPoolModal, { modalId, selectedTalentPool });
        continue;
      }

      default:
        break;
    }
  }
}
