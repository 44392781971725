import * as yup from 'yup';
import { isPast, isToday, parse } from 'date-fns';

import { JobType } from 'model/api-enums.constants';

import {
  jobNameValidator,
  validationSchemaFormPositionDescription,
  validationSchemaFormPositionDescriptionNotRequired,
  validationSchemaFormSalary,
  validationSchemaFormSalaryNotRequired,
  validationSchemaJobPosition,
} from 'containers/JobForms/validators/index';
import { referenceNoValidator } from 'containers/JobForms/validators/referenceNoValidator';

import { emptyStringOrZeroToUndefined, emptyStringToUndefined, stringNullable, stringRequired } from 'utils';

const isDateInPast = (value: string) => {
  const date = parse(value, 'yyyy-MM-dd', new Date());
  return !isPast(date) || isToday(date);
};

export const validationSchemaDetails: yup.AnyObjectSchema = yup.object({
  id: yup.string().notRequired(),
  jobId: yup.string().notRequired(),
  jobName: jobNameValidator,
  referenceNo: referenceNoValidator,
  employmentType: yup.number().label('Employment type').required().transform(emptyStringToUndefined),
  countryHiringFrom: yup.string().required().label('Country').nullable(true),
  position: validationSchemaJobPosition.label('Position').typeError('Position is required'),
  jobLocation: yup.object().label('Location').nullable(true).required(),
  vacancies: yup
    .number()
    .typeError('Only ${type} are accepted') // eslint-disable-line no-template-curly-in-string
    .integer()
    .label('Number of Vacancies')
    .default(1)
    .min(1)
    .max(999)
    .transform((v, o) => (o === '' ? 1 : v)),
  pipelineId: stringRequired.label('Hiring pipeline').transform(emptyStringToUndefined),
  deadline: yup
    .string()
    .label('End Date')
    .when('id', {
      is: (v) => !v,
      then: yup
        .string()
        .transform(emptyStringOrZeroToUndefined)
        .test(
          'minDate',
          ({ label }) => `${label} cannot be in the past`,
          (ov) => isDateInPast(ov ?? ''),
        )
        .transform(emptyStringOrZeroToUndefined)
        .nullable(true)
        .default(null),
      otherwise: stringNullable,
    }),
  jobType: yup
    .mixed()
    .oneOf(Object.values(JobType), 'Please select a value from the options.')
    .label('Job Type')
    .required(),
  interviewTemplates: yup.mixed().label('Interview Template'),
});

export const validationSchemaDetailsNotRequired: yup.AnyObjectSchema = yup.object({
  id: yup.string().notRequired(),
  jobId: yup.string().notRequired(),
  jobName: jobNameValidator,
  referenceNo: referenceNoValidator,
  employmentType: yup.number().label('Employment type').transform(emptyStringToUndefined),
  countryHiringFrom: yup.string().label('Country').nullable(true),
  position: validationSchemaJobPosition.label('Position').typeError('Position is required'),
  jobLocation: yup.object().label('Location').nullable(true),
  vacancies: yup
    .number()
    .typeError('Only ${type} are accepted') // eslint-disable-line no-template-curly-in-string
    .integer()
    .label('Number of Vacancies')
    .default(1)
    .min(1)
    .max(999)
    .transform((v, o) => (o === '' ? 1 : v)),
  pipelineId: yup.string().label('Hiring pipeline').transform(emptyStringToUndefined),
  deadline: yup
    .string()
    .label('End Date')
    .when('id', {
      is: (v) => !v,
      then: yup
        .string()
        .transform(emptyStringOrZeroToUndefined)
        .test(
          'minDate',
          ({ label }) => `${label} cannot be in the past`,
          (ov) => isDateInPast(ov ?? ''),
        )
        .transform(emptyStringOrZeroToUndefined)
        .nullable(true)
        .default(null),
      otherwise: stringNullable,
    }),
  jobType: yup.mixed().oneOf(Object.values(JobType), 'Please select a value from the options.').label('Job Type'),
  interviewTemplates: yup.mixed().label('Interview Template'),
});

export const createJobValidationSchema = validationSchemaDetails
  .concat(validationSchemaFormSalary)
  .concat(validationSchemaFormPositionDescription);

export const createJobValidationSchemaNotRequired = validationSchemaDetailsNotRequired
  .concat(validationSchemaFormSalaryNotRequired)
  .concat(validationSchemaFormPositionDescriptionNotRequired);

export const createJobValidationSchemaLazy = yup.lazy((_, options) => {
  const isDraft = options?.context?.getIsDraft?.();

  return isDraft ? createJobValidationSchemaNotRequired : createJobValidationSchema;
});
