import CloseButton from 'react-bootstrap/CloseButton';
import styled, { css } from 'styled-components/macro';

const CloseButtonStyled = styled(CloseButton)`
  display: none;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
`;

type CheckableRowStyledProps = { isHoverable?: boolean };

const checkableRowStyledHoverable = ({ isHoverable }: CheckableRowStyledProps) =>
  isHoverable &&
  css`
    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.02);

      ${CloseButtonStyled} {
        display: block;
      }
    }
  `;

const CheckableRowStyled = styled.div<CheckableRowStyledProps>`
  display: flex;
  align-items: center;
  position: relative;
  transition: background-color 0.2s ease-out;
  border-radius: 8px;
  ${checkableRowStyledHoverable};
`;

const Description = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export { CheckableRowStyled, CloseButtonStyled, Description };
