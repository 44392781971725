import { GlobalNavQueryParam } from '@expedo/types';

import { UserCompany } from 'api-endpoints/user/user.model';

const cleanUpSessionQueryParams = () => {
  sessionStorage.removeItem(GlobalNavQueryParam.Persona);
  sessionStorage.removeItem(GlobalNavQueryParam.Company);
  sessionStorage.removeItem(GlobalNavQueryParam.Referrer);
};

const getGlobalNavQueryParams = () => {
  const personaId = sessionStorage.getItem(GlobalNavQueryParam.Persona);
  const companyId = sessionStorage.getItem(GlobalNavQueryParam.Company);
  const referrer = sessionStorage.getItem(GlobalNavQueryParam.Referrer);

  return { personaId, companyId, referrer };
};

export const getCompanyFromQueryParams = (companyList: UserCompany[]) => {
  const { companyId, personaId } = getGlobalNavQueryParams();

  const company = companyList.find((c: UserCompany) =>
    companyId
      ? c.directoryServiceCompanyId === Number(companyId)
      : personaId && c.directoryServicePersonaIds?.includes(personaId),
  );

  cleanUpSessionQueryParams();
  return company || null;
};
