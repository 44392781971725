import { createAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { Routes } from 'router';
import { put } from 'redux-saga/effects';

import {
  getJobApplicationActivityAnalytic,
  getJobCandidateSourceAnalytics,
  JobListItemResponse,
  putJob,
  updateJobStatus,
} from 'api-endpoints/job';

import { ApiResponseWithPagination } from 'model';
import { Unwrap } from 'model/utils';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { jobFormActions } from 'containers/JobForms/state';
import { jobListActions } from 'containers/JobLists/store';
import { mapJobs } from 'containers/JobLists/store/mappers';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { loaderManagerActions } from 'modules/LoaderManager/redux';
import { createLoader, createRequestTypes } from 'utils/actions';
import { getTranslate } from 'utils/i18utils';

import { enhancedJobAdsApi, JOB_AD_TAG_TYPE } from 'store/entities/job-ads/job-ads.api';
import {
  BulkDownloadFilesFuncParams,
  JobChangeStatusRequestParams,
  JobGetRequestParams,
  JobListRequestParams,
  JobPutRequestParams,
} from 'store/entities/jobs/api/requests';
import {
  JOB_ACTIVITY_ANALYTIC_FETCH,
  JOB_AVERAGE_TIME_TO_HIRE_FETCH,
  JOB_CANDIDATE_SOURCE_FETCH,
  JOB_CREATE,
  JOB_DAYS_OPEN_FETCH,
  JOB_DEADLINE_FETCH,
  JOB_DESCRIPTION_EDIT,
  JOB_DETAILS_EDIT,
  JOB_FETCH,
  JOB_GET,
  JOB_GET_APPLICANTS_PER_STAGE_FOR_JOB_BOARD,
  JOB_REQUIREMENTS_EDIT,
  JOB_RESPONSIBILITIES_EDIT,
  JOB_SALARY_EDIT,
  JOB_SOURCE_PER_DAY_FETCH,
  JOB_STATUS_UPDATE,
  JOB_TEAM_ADD_MEMBER,
  JOB_TEAM_EDIT,
  JOB_TEAM_FETCH,
  JOB_TEAM_MEMBER_CREATE,
  JOB_TEAM_MEMBER_PREPARE_CREATE,
  JOB_TEAM_MEMBER_PREPARE_REMOVE,
  JOB_TEAM_MEMBER_REMOVE,
  JOB_TEAM_REMOVE_MEMBER,
  JOB_UPDATE,
} from 'store/entities/jobs/job.constants';
import { createActionApiCallBatch } from 'store/entities/qualification-type';
import { AppEffectParams } from 'store/types';

import { jobsActions } from './index';
import { enhancedJobApi } from './jobs.api';
import { Job, JobTeamMember } from './models';

//--------------------------------------------------------------------------------------------------
// LOAD JOB
const jobRequestTypes = createRequestTypes(JOB_GET);
export const loadJobPage = createAction<{ jobId?: Job['id'] }>(jobRequestTypes.LOAD);
export const loadJobPageCancel = createAction(jobRequestTypes.CANCEL);

//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// LOAD JOBS
const jobsRequestTypes = createRequestTypes(JOB_FETCH);
export const loadJobsPage = createAction<
  AppEffectParams<{
    listId?: string;
  }>
>(jobsRequestTypes.LOAD);
export const loadJobSPageCancel = createAction(jobsRequestTypes.CANCEL);
export const jobEntities = createLoader(
  {
    success: (data: ApiResponseWithPagination<JobListItemResponse>, params: JobListRequestParams) => {
      const { jobIds, jobs } = mapJobs(data.items);

      return [
        jobsActions.upsert({ items: jobs }),
        jobListActions.fetchSuccess({
          id: params.params.id,
          items: jobIds,
          pageCount: data.pageCount,
          totalItemsCount: data?.totalItemsCount,
        }),
      ];
    },
    finally: (params) => {
      const { preloader, payload } = params;
      const preloaderString = preloader ?? payload?.preloader;
      const finallyAction = createAction<{ preloader: string | boolean }>(jobsRequestTypes.FINALLY);
      return [
        loaderManagerActions.stop(loadJobsPage.type),
        finallyAction({
          preloader: typeof preloaderString === 'string' ? preloaderString : true,
        }),
      ];
    },
  },
  jobsRequestTypes,
);
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// CHANGE JOB STATUS
export const jobUpdateStatusTypes = createRequestTypes(JOB_STATUS_UPDATE);
export const updateJobStatusWithModalAction = createAction<{
  jobId: Job['id'];
  status: string;
  listId?: string;
  jobStatus: Job['status'];
}>(JOB_STATUS_UPDATE);
export const updateJobStatusAction = createAction<JobChangeStatusRequestParams & { listId?: string }>(
  jobUpdateStatusTypes.REQUEST,
);
export const postJobStatusCancel = createAction(jobUpdateStatusTypes.CANCEL);
export const updateJobStatusResultHandlers = createLoader(
  {
    success: (data: Unwrap<ReturnType<typeof updateJobStatus>>, params: ReturnType<typeof updateJobStatusAction>) => [
      jobsActions.updateOne(data),
      alertsEffects.showSuccess({ message: getTranslate('job_status_change_success') }),
      enhancedJobApi.util.invalidateTags([{ type: 'Jobs', id: 'LIST' }]),
      enhancedJobAdsApi.util.invalidateTags([{ type: JOB_AD_TAG_TYPE, id: 'LIST' }]),
      loadJobPage({ jobId: params.payload.urlParams.jobId }),
    ],
  },
  jobUpdateStatusTypes,
);
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// LOAD JOB ANALYTIC ACTIVITY
const jobActivityAnalyticsTypes = createRequestTypes(JOB_ACTIVITY_ANALYTIC_FETCH);
export const jobActivityAnalyticsRequest = createAction<JobGetRequestParams>(jobActivityAnalyticsTypes.LOAD);
export const jobActivityAnalyticsCancel = createAction(jobActivityAnalyticsTypes.CANCEL);
export const jobActivityAnalyticsHandler = createLoader(
  {
    success: (data: Unwrap<ReturnType<typeof getJobApplicationActivityAnalytic>>['data']) => {
      return jobsActions.updateOne(data);
    },
  },
  jobActivityAnalyticsTypes,
);
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// LOAD JOB ANALYTIC CANDIDATE SOURCE
const jobCandidateSourceTypes = createRequestTypes(JOB_CANDIDATE_SOURCE_FETCH);
export const jobCandidateSourceRequest = createAction<JobGetRequestParams>(jobCandidateSourceTypes.LOAD);
export const jobCandidateSourceCancel = createAction(jobCandidateSourceTypes.CANCEL);
export const jobCandidateSourceHandler = createLoader(
  {
    success: (data: Unwrap<ReturnType<typeof getJobCandidateSourceAnalytics>>['data'], params) => {
      const jobId = params.payload.urlParams.jobId;
      return jobsActions.updateOne({ id: jobId, ...data });
    },
  },
  jobCandidateSourceTypes,
);
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// CREATE JOB
const jobCreateTypes = createRequestTypes(JOB_CREATE);
export const jobCreateRequest = createAction<{
  asOpen?: boolean;
  isAutosave?: boolean;
  isOpenEdit?: boolean;
}>(jobCreateTypes.LOAD);

//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// UPDATE JOB
const jobUpdateTypes = createRequestTypes(JOB_UPDATE);
export const jobUpdateRequest = createAction<JobPutRequestParams & { goToFinalPage?: boolean }>(jobUpdateTypes.LOAD);
export const jobUpdateCancel = createAction(jobUpdateTypes.CANCEL);
export const jobUpdateHandler = createLoader(
  {
    success: (data: Unwrap<ReturnType<typeof putJob>>['data'], params: { goToFinalPage?: boolean }) => {
      const actions: any[] = [
        put(jobsActions.updateOne({ id: data.jobId, ...data })),
        put(loadJobPage({ jobId: data.jobId })),
        put(jobFormActions.toggleIsDraftSavedSteps({ isDraftSaved: true })),
        put(alertsEffects.showSuccess({ message: 'Job successfully saved' })),
      ];
      if (params.goToFinalPage) {
        actions.push(push({ pathname: Routes.jobFormStepFinal }));
      }
      return actions;
    },
    finally: () => loaderManagerActions.stop(jobUpdateRequest.type),
  },
  jobUpdateTypes,
);
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// createActionApiCallBatch
export const getApplicantsPerStageForJobBoardAction = createActionApiCallBatch<Pick<Job, 'jobId'>>(
  JOB_GET_APPLICANTS_PER_STAGE_FOR_JOB_BOARD,
);
//--------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// GetApplicantsRecievedPerDayForJobFunc
export const getApplicantsRecievedPerDayForJobFuncAction =
  createActionApiCallBatch<Pick<Job, 'jobId'>>(JOB_SOURCE_PER_DAY_FETCH);
//--------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------
// GetJobDeadlineFunc
export const getJobDeadlineFuncAction = createActionApiCallBatch<Pick<Job, 'jobId'>>(JOB_DEADLINE_FETCH);
// -------------------------------------------------------------------------------------------------

//GetJobDaysOpenFunc
export const getJobDaysOpenFuncAction = createActionApiCallBatch<Pick<Job, 'jobId'>>(JOB_DAYS_OPEN_FETCH);
// -------------------------------------------------------------------------------------------------

//GetJobAcceptanceRateFunc
export const getJobAcceptanceRateFuncAction = createActionApiCallBatch<Pick<Job, 'jobId'>>(JOB_DAYS_OPEN_FETCH);
// -------------------------------------------------------------------------------------------------

//GetJobAverageTimeToHireFunc
export const getJobAverageTimeToHireFuncAction =
  createActionApiCallBatch<Pick<Job, 'jobId'>>(JOB_AVERAGE_TIME_TO_HIRE_FETCH);
// -------------------------------------------------------------------------------------------------

// Job Salary Edit
export const jobSalaryEdit = createAction(JOB_SALARY_EDIT);
// -------------------------------------------------------------------------------------------------

// Job Details Edit
export const jobDetailsEdit = createAction(JOB_DETAILS_EDIT, () => ({ payload: ModalsTypeKey.editJobDetails }));
// -------------------------------------------------------------------------------------------------

// Job Description Edit
export const jobDescriptionEdit = createAction(JOB_DESCRIPTION_EDIT, () => ({
  payload: ModalsTypeKey.editJobDescription,
}));
// -------------------------------------------------------------------------------------------------

// Job Responsibilities Edit
export const jobResponsibilitiesEdit = createAction(JOB_RESPONSIBILITIES_EDIT, () => ({
  payload: ModalsTypeKey.editJobResponsibilities,
}));
// -------------------------------------------------------------------------------------------------

// Job Requirements Edit
export const jobRequirementsEdit = createAction(JOB_REQUIREMENTS_EDIT, () => ({
  payload: ModalsTypeKey.editJobRequirements,
}));
// -------------------------------------------------------------------------------------------------

// Job Create
export const jobCreate = createAction(JOB_CREATE);
// -------------------------------------------------------------------------------------------------

// Job Team Fetch
export const jobTeamFetch = createAction<JobGetRequestParams>(JOB_TEAM_FETCH);
// -------------------------------------------------------------------------------------------------

// Job Team Add Member
export const jobTeamAddMemberModal = createAction(JOB_TEAM_ADD_MEMBER);
// -------------------------------------------------------------------------------------------------

// Job Team Remove Member
export const jobTeamRemoveMemberModal = createAction<{ userId: string }>(JOB_TEAM_REMOVE_MEMBER);
// -------------------------------------------------------------------------------------------------

// Job Team Edit
export const jobTeamEdit = createAction<{ jobId: Job['id'] }>(JOB_TEAM_EDIT);
// -------------------------------------------------------------------------------------------------

type JobTeamMemberActionType = { jobId: string; userId: string };
type JobTeamMemberPrepareCreateActionType = { jobId: string; teamMembers: JobTeamMember[] };

// Job Team Member Prepare Create
export const jobTeamMemberPrepareCreate =
  createAction<JobTeamMemberPrepareCreateActionType>(JOB_TEAM_MEMBER_PREPARE_CREATE);
// -------------------------------------------------------------------------------------------------
// Job Team Member Create
export const jobTeamMemberCreate = createAction<JobTeamMemberActionType>(JOB_TEAM_MEMBER_CREATE);
// -------------------------------------------------------------------------------------------------
// Job Team Member Prepare Remove
export const jobTeamMemberPrepareRemove = createAction<JobTeamMemberActionType>(JOB_TEAM_MEMBER_PREPARE_REMOVE);
// -------------------------------------------------------------------------------------------------
// Job Team Member Remove
export const jobTeamMemberRemove = createAction<JobTeamMemberActionType>(JOB_TEAM_MEMBER_REMOVE);
// -------------------------------------------------------------------------------------------------
// Job Team Member Processing Finished
export const jobTeamMemberProcessingFinished = createAction('job/team/member/processingFinished');
// -------------------------------------------------------------------------------------------------
// BulkDownloadFilesFunc
export const jobBulkDownloadFilesAction = createAction<BulkDownloadFilesFuncParams>('job/bulk/download/resumes');
