import React from 'react';
import styled from 'styled-components';

import { Ability } from 'model';

import { JobAdAdditionalDetailsRowProps } from 'components/DetailsCard';
import { Ellipsis } from 'components/Ellipsis';
import { CheckableList } from 'components/Form/CheckableList';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

const JobAdDetailsTitle = styled.div`
  font-size: ${getThemeFontSize('large')};
  font-weight: bold;
`;

const JobAdDetailsCard = styled.div`
  position: relative;
`;

const JobAdDetailsHeaderWrapper = styled.div<{ title?: string }>`
  ${(props) => (!props.title ? 'float: right;' : undefined)};
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const JobAdDetailsEditText = styled.div`
  margin-left: auto;
`;

const JobAdDetailsRow = styled.div`
  margin-bottom: 1.5rem;
`;

const JobAdDetailsLabel = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;
const HtmlRowStyled = styled.div`
  word-break: break-word;
`;

const HtmlRow: React.FC<{ value: JobAdAdditionalDetailsRowProps['value'] }> = ({ value }) => {
  return <HtmlRowStyled dangerouslySetInnerHTML={{ __html: value?.toString() || '<small>--</small>' }} />;
};

const TextRow: React.FC<{ value: JobAdAdditionalDetailsRowProps['value'] }> = ({ value }) => {
  return <Ellipsis title={value?.toString()}>{value || <small>--</small>}</Ellipsis>;
};

const ListRow: React.FC<{ value: JobAdAdditionalDetailsRowProps['value'] }> = ({ value }) => {
  return (
    <CheckableList
      disabled
      items={(value as Ability[]) || []}
      onChange={() => {
        // do nothing
      }}
    />
  );
};

type RowTypes = Record<'html' | 'list' | 'default', React.FC<{ value: JobAdAdditionalDetailsRowProps['value'] }>>;

const rowMap: RowTypes = {
  html: HtmlRow,
  list: ListRow,
  default: TextRow,
};

function getRowKey(row: JobAdAdditionalDetailsRowProps): keyof typeof rowMap {
  switch (true) {
    case row.html:
      return 'html';
    case row.list:
      return 'list';
    default:
      return 'default';
  }
}

const RowComponent: React.FC<{ row: JobAdAdditionalDetailsRowProps }> = ({ row }) => {
  const rowKey = getRowKey(row);
  const Component = rowMap[rowKey];

  return <Component value={row.value} />;
};

export {
  JobAdDetailsCard,
  JobAdDetailsTitle,
  JobAdDetailsHeaderWrapper,
  JobAdDetailsEditText,
  JobAdDetailsRow,
  JobAdDetailsLabel,
  RowComponent,
};
