import { useMemo } from 'react';

import { walkDOM } from 'utils';

import { defaultTextEditorOptions } from './config';
import { TextEditorInnerOptions, TextEditorOuterOptions } from './TextEditorProps';

/**
 * Create TinyMCE toobar string from array of toolbar options.
 *
 * @param {TextEditorOuterOptions} [options]
 * @returns {Partial<TextEditorInnerOptions>}
 */
function prepareToolbarOptions(options?: TextEditorOuterOptions): Partial<TextEditorInnerOptions> {
  if (!options) {
    return {};
  }

  if (options?.toolbar) {
    return {
      ...options,
      toolbar: options.toolbar.join(' '),
    };
  }

  const { toolbar, ...preparedOptions } = options;

  return preparedOptions;
}

/**
 * Generate React-TinyMCE Editor init options object;
 *
 * @export
 * @param {TextEditorOuterOptions} [options]
 * @returns {TextEditorInnerOptions}
 */
export function useTextEditorOptions(options?: TextEditorOuterOptions): TextEditorInnerOptions | {} {
  const preparedOptions = useMemo(() => prepareToolbarOptions(options), [options]);

  const editorOptions = useMemo(() => {
    return {
      ...defaultTextEditorOptions,
      ...preparedOptions,
    };
  }, [preparedOptions]);

  return editorOptions as TextEditorInnerOptions;
}

export function replaceMergeFieldsWithValues(stringHtmlContent?: string | null): string | undefined {
  if (!stringHtmlContent) {
    return '';
  }

  /**
   * For rendering on BE using scriban https://github.com/lunet-io/scriban
   * next function converts a variable as a span to a string variable in curved brackets {{variable}}
   *
   */

  const div = document.createElement('div');
  div.innerHTML = stringHtmlContent;

  walkDOM(div, (n: HTMLElement) => {
    if (n.nodeType === 1) {
      const original = n.getAttribute('data-original-variable');
      const parent = n.parentNode!;
      if (original && parent) {
        const newChild = document.createTextNode(original);
        parent.replaceChild(newChild, n);
      }
    }
  });

  return div.innerHTML;
}

export function replaceValuesWithMergeFields(
  stringHtmlContent: string | null | undefined,
  mergeFields: string[],
): string | undefined {
  if (!stringHtmlContent || !mergeFields?.length) {
    return '';
  }

  /**
   * Transform a String Variable received from an API to a HTML variable
   */

  const regString = '{{(.*?)}}';
  const regExp = new RegExp(regString, 'gm');

  return stringHtmlContent.replace(regExp, (...args) => {
    const variable = args[0];
    const cleanMappedValue = args[1];
    if (mergeFields.includes(cleanMappedValue)) {
      return `<span class="variable" contenteditable="false" data-original-variable="${variable}">${cleanMappedValue}</span>`;
    }

    return variable;
  });
}

export function checkHasMergeField(stringHtmlContent: string | null | undefined, mergeField: string): boolean {
  if (!stringHtmlContent || !mergeField) {
    return false;
  }

  return stringHtmlContent.includes(`{{${mergeField}}}`);
}
