import React from 'react';
import * as yup from 'yup';
import styled from 'styled-components';

import { ExLoaderHandlerWrapper, ExLoaderHandlerWrapperStrict } from 'components/Common/LoaderHandlerWrapper';
import { ExButton } from 'components/ui/ExButton';
import { ExDialogActions, ExDialogFooter } from 'components/ui/ExDialog';
import { safariFlexBoxTweak } from 'utils/styled/common';

import { ModalStepProps } from 'store/modals/config';

const MessageStyled = styled.div`
  white-space: pre-wrap;
  text-align: center;
`;

export const ModalBodyStyled = styled.div`
  width: 100vw;
  max-width: 600px;
  height: 100%;
  max-height: 80vh;
  min-height: 100px;
  position: relative;
  ${safariFlexBoxTweak};

  @media (max-width: 769.98px) {
    max-height: 100vh;
  }
`;

export const backupIdValidationSchema: yup.SchemaOf<{ backupId: string }> = yup.object().shape({
  backupId: yup.string().required('This field is a required'),
});

export const RemoveConfirmModal: React.FC<ModalStepProps> = ({
  nextButtonHandler,
  message,
  onCloseDialogHandler,
  modalProps,
}) => {
  const action = modalProps && modalProps.action ? { type: modalProps.action } : undefined;
  return (
    <ModalBodyStyled>
      <MessageStyled>{message}</MessageStyled>
      <ExDialogFooter>
        <ExDialogActions>
          <ExButton onClick={() => onCloseDialogHandler()} variant="light">
            Cancel
          </ExButton>
          <ExLoaderHandlerWrapperStrict>
            <ExLoaderHandlerWrapper action={action}>
              <ExButton className="ml-3" onClick={nextButtonHandler}>
                Confirm
              </ExButton>
            </ExLoaderHandlerWrapper>
          </ExLoaderHandlerWrapperStrict>
        </ExDialogActions>
      </ExDialogFooter>
    </ModalBodyStyled>
  );
};
