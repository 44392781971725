import React, { useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import { Control, useController, UseControllerOptions } from 'react-hook-form';
import styled from 'styled-components/macro';

import { FormChildrenInputProps } from 'components/Form/FormProps';
import { FormCard } from 'components/FormCard';
import { FormRadioSwitch, FormRadioSwitchContainer } from 'components/FormRadio/FormRadioSwitch';
import { FormTooltip } from 'components/FormTooltip';

interface FormToggleButtonsProps extends FormChildrenInputProps {
  className?: string;
  label?: string;
  required?: boolean;
  hint?: string;
  rules?: UseControllerOptions['rules'];
  onFocus?: UseControllerOptions['onFocus'];
  defaultValue?: unknown;
  noOverlay?: boolean;
  options: Array<{
    value: string | number | boolean;
    label: string;
    slotAfterLabel?: any;
    labelClassName?: string;
  }>;
  control?: Control;
  disabled?: boolean;
  tooltipOffset?: [number, number];
}

const FormToggleButtonsStyled = styled(Form.Group)``;

const useFormToggleButtonsState = ({
  className,
  name,
  label,
  required,
  hint,
  onFocus,
  rules,
  options,
  errors,
  defaultValue,
  control,
  disabled,
  tooltipOffset,
}: FormToggleButtonsProps) => {
  const {
    field: { onChange: onChangeController, ref, value },
    meta: { invalid },
  } = useController({ control, name, defaultValue, onFocus, rules });

  const onChange =
    (val: string | number | boolean): React.ChangeEventHandler<HTMLInputElement> =>
    () => {
      onChangeController(val);
    };

  const isOptionChecked = useCallback((v, ov) => {
    return v === ov;
  }, []);

  return {
    className,
    name,
    label,
    required,
    hint,
    ref,
    options,
    errors,
    onChange,
    value,
    isOptionChecked,
    invalid,
    disabled,
    tooltipOffset,
  } as const;
};

export const FormToggleButtons: React.FC<FormToggleButtonsProps> = (props) => {
  const {
    className,
    name,
    label,
    required,
    hint,
    ref,
    options,
    errors,
    onChange,
    value,
    isOptionChecked,
    invalid,
    disabled,
    tooltipOffset,
  } = useFormToggleButtonsState(props);

  return (
    <FormToggleButtonsStyled className={className} ref={ref}>
      {label && (
        <FormCard.InputLabel htmlFor={name}>
          {label} {required && <FormCard.InputLabelRequired>*</FormCard.InputLabelRequired>}{' '}
          {<FormCard.InputLabelHint>{hint}</FormCard.InputLabelHint>}
        </FormCard.InputLabel>
      )}
      <div>
        <FormRadioSwitchContainer>
          {options.map((option, index) => (
            <FormRadioSwitch
              key={index}
              id={option.label}
              checked={isOptionChecked(value, option.value)}
              name={name}
              label={option.label}
              value={option.value.toString()}
              onChange={onChange(option.value)}
              slotAfterLabel={option.slotAfterLabel}
              disabled={disabled}
              labelClassName={option.labelClassName}
            />
          ))}
        </FormRadioSwitchContainer>
      </div>

      <FormTooltip hideOverlay={props.noOverlay} target={ref} show={invalid} errors={errors} offset={tooltipOffset} />
    </FormToggleButtonsStyled>
  );
};
