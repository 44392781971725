import { createLookupReducer, InitialState } from 'utils/reducer/reducer-helper';

import { EntityKeys } from 'store/constants';
import { JobAd } from 'store/entities/job-ads/models';

export default createLookupReducer({
  name: EntityKeys.jobAds,
  initialState: {
    allIds: [],
    byId: {},
  } as InitialState<JobAd>,
  reducers: {},
});
