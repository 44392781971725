import * as yup from 'yup';

import { validationSchemaNewApplicantFiveStep } from 'containers/ApplicantForm/ApplicantFormValidators';
import {
  baseCandidateValidationSchema,
  educationSchema,
  workExperienceSchema,
} from 'containers/CandidateForm/CandidateFormValidators';

import { stringOptional } from 'utils/validator-helpers';

//yup.SchemaOf<ApplicantModalFormFormData>
export const applicantModalSchema = (candidateRequired: boolean) => {
  const schema = yup
    .object({
      applicantId: stringOptional,
      jobId: stringOptional,
      candidateId: stringOptional,
      educations: yup.array(educationSchema),
      workExperiences: yup.array(workExperienceSchema),
    })
    .concat(validationSchemaNewApplicantFiveStep);
  return candidateRequired ? schema.concat(baseCandidateValidationSchema) : schema;
};
