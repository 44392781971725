import isEqual from 'lodash/isEqual';
import { all, fork, join, put, SagaReturnType, select } from 'redux-saga/effects';

import * as fromApiEndpointsApplicant from 'api-endpoints/applicant';

import { ExEducation } from 'model';

import { applicantModalSliceSelectors } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/store/ApplicantModal.selectors';

import { pluck } from 'utils/pluck';
import { invokeApiCall } from 'utils/sagas';

import { candidateEducationsSlice } from 'store/entities/candidate-education/candidate-education.reducer';
import { workExperienceDateRequestMapper } from 'store/entities/candidate-work-experience/api/request.mappers';
import type { CandidateWorkExperience } from 'store/entities/candidate-work-experience/candidate-work-experience.models';
import { candidateWorkExperienceSlice } from 'store/entities/candidate-work-experience/candidate-work-experience.reducer';

function difference(setA: Set<string>, setB: Set<string>) {
  const _difference = new Set(setA);
  for (const elem of setB) {
    _difference.delete(elem);
  }
  return _difference;
}

export function* educationsUpdate(jobId: string, applicantId: string, formEducations: ExEducation[]) {
  const [initEducationIds, initEducations]: [
    SagaReturnType<typeof applicantModalSliceSelectors.selectEducationIdsForCurrentApplicant>,
    SagaReturnType<typeof applicantModalSliceSelectors.selectEducationsForCurrentApplicant>,
  ] = yield all([
    select(applicantModalSliceSelectors.selectEducationIdsForCurrentApplicant),
    select(applicantModalSliceSelectors.selectEducationsForCurrentApplicant),
  ]);

  const newEducations = formEducations.filter((education) => !education.educationId);
  const updateEducations = formEducations
    .filter((education) => !!education.educationId)
    .filter((updateEducation) => {
      const original = initEducations.find((e) => updateEducation.educationId === e.educationId);
      return !isEqual(original, updateEducation);
    });
  const setEducationA = new Set<string>(initEducationIds);
  const setEducationB = new Set<string>(
    formEducations.filter((education) => !!education.educationId).map(pluck('educationId')),
  );
  const removeEducations = Array.from<string>(difference(setEducationA, setEducationB));

  const newEducationsTask = yield all(
    newEducations.map((education) =>
      fork(invokeApiCall, fromApiEndpointsApplicant.addJobApplicantEducationRoute, {
        urlParams: { jobId, applicantId },
        data: education,
      }),
    ),
  );
  const updateEducationsTask = yield all(
    updateEducations.map((education) =>
      fork(invokeApiCall, fromApiEndpointsApplicant.updateJobApplicantEducationFunc, {
        urlParams: { jobId, applicantId, educationId: education.educationId },
        data: education,
      }),
    ),
  );
  const removeEducationsTask = yield all(
    removeEducations.map((educationId) =>
      fork(invokeApiCall, fromApiEndpointsApplicant.deleteJobApplicantEducationFunc, {
        urlParams: { jobId, applicantId, educationId },
      }),
    ),
  );
  const results = yield join([...newEducationsTask, ...updateEducationsTask, ...removeEducationsTask]);
  if (!results[2]?.errorData && !results[2]?.message) {
    yield put(candidateEducationsSlice.actions.removeMany(removeEducations));
  }
  return results;
}

export function* workExperiencesUpdate(
  jobId: string,
  applicantId: string,
  formworkExperiences: Partial<CandidateWorkExperience>[],
) {
  const [initWorkExperienceIds, initWorkExperiences]: [
    SagaReturnType<typeof applicantModalSliceSelectors.selectWorkExperienceIdsForCurrentApplicant>,
    SagaReturnType<typeof applicantModalSliceSelectors.selectWorkExperiencesForCurrentApplicant>,
  ] = yield all([
    select(applicantModalSliceSelectors.selectWorkExperienceIdsForCurrentApplicant),
    select(applicantModalSliceSelectors.selectWorkExperiencesForCurrentApplicant),
  ]);

  const newWorkExperience = formworkExperiences.filter((education) => !education.workExperienceId);
  const updateWorkExperiences: Partial<CandidateWorkExperience>[] = formworkExperiences
    .filter((education) => !!education.workExperienceId)
    .map(
      (workExperience) =>
        workExperienceDateRequestMapper({ data: workExperience } as any).data as Partial<CandidateWorkExperience>,
    )
    .filter((we) => {
      const original = initWorkExperiences.find((e) => we.workExperienceId === e.workExperienceId);
      return !isEqual(original, we);
    });
  const setWorkExperienceA = new Set<string>(initWorkExperienceIds);
  const setWorkExperienceB = new Set(
    formworkExperiences.filter((education) => !!education.workExperienceId).map(pluck('workExperienceId')),
  );
  const removeWorkExperiences = Array.from(difference(setWorkExperienceA, setWorkExperienceB as Set<string>));

  const newWorkExperienceTask = yield all(
    newWorkExperience.map((workExperience) =>
      fork(invokeApiCall, fromApiEndpointsApplicant.addJobApplicantWorkExperienceFunc, {
        urlParams: { jobId, applicantId },
        data: workExperienceDateRequestMapper({ data: workExperience } as any).data,
      }),
    ),
  );
  const updateWorkExperienceTask = yield all(
    updateWorkExperiences.map((workExperience) =>
      fork(invokeApiCall, fromApiEndpointsApplicant.updateJobApplicantWorkExperienceFunc, {
        urlParams: {
          jobId,
          applicantId,
          workExperienceId: workExperience.workExperienceId ?? workExperience.workExperienceId,
        },
        data: {
          ...workExperience,
          jobApplicantWorkExperienceId: workExperience.workExperienceId ?? workExperience.workExperienceId,
        },
      }),
    ),
  );
  const removeWorkExperienceTask = yield all(
    removeWorkExperiences.map((workExperienceId) =>
      fork(invokeApiCall, fromApiEndpointsApplicant.deleteJobApplicantWorkExperienceFunc, {
        urlParams: {
          jobId,
          applicantId,
          workExperienceId,
        },
      }),
    ),
  );
  const results = yield join([...newWorkExperienceTask, ...updateWorkExperienceTask, ...removeWorkExperienceTask]);
  if (!results[2]?.errorData && !results[2]?.message) {
    yield put(candidateWorkExperienceSlice.actions.removeMany(removeWorkExperiences));
  }
  return results;
}
