import { useCallback } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import styled from 'styled-components/macro';

import { ExInterview } from 'model';
import { InterviewState } from 'model/api-enums.constants';

import { CustomCheckbox } from 'components/Form/CustomCheckbox';
import { EnumLiteralsOf } from 'utils/funcs';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useModal } from 'utils/hooks/useModal';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { useInterviewQuery, useInterviewUpdateMutation } from 'store/entities/interview/interview.api';
import { tipsActions } from 'store/ui/tips/tips.reducer';
import { tipsSelectors } from 'store/ui/tips/tips.selectors';

const InterviewChangeModalContentStyled = styled.div`
  text-align: center;
`;

const CustomCheckboxStyled = styled(CustomCheckbox)`
  margin-top: 20px;
`;

type useInterviewChangeStatusParams = {
  interview?: ExInterview;
  interviewId?: ExInterview['interviewId'];
};

type InterviewChangeModalContentProps = {
  interview?: ExInterview;
};

const InterviewChangeModalContent: React.FC<InterviewChangeModalContentProps> = ({ interview }) => {
  const dispatch = useAppDispatch();

  const isInterviewEditPopupShow = useAppSelector(tipsSelectors.selectIsInterviewEditPopupShow);

  const interviewEditPopupShowChangeHandler = (value: boolean) => {
    dispatch(tipsActions.updateTips({ interviewEditPopup: !value }));
  };

  if (!interview) {
    return null;
  }

  return (
    <InterviewChangeModalContentStyled>
      <div>
        Status changes to draft.
        <br /> You confirm edit the interview {interview.name}?
      </div>
      <CustomCheckboxStyled checked={!isInterviewEditPopupShow} onChange={interviewEditPopupShowChangeHandler}>
        Do Not show popup
      </CustomCheckboxStyled>
    </InterviewChangeModalContentStyled>
  );
};

const getModalFields = ({ newState, interview }) => {
  const isPublish = newState === InterviewState.Saved;
  const isEdit = newState === InterviewState.Draft;
  const isRePublish = newState === InterviewState.Saved && interview?.wasPublished;

  const name = interview.name;

  switch (true) {
    case isRePublish:
      return {
        title: 'Confirm re-publish',
        message: `You confirm re-publish the interview "${name}"?`,
        buttonOk: 'Re-Publish',
      };
    case isPublish:
      return {
        title: 'Confirm publish',
        message: `You confirm publish the interview "${name}"?`,
        buttonOk: 'Publish',
      };
    case isEdit:
      return {
        title: 'Confirm edit',
        buttonOk: 'Edit',
        children: () => <InterviewChangeModalContent interview={interview} />,
      };
    default:
      return {
        title: 'Confirm',
        message: '',
        buttonOk: '',
      };
  }
};

export function useInterviewChangeStatus({ interview, interviewId }: useInterviewChangeStatusParams) {
  const { show } = useModal({});

  const { data } = useInterviewQuery(interviewId ?? skipToken, { skip: !!interview });

  const [updateInterview, updateMeta] = useInterviewUpdateMutation();
  const isInterviewEditPopupShow = useAppSelector(tipsSelectors.selectIsInterviewEditPopupShow);

  const changeInterviewStatusInterviewWithConfirmModal = useCallback(
    async (newState: EnumLiteralsOf<typeof InterviewState>) => {
      const interviewForUpdate = interview ?? data;

      if (!interviewForUpdate) {
        return;
      }

      if (!isInterviewEditPopupShow && newState === InterviewState.Draft) {
        await updateInterview({ ...interviewForUpdate, state: newState });
        return;
      }

      const modalFields = getModalFields({ newState, interview: interviewForUpdate });

      const result = await show({
        withTitle: true,
        buttonOkVariant: 'primary',
        withActions: true,
        ...modalFields,
      });

      if (result) {
        await updateInterview({ ...interviewForUpdate, state: newState });
      }

      return result;
    },
    [data, interview, isInterviewEditPopupShow, show, updateInterview],
  );

  return [changeInterviewStatusInterviewWithConfirmModal, updateMeta] as const;
}
