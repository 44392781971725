import React from 'react';
import styled from 'styled-components';

import { ApplicantDetailsFormBody } from 'containers/ApplicantForm/FormBody';

import { StepComponent, StepDescription } from 'components/StepForm';
import { ExCard } from 'components/ui/ExCard';
import { getTranslate } from 'utils/i18utils';

export interface NewApplicantSelectJobStepProps extends StepComponent {
  className?: string;
}

const NewApplicantDetailsStep: React.FC<NewApplicantSelectJobStepProps> = ({ className }) => {
  return (
    <div className={className}>
      <StepDescription>
        <StepDescription.Title>Step 5: Applicant Details</StepDescription.Title>
        <StepDescription.Description>{getTranslate('new_applicant.step_5.description')}</StepDescription.Description>
      </StepDescription>
      <ExCard title="Applicant Details">
        <ApplicantDetailsFormBody />
      </ExCard>
    </div>
  );
};

export default styled(NewApplicantDetailsStep)``;
