import { useEffect, useMemo, useState } from 'react';

export function useEntitiesStateWithToggle<T>(defaultValue: Array<T>, allEntities: Array<T>) {
  const [entities, setEntities] = useState(defaultValue);
  const [allSelected, setAllSelected] = useState(false);
  const toggleSelectAll = () => {
    const updatedValue = !allSelected;
    if (updatedValue) {
      setEntities(allEntities);
    } else {
      setEntities([]);
    }

    setAllSelected(updatedValue);
  };

  const entitiesLength = useMemo(() => (entities ? entities.length : 0), [entities]);
  const allEntitiesLength = useMemo(() => (allEntities ? allEntities.length : 0), [allEntities]);

  /**
   * Effect for recalculate is all entities are selected
   */
  useEffect(() => {
    if (entitiesLength === allEntitiesLength && !allSelected) {
      setAllSelected(true);
    }
  }, [entitiesLength, allEntitiesLength, allSelected]);

  return { entities, setEntities, toggleSelectAll, allSelected, setAllSelected } as const;
}
