import { QuestionType } from 'model/api-enums.constants';

import { QuestionCheckBox } from 'components/InterviewTemplate/QuestionCheckBox';
import { QuestionFileUpload } from 'components/InterviewTemplate/QuestionFileUpload';
import { QuestionSelectBox } from 'components/InterviewTemplate/QuestionSelectBox';
import { QuestionTextArea } from 'components/InterviewTemplate/QuestionTextArea';
import { QuestionTextField } from 'components/InterviewTemplate/QuestionTextField';
import { QuestionYesNo } from 'components/InterviewTemplate/QuestionYesNo';
import { EnumLiteralsOf } from 'utils/funcs';

export const useQuestionViewComponent = (type?: EnumLiteralsOf<typeof QuestionType>) => {
  if (type === undefined) {
    return null;
  }

  return {
    [QuestionType.SelectBoxList]: QuestionSelectBox,
    [QuestionType.TextField]: QuestionTextField,
    [QuestionType.YesNo]: QuestionYesNo,
    [QuestionType.CheckBoxList]: QuestionCheckBox,
    [QuestionType.TextArea]: QuestionTextArea,
    [QuestionType.FileUpload]: QuestionFileUpload,
  }[type];
};
