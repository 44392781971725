import { useMemo } from 'react';
import {
  differenceInCalendarDays,
  formatDistanceStrict,
  formatDistanceToNowStrict,
  isPast,
  parseISO,
  startOfToday,
} from 'date-fns';
import startCase from 'lodash/startCase';

import { ExBadgeVariant } from 'components/ui/ExBadge/ExBadgeProps';
import { useDateDefaultFormatFunction } from 'utils/hooks/dateTime';

export type BadgeTypes = 'jobAd' | 'default' | 'externalJob' | 'externalJobV2' | 'job';

const defaultBadgeVariants = {
  '<0': ExBadgeVariant.danger,
  '=0': ExBadgeVariant.warning,
  '<=5': ExBadgeVariant.warning,
  '<=25': ExBadgeVariant.default,
  default: ExBadgeVariant.default,
};

const jobAdBadgeVariants = {
  ...defaultBadgeVariants,
  '<=25': ExBadgeVariant.primary,
  default: ExBadgeVariant.primary,
};

const externalJobBadgeVariants = {
  ...defaultBadgeVariants,
  '=0': ExBadgeVariant.danger,
  '<=5': ExBadgeVariant.danger,
};

const externalJobV2BadgeVariants = {
  '<0': ExBadgeVariant.light,
  '=0': ExBadgeVariant.light,
  '<=5': ExBadgeVariant.light,
  '<=25': ExBadgeVariant.light,
  default: ExBadgeVariant.light,
};

const jobBadgeVariants = {
  ...defaultBadgeVariants,
  '=0': ExBadgeVariant.danger,
  '<=25': ExBadgeVariant.warning,
};

const badgeVariants = {
  jobAd: jobAdBadgeVariants,
  default: defaultBadgeVariants,
  externalJob: externalJobBadgeVariants,
  externalJobV2: externalJobV2BadgeVariants,
  job: jobBadgeVariants,
};

const getHashFromDifferenceInDays = (differenceInDays: number) => {
  switch (true) {
    case differenceInDays < 0:
      return '<0';
    case differenceInDays === 0:
      return '=0';
    case differenceInDays <= 5:
      return '<=5';
    case differenceInDays <= 25:
      return '<=25';
    default:
      return 'default';
  }
};

function useDeadlineDay(deadline?: string | null) {
  return useMemo(() => (deadline ? parseISO(deadline) : startOfToday()), [deadline]);
}

function useDifferenceInDays(deadline?: string | null) {
  const deadlineDay = useDeadlineDay(deadline);

  return useMemo(() => differenceInCalendarDays(deadlineDay, startOfToday()), [deadlineDay]);
}

export const TEXT_ONGOING = 'Ongoing';

export const CTX_JOB_DETAILS = 'jobDetails';
export const CTX_JOB_END_DATE = 'jobEndDate';
export const CTX_ACTIVE_JOB_WIDGET_BADGE = 'activeJobWidget';
export const CTX_REMAINING_BASIC_COMPONENT = 'RemainingBasicComponent';
export const CTX_JOB_AD_EXPIRE_BADGE = 'jobAdExpireBadge';

const deadlineDifferenceTextContext = {
  [CTX_JOB_DETAILS]: (deadline, differenceInDays, deadlineDay) => {
    if (!deadline) {
      return '';
    }

    const differenceSuffix = differenceInDays > 0 ? 'Remaining' : 'Expired';

    return `(${startCase(formatDistanceStrict(startOfToday(), deadlineDay, { unit: 'day' }))} ${differenceSuffix})`;
  },
  [CTX_JOB_END_DATE]: (deadline, differenceInDays, deadlineDay) => {
    if (!deadline) {
      return '';
    }

    const differenceSuffix = differenceInDays > 0 ? 'remaining' : 'past';

    return `${formatDistanceStrict(startOfToday(), deadlineDay, { unit: 'day' })} ${differenceSuffix}`;
  },
  [CTX_ACTIVE_JOB_WIDGET_BADGE]: (deadline, differenceInDays, deadlineDay) => {
    if (!deadline) {
      return '';
    }

    const differenceSuffix = differenceInDays > 0 ? 'remaining' : 'expired';

    return `${formatDistanceStrict(startOfToday(), deadlineDay, { unit: 'day' })} ${differenceSuffix}`;
  },
  [CTX_REMAINING_BASIC_COMPONENT]: (deadline, differenceInDays, deadlineDay) => {
    if (!deadline) {
      return TEXT_ONGOING;
    }

    return differenceInDays < 0
      ? 'Closed'
      : `${formatDistanceStrict(startOfToday(), deadlineDay, { unit: 'day' })} remaining`;
  },
  [CTX_JOB_AD_EXPIRE_BADGE]: (deadline) => {
    if (!deadline) {
      return '';
    }

    const deadlineIso = parseISO(`${deadline}Z`);
    const differenceSuffix = deadline && isPast(deadlineIso) ? 'ago' : 'remaining';
    const differenceDistance = deadline && formatDistanceToNowStrict(deadlineIso);

    return `${differenceDistance} ${differenceSuffix}`;
  },
  default: () => '',
};

export function useJobDeadlineText(
  deadline?: string | null,
  options?: {
    context?: string;
    defaultEmpty?: string;
  },
): {
  deadlineDay: Date;
  differenceInDays: number;
  deadlineDateText: string;
  deadlineDateOrDefaultText: string;
  deadlineDifferenceText: string;
  deadlineDifferenceOrDeafultText: string;
  deadlineDateWithDifferenceText: string;
  deadlineDateWithDifferenceOrDefaultText: string;
} {
  const { defaultEmpty = TEXT_ONGOING, context = 'default' } = options ?? {};

  const deadlineDay = useDeadlineDay(deadline);

  const differenceInDays = useDifferenceInDays(deadline);

  const deadlineDifferenceText = useMemo(
    () => deadlineDifferenceTextContext[context]?.(deadline, differenceInDays, deadlineDay),
    [context, deadline, differenceInDays, deadlineDay],
  );

  const deadlineDifferenceOrDeafultText = useMemo(
    () => deadlineDifferenceText || defaultEmpty,
    [deadlineDifferenceText, defaultEmpty],
  );

  const { formatDateToDefault } = useDateDefaultFormatFunction();

  const deadlineDateText = useMemo(
    () => (deadline ? formatDateToDefault(deadline) : ''),
    [deadline, formatDateToDefault],
  );

  const deadlineDateOrDefaultText = useMemo(() => deadlineDateText || defaultEmpty, [deadlineDateText, defaultEmpty]);

  const deadlineDateWithDifferenceText = useMemo(
    () => (deadline ? `${deadlineDateText} ${deadlineDifferenceText}` : ''),
    [deadline, deadlineDateText, deadlineDifferenceText],
  );

  const deadlineDateWithDifferenceOrDefaultText = useMemo(
    () => deadlineDateWithDifferenceText || defaultEmpty,
    [deadlineDateWithDifferenceText, defaultEmpty],
  );

  return {
    deadlineDay,
    differenceInDays,
    deadlineDateText,
    deadlineDateOrDefaultText,
    deadlineDifferenceText,
    deadlineDifferenceOrDeafultText,
    deadlineDateWithDifferenceText,
    deadlineDateWithDifferenceOrDefaultText,
  };
}

export function useBadgeVariant(deadline: string | undefined | null, badgeType: BadgeTypes) {
  const differenceInDays = useDifferenceInDays(deadline);

  return useMemo(() => {
    if (!deadline) {
      return ExBadgeVariant.primary;
    }

    const hash = getHashFromDifferenceInDays(differenceInDays);
    const variants = badgeVariants[badgeType];

    return variants[hash] || ExBadgeVariant.default;
  }, [deadline, differenceInDays, badgeType]);
}
