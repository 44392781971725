import loaderSlice from './reducers';
import * as selectors from './selectors';

const { reducer, actions } = loaderSlice;

export const loaderActions = actions;

export const loaderSelectors = selectors;

export * from './helpers';

export default reducer;
