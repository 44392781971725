import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

/**
 * This hook listen to window resize and return current actual window inner width
 *
 * @returns {number}
 */
export function useWindowSize() {
  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const resizeEventListener = () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    };

    const debouncedListener = debounce(resizeEventListener, 200);

    window.addEventListener('resize', debouncedListener);

    return () => window.removeEventListener('resize', debouncedListener);
  }, []);

  return size;
}
