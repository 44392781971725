import React from 'react';
import styled from 'styled-components';

import { ExEducation } from 'model';
import { WithRequired } from 'model/utils';

import { educationSchema } from 'containers/CandidateForm';
import { EducationFormBody } from 'containers/EducationForm';
import { modalsSelectors } from 'containers/Modals/store';

import { Form } from 'components';
import { ExLoaderHandlerWrapperStrict } from 'components/Common/LoaderHandlerWrapper';
import { DialogProps } from 'components/Dialog';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { candidateEducationSelectors } from 'store/entities/candidate-education/candidate-education.selectors';
import { exModalConfirmAction } from 'store/modals/modals.actions';

import { EditCandidateEducationModalStyled } from './EditCandidateEducationModalComponents';
import { EditCandidateEducationModalProps } from './EditCandidateEducationModalProps';

const EditCandidateEducationModalFooter = styled.div`
  display: flex;
  justify-content: center;
`;

export const EditCandidateEducationModal: React.FC<EditCandidateEducationModalProps> = ({ id, className, onClose }) => {
  const dispatch = useAppDispatch();
  const candidateId = useAppSelector(modalsSelectors.modalCandidateId);
  const educationId = useAppSelector(modalsSelectors.modalEducationId);
  const education = useAppSelector(candidateEducationSelectors.selectById, educationId!);

  const content: DialogProps['content'] = {
    title: `${educationId ? 'Edit' : 'Create'} Education`,
    withTitle: true,
  };

  const defaultValues = { ...education };

  const submitHandler = async (formData: Partial<ExEducation>) => {
    const params = {
      data: formData,
      urlParams: { candidateId: candidateId!, educationId: formData.educationId },
    };
    dispatch(exModalConfirmAction({ id, modalResult: params }));
  };

  return (
    <EditCandidateEducationModalStyled className={className} onClose={onClose} content={content}>
      <Form defaultValues={defaultValues} onSubmit={submitHandler} validationSchema={educationSchema}>
        <FormGroup name="candidateEducation" value={education} />
        <EditCandidateEducationModalFooter>
          <ExButton variant="light" onClick={() => onClose(false)} type="button">
            Cancel
          </ExButton>
          <ExLoaderHandlerWrapperStrict>
            <ExButton variant="primary" className="ml-3" type="submit">
              {educationId ? 'Update' : 'Create'}
            </ExButton>
          </ExLoaderHandlerWrapperStrict>
        </EditCandidateEducationModalFooter>
      </Form>
    </EditCandidateEducationModalStyled>
  );
};

const FormGroup = <T extends WithRequired<ExEducation, 'educationId'>>({ value }: { value?: T; name: string }) => (
  <EducationFormBody value={value} groupName="candidateEducation" idFieldName="educationId" />
);
