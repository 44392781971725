import React, { useEffect, useMemo, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styled from 'styled-components/macro';

import { Ability } from 'model';

import { Ellipsis } from 'components/Ellipsis';
import {
  CheckableRowStyled,
  CloseButtonStyled,
  Description,
} from 'components/Form/CheckableRow/CheckableRowComponents';
import { CheckableRowProps } from 'components/Form/CheckableRow/CheckableRowProps';
import { CustomCheckbox } from 'components/Form/CustomCheckbox';
import { FormCard } from 'components/FormCard';

/**
 * Row of text with ability to check it and delete it.
 *
 * @param {CheckableRowProps} {
 *   item,
 *   onChange,
 *   onDelete,
 * }
 * @returns {React.ReactElement}
 */
export const CheckableRow: React.FC<CheckableRowProps> = ({
  className,
  item,
  onChange,
  onDelete,
  variant = 'primary',
  disabled,
  required,
}: CheckableRowProps): React.ReactElement => {
  const [innerItem, setInnerItem] = useState<Ability>(item);

  const handleCheckedChange = (isEnabled: boolean) => {
    const newInnerItemState = {
      ...innerItem,
      isEnabled,
    };

    setInnerItem(newInnerItemState);
    onChange && onChange(newInnerItemState);
  };

  const isElementInteractive = useMemo(() => {
    return !disabled && onDelete;
  }, [disabled, onDelete]);

  useEffect(() => {
    setInnerItem(item);
  }, [item]);

  return (
    <CheckableRowStyled className={className} isHoverable={!disabled}>
      <CustomCheckbox
        onChange={handleCheckedChange}
        checked={innerItem.isEnabled}
        variant={variant}
        disabled={disabled}
      />
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip className="expedo-tooltip" id={`tooltip-${innerItem.id}`}>
            {innerItem.text}
          </Tooltip>
        }
      >
        <Ellipsis clamp={2}>
          <Description>
            {innerItem.text} {required && <FormCard.InputLabelRequired>*</FormCard.InputLabelRequired>}
          </Description>
        </Ellipsis>
      </OverlayTrigger>
      {isElementInteractive && <CloseButtonStyled onClick={() => onDelete && onDelete(innerItem.id)} />}
    </CheckableRowStyled>
  );
};

export const CheckableRowBold = styled(CheckableRow)`
  font-weight: bold;
`;
