import { JobApplicantItem, JobApplicantListItem } from 'api-endpoints/job/models';

import { PipelineStageType } from 'model/api-enums.constants';

import { pipe } from 'utils/funcs';
import { KeyMap, toStateKeys, TwoWayMap } from 'utils/key-mapper';

import { Applicant } from 'store/entities/applicants/models';

const jobApplicantsItemMapper: KeyMap<JobApplicantListItem, Applicant> = {
  applicantId: 'id',
};

export const mapJobApplicantListItemKeys = toStateKeys(new TwoWayMap(jobApplicantsItemMapper));

const assignJobApplicantListItemKeys = (applicant: Applicant): Applicant => {
  return Object.assign(applicant, mapJobApplicantListItemKeys(applicant));
};

const assignJobApplicantId = (applicant: Applicant): Applicant => {
  return Object.assign(applicant, {
    ...(applicant.jobApplicantId && { id: applicant.jobApplicantId }),
  });
};

const assignStageType = (applicant: Applicant): Applicant => {
  const stageName = applicant.onboardingStatus || applicant.pipelineStageName || applicant.status;
  const stageType = PipelineStageType[stageName as keyof typeof PipelineStageType];
  return Object.assign(applicant, {
    stageType,
  });
};

export const applicantToState = (applicant: JobApplicantListItem | JobApplicantItem): Applicant =>
  pipe(applicant, assignJobApplicantListItemKeys, assignJobApplicantId, assignStageType);
