import { AxiosProgressEvent } from 'axios';
import { Channel, END, eventChannel } from 'redux-saga';
import { call, delay, put, take } from 'redux-saga/effects';

import { ParsingResult } from 'model';

import { pluralize } from 'utils/pluralize';

import { updateWizardPage } from 'store/modals/modals.actions';

export function* uploadResumes(channel: Channel<any>, modalId: string) {
  while (channel) {
    const data = yield take(channel);
    yield call(updateWizardPageProps, modalId, 'upload', 'Uploading...', {
      uploadValue: data?.uploadValue,
      uploadText: `${data?.uploadValue ?? 0}% of 100%`,
    });
    yield delay(200);
    if (data?.isUploadComplete) {
      yield call(updateWizardPageProps, modalId, 'upload', 'Parsing...', {
        uploadValue: data?.uploadValue,
        uploadText: 'Resumes was uploaded. Please wait parsing result',
      });
      channel.close();
      break;
    }
  }
}

function* updateWizardPageProps(modalId: string, page: string, title: string, modalProps: {}) {
  yield put(
    updateWizardPage({
      id: modalId,
      page,
      modalConfig: {
        content: {
          withTitle: true,
          title,
        },
      },
      modalProps,
    }),
  );
}

export function prepareFormData(files: File[]) {
  const fd = new FormData();

  files.forEach((file) => {
    fd.append('resume', file, file.name);
  });

  let emitter: any;
  const channel = eventChannel((em) => {
    emitter = em;
    return () => {
      // do nothing
    };
  });

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    const total = progressEvent.total || 1;
    const uploadValue = Math.floor((progressEvent.loaded * 100) / total);
    if (uploadValue === 100) {
      emitter({ uploadValue, isUploadComplete: true });
      emitter(END);
    } else {
      emitter({ uploadValue });
    }
  };

  return {
    formData: fd,
    onUploadProgress,
    channel,
  };
}

export function generateUploadMessages(
  data: ParsingResult,
  filesCount: number,
  entityType: 'applicants' | 'candidates',
) {
  const errorsCount =
    data?.parsingResults.reduce((acc: number, result: any) => (!result.isSuccess ? acc + 1 : acc), 0) ?? 0;

  const successFullyUploadedCount = filesCount - errorsCount;
  const resumeWord = pluralize('resume', successFullyUploadedCount || 0);
  const errorResumeWord = pluralize('resume', errorsCount || 0);

  const messages: Record<string, string> = {
    candidates: `You have successfully created candidate profiles from ${successFullyUploadedCount} ${resumeWord}.`,
    applicants: `You have successfully created applicants from ${successFullyUploadedCount} ${resumeWord}.`,
  };

  const successPageMessage = Boolean(errorsCount)
    ? `The following ${errorResumeWord} could not be uploaded`
    : 'All resumes successfully parsing';

  return {
    errorsCount,
    successPageMessage,
    messageSuccessUploaded: messages[entityType],
  };
}
