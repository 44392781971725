import Form from 'react-bootstrap/Form';
import styled, { css } from 'styled-components/macro';

export const ScreeningQuestionFormList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export const ScreeningQuestionFormListItem = styled.li`
  margin: 0;
  padding: 0;

  &:not(:first-child) {
    margin-top: 10px;
  }
`;

export const ScreeningQuestionFormStyled = styled.div``;

export const ScreeningQuestionFormGroupStyled = styled(Form.Group)`
  margin-bottom: 0;
`;

type ScreeningQuestionFormLabelProps = {
  $disabled?: boolean;
};

export const ScreeningQuestionFormLabel = styled.label<ScreeningQuestionFormLabelProps>`
  margin-bottom: 0;
  position: relative;
  padding-left: 20px;
  cursor: ${(props) => (props.$disabled ? 'auto' : 'pointer')};

  &::before {
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    display: block;
    width: 10px;
    height: 10px;
    background: #c4c4c4;
    border: 1px solid ${(props) => props.theme.colors.inactive};
  }
`;

export const ScreeningQuestionFormInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;

  &[type='radio'] {
    & ~ ${ScreeningQuestionFormLabel} {
      &::before {
        border-radius: 50%;
      }
    }
  }

  &:checked ~ ${ScreeningQuestionFormLabel} {
    &::before {
      background: ${(props) => props.theme.colors.primary};
      border-color: ${(props) => props.theme.colors.primary};
    }

    &::after {
      position: absolute;
      top: 5px;
      left: 4px;
      content: '';
      display: block;
      width: 5px;
      height: 8px;
      border-radius: 1px;
      border-bottom: 2px solid ${(props) => props.theme.colors.white};
      border-right: 2px solid ${(props) => props.theme.colors.white};
      transform: rotate(45deg);
      transform-origin: center;
    }
  }
`;

export const ScreeningQuestionFormLabelV2 = styled.label<ScreeningQuestionFormLabelProps>`
  margin-bottom: 0;
  position: relative;
  padding-left: 24px;
  cursor: ${(props) => (props.$disabled ? 'auto' : 'pointer')};

  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: #404968;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background: inherit;
    border: 1px solid #b9c0d4;
    border-radius: 2px;
  }
`;

export const ScreeningQuestionFormInputV2 = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;

  &[type='radio'] {
    & ~ ${ScreeningQuestionFormLabelV2} {
      &::before {
        border-radius: 50%;
      }
    }
  }

  &:checked ~ ${ScreeningQuestionFormLabelV2} {
    &::before {
      background: ${(props) => props.theme.colors.primary};
      border-color: ${(props) => props.theme.colors.primary};
    }

    &::after {
      position: absolute;
      top: 3px;
      left: 5px;
      content: '';
      display: block;
      width: 5px;
      height: 8px;
      border-radius: 1px;
      border-bottom: 2px solid ${(props) => props.theme.colors.white};
      border-right: 2px solid ${(props) => props.theme.colors.white};
      transform: rotate(45deg);
      transform-origin: center;
    }
  }
`;

export const ScreeningQuestionFormSwitchContainer = styled.div`
  display: inline-flex;
  overflow: hidden;
`;

type FormRadioLabelStyledProps = {
  $disabled?: boolean;
};

const ScreeningQuestionFormSwitchLabelCheckedStyles = css`
  background: ${(props) => props.theme.colors.primary};
  border-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
`;

const ScreeningQuestionFormSwitchLabelDisabledStyles = ({ $disabled: disabled }: FormRadioLabelStyledProps) =>
  disabled &&
  css`
    opacity: 0.65;
    cursor: auto;
  `;

export const ScreeningQuestionFormSwitchLabelStyled = styled.label<FormRadioLabelStyledProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 80px;
  height: 40px;
  margin: 0;
  padding: 8px;
  gap: 10px;

  border: 1px solid #dcdfea;

  cursor: pointer;

  ${ScreeningQuestionFormSwitchLabelDisabledStyles};

  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  color: #404968;

  svg {
    path {
      fill: currentColor;
    }
  }
`;

export const ScreeningQuestionFormSwitchOptionStyled = styled.div`
  position: relative;

  &:first-child {
    ${ScreeningQuestionFormSwitchLabelStyled} {
      border-radius: 8px 0px 0px 8px;
    }
  }

  &:last-child {
    ${ScreeningQuestionFormSwitchLabelStyled} {
      border-radius: 0px 8px 8px 0px;
    }
  }
`;

export const ScreeningQuestionFormSwitchRadioInputStyled = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:checked ~ ${ScreeningQuestionFormSwitchLabelStyled} {
    ${ScreeningQuestionFormSwitchLabelCheckedStyles}
  }
`;

export const ScreeningQuestionFormHiddenFocusInputStyled = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 0;
  height: 0;
`;
