import React from 'react';
import styled from 'styled-components';

import { Spinner } from 'components/Spinner';

import { AppFileCardBasic, FileLoader } from './AppFileCardBasic';

interface AppFileCardLoadingProps {
  className?: string;
}

const AppFileCardLoadingPure: React.FC<AppFileCardLoadingProps> = ({ className }) => {
  return (
    <AppFileCardBasic className={className}>
      <FileLoader>
        <Spinner />
      </FileLoader>
    </AppFileCardBasic>
  );
};

export const AppFileCardLoading = styled(AppFileCardLoadingPure)`
  cursor: unset;
`;
