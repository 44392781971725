const copiedStore = {
  dispatch: (() => {}) as Function,
  getState: (() => {}) as Function,
};

export const setGlobalDispatch = (dispatch) => (copiedStore.dispatch = dispatch);

export const getGlobalDispatch = () => copiedStore.dispatch;

export const setGlobalGetState = (getState) => (copiedStore.getState = getState);

export const getGlobalGetState = () => copiedStore.getState();
