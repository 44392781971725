import React, { useCallback } from 'react';

import { getJobTeamMemberAutocompleteFunc } from 'api-endpoints/job';

import { ApplicationRole } from 'model/api-enums.constants';

import { rbacConstants } from 'containers/Auth/AuthMatrix';

import { ExMultiselectFormBody } from 'components/ui/ExMultiselectFormBody';

import { authSelectors } from 'store/auth/auth.selectors';
import { JobTeamMember } from 'store/entities/jobs/models';
import { useSelector } from 'store/rootSelectors';

type JobTeamAddMemberFormBodyProps = {
  className?: string;
  onChange: (entityList: Array<JobTeamMember>) => void;
  defaultValue: Array<JobTeamMember>;
};

const useJobTeamAddMemberFormBodyState = ({ className, defaultValue, onChange }: JobTeamAddMemberFormBodyProps) => {
  const isAllowed = useSelector((state) =>
    authSelectors.isFeatureAllowed(state, { feature: rbacConstants.jobs.hiringTeamRemoveMember }),
  );

  const isJobTeamMemberUnRemovable = useCallback(
    (jobTeamMember: JobTeamMember) => {
      const isTeamMemberLineManager = jobTeamMember?.applicationRoles === ApplicationRole.Manager;

      return !(isAllowed && isTeamMemberLineManager);
    },
    [isAllowed],
  );

  const loadEntitiesHandler = async (searchTerm: string) => {
    const response = await getJobTeamMemberAutocompleteFunc({
      urlParams: { jobId: '91e745c9-77be-44da-a435-9c32161efd31' },
      params: { searchTerm },
    });

    return (response.data || []).map((teamMember) => ({
      ...teamMember,
      isRemoved: false,
      userId: teamMember.id,
      photoUrl: '',
    }));
  };

  return {
    className,
    defaultValue,
    loadEntitiesHandler,
    onChange,
    isJobTeamMemberUnRemovable,
  };
};

export const JobTeamAddMemberFormBody: React.FC<JobTeamAddMemberFormBodyProps> = (props) => {
  const { className, defaultValue, loadEntitiesHandler, onChange, isJobTeamMemberUnRemovable } =
    useJobTeamAddMemberFormBodyState(props);

  return (
    <ExMultiselectFormBody
      className={className}
      defaultValue={defaultValue}
      getOptionLabelCallback={(option) => option.name}
      getOptionValueCallback={(option) => option.id}
      loadEntitiesHandler={loadEntitiesHandler}
      onChangeHandler={onChange}
      title="Search Members"
      backspaceRemovesValue={false}
      unRemovedValuesFilter={isJobTeamMemberUnRemovable}
    />
  );
};
