import { Action } from 'redux';
import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects';

import * as candidateApi from 'api-endpoints/candidate';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { getTranslate } from 'utils/i18utils';
import { fetchEntity, invokeApiCall, invokeExModal, prepareExModalChannel, ReturnData, worker } from 'utils/sagas';

import { exModalHideAction } from 'store/modals/modals.actions';
import { ModalGeneralResult } from 'store/modals/modals.interfaces';

import {
  CreateCandidateWorkExperienceInnerRequestParams,
  UpdateCandidateWorkExperienceInnerRequestParams,
} from './api/requests';
import * as candidateWorkExperienceActions from './candidate-work-experience.actions';
import { candidateWorkExperienceSlice } from './candidate-work-experience.reducer';

//--------------------------------------------------------------------------------------------------

const apiGetCandidateWorkExperienceList = fetchEntity.bind(
  null,
  candidateWorkExperienceActions.candidateWorkExperienceGetListHandler,
  candidateApi.getCandidateWorkExperienceList,
);

const apiCreateCandidateWorkExperience = fetchEntity.bind(
  null,
  candidateWorkExperienceActions.candidateWorkExperienceCreateHandler,
  candidateApi.createCandidateWorkExperience,
);

const apiUpdateCandidateWorkExperience = fetchEntity.bind(
  null,
  candidateWorkExperienceActions.candidateWorkExperienceUpdateHandler,
  candidateApi.updateCandidateWorkExperience,
);

const apiDeleteCandidateWorkExperience = fetchEntity.bind(
  null,
  candidateWorkExperienceActions.candidateWorkExperienceDeleteHandler,
  candidateApi.deleteCandidateWorkExperience,
);

const apiGetCandidateWorkExperience = fetchEntity.bind(
  null,
  candidateWorkExperienceActions.candidateWorkExperienceGetHandler,
  candidateApi.getCandidateWorkExperience,
);

//--------------------------------------------------------------------------------------------------

const cancelableGetCandidateWorkExperienceList = worker.bind(
  null,
  candidateWorkExperienceActions.candidateWorkExperienceGetListCancel,
  apiGetCandidateWorkExperienceList,
);

const cancelableCreateCandidateWorkExperience = worker.bind(
  null,
  candidateWorkExperienceActions.candidateWorkExperienceCreateCancel,
  apiCreateCandidateWorkExperience,
);

const cancelableUpdateCandidateWorkExperience = worker.bind(
  null,
  candidateWorkExperienceActions.candidateWorkExperienceUpdateCancel,
  apiUpdateCandidateWorkExperience,
);

const cancelableDeleteCandidateWorkExperience = worker.bind(
  null,
  candidateWorkExperienceActions.candidateWorkExperienceDeleteCancel,
  apiDeleteCandidateWorkExperience,
);

const cancelableGetCandidateWorkExperience = worker.bind(
  null,
  candidateWorkExperienceActions.candidateWorkExperienceGetCancel,
  apiGetCandidateWorkExperience,
);

//--------------------------------------------------------------------------------------------------

function* candidateWorkExperienceCreateWorker(action: Action) {
  const { modalId, sagaChannel } = yield prepareExModalChannel();
  yield fork(invokeExModal, {
    channel: sagaChannel,
    modalId,
    modalType: ModalsTypeKey.editCandidateWorkExperience,
  });

  while (true) {
    yield call(stopLoader, action);
    const { confirm, cancel }: ModalGeneralResult = yield take(sagaChannel);

    if (cancel || !confirm) {
      return;
    }

    yield call(startLoader, action);

    const requestPayload = confirm.payload.modalResult as CreateCandidateWorkExperienceInnerRequestParams;
    const { data, errorData, message }: ReturnData<typeof candidateApi.createCandidateWorkExperience> = yield call(
      invokeApiCall,
      candidateApi.createCandidateWorkExperience,
      requestPayload,
    );

    if ([errorData, message].some(Boolean)) {
      continue;
    }

    if (data) {
      yield all([
        put(
          candidateWorkExperienceActions.candidateWorkExperienceGetListRequest({
            params: { orderBy: 'AdvancedStartDate', orderDirection: 'Ascending' },
            urlParams: { candidateId: requestPayload.urlParams.candidateId },
          }),
        ),
        put(candidateWorkExperienceSlice.actions.upsertOne({ ...requestPayload.data, ...data } as any)),
        put(exModalHideAction({ id: modalId })),
        put(alertsEffects.showSuccess({ message: getTranslate('work_experience_create_success') })),
        call(stopLoader, action),
      ]);
    }

    break;
  }
}

function* candidateWorkExperienceUpdateWorker(action: Action) {
  const { modalId, sagaChannel } = yield prepareExModalChannel();
  yield fork(invokeExModal, {
    channel: sagaChannel,
    modalId,
    modalType: ModalsTypeKey.editCandidateWorkExperience,
  });
  while (true) {
    yield call(stopLoader, action);
    const { confirm, cancel }: ModalGeneralResult = yield take(sagaChannel);

    if (cancel || !confirm) {
      return;
    }

    yield call(startLoader, action);

    const requestPayload = confirm.payload.modalResult as UpdateCandidateWorkExperienceInnerRequestParams;
    const { errorData, message }: ReturnData<typeof candidateApi.updateCandidateWorkExperience> = yield call(
      invokeApiCall,
      candidateApi.updateCandidateWorkExperience,
      requestPayload,
    );

    if ([errorData, message].some(Boolean)) {
      continue;
    }

    yield all([
      put(
        candidateWorkExperienceSlice.actions.updateOne({
          changes: { ...requestPayload.data } as any,
          id: requestPayload.urlParams.workExperienceId!,
        }),
      ),
      put(
        candidateWorkExperienceActions.candidateWorkExperienceGetListRequest({
          params: { orderBy: 'AdvancedStartDate', orderDirection: 'Ascending' },
          urlParams: { candidateId: requestPayload.urlParams.candidateId },
        }),
      ),
      put(exModalHideAction({ id: modalId })),
      put(alertsEffects.showSuccess({ message: getTranslate('work_experience_update_success') })),
      call(stopLoader, action),
    ]);
    break;
  }
}

function* candidateWorkExperienceRemoveWorker(
  action: ReturnType<typeof candidateWorkExperienceActions.candidateWorkExperienceRemove>,
) {
  const candidateWorkExperience = action.payload;
  const { modalId, sagaChannel } = yield prepareExModalChannel();
  yield fork(invokeExModal, {
    channel: sagaChannel,
    modalConfig: {
      content: {
        buttonOk: 'Remove',
        buttonOkVariant: 'primary',
        message: `Are you sure you want to remove work experience?`,
        title: 'Confirmation',
        withActions: true,
        withTitle: true,
      },
    },
    modalId,
    modalType: ModalsTypeKey.confirmModal,
  });

  while (true) {
    yield call(stopLoader, action);
    const { confirm, cancel }: ModalGeneralResult = yield take(sagaChannel);

    if ([cancel, !confirm].some(Boolean)) {
      return;
    }

    yield call(startLoader, action);
    const { errorData, message } = yield call(invokeApiCall, candidateApi.deleteCandidateWorkExperience, {
      urlParams: {
        candidateId: candidateWorkExperience.candidateId,
        workExperienceId: candidateWorkExperience.workExperienceId,
      },
    });

    if ([errorData, message].some(Boolean)) {
      continue;
    }

    yield all([
      put(candidateWorkExperienceSlice.actions.removeOne(candidateWorkExperience.workExperienceId)),
      put(alertsEffects.showSuccess({ message: getTranslate('work_experience_delete_success') })),
      put(exModalHideAction({ id: modalId })),
      call(stopLoader, action),
    ]);

    break;
  }
}

export function* candidateWorkExperienceSagas() {
  yield takeLatest(
    candidateWorkExperienceActions.candidateWorkExperienceGetListRequest,
    cancelableGetCandidateWorkExperienceList,
  );
  yield takeLatest(
    candidateWorkExperienceActions.candidateWorkExperienceCreateRequest,
    cancelableCreateCandidateWorkExperience,
  );
  yield takeLatest(
    candidateWorkExperienceActions.candidateWorkExperienceUpdateRequest,
    cancelableUpdateCandidateWorkExperience,
  );
  yield takeLatest(
    candidateWorkExperienceActions.candidateWorkExperienceDeleteRequest,
    cancelableDeleteCandidateWorkExperience,
  );
  yield takeLatest(
    candidateWorkExperienceActions.candidateWorkExperienceGetRequest,
    cancelableGetCandidateWorkExperience,
  );
  yield takeLatest(candidateWorkExperienceActions.candidateWorkExperienceCreate, candidateWorkExperienceCreateWorker);
  yield takeLatest(candidateWorkExperienceActions.candidateWorkExperienceUpdate, candidateWorkExperienceUpdateWorker);
  yield takeLatest(candidateWorkExperienceActions.candidateWorkExperienceRemove, candidateWorkExperienceRemoveWorker);
}
