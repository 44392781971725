/* eslint-disable sonarjs/no-identical-functions */
import { useCallback } from 'react';
import { OnDragEndResponder } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';

import {
  ApplicantFormScreeningQuestionsElementProps,
  ApplicantFormScreeningQuestionsElementV2Props,
  CompanyScreeningQuestionsElementProps,
} from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/CompanyScreeningQuestionsTypes';
import { ScreeningQuestionViewProps } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionView/ScreeningQuestionViewProps';
import { useIsDisabledJobEditByRole } from 'pages/Jobs/CreateJobV2/hooks';

import { jobFormSelectors } from 'containers/JobForms/state';
import { modalsSelectors } from 'containers/Modals/store';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useModal } from 'utils/hooks/useModal';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { jobScreeningQuestionsActions, jobScreeningQuestionsSelectors } from 'store/entities/job-screening-questions';
import { jobsSelectors } from 'store/entities/jobs';
import {
  useDeleteScreeningQuestionMutation,
  useRemoveJobScreeningQuestionMutation,
  useReorderJobScreeningQuestionsMutation,
  useSearchJobScreeningQuestionsQuery,
  useSearchScreeningQuestionQuery,
} from 'store/entities/screening-question/screening-question.api';
import { companyScreeningQuestionUiActions } from 'store/ui/company/company-screening-question/';
import { companyScreeningQuestionListUiSelectors } from 'store/ui/company/company-screening-question-list/company-screening-question-list.selectors';

export const useCompanyScreeningQuestionsListState = () => {
  const searchScreeningQuestionParams = useAppSelector(
    companyScreeningQuestionListUiSelectors.searchScreeningQuestionParams,
  );

  const { data, isFetching } = useSearchScreeningQuestionQuery(searchScreeningQuestionParams);

  const entities = data?.entities || {};

  const itemsIds = data?.ids || [];
  const hasItemsIds = Boolean(itemsIds.length);
  const noItemsIds = !hasItemsIds && !isFetching;
  const isDragDisabled = true;

  return {
    itemsIds,
    entities,
    hasItemsIds,
    isFetching,
    noItemsIds,
    onDragEnd: () => {},
    isDragDisabled,
  } as const;
};

export const useJobScreeningQuestionsListState = () => {
  const jobId = useAppSelector(modalsSelectors.modalJobId)!;
  const { data: jobScreeningQuestions } = useSearchJobScreeningQuestionsQuery({ jobId });

  const [reorderJobScreeningQuestionsMutation] = useReorderJobScreeningQuestionsMutation();

  const entities = jobScreeningQuestions?.entities || {};

  const itemsIds = jobScreeningQuestions?.ids || [];
  const hasItemsIds = Boolean(itemsIds.length);
  const noItemsIds = !hasItemsIds;
  const isDragDisabled = false;
  const isFetching = false;

  const onDragEnd: OnDragEndResponder = (result) => {
    if (result.destination && result.destination.index !== result.source.index) {
      const newItemsIds = [...itemsIds] as string[];
      newItemsIds.splice(result.source.index, 1);
      newItemsIds.splice(result.destination.index, 0, result.draggableId);

      const questions = newItemsIds.map((newItemsId) => ({ jobScreeningQuestionId: newItemsId }));

      reorderJobScreeningQuestionsMutation({
        jobId,
        data: { questions },
      });
    }
  };

  return {
    itemsIds,
    entities,
    hasItemsIds,
    isFetching,
    noItemsIds,
    onDragEnd,
    isDragDisabled,
  } as const;
};

export const useCreateJobScreeningQuestionsListState = () => {
  const dispatch = useAppDispatch();

  const jobScreeningQuestions = useAppSelector(jobScreeningQuestionsSelectors.selectState);

  const entities = jobScreeningQuestions?.entities || {};

  const itemsIds = jobScreeningQuestions?.ids || [];
  const hasItemsIds = Boolean(itemsIds.length);
  const noItemsIds = !hasItemsIds;
  const isFetching = false;

  const onDragEnd: OnDragEndResponder = (result) => {
    if (result.destination && result.destination.index !== result.source.index) {
      dispatch(
        jobScreeningQuestionsActions.reorder({
          itemId: result.draggableId,
          target: result.destination.index,
          source: result.source.index,
        }),
      );
    }
  };

  const jobId = useAppSelector(jobFormSelectors.selectFormDataJobId);
  const isQuestionsLocked = useAppSelector(jobsSelectors.selectIsQuestionsLocked, jobId);

  const isDragDisabled = isQuestionsLocked;

  return {
    itemsIds,
    entities,
    hasItemsIds,
    isFetching,
    noItemsIds,
    onDragEnd,
    isDragDisabled,
  } as const;
};

export const useCompanyScreeningQuestionsElementState = ({
  className,
  forwardRef,
  question,
  editable = false,
  index,
  isDragDisabled,
  ...dndProps
}: CompanyScreeningQuestionsElementProps) => {
  const dispatch = useAppDispatch();

  const { itemId: questionId, questionTitle } = question;

  const onEditButtonClickHandler = useCallback(() => {
    dispatch(companyScreeningQuestionUiActions.setEditQuestionId(questionId));
  }, [dispatch, questionId]);

  const { show } = useModal({});

  const [deleteScreeningQuestion] = useDeleteScreeningQuestionMutation();

  const onDeleteButtonClickHandler: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation();
    const result = await show({
      withTitle: true,
      title: 'Confirm Remove',
      withActions: true,
      buttonOk: 'Remove',
      buttonOkVariant: 'danger',
      message: `You confirm remove the question\n "${questionTitle}"`,
    });
    if (result) {
      await deleteScreeningQuestion({ questionId, questionTitle });
    }
  };

  const isEditShown = true;
  const isEditable = false;
  const isControlsDisabled = false;

  return {
    className,
    forwardRef,
    dndProps,
    data: question,
    onEditButtonClickHandler,
    onDeleteButtonClickHandler,
    editable,
    isDragDisabled,
    index,
    isEditShown,
    isEditable,
    isControlsDisabled,
  };
};

export const useJobScreeningQuestionsElementState = ({
  className,
  forwardRef,
  question,
  editable = false,
  index,
  isDragDisabled,
  ...dndProps
}: CompanyScreeningQuestionsElementProps) => {
  const jobId = useAppSelector(modalsSelectors.modalJobId)!;
  const questionId = question.itemId;
  const questionTitle = question.questionTitle;

  const [removeJobScreeningQuestionMutation] = useRemoveJobScreeningQuestionMutation();

  const onEditButtonClickHandler = useCallback(() => {}, []);

  const onDeleteButtonClickHandler: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    removeJobScreeningQuestionMutation({ jobId, questionId, questionTitle });
  }, [jobId, questionId, questionTitle, removeJobScreeningQuestionMutation]);

  const isEditShown = false;
  const isEditable = true;
  const isControlsDisabled = false;

  return {
    className,
    forwardRef,
    dndProps,
    data: question,
    onEditButtonClickHandler,
    onDeleteButtonClickHandler,
    editable,
    isDragDisabled,
    index,
    isEditShown,
    isEditable,
    isControlsDisabled,
  };
};

export const useCreateJobScreeningQuestionsElementState = ({
  className,
  forwardRef,
  question,
  editable = false,
  index,
  isDragDisabled,
  ...dndProps
}: CompanyScreeningQuestionsElementProps) => {
  const dispatch = useAppDispatch();

  const questionId = question.itemId;

  const onEditButtonClickHandler = useCallback(() => {}, []);

  const onDeleteButtonClickHandler: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    dispatch(jobScreeningQuestionsActions.removeOne(questionId));
  }, [dispatch, questionId]);

  const jobId = useAppSelector(jobFormSelectors.selectFormDataJobId);
  const isQuestionsLocked = useAppSelector(jobsSelectors.selectIsQuestionsLocked, jobId);

  const { isDisabledJobEditByRole } = useIsDisabledJobEditByRole();
  const isControlsDisabled = isQuestionsLocked || isDisabledJobEditByRole;

  const isEditShown = false;
  const isEditable = !isControlsDisabled;

  return {
    className,
    forwardRef,
    dndProps,
    data: question,
    onEditButtonClickHandler,
    onDeleteButtonClickHandler,
    editable,
    isDragDisabled,
    index,
    isEditShown,
    isEditable,
    isControlsDisabled,
  };
};

export const useApplicantFormScreeningQuestionsElementState = ({
  className,
  question,
  editable = false,
  index,
  context,
}: ApplicantFormScreeningQuestionsElementProps) => {
  return {
    className,
    question,
    editable,
    index,
    context,
  };
};

export const useApplicantFormScreeningQuestionsElementV2State = ({
  className,
  question,
  editable = false,
  context,
}: ApplicantFormScreeningQuestionsElementV2Props) => {
  return {
    className,
    question,
    editable,
    context,
  };
};

export const useApplicationFormQuestionField = ({ editable, question }: ScreeningQuestionViewProps) => {
  const { errors, formState, register, setValue } = useFormContext();
  const validated = formState.isSubmitted;

  const disabled = !editable;

  const { itemId } = question;

  const fieldName = `screeningQuestions[${itemId}]`;
  const fieldNameValid = `screeningQuestions[${itemId}_valid]`;

  const fieldErrors = errors?.screeningQuestions?.[itemId];

  const isInvalid = validated && !!fieldErrors;

  return {
    itemId,
    fieldName,
    fieldNameValid,
    fieldErrors,
    disabled,
    validated,
    isInvalid,
    register,
    setValue,
  };
};
