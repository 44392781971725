import { AdditionalField, ApiValueForDynamicForm, Country, PostingDuration } from 'model';

import { BasicModel } from 'utils/reducer/reducer-helper';

export interface JobBoard {
  id: string;
  jobBoardId: string;
  name: string;
  url: string;
  logo: string;
  description?: string;
  settings?: JobBoardSettings[];
  additionalFields?: AdditionalField[];

  postingDuration?: PostingDuration | null;

  // mapped fields
  logoUrl?: string;

  isEditable?: boolean;
}

export interface JobBoardSettings {
  key: string;
  value: string | number;
  values: ApiValueForDynamicForm[];
  settingType: string;
  displayName: string;
  required: boolean;
}

export enum JobBoardBelongsTo {
  main = 'main',
  new = 'new',
  newJobAd = 'newJobAd',
}

export enum JobBoardStatus {
  subscribed,
  unsubscribed,
}

export enum JobBoardFiltersNames {
  name = 'name',
  status = 'status',
  belongsTo = 'belongsTo',
  industries = 'industries',
  countryCodes = 'countryCodes',
}

export interface JobBoardFilters {
  [JobBoardFiltersNames.status]: JobBoardStatus;
  [JobBoardFiltersNames.name]?: string;
  [JobBoardFiltersNames.belongsTo]?: [JobBoardBelongsTo, BasicModel['id']];
  [JobBoardFiltersNames.countryCodes]?: Country[];
}

export enum SortBy {
  name = 'Name',
  url = 'Url',
}

export enum Columns {
  tickHeader = 'tickHeader',
  tick = 'tick',
  name = 'name',
  url = 'url',
  actions = 'actions',
}
