import { useCallback, useMemo } from 'react';
import { differenceInCalendarDays, format, isDate, parseISO, startOfToday } from 'date-fns';
import { compose } from 'redux';

import { companySelectors } from 'store/company/company.selectors';
import { useSelector } from 'store/rootSelectors';

export const getDifferenceInDays = (deadlineDay: Date) => {
  return differenceInCalendarDays(deadlineDay, startOfToday());
};

export const getDeadlineDay = (deadline: string | null | undefined) => {
  return deadline ? parseISO(deadline) : startOfToday();
};

export function useDeadlineDaysCount(deadline?: string | null) {
  return useMemo(() => {
    const deadlineProcessing = compose(getDifferenceInDays, getDeadlineDay);
    return deadlineProcessing(deadline);
  }, [deadline]);
}

function isCanParse(value: string | Date): value is Date {
  return (typeof value === 'string' && !!Date.parse(value)) || isDate(value);
}

export function isDateGuard(value: string | Date): value is Date {
  return isDate(value);
}

/**
 * Function format and parsedISO from `date-fns` to return formatted date;
 *
 * @param {string} dateString
 * @param {string} dateFormat
 *
 * @returns string
 */
export function dateFnsFormat(dateString: string | Date, dateFormat: string): string {
  if (!isCanParse(dateString)) {
    return dateString;
  }

  const dateToFormat = isDateGuard(dateString) ? dateString : parseISO(dateString);

  return format(dateToFormat, dateFormat);
}

/**
 * Hook that used format and parsedISO from `date-fns` to return formatted date;
 *
 * @param {string} dateString
 * @param {string} dateFormat
 *
 * @returns string
 */
function useDateFnsFormat(dateString: string | Date, dateFormat: string): string {
  return dateFnsFormat(dateString, dateFormat);
}

/**
 * This hook get default date format from redux and applied it to passed date
 */
function useDateDefaultFormatFunction() {
  const defaultDateFormat = useSelector(companySelectors.getDefaultDateFormat);

  // Create formatter function with bound dateFormat argument
  const formatDateToDefault = useCallback<(dateString: string | Date) => string>(
    (dateString) => dateFnsFormat(dateString, defaultDateFormat),
    [defaultDateFormat],
  );

  return { formatDateToDefault };
}

/**
 * This hooks use `useDateDefaultFormatFunction` function to apply default formatting to date.
 *
 * @param {string} dateString
 */
function useDateDefaultFormat(dateString: string) {
  const { formatDateToDefault } = useDateDefaultFormatFunction();

  return formatDateToDefault(dateString);
}

export { useDateFnsFormat, useDateDefaultFormatFunction, useDateDefaultFormat };
