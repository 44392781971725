import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { CompanyDateFormatValues } from 'model/api-enums.constants';

import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import { ExModalBodyStyled, ExModalWrapperStyled } from 'containers/Modals/ModalsContent/Company/ExModalComponents';

import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { DialogProps } from 'components/Dialog';
import { FormCheckbox } from 'components/FormCheckbox';
import { ExButton } from 'components/ui/ExButton';
import { ExDialogActions, ExDialogFooter } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

import { updateCompanyDefaultDateFormat } from 'store/company/company.actions';
import { companySelectors } from 'store/company/company.selectors';
import { exModalConfirmAction } from 'store/modals/modals.actions';
import { useSelector } from 'store/rootSelectors';

type CompanyUpdateDateTimeProps = {
  id: string;
};
const DateTimeWrapper = styled.div`
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
`;
const DateTimeLabel = styled.div`
  font-size: ${getThemeFontSize('cardHeading')};
  margin-bottom: 1rem;
`;

export const CompanyUpdateDateTime = ({ id, onClose }: CompanyUpdateDateTimeProps & AppModalContentProps) => {
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<number>();

  const content: DialogProps['content'] = {
    title: 'Date and Time',
    withTitle: true,
  };

  const onSubmitClickHandler = () => {
    dispatch(exModalConfirmAction({ id, modalResult: { dateFormat: selected } }));
  };
  const dateVariants = CompanyDateFormatValues;

  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSelected(parseInt(e.target.value));
  };
  const companyInfo = useSelector(companySelectors.getCompanyInfo);

  useEffect(() => {
    setSelected(companyInfo?.dateFormat);
  }, [companyInfo]);

  return (
    <ExModalWrapperStyled onClose={onClose} content={content}>
      <ExModalBodyStyled>
        <DateTimeWrapper>
          <DateTimeLabel>Select your preferred date and time</DateTimeLabel>
          <div>
            {dateVariants.map((format, index) => (
              <FormCheckbox
                type="radio"
                id={format}
                name="dateTimeFormat"
                key={format}
                label={format.toUpperCase()}
                value={index}
                checked={index === selected}
                onChange={changeHandler}
              />
            ))}
          </div>
        </DateTimeWrapper>
        <ExDialogFooter>
          <ExDialogActions>
            <ExButton variant="light" onClick={onClose}>
              Cancel
            </ExButton>
            <ExLoaderHandlerWrapper action={updateCompanyDefaultDateFormat}>
              <ExButton className="ml-3" onClick={onSubmitClickHandler}>
                Save
              </ExButton>
            </ExLoaderHandlerWrapper>
          </ExDialogActions>
        </ExDialogFooter>
      </ExModalBodyStyled>
    </ExModalWrapperStyled>
  );
};
