import { createGlobalStyle, css, keyframes } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .form-control{
    line-height: 1.2;
    padding: 0.55rem 0.8125rem;
  }
  .form-control:focus{
    color: #000;
    background-color: #fff;
    outline: 0;
    box-shadow: none;
  }
  .expedo-tooltip {
    &.bs-tooltip-bottom .arrow,
    &.bs-tooltip-auto[x-placement^=bottom] .arrow {
      top: 2px;

      &::before {
        border-bottom-color: var(--white);
      }
    }

    &.bs-tooltip-top .arrow,
    &.bs-tooltip-auto[x-placement^=top] .arrow {
      &::before {
        top: -2px;
        border-top-color: var(--white);
      }
    }

    & > .tooltip-inner {
      border-radius: 6px;
      line-height: 24px;
      padding: 2px 10px;
      max-width: 80vw;
      background-color: var(--white);
      color: var(--dark);
      border: 1px solid rgb(238, 238, 238);
      box-shadow: rgb(0 0 0 / 10%) 0 9px 50px;
      font-size: 14px;
    }

    &.show {
      opacity: 1;
    }
  }
  .ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .mw-0 {
    min-width: 0;
  }
  .expedo-popover {
    .popover-header {
      padding-bottom: 0.25rem;
      background-color: inherit;
      border-bottom: unset;
      line-height: 1.2;
      font-size: 0.85rem;
      font-weight: 500;
    }

    .popover-body {
      padding-top: 0.25rem;
      line-height: 1.2;
      font-size: 0.85rem;
    }
  }
  .no-top-padding {
    padding-top: 0 !important;
  }
  .overflow-initial {
    overflow: initial !important;
  }
`;

const delay = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

function getAnimations() {
  let str = '';
  for (let index = 0; index < 64; ++index) {
    str += `
      &:nth-child(${index}) {
        animation-delay: ${index * 30}ms;
      }
    `;
  }
  return str;
}

export const globalPageAnimation = css`
  animation-name: ${delay};
  animation-duration: 200ms;
  animation-timing-function: linear;
  animation-fill-mode: both;
  ${getAnimations()};
`;
