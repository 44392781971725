import { EmploymentType } from 'model/api-enums.constants';

export const OnCost = (dto: object) => ({
  country: null as OnCostCountry | null,
  id: (dto as any).onCostId as string,
  isDefault: false as boolean,
  onCostId: '' as string | undefined,
  onCostValues: [] as OnCostValue[],
  ...dto,
  employmentType: Object.fromEntries(
    (dto as { onCostValues: OnCostValue[] }).onCostValues.map((onCostValue) => [
      EmploymentType[onCostValue.employmentType],
      { ...onCostValue },
    ]),
  ) as Record<string, OnCostValue>,
});

export interface OnCostCountry {
  code?: string;
  name?: string;
}

export interface OnCostValue {
  employmentType: EmploymentType;
  onCostValue: number;
}
