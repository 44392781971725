import { createAction } from '@reduxjs/toolkit';

import { TalentPoolListState } from 'containers/TalentPoolLists/store';

import { TalentPool } from './models';

const TALENT_POOL_REMOVE = 'talentPool/remove';
export const TALENT_POOL_FETCH = 'talentPool/fetch';
export const TALENT_POOL_CREATE = 'talentPool/create';

export const createTalentPool = createAction(TALENT_POOL_CREATE);

export const removeTalentPool =
  createAction<{ id: TalentPool['id']; listId?: TalentPoolListState['id'] }>(TALENT_POOL_REMOVE);
