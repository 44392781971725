import React, { useEffect } from 'react';
import styled from 'styled-components';

import {
  candidateFormActions,
  CreateNewCandidateFormBody,
  validationSchemaCreateNewCandidate,
} from 'containers/CandidateForm';
import { SearchAutocomplete } from 'containers/CandidateForm/CandidateFormFormBody/CreateNewCandidateFormBody';
import { ExModalBodyStyled, ExModalBodyStyledWide } from 'containers/Modals/ModalsContent/Company/ExModalComponents';

import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { Form } from 'components/Form';
import { ExButton } from 'components/ui/ExButton';
import { ExDialogActions, ExDialogProps } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { candidateCreate } from 'store/entities/candidates/candidate.actions';
import { Candidate } from 'store/entities/candidates/models';
import { exModalHideAction } from 'store/modals/modals.actions';
import { ExModal } from 'store/modals/modals.interfaces';

type CreateNewCandidateProps = ExModal & ExDialogProps;

const CreateNewCandidateModalStyled = styled(ExModalBodyStyledWide)``;

const FormStyled = styled(Form)`
  margin: 0 10px;

  & > *:not(:last-child) {
    margin-bottom: 0;
  }
`;

const useCreateNewCandidateState = ({ id, modalProps }: CreateNewCandidateProps) => {
  const dispatch = useAppDispatch();

  const submitHandler = async (data: Partial<Candidate & { talentPools: Array<SearchAutocomplete> }>, _?: boolean) => {
    dispatch(candidateCreate(data));
  };

  const cancelHandler = () => {
    dispatch(exModalHideAction({ id }));
  };

  useEffect(() => {
    return () => {
      dispatch(candidateFormActions.resetState({}));
    };
  });

  return {
    id,
    submitHandler,
    defaultValues: modalProps?.formData,
    cancelHandler,
  } as const;
};

const CreateNewCandidate: React.FC<CreateNewCandidateProps> = (props) => {
  const { id, submitHandler, defaultValues, cancelHandler } = useCreateNewCandidateState(props);
  return (
    <CreateNewCandidateModalStyled>
      <ExModalBodyStyled>
        <FormStyled
          onSubmit={submitHandler}
          validationSchema={validationSchemaCreateNewCandidate}
          defaultValues={defaultValues}
        >
          <CreateNewCandidateFormBody modalId={id} />
          <ExDialogActions>
            <ExButton variant="light" onClick={cancelHandler}>
              Cancel
            </ExButton>
            <ExLoaderHandlerWrapper action={{ type: 'createCandidateFormData' }}>
              <ExButton className="ml-3" variant="primary" type="submit">
                Save
              </ExButton>
            </ExLoaderHandlerWrapper>
          </ExDialogActions>
        </FormStyled>
      </ExModalBodyStyled>
    </CreateNewCandidateModalStyled>
  );
};

export default styled(CreateNewCandidate)`
  .dialog {
    background-color: ${(props) => props.theme.colors.white};
  }

  .dialog-body {
    padding-bottom: 0;
  }
`;
