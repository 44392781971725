import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { unwrapResult } from '@reduxjs/toolkit';
import styled from 'styled-components';

import { AppFile } from 'model';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { applicantFormActions, applicantFormSelectors } from 'containers/ApplicantForm/ApplicantFormState';
import { ApplicantForm } from 'containers/ApplicantForm/ApplicantFormState/applicant-form.models';
import { formApplicantKey } from 'containers/ApplicantForm/ApplicantFormState/applicant-form.reducer';
import { loaderSelectors } from 'containers/Loader/store';

import { AppFileCard } from 'components/AppFileCard';
import { AppFileCardLoading } from 'components/AppFileCard/AppFileCardLoading';
import { AppFileCardNew } from 'components/AppFileCard/AppFileCardNew';
import { StepComponent, StepDescription } from 'components/StepForm';
import { ExCard } from 'components/ui/ExCard';
import { FILE_INPUT_ACCEPT_DOCUMENT, fileValidationRuleTypeDocument } from 'utils/files';
import { isDocumentFile } from 'utils/funcs';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useOnChangeFilewithValidation } from 'utils/hooks/useFileInputValidation';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getTranslate } from 'utils/i18utils';

import { createDocumentUploadLoaderId, uploadDocumentFiles } from 'store/app-files/documents/documents.effects';
import { documentsActions } from 'store/app-files/documents/documents.reducer';
import { documentsSelectors } from 'store/app-files/documents/index';
import { Applicant } from 'store/entities/applicants/models';

export interface NewApplicantSelectJobStepProps extends StepComponent {
  className?: string;
  formData: ApplicantForm;
}

const fileValidationOptions = [fileValidationRuleTypeDocument];

const NewApplicantSelectCoverLetterStep: React.FC<NewApplicantSelectJobStepProps> = ({ className, formData }) => {
  const { register, errors, setValue, formState } = useFormContext<Applicant>();
  const dispatch = useAppDispatch();
  const candidateId = formData.candidateId;
  const listName = formApplicantKey + candidateId;

  const memoSelectedItemsIds = useMemo(() => documentsSelectors.selectedItemsIds(), []);
  const selectedIds = useAppSelector(memoSelectedItemsIds, listName);

  const candidateDocuments = useAppSelector(applicantFormSelectors.selectReviewStep);

  const setValueConfig = useMemo(() => ({ shouldValidate: formState.isSubmitted }), [formState]);

  const isDocumentUploading = useAppSelector(loaderSelectors.isTaskActive, {
    taskId: createDocumentUploadLoaderId(candidateId),
  });

  const setSelected = useCallback(
    (id: string | undefined) => {
      dispatch(documentsActions.setSelected({ listName, selectedIds: id }));
    },
    [dispatch, listName],
  );

  useEffect(() => {
    register('documentId');
    const documentId = formData.documentId;
    if (documentId && !selectedIds) {
      setSelected(documentId);
      setValue('documentId', documentId, {
        shouldValidate: true,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onItemClick = (item: AppFile) => {
    if (isDocumentFile(item)) {
      setSelected(item.documentId);
      setValue('documentId', item.documentId, setValueConfig);
    }
  };

  const uploadHandler = useCallback(
    async (files: File[]) => {
      if (candidateId) {
        const formForFiles = new FormData();
        files.forEach((file) => formForFiles.append('document', file, file.name));
        const resultAction = await dispatch(
          uploadDocumentFiles({ candidateId, formData: formForFiles, preloader: true }),
        );
        const { success, data } = unwrapResult(resultAction);
        if (success) {
          setSelected(data.documentId);
          dispatch(alertsEffects.showSuccess({ message: 'Document successfully uploaded' }));
          setValue('documentId', data.documentId, setValueConfig);
          dispatch(applicantFormActions.addUploadedDocument(data));
        }
      }
    },
    [dispatch, candidateId, setSelected, setValue, setValueConfig],
  );

  const { onChangeFileWithValidation } = useOnChangeFilewithValidation({
    options: fileValidationOptions,
    withToasts: true,
    onSuccess: uploadHandler,
  });

  useEffect(() => {
    if (selectedIds) {
      setValue('documentId', selectedIds[0], setValueConfig);
    }
  }, [selectedIds]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const error = errors.documentId;
    if (error?.message) {
      dispatch(alertsEffects.showError({ message: error.message.toString() }));
    }
  }, [dispatch, errors]);

  const isDocumentSelected = useCallback(
    (documentId) => {
      return selectedIds && selectedIds.includes(documentId);
    },
    [selectedIds],
  );

  return (
    <div className={className}>
      <StepDescription>
        <StepDescription.Title>Step 4: Cover Letter</StepDescription.Title>
        <StepDescription.Description>{getTranslate('new_applicant.step_4.description')}</StepDescription.Description>
      </StepDescription>
      <ExCard title="Cover Letter">
        <div className="row">
          <div className="col-auto">
            <AppFileCardNew
              accept={FILE_INPUT_ACCEPT_DOCUMENT}
              disabled={isDocumentUploading}
              onChange={onChangeFileWithValidation}
            />
          </div>
          {isDocumentUploading && (
            <div className="col-auto">
              <AppFileCardLoading />
            </div>
          )}
          {!isDocumentUploading &&
            candidateDocuments.documents?.map((document) => (
              <div className="col-auto" key={document.documentId}>
                <AppFileCard
                  item={{ ...document, selected: isDocumentSelected(document.documentId) }}
                  onClick={onItemClick}
                  isCheckable={true}
                />
              </div>
            ))}
        </div>
      </ExCard>
    </div>
  );
};

export default styled(NewApplicantSelectCoverLetterStep)``;
