import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ApplicantStatusBadgeDinamicHiringPipeline } from 'containers/ApplicantLists/ApplicantStatusBadge';

import { TickBox } from 'components/ListView';
import { ExCardStyled } from 'components/ui/ExCard/ExCardComponents';
import { HoveredBoxShadowCard } from 'utils/styled/common';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

export const ApplicantCardV2Styled = styled(HoveredBoxShadowCard(ExCardStyled))`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px 15px 15px;
  height: 100%;
  border-radius: 6px;
`;

export const AvatarContainer = styled.div`
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
`;

export const ContentContainer = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ApplicantTitle = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
`;

export const ApplicantSubtitle = styled.p`
  font-size: ${getThemeFontSize('extraSmall')};
  margin: 0;
  margin-bottom: 5px;
`;

export const ApplicantFieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ApplicantFieldStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
  margin-bottom: 5px;
`;

export const ApplicantFieldTitle = styled.h5`
  font-size: 8px;
  line-height: 11px;
  color: ${(props) => props.theme.colors.gray};
  margin-bottom: 3px;
`;

export const ApplicantFieldValue = styled.p`
  margin: 0;
  font-size: ${getThemeFontSize('small')};
`;

export const CardHeader = styled.div<{ $selectable?: boolean }>`
  display: flex;
  margin-left: ${(props) => (props.$selectable ? '30px;' : '0px')};
`;

export const CardHeaderInfo = styled.div`
  padding: 0 5px 0 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

export const CardHeaderBadgesWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;
  > :not(:first-child) {
    margin-left: 0.5rem;
  }
`;

export const ApplicantRating = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  > :not(:first-child) {
    margin-left: 0.5rem;
  }
`;

export const ApplicantStatusBadge = styled(ApplicantStatusBadgeDinamicHiringPipeline)``;

export const ApplicantFullName = styled(Link)`
  font-size: ${getThemeFontSize('default')};
  font-weight: 700;
  margin: 0;
  margin-bottom: 4px;
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

export const ApplicantComments = styled.div`
  position: relative;
  top: 3px;
  margin: 0;
  font-size: ${getThemeFontSize('small')};
  display: flex;
  font-weight: 700;
  line-height: 1;
`;

export const ApplicantCardTick = styled(TickBox)`
  position: absolute;
  top: 20px;
  left: 15px;
`;
