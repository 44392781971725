import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as EditIcon } from 'assets/img/edit.svg';
import { ReactComponent as UploadIcon } from 'assets/img/upload.svg';

const candidateAvatarIconCss = css`
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  color: white;
  cursor: pointer;
`;
const CandidateUploadIcon = styled(UploadIcon)`
  ${candidateAvatarIconCss};
`;

const CandidateEditIcon = styled(EditIcon)`
  ${candidateAvatarIconCss};
`;

const CandidateAvatarIconComponent: React.FC<{
  candidateHasAvatar: boolean;
  onClick: (value: boolean) => void;
}> = ({ candidateHasAvatar, onClick }) => {
  const onClickHandler = useCallback(() => {
    onClick(candidateHasAvatar);
  }, [candidateHasAvatar, onClick]);

  return (
    <>
      {!candidateHasAvatar && <CandidateUploadIcon onClick={onClickHandler} />}
      {candidateHasAvatar && <CandidateEditIcon onClick={onClickHandler} />}
    </>
  );
};

const CandidateAvatarIcon = React.memo(CandidateAvatarIconComponent);

export { CandidateAvatarIcon };
