import React from 'react';
import styled from 'styled-components';

import { ExBadgeOutline } from 'components/ui/ExBadgeOutline';
import {
  BadgeTypes,
  CTX_REMAINING_BASIC_COMPONENT,
  useBadgeVariant,
  useJobDeadlineText,
} from 'components/ui/ExDeadlineBadge/hooks';

export type ExDeadlineBadgeProps = {
  className?: string;
  deadline?: string | null;
  badgeType?: BadgeTypes;
  prependIcon?: boolean;
};

const RemainingBadge = styled(ExBadgeOutline)`
  font-weight: 600;
  white-space: nowrap;
`;

const ExDeadlineBadge: React.FC<ExDeadlineBadgeProps> = ({
  deadline,
  badgeType = 'default',
  prependIcon,
  className,
}) => {
  const { deadlineDifferenceText } = useJobDeadlineText(deadline, { context: CTX_REMAINING_BASIC_COMPONENT });
  const badgeVariant = useBadgeVariant(deadline, badgeType);

  return (
    <RemainingBadge variant={badgeVariant} prependIcon={prependIcon} className={className}>
      {deadlineDifferenceText}
    </RemainingBadge>
  );
};

export { ExDeadlineBadge };
