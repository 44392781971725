import React from 'react';
import styled from 'styled-components';

import { ReactComponent as IconInfo } from 'assets/img/redesign/icons/info.svg';

import { ExTooltip } from 'components/ui/ExTooltip/ExTooltip';
import { ExTooltipProps } from 'components/ui/ExTooltip/types';

export const ExIconInfo = styled(IconInfo)<{ variant?: ExTooltipProps['variant'] }>`
  fill: transparent;
  color: ${props => (props.variant ? props.theme.colors[props.variant] : 'inherit')};
`;

export const ExInfoWithTooltip = ({ text, variant = 'dark', tooltipPosition = 'top', style }: ExTooltipProps) => {
  return (
    <>
      <ExTooltip text={text} variant={variant} tooltipPosition={tooltipPosition}>
        <ExIconInfo variant={variant} style={style} />
      </ExTooltip>
    </>
  );
};
