import { useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { ExRoutes, ExRoutesNames, ExRoutesPathMap } from 'router/routes';
import isEmpty from 'lodash/isEmpty';

import { AppRouterParams } from 'model/router';

import { historyManagerSelectors } from 'modules/HistoryManager/redux';
import { getGlobalGetState } from 'utils/globalStore';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { jobAdSelectors } from 'store/entities/job-ads/selectors';
import { jobBoardSelectors } from 'store/entities/job-boards/selectors';
import { jobsSelectors } from 'store/entities/jobs/selectors';
import { talentPoolSelectors } from 'store/entities/talent-pools/selectors';

type EntityTitlesProps = {
  jobId?: string;
  jobAdId?: string;
  jobBoardId?: string;
  candidateId?: string;
  talentPoolId?: string;
};

const entitySelectorMap = {
  jobId: jobsSelectors.getById,
  jobAdId: jobAdSelectors.getById,
  jobBoardId: jobBoardSelectors.selectById,
  candidateId: () => ({}),
  talentPoolId: talentPoolSelectors.getById,
};

const entityFieldSelectorMap = {
  jobId: (job) => job?.jobName,
  jobAdId: (jobAd) => jobAd?.jobAdName ?? jobAd?.name ?? jobAd?.jobName,
  jobBoardId: (jobBoard) => jobBoard?.name,
  candidateId: () => 'Candidate',
  talentPoolId: (talentPool) => talentPool?.name,
};

const entityPagePathnameMap = {
  jobId: (jobId) => ExRoutes.jobPage({ jobId }),
  jobAdId: (jobAdId) => ExRoutes.jobAdPage({ jobAdId }),
  jobBoardId: (jobBoardId) => ExRoutes.jobBoardAds({ jobBoardId }),
  candidateId: (candidateId) => ExRoutes.candidatePage({ candidateId }),
  talentPoolId: (talentPoolId) => ExRoutes.talentPoolPage({ talentPoolId }),
};

const getEntityTitles = (params: AppRouterParams): EntityTitlesProps => {
  if (isEmpty(params)) {
    return {};
  }

  const state = getGlobalGetState();

  return Object.entries(params).reduce((acc, [paramName, paramValue]) => {
    const selector = entitySelectorMap[paramName];
    const fieldSelector = entityFieldSelectorMap[paramName];

    if (!selector || !fieldSelector) {
      return acc;
    }

    const entity = selector(state, paramValue);
    const field = fieldSelector(entity);

    if (field) {
      acc[paramName] = field;
    }

    return acc;
  }, {});
};

export const selectEntityTitleByOrder = (entityTitles?: EntityTitlesProps): string => {
  if (isEmpty(entityTitles)) {
    return '';
  }

  const {
    jobId: jobTitle,
    jobAdId: jobAdTitle,
    jobBoardId: jobBoardTitle,
    candidateId: candidateTitle,
    talentPoolId: talentPoolTitle,
  } = entityTitles!;

  return jobTitle || jobAdTitle || jobBoardTitle || candidateTitle || talentPoolTitle || '';
};

export const getRouteMetaByPathname = (pathname?: string) => {
  if (!pathname) {
    return;
  }

  let matchResult;

  const route = Object.entries(ExRoutesPathMap).find(([_key, value]) => {
    const path = value?.path;
    const match = matchPath(pathname, {
      exact: true,
      path,
    });

    matchResult = match;

    return match !== null;
  });

  if (!route) {
    return;
  }

  const routeParams = matchResult?.params;

  const routeEntityTitles = getEntityTitles(routeParams);

  const selectedEntityTitle = selectEntityTitleByOrder(routeEntityTitles);

  return {
    routeName: route[0] as ExRoutesNames,
    route: route[1],
    routeParams,
    routeEntityTitles,
    selectedEntityTitle,
    matchResult,
  } as const;
};

export const useFallbackToEntityPage = (pathname?: string) => {
  if (!pathname) {
    return;
  }

  const routeMeta = getRouteMetaByPathname(pathname);

  if (!routeMeta) {
    return;
  }

  const { routeParams, routeEntityTitles } = routeMeta;

  if (isEmpty(routeParams)) {
    return;
  }

  return Object.entries(routeParams).reduce((acc, [paramName, paramValue]) => {
    const title = routeEntityTitles[paramName];
    const pathnameSelector = entityPagePathnameMap[paramName];

    if (!title || !pathnameSelector) {
      return acc;
    }

    acc[paramName] = {
      title,
      pathname: pathnameSelector(paramValue),
    };

    return acc;
  }, {});
};

/**
 * Hook that returns from the history queue the previous element due to route name that we pass.
 *
 * @example
 * history: [{routeName: 'dashBoard', ...}, {routeName:'jobPage', ...}];
 *
 * In this case `usePreviousHistoryEntity('jobPage')` will returns entity with routeName === 'dashboard'
 *
 * @param {ExRoutesNames} routeName
 */
export function usePreviousHistoryEntity(routeName: ExRoutesNames) {
  const previousEntity = useAppSelector(historyManagerSelectors.selectPreviousAllowedByRouteName, routeName);

  const { pathname, search } = previousEntity?.location || {};

  return useMemo(() => {
    if (!pathname) {
      return { title: undefined, pathname: undefined, entityTitles: undefined } as const;
    }

    const fullPath = search ? `${pathname}${search}` : pathname;

    const routeMeta = getRouteMetaByPathname(fullPath);

    if (!routeMeta) {
      return { title: undefined, pathname: undefined, entityTitles: undefined } as const;
    }

    const { route, routeEntityTitles } = routeMeta;

    const title = route?.selectTitle();

    if (search) {
      return { title, pathname: fullPath, entityTitles: routeEntityTitles } as const;
    }

    return { title, pathname, entityTitles: routeEntityTitles } as const;
  }, [pathname, search]);
}
