import styled from 'styled-components/macro';

import { CandidateAvatar } from 'components/CandidateAvatar';
import { ExSizedBox } from 'components/ui/ExSizedBox';
import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';
import { getThemeColor } from 'utils/styled/getThemeColor';

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Message = styled.div`
  text-align: center;
  padding: 1rem 0;
  border-top-left-radius: ${getThemeBorderProps('borderRadiusSm')};
  border-top-right-radius: ${getThemeBorderProps('borderRadiusSm')};
  margin-bottom: 0 !important;
`;

export const CandidatesWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: auto;
  border-bottom-left-radius: ${getThemeBorderProps('borderRadiusSm')};
  border-bottom-right-radius: ${getThemeBorderProps('borderRadiusSm')};
`;

export const CandidateAvatarStyled = styled(CandidateAvatar)`
  width: 40px;
  height: 40px;
  margin: 0 7px 14px;
  cursor: default;
`;

export const ExSizedBoxStyled = styled(ExSizedBox)`
  position: sticky;
  width: 100%;
  z-index: 10;
  background: ${getThemeColor('bodyBackgroundColor')};
`;

export const ExSizedBoxTopStyled = styled(ExSizedBoxStyled)`
  top: 0;
  background: linear-gradient(
    180deg,
    ${getThemeColor('bodyBackgroundColor')} 0%,
    ${getThemeColor('bodyBackgroundColor')} 40%,
    transparent 90%
  );
`;

export const ExSizedBoxBottomStyled = styled(ExSizedBoxStyled)`
  bottom: 0;
  background: linear-gradient(
    0deg,
    ${getThemeColor('bodyBackgroundColor')} 0%,
    ${getThemeColor('bodyBackgroundColor')} 40%,
    transparent 90%
  );
`;
