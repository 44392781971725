import styled from 'styled-components';

import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

const PageTitle = styled.span`
  font-size: ${getThemeFontSize('extraLarge')};
  line-height: initial;
`;

export default PageTitle;
