import { JobBoardItemResponse } from 'api-endpoints/job-board';

import { JobBoard, jobBoardToState } from 'store/entities/job-boards';

interface MapAJobBoardsReturnValue {
  jobBoardIds: Array<JobBoard['jobBoardId']>;
  jobBoards: Array<JobBoard>;
}

export const mapJobBoards = (response: Array<JobBoardItemResponse>): MapAJobBoardsReturnValue => {
  return response.reduce(
    (acc, { jobBoardId, ...restJobBoard }) => ({
      jobBoardIds: [...acc.jobBoardIds, jobBoardId],
      jobBoards: [...acc.jobBoards, jobBoardToState({ jobBoardId, ...restJobBoard, id: jobBoardId })],
    }),
    {
      jobBoardIds: [],
      jobBoards: [],
    } as MapAJobBoardsReturnValue,
  );
};
