import React from 'react';
import styled from 'styled-components';

import { rbacConstants } from 'containers/Auth/AuthMatrix';
import { RBAC } from 'containers/Auth/components/RBAC';

import { MenuItem } from 'components/ActionsDropdown';
import { Ellipsis } from 'components/Ellipsis';
import { ExActionButton, ExActionButtonToggle } from 'components/ui/ExActionButton';
import { CardAction } from 'components/ui/ExCard/components';
import { ExVisible } from 'components/ui/ExVisible';
import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';
import { getThemeColor } from 'utils/styled/getThemeColor';

import { EducationCardItemProps } from './EducationCardItemProps';
import { useEducationDateRange } from './hooks';

export const EducationCardItemStyled = styled.div`
  background-color: ${getThemeColor('bodyBackgroundColor')};
  border-radius: ${getThemeBorderProps('borderRadius')};
  padding: 20px;
  line-height: 1.6;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const TitleWrapper = styled.div`
  padding-right: 32px;
`;

const ExActionButtonStyled = styled(ExActionButton)`
  ${ExActionButtonToggle} {
    background-color: transparent;
  }
`;

export const EducationCardItem: React.FC<EducationCardItemProps> = ({
  className,
  item,
  onEditClick,
  onDeleteClick,
  isEditable,
}) => {
  const dateRange = useEducationDateRange(item);

  const menuItems: MenuItem[] = [
    {
      label: 'Edit',
      title: 'Edit education',
      action: () => onEditClick instanceof Function && onEditClick(item),
    },
    {
      label: 'Delete',
      action: () => onDeleteClick instanceof Function && onDeleteClick(item),
      title: 'Delete education',
    },
  ];

  return (
    <EducationCardItemStyled className={className}>
      <RBAC feature={rbacConstants.candidates.editEducation}>
        <ExVisible visible={isEditable}>
          <CardAction>
            <ExActionButtonStyled buttonId="candidate-education-dropdown" menuItems={menuItems} dropDirection="down" />
          </CardAction>
        </ExVisible>
      </RBAC>
      <TitleWrapper>
        <Ellipsis>
          <strong>{item.organization}</strong>
        </Ellipsis>
      </TitleWrapper>
      <div>{dateRange}</div>
      <Ellipsis>{item.name}</Ellipsis>
    </EducationCardItemStyled>
  );
};
