import { useCallback } from 'react';

import { InterviewsTabProps } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/Tabs/InterviewsTab/InterviewsTabProps';

import { useApplicantInterviewListQuery } from 'components/ApplicantInterview/ApplicantInterview.api';
import { findNewOrderDirection } from 'utils/api';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { applicantInterviewTabListUiSlice } from 'store/ui/applicants/applicant-interview-tab-list/applicant-interview-tab-list.reducer';
import { applicantInterviewTabListSelectors } from 'store/ui/applicants/applicant-interview-tab-list/applicant-interview-tab-list.selectors';

export const useInterviewsTabState = ({ className }: InterviewsTabProps) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(applicantInterviewTabListSelectors.filters);

  const pageNo = filters.pageNo;
  const orderBy = filters.orderBy;
  const orderDir = filters.orderDirection === 1 ? 'Ascending' : 'Descending';

  const { data, isFetching } = useApplicantInterviewListQuery(filters, {
    selectFromResult: ({ data: innerData, ...rest }) => ({
      ...rest,
      data: { ...innerData, items: innerData ? innerData.ids.map((id) => innerData.entities[id]!) : [] },
    }),
    skip: !filters.jobApplicantId,
  });

  const pageCount = data.pageCount || 0;
  const items = data.items;

  const onPaginationClick = useCallback(
    (newPageNo) => {
      dispatch(applicantInterviewTabListUiSlice.actions.updateState({ filters: { ...filters, pageNo: newPageNo } }));
    },
    [dispatch, filters],
  );

  const onSortClick = useCallback(
    (value) => {
      const orderDirection = findNewOrderDirection(filters, value);
      dispatch(
        applicantInterviewTabListUiSlice.actions.updateState({
          filters: {
            ...filters,
            ...value,
            orderDirection,
          },
        }),
      );
    },
    [dispatch, filters],
  );

  return {
    className,
    items,
    pageNo,
    pageCount,
    orderBy,
    orderDir,
    onPaginationClick,
    onSortClick,
    isFetching,
  } as const;
};
