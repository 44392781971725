import { TalentPool, TalentPoolResponseUnion, talentPoolToState } from 'store/entities/talent-pools';

interface MapTalentPoolsReturnValue {
  talentPoolIds: Array<TalentPool['id']>;
  talentPools: Array<TalentPool>;
}

export const mapTalentPools = (response: Array<TalentPoolResponseUnion>): MapTalentPoolsReturnValue => {
  return response.map(talentPoolToState).reduce<MapTalentPoolsReturnValue>(
    (acc, talentPool) => ({
      talentPoolIds: [...acc.talentPoolIds, talentPool.id],
      talentPools: [...acc.talentPools, talentPool],
    }),
    {
      talentPoolIds: [],
      talentPools: [],
    },
  );
};

export const mapTalentPool = (response: TalentPoolResponseUnion): TalentPool => {
  return talentPoolToState(response);
};
