import { UseFormMethods } from 'react-hook-form';
import styled from 'styled-components/macro';

import { ReactComponent as IconAlert } from 'assets/img/triangle-alert.svg';

type ApplicantFormScreeningQuestionsErrorMessageProps = {
  show: boolean;
  errors?: UseFormMethods['errors'];
};

const ErrorMessageField = styled('div')`
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const ErrorMessageText = styled('span')`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: #d92d20;
`;

export const ApplicantFormScreeningQuestionsErrorMessage = ({
  show,
  errors,
}: ApplicantFormScreeningQuestionsErrorMessageProps) => {
  if (!show) {
    return null;
  }

  return (
    <ErrorMessageField>
      <IconAlert />
      <ErrorMessageText>{errors?.message}</ErrorMessageText>
    </ErrorMessageField>
  );
};

type ApplicantFormScreeningQuestionsControlContainerProps = {
  className?: string;
  children?: any;
  show: boolean;
  errors?: UseFormMethods['errors'];
};

const ControlContainer = styled('div')`
  position: relative;
  padding-bottom: 24px;

  ${ErrorMessageField} {
    position: absolute;
    bottom: 0;
  }
`;

export const ApplicantFormScreeningQuestionsControlContainer = ({
  className,
  children,
  show,
  errors,
}: ApplicantFormScreeningQuestionsControlContainerProps) => {
  return (
    <ControlContainer className={className}>
      {children}
      <ApplicantFormScreeningQuestionsErrorMessage show={show} errors={errors} />
    </ControlContainer>
  );
};
