import { QuestionType } from 'model/api-enums.constants';

import {
  ApplicantFormScreeningQuestionCheckBox,
  ScreeningQuestionCheckBox,
} from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionCheckBox';
import {
  ApplicantFormScreeningQuestionFileUpload,
  ScreeningQuestionFileUpload,
} from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionFileUpload';
import {
  ApplicantFormScreeningQuestionSelectBox,
  ScreeningQuestionSelectBox,
} from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionSelectBox';
import {
  ApplicationFormScreeningQuestionTextArea,
  ScreeningQuestionTextArea,
} from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionTextArea';
import {
  ApplicantFormScreeningQuestionTextField,
  ScreeningQuestionTextField,
} from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionTextField';
import {
  ApplicantFormScreeningQuestionYesNo,
  ScreeningQuestionYesNo,
} from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionYesNo';

import { EnumLiteralsOf } from 'utils/funcs';

const applicantFormScreeningQuestionComponents = {
  [QuestionType.YesNo]: ApplicantFormScreeningQuestionYesNo,
  [QuestionType.TextField]: ApplicantFormScreeningQuestionTextField,
  [QuestionType.TextArea]: ApplicationFormScreeningQuestionTextArea,
  [QuestionType.FileUpload]: ApplicantFormScreeningQuestionFileUpload,
  [QuestionType.SelectBoxList]: ApplicantFormScreeningQuestionSelectBox,
  [QuestionType.CheckBoxList]: ApplicantFormScreeningQuestionCheckBox,
};

const screeningQuestionComponents = {
  [QuestionType.YesNo]: ScreeningQuestionYesNo,
  [QuestionType.TextField]: ScreeningQuestionTextField,
  [QuestionType.TextArea]: ScreeningQuestionTextArea,
  [QuestionType.FileUpload]: ScreeningQuestionFileUpload,
  [QuestionType.SelectBoxList]: ScreeningQuestionSelectBox,
  [QuestionType.CheckBoxList]: ScreeningQuestionCheckBox,
};

export const useScreeningQuestionViewComponent = (type?: EnumLiteralsOf<typeof QuestionType>, context?: string) => {
  if (type === undefined) {
    return null;
  }

  if (context === 'applicantFormScreeningQuestions') {
    return applicantFormScreeningQuestionComponents[type];
  }

  return screeningQuestionComponents[type];
};
