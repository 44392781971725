import { Action } from 'redux';
import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects';

import * as candidateApi from 'api-endpoints/candidate';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { getTranslate } from 'utils/i18utils';
import { fetchEntity, invokeApiCall, invokeExModal, prepareExModalChannel, ReturnData, worker } from 'utils/sagas';

import { exModalHideAction } from 'store/modals/modals.actions';
import { ModalGeneralResult } from 'store/modals/modals.interfaces';

import { CreateCandidateEducationInnerRequestParams, UpdateCandidateEducationInnerRequestParams } from './api/requests';
import * as candidateEducationActions from './candidate-education.actions';
import { candidateEducationsSlice } from './candidate-education.reducer';

//--------------------------------------------------------------------------------------------------

const apiGetCandidateEducations = fetchEntity.bind(
  null,
  candidateEducationActions.candidateEducationHandler,
  candidateApi.getCandidateEducations,
);
const apiCreateCandidateEducations = fetchEntity.bind(
  null,
  candidateEducationActions.candidateEducationCreateHandler,
  candidateApi.createCandidateEducations,
);
const apiUpdateCandidateEducations = fetchEntity.bind(
  null,
  candidateEducationActions.candidateEducationUpdateHandler,
  candidateApi.updateCandidateEducations,
);
const apiDeleteCandidateEducations = fetchEntity.bind(
  null,
  candidateEducationActions.candidateEducationDeleteHandler,
  candidateApi.deleteCandidateEducations,
);

//--------------------------------------------------------------------------------------------------

const apiGetCandidateEducationCancelable = worker.bind(
  null,
  candidateEducationActions.candidateEducationCancel,
  apiGetCandidateEducations,
);
const apiCreateCandidateEducationCancelable = worker.bind(
  null,
  candidateEducationActions.candidateEducationCreateCancel,
  apiCreateCandidateEducations,
);
const apiUpdateCandidateEducationCancelable = worker.bind(
  null,
  candidateEducationActions.candidateEducationUpdateCancel,
  apiUpdateCandidateEducations,
);
const apiDeleteCandidateEducationCancelable = worker.bind(
  null,
  candidateEducationActions.candidateEducationDeleteCancel,
  apiDeleteCandidateEducations,
);

//--------------------------------------------------------------------------------------------------

function* candidateEducationCreateWorker(action: Action) {
  const { modalId, sagaChannel } = yield prepareExModalChannel();
  yield fork(invokeExModal, {
    channel: sagaChannel,
    modalId,
    modalType: ModalsTypeKey.editCandidateEducation,
  });

  while (true) {
    yield call(stopLoader, action);
    const { confirm, cancel }: ModalGeneralResult = yield take(sagaChannel);

    if (cancel || !confirm) {
      return;
    }

    yield call(startLoader, action);

    const requestPayload = confirm.payload.modalResult as CreateCandidateEducationInnerRequestParams;
    const { data, errorData, message }: ReturnData<typeof candidateApi.createCandidateEducations> = yield call(
      invokeApiCall,
      candidateApi.createCandidateEducations,
      requestPayload,
    );

    if ([errorData, message].some(Boolean)) {
      continue;
    }

    if (data) {
      yield all([
        put(
          candidateEducationsSlice.actions.upsertOne({
            ...requestPayload.data,
            ...data,
            educationId: data.educationId,
          }),
        ),
        put(exModalHideAction({ id: modalId })),
        put(
          candidateEducationActions.candidateEducationRequest({
            urlParams: { candidateId: requestPayload.urlParams.candidateId },
          }),
        ),
        put(alertsEffects.showSuccess({ message: getTranslate('education_create_success') })),
        call(stopLoader, action),
      ]);
    }

    break;
  }
}

function* candidateEducationUpdateWorker(action: Action) {
  const { modalId, sagaChannel } = yield prepareExModalChannel();
  yield fork(invokeExModal, {
    channel: sagaChannel,
    modalId,
    modalType: ModalsTypeKey.editCandidateEducation,
  });
  while (true) {
    yield call(stopLoader, action);
    const { confirm, cancel }: ModalGeneralResult = yield take(sagaChannel);

    if (cancel || !confirm) {
      return;
    }

    yield call(startLoader, action);

    const requestPayload = confirm.payload.modalResult as UpdateCandidateEducationInnerRequestParams;
    const { errorData, message }: ReturnData<typeof candidateApi.updateCandidateEducations> = yield call(
      invokeApiCall,
      candidateApi.updateCandidateEducations,
      requestPayload,
    );

    if ([errorData, message].some(Boolean)) {
      continue;
    }

    yield all([
      put(
        candidateEducationsSlice.actions.updateOne({
          changes: { ...requestPayload.data },
          id: requestPayload.urlParams.educationId!,
        }),
      ),
      put(exModalHideAction({ id: modalId })),
      put(
        candidateEducationActions.candidateEducationRequest({
          urlParams: { candidateId: requestPayload.urlParams.candidateId },
        }),
      ),
      put(alertsEffects.showSuccess({ message: getTranslate('education_update_success') })),
      call(stopLoader, action),
    ]);
    break;
  }
}

function* candidateEducationRemoveWorker(
  action: ReturnType<typeof candidateEducationActions.candidateEducationRemove>,
) {
  const candidateEducation = action.payload;
  const { modalId, sagaChannel } = yield prepareExModalChannel();
  yield fork(invokeExModal, {
    channel: sagaChannel,
    modalConfig: {
      content: {
        buttonOk: 'Remove',
        buttonOkVariant: 'primary',
        message: `Are you sure you want to remove education ${candidateEducation.name}?`,
        title: 'Confirmation',
        withActions: true,
        withTitle: true,
      },
    },
    modalId,
    modalType: ModalsTypeKey.confirmModal,
  });

  while (true) {
    yield call(stopLoader, action);
    const { confirm, cancel }: ModalGeneralResult = yield take(sagaChannel);

    if ([cancel, !confirm].some(Boolean)) {
      return;
    }

    yield call(startLoader, action);
    const { errorData, message } = yield call(invokeApiCall, candidateApi.deleteCandidateEducations, {
      urlParams: {
        educationId: candidateEducation.educationId,
        candidateId: candidateEducation.candidateId,
      },
    });

    if ([errorData, message].some(Boolean)) {
      continue;
    }

    yield all([
      put(candidateEducationsSlice.actions.removeOne(candidateEducation.educationId)),
      put(alertsEffects.showSuccess({ message: getTranslate('education_delete_success') })),
      put(exModalHideAction({ id: modalId })),
      call(stopLoader, action),
    ]);

    break;
  }
}

export function* candidateEducationSagas() {
  yield takeLatest(candidateEducationActions.candidateEducationRequest, apiGetCandidateEducationCancelable);
  yield takeLatest(candidateEducationActions.candidateEducationCreateRequest, apiCreateCandidateEducationCancelable);
  yield takeLatest(candidateEducationActions.candidateEducationUpdateRequest, apiUpdateCandidateEducationCancelable);
  yield takeLatest(candidateEducationActions.candidateEducationDeleteRequest, apiDeleteCandidateEducationCancelable);
  yield takeLatest(candidateEducationActions.candidateEducationCreate, candidateEducationCreateWorker);
  yield takeLatest(candidateEducationActions.candidateEducationUpdate, candidateEducationUpdateWorker);
  yield takeLatest(candidateEducationActions.candidateEducationRemove, candidateEducationRemoveWorker);
}
