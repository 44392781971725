import { createSelector } from 'reselect';

import { Country } from 'model';

import { compareCountryByCountryCode } from 'utils/funcs';

import { countryAdapter, useCountryQuery } from 'store/dictionary/dictionary.api';

export const useCountryQuerySelectors = () => {
  const query = useCountryQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  const adapterSelectors = countryAdapter.getSelectors(() => query.data ?? countryAdapter.getInitialState());

  const selectIdByExpedoCode2 = createSelector(
    adapterSelectors.selectAll,
    (_, expedoCode2: string) => expedoCode2,
    (countries: Country[], expedoCode2) => {
      const country = countries.find(compareCountryByCountryCode(expedoCode2, 'expedoCode2'));
      return country?.countryId!;
    },
  );

  const selectIdByIdibuCode2 = createSelector(
    adapterSelectors.selectAll,
    (_, idibuCode2: string) => idibuCode2,
    (countries, idibuCode2) => {
      const country = countries.find(compareCountryByCountryCode(idibuCode2, 'idibuCode2'));
      return country?.countryId!;
    },
  );

  const selectors = {
    ...adapterSelectors,
    selectByExpedoCode2: createSelector((root) => root, selectIdByExpedoCode2, adapterSelectors.selectById),
    selectByIdibuCode2: createSelector((root) => root, selectIdByIdibuCode2, adapterSelectors.selectById),
    selectIdByExpedoCode2,
  };

  return {
    ...query,
    selectors,
  };
};
