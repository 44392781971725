import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components/macro';

import { PipelineStageType } from 'model/api-enums.constants';

import { ApplicantFilterHeader } from 'containers/ApplicantLists/ApplicantFilterComponents';

import { useApplicantFiltersPipelineStagesForJob } from 'utils/hooks/job';

import { ApplicantFilterOpt } from 'store/entities/applicants';
import { RootState } from 'store/rootReducer';

import { ApplicantListComponentProps } from './ApplicantListProps';
import { applicantListActions, applicantListSelectors } from './store';

const ApplicantListFilterPure: React.FC<ApplicantListComponentProps & PropsFromRedux> = ({
  updateFilters,
  updatePage,
  filters,
  listId,
  className,
}) => {
  const handleStatusChange = (selected: ApplicantFilterOpt<PipelineStageType | undefined> | null) => {
    const value = selected?.value;
    updatePage({ id: listId, pageNo: 0 });
    updateFilters({ id: listId, filters: { pipelineStages: value } });
  };

  const options = useApplicantFiltersPipelineStagesForJob();
  const filterValue = options?.find((option) => option.value === filters.pipelineStages);

  return (
    <div className={className}>
      <ApplicantFilterHeader options={options} value={filterValue} handleStatusChange={handleStatusChange} />
    </div>
  );
};

const mapDispatch = {
  updatePage: applicantListActions.updatePage,
  updateFilters: applicantListActions.updateFilters,
};

const mapState = (state: RootState, own: ApplicantListComponentProps) => ({
  filters: applicantListSelectors.getFilters(state, { id: own.listId }),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const ApplicantListFilter = styled(connector(ApplicantListFilterPure))`
  display: flex;

  .list-filter-search {
    width: 205px;

    .applicant-status-select {
      width: 100%;
      margin-bottom: 0;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
`;
