import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from 'router';
import styled from 'styled-components';

import { jobBoardListActions } from 'containers/JobBoardLists/store';
import { jobBoardFormActions, jobBoardFormSelectors } from 'containers/JobBoardsForm/state';

import { ReactComponent as Image } from 'assets/img/successful-subscribed-page.svg';

import { ArrowForward } from 'components/StepForm';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { useLoaderSubscription } from 'modules/LoaderManager/react';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';

import { JobBoard, jobBoardActions, jobBoardSelectors } from 'store/entities/job-boards';
import { RootState } from 'store/rootReducer';

const JobBoardFinalPagePure: React.FC<{ className?: string }> = ({ className }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const formData = useSelector(jobBoardFormSelectors.selectFormData);
  const jobBoard = useSelector<RootState>((state) => jobBoardSelectors.selectById(state, formData.jobBoardId!)) as
    | JobBoard
    | undefined;
  useEffect(() => {
    if (!jobBoard && formData.jobBoardId) {
      dispatch(jobBoardActions.get({ jobBoardId: formData.jobBoardId }));
    }
    dispatch(jobBoardFormActions.toggleShowSteps({ showSteps: false }));
    return () => {
      dispatch(jobBoardFormActions.toggleShowSteps({ showSteps: true }));
      dispatch(jobBoardFormActions.clearForm({}));
      dispatch(jobBoardListActions.resetState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading: loading } = useLoaderSubscription(jobBoardFormActions.saveSubscription);

  useEffect(() => {
    if (!loading && !formData.jobBoardId) {
      history.push(Routes.jobBoardsFormSelectBoard);
    }
  }, [loading, formData.jobBoardId, formData, history]);

  const linkToAnotherJob = ['', Routes.jobBoards, Routes.new, Routes.jobBoardsFormSelectBoard].join('/');

  return (
    <div className={className}>
      <h3 className="mb-3">Subscribed to {jobBoard?.name}</h3>
      <div>
        <Image />
      </div>
      <div className="message">You have completed the steps required to subscribe to a job board.</div>
      <h4 className="mb-4">What’s Next?</h4>
      <div className="d-flex justify-content-center">
        <div className="col-12 p-0">
          <ExButton variant="success" forwardedAs={Link} to={linkToAnotherJob}>
            Subscribe to another job board {<ArrowForward className="ml-3" />}
          </ExButton>
        </div>
      </div>
    </div>
  );
};

export const JobBoardFinalPage = styled(JobBoardFinalPagePure)`
  margin: 4% auto 0;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 792px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 473px;
  border-radius: ${getThemeBorderProps('borderRadius')};
  border: 1px solid ${(props) => props.theme.border.borderColor};
  background: white;

  .message {
    font-size: 20px;
    margin-bottom: 60px;
  }
`;
