import React from 'react';
import styled from 'styled-components';

import { AppFile } from 'model';

import { AppFileCardActions, AppFileCardBasic, FileLoader } from 'components/AppFileCard/AppFileCardBasic';
import { Ellipsis } from 'components/Ellipsis';
import { EllipsisWithTooltip } from 'components/EllipsisWithTooltip';
import { FileExtension } from 'components/FileExtension';
import { Spinner } from 'components/Spinner';
import { Tick } from 'components/Tick';
import RemoveIcon from 'components/ui/RemoveIcon/RemoveIcon';

export type InputFile = AppFile & { selected?: boolean };
export interface AppFileCardProps {
  className?: string;
  item: InputFile | null;
  onClick?: (item: InputFile, event: React.MouseEvent<HTMLDivElement>) => void;
  onDelete?: (item: InputFile, event: React.MouseEvent<HTMLElement>) => void;
  isCheckable?: boolean;
  isDownloadable?: boolean;
}

export const useAppFileCardState = ({
  className,
  item,
  onClick,
  onDelete,
  isCheckable,
  isDownloadable,
}: AppFileCardProps) => {
  const [hovered, setHovered] = React.useState(false);
  const isDeletable = Boolean(onDelete);
  const isProcessing = Boolean(item?.processing);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const itemClickHandler: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (item) {
      onClick && onClick(item, e);
    }
  };

  const itemDeleteHandler: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (item) {
      onDelete && onDelete(item, e);
    }
  };

  const itemDownloadHandler: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (item && isDownloadable) {
      onClick && onClick(item, e);
    }
  };

  return {
    className,
    item,
    hovered,
    isCheckable,
    isDeletable,
    isProcessing,
    handleMouseEnter,
    handleMouseLeave,
    itemClickHandler,
    itemDeleteHandler,
    itemDownloadHandler,
  };
};

const AppFileCardBase: React.FC<AppFileCardProps> = (props) => {
  const {
    className,
    item,
    hovered,
    isCheckable,
    isDeletable,
    isProcessing,
    handleMouseEnter,
    handleMouseLeave,
    itemClickHandler,
    itemDeleteHandler,
    itemDownloadHandler,
  } = useAppFileCardState(props);

  if (!item) {
    return null;
  }

  return (
    <AppFileCardBasic
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`${className} ${item.selected ? 'selected' : ''}`}
      onClick={itemDownloadHandler}
    >
      {isProcessing && (
        <FileLoader>
          <Spinner />
        </FileLoader>
      )}
      <FileExtension fileExtension={item.filetype} />
      <EllipsisWithTooltip label={item.filename as string}>
        <Ellipsis>{item.filename}</Ellipsis>
      </EllipsisWithTooltip>
      <AppFileCardActions>
        {isDeletable && <RemoveIcon hovered={hovered} onClick={itemDeleteHandler} />}
        {isCheckable && (
          <Tick
            hovered={hovered}
            className={`app-file-card__action-item ${item.selected ? 'selected' : ''}`}
            onClick={itemClickHandler}
          />
        )}
      </AppFileCardActions>
    </AppFileCardBasic>
  );
};

export const AppFileCard = styled(AppFileCardBase)`
  &:hover {
    filter: grayscale(0);
    border-color: ${(p) => p.theme.border.borderColorInner};
  }

  &.selected {
    border-color: ${(p) => p.theme.colors.primary};
    filter: grayscale(0);
  }

  ${Ellipsis} {
    max-width: 90%;
  }

  ${FileExtension} {
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }

  .app-file-card__action-item {
    &:not(:first-child) {
      margin-left: 5px;
    }
  }
`;
