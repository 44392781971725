export const DictionaryEndpoints = {
  SearchLocationsFunc: '/api/company/{companyId}/dictionary/find-location',
};

export const DictionaryEndpointsForApi = {
  SearchCountriesFunc: '/dictionary/country',
  SearchCountriesForCompanyFunc: '/dictionary/country',
  SearchCurrenciesFunc: '/dictionary/currency',
  SearchIndustriesFunc: '/dictionary/industry',
  SearchIndustriesForCompanyFunc: '/dictionary/industry',
  SearchSectorsFunc: '/dictionary/sector',
  SearchCategoriesFunc: '/position/category',
  GetCompanyPositionCategoriesFunc: '/position/category',
  SearchPositionsFunc: '/position',
  GetCompanyPositionsFunc: '/position',
  GetPositionRequirementsFunc: '/position/{positionId}/requirement',
  GetPositionResponsibilitiesFunc: '/position/{positionId}/responsibility',
  GetPositionDetailsFunc: '/position/{positionId}/details',
  SearchExperienceFunc: '/dictionary/experience',
  SearchEducationFunc: '/dictionary/education',
} as const;
