import { QuestionFormInput, QuestionFormLabel, QuestionFormStyled } from './QuestionForm';
import { QuestionFormProps } from './QuestionFormProps';

export const QuestionRadio: React.FC<QuestionFormProps> = ({
  className,
  id,
  name,
  label,
  disabled,
  checked,
  value,
  onChange,
}) => {
  return (
    <QuestionFormStyled className={className}>
      <QuestionFormInput
        type="radio"
        id={id}
        value={value}
        name={name}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <QuestionFormLabel htmlFor={id} $disabled={disabled}>
        {label}&nbsp;
      </QuestionFormLabel>
    </QuestionFormStyled>
  );
};
