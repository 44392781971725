import React, { useMemo } from 'react';
import styled from 'styled-components';
import startCase from 'lodash/startCase';

import { EmailCategory } from 'model/api-enums.constants';

import { emailTemplateActions, EmailTemplateModel } from 'pages/Company/CompanyTabs/CompanyEmailTemplateTab/state';

import { MenuItem } from 'components/ActionsDropdown';
import { Ellipsis } from 'components/Ellipsis';
import { ColumnMapCustom } from 'components/ListView/ListViewProps';
import { ExActionButton } from 'components/ui/ExActionButton';
import { getKeyByValue } from 'utils/funcs';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

const ExBold = styled.span`
  font-weight: 600;
`;

const ColumnValue = ({ value }: { value: React.ReactNode }) => {
  return (
    <Ellipsis>
      <ExBold>{value}</ExBold>
    </Ellipsis>
  );
};

const EmailTemplateCategoryValue = ({ value }: { value: EmailTemplateModel['emailCategory'] }) => {
  const name = getKeyByValue(EmailCategory, value);

  return (
    <Ellipsis>
      <ExBold>{startCase(name)}</ExBold>
    </Ellipsis>
  );
};

type EmailTemplateActionButtonProps = {
  templateId: EmailTemplateModel['templateId'];
  emailCategory: EmailTemplateModel['emailCategory'];
  className?: string;
};

const EmailTemplateActionButton = ({ templateId, className, emailCategory }: EmailTemplateActionButtonProps) => {
  const dispatch = useAppDispatch();

  const menuItems: MenuItem[] = useMemo(
    () => [
      {
        action: () => {
          dispatch(emailTemplateActions.editSaga({ templateId }));
        },
        label: 'Edit',
      },
      ...(emailCategory !== EmailCategory.CandidateNotifications
        ? [
            {
              action: () => {
                dispatch(emailTemplateActions.removeSaga({ templateId }));
              },
              label: 'Delete',
            },
          ]
        : []),
    ],
    [dispatch, emailCategory, templateId],
  );

  return (
    <ExActionButton
      buttonId="emailTemplateActionButton"
      menuItems={menuItems}
      dropDirection="down"
      className={className}
    />
  );
};

export const emailTemplatesColumn: ColumnMapCustom<EmailTemplateModel> = {
  emailTitle: {
    component: ({ item }) => <ColumnValue value={item.emailTitle} />,
    label: 'Email Template Title',
    order: 'emailTitle',
  },
  emailCategory: {
    component: ({ item }) => <EmailTemplateCategoryValue value={item.emailCategory} />,
    label: 'Category',
    order: 'emailCategory',
  },
  actions: {
    component: ({ item }) => (
      <EmailTemplateActionButton templateId={item.templateId} emailCategory={item.emailCategory} />
    ),
    label: '',
    style: { width: '10%' },
  },
};
