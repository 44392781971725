import { css } from 'styled-components';

import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

export const listTableStyles = css`
  margin: 0;
  width: 100%;
  border: none;
  border-top: 0;
  background: white;
  border-collapse: separate;
  border-radius: ${getThemeBorderProps('borderRadius')};
  border-spacing: 0;
  color: ${(props) => props.theme.colors.blackHeadings};
  td,
  th {
    &:last-child {
      border-right: 0;
    }
  }
  tbody {
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: ${getThemeBorderProps('borderRadius')};
      }
      td:last-child {
        border-bottom-right-radius: ${getThemeBorderProps('borderRadius')};
      }
    }
    tr:first-child {
      td:first-child {
        border-top-left-radius: ${getThemeBorderProps('borderRadius')};
      }
      td:last-child {
        border-top-right-radius: ${getThemeBorderProps('borderRadius')};
      }
      td {
        border-top: 0;
      }
    }
  }
  thead {
    line-height: 16px;
    tr:first-child {
      th:first-child {
        border-top-left-radius: ${getThemeBorderProps('borderRadius')};
      }
      th:last-child {
        border-top-right-radius: ${getThemeBorderProps('borderRadius')};
      }
    }
    th {
      padding: 20px 16px 16px;
      vertical-align: middle;
      background-color: white;
      z-index: 999;
      border: none;
      font-size: ${getThemeFontSize('tableHeading')};
      font-weight: 600;
    }
  }

  td {
    padding: 15px;
    vertical-align: middle;
    font-size: ${getThemeFontSize('tableData')};
    background-color: ${(props) => props.theme.colors.bodyBackgroundColor};
    .dropdown-menu {
      z-index: 1000;
    }
  }
`;

export const innerTableStyles = css`
  thead {
    th {
      background-color: ${(props) => props.theme.colors.bodyBackgroundColor};
      border-bottom: 1px solid #eeeeee;
    }
  }
  tbody {
    tr:first-child {
      td:first-child {
        border-top-left-radius: 0;
      }
      td:last-child {
        border-top-right-radius: 0;
      }
      td {
        border-top: 0;
      }
    }
  }
`;
