import { combineReducers } from '@reduxjs/toolkit';

import { apiConfig } from 'config';

import { publicCompanyReducer, publicCompanySlice } from './public-company/public-company.reducer';
import { publicCountryReducer, publicCountrySlice } from './public-country/public-country.reducer';
import { publicCurrencyReducer, publicCurrencySlice } from './public-currency/public-currency.reducer';
import { publicJobsReducer, publicJobsSlice } from './public-jobs/public-jobs.reducer';
import {
  publicNewApplicantFormReducer,
  publicNewApplicantFormSlice,
} from './public-new-applicant-form/public-new-applicant-form.reducer';

export const publicReducers = combineReducers({
  [publicJobsSlice.name]: publicJobsReducer,
  [publicCompanySlice.name]: publicCompanyReducer,
  [publicNewApplicantFormSlice.name]: publicNewApplicantFormReducer,
  [publicCountrySlice.name]: publicCountryReducer,
  [publicCurrencySlice.name]: publicCurrencyReducer,
});

export const basicPublicUrl = `${apiConfig.baseUrl}/public`;
