import { createSelector } from '@reduxjs/toolkit';

import { jobBoardSelectors } from 'store/entities/job-boards';
import { RootState } from 'store/rootReducer';

export const domain = (state: RootState) => state.formJobBoard;

export const selectFormData = createSelector(domain, state => state.formData);

export const selectJobBoardId = createSelector(selectFormData, formData => formData.jobBoardId);

export const selectJobBoard = createSelector(selectJobBoardId, jobBoardSelectors.selectEntities, (id, entities) => {
  if (id && entities) {
    return entities[id];
  }
});

export const selectValidSteps = createSelector(domain, state => state.validSteps);

export const selectShowSteps = createSelector(domain, state => state.showSteps);
