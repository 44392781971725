/* eslint-disable no-console */
export const consoleErrorForDevEnv = (...args: unknown[]) => {
  const isDevEnv = process.env.NODE_ENV === 'development';

  if (isDevEnv && args) {
    console.log(new Error('Stack trace for following error').stack);
    console.error(...args);
  }
};

export const consoleWarnForDevEnv = (...args: unknown[]) => {
  const isDevEnv = process.env.NODE_ENV === 'development';

  if (isDevEnv && args) {
    console.log(new Error('Stack trace for following warning').stack);
    console.warn(...args);
  }
};
