import { EntityAdapter, EntityState } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { api } from 'api-client';
import { JobEndpoints } from 'api-endpoints/job/endpoints';
import { JobAdEndpointsForApi } from 'api-endpoints/job-ad/endpoints';

import { ApiResponseWithPagination } from 'model';

import { apiCallError } from 'containers/AlertManager/store/alert.actions';

import { ListStateFilter } from 'utils/reducer/reducer-helper';

import type { AxiosBaseQueryFnArgs, BaseQueryFn } from './axiosBaseQueryTypes';

const makeRegexFromEndpoint = (endpoint: string): RegExp => new RegExp(endpoint.replaceAll(/\{.*\}/g, '[^/]+'));

const ignoreErrorEndpoints = [
  makeRegexFromEndpoint(JobEndpoints.PutJobFunc),
  makeRegexFromEndpoint(JobAdEndpointsForApi.GetJobAdStatusFunc),
];

export const axiosBaseQuery =
  <
    Args extends AxiosBaseQueryFnArgs = AxiosBaseQueryFnArgs,
    Result = unknown,
    Error = unknown,
    DefinitionExtraOptions = {},
    Meta = {},
  >(
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<Args, Result, Error, DefinitionExtraOptions, Meta> =>
  async ({ url, method, data, params }: Args, { dispatch, signal }) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const {
        data: result,
        errorData,
        message,
        response,
      } = await api().request<Result>({
        url: baseUrl + url,
        method,
        data,
        cancelToken: source.token,
        params,
      });

      if (message && !ignoreErrorEndpoints.some((regex) => url.match(regex) !== null)) {
        dispatch(apiCallError({ errorData, message, status: response?.status }));
      }

      if (errorData) {
        return {
          error: {
            data: errorData,
          },
        };
      }

      return { data: result };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return { error: { status: err.response?.status, data: err.response?.data } };
    }
  };

export function providesList<R extends Array<string | number>, T extends string>(
  resultsWithIds: R | undefined,
  tagType: T,
) {
  return resultsWithIds
    ? [{ type: tagType, id: 'LIST' }, ...resultsWithIds.map((id) => ({ type: tagType, id }))]
    : [{ type: tagType, id: 'LIST' }];
}

export type EntityStateWithPagination<T> = EntityState<T> & {
  pageCount: number;
  pageNo: number;
  pageSize: number;
  totalItemsCount: number;
  filter?: ListStateFilter;
};

export function transformListResponse<T>(adapter: EntityAdapter<T>) {
  return function (data: ApiResponseWithPagination<T>): EntityStateWithPagination<T> {
    return adapter.addMany(
      adapter.getInitialState({
        pageCount: data.pageCount,
        pageNo: data.pageNo,
        pageSize: data.pageSize,
        totalItemsCount: data.totalItemsCount,
        filter: data?.filter,
      }),
      data.items ?? [],
    );
  };
}
