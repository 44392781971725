import React from 'react';
import styled, { keyframes } from 'styled-components';

import { MakeRequired } from 'model/utils';

const load8 = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerOverlay = styled.div`
  @keyframes table-loading {
    from {
      background-color: #fff0;
    }

    to {
      background-color: #ffffff40;
    }
  }

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  place-content: center;
  place-items: center;
  background-color: #ffffff40;
  z-index: 1;
  animation-name: table-loading;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  cursor: progress;
`;

export const Spinner = styled.div`
  margin: 20px auto;
  padding: 0 !important;
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  border-top: 1em solid ${(props) => props.theme.colors.primaryLight}; /* Acsent colour */
  border-right: 1em solid ${(props) => props.theme.colors.primaryLight}; /* Acsent colour */
  border-bottom: 1em solid ${(props) => props.theme.colors.primaryLight}; /* Acsent colour */
  border-left: 1em solid ${(props) => props.theme.colors.primary}; /* Main colour */
  transform: translateZ(0);
  animation-name: ${load8};
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  box-sizing: initial;
  will-change: transform;
  border-radius: 50%;
  width: 24px;
  height: 24px;

  &::after {
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }
`;

export const SpinnerSM = styled(Spinner)`
  margin: 0;
  width: 15px;
  height: 15px;
  font-size: 2px;
`;

export const Spinner0 = styled(Spinner)`
  margin: 0 auto;
`;

export const EmptyPageSpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const ComponentSpinner = styled(Spinner)`
  position: static;
`;

type MakeComponentSpinnerWrapper = Omit<ComponentSpinnerWrapperProps, 'children'>;

type ComponentSpinnerWrapperProps = {
  loading: boolean;
  children(props: MakeComponentSpinnerWrapper): JSX.Element;
};

export const ComponentSpinnerWrapper: React.FC<ComponentSpinnerWrapperProps> = ({ children, ...props }) => {
  if (props.loading) {
    return <ComponentSpinner />;
  }

  return children(props);
};

interface ComponentProps {
  items?: any[];
  item?: any;
}

export const makeComponentSpinnerWrapper =
  <P extends ComponentProps>(
    Component: React.ComponentType<MakeRequired<P, 'item'>>,
    ComponentNoItem: React.ComponentType<Omit<P, 'item'>>,
  ) =>
  ({ loading, ...props }: P & MakeComponentSpinnerWrapper) =>
    (
      <ComponentSpinnerWrapper loading={loading}>
        {(injectedProps) =>
          props.item ? (
            <Component {...(props as MakeRequired<P, 'item'> & MakeComponentSpinnerWrapper)} {...injectedProps} />
          ) : (
            <ComponentNoItem {...(props as P & MakeComponentSpinnerWrapper)} {...injectedProps} />
          )
        }
      </ComponentSpinnerWrapper>
    );

export const makeComponentSpinnerListWrapper =
  <P extends ComponentProps>(
    Component: React.ComponentType<MakeRequired<P, 'items'>>,
    ComponentNoItem: React.ComponentType<Omit<P, 'items'>>,
  ) =>
  ({ loading, ...props }: P & MakeComponentSpinnerWrapper) =>
    (
      <ComponentSpinnerWrapper loading={loading}>
        {(injectedProps) =>
          props?.items?.length ? (
            <Component {...(props as MakeRequired<P, 'items'> & MakeComponentSpinnerWrapper)} {...injectedProps} />
          ) : (
            <ComponentNoItem {...(props as P & MakeComponentSpinnerWrapper)} {...injectedProps} />
          )
        }
      </ComponentSpinnerWrapper>
    );

export const FloatingSpinnerOverlay = styled(SpinnerOverlay)`
  overflow: visible;
`;

export const FloatingSpinnerWrapper = styled.div`
  position: sticky;
  top: 25%;
  bottom: 50%;
`;

export const FloatingSpinner = () => {
  return (
    <FloatingSpinnerOverlay>
      <FloatingSpinnerWrapper>
        <Spinner />
      </FloatingSpinnerWrapper>
    </FloatingSpinnerOverlay>
  );
};

export const SpinnerOverlayFixed = styled(SpinnerOverlay)`
  position: fixed;
  background-color: #ffffff40;
  z-index: 10;
`;

export const FixedOverlaySpinner = () => {
  return (
    <SpinnerOverlayFixed>
      <Spinner />
    </SpinnerOverlayFixed>
  );
};
