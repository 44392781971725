import { Action, AnyAction, nanoid } from '@reduxjs/toolkit';
import { buffers, Channel, channel } from 'redux-saga';
import { all, call, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { ErrorDTO } from 'model/api-errors.constants';
import { Unwrap } from 'model/utils';

import {
  createTemplateFuncApiCall,
  deleteTemplateFuncApiCall,
  searchTemplateFuncApiCall,
  updateTemplateFuncApiCall,
} from 'pages/Company/CompanyTabs/CompanyEmailTemplateTab/api';
import {
  emailTemplateActions,
  EmailTemplateCreateRequestDTO,
  EmailTemplateCreateResponseDTO,
  EmailTemplateFormModel,
  emailTemplateSelectors,
  EmailTemplateUpdateRequestDTO,
} from 'pages/Company/CompanyTabs/CompanyEmailTemplateTab/state';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { getActionName } from 'modules/LoaderManager/utils';
import { consoleErrorForDevEnv } from 'utils/consoleErrorForDevEnv';
import { walkDOM } from 'utils/funcs';
import { getTranslate } from 'utils/i18utils';
import { invokeApiCall, invokeExModalWizard, ReturnData } from 'utils/sagas';

import {
  exModalCancelAction,
  exModalConfirmAction,
  exModalHideAction,
  updateWizardPage,
  wizardBackward,
  wizardForward,
} from 'store/modals/modals.actions';
import { ModalGeneralResult, ShowModal } from 'store/modals/modals.interfaces';
import { modalSagaWorker } from 'store/modals/modals.sagas';

function* invokeModal(
  modalChannel: Channel<any>,
  modalId: string,
  modalType: ModalsTypeKey,
  modalConfig?: ShowModal['modalProps'],
  modalProps?: ShowModal['modalProps'],
) {
  const result: ModalGeneralResult = yield call(modalSagaWorker, {
    modalType,
    ...(modalId ? { id: modalId } : {}),
    modalConfig,
    modalProps,
  });

  yield put(modalChannel, result);
}

function prepareRequestData(
  emailTemplate?: EmailTemplateCreateRequestDTO | EmailTemplateUpdateRequestDTO,
): EmailTemplateCreateRequestDTO | undefined {
  /**
   * Checking that the email template was received
   */
  if (!emailTemplate) {
    return;
  }

  /**
   * For rendering on BE using scriban https://github.com/lunet-io/scriban
   * next function converts a variable as a span to a string variable in curved brackets {{variable}}
   *
   */
  // START convert data
  const div = document.createElement('div');
  div.innerHTML = emailTemplate.emailBodyHtmlContent;

  walkDOM(div, (n: HTMLElement) => {
    if (n.nodeType === 1) {
      const original = n.getAttribute('data-original-variable');
      const parent = n.parentNode!;
      if (original && parent) {
        const newChild = document.createTextNode(original);
        parent.replaceChild(newChild, n);
      }
    }
  });

  emailTemplate.emailBodyHtmlContent = div.innerHTML;
  // END convert data

  return emailTemplate;
}

function* callApiForRemoveEmailTemplate(action: AnyAction, templateId: string, backupId?: string) {
  yield call(startLoader, action);
  const { errorData, message }: ReturnData<typeof deleteTemplateFuncApiCall> = yield invokeApiCall(
    deleteTemplateFuncApiCall,
    {
      params: {
        backupId,
      },
      urlParams: {
        templateId,
      },
    },
  );

  yield call(stopLoader, action);

  if (!errorData && !message) {
    yield all([
      /**
       * Showing to the user a success message
       */
      put(
        alertsEffects.showSuccess({
          message: getTranslate('company.emailTemplates.remove.success'),
        }),
      ),
      /**
       * Remove email template from the redux store
       */
      put(emailTemplateActions.removeOne(templateId)),
      /**
       * Re-fetching email templates from the API, using last filters
       */
      put(emailTemplateActions.fetchSaga.start({})),
    ]);
  }
  return !errorData && !message;
}

function* emailTemplateFetchSaga(action: ReturnType<typeof emailTemplateActions.fetchSaga.start>) {
  const { payload } = action;

  try {
    yield call(startLoader, action);
    /**
     * Selecting current stored sort parameters from the redux
     */
    const sortMode = yield select(emailTemplateSelectors.emailTemplateSortMode);
    const filters = yield select(emailTemplateSelectors.emailTemplateFilters);

    /**
     * Making API call
     */
    const {
      data,
      errorData,
      message,
    }: {
      data: Unwrap<ReturnType<typeof searchTemplateFuncApiCall>>['data'];
      errorData: ErrorDTO;
      message: string;
    } = yield call(
      invokeApiCall,
      searchTemplateFuncApiCall,
      {
        params: {
          orderBy: sortMode.orderBy,
          orderDirection: sortMode.orderDir,
          ...filters,
        },
      },
      {
        loaderId: !payload.silent ? 'emailTemplateFetchSaga' : undefined,
      },
    );

    /**
     * When get API errors return to start
     */
    if ([errorData, message].some(Boolean)) {
      yield call(stopLoader, action);
      throw new Error(errorData?.description || message);
    }

    /**
     * Put the received data into the store
     */
    yield put(emailTemplateActions.setAll(data.items));
    yield call(stopLoader, action);
  } catch (e) {
    consoleErrorForDevEnv(e);
  }
}

function* emailTemplateEditSaga(action: ReturnType<typeof emailTemplateActions.editSaga>) {
  const { templateId } = action.payload;
  const modalId = nanoid();
  const sagaChannel = yield channel(buffers.none());

  /**
   * Select by Id email template from the store
   */
  const emailTemplate: ReturnType<typeof emailTemplateSelectors.selectById> = yield select(
    emailTemplateSelectors.selectById,
    templateId,
  );

  if (!emailTemplate) {
    return;
  }

  /**
   * Transform a String Variable received from an API to a HTML variable
   */
  // START convert data
  const mergeFields: ReturnType<typeof emailTemplateSelectors.emailTemplateMergeFields> = yield select(
    emailTemplateSelectors.emailTemplateMergeFields,
  );
  const regString = '{{(.*?)}}';
  const regExp = new RegExp(regString, 'gm');
  const preparedEmailTemplate = { ...emailTemplate, ccRecipients: undefined } as EmailTemplateFormModel;
  preparedEmailTemplate.emailBodyHtmlContent = preparedEmailTemplate.emailBodyHtmlContent.replace(regExp, (...args) => {
    const variable = args[0];
    const cleanMappedValue = args[1];
    if (mergeFields.includes(cleanMappedValue)) {
      return `<span class="variable" data-original-variable="${variable}" contenteditable="false">${cleanMappedValue}</span>`;
    }
    return variable;
  });
  // Convert CC Recipients from objects to a comma-separated string
  preparedEmailTemplate.ccRecipients = emailTemplate.ccRecipients?.reduce(
    (recipientsString, recipient, index) => recipientsString + (index > 0 ? ', ' : '') + recipient.email,
    '',
  );
  // END convert data

  while (true) {
    /**
     * Show modal with provided config
     */
    yield fork(
      invokeModal,
      sagaChannel,
      modalId,
      ModalsTypeKey.companyEmailTemplateFormModal,
      {
        content: {
          buttonCancel: 'Cancel',
          buttonOk: 'Update',
          buttonOkVariant: 'success',
          title: 'Edit Email Template',
          withActions: true,
          withTitle: true,
        },
      },
      {
        emailTemplate: preparedEmailTemplate,
      },
    );

    /**
     * Waiting result from the modal window
     */
    const result: ModalGeneralResult = yield take(sagaChannel);

    if (result.cancel) {
      break;
    }

    /**
     * Preparing the data for sending to the API
     */
    const requestData = prepareRequestData(result.confirm?.payload.modalResult?.emailTemplate);

    /**
     * If data not prepared return to start
     */
    if (!requestData) {
      continue;
    }
    yield call(startLoader, action);
    /**
     * Making API call
     */
    const {
      errorData,
      message,
    }: {
      errorData: ErrorDTO;
      message: string;
    } = yield call(invokeApiCall, updateTemplateFuncApiCall, {
      data: requestData,
      urlParams: {
        templateId,
      },
    });

    /**
     * When get API errors return to start
     */
    if ([errorData, message].some(Boolean)) {
      yield call(stopLoader, action);
      continue;
    }

    yield all([
      /**
       * Closing modal by id
       */
      put(
        exModalHideAction({
          id: modalId,
        }),
      ),
      /**
       * Updating email template in the redux store
       */
      put(
        emailTemplateActions.updateOne({
          changes: {
            ...requestData,
          },
          id: templateId,
        }),
      ),
      /**
       * Showing to the user a success message
       */
      put(
        alertsEffects.showSuccess({
          message: getTranslate('company.emailTemplates.update.success'),
        }),
      ),
      call(stopLoader, action),
    ]);
    break;
  }
}

function* emailTemplateRemoveSaga(action: ReturnType<typeof emailTemplateActions.removeSaga>) {
  const { templateId } = action.payload;
  const modalId = nanoid();
  const sagaChannel = yield channel(buffers.none());

  const emailTemplate: ReturnType<typeof emailTemplateSelectors.selectById> = yield select(
    emailTemplateSelectors.selectById,
    templateId,
  );

  if (!emailTemplate) {
    return;
  }

  const isShowFormFoSelectBackupEmail = emailTemplate.isInUse;

  // Start modal wizard
  yield fork(invokeExModalWizard, {
    channel: sagaChannel,
    modalConfig: {
      content: {
        message: getTranslate('company.emailTemplates.remove.confirmMessage', {
          emailTitle: emailTemplate.emailTitle,
        }),
        title: 'Remove Hiring Pipeline',
        withTitle: true,
      },
      page: 'confirmETD',
      wizardType: 'emailTemplateDelete',
    },
    modalId,
    modalProps: {
      action: getActionName(action),
    },
  });

  const { forward, cancel } = yield race({
    cancel: take(wizardBackward),
    forward: take(wizardForward),
  });

  if (cancel || !forward) {
    return;
  }

  /**
   * If emailTemplate is InUse we show modal for get from user backupId
   */
  if (isShowFormFoSelectBackupEmail) {
    yield put(
      updateWizardPage({
        id: modalId,
        modalConfig: {
          content: {
            title: 'Select New Email',
            withTitle: true,
          },
        },
        modalProps: {
          idForExclude: templateId,
        },
        page: 'selectETD',
      }),
    );
  }

  let backupId: string | undefined;

  while (true) {
    if (isShowFormFoSelectBackupEmail) {
      /**
       * Waiting result from the modal window
       */
      const {
        confirm: submit,
        cancel: close,
      }: ModalGeneralResult<{
        backupId: string;
      }> = yield race({
        cancel: take(exModalCancelAction),
        confirm: take(exModalConfirmAction),
      });

      /**
       * We terminate the saga if the user click cancel.
       */
      if ([!submit, submit && !submit.payload.modalResult, close].some(Boolean)) {
        yield put(
          exModalHideAction({
            id: modalId,
          }),
        );
        return;
      }
      backupId = submit!.payload.modalResult!.backupId;
    }

    /**
     * Making API call
     */
    const apicalResult: ReturnType<typeof callApiForRemoveEmailTemplate> = yield call(
      callApiForRemoveEmailTemplate,
      action,
      templateId,
      backupId,
    );
    /**
     * When get API errors return to start
     */
    if (!isShowFormFoSelectBackupEmail || !!apicalResult) {
      break;
    }
  }

  /**
   * Closing modal by id
   */
  yield put(
    exModalHideAction({
      id: modalId,
    }),
  );
}

function* emailTemplateCreateSaga(action: Action) {
  const modalId = nanoid();
  const sagaChannel = yield channel(buffers.none());

  while (true) {
    /**
     * Show modal with provided config, for create new an email template
     */
    yield fork(invokeModal, sagaChannel, modalId, ModalsTypeKey.companyEmailTemplateFormModal, {
      content: {
        buttonCancel: 'Cancel',
        buttonOk: 'Save',
        buttonOkVariant: 'success',
        title: 'Add Email Template',
        withActions: true,
        withTitle: true,
      },
    });

    /**
     * Waiting result from the modal window
     */
    const result: ModalGeneralResult = yield take(sagaChannel);

    /**
     * We terminate the saga if the user click cancel.
     */
    if (result.cancel) {
      break;
    }

    yield call(startLoader, action);

    /**
     * Preparing the data for sending to the API
     */
    const requestData = prepareRequestData(result.confirm?.payload.modalResult?.emailTemplate);

    /**
     * If data not prepared return to start
     */
    if (!requestData) {
      yield call(stopLoader, action);
      continue;
    }

    /**
     * Making API call
     */
    const {
      errorData,
      data,
    }: {
      errorData: ErrorDTO;
      data: EmailTemplateCreateResponseDTO;
    } = yield call(invokeApiCall, createTemplateFuncApiCall, {
      data: requestData,
    });

    /**
     * When get API errors return to start
     */
    if (errorData || !data) {
      call(stopLoader, action);
      continue;
    }

    yield all([
      /**
       * Closing modal by id
       */
      put(exModalHideAction({ id: modalId })),
      /**
       * Add email template to the redux store
       */
      put(emailTemplateActions.addOne({ ...requestData, ...data })),
      /**
       * Showing to the user a success message
       */
      put(
        alertsEffects.showSuccess({
          message: getTranslate('company.emailTemplates.create.success'),
        }),
      ),
      call(stopLoader, action),
    ]);
    break;
  }
}

export function* emailTemplateSagaWorkers() {
  yield takeLatest(emailTemplateActions.fetchSaga.start, emailTemplateFetchSaga);
  yield takeLatest(emailTemplateActions.editSaga, emailTemplateEditSaga);
  yield takeLatest(emailTemplateActions.removeSaga, emailTemplateRemoveSaga);
  yield takeLatest(emailTemplateActions.createSaga, emailTemplateCreateSaga);
}
