import { useCallback, useEffect, useState } from 'react';

import { ExInterviewQuestion } from 'model';

import { QuestionViewProps } from 'components/InterviewTemplate/QuestionView/QuestionViewProps';
import { useDebounce } from 'utils/hooks/useDebounce';
import { getTranslate } from 'utils/i18utils';

import { useUpdateInterviewQuestionMutation } from 'store/entities/interview-question';

export const useQuestionYesNoState = ({ className, editable, question, interviewId }: QuestionViewProps) => {
  const [updateInterviewQuestionMutation, { isLoading }] = useUpdateInterviewQuestionMutation();
  const disabled = !editable || isLoading;

  const [updatedQuestion, setUpdatedQuestion] = useState<null | Partial<ExInterviewQuestion>>(null);
  const debouncedUpdatedQuestion = useDebounce(updatedQuestion, 1500);
  useEffect(() => {
    if (debouncedUpdatedQuestion && interviewId) {
      const { interviewQuestionFiles, ...clearUpdatedQuestion } = debouncedUpdatedQuestion;
      updateInterviewQuestionMutation({
        ...clearUpdatedQuestion,
        interviewQuestionId: question.questionId,
        interviewId,
      });
    }
  }, [debouncedUpdatedQuestion, interviewId, question.questionId, updateInterviewQuestionMutation]);
  const [answer, setAnswer] = useState(question?.answer);

  const labels = {
    yes: getTranslate('interviewTemplate.questionMandatory.yes'),
    no: getTranslate('interviewTemplate.questionMandatory.no'),
  };

  const onSubmit = useCallback(
    (e) => {
      const newAnswer = e.target.value === 'true';
      setAnswer(newAnswer);
      setUpdatedQuestion({
        ...question,
        answer: newAnswer,
      });
    },
    [question, setUpdatedQuestion],
  );

  return {
    className,
    disabled,
    interviewTemplateQuestionId: question.questionId,
    question,
    labels,
    answer,
    onSubmit,
    isLoading,
  } as const;
};
