import { useCallback } from 'react';

import { api } from 'api-client';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';

import { getFileNameFromHeaders } from 'utils/getFileNameFromHeaders';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { saveFile } from 'utils/saveFile';

export function useDownloadFile() {
  const dispatch = useAppDispatch();
  return useCallback(
    async ({ path, fileName }: { path: string; fileName?: string }) => {
      // Download file as Blob. Using App api-client.
      const { data, message, headers } = await api().request<Blob>({
        responseType: 'blob',
        url: path,
      });

      if (message) {
        dispatch(alertsEffects.showError({ message }));
        return;
      }

      const filenameByHeader = getFileNameFromHeaders(headers);

      saveFile(data, fileName || filenameByHeader);
    },
    [dispatch],
  );
}
