import { css, keyframes } from 'styled-components/macro';

export const AnimationIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const AnimationScale = keyframes`
 0% {
  opacity: 0;
  transform: scale(.1);
 }

 100% {
  opacity: 1;
  transform: scale(1);
 }
`;

export const fullScreenModalsAnimation = css`
  opacity: 0;
  transform: scale(0.1);
  animation-timing-function: ease-out !important;
`;

export type modalWrapperStyleProps = {
  hideBackDrop?: boolean;
  zIndex?: number;
};

const modalWrapperStyleHideBackDrop = ({ hideBackDrop }: modalWrapperStyleProps) =>
  !hideBackDrop &&
  css`
    background-color: rgba(0, 0, 0, 0.2);
  `;

export const modalWrapperStyle = css<modalWrapperStyleProps>`
  ${modalWrapperStyleHideBackDrop};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ zIndex }) => zIndex || 1040};
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${AnimationIn};
  animation-duration: 300ms;
  padding: 20px;
`;

export const modalStyle = css`
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.2);
  border-radius: ${(p) => p.theme.border.borderRadius};

  &:not(.disable-animation) {
    animation: ${AnimationScale};
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  @media (max-width: 767.98px) {
    width: 100vw;
    height: 100vh;
    max-width: none;
    max-height: none;
    border-radius: 0;

    .talent-pool-dialog {
      height: 100%;
    }

    .dialog {
      height: 100%;
    }
  }
`;

export const fullscreenModalStyleV2 = css`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.bodyBackgroundColor};
  border-radius: 0 !important;

  > * {
    margin: 0 auto;
  }
  .form-with-steps {
    display: flex;
    align-items: flex-start;
    height: 100%;
  }
  .steps {
    padding: 1rem;
  }
`;

export const fullscreenModalStyle = css`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.bodyBackgroundColor};
  ${fullScreenModalsAnimation};
  border-radius: 0 !important;

  > * {
    margin: 0 auto;
  }
  .form-with-steps {
    display: flex;
    align-items: flex-start;
    padding: 102px 15px 102px;
    height: 100%;
  }
  .steps {
    padding: 1rem;
  }
`;
