import type { DetailsRowProps } from 'components/DetailsCard';
import { getFrequencyNames } from 'utils/hooks/formsHooks/useFrequencyOptions';
import { useCurrency } from 'utils/hooks/useCurrency';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { useCurrencyQuerySelectors } from 'store/dictionary/hooks/useCurrencyQuerySelectors';
import type { Salary } from 'store/entities/jobs/models';

export type SalaryInfoRowsKeys = 'currency' | 'salary' | 'salaryFrom' | 'salaryTo' | 'salaryFrequency';

export function useSalaryInfoRows(salary: Salary): Record<SalaryInfoRowsKeys, DetailsRowProps> {
  const { selectors: currenciesSelectors } = useCurrencyQuerySelectors();
  const currency = useAppSelector(currenciesSelectors.selectById, salary.currency);

  const { currencyToLocaleString, makeStringFromSalaryRange } = useCurrency({
    currency: currency?.currency,
  });

  const salaryMoneyRangeMinimum = salary.moneyRange?.minimum ?? 0;
  const salaryMoneyRangeMaximum = salary.moneyRange?.maximum ?? 0;

  return {
    currency: {
      label: 'Currency',
      value: salary?.currency,
    },
    salary: { label: 'Salary', value: makeStringFromSalaryRange(salary) },
    salaryFrom: {
      label: 'Salary From',
      value: currencyToLocaleString(salaryMoneyRangeMinimum),
    },
    salaryTo: {
      label: 'Salary To',
      value: currencyToLocaleString(salaryMoneyRangeMaximum),
    },
    salaryFrequency: {
      label: 'Salary Frequency',
      value: salary?.frequency !== undefined ? getFrequencyNames(salary.frequency) : null,
    },
  };
}
