import { FormJobAdCaseReducer, formJobAdInitialState } from 'containers/JobAdForms/state/models';

export const clearForm: FormJobAdCaseReducer<any> = (state) => ({
  ...state,
  formData: formJobAdInitialState.formData,
  apiErrors: formJobAdInitialState.apiErrors,
  additionFieldsView: formJobAdInitialState.additionFieldsView,
  selectedJobId: formJobAdInitialState.selectedJobId,
  selectedBoardIds: formJobAdInitialState.selectedBoardIds,
});
