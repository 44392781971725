import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ExScreeningQuestion } from 'model';

import { adapterHelper } from 'utils/reducer/adapter-helper';

import { EntityKeysNew } from 'store/constants';

export const jobScreeningQuestionsEntityAdapter = createEntityAdapter<ExScreeningQuestion>({
  selectId: (item) => item.itemId,
});

export const jobScreeningQuestionsSlice = createSlice({
  name: EntityKeysNew.jobScreeningQuestions,
  initialState: jobScreeningQuestionsEntityAdapter.getInitialState(),
  reducers: {
    ...adapterHelper(jobScreeningQuestionsEntityAdapter),
    reorder: (
      draft,
      { payload }: PayloadAction<{ target: number; source: number } & Pick<ExScreeningQuestion, 'itemId'>>,
    ) => {
      draft.ids.splice(payload.source, 1);
      draft.ids.splice(payload.target, 0, payload.itemId);
    },
  },
});

export const jobScreeningQuestionsActions = {
  ...jobScreeningQuestionsSlice.actions,
};
