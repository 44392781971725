import styled from 'styled-components';

import { MediaCssProps } from 'components/ui/ExLayout/ExLayoutProps';
import { buildMediaCss } from 'components/ui/ExLayout/utils';

/**
 * Basic ExRow - behaviour like bootstrap row
 */
export const ExRow = styled.div<{ column?: boolean; noWrap?: boolean }>`
  display: flex;
  flex-wrap: ${props => (props.noWrap ? 'nowrap' : 'wrap')};
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  margin: -10px;
  max-width: ${props => (props.noWrap ? '100%' : 'none')};

  &:not(:first-child) {
    padding-top: 20px;
  }
`;

/**
 * Basic ExColumn - behaviour like bootstrap column
 *
 * Along with column size in bootstrap style (1,2,3...12, auto) ExColumn allow
 * to pass a styled css callback instead of column size. Styled css theming function
 * is passed to callback.
 *
 * It looks like example below:
 *
 * <ExColumn medium={(css) => css`max-width: 400px`}
 */
export const ExColumn = styled.div<{ flex?: boolean; column?: boolean } & MediaCssProps>`
  display: ${props => (props.flex ? 'flex' : 'block')};
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  padding: 10px;
  flex-basis: 100%;
  max-width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  ${({ extraSmall, small, medium, large, extraLarge, custom }) => {
    return buildMediaCss({ extraSmall, small, medium, large, extraLarge, custom });
  }}
`;
