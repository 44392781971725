import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { list } from 'config/list';

import { PaginationState } from 'store/app-files/appFiles.models';
import { paginationHelper } from 'store/app-files/appFiles.reducer.helper';
import { loadDocumentFiles } from 'store/app-files/documents/documents.effects';
import { DocumentFile, DocumentQueryInterface } from 'store/app-files/documents/documents.models';
import { AppFilesKeys } from 'store/constants';

export const documentsAdapter = createEntityAdapter<DocumentFile>({
  selectId: (file) => file.documentId,
});

export const documentsSlice = createSlice({
  name: AppFilesKeys.documents,
  initialState: documentsAdapter.getInitialState<PaginationState<DocumentFile, DocumentQueryInterface>>({
    pagination: {
      defaultPageSize: list.pageSize,
      defaultPageNo: 0,
      requests: {},
      lists: {},
    },
  }),
  reducers: {
    ...paginationHelper.reducers(documentsAdapter),
  },
  extraReducers: (builder) => {
    builder.addCase(loadDocumentFiles.pending, paginationHelper.pending);
    builder.addCase(loadDocumentFiles.fulfilled, paginationHelper.fulfilled(documentsAdapter));
    builder.addCase(loadDocumentFiles.rejected, paginationHelper.rejected);
  },
});

export const documentsActions = documentsSlice.actions;

export const documentsReducer = documentsSlice.reducer;
