import React from 'react';
import styled from 'styled-components';

import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

export const ExEmptyStateBody = styled.div`
  display: flex;
  place-content: center;
  place-items: center;
  flex-direction: column;
`;

export const ExEmptyStateDescription = styled.p`
  font-size: ${getThemeFontSize('small')};
  margin-bottom: 0;
`;

const ExEmptyStatePicture = styled.picture`
  width: 146px;
  height: 146px;
`;

export const ExEmptyStateImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

type ExEmptyStateProps = {
  className?: string;
  entityName?: string;
  fullDescription?: string;
  height?: number;
};

export const ExEmptyStateStyled = styled.div<{ height?: number }>`
  display: flex;
  place-content: center;
  place-items: center;
  width: 100%;
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor};
  height: ${(props) => props.height ?? 200}px;
  border-radius: ${getThemeBorderProps('borderRadius')};
`;

const useExEmptyStateState = ({ className, entityName, fullDescription, height }: ExEmptyStateProps) => {
  const defaultDescription = `There are currently no ${entityName}`;
  const description = fullDescription ?? defaultDescription;

  return {
    className,
    description,
    height,
  };
};

export const ExEmptyState: React.FC<ExEmptyStateProps> = (props) => {
  const { className, description, height } = useExEmptyStateState(props);

  return (
    <ExEmptyStateStyled className={className} height={height}>
      <ExEmptyStateBody>
        <ExEmptyStatePicture>
          <source type="image/webp" srcSet="img/empty-state.webp, img/empty-state@2x.webp 2x" />
          <source type="image/png" srcSet="img/empty-state.png, img/empty-state@2x.png 2x" />
          <ExEmptyStateImage src="img/empty-state.png" alt="empty state" />
        </ExEmptyStatePicture>
        <ExEmptyStateDescription>{description}</ExEmptyStateDescription>
      </ExEmptyStateBody>
    </ExEmptyStateStyled>
  );
};
