import { OrderDirection } from 'model/api-enums.constants';

import { SortDirections } from 'store/constants';

export function orderDirectionWithSpace({
  newSort,
}: {
  currentSort?: { orderBy?: string; orderDirection?: OrderDirection };
  newSort?: { orderBy?: string; orderDirection?: OrderDirection };
  defaultSorting?: { orderBy?: string; orderDirection?: OrderDirection };
}): { orderBy?: string } | undefined {
  if (newSort?.orderBy) {
    const result = { orderBy: newSort?.orderBy };

    result.orderBy += newSort?.orderDirection === OrderDirection.Descending ? ' ' + SortDirections.desc : '';

    return result;
  }
}
