import React, { useEffect, useMemo, useState } from 'react';
import { SelectCallback } from 'react-bootstrap/helpers';
import { useHistory } from 'react-router-dom';
import { ExRoutes } from 'router/routes';
import styled from 'styled-components';

import { JobStatus } from 'model/api-enums.constants';

import { useJobDelete } from 'pages/Jobs/Job/hooks/useJobDelete';
import { useJobDuplicate } from 'pages/Jobs/Job/hooks/useJobDuplicate';

import { MenuItem } from 'components/DropDownButton';
import { CreateJobActionButtonProps, JobActionButtonProps } from 'components/JobActionButton/JobActionButtonProps';
import { ExActionButton } from 'components/ui/ExActionButton';
import { ExDropdownButton } from 'components/ui/ExButton/ExDropdownButton';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { authSelectors } from 'store/auth/auth.selectors';
import { jobsSelectors } from 'store/entities/jobs';
import { updateJobStatusWithModalAction } from 'store/entities/jobs/job.actions';
import { useSelector } from 'store/rootSelectors';

const JobActionButton: React.FC<JobActionButtonProps> = ({ listId, job, size, className }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const withButton = size !== 'sm';

  const { jobDeleteWithModal, hideDeleteButton } = useJobDelete({ job });
  const { jobDuplicateWithModal } = useJobDuplicate({ job, redirect: withButton });

  const handlerAction: SelectCallback = async (eventKey, ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const selectedIndex = Number(eventKey);
    const item = filteredMenuItems[selectedIndex];
    const action = item.path;

    if (action === 'delete') {
      const result = await jobDeleteWithModal();

      if (!result) {
        return;
      }

      if (withButton) {
        history.push(ExRoutes.jobListDraft());
      }

      return;
    }

    if (action === 'duplicate') {
      await jobDuplicateWithModal();
      return;
    }

    dispatch(updateJobStatusWithModalAction({ jobId: job.jobId, status: action, listId, jobStatus: job.status }));
  };

  const isUserCanPublishJob = useSelector(authSelectors.isUserCanPublishJobSelector);
  const isUserCanCancelJob = useSelector(authSelectors.isUserCanCancelJobSelector);
  const isUserCanCompleteJob = useSelector(authSelectors.isUserCanCompleteJobSelector);

  const [menuItems, updateMenuItems] = useState<MenuItem[]>([]);
  useEffect(() => {
    updateMenuItems([
      {
        label: 'Open Job',
        path: 'open',
        variant: 'primary',
        hide: [JobStatus.open, JobStatus.filled, JobStatus.draft].includes(job.status) || !isUserCanPublishJob,
      },
      {
        label: 'Complete Job',
        path: 'complete',
        variant: 'primary',
        hide: [JobStatus.draft, JobStatus.cancelled, JobStatus.filled].includes(job.status) || !isUserCanCompleteJob,
      },
      {
        label: 'Mark Not Filled',
        path: 'open',
        hide: !([JobStatus.filled].includes(job.status) && isUserCanPublishJob),
      },
      {
        label: 'Cancel Job',
        path: 'cancel',
        variant: 'light',
        hide: [JobStatus.draft, JobStatus.cancelled, JobStatus.filled].includes(job.status) || !isUserCanCancelJob,
      },
      {
        label: 'Delete Job',
        path: 'delete',
        variant: 'danger',
        hide: hideDeleteButton,
      },
      {
        label: 'Duplicate Job',
        path: 'duplicate',
      },
    ]);
  }, [
    job.status,
    job.applicantsCount,
    isUserCanPublishJob,
    isUserCanCancelJob,
    isUserCanCompleteJob,
    hideDeleteButton,
  ]);

  const filteredMenuItems = useMemo(() => menuItems.filter((item) => !item.hide), [menuItems]);

  const buttonId = `job-${job.jobId}-action-button`;

  return (
    <ExActionButton
      buttonId={buttonId}
      menuItems={filteredMenuItems}
      dropDirection="down"
      className={className}
      handlerAction={handlerAction}
      withButton={withButton}
    >
      {withButton && <ExDropdownButton variant="light">Actions</ExDropdownButton>}
    </ExActionButton>
  );
};

export default styled(JobActionButton)`
  display: flex;
  flex-shrink: 0;
`;

const CreateJobActionButtonStyled = styled(ExActionButton)`
  padding: 0;
  margin: 0;
`;

export const CreateJobActionButton: React.FC<CreateJobActionButtonProps> = ({ className, jobId, isDisabled }) => {
  const job = useAppSelector(jobsSelectors.getById, jobId);

  const history = useHistory();

  const { jobDeleteWithModal, hideDeleteButton } = useJobDelete({ job: job! });

  const handlerAction: SelectCallback = async (eventKey, ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    const selectedIndex = Number(eventKey);
    const item = filteredMenuItems[selectedIndex];
    const action = item.path;

    if (action === 'delete') {
      const result = await jobDeleteWithModal();

      if (!result) {
        return;
      }

      history.push(ExRoutes.jobListDraft());
    }
  };

  const [menuItems, updateMenuItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    updateMenuItems([
      {
        label: 'Delete Draft',
        path: 'delete',
        variant: 'danger',
        hide: hideDeleteButton,
      },
    ]);
  }, [hideDeleteButton]);

  const filteredMenuItems = useMemo(() => menuItems.filter((item) => !item.hide), [menuItems]);

  const buttonId = `create-job-${job?.jobId}-action-button`;

  return (
    <CreateJobActionButtonStyled
      buttonId={buttonId}
      menuItems={filteredMenuItems}
      dropDirection="down"
      className={className}
      handlerAction={handlerAction}
      disabled={isDisabled}
    >
      <ExDropdownButton disabled={isDisabled} variant="light">
        Actions
      </ExDropdownButton>
    </CreateJobActionButtonStyled>
  );
};
