// @ts-check
import {
  CandidateValidationErrorCodes,
  CompanyValidationErrorCodes,
  JobApplicantValidationErrorCodes,
  JobValidationErrorCodes,
  PipelineValidationErrors,
  QualificationErrorCodes,
  TalentPoolValidationErrorCodes,
  JobBoardValidationErrorCodes,
  JobAdValidationErrorCodes,
  CompanyEmailTemplateValidationErrorCodes,
  UserValidationErrorCodes,
  InterviewTemplateValidationErrorCodes,
  InterviewValidationErrorCodes,
  InterviewQuestionsValidationErrorCodes,
  InterviewTemplateQuestionsValidationErrorCodes,
  PositionValidationErrors,
  JobScreeningValidationErrorCodes,
} from 'model/api-errors.constants';

const positionNotFound = 'Position Not Found';
const validationErrorCodes = {
  [CandidateValidationErrorCodes.FirstNameEmpty]: 'First Name Empty',
  [CandidateValidationErrorCodes.LastNameEmpty]: 'Last Name Empty',
  [CandidateValidationErrorCodes.EmailEmpty]: 'Email Empty',
  [CandidateValidationErrorCodes.EmailNotValid]: 'Email Not Valid',
  [CandidateValidationErrorCodes.EmailNotUnique]: 'Email is not unique',
  [CandidateValidationErrorCodes.AttachmentFileTypeNotSupported]: 'File Type Not Supported',
  [CandidateValidationErrorCodes.AttachmentFileIsInUse]:
    'This file is being used to apply for a job. You cannot delete this file.',
  [CandidateValidationErrorCodes.EducationNameEmpty]: 'Education Name Empty',
  [CandidateValidationErrorCodes.EducationNumberEmpty]: 'Education Number Empty',
  [CandidateValidationErrorCodes.EducationOrganizationEmpty]: 'Education Organization Empty',
  [CandidateValidationErrorCodes.EducationIssueDateEmpty]: 'Education Issue Date Empty',
  [CandidateValidationErrorCodes.EducationQualificationEmpty]: 'Education Qualification Empty',
  [CandidateValidationErrorCodes.WorkExperienceCompanyNameEmpty]: 'Work Experience Company Name Empty',
  [CandidateValidationErrorCodes.WorkExperiencePositionEmpty]: 'Work Experience Position Empty',
  [CandidateValidationErrorCodes.WorkExperienceStartDateEmpty]: 'Work Experience Start Date Empty',
  [CandidateValidationErrorCodes.WorkExperienceEndDateEmpty]: 'Work Experience End Date Empty',
  [CandidateValidationErrorCodes.PhotoFileTypeNotSupported]: 'Photo File Type Not Supported',
  [CandidateValidationErrorCodes.PhotoFileMaxLengthExceeded]: 'Photo File Max Length Exceeded',
  [CandidateValidationErrorCodes.AttachmentFileMaxLengthExceeded]: 'Attachment File Max Length Exceeded',
  [CandidateValidationErrorCodes.EmptyFileName]: 'EmptyFileName',
  [CandidateValidationErrorCodes.DoNotHireCandidateCannotBeAddedToCustomTalentPool]:
    'Do Not Hire Candidate can not Be Added To Talent Pool',
  [CandidateValidationErrorCodes.AllCandidatesAlreadyAdded]: 'All Candidates Already Added',
  [CompanyValidationErrorCodes.CountriesHasNoMainErrorCode]: "You can't delete default country",
  [CompanyValidationErrorCodes.CompanyNameEmptyErrorCode]: 'Company Name Empty',
  [CompanyValidationErrorCodes.CountriesEmptyErrorCode]: 'Countries Empty',
  [CompanyValidationErrorCodes.CountryInvalidErrorCode]: 'Country Invalid',
  [CompanyValidationErrorCodes.CurrencyInvalidErrorCode]: 'Currency Invalid',
  [CompanyValidationErrorCodes.CurrenciesRequiredErrorCode]: 'Currencies Required',
  [CompanyValidationErrorCodes.CurrenciesHasNoMainErrorCode]: 'Currencies Has No Main',
  [CompanyValidationErrorCodes.IndustriesEmptyErrorCode]: 'Industries Empty',
  [CompanyValidationErrorCodes.IndustriesInvalidErrorCode]: 'Industries Invalid',
  [CompanyValidationErrorCodes.NotAllEmployeeTypesPresented]: 'Not All Employee Types Presented',
  [CompanyValidationErrorCodes.IncorrectCountry]: 'Incorrect Country',
  [CompanyValidationErrorCodes.CannotDeleteDefaultOnCost]: 'Cannot Delete Default On-Cost',
  [CompanyValidationErrorCodes.LogoFileTypeNotSupported]: 'Logo File Type Not Supported',
  [CompanyValidationErrorCodes.LogoFileMaxLengthExceeded]: 'Logo File Max Length Exceeded',
  [CompanyValidationErrorCodes.EmptyFileName]: 'Empty File Name.',
  [CompanyValidationErrorCodes.WrongContactsNumber]: 'Wrong Contacts Number',
  [CompanyValidationErrorCodes.IdibuEmailTestNameNotAllowedErrorCode]: 'Idibu Email Test Name Not Allowed',
  [CompanyValidationErrorCodes.IdibuUserNameAlreadyInUseErrorCode]: 'Idibu User Name Already In Use',
  [CompanyValidationErrorCodes.IdibuClientNotCreatedErrorCode]: 'Idibu Client Not Created',
  [JobApplicantValidationErrorCodes.ResumeEmpty]: 'Resume Empty',
  [JobApplicantValidationErrorCodes.ResumeNotFound]: 'Resume Not Found',
  [JobApplicantValidationErrorCodes.CandidateAppliedBefore]: 'Candidate Applied Before',
  [JobApplicantValidationErrorCodes.FirstNameEmpty]: 'First Name Empty',
  [JobApplicantValidationErrorCodes.LastNameEmpty]: 'Last Name Empty',
  [JobApplicantValidationErrorCodes.MobileEmpty]: 'Mobile Empty',
  [JobApplicantValidationErrorCodes.EmailEmpty]: 'Email Empty',
  [JobApplicantValidationErrorCodes.EmailNotValid]: 'Email Not Valid',
  [JobApplicantValidationErrorCodes.IncorrectSalaryAmount]: 'Incorrect Salary Amount',
  [JobApplicantValidationErrorCodes.IncorrectCurrency]: 'Incorrect Currency',
  [JobApplicantValidationErrorCodes.IncorrectFrequency]: 'Incorrect Frequency',
  [JobApplicantValidationErrorCodes.AttachmentFileTypeNotSupported]: 'Attachment File Type Not Supported',
  [JobApplicantValidationErrorCodes.PhotoFileTypeNotSupported]: 'Photo File Type Not Supported',
  [JobApplicantValidationErrorCodes.PhotoFileMaxLengthExceeded]: 'Photo File Max Length Exceeded',
  [JobApplicantValidationErrorCodes.AttachmentFileMaxLengthExceeded]: 'Attachment File Max Length Exceeded',
  [JobApplicantValidationErrorCodes.EmptyFileName]: 'Empty File Name',
  [JobApplicantValidationErrorCodes.ApplicantIsCreated]: 'You already confirmed the application submission.',
  [JobApplicantValidationErrorCodes.JobApplicantNotExists]: 'Job Applicant Not Exists',
  [JobApplicantValidationErrorCodes.PendingJobApplicantNotExists]: 'You already declined this application',
  [JobApplicantValidationErrorCodes.PendingJobApplicantAlreadyDeclined]: 'Pending Job Applicant Already Declined',
  [JobApplicantValidationErrorCodes.WrongAvailabilityActualDateValue]: 'Wrong Availability Actual Date',
  [JobApplicantValidationErrorCodes.ApplicantAlreadyExists]:
    'Our system identified that you have already submitted an application to this job. This application cannot be resubmitted.',
  [JobApplicantValidationErrorCodes.ApplicantNotExists]: 'Applicant Not Exists',
  [JobApplicantValidationErrorCodes.InvalidJobApplicantOnboardingEventCode]: 'Invalid Job Applicant Onboarding',
  [JobApplicantValidationErrorCodes.WrongPipelineStage]: 'Onboarding has started',
  [JobApplicantValidationErrorCodes.InvalidOnboardingEventCode]: 'Invalid Onboarding',
  [JobApplicantValidationErrorCodes.RequiredStagesUnpassed]: 'Required Stages Unpassed',
  [JobApplicantValidationErrorCodes.CurrentStageLocked]: 'Current Stage Locked',
  [JobApplicantValidationErrorCodes.HasInterviewsToPass]: 'Has Interviews To Pass',
  [JobValidationErrorCodes.PositionEmpty]: 'Position Empty',
  [JobValidationErrorCodes.PositionCategoryEmpty]: 'Position Category Empty',
  [JobValidationErrorCodes.PositionNameEmpty]: 'Position Name Empty',
  [JobValidationErrorCodes.PositionNotFound]: positionNotFound,
  [JobValidationErrorCodes.CurrencyNotAvailable]: 'Currency Not Available',
  [JobValidationErrorCodes.SalaryEmpty]: 'Salary Empty',
  [JobValidationErrorCodes.SalaryRangeMaxLessMin]: 'Salary Range Max Less Min',
  [JobValidationErrorCodes.JobNameEmpty]: 'Job Name Empty',
  [JobValidationErrorCodes.JobDescriptionMinLength]: 'Job Description too short',
  [JobValidationErrorCodes.ReferenceNumberEmpty]: 'Reference Number Empty',
  [JobValidationErrorCodes.ReferenceNumberLess10Symbols]: 'Reference Number Less 10 Symbols',
  [JobValidationErrorCodes.ReferenceNumberNotUnique]: 'Reference Number Not Unique',
  [JobValidationErrorCodes.VacancyNumberRangeInvalid]: 'Vacancy Number Invalid',
  [JobValidationErrorCodes.CountryFromEmpty]: 'Country From Empty',
  [JobValidationErrorCodes.JobLocationIdEmpty]: 'Job Location Empty',
  [JobValidationErrorCodes.PipelineChangedForNonDraft]: 'Pipeline Changed For Non Draft',
  [JobValidationErrorCodes.IncorrectFrequency]: 'Incorrect Frequency',
  [JobValidationErrorCodes.WrongJobType]: 'Wrong Job Type',
  [JobValidationErrorCodes.JobTypeCannotBeExternal]: 'Job Type Cannot Be External',
  [JobValidationErrorCodes.JobIsNotExists]: 'Job Is Not Exists',
  [JobValidationErrorCodes.AtLeastOneInterviewTemplateMustBeSpecified]:
    'At Least One Interview Template Must Be Specified',
  [JobValidationErrorCodes.OneOrMoreInterviewTemplatesNotExists]: 'One Or More Interview Templates Not Exists',
  [JobValidationErrorCodes.EmptyReferenceNo]: 'Empty Reference No',
  [JobValidationErrorCodes.EmptyJobName]: 'Empty Job Name',
  [JobValidationErrorCodes.WrongExperienceValue]: 'Wrong Experience Value',
  [JobValidationErrorCodes.WrongEducationValue]: 'Wrong Education Value',
  [JobValidationErrorCodes.WrongIndustryValue]: 'Wrong Industry Value',
  [JobValidationErrorCodes.DisabledPipeline]: 'Pipeline Is Disabled',
  [PipelineValidationErrors.PipelineIsInUse]: 'Pipeline Is In Use',
  [PipelineValidationErrors.PipelineNameEmpty]: 'Pipeline Name Empty',
  [PipelineValidationErrors.PipelineIsDefault]: 'Pipeline Is Default',
  [PipelineValidationErrors.PipelineNameNotUnique]: 'Pipeline Name Not Unique',
  [PipelineValidationErrors.PipelineStageNameEmpty]: 'Pipeline Stage Name Empty',
  [PipelineValidationErrors.PipelineOrderMissStages]: 'Missed pipeline stage',
  [PipelineValidationErrors.InvalidPipeline]: 'Invalid Pipeline',
  [PipelineValidationErrors.InvalidPipelineId]: 'Invalid Pipeline Id',
  [PipelineValidationErrors.BackupPipelineIdFromWrongCompany]: 'Backup Pipeline Id From Wrong Company',
  [PipelineValidationErrors.NoBackupPipelineIdSpecified]: 'No Backup Pipeline Id Specified',
  [PipelineValidationErrors.PipelineMustHaveAllRequiredStages]:
    'There must be 1 stage with New / Ready to Onboard / In Onboarding / Hired stage type',
  [PipelineValidationErrors.TypeChangeOfThisPipelineStageIsNotAllowed]:
    'Type Change Of This Pipeline Stage Is Not Allowed',
  [PipelineValidationErrors.PipelineMustHaveAtLeastOneOfSecondaryStage]:
    'The pipeline should have at least one stage of the one type from: Shortlisted, Interviewed, Screened, Custom',
  [PipelineValidationErrors.PipelineStageHasDependentApplicants]:
    'To remove this stage from the hiring pipeline, please reassign any applicants first',
  [PipelineValidationErrors.PipelineIsDisabled]: 'Pipeline Is Disabled',
  [QualificationErrorCodes.DaxtraQualificationNameEmpty]: 'Daxtra Qualification Name Empty',
  [QualificationErrorCodes.DaxtraQualificationWithoutMapping]: 'Daxtra Qualification Without Mapping',
  [QualificationErrorCodes.QualificationEmpty]: 'Qualification Empty',
  [QualificationErrorCodes.QualificationNameEmpty]: 'Qualification Name Empty',
  [TalentPoolValidationErrorCodes.GeneralNameForbidden]: 'General Name Forbidden',
  [TalentPoolValidationErrorCodes.TalentPoolNameEmpty]: 'Talent Pool Name Empty',
  [TalentPoolValidationErrorCodes.TalentPoolNameNotUnique]: 'Talent Pool Name Not Unique',
  [TalentPoolValidationErrorCodes.TalentPoolIdEmpty]: 'Talent Pool Id Empty',
  [TalentPoolValidationErrorCodes.TalentPoolNotFound]: 'Talent Pool Not Found',
  [TalentPoolValidationErrorCodes.PositionNotFound]: positionNotFound,
  [TalentPoolValidationErrorCodes.PositionNameRequired]: 'Position Name Required',
  [TalentPoolValidationErrorCodes.PositionCategoryRequired]: 'Position Category Required',
  [TalentPoolValidationErrorCodes.PositionIdRequired]: 'Position Required',
  [JobBoardValidationErrorCodes.RequiredSettingMissedErrorCode]: 'Required Setting Missed',
  [JobBoardValidationErrorCodes.PostingDurationMissedErrorCode]: 'Posting Duration Missed',
  [JobBoardValidationErrorCodes.CountryInvalidErrorCode]: 'Country Invalid',
  [JobBoardValidationErrorCodes.LocationInvalidErrorCode]: 'Location Invalid',
  [JobAdValidationErrorCodes.LocationInvalidErrorCode]: 'Location Invalid',
  [JobAdValidationErrorCodes.RequiredAdditionalFieldInvalidErrorCode]: 'Required Additional Field Invalid',
  [JobAdValidationErrorCodes.SectorInvalidErrorCode]: 'Sector Invalid',
  [CompanyEmailTemplateValidationErrorCodes.BothTemplatesInUse]: 'Both Templates In Use',
  [CompanyEmailTemplateValidationErrorCodes.InvalidTemplateId]: 'Invalid Template Id',
  [CompanyEmailTemplateValidationErrorCodes.BackupTemplateIdFromWrongCompany]: 'Backup Template Id From Wrong Company',
  [CompanyEmailTemplateValidationErrorCodes.NoBackupTemplateIdSpecified]: 'No Backup Template Id Specified',
  [UserValidationErrorCodes.FirstNameEmptyErrorCode]: 'First Name Empty Error Code',
  [UserValidationErrorCodes.LastNameEmptyErrorCode]: 'Last Name Empty Error Code',
  [UserValidationErrorCodes.EmailEmptyErrorCode]: 'Email Empty Error Code',
  [UserValidationErrorCodes.EmailInvalidErrorCode]: 'Email Invalid Error Code',
  [UserValidationErrorCodes.EmailUsedErrorCode]: 'Email Used Error Code',
  [UserValidationErrorCodes.ApplicationRoleInvalidErrorCode]: 'ApplicationRoleInvalidErrorCode',
  [InterviewTemplateValidationErrorCodes.InterviewTemplateNotExists]: 'Interview Template Not Exists',
  [InterviewTemplateValidationErrorCodes.InterviewTemplateNameNotUnique]: 'Interview Template Name Not Unique',
  [InterviewTemplateValidationErrorCodes.AllInterviewTemplateQuestionsMustBeListedInOrder]:
    'All Interview Template Questions Must Be Listed In Order',
  [InterviewTemplateValidationErrorCodes.InterviewTemplateAssignedToJobCannonBeDeleted]:
    'Interview Template Assigned To Job Cannot Be Deleted',
  [InterviewTemplateValidationErrorCodes.InterviewTemplateAssignedToPipelineStageCannotBeDeleted]:
    'Interview Template Assigned To Pipeline Cannot Be Deleted',
  [InterviewTemplateValidationErrorCodes.InterviewTemplateAssignedToPipelineStageCannotBeEdited]:
    'Interview Template Assigned To Pipeline Cannot Be Edited',
  [InterviewValidationErrorCodes.InterviewStartDateMustBeLessThanOrEqualToCurrentDate]:
    'Interview Start Date Must Be Less Than Or Equal To Current Date',
  [InterviewValidationErrorCodes.InterviewEndDateMustBeGreaterThanStartDate]:
    'Interview End Date Must Be Greater Than Start Date',
  [InterviewValidationErrorCodes.OneOrMoreInterviewersAreNotExist]: 'One Or More Interviewers Are Not Exist',
  [InterviewValidationErrorCodes.AllMandatoryQuestionsMustBeAnswered]: 'All Mandatory Questions Must Be Answered',
  [InterviewValidationErrorCodes.OperationIsNotPermitted]: 'Operation Is Not Permitted',
  [InterviewQuestionsValidationErrorCodes.EmptyMandatoryAnswer]: 'Empty Mandatory Answer',
  [InterviewQuestionsValidationErrorCodes.InvalidAnswer]: 'Invalid Answer',
  [InterviewQuestionsValidationErrorCodes.TooLongAnswer]: 'Answer is Too Long',
  [InterviewQuestionsValidationErrorCodes.EmptyFileName]: 'Empty File Name',
  [InterviewQuestionsValidationErrorCodes.UnsupportedFileType]: 'Unsupported File Type',
  [InterviewQuestionsValidationErrorCodes.TooBigFileSize]: 'Too Big File Size',
  [InterviewTemplateQuestionsValidationErrorCodes.MandatoryInterviewTemplateQuestionMustHaveAnswer]:
    'Mandatory Interview Template Question Must Have Answer',
  [PositionValidationErrors.PositionNotExists]: 'Position Not Exists',
  [PositionValidationErrors.PositionAlreadyExists]: 'Position Already Exists',
  [PositionValidationErrors.InvalidEmploymentTypes]: 'Invalid Employment Types',
  [PositionValidationErrors.InvalidEmploymentTypeName]: 'Invalid Employment Type Name',
  [PositionValidationErrors.PositionCategoryNameIsEmpty]: 'Position Category Name Is Empty',
  [JobScreeningValidationErrorCodes.QuestionsAreLocked]: 'Questions Are Locked',
};

export default validationErrorCodes;
