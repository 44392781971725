import React from 'react';
import styled from 'styled-components/macro';

type ApplicantSideModalViewBtnProps = {
  className?: string;
  title: string;
  onClick: () => void;
};

const ApplicantSideModalViewBtnStyled = styled.button`
  transform: rotate(-90deg);
  transform-origin: bottom right;
  border: 0;
  background: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  text-align: center;
  padding: 11px 15px;
  white-space: nowrap;
  top: 30px;
`;

const useApplicantSideModalViewBtnState = ({ className, title, onClick }: ApplicantSideModalViewBtnProps) => {
  const onClickHandler = () => {
    onClick && onClick();
  };

  return {
    className,
    title,
    onClickHandler,
  } as const;
};

export const ApplicantSideModalViewBtn: React.FC<ApplicantSideModalViewBtnProps> = (props) => {
  const { className, title, onClickHandler } = useApplicantSideModalViewBtnState(props);

  return (
    <ApplicantSideModalViewBtnStyled className={className} onClick={onClickHandler}>
      {title}
    </ApplicantSideModalViewBtnStyled>
  );
};
