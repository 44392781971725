import { JOB_AD_ID_PARAMETER } from '@public/utils/generateKeyName';
import { push } from 'connected-react-router';
import { all, call, put, select } from 'redux-saga/effects';

import { JobApplicantValidationErrorCodes } from 'model/api-errors.constants';

import { basicPublicUrl } from 'pages/public/state/index';
import { publicCompanyActions } from 'pages/public/state/public-company';
import { publicCompanySelectors } from 'pages/public/state/public-company/public-company.selectors';
import { publicNewApplicantFormActions } from 'pages/public/state/public-new-applicant-form/public-new-applicant-form.actions';
import { publicNewApplicantFormSelectors } from 'pages/public/state/public-new-applicant-form/public-new-applicant-form.selectors';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { getTranslate } from 'utils/i18utils';
import { prepareExModalChannel } from 'utils/sagas';

import { SocialNetwork } from 'store/entities/candidates/models';
import { exModalPropsAction } from 'store/modals/modals.actions';

export function* publicSubmitApplicationSaga(
  action: ReturnType<typeof publicNewApplicantFormActions.publicNewApplicantFormSubmitAction>,
) {
  const { jobId } = action.payload;
  const { modalId, sagaChannel } = yield prepareExModalChannel();

  const publicNewApplicantForm: ReturnType<typeof publicNewApplicantFormSelectors.publicNewApplicantFormSelector> =
    yield select(publicNewApplicantFormSelectors.publicNewApplicantFormSelector);

  const jobScreeningQuestionsWithAnswers: ReturnType<
    typeof publicNewApplicantFormSelectors.publicNewApplicantJobScreeningQuestionsWithAnswersSelector
  > = yield select(publicNewApplicantFormSelectors.publicNewApplicantJobScreeningQuestionsWithAnswersSelector);

  const { screeningQuestions, ...restFormData } = publicNewApplicantForm;

  const questionsWithAnswers = jobScreeningQuestionsWithAnswers.map(
    ({ itemId: jobScreeningQuestionId, questionType, answer }) => ({
      jobScreeningQuestionId,
      questionType,
      answer,
    }),
  );

  const socialNetworks = publicNewApplicantForm.socialNetworks?.filter(
    (socialNetwork) => socialNetwork.type === SocialNetwork.LinkedIn && Boolean(socialNetwork.url),
  );

  const formData = {
    ...restFormData,
    socialNetworks,
    jobId,
    questions: questionsWithAnswers,
  };

  yield put(publicNewApplicantFormActions.setProcessing(true));
  const companyId = yield select(publicCompanySelectors.selectPublicCompanyId);
  const url = `${basicPublicUrl}/api/company/${companyId}/job/${jobId}/applicant`;
  const res = yield call(fetch, url, { method: 'POST', body: JSON.stringify(formData) });
  const json = yield call([res, 'json']);
  const applicantId = json.applicantId;
  const apiErrors: JobApplicantValidationErrorCodes[] = json.validationErrorCodes ?? [];

  if (apiErrors.length) {
    yield put(publicNewApplicantFormActions.setProcessing(false));
    /**
     * case 1
     * case 2
     */
    // case 3 CandidateAppliedBefore
    if (apiErrors.includes(JobApplicantValidationErrorCodes.CandidateAppliedBefore)) {
      yield put(
        exModalPropsAction({
          id: modalId,
          modalType: ModalsTypeKey.confirmModal,
          isOpen: true,
          modalConfig: {
            content: {
              title: 'Your application is received',
              withTitle: true,
              buttonOk: null,
              buttonCancelVariant: 'primary',
              withActions: true,
              message: 'Candidate Applied Before.',
            },
          },
        }),
      );
      return;
    }

    yield all(
      apiErrors.map((validationErrorCode: string) => {
        const validationErrorMessage = getTranslate(validationErrorCode, {
          ns: 'validationErrorCodes',
        });
        return put(alertsEffects.showError({ message: validationErrorMessage || 'Error' }));
      }),
    );

    yield put(publicNewApplicantFormActions.setApiErrors(apiErrors));
    return;
  }

  if (applicantId) {
    yield put(
      publicCompanyActions.setQueryParameter({ companyId, jobId, parameter: JOB_AD_ID_PARAMETER, value: null }),
    );
    yield put(push(`/public/${companyId}/${jobId}/submitted`));
    yield put(publicNewApplicantFormActions.setProcessing(false));
    return;
  }

  yield put(alertsEffects.showError({ message: 'Error' }));

  yield put(publicNewApplicantFormActions.setProcessing(false));

  sagaChannel.close();
}
