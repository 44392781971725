/**
 *  Get file name from headers `Content-Disposition`
 *
 * @export
 * @param {*} headers  response headers
 * @return {string|undefined}  file name
 */
export function getFileNameFromHeaders(headers: any): string | undefined {
  let fileName: string | undefined;
  const contentDispositionHeader: string | undefined = headers['content-disposition'];

  if (contentDispositionHeader) {
    // get filename from content-disposition header, and remove quotes from it
    fileName = contentDispositionHeader.split('filename=')[1]?.split(';')[0]?.replace(/['"]/g, '');
  }
  return fileName;
}
