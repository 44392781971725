import React from 'react';
import { Link } from 'react-router-dom';

import { AlertIcon, AlertStyled, AlertText } from 'components/Alert/alert.styles';
import { IconSuggested } from 'components/Icons/IconSuggested';

type AlertProps = {
  className?: string;
  children?: any;
};

const useAlertState = ({ className, children }: AlertProps) => {
  return {
    className,
    children,
  } as const;
};

export const Alert: React.FC<AlertProps> = (props) => {
  const { className, children } = useAlertState(props);

  return (
    <AlertStyled className={className}>
      <AlertIcon>
        <IconSuggested />
      </AlertIcon>
      <AlertText>{children}</AlertText>
    </AlertStyled>
  );
};

type AlertLinkProps = AlertProps & {
  to: string;
};

export const AlertLink: React.FC<AlertLinkProps> = (props) => {
  const { className, children } = useAlertState(props);
  const { to } = props;

  return (
    <AlertStyled as={Link} className={className} to={to}>
      <AlertIcon>
        <IconSuggested />
      </AlertIcon>
      <AlertText>{children}</AlertText>
    </AlertStyled>
  );
};
