import styled from 'styled-components';

import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

/**
 * ExAvatarWrapper
 *
 * @param {number} size - size in px which describe size of inner elements (CandidateAvatar)
 */
export const ExAvatarWrapper = styled.div<{ size?: number }>`
  --size: ${(props) => props.size ?? 40}px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  z-index: 1;
  box-shadow: 0 0 0 1px #fff;
  &:not(:first-child) {
    margin-left: -${(props) => (props.size ? props.size / 2 : 15)}px;
  }
`;
export const ExAvatarsWrapper = styled.div`
  display: flex;
  margin-right: 5px;
`;
export const ExAvatarCount = styled(ExAvatarWrapper)`
  font-size: ${getThemeFontSize('small')};
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  background-color: ${(p) => p.theme.colors.defaultLight};
`;
export const ExAvatarCountText = styled.div`
  font-size: ${getThemeFontSize('small')};
  align-self: center;
`;
