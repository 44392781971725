import { useEffect } from 'react';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { QueryHooks } from '@reduxjs/toolkit/dist/query/react/buildHooks';

export const useQueryForLazy = <T extends QueryHooks<QueryDefinition<any, any, any, any>>['useLazyQuery']>(
  query: T,
) => {
  const [queryFetcher, { data, isSuccess }] = query({
    selectFromResult: ({ data: innerData, ...rest }) => ({
      ...rest,
      data: { ...innerData, items: innerData ? innerData.ids.map((id) => innerData.entities[id]!) : [] },
    }),
  });

  useEffect(() => {
    queryFetcher(undefined, true);
  }, [queryFetcher]);

  const loader = async (searchString: string) => {
    return data?.items.filter((country) => country.name.toLowerCase().includes(searchString));
  };

  return {
    loader,
    isSuccess,
  };
};
