import * as effects from './effects';
import { applicantListsSlice } from './reducers';
import * as selectors from './selectors';

const { reducer } = applicantListsSlice;

export { applicantListActions } from 'containers/ApplicantLists/store/reducers';

export const applicantListSelectors = selectors;

export const applicantListEffects = effects;

export default reducer;

export * from './models';
