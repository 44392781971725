import { ExInterview } from 'model';
import { InterviewOrderProperty } from 'model/api-enums.constants';

import { InterviewTableActionButton } from 'pages/Jobs/Job/JobTabs/JobInterviews/components/InterviewTableActionButton';
import { InterviewTableApplicantName } from 'pages/Jobs/Job/JobTabs/JobInterviews/components/InterviewTableApplicantName';
import { InterviewTableDate } from 'pages/Jobs/Job/JobTabs/JobInterviews/components/InterviewTableDate';
import { InterviewTableInterviewTemplate } from 'pages/Jobs/Job/JobTabs/JobInterviews/components/InterviewTableInterviewTemplate';
import { InterviewTablePublishedBy } from 'pages/Jobs/Job/JobTabs/JobInterviews/components/InterviewTablePublishedBy';

import { InterviewStatus } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/Tabs/InterviewsTab/components/InterviewStatus';
import { InterviewTitle } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/Tabs/InterviewsTab/components/InterviewTitle';

import { ExHeaderProps } from 'components/ui/ExTable/ExTable.props';
import { EnumLiteralsOf } from 'utils';

const interviewTableHeaders: Record<
  string,
  ExHeaderProps<ExInterview, EnumLiteralsOf<typeof InterviewOrderProperty>>
> = {
  InterviewName: {
    label: 'Interview Name',
    isSortable: true,
    sortBy: InterviewOrderProperty.Name,
    component: InterviewTitle,
  },
  InterviewTemplate: {
    label: 'Template',
    isSortable: false,
    component: InterviewTableInterviewTemplate,
  },
  ApplicantName: {
    label: 'Applicant Name',
    isSortable: true,
    sortBy: InterviewOrderProperty.JobApplicantName,
    component: InterviewTableApplicantName,
  },
  PublishedDate: {
    label: 'Published Date',
    isSortable: true,
    sortBy: InterviewOrderProperty.PublishedOn,
    component: InterviewTableDate,
  },
  PublishedBy: {
    label: 'Published By',
    isSortable: false,
    component: InterviewTablePublishedBy,
  },
  Status: {
    label: 'Status',
    isSortable: true,
    sortBy: InterviewOrderProperty.State,
    component: InterviewStatus,
    width: 110,
  },
  Actions: {
    label: '',
    component: InterviewTableActionButton,
    width: 70,
  },
};

export const jobInterviewsTableHeaders: ExHeaderProps<ExInterview, EnumLiteralsOf<typeof InterviewOrderProperty>>[] = [
  interviewTableHeaders.InterviewName,
  interviewTableHeaders.InterviewTemplate,
  interviewTableHeaders.ApplicantName,
  interviewTableHeaders.PublishedDate,
  interviewTableHeaders.PublishedBy,
  interviewTableHeaders.Status,
  interviewTableHeaders.Actions,
];

export const applicantInterviewsTableHeaders: ExHeaderProps<
  ExInterview,
  EnumLiteralsOf<typeof InterviewOrderProperty>
>[] = [
  interviewTableHeaders.InterviewName,
  interviewTableHeaders.InterviewTemplate,
  interviewTableHeaders.PublishedDate,
  interviewTableHeaders.PublishedBy,
  interviewTableHeaders.Status,
  interviewTableHeaders.Actions,
];
