import { FeatureMap } from '@expedo/feature-flag';

export enum Features {
  GlobalNav = 'db4fad12-68bb-455c-abac-a02b2c9364d8',
}

export const featureMap: FeatureMap = {
  [Features.GlobalNav]: {
    name: 'global-nav',
    enabled: {
      value: true,
      environment: ['dev', 'qa', 'staging', 'production'],
      context: ['hire'],
    },
  },
};
