import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import { useForm } from 'react-hook-form';
import { Mention, MentionsInput } from 'react-mentions';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppRouterParams } from 'router';
import * as yup from 'yup';
import styled, { useTheme } from 'styled-components';

import { ExComment } from 'model';

import { FormTooltip } from 'components/FormTooltip';
import { ExButton } from 'components/ui/ExButton';
import { ExVisible } from 'components/ui/ExVisible';

import { JobTeamMember } from 'store/entities/jobs/models';

import { useAdminTeamMembersForComments } from './hooks/useAdminTeamMembersForComments';
import { useJobTeamMembersForComments } from './hooks/useJobTeamMembersForComments';
import { ExCommentDropdownItem } from './ExCommentDropdownItem';
import type { CommentAddHandler, CommentDeleteHandler, CommentUpdateHandler } from './ExCommentListProps';

type ExCommentFormProps = {
  className?: string;
  text?: string;
  commentId?: string;
  onSubmit?: CommentAddHandler | CommentDeleteHandler | CommentUpdateHandler;
  isEdit?: boolean;
  onCancel?: () => void;
  commentTarget?: 'JOB' | 'CANDIDATE';
};

const FormInputArea = styled(FormControl)<{ $isInvalid: boolean }>`
  width: 100%;
  min-height: 3rem;
  resize: none;
  border: 1px solid ${(props) => props.theme.border.borderColor};
  border-color: ${({ theme, $isInvalid }) => $isInvalid && theme.colors.danger};
  border-radius: ${(props) => props.theme.border.borderRadius};
  overflow: hidden;

  &:focus-visible,
  &:focus,
  &:active {
    outline: none;
  }
`;

const FormStyled = styled.form``;

const ActionButtons = styled.div`
  padding-top: 10px;
  display: flex;
  align-items: center;

  & > button {
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
`;

const ActionButton = styled(ExButton)`
  padding: 0.275rem 0.55rem !important;
`;

const exCommentFormValidationSchema: yup.SchemaOf<Pick<ExComment, 'text'>> = yup.object().shape({
  text: yup.string().trim().required().max(300).label('Message'),
});

const ExCommentFormStyled = styled.div``;

type MentionedUsers = JobTeamMember & { display: string };

const useMentionState = () => {
  const [mentionedUsers, setMentionedUsers] = useState<MentionedUsers[]>([]);

  const getMentionedUserIds = useCallback(() => {
    const mentionedUserIds = mentionedUsers.map((mention) => mention.id);
    return [...new Set([...mentionedUserIds])];
  }, [mentionedUsers]);

  return {
    setMentionedUsers,
    getMentionedUserIds,
  };
};

const useExCommentFormState = ({
  className,
  commentId,
  onSubmit,
  isEdit,
  onCancel = () => {},
  text = '',
  commentTarget = 'JOB',
}: ExCommentFormProps) => {
  const { handleSubmit, errors, register, watch, reset, setValue, formState } = useForm<{ text: string }>({
    defaultValues: { text },
    resolver: yupResolver(exCommentFormValidationSchema),
    shouldFocusError: true,
  });

  const { jobId } = useAppRouterParams();
  const mentionDataForJob = useJobTeamMembersForComments();
  const mentionDataForCandidate = useAdminTeamMembersForComments().map((member) => ({
    ...member,
    display: member.name,
  }));
  const mentionData = commentTarget === 'JOB' ? mentionDataForJob : mentionDataForCandidate;
  const theme = useTheme();
  const { setMentionedUsers, getMentionedUserIds } = useMentionState();

  const validated = formState.isSubmitted;
  const submitButtonRef = useRef<any>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const textFieldValue = watch('text');
  const textLength = textFieldValue.length;
  const showActionButtons = useMemo(
    () => isEdit || commentTarget === 'CANDIDATE' || textLength > 0,
    [isEdit, commentTarget, textLength],
  );
  const showErrorTooltip = validated && !!errors.text;

  const handleOnCancel = useCallback(() => {
    onCancel();
    reset({ text: '' });
  }, [onCancel, reset]);

  const handleSubmitForm = handleSubmit((data: any) => {
    if (onSubmit instanceof Function) {
      const mentionedUsers = getMentionedUserIds();
      onSubmit({ ...data, mentionedUsers, commentId });
    }
    reset({ text: '' });
  });

  const submitClickHandler: React.MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    submitButtonRef.current.click();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      submitButtonRef.current.click();
    }
  };

  const handleChange = useCallback(
    (...[, newValue, , mentions]) => {
      setValue('text', newValue, { shouldValidate: validated });
      setMentionedUsers(mentions);
    },
    [setMentionedUsers, setValue, validated],
  );

  const onBlur = () => {
    if (!textFieldValue) {
      reset({ text: '' });
    }
  };

  useEffect(() => {
    register('text');
  }, [register]);

  const renderUserSuggestion = useCallback(
    (entry) => {
      return <ExCommentDropdownItem jobId={jobId} userId={entry.userId || entry.id} commentTarget={commentTarget} />;
    },
    [commentTarget, jobId],
  );

  const mentionStyles = {
    backgroundColor: theme.colors.defaultLight,
    borderRadius: '20px',
    cursor: 'pointer',
    padding: '0px 0.1em 2px 0.1em',
    lineHeight: '1',
    fontSize: '1em',
    fontWeight: 'normal',
    wordBreak: 'break-word',
  };

  return {
    className,
    errors,
    handleKeyDown,
    handleOnCancel,
    handleSubmitForm,
    isEdit,
    onBlur,
    register,
    showActionButtons,
    showErrorTooltip,
    submitButtonRef,
    submitClickHandler,
    textAreaRef,
    setValue,
    textFieldValue,
    handleChange,
    mentionData,
    renderUserSuggestion,
    mentionStyles,
  } as const;
};

const inputStyles = {
  border: 'none',
  padding: '5px 10px',
  outline: 0,
  background: '#ffffff',
};

const mentionInputStyles = {
  input: inputStyles,
  highlighter: inputStyles,
  suggestions: {
    zIndex: '9999',
    list: {
      width: '280px',
      maxHeight: '380px',
      background: '#fff',
      borderRadius: '3px',
      padding: '4px 0',
      boxShadow: 'rgb(9 30 66 / 31%) 0px 0px 1px, rgb(9 30 66 / 25%) 0px 4px 8px -2px',
      overflowY: 'auto',
    },
    item: {
      overflow: 'hidden',
      height: '48px',
      cursor: 'pointer',
      display: 'flex',
      '&focused': {
        background: '#ebecf0',
      },
    },
  },
};

export const ExCommentForm: React.FC<ExCommentFormProps> = (props) => {
  const {
    className,
    errors,
    handleKeyDown,
    submitButtonRef,
    isEdit,

    showActionButtons,
    handleSubmitForm,
    handleOnCancel,
    textAreaRef,
    showErrorTooltip,
    submitClickHandler,
    onBlur,
    textFieldValue,
    handleChange,
    mentionData,
    renderUserSuggestion,
    mentionStyles,
  } = useExCommentFormState(props);

  return (
    <ExCommentFormStyled className={className}>
      <FormStyled noValidate onSubmit={handleSubmitForm}>
        <input ref={submitButtonRef} type="submit" hidden />
        <FormInputArea
          as={MentionsInput}
          value={textFieldValue}
          inputRef={textAreaRef}
          onBlur={onBlur}
          allowSpaceInQuery
          style={mentionInputStyles}
          suggestionsPortalHost={document.body}
          allowSuggestionsAboveCursor
          onChange={handleChange}
          placeholder="Leave a comment"
          onKeyDown={handleKeyDown}
          $isInvalid={showErrorTooltip}
        >
          <Mention
            trigger="@"
            data={mentionData}
            renderSuggestion={renderUserSuggestion}
            style={mentionStyles}
            markup={'@[__display__](__id__)'}
            displayTransform={(id, display) => `@${display}`}
            appendSpaceOnAdd
          />
        </FormInputArea>
        <FormTooltip target={textAreaRef} show={showErrorTooltip} errors={errors?.text} offset={[0, 3]} />
        <ExVisible visible={showActionButtons}>
          <ActionButtons>
            <ActionButton variant="light" onClick={handleOnCancel}>
              Cancel
            </ActionButton>
            <ActionButton onClick={submitClickHandler} variant="primary">
              {isEdit ? 'Save' : 'Add'}
            </ActionButton>
          </ActionButtons>
        </ExVisible>
      </FormStyled>
    </ExCommentFormStyled>
  );
};
