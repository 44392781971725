import React from 'react';
import styled from 'styled-components/macro';

import { useSinglePipeline } from 'pages/Jobs/Job/hooks/useSinglePipeline';

import { ApplicantStatusBadgeDinamicHiringPipeline } from 'containers/ApplicantLists/ApplicantStatusBadge';
import { loadApplicant } from 'containers/ApplicantLists/store/effects';
import {
  ApplicantStatusBadgeWrapper,
  ApplicantWorkExperience,
  ApplicantWorkExperiencePosition,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/ApplicantModalV3Components';
import { usePushToSiblingApplicantConfig } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalFooter/ApplicantModalFooterView';
import {
  ApplicantRatingStars,
  useApplicantRating,
  useApplicantWorkExperience,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalStatusInfo/ApplicantModalStatusInfoComponents';
import { useApplicantFromModal } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/hook/useApplicantFromModal';

import { FormTagSelect } from 'components';
import { BadgeDoNotHire } from 'components/Badge';
import { Ellipsis } from 'components/Ellipsis';
import { ExVisible } from 'components/ui/ExVisible';
import { useLoaderSubscription } from 'modules/LoaderManager/react';
import { useApplicantsByLocation } from 'utils/hooks/applicant';
import { usePipelineStageChecks } from 'utils/hooks/hiringPipelineStage';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useNavigation } from 'utils/hooks/useNavigation';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { applicantsActions } from 'store/entities/applicants';
import { hiringPipelinesStagesSelectors } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.reducer';

type ApplicantModalStatusInfoViewProps = {
  className?: string;
  readonly?: boolean;
};

const ApplicantModalStatusInfoViewStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BadgeDoNotHireStyled = styled(BadgeDoNotHire)`
  margin-bottom: 20px;
`;

const FormTagSelectStyled = styled(FormTagSelect)`
  width: 100%;
`;

const useApplicantModalStatusInfoViewState = ({ className }: ApplicantModalStatusInfoViewProps) => {
  const applicant = useApplicantFromModal();

  const hasApplicant = Boolean(applicant);
  const applicantId = applicant?.applicantId || '';
  const isDoNotHire = Boolean(applicant?.doNotHire);
  const isLocked = applicant?.isLocked;

  return {
    className,
    hasApplicant,
    applicantId,
    isDoNotHire,
    isLocked,
  } as const;
};

const getLabelForMenuItems = (stage) => {
  if (stage.isRequired) {
    return `${stage.name} *`;
  }

  return stage.name;
};

const useApplicantMoveToState = ({ readonly = false }: ApplicantModalStatusInfoViewProps) => {
  const dispatch = useAppDispatch();
  const applicant = useApplicantFromModal();
  const applicantId = applicant?.applicantId;
  const { generatePath } = useNavigation();
  const pushToSiblingApplicantConfig = usePushToSiblingApplicantConfig();
  const applicants = useApplicantsByLocation(applicant);

  const jobId = applicant?.jobId;
  const pipelineId = applicant?.pipelineId;
  const pipelineStageId = applicant?.pipelineStageId ?? '';

  useSinglePipeline({ pipelineId });

  const isNewApplicant = useAppSelector(hiringPipelinesStagesSelectors.selectIsNewStage, pipelineStageId);

  const isPipelineStageIsHiredStage = useAppSelector(
    hiringPipelinesStagesSelectors.selectIsPipelineStageIsHiredStage,
    pipelineStageId,
  );

  const showMoveTo = !readonly && !isNewApplicant && !isPipelineStageIsHiredStage;

  const pipelineStages = useAppSelector(hiringPipelinesStagesSelectors.selectSimilarByPipelineStageId, pipelineStageId);

  const { isInOnboardingDisabled } = usePipelineStageChecks();

  const moveToMenuItems = pipelineStages.map((stage) => ({
    value: stage.pipelineStageId,
    label: getLabelForMenuItems(stage),
    isDisabled: isInOnboardingDisabled({ stageType: stage.stageType }) || stage.pipelineStageId === pipelineStageId,
  }));

  const isPrevButtonDisabled = applicants?.length <= 1;
  const isNextButtonDisabled = applicants?.length <= 1;

  const handlerMoveApplicant = async ({ value }: { value: string }) => {
    if (!jobId || !applicantId) {
      return;
    }

    let pushConfig: undefined | ReturnType<typeof pushToSiblingApplicantConfig> = undefined;
    let pushPath: undefined | ReturnType<typeof generatePath> = undefined;

    if (!isNextButtonDisabled) {
      pushConfig = pushToSiblingApplicantConfig('next');
    } else if (!isPrevButtonDisabled) {
      pushConfig = pushToSiblingApplicantConfig('prev');
    }

    if (pushConfig) {
      pushPath = generatePath(pushConfig.path, pushConfig.config);
    }

    dispatch(
      applicantsActions.applicantsStageChange({
        applicantId,
        currentStageId: pipelineStageId,
        jobId,
        stageId: value,
        pushPath,
      }),
    );
  };

  const { isLoading: isApplicantLoading } = useLoaderSubscription({ type: loadApplicant.name });
  const { isLoading: applicantsStageChangeProcessing } = useLoaderSubscription(applicantsActions.applicantsStageChange);
  const isProcessing = [isApplicantLoading, applicantsStageChangeProcessing].some(Boolean);

  return {
    showMoveTo,
    moveToMenuItems,
    handlerMoveApplicant,
    isProcessing,
    readonly,
  };
};

export const ApplicantModalStatusInfoView: React.FC<ApplicantModalStatusInfoViewProps> = (props) => {
  const { className, hasApplicant, applicantId, isDoNotHire, isLocked } = useApplicantModalStatusInfoViewState(props);

  const { applicantRating, ratingChangeClickHandler } = useApplicantRating();
  const { hasWorkExperience, workExperiencePosition, workExperienceCompanyName, dates } = useApplicantWorkExperience();
  const { showMoveTo, moveToMenuItems, handlerMoveApplicant, isProcessing, readonly } = useApplicantMoveToState(props);

  return (
    <ApplicantModalStatusInfoViewStyled className={className}>
      <ApplicantStatusBadgeWrapper>
        {hasApplicant ? <ApplicantStatusBadgeDinamicHiringPipeline applicantId={applicantId} /> : null}
      </ApplicantStatusBadgeWrapper>
      <ApplicantRatingStars rating={applicantRating} onChange={ratingChangeClickHandler} readonly={readonly} />
      <ExVisible visible={isDoNotHire}>
        <BadgeDoNotHireStyled />
      </ExVisible>
      <ExVisible visible={hasWorkExperience}>
        <ApplicantWorkExperience>
          <ApplicantWorkExperiencePosition>{workExperiencePosition}</ApplicantWorkExperiencePosition>
          <Ellipsis title={workExperienceCompanyName ?? ''}>{workExperienceCompanyName}</Ellipsis>
          <div>({dates})</div>
        </ApplicantWorkExperience>
      </ExVisible>
      {showMoveTo && (
        <FormTagSelectStyled
          key={applicantId}
          isSearchable={false}
          isMulti={false}
          defaultOptions={moveToMenuItems}
          onChange={handlerMoveApplicant}
          isDisabled={isLocked || isProcessing}
          placeholder="Move to"
          value=""
        />
      )}
    </ApplicantModalStatusInfoViewStyled>
  );
};
