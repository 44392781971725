import { createSlice } from '@reduxjs/toolkit';

import {
  ApplicantForm,
  ApplicantFormState,
  FormApplicantCaseReducer,
} from 'containers/ApplicantForm/ApplicantFormState/applicant-form.models';

import { DocumentFile } from 'store/app-files/documents/documents.models';

export const formApplicantKey = 'formApplicant';

export const formApplicantInitialState: ApplicantFormState = {
  validSteps: [],
  showSteps: true,
  formData: {},
  selectedCandidateId: null,
  selectedJobId: null,
  selectedApplicantId: null,
  selectedDocuments: [],
  selectedResumes: [],
};

const resetState: FormApplicantCaseReducer<any> = (state) => ({
  ...state,
  ...formApplicantInitialState,
});

const addUploadedDocument: FormApplicantCaseReducer<DocumentFile> = (state, { payload }) => {
  state.selectedDocuments.push(payload);
};

const saveForm: FormApplicantCaseReducer<{ formData: Partial<ApplicantForm> }> = (state, { payload }) => {
  state.formData = {
    ...state.formData,
    ...payload.formData,
  };
};

const setState: FormApplicantCaseReducer<Partial<ApplicantFormState>> = (state, { payload }) => ({
  ...state,
  ...payload,
});

const toggleShowSteps: FormApplicantCaseReducer<{ showSteps: boolean }> = (state, { payload }) => ({
  ...state,
  ...payload,
});

type FormApplicantCaseReducers = {
  resetState: typeof resetState;
  toggleShowSteps: typeof toggleShowSteps;
  saveForm: typeof saveForm;
  setState: typeof setState;
  addUploadedDocument: typeof addUploadedDocument;
};

const applicantFormSlice = createSlice<ApplicantFormState, FormApplicantCaseReducers>({
  name: formApplicantKey,
  initialState: formApplicantInitialState,
  reducers: {
    resetState,
    addUploadedDocument,
    saveForm,
    setState,
    toggleShowSteps,
  },
});

export const applicantFormActions = applicantFormSlice.actions;

export default applicantFormSlice;
