import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { deleteCandidateDocument, getCandidateInfoById, uploadCandidateInfoById } from 'api-endpoints/candidate';

import { ApiResponseWithPagination, AppFile } from 'model';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { loaderActions } from 'containers/Loader/store';

import { isDocumentFile } from 'utils/funcs';

import { showValidationErrorCodes } from 'store/app-files/apFiles.effects';
import { DocumentFile, DocumentFilesParams } from 'store/app-files/documents/documents.models';
import { documentsActions } from 'store/app-files/documents/documents.reducer';
import { Candidate, CandidateIfoKey } from 'store/entities/candidates/models';
import { AppEffectParams, ThunkApiConfig } from 'store/types';

const DOCUMENTS_FETCH = 'documents/fetch';
const DOCUMENTS_UPLOAD = 'documents/upload';
const DOCUMENTS_REMOVE = 'documents/remove';

export const createDocumentUploadLoaderId = (candidateId = '') => `${DOCUMENTS_UPLOAD}/${candidateId}`;

export const uploadDocumentFiles = createAsyncThunk<
  { success: boolean; data: any },
  AppEffectParams<{ formData: FormData; candidateId: Candidate['id'] }>,
  ThunkApiConfig
>(DOCUMENTS_UPLOAD, async ({ preloader, candidateId, formData }, { dispatch, signal }) => {
  const source = axios.CancelToken.source();
  const loaderId = createDocumentUploadLoaderId(candidateId);
  if (preloader) {
    dispatch(loaderActions.start(loaderId));
  }
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const { message, response, data } = await uploadCandidateInfoById(
    candidateId,
    CandidateIfoKey.document,
    formData,
    source,
  );
  if (preloader) {
    dispatch(loaderActions.stop(loaderId));
  }
  if (message) {
    if (response?.data.validationErrorCodes) {
      showValidationErrorCodes(dispatch, response.data.validationErrorCodes, message);
    } else {
      dispatch(
        alertsEffects.showError({
          message,
        }),
      );
    }
    return { data, success: false };
  }
  if (isDocumentFile(data)) {
    dispatch(documentsActions.upsertOne(data));
  }
  return { data, success: true };
});

export const loadDocumentFiles = createAsyncThunk<
  ApiResponseWithPagination<DocumentFile>,
  AppEffectParams<DocumentFilesParams>,
  ThunkApiConfig
>(DOCUMENTS_FETCH, async ({ preloader, candidateId }, { dispatch, signal, requestId, rejectWithValue, getState }) => {
  const { pageParams, query } = getState().appFiles.documents.pagination.requests[requestId] || {};
  const requestParams = { ...pageParams, ...query };

  const source = axios.CancelToken.source();
  if (preloader) {
    dispatch(loaderActions.start(requestId));
  }
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const { data, message } = await getCandidateInfoById<DocumentFile>(
    candidateId,
    CandidateIfoKey.document,
    requestParams,
    source,
  );
  if (preloader) {
    dispatch(loaderActions.stop(requestId));
  }
  if (message) {
    dispatch(
      alertsEffects.showError({
        message,
      }),
    );
    return rejectWithValue(message);
  }
  return data;
});

export const deleteDocumentFile = createAsyncThunk<{ success: boolean; data: AppFile }, any, ThunkApiConfig>(
  DOCUMENTS_REMOVE,
  async ({ preloader, candidateId, documentId }, { dispatch, signal, requestId }) => {
    const source = axios.CancelToken.source();
    if (preloader) {
      dispatch(loaderActions.start(requestId));
    }
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    const { message, response, data } = await deleteCandidateDocument({
      cancelToken: source,
      candidateId,
      documentId,
    });

    if (preloader) {
      dispatch(loaderActions.stop(requestId));
    }

    if (message) {
      if (response?.data.validationErrorCodes) {
        showValidationErrorCodes(dispatch, response.data.validationErrorCodes, message);
      } else {
        dispatch(alertsEffects.showError({ message }));
      }

      return { data, success: false };
    }

    if (isDocumentFile(data)) {
      dispatch(documentsActions.upsertOne(data));
    }
    return { data, success: true };
  },
);
