import React, { HtmlHTMLAttributes } from 'react';
import styled from 'styled-components';

import { IconCloseStyled } from 'components/Icons/IconClose';

const ExCloseIconCloseStyled = styled(IconCloseStyled)`
  width: 100%;
  height: 100%;
`;

const IconCloseWrapper = styled.button`
  display: flex;
  width: 24px;
  padding: 6px;
  cursor: pointer;
  outline: none;
  appearance: none;
  border: 0;
  background: none;
`;

type ExCloseButtonProps = HtmlHTMLAttributes<HTMLButtonElement> & {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  disabled?: boolean;
};

export const ExCloseButton: React.FC<ExCloseButtonProps> = ({ onClick, ...props }) => {
  return (
    <IconCloseWrapper onClick={onClick} {...props}>
      <ExCloseIconCloseStyled />
    </IconCloseWrapper>
  );
};

export const ExCloseButtonSideModal = styled(ExCloseButton)`
  margin-left: auto;
`;
