import styled from 'styled-components';

import { ExDialog } from 'components/ui/ExDialog';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

export const ExModalWrapperStyled = styled(ExDialog)`
  width: 100%;
  margin: 0 auto;
  max-width: 500px;
  background-color: ${(props) => props.theme.colors.white};
`;

export const ExModalBodyStyled = styled.div`
  width: 100%;
`;

export const ExModalBodyStyledWide = styled(ExModalBodyStyled)`
  width: 100vw;
  max-width: 700px;
`;

export const ExModalInputRow = styled.div`
  display: flex;
  align-items: center;
`;

export const ExModalRow = styled.div`
  padding: 10px 0;
`;

export const ExModalLabel = styled.label`
  font-weight: bold;
  font-size: ${getThemeFontSize('cardHeading')};
`;
