import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { CancelableCountdownActionPayload } from 'containers/AlertManager/store/models';
import { alertsActions } from 'containers/AlertManager/store/reducers';

import { ExButton } from 'components/ui/ExButton';
import { ExProgress } from 'components/ui/ExProgress';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

export type CancelableCountdownProps = CancelableCountdownActionPayload & {
  id: string;
};

const StyledProgress = styled(ExProgress)`
  border-width: 0;
  background-color: #ffffff55;

  & div {
    background-color: ${(props) => props.theme.colors.white};
  }
`;

const countdownStep = 100;

const useCancelableCountdownState = ({
  message,
  cancelMessage,
  successMessage,
  duration,
  onCancel,
  id,
}: CancelableCountdownProps) => {
  const dispatch = useAppDispatch();
  const [timeLeft, setTimeLeft] = useState(duration);
  const [displayText, setDisplayText] = useState(message);
  const intervalIdRef = useRef(0);

  const endCountdown = useCallback(
    (cancel: boolean) => {
      clearInterval(intervalIdRef.current);
      setDisplayText(cancel ? cancelMessage : successMessage);
      cancel && onCancel();
      setTimeout(() => dispatch(alertsActions.del(id)), 1500);
    },
    [intervalIdRef, setDisplayText, cancelMessage, successMessage, onCancel, id, dispatch],
  );

  const cancel = useCallback(() => endCountdown(true), [endCountdown]);

  useEffect(() => {
    intervalIdRef.current = window.setInterval(() => {
      setTimeLeft((t) => t - countdownStep);
    }, countdownStep);
    return () => clearInterval(intervalIdRef.current);
  }, [duration, intervalIdRef]);

  useEffect(() => {
    if (timeLeft <= 0) endCountdown(false);
  }, [endCountdown, timeLeft]);

  const timeString = useMemo(() => (timeLeft > 0 ? `${Math.floor(timeLeft / 1000)}s` : ''), [timeLeft]);

  const progressValue = useMemo(() => (timeLeft / duration) * 100, [timeLeft, duration]);

  return { progressValue, displayText, cancel, timeString };
};

export const CancelableCountdown: React.FC<CancelableCountdownProps> = (props) => {
  const { progressValue, displayText, cancel, timeString } = useCancelableCountdownState(props);

  return (
    <div style={{ width: '256px', paddingBottom: '8px' }}>
      <h1>{timeString}</h1>
      <div style={{ position: 'absolute', top: '16px', right: '16px' }}>
        <ExButton onClick={cancel} variant="light">
          Cancel
        </ExButton>
      </div>
      <p>{displayText}</p>
      <StyledProgress value={progressValue} />
    </div>
  );
};
