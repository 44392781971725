import styled, { css } from 'styled-components/macro';

import { Spinner } from 'components/Spinner';

type TextEditorWrapperProps = {
  height?: number;
  isValid?: boolean;
  isInvalid?: boolean;
};

export const TextEditorStatusbar = styled.div`
  height: 39px;
  background: #f0f0f0;
  padding: 0 8px;
  border: 1px solid #ccc;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
`;

export const TextEditorStatusbarTextContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const TextEditorStatusbarWordCount = styled.div`
  color: rgba(34, 47, 62, 0.7);
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 0;
  margin-left: auto;
`;

const isValidTextEditor = ({ isValid }: TextEditorWrapperProps) =>
  isValid &&
  css`
    .tox-tinymce {
      border-color: #47c144;
    }
  `;

const isInvalidTextEditor = ({ isInvalid }: TextEditorWrapperProps) =>
  isInvalid &&
  css`
    .tox-tinymce {
      border-color: #ff6865;
    }

    ${TextEditorStatusbar} {
      border-color: #ff6865;
    }
  `;

const TextEditorWrapper = styled.div<TextEditorWrapperProps>`
  position: relative;
  border-radius: 5px;
  min-height: ${(props) => (props.height ? props.height + 'px' : 'auto')};

  .tox {
    &.tox-tinymce {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom: 0;
    }

    .tox-toolbar__primary {
      background: #f0f0f0;
    }

    .tox-statusbar {
      height: 39px;
      background: #f0f0f0;
    }
  }

  ${isValidTextEditor};
  ${isInvalidTextEditor};
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export { TextEditorWrapper, StyledSpinner };
