import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import { ExModalBodyStyled, ExModalWrapperStyled } from 'containers/Modals/ModalsContent/Company/ExModalComponents';

import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { DialogProps } from 'components/Dialog';
import { FormInput } from 'components/FormInput';
import { ExButton } from 'components/ui/ExButton';
import { ExDialogActions } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { stringOptional } from 'utils/validator-helpers';

import {
  qualificationTypeActions,
  QualificationTypeModel,
} from 'store/entities/qualification-type/qualification-type.model';
import { exModalConfirmAction } from 'store/modals/modals.actions';
import { ExModal } from 'store/modals/modals.interfaces';

type CompanyUpdateQualificationTypesProps = ExModal & AppModalContentProps;

type CompanyUpdateQualificationFormType = Partial<QualificationTypeModel>;

const companyQualificationTypeSchema: yup.SchemaOf<CompanyUpdateQualificationFormType> = yup.object().shape({
  qualificationId: stringOptional,
  qualificationName: yup.string().trim().required().min(2).max(50).label('Qualification type name'),
});

export const CompanyUpdateQualificationTypes = ({
  id,
  onClose,
  modalProps,
  modalConfig,
}: CompanyUpdateQualificationTypesProps) => {
  const dispatch = useAppDispatch();
  const { handleSubmit, errors, formState, register } = useForm<CompanyUpdateQualificationFormType>({
    resolver: yupResolver(companyQualificationTypeSchema),
    defaultValues: modalProps?.qualificationType,
  });
  const content: DialogProps['content'] = {
    title: 'Add Qualification Type',
    withTitle: true,
    ...modalConfig?.content,
  };

  const onSubmitClickHandler = (data: CompanyUpdateQualificationFormType) => {
    dispatch(exModalConfirmAction({ id, modalResult: { ...data } }));
  };

  const action = modalConfig?.content
    ? qualificationTypeActions.qualificationTypeUpdate.start
    : qualificationTypeActions.qualificationTypeCreate;

  return (
    <ExModalWrapperStyled onClose={onClose} content={content}>
      <ExModalBodyStyled>
        <form onSubmit={handleSubmit(onSubmitClickHandler)}>
          <input ref={register} hidden name="qualificationId" />
          <FormInput
            inputRef={register}
            name="qualificationName"
            label="Name"
            required
            errors={errors.qualificationName}
            validated={formState.isSubmitted}
          />
          <ExDialogActions>
            <ExButton variant="light" onClick={onClose}>
              Cancel
            </ExButton>
            <ExLoaderHandlerWrapper action={action}>
              <ExButton className="ml-3" type="submit">
                Save
              </ExButton>
            </ExLoaderHandlerWrapper>
          </ExDialogActions>
        </form>
      </ExModalBodyStyled>
    </ExModalWrapperStyled>
  );
};
