import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Routes } from 'router';
import { ExRoutes } from 'router/routes';
import styled from 'styled-components';

import { PipelineStageType } from 'model/api-enums.constants';

import { ApplicantLocationParams } from 'pages/Jobs/Job/utils';

import {
  applicantFormActions,
  applicantFormEffects,
  ApplicantFormFinalPage,
  applicantFormSelectors,
} from 'containers/ApplicantForm';
import { useMountHook } from 'containers/Modals/ModalsContent/Applicant/ApplicantNew/hooks/useMountHook';
import { initFormStepsV2 } from 'containers/Modals/ModalsContent/Applicant/ApplicantNew/Steps';

import { ExLoaderHandlerWrapperStrict } from 'components/Common/LoaderHandlerWrapper';
import { makeChildren } from 'components/Dialog';
import { FormStepsPanel } from 'components/FormStepsPanel';
import { Step, StepForm, StepFormHeader, useSteps } from 'components/StepForm';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { ExCreateEntityWrapper } from 'components/ui/ExCreateEntityWrapper';
import { ExVisible } from 'components/ui/ExVisible';
import { useBackLinkState } from 'modules/HistoryManager/components/BackLink';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useModal } from 'utils/hooks/useModal';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { applicantsActions } from 'store/entities/applicants';
import { Applicant } from 'store/entities/applicants/models';
import { jobsSelectors } from 'store/entities/jobs';
import { useSelector } from 'store/rootSelectors';

export interface ApplicantNewProps {
  className?: string;
  onClose?: () => void;
}

const useApplicantNewState = ({ className, onClose }: ApplicantNewProps) => {
  const history = useHistory();
  const location = useLocation<ApplicantLocationParams>();
  const pipelineStageId = useRef<string | undefined>();
  useEffect(() => {
    if (location.state?.pipelineStages) {
      pipelineStageId.current = location.state?.pipelineStages;
    }
  }, [location.state?.pipelineStages]);

  const dispatch = useAppDispatch();
  const modal = useModal({ initialOpen: false });

  const { steps, updateSteps, isLastStep } = useSteps({ steps: initFormStepsV2 });
  const defaultValues = useSelector(applicantFormSelectors.selectFormData);
  const showSteps = useSelector(applicantFormSelectors.selectShowSteps);
  const job = useAppSelector(jobsSelectors.getById, defaultValues.jobId);

  const { pathname } = useBackLinkState({});

  const resumeParsingFailedModal = useModal({ initialOpen: false });
  const saveValidStep = useCallback(
    async (path: Step['path']) => {
      updateSteps(path);
      if (!isLastStep) {
        return;
      }
      const result = await dispatch(applicantFormEffects.saveApplicant({ preloader: true }));
      if (!result?.message) {
        if (pipelineStageId.current) {
          const currentStageId = job?.jobApplicantsByStage?.find(
            (stage) => stage.stageType === PipelineStageType.New,
          )?.pipelineStageId;
          currentStageId &&
            dispatch(
              applicantsActions.applicantsStageChange({
                applicantId: result.storedData.jobApplicantId,
                jobId: result.storedData.jobId,
                stageId: pipelineStageId.current,
                currentStageId,
              }),
            );
        }

        history.push({
          pathname: Routes.applicantStepFinal,
        });

        if (!result?.storedData.resumeParsingSucceeded) {
          const resumeParsingFailedModalResult = await resumeParsingFailedModal.show({
            title: `You're almost done!`,
            withTitle: true,
            message: `The resume provided could not pre-fill the applicant's background information.\nPlease add any additional details on the application as necessary.`,
            buttonOk: `Edit candidate's application`,
            buttonCancel: 'Close',
            withActions: true,
          });
          if (resumeParsingFailedModalResult) {
            dispatch(
              push(
                ExRoutes.applicantOverviewView({
                  candidateId: result?.storedData.candidateId,
                  jobId: result?.storedData.jobId,
                  applicantId: result?.storedData.jobApplicantId,
                }),
              ),
            );
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLastStep],
  );

  const dialogContentOnClose = useMemo(
    () =>
      makeChildren(({ onClose: onCloseDialog }) => (
        <div style={{ textAlign: 'center' }}>
          <h6 className="mb-4">If you close the new job application is not saved.</h6>
          <div>
            <ExButton variant="light" className="mr-3" onClick={() => onCloseDialog(false)}>
              Close without Saving
            </ExButton>
            <ExLoaderHandlerWrapperStrict>
              <ExButton variant="primary" onClick={() => onCloseDialog(true)}>
                Continue
              </ExButton>
            </ExLoaderHandlerWrapperStrict>
          </div>
        </div>
      )),
    [],
  );

  const handlerClickClose: React.MouseEventHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (showSteps === false) {
      onClose && onClose();
      dispatch(push(pathname));
    }
    const result = await modal.show({
      children: dialogContentOnClose,
      title: 'Confirmation',
      withTitle: true,
    });
    if (result === false) {
      onClose && onClose();
      dispatch(push(pathname));
    }
  };

  const handlerSaveForm = useCallback(
    (formData: Partial<Applicant>) => {
      dispatch(
        applicantFormActions.saveForm({
          formData: { ...formData },
        }),
      );
    },
    [dispatch],
  );

  useMountHook();

  return {
    className,
    handlerClickClose,
    showSteps,
    steps,
    defaultValues,
    handlerSaveForm,
    saveValidStep,
  };
};

const ApplicantNewPure: React.FC<ApplicantNewProps> = (props) => {
  const { className, handlerClickClose, showSteps, steps, defaultValues, handlerSaveForm, saveValidStep } =
    useApplicantNewState(props);

  return (
    <ExCreateEntityWrapper className={className}>
      <StepFormHeader closeButtonText="Close" onClose={handlerClickClose} title="New Job Application" />
      <div className="form-with-steps">
        <ExVisible visible={showSteps}>
          <FormStepsPanel steps={steps} />
        </ExVisible>
        <StepForm
          steps={steps}
          defaultValues={defaultValues}
          saveForm={handlerSaveForm}
          saveValidStep={saveValidStep}
          finalPageUrl={Routes.applicantStepFinal}
          finalPage={ApplicantFormFinalPage}
        />
      </div>
    </ExCreateEntityWrapper>
  );
};

export const ApplicantNew = styled(ApplicantNewPure)`
  .divider-vertical {
    background-color: ${({ theme }) => theme.colors.light};
    width: 1px;
    margin: 0 1rem;
  }
`;
