/* eslint-disable sonarjs/no-duplicate-string */
export const OnCostEndpoints = {
  // GET
  GetCompanyOnCostValuesFunc: '/api/company/{companyId}/oncost',
  // POST
  CreateCompanyOnCostValueFunc: '/api/company/{companyId}/oncost',
  // GET
  GetCompanyOnCostValueFunc: '/api/company/{companyId}/oncost/{onCostId}',
  // PUT
  UpdateCompanyOnCostValueFunc: '/api/company/{companyId}/oncost/{onCostId}',
  // DELETE
  DeleteCompanyOnCostValueFunc: '/api/company/{companyId}/oncost/{onCostId}',
};
