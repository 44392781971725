import styled from 'styled-components';

import { safariFlexBoxTweak } from 'utils/styled/common';

export const DialogFooter = styled.div`
  padding-top: 20px;
  display: flex;
  ${safariFlexBoxTweak};
`;

export const DialogBody = styled.div`
  min-height: 50px;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const DialogContentComponent = styled.div`
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
