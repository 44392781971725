export const additionalFieldsTransformer = ([key, value]) => {
  const postValue = value?.children ? value?.children : value?.value ?? '';
  const postParent = value?.value ?? null;

  return {
    key,
    value: postValue,
    parent: postValue === postParent && postValue !== '' ? null : postParent,
  };
};
