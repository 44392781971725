import { createSelector } from '@reduxjs/toolkit';

import { createListSelectors } from 'utils/reducer/selector-helper';

import { EntityKeys, UIKeys } from 'store/constants';
import { SortBy, TalentPool, TalentPoolFilters } from 'store/entities/talent-pools/models';
import { RootState } from 'store/rootReducer';
import { uiDomain } from 'store/rootSelectors';

export const domain = createSelector(uiDomain, (state) => state[UIKeys.talentPoolLists]);

export const {
  getListById,
  getFilters,
  getSortMode,
  getListParams,
  getViewMode,
  getSelected,
  getItems: getTalentPools,
  getRecentItems: getRecentTalentPools,
} = createListSelectors<TalentPool, SortBy, TalentPoolFilters>(
  EntityKeys.talentPools,
  (state: RootState) => state.ui.talentPoolLists,
);
