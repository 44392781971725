import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Controller, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { components } from 'react-select';
import Select from 'react-select/src/Select';
import * as yup from 'yup';
import styled, { DefaultTheme, ThemeContext } from 'styled-components';

import { apiConfig } from 'config/apiConfig';

import { rbacConstants } from 'containers/Auth/AuthMatrix';
import { RBAC } from 'containers/Auth/components/RBAC';
import { jobFormEffects } from 'containers/JobForms/state';
import { loaderSelectors } from 'containers/Loader/store';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';
import { talentPoolFormEffects } from 'containers/TalentPoolForms/state';

import { ReactComponent as CircleClose } from 'assets/img/circle-x.svg';

import { FormCard } from 'components/FormCard';
import { FormInput } from 'components/FormInput';
import { FormTagSelect } from 'components/FormTagSelect';
import { Spinner } from 'components/Spinner';
import { StepFormBody } from 'components/StepForm';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { sortByName } from 'utils/list';

import { Candidate } from 'store/entities/candidates/models';
import { TalentPool } from 'store/entities/talent-pools/models';
import { createTalentPool } from 'store/entities/talent-pools/talent-pool.actions';
import { exModalPropsAction } from 'store/modals/modals.actions';
import { useSelector } from 'store/rootSelectors';

export interface CreateNewCandidateFormBodyProps extends StepFormBody {
  className?: string;
  modalId?: string;
}

export interface SearchAutocomplete {
  name: string;
  id: string;
}

const StyledCircleClose = styled(CircleClose)<{ variant: keyof DefaultTheme['colors'] }>`
  height: 11px;
  width: 11px;
  margin-left: 5px;
  fill: ${(props) => props.theme.colors[props.variant]};

  &:hover {
    fill: ${(props) => props.theme.colors['danger']};
  }
`;

const MultiValueRemove: React.FC = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <StyledCircleClose variant="primary" />
    </components.MultiValueRemove>
  );
};

const CreateNewCandidateFormBody: React.FC<CreateNewCandidateFormBodyProps> = ({ className, modalId }) => {
  const dispatch = useAppDispatch();
  const talentPoolsRef = useRef<Select<SearchAutocomplete>>(null);
  const { control, formState, getValues, register, errors } = useFormContext<
    Candidate & { talentPools: Array<SearchAutocomplete> | null }
  >();
  const [debounce, setDebounce] = useState<{ cb?: Function; delay?: number }>({});
  const theme = useContext(ThemeContext);

  const validated = formState.isSubmitted;

  const handlerLoadOptions = (
    searchTerm: string,
    callback: (options: Array<Pick<TalentPool, 'name' | 'id'>>) => void,
  ) => {
    setDebounce({
      cb: async () => {
        const result = await dispatch(talentPoolFormEffects.searchTalentPoolByName({ searchTerm }));
        const payload = result.payload;
        if (payload?.length && typeof payload !== 'string') {
          callback(sortByName(payload));
        } else {
          callback([]);
        }
      },
      delay: apiConfig.debounceTime,
    });
  };

  useEffect(() => {
    const { cb, delay } = debounce;
    if (cb) {
      const timeout = setTimeout(cb, delay);
      return () => clearTimeout(timeout);
    }
  }, [debounce]);

  const loading = useSelector((state) =>
    loaderSelectors.isTaskActive(state, { taskId: jobFormEffects.loadSelectorsData }),
  );

  useEffect(() => {
    register('talentPools');
  }, [dispatch, register]);

  const noOptionsHandler = ({ inputValue }: { inputValue: string }) => {
    if (inputValue.length === 0) {
      return 'Start typing';
    }
    return 'No Options';
  };

  const handlerNewTalentPool: React.MouseEventHandler<HTMLAnchorElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const formData = getValues();
    dispatch(exModalPropsAction({ id: modalId, modalProps: { formData } }));
    dispatch(createTalentPool());
  };

  if (loading) {
    return (
      <div className="my-3">
        <Spinner />
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="row">
        <FormInput
          type="text"
          placeholder=""
          className="col-12"
          label="First Name"
          name="firstName"
          required
          errors={errors?.firstName}
          validated={validated}
          inputRef={register}
        />
        <FormInput
          type="text"
          placeholder=""
          className="col-12"
          label="Last Name"
          name="lastName"
          required
          errors={errors?.lastName}
          validated={validated}
          inputRef={register}
        />
        <FormInput
          type="text"
          placeholder=""
          className="col-12"
          label="Email"
          name="email"
          required
          errors={errors?.email}
          validated={validated}
          inputRef={register}
        />
        <RBAC feature={rbacConstants.candidates.addToTalentPool}>
          <Form.Group className="col-12 mb-0">
            <FormCard.InputLabel htmlFor="talentPools" className="d-flex">
              Add to Talent Pool{' '}
              <RBAC feature={rbacConstants.talentPools.create}>
                <Link
                  className="ml-auto"
                  to={`?modal=${ModalsTypeKey['newTalentPool']}`}
                  onClick={handlerNewTalentPool}
                >
                  New Talent Pool
                </Link>
              </RBAC>
            </FormCard.InputLabel>
            <Controller
              isClearable={false}
              required
              as={<FormTagSelect ref={talentPoolsRef} />}
              name="talentPools"
              isMulti={true}
              control={control}
              loadOptions={handlerLoadOptions}
              errors={errors?.talentPools}
              validated={formState.isSubmitted}
              getOptionLabel={(option: SearchAutocomplete) => option.name}
              getOptionValue={(option: SearchAutocomplete) => option.id}
              maxMenuHeight={100}
              onFocus={() => talentPoolsRef?.current?.focus()}
              onBlurName="blur"
              backspaceRemovesValue={false}
              noOptionsMessage={noOptionsHandler}
              overrideStyles={{
                input: (base: CSSProperties) => ({
                  ...base,
                  lineHeight: 1,
                  margin: '8px',
                }),
                control: (base: CSSProperties) => ({
                  ...base,
                  minHeight: '40px',
                  borderColor: `${theme.border.borderColor}`,
                  borderRadius: `${theme.border.borderRadius}`,
                  boxShadow: 'none',
                }),
              }}
              components={{
                MultiValueRemove,
                Placeholder: () => null,
                IndicatorSeparator: null,
                DropdownIndicator: null,
              }}
            />
          </Form.Group>
        </RBAC>
      </div>
    </div>
  );
};

export default styled(CreateNewCandidateFormBody)``;

export const validationSchemaTalentPools: yup.SchemaOf<SearchAutocomplete> = yup.object({
  name: yup.string().required().defined(),
  id: yup.string().required().defined(),
});

export const validationSchemaCreateNewCandidate: yup.SchemaOf<
  Pick<Candidate, 'firstName' | 'lastName' | 'email'> & {
    talentPools: Array<yup.TypeOf<typeof validationSchemaTalentPools>> | null;
  }
> = yup.object({
  firstName: yup.string().required().label('First Name'),
  lastName: yup.string().required().label('Last Name'),
  email: yup.string().email().required().label('Email'),
  talentPools: yup.array().of(validationSchemaTalentPools).required().label('Talent Pool').nullable().defined(),
});
