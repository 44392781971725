import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UIKeys } from 'store/constants';

export enum TipVariants {
  applicantModalKeyboard = 'applicantModalKeyboard',
  interviewEditPopup = 'interviewEditPopup',
}

export type Tips = Partial<Record<TipVariants, boolean | undefined>>;

const initialState = {
  [TipVariants.applicantModalKeyboard]: true,
  [TipVariants.interviewEditPopup]: true,
};

const tipsSlice = createSlice({
  name: UIKeys.tips,
  initialState,
  reducers: {
    updateTips(state, { payload }: PayloadAction<Partial<Tips>>) {
      state = {
        ...state,
        ...payload,
      };
      return state;
    },
  },
});

export const tipsReducer = tipsSlice.reducer;
export const tipsActions = tipsSlice.actions;
