import {
  UseAbilityItems,
  useAbilityItems,
} from 'containers/Modals/ModalsContent/Job/JobAbilitiesModal/hooks/useAbilityItems';
import { modalsSelectors } from 'containers/Modals/store';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { jobsSelectors } from 'store/entities/jobs';
import { exModalConfirmAction } from 'store/modals/modals.actions';
import { useSelector } from 'store/rootSelectors';

export function useJobAbilityItems({ modalId, abilityEntity, validationSchemaLabel }: UseAbilityItems) {
  const dispatch = useAppDispatch();
  const entityId = useSelector(modalsSelectors.modalJobId);
  const entity = useSelector((state) => jobsSelectors.getById(state, entityId));
  const { items, onSubmitValidation } = useAbilityItems({
    abilityEntity,
    entity,
    entityId,
    onSubmitValidationCallback: async (jobId, data) => {
      dispatch(
        exModalConfirmAction({
          id: modalId,
          modalResult: {
            data,
            urlParams: {
              jobId,
            },
          },
        }),
      );
    },
    validationSchemaLabel,
  });

  return { items, onSubmitValidation } as const;
}
