import React, { useEffect, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useRouteMatch } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { yupResolver } from '@hookform/resolvers/yup';

import { JobApplicantItem } from 'api-endpoints/job/models';

import { CandidateValidationErrorCodes } from 'model/api-errors.constants';
import { AppRouterParams } from 'model/router';

import { loadApplicant } from 'containers/ApplicantLists/store/effects';
import { candidates } from 'containers/Auth/AuthMatrix/authMatrix.constants';
import { useRBAC } from 'containers/Auth/components/RBAC';
import { ApplicantModalFooter } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalFooter/ApplicantModalFooter';
import { ApplicantModalFormFormData } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/models';
import { applicantModalSchema } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/yup/index';
import { modalsActions } from 'containers/Modals/store';

import { IconCloseStyled } from 'components/Icons/IconClose';
import { Spinner } from 'components/Spinner';
import { useLoaderSubscription } from 'modules/LoaderManager/react';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useKeyPress } from 'utils/hooks/useKeyPress';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getTranslate } from 'utils/i18utils';

import { applicantCommentsActions } from 'store/entities/applicant-comments/applicant-comments.actions';
import { applicantsActions, applicantSelectors } from 'store/entities/applicants';
import { candidateEducationsSlice } from 'store/entities/candidate-education/candidate-education.reducer';
import { candidateWorkExperienceSlice } from 'store/entities/candidate-work-experience/candidate-work-experience.reducer';
import { applicantInterviewTabListUiSlice } from 'store/ui/applicants/applicant-interview-tab-list/applicant-interview-tab-list.reducer';

import { updateApplicantModalAction } from './store/ApplicantModal.actions';
import { applicantModalSlice } from './store/ApplicantModal.reducer';
import { applicantModalSliceSelectors } from './store/ApplicantModal.selectors';
import { ApplicantInfoSection } from './ApplicantInfoSection';
import { ApplicantModalBody } from './ApplicantModalBody';
import { ApplicantModalTip } from './ApplicantModalTip';
import {
  ApplicantCommentTabStyled,
  ApplicantModalBodyWrapper,
  ApplicantModalClose,
  ApplicantModalComments,
  ApplicantModalContent,
  ApplicantModalFooterWrapper,
  ApplicantModalForm,
  ApplicantModalInfo,
  ApplicantModalTitle,
  ApplicantModalV4Styled,
  SpinnerWrapper,
} from './ApplicantModalV4Components';

type ApplicantModalV4Props = {
  className?: string;
  onClose?: () => void;
  isCandidateView?: boolean;
};

const useApplicantModalV4State = ({ className, onClose, isCandidateView }: ApplicantModalV4Props) => {
  const dispatch = useAppDispatch();
  const match = useRouteMatch<AppRouterParams>();
  const { applicantId } = match.params;

  const applicant = useAppSelector(applicantSelectors.selectApplicantModalData, applicantId);

  const formState = useAppSelector(applicantModalSliceSelectors.selectFormState);

  const jobId = applicant?.jobId ?? match.params.jobId;
  const candidateId = applicant?.candidateId ?? null;

  const { isLoading: isApplicantLoading } = useLoaderSubscription({ type: loadApplicant.name });
  const { isLoading: applicantsStageChangeProcessing } = useLoaderSubscription(applicantsActions.applicantsStageChange);
  const isProcessing = isApplicantLoading || applicantsStageChangeProcessing;

  const { isLoading: isUpdateApplicantModalLoading } = useLoaderSubscription(updateApplicantModalAction);

  const submitFormHandler: SubmitHandler<JobApplicantItem> = (data) => {
    if (isUpdateApplicantModalLoading) {
      return;
    }

    dispatch(applicantModalSlice.actions.set({ formState: data }));
    dispatch(updateApplicantModalAction(data));
  };

  useEffect(() => {
    if (applicantId) {
      dispatch(applicantModalSlice.actions.set({ currentApplicantId: applicantId }));
      dispatch(applicantInterviewTabListUiSlice.actions.resetState({ jobApplicantId: applicantId }));
      dispatch(modalsActions.setApplicantId({ applicantId }));

      return () => {
        dispatch(modalsActions.setApplicantId({ applicantId: null }));
        dispatch(applicantModalSlice.actions.set({ currentApplicantId: null }));
        dispatch(applicantInterviewTabListUiSlice.actions.resetState({}));
      };
    }
  }, [applicantId, dispatch]);

  useEffect(
    () => () => {
      dispatch(applicantModalSlice.actions.toggleEdit(false));
      dispatch(candidateWorkExperienceSlice.actions.removeAll());
      dispatch(candidateEducationsSlice.actions.removeAll());
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(modalsActions.setCandidateId({ candidateId }));
  }, [applicant?.applicantId, candidateId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (applicantId && jobId) {
      dispatch(applicantsActions.applicantsModalFetchAction({ applicantId, jobId }));
    }
  }, [applicantId, dispatch, jobId]);

  // Effect for load applicant comments
  useEffect(() => {
    if (applicantId && jobId) {
      dispatch(
        applicantCommentsActions.applicantCommentsFetch({
          applicantId,
          jobId,
        }),
      );
    }
    return () => {
      dispatch(applicantCommentsActions.resetCurrent());
    };
  }, [applicantId, dispatch, jobId]);

  useEffect(() => {
    if (applicantId && candidateId) {
      dispatch(
        applicantsActions.getPreviousJobApplicationsNumberAction({
          applicantId,
          candidateId,
        }),
      );
    }
  }, [applicantId, candidateId, dispatch]);

  useKeyPress('Escape', () => {
    onClose && onClose();
  });

  return {
    className,
    onClose,
    applicant,
    isProcessing,
    isCandidateView,
    formState,
    submitFormHandler,
  } as const;
};

export const ApplicantModalV4: React.FC<ApplicantModalV4Props> = (props) => {
  const { className, onClose, applicant, isProcessing, isCandidateView, submitFormHandler } =
    useApplicantModalV4State(props);

  if (!applicant) {
    return null;
  }

  return (
    <ApplicantModalV4Styled className={className}>
      <ApplicantModalFormProvider {...{ key: applicant.applicantId }}>
        {({ handleSubmit, register }) => (
          <>
            <ApplicantModalClose onClick={onClose}>
              <IconCloseStyled />
            </ApplicantModalClose>
            <ApplicantModalContent>
              <ApplicantModalForm onSubmit={handleSubmit(submitFormHandler)}>
                <input type="hidden" name="jobId" ref={register} />
                <input type="hidden" name="candidateId" ref={register} />
                <input type="hidden" name="applicantId" ref={register} />
                <ApplicantModalInfo>
                  <ApplicantInfoSection />
                </ApplicantModalInfo>
                <ApplicantModalBodyWrapper>
                  <ApplicantModalBody />
                </ApplicantModalBodyWrapper>
              </ApplicantModalForm>
              <ApplicantModalComments>
                <ApplicantModalTitle>Comments</ApplicantModalTitle>
                <ApplicantCommentTabStyled item={applicant} />
              </ApplicantModalComments>
              <ApplicantModalTip />
            </ApplicantModalContent>
            <ApplicantModalFooterWrapper>
              <ApplicantModalFooter isCandidateView={isCandidateView} />
            </ApplicantModalFooterWrapper>
            <Transition in={isProcessing} timeout={0} unmountOnExit mountOnEnter>
              {(state) => (
                <SpinnerWrapper $state={state}>
                  <Spinner />
                </SpinnerWrapper>
              )}
            </Transition>
          </>
        )}
      </ApplicantModalFormProvider>
    </ApplicantModalV4Styled>
  );
};

const fieldByError: Partial<Record<CandidateValidationErrorCodes, string>> = {
  [CandidateValidationErrorCodes.EmailNotUnique]: 'email',
};

const ApplicantModalFormProvider: React.FC<any> = ({ children }) => {
  const dispatch = useAppDispatch();
  const match = useRouteMatch<AppRouterParams>();
  const { applicantId } = match.params;
  const isEditMode = useAppSelector(applicantModalSliceSelectors.selectIsApplicantModalInEditMode);
  const formState = useAppSelector(applicantModalSliceSelectors.selectFormState);

  const applicant = useAppSelector(applicantSelectors.selectApplicantModalData, applicantId);
  const educations = useAppSelector(applicantModalSliceSelectors.selectEducationsForCurrentApplicant);
  const workExperiences = useAppSelector(applicantModalSliceSelectors.selectWorkExperiencesForCurrentApplicant);
  const apiErrors = useAppSelector(applicantModalSliceSelectors.selectApiErrors);
  const canEditCandidate = useRBAC(candidates.editDetails);

  const defaultValues = useMemo(
    () => ({
      ...applicant,
      educations,
      workExperiences,
    }),
    [applicant, educations, workExperiences],
  );
  const methods = useForm<ApplicantModalFormFormData>({
    defaultValues,
    resolver: yupResolver(applicantModalSchema(canEditCandidate)),
    shouldFocusError: true,
    shouldUnregister: false,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => methods.reset(Object.keys(formState).length ? formState : defaultValues), [defaultValues, formState]);

  useEffect(() => {
    apiErrors.forEach((error) => {
      const message = getTranslate(error, { ns: 'validationErrorCodes' });
      const field = fieldByError[error];
      if (field) {
        methods.setError(field, { message, type: 'sever' });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiErrors]);

  useEffect(() => {
    if (applicant && isEditMode) {
      dispatch(applicantModalSlice.actions.set({ formState: defaultValues, currentApplicant: defaultValues }));
    }
    return () => {
      dispatch(applicantModalSlice.actions.set({ currentApplicant: null }));
      dispatch(applicantModalSlice.actions.set({ formState: {} }));
    };
  }, [applicant, defaultValues, dispatch, isEditMode]);

  return <FormProvider {...methods}>{children(methods)}</FormProvider>;
};
