import React, { useEffect, useState } from 'react';

import { CustomCheckboxProps } from './CustomChecboxProps';
import { CustomCheckboxStyled, StyledCircle, StyledCircleCheck } from './CustomCheckboxComponents';

/**
 * Custom nice checkbox.
 *
 * @param {CustomCheckboxProps} {
 *   onChange,
 *   variant = 'primary', - keyof styled components DefaultTheme
 *   checked = false,
 * }
 * @returns {React.ReactElement}
 */
const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  onChange,
  variant = 'primary',
  checked = false,
  disabled,
  children,
  className,
}): React.ReactElement => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const checkboxClickHandler = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    const newState = !isChecked;
    setIsChecked(newState);
    onChange && onChange(newState);
  };

  return (
    <CustomCheckboxStyled className={className} onClick={checkboxClickHandler} disabled={disabled}>
      {isChecked ? <StyledCircleCheck variant={variant} /> : <StyledCircle variant={variant} />} {children}
    </CustomCheckboxStyled>
  );
};

export { CustomCheckbox };
