import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';
import isEqual from 'lodash/isEqual';

import { FilterCheckbox } from 'components/Filter';
import { FormCard } from 'components/FormCard';
import { ExVisible } from 'components/ui/ExVisible';

type FormMultiCheckboxOptionsProps = {
  key: string;
  value: string;
};

type FormMultiCheckboxProps = {
  className?: string;
  name: string;
  options: FormMultiCheckboxOptionsProps[];
  label?: string;
};

const FormMultiCheckboxStyled = styled.div``;

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.border.borderColor};
  padding: 2px 7px;
  border-radius: ${({ theme }) => theme.border.borderRadius};
  max-height: 150px;
  overflow-y: auto;
`;

const useFormMultiCheckboxState = ({ className, name, options, label }: FormMultiCheckboxProps) => {
  const { register, setValue, watch } = useFormContext();

  const selectedOptions = watch(name);

  const optionsKeys = useMemo(() => {
    return options.map((country) => country.key);
  }, [options]);

  const [isAllSelected, setAllSelected] = useState(false);

  const isSelectedEqual = isEqual(selectedOptions, optionsKeys);

  useEffect(() => {
    setAllSelected(isSelectedEqual);
  }, [isSelectedEqual]);

  const selectActionLabel = isAllSelected ? 'Deselect All' : 'Select All';

  const selectAction = useCallback(
    (e) => {
      const isChecked = e.target.checked;

      if (isChecked) {
        setValue(name, optionsKeys);
      } else {
        setValue(name, []);
      }
    },
    [name, optionsKeys, setValue],
  );

  const hasLabel = Boolean(label);

  return {
    className,
    selectActionLabel,
    selectAction,
    isAllSelected,
    options,
    name,
    register,
    label,
    hasLabel,
  } as const;
};

export const FormMultiCheckbox: React.FC<FormMultiCheckboxProps> = (props) => {
  const { className, selectActionLabel, selectAction, isAllSelected, options, name, register, label, hasLabel } =
    useFormMultiCheckboxState(props);

  if (!options || !options.length) {
    return null;
  }

  return (
    <FormMultiCheckboxStyled className={className}>
      <ExVisible visible={hasLabel}>
        <FormCard.InputLabel>{label}</FormCard.InputLabel>
      </ExVisible>
      <Wrapper>
        <FilterCheckbox label={selectActionLabel}>
          <input type="checkbox" checked={isAllSelected} onClick={selectAction} />
        </FilterCheckbox>
        {options.map((option) => (
          <FilterCheckbox label={option.value || '--'} key={option.key}>
            <input type="checkbox" name={name} value={option.key} ref={register} />
          </FilterCheckbox>
        ))}
      </Wrapper>
    </FormMultiCheckboxStyled>
  );
};
