import { createAction } from '@reduxjs/toolkit';

import { WithRequired } from 'model/utils';

import { Loader } from 'utils/sagas';

export enum ActionEnum {
  LOAD = 'LOAD',
  REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  CANCEL = 'CANCEL',
  FINALLY = 'FINALLY',
}

type CreateRequestTypes = Record<ActionEnum, string>;

export function createRequestTypes(base: string) {
  return Object.values(ActionEnum).reduce<CreateRequestTypes>((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {} as CreateRequestTypes);
}

export function action(type: string, payload = {}) {
  return { type, payload };
}

/**
 * @deprecated  use createActionApiCallBatch
 */
export function createLoader(
  loader: WithRequired<Partial<Loader>, 'success'>,
  requestTypes: Record<ActionEnum, string>,
): WithRequired<Loader, 'success'> {
  return {
    finally: params => {
      const { preloader, payload } = params;
      const preloaderString = preloader ?? payload?.preloader;
      const finallyAction = createAction<{ preloader: string | boolean }>(requestTypes.FINALLY);
      return finallyAction({
        preloader: typeof preloaderString === 'string' ? preloaderString : true,
      });
    },
    ...loader,
  };
}
