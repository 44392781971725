import React from 'react';
import styled from 'styled-components';

import { MenuItem } from 'components/ActionsDropdown';
import { ExActionButton, ExActionButtonToggle } from 'components/ui/ExActionButton';

type CandidateCommentCardActionButtonProps = {
  onEdit: () => void;
  onDelete: () => void;
};

const ExActionButtonStyled = styled(ExActionButton)`
  ${ExActionButtonToggle} {
    background-color: transparent;
  }
`;

export const CandidateCommentCardActionButton: React.FC<CandidateCommentCardActionButtonProps> = ({
  onEdit,
  onDelete,
}) => {
  const menuItems: MenuItem[] = [
    {
      label: 'Edit',
      action: onEdit,
      title: 'Edit comment for candidate',
    },
    {
      label: 'Delete',
      action: onDelete,
      title: 'Delete comment for candidate',
    },
  ];

  return (
    <ExActionButtonStyled buttonId="candidate-work-experience-dropdown" menuItems={menuItems} dropDirection="down" />
  );
};
