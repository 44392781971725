import { createSlice } from '@reduxjs/toolkit';

import { companyInitialState } from 'store/company/company.initial-state';
import { CompanyKeys } from 'store/constants';

const companySlice = createSlice({
  name: CompanyKeys.root,
  initialState: companyInitialState,
  reducers: {
    update(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default companySlice.reducer;

// Actions
export const companyActions = companySlice.actions;
