import { useCallback } from 'react';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';

import { FileValidationResult, FileValidationRules, validateFile } from 'utils/files';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

type FileInputValidationOptions = {
  options: FileValidationRules;
  withToasts?: boolean;
  onError?: Function;
  onSuccess?: Function;
};

type FileInputValidationProps = FileInputValidationOptions & { file: File };

type FileInputValidationMultipleProps = FileInputValidationOptions & { files: File[] };

export const useFileInputValidation = () => {
  const dispatch = useAppDispatch();

  const showErrors = useCallback(
    (errors: string[]) => errors.forEach((message) => dispatch(alertsEffects.showError({ message }))),
    [dispatch],
  );

  const validateFileInputSingle = useCallback(
    ({ file, options, withToasts, onError, onSuccess }: FileInputValidationProps): FileValidationResult => {
      const result = validateFile(file, options);

      const { isValid, errors } = result;

      if (!isValid && withToasts) {
        showErrors(errors);
      }

      if (!isValid && typeof onError === 'function') {
        onError();
      }

      if (isValid && typeof onSuccess === 'function') {
        onSuccess([file]);
      }

      return result;
    },
    [showErrors],
  );

  const validateFileInputs = useCallback(
    ({ files, options, withToasts, onError, onSuccess }: FileInputValidationMultipleProps): FileValidationResult[] => {
      if (files.length < 2) {
        return [
          validateFileInputSingle({
            file: files[0],
            options,
            withToasts,
            onError,
            onSuccess,
          }),
        ];
      }

      const { results, validFiles, invalidFiles } = files.reduce(
        (acc, file: File) => {
          const result = validateFile(file, options);

          acc.results.push(result);

          if (result.isValid) {
            acc.validFiles.push(file);
          } else {
            acc.invalidFiles.push(file);
            acc.invalidResults.push(result);
          }

          return acc;
        },
        {
          results: [] as FileValidationResult[],
          invalidResults: [] as FileValidationResult[],
          validFiles: [] as File[],
          invalidFiles: [] as File[],
        },
      );

      const hasValidFiles = Boolean(validFiles.length);
      const hasInvalidFiles = Boolean(invalidFiles.length);

      if (!hasInvalidFiles && withToasts) {
        showErrors(['Some files are invalid and will be ignored']);
      }

      if (!hasInvalidFiles && typeof onError === 'function') {
        onError();
      }

      if (hasValidFiles && typeof onSuccess === 'function') {
        onSuccess(validFiles);
      }

      return results;
    },
    [showErrors, validateFileInputSingle],
  );

  return {
    validateFileInputs,
  };
};

export const useOnChangeFilewithValidation = ({
  options,
  withToasts,
  onError,
  onSuccess,
}: FileInputValidationOptions) => {
  const { validateFileInputs } = useFileInputValidation();

  const onChangeFileWithValidation = useCallback(
    (files: File[]) =>
      validateFileInputs({
        files,
        options,
        withToasts,
        onError,
        onSuccess,
      }),
    [validateFileInputs, options, withToasts, onError, onSuccess],
  );

  return {
    onChangeFileWithValidation,
  };
};
