import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import styled from 'styled-components';

import { Country } from 'model';
import { KeyCodes } from 'model/keycodes';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import { ExModalBodyStyled, ExModalWrapperStyled } from 'containers/Modals/ModalsContent/Company/ExModalComponents';

import { Form, FormInput, FormSelect } from 'components';
import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { ExButton } from 'components/ui/ExButton';
import { ExDialogActions } from 'components/ui/ExDialog';
import { ExColumn, ExRow } from 'components/ui/ExLayout';
import { useEmploymentTypeOptions } from 'utils/hooks/formsHooks/useEmploymentTypeOptions';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useRestrictedKeyCodes } from 'utils/hooks/useRestrictedKeyCodes';
import { stringOptional } from 'utils/validator-helpers';

import { onCostActions } from 'store/entities/onCost';
import { OnCostValue } from 'store/entities/onCost/onCost.model';
import type { OnCost } from 'store/entities/onCost/onCost.types';
import { exModalConfirmAction } from 'store/modals/modals.actions';
import { ExModal } from 'store/modals/modals.interfaces';

export type CompanyUpdateOnCostModalProps = {
  id?: string;
} & ExModal &
  AppModalContentProps;

export const CompanyUpdateOnCostModalStyled = styled.div``;

const OnCostText = styled(ExColumn)`
  align-items: center;
  display: flex;
`;

const OnCostLabel = styled(OnCostText)`
  font-weight: bold;
`;

const FormBodyWrapper = styled(ExRow)`
  max-width: 340px;
  margin: 0 auto;

  ${FormInput} {
    .form-control {
      padding-right: 1em !important;
    }
  }
`;

const onCostValueErrorMessage = `OnCost values fields are required and ​​accept numbers between -100 and 100 only.`;

const onCostValidationSchema: yup.SchemaOf<Pick<OnCost, 'country' | 'onCostValues' | 'onCostId' | 'isDefault'>> =
  yup.object({
    country: yup
      .object()
      .label('Country')
      .when('isDefault', {
        is: (v) => Boolean(v),
        otherwise: yup.object().required(),
        then: yup.object().nullable().default(null),
      })
      .nullable(true),
    isDefault: yup.boolean().default(false),
    onCostId: stringOptional,
    onCostValues: yup.array<OnCostValue>().of(
      yup.object().shape({
        employmentType: yup.number().defined(),
        onCostValue: yup
          .number()
          .required(onCostValueErrorMessage)
          .min(-100, onCostValueErrorMessage)
          .max(100, onCostValueErrorMessage)
          .transform(function (value, originalValue) {
            if (this.isType(value)) return value;
            if (!originalValue || !originalValue.trim()) {
              return undefined;
            }
            // we return the invalid original value
            return originalValue;
          })
          .test('is-max-decimal', 'Maximum decimal is 4.', function (value: any) {
            if (isNaN(value)) {
              return false;
            }
            const [, dec] = (value + '').split('.');

            return !dec || dec.length <= 4;
          })
          .typeError(onCostValueErrorMessage),
      }),
    ),
  });

export const CompanyUpdateOnCostModal: React.FC<CompanyUpdateOnCostModalProps> = ({
  id,
  modalConfig,
  onClose,
  modalProps,
}) => {
  const dispatch = useAppDispatch();
  const onSubmitClickHandler = (item: object) => {
    dispatch(exModalConfirmAction({ id, modalResult: { item } }));
  };

  const isEdit = Boolean((modalProps as any)?.item?.onCostId);
  const action = isEdit ? onCostActions.onCostUpdate.start : onCostActions.onCostCreate.start;

  return (
    <ExModalWrapperStyled onClose={onClose} content={modalConfig?.content ?? {}}>
      <ExModalBodyStyled>
        <Form
          onSubmit={onSubmitClickHandler}
          defaultValues={modalProps?.item}
          validationSchema={onCostValidationSchema}
        >
          <OnCostFormBody
            name="OnCostFormBody"
            countries={modalProps?.countries}
            isCountrySelectDisabled={modalProps?.isCountrySelectDisabled}
            isEdit={isEdit}
          />
          <ExDialogActions>
            <ExButton variant="light" onClick={onClose}>
              Cancel
            </ExButton>
            <ExLoaderHandlerWrapper action={action}>
              <ExButton className="ml-3" type="submit">
                Save
              </ExButton>
            </ExLoaderHandlerWrapper>
          </ExDialogActions>
        </Form>
      </ExModalBodyStyled>
    </ExModalWrapperStyled>
  );
};

const OnCostFormBody: React.FC<any> = ({ countries, isCountrySelectDisabled, isEdit }) => {
  const dispatch = useAppDispatch();
  const { register, watch, setValue, errors, formState } = useFormContext<OnCost>();
  const employmentTypeOptions = useEmploymentTypeOptions();
  const country = watch('country');
  const { handlerKeyDown: handlerKeyDownVacancies } = useRestrictedKeyCodes({
    restrictedKeyCodes: [KeyCodes.KEY_E, KeyCodes.EQUALS, KeyCodes.ADD],
  });

  useEffect(() => {
    const message = errors.onCostValues
      ?.map((onCostValuesErrors) => onCostValuesErrors?.onCostValue?.message)
      .find(Boolean);
    if (message) {
      dispatch(alertsEffects.showError({ message }));
    }
  }, [formState.submitCount, errors.onCostValues, dispatch]);

  /**
   * This effect is needed to set default value to the newly created on cost entity.
   * It caused by react-hook-form update.
   */
  useEffect(() => {
    if (isEdit === false) {
      setValue('isDefault', false);
    }
  }, [isEdit, setValue]);

  return (
    <>
      <input name="onCostId" ref={register} hidden />
      <input type="checkbox" name="isDefault" ref={register} hidden defaultChecked={false} />
      <FormBodyWrapper className="w-100 my-0">
        <OnCostLabel custom="4">Country</OnCostLabel>
        <ExColumn custom="8">
          <FormSelect
            name="country"
            className="mb-0"
            isDisabled={isCountrySelectDisabled}
            defaultValue={country as any}
            options={countries}
            getOptionLabel={(option: Country) => option.name}
            getOptionValue={(option: Country) => option.code.expedoCode2}
            onChange={(item: OnCost['country']) => setValue('country', item)}
            errors={errors?.country}
            validated={formState.isSubmitted}
          />
        </ExColumn>
      </FormBodyWrapper>
      {employmentTypeOptions.map((item, index) => (
        <React.Fragment key={item.value}>
          <input name={'onCostValues[' + index + '].employmentType'} ref={register} hidden />
          <FormBodyWrapper className="my-0 p-0">
            <ExColumn custom="10">
              <FormInput
                className="mb-0"
                name={'onCostValues[' + index + '].onCostValue'}
                labelLeft={5}
                inputRef={register}
                label={item.label}
                type="number"
                errors={errors.onCostValues?.[index]?.onCostValue}
                validated={formState.isSubmitted}
                onKeyDown={handlerKeyDownVacancies}
                noOverlay
              />
            </ExColumn>
            <OnCostText custom="2">%</OnCostText>
          </FormBodyWrapper>
        </React.Fragment>
      ))}
    </>
  );
};
