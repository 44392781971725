import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as jobBoardApi from 'api-endpoints/job-board';
import { getJobBoardAdditionalFieldsFuncTransformer } from 'api-endpoints/job-board/transformer';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { getAdditionalFieldsDefaultValues, mapAdditionalFieldsWithDefaultValues } from 'utils/additional-fields-helper';
import { invokeApiCall, ReturnData } from 'utils/sagas';

import { jobBoardActions } from 'store/entities/job-boards';

function* getJobBoard(action: ReturnType<typeof jobBoardActions.get>) {
  const { jobBoardId } = action.payload;
  yield call(startLoader, action);

  const { data, message, errorData }: ReturnData<typeof jobBoardApi.getJobBoard> = yield invokeApiCall(
    jobBoardApi.getJobBoard,
    {
      urlParams: { jobBoardId },
    },
  );

  if (!message && !errorData && data) {
    yield put(jobBoardActions.upsertMany([{ ...data, jobBoardId }]));
  }

  yield call(stopLoader, action);
}

function* getJobBoardSettings(action: ReturnType<typeof jobBoardActions.getSettings>) {
  const { jobBoardId } = action.payload;
  yield call(startLoader, action);
  const { data, message, errorData }: ReturnData<typeof jobBoardApi.getJobBoardSettings> = yield invokeApiCall(
    jobBoardApi.getJobBoardSettings,
    {
      urlParams: { jobBoardId },
    },
  );

  if (!message || !errorData) {
    yield put(jobBoardActions.updateOne({ id: jobBoardId, changes: { ...data } }));
  }

  yield call(stopLoader, action);
}

function* getJobBoardAdditionalFields(action: ReturnType<typeof jobBoardActions.getAdditionalFields>) {
  const {
    jobBoardId,
    options: { location, country, jobId },
  } = action.payload;
  yield call(startLoader, action);

  const {
    data: pureData,
    message,
    errorData,
  }: ReturnData<typeof jobBoardApi.getJobBoardAdditionalFields> = yield invokeApiCall(
    jobBoardApi.getJobBoardAdditionalFields,
    { urlParams: { jobBoardId }, params: { location, jobId } },
  );

  const data = getJobBoardAdditionalFieldsFuncTransformer(pureData);

  const updatedAdditionalFields = mapAdditionalFieldsWithDefaultValues(
    data?.additionalFields || [],
    getAdditionalFieldsDefaultValues({
      country,
      location,
    }),
  );

  if (data) {
    data.additionalFields = updatedAdditionalFields;
  }

  if (!message || !errorData) {
    yield put(jobBoardActions.updateOne({ id: jobBoardId, changes: { ...data } }));
  }

  yield call(stopLoader, action);
}

function* unsubscribeJobBoardSettings(action: ReturnType<typeof jobBoardActions.unsubscribeSettings>) {
  const { jobBoardId } = action.payload;
  yield call(startLoader, action);

  const { message, errorData }: ReturnData<typeof jobBoardApi.unSubscribeJobBoard> = yield invokeApiCall(
    jobBoardApi.unSubscribeJobBoard,
    {
      urlParams: { jobBoardId },
    },
  );

  if (!message || !errorData) {
    yield put(jobBoardActions.removeOne(jobBoardId));
  }

  yield call(stopLoader, action);
}

export function* jobBoardsSagas() {
  yield takeEvery(jobBoardActions.get, getJobBoard);
  yield takeEvery(jobBoardActions.getSettings, getJobBoardSettings);
  yield takeEvery(jobBoardActions.getAdditionalFields, getJobBoardAdditionalFields);
  yield takeLatest(jobBoardActions.unsubscribeSettings, unsubscribeJobBoardSettings);
}
