import React, { useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components/macro';

import { CandidateListPlaceholder } from 'containers/CandidateLists/CandidateListPlaceholder';
import { CandidateListPropsPure, CandidateListRenderHeaderProps } from 'containers/CandidateLists/CandidateListProps';
import { candidateListActions, candidateListSelectors, CandidateListState } from 'containers/CandidateLists/store';
import {
  loadCandidates as candidateListEffectsLoadCandidates,
  loadCandidatesTaskId,
} from 'containers/CandidateLists/store/effects';
import { loaderSelectors } from 'containers/Loader/store';

import { ListTableViewHeader, ListTableViewItem, ListViewPagination } from 'components/ListView';
import { useTickColumn } from 'components/ListView/hooks/useTickColumn';
import { Column, GetColumnProps } from 'components/ListView/ListViewProps';
import { Spinner } from 'components/Spinner';
import { TableList } from 'components/TableList';
import { useTableListUpdatePageHandler } from 'components/TableList/hooks';
import { ExCardTableWrapper } from 'components/TableList/TableListComponents';
import { useTableFooterCounters } from 'components/ui/ExTable/ExTable.hook';
import { pipe } from 'utils/funcs';

import { Candidate, Columns } from 'store/entities/candidates/models';
import { RootState } from 'store/rootReducer';

const ExCardTableWrapperTable = styled(ExCardTableWrapper)`
  padding: 0;
  box-shadow: none;
`;

const getColumn =
  ({ col, updateSort, sortMode }: GetColumnProps) =>
  ({ label, order, style, component }: Column) => {
    if (col === Columns.tickHeader) {
      return (
        <th className="column-action" style={style} key={col}>
          {component({ item: {} as Candidate })}
        </th>
      );
    }
    return (
      <ListTableViewHeader
        key={col}
        order={order}
        updateSort={updateSort}
        sortMode={sortMode}
        label={label}
        style={style}
      />
    );
  };

const renderHeader = ({ updateSort, sortMode, columns }: CandidateListRenderHeaderProps) => {
  const cols = Object.keys(columns) as Array<Columns>;
  return <>{cols.map((col) => pipe(columns[col], getColumn({ col, sortMode, updateSort })))}</>;
};

type CandidateListProps = CandidateListPropsPure & PropsFromRedux;

const useCandidateListState = ({
  candidates,
  recentCandidates,
  loadCandidates,
  updatePage,
  id,
  sortMode,
  updateSortMode,
  isCandidateListLoading,
  list,
  columns,
  toggleItem,
  toggleItemRadio,
  selectable,
  multiselect,
  filters,
  initList,
  cleanupList,
  selectAll,
  deselectAll,
  selectableHeader,
  selectableHeaderDisabled,
  className,
}: CandidateListProps) => {
  const updateSort = (sortModeForUpdate: CandidateListState['sortMode']) => {
    updateSortMode({ id, sortMode: sortModeForUpdate });
    loadCandidates({ listId: id });
  };

  const updatePageHandler = useTableListUpdatePageHandler({
    updateTableView: updatePage,
    id,
    load: loadCandidates,
  });

  useEffect(() => {
    initList({ filters: { orderBy: 'FullName', doNotHire: false }, id });
    return function cleanup() {
      cleanupList({ id });
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    const updateAndFetch = async () => {
      updatePage({ id, pageNo: 0 });
      await loadCandidates({ listId: id });
    };
    updateAndFetch();
  }, [filters, id, loadCandidates, updatePage]);

  const [cols, setCols] = React.useState(columns);

  const isAllSelected = useMemo(
    () => list.selectedItems.length === list.items.length || false,
    [list.selectedItems, list.items],
  );

  const { headerTickColumn, tickColumn } = useTickColumn({
    deselectAll,
    id,
    isAllSelected,
    list,
    multiselect,
    selectAll,
    selectableHeaderDisabled,
    toggleItem,
    toggleItemRadio,
  });

  useEffect(() => {
    const { actions, ...rest } = columns;
    if (selectable) {
      setCols({ ...tickColumn, ...(selectableHeader ? headerTickColumn : {}), ...rest });
    } else {
      setCols({ ...rest });
    }
    // eslint-disable-next-line
  }, [list]);

  const listColumns = useMemo(() => {
    const { [Columns.tickHeader]: headertickColumn, ...restListColumns } = cols;

    return restListColumns;
  }, [cols]);

  const headerColumns = useMemo(() => {
    if (!selectableHeader) {
      return cols;
    }
    const { [Columns.tick]: tableBodyTickColumn, ...restHeaderColumns } = cols;

    return restHeaderColumns;
  }, [cols, selectableHeader]);

  const candidateList = candidates.length ? candidates : recentCandidates;
  const dataLength = candidateList.length;

  const { tableFooterCountersText } = useTableFooterCounters({
    pageNo: list.pageNo,
    pageCount: list.pageCount,
    totalItemsCount: list.totalItemsCount,
    pageSize: list.pageSize,
    section: 'Candidates',
  });

  return {
    sortMode,
    isCandidateListLoading,
    list,
    className,
    dataLength,
    headerColumns,
    updateSort,
    updatePageHandler,
    candidateList,
    listColumns,
    tableFooterCountersText,
  };
};

const CandidateListPure: React.FC<CandidateListProps> = (props) => {
  const {
    sortMode,
    isCandidateListLoading,
    list,
    className,
    dataLength,
    headerColumns,
    updateSort,
    updatePageHandler,
    candidateList,
    listColumns,
    tableFooterCountersText,
  } = useCandidateListState(props);

  if ((!list.fetched || !dataLength) && isCandidateListLoading) {
    return <Spinner />;
  }

  if (list.fetched && list.pageCount === 0) {
    return <CandidateListPlaceholder />;
  }

  return (
    <ExCardTableWrapperTable>
      <TableList.TableListWrapper isLoading={isCandidateListLoading} dataLength={dataLength}>
        <TableList
          className={className}
          header={renderHeader({ columns: headerColumns, sortMode, updateSort })}
          pagination={
            <ListViewPagination updatePage={updatePageHandler} pageNo={list.pageNo} pageCount={list.pageCount} />
          }
          tableFooterText={tableFooterCountersText}
          pageCount={list.pageCount}
        >
          {candidateList.map((candidate) => (
            <ListTableViewItem key={candidate.id} item={candidate} columns={listColumns} />
          ))}
        </TableList>
      </TableList.TableListWrapper>
    </ExCardTableWrapperTable>
  );
};

const mapDispatch = {
  cleanupList: candidateListActions.cleanupList,
  deselectAll: candidateListActions.deselectAllItems,
  initList: candidateListActions.init,
  loadCandidates: candidateListEffectsLoadCandidates,
  selectAll: candidateListActions.selectAllItems,
  toggleItem: candidateListActions.toggleItemSelect,
  toggleItemRadio: candidateListActions.toggleItemRadio,
  updateFilters: candidateListActions.updateFilters,
  updatePage: candidateListActions.updatePage,
  updateSortMode: candidateListActions.updateSort,
};

const mapState = (state: RootState, own: CandidateListPropsPure) => ({
  candidates: candidateListSelectors.getCandidates(state, own),
  filters: candidateListSelectors.getFilters(state, own),
  isCandidateListLoading: loaderSelectors.isTaskActive(state, {
    taskId: loadCandidatesTaskId,
  }),
  list: candidateListSelectors.getListById(state, own),
  recentCandidates: candidateListSelectors.getRecentCandidates(state, own),
  sortMode: candidateListSelectors.getSortMode(state, own),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const CandidateList = connector(CandidateListPure);

export default CandidateList;
