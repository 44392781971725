import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrderDirection } from 'model/api-enums.constants';

import { UIKeys } from 'store/constants';

type CompanyInterviewTemplateListUiSliceState = ReturnType<typeof initialState>;

export enum InterviewTemplateOrderBy {
  name = 'Name',
  state = 'State',
}

const initialState = () => ({
  filters: { pageNo: 0, orderDirection: OrderDirection.Ascending, orderBy: InterviewTemplateOrderBy.name },
});

export const companyInterviewTemplateListUiSlice = createSlice({
  name: UIKeys.companyInterviewTemplateList,
  initialState: initialState(),
  reducers: {
    updateState: (draft, { payload }: PayloadAction<Partial<CompanyInterviewTemplateListUiSliceState>>) =>
      Object.assign(draft, payload),
    resetState: () => initialState(),
  },
});
