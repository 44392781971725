import React, { useCallback } from 'react';
import styled from 'styled-components';

import { JobBoardListFiltersModal } from 'containers/JobBoardLists/JobBoardListFiltersModal';
import { jobBoardListActions, jobBoardListSelectors, JobBoardListState } from 'containers/JobBoardLists/store';
import { jobBoardListResetFilters } from 'containers/JobBoardLists/store/job-board.actions';

import { TableSearchInput } from 'components/TableSearchInput';
import { ExFilterButton } from 'components/ui/ExButton/ExFilterButton';
import { ExFilterClearButton } from 'components/ui/ExButton/ExFilterClearButton';
import { ExFiltersBarWrapper } from 'components/ui/ExFilterBar';
import { ExVisible } from 'components/ui/ExVisible';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useListFiltersModalOpenState } from 'utils/hooks/useListFiltersModalOpenState';
import { useAppSelector } from 'utils/hooks/useSelectors';

type JobBoardListFiltersType = {
  listId: JobBoardListState['id'];
  className?: string;
  hideSelectBar?: boolean;
};

const ExFilterClearButtonStyled = styled(ExFilterClearButton)`
  margin-left: 10px;
`;

const ExFilterButtonStyled = styled(ExFilterButton)`
  margin-left: 10px;
`;

export const JobBoardListFiltersBar: React.FC<JobBoardListFiltersType> = ({ listId }) => {
  const { handlerClickFilters, isFiltersOpen, onSubmit } = useListFiltersModalOpenState();

  const dispatch = useAppDispatch();

  const filters = useAppSelector(jobBoardListSelectors.getFilters, { id: listId });

  const isFiltersChanged = useAppSelector(jobBoardListSelectors.isFiltersChanged, { id: listId });

  const handleClearFilters = () => {
    dispatch(jobBoardListResetFilters({ listId }));
  };

  const tableSearchFieldName = 'name';
  const tableSearchValue = filters[tableSearchFieldName] || '';

  const onTableSearchChange = useCallback(
    (search, fieldName) => {
      dispatch(jobBoardListActions.updateFilters({ id: listId, filters: { [fieldName]: search } }));
    },
    [dispatch, listId],
  );

  return (
    <ExFiltersBarWrapper>
      <TableSearchInput
        name={tableSearchFieldName}
        placeholder="Search Job Board"
        value={tableSearchValue}
        onChange={onTableSearchChange}
        onChangeDelay={500}
        searchHint="Start typing a Job Board Name"
      />
      <ExFilterButtonStyled onClick={() => handlerClickFilters()} />
      <ExVisible visible={isFiltersChanged}>
        <ExFilterClearButtonStyled onClick={handleClearFilters} variant="link">
          Clear
        </ExFilterClearButtonStyled>
      </ExVisible>

      <JobBoardListFiltersModal listId={listId} isOpen={isFiltersOpen} closeModal={onSubmit} />
    </ExFiltersBarWrapper>
  );
};
