import React from 'react';
import styled from 'styled-components/macro';

import { JobBoardForJobs } from 'api-endpoints/job-board/job-board.dto';

import { FormSelectMultiCheckbox, FormSelectMultiCheckboxProps } from 'components/FormSelectMultiCheckbox';

import { useJobBoardsForJobsQuery } from 'store/entities/job-boards/job-boards.api';
import { JobsListUiSliceState } from 'store/ui/jobs/jobs-list/jobs-list.reducer';

type JobListBoardsFilterProps = {
  className?: string;
  name: string;
  value: JobBoardForJobs[];
  onChange?: (filter: Partial<JobsListUiSliceState['filters']>) => void;
};

const JobListBoardsFilterStyled = styled.div`
  margin-left: 10px;
  width: 185px;
`;

const useJobListBoardsFilterState = ({ className, value, name, onChange }: JobListBoardsFilterProps) => {
  const { data } = useJobBoardsForJobsQuery();

  const options = data?.jobBoards || [];

  const onChangeInner: FormSelectMultiCheckboxProps<JobBoardForJobs>['onChange'] = (newValue) => {
    onChange && onChange({ [name]: newValue || [] });
  };

  return {
    className,
    value,
    options,
    onChangeInner,
  } as const;
};

export const JobListBoardsFilter: React.FC<JobListBoardsFilterProps> = (props) => {
  const { className, value, options, onChangeInner } = useJobListBoardsFilterState(props);

  return (
    <JobListBoardsFilterStyled className={className}>
      <FormSelectMultiCheckbox
        value={value}
        className={className}
        options={options}
        getOptionLabel={(option: JobBoardForJobs) => option.name}
        getOptionValue={(option: JobBoardForJobs) => option.id}
        placeholder="Job Boards"
        onChange={onChangeInner}
      />
    </JobListBoardsFilterStyled>
  );
};
