import { useWindowSize } from 'utils/hooks/useWindowSize';

type Devices = Record<DeviceWidth, DeviceSize>;

/**
 * Taken from https://getbootstrap.com/docs/4.0/layout/grid/#grid-options
 */
export enum DeviceSizes {
  extraSmall = 'extraSmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  extraLarge = 'extraLarge',
}

export enum DeviceWidthValues {
  extraSmall = '<576',
  small = '>=576',
  medium = '>=768',
  large = '>=992',
  extraLarge = '>=1200',
}

export type DeviceSize = DeviceSizes;
export type DeviceWidth = DeviceWidthValues;

const devices: Devices = {
  [DeviceWidthValues.extraSmall]: DeviceSizes.extraSmall,
  [DeviceWidthValues.small]: DeviceSizes.small,
  [DeviceWidthValues.medium]: DeviceSizes.medium,
  [DeviceWidthValues.large]: DeviceSizes.large,
  [DeviceWidthValues.extraLarge]: DeviceSizes.extraLarge,
};

function getDeviceWidth(width: number): DeviceWidth {
  switch (true) {
    case width >= 0 && width < 576:
      return DeviceWidthValues.extraSmall;
    case width >= 576 && width < 768:
      return DeviceWidthValues.small;
    case width >= 768 && width < 992:
      return DeviceWidthValues.medium;
    case width >= 992 && width < 1200:
      return DeviceWidthValues.large;
    case width >= 1200:
      return DeviceWidthValues.extraLarge;
    default:
      return DeviceWidthValues.extraSmall;
  }
}

/**
 * This hook operates with bootstrap grid sizes and returns related size for each window width
 *
 * @returns {DeviceSize}
 */
export function useDeviceSize(): DeviceSize {
  const { width } = useWindowSize();
  const deviceWidth = getDeviceWidth(width);

  return devices[deviceWidth];
}
