import styled from 'styled-components';

import { ReactComponent as ExpedoLogo } from 'assets/img/expedo.svg';

import { ExCardHoverable } from 'components/ui/ExCard';
import { ExRow } from 'components/ui/ExLayout';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

export const ExpedoLogoImage = styled(ExpedoLogo)`
  margin-bottom: 1rem;
`;

export const ListTitle = styled.h1`
  font-size: ${getThemeFontSize('extraLarge')};
`;

export const SelectCompanyPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

export const ExRowStyled = styled(ExRow)`
  max-width: 700px;
  width: 100%;
`;

export const CompanyImageWrapper = styled.div`
  width: 40px;
  height: 40px;
  flex-shrink: 0;

  & > div {
    display: flex;

    img {
      object-fit: contain;
    }
  }
`;

export const CompanyName = styled.h4`
  font-size: ${getThemeFontSize('cardHeading')};
`;

export const CardTitle = styled(CompanyName)`
  margin-bottom: 20px;
`;

export const CompanyCardItem = styled(ExCardHoverable)`
  & > a > div > div {
    display: flex;
  }

  ${CompanyImageWrapper} {
    margin-right: 20px;
  }
`;
