export const JOB_BOARD_PARAMETER = 'job_board';
export const JOB_AD_ID_PARAMETER = 'jobAdId';

type GenerateKeyNameProps = {
  companyId: string;
  jobId: string;
  parameter: string;
};

export const generateKeyName = ({ companyId, jobId, parameter }: GenerateKeyNameProps) => {
  return `${companyId}:${jobId}:${parameter}`;
};
