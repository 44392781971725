import React, { useCallback } from 'react';
import styled from 'styled-components/macro';

import { JobListClearFilters } from 'pages/Jobs/components/JobListClearFilters';

import { JobListBoardsFilter } from 'containers/JobLists/components/JobListBoardsFilter';
import { JobListEmploymentType } from 'containers/JobLists/components/JobListEmploymentType';
import { JobListComponentProps } from 'containers/JobLists/JobListProps';
import { jobListActions, jobListSelectors } from 'containers/JobLists/store';

import { TableSearchInput } from 'components/TableSearchInput';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { JobBelongsTo, JobFiltersNames } from 'store/entities/jobs';
import { jobsListUiSlice, JobsListUiSliceState } from 'store/ui/jobs/jobs-list/jobs-list.reducer';
import { jobsListSelectors } from 'store/ui/jobs/jobs-list/jobs-list.selectors';

const JobListFilter: React.FC<JobListComponentProps> = ({ listId, className }) => {
  const dispatch = useAppDispatch();

  const isJobPage = listId === JobBelongsTo.main;

  const jobsListFilters = useAppSelector(jobsListSelectors.selectFilters);

  const onJobListFilterChange = (newFilter: Partial<JobsListUiSliceState['filters']>) => {
    dispatch(
      jobsListUiSlice.actions.updateFilters({
        ...newFilter,
        pageNo: 0,
      }),
    );
  };

  const tableSearchFieldNameJobsPage = 'searchTerm';
  const tableSearchValueJobsPage = jobsListFilters[tableSearchFieldNameJobsPage] || '';

  const onTableSearchChangeJobsPage = useCallback(
    (search, fieldName) => {
      dispatch(jobsListUiSlice.actions.updateFilters({ [fieldName]: search, pageNo: 0 }));
    },
    [dispatch],
  );

  const jobListFiltersNotJobsPage = useAppSelector(jobListSelectors.getFilters, { listId });

  const tableSearchFieldNameNotJobsPage = JobFiltersNames.search;
  const tableSearchValueNotJobsPage = jobListFiltersNotJobsPage[tableSearchFieldNameNotJobsPage] || '';

  const onTableSearchChangeNotJobsPage = useCallback(
    (search, fieldName) => {
      dispatch(jobListActions.updateFilters({ id: listId, filters: { [fieldName]: search } }));
    },
    [dispatch, listId],
  );

  return (
    <div className={className}>
      {isJobPage && (
        <TableSearchInput
          name={tableSearchFieldNameJobsPage}
          placeholder="Search Jobs"
          value={tableSearchValueJobsPage}
          onChange={onTableSearchChangeJobsPage}
          onChangeDelay={500}
          searchHint="Start typing a Job Name"
        />
      )}
      {!isJobPage && (
        <>
          <TableSearchInput
            name={tableSearchFieldNameNotJobsPage}
            placeholder="Search Jobs"
            value={tableSearchValueNotJobsPage}
            onChange={onTableSearchChangeNotJobsPage}
            onChangeDelay={500}
            searchHint="Start typing a Job Name"
          />
        </>
      )}
      {isJobPage && (
        <>
          <div className="list-filter-pipeline">
            <JobListEmploymentType
              name="employmentTypes"
              value={jobsListFilters.employmentTypes}
              onChange={onJobListFilterChange}
            />
          </div>
          <JobListBoardsFilter value={jobsListFilters.jobBoards} name="jobBoards" onChange={onJobListFilterChange} />
          <JobListClearFilters />
        </>
      )}
    </div>
  );
};

export default styled(JobListFilter)`
  display: flex;

  .list-filter-pipeline {
    margin-left: 10px;
    width: 300px;

    .form-group {
      margin-bottom: 0;
    }
  }

  .list-filter-search {
    width: 205px;
  }
`;
