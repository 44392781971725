import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Routes } from 'router';
import styled from 'styled-components/macro';

import { CompanyInfoItem } from 'pages/Company/components/CompanyInfoItem';

import { selectInterviewTemplatesSelected } from 'containers/JobForms/state/selectors';

import { JobSettingsCardRedesignStyled } from 'components/JobSettingsCardRedesign/JobSettingsCardComponents';
import { ExCardEditButton } from 'components/ui/ExCard/components/ExCardEditButton';
import { ExCardBodyStyled, ExCardTitle } from 'components/ui/ExCard/ExCardComponents';
import { ExVisible } from 'components/ui/ExVisible';
import { useAppSelector } from 'utils/hooks/useSelectors';

type JobInterviewTemplatesReviewProps = {
  className?: string;
};

const JobInterviewTemplatesReviewStyled = styled(JobSettingsCardRedesignStyled)`
  height: auto;

  ${ExCardBodyStyled} {
    height: 112px;
    overflow-y: auto;
  }
`;

const useJobInterviewTemplatesReviewState = ({ className }: JobInterviewTemplatesReviewProps) => {
  const { push } = useHistory();

  const title = 'Interview Template';
  const interviewTemplatesSelected = useAppSelector(selectInterviewTemplatesSelected) || [];
  const hasInterviewTemplatesSelected = interviewTemplatesSelected.length;

  const editAction = useCallback(() => {
    push(Routes.jobFormStepDetails);
  }, [push]);

  return {
    className,
    title,
    interviewTemplatesSelected,
    hasInterviewTemplatesSelected,
    editAction,
  } as const;
};

export const JobInterviewTemplatesReview: React.FC<JobInterviewTemplatesReviewProps> = (props) => {
  const { className, title, interviewTemplatesSelected, hasInterviewTemplatesSelected, editAction } =
    useJobInterviewTemplatesReviewState(props);

  return (
    <JobInterviewTemplatesReviewStyled
      className={className}
      header={
        <>
          <ExCardTitle>{title}</ExCardTitle>
          <ExCardEditButton onClick={editAction}>Edit</ExCardEditButton>
        </>
      }
    >
      <ExVisible visible={!hasInterviewTemplatesSelected}>
        <CompanyInfoItem>No items</CompanyInfoItem>
      </ExVisible>
      <ExVisible visible={hasInterviewTemplatesSelected}>
        {interviewTemplatesSelected.map((interviewTemplate) => (
          <CompanyInfoItem key={interviewTemplate.interviewTemplateId}>{interviewTemplate.name}</CompanyInfoItem>
        ))}
      </ExVisible>
    </JobInterviewTemplatesReviewStyled>
  );
};
