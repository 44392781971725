import { useEffect } from 'react';

const getScrollWidth = () => {
  const documentWidth = document.documentElement.clientWidth;
  return Math.abs(window.innerWidth - documentWidth);
};

const lockBodyScroll = () => {
  const style = window.document.createElement('style');
  const scrollWidth = getScrollWidth();
  style.innerHTML = `body.no-scroll { padding-right: ${scrollWidth}px; overflow: hidden;}`;
  document.getElementsByTagName('head')[0].appendChild(style);
  window.document.body.classList.add('no-scroll');
  return () => {
    window.document.body.classList.remove('no-scroll');
    style.remove();
  };
};

export function useNoScrollBodyOnMount() {
  useEffect(lockBodyScroll, []);
}

export function useNoScrollBodyWhen(condition: boolean) {
  useEffect(() => {
    if (condition) {
      return lockBodyScroll();
    }
  }, [condition]);
}
