import { nanoid } from '@reduxjs/toolkit';
import pick from 'lodash/pick';
import { buffers, Channel, channel } from 'redux-saga';
import { all, call, fork, put, putResolve, race, SagaReturnType, select, take } from 'redux-saga/effects';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { invokeExModalWizard, prepareExModalChannel, PrepareResultActions, prepareResultActions } from 'utils/sagas';

import {
  HiringPipelineStage,
  HiringPipelineStagePrepareEdit,
} from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.model';
import {
  hiringPipelinesStagesSelectors,
  hiringPipelineStageActions,
} from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.reducer';
import * as hiringPipelineStagesTypes from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.types';
import { enhancedHiringPipelineApi } from 'store/entities/hiring-pipelines/hiring-pipelines.api';
import {
  hiringPipelineActions,
  hiringPipelineSelectors,
} from 'store/entities/hiring-pipelines/hiring-pipelines.reducer';
import {
  cannotRemovePipelineStage,
  EditHiringPipelineActions,
  EditSagaAction,
  getEditModalPage,
  getEditModalTitle,
  getHiringPipelineStagesTemporary,
  getPipelineButtonLabels,
  getResultActions,
  goToAddNewStage,
  goToEditCustomPipelinePage,
  goToEditStage,
  goToRemoveStage,
  groupPipelineActionsById,
  newNameForHiringPipelineWorker,
  prepareActionsForStage,
  prepareActionsOfStageActions,
  updateHiringPipelineStages,
} from 'store/entities/hiring-pipelines/sagas/hiring-piplines-edit/utils';
import { exModalConfirmAction, exModalHideAction, wizardBackward } from 'store/modals/modals.actions';
import { ModalGeneralResult } from 'store/modals/modals.interfaces';

// EDIT SAGA

/**
 * This saga runs the edit pipeline modal window.
 * In this window user can remove/edit/create pipeline stages.
 * Changes will be applied (sent to the API) only when user will press the `Save` button.
 *
 * In this saga we use the `actionsChannel` (FIFO query) with the `actionsChannelBuffer` to handle all of
 * actions which user done during interacting with UI.
 * After pressing `Save` button all of these actions are grouped by `pipelineStageId`
 * and processing with the next algorithm:
 *
 * There may be only three types of actions per each pipeline stage - `remove`, `update` and `create`.
 * For example we have two pipeline stages with the next ids - `firstStage`, `secondStage`.
 * If user will update and then delete `firstStage`, then update `secondStage` our `actionChannel` will contain the next actions:
 *
 * |`secondStage` - `update`| - caused by user updating the stage name or stageType
 * |`secondStage` - `update`| - caused by removing the firstStage. In this case we must recalculate order of each stage
 * |`firstStage`  - `remove`| - caused by user removing
 * |`firstStage`  - `update`| - caused by user updating
 *
 * After, we will group all actions by `pipelineStageId` in the same order they appears in the action channel.
 * To process these actions we must use only the first and the last action of the each pipelineStage.
 *
 * There are 4 (four) possible variants (firstAction - secondAction - result):
 * 1. `create` - `remove` - do nothing (emptyAction);
 * 2. `create` - `update` - `create` with the most actual payload;
 * 3. !`create` - `any action` - `any action` with the most actual payload;
 * 4. actions are equal - `lastAction` with the most actual payload;
 *
 * This logic described in the `getResultActionFromActionPair` function.
 */
export function* editHiringPipelineSaga(action: EditHiringPipelineActions) {
  const { modalId, sagaChannel } = yield prepareExModalChannel();

  let pipelineId = action.payload;

  const goToEditPageWithModalId = goToEditCustomPipelinePage({ modalId });
  const goToAddNewStageWithModalId = goToAddNewStage({ modalId });
  const goToRemoveStageWithModalId = goToRemoveStage({ modalId });
  const goToEditStageWithModalId = goToEditStage({ modalId });

  // This temporary array of stages needed to operate via Edit modal life cycle
  let hiringPipelineStagesTemporary = yield getHiringPipelineStagesTemporary(pipelineId);

  // Start modal wizard
  yield fork(invokeExModalWizard, {
    channel: sagaChannel,
    modalId,
    modalProps: {
      pipelineId,
      hiringPipelineStages: hiringPipelineStagesTemporary,
    },
    modalConfig: {
      wizardType: 'hiringPipelineEdit',
      page: getEditModalPage(action),
      content: {
        withTitle: true,
        title: getEditModalTitle(action),
      },
    },
  });

  // Prepare channel and buffer for actions
  const actionsChannelBuffer = buffers.expanding();
  const actionsChannel: Channel<EditSagaAction> = yield channel(actionsChannelBuffer);

  // Run the main saga flow
  while (true) {
    /**
     * Wait for action from user. Result - is the `Cancel` or `Save` button reaction.
     */
    const {
      showEditModal,
      showRemoveModal,
      showCreateModal,
      result,
      newNameForHiringPipeline,
      back,
      prepareCreate,
      prepareRemove,
      prepareEdit,
    }: {
      showEditModal: SagaReturnType<typeof hiringPipelineStageActions.showEditModal>;
      showRemoveModal: SagaReturnType<typeof hiringPipelineStageActions.showRemoveModal>;
      showCreateModal: SagaReturnType<typeof hiringPipelineStageActions.showCreateModal>;
      newNameForHiringPipeline: SagaReturnType<typeof hiringPipelineActions.create>;
      result: SagaReturnType<typeof sagaChannel>;
      back: SagaReturnType<typeof wizardBackward>;
      prepareCreate: ReturnType<typeof hiringPipelineStageActions.prepareCreate>;
      prepareRemove: ReturnType<typeof hiringPipelineStageActions.prepareRemove>;
      prepareEdit: { payload: HiringPipelineStagePrepareEdit };
    } = yield race({
      showEditModal: take(hiringPipelineStageActions.showEditModal),
      showRemoveModal: take(hiringPipelineStageActions.showRemoveModal),
      showCreateModal: take(hiringPipelineStageActions.showCreateModal),
      newNameForHiringPipeline: take(hiringPipelineActions.create),
      prepareCreate: take(hiringPipelineStageActions.prepareCreate),
      prepareRemove: take(hiringPipelineStageActions.prepareRemove),
      prepareEdit: take(hiringPipelineStageActions.prepareEdit),
      result: take(sagaChannel),
      back: take(wizardBackward),
    });

    // In this block we handle all possible user interactions
    switch (true) {
      // Creating/cloning pipeline
      case Boolean(newNameForHiringPipeline): {
        /**
         * In the current saga we must use ClonePipelineFunc because due to
         * requirements documentation new Custom Hiring Pipeline
         * must be based on the general (default) pipeline
         */
        const newPipelineName = newNameForHiringPipeline?.payload.name!;

        const newNameForHiringPipelineResult = yield newNameForHiringPipelineWorker(
          action,
          modalId,
          newPipelineName,
          newNameForHiringPipeline,
          hiringPipelineStagesTemporary,
          pipelineId,
        );

        pipelineId = newNameForHiringPipelineResult.pipelineId;

        hiringPipelineStagesTemporary = newNameForHiringPipelineResult.hiringPipelineStagesTemporary;

        continue;
      }
      // CREATE HIRING PIPELINE STAGE MODAL LOGIC
      case Boolean(showCreateModal): {
        // Change modal wizard page for the `Add New Stage`
        yield put(goToAddNewStageWithModalId());
        continue;
      }
      case Boolean(prepareCreate): {
        /**
         * If user have created new stage do the next:
         *
         * 1. Create temporary new pipelineStage;
         * 2. Merge existed stages with the new one;
         * 3. Put the related action into the `actionsChannel`
         */
        // 1.
        const lastCustomStage = yield select(
          hiringPipelinesStagesSelectors.selectLastCustomPipelineStage,
          prepareCreate.payload.pipelineId,
        );

        hiringPipelineStagesTemporary = yield getHiringPipelineStagesTemporary(pipelineId);

        const lastCustomStageIndex = hiringPipelineStagesTemporary.findIndex(
          (stage) => stage.pipelineStageId === lastCustomStage.pipelineStageId,
        );

        const newHiringPipelineStage: hiringPipelineStagesTypes.HiringPipelineStage = HiringPipelineStage({
          ...prepareCreate.payload,
          pipelineStageId: nanoid(), // this temporary `pipelineStageId` is needed to manage current stage during Edit Modal lifecycle
          order: lastCustomStage.order + 1,
        });

        // 2.
        const modifiedStages = [...hiringPipelineStagesTemporary];
        modifiedStages.splice(lastCustomStageIndex + 1, 0, newHiringPipelineStage);
        hiringPipelineStagesTemporary = [...modifiedStages];

        // 3.
        actionsChannel.put(hiringPipelineStageActions.create.start(newHiringPipelineStage));

        // Send 'Confirm' to proceed updating.
        yield put(exModalConfirmAction({ id: modalId }));

        // Restart the main saga flow
        continue;
        // -------------------------------------------------------------------------------------------------
      }
      case Boolean(showRemoveModal): {
        const { pipelineStageId } = showRemoveModal.payload;

        const cannotRemoveStage = yield cannotRemovePipelineStage(pipelineId!, pipelineStageId);

        if (cannotRemoveStage) {
          continue;
        }

        // REMOVE HIRING PIPELINE STAGE MODAL LOGIC

        hiringPipelineStagesTemporary = yield getHiringPipelineStagesTemporary(pipelineId);

        // Change modal wizard page for the `Confirm Remove Stage`
        yield put(
          goToRemoveStageWithModalId({
            pipelineStageId,
            hiringPipelineStages: hiringPipelineStagesTemporary,
          }),
        );

        // Restart the main saga flow
        continue;
      }
      case Boolean(prepareRemove): {
        const cannotRemoveStage = yield cannotRemovePipelineStage(pipelineId!, prepareRemove.payload.pipelineStageId);

        if (cannotRemoveStage) {
          continue;
        }

        /**
         * If use have removed pipelineStage we do the next:
         *
         * 1. Remove this pipelineStage from array of `hiringPipelineStagesTemporary`;
         * 2. Put in the `actionChannel` actions to change the order of all pipelineStages affected by remove;
         *    Change order of all pipelineStages affected by remove and write them to the `hiringPipelineStagesTemporary`;
         * 4. Put the related action into the `actionsChannel`
         */

        // 1.
        const hiringPipelineStagesTemporaryFiltered = [...hiringPipelineStagesTemporary].filter(
          (pipelineStage) => pipelineStage.pipelineStageId !== prepareRemove.payload.pipelineStageId,
        );

        // 2.
        hiringPipelineStagesTemporary = hiringPipelineStagesTemporaryFiltered.map((pipelineStage, index) => {
          return {
            ...pipelineStage,
            order: index,
          };
        });

        // 3.
        actionsChannel.put(hiringPipelineStageActions.remove.start(prepareRemove.payload));

        // Send 'Confirm' to proceed updating.
        yield put(exModalConfirmAction({ id: modalId }));

        // Restart the main saga flow
        continue;
        // -------------------------------------------------------------------------------------------------
      }
      // EDIT HIRING PIPELINE STAGE MODAL LOGIC
      case Boolean(showEditModal): {
        const { pipelineStageId } = showEditModal.payload;

        const hiringPipelineStages = yield select(
          hiringPipelinesStagesSelectors.selectByPipelineIdSortedByOrder,
          pipelineId,
        );
        hiringPipelineStagesTemporary = yield getHiringPipelineStagesTemporary(pipelineId);

        hiringPipelineStagesTemporary = yield call(
          prepareActionsForStage,
          pipelineStageId,
          pipelineId!,
          hiringPipelineStages,
          hiringPipelineStagesTemporary,
        );

        // Change modal wizard page for the `Edit Custom Stage`
        yield put(
          goToEditStageWithModalId({
            pipelineStageId,
            hiringPipelineStages: hiringPipelineStagesTemporary,
          }),
        );

        // Restart the main saga flow
        continue;
      }
      case Boolean(prepareEdit): {
        /**
         * If user have edited the pipelineStage do the next:
         *
         * 1. Update the existed pipeline stage in `hiringPipelineStagesTemporary`;
         * 2. Find the updated stage;
         * 3. Put the related action into the `actionsChannel`
         */
        // 1.
        hiringPipelineStagesTemporary = updateHiringPipelineStages(hiringPipelineStagesTemporary, prepareEdit.payload);

        // 2.
        const pipelineStageForUpdate = hiringPipelineStagesTemporary.find(
          (pipelineStage) => pipelineStage.pipelineStageId === prepareEdit.payload.pipelineStageId,
        );

        // 3.
        actionsChannel.put(hiringPipelineStageActions.update.start(pipelineStageForUpdate));

        // Send 'Confirm' to proceed updating.
        yield put(exModalConfirmAction({ id: modalId }));

        // Restart the main saga flow
        continue;
        // -------------------------------------------------------------------------------------------------
      }
      case Boolean(back): {
        /**
         * Change modal wizard page to the Edit Custom Pipeline.
         */
        yield put(
          goToEditPageWithModalId({
            pipelineId: pipelineId!,
          }),
        );

        // Restart the main saga flow
        continue;
      }
      //  If user click `Cancel` or `Save` button - break the switch and resume saga flow.
      default:
        break;
    }

    const { confirm, cancel } = result as ModalGeneralResult;

    // If user click `Cancel` button - close the modal window and break the saga flow.
    if ([cancel, !confirm].some(Boolean)) {
      yield put(exModalHideAction({ id: modalId }));
      return;
    }

    yield call(startLoader, action);

    // Take all actions from the actionsChannel
    // Group actions by id and decide which action must be invoked.
    const resultActions: SagaReturnType<PrepareResultActions<EditSagaAction>> = yield call(
      prepareResultActions,
      actionsChannel,
      groupPipelineActionsById,
      getResultActions,
    );

    //-------------------------------------------------------------------------------------------

    // Run all specified actions in background. Each type of action have it own saga;
    yield all(resultActions);

    // Array of stage ids for assign stage actions
    const stageIds: Array<{ originalId: string; returnedId: string }> = [];

    const resultActionsLength = resultActions.length;
    // Waiting for all actions are running in the background;
    for (let i = 0; i < resultActionsLength; i++) {
      const stageId: ReturnType<typeof hiringPipelineStageActions.processingFinished> = yield take(
        hiringPipelineStageActions.processingFinished,
      );
      const { originalId, returnedId } = stageId.payload;
      // If the save process return stageId store it for next step
      if ([originalId, returnedId].every(Boolean)) {
        stageIds.push({ originalId: originalId!, returnedId: returnedId! });
      }
    }

    const stagesActions = yield prepareActionsOfStageActions(pipelineId!, stageIds, hiringPipelineStagesTemporary);

    yield all(stagesActions);

    const pipeline = yield select(hiringPipelineSelectors.selectById, pipelineId!);

    const pipelineData = pick(pipeline, ['pipelineId', 'name', 'firstStageButtonLabel', 'declineButtonLabel']);

    const pipelineUpdated = {
      ...pipelineData,
      ...getPipelineButtonLabels(hiringPipelineStagesTemporary),
    };

    const mutationUpdate = yield call(enhancedHiringPipelineApi.endpoints.updatePipeline.initiate, {
      pipelineId,
      data: pipelineUpdated,
    });
    yield putResolve(mutationUpdate);

    // CLose modal window and refetch the pipeline in the background
    yield all([
      put(
        goToEditPageWithModalId({
          pipelineId: pipelineId!,
        }),
      ),
      call(stopLoader, action),
    ]);
  }
}
//---------------------------------------------------------------------------------------------------------
