import { list } from 'config/list';

import { TalentPoolBelongsTo } from 'store/entities/talent-pools/models';

import { InitTalentPoolListsState, TalentPoolListState } from './models';

export const initialListState: Omit<TalentPoolListState, 'id'> = {
  fetched: false,
  filters: {},
  items: [],
  pageCount: 0,
  pageNo: 0,
  totalItemsCount: 0,
  pageSize: list.pageSize,
  pagination: {},
  selectedItems: [],
  sortMode: {},
  viewMode: list.viewMode,
};

export const initialState: InitTalentPoolListsState = {
  [TalentPoolBelongsTo.main]: { ...initialListState, id: TalentPoolBelongsTo.main },
  [TalentPoolBelongsTo.candidate]: { ...initialListState, id: TalentPoolBelongsTo.candidate },
} as Record<TalentPoolListState['id'], TalentPoolListState>;
