import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { Action } from 'redux';

import { useLoaderManager, useLoaderSubscription } from 'modules/LoaderManager/react/hooks';

type LoaderHandlerWrapperProps = {
  children: ReactElement;
  className?: string;
  disabled?: boolean;
  action?: Action<string>;
  isLoading?: boolean;
};

/**
 * This simple wrapper which listen on any loader selector - in case if something is loading now,
 * and adds {disabled: boolean} prop to the children.
 *
 * Will work only with elements that handle `disable` prop such as buttons, inputs
 * and these inputs causes global loader actions.
 * If you want to use this wrapper on custom component, please provide `disable` prop handling to it.
 *
 * @param {LoaderHandlerWrapperProps} { children }
 * @returns {ReactElement}
 */
export const ExLoaderHandlerWrapper: React.FC<LoaderHandlerWrapperProps> = ({
  children,
  disabled: propDisabled,
  isLoading: propIsLoading,
  action,
  ...rest
}: LoaderHandlerWrapperProps): ReactElement => {
  const { isLoading } = useLoaderSubscription(action ?? { type: 'emptyAction' });

  const isChildrenLoading = isLoading || children?.props?.isLoading || propIsLoading;

  const isChildrenDisabled = isChildrenLoading || children?.props?.disabled || propDisabled;

  return <>{React.cloneElement(children, { ...rest, disabled: isChildrenDisabled, isLoading: isChildrenLoading })}</>;
};

/**
 * This is the copy of component above, but it subscribe for any loaderEvent
 *
 * @param {LoaderHandlerWrapperProps} { children }
 * @returns {ReactElement}
 */
export const ExLoaderHandlerWrapperStrict: React.FC<LoaderHandlerWrapperProps> = ({
  children,
  disabled: propDisabled,
  isLoading: propIsLoading,
  action,
  ...rest
}: LoaderHandlerWrapperProps): ReactElement => {
  const { isSomethingLoading } = useLoaderManager(action?.type ?? 'emptyAction');

  const isChildrenLoading = isSomethingLoading || children?.props?.isLoading || propIsLoading;

  const isChildrenDisabled = isChildrenLoading || children?.props?.disabled || propDisabled;

  return <>{React.cloneElement(children, { ...rest, disabled: isChildrenDisabled, isLoading: isChildrenLoading })}</>;
};

export const ExLoaderFormContextHandlerWrapper: React.FC<LoaderHandlerWrapperProps> = ({
  children,
  disabled: propDisabled,
  isLoading: propIsLoading,
  action,
  ...rest
}: LoaderHandlerWrapperProps): ReactElement => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  const isChildrenLoading = isSubmitting || children?.props?.isLoading || propIsLoading;

  const isChildrenDisabled = isChildrenLoading || children?.props?.disabled || propDisabled;

  return <>{React.cloneElement(children, { ...rest, disabled: isChildrenDisabled, isLoading: isChildrenLoading })}</>;
};
