import { useSelector } from 'react-redux';
import { isDate } from 'date-fns';
import startCase from 'lodash/startCase';

import { AvailabilityType, SeniorityLevel } from 'model/api-enums.constants';

import { DetailsRowProps } from 'components/DetailsCard';
import { EnumLiteralsOf } from 'utils/funcs';
import { useCandidateAddressLine, useCandidateEmail, useCandidateFullName } from 'utils/hooks/candidate';
import { dateFnsFormat } from 'utils/hooks/dateTime';
import { findSocialNetwork, socialNetworkKeys } from 'utils/socialNetworks';

import { companySelectors } from 'store/company/company.selectors';
import { AvailabilityObject, Candidate, SocialNetwork } from 'store/entities/candidates/models';

export function candidateAvailability(
  availability: AvailabilityObject | undefined | null,
  dateFormat: string,
): string | undefined {
  if (!availability) {
    return availability ?? undefined;
  }

  if (availability.availabilityType !== AvailabilityType.ActualDate) {
    return startCase(AvailabilityType[availability.availabilityType]);
  }

  if (availability.actualDate === null) {
    return undefined;
  }
  const specificDate = new Date(availability.actualDate);

  return isDate(specificDate) ? dateFnsFormat(specificDate, dateFormat) : undefined;
}

export const useCandidateAvailability = (availability: AvailabilityObject | undefined | null) => {
  const defaultDateFormat = useSelector(companySelectors.getDefaultDateFormat);

  return candidateAvailability(availability, defaultDateFormat);
};

/**
 * Function that generate candidate social network details row and filter only those have urls
 *
 * @param candidate
 */
const socialNetworkProps = (candidate: Candidate): DetailsRowProps[] =>
  socialNetworkKeys
    .map((socialNetwork) => ({
      label: SocialNetwork[socialNetwork],
      value: candidate?.socialNetworks?.find(findSocialNetwork(socialNetwork))?.url,
    }))
    .filter((socialNetworkProp) => Boolean(socialNetworkProp.value));

const useDetailsRowProps = (candidate: Candidate): DetailsRowProps[] => {
  return [
    { label: 'Name', value: useCandidateFullName(candidate) },
    { label: 'Phone', value: candidate?.phone },
    { label: 'Mobile', value: candidate?.mobile },
    { label: 'Email', value: useCandidateEmail(candidate) },
    { label: 'Address', value: useCandidateAddressLine(candidate) },
    { label: 'Availability', value: useCandidateAvailability(candidate?.candidateAvailability) },
    ...socialNetworkProps(candidate),
  ];
};

type ApplicantSeniorityLevelProps = {
  seniorityLevel?: EnumLiteralsOf<typeof SeniorityLevel> | null;
};

export const getApplicantSeniorityLevel = ({ seniorityLevel }: ApplicantSeniorityLevelProps) => {
  const result = Object.entries(SeniorityLevel).find((level) => level[1] === seniorityLevel)?.[0];
  return result ? startCase(result) : '--';
};

export { useDetailsRowProps };
