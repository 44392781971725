import { CompanyCountriesFormBody } from 'containers/CompanyForms/CompanyCountriesFormBody';
import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import { ExModalBodyStyled, ExModalWrapperStyled } from 'containers/Modals/ModalsContent/Company/ExModalComponents';
import { useEntitiesStateWithToggle } from 'containers/Modals/ModalsContent/Company/hooks';

import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { DialogProps } from 'components/Dialog';
import { ExDialogActionButton, ExDialogActions } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { companyUpdateCountries } from 'store/company/company.actions';
import { companySelectors } from 'store/company/company.selectors';
import type { CompanyInfo } from 'store/company/company.types';
import { useCountryQuerySelectors } from 'store/dictionary/hooks/useCountryQuerySelectors';
import { exModalConfirmAction } from 'store/modals/modals.actions';

type CompanyUpdateCountriesProps = {
  id: string;
};

export const CompanyUpdateCountries = ({ id, onClose }: CompanyUpdateCountriesProps & AppModalContentProps) => {
  const defaultValue = useAppSelector(companySelectors.getCompanyCountries);
  const { selectors: countriesSelectors } = useCountryQuerySelectors();
  const allEntities = useAppSelector(countriesSelectors.selectAll) as CompanyInfo['countries'];
  const { entities, setEntities, allSelected, setAllSelected } = useEntitiesStateWithToggle(defaultValue, allEntities);

  const toggleSelectAll = () => {
    const updatedValue = !allSelected;
    if (updatedValue) {
      /**
       * In the code below we filter already selected countries and then merge them.
       * This functionality needs to prevent reset of previously selected default country
       */
      const countriesForUpdate = allEntities.filter((country) => {
        const isSelected = entities.map((selectedCountry) => selectedCountry.countryId).includes(country.countryId);

        return !isSelected;
      });

      setEntities([...entities, ...countriesForUpdate]);
    } else {
      setEntities([]);
    }

    setAllSelected(updatedValue);
  };

  const dispatch = useAppDispatch();

  const content: DialogProps['content'] = {
    title: 'Add Countries',
    withTitle: true,
  };

  const onSubmitClickHandler = () => {
    dispatch(exModalConfirmAction({ id, modalResult: { entities } }));
  };

  return (
    <ExModalWrapperStyled onClose={onClose} content={content}>
      <ExModalBodyStyled>
        <CompanyCountriesFormBody
          onChange={setEntities}
          defaultValue={entities}
          toggleSelectAll={toggleSelectAll}
          allSelected={allSelected}
        />
        <ExDialogActions>
          <ExDialogActionButton variant="light" onClick={onClose}>
            Cancel
          </ExDialogActionButton>
          <ExLoaderHandlerWrapper action={companyUpdateCountries}>
            <ExDialogActionButton onClick={onSubmitClickHandler}>Save</ExDialogActionButton>
          </ExLoaderHandlerWrapper>
        </ExDialogActions>
      </ExModalBodyStyled>
    </ExModalWrapperStyled>
  );
};
