import React from 'react';
import styled from 'styled-components';

import { Form } from 'components/Form';

const Step: React.FC<any> = React.forwardRef(({ children, ...rest }, ref) => {
  return (
    <Form ref={ref} {...rest}>
      {children}
    </Form>
  );
});

export default styled(Step)`
  width: 100%;
  overflow-y: auto;
  height: 100%;
  padding-right: 15px;
  margin-right: -15px;
  padding-left: 15px;
  margin-left: -15px;
  padding-bottom: 170px;
`;
