import { useEffect } from 'react';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';
import { modalsActions } from 'containers/Modals/store';
import { loadTalentPools } from 'containers/TalentPoolLists/store/effects';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { TalentPool, talentPoolEffects } from 'store/entities/talent-pools';

type SaveTalentPoolProps = {
  data: Partial<TalentPool> | null;
  onClose?: () => void;
  submitting: boolean;
  setSubmitting: (submitting: boolean) => void;
  message: string;
  id: ModalsTypeKey;
};

export const useSaveTalentPool = ({ data, onClose, submitting, setSubmitting, message, id }: SaveTalentPoolProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const save = async () => {
      const resultAction = await dispatch(talentPoolEffects.saveTalentPool({ formData: data!, preloader: true }));
      if (talentPoolEffects.saveTalentPool.fulfilled.match(resultAction)) {
        dispatch(
          modalsActions.saveResult({
            id,
            payload: resultAction.payload,
          }),
        );

        dispatch(alertsEffects.showSuccess({ message }));
        dispatch(loadTalentPools({ listId: 'main' }));
        onClose && onClose();
      } else {
        setSubmitting(false);
      }
    };

    if (data) {
      if (submitting) return;
      setSubmitting(true);
      save();
    }
  }, [data]); // eslint-disable-line
};
