import React from 'react';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { LabelWrapper, StepNumber } from './TabMenuComponents';
import { TabMenuItemProps } from './TabMenuProps';

const TabMenuItem = React.forwardRef<NavLink, TabMenuItemProps>(({ item, className }, ref) => {
  const match = useRouteMatch();
  return (
    <NavItem className={className}>
      <NavLink
        className="link"
        ref={ref as any}
        eventKey={item.path}
        as={Link}
        to={`${match.url}/${item.path}`}
        disabled={item.disabled}
      >
        {item.stepNumber && <StepNumber className="step-number">{item.stepNumber}</StepNumber>}{' '}
        <LabelWrapper>
          {item.label}
          {item?.renderSubTitle && React.createElement(item.renderSubTitle)}
        </LabelWrapper>
      </NavLink>
    </NavItem>
  );
});

export default styled(TabMenuItem)`
  .link {
    color: ${({ theme }) => theme.colors.dark};
    padding: ${({ $vertical }) => (!$vertical ? '0 0 10px' : '16px 5px')};
    margin: ${({ $vertical }) => (!$vertical ? '0 50px 0 0' : '0')};
    display: flex;
    align-items: center;

    &.active {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 700;

      .step-number {
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;
