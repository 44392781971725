import React from 'react';
import styled from 'styled-components/macro';

import { ApplicantModalSwitchMode } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalSwitchMode';

import { ApplicantModalNameInfoEdit } from './ApplicantModalNameInfoEdit';
import { ApplicantModalNameInfoView } from './ApplicantModalNameInfoView';

type ApplicantModalNameInfoProps = {
  className?: string;
};

const ApplicantModalNameInfoStyled = styled(ApplicantModalSwitchMode)`
  width: 100%;
`;

const useApplicantModalNameInfoState = ({ className }: ApplicantModalNameInfoProps) => {
  return {
    className,
  } as const;
};

export const ApplicantModalNameInfo: React.FC<ApplicantModalNameInfoProps> = (props) => {
  const { className } = useApplicantModalNameInfoState(props);

  return (
    <ApplicantModalNameInfoStyled
      className={className}
      editComponent={<ApplicantModalNameInfoEdit />}
      viewComponent={<ApplicantModalNameInfoView />}
    />
  );
};
