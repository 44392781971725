import { useCallback } from 'react';

import { ExInterview } from 'model';

import { ModalsTypeKey } from 'containers/Modals/AppModalProps';
import { modalsActions } from 'containers/Modals/store';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';

type useInterviewSettingsModalParams = {
  interviewId?: ExInterview['interviewId'];
};

export function useInterviewSettingsModal({ interviewId }: useInterviewSettingsModalParams) {
  const dispatch = useAppDispatch();

  const openInterviewSettingsModal = useCallback(() => {
    if (!interviewId) {
      return;
    }
    dispatch(modalsActions.setInterviewId({ interviewId }));
    dispatch(modalsActions.showModal({ modalType: ModalsTypeKey.newInterviewSettingsModal }));
  }, [dispatch, interviewId]);
  return [openInterviewSettingsModal] as const;
}
