import React from 'react';
import styled, { DefaultTheme } from 'styled-components';

import { ExBadgeVariant } from 'components/ui/ExBadge/ExBadgeProps';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

type ExBadgeOutlineProps = {
  className?: string;
  variant: keyof DefaultTheme['colors'] | ExBadgeVariant;
  label?: string;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  prependIcon?: boolean;
};

export const ExBadgeOutlineComponent = styled.div<{ variant: ExBadgeOutlineProps['variant'] }>`
  color: ${({ theme, variant }) => theme.colors[variant]};
  display: inline-flex;
  align-items: center;
  font-size: ${getThemeFontSize('small')};
`;

const ExBadgeCircle = styled.span<{ variant: ExBadgeOutlineProps['variant'] }>`
  background-color: currentColor;
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 6px;
  flex-shrink: 0;
`;

export const ExBadgeOutline: React.FC<ExBadgeOutlineProps> = ({ className, children, variant, prependIcon }) => {
  return (
    <ExBadgeOutlineComponent className={className} variant={variant}>
      {prependIcon && <ExBadgeCircle variant={variant} />}
      {children}
    </ExBadgeOutlineComponent>
  );
};
