import candidateListSlice from './reducers';
import * as selectors from './selectors';

const { reducer, actions } = candidateListSlice;

export const candidateListActions = actions;

export const candidateListSelectors = selectors;

export default reducer;

export * from './models';
