import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { matchPath } from 'react-router-dom';
import { ExRoutesPathMap } from 'router/routes';

export function useApplicantCounter() {
  const match = useRouteMatch();

  const [showCounter, setShowCounter] = useState(false);
  useEffect(() => {
    const isModalInJob = matchPath(match.url, { path: ExRoutesPathMap.applicantsView.path });
    setShowCounter(!!isModalInJob);
  }, [match.url]);

  return showCounter;
}
