import { takeEvery, takeLatest } from 'redux-saga/effects';

import { publicCompanyActions } from 'pages/public/state/public-company/public-company.actions';
import { publicCompanyGetWorker } from 'pages/public/state/public-company/public-company.saga';
import { publicCountryActions } from 'pages/public/state/public-country/public-country.actions';
import { publicCountryFetchAction } from 'pages/public/state/public-country/public-country.saga';
import { publicCurrencyActions } from 'pages/public/state/public-currency/public-currency.actions';
import { publicCurrencyFetchAction } from 'pages/public/state/public-currency/public-currency.saga';
import { publicJobsActions } from 'pages/public/state/public-jobs/public-jobs.actions';
import {
  confirmPendingJobAction,
  publicJobsFetchAction,
  publicJobsGetAction,
  withdrawPendingJobAction,
} from 'pages/public/state/public-jobs/public-jobs.saga';
import { publicNewApplicantFormActions } from 'pages/public/state/public-new-applicant-form/public-new-applicant-form.actions';
import {
  publicNewApplicantFormCloseWorker,
  publicNewApplicantFormRemoveUploadAction,
  publicNewApplicantFormUploadAction,
} from 'pages/public/state/public-new-applicant-form/public-new-applicant-form.saga';
import { publicSubmitApplicationSaga } from 'pages/public/state/sagas/public-submit-application.saga';

export function* publicPageSagas() {
  yield takeEvery(publicCompanyActions.publicCompanyGetAction, publicCompanyGetWorker);
  yield takeLatest(publicJobsActions.publicJobsFetchAction, publicJobsFetchAction);
  yield takeEvery(publicJobsActions.publicJobsGetAction, publicJobsGetAction);
  yield takeLatest(publicCountryActions.publicCountryFetchAction, publicCountryFetchAction);
  yield takeLatest(publicCurrencyActions.publicCurrencyFetchAction, publicCurrencyFetchAction);
  yield takeLatest(publicJobsActions.confirmPendingJobAction, confirmPendingJobAction);
  yield takeLatest(publicJobsActions.withdrawPendingJobAction, withdrawPendingJobAction);
  // FromPublicNewApplicantForm
  yield takeEvery(publicNewApplicantFormActions.publicNewApplicantFormSubmitAction, publicSubmitApplicationSaga);
  yield takeEvery(publicNewApplicantFormActions.formUploadAction, publicNewApplicantFormUploadAction);
  yield takeEvery(publicNewApplicantFormActions.formRemoveUploadAction, publicNewApplicantFormRemoveUploadAction);
  yield takeEvery(publicNewApplicantFormActions.publicNewApplicantFormCloseAction, publicNewApplicantFormCloseWorker);
}
