import styled, { css } from 'styled-components/macro';

import { PageTitle } from 'components/PageHeader';
import { innerTableStyles, listTableStyles } from 'components/TableList/TableListStyles';
import { ExAccordion, ExAccordionToggle } from 'components/ui/ExAccordion';
import { ExCard } from 'components/ui/ExCard';
import { Table } from 'components/ui/ExTable/ExTable.components';

export const CompanyInterviewTemplatesTabStyled = styled(ExCard)``;

export const CompanyInterviewTemplatesTableStyled = styled.div`
  ${Table} {
    height: auto;
    ${listTableStyles};
    ${innerTableStyles};

    tbody {
      background: none;
    }
  }
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  & > :nth-child(n) {
    &:not(:first-child) {
      margin-left: 16px;
    }
  }
`;

export const CompanyInterviewTemplatesTableTitleStyled = styled.div`
  font-weight: 600;
`;

export const CompanyInterviewTemplatesStyled = styled.div`
  padding: 24px;
  min-height: 100%;
`;

export const PageTitleStyled = styled(PageTitle)`
  font-size: 24px;
  flex-shrink: 0;
`;

export const grid = css`
  display: grid;
  grid-template-columns: 25px auto 150px 100px 115px;
  column-gap: 10px;
  align-items: center;
`;

export const CompanyInterviewTemplateBody = styled.div`
  max-width: 1050px;
  margin: 0 auto;
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  height: 53px;
`;
export const HeaderLink = styled.div`
  flex-grow: 1;
`;

export const CompanyInterviewTemplateElementDraggableWrapper = styled.div``;

export const CompanyInterviewTemplateElementStyled = styled.div`
  margin-bottom: 10px;
`;

export const CompanyInterviewTemplateHeaderStyled = styled.div`
  ${grid};
  padding: 0 15px;
  margin-bottom: 10px;
`;

export const QuestionsLoading = styled(CompanyInterviewTemplateHeaderStyled)``;

export const QuestionsLoadingContent = styled.div`
  grid-column: 2 / 6;
`;

export const NoQuestionsToDisplay = styled(CompanyInterviewTemplateHeaderStyled)`
  padding: 15px;
  background: #eee;
  border-radius: ${({ theme }) => theme.border.borderRadiusSm};
`;

export const NoQuestionsToDisplayContent = styled.div`
  grid-column: 1 / 6;
`;

export const getExAccordionStyled = css`
  ${grid};
  grid-template-rows: auto auto;
`;

export const ExAccordionStyled = styled(ExAccordion)`
  ${getExAccordionStyled};
`;

export const ExCardInner = styled.div`
  ${getExAccordionStyled};
`;

export const DnDButton = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding-top: 2px;
`;

export const DnDContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
`;

export const QuestionContainer = styled.div`
  grid-column: 2;
  grid-row: 1;
`;

export const TypeContainer = styled.div`
  grid-column: 3;
  grid-row: 1;
`;

export const MandatoryContainer = styled.div`
  grid-column: 4;
  grid-row: 1;
`;

export const ActionsContainer = styled.div`
  grid-column: 5;
  grid-row: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const actionButton = css`
  border: 0;
  background: none;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const EditActionButton = styled.button`
  ${actionButton};
`;

export const DeleteActionButton = styled.button`
  ${actionButton};
`;

export const ExAccordionToggleStyled = styled(ExAccordionToggle)`
  ${actionButton};
`;

export const getCollapsedContainerStyled = css`
  grid-column: 2 / 6;
  grid-row: 2;
`;

export const CollapsedContainer = styled(ExAccordion.Collapse)`
  ${getCollapsedContainerStyled};
`;

export const ContentContainerWrapper = styled.div`
  ${getCollapsedContainerStyled};
`;

export const QuestionTitle = styled.div`
  font-weight: 900;
`;

export const QuestionInfo = styled.div`
  margin-top: 4px;
`;

export const ContentContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;
`;

export const MandatoryElementStyled = styled.div`
  color: #e00;
  display: inline-block;
  margin-left: 4px;
`;
