import { Action, nanoid } from '@reduxjs/toolkit';
import { buffers, Channel, channel } from 'redux-saga';
import { all, call, fork, put, take } from 'redux-saga/effects';

import { assignDaxtraMappingApiCall } from 'api-endpoints/qualification-type';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { getTranslate } from 'utils/i18utils';
import { invokeApiCall } from 'utils/sagas';

import { qualificationTypeActions } from 'store/entities/qualification-type';
import { exModalHideAction } from 'store/modals/modals.actions';
import { ModalGeneralResult } from 'store/modals/modals.interfaces';
import { modalSagaWorker } from 'store/modals/modals.sagas';

function* invokeModal(modalChannel: Channel<any>, modalId: string, modalType: ModalsTypeKey) {
  const result: ModalGeneralResult = yield call(modalSagaWorker, {
    modalType,
    ...(modalId ? { id: modalId } : {}),
    modalConfig: {
      content: {
        title: 'Qualification Mapping',
        withTitle: true,
      },
    },
  });

  yield put(modalChannel, result);
}

export function* updateQualificationTypesMaps(action: Action) {
  const modalId = nanoid();
  const sagaChannel = yield channel(buffers.none());
  while (true) {
    yield fork(invokeModal as any, sagaChannel, modalId, ModalsTypeKey.companyUpdateQualificationTypesMaps);

    const result: ModalGeneralResult = yield take(sagaChannel);

    if (result.cancel) {
      return;
    }

    yield call(startLoader, action);

    const { data } = yield call(invokeApiCall as any, assignDaxtraMappingApiCall, {
      data: result.confirm?.payload.modalResult,
    });

    if (data) {
      yield all([
        put(exModalHideAction({ id: modalId })),
        put(
          alertsEffects.showSuccess({
            message: getTranslate('company.qualificationTypesMaps.update.success'),
          }),
        ),
        put(qualificationTypeActions.qualificationTypeFetch.start({ silent: true })),
        call(stopLoader, action),
      ]);
      break;
    }
    yield call(stopLoader, action);
  }
}
