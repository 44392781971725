import type { Editor, PluginManager } from 'tinymce';
/**
 * Strip variable to keep the plain variable string
 * @example "{test}" => "test"
 * @param {string} value
 * @return {string}
 */
function cleanVariable(value: string): string {
  return value.replace(/[^a-zA-Z._]/g, '');
}

function isVariable(element: any) {
  if (typeof element.getAttribute === 'function' && element.hasAttribute('data-original-variable')) return true;

  return false;
}

function isValid(editor: Editor) {
  /**
   * check if a certain variable is valid
   * @param {string} name
   * @return {boolean}
   */
  return function (name: string): boolean {
    /**
     * define a list of variables that are allowed
     * if the variable is not in the list it will not be automatically converted
     * by default no validation is done
     * @type {Array.<string>|undefined}
     */
    const valid: Array<string> | undefined = editor.getParam('variable_valid') as unknown as Array<string> | undefined;

    if (!valid || valid.length === 0) {
      return true;
    }

    const validString = '|' + valid.join('|') + '|';

    return validString.indexOf('|' + name + '|') > -1;
  };
}

function getMappedValue(editor: Editor) {
  return function (cleanValue: string) {
    /**
     * Object that is used to replace the variable string to be used
     * in the HTML view
     * @type {object}
     */
    const mapper = editor.getParam('variable_mapper') || {};

    if (typeof mapper === 'function') return mapper(cleanValue);

    return mapper.hasOwnProperty(cleanValue) ? mapper[cleanValue] : cleanValue;
  };
}

function tinymceWalk(editor: Editor) {
  return function (nodeList: Node[], node: Node) {
    /**
     * Prefix and suffix to use to mark a variable
     * @type {string}
     */
    const prefix: string = editor.getParam('variable_prefix', '{{');
    const suffix: string = editor.getParam('variable_suffix', '}}');
    const stringVariableRegex = new RegExp(prefix + '([a-z. _]*)?' + suffix, 'g');

    if (!node.nodeValue) {
      return;
    }

    if (node.nodeType === 3) {
      return;
    }

    if (stringVariableRegex.test(node.nodeValue)) {
      nodeList.push(node);
    }
  };
}

function createHTMLVariable(editor: Editor) {
  const getMappedValueFunc = getMappedValue(editor);

  /**
   * convert a text variable "x" to a span with the needed
   * attributes to style it with CSS
   * @param  {string} value
   * @return {string}
   */
  return function (value: string): string {
    /**
     * Get custom variable class name
     * @type {string}
     */
    const className: string = editor.getParam('variable_class', 'variable');

    /**
     * Prefix and suffix to use to mark a variable
     * @type {string}
     */
    const prefix: string = editor.getParam('variable_prefix', '{{');
    const suffix: string = editor.getParam('variable_suffix', '}}');
    const cleanValue = cleanVariable(value);

    const validator = isValid(editor);

    // check if variable is valid
    if (!validator(cleanValue)) {
      return value;
    }

    const cleanMappedValue = getMappedValueFunc(cleanValue);

    editor.fire('variableToHTML', {
      value,
      cleanValue,
    });

    const variable = prefix + cleanValue + suffix;
    return `<span class="${className}" data-original-variable="${variable}" contenteditable="false">${cleanMappedValue}</span>`;
  };
}

/**
 * convert variable strings into html elements
 */
function stringToHTML(editor: Editor, tinymce: any) {
  const tinymceWalkCallback = tinymceWalk(editor);
  return function (): void {
    const nodeList: Element[] = [];
    let nodeValue: string | undefined;
    let node: Node | undefined | null;
    let div: HTMLDivElement | undefined;

    /**
     * Prefix and suffix to use to mark a variable
     * @type {string}
     */

    const prefix: string = editor.getParam('variable_prefix', '{{');
    const suffix: string = editor.getParam('variable_suffix', '}}');
    const stringVariableRegex = new RegExp(prefix + '([a-z. _]*)?' + suffix, 'g');

    // find nodes that contain a string variable
    tinymce.walk(editor.getBody(), tinymceWalkCallback.bind(nodeList), 'childNodes');

    // loop over all nodes that contain a string variable
    for (const nodeInList of nodeList) {
      nodeValue = nodeInList.nodeValue?.replace(stringVariableRegex, createHTMLVariable(editor));
      div = editor.dom.create('div', undefined, nodeValue);
      while ((node = div.lastChild)) {
        editor.dom.insertAfter(node as Element, nodeInList);

        if (isVariable(node)) {
          const next = node.nextSibling;
          if (next) {
            editor.selection.setCursorLocation(next, 0);
          }
        }
      }

      editor.dom.remove(nodeInList);
    }
  };
}

export function addTinyMcePluginVariables(tinymce: {
  PluginManager: PluginManager;
  walk: (body: HTMLBodyElement, cb: (node: Node) => void, arg2: string) => void;
}) {
  tinymce.PluginManager.add('variables', function (editor) {
    const creatorHtmlVariables = createHTMLVariable(editor);
    /**
     * insert a variable into the editor at the current cursor location
     * @param {string} value
     * @return {void}
     */
    function addVariable(value: string): void {
      const htmlVariable = creatorHtmlVariables(value);
      editor.execCommand('mceInsertContent', false, htmlVariable, { editor });
    }

    function addVariableNewLine(value: string): void {
      const htmlVariable = creatorHtmlVariables(value);
      editor.execCommand('mceInsertContent', false, `<p>${htmlVariable}</p>`, { editor });
    }

    editor.on('keyup', stringToHTML(editor, tinymce));

    //@ts-ignore
    this.addVariable = addVariable;
    //@ts-ignore
    this.addVariableNewLine = addVariableNewLine;
  });
}
