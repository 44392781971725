import { createSelector } from '@reduxjs/toolkit';
import { format, parseISO } from 'date-fns';
import startCase from 'lodash/startCase';

import { PipelineStageType } from 'model/api-enums.constants';

import {
  candidateAvailability,
  getApplicantSeniorityLevel,
} from 'pages/Candidates/Candidate/CandidateDetailsTab/hooks/useDetailsRowProps';

import { applicantModalSliceSelectors } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/store/ApplicantModal.selectors';

import { getApplicantFullName } from 'utils/funcs';
import { getLocation } from 'utils/getLocation';
import { isDateGuard } from 'utils/hooks/dateTime';
import { getApplicantFormattedCurrency, getApplicantFormattedCurrencyWithFrequency } from 'utils/hooks/useCurrency';
import { createLookupSelectors } from 'utils/reducer/selector-helper';

import { companySelectors } from 'store/company/company.selectors';
import { EntityKeys } from 'store/constants';
import { Applicant, ApplicantCard, ApplicantTableRow, Source } from 'store/entities/applicants/models';

export const applicantLookupSelectors = createLookupSelectors<Applicant>(EntityKeys.applicants);

const selectById = applicantLookupSelectors.getById;

/**
 * This selector is needed on the batch action on the PipelineApplicantsHeader.
 * It will filter already hired applicants to avoid send them to onboarding system.
 */
const selectByIdsNotHired = createSelector(applicantLookupSelectors.getByIds, (applicants) =>
  applicants.filter((applicant) => applicant.stageName !== PipelineStageType[PipelineStageType.Hired]),
);

const selectApplicantCurrentPipelineStageId = createSelector(
  applicantLookupSelectors.getById,
  (applicant) => applicant?.pipelineStageId,
);
const selectApplicantCurrentPipelineId = createSelector(
  applicantLookupSelectors.getById,
  (applicant) => applicant?.pipelineId,
);

const selectApplicantFormattedCurrency = createSelector(selectById, (applicant) => {
  return getApplicantFormattedCurrency({
    salaryExpectationCurrency: applicant?.salaryExpectationCurrency,
    salaryExpectationAmount: applicant?.salaryExpectationAmount,
  });
});

const selectApplicantFormattedCurrencyWithFrequency = createSelector(
  selectById,
  selectApplicantFormattedCurrency,
  (applicant, formattedCurrency) => {
    return getApplicantFormattedCurrencyWithFrequency({
      salaryExpectationFrequency: applicant?.salaryExpectationFrequency,
      formattedCurrency,
    });
  },
);

const selectApplicantSeniorityLevel = createSelector(selectById, (applicant) => {
  return getApplicantSeniorityLevel({ seniorityLevel: applicant?.seniorityLevel });
});

const selectApplicantFullName = createSelector(selectById, getApplicantFullName);

const selectAppliedOnFormatted = createSelector(
  selectById,
  companySelectors.getDefaultDateFormat,
  (applicant, dateFormat) => {
    const value = applicant?.appliedOn ?? new Date();

    const dateToFormat = isDateGuard(value) ? value : parseISO(value);

    return format(dateToFormat, dateFormat);
  },
);

const selectApplicantLastRoleInCompany = createSelector(selectById, (applicant) => {
  if (applicant?.lastRole) {
    return `${applicant?.lastRole}`;
  }

  return 'Refer to Resume';
});

const selectApplicantLocation = createSelector(selectById, (applicant) => applicant?.location);

const selectApplicantLocationCountry = createSelector(selectById, (applicant) => applicant?.location?.country ?? '--');

const selectApplicantLocationLongString = createSelector(selectById, (applicant) =>
  getLocation({ location: applicant?.location, locationString: applicant?.locationFormatted }),
);

const selectJobApplicantAvailability = createSelector(
  selectById,
  companySelectors.getDefaultDateFormat,
  (applicant, defaultDateFormat) =>
    candidateAvailability(applicant?.jobApplicantAvailability, defaultDateFormat) ?? '--',
);

const selectApplicantSource = createSelector(selectById, (applicant) =>
  applicant?.source ? startCase(Source[applicant.source]) : applicant?.sourceName ?? '--',
);

const selectApplicantRating = createSelector(selectById, (applicant) => applicant?.rating ?? 0);
const selectApplicantDoNotHire = createSelector(selectById, (applicant) => !!applicant?.doNotHire);

const selectApplicantAvatar = createSelector(selectById, (applicant) => applicant?.photoUrl);

const selectApplicantNonPassedInterviewTemplates = createSelector(
  selectById,
  (applicant) => applicant?.nonPassedInterviewTemplates,
);

const selectApplicantView = createSelector(
  selectApplicantRating,
  selectApplicantSource,
  selectJobApplicantAvailability,
  selectApplicantFormattedCurrencyWithFrequency,
  selectApplicantSeniorityLevel,
  (
    applicantRating,
    applicantSource,
    jobApplicantAvailability,
    applicantFormattedCurrencyWithFrequency,
    applicantSeniorityLevel,
  ) => {
    return {
      rating: applicantRating,
      source: applicantSource,
      availability: jobApplicantAvailability,
      salaryExpectation: applicantFormattedCurrencyWithFrequency,
      seniorityLevel: applicantSeniorityLevel,
    };
  },
);

const selectApplicantCard = createSelector(
  selectById,
  selectApplicantView,
  selectApplicantLocationCountry,
  selectApplicantFullName,
  selectApplicantLastRoleInCompany,
  selectApplicantAvatar,
  companySelectors.selectIsEnabledInHr,
  (
    byId,
    applicantView,
    applicantLocationCountry,
    applicantFullName,
    applicantLastRoleInCompany,
    applicantAvatar,
    isEnabledInHr,
  ) => {
    const applicantId = byId?.id;

    if (!applicantId) {
      return undefined;
    }

    return {
      ...applicantView,
      applicantId,
      fullName: applicantFullName,
      lastRole: applicantLastRoleInCompany,
      avatar: applicantAvatar,
      location: applicantLocationCountry,
      onboardingStatus: isEnabledInHr && byId.onboardingStatus,
    } as ApplicantCard;
  },
);

const selectApplicantForJobTable = createSelector(
  selectById,
  selectApplicantView,
  selectJobApplicantAvailability,
  selectApplicantLocationLongString,
  selectApplicantLastRoleInCompany,
  (byId, applicantView, jobApplicantAvailability, applicantLocationLongString, applicantLastRoleInCompany) => {
    return {
      ...applicantView,
      id: byId!.id,
      profile: byId!,
      location: applicantLocationLongString,
      lastRole: applicantLastRoleInCompany,
      availability: jobApplicantAvailability,
    } as ApplicantTableRow;
  },
);

const selectApplicantModalData = createSelector(
  selectById,
  applicantModalSliceSelectors.selectPreviousWorkExperienceForCurrentApplicant,
  selectApplicantFormattedCurrencyWithFrequency,
  selectApplicantSeniorityLevel,
  selectApplicantFullName,
  selectApplicantSource,
  selectJobApplicantAvailability,
  (
    applicant,
    previousWorkExperience,
    applicantFormattedCurrencyWithFrequency,
    applicantSeniorityLevel,
    applicantFullName,
    applicantSource,
    jobApplicantAvailability,
  ) => {
    if (!applicant) {
      return undefined;
    }

    return {
      ...applicant,
      workExperience: previousWorkExperience,
      salaryExp: applicantFormattedCurrencyWithFrequency,
      seniority: applicantSeniorityLevel,
      fullName: applicantFullName,
      sourceName: applicantSource,
      availability: jobApplicantAvailability,
    };
  },
);

const selectApplicantJobId = createSelector(selectById, (applicant) => applicant?.jobId);

export const applicantSelectors = {
  ...applicantLookupSelectors,
  selectByIdsNotHired,
  selectApplicantCurrentPipelineId,
  selectApplicantCurrentPipelineStageId,
  selectApplicantFormattedCurrency,
  selectApplicantFormattedCurrencyWithFrequency,
  selectApplicantSeniorityLevel,
  selectApplicantFullName,
  selectAppliedOnFormatted,
  selectApplicantLastRoleInCompany,
  selectApplicantLocation,
  selectApplicantCard,
  selectApplicantForJobTable,
  selectApplicantModalData,
  selectApplicantDoNotHire,
  selectApplicantJobId,
  selectApplicantNonPassedInterviewTemplates,
};
