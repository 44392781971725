import React, { useCallback } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ExRoutes } from 'router/routes';
import styled from 'styled-components';

import { AppRouterParams } from 'model/router';

import { ApplicantStatusBadgeDinamicHiringPipeline } from 'containers/ApplicantLists/ApplicantStatusBadge';

import { BadgeDoNotHireNoIcon } from 'components/Badge';
import { CandidateAvatarTable } from 'components/CandidateAvatar';
import { Ellipsis } from 'components/Ellipsis';
import { ExVisible } from 'components/ui/ExVisible';
import { getApplicantFullName } from 'utils/funcs';
import { getUserInitials } from 'utils/getUserInitials';

import { Applicant, ApplicantBelongsTo } from 'store/entities/applicants/models';

type ApplicantBelongsToType = ApplicantBelongsTo.applicants | ApplicantBelongsTo.placements;

interface ApplicantListCandidateRowProps {
  className?: string;
  applicant: Applicant;
  belongsTo: ApplicantBelongsToType;
}

const ApplicantListCandidateRowWrapper = styled.div`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;
/**
 * This wrapper needs to contain absolute positioning CandidateAvatarTable.
 *
 * CandidateAvatarTable has absolute positioning to keep all tables td padding consistently
 * and don't provide specific styling for tables with avatars.
 */
const ApplicantListCandidateAvatarWrapper = styled.div`
  padding-left: 40px;
  width: 40px;
  height: 40px;
`;

const ApplicantListCandidateName = styled.span`
  padding: 0 15px;
`;

const ApplicantListCandidateRowName = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.colors.blackHeadings};
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  line-height: 19px;
  flex: 1 1 auto;
  cursor: pointer;
`;

const ApplicantListNameBlock = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 0;
  display: flex;
  flex-direction: column;
`;

const ApplicantNameEllipsis = styled(Ellipsis)`
  display: flex;
`;

const ApplicantRowStatusBadge = styled(ApplicantStatusBadgeDinamicHiringPipeline)`
  margin-left: 10px;
  min-width: 70px;
  flex-shrink: 0;
`;

const BadgeDoNotHireStyled = styled(BadgeDoNotHireNoIcon)`
  margin-left: 10px;
`;

function useApplicantsModalPaths({
  jobId,
  applicantId,
  belongsTo,
}: {
  jobId?: string;
  applicantId: string;
  belongsTo: ApplicantBelongsToType;
}) {
  const applicantsModalPath = ExRoutes.jobApplicantsModal({ jobId, applicantId, applicantsViewTab: 'applicants' });

  const modalPathMap: Record<ApplicantListCandidateRowProps['belongsTo'], Function> = {
    applicants: () => applicantsModalPath,
    placements: () => ExRoutes.applicantModalForPlacements({ applicantId }),
  };

  const modalPathFunction = modalPathMap[belongsTo];

  return modalPathFunction({ jobId, applicantId });
}

function useApplicantListCandidateRowState({ applicant, className, belongsTo }: ApplicantListCandidateRowProps) {
  const { jobId } = useParams<AppRouterParams>();

  const history = useHistory();
  const modalPath = useApplicantsModalPaths({ jobId, applicantId: applicant.applicantId, belongsTo });

  const applicantAvatar = applicant.photoUrl || '';
  const applicantFullName = getApplicantFullName(applicant);

  const applicantName = applicantFullName ?? '--';

  const applicantNameClickHandler = useCallback(() => history.push(modalPath), [modalPath, history]);

  const userInitials = getUserInitials(`${applicantFullName ?? ''}`);

  const isPlacements = belongsTo === ApplicantBelongsTo.placements;

  return {
    applicantAvatar,
    applicantName,
    modalPath,
    className,
    applicant,
    applicantNameClickHandler,
    userInitials,
    isPlacements,
  } as const;
}

export const ApplicantListCandidateRow: React.FC<ApplicantListCandidateRowProps> = (props) => {
  const {
    modalPath,
    className,
    applicantAvatar,
    applicantName,
    applicant,
    applicantNameClickHandler,
    userInitials,
    isPlacements,
  } = useApplicantListCandidateRowState(props);

  return (
    <ApplicantListCandidateRowWrapper className={className}>
      <ApplicantListCandidateAvatarWrapper>
        <Link to={modalPath}>
          <CandidateAvatarTable src={applicantAvatar} initials={userInitials} />
        </Link>
      </ApplicantListCandidateAvatarWrapper>
      <ApplicantListNameBlock>
        <ApplicantNameEllipsis title={applicantName} onClick={applicantNameClickHandler}>
          <ApplicantListCandidateRowName>
            <ApplicantListCandidateName>{applicantName}</ApplicantListCandidateName>
          </ApplicantListCandidateRowName>
        </ApplicantNameEllipsis>
      </ApplicantListNameBlock>
      <ExVisible visible={!!applicant.doNotHire}>
        <BadgeDoNotHireStyled />
      </ExVisible>
      <ExVisible visible={!isPlacements}>
        <Ellipsis as={ApplicantRowStatusBadge} applicantId={applicant.applicantId} />
      </ExVisible>
    </ApplicantListCandidateRowWrapper>
  );
};
