import { AdditionalField, AdditionalFieldValidator } from 'model';

import { StringIsNumber } from 'utils/funcs';

import { JobBoardSettings } from 'store/entities/job-boards/models';

export const getRequiredRules = (field: AdditionalField | JobBoardSettings) =>
  field.required ? `${field.displayName} is required` : false;

const getValidatorRules = (validator: AdditionalFieldValidator | null, value?: string) => {
  if (!validator || !value) {
    return undefined;
  }

  if (validator.type === 'fieldMaxLength') {
    const maxLength = Number(validator.control);
    return value.length > maxLength ? validator.validationErrorMessage : undefined;
  }

  if (validator.type === 'onlyNumbers') {
    const isNumber = StringIsNumber(value);
    return !isNumber ? validator.validationErrorMessage : undefined;
  }

  return undefined;
};

export const getFormValidatorRules = (field: AdditionalField) => {
  return {
    required: getRequiredRules(field),
    validate: {
      fieldMaxLength: (value?: string) => getValidatorRules(field.validator, value),
      onlyNumbers: (value?: string) => getValidatorRules(field.validator, value),
    },
  };
};
