import React from 'react';
import styled from 'styled-components';

import { MultiValueRemove } from 'components/FormTagSelect';
import { useAppSelectStyles } from 'components/FormTagSelect/FormTagSelectStyles';

interface FilterTagSelectProps {
  children: React.ReactElement;
  className?: string;
}

const Placeholder = () => null;
const IndicatorsContainer = () => null;

const FilterTagSelectPure: React.FC<FilterTagSelectProps> = ({ children }) => {
  const styles = useAppSelectStyles([
    'control',
    'multiValue',
    'multiValueLabel',
    'multiValueRemove',
    'valueContainer',
    'menuList',
    'option',
  ]);

  return React.cloneElement(children, {
    styles,
    components: { MultiValueRemove, IndicatorsContainer, Placeholder },
  });
};

export const FilterTagSelect = styled(FilterTagSelectPure)``;
