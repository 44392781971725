import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as LnIcon } from 'assets/img/redesign/linkedin.svg';

import { companySelectors } from 'store/company/company.selectors';
import { useSelector } from 'store/rootSelectors';

type CompanyEmailTemplatePreviewProps = {
  className?: string;
};

const CompanyEmailTemplatePreviewStyled = styled.div`
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor};
  height: 100%;

  .variable {
    cursor: default;
    background-color: ${(props) => props.theme.colors.primary};
    color: #fff;
    padding: 2px;
    border-radius: 3px;
  }
`;

const EmailCSS = css`
  .ex-email-body,
  .ex-email-content {
    padding: 48px;
  }

  .ex-email-body {
    line-height: 24px;
    background-color: #f9f9f9;
  }

  .ex-email-content {
    background-color: white;
    border-radius: 16px;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.03);
    margin-bottom: 48px;
  }

  .ex-email-company-logo {
    display: flex;
    height: 100px;
    max-width: 100%;
    margin: 0 auto 48px;
  }

  .ex-email-company-logo > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .ex-email-btn,
  button {
    height: 40px;
    border-radius: 8px;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    padding: 0 16px;
    background-color: lightblue;
  }

  .ex-email-btn:hover {
    color: white;
    text-decoration: none;
  }

  .ex-email-btn:focus,
  button:focus {
    outline: 0;
  }

  .ex-email-copyright {
    margin-bottom: 24px;
    color: #999;
    text-align: center;
  }

  .ex-email-footer {
    text-align: center;
    color: #999;
  }

  .ex-email-social-icon {
    width: 24px;
  }

  .ex-email-social-icons-wrapper {
    text-align: center;
  }
`;

export const CompanyEmailTemplatePreview: React.FC<CompanyEmailTemplatePreviewProps> = ({ children, className }) => {
  const companyInfo = useSelector(companySelectors.getCompanyInfo);
  return (
    <CompanyEmailTemplatePreviewStyled className={className}>
      <style>{EmailCSS}</style>
      <div className="ex-email-body">
        <div className="ex-email-company-logo">
          {/* <img src="{{company_logo}}" alt="{{company_name}}"> */}
          <img src={companyInfo?.companyLogoUrl} alt={companyInfo?.name} />
        </div>
        <div className="ex-email-content">{children}</div>
        {/* <a href="{{company_linkedin_url}}" target="_blank" rel="noopener noreferrer"> */}
        <div className="ex-email-social-icons-wrapper">
          <a href="https://www.linkedin.com/company/expedohcmplatform" target="_blank" rel="noopener noreferrer">
            <LnIcon className="ex-email-social-icon" />
          </a>
        </div>
        {/* <div className="ex-email-copyright">© {{company_name}} {{current_year}}. All rights reserved.</div> */}
        <div className="ex-email-copyright">© Expedo {new Date().getFullYear()}. All rights reserved.</div>
        <div className="ex-email-footer">
          This email is intended for “<strong>{'{{first_name}} {{last_name}}'}</strong>”. If you have receive this email
          by mistake, please notify “<strong>{'{{company_name}}'}</strong>” as soon as possible and permanently delete
          the email and your respective reply.
        </div>
      </div>
    </CompanyEmailTemplatePreviewStyled>
  );
};
