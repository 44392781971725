import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';

import { AppRouterParams } from 'model/router';

import { CandidateDetailsTabCardEmptyItem } from 'pages/Candidates/Candidate/CandidateDetailsTab/CandidateDetailsTabComponents';
import { EducationCardItemForApplicantModalV4 } from 'pages/Candidates/Candidate/CandidateDetailsTab/components/EducationCardItem';

import { ApplicantModalTitle } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalV4Components';
import { applicantModalSliceSelectors } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/store';

import { Spinner } from 'components/Spinner';
import { useLoaderSubscription } from 'modules/LoaderManager/react';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { createByIdActionLoader } from 'utils/loaders/createByIdActionLoader';
import { customLoadAction } from 'utils/loaders/customLoadAction';

type ApplicantModalEducationViewProps = {
  className?: string;
};

const ApplicantModalEducationViewStyled = styled.div``;

const useApplicantModalEducationViewState = ({ className }: ApplicantModalEducationViewProps) => {
  const match = useRouteMatch<AppRouterParams>();
  const { applicantId } = match.params;

  const educationIds = useAppSelector(applicantModalSliceSelectors.selectEducationIdsForCurrentApplicant);
  const hasEducation = Boolean(educationIds.length);

  const { isLoading } = useLoaderSubscription(
    createByIdActionLoader(customLoadAction({ type: 'loadEducation', id: applicantId! })),
  );

  const hasItems = !isLoading && hasEducation;
  const isEmpty = !isLoading && !hasItems;

  return {
    className,
    educationIds,
    hasItems,
    isLoading,
    isEmpty,
  } as const;
};

export const ApplicantModalEducationView: React.FC<ApplicantModalEducationViewProps> = (props) => {
  const { className, educationIds, hasItems, isLoading, isEmpty } = useApplicantModalEducationViewState(props);

  return (
    <ApplicantModalEducationViewStyled className={className}>
      <ApplicantModalTitle>Education</ApplicantModalTitle>
      {isLoading && <Spinner />}
      {hasItems &&
        educationIds.map((educationId) => (
          <EducationCardItemForApplicantModalV4 key={educationId} educationId={educationId} />
        ))}
      {isEmpty && <CandidateDetailsTabCardEmptyItem>Please refer to Resume</CandidateDetailsTabCardEmptyItem>}
    </ApplicantModalEducationViewStyled>
  );
};
