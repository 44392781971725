import React from 'react';
import { Routes } from 'router';
import styled from 'styled-components';
import startCase from 'lodash/startCase';

import { AvailabilityType, SeniorityLevel } from 'model/api-enums.constants';

import { applicantFormSelectors } from 'containers/ApplicantForm/ApplicantFormState';
import { ApplicantForm } from 'containers/ApplicantForm/ApplicantFormState/applicant-form.models';

import { AppFileCard, ReviewStepCard } from 'components';
import { DetailsRowProps } from 'components/DetailsCard';
import { FormCard } from 'components/FormCard';
import { StepComponent, StepDescription } from 'components/StepForm';
import { ExCard } from 'components/ui/ExCard';
import { useCandidateFullName } from 'utils/hooks/candidate';
import { getFrequencyNames } from 'utils/hooks/formsHooks/useFrequencyOptions';
import { getTranslate } from 'utils/i18utils';

import { Candidate } from 'store/entities/candidates/models';
import { useSelector } from 'store/rootSelectors';

export interface NewApplicantReviewStepProps extends StepComponent {
  className?: string;
  formData: ApplicantForm;
}

const NewApplicantReviewStep: React.FC<NewApplicantReviewStepProps> = ({ className, formData }) => {
  const job = useSelector(applicantFormSelectors.selectJob);
  const candidate = useSelector(applicantFormSelectors.selectCandidate);
  const files = useSelector(applicantFormSelectors.selectReviewStep);

  const jobDetails: DetailsRowProps[] = [{ label: 'Job Name', value: job?.jobName }];

  const seniorityLevel = React.useMemo(
    () => Object.entries(SeniorityLevel).find(([_key, value]) => formData.seniorityLevel === value)?.[0],
    [formData.seniorityLevel],
  );

  const applicantDetails: DetailsRowProps[] = [
    {
      label: 'Seniority Level',
      value: startCase(seniorityLevel),
    },
    {
      label: 'Availability',
      value:
        formData.jobApplicantAvailability && formData.jobApplicantAvailability?.availabilityType !== undefined
          ? startCase(AvailabilityType[formData.jobApplicantAvailability?.availabilityType])
          : null,
    },
    ...(formData.jobApplicantAvailability?.actualDate
      ? [
          {
            label: 'Specific Date',
            value: formData.jobApplicantAvailability?.actualDate,
          },
        ]
      : []),
    {
      label: 'Salary Expectation Currency',
      value: formData.salaryExpectationCurrency,
    },
    {
      label: 'Salary Expectation Amount',
      value: formData.salaryExpectationAmount || null,
    },
    {
      label: 'Salary Expectation Frequency',
      value: formData.salaryExpectationFrequency ? getFrequencyNames(formData.salaryExpectationFrequency) : null,
    },
  ];
  const candidateDetails: DetailsRowProps[] = [
    {
      label: 'Candidate Name',
      value: useCandidateFullName(candidate || ({} as Candidate)),
    },
  ];

  const onItemClick = () => {
    // do nothing
  };

  const stepTitle = 'Step 6: Review';

  return (
    <div className={className}>
      <StepDescription>
        <StepDescription.Title>{stepTitle}</StepDescription.Title>
        <StepDescription.Description>{getTranslate('new_applicant.step_6.description')}</StepDescription.Description>
      </StepDescription>
      <ExCard title="Review">
        <ReviewStepCard rows={candidateDetails} editUrl={Routes.applicantStepSelectCandidate} />
        <ReviewStepCard rows={jobDetails} editUrl={Routes.applicantStepSelectJob} />
        <FormCard.FormCardBodyDivider className="my-2" />
        <ReviewStepCard rows={applicantDetails} editUrl={Routes.applicantStepDetails} />
        <FormCard.FormCardBodyDivider className="my-2" />
        <ReviewStepCard title="Resume" editUrl={Routes.applicantStepSelectResume} />
        <div className="row">
          {files.resumes.map((resume) => (
            <div className="col-auto" key={resume.resumeId}>
              <AppFileCard item={resume} onClick={onItemClick} isCheckable={true} />
            </div>
          ))}
        </div>
        <FormCard.FormCardBodyDivider className="my-2" />
        <ReviewStepCard title="Cover Letter" editUrl={Routes.applicantStepSelectCoverLetter} />
        <div className="row">
          {files.documents.length ? (
            files.documents
              .filter((d) => d.documentId === formData.documentId)
              .map((document) => (
                <div className="col-auto" key={document.documentId}>
                  <AppFileCard item={document} onClick={onItemClick} isCheckable={true} />
                </div>
              ))
          ) : (
            <div className="col-auto mb-4">Cover letter not selected</div>
          )}
        </div>
      </ExCard>
    </div>
  );
};

export default styled(NewApplicantReviewStep)`
  ${AppFileCard} {
    cursor: initial;
  }

  ${FormCard.FormCardBodyDivider} {
    margin: 0 -20px;
  }
`;
