/**
 * Creates function that will append params (url parts) to basic url
 * When you don't need the basicUrl functionality you can pass empty string as basicUrl
 *
 * @param {string} basicUrl
 */
const createUrlWithParams = (basicUrl: string) => (params: Array<string>): string => {
  return [basicUrl, ...params].join('/');
};

export { createUrlWithParams };
