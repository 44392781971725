import React, { useEffect, useMemo, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';

import { AppFile } from 'model';

import { DocumentPreviewModalContent } from 'containers/Modals/ModalsContent/Document/DocumentPreviewModalContent';

import { makeChildren } from 'components/Dialog';
import { FormCard } from 'components/FormCard';
import { FormTooltip } from 'components/FormTooltip';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { ExVisible } from 'components/ui/ExVisible';
import { UploadButton } from 'components/UploadButton';
import { useModal } from 'utils/hooks/useModal';

import { CloseButtonWrapper, CustomCloseButton, FormFileStyled } from './FormFileComponents';
import { FormFileProps } from './FormFileProps';

export const FormFile: React.FC<FormFileProps> = ({
  inputRef,
  name,
  className,
  errors,
  label,
  required,
  validated,
  hint,
  defaultValue,
  onFileChange,
  clearable,
  onClear,
  overrideDefaultValue,
  value,
  accept,
  isLoading = false,
  ...rest
}) => {
  const [file, setFile] = useState<AppFile | null>(null);
  const [focus, setFocus] = useState(false);
  const [showError, setShowError] = useState(true);
  useEffect(() => {
    if (errors) {
      setShowError(true);
      return;
    }
    setShowError(false);
  }, [errors]);
  const target = useRef<any>();
  const previewModal = useModal({
    initialOpen: false,
  });
  const hasFile = Boolean(file);

  const previewModalContent = useMemo(
    () => makeChildren(({ onClose }) => (file ? <DocumentPreviewModalContent onClose={onClose} item={file} /> : null)),
    [file],
  );

  const handlePreviewClick: React.MouseEventHandler = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    previewModal.show({
      children: previewModalContent,
    });
  };

  useEffect(() => {
    let defaultFile: AppFile | null = null;
    if (defaultValue) {
      defaultFile = { documentId: defaultValue } as AppFile;
    }
    setFile(defaultFile);
  }, [defaultValue]);

  return (
    <FormFileStyled ref={target} className={className}>
      <ExVisible visible={Boolean(label)}>
        <FormCard.InputLabel htmlFor={name}>
          {label} {required && <FormCard.InputLabelRequired>*</FormCard.InputLabelRequired>}{' '}
          {<FormCard.InputLabelHint>{hint}</FormCard.InputLabelHint>}
        </FormCard.InputLabel>
      </ExVisible>
      <div className="row">
        <div className="col-8">
          <CloseButtonWrapper>
            <Form.Control
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              name={name}
              id={name}
              ref={inputRef}
              defaultValue={defaultValue}
              {...rest}
              readOnly
              isValid={validated && !errors}
              isInvalid={validated && !!errors}
              style={overrideDefaultValue ? { position: 'absolute', visibility: 'hidden' } : {}}
            />
            {overrideDefaultValue && (
              <Form.Control
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                id={'overridedDefaultValue'}
                value={overrideDefaultValue}
                {...rest}
                readOnly
              />
            )}
            <FormTooltip target={target} show={focus && showError} errors={errors} />
            {clearable && onClear && (
              <CustomCloseButton type="button" className="close" aria-label="Close" onClick={onClear}>
                <span aria-hidden="true">&times;</span>
              </CustomCloseButton>
            )}
          </CloseButtonWrapper>
        </div>

        <div className="col-4">
          <ExVisible visible={hasFile}>
            <ExButton className="block align-self-end w-100" variant="light" onClick={handlePreviewClick}>
              Preview
            </ExButton>
          </ExVisible>
          <ExVisible visible={!hasFile}>
            <UploadButton
              accept={accept ?? '.pdf'}
              disabled={isLoading}
              onChange={onFileChange}
              className="block align-self-end w-100"
            >
              <ExButton variant="light" className="block w-100" isLoading={isLoading}>
                Browse
              </ExButton>
            </UploadButton>
          </ExVisible>
        </div>
      </div>
    </FormFileStyled>
  );
};
