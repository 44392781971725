import { createApiMethod } from 'api-endpoints/utils';

import * as fromApiEndpointsModels from './apiOnCost.models';
import { OnCostEndpoints } from './endpoints';

export const getCompanyOnCostValuesFunc = createApiMethod<
  fromApiEndpointsModels.getCompanyOnCostValuesFuncParams,
  fromApiEndpointsModels.getCompanyOnCostValuesFuncResponse
>({
  url: OnCostEndpoints.GetCompanyOnCostValuesFunc,
  method: 'GET',
});

export const createCompanyOnCostValueFunc = createApiMethod<
  fromApiEndpointsModels.createCompanyOnCostValueFuncParams,
  fromApiEndpointsModels.createCompanyOnCostValueFuncResponse
>({
  url: OnCostEndpoints.CreateCompanyOnCostValueFunc,
  method: 'POST',
});

export const getCompanyOnCostValueFunc = createApiMethod<
  fromApiEndpointsModels.getCompanyOnCostValueFuncParams,
  fromApiEndpointsModels.getCompanyOnCostValueFuncResponse
>({
  url: OnCostEndpoints.GetCompanyOnCostValueFunc,
  method: 'GET',
});

export const updateCompanyOnCostValueFunc = createApiMethod<
  fromApiEndpointsModels.updateCompanyOnCostValueFuncParams,
  fromApiEndpointsModels.updateCompanyOnCostValueFuncResponse
>({
  url: OnCostEndpoints.UpdateCompanyOnCostValueFunc,
  method: 'PUT',
});

export const deleteCompanyOnCostValueFunc = createApiMethod<
  fromApiEndpointsModels.deleteCompanyOnCostValueFuncParams,
  fromApiEndpointsModels.deleteCompanyOnCostValueFuncResponse
>({
  url: OnCostEndpoints.DeleteCompanyOnCostValueFunc,
  method: 'DELETE',
});
