import { getI18n } from 'react-i18next';
import en from 'lang/en';

export function getTranslate(...args) {
  // @ts-ignore
  return getI18n().t(...args);
}

type LangFile = typeof en;

type GetSuccessToastForEntityActionArgs = {
  name?: string;
  action: keyof LangFile['entityActions'];
  entityName: keyof LangFile['entityTypes'];
};

export function getSuccessToastForEntityAction({ name, action, entityName }: GetSuccessToastForEntityActionArgs) {
  const entityType = getI18n().t(`entityTypes.${entityName}`);
  const entityAction = getI18n().t(`entityActions.${action}`);

  if (name) {
    return getI18n().t('entity_success', { entityType, entityAction, name });
  } else {
    return getI18n().t('entity_success_no_name', { entityType, entityAction });
  }
}
