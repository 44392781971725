import './App.scss';

import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useFeatureFlag } from '@expedo/feature-flag';
import { AuthContextProvider } from 'auth';
import { PrivateRoute, Routes } from 'router';
import { AuthRoute } from 'router/AuthRouter';
import { RouteRBAC } from 'router/RouteRBAC';
import { ExRoutes, ExRoutesPathMap } from 'router/routes';

import { Features } from 'config/feature-flag';

import { HomePage } from 'pages';
import { PublicPage } from 'pages/public';
import { SelectCompanyPage } from 'pages/SelectCompanyPage';

import { rbacConstants } from 'containers/Auth/AuthMatrix';
import { AuthRoot } from 'containers/Auth/Pages/AuthRoot';
import { ProfilePage } from 'containers/Auth/Pages/ProfilePage';
import { AppModal } from 'containers/Modals';
import { ExModals } from 'containers/Modals/ExModals';
import { ApplicantNew } from 'containers/Modals/ModalsContent/Applicant/ApplicantNew/ApplicantNew';

import { ApplicantInterview } from 'components/ApplicantInterview';
import { Spinner } from 'components/Spinner';

import { PreFetch } from './components/PreFetch';
import { DynamicLayout, EmptyLayout } from './layouts';

const Jobs = lazy(() => import(/* webpackChunkName: "jobs" */ './pages/Jobs'));
const Dashboard = lazy(() => import(/* webpackChunkName: "dashboard" */ './pages/Dashboard'));
const DashboardJobAdsV2 = lazy(() => import(/* webpackChunkName: "dashboard" */ './pages/Dashboard/DashboardJobAdsV2'));
const TalentPools = lazy(() => import(/* webpackChunkName: "talent-pools" */ './pages/TalentPools'));
const Placements = lazy(() => import(/* webpackChunkName: "placements" */ './pages/Placements'));
const JobBoards = lazy(() => import(/* webpackChunkName: "job-boards" */ './pages/JobBoards'));
const Applicants = lazy(() => import(/* webpackChunkName: "applicants" */ './pages/Applicants'));
const Candidates = lazy(() => import(/* webpackChunkName: "candidates" */ './pages/Candidates'));
const Company = lazy(() =>
  import(/* webpackChunkName: "company" */ './pages/Company').then((module) => ({ default: module.Company })),
);
const NotFound404 = lazy(() => import(/* webpackChunkName: "not-found-404" */ './pages/NotFound404Page'));
const Forbidden403 = lazy(() =>
  import(/* webpackChunkName: "forbidden-403" */ './pages/Forbidden403').then((module) => ({
    default: module.Forbidden403,
  })),
);

const ExSidebarLegacy = lazy(() =>
  import(/* webpackChunkName: "ex-sidebar-legacy" */ './components/ui/ExGlobalSidebar').then((module) => ({
    default: module.ExGlobalSidebar,
  })),
);

const ExSidebarV2 = lazy(() =>
  import(/* webpackChunkName: "ex-sidebar-v2" */ './components/ui/ExGlobalSidebarV2').then((module) => ({
    default: module.ExGlobalSidebarV2,
  })),
);

const ExSidebar = () => {
  const { isEnabled } = useFeatureFlag();
  const Sidebar = isEnabled(Features.GlobalNav) ? ExSidebarV2 : ExSidebarLegacy;
  return (
    <Suspense fallback={<Spinner />}>
      <Sidebar />
    </Suspense>
  );
};

const App = () => {
  return (
    <AuthContextProvider>
      <Switch>
        <AuthRoute exact path={`/${Routes.home}`}>
          <EmptyLayout>
            <HomePage />
          </EmptyLayout>
        </AuthRoute>
        <Route path={'/log*'} component={AuthRoot} />
        <Route path="/public/:companyPublicUrl" component={PublicPage} />
        <RouteRBAC exact path={ExRoutesPathMap.selectCompanyPage.path} component={SelectCompanyPage} />
        <PrivateRoute path="/">
          <PreFetch />
          <DynamicLayout topLeft={<ExSidebar />}>
            <Suspense fallback={<Spinner />}>
              <Switch>
                <RouteRBAC path={ExRoutesPathMap.applicantInterview.path} component={ApplicantInterview} />
                <RouteRBAC
                  path={ExRoutesPathMap.applicantNewFromCandidateJobApplicationsTab.path}
                  component={ApplicantNew}
                />

                <RouteRBAC path={`/${Routes.jobs}`} component={Jobs} />
                <RouteRBAC path={`/${Routes.dashboard}/${Routes.jobAds}`} component={DashboardJobAdsV2} />
                <RouteRBAC
                  feature={rbacConstants.mainDashboard.view}
                  path={`/${Routes.dashboard}`}
                  component={Dashboard}
                  redirect={ExRoutes.jobListOpen()}
                />
                <RouteRBAC path={`/${Routes.talentPools}`} component={TalentPools} />
                <RouteRBAC path={`/${Routes.applicants}`} component={Applicants} />
                <RouteRBAC path={`/${Routes.candidates}`} component={Candidates} />
                <RouteRBAC path={`/${Routes.jobBoards}`} component={JobBoards} />
                <RouteRBAC
                  feature={rbacConstants.placements.view}
                  path={`/${Routes.placements}`}
                  component={Placements}
                />
                <RouteRBAC feature={rbacConstants.company.view} path={`/${Routes.company}`} component={Company} />

                <RouteRBAC path={`/${Routes.profile}`} component={ProfilePage} />

                <Route path={`/${Routes.forbidden403}`} component={Forbidden403} />
                <Redirect from={'/dashboard.html'} to={`/${Routes.dashboard}`} />
                <Route component={NotFound404} />
              </Switch>
            </Suspense>
          </DynamicLayout>
        </PrivateRoute>
      </Switch>
      <AppModal />
      <ExModals />
    </AuthContextProvider>
  );
};

export default App;
