import {
  ScreeningQuestionFormInput,
  ScreeningQuestionFormLabel,
  ScreeningQuestionFormStyled,
} from './ScreeningQuestionForm';
import { ScreeningQuestionFormProps } from './ScreeningQuestionFormProps';

export const ScreeningQuestionRadio: React.FC<ScreeningQuestionFormProps> = ({
  className,
  id,
  name,
  label,
  disabled,
  checked,
  value,
  onChange,
}) => {
  return (
    <ScreeningQuestionFormStyled className={className}>
      <ScreeningQuestionFormInput
        type="radio"
        id={id}
        value={value}
        name={name}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <ScreeningQuestionFormLabel htmlFor={id} $disabled={disabled}>
        {label}&nbsp;
      </ScreeningQuestionFormLabel>
    </ScreeningQuestionFormStyled>
  );
};
