import React from 'react';
import styled from 'styled-components/macro';

import { useQuestionCheckBoxState } from 'components/InterviewTemplate/QuestionCheckBox/hooks/useQuestionCheckBoxState';
import { QuestionCheckbox } from 'components/InterviewTemplate/QuestionForm/QuestionCheckbox';
import { QuestionFromList, QuestionFromListItem } from 'components/InterviewTemplate/QuestionForm/QuestionForm';
import { QuestionViewLoading } from 'components/InterviewTemplate/QuestionView/QuestionViewLoading';
import { QuestionViewProps } from 'components/InterviewTemplate/QuestionView/QuestionViewProps';

const QuestionCheckboxStyled = styled.div``;

export const QuestionCheckBox: React.FC<QuestionViewProps> = (props) => {
  const { className, disabled, choices, questionId, onChange, isLoading } = useQuestionCheckBoxState(props);

  return (
    <QuestionCheckboxStyled className={className}>
      <QuestionViewLoading isLoading={isLoading} />
      <QuestionFromList>
        {choices.map((choice, index) => (
          <QuestionFromListItem key={index}>
            <QuestionCheckbox
              label={choice.value}
              name={questionId}
              id={`${questionId}_${choice.value}`}
              disabled={disabled}
              checked={choice.selected}
              value={choice.value}
              onChange={onChange}
            />
          </QuestionFromListItem>
        ))}
      </QuestionFromList>
    </QuestionCheckboxStyled>
  );
};
