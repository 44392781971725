import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { adapterHelper } from 'utils/reducer/adapter-helper';

import { EntityKeysNew } from 'store/constants';
import { JobBoard } from 'store/entities/job-boards/models';

export const jobBoardsAdapter = createEntityAdapter<JobBoard>({
  selectId: (item) => item.jobBoardId,
});

const jobBoardsSlice = createSlice({
  name: EntityKeysNew.jobBoards,
  initialState: jobBoardsAdapter.getInitialState(),
  reducers: {
    ...adapterHelper(jobBoardsAdapter),
  },
});

export default jobBoardsSlice;
