export const JobBoardsEndpoints = {
  GetJobBoardSettingsFunc: '/api/company/{companyId}/job-board/{jobBoardId}/settings',
  GetJobBoardFunc: '/api/company/{companyId}/job-board/{jobBoardId}',
  UnsubscribeJobBoardFunc: '/api/company/{companyId}/job-board/{jobBoardId}/unsubscribe',
  GetJobBoardAdditionalFieldsFunc: '/api/company/{companyId}/job-board/{jobBoardId}/additional-field',
  SearchSubscribedJobBoardsFunc: '/api/company/{companyId}/job-board/subscribed',
  SearchUnsubscribedJobBoardsFunc: '/api/company/{companyId}/job-board/unsubscribed',
  JobBoardsAutocompleteFunc: '/api/company/{companyId}/job-board/autocomplete',
  SearchJobAdsForJobBoardFunc: '/api/company/{companyId}/job-board/{jobBoardId}/ad',
  UpdateJobBoardSettingsFunc: '/api/company/{companyId}/job-board/{jobBoardId}/settings',
  SubscribeJobBoardFunc: '/api/company/{companyId}/job-board/{jobBoardId}/subscribe',
  SearchJobBoardChangeNotesFunc: '/api/company/{companyId}/job-board/{companyJobBoardId}/change-note',
};

export const JobBoardsEndpointsForApi = {
  // GET
  GetJobBoardsForJobsFunc: '/job-board',
  GetJobBoardAdditionalFieldsFunc: '/job-board/{jobBoardId}/additional-field',
} as const;
