import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserProfile } from 'oidc-client-ts';

import { UserCompany } from 'api-endpoints/user/user.model';

import { permissionMatrix } from 'containers/Auth/AuthMatrix';

import { GeneralTalentPool } from 'store/company/company.models';

import type { UserAPI } from './auth.type';

const initialState = () => ({
  user: null as null | UserProfile,
  userAPI: null as null | UserAPI,
  generalTalentPool: null as null | GeneralTalentPool,
  loading: true as boolean,
  permissionMatrix: permissionMatrix as typeof permissionMatrix | null,
  company: null as UserCompany | null,
  companyId: null as string | null,
  companies: [] as UserCompany[],
});

const { actions, reducer, name } = createSlice({
  name: 'auth',
  initialState: initialState(),
  reducers: {
    update: (state, { payload }: PayloadAction<Partial<ReturnType<typeof initialState>>>) => ({
      ...state,
      ...payload,
    }),
    setUser(state, { payload }) {
      state.user = payload.user;
    },
    setUserApi(state, { payload }) {
      state.userAPI = payload.userAPI;
    },
    setPermissionMatrix(state, { payload }) {
      state.permissionMatrix = payload.permissionMatrix;
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    logout() {
      return initialState();
    },
  },
});

export const authReducerName = name;

export const authSliceActions = actions;

export const authReducer = reducer;
