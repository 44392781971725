import { createEntityAdapter, createSlice, nanoid } from '@reduxjs/toolkit';

import { SourceEntity } from 'model';

import { CheckableRowItem } from 'components/Form/CheckableRow/CheckableRowProps';

const createItem = ({
  text,
  sourceEntity,
}: {
  text: string;
  sourceEntity?: SourceEntity;
}): { payload: CheckableRowItem } => {
  return {
    payload: {
      id: nanoid(20),
      isEnabled: true,
      text,
      sourceEntity: sourceEntity || SourceEntity.Position,
    },
  };
};

const checkableListItemAdapter = createEntityAdapter<CheckableRowItem>();

const checkableListSlice = createSlice({
  name: 'abilities',
  initialState: checkableListItemAdapter.getInitialState(),
  reducers: {
    add: {
      reducer: checkableListItemAdapter.addOne,
      prepare: createItem,
    },
    change: checkableListItemAdapter.updateOne,
    changeMany: checkableListItemAdapter.updateMany,
    deleteItem: checkableListItemAdapter.removeOne,
    load: checkableListItemAdapter.setAll,
  },
});

const checkableListSelectAll = checkableListItemAdapter.getSelectors().selectAll;
const checkableListActions = checkableListSlice.actions;
const checkableListReducer = checkableListSlice.reducer;

export {
  checkableListItemAdapter,
  checkableListSlice,
  checkableListSelectAll,
  checkableListActions,
  checkableListReducer,
};
