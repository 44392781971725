import { compose } from '@reduxjs/toolkit';
import type { NumberSchema, SchemaOf, StringSchema, TestConfig, TestContext } from 'yup';
import { mixed, object, string } from 'yup';
import { MixedSchema } from 'yup/lib/mixed';
import { TransformFunction } from 'yup/lib/types';
import { isFuture, parseISO } from 'date-fns';

import { DateTimeDto } from 'model';
import { DateTimeType } from 'model/api-enums.constants';

import { EmailRecipient } from 'pages/Company/CompanyTabs/CompanyEmailTemplateTab';

import type { EnumLiteralsOf } from 'utils/funcs';
import { StringIsNumber } from 'utils/funcs';

export const emptyStringOrZeroToUndefined: TransformFunction<StringSchema | NumberSchema> = (v, o) => {
  if (o === '') {
    return undefined;
  }
  return Number(o) === 0 ? undefined : v;
};

export const emptyStringToUndefined: TransformFunction<StringSchema | NumberSchema> = (v: string | number, o: string) =>
  o === '' ? undefined : v;

export const emptyStringToNull: TransformFunction<StringSchema<string | null> | NumberSchema<number | null>> = (
  value,
  originalValue,
) => (String(originalValue).trim() === '' ? null : value);

export const nullToUndefined: TransformFunction<MixedSchema> = (v) => (v === null ? undefined : v);

const removeHTML = (value: string): string => value.replace(/(<([^>]+)>)/gi, '');

export const trimLeadAndTrailingSpaces = (value: string): string => value.trim();

export const replaceNonBreakableSpacesToSpace = (value: string): string => value?.replace(/&nbsp;/gi, '');

const getLinks = (value: string): string[] => {
  const regex = /href="([^\s]+)"/gim;
  const result: string[] = [];
  let match;
  do {
    match = regex.exec(value);
    if (match) {
      result.push(match[1]);
    }
  } while (match);

  return result.filter(Boolean);
};

const validateLinks = (links: string[]) => {
  const schema = string().url();
  return links.reduce((acc, link) => {
    return acc && schema.isValidSync(link);
  }, true);
};

export function linkValidator(this: TestContext, value = '') {
  const links = getLinks(value);
  validateLinks(links);
  return validateLinks(links);
}

const emailListSanitiser = (value = '') => [
  ...value
    .split(',')
    .map((email) => email.trim())
    .filter(Boolean),
];

// Test for either a single valid email, or check that the value contains comma separators
export function emailSeparatorValidator(this: TestContext, value = '') {
  return string().email().isValidSync(value.trim()) || value.includes(',');
}

export function emailListValidator(this: TestContext, value = '') {
  const emails = emailListSanitiser(value);
  const schema = string().email();
  return emails.every((email) => schema.isValidSync(email));
}

export function emailRecipientBuilder(value = ''): EmailRecipient[] | undefined {
  if (value === '') {
    return undefined;
  }
  const emails = emailListSanitiser(value);
  return emails.map((email) => ({
    email,
    displayName: email,
  }));
}

function removeWhiteSpace(str: string) {
  return str.replace(/\s/g, '');
}

export const validateStringLengthWithoutHtmlTagsPrepare = compose<string, string, string, string, string>(
  removeWhiteSpace,
  trimLeadAndTrailingSpaces,
  replaceNonBreakableSpacesToSpace,
  removeHTML,
);

export function validateStringLengthWithoutHtmlTags(min: number) {
  return function (this: TestContext, value: string | undefined) {
    const preparedValue = validateStringLengthWithoutHtmlTagsPrepare(value ?? '');
    return preparedValue.length >= min;
  };
}

export const stringOptional = string().transform(emptyStringToUndefined);
export const stringNullable = stringOptional.nullable(true).default(null);
export const stringRequired = string().required().trim();

export const isBeforeTodayTest: TestConfig<string | null | undefined> = {
  exclusive: true,
  message: ({ path }) => `${path} should not be beyond current date`,
  name: 'isBeforeToday',
  test: (value) => {
    if (!value) {
      return true;
    }
    const valueDate = parseISO(value);
    return !isFuture(valueDate);
  },
};

export const maxLengthForCurrencyAmount: TestConfig<string | number | undefined> = {
  exclusive: false,
  message: ({ label }) => `${label} should be less than 999,999,999`,
  name: 'maxLength',
  params: {},
  test: (v) => {
    if (StringIsNumber(v) && v > 999_999_999) {
      return false;
    }
    return !v || v.toString().length < 10;
  },
};

export const validationSchemaDateTimeDto: SchemaOf<DateTimeDto> = object({
  actualDate: mixed()
    .label('Specific Date')
    .when('dateTimeType', {
      is: (v: EnumLiteralsOf<typeof DateTimeType>) => v === DateTimeType.ActualDate,
      then: string()
        .required()
        .typeError(({ label }) => `${label} is required.`),
      otherwise: string().nullable(true).default(null),
    })
    .default(null),
  dateTimeType: mixed<EnumLiteralsOf<typeof DateTimeType>>()
    .strict()
    .oneOf([...Object.values(DateTimeType).filter(StringIsNumber)], `Please select a value from the options.`)
    .default(DateTimeType.ActualDate),
});
