import React from 'react';
import styled from 'styled-components';

import { MenuItem } from 'components/ActionsDropdown';
import { ExActionButton } from 'components/ui/ExActionButton';

import { CandidateActionButtonProps } from './CandidateListProps';

const CandidateActionButton: React.FC<CandidateActionButtonProps> = ({ className }) => {
  const menuItems: MenuItem[] = [
    {
      label: 'Action 1',
      action: async () => {
        // do nothing
      },
    },
    {
      label: 'Acton 2',
      action: async () => {
        // do nothing
      },
    },
  ];

  return (
    <ExActionButton
      buttonId="candidateActionDropdown"
      menuItems={menuItems}
      dropDirection="down"
      className={className}
    />
  );
};

export default styled(CandidateActionButton)``;
