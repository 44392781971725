import { all, call, spawn, takeLatest } from 'redux-saga/effects';

import * as apiCandidate from 'api-endpoints/candidate';

import { consoleErrorForDevEnv } from 'utils/consoleErrorForDevEnv';
import { fetchEntity, worker } from 'utils/sagas';

import * as fromCandidateSagas from 'store/entities/candidates/candidate-sagas';

import * as fromCandidateActions from './candidate.actions';

//--------------------------------------------------------------------------------------------------
const apiRemoveAvatar = fetchEntity.bind(
  null,
  fromCandidateActions.candidateRemoveAvatarHandler,
  apiCandidate.deleteCandidateAvatar,
);
//--------------------------------------------------------------------------------------------------
const removeAvatarCancelable = worker.bind(null, fromCandidateActions.candidateAvatarRemoveCancel, apiRemoveAvatar);
//--------------------------------------------------------------------------------------------------

export function* candidateSagas() {
  yield takeLatest(fromCandidateActions.candidateAvatarRemoveRequest, removeAvatarCancelable);
  const sagas = [...Object.values(fromCandidateSagas)];
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            consoleErrorForDevEnv(e);
          }
        }
      }),
    ),
  );
}
