import type { OnDragEndResponder } from 'react-beautiful-dnd';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppRouterParams } from 'router';

import { interviewTemplateEditFormSlice } from 'containers/InterviewTemplateForms';
import { interviewTemplateEditFormSelectors } from 'containers/InterviewTemplateForms/InterviewTemplateEditForm/state/InterviewTemplateEditForm.selectors';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { useInterviewQuestionListQuery } from 'store/entities/interview-question';
import { useInterviewTemplateQuery } from 'store/entities/interview-template';
import { useQuestionListQuery } from 'store/entities/interview-template-questions/interview-template-questions.api';

export const useInterviewTemplateElementListState = () => {
  const { interviewTemplateId } = useAppRouterParams();

  const { isLoading: isInterviewTemplateLoading } = useInterviewTemplateQuery(interviewTemplateId ?? skipToken);
  const {
    data: questions,
    isFetching: isQuestionListFetching,
    isLoading: isQuestionListLoading,
  } = useQuestionListQuery(interviewTemplateId ?? skipToken);
  const itemsIds = questions?.ids || [];
  const hasItemsIds = Boolean(itemsIds.length);
  const noItemsIds = !hasItemsIds && !isQuestionListFetching;
  const isLoading = isQuestionListLoading || isInterviewTemplateLoading;

  return {
    itemsIds,
    hasItemsIds,
    isLoading,
    noItemsIds,
    interviewTemplateId: interviewTemplateId!,
    onDragEnd: () => {},
    isLoadingReorder: false,
  } as const;
};

export const useInterviewElementListState = () => {
  const params = useAppRouterParams();
  const interviewId = params.interviewId!;

  const { data, isLoading } = useInterviewQuestionListQuery(interviewId);
  const itemsIds = data?.ids || [];

  return {
    itemsIds,
    interviewTemplateId: interviewId,
    isLoading,
  };
};

export const useInterviewTemplateElementListFormState = () => {
  const dispatch = useAppDispatch();
  const { interviewTemplateId } = useAppRouterParams();

  const { isLoading: isInterviewTemplateLoading } = useInterviewTemplateQuery(interviewTemplateId ?? skipToken);
  const { isLoading: isQuestionListLoading, isFetching: isQuestionListFetching } = useQuestionListQuery(
    interviewTemplateId ?? skipToken,
  );

  const itemsIds = useAppSelector(interviewTemplateEditFormSelectors.questionsFormSelectorsFromAdapter.selectIds);
  const hasItemsIds = Boolean(itemsIds?.length);
  const noItemsIds = !hasItemsIds && !isQuestionListFetching;
  const isLoading = isQuestionListLoading || isInterviewTemplateLoading;

  const onDragEnd: OnDragEndResponder = (result) => {
    if (result.destination && result.destination.index !== result.source.index) {
      dispatch(
        interviewTemplateEditFormSlice.actions.interviewTemplateEditFormReorderQuestionReducer({
          interviewTemplateQuestionId: result.draggableId,
          target: result.destination.index,
          source: result.source.index,
        }),
      );
    }
  };

  return {
    itemsIds,
    hasItemsIds,
    isLoading,
    noItemsIds,
    onDragEnd,
    interviewTemplateId: interviewTemplateId!,
    isLoadingReorder: false,
  } as const;
};
