import React from 'react';

import { ExCardTitle } from 'components/ui/ExCard/ExCardComponents';

import { ExCardEditButton } from './ExCardEditButton';

interface TitleProps {
  onClick?: React.MouseEventHandler;
  title: string;
  className?: string;
}

const ExCardHeader: React.FC<TitleProps> = ({ onClick, title }) => (
  <>
    <ExCardTitle>{title}</ExCardTitle>
    <ExCardEditButton onClick={onClick}>Edit</ExCardEditButton>
  </>
);

const ExCardTitleMemo = React.memo(ExCardHeader);

export { ExCardTitleMemo as ExCardHeader };
