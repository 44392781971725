import { SyntheticEvent, useCallback } from 'react';

export const ReactCountryFlagCdnUrl = 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.5/flags/1x1/';

export const useReactCountryFlagOptions = () => {
  const onReactCountryFlagError = useCallback((e: SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.onerror = null;
    e.currentTarget.src = `${ReactCountryFlagCdnUrl}xx.svg`;
  }, []);

  return {
    ReactCountryFlagCdnUrl,
    onReactCountryFlagError,
  };
};
