import React from 'react';

import { useQuestionViewComponent } from './hooks/useQuestionViewComponent';
import { QuestionViewProps } from './QuestionViewProps';

export const QuestionView: React.FC<QuestionViewProps> = (props) => {
  const type = props.question.questionType;
  const QuestionViewComponent = useQuestionViewComponent(type);

  if (!QuestionViewComponent) {
    return null;
  }

  return <QuestionViewComponent {...props} />;
};
