import React from 'react';

import { Country } from 'model';

import { ExButton } from 'components/ui/ExButton';
import { ExMultiselectFormBody } from 'components/ui/ExMultiselectFormBody';

import type { CompanyInfo } from 'store/company/company.types';
import { useCountryQuerySelectors } from 'store/dictionary/hooks/useCountryQuerySelectors';
import { useSelector } from 'store/rootSelectors';

type CompanyCountriesFormBodyProps = {
  className?: string;
  onChange: (entityList: CompanyInfo['countries']) => void;
  defaultValue: CompanyInfo['countries'];
  toggleSelectAll: () => void;
  allSelected: boolean;
};

export const CompanyCountriesFormBody = ({
  className,
  onChange,
  defaultValue,
  toggleSelectAll,
  allSelected,
}: CompanyCountriesFormBodyProps) => {
  const { selectors: countriesSelectors } = useCountryQuerySelectors();
  const countries = useSelector(countriesSelectors.selectAll) as CompanyInfo['countries'];

  const selectAllButtonText = (allSelected ? 'Deselect' : 'Select') + ' All';

  return (
    <ExMultiselectFormBody
      className={className}
      defaultValue={defaultValue}
      getOptionLabelCallback={(option: Country) => option.name}
      getOptionValueCallback={(option: Country) => option.countryId}
      loadEntitiesHandler={() => Promise.resolve(countries)}
      onChangeHandler={onChange}
      title="Search Country"
      backspaceRemovesValue={false}
      headerActions={
        <ExButton variant="link" onClick={toggleSelectAll}>
          {selectAllButtonText}
        </ExButton>
      }
    />
  );
};
