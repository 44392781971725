import React from 'react';
import styled from 'styled-components/macro';

import { ApplicantModalSwitchMode } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalSwitchMode';

import { ApplicantModalStatusInfoEdit } from './ApplicantModalStatusInfoEdit';
import { ApplicantModalStatusInfoView } from './ApplicantModalStatusInfoView';

type ApplicantModalStatusInfoProps = {
  className?: string;
};

const ApplicantModalStatusInfoStyled = styled(ApplicantModalSwitchMode)`
  width: 100%;
`;

const useApplicantModalStatusInfoState = ({ className }: ApplicantModalStatusInfoProps) => {
  return {
    className,
  } as const;
};

export const ApplicantModalStatusInfo: React.FC<ApplicantModalStatusInfoProps> = (props) => {
  const { className } = useApplicantModalStatusInfoState(props);

  return (
    <ApplicantModalStatusInfoStyled
      className={className}
      editComponent={<ApplicantModalStatusInfoEdit />}
      viewComponent={<ApplicantModalStatusInfoView />}
    />
  );
};
