import styled from 'styled-components/macro';

export const PUBLIC_PAGE_MAX_WIDTH = '767px';
export const PUBLIC_PAGE_MIN_WIDTH = '768px';

export const PublicPageContainerWrapper = styled.div`
  background-color: #f9f9fb;
  padding: 16px 16px 80px;

  @media (min-width: ${PUBLIC_PAGE_MIN_WIDTH}) {
    padding: 48px 24px 80px;
  }
`;

export const PublicPageContainer = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
`;
