import { all, call, put, race, select, take } from 'redux-saga/effects';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { getTranslate } from 'utils/i18utils';

import * as fromCompanyActions from 'store/company/company.actions';
import { exModalHideAction } from 'store/modals/modals.actions';
import { modalSagaWorker } from 'store/modals/modals.sagas';

import { companySelectors } from './company.selectors';
import type { CompanyInfo } from './company.types';

export function isListEmpty<T>(list: T[] | undefined): boolean {
  return !list || list.length === 0;
}

export function isDefaultInList<T>(currencies: T[], path: keyof T): boolean {
  function isDefault(item: T) {
    return item[path];
  }

  return currencies.some(isDefault);
}

export function* companyUpdateConfirmModal(modalId: string | undefined, message: string) {
  return yield call(modalSagaWorker, {
    ...(modalId ? { id: modalId } : {}),
    modalType: ModalsTypeKey.confirmModal,
    modalConfig: {
      content: {
        message,
        title: 'Confirm Action',
        withTitle: true,
        withActions: true,
      },
    },
  });
}

interface EntitiesForUpdate {
  main: boolean;
  currencyId?: string;
  industryId?: string;
  countryId?: number;
}
export function* prepareRequestData<T extends EntitiesForUpdate>(
  field: keyof Pick<CompanyInfo, 'currencies' | 'industries' | 'countries'>,
  funcs: {
    mapFunction?: (entity: T, index: number) => T;
    filterFunction?: (entity: T, index: number) => boolean;
  },
  array?: T[],
) {
  const companyInfo: ReturnType<typeof companySelectors.getCompanyInfo> = yield select(companySelectors.getCompanyInfo);
  const companyId = companyInfo?.companyId;

  if (!companyInfo || !companyId) {
    return null;
  }

  let entities = (array ?? companyInfo[field] ?? []) as T[];

  if (funcs.filterFunction) {
    entities = entities.filter(funcs.filterFunction);
  }

  if (funcs.mapFunction) {
    entities = entities.map(funcs.mapFunction);
  }

  return {
    [field]: entities,
  } as Partial<CompanyInfo>;
}

export function makeDefaultFirstItemOrOther<T>(entity: T, index: number) {
  return {
    ...entity,
    main: index === 0,
  };
}

type updateRequestProps = 'currencies' | 'countries' | 'industries' | 'none';

const getUpdateRequestByType = (type: updateRequestProps) => {
  return {
    currencies: fromCompanyActions.updateCompanyCurrenciesRequest,
    countries: fromCompanyActions.updateCompanyCountriesRequest,
    industries: fromCompanyActions.updateCompanyIndustriesRequest,
    none: fromCompanyActions.updateCompanyRequest,
  }[type];
};

export function* updateCompanyUtil(
  modalId: string,
  requestData: Partial<CompanyInfo>,
  successMessage: string,
  type: updateRequestProps = 'none',
) {
  const companyId = requestData.companyId;
  const updateRequestByType = getUpdateRequestByType(type);

  yield put(
    updateRequestByType({
      data: requestData,
      urlParams: { companyId },
    }),
  );

  const { success } = yield race({
    success: take(fromCompanyActions.updateCompanySuccess),
    failure: take(fromCompanyActions.updateCompanyFailure),
  });

  yield all([
    put(exModalHideAction({ id: modalId })),
    put(alertsEffects.showSuccess({ message: getTranslate(successMessage) })),
    put(fromCompanyActions.loadCompanyInfoRequest({ urlParams: { companyId } })),
  ]);

  return !!success;
}
