import { createAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import startCase from 'lodash/startCase';

import { WithCancelToken } from 'api-endpoints/utils';

import { ApiResponseWithPagination } from 'model';
import { EmailCategory, OrderDirection } from 'model/api-enums.constants';

import type { EnumLiteralsOf } from 'utils/funcs';
import { pickAndRename } from 'utils/funcs';
import { adapterHelper } from 'utils/reducer/adapter-helper';

import { EntityKeysNew } from 'store/constants';
import { createActionApiCallBatch } from 'store/entities/qualification-type/qualification-type.model';
import { RootState } from 'store/rootReducer';
import { entitiesDomain } from 'store/rootSelectors';

//------------------------------------------------------------------------------

// MODELS
export type EmailRecipient = {
  email: string;
  displayName: string;
};

export type EmailTemplateModel = {
  templateId: string;
  companyId?: string;
  emailCategory: EnumLiteralsOf<typeof EmailCategory>;
  emailTitle: string;
  emailSubject: string;
  emailBodyHtmlContent: string;
  isInUse?: boolean;
  ccRecipients?: EmailRecipient[];
};

export type EmailTemplateFormModel = Omit<EmailTemplateModel, 'ccRecipients'> & { ccRecipients?: string };
//------------------------------------------------------------------------------

// API TYPES
export type EmailTemplateCreateRequestDTO = Omit<EmailTemplateModel, 'companyId' | 'templateId'>;
export type EmailTemplateCreateResponseDTO = Pick<EmailTemplateModel, 'templateId'>;
export type EmailTemplateUpdateRequestDTO = Omit<EmailTemplateModel, 'companyId'>;
export type EmailTemplateUpdateResponseDTO = void;
export type EmailTemplateGetResponseDTO = EmailTemplateModel;
export type EmailTemplateSearchRequestParams = WithCancelToken<
  EmailTemplateUrlParams & {
    orderDirection?: OrderDirection;
    orderBy?: keyof EmailTemplateModel;
    pageNo?: number;
    pageSize?: number;
  }
>;
export type EmailTemplateSearchResponseDTO = ApiResponseWithPagination<EmailTemplateModel>;
export type EmailTemplateDeleteResponseDTO = void;

export type EmailTemplateUrlParams = {
  urlParams: {
    templateId: string;
  };
};

export type EmailTemplateRequestUrlParams = WithCancelToken<EmailTemplateUrlParams>;
export type EmailTemplateUpdateUrlParams = WithCancelToken<
  EmailTemplateUrlParams & { data: EmailTemplateUpdateRequestDTO }
>;
export type EmailTemplateCreateUrlParams = WithCancelToken<{ data: EmailTemplateCreateRequestDTO }>;
//------------------------------------------------------------------------------

// REDUX
const emailTemplateEntityAdapter = createEntityAdapter<EmailTemplateModel>({
  selectId: (entity) => entity.templateId,
});

export const emailTemplateSlice = createSlice({
  initialState: emailTemplateEntityAdapter.getInitialState({
    emailCategories: Object.entries(EmailCategory).map(([label, value]) => ({
      label: startCase(label),
      value,
    })),
    mergeFields: [
      'first_name',
      'last_name',
      'stage_name',
      'job_name',
      'company_name',
      'applied_on',
      'applicant_confirm_page_url',
      'applicant_decline_page_url',
    ] as const,
    sortMode: {
      orderBy: 'emailTitle',
      orderDir: OrderDirection[OrderDirection.Ascending].toLocaleLowerCase(),
    },
    filters: {},
  }),
  name: EntityKeysNew.emailTemplate,
  reducers: {
    ...adapterHelper(emailTemplateEntityAdapter),
    setSortMode(state, action) {
      state.sortMode = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
  },
});

export const emailTemplateReducer = emailTemplateSlice.reducer;

// ACTIONS
export const EMAIL_TEMPLATE_FETCH = 'emailTemplate/fetch';
const EMAIL_TEMPLATE_GET = 'emailTemplate/get';
const EMAIL_TEMPLATE_UPDATE = 'emailTemplate/update';
const EMAIL_TEMPLATE_REMOVE = 'emailTemplate/remove';
const EMAIL_TEMPLATE_EDIT = 'emailTemplate/edit';
const EMAIL_TEMPLATE_CREATE = 'emailTemplate/create';

type EmailTemplateRequestActionPayloadProps = Pick<EmailTemplateModel, 'templateId'>;

const fetchSaga = createActionApiCallBatch(EMAIL_TEMPLATE_FETCH);
const getSaga = createAction<EmailTemplateRequestActionPayloadProps>(EMAIL_TEMPLATE_GET);
const updateSaga = createAction<EmailTemplateRequestActionPayloadProps>(EMAIL_TEMPLATE_UPDATE);
const removeSaga = createAction<EmailTemplateRequestActionPayloadProps>(EMAIL_TEMPLATE_REMOVE);
const editSaga = createAction<EmailTemplateRequestActionPayloadProps>(EMAIL_TEMPLATE_EDIT);
const createSaga = createAction(EMAIL_TEMPLATE_CREATE);

export const emailTemplateActions = {
  ...emailTemplateSlice.actions,
  createSaga,
  editSaga,
  fetchSaga,
  getSaga,
  removeSaga,
  updateSaga,
};

// SELECTORS
const domain = createSelector(entitiesDomain, (state) => state.emailTemplate);
const adapterSelectors = emailTemplateEntityAdapter.getSelectors(domain);
const emailTemplateCategories = createSelector(domain, (state) => state.emailCategories);
const emailTemplateSortMode = createSelector(domain, (state) => state.sortMode);
const emailTemplateMergeFields = createSelector(domain, (state) => state.mergeFields);
const emailTemplateFilters = createSelector(domain, (state) => state.filters);

const emailTemplateToOption = pickAndRename<EmailTemplateModel>([
  ['templateId', 'value'],
  ['emailTitle', 'label'],
]);

const emailTemplateOptionsForSelector = createSelector(adapterSelectors.selectAll, (emailTemplates) =>
  emailTemplates.map(emailTemplateToOption),
);

const emailTemplatesForSelectorSendEmailActions = createSelector(adapterSelectors.selectAll, (emailTemplates) =>
  emailTemplates.filter(({ emailCategory }) => emailCategory === EmailCategory.PipelineNotifications),
);
const emailTemplateOptionsForSelectorSendEmailActions = createSelector(
  emailTemplatesForSelectorSendEmailActions,
  (emailTemplates) => emailTemplates.map(emailTemplateToOption),
);

const selectEmailTemplatesExcludeCandidateNotification = createSelector(adapterSelectors.selectAll, (emailTemplates) =>
  emailTemplates.filter((emailTemplate) => emailTemplate.emailCategory !== EmailCategory.CandidateNotifications),
);

const selectEmailTemplatesForReassignExcludeByIds = createSelector(
  selectEmailTemplatesExcludeCandidateNotification,
  (state: RootState, pipelineIds: string[]) => pipelineIds,
  (emailTemplates, emailTemplateIdsToExclude) =>
    emailTemplates.filter((emailTemplate) => !emailTemplateIdsToExclude.includes(emailTemplate.templateId)),
);

const selectEmailTemplatesExcludeByIds = createSelector(
  adapterSelectors.selectAll,
  (state: RootState, pipelineIds: string[]) => pipelineIds,
  (emailTemplates, emailTemplateIdsToExclude) =>
    emailTemplates.filter((emailTemplate) => !emailTemplateIdsToExclude.includes(emailTemplate.templateId)),
);
export const emailTemplateSelectors = {
  ...adapterSelectors,
  emailTemplateCategories,
  emailTemplateMergeFields,
  emailTemplateOptionsForSelector,
  emailTemplateSortMode,
  selectEmailTemplatesExcludeByIds,
  selectEmailTemplatesExcludeCandidateNotification,
  selectEmailTemplatesForReassignExcludeByIds,
  emailTemplateFilters,
  emailTemplatesForSelectorSendEmailActions,
  emailTemplateOptionsForSelectorSendEmailActions,
};
