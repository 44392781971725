import { createEntityAdapter } from '@reduxjs/toolkit';

import { EntityStateWithPagination, providesList, transformListResponse } from 'api-client/axiosBaseQuery';
import { replaceParamsInUrl } from 'api-client/utils';
import { JobItemResponse } from 'api-endpoints/job';
import { JobEndpointsForApi } from 'api-endpoints/job/endpoints';
import {
  CopyJobFuncRequestData,
  CopyJobFuncResponse,
  JobApplicantsPerPipelineStageFiguresRequest,
  JobApplicantsPerPipelineStageFiguresResponse,
  SearchJobsFuncRequestParams,
} from 'api-endpoints/job/job.dto';

import { JobStatus } from 'model/api-enums.constants';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { loaderActions } from 'containers/Loader/store';

import { getSuccessToastForEntityAction } from 'utils/i18utils';

import { emptyApi } from 'store/entities/emptyApi';
import { jobsActions } from 'store/entities/jobs';
import { loadJobWorkflowTaskId } from 'store/entities/jobs/job.constants';
import { jobToState } from 'store/entities/jobs/mappers';
import { Job, JobTeamMember } from 'store/entities/jobs/models';

export const JOB_TEAM_TAG_TYPE = 'JobTeam';

type JobBaseProps = {
  jobId: Job['jobId'];
  jobName: Job['jobName'];
};

export const jobAdapter = createEntityAdapter<Job>({
  selectId: (item) => item.jobId,
});

const extendedJobApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    jobsList: builder.query<EntityStateWithPagination<Job>, SearchJobsFuncRequestParams>({
      query: (arg: SearchJobsFuncRequestParams) => ({
        url: replaceParamsInUrl(JobEndpointsForApi.SearchJobsFunc, {}),
        method: 'GET',
        params: arg,
      }),
      transformResponse: transformListResponse(jobAdapter),
    }),
    jobsListLoadMore: builder.query({
      query: (arg: SearchJobsFuncRequestParams) => ({
        url: replaceParamsInUrl(JobEndpointsForApi.SearchJobsFunc, {}),
        method: 'GET',
        params: {
          ...arg,
        },
      }),
      onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            extendedJobApi.util.updateQueryData('jobsListLoadMore', { ...arg, pageNo: 0 }, (draft) => {
              jobAdapter.upsertMany(
                draft,
                data.ids.map((id) => data.entities[id]!),
              );
              draft.pageCount = data.pageCount;
              draft.pageNo = data.pageNo;
            }),
          );
        } catch (error) {}
      },
      transformResponse: transformListResponse(jobAdapter),
    }),
    jobsListCount: builder.query<Partial<Record<keyof typeof JobStatus, number>>, any>({
      query: (arg) => ({
        url: replaceParamsInUrl(JobEndpointsForApi.GetJobCountFunc, {}),
        method: 'GET',
        params: arg,
      }),
    }),
    jobDelete: builder.mutation<void, JobBaseProps>({
      query: ({ jobId }) => ({
        url: replaceParamsInUrl(JobEndpointsForApi.DeleteJobFunc, { jobId }),
        method: 'DELETE',
      }),
      onQueryStarted: async ({ jobName }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          const message = getSuccessToastForEntityAction({
            name: jobName,
            action: 'deleted',
            entityName: 'job',
          });
          dispatch(alertsEffects.showSuccess({ message }));
        } catch (e) {}
      },
    }),
    jobCopy: builder.mutation<CopyJobFuncResponse, CopyJobFuncRequestData>({
      query: (arg) => ({
        url: replaceParamsInUrl(JobEndpointsForApi.CopyJobFunc, { jobId: arg.jobId }),
        method: 'POST',
        data: {
          ...arg,
        },
      }),
      onQueryStarted: async ({ jobName }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          const message = getSuccessToastForEntityAction({
            name: jobName,
            action: 'duplicated',
            entityName: 'job',
          });
          dispatch(alertsEffects.showSuccess({ message }));
        } catch (e) {}
      },
    }),
    jobApplicantsPerPipelineStageFigures: builder.query<
      JobApplicantsPerPipelineStageFiguresResponse,
      JobApplicantsPerPipelineStageFiguresRequest
    >({
      query: ({ jobId }) => ({
        url: replaceParamsInUrl(JobEndpointsForApi.GetJobApplicantsPerPipelineStageFiguresForJobFunc, { jobId }),
        method: 'GET',
      }),
    }),
    jobApplicants: builder.query({
      query: (arg) => ({
        url: replaceParamsInUrl(JobEndpointsForApi.SearchJobApplicantsFunc, { jobId: arg.jobId }),
        method: 'POST',
        data: {
          ...arg,
        },
      }),
    }),
    jobPipelines: builder.query({
      query: (arg) => ({
        url: replaceParamsInUrl(JobEndpointsForApi.GetApplicationPipelineStagesFunc, { jobId: arg.jobId }),
        method: 'GET',
      }),
      onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
        try {
          dispatch(loaderActions.start(loadJobWorkflowTaskId));
          await queryFulfilled;
        } finally {
          dispatch(loaderActions.stop(loadJobWorkflowTaskId));
        }
      },
    }),
    getJobTeam: builder.query({
      query: ({ jobId }) => ({
        url: replaceParamsInUrl(JobEndpointsForApi.GetJobTeamFunc, { jobId }),
        method: 'GET',
      }),
    }),
    getJobTeamAutocomplete: builder.query<JobTeamMember[], { jobId: string; searchTerm?: string }>({
      query: ({ jobId, searchTerm }) => ({
        url: replaceParamsInUrl(JobEndpointsForApi.GetJobTeamMemberAutocompleteFunc, { jobId }),
        method: 'GET',
        params: {
          searchTerm,
        },
      }),
      transformResponse(response: JobTeamMember[]) {
        return response.map((member) => ({
          ...member,
          userId: member.id,
          isRemoved: member?.isRemoved || false,
        }));
      },
    }),
    getJobForForm: builder.mutation<JobItemResponse, { jobId: Job['jobId'] }>({
      query: ({ jobId }) => ({
        url: replaceParamsInUrl(JobEndpointsForApi.GetJobFunc, { jobId }),
        method: 'GET',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(jobsActions.upsertOne({ item: jobToState({ ...data }) }));
        } catch (e) {}
      },
    }),
  }),
});

export const enhancedJobApi = extendedJobApi.enhanceEndpoints({
  addTagTypes: ['Jobs', 'JobApplicantsPerPipelineStageFigures', 'JobPipelines', JOB_TEAM_TAG_TYPE],
  endpoints: {
    jobsList: {
      providesTags: (result) => providesList(result?.ids, 'Jobs'),
    },
    jobsListLoadMore: {
      providesTags: (result) => providesList(result?.ids, 'Jobs'),
    },
    jobDelete: {
      invalidatesTags: (_, __, { jobId }) => [
        { type: 'Jobs', id: 'LIST' },
        { type: 'Jobs', id: jobId },
      ],
    },
    jobCopy: {
      invalidatesTags: () => [{ type: 'Jobs', id: 'LIST' }],
    },
    jobsListCount: {
      providesTags: () => [{ type: 'Jobs', id: 'LIST' }],
    },
    jobApplicantsPerPipelineStageFigures: {
      providesTags: (_, __, { jobId }) => [{ type: 'JobApplicantsPerPipelineStageFigures', id: jobId }],
    },
    jobPipelines: {
      providesTags: (_, __, { jobId }) => [{ type: 'JobPipelines', id: jobId }],
    },
    getJobTeam: {
      providesTags: () => [{ type: JOB_TEAM_TAG_TYPE, id: 'LIST' }],
    },
  },
});

export const {
  useJobsListQuery,
  useJobsListLoadMoreQuery,
  useLazyJobsListLoadMoreQuery,
  useJobsListCountQuery,
  useJobDeleteMutation,
  useJobCopyMutation,
  useJobApplicantsPerPipelineStageFiguresQuery,
  useJobApplicantsQuery,
  useJobPipelinesQuery,
  useGetJobTeamQuery,
  useLazyGetJobTeamAutocompleteQuery,
  useGetJobForFormMutation,
} = enhancedJobApi;
