import { useHistory } from 'react-router-dom';
import { useAppRouterParams } from 'router';
import { ExRoutes } from 'router/routes';

import { InterviewState } from 'model/api-enums.constants';

import {
  InterviewTableCellComponent,
  InterviewTableCellComponentProps,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/Tabs/InterviewsTab/InterviewsTabProps';

import { MenuItem } from 'components/ActionsDropdown/ActionsDropdownProps';
import { useInterviewActions } from 'components/ApplicantInterview/hooks/useInterviewActions';
import { ExActionButton } from 'components/ui/ExActionButton';
import { useInterviewChangeStatus, useInterviewRemove, useInterviewSettingsModal } from 'utils/hooks/interview';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { tipsSelectors } from 'store/ui/tips';

const useInterviewTableActionButtonState = ({ item: interview }: InterviewTableCellComponentProps) => {
  const [removeJobInterview] = useInterviewRemove({ interview });
  const [openInterviewSettingsModal] = useInterviewSettingsModal({ interviewId: interview.interviewId });
  const isInterviewEditPopupShow = useAppSelector(tipsSelectors.selectIsInterviewEditPopupShow);
  const { jobId } = useAppRouterParams();
  const history = useHistory();
  const { showSettingsButton, showDeleteButton, showEditMenuButton } = useInterviewActions({
    interview,
  });

  const [changeInterviewStatusInterviewWithConfirmModal] = useInterviewChangeStatus({
    interview,
  });

  const onEditHandler = async () => {
    if (interview.state === InterviewState.Saved) {
      const result = await changeInterviewStatusInterviewWithConfirmModal(InterviewState.Draft);

      if (!result && isInterviewEditPopupShow) {
        return;
      }
    }

    history.push(
      ExRoutes.applicantInterview({
        jobId,
        applicantId: interview.jobApplicantId,
        interviewId: interview.interviewId,
      }),
    );
  };

  const menuItems: MenuItem[] = [
    ...(showEditMenuButton
      ? [
          {
            label: 'Edit Interview',
            onSelect: onEditHandler,
          },
        ]
      : []),
    ...(showSettingsButton
      ? [
          {
            label: 'Edit settings',
            onSelect: openInterviewSettingsModal,
          },
        ]
      : []),
    ...(showDeleteButton
      ? [
          {
            label: 'Remove interview',
            variant: 'danger',
            onSelect: removeJobInterview,
          },
        ]
      : []),
  ];

  return { menuItems } as const;
};

export const InterviewTableActionButton: InterviewTableCellComponent = (props) => {
  const { menuItems } = useInterviewTableActionButtonState(props);

  return <ExActionButton className={undefined} menuItems={menuItems} dropDirection="down" buttonId="interview" />;
};
