import { useMemo } from 'react';
import startCase from 'lodash/startCase';

import { JobType } from 'model/api-enums.constants';

import { EnumLiteralsOf } from 'utils/funcs';

const getJobTypeLabel = (value: number, key: string) => {
  if (value === JobType.External) {
    return 'Listed';
  }

  return startCase(key);
};

export function useJobTypeOptions() {
  return useMemo(
    () => Object.entries(JobType).map(([key, value]) => ({ value, label: getJobTypeLabel(value, key) })),
    [],
  );
}

export function useJobTypeOptionsForToggle() {
  return [
    { value: JobType.External, label: 'Yes' },
    { value: JobType.Unlisted, label: 'No' },
  ];
}

export function useJobTypeByValue(value?: EnumLiteralsOf<typeof JobType>) {
  const jobTypeOptions = useJobTypeOptions();
  return useMemo(() => jobTypeOptions.find((jobType) => jobType.value === value), [jobTypeOptions, value]);
}

export function useJobListOnCareerPage(value?: EnumLiteralsOf<typeof JobType>) {
  const jobTypeOptions = useJobTypeOptionsForToggle();
  return useMemo(() => jobTypeOptions.find((jobType) => jobType.value === value), [jobTypeOptions, value]);
}
