import { matchPath } from 'react-router-dom';
import { Routes } from 'router/names';

import { VIEW_MODE } from 'model';
import { AppRouterParams } from 'model/router';

import { createPath, createRoute } from './utils';

const newJobAdTitle = 'New Job Ad';

const exRouteNames = [
  'dashboard',
  'dashboardJobAds',
  'jobNew',
  'jobBoardSubscribe',
  'jobBoardList',
  'jobBoardAds',
  'applicantNew',
  'applicantNewStepSelectJob',
  'jobAdNew',
  'jobAdNewDetails',
  'jobAdNewRequirements',
  'jobAdNewReview',
  'jobAdView',
  'jobAdCreate',
  'jobAdDraft',
  'talentPools',
  'talentPoolPage',
  'talentPoolAddNewCandidates',
  'talentPoolCandidatePage',
  'candidateApplications',
  'jobListOpen',
  'jobPage',
  'jobAdPage',
  'jobSettings',
  'jobJobAds',
  'jobInterviewsView',
  'applicantsView',
  'jobApplicantsView',
  'jobPipelineView',
  'jobApplicantsModal',
  'applicantOverviewView',
  'applicantInterviewsView',
  'applicantNewFromJob',
  'applicantNewFromCandidateJobApplicationsTab',
  'company',
  'companyDetails',
  'companyInterviewTemplates',
  'placements',
  'applicantModalForPlacements',
  'jobListAll',
  'jobListDraft',
  'jobListCancelled',
  'jobListFilled',
  'jobAdNewFromJob',
  'jobAdNewFromJobSelectJob',
  'jobAdNewFromJobSelectJobBoards',
  'jobAdNewFromJobDetails',
  'jobAdNewFromJobRequirements',
  'jobAdNewFromJobReview',
  'selectCompanyPage',
  'jobComments',
  'interviewTemplate',
  'interviewTemplateEdit',
  'interviewTemplatePreview',
  'applicantInterview',
  'candidates',
  'candidatePage',
  'jobEdit',
  'jobCreate',
] as const;

export type ExRoutesNames = typeof exRouteNames[number];

type ExRoutesType = Record<ExRoutesNames, (urlParams?: AppRouterParams) => string>;
type ExRoutesEntity = {
  path: string;
  selectTitle: () => string;
  preventHistoryPush?: boolean;
  preventBackLink?: boolean;
  allowedBackPathList?: Array<ExRoutesNames>;
  restrictedBackPathList?: Array<ExRoutesNames>;
};
type ExRoutesPathMapProps = Record<ExRoutesNames, ExRoutesEntity>;

const jobListPages: Array<ExRoutesNames> = [
  'jobListAll',
  'jobListOpen',
  'jobListDraft',
  'jobListCancelled',
  'jobListFilled',
];

export const ExRoutesPathMap: ExRoutesPathMapProps = {
  applicantModalForPlacements: {
    path: createPath([Routes.home, Routes.placements, Routes.applicantIdParam]),
    selectTitle: () => 'Applicant Modal',
  },
  applicantNew: {
    preventBackLink: true,
    path: createPath([Routes.home, Routes.applicants, Routes.applicantNew]),
    selectTitle: () => 'New Applicant',
  },
  applicantNewStepSelectJob: {
    preventBackLink: true,
    path: createPath([Routes.home, Routes.applicants, Routes.applicantNew, Routes.applicantStepSelectJob]),
    selectTitle: () => 'New Applicant',
  },
  applicantNewFromCandidateJobApplicationsTab: {
    preventBackLink: true,
    path: createPath([
      Routes.home,
      Routes.talentPools,
      Routes.talentPoolIdParam,
      Routes.candidates,
      Routes.candidateIdParam,
      Routes.candidateJobApplications,
      Routes.applicantNew,
    ]),
    selectTitle: () => 'New Applicant From Candidate',
  },
  applicantNewFromJob: {
    preventBackLink: true,
    path: createPath([
      Routes.home,
      Routes.jobs,
      Routes.jobIdParam,
      Routes.applicantsView,
      Routes.applicantNew,
      ':step?',
    ]),
    selectTitle: () => 'New Applicant From Job',
  },
  applicantsView: {
    allowedBackPathList: ['dashboard', ...jobListPages],
    path: createPath([
      Routes.home,
      Routes.jobs,
      Routes.jobIdParam,
      Routes.applicantsView,
      Routes.applicantsViewTabParam,
    ]),
    selectTitle: () => 'Applicants View',
  },
  jobApplicantsView: {
    allowedBackPathList: ['dashboard', ...jobListPages],
    path: createPath([Routes.home, Routes.jobs, Routes.jobIdParam, Routes.applicantsView, Routes.jobApplicants]),
    selectTitle: () => 'Job Applicants View',
  },
  jobPipelineView: {
    allowedBackPathList: ['dashboard', ...jobListPages],
    path: createPath([Routes.home, Routes.jobs, Routes.jobIdParam, Routes.applicantsView, 'pipeline']),
    selectTitle: () => 'Job Pipeline View',
  },
  jobApplicantsModal: {
    path: createPath([
      Routes.home,
      Routes.jobs,
      Routes.jobIdParam,
      Routes.applicantsView,
      Routes.applicantsViewTabParam,
      Routes.applicantIdParam,
    ]),
    selectTitle: () => 'Applicant Modal',
  },
  applicantOverviewView: {
    path: createPath([
      Routes.home,
      Routes.candidates,
      Routes.candidateIdParam,
      Routes.candidateJobApplications,
      Routes.jobIdParam,
      Routes.applicant,
      Routes.applicantIdParam,
      Routes.applicantOverview,
    ]),
    selectTitle: () => '',
  },
  applicantInterviewsView: {
    path: createPath([
      Routes.home,
      Routes.jobs,
      Routes.jobIdParam,
      Routes.applicantsView,
      Routes.jobApplicants,
      Routes.applicantIdParam,
      Routes.applicantInterviews,
    ]),
    selectTitle: () => '',
  },
  jobComments: {
    allowedBackPathList: ['jobPage', 'dashboard'],
    path: createPath([Routes.home, Routes.jobs, Routes.jobIdParam, Routes.jobEdit, 'comments']),
    selectTitle: () => 'Job Comments',
  },
  candidateApplications: {
    path: createPath([
      Routes.home,
      Routes.talentPools,
      Routes.talentPoolIdParam,
      Routes.candidates,
      Routes.candidateIdParam,
      Routes.candidateJobApplications,
    ]),
    selectTitle: () => 'Candidate Applications',
  },
  company: {
    path: createPath([Routes.home, Routes.company]),
    selectTitle: () => 'Company',
  },
  companyDetails: {
    path: createPath([Routes.home, Routes.company, Routes.companyDetails]),
    selectTitle: () => 'Company Details',
  },
  companyInterviewTemplates: {
    path: createPath([Routes.home, Routes.company, Routes.companyInterviewTemplates]),
    selectTitle: () => 'Company Interview Template',
  },
  dashboard: {
    path: createPath([Routes.home, Routes.dashboard]),
    selectTitle: () => 'Dashboard',
  },
  dashboardJobAds: {
    path: createPath([Routes.home, Routes.dashboard, Routes.jobAds]),
    selectTitle: () => 'Dashboard Job Ads',
  },
  jobAdNew: {
    preventBackLink: true,
    path: createPath([Routes.home, Routes.jobBoards, Routes.jobAdNew]),
    selectTitle: () => newJobAdTitle,
  },
  jobAdNewDetails: {
    preventBackLink: true,
    path: createPath([Routes.home, Routes.jobBoards, Routes.jobAdNew, Routes.jobAdDetails]),
    selectTitle: () => newJobAdTitle,
  },
  jobAdNewRequirements: {
    preventBackLink: true,
    path: createPath([Routes.home, Routes.jobBoards, Routes.jobAdNew, Routes.jobAdRequirements]),
    selectTitle: () => newJobAdTitle,
  },
  jobAdNewReview: {
    preventBackLink: true,
    path: createPath([Routes.home, Routes.jobBoards, Routes.jobAdNew, Routes.jobAdReview]),
    selectTitle: () => newJobAdTitle,
  },
  jobAdNewFromJob: {
    preventBackLink: true,
    path: createPath([Routes.home, Routes.jobs, Routes.jobIdParam, Routes.jobAds, Routes.jobAdNew]),
    selectTitle: () => newJobAdTitle,
  },
  jobAdNewFromJobSelectJob: {
    preventBackLink: true,
    path: createPath([
      Routes.home,
      Routes.jobs,
      Routes.jobIdParam,
      Routes.jobAds,
      Routes.jobAdNew,
      Routes.jobAdSelectJob,
    ]),
    selectTitle: () => newJobAdTitle,
  },
  jobAdNewFromJobSelectJobBoards: {
    preventBackLink: true,
    path: createPath([
      Routes.home,
      Routes.jobs,
      Routes.jobIdParam,
      Routes.jobAds,
      Routes.jobAdNew,
      Routes.jobAdSelectJobBoards,
    ]),
    selectTitle: () => newJobAdTitle,
  },
  jobAdNewFromJobDetails: {
    preventBackLink: true,
    path: createPath([
      Routes.home,
      Routes.jobs,
      Routes.jobIdParam,
      Routes.jobAds,
      Routes.jobAdNew,
      Routes.jobAdDetails,
    ]),
    selectTitle: () => newJobAdTitle,
  },
  jobAdNewFromJobRequirements: {
    preventBackLink: true,
    path: createPath([
      Routes.home,
      Routes.jobs,
      Routes.jobIdParam,
      Routes.jobAds,
      Routes.jobAdNew,
      Routes.jobAdRequirements,
    ]),
    selectTitle: () => newJobAdTitle,
  },
  jobAdNewFromJobReview: {
    preventBackLink: true,
    path: createPath([Routes.home, Routes.jobs, Routes.jobIdParam, Routes.jobAds, Routes.jobAdNew, Routes.jobAdReview]),
    selectTitle: () => newJobAdTitle,
  },
  applicantInterview: {
    allowedBackPathList: ['applicantInterviewsView', 'jobInterviewsView'],
    path: createPath([
      Routes.home,
      Routes.jobs,
      Routes.jobIdParam,
      Routes.applicantsView,
      Routes.applicants,
      Routes.applicantIdParam,
      Routes.interview,
      Routes.interviewIdParam,
    ]),
    selectTitle: () => 'Applicant Interview',
  },
  jobAdPage: {
    path: createPath([
      Routes.home,
      Routes.jobBoards,
      Routes.jobBoardIdParam,
      Routes.jobAds,
      Routes.jobAdIdParam,
      Routes.jobAdStatistics,
    ]),
    selectTitle: () => 'Job Ad Page',
  },
  jobBoardAds: {
    allowedBackPathList: ['jobBoardList'],
    path: createPath([Routes.home, Routes.jobBoards, Routes.jobBoardIdParam, Routes.jobAds]),
    selectTitle: () => 'Job Board Ads',
  },
  jobBoardList: {
    path: createPath([Routes.home, Routes.jobBoards]),
    selectTitle: () => 'Job Boards',
  },
  jobBoardSubscribe: {
    preventBackLink: true,
    path: createPath([Routes.home, Routes.jobBoards, Routes.new, Routes.jobBoardsFormSelectBoard]),
    selectTitle: () => 'Subscribe To Job Board',
  },
  jobListOpen: {
    path: createPath([Routes.home, Routes.jobs], '?status=open'),
    selectTitle: () => 'Open Jobs',
  },
  jobListAll: {
    path: createPath([Routes.home, Routes.jobs]),
    selectTitle: () => 'All Jobs',
  },
  jobListCancelled: {
    path: createPath([Routes.home, Routes.jobs], '?status=cancelled'),
    selectTitle: () => 'Cancelled Jobs',
  },
  jobListDraft: {
    path: createPath([Routes.home, Routes.jobs], '?status=draft'),
    selectTitle: () => 'Draft Jobs',
  },
  jobListFilled: {
    path: createPath([Routes.home, Routes.jobs], '?status=filled'),
    selectTitle: () => 'Filled Jobs',
  },
  jobNew: {
    preventBackLink: true,
    path: createPath([Routes.home, Routes.jobs, Routes.new, Routes.jobFormStepDetails]),
    selectTitle: () => 'New Job',
  },
  jobEdit: {
    preventBackLink: true,
    path: createPath([Routes.home, Routes.jobs, Routes.jobIdParam, Routes.jobEdit]),
    selectTitle: () => 'Edit Job',
  },
  jobCreate: {
    preventBackLink: true,
    path: createPath([Routes.home, Routes.jobs, Routes.jobCreate]),
    selectTitle: () => 'Create Job',
  },
  jobPage: {
    allowedBackPathList: ['dashboard', ...jobListPages],
    path: createPath([Routes.home, Routes.jobs, Routes.jobIdParam, Routes.jobPipelineView]),
    selectTitle: () => 'Job Page',
  },
  jobSettings: {
    preventBackLink: true,
    allowedBackPathList: ['dashboard', ...jobListPages],
    path: createPath([Routes.home, Routes.jobs, Routes.jobIdParam, Routes.jobSettings]),
    selectTitle: () => 'Job Settings',
  },
  jobJobAds: {
    allowedBackPathList: ['dashboard', ...jobListPages],
    path: createPath([Routes.home, Routes.jobs, Routes.jobIdParam, Routes.jobAds]),
    selectTitle: () => 'Job Ads',
  },
  jobInterviewsView: {
    allowedBackPathList: ['dashboard', ...jobListPages],
    path: createPath([Routes.home, Routes.jobs, Routes.jobIdParam, Routes.jobInterviews]),
    selectTitle: () => 'Job Interviews',
  },
  placements: {
    path: createPath([Routes.home, Routes.placements]),
    selectTitle: () => 'Placements',
  },
  selectCompanyPage: {
    path: createPath([Routes.home, Routes.selectCompany]),
    selectTitle: () => 'Select Company',
  },
  talentPoolCandidatePage: {
    allowedBackPathList: [
      'jobPage',
      'jobApplicantsModal',
      'applicantModalForPlacements',
      'talentPoolPage',
      'talentPoolAddNewCandidates',
    ],
    path: createPath([
      Routes.home,
      Routes.talentPools,
      Routes.talentPoolIdParam,
      Routes.candidates,
      Routes.candidateIdParam,
      Routes.candidateDetails,
    ]),
    selectTitle: () => 'Candidate Page',
  },
  talentPoolPage: {
    allowedBackPathList: ['candidates', 'talentPools'],
    path: createPath([Routes.home, Routes.talentPools, Routes.talentPoolIdParam, Routes.candidates]),
    selectTitle: () => 'Talent Pool',
  },
  talentPoolAddNewCandidates: {
    allowedBackPathList: ['talentPoolPage'],
    path: createPath([Routes.home, Routes.talentPools, Routes.talentPoolIdParam, Routes.talentPoolAddNewCandidates]),
    selectTitle: () => 'Talent Pool',
  },
  talentPools: {
    path: createPath([Routes.home, Routes.talentPools]),
    selectTitle: () => 'Talent Pools',
  },
  interviewTemplate: {
    allowedBackPathList: ['companyInterviewTemplates'],
    path: createPath([Routes.home, Routes.company, Routes.companyInterviewTemplates, Routes.interviewTemplateIdParam]),
    selectTitle: () => 'Interview Template',
  },
  interviewTemplateEdit: {
    allowedBackPathList: ['companyInterviewTemplates'],
    path: createPath([
      Routes.home,
      Routes.company,
      Routes.companyInterviewTemplates,
      Routes.interviewTemplateIdParam,
      'edit',
    ]),
    selectTitle: () => 'Interview Template Edit',
  },
  interviewTemplatePreview: {
    allowedBackPathList: ['interviewTemplateEdit', 'interviewTemplate'],
    path: createPath([
      Routes.home,
      Routes.company,
      Routes.companyInterviewTemplates,
      Routes.interviewTemplateIdParam,
      'preview',
    ]),
    selectTitle: () => 'Interview Template Preview',
  },
  candidates: {
    selectTitle: () => 'Candidates',
    path: createPath([Routes.home, Routes.candidates]),
  },
  candidatePage: {
    selectTitle: () => 'Candidate',
    path: createPath([Routes.home, Routes.candidates, Routes.candidateIdParam, Routes.candidateDetails]),
  },
  jobAdView: {
    selectTitle: () => 'View Job Ad',
    path: createPath([
      Routes.home,
      Routes.jobs,
      Routes.jobIdParam,
      Routes.jobBoards,
      Routes.jobBoardIdParam,
      Routes.jobAds,
      Routes.jobAdIdParam,
      VIEW_MODE.VIEW.toLowerCase(),
    ]),
  },
  jobAdCreate: {
    selectTitle: () => 'Create Job Ad',
    path: createPath([
      Routes.home,
      Routes.jobs,
      Routes.jobIdParam,
      Routes.jobBoards,
      Routes.jobBoardIdParam,
      VIEW_MODE.CREATE.toLowerCase(),
    ]),
  },
  jobAdDraft: {
    selectTitle: () => 'Edit Job Ad Draft',
    path: createPath([
      Routes.home,
      Routes.jobs,
      Routes.jobIdParam,
      Routes.jobBoards,
      Routes.jobBoardIdParam,
      Routes.jobAds,
      Routes.jobAdIdParam,
      VIEW_MODE.DRAFT.toLowerCase(),
    ]),
  },
};

export const findInRoutesMap = (pathname: string) => {
  const route = Object.entries(ExRoutesPathMap).find(([_key, value]) => {
    const path = value?.path;
    const match = matchPath(pathname, {
      exact: true,
      path,
    });

    return match !== null;
  });

  if (!route) {
    return;
  }

  return {
    preventHistoryPush: route[1].preventHistoryPush,
    routeName: route[0] as ExRoutesNames,
  } as const;
};

/**
 * This object will contains all static and dynamic routes in Expedo App.
 * Example of dynamic  is `talentPoolCandidatePage`
 */
export const ExRoutes = exRouteNames.reduce((acc, name) => {
  acc[name] = (urlParams) => createRoute(ExRoutesPathMap[name].path, urlParams);

  return acc;
}, {} as ExRoutesType);
