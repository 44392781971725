import React, { useCallback, useMemo } from 'react';

import { ExLoaderHandlerWrapperStrict } from 'components/Common/LoaderHandlerWrapper';
import { IconSuccess } from 'components/Icons/IconSuccess';
import {
  ExButtonCancel,
  ExButtonOk,
  ExDialogActions,
  ExDialogBody,
  ExDialogCloseButton,
  ExDialogComponent,
  ExDialogFooter,
  ExDialogHeader,
  ExDialogIcon,
  ExDialogTitle,
  ExWrappedMessage,
} from 'components/ui/ExDialog/ExDialogComponents';
import { ExDialogContentChildrenProps, ExDialogProps, ExDialogStatus } from 'components/ui/ExDialog/ExDialogProps';

const ExDialog: React.FC<ExDialogProps> = ({ children, onClose, content, className, theme }) => {
  const onCloseHandler = useCallback((value: boolean | null) => () => onClose(value), [onClose]);

  const exDialogBody = useMemo(() => {
    if (children) {
      return React.cloneElement(children as React.ReactElement, { ...content.childrenProps, theme });
    }
    if (content.children) {
      return React.createElement(content.children, { ...content.childrenProps, onClose, theme });
    }
    return <ExWrappedMessage>{content.message}</ExWrappedMessage>;
  }, [children, content.children, content.childrenProps, content.message, onClose, theme]);

  const IconSets = {
    [ExDialogStatus.success]: <IconSuccess />,
  };

  const Icon = content.status ? IconSets[content.status] : null;

  return (
    <ExDialogComponent className={className}>
      <ExDialogCloseButton onClick={onCloseHandler(null)} />
      {Icon && <ExDialogIcon>{Icon}</ExDialogIcon>}
      <ExDialogHeader>{content.withTitle && <ExDialogTitle>{content.title}</ExDialogTitle>}</ExDialogHeader>
      <ExDialogBody>{exDialogBody}</ExDialogBody>
      {content.withActions && (
        <ExDialogFooter>
          <ExDialogActions>
            <ExButtonCancel variant={content.buttonCancelVariant ?? 'light'} onClick={onCloseHandler(false)}>
              {content.buttonCancel}
            </ExButtonCancel>
            {content.buttonOk !== undefined && content.buttonOk !== null && (
              <ExLoaderHandlerWrapperStrict>
                <ExButtonOk
                  variant={content.buttonOkVariant ?? 'primary'}
                  onClick={onCloseHandler(true)}
                  {...content.buttonOkProps}
                >
                  {content.buttonOk}
                </ExButtonOk>
              </ExLoaderHandlerWrapperStrict>
            )}
          </ExDialogActions>
        </ExDialogFooter>
      )}
    </ExDialogComponent>
  );
};

const makeChildren =
  <P extends ExDialogContentChildrenProps>(Component: React.ComponentType<P>) =>
  (props: P) =>
    <Component {...props} />;

export { ExDialog, makeChildren };
