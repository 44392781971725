import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { list } from 'config/list';

import { InterviewOrderProperty, OrderDirection } from 'model/api-enums.constants';

import { EnumLiteralsOf } from 'utils/funcs';

import { UIKeys } from 'store/constants';

type InitialStateProps = {
  jobId?: null | string;
};

type JobInterviewTabListUiSliceState = ReturnType<typeof initialState>;

export type JobInterviewTabListFilters = JobInterviewTabListUiSliceState['filters'];

const initialState = ({ jobId = null } = {} as InitialStateProps) => ({
  filters: {
    jobId,
    pageNo: 0,
    pageSize: list.pageSize,
    orderDirection: OrderDirection.Ascending,
    orderBy: InterviewOrderProperty.PublishedOn as EnumLiteralsOf<typeof InterviewOrderProperty>,
  },
});

export const jobInterviewTabListUiSlice = createSlice({
  name: UIKeys.jobInterviewTabList,
  initialState: initialState(),
  reducers: {
    updateState: (state, { payload }: PayloadAction<Partial<JobInterviewTabListUiSliceState>>) =>
      Object.assign(state, payload),
    resetState: (state, { payload }: PayloadAction<InitialStateProps>) => initialState({ jobId: payload.jobId }),
  },
});
