import React, { useCallback, useEffect, useRef, useState } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import { nanoid } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { FormTooltip } from 'components/FormTooltip';
import { useInputEventHandler } from 'utils/hooks/useInputEnterHandler';

import { InputGroupStyled, InputGroupTextStyled } from './InputRowComponents';
import { InputRowProps } from './InputRowProps';

/**
 * Wrapper on top of react-bootstrap InputGroup
 * https://react-bootstrap.github.io/components/input-group/
 *
 * @param {InputRowProps} {
 *   onAdd,
 *   appendButtonText = 'Add',
 *   placeholder = '',
 * }
 * @returns {React.ReactElement}
 */
const InputRow = ({
  onAdd,
  appendButtonText = 'Add',
  placeholder = '',
  validated,
  errors,
  inputRef,
}: InputRowProps): React.ReactElement => {
  const [text, setText] = useState('');
  const [focus, setFocus] = useState(false);
  const [showError, setShowError] = useState(true);

  useEffect(() => {
    const hasError = Boolean(errors) || !isEmpty(errors);
    if (!hasError) {
      setShowError(false);
    } else {
      setShowError(true);
    }
  }, [errors]);

  const target = useRef<any>();
  const [id] = useState(nanoid());

  const addAndReset = useCallback(
    (label) => {
      onAdd(label);
      setText('');
    },
    [onAdd, setText],
  );

  const onAddHandler = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();
    addAndReset(text);
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const onKeyDownHandler = useInputEventHandler(() => {
    addAndReset(text);
  });

  return (
    <InputGroupStyled ref={target}>
      <FormControl
        placeholder={placeholder}
        aria-label={placeholder}
        aria-describedby={id}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
        value={text}
        isInvalid={validated && !!errors}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        ref={inputRef}
      />
      <InputGroupStyled.Append>
        <InputGroupTextStyled id={id} onClick={onAddHandler}>
          {appendButtonText}
        </InputGroupTextStyled>
      </InputGroupStyled.Append>
      <FormTooltip target={target} show={focus && showError && !!validated} errors={errors} offset={[32, 0]} />
    </InputGroupStyled>
  );
};

export { InputRow };
