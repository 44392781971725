import { DeepPartial } from 'redux';

import { FormJobCaseReducer } from 'containers/JobForms/state/models';

import { Job } from 'store/entities/jobs/models';

export const saveForm: FormJobCaseReducer<{ form: DeepPartial<Job> }> = (state, action) => {
  state.formData = {
    ...state.formData,
    ...action.payload.form,
  };
  state.apiErrors = [];
};
