import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { getDictionary } from 'api-endpoints/dictionary';

import { Country } from 'model';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { loaderActions } from 'containers/Loader/store';

import { compareCountryByCountryCode } from 'utils/funcs';
import { adapterHelper } from 'utils/reducer/adapter-helper';

import { DictionaryKeys } from 'store/constants';
import { RootState } from 'store/rootReducer';
import { dictionariesDomain } from 'store/rootSelectors';
import { AppEffectParams, ThunkApiConfig } from 'store/types';

const countryAdapter = createEntityAdapter<Country>({
  selectId: (country) => country.countryId,
});

const DICTIONARY_COUNTRY_FETCH = 'dictionary/country/fetch';

export const loadCountries = createAsyncThunk<Country[] | void, AppEffectParams, ThunkApiConfig>(
  DICTIONARY_COUNTRY_FETCH,
  async (params, { dispatch, signal, requestId, getState, rejectWithValue }) => {
    const { preloader } = params ?? {};
    const source = axios.CancelToken.source();
    const currentRequestId = selectCurrentRequestId(getState());
    if (currentRequestId !== requestId) {
      return;
    }
    if (preloader) {
      dispatch(loaderActions.start(requestId));
    }
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    const { data, message } = await getDictionary<Country[]>(DictionaryKeys.country, source.token);
    if (message) {
      dispatch(
        alertsEffects.showError({
          message,
        }),
      );
      return rejectWithValue(message);
    }
    if (preloader) {
      dispatch(loaderActions.stop(requestId));
    }
    return data;
  },
);

export const countriesSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(loadCountries.fulfilled, (state, action) => {
      if (state.currentRequestId && state.currentRequestId === action.meta.requestId) {
        state.currentRequestId = null;
        if (Array.isArray(action.payload)) {
          countryAdapter.setAll(state, action.payload);
        }
      }
    });
    builder.addCase(loadCountries.pending, (state, action) => {
      state.currentRequestId = action.meta.requestId;
    });
  },
  initialState: countryAdapter.getInitialState({
    currentRequestId: null as string | null,
  }),
  name: DictionaryKeys.country,
  reducers: {
    ...adapterHelper(countryAdapter),
  },
});

// Selectors
export const domain = createSelector(dictionariesDomain, (state) => state.country);

const countriesAdapterSelectors = countryAdapter.getSelectors(domain);

const selectIdByExpedoCode2 = createSelector(
  countriesAdapterSelectors.selectAll,
  (_: RootState, expedoCode2: string) => expedoCode2,
  (countries, expedoCode2) => {
    const country = countries.find(compareCountryByCountryCode(expedoCode2, 'expedoCode2'));
    return country?.countryId!;
  },
);

const selectIdByIdibuCode2 = createSelector(
  countriesAdapterSelectors.selectAll,
  (_: RootState, idibuCode2: string) => idibuCode2,
  (countries, idibuCode2) => {
    const country = countries.find(compareCountryByCountryCode(idibuCode2, 'idibuCode2'));
    return country?.countryId!;
  },
);

const selectByExpedoCode2 = createSelector((root) => root, selectIdByExpedoCode2, countriesAdapterSelectors.selectById);

const selectByIdibuCode2 = createSelector((root) => root, selectIdByIdibuCode2, countriesAdapterSelectors.selectById);

export const countriesSelectors = {
  ...countriesAdapterSelectors,
  selectByExpedoCode2,
  selectByIdibuCode2,
  selectIdByExpedoCode2,
};

export const selectCurrentRequestId = createSelector(domain, (state) => state.currentRequestId);
export const countriesActions = countriesSlice.actions;
