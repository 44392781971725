import { useCallback, useEffect } from 'react';

import { emailTemplatesColumn } from 'pages/Company/CompanyTabs/CompanyEmailTemplateTab/components/EmailTemplateTable';
import { emailTemplateActions, emailTemplateSelectors } from 'pages/Company/CompanyTabs/CompanyEmailTemplateTab/state';
import { useShowModal } from 'pages/Company/CompanyTabs/hooks/useShowModal';

import { ExAddNewButton } from 'components/ui/ExAddNewButton/ExAddNewButton';
import { ExCard } from 'components/ui/ExCard';
import { ExSortableTable } from 'components/ui/ExSortableTable';
import { useLoaderSubscription } from 'modules/LoaderManager/react';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { useSelector } from 'store/rootSelectors';

type CompanyEmailTemplateWidgetProps = {};

export const CompanyEmailTemplateWidget = (_: CompanyEmailTemplateWidgetProps) => {
  const { clickHandler: addClickHandler } = useShowModal();
  const dispatch = useAppDispatch();
  const emailTemplateList = useSelector(emailTemplateSelectors.selectAll);
  const sortMode = useSelector(emailTemplateSelectors.emailTemplateSortMode);
  const { isLoading } = useLoaderSubscription(emailTemplateActions.fetchSaga.start);

  useEffect(() => {
    dispatch(emailTemplateActions.setFilters({}));
    dispatch(emailTemplateActions.fetchSaga.start({}));
  }, [dispatch]);

  const handleOnSort = useCallback(
    (sortModeForUpdate: object) => {
      dispatch(emailTemplateActions.setSortMode(sortModeForUpdate));
      dispatch(emailTemplateActions.fetchSaga.start({ silent: true }));
    },
    [dispatch],
  );

  return (
    <ExCard
      title="Email Templates"
      headerActions={
        <ExAddNewButton onClick={addClickHandler(emailTemplateActions.createSaga())}>Add New</ExAddNewButton>
      }
    >
      <ExSortableTable
        entityList={emailTemplateList}
        columns={emailTemplatesColumn}
        sortMode={sortMode}
        setSortMode={handleOnSort}
        loading={isLoading}
      />
    </ExCard>
  );
};
