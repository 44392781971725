import { useMemo } from 'react';
import {
  formatDistance,
  formatDuration,
  intervalToDuration,
  isSameMonth,
  isSameYear,
  isValid,
  parseISO,
} from 'date-fns';

function getValidEndDate(endDate: string | null): Date {
  let result = parseISO(new Date().toISOString());

  if (endDate !== null) {
    result = parseISO(endDate);
  }

  if (!isValid(result)) {
    result = parseISO(new Date().toISOString());
  }

  return result;
}

export const isSameYearsFirstMonthsAndDays = (startDate: Date, endDate: Date) => {
  const isFirstMonth = startDate.getMonth() === 0 && endDate.getMonth() === 0;
  const isFirstDay = startDate.getDate() === 1 && endDate.getDate() === 1;

  return isSameYear(startDate, endDate) && isFirstMonth && isFirstDay;
};

export const isSameYearsMonthsFirstDays = (startDate: Date, endDate: Date) => {
  const isFirstDay = startDate.getDate() === 1 && endDate.getDate() === 1;

  return isSameMonth(startDate, endDate) && isFirstDay;
};

/**
 * This hook receive start and end dates as string and then returns formatted human readable string.
 * If interval between dates more then one month date formatted to "{number} month"
 * If interval between dates more then one year date formatted to "{number} years {number} months"
 * If interval between dates less then month date formatted to "{number} days"
 *
 * @param {string} startDate
 * @param {string | null} endDate
 *
 * @returns string
 */
export function useDuration(startDate: string | null, endDate: string | null): string | null {
  return useMemo(() => {
    if (!startDate) {
      return null;
    }

    const startDateParsed = parseISO(startDate);
    const endDateParsed = getValidEndDate(endDate);

    if (isSameYearsFirstMonthsAndDays(startDateParsed, endDateParsed)) {
      return 'Less than a year';
    }

    if (isSameYearsMonthsFirstDays(startDateParsed, endDateParsed)) {
      return 'Less than a month';
    }

    const duration = intervalToDuration({ start: startDateParsed, end: endDateParsed });

    let formattedDuration;

    if (duration.years) {
      formattedDuration = formatDuration(duration, { format: ['years', 'months'], zero: false });
    } else if (duration.months) {
      formattedDuration = formatDuration(duration, { format: ['months'], zero: false });
    } else {
      formattedDuration = formatDuration(duration, { format: ['days'], zero: false });
    }

    return formattedDuration === '' ? formatDistance(startDateParsed, endDateParsed) : formattedDuration;
  }, [startDate, endDate]);
}
