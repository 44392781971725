import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { AppModalContentProps, ModalsTypeKey } from 'containers/Modals/AppModalProps';
import { modalsSelectors } from 'containers/Modals/store';
import { NewTalentPoolFormBody, validationSchemaNewTalentPool } from 'containers/TalentPoolForms';

import { Form } from 'components';
import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { DialogProps } from 'components/Dialog';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { ExDialog, ExDialogActions } from 'components/ui/ExDialog';
import { loaderThunkActionWrapper } from 'modules/LoaderManager/redux';

import { TalentPool, talentPoolEffects, talentPoolSelectors } from 'store/entities/talent-pools';
import { RootState } from 'store/rootReducer';

import { useSaveTalentPool } from './hooks/useSaveTalentPool';

export interface EditTalentPoolProps extends AppModalContentProps {
  className?: string;
}

const FormStyled = styled(Form)`
  min-width: 400px;

  & > *:not(:last-child) {
    margin-bottom: 0;
  }
`;

const EditTalentPool: React.FC<EditTalentPoolProps> = ({ className, onClose }) => {
  const isMountedRef = React.useRef(true);

  const [submitting, setSubmitting] = React.useState(false);
  const [data, setData] = React.useState<Partial<TalentPool> | null>(null);

  const modalTalentPoolId = useSelector(modalsSelectors.modalTalentPoolId);

  const talentPool =
    (useSelector((state: RootState) => talentPoolSelectors.getById(state, modalTalentPoolId)) as TalentPool) ||
    undefined;

  const content: DialogProps['content'] = {
    title: 'Edit Talent Pool',
    withTitle: true,
  };

  const defaultValues = talentPool;

  const message = data ? `Talent Pool "${data.name}" successfully updated.` : '';
  const id = ModalsTypeKey.editTalentPool;
  useSaveTalentPool({ data, onClose, submitting, setSubmitting, message, id });

  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []); // eslint-disable-line

  const submitHandler = React.useCallback((talentPoolFormData: Partial<TalentPool>) => {
    if (isMountedRef.current) {
      setData(talentPoolFormData);
    }
  }, []);

  return (
    <div className={className}>
      <ExDialog onClose={onClose} content={content} className="dialog">
        <FormStyled
          onSubmit={submitHandler}
          validationSchema={validationSchemaNewTalentPool}
          defaultValues={defaultValues}
        >
          <NewTalentPoolFormBody />
          <ExDialogActions>
            <ExButton variant="light" onClick={onClose}>
              Cancel
            </ExButton>
            <ExLoaderHandlerWrapper action={loaderThunkActionWrapper(talentPoolEffects.saveTalentPool)}>
              <ExButton className="ml-3" variant="primary" type="submit">
                Save
              </ExButton>
            </ExLoaderHandlerWrapper>
          </ExDialogActions>
        </FormStyled>
      </ExDialog>
    </div>
  );
};

export default styled(EditTalentPool)`
  .dialog {
    background-color: ${(props) => props.theme.colors.white};
  }
`;
