import React, { useEffect } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { createFilter } from 'react-select';
import styled from 'styled-components';
import get from 'lodash/get';

import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import { ExModalBodyStyled, ExModalWrapperStyled } from 'containers/Modals/ModalsContent/Company/ExModalComponents';

import { FormSelect } from 'components';
import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { FormInput } from 'components/FormInput';
import { ExButton } from 'components/ui/ExButton';
import { ExDialogActions } from 'components/ui/ExDialog';
import { ExColumn, ExRow } from 'components/ui/ExLayout';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { getTranslate } from 'utils/i18utils';

import { updateCompanyQualificationTypeMaps } from 'store/company/company.actions';
import { companySelectors } from 'store/company/company.selectors';
import { QualificationTypeModel, qualificationTypeSelectors } from 'store/entities/qualification-type';
import { exModalConfirmAction } from 'store/modals/modals.actions';
import { ExModal } from 'store/modals/modals.interfaces';

type CompanyUpdateQualificationTypesMapsProps = {
  id: string;
} & ExModal &
  AppModalContentProps;

const CompanyUpdateQualificationTypesMapsStyled = styled(ExModalWrapperStyled)`
  max-width: 980px;

  .form-group {
    margin-bottom: 0;
  }

  ${FormInput} {
    .form-control::placeholder {
      color: ${(props) => props.theme.colors.default};
    }
  }
`;

export const ListLabel = styled.div`
  font-weight: bold;
`;

const ModalForm = styled.form`
  margin-bottom: 20px;
`;

type DaXtraDisabledInputProps = {
  children: string;
  name: string;
  className?: string;
  inputRef?: any;
  innerRef?: any;
  value?: React.HtmlHTMLAttributes<HTMLInputElement>['defaultValue'];
};
export const DaXtraDisabledInput = styled(
  ({ children, className, name, innerRef, inputRef, value }: DaXtraDisabledInputProps) => {
    return (
      <FormControl as="div" readOnly className={className} ref={innerRef}>
        <input hidden ref={inputRef} name={name} value={value} />
        <span>{getTranslate(`daxtraQualifications.${children}` as any)}</span>
      </FormControl>
    );
  },
)`
  display: flex;
  align-items: center;
  height: 38px;
`;

export const CompanyUpdateQualificationTypesMaps = ({
  id,
  onClose,
  modalConfig,
}: CompanyUpdateQualificationTypesMapsProps & AppModalContentProps) => {
  const dispatch = useAppDispatch();
  const daxtraQualifications = useSelector(companySelectors.getCompanyDaxtraMapping);
  const qualificationTypeList = useSelector(qualificationTypeSelectors.selectAll);

  const { handleSubmit, errors, formState, register, control, setValue } = useForm({
    defaultValues: { items: daxtraQualifications },
  });

  useEffect(() => {
    const newMapping = daxtraQualifications.map((daxtraQualification) => ({
      ...daxtraQualification,
      qualificationId: qualificationTypeList.find(
        (qualificationType) => qualificationType.qualificationId === daxtraQualification.qualificationId,
      )?.qualificationId,
    }));
    setValue('items', newMapping);
  }, [daxtraQualifications, qualificationTypeList, setValue]);

  const onSubmitClickHandler = (data: any) => {
    dispatch(
      exModalConfirmAction({
        id,
        modalResult: data,
      }),
    );
  };

  return (
    <CompanyUpdateQualificationTypesMapsStyled onClose={onClose} content={modalConfig?.content ?? {}}>
      <ExModalBodyStyled>
        <ModalForm onSubmit={handleSubmit(onSubmitClickHandler)} id="test">
          <ExRow>
            <ExColumn custom="6">
              <ListLabel>DaXtra Degree List</ListLabel>
            </ExColumn>
            <ExColumn custom="6">
              <ListLabel>Expedo Qualification Type</ListLabel>
            </ExColumn>
            <ExColumn custom="12">
              {daxtraQualifications.map(({ daxtraQualificationName }, index) => (
                <ExRow key={daxtraQualificationName}>
                  <ExColumn custom="6">
                    <DaXtraDisabledInput inputRef={register} name={`items[${index}].daxtraQualificationName`}>
                      {daxtraQualificationName}
                    </DaXtraDisabledInput>
                  </ExColumn>
                  <ExColumn custom="6">
                    <Controller
                      name={`items[${index}].qualificationId`}
                      control={control}
                      rules={{ required: 'This field is required' }}
                      render={({ value, onChange }) => {
                        return (
                          <FormSelect
                            name={`items[${index}].qualificationId`}
                            label=""
                            required
                            isMulti={false}
                            value={
                              qualificationTypeList.find(
                                (qualificationType) => qualificationType.qualificationId === value,
                              )?.qualificationId
                            }
                            placeholder="Select"
                            onChange={(qualificationTypeModel: QualificationTypeModel) =>
                              onChange(qualificationTypeModel.qualificationId)
                            }
                            errors={get(errors, `items[${index}].qualificationId`)}
                            validated={formState.isSubmitted}
                            options={qualificationTypeList}
                            getOptionLabel={(qualificationType: QualificationTypeModel) =>
                              qualificationType.qualificationName
                            }
                            getOptionValue={(qualificationType: QualificationTypeModel) =>
                              qualificationType.qualificationId
                            }
                            createFilterOuter={createFilter({
                              ignoreCase: true,
                              matchFrom: 'any',
                              stringify: (option) => `${option.label}`,
                              trim: true,
                            })}
                            menuPlacement="auto"
                          />
                        );
                      }}
                    />
                  </ExColumn>
                </ExRow>
              ))}
            </ExColumn>
          </ExRow>
        </ModalForm>
        <ExDialogActions id="sds">
          <ExButton variant="light" onClick={onClose}>
            Cancel
          </ExButton>
          <ExLoaderHandlerWrapper action={updateCompanyQualificationTypeMaps}>
            <ExButton className="ml-3" type="submit" form="test">
              Save
            </ExButton>
          </ExLoaderHandlerWrapper>
        </ExDialogActions>
      </ExModalBodyStyled>
    </CompanyUpdateQualificationTypesMapsStyled>
  );
};
