import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { ApplicationRole } from 'model/api-enums.constants';

import { CompanyInfoItem } from 'pages/Company/components/CompanyInfoItem';

import { rbacConstants } from 'containers/Auth/AuthMatrix';

import { CandidateAvatar } from 'components/CandidateAvatar';
import { IconCloseStyled } from 'components/Icons/IconClose';
import { ExAvatarWrapper } from 'components/ui/ExAvatar';
import { getUserInitials } from 'utils/getUserInitials';
import { useTeamMemberPosition } from 'utils/hooks/job';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { authSelectors } from 'store/auth/auth.selectors';
import { jobsSelectors, JobTeamMember as JobTeamMemberType } from 'store/entities/jobs';
import { jobTeamRemoveMemberModal } from 'store/entities/jobs/job.actions';
import { useSelector } from 'store/rootSelectors';

type JobTeamMemberProps = {
  className?: string;
  userId: string;
  jobId: string;
  isRemovable?: boolean;
  member?: JobTeamMemberType;
};

const IconCloseInnerStyled = styled(IconCloseStyled)`
  width: 15px;
  height: 15px;
  margin-left: auto;
`;

const JobTeamMemberStyled = styled(CompanyInfoItem)``;

const JobTeamMemberDescription = styled.div`
  padding: 0 15px;
`;

const JobTeamMemberName = styled.p`
  margin: 0;
  font-weight: 700;
  line-height: 19px;
`;

const JobTeamMemberPosition = styled.p`
  margin: 0;
  font-weight: 400;
  line-height: 19px;
`;

const useJobTeamMemberState = ({ className, userId, jobId, member, isRemovable = true }: JobTeamMemberProps) => {
  const dispatch = useAppDispatch();
  const teamMemberRedux = useSelector((state) => jobsSelectors.selectTeamMemberById(state, { jobId, userId }));
  const teamMember = member ?? teamMemberRedux;
  const avatar = teamMember?.photoUrl;
  const name = teamMember?.name;
  const isAllowed = useSelector((state) =>
    authSelectors.isFeatureAllowed(state, { feature: rbacConstants.jobs.hiringTeamRemoveMember }),
  );
  const isTeamMemberLineManager = teamMember?.applicationRoles === ApplicationRole.Manager;
  const isRemovableComputed = useMemo(
    () => isRemovable && isAllowed && isTeamMemberLineManager,
    [isRemovable, isAllowed, isTeamMemberLineManager],
  );

  const position = useTeamMemberPosition({ jobId, userId, member });
  const userInitials = getUserInitials(`${name}`);

  const removeMember = useCallback(() => dispatch(jobTeamRemoveMemberModal({ userId })), [dispatch, userId]);

  return {
    className,
    avatar,
    name,
    position,
    isRemovable: isRemovableComputed,
    removeMember,
    userInitials,
  };
};

export const JobTeamMember: React.FC<JobTeamMemberProps> = (props) => {
  const { className, avatar, name, position, isRemovable, removeMember, userInitials } = useJobTeamMemberState(props);

  return (
    <JobTeamMemberStyled className={className}>
      <ExAvatarWrapper>
        <CandidateAvatar src={avatar} initials={userInitials} />
      </ExAvatarWrapper>
      <JobTeamMemberDescription>
        <JobTeamMemberName>{name}</JobTeamMemberName>
        <JobTeamMemberPosition>{position}</JobTeamMemberPosition>
      </JobTeamMemberDescription>
      {isRemovable && <IconCloseInnerStyled onClick={removeMember} />}
    </JobTeamMemberStyled>
  );
};
