import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UIKeys } from 'store/constants';

type CompanyScreeningQuestionListUiSliceState = typeof initialState;

type CompanyScreeningQuestionListUiSliceFilters = typeof initialState.filters;

export const ScreeningQuestionFilterSearchName = 'searchTerm';

export const orderByCreatedOnDesc = 'CreatedOn desc';

const initialFilters = {
  pageNo: 0,
  [ScreeningQuestionFilterSearchName]: '',
  orderBy: orderByCreatedOnDesc,
};

const initialState = {
  filters: initialFilters,
};

export const companyScreeningQuestionListUiSlice = createSlice({
  name: UIKeys.companyScreeningQuestionList,
  initialState,
  reducers: {
    updateState: (draft, { payload }: PayloadAction<Partial<CompanyScreeningQuestionListUiSliceState>>) =>
      Object.assign(draft, payload),
    resetState: () => initialState,
    updateFilters: (draft, { payload }: PayloadAction<Partial<CompanyScreeningQuestionListUiSliceFilters>>) => {
      draft.filters = {
        ...draft.filters,
        ...payload,
      };
    },
    resetFilters: (draft) => ({ ...draft, filters: initialFilters }),
  },
});
