import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { validationSchemaFormSalary } from 'containers/JobForms';
import JobSalaryFormBody from 'containers/JobForms/FormBody/JobSalaryFormBody';
import { modalsSelectors } from 'containers/Modals/store';

import { Form } from 'components';
import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { DialogProps } from 'components/Dialog';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { ExDialog } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { Job, jobsSelectors } from 'store/entities/jobs';
import { jobSalaryEdit } from 'store/entities/jobs/job.actions';
import { exModalConfirmAction } from 'store/modals/modals.actions';
import { useSelector } from 'store/rootSelectors';

const FormStyled = styled(Form)`
  & > *:not(:last-child) {
    margin-bottom: 0;
  }
`;

const DialogActions = styled.div`
  display: flex;
  justify-content: center;
`;

const JobEditSalary: React.FC<any> = ({ id, className, onClose }) => {
  const [show, setShow] = useState(false);
  const jobId = useSelector(modalsSelectors.modalJobId);
  const memo = useMemo(() => jobsSelectors.createGetById(), []);
  const dispatch = useAppDispatch();
  const job = useSelector((state) => memo(state, jobId!));
  const submitHandler = (data: Partial<Job>) => {
    dispatch(exModalConfirmAction({ id, modalResult: { urlParams: { jobId: jobId! }, data: data.salary } }));
  };

  const content: DialogProps['content'] = {
    title: 'Edit Job Salary',
    withTitle: true,
  };

  useEffect(() => {
    if (!job || !jobId) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [job, jobId]);

  if (!show) {
    return null;
  }

  return (
    <ExDialog onClose={onClose} content={content} className={className}>
      <FormStyled
        onSubmit={submitHandler}
        validationSchema={validationSchemaFormSalary}
        defaultValues={job}
        className="w-100"
      >
        <JobSalaryFormBody jobId={jobId!} />
        <DialogActions>
          <ExButton variant="light" onClick={onClose}>
            Cancel
          </ExButton>
          <ExLoaderHandlerWrapper action={jobSalaryEdit}>
            <ExButton className="ml-3" variant="primary" type="submit">
              Update
            </ExButton>
          </ExLoaderHandlerWrapper>
        </DialogActions>
      </FormStyled>
    </ExDialog>
  );
};

export default styled(JobEditSalary)`
  max-width: 800px;
  width: 100%;
`;
