import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import difference from 'lodash/difference';

import { QueryReturnValue } from 'api-client/axiosBaseQueryTypes';
import { replaceParamsInUrl } from 'api-client/utils';
import { JobInterviewTemplate } from 'api-endpoints/job-interview-template/job-interview-template.endpoints';

import { ExJobInterviewTemplate } from 'model';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';

import { getTranslate } from 'utils/i18utils';

import { emptyApi } from 'store/entities/emptyApi';
import { Job } from 'store/entities/jobs/models';
import { RootState } from 'store/rootReducer';

type JobInterviewTemplateListParams = {
  jobId: Job['jobId'];
  includePipelineTemplates?: boolean;
};

type UpdateJobInterviewTemplateParams = {
  jobId: Job['jobId'];
  entities: ExJobInterviewTemplate[];
  isQuiet?: boolean;
};

const extendedJobInterviewTemplateApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    jobInterviewTemplateList: builder.query<ExJobInterviewTemplate[], JobInterviewTemplateListParams>({
      query: ({ jobId, includePipelineTemplates }) => ({
        url: replaceParamsInUrl(JobInterviewTemplate.GetJobInterviewTemplatesFunc, { jobId }),
        method: 'GET',
        params: { includePipelineTemplates },
      }),
    }),
    updateJobInterviewTemplate: builder.mutation<void, UpdateJobInterviewTemplateParams>({
      queryFn: async (args, { getState, dispatch }, extra, baseQuery) => {
        const jobId = args.jobId;
        const newJobInterviewList = args.entities.map((interviewTemplate) => interviewTemplate.interviewTemplateId);

        const state = getState() as RootState;
        const { data } = enhancedJobInterviewTemplateApi.endpoints.jobInterviewTemplateList.select({ jobId })(state);
        const originalJobInterviewList = data?.map((interviewTemplate) => interviewTemplate.interviewTemplateId) || [];

        const assignInterviewTemplatesIds = difference(newJobInterviewList, originalJobInterviewList);
        const deleteInterviewTemplatesIds = difference(originalJobInterviewList, newJobInterviewList);

        const promises: MaybePromise<QueryReturnValue>[] = [];

        if (assignInterviewTemplatesIds.length) {
          const assignInterviewTemplates = baseQuery({
            method: 'POST',
            url: replaceParamsInUrl(JobInterviewTemplate.AssignInterviewTemplatesFunc, { jobId }),
            data: { interviewTemplates: assignInterviewTemplatesIds },
          });
          promises.push(assignInterviewTemplates);
        }

        if (deleteInterviewTemplatesIds.length) {
          const deleteInterviewTemplates = baseQuery({
            method: 'DELETE',
            url: replaceParamsInUrl(JobInterviewTemplate.DeleteInterviewTemplatesFunc, { jobId }),
            data: { interviewTemplates: deleteInterviewTemplatesIds },
          });
          promises.push(deleteInterviewTemplates);
        }

        await Promise.all(promises);

        if (!args?.isQuiet) {
          const message = getTranslate('job_interview_template_updated_success');
          dispatch(alertsEffects.showSuccess({ message }));
        }

        return { data: undefined };
      },
    }),
  }),
});

export const enhancedJobInterviewTemplateApi = extendedJobInterviewTemplateApi.enhanceEndpoints({
  addTagTypes: ['JobInterviewTemplate'],
  endpoints: {
    jobInterviewTemplateList: {
      providesTags: [{ type: 'JobInterviewTemplate', id: 'LIST' }],
    },
    updateJobInterviewTemplate: {
      invalidatesTags: [{ type: 'JobInterviewTemplate', id: 'LIST' }],
    },
  },
});

export const { useJobInterviewTemplateListQuery, useUpdateJobInterviewTemplateMutation } =
  enhancedJobInterviewTemplateApi;
