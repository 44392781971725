import { compose, createSelector } from '@reduxjs/toolkit';
import { ChartDataSets } from 'chart.js';

import { Analytics } from 'model';

import { isTaskActiveMake } from 'containers/Loader/store/selectors';

import { totalReducerObjectValue } from 'utils/funcs';

import { SummaryPerSourceElement } from 'store/entities/jobs/models';
import { RootState } from 'store/rootReducer';
import { WIDGET_CANDIDATE_SOURCE_FETCH } from 'store/ui/widgets/widgets.constants';

const domain = (state: RootState) => state.ui.widgets.CandidateSources;

export const isRequestCandidateSourcesTaskActive = isTaskActiveMake(WIDGET_CANDIDATE_SOURCE_FETCH);

const candidateSourcesDataSelector = createSelector(domain, (state) => state.applicantsPerSourceFigures);

export const candidateSourcesSelector = createSelector(
  candidateSourcesDataSelector,
  (
    data,
  ): {
    data: Chart.ChartData;
    totalCount: number;
    options: Chart.ChartOptions;
  } => {
    const totalCount = data.reduce(totalReducerObjectValue, 0);
    const dataCount = data.length;
    const labels = Array.from({ length: dataCount }, (_, index) => index);
    return {
      data: {
        datasets: data.reduce<ChartDataSets[]>(
          (acc, { key: label, value }) =>
            acc.concat({
              borderColor: 'white',
              data: [value, totalCount - value],
              hoverBorderColor: 'white',
              label,
            }),
          [],
        ),
        labels,
      },
      options: {
        cutoutPercentage: 100 - 12 * dataCount,
        elements: {
          center: {
            count: totalCount,
            fontSize: 25,
            text: 'Total Talents',
          },
        } as any,
      },
      totalCount,
    };
  },
);

type ChartDoughnutDataProps = {
  total: number;
  labels: string[];
  data: number[];
};

export const chartDataDoughnutDataBuilder = (
  chartData: ChartDoughnutDataProps,
): {
  data: Chart.ChartData;
  totalCount: number;
  options: Chart.ChartOptions;
} => ({
  data: {
    datasets: [
      {
        data: chartData.data,
        borderWidth: 0,
      },
    ],
    labels: chartData.labels,
  },
  options: {
    elements: {
      center: {
        count: chartData.total,
        fontSize: 25,
        text: 'Total Talents',
      },
    } as any,
  },
  totalCount: chartData.total,
});

export const convertSummaryPerSourceElementToAnalytics = (data: SummaryPerSourceElement[]): Analytics<string>[] =>
  data.map((item) => ({
    key: item.key,
    value: item.value.reduce(totalReducerObjectValue, 0),
  }));

export const prepareChartDataForDoughnut = (data: Analytics<string>[]): ChartDoughnutDataProps => {
  return data.reduce<ChartDoughnutDataProps>(
    (acc, item) => {
      acc.total += item.value;
      acc.labels.push(item.key);
      acc.data.push(item.value);

      return acc;
    },
    { total: 0, labels: [], data: [] },
  );
};

const composed = compose(chartDataDoughnutDataBuilder, prepareChartDataForDoughnut);

export const candidateSourcesChartDoughnutDataSelector = createSelector(candidateSourcesDataSelector, composed);
