import React, { useCallback, useMemo } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { JobBoard } from 'store/entities/job-boards';

interface JobBoardCardProps {
  className?: string;
  jobBoard: JobBoard;
  withLink?: boolean;
}

const ApplicantListCandidateRow: React.FC<JobBoardCardProps> = ({ jobBoard, className, withLink = true }) => {
  const match = useRouteMatch();
  const link = React.useRef<any>(null);
  const [show, setShow] = React.useState(false);
  const modalPath = useMemo(() => [match.url, jobBoard.jobBoardId].join('/'), [jobBoard.jobBoardId, match.url]);
  const checkEllipsis: React.MouseEventHandler<HTMLAnchorElement> = useCallback(() => {
    if (link?.current?.offsetWidth < link?.current?.scrollWidth) {
      setShow(true);
    }
  }, []);
  const content = useMemo(
    () =>
      withLink ? (
        <Link to={modalPath} className="job-board-row__name" ref={link} onMouseEnter={checkEllipsis}>
          {jobBoard.name}
        </Link>
      ) : (
        <span className="job-board-row__name">{jobBoard.name}</span>
      ),
    [checkEllipsis, jobBoard.name, modalPath, withLink],
  );
  return (
    <div className={className}>
      {withLink ? (
        <Link to={modalPath} className="job-board-row__logo">
          <img src={jobBoard.logoUrl} alt="logo" />
        </Link>
      ) : (
        <div className="job-board-row__logo">
          <img src={jobBoard.logoUrl} alt="logo" />
        </div>
      )}
      {show ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip className="expedo-tooltip" id={`tooltip-${jobBoard.name}`}>
              {jobBoard.name}
            </Tooltip>
          }
        >
          {content}
        </OverlayTrigger>
      ) : (
        content
      )}
    </div>
  );
};

export default styled(ApplicantListCandidateRow)`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .job-board-row__logo {
    margin-right: 42px;
    flex: 0 0 62px;
    height: 24px;

    img {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }

  .job-board-row__name {
    display: inline-block;
    color: ${(props) => props.theme.colors.blackHeadings};
    font-weight: ${(props) => props.theme.fonts.heading.fontWeight};
    margin-bottom: 0;
    width: 195px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
