import styled from 'styled-components';

export const CardIndicator = styled.div`
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #eeeeee;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;

const CardWrapper = styled.div`
  padding-left: 30px;
  position: relative;
  &:first-child {
    &::after {
      display: none;
    }
    ${CardIndicator} {
      background-color: ${(props) => props.theme.colors.primary};
    }
  }

  &:last-child {
    &::before {
      display: none;
    }
  }

  &::after,
  &::before {
    display: block;
    content: ' ';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #eeeeee;
    left: 5px;
    top: 53%;
  }

  &::after {
    top: -50%;
  }
`;

const CardContent = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.bodyBackgroundColor};
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`;

const CardHeader = styled.div``;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.3;
  margin-top: 6px;
`;

const WorkExperiencePosition = styled.span`
  font-weight: bold;
`;

const WorkExperienceDuration = styled.span``;

const WorkExperienceDates = styled.span``;

const WorkExperienceCompany = styled.span``;

const WorkExperienceAddress = styled.span``;

export {
  CardBody,
  CardContent,
  CardWrapper,
  CardHeader,
  WorkExperienceAddress,
  WorkExperiencePosition,
  WorkExperienceDuration,
  WorkExperienceDates,
  WorkExperienceCompany,
};
