import { createSelector } from '@reduxjs/toolkit';
import format from 'date-fns/format';

import { CalendarTargetDate } from 'containers/Calendar/state/calendar.model';
import { calendarUiAdapter } from 'containers/Calendar/state/calendar-ui/calendar-ui.reducer';

import { RootState } from 'store/rootReducer';

const calendarDomain = (state: RootState) => state.calendar;
const calendarUiDomain = createSelector(calendarDomain, (domain) => domain.ui);

const selectView = createSelector(calendarUiDomain, (state) => state.view);
const selectViewTarget = createSelector(selectView, (view) => {
  return {
    week: CalendarTargetDate.Week,
    month: CalendarTargetDate.Month,
    year: CalendarTargetDate.Year,
  }[view];
});

const selectDate = createSelector(calendarUiDomain, (state) => state.date);
const selectTargetDate = createSelector(selectDate, (date) => {
  return format(new Date(date), 'yyyy-MM-dd');
});

const selectFilters = createSelector(calendarUiDomain, (state) => state.filters);

export const calendarUiSelectors = {
  ...calendarUiAdapter.getSelectors(calendarUiDomain),
  selectView,
  selectViewTarget,
  selectDate,
  selectTargetDate,
  selectFilters,
};
