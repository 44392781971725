import * as jobApi from 'api-endpoints/job';

import { JobStatus } from 'model/api-enums.constants';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { JobListState } from 'containers/JobLists/store/models';
import jobListsSlice from 'containers/JobLists/store/reducers';
import * as jobListSelectors from 'containers/JobLists/store/selectors';

import { ViewMode } from 'store/constants';
import { JobBelongsTo } from 'store/entities/jobs/models';
import { AppDispatch, AppThunk } from 'store/types';

const jobListActions = jobListsSlice.actions;

export const loadJobsTaskId = 'loadJobsTask';

export const changeViewMode = (listId: JobListState['id'], viewMode: ViewMode) => async (dispatch: AppDispatch) => {
  dispatch(jobListActions.updateViewMode({ id: listId, viewMode }));
  dispatch(jobListActions.clearItems({ id: listId }));
};

export const getJobsCountByStatus =
  (listId: JobListState['id']): AppThunk =>
  async (dispatch, getState) => {
    const params = jobListSelectors.getListParams(getState(), { listId });
    if (params.id === JobBelongsTo.dashboard) {
      params.filters = {
        ...params.filters,
        status: JobStatus.open,
        pipelineId: undefined,
      };
    }
    const { data } = await jobApi.getJobsCountByStatus({ params });
    if (data) {
      dispatch(jobListActions.update({ id: listId, countByStatus: data } as Partial<JobListState>));
    }
  };

export const jobAutocomplete =
  (searchTerm: string): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    const jobStatus = jobListSelectors.getFilters(getState(), { listId: JobBelongsTo.main })?.status;
    const { message, data } = await jobApi.jobAutocomplete({ params: { searchTerm, jobStatus } });
    if (message) {
      return dispatch(alertsEffects.showError({ message }));
    }
    return data;
  };

export const jobJobBoardsAutocomplete =
  (jobId: string) =>
  (searchTerm: string): AppThunk<Promise<any>> =>
  async (dispatch) => {
    const { message, data } = await jobApi.jobJobBoardsAutocomplete({
      urlParams: { jobId },
      params: { searchTerm },
    });

    if (message) {
      dispatch(alertsEffects.showError({ message }));
      return [];
    }

    return data;
  };
