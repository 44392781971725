import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as jobBoardApi from 'api-endpoints/job-board';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { invokeApiCall, ReturnData } from 'utils/sagas';

import { SortDirections } from 'store/constants';
import { jobBoardActions, JobBoardStatus } from 'store/entities/job-boards';

import * as fromJobBoardActions from './job-board.actions';
import { mapJobBoards } from './mappers';
import { JobBoardListState } from './models';
import { jobBoardListActions, jobBoardListSelectors } from '.';

function* jobBoardListResetFiltersWorker({ payload }: ReturnType<typeof fromJobBoardActions.jobBoardListResetFilters>) {
  const id = payload.listId;
  yield put(
    jobBoardListActions.updateFilters({
      id,
      filters: {
        industries: [],
        countryCodes: [],
        name: undefined,
      },
    }),
  );
}

function* jobBoardsFetch(action: ReturnType<typeof jobBoardActions.fetch>) {
  const { listId } = action.payload;
  yield call(startLoader, action);

  const { filters, pageNo, pageSize, sortMode }: ReturnType<typeof jobBoardListSelectors.getListParams> = yield select(
    jobBoardListSelectors.getListParams,
    {
      id: listId,
    },
  );
  const params = {
    pageNo,
    pageSize,
    ...(filters.name && { searchTerm: filters.name }),
    ...(filters.countryCodes?.length && {
      countryCodes: filters.countryCodes.map((countryCode) => countryCode.code.expedoCode2),
    }),
    ...(sortMode?.orderBy && {
      orderBy: sortMode.orderBy,
    }),
    ...(sortMode?.orderDir && {
      orderDirection: sortMode.orderDir === SortDirections.desc ? 'Descending' : 'Ascending',
    }),
  };

  const apiMethods: Record<keyof typeof JobBoardStatus, typeof jobBoardApi.getJobBoardsSubscribed> = {
    subscribed: jobBoardApi.getJobBoardsSubscribed,
    unsubscribed: jobBoardApi.getJobBoardsUnsubscribed,
  };

  const apiMethod = apiMethods[JobBoardStatus[filters.status] as keyof typeof JobBoardStatus];

  const { data, message, errorData }: ReturnData<typeof apiMethod> = yield invokeApiCall(apiMethod, {
    params,
  });

  if (!message && !errorData && data) {
    const { jobBoardIds, jobBoards } = mapJobBoards(data.items || []);
    yield put(jobBoardActions.upsertMany(jobBoards));
    yield put(
      jobBoardListActions.fetchSuccess({
        id: listId,
        items: jobBoardIds,
        pageCount: data.pageCount,
        totalItemsCount: data?.totalItemsCount,
      }),
    );
  }

  yield call(stopLoader, action);
}

function* jobBoardListChangeViewModeWorker(action: ReturnType<typeof fromJobBoardActions.jobBoardListChangeViewMode>) {
  const { listId, viewMode } = action.payload;

  const { viewMode: currentViewMode }: JobBoardListState = yield select(jobBoardListSelectors.getListById, {
    id: listId,
  });

  if (viewMode !== currentViewMode) {
    yield put(jobBoardListActions.updateViewMode({ id: listId, viewMode }));
    yield put(jobBoardListActions.clearItems({ id: listId }));
  }
}

export function* jobBoardListsSagas() {
  yield takeEvery(fromJobBoardActions.jobBoardListResetFilters, jobBoardListResetFiltersWorker);
  yield takeEvery(fromJobBoardActions.jobBoardListChangeViewMode, jobBoardListChangeViewModeWorker);
  yield takeLatest(jobBoardActions.fetch, jobBoardsFetch);
}
