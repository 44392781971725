import React, { CSSProperties, Ref, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Transition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import { CustomCheckbox } from 'components/Form/CustomCheckbox';
import { ExButton } from 'components/ui/ExButton';
import { ExCard } from 'components/ui/ExCard';
import { ExDropDownCheckableProps } from 'components/ui/ExDropDownCheckable/ExDropDownCheckableProps';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { fadeInAnimation } from 'utils/styled/FadeTransition';

const ExDropDownCheckableStyled = styled(ExButton)``;

export const angleCss = css`
  content: ' ';
  top: -5px;
  position: absolute;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.15);
  border-width: 0 0 1px 1px;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 80px;
  right: 40px;
  border-radius: 20px;
  box-shadow: 0 0 10px #eee;
  border-color: ${(props) => props.theme.colors.defaultLight};
  z-index: 1040;

  &::before {
    ${angleCss}
    top: -8px;
    right: 15px;
    border-width: 0 8px 8px;
    border-color: transparent transparent ${(props) => props.theme.border.borderColor} transparent;
  }

  &::after {
    ${angleCss}
    top: -6px;
    right: 16px;
    border-width: 0 7px 7px;
    border-color: transparent transparent ${(props) => props.theme.colors.white} transparent;
  }
`;

const Item = styled.div`
  display: flex;
  white-space: nowrap;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

const ExCardStyled = styled(ExCard)``;

const DropDown = (
  {
    className,
    items,
    onChange,
    styles,
  }: {
    className?: string;
    items: ExDropDownCheckableProps['items'];
    onChange: ExDropDownCheckableProps['onChange'];
    styles: CSSProperties;
  },
  ref: Ref<HTMLDivElement>,
) => {
  const itemClickHandler = (item: ExDropDownCheckableProps['items'][number]) => (isChecked: boolean) => {
    const newItems = [...items];
    const idx = newItems.indexOf(item);
    newItems.splice(idx, 1, { ...item, isChecked });
    onChange(newItems);
  };

  return createPortal(
    <Wrapper className={className} ref={ref} style={styles}>
      <ExCardStyled>
        {items.map((item) => (
          <Item key={item.fieldName}>
            <CustomCheckbox checked={item.isChecked} onChange={itemClickHandler(item)}>
              {item.label}
            </CustomCheckbox>
          </Item>
        ))}
      </ExCardStyled>
    </Wrapper>,
    document.body,
  );
};

const DropDownWithRef = React.forwardRef(DropDown);

const DropDownFaded = fadeInAnimation(DropDownWithRef, 0.1);

const useExDropDownCheckableState = ({ className, items, onChange, prependIcon }: ExDropDownCheckableProps) => {
  const [styles, setStyles] = React.useState<CSSProperties>({});
  const [showPopover, setShowPopover] = React.useState(false);

  const popoverRef = useRef(null);
  const triggerRef = useRef(null);

  const togglerClickHandler: React.MouseEventHandler = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setStyles({
      top: 0,
      left: rect.right,
      right: 'auto',
      transform: `translate(-${rect.width + 24}px,${rect.y + rect.height + 10}px)`,
    });
    setShowPopover((prev) => !prev);
  };

  useOnClickOutside([popoverRef, triggerRef], (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setShowPopover(false);
  });

  return {
    className,
    popoverRef,
    showPopover,
    togglerClickHandler,
    triggerRef,
    items,
    onChange,
    styles,
    prependIcon,
  };
};

export const ExDropDownCheckable: React.FC<ExDropDownCheckableProps> = ({ children, ...props }) => {
  const { className, popoverRef, showPopover, togglerClickHandler, triggerRef, items, onChange, styles, prependIcon } =
    useExDropDownCheckableState(props);

  return (
    <>
      <ExDropDownCheckableStyled
        ref={triggerRef}
        prependIcon={prependIcon}
        variant="light"
        className={className}
        onClick={togglerClickHandler}
      >
        {children}
      </ExDropDownCheckableStyled>
      <Transition in={showPopover} timeout={100} mountOnEnter unmountOnExit>
        {(state) => <DropDownFaded ref={popoverRef} state={state} items={items} onChange={onChange} styles={styles} />}
      </Transition>
    </>
  );
};
