import { Action } from 'redux';
import { call, put, select } from 'redux-saga/effects';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { getTranslate } from 'utils/i18utils';

import * as fromCompanyActions from 'store/company/company.actions';
import { companySelectors } from 'store/company/company.selectors';
import type { CompanyInfo } from 'store/company/company.types';
import {
  companyUpdateConfirmModal,
  isDefaultInList,
  isListEmpty,
  makeDefaultFirstItemOrOther,
  prepareRequestData,
  updateCompanyUtil,
} from 'store/company/company.utils';
import { ModalGeneralResult } from 'store/modals/modals.interfaces';
import { modalSagaWorker } from 'store/modals/modals.sagas';

const successMessagePath = 'company.currencies.update.success';

export function* companyUpdateCurrenciesSaga(action: Action) {
  /**
   * This variable needs to store modal id in case of network error to restart
   * saga with existed modal instead of rendering the new one.
   */
  let modalId: string | undefined;

  while (true) {
    const result: ModalGeneralResult<{ entities: CompanyInfo['currencies'] }> = yield call(modalSagaWorker, {
      ...(modalId ? { id: modalId } : {}),
      modalType: ModalsTypeKey.companyUpdateCurrencies,
      modalConfig: {
        content: {
          title: 'Select Currency',
          withTitle: true,
        },
      },
    });

    if (result.cancel || !result.confirm) {
      return;
    }

    modalId = result.confirm.payload.id!;

    const { modalResult } = result.confirm.payload;
    yield call(startLoader, action);
    const currenciesListIsEmpty = yield call(isListEmpty, modalResult?.entities);

    /**
     * If currency list is empty will show alert and restart saga
     */
    if (currenciesListIsEmpty) {
      yield put(
        alertsEffects.showWarning({
          message: getTranslate('company.currencies.update.emptyCurrencyList'),
        }),
      );
      yield call(stopLoader, action);
      continue;
    }

    let success = false;

    const entities = result.confirm.payload.modalResult?.entities ?? [];

    const entitiesHasDefaultItem = isDefaultInList(entities, 'main');

    const requestData: CompanyInfo | null = yield call(
      prepareRequestData,
      'currencies',
      {
        mapFunction: !entitiesHasDefaultItem ? makeDefaultFirstItemOrOther : undefined,
      },
      entities,
    );

    if (requestData) {
      success = yield call(updateCompanyUtil, modalId, requestData, successMessagePath, 'currencies');
    }

    yield call(stopLoader, action);
    if (success) {
      break;
    }
  }
}

export function* changeDefaultCompanyCurrency(
  action: ReturnType<typeof fromCompanyActions.changeDefaultCompanyCurrency>,
) {
  const company: ReturnType<typeof companySelectors.getCompanyInfo> = yield select(companySelectors.getCompanyInfo);
  if (!company) {
    return;
  }
  let modalId: string | undefined;

  while (true) {
    const result: ModalGeneralResult = yield call(
      companyUpdateConfirmModal,
      modalId,
      'You confirm change Default Currency?',
    );
    if (result.cancel || !result.confirm) {
      return;
    }
    yield call(startLoader, action);
    modalId = result.confirm.payload.id!;
    const { currencyId } = action.payload;

    let success = false;

    const newCompanyInfo: CompanyInfo | null = yield call(prepareRequestData, 'currencies', {
      mapFunction: (currency) => ({ ...currency, main: currency.currencyId === currencyId }),
    });

    if (newCompanyInfo) {
      success = yield call(updateCompanyUtil, modalId, newCompanyInfo, successMessagePath, 'currencies');
    }

    yield call(stopLoader, action);
    if (success) {
      break;
    }
    yield call(stopLoader, action);
  }
}

export function* removeCompanyCurrency(action: ReturnType<typeof fromCompanyActions.removeCompanyCurrency>) {
  const company: ReturnType<typeof companySelectors.getCompanyInfo> = yield select(companySelectors.getCompanyInfo);
  if (!company) {
    return;
  }
  let modalId: string | undefined;

  while (true) {
    const result: ModalGeneralResult = yield call(companyUpdateConfirmModal, modalId, 'You confirm remove Currency?');

    if (result.cancel || !result.confirm) {
      return;
    }
    yield call(startLoader, action);
    modalId = result.confirm.payload.id!;

    const { currencyId } = action.payload;

    let success = false;

    const newCompanyInfo: CompanyInfo | null = yield call(prepareRequestData, 'currencies', {
      filterFunction: (currency) => currency.currencyId !== currencyId,
    });

    if (newCompanyInfo) {
      success = yield call(updateCompanyUtil, modalId, newCompanyInfo, successMessagePath, 'currencies');
    }

    yield call(stopLoader, action);
    if (success) {
      break;
    }
  }
}
