import React from 'react';
import styled from 'styled-components/macro';

export interface CompanyInfoItemProps {
  className?: string;
  children?: React.ReactNode;
  style?: any;
  onClick?: (x: any) => void;
}

export const CompanyInfoItemStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.bodyBackgroundColor};
  border-radius: ${({ theme }) => theme.border.borderRadiusSm};
  display: flex;
  padding: 15px 20px;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const CompanyInfoItem = React.forwardRef<HTMLDivElement, CompanyInfoItemProps>(
  ({ className, children, ...rest }, ref) => {
    return (
      <CompanyInfoItemStyled ref={ref} className={className} {...rest}>
        {children}
      </CompanyInfoItemStyled>
    );
  },
);

export const CompanyInfoItemClickable = styled(CompanyInfoItem)`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.border.borderRadiusSm};
`;
