const {
  REACT_APP_AVATAR_UPLOAD_FORMAT = 'image/jpeg',
  REACT_APP_AVATAR_UPLOAD_WIDTH = 120,
  REACT_APP_AVATAR_UPLOAD_HEIGHT = 120,
} = process.env;

export const imagesConfig = {
  idibuImageBasicUrl: 'https://www.idibu.com/images/stories/Portal_logos/',
  AvatarUploadFormat: REACT_APP_AVATAR_UPLOAD_FORMAT,
  AvatarUploadWidth: Number(REACT_APP_AVATAR_UPLOAD_WIDTH),
  AvatarUploadHeight: Number(REACT_APP_AVATAR_UPLOAD_HEIGHT),
};
