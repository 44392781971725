import React from 'react';

import { Modal } from 'containers/Modals';

import { openedModals } from 'store/modals/modals.selectors';
import { useSelector } from 'store/rootSelectors';

export interface ExModalsProps {}

export const ExModals: React.FC<ExModalsProps> = () => {
  const modals = useSelector(openedModals);

  if (!modals.length) {
    return null;
  }
  return React.createElement(
    React.Fragment,
    null,
    modals.map((modal, index) =>
      React.createElement(Modal, {
        key: modal.id,
        ...modal,
        hideBackDrop: index !== modals.length - 1,
      }),
    ),
  );
};
