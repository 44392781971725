import styled from 'styled-components';

import { ExLoaderHandlerWrapper, ExLoaderHandlerWrapperStrict } from 'components/Common/LoaderHandlerWrapper';
import { ExButton } from 'components/ui/ExButton';
import { ExDialogActions } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { hiringPipelineStageActions } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.reducer';
import { HiringPipelineStage } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.types';
import { ModalStepProps } from 'store/modals/config';
import { wizardBackward } from 'store/modals/modals.actions';

import { ExModalBodyStyledWide } from './ExModalComponents';

const MessageWrapper = styled.div`
  min-height: 100px;
  max-width: 80%;
  margin: auto;
  text-align: center;
`;

function useHiringPipelineStageRemoveModalState({
  id,
  modalProps,
}: ModalStepProps & { modalProps: { hiringPipelineStage: HiringPipelineStage } }) {
  const dispatch = useAppDispatch();
  const { hiringPipelineStage } = modalProps;
  const { pipelineId, pipelineStageId, name } = hiringPipelineStage;
  const onBackClickHandler = () => dispatch(wizardBackward({ id }));
  const onConfirmClickHandler = () =>
    dispatch(hiringPipelineStageActions.prepareRemove({ pipelineId, pipelineStageId }));

  return { onBackClickHandler, onConfirmClickHandler, name };
}

export const HiringPipelineStageRemoveModal = (
  props: ModalStepProps & { modalProps: { hiringPipelineStage: HiringPipelineStage } },
) => {
  const { onBackClickHandler, onConfirmClickHandler, name } = useHiringPipelineStageRemoveModalState(props);

  return (
    <ExModalBodyStyledWide>
      <MessageWrapper>You confirm remove {name}?</MessageWrapper>
      <ExDialogActions>
        <ExButton variant="light" onClick={onBackClickHandler}>
          Back
        </ExButton>
        <ExLoaderHandlerWrapperStrict>
          <ExLoaderHandlerWrapper>
            <ExButton className="ml-3" onClick={onConfirmClickHandler}>
              Confirm
            </ExButton>
          </ExLoaderHandlerWrapper>
        </ExLoaderHandlerWrapperStrict>
      </ExDialogActions>
    </ExModalBodyStyledWide>
  );
};
