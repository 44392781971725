import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { CalendarEventDto } from 'containers/Calendar/state/calendar.model';

import { adapterHelper } from 'utils/reducer/adapter-helper';

const calendarJobsAdapter = createEntityAdapter<CalendarEventDto>({
  selectId: (job) => job.id,
});

const calendarJobsInitialState = calendarJobsAdapter.getInitialState({});

const calendarJobsSlice = createSlice({
  name: 'jobs-events',
  initialState: calendarJobsInitialState,
  reducers: {
    ...adapterHelper(calendarJobsAdapter),
  },
});

const calendarJobsReducer = calendarJobsSlice.reducer;

export { calendarJobsReducer, calendarJobsSlice, calendarJobsAdapter, calendarJobsInitialState };
