import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

import { SearchFormInput } from 'components/FormInput/SearchFormInput';

type TableSearchInputProps = {
  className?: string;
  value?: string;
  onChange?: (fieldValue: string, fieldName?: string) => void;
  name: string;
  placeholder?: string;
  onChangeDelay?: number;
  searchHint?: string;
};

const TableSearchInputHint = styled.div`
  display: none;
  position: absolute;
  left: 0;
  bottom: 105%;
  padding: 2px 10px;
  max-width: 80vw;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 10%) 0 5px 5px;
  background-color: var(--white);
  pointer-events: none;
  color: var(--dark);
  line-height: 24px;
  font-size: 14px;
  white-space: nowrap;
`;

const TableSearchInputWrapper = styled.div`
  position: relative;
  width: 200px;

  &:focus-within {
    ${TableSearchInputHint} {
      display: block;
    }
  }
`;

const useTableSearchInputState = ({
  className,
  onChange,
  value,
  name,
  placeholder = 'Search',
  onChangeDelay,
  searchHint = '',
}: TableSearchInputProps) => {
  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const onChangeCb = useMemo(() => {
    if (onChange && onChangeDelay) {
      return debounce((fieldValue, fieldName) => onChange(fieldValue, fieldName), onChangeDelay);
    }

    return onChange;
  }, [onChangeDelay, onChange]);

  const onChangeInner = useCallback(
    (event) => {
      const newValue = event.target.value || '';

      setInnerValue(newValue);

      if (onChangeCb) {
        onChangeCb(newValue, name);
      }
    },
    [onChangeCb, name],
  );

  const isSearchHintVisible = Boolean(searchHint && !innerValue);

  return {
    className,
    name,
    innerValue,
    onChangeInner,
    placeholder,
    searchHint,
    isSearchHintVisible,
  } as const;
};

export const TableSearchInput: React.FC<TableSearchInputProps> = (props) => {
  const { className, name, innerValue, onChangeInner, placeholder, searchHint, isSearchHintVisible } =
    useTableSearchInputState(props);

  return (
    <TableSearchInputWrapper className={className}>
      <SearchFormInput
        type="search"
        name={name}
        value={innerValue}
        label=""
        placeholder={placeholder}
        onChange={onChangeInner}
      />
      {isSearchHintVisible && <TableSearchInputHint>{searchHint}</TableSearchInputHint>}
    </TableSearchInputWrapper>
  );
};
