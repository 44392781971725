import styled from 'styled-components';

import { ExButton } from 'components/ui/ExButton/ExButton';

export const ExFiltersBarClearButton = styled(ExButton)``;

export const ExFiltersBarWrapper = styled.div`
  display: flex;

  ${ExFiltersBarClearButton} {
    margin-left: 20px;
  }
`;
