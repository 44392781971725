import Form from 'react-bootstrap/Form';
import type { StylesConfig } from 'react-select';
import styled from 'styled-components/macro';

import { FormSelect } from 'components/FormSelect';
import { IconCross } from 'components/Icons/IconCross';

export type ExSalaryInputWithCountryProps = {
  className?: string;
  label?: string;
  required?: boolean;
};

export const ExSalaryInputWithCountryStyled = styled.div`
  position: relative;
`;

export const FormGroupStyled = styled(Form.Group)`
  position: relative;
`;

export const FormSelectStyled = styled(FormSelect)`
  margin-bottom: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 10;
`;

export const reactCountryFlagStyles = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  display: 'block',
};

export const InputStyled = styled(Form.Control)`
  border-radius: 99px;
  padding-left: 70px !important;
  padding-right: 40px !important;
`;

const ClearButton = styled.button.attrs({ type: 'button' })`
  color: #ccc;
  background: none;
  border: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0;
  height: 40px;
  width: 40px;

  &:hover {
    color: #999;
  }
`;

type ClearFormProps = {
  show: boolean;
  method: () => void;
};

export const ClearForm = ({ show, method }: ClearFormProps) => {
  if (!show) {
    return null;
  }

  return (
    <ClearButton onClick={method}>
      <IconCross />
    </ClearButton>
  );
};

export const overrideStyles: StylesConfig<any, any> = {
  control: () => ({
    display: 'flex',
    paddingLeft: '10px',
    height: '100%',
  }),
  container: () => ({
    height: '100%',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    width: '20px',
    flexWrap: 'nowrap',
  }),
  singleValue: () => ({
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    overflow: 'hidden',
    flexShrink: 0,
  }),
  menu: (base) => ({
    ...base,
    width: '200px',
  }),
};
