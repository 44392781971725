import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ErrorDTO } from 'model/api-errors.constants';

import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import { ExModalBodyStyled, ExModalBodyStyledWide } from 'containers/Modals/ModalsContent/Company/ExModalComponents';

import { ExLoaderHandlerWrapperStrict } from 'components/Common/LoaderHandlerWrapper';
import { FormInput } from 'components/FormInput';
import { ExButton } from 'components/ui/ExButton';
import { ExDialogActions } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { hiringPipelineActions } from 'store/entities/hiring-pipelines/hiring-pipelines.reducer';
import type { HiringPipeline } from 'store/entities/hiring-pipelines/hiring-pipelines.types';
import { exModalCancelAction } from 'store/modals/modals.actions';
import { ExModal } from 'store/modals/modals.interfaces';

type HiringPipelineEditProps = ExModal &
  AppModalContentProps & {
    modalProps: { hiringPipeline?: HiringPipeline; errorData?: ErrorDTO; pipelineId: HiringPipeline['pipelineId'] };
  };

type HiringPipelineFormProps = Partial<HiringPipeline>;

const hiringPipelineEditSchema: yup.SchemaOf<Pick<HiringPipelineFormProps, 'name'>> = yup.object({
  name: yup.string().trim().required().min(2).max(50).label('Pipeline name'),
});

function getConfirmButtonText(modalProps: HiringPipelineEditProps['modalProps']) {
  switch (true) {
    case Boolean(modalProps?.hiringPipeline):
      return 'Update';
    case Boolean(modalProps?.pipelineId):
      return 'Duplicate';
    default:
      return 'Add';
  }
}

function useHiringPiplineEditNameModalState({ id, modalProps }: HiringPipelineEditProps) {
  const dispatch = useAppDispatch();
  const { handleSubmit, errors, formState, register, setError } = useForm<HiringPipelineFormProps>({
    resolver: yupResolver(hiringPipelineEditSchema),
    defaultValues: modalProps?.hiringPipeline,
  });

  const onSubmitClickHandler = (data: HiringPipelineFormProps) => {
    dispatch(hiringPipelineActions.create({ ...data }));
  };

  const confirmButtonText = getConfirmButtonText(modalProps);

  const onCloseHandler = () => {
    dispatch(exModalCancelAction({ id }));
  };

  useEffect(() => {
    if (modalProps?.errorData) {
      setError('name', { message: 'Pipeline name is not unique', type: 'manual' });
    }
  }, [modalProps, setError]);

  return {
    confirmButtonText,
    handleSubmit,
    register,
    errors,
    onSubmitClickHandler,
    formState,
    onCloseHandler,
  } as const;
}

export const HiringPipelineEditNameModal = (props: HiringPipelineEditProps) => {
  const { handleSubmit, register, errors, onSubmitClickHandler, formState, confirmButtonText, onCloseHandler } =
    useHiringPiplineEditNameModalState(props);

  return (
    <ExModalBodyStyledWide>
      <ExModalBodyStyled>
        <form onSubmit={handleSubmit(onSubmitClickHandler)}>
          <input ref={register} hidden name="pipelineId" />
          <FormInput
            inputRef={register}
            name="name"
            label="Pipeline name"
            required
            errors={errors.name}
            validated={formState.isSubmitted}
          />
          <ExDialogActions>
            <ExButton variant="light" onClick={onCloseHandler}>
              Cancel
            </ExButton>
            <ExLoaderHandlerWrapperStrict>
              <ExButton className="ml-3" type="submit">
                {confirmButtonText}
              </ExButton>
            </ExLoaderHandlerWrapperStrict>
          </ExDialogActions>
        </form>
      </ExModalBodyStyled>
    </ExModalBodyStyledWide>
  );
};
