import { useMemo } from 'react';
import startCase from 'lodash/startCase';

import { AvailabilityType } from 'model/api-enums.constants';

export function useAvailabilityTypeOption() {
  return useMemo(
    () =>
      Object.values(AvailabilityType)
        .filter((value) => !Number.isNaN(Number(value)))
        .map((value: any) => ({ label: startCase(AvailabilityType[value]), value })),
    [],
  );
}
