import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { ExBadgeStyled } from 'components/ui/ExBadge/ExBadgeComponents';
import { ExButton } from 'components/ui/ExButton';

import { TabMenuItemPropsV2 } from './TabMenuProps';

export const DownloadLink = styled.a`
  background-color: ${({ theme }) => theme.colors.light};
  color: ${({ theme }) => theme.colors.blackHeadings};
  border-radius: 10px;
  padding: 2px 14px;
  height: 25px;
  margin-left: auto;
  position: absolute;
  transform: translateY(50%);
  right: 20px;
  top: 0;
  z-index: 1;
  opacity: 0.8;
  transition: opacity 0.1s ease-in-out;
  &:hover {
    opacity: 1;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.blackHeadings};
  }
`;

export const TabMenuNavigation = styled(Nav)<{ $vertical?: boolean }>`
  position: relative;
  ${({ $vertical }) => ($vertical ? 'flex-direction: column;' : 'margin-bottom: 24px;')};
  flex-shrink: 0;
`;

export const ExTabMenuNavigationV2 = styled(Nav)`
  position: relative;
  margin-bottom: 24px;
  flex-shrink: 0;
  display: flex;
`;

export const StepNumber = styled.span`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.light};
  display: inline-block;
  width: 1.625rem;
  height: 1.625rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin: 0 14px;
  color: ${({ theme }) => theme.colors.dark};

  & :hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const LabelWrapper = styled.span`
  display: flex;
  flex-direction: column;
`;

export const TabMenuStyle = css<{ $isActiveTab?: boolean }>`
  padding: 10px 20px;
  background-color: ${({ theme, $isActiveTab }) => ($isActiveTab ? theme.colors.primary : 'transparent')};
  color: ${({ theme, $isActiveTab }) => ($isActiveTab ? theme.colors.white : 'inherit')};
  cursor: ${({ $isActiveTab }) => ($isActiveTab ? 'default' : 'pointer')};
  border-radius: 20px;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    transition: background-color 0.2s ease-in-out;
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ExNavLink = styled(Link)<TabMenuItemPropsV2 & { $isActiveTab?: boolean }>`
  ${TabMenuStyle};
  display: flex;
  align-items: center;
  font-weight: 400;

  &.active {
    ${StepNumber} {
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
    }
  }

  ${ExBadgeStyled} {
    margin-left: 10px;
  }
`;

export const ExTabMenuItemV2Styled = styled(Nav.Item)`
  background-color: ${(props) => props.theme.colors.light};
  &:first-of-type {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  &:last-of-type {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`;
export const TabMenuDownloadButton = styled(ExButton)``;
