import { theme } from 'theme';
import { css, DefaultTheme } from 'styled-components';

import { Coords, ExTooltipProps } from 'components/ui/ExTooltip/types';

export function getTooltipTextColor(variant: keyof DefaultTheme['colors']) {
  const lightBackgrounds: Array<keyof DefaultTheme['colors']> = ['white'];
  const isLightBackground = lightBackgrounds.includes(variant);

  const color = isLightBackground ? theme.colors.dark : theme.colors.white;

  return css`
    color: ${color};
  `;
}

export function getTooltipBorder(tooltipPosition: ExTooltipProps['tooltipPosition']) {
  const borderColor = '#eeeeee';

  switch (tooltipPosition) {
    case 'right':
      return css`
        border-left: 1px solid ${borderColor};
        border-bottom: 1px solid ${borderColor};
      `;
    case 'left':
      return css`
        border-top: 1px solid ${borderColor};
        border-right: 1px solid ${borderColor};
      `;
    case 'top':
      return css`
        border-bottom: 1px solid ${borderColor};
        border-right: 1px solid ${borderColor};
      `;
    case 'bottom':
      return css`
        border-top: 1px solid ${borderColor};
        border-left: 1px solid ${borderColor};
      `;
  }
}

export function getTooltipPositionForTooltip(tooltipPosition: ExTooltipProps['tooltipPosition'], coords: Coords) {
  const windowYOffset = window.scrollY;

  switch (tooltipPosition) {
    case 'right':
      return css`
        left: ${coords.x + coords.width}px;
        top: ${coords.y + coords.height / 2 + windowYOffset}px;
        transform: translate(10px, -50%);
      `;
    case 'left':
      return css`
        left: ${coords.x - 15}px;
        top: ${coords.y + windowYOffset}px;
        transform: translateX(-100%);
      `;
    case 'top':
      return css`
        left: ${coords.x + coords.width / 2}px;
        transform: translateX(-50%);
        top: ${coords.y - 40 + windowYOffset}px;
      `;
    case 'bottom':
      return css`
        left: ${coords.x + coords.width / 2}px;
        transform: translateX(-50%);
        top: ${coords.y + 40 + windowYOffset}px;
      `;
    default:
      return css``;
  }
}

export function getArrowPositionForTooltip(tooltipPosition: ExTooltipProps['tooltipPosition']) {
  switch (tooltipPosition) {
    case 'right':
      return css`
        left: -6px;
        top: 30%;
      `;
    case 'left':
      return css`
        right: -6px;
        top: 30%;
      `;
    case 'top':
      return css`
        left: 50%;
        bottom: -7px;
        transform: rotate(45deg) translate(-50%, 30%);
      `;
    case 'bottom':
      return css`
        left: 48%;
        top: -6px;
      `;
    default:
      return css``;
  }
}
