import { Action, ActionCreatorWithoutPayload, AnyAction, nanoid } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { buffers, Channel, channel, Task } from 'redux-saga';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  cancel,
  cancelled,
  delay,
  effectTypes,
  flush,
  fork,
  put,
  PutEffect,
  race,
  take,
} from 'redux-saga/effects';

import { putJob } from 'api-endpoints/job';

import { Unwrap } from 'model/utils';

import { apiCallError } from 'containers/AlertManager/store/alert.actions';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { createActionApiCallBatch } from 'store/entities/qualification-type/qualification-type.model';
import { exModalCancelAction, exModalConfirmAction } from 'store/modals/modals.actions';
import { ExModal } from 'store/modals/modals.interfaces';
import { modalSagaWorker } from 'store/modals/modals.sagas';

type ApiFunction = (...ags: any[]) => Promise<AxiosResponse>;
type ApiFunctionWorker = (...ags: any[]) => Generator;

type LoaderHandlerSuccess = (
  data: any,
  params: any,
) => Action | Array<AllEffect<PutEffect | CallEffect> | PutEffect | CallEffect> | Array<Action>;
type LoaderHandlerFailure = (message: string, request: AxiosResponse, params: any) => Action | Action[];
type LoaderHandlerFinally = (
  params: ParamsFinally,
) => Action | Array<AllEffect<PutEffect | CallEffect> | PutEffect | CallEffect> | Array<Action>;

type ParamsFinally = {
  preloader?: string | boolean;
  payload?: {
    preloader: string | boolean;
  };
};

export type Loader = {
  success: LoaderHandlerSuccess;
  failure?: LoaderHandlerFailure;
  finally?: LoaderHandlerFinally;
};

const addPut = (item: Action) =>
  [effectTypes.PUT, effectTypes.CALL, effectTypes.ALL].includes(item.type) ? item : put(item);

const ignoreErrorApiFns = [putJob];

export function* fetchEntity(entity: Loader, apiFn: ApiFunction, params: any) {
  const cancelToken = axios.CancelToken.source();
  try {
    const { message, data, response, errorData } = yield call(apiFn, {
      ...params,
      cancelToken: cancelToken.token,
    });
    if (message && !ignoreErrorApiFns.includes(apiFn)) {
      let puts: Action | Action[] = [];
      if (typeof entity.failure === 'function') {
        puts = entity.failure(message, response, params);
      }
      yield all([
        ...(Array.isArray(puts)
          ? [...puts, apiCallError({ errorData, message })]
          : [puts, apiCallError({ errorData, message })]
        ).map(addPut),
      ]);
    } else if (typeof entity.success === 'function') {
      const puts = entity.success(data, params);
      const preparedPuts = (Array.isArray(puts) ? puts : [puts]).map(addPut);
      for (const action of preparedPuts) {
        yield action;
      }
    }
  } finally {
    let finallyActions: Action | Action[] = [];
    if (typeof entity.finally === 'function') {
      finallyActions = entity.finally(params);
    }
    yield delay(500);
    yield all([...(Array.isArray(finallyActions) ? [...finallyActions] : [finallyActions]).map(addPut)]);
    if (yield cancelled()) {
      cancelToken.cancel();
    }
  }
}

//-------------------------------------------------------------------------------------

type InvokeApiCallOptionsType = {
  silent?: boolean;
  action?: ReturnType<typeof createActionApiCallBatch>;
  loaderId?: string;
};

export type ReturnData<Fn extends ApiFunction = ApiFunction> = Partial<
  Pick<Unwrap<ReturnType<Fn>>, 'data' | 'errorData' | 'message' | 'status'>
>;

export function* invokeApiCallNoType<Fn extends (...args: any) => any>(
  apiFn: Fn,
  params: Parameters<Fn>[0],
  _options?: InvokeApiCallOptionsType,
): Generator<any, Pick<ReturnType<Fn>, 'data' | 'errorData' | 'message' | 'status'>, any> {
  const cancelToken = axios.CancelToken.source();

  try {
    const { message, data, errorData, status } = yield call(apiFn as any, {
      ...params,
      cancelToken: cancelToken.token,
    });
    if (message && !ignoreErrorApiFns.includes(apiFn)) {
      yield put(apiCallError({ errorData, message }));
    }
    return { data, errorData, message, status };
  } catch (e) {
    throw new Error('invokeApiCallNoType');
  } finally {
    if (yield cancelled()) {
      cancelToken.cancel();
    }
  }
}
export function* invokeApiCall<Fn extends (...args: any) => any, Params extends Parameters<Fn>[0]>(
  fn: Fn,
  params?: Params,
  options?: Parameters<typeof invokeApiCallNoType>[2],
) {
  return yield call(invokeApiCallNoType, fn, params ?? {}, options);
}

export function* worker(cancelType: ActionCreatorWithoutPayload, forkFn: ApiFunctionWorker, parameters: any) {
  let task: Task | null = null;
  try {
    task = yield fork(forkFn, parameters);
    yield take(cancelType);
    if (task) {
      yield cancel(task);
    }
  } finally {
    if (yield cancelled() && task !== null) {
      yield cancel(task as Task);
    }
  }
}

export type InvokeExModalProps = {
  channel: Channel<any>;
  modalId: string;
  modalType: ModalsTypeKey;
  modalProps?: ExModal['modalProps'];
  modalConfig?: ExModal['modalConfig'];
};

export function* invokeExModal({
  channel: modalChannel,
  modalId,
  modalType,
  modalProps,
  modalConfig,
}: InvokeExModalProps) {
  yield fork(modalSagaWorker, {
    ...(modalId ? { id: modalId } : {}),
    modalConfig,
    modalProps,
    modalType,
  });

  while (true) {
    const { cancelResult, confirm } = yield race({
      cancelResult: take(exModalCancelAction.type),
      confirm: take(exModalConfirmAction.type),
    });

    yield put(modalChannel, { cancel: cancelResult, confirm });
  }
}

export function* prepareExModalChannel() {
  const modalId = nanoid();
  const sagaChannel = yield channel(buffers.none());

  return { modalId, sagaChannel } as const;
}

export function* invokeExModalWizard({
  channel: modalChannel,
  modalId,
  modalProps,
  modalConfig,
}: {
  channel: Channel<any>;
  modalId: string;
  modalProps?: ExModal['modalProps'];
  modalConfig?: ExModal['modalConfig'];
}) {
  yield fork(modalSagaWorker as any, {
    id: modalId,
    modalConfig,
    modalProps,
    modalType: ModalsTypeKey.wizard,
  });

  while (true) {
    const { cancelResult, confirm } = yield race({
      cancelResult: take(exModalCancelAction.type),
      confirm: take(exModalConfirmAction.type),
    });

    yield put(modalChannel, { cancel: cancelResult, confirm });
  }
}

export interface PrepareResultActions<T extends AnyAction> {
  <M extends Map<string, T[]> = Map<string, T[]>>(
    actionsChannel: Channel<T>,
    groupFn: (previousValue: M, currentValue: T, currentIndex: number, array: T[]) => M,
    resultFn: (map: M) => Array<PutEffect<T>>,
  ): Array<PutEffect<T>>;
}

export const prepareResultActions = function* (actionsChannel, groupFn, resultFn) {
  // Take all actions from the actionsChannel
  const actions: AnyAction[] = yield flush(actionsChannel);

  // Group actions by id and decide which action must be invoked.
  const actionsGroupedByPipelineStageId = actions.reduce(groupFn, new Map());

  return resultFn(actionsGroupedByPipelineStageId);
};
