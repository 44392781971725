import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { createFilter } from 'react-select';
import styled from 'styled-components';
import get from 'lodash/get';

import {
  DaXtraDisabledInput,
  ListLabel,
} from 'containers/Modals/ModalsContent/Company/CompanyUpdateQualificationTypesMaps';
import {
  ModalBodyStyled,
  ModalBodyStyledNarrow,
  ModalBodyStyledWide,
} from 'containers/Modals/ModalsContent/Wizard/sagas-components/job-change-status-modal-components';

import { FormSelect } from 'components';
import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { Spinner } from 'components/Spinner';
import { ExButton } from 'components/ui/ExButton';
import { ExDialogActions } from 'components/ui/ExDialog';
import { ExColumn, ExRow } from 'components/ui/ExLayout';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { DaxtraQualificationMapping } from 'store/company/company.models';
import {
  qualificationTypeActions,
  QualificationTypeModel,
  qualificationTypeSelectors,
} from 'store/entities/qualification-type';
import { ModalStepProps } from 'store/modals/config';
import { exModalConfirmAction } from 'store/modals/modals.actions';
import { useSelector } from 'store/rootSelectors';

const PendingModal = (_: ModalStepProps) => {
  return (
    <ModalBodyStyledNarrow>
      <Spinner />
    </ModalBodyStyledNarrow>
  );
};

const MessageWrapper = styled.div`
  min-height: 100px;
  max-width: 80%;
  margin: auto;
  text-align: center;
`;

const ConfirmModal = ({ id, onCloseDialogHandler, modalProps }: ModalStepProps) => {
  const dispatch = useAppDispatch();
  const qualificationType = useSelector((state) =>
    qualificationTypeSelectors.selectById(state, modalProps?.qualificationId),
  );

  return (
    <ModalBodyStyledNarrow>
      <MessageWrapper>You confirm remove {qualificationType?.qualificationName}?</MessageWrapper>
      <ExDialogActions>
        <ExButton variant="light" onClick={() => onCloseDialogHandler()}>
          Cancel
        </ExButton>
        <ExLoaderHandlerWrapper action={qualificationTypeActions.qualificationTypeRemove}>
          <ExButton className="ml-3" onClick={() => dispatch(exModalConfirmAction({ id }))}>
            Confirm
          </ExButton>
        </ExLoaderHandlerWrapper>
      </ExDialogActions>
    </ModalBodyStyledNarrow>
  );
};

const ConfirmModalWithQuestion = ({ onCloseDialogHandler, nextButtonHandler, modalProps }: ModalStepProps) => {
  const qualificationType = useSelector((state) =>
    qualificationTypeSelectors.selectById(state, modalProps?.qualificationId),
  );

  return (
    <ModalBodyStyled>
      <MessageWrapper>
        You’ve mapped this qualification type against DaXtra Degree List in your Company Settings - Default Value. Are
        you sure you want to remove {qualificationType?.qualificationName}?
      </MessageWrapper>
      <ExDialogActions>
        <ExButton variant="light" onClick={() => onCloseDialogHandler()}>
          Cancel
        </ExButton>
        <ExLoaderHandlerWrapper>
          <ExButton className="ml-3" onClick={() => nextButtonHandler()}>
            Confirm
          </ExButton>
        </ExLoaderHandlerWrapper>
      </ExDialogActions>
    </ModalBodyStyled>
  );
};

const ModalBodyStyledWideWithForm = styled(ModalBodyStyledWide)`
  .form-group {
    margin-bottom: 0;
  }
`;

const ModalForm = styled.form`
  margin-bottom: 20px;
`;

const DaxtraMappingModal = ({ id, modalProps }: ModalStepProps) => {
  const dispatch = useAppDispatch();
  const { daxtraQualifications, qualificationId } = modalProps as {
    daxtraQualifications: DaxtraQualificationMapping['daxtraQualificationName'][];
    qualificationId: QualificationTypeModel['qualificationId'];
  };

  const qualificationTypeList = useSelector((state) =>
    qualificationTypeSelectors.selectExcludeId(state, qualificationId),
  );

  const { handleSubmit, errors, formState, register, control } = useForm();

  const onSubmitClickHandler = (data: any) => {
    dispatch(
      exModalConfirmAction({
        id,
        modalResult: data,
      }),
    );
  };

  return (
    <ModalBodyStyledWideWithForm>
      <MessageWrapper>Please re-map your Qualification Type below</MessageWrapper>
      <ModalForm onSubmit={handleSubmit(onSubmitClickHandler)} id="remap-qualification-type">
        <ExRow>
          <ExColumn custom="6">
            <ListLabel>DaXtra Degree List</ListLabel>
          </ExColumn>
          <ExColumn custom="6">
            <ListLabel>Expedo Qualification Type</ListLabel>
          </ExColumn>
          <ExColumn custom="12">
            {daxtraQualifications.map((daxtraQualificationName, index) => (
              <ExRow key={daxtraQualificationName}>
                <ExColumn custom="6">
                  <DaXtraDisabledInput
                    inputRef={register}
                    name={`items[${index}].daxtraQualificationName`}
                    value={daxtraQualificationName}
                  >
                    {daxtraQualificationName}
                  </DaXtraDisabledInput>
                </ExColumn>
                <ExColumn custom="6">
                  <Controller
                    name={`items[${index}].qualificationId`}
                    control={control}
                    rules={{ required: 'This field is required' }}
                    render={({ value, onChange }) => {
                      return (
                        <FormSelect
                          name={`items[${index}].qualificationId`}
                          label=""
                          required
                          placeholder="Select"
                          isMulti={false}
                          value={
                            qualificationTypeList.find(
                              (qualificationType) => qualificationType.qualificationId === value,
                            )?.qualificationId
                          }
                          onChange={(qualificationTypeModel: QualificationTypeModel) =>
                            onChange(qualificationTypeModel.qualificationId)
                          }
                          errors={get(errors, `items[${index}].qualificationId`)}
                          validated={formState.isSubmitted}
                          defaultOptions={qualificationTypeList}
                          options={qualificationTypeList}
                          getOptionLabel={(qualificationType: QualificationTypeModel) =>
                            qualificationType.qualificationName
                          }
                          getOptionValue={(qualificationType: QualificationTypeModel) =>
                            qualificationType.qualificationId
                          }
                          createFilterOuter={createFilter({
                            ignoreCase: true,
                            matchFrom: 'any',
                            stringify: (option) => `${option.label}`,
                            trim: true,
                          })}
                          menuPlacement="auto"
                        />
                      );
                    }}
                  />
                </ExColumn>
              </ExRow>
            ))}
          </ExColumn>
        </ExRow>
      </ModalForm>
      <ExDialogActions>
        <ExLoaderHandlerWrapper>
          <ExButton type="submit" form="remap-qualification-type">
            Done
          </ExButton>
        </ExLoaderHandlerWrapper>
      </ExDialogActions>
    </ModalBodyStyledWideWithForm>
  );
};

export const qualificationTypeRemoveSteps = {
  ConfirmModal,
  ConfirmModalWithQuestion,
  DaxtraMappingModal,
  PendingModal,
};
