export const InterviewTemplateQuestion = {
  // POST
  AddInterviewTemplateQuestionFunc: '/interview-template/{interviewTemplateId}/question',
  // GET
  GetInterviewTemplateQuestionsFunc: '/interview-template/{interviewTemplateId}/question',
  // PUT
  UpdateInterviewTemplateQuestionFunc:
    '/interview-template/{interviewTemplateId}/question/{interviewTemplateQuestionId}',
  // DELETE
  DeleteInterviewTemplateQuestionFunc:
    '/interview-template/{interviewTemplateId}/question/{interviewTemplateQuestionId}',
} as const;
