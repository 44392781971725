import React, { useEffect } from 'react';
import { useAppRouterParams } from 'router/useAppRouterParams';

import { ApplicantCommentTab } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/Tabs/ApplicantCommentTab';
import { ApplicantTabProps } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/Tabs/ApplicantTabProps';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { applicantCommentsActions } from 'store/entities/applicant-comments';

export const ApplicantSideModalCommentTab: React.FC<ApplicantTabProps> = (props) => {
  const { applicantId, jobId } = useAppRouterParams();
  const dispatch = useAppDispatch();

  // Effect for load applicant comments
  useEffect(() => {
    if (applicantId && jobId) {
      dispatch(
        applicantCommentsActions.applicantCommentsFetch({
          applicantId,
          jobId,
        }),
      );
    }
    return () => {
      dispatch(applicantCommentsActions.resetCurrent());
    };
  }, [applicantId, dispatch, jobId]);

  return <ApplicantCommentTab {...props} />;
};
