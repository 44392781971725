import { push, replace } from 'connected-react-router';
import { Routes } from 'router';
import { call, CallEffect, put, takeEvery } from 'redux-saga/effects';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';

import { getTranslate } from 'utils/i18utils';

import { authActions } from 'store/auth';

import * as fromAuthActions from './auth.actions';
import AuthenticationService, { AuthenticationResultStatus } from './AuthenticationService';

function* authGetUserWorker() {
  const isAuthenticated: CallEffect<boolean> = yield call([AuthenticationService, 'isAuthenticated']);
  if (isAuthenticated) {
    // We get user from service
    const user: CallEffect<ReturnType<typeof AuthenticationService['getUser']>> = yield call([
      AuthenticationService,
      'getUser',
    ]);
    // We save a user in the redux
    yield put(authActions.setUser({ user }));
  }
}

function* authLoginWorker() {
  yield put(authActions.setLoading(true));
  // We check is user isAuthenticated
  const isAuthenticated: CallEffect<boolean> = yield call([AuthenticationService, 'isAuthenticated']);
  if (isAuthenticated) {
    // We get user from service
    const user: CallEffect<ReturnType<typeof AuthenticationService['getUser']>> = yield call([
      AuthenticationService,
      'getUser',
    ]);
    // We save a user in the redux
    yield put(authActions.setUser({ user }));
  } else {
    // We call signIn method for Authenticate user
    const result: CallEffect<ReturnType<typeof AuthenticationService['signIn']>> = yield call(
      [AuthenticationService, 'signIn'],
      undefined,
    );
    yield processCallback(result, `/${Routes.logoutConfirm}`, 'Login Success');
    if ((result as any).status === AuthenticationResultStatus.Success) {
      const user: CallEffect<ReturnType<typeof AuthenticationService['getUser']>> = yield call([
        AuthenticationService,
        'getUser',
      ]);
      yield put(authActions.setUser({ user }));
    }
  }
  yield put(authActions.setLoading(false));
}

function* authLoginConfirmWorker({ payload }: ReturnType<typeof fromAuthActions.authLoginConfirmAction>) {
  yield put(authActions.setLoading(true));
  const url = payload.url;
  // We try to check that the user already Authenticated
  const user: CallEffect<ReturnType<typeof AuthenticationService['getUser']>> = yield call([
    AuthenticationService,
    'getUser',
  ]);
  if (!user) {
    // We complete sign in
    const result: CallEffect<ReturnType<typeof AuthenticationService['completeSignIn']>> = yield call(
      [AuthenticationService, 'completeSignIn'],
      url,
    );
    yield processCallback(result, `/${Routes.dashboard}`, 'Login Success');
  } else {
    // Redirect user to Dashboard
    yield put(
      replace({
        pathname: `/${Routes.dashboard}`,
      }),
    );
  }
  yield put(authActions.setLoading(false));
}

function* authLogoutWorker() {
  yield put(authActions.setLoading(true));
  // We check is user isAuthenticated
  const isAuthenticated: CallEffect<boolean> = yield call([AuthenticationService, 'isAuthenticated']);
  if (isAuthenticated) {
    // We call signOut method for signOut
    const result: CallEffect<ReturnType<typeof AuthenticationService['signOut']>> = yield call([
      AuthenticationService,
      'signOut',
    ]);
    yield processCallback(result, `/${Routes.logoutConfirm}`, 'LogOut Success');
  } else {
    // We redirect user
    yield put(replace({ pathname: `/${Routes.logoutConfirm}` }));
  }
  yield put(authActions.setLoading(false));
}

function* authLogoutConfirmWorker({ payload }: ReturnType<typeof fromAuthActions.authLogoutConfirmAction>) {
  yield put(authActions.setLoading(true));

  // We try to check that the user already Authenticated
  const user: CallEffect<ReturnType<typeof AuthenticationService['getUser']>> = yield call([
    AuthenticationService,
    'getUser',
  ]);
  if (!!user) {
    // We redirect user to dashboard ti prevent show LoginConfirmPage
    yield put(
      replace({
        pathname: `/${Routes.dashboard}`,
      }),
    );
  } else {
    const url = payload.url;
    // We redirect user to dashboard ti prevent show LoginConfirmPage
    const result: CallEffect<ReturnType<typeof AuthenticationService['completeSignOut']>> = yield call(
      [AuthenticationService, 'completeSignOut'],
      url,
    );
    yield processCallback(result, `/${Routes.home}`, 'LogOut Success', true);
  }
  yield put(authActions.setLoading(false));
}

function* processCallback(result: any, pathname: string, message: string, useReplaceUrl?: boolean) {
  switch (result.status) {
    case AuthenticationResultStatus.Redirect:
      yield put(push({ pathname }));
      break;
    case AuthenticationResultStatus.Success:
      yield put(alertsEffects.showSuccess({ message: getTranslate(message) }));
      const action = useReplaceUrl ? replace : push;
      yield put(action({ pathname }));
      break;
    case AuthenticationResultStatus.Fail:
      yield put(alertsEffects.showError({ message: getTranslate(message) }));
      break;
    default:
  }
}

export function* authSagas() {
  yield takeEvery(fromAuthActions.authGetUserAction, authGetUserWorker);
  // Login workers
  yield takeEvery(fromAuthActions.authLoginAction, authLoginWorker);
  yield takeEvery(fromAuthActions.authLoginConfirmAction, authLoginConfirmWorker);

  // Logout workers
  yield takeEvery(fromAuthActions.authLogoutAction, authLogoutWorker);
  yield takeEvery(fromAuthActions.authLogoutConfirmAction, authLogoutConfirmWorker);
}
