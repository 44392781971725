import React from 'react';

import { ApplicantModalSwitchMode } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalSwitchMode';
import { applicantModalSlice } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/store';
import { updateApplicantModalAction } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/store/ApplicantModal.actions';

import { useLoaderSubscription } from 'modules/LoaderManager/react';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { ActionButtonStyled, ApplicantModalFooterStyled, ButtonsWrapper } from './ApplicantModalFooterComponents';
import { ApplicantModalFooterView } from './ApplicantModalFooterView';

type ApplicantModalFooterProps = {
  className?: string;
  isCandidateView?: boolean;
};

export const useApplicantModalFooterState = ({ className, isCandidateView }: ApplicantModalFooterProps) => {
  const dispatch = useAppDispatch();

  const { isLoading: isUpdateApplicantModalLoading } = useLoaderSubscription(updateApplicantModalAction);

  const cancelButtonClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    dispatch(applicantModalSlice.actions.toggleEdit(false));
  };
  return { className, isCandidateView, cancelButtonClickHandler, isUpdateApplicantModalLoading } as const;
};

export const ApplicantModalFooter: React.FC<ApplicantModalFooterProps> = (props) => {
  const { className, isCandidateView, cancelButtonClickHandler, isUpdateApplicantModalLoading } =
    useApplicantModalFooterState(props);

  return (
    <ApplicantModalFooterStyled className={className}>
      <ApplicantModalSwitchMode
        editComponent={
          <ButtonsWrapper>
            <ActionButtonStyled
              variant="danger"
              onClick={cancelButtonClickHandler}
              disabled={isUpdateApplicantModalLoading}
            >
              Cancel
            </ActionButtonStyled>
            <ActionButtonStyled
              type="submit"
              variant="success"
              form="applicant-modal-form"
              isLoading={isUpdateApplicantModalLoading}
            >
              Save
            </ActionButtonStyled>
          </ButtonsWrapper>
        }
        viewComponent={<ApplicantModalFooterView isCandidateView={isCandidateView} />}
      />
    </ApplicantModalFooterStyled>
  );
};
