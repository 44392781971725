import React from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

import { SourceEntity } from 'model';

import { CheckableList } from 'components/Form/CheckableList';
import { StepFormBody } from 'components/StepForm';

import { JobAd } from 'store/entities/job-ads';

export interface JobAdRequirementsFormBodyProps extends StepFormBody {
  className?: string;
  formData?: Partial<JobAd>;
}

export const JobAdRequirementsFormBodyStyled = styled.div``;

export const JobAdRequirementsFormBody: React.FC<JobAdRequirementsFormBodyProps> = ({ className, formData }) => {
  const {
    field: { ref, onChange },
  } = useController({ name: 'requirements' });

  return (
    <JobAdRequirementsFormBodyStyled className={className}>
      <CheckableList
        items={formData?.requirements ?? []}
        onChange={onChange}
        placeholder="Add new Job Ad requirement"
        sourceEntity={SourceEntity.JobAd}
        name="requirements"
        inputRef={ref}
      />
    </JobAdRequirementsFormBodyStyled>
  );
};
