import { createEntityAdapter } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { ExQuestion } from 'model';

import { interviewTemplateEditFormSliceName } from 'containers/InterviewTemplateForms/InterviewTemplateEditForm/state/InterviewTemplateEditForm.initialState';

import { RootState } from 'store/rootReducer';

const interviewTemplateQuestionsAdapter = createEntityAdapter<ExQuestion>({
  selectId: (item) => item.questionId,
});

const domain = (state: RootState) => state.forms[interviewTemplateEditFormSliceName];

const formData = createSelector(domain, (state) => state.formData);
const initialData = createSelector(domain, (state) => state.initialData);
const initialDataQuestions = createSelector(domain, (state) => state.initialData.questions);
const formQuestions = createSelector(domain, (state) => state.formData.questions);
const formTemplate = createSelector(domain, (state) => state.formData.interviewTemplate);

const questionsInitialDataSelectorsAdapter = interviewTemplateQuestionsAdapter.getSelectors(initialDataQuestions);

const questionsFormSelectorsFromAdapter = interviewTemplateQuestionsAdapter.getSelectors(formQuestions);

const isNoQuestionsInInterviewTemplate = createSelector(
  questionsInitialDataSelectorsAdapter.selectTotal,
  (state) => state < 1,
);
const isPreviewButtonVisible = createSelector(isNoQuestionsInInterviewTemplate, (state) => !state);

const formIsChanged = createSelector(domain, (state) => {
  return JSON.stringify(state.formData) !== JSON.stringify(state.initialData);
});

export const interviewTemplateEditFormSelectors = {
  questionsFormSelectorsFromAdapter,
  formTemplate,
  initialData,
  formData,
  isPreviewButtonVisible,
  isNoQuestionsInInterviewTemplate,
  questionsInitialDataSelectorsAdapter,
  formIsChanged,
};
