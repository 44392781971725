import React from 'react';
import styled from 'styled-components/macro';

import { ApplicantFormScreeningQuestionsControlContainer } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ApplicantFormScreeningQuestionsControlContainer';
import { ScreeningQuestionViewProps } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionView/ScreeningQuestionViewProps';
import { useApplicationFormQuestionField } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/hooks/useCompanyScreeningQuestionsListState';

import { FormInput } from 'components/FormInput';

type ScreeningQuestionTextAreaProps = ScreeningQuestionViewProps & {
  className?: string;
};

const ScreeningQuestionTextAreaStyled = styled.div`
  width: 100%;
`;

const FormInputStyled = styled(FormInput)`
  margin-bottom: 0;

  textarea {
    border-radius: 20px;
    min-height: 88px;

    &:disabled {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const ApplicantFormInputStyled = styled(FormInput)`
  margin-bottom: 0;

  textarea {
    border-radius: 20px;
    min-height: 140px;

    &:disabled {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const useScreeningQuestionTextAreaState = ({ className, editable, question }: ScreeningQuestionTextAreaProps) => {
  const disabled = !editable;

  const name = question.itemId;

  const answer = question?.answer?.toString() || '';

  return {
    className,
    disabled,
    name,
    answer,
  } as const;
};

export const ScreeningQuestionTextArea: React.FC<ScreeningQuestionTextAreaProps> = (props) => {
  const { className, disabled, name, answer } = useScreeningQuestionTextAreaState(props);

  return (
    <ScreeningQuestionTextAreaStyled className={className}>
      <FormInputStyled
        asIs="textarea"
        classNameInput="form-control"
        label=""
        name={name}
        disabled={disabled}
        value={answer}
      />
    </ScreeningQuestionTextAreaStyled>
  );
};

const useApplicationFormScreeningQuestionTextAreaState = (props: ScreeningQuestionTextAreaProps) => {
  const { className } = props;

  const { fieldName, fieldErrors, disabled, validated, register, isInvalid } = useApplicationFormQuestionField(props);

  return {
    className,
    disabled,
    fieldName,
    register,
    validated,
    fieldErrors,
    isInvalid,
  } as const;
};

export const ApplicationFormScreeningQuestionTextArea: React.FC<ScreeningQuestionTextAreaProps> = (props) => {
  const { className, disabled, fieldName, register, fieldErrors, isInvalid } =
    useApplicationFormScreeningQuestionTextAreaState(props);

  return (
    <ApplicantFormScreeningQuestionsControlContainer className={className} show={isInvalid} errors={fieldErrors}>
      <ScreeningQuestionTextAreaStyled>
        <ApplicantFormInputStyled
          asIs="textarea"
          classNameInput="form-control"
          label=""
          name={fieldName}
          disabled={disabled}
          inputRef={register}
        />
      </ScreeningQuestionTextAreaStyled>
    </ApplicantFormScreeningQuestionsControlContainer>
  );
};
