import { createSelector } from '@reduxjs/toolkit';

import jobFormSlice from 'containers/JobForms/state/reducer';

import { RootState } from 'store/rootReducer';

export const domain = (state: RootState) => state[jobFormSlice.name];

export const selectPositions = createSelector(domain, (state) => state.positions);

export const selectShowSteps = createSelector(domain, (state) => state.showSteps);

export const selectFormData = createSelector(domain, (state) => state.formData);

export const selectIsDraftSaved = createSelector(domain, (state) => state.isDraftSaved);

export const selectValidSteps = createSelector(domain, (state) => state.validSteps);

export const selectApiErrors = createSelector(domain, (state) => state.apiErrors);

export const selectInterviewTemplatesSelected = createSelector(domain, (state) => state.interviewTemplates || []);

export const selectInterviewTemplatesTitleSelected = createSelector(
  selectInterviewTemplatesSelected,
  (interviewTemplates) => interviewTemplates?.map(({ name }) => name),
);

export const selectFormDataId = createSelector(selectFormData, (formData) => formData?.id);

export const selectFormDataJobId = createSelector(selectFormData, (formData) => formData?.jobId);

export const selectFormDataPositionId = createSelector(selectFormData, (formData) => formData?.position?.positionId);

export const selectHiringManagersSelected = createSelector(domain, (state) => state.hiringManagers);

export const selectIsAutosaving = createSelector(domain, (state) => state?.isAutosaving);

export const selectIsOpening = createSelector(domain, (state) => state?.isOpening);

export const selectFormDataJobName = createSelector(selectFormData, (formData) => formData?.jobName);
