import { createAction } from '@reduxjs/toolkit';

const AUTH_GET_USER = 'auth/getUser';
const AUTH_LOGIN = 'auth/login';
const AUTH_LOGIN_CONFIRM = 'auth/login/confirm';
const AUTH_LOGOUT = 'auth/logout';
const AUTH_LOGOUT_CONFIRM = 'auth/logout/confirm';

export const authGetUserAction = createAction(AUTH_GET_USER);

export const authLoginAction = createAction(AUTH_LOGIN);
export const authLoginConfirmAction = createAction<{ url: string }>(AUTH_LOGIN_CONFIRM);

export const authLogoutAction = createAction(AUTH_LOGOUT);
export const authLogoutConfirmAction = createAction<{ url: string }>(AUTH_LOGOUT_CONFIRM);
