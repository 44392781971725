import React, { useState } from 'react';

import { Ability, SourceEntity } from 'model';

import { JobResponsibilitiesComponent } from 'containers/Modals/ModalsContent/Job/JobResponsibilitiesModal/JobResponsibilitiesComponent';

import { ExLoaderHandlerWrapperStrict } from 'components/Common/LoaderHandlerWrapper';
import { DialogBody, DialogContentComponent, DialogFooter, DialogProps } from 'components/Dialog';
import { CheckableList } from 'components/Form/CheckableList';
import { CheckableRowItem } from 'components/Form/CheckableRow/CheckableRowProps';
import { ExButton } from 'components/ui/ExButton/ExButton';

interface AbilityModalBodyProps {
  onClose: (result: boolean | null) => void;
  submitHandler: (items: Ability[]) => void;
  items: Ability[] | undefined;
  className?: string;
  content: DialogProps['content'];
  sourceEntity?: SourceEntity;
}

export const AbilityModalBody: React.FC<AbilityModalBodyProps> = ({
  className,
  onClose,
  content,
  submitHandler,
  items = [],
}) => {
  const [ability, setResponsibilities] = useState<Ability[]>([]);
  const changeListHandler = (checkableRowItems: CheckableRowItem[]) => setResponsibilities(checkableRowItems);

  const updateClickHandler: React.MouseEventHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    submitHandler(ability);
  };

  const closeHandler: React.MouseEventHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onClose(false);
  };

  return (
    <JobResponsibilitiesComponent className={className} onClose={onClose} content={content}>
      <DialogContentComponent>
        <DialogBody>
          <CheckableList
            items={items}
            onChange={changeListHandler}
            placeholder="Add new responsibility"
            disabled={false}
            maxListHeight={500}
          />
        </DialogBody>
        <DialogFooter className="d-flex justify-content-center">
          <ExButton variant="light" onClick={closeHandler}>
            Cancel
          </ExButton>
          <ExLoaderHandlerWrapperStrict>
            <ExButton className="ml-3" variant="primary" onClick={updateClickHandler}>
              Update
            </ExButton>
          </ExLoaderHandlerWrapperStrict>
        </DialogFooter>
      </DialogContentComponent>
    </JobResponsibilitiesComponent>
  );
};
