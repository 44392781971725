import { api } from 'api-client';
import { createApiMethod } from 'api-endpoints/utils';

import { ApiResponseWithPagination } from 'model';
import { WithRequired } from 'model/utils';

import { ApplicantListParams } from 'containers/ApplicantLists/store';

import { Applicant } from 'store/entities/applicants/models';

import { ApplicantEndpoints } from './applicant.endpoints';
import * as fromApplicantApiModels from './models';

export const postApplicant = (data: fromApplicantApiModels.PostApplicantParams) =>
  api().request<fromApplicantApiModels.PostApplicantFuncResponse>({
    method: 'POST',
    url: ['/api/company/{companyId}/candidate', data.candidateId, 'applicant'].join('/'),
    data,
  });
//----------------------------------------------------------------------------------------------------------------------
// PUT UpdateJobApplicantFunc
export const updateJobApplicantFunc = createApiMethod<
  fromApplicantApiModels.UpdateJobApplicantFuncParams,
  fromApplicantApiModels.UpdateJobApplicantFuncResponse
>({
  url: ApplicantEndpoints.UpdateJobApplicantFunc,
  method: 'PUT',
});
//----------------------------------------------------------------------------------------------------------------------

export const getApplicantDashboard = ({ pageNo, pageSize }: WithRequired<ApplicantListParams, 'filters'>) => {
  return api().request<ApiResponseWithPagination<Applicant>>({
    url: '/api/company/{companyId}/dashboard/recent-applicants',
    params: {
      pageNo,
      pageSize,
    },
  });
};

export const postAddToTalentPool = createApiMethod<{ data: any; urlParams: { jobId: string } }, void>({
  url: '/api/company/{companyId}/job/{jobId}/applicant/to-talent-pools',
  method: 'POST',
});

//----------------------------------------------------------------------------------------------------------------------
// Applicant comments
// AddCommentForJobApplicantFunc
export const addCommentForJobApplicantFunc = createApiMethod<
  fromApplicantApiModels.AddCommentForJobApplicantFuncParams,
  fromApplicantApiModels.AddCommentForJobApplicantFuncResponse
>({ url: ApplicantEndpoints.AddCommentForJobApplicantFunc, method: 'POST' });

// GetCommentForJobApplicantFunc
export const getCommentForJobApplicantFunc = createApiMethod<
  fromApplicantApiModels.GetCommentForJobApplicantFuncParams,
  fromApplicantApiModels.GetCommentForJobApplicantFuncResponse
>({ url: ApplicantEndpoints.GetCommentForJobApplicantFunc });

// SearchCommentsForJobApplicantFunc
export const searchCommentsForJobApplicantFunc = createApiMethod<
  fromApplicantApiModels.SearchCommentsForJobApplicantFuncParams,
  fromApplicantApiModels.SearchCommentsForJobApplicantFuncResponse
>({ url: ApplicantEndpoints.SearchCommentsForJobApplicantFunc });

// UpdateCommentForJobApplicantFunc
export const updateCommentForJobApplicantFunc = createApiMethod<
  fromApplicantApiModels.UpdateCommentForJobApplicantFuncParams,
  fromApplicantApiModels.UpdateCommentForJobApplicantFuncResponse
>({ url: ApplicantEndpoints.UpdateCommentForJobApplicantFunc, method: 'PUT' });

// DeleteCommentForJobApplicantFunc
export const deleteCommentForJobApplicantFunc = createApiMethod<
  fromApplicantApiModels.DeleteCommentForJobApplicantFuncParams,
  fromApplicantApiModels.DeleteCommentForJobApplicantFuncResponse
>({ url: ApplicantEndpoints.DeleteCommentForJobApplicantFunc, method: 'DELETE' });

// ReadCommentsForJobApplicantFunc
export const readCommentsForJobApplicantFunc = createApiMethod<
  fromApplicantApiModels.ReadCommentsForJobApplicantFuncParams,
  fromApplicantApiModels.ReadCommentsForJobApplicantFuncResponse
>({ url: ApplicantEndpoints.ReadCommentsForJobApplicantFunc, method: 'POST' });

// ReadAllCommentForJobApplicantFunc
export const readAllCommentForJobApplicantFunc = createApiMethod<
  fromApplicantApiModels.ReadAllCommentForJobApplicantFuncParams,
  fromApplicantApiModels.ReadAllCommentForJobApplicantFuncResponse
>({ url: ApplicantEndpoints.ReadAllCommentForJobApplicantFunc, method: 'POST' });

// GetPreviousJobApplicationsNumberFunc

export const getPreviousJobApplicationsNumberFunc = createApiMethod({
  url: ApplicantEndpoints.GetPreviousJobApplicationsNumberFunc,
});

// -------------------------------------------------------------------------------------------------
export const getJobApplicantOrderedInfoFunc = createApiMethod<
  fromApplicantApiModels.GetJobApplicantOrderedInfoFuncParams,
  fromApplicantApiModels.GetJobApplicantOrderedInfoFuncResponse
>({
  url: ApplicantEndpoints.GetJobApplicantOrderedInfoFunc,
  method: 'POST',
});
// -------------------------------------------------------------------------------------------------

export const getJobApplicantWorkExperienceFunc = createApiMethod<
  fromApplicantApiModels.GetJobApplicantWorkExperienceFuncParams,
  fromApplicantApiModels.GetJobApplicantWorkExperienceFuncResponse
>({
  url: ApplicantEndpoints.GetJobApplicantWorkExperienceFunc,
  method: 'GET',
});
export const addJobApplicantWorkExperienceFunc = createApiMethod<
  fromApplicantApiModels.AddJobApplicantWorkExperienceFuncParams,
  fromApplicantApiModels.AddJobApplicantWorkExperienceFuncResponse
>({
  url: ApplicantEndpoints.AddJobApplicantWorkExperienceFunc,
  method: 'POST',
});
export const updateJobApplicantWorkExperienceFunc = createApiMethod<
  fromApplicantApiModels.UpdateJobApplicantWorkExperienceFuncParams,
  fromApplicantApiModels.UpdateJobApplicantWorkExperienceFuncResponse
>({
  url: ApplicantEndpoints.UpdateJobApplicantWorkExperienceFunc,
  method: 'PUT',
});
export const deleteJobApplicantWorkExperienceFunc = createApiMethod<
  fromApplicantApiModels.DeleteJobApplicantWorkExperienceFuncParams,
  fromApplicantApiModels.DeleteJobApplicantWorkExperienceFuncResponse
>({
  url: ApplicantEndpoints.DeleteJobApplicantWorkExperienceFunc,
  method: 'DELETE',
});

// -------------------------------------------------------------------------------------------------

export const getJobApplicantEducationFunc = createApiMethod<
  fromApplicantApiModels.GetJobApplicantEducationFuncParams,
  fromApplicantApiModels.GetJobApplicantEducationFuncResponse
>({
  url: ApplicantEndpoints.GetJobApplicantEducationFunc,
  method: 'GET',
});
export const addJobApplicantEducationRoute = createApiMethod<
  fromApplicantApiModels.AddJobApplicantEducationRouteParams,
  fromApplicantApiModels.AddJobApplicantEducationRouteResponse
>({
  url: ApplicantEndpoints.AddJobApplicantEducationRoute,
  method: 'POST',
});
export const updateJobApplicantEducationFunc = createApiMethod<
  fromApplicantApiModels.UpdateJobApplicantEducationFuncParams,
  fromApplicantApiModels.UpdateJobApplicantEducationFuncResponse
>({
  url: ApplicantEndpoints.UpdateJobApplicantEducationFunc,
  method: 'PUT',
});
export const deleteJobApplicantEducationFunc = createApiMethod<
  fromApplicantApiModels.DeleteJobApplicantEducationFuncParams,
  fromApplicantApiModels.DeleteJobApplicantEducationFuncResponse
>({
  url: ApplicantEndpoints.DeleteJobApplicantEducationFunc,
  method: 'DELETE',
});

export const updateCustomSortOrderFunc = createApiMethod<
  fromApplicantApiModels.UpdateCustomSortOrderFuncParams,
  fromApplicantApiModels.UpdateCustomSortOrderFuncResponse
>({
  method: 'PUT',
  url: ApplicantEndpoints.UpdateCustomSortOrderFunc,
});
