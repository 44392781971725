import React from 'react';
import styled from 'styled-components';

import { ListViewSelectedBarProps } from './ListViewProps';

const ListViewSelectedBar: React.FC<ListViewSelectedBarProps> = ({
  className,
  onSelectAll,
  onDeselectAll,
  selected,
  isAllSelected,
}) => {
  const handleSelectAll = () => {
    onSelectAll();
  };

  const handleDeselectAll = () => {
    onDeselectAll();
  };

  return (
    <div className={className}>
      {selected ? (
        <>
          <p>{selected} Selected</p>
          <p className="divider">|</p>
          {!isAllSelected && (
            <p className="select-all" onClick={handleSelectAll}>
              Select all
            </p>
          )}
          {isAllSelected && (
            <p className="select-all" onClick={handleDeselectAll}>
              Deselect all
            </p>
          )}
        </>
      ) : null}
    </div>
  );
};

export default styled(ListViewSelectedBar)`
  display: flex;
  flex-direction: row;

  .divider {
    padding-left: 8px;
    padding-right: 8px;
    color: ${(props) => props.theme.colors.dark};
  }

  p {
    font-weight: 700;
    margin: 0;

    &.select-all {
      color: ${(props) => props.theme.colors.primary};
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
