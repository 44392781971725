import styled from 'styled-components';

import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

export const PageSubTitleItemRow = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
`;

export const PageSubTitleItem = styled.div`
  font-size: ${getThemeFontSize('small')};
  padding: 0 10px;
  flex: 1 0 auto;

  @media (min-width: 769px) {
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  @media (max-width: 768px) {
    width: 50%;
  }
`;
