import { useCallback } from 'react';

import { JobDuplicateModal } from 'pages/Jobs/Job/components/JobDuplicateModal';

import { useModal } from 'utils/hooks/useModal';

import { Job } from 'store/entities/jobs/models';

type UseJobDuplicateProps = {
  job: Job;
  redirect?: boolean;
};

export const useJobDuplicate = ({ job, redirect }: UseJobDuplicateProps) => {
  const { show } = useModal({});

  const jobDuplicateModalConfirm = useCallback(async () => {
    return show({
      children: JobDuplicateModal,
      childrenProps: {
        jobId: job.jobId,
        jobName: job.jobName,
        redirect,
      },
    });
  }, [show, job.jobId, job.jobName, redirect]);

  const jobDuplicateWithModal = useCallback(async () => {
    const result = await jobDuplicateModalConfirm();

    if (!result) {
      return;
    }
  }, [jobDuplicateModalConfirm]);

  return {
    jobDuplicateWithModal,
  };
};
