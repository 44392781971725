import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { list } from 'config/list';

import { PaginationState } from 'store/app-files/appFiles.models';
import { paginationHelper } from 'store/app-files/appFiles.reducer.helper';
import { loadResumeFiles } from 'store/app-files/resumes/resumes.effects';
import { ResumeFile, ResumeQueryInterface } from 'store/app-files/resumes/resumes.models';
import { AppFilesKeys } from 'store/constants';

export const resumesAdapter = createEntityAdapter<ResumeFile>({
  selectId: (file) => file.resumeId,
});

export const resumesSlice = createSlice({
  name: AppFilesKeys.resumes,
  initialState: resumesAdapter.getInitialState<PaginationState<ResumeFile, ResumeQueryInterface>>({
    pagination: {
      defaultPageSize: list.pageSize,
      defaultPageNo: 0,
      requests: {},
      lists: {},
    },
  }),
  reducers: {
    ...paginationHelper.reducers(resumesAdapter),
  },
  extraReducers: (builder) => {
    builder.addCase(loadResumeFiles.pending, paginationHelper.pending);
    builder.addCase(loadResumeFiles.fulfilled, paginationHelper.fulfilled(resumesAdapter));
    builder.addCase(loadResumeFiles.rejected, paginationHelper.rejected);
  },
});

export const resumesActions = resumesSlice.actions;

export const resumesReducer = resumesSlice.reducer;
