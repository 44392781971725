import { createAction } from '@reduxjs/toolkit';

import { WithRequired } from 'model/utils';

import { Applicant } from 'store/entities/applicants/models';
import { applicantSlice } from 'store/entities/applicants/reducer';
import { Job } from 'store/entities/jobs/models';

export * from 'store/entities/applicants/mappers';
export * from 'store/entities/applicants/models';
export { applicantSelectors } from 'store/entities/applicants/selectors';

const APPLICANTS_MODAL_SHOW = 'applicants/modal/show';
const APPLICANTS_MODAL_FETCH = 'applicants/modal/fetch';
const APPLICANTS_MODAL_EDIT_DETAILS_SHOW = 'applicants/modal/show/editDetails';
const APPLICANTS_MODAL_UPDATE_RATING = 'applicants/modal/update/rating';
const APPLICANTS_SEND_SINGLE_TO_ONBOARDING = 'applicants/sendSingleToOnboarding';
const APPLICANTS_STAGE_CHANGE = 'applicants/stage/change';
const APPLICANTS_STAGE_CHANGE_FROM_PIPELINE = 'applicants/stage/changeFromPipeline';
const APPLICANTS_PREVIOUS_JOB = 'applicants/previousJob';

const applicantsModalShow = createAction<Applicant['id']>(APPLICANTS_MODAL_SHOW);
const applicantsModalFetchAction = createAction<Pick<Applicant, 'applicantId' | 'jobId'>>(APPLICANTS_MODAL_FETCH);
const applicantsModalEditDetailsShow = createAction<Applicant['id']>(APPLICANTS_MODAL_EDIT_DETAILS_SHOW);
const applicantsModalUpdatePatch = createAction<WithRequired<Applicant, 'id'> & { updateList?: boolean }>(
  APPLICANTS_MODAL_UPDATE_RATING,
);
const sendSingleToOnboarding = createAction<Applicant['id']>(APPLICANTS_SEND_SINGLE_TO_ONBOARDING);

type ApplicantsStageChangeParams = {
  jobId: Job['id'];
  applicantId: Applicant['applicantId'];
  stageId: string;
  currentStageId: string;
  pushPath?: string;
};

const applicantsStageChange = createAction<ApplicantsStageChangeParams>(APPLICANTS_STAGE_CHANGE);
const applicantsStageChangeFromPipeline = createAction<ApplicantsStageChangeParams>(
  APPLICANTS_STAGE_CHANGE_FROM_PIPELINE,
);

const getPreviousJobApplicationsNumberAction =
  createAction<{ applicantId: string; candidateId: string }>(APPLICANTS_PREVIOUS_JOB);

export const applicantsActions = {
  ...applicantSlice.actions,
  applicantsModalShow,
  applicantsModalFetchAction,
  sendSingleToOnboarding,
  applicantsStageChange,
  applicantsStageChangeFromPipeline,
  applicantsModalEditDetailsShow,
  applicantsModalUpdatePatch,
  getPreviousJobApplicationsNumberAction,
};

export default applicantSlice.reducer;
