import { initialState } from 'containers/CandidateLists/store/initialState';

import { createListReducer } from 'utils/reducer/reducer-helper';

import { UIKeys } from 'store/constants';

export default createListReducer({
  name: UIKeys.candidateLists,
  initialState,
  reducers: {},
});
