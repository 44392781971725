import { AxiosResponse, CancelToken } from 'axios';

import { api } from 'api-client';
import { DictionaryEndpoints } from 'api-endpoints/dictionary/endpoints';
import { createApiMethod } from 'api-endpoints/utils';

import { ApiResponseWithPagination, Location } from 'model';

import { DictionaryKeys } from 'store/constants';

import { FindLocationsParams } from './models';

export const getDictionary = <T>(dictionaryKey: DictionaryKeys, cancelToken?: CancelToken): Promise<AxiosResponse<T>> =>
  api().request<T>({
    url: ['/api/dictionary', dictionaryKey].join('/'),
    cancelToken,
  });

export const findLocations = createApiMethod<
  FindLocationsParams & {
    cancelToken: CancelToken;
  },
  ApiResponseWithPagination<Location>
>({
  url: DictionaryEndpoints.SearchLocationsFunc,
});
