import * as yup from 'yup';

import { TalentPool } from 'store/entities/talent-pools';

export const validationSchemaNewTalentPool: yup.SchemaOf<
  Pick<TalentPool, 'id' | 'name' | 'categoryId' | 'categoryName' | 'positionId' | 'positionName'>
> = yup.object({
  id: yup.string().defined(),
  name: yup.string().required().label('Talent Pool Name'),
  categoryId: yup.string().required().label('Position Category'),
  categoryName: yup.string().defined(),
  positionId: yup.number().required().label('Position Name'),
  positionName: yup.string().defined(),
});
