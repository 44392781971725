import { PayloadAction } from '@reduxjs/toolkit';
import { Task } from 'redux-saga';
import { call, cancel, fork, put, SagaReturnType, select, take, takeLatest } from 'redux-saga/effects';

import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import * as fromCandidateActions from 'store/entities/candidates/candidate.actions';
import * as fromModalActions from 'store/modals/modals.actions';
import * as fromModalConstants from 'store/modals/modals.constants';
import { ExModal } from 'store/modals/modals.interfaces';
import { modalSagaWorker } from 'store/modals/modals.sagas';
import { modalById } from 'store/modals/modals.selectors';

function* onStepChangeBackgroundActions() {
  let task: Task | undefined;
  while (true) {
    const {
      payload: { id, page },
    }: PayloadAction<fromModalActions.UpdateWizardPage> = yield take(fromModalConstants.MODAL_PAGE_UPDATE);

    // Cancel ongoing process if exists
    if (task && task.isRunning()) {
      yield cancel(task);
    }

    switch (page) {
      case 'selectTalentPoolForBulkAdd':
        task = yield fork(prepareStepSelectTalentPool, id);
        break;
      case 'createNewTalentPoolForBulkAdd':
        task = yield fork(createNewTalentPool, id);
        break;
    }
  }
}

function* createNewTalentPool(id: string) {
  const modal: SagaReturnType<typeof modalById> = yield select(modalById, id);

  yield put(
    fromModalActions.exModalPropsAction({
      ...modal,
      modalConfig: {
        ...modal?.modalConfig,
        content: {
          ...modal?.modalConfig?.content,
          withTitle: true,
          title: 'Create new Talent Pool',
        },
      },
    }),
  );
}

function* prepareStepSelectTalentPool(id: string) {
  const modal: SagaReturnType<typeof modalById> = yield select(modalById, id);

  yield put(
    fromModalActions.exModalPropsAction({
      ...modal,
      modalConfig: {
        ...modal?.modalConfig,
        page: 'selectTalentPoolForBulkAdd',
        content: {
          ...modal?.modalConfig?.content,
          withTitle: true,
          title: 'Add to Talent Pool',
        },
      },
    }),
  );
}

function* handleTransition() {
  while (true) {
    const { payload }: PayloadAction<fromModalActions.WizardNavigation> = yield take([
      fromModalConstants.MODAL_WIZARD_FORWARD,
      fromModalConstants.MODAL_WIZARD_BACKWARD,
      fromModalConstants.MODAL_WIZARD_ERROR,
    ]);

    const id = payload.id;

    if (!id) {
      break;
    }

    const { modalConfig }: ExModal = yield select(modalById, id);
    const { page } = modalConfig || {};

    if (page === 'createNewTalentPoolForBulkAdd') {
      yield put(
        fromModalActions.updateWizardPage({
          id,
          page: 'selectTalentPoolForBulkAdd',
          modalConfig: {
            content: {
              withTitle: false,
            },
          },
        }),
      );
    }
  }
}

function* bulkAddToTalentPoolSaga() {
  const result = yield call(modalSagaWorker, {
    modalType: ModalsTypeKey.wizard,
    modalConfig: {
      wizardType: 'bulkAddToTalentTool',
      page: 'selectTalentPoolForBulkAdd',
      content: {
        title: 'Add to Talent Pool',
        withTitle: true,
      },
    },
    modalProps: {},
  });

  if (result.cancel) {
    return;
  }

  const modalId = result.confirm.payload.id;

  yield put(fromModalActions.exModalHideAction({ id: modalId }));
}

export function* bulkAddToTalentPoolSagas() {
  yield fork(handleTransition);
  yield fork(onStepChangeBackgroundActions);
}

export function* takeBulkAddToTalentPoolModalActions() {
  yield takeLatest(fromCandidateActions.bulkAddToTalentPoolAction, bulkAddToTalentPoolSaga);
}
