import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ApplicantStatusBadge } from 'containers/ApplicantLists/ApplicantStatusBadge';

import { CardDataLineCss, CardNameCss } from 'components/ui/ExCard/components';

interface HoverGradientProps {
  hovered: boolean;
}
export const HoverGradient = styled.div<HoverGradientProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.border.borderRadius};
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 75%);
  opacity: ${(props) => (props.hovered ? 1 : 0)};
  transition: opacity 0.3s;
  pointer-events: none;

  &.selected {
    opacity: 0;
  }
`;

export const ApplicantCardName = styled(Link)`
  ${CardNameCss};
  font-weight: ${(props) => props.theme.fonts.heading.fontWeight};
  width: 120px;
  padding-right: 1px;
`;

export const ApplicantAvatarWrapper = styled.div`
  position: relative;
  margin-right: 14px;
  flex: 0 0 52px;

  ${ApplicantStatusBadge} {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    min-width: 62px;
    padding: 1px 0;
  }
`;
export const ApplicantAvatar = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;

  img {
    width: 100%;
    max-width: 100%;
  }
`;

export const ApplicantInnerCard = styled.div`
  display: flex;
  flex-direction: row;

  .applicant-card__data-line {
    ${CardDataLineCss};
    color: ${(props) => props.theme.colors.blackHeadings};
    padding-left: 4px;

    dt {
      margin-right: 4px;
    }

    dd {
      flex: 1 0 100%;
      margin-bottom: 0;
    }
  }
`;

export const CardInfoWrapper = styled.div``;
