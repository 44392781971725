import styled, { css } from 'styled-components';

import { Ellipsis } from 'components/Ellipsis';
import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

export const FormStepsPanelSizeStyled = css`
  min-width: 260px;
  margin-right: 1.5rem;

  @media screen and (max-width: 992px) {
    min-width: 250px;
  }

  @media screen and (max-width: 768px) {
    min-width: 200px;
  }
`;

export const FormStepsPanelStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${getThemeBorderProps('borderRadius')};
  border: 1px solid ${getThemeBorderProps('borderColorOuter')};
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
  ${FormStepsPanelSizeStyled}
`;

export const FormStepsPanelTitle = styled.div`
  padding: 1rem;
  line-height: 1;
  font-weight: bold;
  font-size: ${getThemeFontSize('cardHeading')};
`;

export const SubTitleItem = styled(Ellipsis)`
  color: ${(p) => p.theme.colors.gray};
  font-weight: normal;
  max-width: 300px;
`;
