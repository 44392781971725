import React from 'react';

import { emptyAction, LoaderWrapperProps } from 'modules/LoaderManager/model';
import { useLoaderSubscription } from 'modules/LoaderManager/react';

export function createLoaderWrapper(Component: React.ElementType) {
  return ({ action, children, isLoading: isLoadingExternal, style }: LoaderWrapperProps) => {
    const { isLoading } = useLoaderSubscription(action ?? emptyAction);
    const isLoadingComputed = isLoadingExternal || isLoading;

    return (
      <>
        {isLoadingComputed && <Component style={style} />}
        {!isLoadingComputed && children}
      </>
    );
  };
}
