import { PipelineStageType } from 'model/api-enums.constants';

import * as hiringPipelineStagesTypes from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.types';
import * as hiringPipelinesTypes from 'store/entities/hiring-pipelines/hiring-pipelines.types';

export const HiringPipelineStage = (dto: object) => ({
  pipelineId: '' as string,
  pipelineStageId: '' as string,
  name: '' as string,
  description: '' as string,
  order: 0 as number,
  stageType: 0 as PipelineStageType,
  stageBadgeLabel: '' as string,
  actions: undefined as undefined | HiringPipelineActions[],
  pipelineActions: undefined as undefined | HiringPipelineActions[],
  stageButtonLabel: undefined as undefined | string | null,
  inUse: false as boolean | undefined,
  isRequired: false as boolean,
  needConfirmationModal: false as boolean,
  hasInterviewStageActions: false as boolean,
  ...dto,
});
//--------------------------------------------------------------------------------------------------
// Addition HiringPipelineStage Type
export type HiringPipelineActions = {
  pipelineActionId?: string;
  type?: hiringPipelinesTypes.PipelineActionType;
  actionItemId?: string;
  recipientType?: number | string;
};

export const PipelineActionType = Object.freeze({
  SendEmail: 1,
  Interview: 2,
});

//--------------------------------------------------------------------------------------------------

export type HiringPipelineStagePrepareCreate = Pick<
  hiringPipelineStagesTypes.HiringPipelineStage,
  'pipelineId' | 'name' | 'stageType' | 'actions' | 'stageBadgeLabel' | 'isRequired'
>;
export type HiringPipelineStagePrepareRemove = Pick<
  hiringPipelineStagesTypes.HiringPipelineStage,
  'pipelineId' | 'pipelineStageId'
>;
export type HiringPipelineStagePrepareEdit = Pick<
  hiringPipelineStagesTypes.HiringPipelineStage,
  'pipelineId' | 'pipelineStageId' | 'name' | 'stageType' | 'actions' | 'isRequired'
>;
