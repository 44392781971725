import React from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import SwitchTransition from 'react-transition-group/SwitchTransition';
import styled from 'styled-components/macro';

import { useAppSelector } from 'utils/hooks/useSelectors';

import { applicantModalSliceSelectors } from './store';

type ApplicantModalSwitchModeProps = {
  className?: string;
  editComponent: JSX.Element;
  viewComponent: JSX.Element;
};

const ApplicantModalSwitchModeStyled = styled.div`
  .fade-enter {
    opacity: 0;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-enter-active {
    opacity: 1;
  }

  .fade-exit-active {
    opacity: 0;
  }

  .fade-enter-done {
    opacity: 1;
  }

  .fade-exit-done {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-exit-active {
    transition: opacity 150ms;
  }
`;

const useApplicantModalSwitchModeState = ({ className }: ApplicantModalSwitchModeProps) => {
  const isEditMode = useAppSelector(applicantModalSliceSelectors.selectIsApplicantModalInEditMode);

  return {
    className,
    isEditMode,
  } as const;
};

export const ApplicantModalSwitchMode: React.FC<ApplicantModalSwitchModeProps> = (props) => {
  const { className, isEditMode } = useApplicantModalSwitchModeState(props);

  return (
    <ApplicantModalSwitchModeStyled className={className}>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={isEditMode ? 'edit' : 'view'}
          addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
          classNames="fade"
        >
          {isEditMode ? props.editComponent : props.viewComponent}
        </CSSTransition>
      </SwitchTransition>
    </ApplicantModalSwitchModeStyled>
  );
};
