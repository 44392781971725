import { useMemo } from 'react';
import type { ExEducation } from 'model';
import { format, parseISO } from 'date-fns';

import { dateFormat } from 'config/date';

import { getActualDate } from 'utils/funcs';

export function useEducationDateRange<T extends Partial<ExEducation>>(item: T) {
  const startDate = getActualDate(item.startDate);
  const expiryDate = getActualDate(item.expiryDate);
  const issueDate = getActualDate(item.issueDate);
  return useMemo(() => {
    const startYear = startDate ? format(parseISO(startDate), dateFormat.formatDateForEducationCard) : '--';
    const expiryYear = expiryDate ? format(parseISO(expiryDate), dateFormat.formatDateForEducationCard) : '--';
    const issueYear = issueDate ? format(parseISO(issueDate), dateFormat.formatDateForEducationCard) : false;

    return `${startYear} - ${issueYear ? issueYear : expiryYear}`;
  }, [expiryDate, issueDate, startDate]);
}
