import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { routerMiddleware } from 'connected-react-router';
import throttle from 'lodash/throttle';
import createSagaMiddleware from 'redux-saga';

import { publicNewApplicantFormActions } from 'pages/public/state/public-new-applicant-form/public-new-applicant-form.actions';

import { bulkUploadResumes } from 'containers/BulkCreation/bulkCreation.actions';

import { loadStateFrom, saveStateTo } from 'modules/Storage';
import { setGlobalDispatch, setGlobalGetState } from 'utils/globalStore';

import { emptyApi, emptyApiGlobal, emptyApiPublic } from 'store/entities/emptyApi';
import { interviewTemplateApi } from 'store/entities/interview-template/interview-template.api';
import { recentMentionsApi } from 'store/entities/recent-mentions/recent-mention.api';
import { history } from 'store/history';
import { rootReducer } from 'store/rootReducer';
import { rootSaga } from 'store/rootSagas';

const STATE_KEY = 'exRecState';

const sagaMiddleware = createSagaMiddleware();
const initialState = rootReducer(history)(undefined, { type: '@@init' });

const persistedState = loadStateFrom(localStorage, STATE_KEY, initialState);
const persistedStateWithSessionData = loadStateFrom(sessionStorage, STATE_KEY, persistedState ?? initialState);

const connectedRouterMiddleware = routerMiddleware(history);

const store = configureStore({
  reducer: rootReducer(history),
  preloadedState: persistedStateWithSessionData,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          // Ignore check for this actions - we send files though sagas
          bulkUploadResumes.type,
          publicNewApplicantFormActions.formUploadAction.type,
        ],
        ignoredActionPaths: ['payload.onCancel'],
      },
    }).concat(
      sagaMiddleware,
      connectedRouterMiddleware,
      recentMentionsApi.middleware,
      interviewTemplateApi.middleware,
      emptyApi.middleware,
      emptyApiGlobal.middleware,
      emptyApiPublic.middleware,
    ),
  enhancers: [],
  devTools: process.env.NODE_ENV === 'development',
});

setupListeners(store.dispatch);

sagaMiddleware.run(rootSaga);

const persistBranches = [
  'ui.jobLists.main.viewMode',
  'ui.applicantLists.placements.viewMode',
  'ui.talentPoolLists.main.viewMode',
  'ui.jobLists.main.filters.search',
  'ui.applicantLists.job.viewMode',
  'ui.applicantLists.job.pageNo',
  'ui.jobAdLists.jobBoard.viewMode',
  'ui.jobAdLists.jobBoard.viewMode',
  'auth.companyId',
  'ui.tips',
];

const sessionBranches = [
  'formJob.formData',
  'formJobBoard.formData',
  'formJobAd',
  'formApplicant.formData',
  'formApplicant.selectedResumes',
  'formApplicant.selectedDocuments',
  'ui.jobBoardLists.newJobAd.selectedItems',
  'ui.applicantLists.job.filters',
  'formCandidate.formData',
  'ui.candidateLists.newApplicant.filters',
];

store.subscribe(
  throttle(() => {
    const state = store.getState();
    saveStateTo(localStorage, STATE_KEY, state, persistBranches);
    saveStateTo(sessionStorage, STATE_KEY, state, sessionBranches);
  }, 1000),
);

setGlobalDispatch(store.dispatch);

setGlobalGetState(store.getState);

export default store;
