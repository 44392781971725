import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Step } from 'components/StepForm/index';

type UseSteps = {
  steps: Step[];
};
export const useSteps = ({ steps = [] }: UseSteps) => {
  const location = useLocation();
  const [isLastStep, toggleIsLastStep] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [stepsPrepared, setSteps] = useState<Step[]>(steps);
  const compareStepPath = (step: Step, path: string) => step.path === path;
  const findStepIdx = useCallback(
    (path: string) => steps.findIndex((step) => compareStepPath(step, path)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const updateSteps = useCallback(
    (path: Step['path']) => {
      const idx = findStepIdx(path);
      const updatedSteps: Step[] = steps.map((step, i) => (i <= idx ? { ...step, disabled: false } : { ...step }));
      setSteps(updatedSteps);
      return idx;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [findStepIdx],
  );

  const pathname = useMemo(() => location.pathname.split('/').pop(), [location.pathname]);

  useEffect(() => {
    const path = pathname;
    if (path) {
      const idx = findStepIdx(path);
      setCurrentStepIndex(idx);
      toggleIsLastStep(idx === steps.length - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findStepIdx, location.pathname]);
  useEffect(() => {
    const path = pathname;
    if (path) {
      updateSteps(path as Step['path']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    steps: stepsPrepared,
    updateSteps,
    isLastStep,
    pathname,
    currentStepIndex,
  };
};
