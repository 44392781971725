import { useContext } from 'react';
import { StylesConfig } from 'react-select';
import { DefaultTheme, ThemeContext } from 'styled-components';

/**
 * This hook used to generate styles for react select with styled components theme properties
 *
 * @param stylesConfigCallBack - function which will be invoked
 * with theme (DefaultTheme from style components context).
 *
 * @example
 *  const styles = useReactSelectThemedOverrideStyles((theme) => ({
    menu: base => ({
      ...base,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.border.borderColor,
      boxShadow: 'none',
      marginTop: '20px',
      position: 'static',
    }),
  }))
 */
export function useReactSelectThemedOverrideStyles(
  stylesConfigCallBack: (theme: DefaultTheme) => StylesConfig<any, any>,
) {
  const theme = useContext(ThemeContext);

  return stylesConfigCallBack(theme);
}
