import React, { useEffect, useMemo, useState } from 'react';
import { format, parseISO } from 'date-fns';

import { dateFormat } from 'config';

import { JobAdGeneralFormBody } from 'containers/JobAdForms';
import { jobAdFormEffects } from 'containers/JobAdForms/state';
import { editJobAdDetailsValidationSchema } from 'containers/JobAdForms/validators';
import { Loader } from 'containers/Loader';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';
import { modalsActions, modalsSelectors } from 'containers/Modals/store';

import { Form } from 'components';
import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { DialogProps } from 'components/Dialog';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { loaderThunkActionWrapper } from 'modules/LoaderManager/redux';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { JobAd, jobAdSelectors } from 'store/entities/job-ads';

import { EditJobAdDetailsModalStyled } from './EditJobAdDetailsModalComponents';
import { EditJobAdDetailsModalProps } from './EditJobAdDetailsModalProps';

export const EditJobAdDetailsModal: React.FC<EditJobAdDetailsModalProps> = ({ className, onClose }) => {
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();
  const jobAdId = useAppSelector(modalsSelectors.modalJobAdId);
  const item = useAppSelector(jobAdSelectors.getById, jobAdId);

  const content: DialogProps['content'] = {
    title: 'Edit Job Ad Details',
    withTitle: true,
  };

  const formValue = useMemo(
    () => ({
      ...item,
      ...(item?.startDate && {
        startDate: format(parseISO(item?.startDate), dateFormat.formatDateForDateInput),
      }),
      country: item?.jobAdCountry?.code.expedoCode2 || item?.country,
      jobAdName: item?.jobAdName || item?.name || item?.jobName,
    }),
    [item],
  );

  const submitHandler = async (data: Partial<JobAd>) => {
    dispatch(modalsActions.saveResult({ id: ModalsTypeKey.editJobAdDetails, payload: data }));
  };

  useEffect(() => {
    if (!item || !jobAdId) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [item, jobAdId]);

  if (!show) {
    return <Loader />;
  }

  const disableField: string[] = [];

  return (
    <EditJobAdDetailsModalStyled className={className} onClose={onClose} content={content}>
      <Form onSubmit={submitHandler} validationSchema={editJobAdDetailsValidationSchema} defaultValues={formValue}>
        <JobAdGeneralFormBody disableField={disableField} />
        <div className="d-flex justify-content-center">
          <ExButton variant="light" onClick={() => onClose(false)}>
            Cancel
          </ExButton>
          <ExLoaderHandlerWrapper action={loaderThunkActionWrapper(jobAdFormEffects.updateJobAd)}>
            <ExButton className="ml-3" variant="primary" type="submit">
              Update
            </ExButton>
          </ExLoaderHandlerWrapper>
        </div>
      </Form>
    </EditJobAdDetailsModalStyled>
  );
};
