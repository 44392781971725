import React from 'react';
import styled from 'styled-components/macro';

import { CandidateAvatar } from 'components/CandidateAvatar';
import { DetailsRowProps } from 'components/DetailsCard';
import { DialogProps } from 'components/Dialog';
import { JobSettingsCardContent } from 'components/JobSettingsCardRedesign/JobSettingsCardComponents';
import { ExDialog } from 'components/ui/ExDialog';
import { getEmploymentType } from 'utils/getEmploymentType';
import { getUserInitials } from 'utils/getUserInitials';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { Applicant, applicantSelectors } from 'store/entities/applicants';
import { jobsSelectors } from 'store/entities/jobs';
import { exModalConfirmAction } from 'store/modals/modals.actions';
import { ExModal } from 'store/modals/modals.interfaces';

type ApplicantConfirmMoveModalProps = {
  className?: string;
} & DialogProps &
  ExModal;

const ApplicantConfirmMoveModalStyled = styled(ExDialog)``;

const ModalMessage = styled.div`
  margin-bottom: 22px;
`;

const JobSettingsCardContentStyled = styled(JobSettingsCardContent)`
  padding: 10px 24px;
  background-color: ${({ theme }) => theme.colors.bodyBackgroundColor};
  height: auto;
`;

const CandidateNameStyled = styled.div`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
`;

const CandidateAvatarStyled = styled(CandidateAvatar)`
  width: 24px;
  height: 24px;
  margin-right: 7px;
`;

type CandidateNameProps = {
  applicant?: Applicant;
};

const CandidateName = ({ applicant }: CandidateNameProps) => {
  const userInitials = getUserInitials(`${applicant?.name || ''}`);

  return (
    <CandidateNameStyled>
      <CandidateAvatarStyled src={applicant?.photoUrl} initials={userInitials} />
      <div>{applicant?.name}</div>
    </CandidateNameStyled>
  );
};

const useApplicantConfirmMoveModalState = ({
  className,
  id,
  modalConfig,
  onClose,
  modalProps,
}: ApplicantConfirmMoveModalProps) => {
  const dispatch = useAppDispatch();

  const applicant = useAppSelector(applicantSelectors.getById, modalProps?.applicantId);
  const job = useAppSelector(jobsSelectors.getById, modalProps?.jobId);

  const employmentType = getEmploymentType(job?.employmentType);
  const position = job?.position?.name;

  const content = {
    ...modalConfig?.content,
    buttonOk: 'Confirm',
    buttonCancel: 'Cancel',
  };

  const message = content.message;

  const closeHandler = (isConfirm: boolean | null) => {
    if (isConfirm) {
      dispatch(exModalConfirmAction({ id }));
    } else {
      onClose(isConfirm);
    }
  };

  const rows: DetailsRowProps[] = [
    { label: 'Candidate Name', value: applicant?.name, component: () => <CandidateName applicant={applicant} /> },
    { label: 'Position', value: position },
    { label: 'Employment Type', value: employmentType },
    { label: 'Email', value: applicant?.email },
    { label: 'Mobile Number', value: applicant?.mobile },
  ];

  return {
    className,
    content,
    message,
    closeHandler,
    rows,
  } as const;
};

export const ApplicantConfirmMoveModal: React.FC<ApplicantConfirmMoveModalProps> = (props) => {
  const { className, content, message, closeHandler, rows } = useApplicantConfirmMoveModalState(props);

  return (
    <ApplicantConfirmMoveModalStyled className={className} content={content} onClose={closeHandler}>
      <div>
        <ModalMessage>{message}</ModalMessage>
        <JobSettingsCardContentStyled rows={rows} />
      </div>
    </ApplicantConfirmMoveModalStyled>
  );
};
