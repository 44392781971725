import React from 'react';
import FormBootstrap from 'react-bootstrap/Form';
import { FormProvider, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';

import { InputHidden } from './InputHidden/InputHidden';
import type { FormComponents, FormProps } from './FormProps';

const Form: React.FC<FormProps> & FormComponents = ({
  className,
  defaultValues,
  validationSchema,
  children,
  onSubmit,
  onSaveAsDraft,
  saveAsDraftHandlerRef,
  id,
  formOptions,
  isDraftValidation,
  onError,
}) => {
  let isDraft = false;

  const methods = useForm({
    defaultValues,
    shouldFocusError: true,
    shouldUnregister: false,
    ...(validationSchema && { resolver: yupResolver(validationSchema) }),
    ...formOptions,
    ...(isDraftValidation
      ? {
          context: {
            getIsDraft() {
              return isDraft;
            },
          },
        }
      : {}),
  });

  const formHandler = methods.handleSubmit(onSubmit, onError);

  if (saveAsDraftHandlerRef && typeof onSaveAsDraft === 'function') {
    saveAsDraftHandlerRef.current = () => {
      isDraft = true;
      methods.handleSubmit(onSaveAsDraft)();
    };
  }

  function isValidReactElementWithPropName<P extends { name: string }>(
    child: {} | null | undefined,
  ): child is React.ReactElement<P> {
    return React.isValidElement<P>(child) && Boolean(child.props.name);
  }

  return (
    <>
      <FormProvider {...methods}>
        <FormBootstrap id={id} noValidate className={className} onSubmit={formHandler}>
          {React.Children.map(children, (child) =>
            isValidReactElementWithPropName(child)
              ? React.createElement(child.type, {
                  ...{
                    ...child.props,
                    inputRef: methods.register,
                    validated: methods.formState.isSubmitted,
                    errors: methods.errors[child.props.name],
                    key: child.props.name,
                    setValue: methods.setValue,
                    control: methods.control,
                  },
                })
              : child,
          )}
        </FormBootstrap>
      </FormProvider>
      {process.env.NODE_ENV === 'development' && <DevTool control={methods.control} />}
    </>
  );
};

Form.InputHidden = InputHidden;

export default styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;

  > * {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;
