import { jobAdFormEffects } from 'containers/JobAdForms/state';
import {
  UseAbilityItems,
  useAbilityItems,
} from 'containers/Modals/ModalsContent/Job/JobAbilitiesModal/hooks/useAbilityItems';
import { modalsSelectors } from 'containers/Modals/store';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { jobAdSelectors } from 'store/entities/job-ads';

type UseJobAdAbilityItems = {
  onClose: (result: boolean | null) => void;
} & UseAbilityItems;

export function useJobAdAbilityItems({ abilityEntity, onClose, validationSchemaLabel }: UseJobAdAbilityItems) {
  const dispatch = useAppDispatch();
  const entityId = useAppSelector(modalsSelectors.modalJobAdId);
  const entity = useAppSelector(jobAdSelectors.getById, entityId);

  const { items, onSubmitValidation } = useAbilityItems({
    abilityEntity,
    entity,
    entityId,
    onSubmitValidationCallback: async (jobAdId, data) => {
      await dispatch(
        jobAdFormEffects.updateJobAd({
          data,
          jobAdId,
          preloader: true,
        }),
      );
      onClose(true);
    },
    validationSchemaLabel,
  });

  return { items, onSubmitValidation } as const;
}
