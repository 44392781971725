import { createEntityAdapter } from '@reduxjs/toolkit';

import { providesList } from 'api-client/axiosBaseQuery';
import { replaceParamsInUrl } from 'api-client/utils';
import { HiringPipelineEndpointsForApi } from 'api-endpoints/hiring-pipeline/endpoints';
import { SearchPipelineFuncResponse } from 'api-endpoints/hiring-pipeline/hiring-pipeline.dto';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';

import { getSuccessToastForEntityAction } from 'utils/i18utils';

import { emptyApi } from 'store/entities/emptyApi';
import { HiringPipelineStage } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.types';
import { HiringPipeline, HiringPipelineDefaultFilters } from 'store/entities/hiring-pipelines/hiring-pipelines.types';

export const HIRING_PIPELINE_TAG_TYPE = 'HiringPipeline';

export const hiringPipelineAdapter = createEntityAdapter<HiringPipeline>({
  selectId: (item) => item.pipelineId,
});

export const hiringPipelineStagesAdapter = createEntityAdapter<HiringPipelineStage>({
  selectId: (item) => item.pipelineStageId,
});

export const extendedHiringPipelineApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    searchPipeline: builder.query<SearchPipelineFuncResponse, HiringPipelineDefaultFilters>({
      query: (params) => ({
        url: replaceParamsInUrl(HiringPipelineEndpointsForApi.SearchPipelineFunc, {}),
        method: 'GET',
        params,
      }),
      transformResponse(data: any) {
        return {
          pipeline: hiringPipelineAdapter.setMany(
            hiringPipelineAdapter.getInitialState({
              pageCount: data.pageCount,
              pageNo: data.pageNo,
              pageSize: data.pageSize,
              totalItemsCount: data.totalItemsCount,
            }),
            data ?? [],
          ),
          pipelineStages: hiringPipelineStagesAdapter.setMany(
            hiringPipelineStagesAdapter.getInitialState(),
            (data ?? []).map((hiringPipeline) => hiringPipeline.stages).flat(),
          ),
        };
      },
    }),
    getDefaultPipeline: builder.query({
      query: () => ({
        url: replaceParamsInUrl(HiringPipelineEndpointsForApi.GetDefaultPipelineFunc, {}),
        method: 'GET',
      }),
    }),
    getPipeline: builder.query({
      query: ({ pipelineId }) => ({
        url: replaceParamsInUrl(HiringPipelineEndpointsForApi.GetPipelineFunc, { pipelineId }),
        method: 'GET',
      }),
      transformResponse(response: HiringPipeline) {
        return {
          pipeline: hiringPipelineAdapter.setOne(hiringPipelineAdapter.getInitialState(), response ?? {}),
          pipelineStages: hiringPipelineStagesAdapter.setMany(
            hiringPipelineStagesAdapter.getInitialState(),
            response?.stages ?? [],
          ),
        };
      },
    }),
    getClonedPipeline: builder.mutation({
      query: ({ pipelineId }) => ({
        url: replaceParamsInUrl(HiringPipelineEndpointsForApi.GetPipelineFunc, { pipelineId }),
        method: 'GET',
      }),
    }),
    clonePipeline: builder.mutation({
      query: ({ pipelineId, data }) => ({
        url: replaceParamsInUrl(HiringPipelineEndpointsForApi.ClonePipelineFunc, { pipelineId }),
        method: 'POST',
        data,
      }),
    }),
    deletePipeline: builder.mutation({
      query: ({ pipelineId, params }) => ({
        url: replaceParamsInUrl(HiringPipelineEndpointsForApi.DeletePipelineFunc, { pipelineId }),
        method: 'DELETE',
        params,
      }),
    }),
    updatePipeline: builder.mutation({
      query: ({ pipelineId, data }) => ({
        url: replaceParamsInUrl(HiringPipelineEndpointsForApi.UpdatePipelineFunc, { pipelineId }),
        method: 'PUT',
        data,
      }),
    }),
    setDefaultPipeline: builder.mutation({
      query: ({ pipelineId }) => ({
        url: replaceParamsInUrl(HiringPipelineEndpointsForApi.SetDefaultPipelineFunc, {
          pipelineId,
        }),
        method: 'PUT',
      }),
    }),
    enablePipeline: builder.mutation({
      query: ({ pipelineId }) => ({
        url: replaceParamsInUrl(HiringPipelineEndpointsForApi.EnablePipelineFunc, {
          pipelineId,
        }),
        method: 'PUT',
      }),
    }),
    disablePipeline: builder.mutation({
      query: ({ pipelineId }) => ({
        url: replaceParamsInUrl(HiringPipelineEndpointsForApi.DisablePipelineFunc, {
          pipelineId,
        }),
        method: 'PUT',
      }),
    }),
    createPipelineStage: builder.mutation({
      query: ({ pipelineId, data }) => ({
        url: replaceParamsInUrl(HiringPipelineEndpointsForApi.CreatePipelineStageFunc, { pipelineId }),
        method: 'POST',
        data,
      }),
    }),
    deletePipelineStage: builder.mutation({
      query: ({ pipelineId, pipelineStageId }) => ({
        url: replaceParamsInUrl(HiringPipelineEndpointsForApi.DeletePipelineStageFunc, { pipelineId, pipelineStageId }),
        method: 'DELETE',
      }),
    }),
    updatePipelineStage: builder.mutation({
      query: ({ pipelineId, pipelineStageId, data }) => ({
        url: replaceParamsInUrl(HiringPipelineEndpointsForApi.UpdatePipelineStageFunc, { pipelineId, pipelineStageId }),
        method: 'PUT',
        data,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          const message = getSuccessToastForEntityAction({
            name: args.data.name,
            action: 'updated',
            entityName: 'pipelineStage',
          });
          dispatch(alertsEffects.showSuccess({ message }));
        } catch (e) {}
      },
    }),
    updatePipelineStagesOrder: builder.mutation({
      query: ({ pipelineId, data }) => ({
        url: replaceParamsInUrl(HiringPipelineEndpointsForApi.UpdatePipelineStageOrderFunc, { pipelineId }),
        method: 'PUT',
        data,
      }),
    }),
    stageGetActions: builder.mutation({
      query: ({ pipelineId, pipelineStageId }) => ({
        url: replaceParamsInUrl(HiringPipelineEndpointsForApi.GetPipelineStageActionsFunc, {
          pipelineId,
          pipelineStageId,
        }),
        method: 'GET',
      }),
    }),
    stageAddSendEmailAction: builder.mutation({
      query: ({ pipelineId, pipelineStageId, data }) => ({
        url: replaceParamsInUrl(HiringPipelineEndpointsForApi.AddSendEmailActionFunc, { pipelineId, pipelineStageId }),
        method: 'POST',
        data,
      }),
    }),
    stageDeleteSendEmailAction: builder.mutation({
      query: ({ pipelineId, pipelineStageId, pipelineStageActionId }) => ({
        url: replaceParamsInUrl(HiringPipelineEndpointsForApi.DeleteSendEmailActionFunc, {
          pipelineId,
          pipelineStageId,
          pipelineStageActionId,
        }),
        method: 'DELETE',
      }),
    }),
    addPipelineStageActionFunc: builder.mutation({
      query: ({ pipelineId, pipelineStageId, data }) => ({
        url: replaceParamsInUrl(HiringPipelineEndpointsForApi.AddPipelineStageActionFunc, {
          pipelineId,
          pipelineStageId,
        }),
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const enhancedHiringPipelineApi = extendedHiringPipelineApi.enhanceEndpoints({
  addTagTypes: [HIRING_PIPELINE_TAG_TYPE],
  endpoints: {
    searchPipeline: {
      providesTags: (result) => providesList(result?.pipeline.ids, HIRING_PIPELINE_TAG_TYPE),
    },
    getPipeline: {
      providesTags: (_, __, { pipelineId }) => {
        return [{ type: HIRING_PIPELINE_TAG_TYPE, id: pipelineId }];
      },
    },
    getClonedPipeline: {
      invalidatesTags: () => [{ type: HIRING_PIPELINE_TAG_TYPE, id: 'LIST' }],
    },
    deletePipeline: {
      invalidatesTags: (_, __, { pipelineId }) => [{ type: HIRING_PIPELINE_TAG_TYPE, id: pipelineId }],
    },
    updatePipeline: {
      invalidatesTags: (_, __, { pipelineId }) => [{ type: HIRING_PIPELINE_TAG_TYPE, id: pipelineId }],
    },
    setDefaultPipeline: {
      invalidatesTags: (_, __, { pipelineId }) => [{ type: HIRING_PIPELINE_TAG_TYPE, id: pipelineId }],
    },
    enablePipeline: {
      invalidatesTags: (_, __, { pipelineId }) => [{ type: HIRING_PIPELINE_TAG_TYPE, id: pipelineId }],
    },
    disablePipeline: {
      invalidatesTags: (_, __, { pipelineId }) => [{ type: HIRING_PIPELINE_TAG_TYPE, id: pipelineId }],
    },
  },
});

export const { useSearchPipelineQuery, useGetDefaultPipelineQuery, useGetPipelineQuery } = enhancedHiringPipelineApi;
