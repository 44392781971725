import { useContext } from 'react';
import { StylesConfig } from 'react-select';
import { ThemeContext } from 'styled-components';

import { useAppSelectStyles } from 'components/FormTagSelect/FormTagSelectStyles';

export function useKeywordSelectStyles(): StylesConfig<any, any> {
  const theme = useContext(ThemeContext);
  const appSharedStyles = useAppSelectStyles([
    'menuList',
    'multiValue',
    'multiValueLabel',
    'multiValueRemove',
    'option',
    'placeholder',
    'input',
  ]);
  return {
    ...appSharedStyles,
    control: (base) => {
      return {
        ...base,
        borderColor: `${theme.border.borderColor}`,
        borderRadius: '20px',
        boxShadow: 'none',
        '&:hover': {},
      };
    },
    valueContainer: (base) => ({
      ...base,
      alignItems: 'flex-start',
      padding: '7px 13px',
    }),
  };
}
