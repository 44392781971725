import React, { useCallback, useMemo, useRef, useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import debounce from 'lodash/debounce';

import { ExScreeningQuestion } from 'model';

import { ExSyncSelect } from 'components';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { jobScreeningQuestionsSelectors } from 'store/entities/job-screening-questions';
import {
  useLazySearchScreeningQuestionLoadMoreQuery,
  useSearchScreeningQuestionLoadMoreQuery,
} from 'store/entities/screening-question';
import { orderByCreatedOnDesc } from 'store/ui/company/company-screening-question-list/company-screening-question-list.reducer';

type ScreeningQuestionFormSelectProps = {
  onChange?: (selectedQuestion: ExScreeningQuestion) => void;
  label?: string;
};

type ScreeningQuestionFormSelectOption = {
  questionId: EntityId;
  questionTitle: string;
};

const mapScreeningQuestionFormSelectOptionsByIds = ({ ids, entities }) => {
  if (!ids || !entities || !ids?.length || !Object.keys(entities).length) {
    return [];
  }

  return ids.reduce((acc: ScreeningQuestionFormSelectOption[], questionId) => {
    const question = entities[questionId];

    if (question) {
      acc.push({
        questionId,
        questionTitle: question.questionTitle,
      });
    }

    return acc;
  }, []);
};

type ScreeningQuestionFormSelectStateProps = ScreeningQuestionFormSelectProps & {
  pageSize?: number;
};

function useScreeningQuestionFormSelectState({
  pageSize,
  onChange,
  label = 'Company Screening Questions',
}: ScreeningQuestionFormSelectStateProps) {
  const [currentFilters, setCurrentFilters] = useState<{
    pageNo: number;
    searchTerm?: string;
    pageSize?: number;
    orderBy?: string;
  }>({
    pageNo: 0,
    searchTerm: undefined,
    pageSize,
    orderBy: orderByCreatedOnDesc,
  });

  const { data } = useSearchScreeningQuestionLoadMoreQuery({
    ...currentFilters,
    pageNo: 0,
  });

  const [loadNextPage, { isFetching: isFetchingCurrentPage, data: pageData }] =
    useLazySearchScreeningQuestionLoadMoreQuery();

  const currentFiltersRef = useRef({
    filters: currentFilters,
    pageCount: data?.pageCount ?? 0,
    isFetchingCurrentPage,
  });
  currentFiltersRef.current = {
    filters: currentFilters,
    pageCount: data?.pageCount ?? 0,
    isFetchingCurrentPage,
  };

  const hasNextPage = (pageData?.pageNo ?? 0) + 1 < (data?.pageCount ?? 0);

  const loadMoreScreeningQuestions = useCallback(() => {
    if (currentFiltersRef.current?.isFetchingCurrentPage) {
      return;
    }

    const nextPageNo = currentFiltersRef.current?.filters?.pageNo + 1;
    const pageCount = currentFiltersRef.current?.pageCount ?? 0;

    if (nextPageNo >= pageCount) {
      return;
    }

    const newFilters = {
      ...currentFiltersRef.current?.filters,
      pageNo: nextPageNo,
    };

    setCurrentFilters(newFilters);

    loadNextPage(newFilters);
  }, [loadNextPage]);

  const jobScreeningQuestions = useAppSelector(jobScreeningQuestionsSelectors.selectState);

  const optionIds = useMemo(
    () => (data ? data.ids.filter((questionId) => !jobScreeningQuestions.ids.includes(questionId)) : []),
    [data, jobScreeningQuestions],
  );

  const options = useMemo(
    () =>
      mapScreeningQuestionFormSelectOptionsByIds({
        ids: optionIds,
        entities: data?.entities,
      }),
    [optionIds, data?.entities],
  );

  const onScreeningQuestionChange = useCallback<(s: ScreeningQuestionFormSelectOption) => void>(
    (selected) => {
      const selectedQuestion = data?.entities[selected?.questionId];

      if (onChange && selectedQuestion) {
        onChange(selectedQuestion);
      }

      if (options.length - 1 < 4 && hasNextPage) {
        loadMoreScreeningQuestions();
      }
    },
    [data?.entities, hasNextPage, loadMoreScreeningQuestions, onChange, options.length],
  );

  const onInputScreeningQuestionChange = useMemo(
    () =>
      debounce((inputValue: string) => {
        const searchTerm = inputValue || undefined;

        if (searchTerm === currentFilters.searchTerm) {
          return;
        }

        const newFilters = {
          ...currentFilters,
          pageNo: 0,
          searchTerm,
        };

        setCurrentFilters(newFilters);

        loadNextPage(newFilters);
      }, 500),
    [currentFilters, loadNextPage],
  );

  return {
    options,
    isFetchingCurrentPage,
    loadMoreScreeningQuestions,
    onScreeningQuestionChange,
    onInputScreeningQuestionChange,
    label,
  } as const;
}

export const ScreeningQuestionFormSelect: React.FC<ScreeningQuestionFormSelectProps> = (props) => {
  const {
    options,
    isFetchingCurrentPage,
    loadMoreScreeningQuestions,
    onScreeningQuestionChange,
    onInputScreeningQuestionChange,
    label,
  } = useScreeningQuestionFormSelectState({ pageSize: 10, ...props });

  return (
    <ExSyncSelect
      className="col-md-6"
      label={label}
      placeholder="Select question"
      options={options}
      getOptionLabel={(option: ScreeningQuestionFormSelectOption) => option.questionTitle}
      getOptionValue={(option: ScreeningQuestionFormSelectOption) => option.questionId}
      onInputChange={onInputScreeningQuestionChange}
      onChange={onScreeningQuestionChange}
      onMenuScrollToBottom={loadMoreScreeningQuestions}
      isLoading={isFetchingCurrentPage}
      controlShouldRenderValue={false}
    />
  );
};
