import styled from 'styled-components';

import { ExCard } from 'components/ui/ExCard';
import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

export const Description = styled.div`
  line-height: 1.8;
  display: flex;
`;
export const Title = styled.div`
  font-weight: bold;
  margin-bottom: 1.375rem;
  font-size: ${getThemeFontSize('extraLarge')};
`;
export const StepDescriptionStyled = styled(ExCard)`
  margin-bottom: 20px;
`;
