import { Currency, ExNonPassedInterviewTemplate, Location } from 'model';
import {
  EmploymentType,
  Frequency,
  PipelineStageType,
  PlacementApplicantStage,
  SalaryBudgetStatus,
  SeniorityLevel,
} from 'model/api-enums.constants';

import type { EnumLiteralsOf } from 'utils/funcs';
import type { BasicModel } from 'utils/reducer/reducer-helper';

import { DocumentFile } from 'store/app-files/documents/documents.models';
import { ResumeFile } from 'store/app-files/resumes/resumes.models';
import { AvailabilityObject, Candidate } from 'store/entities/candidates/models';
import type { HiringPipelineStage } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.types';
import { ApplicantsFilters } from 'store/ui/applicant-filters';

export const PipelineStageTypeKeys = Object.keys(PipelineStageType).filter((value) => parseInt(value, 10) < 0) as Array<
  keyof typeof PipelineStageType
>;

export enum Source {
  Other,
  JobBoard,
  TalentPool,
  Website,
  SocialMedia,
}

export interface Applicant extends BasicModel {
  applicantId: string;
  appliedOn: string;
  candidateId?: Candidate['id'];
  deadline?: string;
  doNotHire?: boolean;
  documentFileType?: string;
  documentId?: DocumentFile['documentId'];
  coverLetterPreviewFileId: string | null;
  email?: string | null;
  employmentType?: EmploymentType;
  firstName?: string | null;
  isLocked?: boolean;
  jobApplicantAvailability: AvailabilityObject | null;
  jobApplicantId?: string;
  jobBoardLogo?: null | string;
  jobId: string;
  jobName?: string;
  jobReference?: string;
  jobReferenceNumber?: string;
  keywords?: string[];
  lastName?: string | null;
  lastRole?: null | string;
  location: Location | null;
  locationFormatted?: string;
  mobile?: string | null;
  name: string | null;
  numberOfComments?: number;
  numberOfInterviews?: number;
  onboardingStatus: string;
  onCostValue?: number;
  phone?: string | null;
  photoId?: string | null;
  photoUrl?: string | null;
  pipelineId?: string;
  pipelineStageId?: string;
  pipelineStageName: string;
  positionName?: string;
  previousApplicationsNumber?: number;
  rating?: number;
  resumeFileType?: string;
  resumeId?: ResumeFile['resumeId'];
  resumePreviewFileId: string | null;
  salaryBudgetStatus?: EnumLiteralsOf<typeof SalaryBudgetStatus>;
  salaryExpectationAmount: number;
  salaryExpectationCurrency: Currency['currency'] | null;
  salaryExpectationFrequency: Frequency | null;
  seniorityLevel: EnumLiteralsOf<typeof SeniorityLevel> | null;
  source: Source;
  sourceName?: string;
  stageChangedOn?: string;
  stageName?: string;
  stageType: PipelineStageType;
  status?: PipelineStageType;
  note: string | null;
  nonPassedInterviewTemplates: ExNonPassedInterviewTemplate[] | null;
  customSortIndex: number | null;
}

export interface ApplicantCard {
  applicantId: string;
  avatar: string;
  fullName: string;
  lastRole: string;
  seniorityLevel: string;
  location: string;
  salaryExpectation: string;
  availability: string;
  source: string;
  rating: number;
  onboardingStatus: string | null;
}

export interface ApplicantTableRow {
  id: string;
  applicantId: string;
  profile: Applicant;
  rating: number;
  lastRole: string;
  seniority: string;
  location: string;
  salaryExpectation: string;
  source: string;
  availability: string;
  seniorityLevel: string;
  onboardingStatus: string | null;
}

export enum ApplicantFiltersNames {
  keywords = 'keywords',
  locations = 'locations',
  availabilities = 'availabilities',
  sources = 'sources',
  status = 'status',
  belongsTo = 'belongsTo',
  jobName = 'jobName',
  name = 'name',
  placementStage = 'placementStage',
  countries = 'countries',
  pipelineStages = 'pipelineStages',
  employer = 'employer',
  education = 'education',
  searchTerm = 'searchTerm',
}

export enum ApplicantBelongsTo {
  job = 'job',
  candidate = 'candidate',
  placements = 'placements',
  dashboard = 'dashboard',
  applicants = 'applicants',
}

export interface ApplicantFilterOpt<P> {
  value: P;
  label: string;
}

export interface ApplicantFilters {
  [ApplicantFiltersNames.keywords]?: string[];
  [ApplicantFiltersNames.locations]?: string[];
  [ApplicantFiltersNames.availabilities]?: AvailabilityObject[];
  [ApplicantFiltersNames.sources]?: Source[];
  [ApplicantFiltersNames.status]?: PlacementApplicantStage;
  [ApplicantFiltersNames.pipelineStages]?: Array<HiringPipelineStage['pipelineId']>;
  [ApplicantFiltersNames.belongsTo]?: [ApplicantBelongsTo, BasicModel['id']];
  [ApplicantFiltersNames.jobName]?: string;
  [ApplicantFiltersNames.name]?: string;
  [ApplicantFiltersNames.placementStage]?: string;
  [ApplicantFiltersNames.countries]?: string[];
  [ApplicantFiltersNames.searchTerm]?: string;
  [ApplicantFiltersNames.employer]?: string;
  [ApplicantFiltersNames.education]?: string;
}

export enum SortBy {
  name = 'Name',
  applied = 'AppliedOn',
  source = 'Source',
  jobName = 'JobName',
  deadline = 'Deadline',
  rating = 'Rating',
  position = 'PositionName',
  seniority = 'Seniority',
  location = 'Location',
  salaryExpectation = 'Salary',
  starDate = 'StartDate',
  employmentType = 'EmploymentType',
  onboardingStatus = 'OnboardingStatus',
  pipelineStageName = 'PipelineStageName',
}

type ApplicantHeadersProps = keyof Pick<
  ApplicantsFilters,
  'rating' | 'lastRole' | 'seniorityLevel' | 'location' | 'salaryExpectation' | 'availability' | 'source'
>;

export const applicantHeadersKeys: Array<ApplicantHeadersProps> = [
  'rating',
  'lastRole',
  'seniorityLevel',
  'location',
  'salaryExpectation',
  'availability',
  'source',
];

export const applicantHeadersNames: Record<ApplicantHeadersProps, string> = {
  rating: 'Rating',
  lastRole: 'Last Role',
  seniorityLevel: 'Seniority Level',
  location: 'Location',
  salaryExpectation: 'Salary Expectation',
  availability: 'Availability',
  source: 'Source',
};
