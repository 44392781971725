import lowerCase from 'lodash/lowerCase';

import { prop } from 'utils/list';

import { SortDirections } from 'store/constants';

const sortByField =
  <T extends Record<string, any>, K extends string>(fieldName: K, direction: SortDirections) =>
  (itemA: T, itemB: T) => {
    const itemAToLowerCase = lowerCase(prop(itemA, fieldName));
    const itemBToLowerCase = lowerCase(prop(itemB, fieldName));

    switch (true) {
      case itemAToLowerCase === itemBToLowerCase:
        return 0;
      case direction === SortDirections.asc:
        return itemAToLowerCase > itemBToLowerCase ? 1 : -1;
      case direction === SortDirections.desc:
        return itemAToLowerCase < itemBToLowerCase ? 1 : -1;
      default:
        return 0;
    }
  };

export function useEntitySort<T>(sortMode: { orderBy?: string; orderDir?: SortDirections }, entityList: T[]) {
  if (!sortMode.orderBy || !sortMode.orderDir) {
    return entityList;
  }

  return [...entityList].sort(sortByField(sortMode.orderBy, sortMode.orderDir));
}
