import jobBoardListSlice from './reducers';
import * as selectors from './selectors';

const { reducer, actions } = jobBoardListSlice;

export const jobBoardListActions = actions;

export const jobBoardListSelectors = selectors;

export default reducer;

export * from './models';
