import React from 'react';
import styled from 'styled-components';

import { CardTitleProps } from './CardTitleProps';

const CardTitlePure: React.FC<CardTitleProps> = ({ title = 'Title', children, className }) => {
  return <div className={className}>{children || title}</div>;
};

export const CardTitle = styled(CardTitlePure)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8em;
  font-weight: 600;
  border-color: ${({ theme }) => theme.border.borderColorInner};
  border-bottom-width: 1px;
  border-bottom-style: solid;
`;
