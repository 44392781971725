import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';

import { list } from 'config';

import * as fromApplicantApi from 'api-endpoints/applicant';

import {
  applicantModalSlice,
  applicantModalSliceSelectors,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/store';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { invokeApiCall, ReturnData } from 'utils/sagas';

import { createTemporaryComment } from 'store/entities/applicant-comments/applicant-comments.utils';

import { applicantCommentsActions } from './applicant-comments.actions';
import { applicantCommentsSelectors } from './applicant-comments.selectors';
import {
  createDirectCommentActionLoader,
  makeCommentRead,
  removeCommentConfirmModal,
} from './applicant-comments.utils';

function* fetchWorker(pageNo: number, jobId: string, applicantId: string) {
  const { data, errorData, message }: ReturnData<typeof fromApplicantApi.searchCommentsForJobApplicantFunc> =
    yield invokeApiCall(fromApplicantApi.searchCommentsForJobApplicantFunc, {
      urlParams: {
        applicantId,
        jobId,
      },
      params: {
        pageNo,
        pageSize: list.pageSize,
      },
    });

  if ([errorData, message].some(Boolean)) {
    return;
  }

  if (data) {
    yield all([
      put(applicantCommentsActions.upsertMany(data.items)),
      put(applicantCommentsActions.setPageCount({ pageCount: data?.pageCount })),
      put(applicantModalSlice.actions.set({ numberOfComments: data.totalItemsCount })),
    ]);
  }

  return data;
}

function* applicantCommentsFetchWorker(action: ReturnType<typeof applicantCommentsActions.applicantCommentsFetch>) {
  yield startLoader(action);

  const { applicantId, jobId } = action.payload;

  yield put(applicantCommentsActions.setCurrentApplicantIdAndJobId({ applicantId, jobId }));

  const pageForLoad = 0;

  const data = yield call(fetchWorker, pageForLoad, jobId, applicantId);

  yield put(applicantCommentsActions.updatePage({ lastLoadedPage: pageForLoad, pageCount: data?.pageCount }));

  yield stopLoader(action);
}

function* applicantCommentsFetchMoreWorker(
  action: ReturnType<typeof applicantCommentsActions.applicantCommentsFetchMore>,
) {
  const { applicantId, jobId, lastLoadedPage, pageCount }: ReturnType<typeof applicantCommentsSelectors.selectCurrent> =
    yield select(applicantCommentsSelectors.selectCurrent);

  if (!applicantId || !jobId) {
    return;
  }

  yield startLoader(action);

  yield put(applicantCommentsActions.setCurrentApplicantIdAndJobId({ applicantId, jobId }));

  const pageForLoad = lastLoadedPage === pageCount - 1 ? lastLoadedPage : lastLoadedPage + 1;

  const data = yield call(fetchWorker, pageForLoad, jobId, applicantId);

  yield put(applicantCommentsActions.updatePage({ lastLoadedPage: pageForLoad, pageCount: data?.pageCount }));

  yield stopLoader(action);
}

function* applicantCommentsCreateWorker(action: ReturnType<typeof applicantCommentsActions.applicantCommentsCreate>) {
  const { applicantId, jobId }: ReturnType<typeof applicantCommentsSelectors.selectCurrent> = yield select(
    applicantCommentsSelectors.selectCurrent,
  );

  if (!applicantId || !jobId) {
    return;
  }

  const data = {
    ...action.payload,
    applicantId,
    jobId,
  };

  const numberOfComments = yield select(applicantModalSliceSelectors.selectApplicantModalNumberOfComments);

  const { temporaryComment, temporaryCommentCommentId } = yield createTemporaryComment(data);

  yield put(applicantCommentsActions.addOne(temporaryComment));

  const directCommentActionLoader = createDirectCommentActionLoader({
    payload: {
      commentId: temporaryCommentCommentId,
    },
  });
  const loaderActions = [action, directCommentActionLoader];
  yield startLoader(loaderActions);

  const {
    data: savedData,
    errorData,
    message,
  }: ReturnData<typeof fromApplicantApi.addCommentForJobApplicantFunc> = yield invokeApiCall(
    fromApplicantApi.addCommentForJobApplicantFunc,
    {
      data,
      urlParams: {
        applicantId,
        jobId,
      },
    },
  );
  yield stopLoader(loaderActions);
  if ([errorData, message].some(Boolean)) {
    return undefined;
  }

  yield all([
    put(
      applicantCommentsActions.addOneAndReplace({
        ...temporaryComment,
        fakeId: temporaryCommentCommentId,
        ...savedData,
      }),
    ),
    put(applicantModalSlice.actions.set({ numberOfComments: numberOfComments + 1 })),
  ]);
}

function* applicantCommentGetWorker(action: ReturnType<typeof applicantCommentsActions.applicantCommentGet>) {
  const { applicantId, jobId }: ReturnType<typeof applicantCommentsSelectors.selectCurrent> = yield select(
    applicantCommentsSelectors.selectCurrent,
  );
  const directCommentActionLoader = createDirectCommentActionLoader(action);
  const loaderActions = [action, directCommentActionLoader];
  yield startLoader(loaderActions);

  const { commentId } = action.payload;

  if (!applicantId || !jobId) {
    return;
  }

  const { data, errorData, message }: ReturnData<typeof fromApplicantApi.getCommentForJobApplicantFunc> =
    yield invokeApiCall(fromApplicantApi.getCommentForJobApplicantFunc, {
      urlParams: {
        applicantId,
        commentId,
        jobId,
      },
    });

  if ([errorData, message].some(Boolean)) {
    yield stopLoader(loaderActions);
    return undefined;
  }

  if (data) {
    yield put(applicantCommentsActions.upsertOne(data));
  }
  yield stopLoader(loaderActions);
}

function* applicantCommentsUpdateWorker(action: ReturnType<typeof applicantCommentsActions.applicantCommentsUpdate>) {
  const { applicantId, jobId }: ReturnType<typeof applicantCommentsSelectors.selectCurrent> = yield select(
    applicantCommentsSelectors.selectCurrent,
  );
  const directCommentActionLoader = createDirectCommentActionLoader(action);
  const loaderActions = [action, directCommentActionLoader];
  yield startLoader(loaderActions);

  const { commentId } = action.payload;

  if (!applicantId || !jobId) {
    return;
  }

  const data = { ...action.payload, applicantId, jobId };

  const { errorData, message }: ReturnData<typeof fromApplicantApi.updateCommentForJobApplicantFunc> =
    yield invokeApiCall(fromApplicantApi.updateCommentForJobApplicantFunc, {
      data,
      urlParams: {
        applicantId,
        commentId,
        jobId,
      },
    });

  if ([errorData, message].some(Boolean)) {
    yield stopLoader(loaderActions);
    return undefined;
  }

  yield call(
    applicantCommentGetWorker,
    applicantCommentsActions.applicantCommentGet({ commentId, jobId, applicantId }),
  );

  yield stopLoader(loaderActions);
}

function* applicantCommentsRemoveWorker(action: ReturnType<typeof applicantCommentsActions.applicantCommentsRemove>) {
  const { applicantId, jobId }: ReturnType<typeof applicantCommentsSelectors.selectCurrent> = yield select(
    applicantCommentsSelectors.selectCurrent,
  );

  if (!applicantId || !jobId) {
    return;
  }
  const directCommentActionLoader = createDirectCommentActionLoader(action);
  const loaderActions = [action, directCommentActionLoader];

  const { commentId } = action.payload;

  /**
   * Waiting confirmation from user for remove comment
   */
  const confirmed: boolean = yield call(removeCommentConfirmModal);
  if (!confirmed) {
    return;
  }
  yield startLoader(loaderActions);

  const { errorData, message }: ReturnData<typeof fromApplicantApi.deleteCommentForJobApplicantFunc> =
    yield invokeApiCall(fromApplicantApi.deleteCommentForJobApplicantFunc, {
      urlParams: {
        applicantId,
        commentId,
        jobId,
      },
    });
  yield stopLoader(loaderActions);

  if ([errorData, message].some(Boolean)) {
    return undefined;
  }

  yield put(applicantCommentsActions.removeOne(commentId));
}

function* applicantCommentsReadWorker(action: ReturnType<typeof applicantCommentsActions.applicantCommentsRead>) {
  const { applicantId, jobId }: ReturnType<typeof applicantCommentsSelectors.selectCurrent> = yield select(
    applicantCommentsSelectors.selectCurrent,
  );

  if (!applicantId || !jobId) {
    return;
  }

  yield startLoader(action);

  const data = { ...action.payload, applicantId, jobId };

  const { errorData, message }: ReturnData<typeof fromApplicantApi.readCommentsForJobApplicantFunc> =
    yield invokeApiCall(fromApplicantApi.readCommentsForJobApplicantFunc, {
      data,
      urlParams: {
        applicantId,
        jobId,
      },
    });
  yield stopLoader(action);

  if ([errorData, message].some(Boolean)) {
    return undefined;
  }

  const updatedComments = data.comments.map(makeCommentRead);

  yield put(applicantCommentsActions.updateMany(updatedComments));

  return updatedComments;
}

function* applicantCommentsReadAllWorker(action: ReturnType<typeof applicantCommentsActions.applicantCommentsReadAll>) {
  const selectCurrent: ReturnType<typeof applicantCommentsSelectors.selectCurrent> = yield select(
    applicantCommentsSelectors.selectCurrent,
  );

  const applicantId = selectCurrent.applicantId ?? action.payload.applicantId;
  const jobId = selectCurrent.jobId ?? action.payload.jobId;

  if (!applicantId || !jobId) {
    return;
  }

  yield startLoader(action);

  const data = { applicantId, jobId };

  const { errorData, message }: ReturnData<typeof fromApplicantApi.readCommentsForJobApplicantFunc> =
    yield invokeApiCall(fromApplicantApi.readAllCommentForJobApplicantFunc, {
      data,
      urlParams: {
        applicantId,
        jobId,
      },
    });
  yield stopLoader(action);

  if ([errorData, message].some(Boolean)) {
    return undefined;
  }

  const ids: ReturnType<typeof applicantCommentsSelectors.selectIds> = yield select(
    applicantCommentsSelectors.selectIds,
  );

  const updatedComments = ids.map(makeCommentRead);

  yield put(applicantCommentsActions.updateMany(updatedComments));

  return updatedComments;
}

export function* applicantCommentsWatcher() {
  yield takeEvery(applicantCommentsActions.applicantCommentGet, applicantCommentGetWorker);
  yield takeLatest(applicantCommentsActions.applicantCommentsFetch, applicantCommentsFetchWorker);
  yield takeLeading(applicantCommentsActions.applicantCommentsFetchMore, applicantCommentsFetchMoreWorker);
  yield takeEvery(applicantCommentsActions.applicantCommentsCreate, applicantCommentsCreateWorker);
  yield takeEvery(applicantCommentsActions.applicantCommentsUpdate, applicantCommentsUpdateWorker);
  yield takeEvery(applicantCommentsActions.applicantCommentsRemove, applicantCommentsRemoveWorker);
  yield takeEvery(applicantCommentsActions.applicantCommentsRead, applicantCommentsReadWorker);
  yield takeEvery(applicantCommentsActions.applicantCommentsReadAll, applicantCommentsReadAllWorker);
  yield fork(pollTaskWatcher);
}

/* Worker Function */
function* pollTask() {
  yield delay(30000);
  while (true) {
    try {
      const { applicantId, jobId }: ReturnType<typeof applicantCommentsSelectors.selectCurrent> = yield select(
        applicantCommentsSelectors.selectCurrent,
      );

      if (!applicantId || !jobId) {
        yield put(applicantCommentsActions.applicantCommentsStopWatcherTask());
        return;
      }
      // Fetching posts at regular interval 4 seconds.
      yield call(fetchWorker, 0, jobId, applicantId);

      yield race([delay(60000), take(applicantCommentsActions.addOneAndReplace)]);
    } catch (err) {
      // Once the polling has encountered an error,
      // it should be stopped immediately
      yield put(applicantCommentsActions.applicantCommentsStopWatcherTask());
    }
  }
}
/* Watcher Function */
function* pollTaskWatcher() {
  while (true) {
    yield take(applicantCommentsActions.applicantCommentsStartWatcherTask);
    yield race([call(pollTask), take(applicantCommentsActions.applicantCommentsStopWatcherTask)]);
  }
}
