import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { AppEffectParams } from 'store/types';

type useTableListUpdatePageHandlerProps = {
  updateTableView: ActionCreatorWithPayload<any>;
  id: string;
  load: AppEffectParams<any>;
  loadArgs?: object;
};

export const useTableListUpdatePageHandler = ({
  updateTableView,
  id,
  load,
  loadArgs = {},
}: useTableListUpdatePageHandlerProps) => {
  return (page?: number) => {
    updateTableView({ id, pageNo: page ?? 0 });
    window.scrollTo({ top: 0, behavior: 'smooth' });

    load({ listId: id, ...loadArgs });
  };
};
