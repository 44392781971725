import * as React from 'react';
import styled, { css } from 'styled-components';

import { getThemeFontSize } from 'utils/styled/getThemeFontSize';

interface FormCardProps {
  className?: string;
  children: React.ReactNode;
}

const FormCardTile = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  padding: 15px 21px 12px;
  border-bottom: 1px solid ${(props) => props.theme.border.borderColor};
  margin: 0;
  white-space: nowrap;
`;

const FormCardBody = styled.div`
  padding: 1rem 1.625rem 2rem;
  line-height: 1;
`;

const FormCardBodyDivider = styled.div`
  margin: 0 -1.625rem;
  border-bottom: 1px solid ${(props) => props.theme.border.borderColor};
`;

type InputLabelProps = {
  labelLeft?: boolean;
};

const InputLabelLeft = ({ labelLeft }: InputLabelProps) =>
  labelLeft &&
  css`
    align-items: center;
    display: flex;
    margin-bottom: 0;
    padding: 10px;
  `;

const InputLabel = styled.label<InputLabelProps>`
  display: inline-block;
  margin-bottom: 5px;
  font-size: ${getThemeFontSize('input')};
  ${InputLabelLeft};
`;

const InputLabelRequired = styled.span`
  color: ${({ theme }) => theme.colors.danger};
`;

const InputLabelHint = styled.span`
  color: ${({ theme }) => theme.colors.gray};
  font-style: italic;
  font-size: ${getThemeFontSize('small')};
`;

class FormCard extends React.Component<FormCardProps> {
  static Title = FormCardTile;
  static Body = FormCardBody;
  static InputLabel = InputLabel;
  static InputLabelRequired = InputLabelRequired;
  static InputLabelHint = InputLabelHint;
  static FormCardBodyDivider = FormCardBodyDivider;
  render() {
    return <div className={this.props.className}>{this.props.children}</div>;
  }
}

export default styled(FormCard)`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.border.borderColor};
  background: white;
`;
