import { useCallback } from 'react';

import { authLogoutAction } from 'containers/Auth/state/auth.actions';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';

export function useLogOutClickHandler() {
  const dispatch = useAppDispatch();
  return useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      dispatch(authLogoutAction());
    },
    [dispatch],
  );
}
