import styled from 'styled-components/macro';

import { ExScreeningQuestion } from 'model';

import {
  ContentContainerWrapper,
  ExCardInner,
  MandatoryElementStyled,
} from 'pages/Company/CompanyTabs/CompanyInterviewTemplatesTab/CompanyInterviewTemplatesStyles';
import {
  ActionsContainer,
  ApplicantFormScreeningQuestionElementCard,
  ApplicantFormScreeningQuestionElementStyled,
  ApplicantFormScreeningQuestionElementV2Styled,
  ApplicationFormScreeningQuestionTitleV2,
  ApplicationFormScreeningQuestionViewWrapperV2,
  CollapsedContainer,
  CompanyScreeningQuestionElementStyled,
  CompanyScreeningQuestionHeaderStyled,
  DeleteActionButton,
  DnDButton,
  DnDContainer,
  EditActionButton,
  ExAccordionStyled,
  ExAccordionToggleStyled,
  MandatoryContainer,
  ScreeningQuestionContainer,
  ScreeningQuestionElementCard,
  ScreeningQuestionTitle,
  TypeContainer,
} from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/CompanyScreeningQuestionsStyles';
import {
  ApplicantFormScreeningQuestionsElementProps,
  ApplicantFormScreeningQuestionsElementV2Props,
  CompanyScreeningQuestionClassNameProps,
  CompanyScreeningQuestionsElementProps,
} from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/CompanyScreeningQuestionsTypes';
import { ScreeningQuestionView } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionView/ScreeningQuestionView';
import { ScreeningQuestionYesNo } from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/components/ScreeningQuestionYesNo/ScreeningQuestionYesNo';
import {
  useApplicantFormScreeningQuestionsElementState,
  useApplicantFormScreeningQuestionsElementV2State,
  useCompanyScreeningQuestionsElementState,
  useCreateJobScreeningQuestionsElementState,
  useJobScreeningQuestionsElementState,
} from 'pages/Company/CompanyTabs/CompanyScreeningQuestionsTab/hooks/useCompanyScreeningQuestionsListState';
import { OptionalBadgeStyled } from 'pages/public/PublicNewApplicationV2/components/PublicNewApplicationStyles';

import { ContentContainer } from 'containers/ApplicantLists/components/ApplicantCards/ApplicantCard/ApplicantCardV2/components';

import { IconCloseStyled as IconClose } from 'components/Icons/IconClose';
import { IconEdit } from 'components/Icons/IconEdit';
import { IconMenu } from 'components/Icons/IconMenu';
import { ExVisible } from 'components/ui/ExVisible';
import { getTranslate } from 'utils/i18utils';

const CompanyInterviewTemplateElementStateList = {
  companyScreeningQuestions: useCompanyScreeningQuestionsElementState,
  jobScreeningQuestions: useJobScreeningQuestionsElementState,
  createJobScreeningQuestions: useCreateJobScreeningQuestionsElementState,
};

type ScreeningQuestionMandatoryProps = {
  data: ExScreeningQuestion;
  isEditable: boolean;
  context: string;
};

const ScreeningQuestionMandatory: React.FC<ScreeningQuestionMandatoryProps> = ({ data, isEditable, context }) => {
  if (isEditable) {
    return (
      <MandatoryContainer>
        <ScreeningQuestionYesNo question={data} editable={isEditable} context={context} />
      </MandatoryContainer>
    );
  }

  return (
    <MandatoryContainer>
      {data.isMandatory && getTranslate('interviewTemplate.questionMandatory.yes')}
      {!data.isMandatory && getTranslate('interviewTemplate.questionMandatory.no')}
    </MandatoryContainer>
  );
};

export const CompanyScreeningQuestionsElement: React.FC<
  CompanyScreeningQuestionsElementProps & { context: keyof typeof CompanyInterviewTemplateElementStateList }
> = (props) => {
  const useComponentState = CompanyInterviewTemplateElementStateList[props.context];

  const {
    className,
    forwardRef,
    dndProps,
    data,
    onEditButtonClickHandler,
    onDeleteButtonClickHandler,
    editable,
    isDragDisabled,
    isEditShown,
    isEditable,
    isControlsDisabled,
  } = useComponentState(props);

  if (!data) {
    return (
      <div
        className={className}
        ref={forwardRef}
        {...dndProps.isDragging}
        {...dndProps.draggableProps}
        {...dndProps.dragHandleProps}
      />
    );
  }

  return (
    <CompanyScreeningQuestionElementStyled
      className={className}
      ref={forwardRef}
      {...dndProps.isDragging}
      {...dndProps.draggableProps}
    >
      <ScreeningQuestionElementCard>
        <ExAccordionStyled>
          <ExVisible visible={Boolean(!isDragDisabled)}>
            <DnDContainer>
              <DnDButton {...dndProps.dragHandleProps}>
                <IconMenu />
              </DnDButton>
            </DnDContainer>
          </ExVisible>
          <ScreeningQuestionContainer>
            <ScreeningQuestionTitle>{data.questionTitle}</ScreeningQuestionTitle>
          </ScreeningQuestionContainer>
          <TypeContainer>
            <ExVisible visible={Boolean(data?.questionType)}>
              {getTranslate(`interviewTemplate.questionTypes.${data?.questionType}`)}
            </ExVisible>
          </TypeContainer>
          <ScreeningQuestionMandatory data={data} isEditable={isEditable} context={props.context} />
          <ActionsContainer>
            <ExVisible visible={isEditShown}>
              <EditActionButton disabled={isControlsDisabled} onClick={onEditButtonClickHandler}>
                <IconEdit />
              </EditActionButton>
            </ExVisible>
            <DeleteActionButton disabled={isControlsDisabled} onClick={onDeleteButtonClickHandler}>
              <IconClose />
            </DeleteActionButton>
            <ExAccordionToggleStyled eventKey={data?.itemId} />
          </ActionsContainer>
          <CollapsedContainer eventKey={data?.itemId}>
            <ContentContainer>
              <ScreeningQuestionView question={data} editable={editable} />
            </ContentContainer>
          </CollapsedContainer>
        </ExAccordionStyled>
      </ScreeningQuestionElementCard>
    </CompanyScreeningQuestionElementStyled>
  );
};

export const CompanyScreeningQuestionHeader: React.FC<CompanyScreeningQuestionClassNameProps> = ({ className }) => {
  return (
    <CompanyScreeningQuestionHeaderStyled className={className}>
      <ScreeningQuestionContainer>Question Title</ScreeningQuestionContainer>
      <TypeContainer>Question Type</TypeContainer>
      <MandatoryContainer>Mandatory</MandatoryContainer>
      <ActionsContainer>Actions</ActionsContainer>
    </CompanyScreeningQuestionHeaderStyled>
  );
};

type MandatoryElementProps = {
  mandatory: boolean;
};

const MandatoryElement: React.FC<MandatoryElementProps> = ({ mandatory }) => {
  if (!mandatory) {
    return null;
  }

  return <MandatoryElementStyled>*</MandatoryElementStyled>;
};

type OptionalBadgeProps = {
  visible: boolean;
  text?: string;
};

export const ScreeningQuestionOptionalBadge = ({ visible, text = 'Optional' }: OptionalBadgeProps) => {
  if (!visible || !text) {
    return null;
  }

  return <OptionalBadgeStyled>{text}</OptionalBadgeStyled>;
};

const ApplicantFormScreeningQuestionContainer = styled(ScreeningQuestionContainer)`
  grid-column: 2/6;
`;

export const ApplicantFormScreeningQuestionsElement: React.FC<ApplicantFormScreeningQuestionsElementProps> = (
  props,
) => {
  const { className, question, index, editable, context } = useApplicantFormScreeningQuestionsElementState(props);

  if (!question) {
    return null;
  }

  const order = `${index + 1}.`;

  return (
    <ApplicantFormScreeningQuestionElementStyled className={className}>
      <ApplicantFormScreeningQuestionElementCard>
        <ExCardInner>
          <DnDContainer>
            <ScreeningQuestionTitle>{order}</ScreeningQuestionTitle>
          </DnDContainer>
          <ApplicantFormScreeningQuestionContainer>
            <ScreeningQuestionTitle>
              {question.questionTitle}
              <MandatoryElement mandatory={!!question.isMandatory} />
            </ScreeningQuestionTitle>
          </ApplicantFormScreeningQuestionContainer>
          <ContentContainerWrapper>
            <ContentContainer>
              <ScreeningQuestionView question={question} editable={editable} context={context} />
            </ContentContainer>
          </ContentContainerWrapper>
        </ExCardInner>
      </ApplicantFormScreeningQuestionElementCard>
    </ApplicantFormScreeningQuestionElementStyled>
  );
};

export const ApplicantFormScreeningQuestionsList = ({
  screeningQuestions,
}: {
  screeningQuestions?: ExScreeningQuestion[];
}) => {
  if (!screeningQuestions || !screeningQuestions.length) {
    return (
      <ApplicantFormScreeningQuestionElementCard>
        There are no screening questions
      </ApplicantFormScreeningQuestionElementCard>
    );
  }

  return (
    <div>
      {screeningQuestions.map((item, index) => (
        <ApplicantFormScreeningQuestionsElement key={item.itemId} index={index} question={item} />
      ))}
    </div>
  );
};

export const ApplicantFormScreeningQuestionsElementV2: React.FC<ApplicantFormScreeningQuestionsElementV2Props> = (
  props,
) => {
  const { className, question, editable, context } = useApplicantFormScreeningQuestionsElementV2State(props);

  if (!question) {
    return null;
  }

  return (
    <ApplicantFormScreeningQuestionElementV2Styled className={className}>
      <ApplicationFormScreeningQuestionTitleV2>
        {question.questionTitle}
        <ScreeningQuestionOptionalBadge visible={!question.isMandatory} />
      </ApplicationFormScreeningQuestionTitleV2>
      <ApplicationFormScreeningQuestionViewWrapperV2>
        <ScreeningQuestionView question={question} editable={editable} context={context} />
      </ApplicationFormScreeningQuestionViewWrapperV2>
    </ApplicantFormScreeningQuestionElementV2Styled>
  );
};
