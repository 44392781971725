import React from 'react';
import styled from 'styled-components/macro';

import { ApplicantModalSwitchMode } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalSwitchMode';

import { ApplicantModalContactsInfoEdit } from './ApplicantModalContactsInfoEdit';
import { ApplicantModalContactsInfoView } from './ApplicantModalContactsInfoView';

type ApplicantModalContactsInfoProps = {
  className?: string;
};

const ApplicantModalContactsInfoStyled = styled(ApplicantModalSwitchMode)``;

const useApplicantModalContactsInfoState = ({ className }: ApplicantModalContactsInfoProps) => {
  return {
    className,
  } as const;
};

export const ApplicantModalContactsInfo: React.FC<ApplicantModalContactsInfoProps> = (props) => {
  const { className } = useApplicantModalContactsInfoState(props);

  return (
    <ApplicantModalContactsInfoStyled
      className={className}
      editComponent={<ApplicantModalContactsInfoEdit />}
      viewComponent={<ApplicantModalContactsInfoView />}
    />
  );
};
