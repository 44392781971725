import styled from 'styled-components/macro';

import { ExButton } from 'components/ui/ExButton';
import { ExCloseButton } from 'components/ui/ExButton/ExCloseButton';

export const ExDialogComponent = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  min-width: 300px;
  border-radius: ${({ theme }) => theme.border.borderRadius};
  flex-direction: column;
  display: flex;
  max-height: 100%;
  padding: 24px;
`;

export const ExDialogHeader = styled.div`
  position: relative;
  padding-bottom: 24px;
`;

export const ExDialogIcon = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

export const ExDialogTitle = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fonts.headingBaseNormal.fontSize};
  letter-spacing: 0.3px;
  line-height: ${({ theme }) => theme.fonts.headingBaseNormal.lineHeight};
`;

export const ExDialogFooter = styled.div`
  padding-top: 24px;
  display: flex;
`;

export const ExDialogActionButton = styled(ExButton)`
  margin: 0.2rem;
`;

export const ExDialogActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;

  ${ExDialogActionButton} {
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
`;

export const ExDialogActionsWithPadding = styled(ExDialogActions)`
  padding-top: 1rem;
`;

export const ExDialogBody = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
`;

export const ExDialogContentComponent = styled.div`
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ExDialogCloseButton = styled(ExCloseButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
`;

export const ExWrappedMessage = styled.span`
  text-align: center;
  white-space: pre-wrap;
`;

export const ExButtonCancel = styled(ExButton)``;

export const ExButtonOk = styled(ExButton)`
  margin-left: 1rem;
`;
