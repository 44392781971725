import { createSelector } from '@reduxjs/toolkit';

import { JobStatus } from 'model/api-enums.constants';

import { createDeepEqualSelector, createListSelectors } from 'utils/reducer/selector-helper';

import { EntityKeys, UIKeys } from 'store/constants';
import { Job, JobFilters, SortByForJobList } from 'store/entities/jobs/models';
import { RootState } from 'store/rootReducer';
import { uiDomain } from 'store/rootSelectors';

export const domain = createSelector(uiDomain, (state) => state[UIKeys.jobLists]);

export const {
  getListById,
  getFilters,
  getSortMode,
  getListParams,
  getViewMode,
  getSelected,
  getItems: getJobs,
  getRecentItems: getRecentJobs,
  getSelectedEntities,
} = createListSelectors<Job, SortByForJobList, JobFilters>(EntityKeys.jobs, (state: RootState) => state.ui.jobLists);

export const getActiveCountSelector = () =>
  createSelector(
    domain,
    (state: RootState, own: { listId: string; status: JobStatus }) => own,
    (lists, { listId, status }) => lists[listId]?.countByStatus[JobStatus[status] as keyof typeof JobStatus],
  );

export const selectJobCountByStatusSelector = createDeepEqualSelector(
  domain,
  (state: RootState, own: { listId: string }) => own,
  (lists, { listId }) => {
    if (!lists || !listId) {
      return undefined;
    }
    const list = lists[listId];
    if (!list) {
      return undefined;
    }
    const countByStatus = list?.countByStatus;
    if (!countByStatus) {
      return undefined;
    }
    return {
      open: 0,
      all: Object.values(countByStatus).reduce<number>((sum, value = 0) => sum + value, 0),
      cancelled: 0,
      draft: 0,
      filled: 0,
      ...countByStatus,
    };
  },
);

export const isPathActive = () =>
  createSelector(
    (_: RootState, own: any) => own,
    ({ location, path }) => location.pathname.includes(path),
  );
