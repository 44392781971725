import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';

import { companyInterviewTemplateUiSlice } from './company interview-template.reducer';

const domain = (state: RootState) => state.ui.companyUi[companyInterviewTemplateUiSlice.name];
const selectQuestionId = createSelector(domain, (state) => state.editQuestionId);
const isSideModalOpen = createSelector(selectQuestionId, Boolean);

export const companyInterviewTemplateUiSelectors = {
  selectQuestionId,
  isSideModalOpen,
};
