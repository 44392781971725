export const JobAdEndpointsForApi = {
  SearchJobAdsForJobFunc: '/job/{jobId}/ad',
  SearchJobAdsForJobBoardFunc: '/job-board/{jobBoardId}/ad',
  SearchJobAdsForActiveJobsFunc: '/ad/active-job',
  GetCurrentJobAdsForJobFunc: '/job/{jobId}/promote-details',
  GetJobAdFunc: '/ad/{jobAdId}',
  GetJobAdStatusFunc: '/ad/{jobAdId}/status',
  StopJobAdsForJobFunc: '/job/{jobId}/ad',
  StopJobAdsByJobAdIdsFunc: '/ad',
  SearchJobAdChangeNotesFunc: '/ad/{jobAdId}/change-note',
} as const;
