import styled from 'styled-components/macro';

import { ExRow } from 'components/ui/ExLayout';

export const CompanyTabStyled = styled(ExRow)`
  margin-bottom: 10px;

  &:not(:first-child) {
    padding-top: 0;
  }
`;
