import { createAction } from '@reduxjs/toolkit';

import * as fromModalsConstants from './modals.constants';
import { ExModal, ModalConfig, ModalResult, ShowModal } from './modals.interfaces';

export const exModalShowAction = createAction<ShowModal>(fromModalsConstants.MODAL_SHOW);
export const exModalPropsAction = createAction<Partial<ExModal>>(fromModalsConstants.MODAL_PROPS_UPDATE);
export const exModalResultAction = createAction<ModalResult>(fromModalsConstants.MODAL_RESULT_UPDATE);
export const exModalConfirmAction = createAction<ModalResult>(fromModalsConstants.MODAL_CONFIRM);
export const exModalCancelAction = createAction<ModalResult>(fromModalsConstants.MODAL_CANCEL);
export const exModalHideAction = createAction<ModalResult>(fromModalsConstants.MODAL_HIDE);

export type WizardNavigation = Pick<ShowModal, 'id'>;
export const wizardForward = createAction<WizardNavigation>(fromModalsConstants.MODAL_WIZARD_FORWARD);
export const wizardBackward = createAction<WizardNavigation>(fromModalsConstants.MODAL_WIZARD_BACKWARD);
export const wizardError = createAction<WizardNavigation>(fromModalsConstants.MODAL_WIZARD_ERROR);

export type UpdateWizardPage = { id: string; page: string };
export const updateWizardPage = createAction<
  UpdateWizardPage & { modalConfig?: ModalConfig; modalProps?: Record<string, any> }
>(fromModalsConstants.MODAL_PAGE_UPDATE);
export const updateNavigation = createAction(fromModalsConstants.MODAL_NAVIGATION_UPDATE);
