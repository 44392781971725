import React, { useCallback, useEffect, useRef } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { components, SingleValueProps } from 'react-select';
import parsePhoneNumber, { getCountryCallingCode } from 'libphonenumber-js';
import styled from 'styled-components/macro';

import { Country } from 'model';

import { FormCard } from 'components/FormCard';
import { FormTooltip } from 'components/FormTooltip';
import {
  ClearForm,
  ExSalaryInputWithCountryProps,
  ExSalaryInputWithCountryStyled,
  FormGroupStyled,
  FormSelectStyled,
  InputStyled,
  overrideStyles,
  reactCountryFlagStyles,
} from 'components/ui/ExSalaryInputWithCountry/ExComponentsInputWithCountry';
import { usePrevious } from 'utils/hooks/usePrevious';
import { ReactCountryFlagCdnUrl } from 'utils/hooks/useReactCountryFlagOptions';

import { companySelectors } from 'store/company/company.selectors';
import { useSelector } from 'store/rootSelectors';

type ExMobileInputWithCountryProps = ExSalaryInputWithCountryProps & {
  name: string;
  disabled?: boolean;
};

const ExMobileInputWithCountryStyled = styled(ExSalaryInputWithCountryStyled)``;

const useExMobileInputWithCountryState = ({ className, label, name, required }: ExMobileInputWithCountryProps) => {
  const { errors, formState, watch, setValue } = useFormContext();
  const target = useRef<any>();

  const countries = useSelector(companySelectors.getCompanyCountries);

  const validated = formState.isSubmitted;

  const errorsByName = errors?.[name];
  const errorMessage = errorsByName?.message;
  const hasErrorMessage = Boolean(errorMessage);

  const countryName = `country_${name}`;

  const countryValue = watch(countryName);
  const inputValue = watch(name);

  const prevValues = usePrevious({ countryValue, inputValue });

  const hasValue = countryValue || inputValue;

  const onClear = useCallback(() => {
    setValue(countryName, null);
    setValue(name, '');
  }, [countryName, name, setValue]);

  useEffect(() => {
    if (!inputValue && !countryValue) {
      return;
    }

    const phoneNumber = parsePhoneNumber(`${inputValue}`);
    const phoneNumberCountry = phoneNumber?.country;

    if (countryValue !== prevValues?.countryValue && countryValue !== phoneNumberCountry) {
      try {
        const countryCallingCode = getCountryCallingCode(countryValue) as string;

        if (countryCallingCode) {
          setValue(name, `+${countryCallingCode}`);
        }
      } catch (e) {
        setValue(name, '');
      }
    } else if (phoneNumberCountry) {
      setValue(countryName, phoneNumberCountry);
    }
  }, [inputValue, countryValue, setValue, name, prevValues?.countryValue, countryName]);

  return {
    className,
    label,
    required,
    name,
    target,
    countries,
    validated,
    errorsByName,
    hasErrorMessage,
    countryName,
    onClear,
    hasValue,
  } as const;
};

const usePhoneInput = (disabled?: boolean) => (props: ControllerRenderProps) =>
  <PhoneInput disabled={disabled} {...props} />;

const PhoneInput = (amountProps: ControllerRenderProps & { disabled?: boolean }) => {
  const { value, name, onChange, ...rest } = amountProps;
  const { formState, errors } = useFormContext();

  const validated = formState.isSubmitted;
  const phoneInputErrors = errors?.[name];

  return (
    <InputStyled
      type="text"
      value={value}
      onChange={onChange}
      isValid={validated && !phoneInputErrors}
      isInvalid={validated && !!phoneInputErrors}
      {...rest}
    />
  );
};

const SingleValue = (singleValueProps: SingleValueProps<Country>) => {
  const item: Country = singleValueProps.data;
  const countryCode = item.code.expedoCode2;

  return (
    <components.SingleValue {...singleValueProps}>
      <ReactCountryFlag style={reactCountryFlagStyles} svg countryCode={countryCode} cdnUrl={ReactCountryFlagCdnUrl} />
    </components.SingleValue>
  );
};
export const ExMobileInputWithCountry: React.FC<ExMobileInputWithCountryProps> = (props) => {
  const {
    className,
    label,
    required,
    name,
    target,
    countries,
    validated,
    errorsByName,
    hasErrorMessage,
    countryName,
    hasValue,
    onClear,
  } = useExMobileInputWithCountryState(props);

  return (
    <FormGroupStyled ref={target} className={className}>
      <FormCard.InputLabel>
        {label} {required && <FormCard.InputLabelRequired>*</FormCard.InputLabelRequired>}
      </FormCard.InputLabel>
      <ExMobileInputWithCountryStyled>
        <FormSelectStyled
          name={countryName}
          placeholder=""
          validated={validated}
          defaultOptions
          getOptionLabel={(option: Country) => option.name}
          getOptionValue={(option: Country) => option.code.expedoCode2}
          options={countries}
          components={{
            IndicatorSeparator: null,
            SingleValue,
            Placeholder: () => null,
          }}
          overrideStyles={overrideStyles}
          isSearchable={false}
          isDisabled={props.disabled}
        />
        <Controller name={name} render={usePhoneInput(props.disabled)} />
        <ClearForm show={hasValue && !props.disabled} method={onClear} />
      </ExMobileInputWithCountryStyled>
      <FormTooltip target={target} show={hasErrorMessage} errors={errorsByName} />
    </FormGroupStyled>
  );
};
