import { replaceParamsInUrl } from 'api-client/utils';
import { JobBoardsEndpointsForApi } from 'api-endpoints/job-board/endpoints';
import { GetJobBoardsForJobsFuncResponse } from 'api-endpoints/job-board/job-board.dto';
import { getJobBoardAdditionalFieldsFuncTransformer } from 'api-endpoints/job-board/transformer';

import { emptyApi } from 'store/entities/emptyApi';
import { JobBoard } from 'store/entities/job-boards';

const extendedJobBoardsApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    jobBoardsForJobs: builder.query<GetJobBoardsForJobsFuncResponse, void>({
      query: () => ({
        url: replaceParamsInUrl(JobBoardsEndpointsForApi.GetJobBoardsForJobsFunc, {}),
        method: 'GET',
      }),
    }),
    getJobBoardAdditionalFields: builder.mutation({
      query({ jobBoardId, location, jobId }) {
        return {
          url: replaceParamsInUrl(JobBoardsEndpointsForApi.GetJobBoardAdditionalFieldsFunc, { jobBoardId }),
          method: 'GET',
          params: { location, jobId },
        };
      },
      transformResponse(data: Pick<JobBoard, 'additionalFields'>) {
        return getJobBoardAdditionalFieldsFuncTransformer(data);
      },
    }),
  }),
});

export const enhancedJobBoardsApi = extendedJobBoardsApi.enhanceEndpoints({
  addTagTypes: ['JobBoard'],
  endpoints: {},
});

export const { useJobBoardsForJobsQuery, useGetJobBoardAdditionalFieldsMutation } = enhancedJobBoardsApi;
