import { createAction } from '@reduxjs/toolkit';

import { publicCountrySlice } from './public-country.reducer';

const publicCountryFetchAction = createAction(`${publicCountrySlice.name}/fetch`);

export const publicCountryActions = {
  ...publicCountrySlice.actions,
  publicCountryFetchAction,
};
