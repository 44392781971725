import React, { useCallback } from 'react';
import styled, { Colors, css, keyframes } from 'styled-components';

import { AlertTypes } from 'containers/AlertManager/store/models';
import { alertsActions } from 'containers/AlertManager/store/reducers';

import { IconCloseStyled } from 'components/Icons/IconClose';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';

export interface AlertProps {
  className?: string;
  message?: string;
  variant: keyof typeof AlertTypes;
  alertId?: string;
  closable?: boolean;
  isHtml?: boolean;
}

const AnimationIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const AlertBackgroundColors: Partial<Record<AlertProps['variant'], keyof Colors>> = {
  [AlertTypes.error]: 'danger',
  [AlertTypes.info]: 'warning',
  [AlertTypes.success]: 'success',
  [AlertTypes.cancelableCountdown]: 'blue',
};

const AlertCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
  color: ${(props) => props.theme.colors.white};
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  &:focus {
    outline: none;
  }
`;

const AlertPure: React.FC<AlertProps> = ({ className, message, children, alertId, closable, isHtml }) => {
  const dispatch = useAppDispatch();

  const closeModal = useCallback(() => {
    if (alertId) {
      dispatch(alertsActions.del(alertId));
    }
  }, [alertId, dispatch]);

  const hasHtml = Boolean(isHtml && message);

  return (
    <div className={className}>
      {closable && (
        <AlertCloseButton onClick={closeModal}>
          <IconCloseStyled />
        </AlertCloseButton>
      )}
      {hasHtml ? <div dangerouslySetInnerHTML={{ __html: message ?? '' }} /> : message || children}
    </div>
  );
};

const closableAlert = ({ closable }: AlertProps) =>
  closable &&
  css`
    padding-right: 30px;
  `;

export const Alert = styled(AlertPure)`
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors[AlertBackgroundColors[props.variant] ?? 'warning']};
  border-radius: ${getThemeBorderProps('borderRadius')};
  animation: ${AnimationIn};
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  margin-bottom: 8px;
  min-width: 285px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  ${closableAlert};
`;
