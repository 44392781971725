import { EntityId } from '@reduxjs/toolkit';
import { compose, DeepPartial } from 'redux';

import { Ability, Location } from 'model';

import { SortDirections } from 'store/constants';
import {
  BulkUpdateApplicantPipelineStageRequest,
  GetJobAdsForJobRequestParams,
  GetJobApplicantsForJobRequestParams,
  JobChangeWorkflowParams,
  JobChangeWorkflowRequest,
  JobListRequestParams,
  JobPostRequestParams,
  JobPutRequestParams,
  JobPutSalaryRequestParams,
} from 'store/entities/jobs/api/requests';
import { Job, SortByForJobList } from 'store/entities/jobs/models';

const jobOrderBy = ({
  params: { sortMode, ...restParams },
  ...restJobListRequestParams
}: JobListRequestParams): JobListRequestParams => {
  const stageFilter = [SortByForJobList.NewApplicants];
  let orderBy;
  if (sortMode?.orderBy) {
    if (stageFilter.includes(sortMode.orderBy)) {
      orderBy = `JobApplicantsCountByStage["${sortMode.orderBy}"]`;
    } else {
      orderBy = sortMode.orderBy;
    }
    orderBy += `${sortMode.orderDir === SortDirections.desc ? ' desc' : ''}`;
  }

  return {
    ...restJobListRequestParams,
    params: {
      ...restParams,
      orderBy,
    },
  };
};

const jobStatuses = ({
  params: { filters, ...restParams },
  ...restJobRequestParams
}: JobListRequestParams): JobListRequestParams => {
  return {
    ...restJobRequestParams,
    params: {
      filters,
      ...restParams,
      ...(filters?.status && { jobStatuses: filters.status }),
    },
  };
};

const jobSearchTerm = ({
  params: { filters, ...restParams },
  ...restJobRequestParams
}: JobListRequestParams): JobListRequestParams => {
  return {
    ...restJobRequestParams,
    params: {
      filters,
      ...restParams,
      ...(filters?.search && { searchTerm: filters.search }),
    },
  };
};

const jobDeadlineNotPassed = ({
  params: { filters, ...restParams },
  ...restJobRequestParams
}: JobListRequestParams): JobListRequestParams => {
  return {
    ...restJobRequestParams,
    params: {
      filters,
      ...restParams,
      ...(filters?.deadlineNotPassed && { deadlineNotPassed: filters.deadlineNotPassed }),
    },
  };
};

const jobDeadlineRange = ({
  params: { filters, ...restParams },
  ...restJobRequestParams
}: JobListRequestParams): JobListRequestParams => {
  return {
    ...restJobRequestParams,
    params: {
      filters,
      ...restParams,
      ...(filters?.deadline?.start && { 'deadline.start': filters?.deadline?.start }),
      ...(filters?.deadline?.end && { 'deadline.end': filters?.deadline?.end }),
    },
  };
};

const jobExcludeForCandidateId = ({
  params: { filters, ...restParams },
  ...restJobRequestParams
}: JobListRequestParams): JobListRequestParams => {
  return {
    ...restJobRequestParams,
    params: {
      filters,
      ...restParams,
      ...(filters?.excludeForCandidateIds?.length && {
        excludeForCandidateId: filters.excludeForCandidateIds,
      }),
    },
  };
};

const jobPrepareAbility = ({ data, ...restJobPostRequestParams }: JobPostRequestParams): JobPostRequestParams => {
  const removeId = <P extends { id: EntityId } = any>({ id, ...rest }: P) => ({ ...rest });

  return {
    data: {
      ...data,
      requirements: data?.requirements?.map(removeId) as Ability[],
      responsibilities: data.responsibilities?.map(removeId) as Ability[],
    },
    ...restJobPostRequestParams,
  };
};

const jobPutCopyIdFromUrlToData = ({
  data,
  urlParams,
  ...restJobPostRequestParams
}: JobPutRequestParams): JobPutRequestParams => {
  return {
    urlParams,
    data: {
      ...data,
      ...(urlParams.jobId && { id: urlParams.jobId }),
    },
    ...restJobPostRequestParams,
  };
};

const copyJobIdFromUrlToData = ({
  data,
  urlParams,
  ...resRequest
}: JobChangeWorkflowParams): { urlParams: { jobId: Job['id'] }; data: DeepPartial<Job> } => ({
  urlParams,
  data: {
    ...data,
    jobId: urlParams.jobId,
  },
  ...resRequest,
});

const getJobAdsForJobOrder = ({ params, ...restRequest }: GetJobAdsForJobRequestParams) => {
  return {
    params: {
      ...params,
      orderBy: params.sortMode.orderBy,
      orderDirection: params.sortMode.orderDir === SortDirections.desc ? 'Descending' : 'Ascending',
    },
    ...restRequest,
  };
};

const getJobAdsForJobStatuses = ({ params, ...restRequest }: GetJobAdsForJobRequestParams) => {
  return {
    params: {
      ...params,
      statuses: params.filters.statuses,
    },
    ...restRequest,
  };
};

const getJobAdsForJobPostedOn = ({ params, ...restRequest }: GetJobAdsForJobRequestParams) => {
  return {
    params: {
      ...params,
      ...(params.filters.postedOnRange?.length &&
        params.filters.postedOnRange[0] &&
        params.filters.postedOnRange[1] && {
          postedAfter: params.filters.postedOnRange[0],
          postedBefore: params.filters.postedOnRange[1],
        }),
    },
    ...restRequest,
  };
};

const getJobAdsForJobSearch = ({ params, ...restRequest }: GetJobAdsForJobRequestParams) => {
  return {
    params: {
      ...params,
      ...(params.filters.search && { searchTerm: params.filters.search }),
    },
    ...restRequest,
  };
};

const getJobApplicantsForJobOrder = ({ params, ...restRequest }: GetJobAdsForJobRequestParams) => {
  return {
    params: {
      ...params,
      ...(params.sortMode?.orderBy && {
        orderBy: `${params.sortMode.orderBy}${params.sortMode.orderDir === SortDirections.desc ? ' descending' : ''}`,
      }),
    },
    ...restRequest,
  };
};

const getJobAdsForJobEmployer = ({ params, ...restRequest }: GetJobAdsForJobRequestParams) => {
  return {
    params: {
      ...params,
      ...(params.filters?.employer && {
        employer: params.filters?.employer,
      }),
    },
    ...restRequest,
  };
};

const getJobAdsForJobEducation = ({ params, ...restRequest }: GetJobAdsForJobRequestParams) => {
  return {
    params: {
      ...params,
      ...(params.filters?.education && {
        education: params.filters?.education,
      }),
    },
    ...restRequest,
  };
};

const getJobApplicantsForJobPipelineStages = ({ params, ...restRequest }: GetJobAdsForJobRequestParams) => {
  return {
    params: {
      ...params,
      ...(params.filters?.pipelineStages !== undefined && params.filters?.pipelineStages.length
        ? { pipelineStages: [params.filters.pipelineStages] }
        : {
            pipelineStages: [],
          }),
    },
    ...restRequest,
  };
};

const jobPipelineId = ({ params, ...restRequest }: GetJobAdsForJobRequestParams) => {
  return {
    params: {
      ...params,
      ...(params.filters?.pipelineId && {
        pipelineId: params.filters?.pipelineId,
      }),
    },
    ...restRequest,
  };
};

const getJobApplicantsForJobKeywords = ({ params, ...restRequest }: GetJobAdsForJobRequestParams) => {
  return {
    params: {
      ...params,
      ...(params.filters.keywords?.length && { keywords: params.filters.keywords }),
    },
    ...restRequest,
  };
};

const getJobApplicantsForJobCountryLocation = ({ params, ...restRequest }: GetJobAdsForJobRequestParams) => {
  return {
    params: {
      ...params,
      ...(params.filters.countries?.length && { countries: params.filters.countries }),
      ...(params.filters.locations?.length && { locations: params.filters.locations }),
    },
    ...restRequest,
  };
};

const getJobApplicantsForJobLocations = ({ params, ...restRequest }: GetJobAdsForJobRequestParams) => {
  return {
    params: {
      ...params,
      ...(params.filters.locations?.length && {
        locations: params.filters.locations.map((location: Location) => ({
          countryCode: location.countryCode,
          locationId: location.id,
        })),
      }),
    },
    ...restRequest,
  };
};

const getJobApplicantsForJobSources = ({ params, ...restRequest }: GetJobAdsForJobRequestParams) => {
  return {
    params: {
      ...params,
      ...(params.filters.sources?.length && { sources: params.filters.sources }),
    },
    ...restRequest,
  };
};

const getJobApplicantsForJobAvailabilities = ({ params, ...restRequest }: GetJobAdsForJobRequestParams) => {
  return {
    params: {
      ...params,
      ...(params.filters.availabilities?.length && {
        availabilities: params.filters.availabilities.filter(
          (availability: any) => typeof availability.availabilityType !== 'undefined',
        ),
      }),
    },
    ...restRequest,
  };
};

const getJobApplicantsForJobClearParams = ({ params, ...restRequest }: GetJobAdsForJobRequestParams) => {
  const { filters, ...clearParams } = params;

  return {
    params: {
      ...clearParams,
    },
    ...restRequest,
  };
};

const getJobApplicantsForJobParamsToData = ({
  params,
  data,
  ...restRequest
}: GetJobAdsForJobRequestParams & { data: {} }) => {
  return {
    data: {
      ...data,
      ...params,
    },
    ...restRequest,
  };
};

const passThroughMapper = (params: any) => {
  return params;
};

export const getJobParamsMapper = compose<JobListRequestParams>(
  jobExcludeForCandidateId,
  jobDeadlineNotPassed,
  jobSearchTerm,
  jobStatuses,
  jobOrderBy,
  jobPipelineId,
  jobDeadlineRange,
  passThroughMapper,
);
export const getJobsCountParamsMapper = compose<JobListRequestParams>(
  jobDeadlineNotPassed,
  jobSearchTerm,
  jobPipelineId,
  jobDeadlineRange,
  passThroughMapper,
);

export const postJobParamsMapper = compose<JobPostRequestParams>(jobPrepareAbility);

export const putJobParamsMapper = compose<JobPutRequestParams>(jobPutCopyIdFromUrlToData, jobPrepareAbility);

export const putJobSalaryParamsMapper = compose<JobPutSalaryRequestParams>(jobPutCopyIdFromUrlToData);

export const changeWorkflowStageMapper = compose<JobChangeWorkflowRequest>();

export const bulkUpdateApplicantPipelineStageRequestMapper =
  compose<BulkUpdateApplicantPipelineStageRequest>(copyJobIdFromUrlToData);

export const getJobAdsForJobMapper = compose<GetJobAdsForJobRequestParams>(
  getJobAdsForJobOrder,
  getJobAdsForJobStatuses,
  getJobAdsForJobPostedOn,
  getJobAdsForJobSearch,
);

export const getJobApplicantsForJobMapper = compose<GetJobApplicantsForJobRequestParams>(
  getJobApplicantsForJobParamsToData,
  getJobApplicantsForJobClearParams,
  getJobApplicantsForJobAvailabilities,
  getJobApplicantsForJobSources,
  getJobApplicantsForJobCountryLocation,
  getJobApplicantsForJobKeywords,
  getJobApplicantsForJobPipelineStages,
  getJobApplicantsForJobOrder,
  getJobAdsForJobEmployer,
  getJobAdsForJobEducation,
  copyJobIdFromUrlToData,
);

export const getJobApplicantsForApplicantModal = compose<GetJobApplicantsForJobRequestParams>(
  getJobApplicantsForJobClearParams,
  getJobApplicantsForJobAvailabilities,
  getJobApplicantsForJobSources,
  getJobApplicantsForJobLocations,
  getJobApplicantsForJobKeywords,
  getJobApplicantsForJobPipelineStages,
  getJobApplicantsForJobOrder,
);
