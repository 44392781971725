import React, { useCallback } from 'react';
import { push } from 'connected-react-router';
import { ExRoutes } from 'router/routes';
import styled from 'styled-components/macro';

import { bulkCreateCandidate } from 'containers/BulkCreation/bulkCreation.actions';
import { modalsSelectors } from 'containers/Modals/store';

import { IconCandidates } from 'components/Icons/IconCandidates';
import { IconNewCandidate } from 'components/Icons/IconNewCandidate';
import { IconShare } from 'components/Icons/IconShare';
import { Spacer } from 'components/Spacer';
import { ExButton } from 'components/ui/ExButton';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getTranslate } from 'utils/i18utils';
import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';
import { getThemeColor } from 'utils/styled/getThemeColor';

import { candidateCreateOpenModal } from 'store/entities/candidates/candidate.actions';
import { exModalCancelAction, exModalHideAction } from 'store/modals/modals.actions';
import { candidatesListUISelectors } from 'store/ui/candidates/candidates-list/candidates-list.selectors';
import { talentPoolCandidateListUiSelectors } from 'store/ui/talent-pools/talent-pools-candidate-list/talent-pools-candidate-list.selectors';

type AddCandidateAction = 'create' | 'select' | 'upload';

type CandidatesListEmptyProps = {
  className?: string;
  onSelect?: (action: AddCandidateAction) => void;
  context: 'candidateList' | 'talentPool' | 'addToTalentPoolModal';
  modalId?: string;
};

const CandidatesListEmptyStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .message {
    margin-bottom: 22px;
  }

  .quick-action-card {
    border-color: ${getThemeColor('neutral')};
    border-radius: ${getThemeBorderProps('borderRadiusSm')};
    border-width: 1px;
    border-style: solid;
    background-color: white;
    padding: 15px;

    &-title {
      font-weight: 700;
      margin-bottom: 8px;
    }

    &-btn {
      margin-top: 22px;
      font-size: 12px;
      width: 137px;
    }

    &-icon {
      color: ${getThemeColor('grayDark')};
    }

    &-wrapper {
      display: flex;

      > div {
        width: 250px;
        text-align: center;

        &:not(:first-of-type) {
          margin-left: 1rem;
        }
      }
    }

    &-content {
      padding-bottom: 15px;
      padding-right: 2px;
      padding-left: 2px;
      height: 220px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  ${IconCandidates} {
    width: 56px;
    height: 56px;

    path {
      stroke-width: 1;
    }
  }
`;

const cards = {
  select: {
    title: 'Add from Existing Candidates',
    description: 'Pick from the existing list of<br /> candidates',
    Icon: IconCandidates,
    iconWidth: 65,
    buttonText: 'Select Now',
    action: 'select',
  },
  create: {
    title: 'Create New Candidates',
    description: 'Create a new candidate<br /> profiles',
    Icon: IconNewCandidate,
    iconWidth: 55,
    buttonText: 'Create New',
    action: 'create',
  },
  upload: {
    title: 'Bulk Create Candidates',
    description: 'Upload multiple candidates<br /> from a spreadsheet',
    Icon: IconShare,
    iconWidth: 55,
    buttonText: 'Browse File',
    action: 'upload',
  },
} as const;

const useCandidatesListEmptyStateCentralCandidateListCntext = ({ className, onSelect }: CandidatesListEmptyProps) => {
  const dispatch = useAppDispatch();

  const isFiltersChanged = useAppSelector(candidatesListUISelectors.isFiltersChanged);

  const message = isFiltersChanged ? 'candidate_list_empty_by_filters' : 'central_candidate_list_empty';

  const actions = [cards.create, cards.upload];

  const buttonClickHandler = useCallback(
    (action: AddCandidateAction): React.MouseEventHandler<HTMLButtonElement> =>
      (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (onSelect instanceof Function) {
          onSelect(action);
        }

        switch (action) {
          case 'create':
            dispatch(candidateCreateOpenModal());
            break;
          case 'upload':
            dispatch(bulkCreateCandidate({}));
            break;
        }
      },
    [dispatch, onSelect],
  );

  return {
    className,
    buttonClickHandler,
    actions,
    message,
  } as const;
};
const useCandidatesListEmptyStateTalentPoolCntext = ({ className, onSelect }: CandidatesListEmptyProps) => {
  const dispatch = useAppDispatch();
  const talentPoolId = useAppSelector(modalsSelectors.modalTalentPoolId);

  const isFiltersChanged = useAppSelector(talentPoolCandidateListUiSelectors.isFiltersChanged);
  const message = isFiltersChanged ? 'candidate_list_empty_by_filters' : 'talent_pool_candidates_list_empty';

  const actions = Object.values(cards);

  const buttonClickHandler = useCallback(
    (action: AddCandidateAction): React.MouseEventHandler<HTMLButtonElement> =>
      (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (onSelect instanceof Function) {
          onSelect(action);
        }

        switch (action) {
          case 'select':
            if (!talentPoolId) {
              return;
            }
            dispatch(push(ExRoutes.talentPoolAddNewCandidates({ talentPoolId })));
            break;
          case 'create':
            dispatch(candidateCreateOpenModal());
            break;
          case 'upload':
            dispatch(bulkCreateCandidate({ talentPoolId: talentPoolId ? talentPoolId : undefined }));
            break;
        }
      },
    [dispatch, onSelect, talentPoolId],
  );

  return {
    className,
    buttonClickHandler,
    actions,
    message,
  } as const;
};

const useAddToTalentPoolModalContext = ({ className, onSelect, modalId }: CandidatesListEmptyProps) => {
  const dispatch = useAppDispatch();
  const talentPoolId = useAppSelector(modalsSelectors.modalTalentPoolId);

  const isFiltersChanged = useAppSelector(talentPoolCandidateListUiSelectors.isFiltersChanged);
  const message = isFiltersChanged ? 'candidate_list_empty_by_filters' : 'talent_pool_candidates_list_empty';

  const actions = Object.values(cards);

  const buttonClickHandler = useCallback(
    (action: AddCandidateAction): React.MouseEventHandler<HTMLButtonElement> =>
      (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (onSelect instanceof Function) {
          onSelect(action);
        }

        switch (action) {
          case 'select':
            if (!talentPoolId) {
              return;
            }
            dispatch(exModalCancelAction({ id: modalId! }));
            dispatch(push(ExRoutes.talentPoolAddNewCandidates({ talentPoolId })));
            break;
          case 'create':
            dispatch(exModalHideAction({ id: modalId! }));
            dispatch(candidateCreateOpenModal());
            break;
          case 'upload':
            dispatch(exModalHideAction({ id: modalId! }));
            dispatch(bulkCreateCandidate({ talentPoolId: talentPoolId ? talentPoolId : undefined }));
            break;
        }
      },
    [dispatch, modalId, onSelect, talentPoolId],
  );

  return {
    className,
    buttonClickHandler,
    actions,
    message,
  } as const;
};

const CandidatesListEmptyContext = {
  talentPool: useCandidatesListEmptyStateTalentPoolCntext,
  candidateList: useCandidatesListEmptyStateCentralCandidateListCntext,
  addToTalentPoolModal: useAddToTalentPoolModalContext,
};

export const CandidatesListEmpty: React.FC<CandidatesListEmptyProps> = (props) => {
  const { className, buttonClickHandler, actions, message } = CandidatesListEmptyContext[props.context](props);

  return (
    <CandidatesListEmptyStyled className={className}>
      <div className="message">{getTranslate(message)}</div>
      <div className="quick-action-card-wrapper">
        {actions.map(({ Icon, buttonText, description, iconWidth, title, action }) => (
          <div className="quick-action-card" key={action}>
            <div className="quick-action-card-content">
              <Spacer />
              <Icon className="quick-action-card-icon" width={iconWidth} />
              <Spacer />
              <div className="quick-action-card-title">{title}</div>
              <div dangerouslySetInnerHTML={{ __html: description }}></div>
              <ExButton onClick={buttonClickHandler(action)} className="quick-action-card-btn">
                {buttonText}
              </ExButton>
            </div>
          </div>
        ))}
      </div>
    </CandidatesListEmptyStyled>
  );
};
