import pick from 'lodash/pick';
import { SagaReturnType, select } from 'redux-saga/effects';

import { Applicant } from 'store/entities/applicants/models';
import { applicantSelectors } from 'store/entities/applicants/selectors';

export function* utilsPrepareUpdateApplicantData<T extends Applicant>(
  applicantId: Applicant['applicantId'],
  updateData: Partial<T>,
) {
  const applicant: SagaReturnType<typeof applicantSelectors.getById> = yield select(
    applicantSelectors.getById,
    applicantId,
  );

  return {
    data: pick({ ...applicant, ...updateData, applicantId }, [
      'jobId',
      'applicantId',
      'seniorityLevel',
      'jobApplicantAvailability',
      'salaryExpectationCurrency',
      'salaryExpectationAmount',
      'salaryExpectationFrequency',
      'rating',
      'doNotHire',
    ]),
    oldData: applicant,
  };
}
