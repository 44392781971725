import { list } from 'config';

import { PlacementApplicantStage } from 'model/api-enums.constants';

import { SortDirections, ViewMode } from 'store/constants';
import { ApplicantBelongsTo, SortBy } from 'store/entities/applicants';

import { ApplicantListState, InitApplicantListsState } from './models';

export const initialListState: Partial<ApplicantListState> = {
  items: [],
  selectedItems: [],
  pageNo: 0,
  pageSize: list.pageSize,
  pageCount: 0,
  totalItemsCount: 0,
  sortMode: {},
  viewMode: ViewMode.table,
  filters: {
    keywords: [],
  },
  fetched: false,
  pagination: {},
};

export const placementsStatusFilterDefaultValue = PlacementApplicantStage.All;

export const initialState: InitApplicantListsState = {
  [ApplicantBelongsTo.job]: {
    ...initialListState,
    filters: {
      ...initialListState.filters,
      education: undefined,
      employer: undefined,
      keywords: undefined,
      pipelineStages: undefined,
    },
    filter: [],
    id: ApplicantBelongsTo.job,
  },
  [ApplicantBelongsTo.candidate]: { ...initialListState, id: ApplicantBelongsTo.candidate },
  [ApplicantBelongsTo.dashboard]: {
    ...initialListState,
    id: ApplicantBelongsTo.dashboard,
    viewMode: ViewMode.grid,
    pageSize: 5,
  },
  [ApplicantBelongsTo.placements]: {
    ...initialListState,
    id: ApplicantBelongsTo.placements,
    filters: {
      ...initialListState.filters,
      status: placementsStatusFilterDefaultValue,
    },
    sortMode: {
      orderDir: SortDirections.asc,
      orderBy: SortBy.name,
    },
  },
} as Record<ApplicantListState['id'], ApplicantListState>;
