import React from 'react';
import styled from 'styled-components';

import { SwitchChildrenProps } from './SwitchChildrenProps';

const SwitchChildren: React.FC<SwitchChildrenProps> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default styled(SwitchChildren)`
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;

  > *:nth-child(1) {
    display: ${(props) => (props.switch ? 'inherit' : 'none')};
  }

  > *:nth-child(2) {
    display: ${(props) => (!props.switch ? 'inherit' : 'none')};
  }
`;
