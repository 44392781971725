import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

import searchIcon from 'assets/img/search-999.svg';

import { ListViewSearchProps } from './ListViewProps';

const ListViewSearch: React.FC<ListViewSearchProps> = ({
  filterName,
  filterValue,
  onChange,
  placeholder,
  className,
  datalist,
}) => {
  const [value, setValue] = React.useState('');
  const handleKeyPress = (event: any) => {
    if (event.code === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      const eventValue = event.currentTarget.value;
      onChange(filterName, eventValue);
    }
  };
  const handleChange = (event: any) => {
    setValue(event.target.value);
  };
  useEffect(() => {
    if (filterValue !== value) {
      setValue(filterValue || '');
    }
    // eslint-disable-next-line
  }, [filterValue]);
  return (
    <Form.Group className={className}>
      <Form.Control
        className="search-control"
        onKeyPress={handleKeyPress}
        value={value}
        onChange={handleChange}
        name={`${filterName}Control`}
        placeholder={placeholder}
        list="listSearch"
      />
      {datalist?.length ? (
        <datalist id="listSearch">
          {datalist.map((data, index) => (
            <option key={index} value={data.value} />
          ))}
        </datalist>
      ) : null}
    </Form.Group>
  );
};

export default styled(ListViewSearch)`
  position: relative;
  margin: 0;

  &::after {
    position: absolute;
    content: '';
    width: 16px;
    height: 16px;
    background-image: url(${searchIcon});
    background-repeat: no-repeat;
    top: 12px;
    left: 12px;
  }

  .search-control {
    padding-left: 40px;
  }
`;
