import Axios from 'axios';

import { api } from 'api-client';
import {
  CancelJobAdParams,
  JobAdLogsParams,
  JobAdResponseItem,
  PostJobAdParams,
  PutJobAdParams,
  RePostJobAdParams,
} from 'api-endpoints/job-ad/models';

import { ApiResponseWithPagination, SearchAutocompleteOptions } from 'model';

import { removeId } from 'utils/list';
import { createUrlWithParams } from 'utils/url';

import { JobAd, JobAdLog } from 'store/entities/job-ads/models';

const jobAdBasicUrl = '/api/company/{companyId}';

const createWithBasicUrl = createUrlWithParams(jobAdBasicUrl);

export const getJobAd = (jobAdId: JobAd['id']) =>
  api().request<JobAdResponseItem & JobAd>({
    method: 'GET',
    url: createWithBasicUrl(['ad', jobAdId]),
  });

export const getJobAdApplicantsPerPipelineStageFigures = (jobAdId: JobAd['id']) =>
  api().request<Pick<JobAd, 'jobApplicantsPerPipelineStageFigures'>>({
    url: createWithBasicUrl(['ad', jobAdId, 'applicants-per-pipeline-stage-figures']),
  });

export const getJobAdApplicantsPerDayFigures = (jobAdId: JobAd['id']) =>
  api().request<Pick<JobAd, 'jobApplicantsPerDayFigures'>>({
    url: createWithBasicUrl(['ad', jobAdId, 'applicants-per-day-figures']),
  });

const prepareJobAdAbility = <J extends Partial<JobAd>>(item: J) => {
  return {
    ...item,
    requirements: item?.requirements?.map(removeId),
    responsibilities: item.responsibilities?.map(removeId),
  };
};

const putJobAdTransformed = [prepareJobAdAbility, ...(Axios.defaults.transformRequest as any)];

export const createJobAd = (data: PostJobAdParams) =>
  api().request<Pick<JobAd, 'jobAdId'>>({
    data,
    method: 'POST',
    transformRequest: putJobAdTransformed,
    url: createWithBasicUrl(['ad']),
  });

export const putJobAd = (jobAdId: JobAd['id'], data: PutJobAdParams) =>
  api().request<JobAd>({
    data,
    method: 'PUT',
    transformRequest: putJobAdTransformed,
    url: createWithBasicUrl(['ad', jobAdId]),
  });

export const postJobAd = (data: RePostJobAdParams) =>
  api().request<void>({
    data,
    method: 'POST',
    url: createWithBasicUrl(['ad', data.jobAdId, 'post']),
  });

export const repostJobAd = (data: RePostJobAdParams) =>
  api().request<void>({
    data,
    method: 'POST',
    url: createWithBasicUrl(['ad', data.jobAdId, 'repost']),
  });

export const cancelJobAd = (data: CancelJobAdParams) =>
  api().request<void>({
    data,
    method: 'POST',
    url: createWithBasicUrl(['ad', data.jobAdId, 'cancel']),
  });

export const jobAdAutocomplete = (searchTerm: string) =>
  api().request<SearchAutocompleteOptions>({
    method: 'GET',
    params: { searchTerm },
    url: createWithBasicUrl(['candidate', 'autocomplete']),
  });

export const logsJobAd = (data: JobAdLogsParams) =>
  api().request<ApiResponseWithPagination<JobAdLog>>({
    method: 'GET',
    params: data.params,
    url: createWithBasicUrl(['ad', data.jobAdId, 'change-note']),
  });
