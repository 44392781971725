import React from 'react';
import styled from 'styled-components';

import { validationSchemaNewApplicantFiveStep } from 'containers/ApplicantForm';
import { ApplicantForm } from 'containers/ApplicantForm/ApplicantFormState/applicant-form.models';
import { ApplicantDetailsFormBody } from 'containers/ApplicantForm/FormBody';

import { Form } from 'components';
import {
  ExDialog,
  ExDialogActionButton,
  ExDialogActions,
  ExDialogBody,
  ExDialogFooter,
  ExDialogProps,
} from 'components/ui/ExDialog';
import { useLoaderSubscription } from 'modules/LoaderManager/react';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { applicantsActions, applicantSelectors } from 'store/entities/applicants';
import { exModalConfirmAction } from 'store/modals/modals.actions';
import { ExModal } from 'store/modals/modals.interfaces';
import { useSelector } from 'store/rootSelectors';

type ApplicantDetailsEditProps = {
  className?: string;
  id: string;
  modalProps: { applicantId: string };
} & ExModal &
  ExDialogProps;

const ApplicantDetailsEditStyled = styled(ExDialog)``;

const useApplicantDetailsEditState = ({
  className,
  id,
  modalProps,
  onClose,
  modalConfig,
}: ApplicantDetailsEditProps) => {
  const dispatch = useAppDispatch();
  const defaultValues = useSelector((state) => applicantSelectors.getById(state, modalProps.applicantId));

  const onSubmit = (applicant: Partial<ApplicantForm>) => {
    dispatch(exModalConfirmAction({ id, modalResult: { applicant } }));
  };

  const content = modalConfig?.content ?? {};

  const validationSchema = validationSchemaNewApplicantFiveStep;

  const { isLoading: isFormProcessing } = useLoaderSubscription(applicantsActions.applicantsModalEditDetailsShow);

  const onCloseButtonClickHandler: React.MouseEventHandler<HTMLButtonElement> = () => onClose(false);

  return {
    className,
    onSubmit,
    defaultValues,
    onClose,
    content,
    validationSchema,
    isFormProcessing,
    onCloseButtonClickHandler,
  } as const;
};

export const ApplicantDetailsEdit: React.FC<ApplicantDetailsEditProps> = (props) => {
  const {
    className,
    onSubmit,
    defaultValues,
    onClose,
    content,
    validationSchema,
    isFormProcessing,
    onCloseButtonClickHandler,
  } = useApplicantDetailsEditState(props);

  return (
    <ApplicantDetailsEditStyled {...{ content, onClose, className }}>
      <Form {...{ defaultValues, onSubmit, validationSchema }}>
        <ExDialogBody>
          <ApplicantDetailsFormBody />
        </ExDialogBody>
        <ExDialogFooter>
          <ExDialogActions>
            <ExDialogActionButton variant="light" onClick={onCloseButtonClickHandler}>
              Close
            </ExDialogActionButton>
            <ExDialogActionButton variant="success" type="submit" isLoading={isFormProcessing}>
              Update
            </ExDialogActionButton>
          </ExDialogActions>
        </ExDialogFooter>
      </Form>
    </ApplicantDetailsEditStyled>
  );
};
