import { EntityKeys, UIKeys } from 'store/constants';

export const initialState = {
  entities: {
    [EntityKeys.jobs]: {
      allIds: [],
      byId: {},
    },
  },
  ui: {
    [UIKeys.documentMeta]: {
      documentTitle: '',
    },
  },
};
