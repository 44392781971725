import { useReactSelectThemedOverrideStyles } from 'utils/hooks/useReactSelectThemedOverrideStyles';

/**
 * This hook used to generate override styles for the modals with opened react multiselect
 * For example modals like AddToTalentPool, AddIndustries, AddCountries, etc...
 */
export function useMultiselectReactSelectStyles() {
  return useReactSelectThemedOverrideStyles(theme => ({
    menu: base => ({
      ...base,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.border.borderColor,
      boxShadow: 'none',
      marginTop: '20px',
      position: 'static',
    }),
    control: base => ({
      ...base,
      maxHeight: '250px',
      overflow: 'auto',
    }),
  }));
}
