import React from 'react';
import styled from 'styled-components';

interface TickProps {
  hideNotSelected?: boolean;
}

export const Tick = styled.div<TickProps>`
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: ${(props) => (props.hideNotSelected ? 'transparent' : props.theme.colors.neutral)};

  &:hover {
    cursor: pointer;
  }

  &.selected {
    background: ${(props) => props.theme.colors.primary};

    &::after {
      content: '';
      display: block;
      width: 5px;
      height: 8px;
      border-radius: 1px;
      border-bottom: 2px solid ${(props) => props.theme.colors.white};
      border-right: 2px solid ${(props) => props.theme.colors.white};
      transform: rotate(45deg) translate3d(5px, -2px, 0);
      transform-origin: center;
    }
  }
`;

interface TickBoxProps {
  hideNotSelected?: boolean;
  preventAction?: boolean;
  selected: boolean;
  handleSelect: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}

export const TickBox: React.FC<TickBoxProps> = ({
  hideNotSelected,
  selected,
  handleSelect,
  preventAction,
  className,
}) => {
  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    if (preventAction) return;
    handleSelect(e);
  };
  return (
    <Tick
      hideNotSelected={hideNotSelected}
      className={`${className} ${selected ? 'selected' : ''}`}
      onClick={handleClick}
    />
  );
};
