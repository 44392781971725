import type {
  DateTimeType,
  EmploymentType,
  InterviewState,
  InterviewTemplateState,
  QuestionType,
} from 'model/api-enums.constants';

import type { EnumLiteralsOf } from 'utils/funcs';
import type { BasicModel, ListStateFilter } from 'utils/reducer/reducer-helper';

export interface Currency {
  id: string;
  currency: string;
  currencyFullName: string;
  symbol: string;
  unicode: string;
}

export interface Sector {
  id: string;
  name: string;
}

export interface Country {
  countryId: number;
  name: string;
  code: {
    expedoCode2: string;
    expedoCode3: string;
    idibuCode2: string;
  };
}

export type Industry = {
  industryId: string;
  name: string;
};

export interface Position {
  id: number;
  category: string;
  name: string;
  requirements: Requirement[];
  responsibilities: Responsibility[];
}

export interface CompanyPosition {
  categoryId: string | number;
  categoryName: string;
  positionId: string | number;
  positionName: string;
}

export enum SourceEntity {
  'Position' = 1,
  'Job',
  'JobAd',
}

export interface Ability {
  id: number | string;
  text: string;
  isEnabled: boolean;
  sourceEntity?: SourceEntity;
}

export interface Requirement extends Ability {
  description?: string;
}

export interface Responsibility extends Ability {
  description?: string;
}

type PositionDetailsEmploymentTypes = {
  type: EmploymentType;
  name: string;
};

export interface PositionDetails {
  positionId: number;
  positionName: string;
  description: string;
  employmentTypes: PositionDetailsEmploymentTypes[];
}

export interface Location {
  id: number;
  placeName: string;
  countryCode: string;
  postalCode: string;
  latitude: number;
  longitude: number;
  county: string;
  region: string;
  country: string;
  source: number;
}

export interface AppFile {
  documentId?: string;
  documentPreviewFileId?: string | null;
  resumeId?: string;
  resumePreviewFileId?: string | null;
  photoId?: string;
  filename?: string;
  filetype?: string;
  createdBy?: string;
  createdOn?: string; //Date
  processing?: boolean;
  fileUrl?: string;
  fileId?: string;
  fileName?: string;
  fileType?: string;
  parsedData?: any;
}

export interface FileUpload {
  fileId: string;
  fileName: string;
  fileType: string;
}

export interface ApiResponseWithPagination<T> {
  totalItemsCount: number;
  pageNo: number;
  pageSize: number;
  pageCount: number;
  items: T[];
  filter?: ListStateFilter;
}

export interface AutocompleteResponse extends BasicModel {
  name: string;
}

export interface ApiRequestParamsWithPaginationAndFilters<
  T extends Record<string | number, Pick<T, 'pageNo' | 'pageSize'>> = any,
> {
  pageNo: number;
  pageSize: number;

  [x: string]: any;
}

export enum DocumentDownloadFormat {
  MicrosoftExcel = 'MicrosoftExcel',
  AdobePdf = 'AdobePdf',
}

export enum DocumentFileTypeIcon {
  adobePdf = 'application/pdf', // pdf
  microsoftWord = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  microsoftWordOctetStream = 'application/octet-stream', // doc, docx
  microsoftWordMsword = 'application/msword',
  rtf = 'application/rtf', // rtf
  jpeg = 'image/jpeg', // jpeg, jpg
  png = 'image/png', // png
  txt = 'text/plain', // txt
  xls = 'application/vnd.ms-excel', // xls
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
}

export interface SearchAutocomplete {
  name: string;
  id: string;
}

export type SearchAutocompleteOptions = Array<SearchAutocomplete>;

export interface FilterOption<T = any> {
  value: T;
  label: string;
}

export interface Analytics<T = string> {
  key: T;
  value: number;
}

export type JobApplicantsByStage = {
  stageType: number;
  pipelineStageId: string;
  name: string;
  value: number;
};

export type JobApplicantsByStageType = {
  stageType: number;
  value: number;
};

export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

export interface ParsingResult {
  parsingResults: ParsingResultElement[];
  jobId?: string;
  talentPoolId?: string;
}

export interface ParsingResultElement {
  key: string;
  value: null | string;
  candidateId: string | null;
  fileName: string;
  isSuccess: boolean;
  validationErrorCodes: string[] | null;
}

export type AdditionalFieldValidatorType = 'fieldMaxLength' | 'onlyNumbers';

export type AdditionalFieldValidator = {
  control: string;
  field: {
    title: string;
  };
  type: AdditionalFieldValidatorType;
  validationErrorMessage: string;
};

export interface AdditionalField {
  key: string;
  value: null | string | number;
  childValue?: null | string | number;
  values?: ApiValueForDynamicForm[];
  options: ApiValueForDynamicForm[];
  displayName: string;
  required: boolean;
  validator: AdditionalFieldValidator | null;
  parent?: string | null;
  type: string;

  hidden?: boolean;
  isEmpty?: boolean;
  isSingle?: boolean;
  sortIndex: number | null;
  defaultValue: ApiValueForDynamicForm | null;
  subtext?: string;
}

export interface ApiValueForDynamicForm {
  key: string;
  value: string;
  children?: ApiValueForDynamicForm[];
}

export interface PostingDuration {
  value?: number;
  title?: string;
}

export interface ExComment {
  commentId: string;
  createdBy: {
    userId: string;
    userName: string;
    isRemoved: boolean;
    photo: null | string;
  };
  createdOn: string; //Date;
  lastModifiedOn: string | null; //Date;
  lastModifiedBy: string | null;
  read: boolean;
  text: string;
  mentionedUsers?: string[];
}

export interface ExCommentWidget extends ExComment {
  commentId: string;
  companyId?: string;
  applicantId?: string;
  applicant?: {
    applicantId: string;
    name: string;
  };
  candidateId?: string;
  candidate?: {
    candidateId: string;
    name: string;
  };
  jobId?: string;
  job?: {
    jobId: string;
    name: string;
  };
  mentionOn?: string;
  mentionedBy?: {
    userId: string;
    userName: string | null;
    isRemoved: boolean;
    photo: null | string;
  };
  lastModifiedOn: string;
}

export interface ExCommentView {
  isRemoved: boolean;
  commentId: string;
  read: boolean;
  userFullName: string;
  userAvatar: string;
  createdOn: string;
  text: string;
  textView: string;
  updatedHint: string;
  isEditable: boolean;
  isEdited: boolean;
}

export type DateTimeDto = {
  dateTimeType: EnumLiteralsOf<typeof DateTimeType>;
  actualDate: string | null;
};

type DateType = null | DateTimeDto;

export interface ExEducation {
  educationId: string;
  companyId?: string;
  candidateId: string;
  applicantId?: string;
  name: string;
  number: string;
  organization: string;
  country: string | null;
  issuingAuthorityCity: string | null;
  qualificationId: string | null;
  startDate: DateType; // Date
  issueDate: DateTimeDto; // Date
  expiryDate: DateType; // Date
  educationAttachmentId: string | null;
  educationAttachmentFileName?: string | null;
  educationAttachmentFileType?: string | null;
}

export interface ExWorkExperience {
  workExperienceId?: string;
  companyName: string | null;
  country: string | null;
  address: null | string;
  city: string | null;
  postcode: null | string;
  state: string | null;
  position: string;
  startDate: DateType;
  endDate: DateType;
  currentlyEmployed: boolean;
  contactName: null | string;
  contactNumber: null | string;
  contactEmail: null | string;
}

export type ExInterview = {
  additionalText: string;
  createdBy: string;
  createdById: string;
  interviewId: string;
  interviewTemplateId: ExInterviewTemplate['interviewTemplateId'];
  interviewTemplateName: ExInterviewTemplate['name'] | null;
  jobApplicantId: string;
  jobApplicantName?: string;
  name: string;
  publishedById: string;
  publishedByName: string;
  publishedOn: string;
  state: EnumLiteralsOf<typeof InterviewState>;
  wasPublished: boolean;
  // custom
  updatedOn?: string;
};

export interface ExInterviewTemplateListItem {
  companyId: string;
  interviewTemplateId: string;
  isUsed: boolean;
  jobs: [];
  name: string;
  state: EnumLiteralsOf<typeof InterviewTemplateState>;
  canEdit: boolean;
  canDelete: boolean;
}

export interface ExJobInterviewTemplate {
  interviewTemplateId: string;
  interviewTemplateName: string;
  state: EnumLiteralsOf<typeof InterviewTemplateState>;
}

export interface ExInterviewTemplate {
  companyId: string;
  createdBy: string;
  createdOn: string;
  interviewTemplateId: string;
  isUsed: boolean;
  name: string;
  state: EnumLiteralsOf<typeof InterviewTemplateState>;
  canEdit: boolean;
  canDelete: boolean;
}

export interface ExNonPassedInterviewTemplate {
  interviewTemplateId: ExInterviewTemplate['interviewTemplateId'];
  name: ExInterviewTemplate['name'];
}

export interface ExQuestionChoices {
  value: string;
  selected: boolean;
}

export type ExInterviewQuestionFile = {
  interviewQuestionFileId: string;
  filename: string;
  filetype: string;
};

export type ExInterviewQuestionFileResponse = {
  interviewQuestionFileId: string;
  interviewQuestionFileName: string;
  interviewQuestionFileType: string;
};

export type ExInterviewQuestion = {
  questionType: EnumLiteralsOf<typeof QuestionType>;
  answer?: null | boolean | string;
  interviewQuestionFiles: ExInterviewQuestionFile[];
  interviewQuestionId: string;
  questionText: string;
  additionalInformation: string;
  isMandatory: boolean;
  isNA: boolean;
  choices?: ExQuestionChoices[];
};

export interface ExInterviewTemplateQuestion {
  interviewTemplateQuestionId: string;
  questionText: string;
  additionalInformation: string;
  questionType: EnumLiteralsOf<typeof QuestionType>;
  isMandatory: boolean;
  choices?: string[];
}

export interface ExQuestion {
  questionId: string;
  questionText: string;
  additionalInformation: string;
  isMandatory: boolean;
  answer?: null | string | boolean;
  questionType: EnumLiteralsOf<typeof QuestionType>;
  choices: ExQuestionChoices[];
  interviewQuestionFiles: ExInterviewQuestionFile[];
}

export type ExQuestionResponse = Omit<ExInterviewTemplateQuestion, 'choices'> & {
  choices?: string[] | null;
};

export interface ExQuestionFileUpload {
  interviewId: string;
  interviewQuestionId: string;
  data: FormData;
  questionText?: string;
}

export interface ExScreeningQuestionOptions {
  value: string;
  selected?: boolean;
}

export interface ExScreeningQuestionFile {
  fileId: string;
  fileName: string;
  fileType: string;
}

export interface ExScreeningQuestion {
  companyId: string;
  itemId: string;
  questionId?: string;
  jobScreeningQuestionId?: string;
  applicantScreeningQuestionId?: string;
  questionTitle: string;
  questionType: EnumLiteralsOf<typeof QuestionType>;
  isMandatory: boolean;
  isNA?: boolean;
  answer?: string | boolean | ExScreeningQuestionOptions[] | ExScreeningQuestionFile[] | null;
  options?: ExScreeningQuestionOptions[];
}

export enum VIEW_MODE {
  CREATE = 'CREATE',
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  DRAFT = 'DRAFT',
}

export enum SEEK_STAND_TYPE {
  CLASSIC = 'Classic',
  STANDOUT = 'StandOut',
  PREMIUM = 'Premium',
}
