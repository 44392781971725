import React from 'react';
import styled from 'styled-components';

interface RemoveIconProps {
  hovered: boolean;
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const RemoveIconStyled = styled.div<RemoveIconProps>`
  position: relative;
  width: 29px;
  height: 26px;
  background: ${(props) => props.theme.colors.light};
  border-radius: 4px;
  opacity: ${(props) => (props.hovered ? 1 : 0)};
  transition: opacity 0.3s;
  cursor: pointer;

  &.selected {
    opacity: 1;
    background: ${(props) => (props.hovered ? props.theme.colors.light : 'transparent')};

    &::before {
      background: ${(props) => props.theme.colors.primary};
    }
  }
`;

const RemoveIconClose = styled.div`
  position: absolute;
  top: 6px;
  left: 7px;
  background: ${(props) => props.theme.colors.subtitle};
  width: 14px;
  height: 14px;
  border-radius: 100%;

  &::after {
    content: '';
    display: block;
    width: 10px;
    height: 8px;
    border-bottom: 2px solid ${(props) => props.theme.colors.light};
    transform: rotate(45deg) translate3d(-2.2px, -8px, 0);
    transform-origin: center;

    &:hover {
      border-bottom-color: red;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 8px;
    border-bottom: 2px solid ${(props) => props.theme.colors.light};
    transform: rotate(135deg) translate3d(0.5px, -6.5px, 0);
    transform-origin: center;
  }
`;

const RemoveIcon: React.FC<RemoveIconProps> = ({ hovered, onClick }) => {
  return (
    <RemoveIconStyled hovered={hovered} onClick={onClick}>
      <RemoveIconClose />
    </RemoveIconStyled>
  );
};

export default RemoveIcon;
