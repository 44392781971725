import styled from 'styled-components';

import { PipelineStageType } from 'model/api-enums.constants';
import { PipelineValidationErrors } from 'model/api-errors.constants';

import { CompanyInfoItemClickable } from 'pages/Company/components/CompanyInfoItem';

import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import { ExModalBodyStyledWide } from 'containers/Modals/ModalsContent/Company/ExModalComponents';

import { Spinner } from 'components/Spinner';
import { ExInfoWithTooltip } from 'components/ui/ExInfoWithTooltip';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { getTranslate } from 'utils/i18utils';

import {
  hiringPipelinesStagesSelectors,
  hiringPipelineStageActions,
} from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.reducer';
import type { HiringPipelineStage } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.types';
import { enhancedHiringPipelineApi } from 'store/entities/hiring-pipelines/hiring-pipelines.api';
import type { HiringPipeline } from 'store/entities/hiring-pipelines/hiring-pipelines.types';
import { ExModal } from 'store/modals/modals.interfaces';

import { HiringPipelineEditModalStage } from './HiringPipelineEditModalStage';

type HiringPipelineEditProps = ExModal &
  AppModalContentProps & {
    modalProps: {
      pipelineId: HiringPipeline['pipelineId'];
    };
  };

const StagesHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

const StagesInfo = styled.div`
  margin-right: 10px;
`;

const StagesLabel = styled.span``;

function isSuperset(set: Set<any>, subset: Set<any>) {
  for (const elem of Array.from(subset)) {
    if (set.has(elem)) {
      return true;
    }
  }
  return false;
}

function isPipelineValidDuringEditing(hiringPipelineStages: HiringPipelineStage[]) {
  const stageTypeList = hiringPipelineStages.map((stage) => stage.stageType);
  const requiredStageTypes = new Set([
    PipelineStageType.Shortlisted,
    PipelineStageType.Interviewed,
    PipelineStageType.Screened,
    PipelineStageType.Custom,
  ]);
  const stageTypeListUnique = new Set([...stageTypeList]);

  return isSuperset(stageTypeListUnique, requiredStageTypes);
}

function useHiringPipelineEditModalState({ modalProps }: HiringPipelineEditProps) {
  const dispatch = useAppDispatch();
  const { pipelineId } = modalProps || {};

  const nonValidMsg = getTranslate(PipelineValidationErrors.PipelineMustHaveAtLeastOneOfSecondaryStage, {
    ns: 'validationErrorCodes',
  });

  const hiringPipelineStages = useAppSelector(
    hiringPipelinesStagesSelectors.selectPipelineStagesOrderedByGroups,
    pipelineId,
  );

  const isValid = isPipelineValidDuringEditing(hiringPipelineStages);

  const showCreateModalHandler = () => dispatch(hiringPipelineStageActions.showCreateModal({ pipelineId }));

  const { isFetching } = enhancedHiringPipelineApi.endpoints.searchPipeline.useQueryState({});

  return {
    hiringPipelineStages,
    showCreateModalHandler,
    isValid,
    nonValidMsg,
    isFetchingPipelines: isFetching,
  } as const;
}

export const HiringPipelineEditModal = (props: HiringPipelineEditProps) => {
  const { hiringPipelineStages, showCreateModalHandler, isValid, nonValidMsg, isFetchingPipelines } =
    useHiringPipelineEditModalState(props);

  if (!hiringPipelineStages) {
    return null;
  }

  return (
    <ExModalBodyStyledWide>
      <StagesHeader>
        {!isValid && (
          <StagesInfo>
            <ExInfoWithTooltip variant="warning" text={nonValidMsg} tooltipPosition="top" />
          </StagesInfo>
        )}
        <StagesLabel>Stages</StagesLabel>
      </StagesHeader>
      {isFetchingPipelines && <Spinner />}
      {!isFetchingPipelines && (
        <>
          {hiringPipelineStages.map((hiringPipelineStage) => (
            <HiringPipelineEditModalStage
              key={hiringPipelineStage.pipelineStageId}
              hiringPipelineStage={hiringPipelineStage}
            />
          ))}
          <CompanyInfoItemClickable onClick={showCreateModalHandler}>
            <b>+ Add New Stage</b>
          </CompanyInfoItemClickable>
        </>
      )}
    </ExModalBodyStyledWide>
  );
};
