import { createApiMethod } from 'api-endpoints/utils';

import { HiringPipelineEndpointsForApi } from './endpoints';

export const cancelDelayedPipelineStageAction = createApiMethod<
  { urlParams: { cancellationId: string }; data: { cancellationId: string } },
  any
>({
  url: HiringPipelineEndpointsForApi.CancelDelayedPipelineStageActionFunc,
  method: 'POST',
});
