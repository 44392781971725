import React from 'react';
import styled, { Colors } from 'styled-components';

import { Alert } from 'containers/AlertManager/Alert';
import { BulkModalStepProp } from 'containers/BulkCreation/bulkCreation.model';
import { ModalBodyStyled } from 'containers/Modals/ModalsContent/Wizard';

import { ReactComponent as CircleCheck } from 'assets/img/circle-check.svg';
import { ReactComponent as IconInfo } from 'assets/img/redesign/icons/info.svg';

import { ExButton } from 'components/ui/ExButton';
import { ExDialogActions, ExDialogFooter, ExDialogTitle } from 'components/ui/ExDialog';
import { ExColumn, ExRow } from 'components/ui/ExLayout';

import { ModalStepProps } from 'store/modals/config';

import { BulkItemFile } from './BulkItemFile';

const CircleCheckStyled = styled(CircleCheck)`
  width: 50px;
  height: 50px;
  fill: ${(props) => props.theme.colors.success};
  margin: 0 auto;
`;

const IconInfoStyled = styled(IconInfo)<{ variant: keyof Colors }>`
  fill: ${({ theme, variant }) => theme.colors[variant]};
  margin-right: 8px;

  &svg {
    fill: ${({ theme, variant }) => theme.colors[variant]};
  }
`;

const SuccessMessage = styled(ExDialogTitle)`
  font-weight: 700;
`;

const useBulkSuccessState = ({ onCloseDialogHandler, modalProps }: ModalStepProps) => {
  const { parsingResults, message, variant, messageSuccessUploaded } = modalProps as BulkModalStepProp;

  const iconInfoStyledVariant = variant === 'error' ? 'danger' : 'success';

  return {
    variant,
    message,
    parsingResults,
    onCloseDialogHandler,
    messageSuccessUploaded,
    iconInfoStyledVariant,
  } as const;
};

export const BulkSuccess: React.FC<ModalStepProps> = (props) => {
  const { onCloseDialogHandler, parsingResults, message, variant, messageSuccessUploaded, iconInfoStyledVariant } =
    useBulkSuccessState(props);

  return (
    <ModalBodyStyled>
      <ExRow>
        <ExColumn flex column>
          <CircleCheckStyled />
          <SuccessMessage>{messageSuccessUploaded}</SuccessMessage>
        </ExColumn>
        <ExColumn>
          <Alert variant={variant}>
            <IconInfoStyled variant={iconInfoStyledVariant} />
            <span>{message}</span>
          </Alert>
        </ExColumn>
        <ExColumn>
          {parsingResults?.map((file, index) => (
            <BulkItemFile
              isSuccess={file.isSuccess}
              fileName={file.fileName}
              key={index}
              fileId={file.value}
              validationErrorCodes={file.validationErrorCodes}
            />
          ))}
        </ExColumn>
      </ExRow>
      <ExDialogFooter>
        <ExDialogActions>
          <ExButton onClick={() => onCloseDialogHandler()} variant="light">
            Close
          </ExButton>
        </ExDialogActions>
      </ExDialogFooter>
    </ModalBodyStyled>
  );
};
