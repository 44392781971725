import { createSelector } from 'reselect';

import { companyCountryAdapter, useCompanyCountryQuery } from 'store/dictionary/dictionary.api';

export const useCompanyCountryQuerySelectors = () => {
  const query = useCompanyCountryQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  const adapterSelectors = companyCountryAdapter.getSelectors(
    () => query.data ?? companyCountryAdapter.getInitialState(),
  );

  const selectIdByExpedoCode2 = createSelector(
    adapterSelectors.selectAll,
    (_, expedoCode2: string) => expedoCode2,
    (countries, expedoCode2) => {
      return countries.find((country) => country.code.expedoCode2 === expedoCode2)?.countryId;
    },
  );

  const selectByExpedoCode2 = createSelector(
    companyCountryAdapter.getInitialState,
    selectIdByExpedoCode2,
    (state, id: number | undefined) => {
      if (!id) {
        return;
      }
      return adapterSelectors.selectById(state as any, id);
    },
  );

  const selectors = {
    ...adapterSelectors,
    selectByExpedoCode2,
    selectIdByExpedoCode2,
  };

  return {
    ...query,
    selectors,
  };
};
