export interface AppModalProps {
  backPath?: string;
  className?: string;
  modalType?: string | null;
  hideBackDrop?: boolean;
}

export interface AppModalContentProps {
  onClose: () => void;
}

export enum ModalsTypeKey {
  addToTalentPool = 'add-to-talent-pool',
  newTalentPool = 'new-talent-pool',
  editTalentPool = 'edit-talent-pool',
  editJobDetails = 'edit-job-details',
  editJobDescription = 'edit-job-position-details',
  editJobResponsibilities = 'edit-job-responsibilities',
  editJobRequirements = 'edit-job-requirements',
  editJobSalary = 'edit-job-salary',
  editJobAdDetails = 'edit-job-ad-details',
  editJobAdSalary = 'edit-job-ad-salary',
  editJobAdAdditionalFields = 'edit-job-ad-additional-fields',
  editJobBoardSettings = 'edit-job-board-settings',
  newCandidate = 'new-candidate',
  editCandidateDetails = 'edit-candidate-details',
  editCandidateEducation = 'edit-candidate-education',
  editCandidateWorkExperience = 'edit-candidate-work-experience',
  editJobAdResponsibilities = 'edit-job-ad-responsibilities',
  editJobAdRequirements = 'edit-job-ad-requirements',
  editJobAdDescription = 'edit-job-ad-position-details',
  confirmModal = 'confirm-modal',
  wizard = 'wizard',
  companyUpdateCountries = 'companyUpdateCountries',
  companyUpdateCurrencies = 'companyUpdateCurrencies',
  companyUpdateIndustries = 'companyUpdateIndustries',
  companyUpdateDateTime = 'companyUpdateDateTime',
  companyUpdateIntegrations = 'companyUpdateIntegrations',
  companyUpdateQualificationTypes = 'companyUpdateQualificationTypes',
  companyUpdateQualificationTypesMaps = 'companyUpdateQualificationTypesMaps',
  companyUpdateOnCost = 'companyUpdateOnCost',
  hiringPipelineEditName = 'hiringPipelineEditName',
  hiringPipelineEdit = 'hiringPipelineEdit',
  hiringPipelineStageCreateModal = 'hiringPipelineStageCreateModal',
  companyEmailTemplateFormModal = 'companyEmailTemplateModalForm',
  hiringPipelineStageRemoveModal = 'hiringPipelineStageRemoveModal',
  applicantsView = 'applicantsView',
  applicantViewModal = 'applicantViewModal',
  applicantDetailsEditModal = 'applicantDetailsEditModal',
  jobTeamEditModal = 'jobTeamMembersEditModal',
  jobTeamAddMemberModal = 'jobTeamAddMemberModal',
  jobTeamRemoveMemberModal = 'jobTeamRemoveMemberModal',
  jobAdPostModal = 'jobAdPostModal',
  publicModalConfirmTerms = 'publicModalConfirmTerms',
  mentionsModal = 'mentionsModal',
  interviewTemplateSettingModal = 'interviewTemplateSettingModal',
  newInterviewSettingsModal = 'newInterviewSettingsModal',
  updateJobInterviewTemplateModal = 'updateJobInterviewTemplateModal',
  bulkAddCandidateToTalentPool = 'bulkAddCandidateToTalentPool',
  bulkDoNotHireCandidates = 'bulkDoNotHireCandidates',
  addCandidateToTalentPool = 'addCandidateToTalentPool',
  applicantConfirmMoveModal = 'applicantConfirmMoveModal',
  jobScreeningQuestionsModal = 'jobScreeningQuestionsModal',
  jobAddResponsibilities = 'jobAddResponsibilities',
  jobAddRequirements = 'jobAddRequirements',
}
