import React from 'react';
import styled from 'styled-components/macro';

import {
  ApplicantInfo,
  CandidateAvatarStyled,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/ApplicantModalV3Components';
import {
  ApplicantSectionDivider,
  ApplicantSectionStyled,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalV4Components';
import { ApplicantAdditionalInfoView } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantAdditionalInfo/ApplicantAdditionalInfoView';
import { ApplicantModalAvailabilityInfoView } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalAvailabilityInfo/ApplicantModalAvailabilityInfoView';
import { ApplicantModalContactsInfoView } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalContactsInfo/ApplicantModalContactsInfoView';
import { ApplicantModalNameInfoView } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalNameInfo/ApplicantModalNameInfoView';
import { ApplicantModalSalaryInfoView } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalSalaryInfo/ApplicantModalSalaryInfoView';
import { ApplicantModalSeniorityLevelInfoView } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalSeniorityLevelInfo/ApplicantModalSeniorityLevelInfoView';
import { ApplicantModalStatusInfoView } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalStatusInfo/ApplicantModalStatusInfoView';
import { ApplicantModalTotalCountInfo } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/components/ApplicantModalTotalCountInfo';
import { useApplicantInfoSectionState } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/hook/useApplicantInfoSectionState';

import { ExVisible } from 'components/ui/ExVisible';

const ApplicantModalStatusInfoViewStyled = styled(ApplicantModalStatusInfoView)`
  width: 100%;
`;

type ApplicantInfoSectionViewProps = {
  className?: string;
};

export const ApplicantInfoSectionView: React.FC<ApplicantInfoSectionViewProps> = (props) => {
  const { className, applicant, userInitials, showApplicantModalCounter } = useApplicantInfoSectionState(props);

  if (!applicant) {
    return null;
  }

  return (
    <ApplicantSectionStyled className={className}>
      <ApplicantInfo>
        <CandidateAvatarStyled src={applicant.photoUrl} alt={applicant.name ?? ''} initials={userInitials} />
        <ApplicantModalNameInfoView />
        <ExVisible visible={showApplicantModalCounter}>
          <ApplicantModalTotalCountInfo />
        </ExVisible>
        <ApplicantModalStatusInfoViewStyled readonly={true} />
      </ApplicantInfo>
      <ApplicantSectionDivider />
      <ApplicantModalSalaryInfoView />
      <ApplicantModalSeniorityLevelInfoView />
      <ApplicantModalAvailabilityInfoView />
      <ApplicantSectionDivider />
      <ApplicantModalContactsInfoView />
      <ApplicantSectionDivider />
      <ApplicantAdditionalInfoView />
    </ApplicantSectionStyled>
  );
};
