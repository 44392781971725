import { useEffect } from 'react';
import { push } from 'connected-react-router';
import { Routes } from 'router';

import authService from 'containers/Auth/state/AuthenticationService';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';

export const HomePage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    async function checkAuth() {
      const isAuthenticated = await authService.isAuthenticated();

      if (isAuthenticated) {
        dispatch(push({ pathname: `/${Routes.dashboard}` }));
      } else {
        dispatch(push({ pathname: `/${Routes.login}` }));
      }
    }
    checkAuth();
  }, [dispatch]);

  return null;
};
