/* eslint-disable sonarjs/no-duplicate-string */
export const HiringPipelineEndpointsForApi = {
  SearchPipelineFunc: '/pipeline',
  GetDefaultPipelineFunc: '/pipeline/default',
  GetPipelineFunc: '/pipeline/{pipelineId}',
  ClonePipelineFunc: '/pipeline/{pipelineId}/clone',
  DeletePipelineFunc: '/pipeline/{pipelineId}',
  UpdatePipelineFunc: '/pipeline/{pipelineId}',
  CreatePipelineStageFunc: '/pipeline/{pipelineId}/stage',
  DeletePipelineStageFunc: '/pipeline/{pipelineId}/stage/{pipelineStageId}',
  UpdatePipelineStageFunc: '/pipeline/{pipelineId}/stage/{pipelineStageId}',
  UpdatePipelineStageOrderFunc: '/pipeline/{pipelineId}/order',
  GetPipelineStageActionsFunc: '/pipeline/{pipelineId}/stage/{pipelineStageId}/action',
  AddSendEmailActionFunc: '/pipeline/{pipelineId}/stage/{pipelineStageId}/action/send-email',
  DeleteSendEmailActionFunc: '/pipeline/{pipelineId}/stage/{pipelineStageId}/action/{pipelineStageActionId}',
  SetDefaultPipelineFunc: '/pipeline/{pipelineId}/default',
  EnablePipelineFunc: '/pipeline/{pipelineId}/enable',
  DisablePipelineFunc: '/pipeline/{pipelineId}/disable',
  AddPipelineStageActionFunc: '/pipeline/{pipelineId}/stage/{pipelineStageId}/action',
  CancelDelayedPipelineStageActionFunc: '/api/company/{companyId}/pipeline/pipelineStageAction/cancel/{cancellationId}',
} as const;
