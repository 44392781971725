import { Action } from 'redux';
import { all, call, put, race, select, take } from 'redux-saga/effects';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';
import { getTranslate } from 'utils/i18utils';

import * as fromCompanyActions from 'store/company/company.actions';
import { companySelectors } from 'store/company/company.selectors';
import type { CompanyInfo } from 'store/company/company.types';
import { exModalHideAction } from 'store/modals/modals.actions';
import { ModalGeneralResult } from 'store/modals/modals.interfaces';
import { modalSagaWorker } from 'store/modals/modals.sagas';

export function* companyUpdateDateFormatSaga(action: Action) {
  const company: ReturnType<typeof companySelectors.getCompanyInfo> = yield select(companySelectors.getCompanyInfo);
  /**
   * This variable needs to store modal id in case of network error to restart
   * saga with existed modal instead of rendering the new one.
   */
  let modalId: string | undefined;
  if (!company) {
    return;
  }

  while (true) {
    const result: ModalGeneralResult = yield call(modalSagaWorker, {
      ...(modalId ? { id: modalId } : {}),
      modalType: ModalsTypeKey.companyUpdateDateTime,
    });

    if (result.cancel || !result.confirm) {
      return;
    }

    yield call(startLoader, action);
    modalId = result.confirm.payload.id;

    const confirmPayload = result.confirm.payload;

    const requestData: CompanyInfo = {
      ...company,
      dateFormat: confirmPayload.modalResult?.dateFormat,
    };
    const companyId = company.companyId;

    yield put(
      fromCompanyActions.updateCompanyRequest({
        urlParams: { companyId },
        data: requestData,
      }),
    );

    const { success } = yield race({
      success: take(fromCompanyActions.updateCompanySuccess),
      failure: take(fromCompanyActions.updateCompanyFailure),
    });

    if (success) {
      yield all([
        put(exModalHideAction({ id: modalId })),
        put(
          alertsEffects.showSuccess({
            message: getTranslate('company.dateFormat.update.success'),
          }),
        ),
        put(fromCompanyActions.loadCompanyInfoRequest({ urlParams: { companyId } })),
        call(stopLoader, action),
      ]);
      break;
    }
    yield call(stopLoader, action);
  }
}
