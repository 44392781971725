import { combineReducers } from '@reduxjs/toolkit';

import { emailTemplateReducer } from 'pages/Company/CompanyTabs/CompanyEmailTemplateTab/state';

import { EntityKeys, EntityKeysNew } from 'store/constants';

import { applicantCommentsSlice } from './applicant-comments/applicant-comments.reducer';
import { hiringPipelinesStagesReducer } from './hiring-pipeline-stages/hiring-pipeline-stages.reducer';
import { hiringPipelinesReducer } from './hiring-pipelines/hiring-pipelines.reducer';
import { jobCommentsSlice } from './job-comments/job-comments.reducer';
import { qualificationTypeReducer } from './qualification-type/qualification-type.model';
import { talentPoolSlice } from './talent-pools/reducer';
import applicantsReducer from './applicants';
import { candidateEducationsReducer } from './candidate-education';
import { candidateWorkExperiencesReducer } from './candidate-work-experience';
import candidatesReducer from './candidates';
import jobAdsReducer from './job-ads';
import jobBoardsReducer from './job-boards';
import { jobScreeningQuestionsSlice } from './job-screening-questions';
import jobsReducer from './jobs';
import { onCostSlice } from './onCost';

export const entitiesReducer = combineReducers({
  [EntityKeys.jobs]: jobsReducer,
  [EntityKeys.applicants]: applicantsReducer,
  [EntityKeys.candidates]: candidatesReducer,
  [EntityKeysNew.candidateEducations]: candidateEducationsReducer,
  [EntityKeysNew.candidateWorkExperiences]: candidateWorkExperiencesReducer,
  [EntityKeysNew.jobBoards]: jobBoardsReducer,
  [EntityKeys.jobAds]: jobAdsReducer,
  [talentPoolSlice.name]: talentPoolSlice.reducer,
  [EntityKeysNew.qualificationTypes]: qualificationTypeReducer,
  [EntityKeysNew.hiringPipeline]: hiringPipelinesReducer,
  [EntityKeysNew.hiringPipelineStage]: hiringPipelinesStagesReducer,
  [EntityKeysNew.emailTemplate]: emailTemplateReducer,
  [onCostSlice.name]: onCostSlice.reducer,
  [EntityKeysNew.emailTemplate]: emailTemplateReducer,
  [applicantCommentsSlice.name]: applicantCommentsSlice.reducer,
  [jobCommentsSlice.name]: jobCommentsSlice.reducer,
  [EntityKeysNew.jobScreeningQuestions]: jobScreeningQuestionsSlice.reducer,
});
