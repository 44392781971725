import { createAsyncThunk } from '@reduxjs/toolkit';

import * as talentPoolApi from 'api-endpoints/talent-pool';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { loaderActions } from 'containers/Loader/store';
import { talentPoolFormActions } from 'containers/TalentPoolForms/state/talentPoolForms.reducer';

import { startLoaderThunk, stopLoaderThunk } from 'modules/LoaderManager/redux';
import { getTranslate } from 'utils/i18utils';

import { TalentPool } from 'store/entities/talent-pools/models';
import { TALENT_POOL_CREATE, TALENT_POOL_FETCH } from 'store/entities/talent-pools/talent-pool.actions';
import { AppEffectParams, ThunkApiConfig } from 'store/types';

export const loadTalentPoolTaskId = 'loadTalentPoolTaskId';

export const loadTalentPool = createAsyncThunk<
  { item: TalentPool },
  AppEffectParams<{ id: TalentPool['id'] }>,
  ThunkApiConfig
>(TALENT_POOL_FETCH, async ({ id, preloader }, { dispatch, rejectWithValue }) => {
  if (!id) {
    return rejectWithValue('Id not provided.');
  }

  startLoaderThunk(dispatch, loadTalentPool);
  if (preloader) {
    dispatch(loaderActions.start(loadTalentPoolTaskId));
  }

  const { data, message } = await talentPoolApi.getTalentPoolById({ id });
  if (message) {
    if (preloader) {
      dispatch(loaderActions.stop(loadTalentPoolTaskId));
    }
    dispatch(alertsEffects.showError({ message }));
    return rejectWithValue(message);
  }
  if (preloader) {
    dispatch(loaderActions.stop(loadTalentPoolTaskId));
  }
  stopLoaderThunk(dispatch, loadTalentPool);
  return { item: data };
});

export const bulkCandidatesActionTaskId = 'bulkCandidatesActionTaskId';

export const saveTalentPool = createAsyncThunk<
  { item: Pick<TalentPool, 'id'> },
  AppEffectParams<{ formData: Partial<TalentPool> }>,
  ThunkApiConfig
>(TALENT_POOL_CREATE, async ({ formData, preloader }, { dispatch, requestId, rejectWithValue }) => {
  if (preloader) {
    startLoaderThunk(dispatch, saveTalentPool);
    dispatch(loaderActions.start(requestId));
  }
  const { data, message, response } = formData.id
    ? await talentPoolApi.putTalentPool({ data: formData })
    : await talentPoolApi.postTalentPool({ data: formData });

  if (message) {
    if (preloader) {
      stopLoaderThunk(dispatch, saveTalentPool);
      dispatch(loaderActions.stop(requestId));
    }
    if (response?.data.validationErrorCodes) {
      response?.data.validationErrorCodes.forEach((validationErrorCode) => {
        const errorMessageOnUserLanguage = getTranslate(validationErrorCode, { ns: 'validationErrorCodes' });
        dispatch(
          alertsEffects.showError({
            message: errorMessageOnUserLanguage || message || 'Error',
          }),
        );
      });
      dispatch(
        talentPoolFormActions.updateState({
          apiErrors: response?.data.validationErrorCodes || [],
        }),
      );
    }
    if (!response?.data.validationErrorCodes) {
      dispatch(
        alertsEffects.showError({
          message,
        }),
      );
    }

    return rejectWithValue(message);
  }
  if (preloader) {
    dispatch(loaderActions.stop(requestId));
    stopLoaderThunk(dispatch, saveTalentPool);
  }
  return { item: { ...formData, ...data } };
});
