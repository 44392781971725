import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components/macro';
import uniqueId from 'lodash/uniqueId';

import { FileUpload } from 'model';

import { PUBLIC_PAGE_MIN_WIDTH } from 'pages/public/components/PublicPageStyles';

import { ReactComponent as IconNo } from 'assets/img/switch-no.svg';
import { ReactComponent as IconYes } from 'assets/img/switch-yes.svg';

import { Ellipsis } from 'components/Ellipsis';
import { Spinner } from 'components/Spinner';

type FileUploaderProps = {
  className?: string;
  disabled?: boolean;
  multiple?: boolean;
  multipleForm?: boolean;
  accept?: string;
  onChange: (files: File[]) => void;
  onClick?: (file: File) => void;
  onDelete?: (file: File) => void;
  deletingDisabled?: boolean;
  files?: FileUpload[];
  isLoading?: boolean;
};

const UploadedFileDeleteButtonIcon = styled(IconNo)`
  width: 9px;
`;

const IconYesStyled = styled(IconYes)``;

type FileUploaderStyledProps = {
  isActive?: boolean;
};

const isActiveFileUploader = ({ isActive }: FileUploaderStyledProps) =>
  isActive &&
  css`
    border: 1px solid #0b78ff;
  `;

const FileUploaderStyled = styled.div<FileUploaderStyledProps>`
  border-radius: 8px;
  border: 1px dashed rgba(0, 0, 0, 0.08);

  ${isActiveFileUploader};
`;

const FileUploaderLabel = styled.label`
  cursor: pointer;
  margin: 0;
  display: block;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #7d89b0;
`;

const uploadedWrapperStyles = css`
  padding: 32px 40px;
`;

const UploadedWrapper = styled.div`
  ${uploadedWrapperStyles};
`;

const FileUploaderLabelBig = styled(FileUploaderLabel)`
  ${uploadedWrapperStyles};
`;

const FileUploaderLabelWithFiles = styled(FileUploaderLabel)`
  padding-top: 6px;
`;

const FileUploaderLabelTitle = styled.span`
  color: #0b78ff;
`;

const FileUploaderLabelSubTitle = styled.span`
  display: none;

  @media (min-width: ${PUBLIC_PAGE_MIN_WIDTH}) {
    display: inline;
  }
`;

const UploadedFileStyled = styled.div`
  background-color: #f5faff;
  border-radius: 8px;
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const UploadedFileStatus = styled.div`
  flex-shrink: 0;
  margin-right: 16px;
  width: 12px;
`;

const UploadedFileName = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  flex-grow: 1;
  cursor: pointer;
`;

const UploadedFileDeleteButton = styled.button.attrs({ type: 'button' })`
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
`;

const Loader = styled(Spinner)`
  margin: 0;
  width: 10px;
  height: 10px;
  font-size: 2px;
`;

const useFileUploaderState = ({
  className,
  disabled: disabledProps,
  accept,
  multiple,
  multipleForm,
  onChange,
  files,
  onClick,
  onDelete,
  deletingDisabled,
  isLoading,
}: FileUploaderProps) => {
  const id = uniqueId();

  const [uploadedFiles, setUploadedFiles] = useState(files || []);
  const hasUploadedFiles = Boolean(uploadedFiles.length);

  const disabled = disabledProps || isLoading;
  const multipleVisual = multiple || multipleForm;

  useEffect(() => {
    if (files) {
      setUploadedFiles(files);
    }
  }, [files]);

  const onDrop = useCallback(
    (acceptedFiles, _, event) => {
      const updatedFiles = acceptedFiles.map((acceptedFile) => ({
        fileId: uniqueId(),
        fileName: acceptedFile.name,
        fileType: acceptedFile.type,
        loading: true,
      }));

      const result = onChange(acceptedFiles);

      const validUpdatedFiles = updatedFiles.filter((_file, index) => {
        if (!result[index]) {
          return true;
        }

        return result[index].isValid;
      });

      if (result[0]?.isValid) {
        if (multipleVisual) {
          setUploadedFiles([...uploadedFiles, ...validUpdatedFiles]);
        } else {
          setUploadedFiles(validUpdatedFiles);
        }
      }

      event.target.value = '';
    },
    [multipleVisual, onChange, uploadedFiles],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true, disabled });

  const onDownloadHandler = useCallback(
    (file) => {
      onClick && onClick(file);
    },
    [onClick],
  );

  const onDeleteHandler = useCallback(
    (file) => {
      const filteredFiles = uploadedFiles.filter((uploadedFile) => uploadedFile.fileId !== file.fileId);
      setUploadedFiles(filteredFiles);

      onDelete && onDelete(file);
    },
    [onDelete, uploadedFiles],
  );

  return {
    className,
    getRootProps,
    getInputProps,
    isDragActive,
    disabled,
    accept,
    multipleForm,
    id,
    hasUploadedFiles,
    uploadedFiles,
    onDownloadHandler,
    onDeleteHandler,
    deletingDisabled,
    isLoading,
  } as const;
};

const UploadedFile = ({ file, onDownload, onDelete, deletingDisabled }) => {
  const onDownloadHandler = useCallback(() => {
    if (file.loading) {
      return;
    }

    onDownload(file);
  }, [file, onDownload]);

  const onDeleteHandler = useCallback(() => {
    onDelete(file);
  }, [file, onDelete]);

  return (
    <UploadedFileStyled>
      <UploadedFileStatus>{file.loading ? <Loader /> : <IconYesStyled />}</UploadedFileStatus>
      <UploadedFileName onClick={onDownloadHandler}>
        <Ellipsis>{file.fileName}</Ellipsis>
      </UploadedFileName>
      <div>
        {!file.loading && !deletingDisabled && (
          <UploadedFileDeleteButton onClick={onDeleteHandler}>
            <UploadedFileDeleteButtonIcon />
          </UploadedFileDeleteButton>
        )}
      </div>
    </UploadedFileStyled>
  );
};

export const FileUploader: React.FC<FileUploaderProps> = (props) => {
  const {
    className,
    getRootProps,
    getInputProps,
    isDragActive,
    disabled,
    accept,
    multipleForm,
    id,
    hasUploadedFiles,
    uploadedFiles,
    onDownloadHandler,
    onDeleteHandler,
    deletingDisabled,
    isLoading,
  } = useFileUploaderState(props);

  return (
    <FileUploaderStyled className={className} isActive={isDragActive} {...getRootProps()}>
      {hasUploadedFiles ? (
        <UploadedWrapper>
          {uploadedFiles.map((uploadedFile) => (
            <UploadedFile
              key={uploadedFile.fileId}
              file={uploadedFile}
              onDownload={onDownloadHandler}
              onDelete={onDeleteHandler}
              deletingDisabled={deletingDisabled}
            />
          ))}
          <FileUploaderLabelWithFiles htmlFor={id}>
            <FileUploaderLabelTitle>Upload a file</FileUploaderLabelTitle>
            <FileUploaderLabelSubTitle> or drag and drop here</FileUploaderLabelSubTitle>
          </FileUploaderLabelWithFiles>
        </UploadedWrapper>
      ) : (
        <>
          {isLoading ? (
            <Spinner />
          ) : (
            <FileUploaderLabelBig htmlFor={id}>
              <FileUploaderLabelTitle>Upload a file</FileUploaderLabelTitle>
              <FileUploaderLabelSubTitle> or drag and drop here</FileUploaderLabelSubTitle>
            </FileUploaderLabelBig>
          )}
        </>
      )}
      <input
        {...getInputProps()}
        hidden
        type="file"
        id={id}
        disabled={disabled}
        accept={accept}
        multiple={multipleForm}
      />
    </FileUploaderStyled>
  );
};
