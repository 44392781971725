import styled from 'styled-components';

import { CandidateAvatar } from 'components/CandidateAvatar/CandidateAvatar';

export const CandidateAvatarTable = styled(CandidateAvatar)`
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;
