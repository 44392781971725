import { all, call, takeLatest } from 'redux-saga/effects';

import { calendarActions } from 'containers/Calendar/state/calendar.actions';
import { calendarJobAdsActions } from 'containers/Calendar/state/calendar-job-ads/calendar-job-ads.actions';
import { calendarJobAdsFetchAction } from 'containers/Calendar/state/calendar-job-ads/calendar-job-ads.saga';
import { calendarJobsActions } from 'containers/Calendar/state/calendar-jobs/calendar-jobs.actions';
import { calendarJobsFetchAction } from 'containers/Calendar/state/calendar-jobs/calendar-jobs.saga';

import { startLoader, stopLoader } from 'modules/LoaderManager/redux/saga';

export function* calendarFetchAction(action: ReturnType<typeof calendarActions.calendarFetchAction>) {
  yield startLoader(action);
  yield all([
    call(calendarJobsFetchAction, calendarJobsActions.calendarJobsFetchAction()),
    call(calendarJobAdsFetchAction, calendarJobAdsActions.calendarJobAdsFetchAction()),
  ]);
  yield stopLoader(action);
}

export function* calendarSagas() {
  yield takeLatest(calendarJobsActions.calendarJobsFetchAction, calendarJobsFetchAction);
  yield takeLatest(calendarJobAdsActions.calendarJobAdsFetchAction, calendarJobAdsFetchAction);
  yield takeLatest(calendarActions.calendarFetchAction, calendarFetchAction);
}
