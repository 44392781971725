export const dateFormat = {
  formatDateForView: 'dd/MM/yyyy',
  formatDateForViewDateRange: 'MMMM dd, yyyy',
  formatDateForDateInput: 'yyyy-MM-dd',
  formatDateForLineChartView: 'dd MMM',
  formatDateTimePeriod: 'dd-MM-yyyy, hh:mm aa',
  formatDateForJobSummaryView: 'dd MMM',
  formatDateForApplicantTable: 'MMM d, yyyy',
  formatDateForJobAdSummaryView: 'dd MMM, yyyy',
  formatDateForJobSummaryViewUpdated: 'dd MMM yyyy',
  formatDateForDocumentCreatedOnView: 'MMM dd, yyyy',
  formatDateForEducationCard: 'yyyy',
  formatDateForDashboardApplicantsForReview: 'dd MMM',
  formatDateForInterviewTable: 'MMM d, yyyy ha',
  formatDateHourShort: 'ha',
} as const;
