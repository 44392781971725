import { createAction } from '@reduxjs/toolkit';

type CustomLoadActionProps = {
  type: 'loadWorkExperience' | 'loadEducation' | 'loadComment';
  id: string;
};

export const customLoadAction = createAction('customLoadAction', ({ type, id }: CustomLoadActionProps) => {
  return {
    payload: {
      type,
      id,
    },
  };
});
