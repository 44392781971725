import React from 'react';

import { ModalsTypeKey } from 'containers/Modals/AppModalProps';
import { modalsActions } from 'containers/Modals/store';

import { EmptyState } from 'components/EmptyState';
import { IconEmptyStateSuitcase } from 'components/Icons/IconEmptyStateSuitcase';
import { ExAddNewButton } from 'components/ui/ExAddNewButton/ExAddNewButton';
import { ExButtonExtendedProps } from 'components/ui/ExButton';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

export type InterviewActionProps = {
  className?: string;
  variant?: ExButtonExtendedProps['variant'];
  size?: ExButtonExtendedProps['size'];
  text?: string;
};

export const InterviewAction: React.FC<InterviewActionProps> = ({ className, text = 'Add new', variant, size }) => {
  const dispatch = useAppDispatch();

  const onClickAddNewHandler: React.MouseEventHandler = () => {
    dispatch(modalsActions.showModal({ modalType: ModalsTypeKey.newInterviewSettingsModal }));
  };

  return (
    <ExAddNewButton variant={variant} size={size} className={className} onClick={onClickAddNewHandler}>
      {text}
    </ExAddNewButton>
  );
};

type InterviewPlaceholderProps = {
  title: string;
  text?: string;
};

export const InterviewPlaceholder: React.FC<InterviewPlaceholderProps> = ({ title, text = 'Add new' }) => {
  return (
    <EmptyState title={title} icon={<IconEmptyStateSuitcase />}>
      <InterviewAction variant="primary" size="sm" text={text} />
    </EmptyState>
  );
};
