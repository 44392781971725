import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import * as modalSideComponents from './ModalSideComponents';
import { ModalSideComponents, ModalSideProps } from './ModalSideProps';

const ModalSide: React.FC<ModalSideProps> & ModalSideComponents = ({ onClose, children, className }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const handlerCloseModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const root = modalRef.current;
    const close = root === e.target;
    if (close) {
      onClose && onClose();
    }
  };
  return createPortal(
    <modalSideComponents.ModalBackground ref={modalRef} className={className} onMouseDown={handlerCloseModal}>
      <modalSideComponents.ModalWrapper>{children}</modalSideComponents.ModalWrapper>
    </modalSideComponents.ModalBackground>,
    document.body,
  );
};

ModalSide.ModalHeader = modalSideComponents.ModalHeader;
ModalSide.ModalTitle = modalSideComponents.ModalTitle;
ModalSide.ModalBody = modalSideComponents.ModalBody;
ModalSide.ModalWrapper = modalSideComponents.ModalWrapper;
ModalSide.ModalActionWrapper = modalSideComponents.ModalActionWrapper;
ModalSide.ModalCSSTransition = modalSideComponents.ModalCSSTransition;

export default styled(ModalSide)``;
