import { AppFile } from 'model';

/**
 * Check is AppFile item is type of PDF document.
 *
 * @param {AppFile} item
 * @returns {boolean}
 */
export const isFilePdf = (item: AppFile): boolean => Boolean(item.filetype?.includes('/pdf'));

export const hasPreview = (item: AppFile) => Boolean(item.resumePreviewFileId || item.documentPreviewFileId);

export const isPdfFileType = (fileType = ''): boolean => fileType?.includes('/pdf');

export const FILE_INPUT_ACCEPT_RESUME = '.doc,.docx,.pdf';
export const FILE_INPUT_ACCEPT_DOCUMENT = '.doc,.docx,.pdf';

type FileValidationRule = {
  type: 'type' | 'size';
  rule: string | number;
};

export type FileValidationRules = FileValidationRule[];

export type FileValidationResult = {
  isValid: boolean;
  errors: string[];
};

export const fileValidationRuleTypeResume: FileValidationRule = {
  type: 'type',
  rule: FILE_INPUT_ACCEPT_RESUME,
};

export const fileValidationRuleTypeDocument: FileValidationRule = {
  type: 'type',
  rule: FILE_INPUT_ACCEPT_DOCUMENT,
};

export const fileValidationRuleSizeLessThan10Mb: FileValidationRule = {
  type: 'size',
  rule: 10_000_000,
};

const getFileExtension = (file: File): string => {
  return file?.name?.split('.').at(-1) ?? '';
};

const getFileTypeParts = (file: File): [string, string] => {
  const [typeBase, typeExt] = file?.type?.split('/') ?? ['', ''];

  return [typeBase, typeExt];
};

const isValidFileType = (file: File, acceptTypes: string) => {
  if (!file) {
    return false;
  }

  const extension = getFileExtension(file);
  const [typeBase, typeExt] = getFileTypeParts(file);

  switch (true) {
    case !acceptTypes: {
      return true;
    }
    case !extension && !typeBase && !typeExt: {
      return true;
    }
    case extension && acceptTypes.includes(extension): {
      return true;
    }
    case typeExt && acceptTypes.includes(typeExt): {
      return true;
    }
    case typeBase && acceptTypes.includes(typeBase): {
      return true;
    }
    default:
      return false;
  }
};

const isValidFileSize = (file: File, acceptSize: number) => {
  if (!file) {
    return false;
  }

  return file.size <= acceptSize;
};

const fileValidators = {
  type: isValidFileType,
  size: isValidFileSize,
};

const fileErrors = {
  type: 'Unsupported file type',
  size: 'File is too big',
};

export const validateFile = (file: File, validationOptions: FileValidationRules): FileValidationResult => {
  if (!file) {
    return {
      isValid: false,
      errors: ['Missed file'],
    };
  }

  const errors: string[] = validationOptions.reduce(
    (acc: string[], { type, rule }: { type: string; rule: FileValidationRule['rule'] }): string[] => {
      const validator: (file: File, rule: FileValidationRule['rule']) => boolean = fileValidators[type];

      if (validator && !validator(file, rule)) {
        acc.push(fileErrors[type]);
      }

      return acc;
    },
    [],
  );

  return {
    isValid: !errors.length,
    errors,
  };
};
