import React, { useRef } from 'react';

import { loaderActions } from 'containers/Loader/store';
import { ApplicantContext } from 'containers/Modals/ModalsContent/Applicant/AppliCantModalContext';

import { useTabLine, useTabLinePosition } from 'components/TabMenu/hooks';
import { TabMenuDownloadButton, TabMenuNavigation } from 'components/TabMenu/TabMenuComponents';
import { ExVisible } from 'components/ui/ExVisible';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useDownloadFile } from 'utils/hooks/useDownloadFile';

import TabLine from './TabLine';
import TabMenuItem from './TabMenuItem';
import { TabMenuProps } from './TabMenuProps';

export const TabMenu: React.FC<TabMenuProps> = ({ navItems, className, vertical }) => {
  const tabMenuNavigationRef = useRef<HTMLElement>(null);
  const { activeItem, handlerSelectTab } = useTabLine({
    navItems,
    parentRef: tabMenuNavigationRef,
    vertical,
  });

  const { position, handlerRefs } = useTabLinePosition({
    navItems,
    parentRef: tabMenuNavigationRef,
    vertical,
  });

  const { downloadLink } = React.useContext(ApplicantContext);

  const downloadFile = useDownloadFile();
  const dispatch = useAppDispatch();

  const downloadHandler: React.MouseEventHandler = async (e) => {
    e.preventDefault();
    dispatch(loaderActions.start('downloadHandler'));

    if (downloadLink) {
      downloadFile({ path: downloadLink });
    }

    dispatch(loaderActions.stop('downloadHandler'));
  };

  return (
    <TabMenuNavigation
      className={className}
      onSelect={handlerSelectTab}
      $vertical={vertical}
      activeKey={activeItem?.path}
      ref={tabMenuNavigationRef}
    >
      <>
        {navItems.map((nav) => (
          <React.Fragment key={nav.path}>
            <TabMenuItem ref={handlerRefs} item={nav} $vertical={vertical} />
          </React.Fragment>
        ))}
        <ExVisible visible={Boolean(downloadLink)}>
          <TabMenuDownloadButton variant="light" onClick={downloadHandler}>
            Download File
          </TabMenuDownloadButton>
        </ExVisible>
      </>
      <TabLine
        left={position.left}
        width={position.width}
        top={position.top}
        bottom={position.bottom}
        height={position.height}
      />
    </TabMenuNavigation>
  );
};
