import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { Currency } from 'model';

import { adapterHelper } from 'utils/reducer/adapter-helper';

const publicCurrencyAdapter = createEntityAdapter<Currency>({});

const publicCurrencyInitialState = publicCurrencyAdapter.getInitialState();

const publicCurrencySlice = createSlice({
  name: 'publicCurrency',
  initialState: publicCurrencyInitialState,
  reducers: {
    ...adapterHelper(publicCurrencyAdapter),
  },
});

const publicCurrencyReducer = publicCurrencySlice.reducer;

export { publicCurrencySlice, publicCurrencyReducer, publicCurrencyInitialState, publicCurrencyAdapter };
