import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import isEqual from 'lodash/isEqual';

import { JobAd } from 'store/entities/job-ads';

export type NewJobAdContextForm = Partial<JobAd>;

type NewJobAdContextType = {
  form: NewJobAdContextForm | null;
  changeFormValue<K extends keyof NewJobAdContextForm>(key: K, value: NewJobAdContextForm[K]): void;
  changeForm(value: NewJobAdContextForm): void;
};
export const NewJobAdsContext = React.createContext<NewJobAdContextType>({
  changeForm: () => {
    // do nothing
  },
  changeFormValue: () => {
    // do nothing
  },
  form: null,
});

export const NewJobAdsFormContext: React.FC = ({ children }) => {
  const [state, setState] = useState<boolean>(false);

  const formRef = useRef<NewJobAdContextForm>({
    country: undefined,
    description: undefined,
    employmentType: undefined,
    jobAdLocation: {},
    jobBoardIds: [],
    jobBoards: {},
    jobId: undefined,
    requirements: [],
    responsibilities: [],
    startDate: undefined,
  });

  /**
   * Change single field of the form;
   */
  const changeFormValue = useCallback(
    function <K extends keyof NewJobAdContextForm>(key: K, value: NewJobAdContextForm[K]) {
      if (formRef.current[key] !== value) {
        formRef.current = { ...formRef.current, [key]: value };
        setState(!state);
      }
    },
    [state],
  );

  /**
   * Change whole form;
   */
  const changeForm = useCallback(
    function (value: NewJobAdContextForm) {
      /**
       * this hack with equality check is needed to re-render children to provide the ability
       * to save as draft directly after user chooses the job or the job boards, without clicks on the continue button.
       */
      const statesAreEqual = isEqual(value, formRef.current);
      if (!statesAreEqual) {
        formRef.current = value;
        setState(!state);
      }
    },
    [state],
  );

  return (
    <NewJobAdsContext.Provider value={{ changeForm, changeFormValue, form: formRef.current }}>
      {children}
    </NewJobAdsContext.Provider>
  );
};

export function withNewJobAdsContext(Component: React.ElementType) {
  return function (...props: any) {
    return (
      <NewJobAdsFormContext>
        <Component {...props} />
      </NewJobAdsFormContext>
    );
  };
}

export function useNewJobAdsContextFormUpdater() {
  const { form, changeForm } = useContext(NewJobAdsContext);

  const formKeys = Object.keys(form ?? {});

  const formData = useWatch({ name: formKeys });

  useEffect(() => {
    if (!isEqual(form, formData)) {
      changeForm(formData as NewJobAdContextForm);
    }
  }, [form, formData, changeForm]);
}
