import React from 'react';
import styled from 'styled-components/macro';

import {
  CardBody,
  CardContent,
  CardHeader,
  CardIndicator,
  CardWrapper,
  WorkExperienceAddress,
  WorkExperienceCompany,
  WorkExperienceDates,
  WorkExperienceDuration,
  WorkExperiencePosition,
} from 'pages/Candidates/Candidate/CandidateDetailsTab/components/CandidateWorkExperienceCardItem/CandidateWorkExperienceCardItemComponents';
import { CandidateWorkExperienceCardItemProps } from 'pages/Candidates/Candidate/CandidateDetailsTab/components/CandidateWorkExperienceCardItem/CandidateWorkExperienceCardItemProps';

import { Ellipsis } from 'components/Ellipsis';
import { getActualDate } from 'utils/funcs';
import { useWorkExperienceAddress, useWorkExperienceDates } from 'utils/hooks/candidateWorkExperience';
import { useDuration } from 'utils/hooks/useDuration';

import { candidateWorkExperienceSelectors } from 'store/entities/candidate-work-experience/candidate-work-experience.selectors';
import { useSelector } from 'store/rootSelectors';

const CardContentStyled = styled(CardContent)`
  flex-direction: row;
`;

const CardHeaderStyled = styled(CardHeader)`
  display: flex;
  flex-direction: column;
  width: 185px;
  padding-right: 20px;
  flex-shrink: 0;
`;

const CardBodyStyled = styled(CardBody)`
  margin-top: 0;
`;

export const CandidateWorkExperienceCardItemV4: React.FC<CandidateWorkExperienceCardItemProps> = ({
  workExperienceId,
  isEditable,
  ...rest
}) => {
  const workExperience = useSelector((state) => candidateWorkExperienceSelectors.selectById(state, workExperienceId));

  const startDate = getActualDate(workExperience?.startDate);

  const endDate = getActualDate(workExperience?.endDate);

  const duration = useDuration(startDate ?? null, endDate ?? null);
  const dates = useWorkExperienceDates(startDate ?? null, endDate ?? null, Boolean(workExperience?.currentlyEmployed));

  const address = useWorkExperienceAddress(workExperience);

  const position = workExperience?.position;
  const companyName = workExperience?.companyName;

  return (
    <CardWrapper {...rest}>
      <CardIndicator />
      <CardContentStyled>
        <CardHeaderStyled>
          <WorkExperienceDates>{dates}</WorkExperienceDates>
          <WorkExperienceDuration>({duration})</WorkExperienceDuration>
        </CardHeaderStyled>
        <CardBodyStyled>
          <Ellipsis title={position}>
            <WorkExperiencePosition>{position}</WorkExperiencePosition>
          </Ellipsis>
          <Ellipsis title={companyName ?? ''}>
            <WorkExperienceCompany>{companyName}</WorkExperienceCompany>
          </Ellipsis>
          <Ellipsis title={address}>
            <WorkExperienceAddress>{address}</WorkExperienceAddress>
          </Ellipsis>
        </CardBodyStyled>
      </CardContentStyled>
    </CardWrapper>
  );
};
