import React from 'react';
import styled from 'styled-components/macro';

import { CandidatesListEmpty } from 'pages/TalentPools/TalentPool/Candidates/components/CandidatesListEmpty/CandidatesListEmpty';

import { AppModalContentProps } from 'containers/Modals/AppModalProps';

import { ModalStepProps } from 'store/modals/config';

type AddCandidateToTalentPoolProps = AppModalContentProps & ModalStepProps;

const AddCandidateToTalentPoolStyled = styled.div``;

export const AddCandidateToTalentPool: React.FC<AddCandidateToTalentPoolProps> = (props) => {
  return (
    <AddCandidateToTalentPoolStyled>
      <CandidatesListEmpty className="p-1" onSelect={props.onClose} context="addToTalentPoolModal" modalId={props.id} />
    </AddCandidateToTalentPoolStyled>
  );
};
