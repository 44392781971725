import React, { useContext, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { ActionMeta, components, StylesConfig } from 'react-select';
import SelectAsync from 'react-select/async';
import styled, { ThemeContext } from 'styled-components';

import { apiConfig } from 'config/apiConfig';

import searchIcon from 'assets/img/search-999.svg';

import { useAppSelectStyles } from 'components/FormTagSelect/FormTagSelectStyles';
import { useReactSelectDebounceHandler } from 'utils/hooks/useReactSelectDebounce';

import { ListViewSearchAutocompleteProps, SearchAutocomplete } from './ListViewProps';

const ListViewSearch: React.FC<ListViewSearchAutocompleteProps> = ({
  filterName,
  filterValue,
  onChange,
  placeholder,
  className,
  loadOptions,
}) => {
  const appSharedStyles = useAppSelectStyles(['menuList']);
  const theme = useContext(ThemeContext);
  const componentStyles: StylesConfig<any, any> = {
    control: (base) => {
      return {
        ...base,
        borderColor: theme.border.borderColor,
        borderRadius: '20px',
        boxShadow: 'none',
        '&:hover': {},
      };
    },
    valueContainer: () => ({
      paddingLeft: '40px',
      width: '205px',
      fontSize: '14px',
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: '14px',
      color: theme.colors.dark,
    }),
    clearIndicator: (base) => ({
      ...base,
      position: 'absolute',
      right: '0px',
      top: '0px',
    }),
    option: (base) => ({
      ...base,
      fontSize: '14px',
      padding: '3px 20px',
    }),
    singleValue: (base) => ({
      ...base,
      maxWidth: 'calc(100% - 70px)',
    }),
    input: (base) => ({
      ...base,
      overflow: 'hidden',
    }),
    menu: (base) => ({
      ...base,
      borderRadius: theme.border.borderRadius,
      overflow: 'hidden',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 1040 }),
  };

  const styles = { ...appSharedStyles, ...componentStyles };

  const [value, setValue] = React.useState<SearchAutocomplete | undefined | null>(undefined);

  const prepareOptions = async (searchTerm: string) => {
    const options = await loadOptions(searchTerm);

    return options?.map(
      (option: string): SearchAutocomplete => ({
        name: option,
        id: option,
      }),
    );
  };

  const loadOptionsUnique = async (searchTerm: string) => {
    const options = await prepareOptions(searchTerm);
    return options.reduce(
      (acc: typeof options, opt: SearchAutocomplete) =>
        acc.find((accOpt: SearchAutocomplete) => accOpt.name === opt.name) ? acc : [...acc, opt],
      [],
    );
  };

  const debouncedLoadOptions = useReactSelectDebounceHandler({
    asyncLoader: loadOptionsUnique,
    delay: apiConfig.debounceTime,
  });

  const handleChange = (changedValue: any, { name, action }: ActionMeta<any>) => {
    if (name) {
      if (action === 'clear') {
        setValue(undefined);
        onChange(filterName, undefined);
      } else {
        setValue(changedValue);
        onChange(filterName, changedValue.name);
      }
    }
  };
  useEffect(() => {
    if (filterValue === undefined) {
      setValue(null);
    }
    if (filterValue && filterValue !== value?.name) {
      setValue({ name: filterValue, id: filterValue });
    }
    // eslint-disable-next-line
  }, [filterValue]);

  const noOptionsLocationHandler = ({ inputValue }: { inputValue: string }) => {
    if (inputValue.length === 0) {
      return 'Start typing';
    }
    return 'No Options';
  };

  return (
    <Form.Group className={className}>
      <SelectAsync
        styles={styles}
        name={`${filterName}Control`}
        value={value}
        isClearable
        loadOptions={debouncedLoadOptions}
        onChange={handleChange}
        getOptionLabel={(option: SearchAutocomplete) => option.name}
        getOptionValue={(option: SearchAutocomplete) => option.name}
        maxMenuHeight={150}
        placeholder={placeholder}
        noOptionsMessage={noOptionsLocationHandler}
        components={{
          Placeholder: (props: any) => <components.Placeholder {...props} />,
          IndicatorSeparator: null,
          DropdownIndicator: null,
          LoadingIndicator: null,
        }}
        menuPortalTarget={document.body}
      />
    </Form.Group>
  );
};

export default styled(ListViewSearch)`
  position: relative;
  margin: 0;
  z-index: 1000;

  &::after {
    position: absolute;
    content: '';
    width: 16px;
    height: 16px;
    background-image: url(${searchIcon});
    background-repeat: no-repeat;
    top: 12px;
    left: 12px;
    pointer-events: none;
  }
`;
