import styled from 'styled-components/macro';

import { useApplicantFromModal } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/hook/useApplicantFromModal';

import { ExRatingStars } from 'components/ui/ExRatingStars';
import { getActualDate } from 'utils/funcs';
import { useWorkExperienceDates } from 'utils/hooks/candidateWorkExperience';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { applicantsActions } from 'store/entities/applicants';

export const ApplicantRatingStars = styled(ExRatingStars)`
  margin-bottom: 15px;
`;

export const useApplicantRating = () => {
  const dispatch = useAppDispatch();

  const applicant = useApplicantFromModal();
  const applicantId = applicant?.applicantId || '';

  const applicantRating = applicant?.rating ?? 0;

  const ratingChangeClickHandler = (rating: number) => {
    dispatch(
      applicantsActions.applicantsModalUpdatePatch({
        id: applicantId,
        rating,
      }),
    );
  };

  return {
    applicantRating,
    ratingChangeClickHandler,
  };
};

export const useApplicantWorkExperience = () => {
  const applicant = useApplicantFromModal();

  const workExperience = applicant?.workExperience;
  const hasWorkExperience = Boolean(workExperience);
  const workExperiencePosition = workExperience?.position;
  const workExperienceCompanyName = workExperience?.companyName;

  const startDate = getActualDate(workExperience?.startDate);
  const endDate = getActualDate(workExperience?.endDate);
  const dates = useWorkExperienceDates(startDate, endDate, Boolean(workExperience?.currentlyEmployed));

  return { hasWorkExperience, workExperiencePosition, workExperienceCompanyName, dates };
};
