import React from 'react';

import { PageTitle } from 'components/PageHeader';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { BackLinkV2 } from 'modules/HistoryManager/components/BackLink';

import { StepFormHeaderStyledV2 } from './StepFormHeaderComponents';
import { StepFormHeaderProps } from './StepFormHeaderProps';

export const StepFormHeader: React.FC<StepFormHeaderProps> = (props) => {
  return (
    <StepFormHeaderStyledV2 className={props.className}>
      <div className="d-flex">
        <div>
          <div onClick={props.onClose}>
            <BackLinkV2 routeName={props.routeName} fallbackProps={props.fallbackProps} />
          </div>
          <PageTitle className="d-block">{props.title}</PageTitle>
        </div>
      </div>
      {props.children}
      <ExButton className="ml-3" variant="light" onClick={props.onClose}>
        {props.closeButtonText ?? 'Close'}
      </ExButton>
    </StepFormHeaderStyledV2>
  );
};
