import React from 'react';
import { Link } from 'react-router-dom';

import { EmptyState } from 'components/EmptyState';
import { IconEmptyStatePlace } from 'components/Icons/IconEmptyStatePlace';
import { TableEmptyRowLoaderStyle } from 'components/TableList/TableListComponents';
import { ExAddNewButton } from 'components/ui/ExAddNewButton/ExAddNewButton';

const defaultTitle = 'There are no applicants in your placement';
const defaultText = 'Add New Applicant';

type ApplicantListPlaceholderProps = {
  title?: string;
  text?: string;
  link?: string;
};

export const ApplicantListPlaceholder: React.FC<ApplicantListPlaceholderProps> = ({ title, text, link }) => {
  const placeholderTitle = title || defaultTitle;
  const placeholderText = text || defaultText;

  return (
    <TableEmptyRowLoaderStyle>
      <EmptyState title={placeholderTitle} icon={<IconEmptyStatePlace />}>
        {link && (
          <ExAddNewButton variant="primary" size="sm" forwardedAs={Link} to={link}>
            {placeholderText}
          </ExAddNewButton>
        )}
      </EmptyState>
    </TableEmptyRowLoaderStyle>
  );
};
