import React from 'react';

import { ListTableViewHeaderContextWrapper } from 'components/ListView/ListTableViewHeader';
import { Column } from 'components/ListView/ListViewProps';
import { TableList } from 'components/TableList';
import { innerTablesStyled } from 'components/TableList/TableListComponents';
import { pipe } from 'utils/funcs';

export const ExSortableTableContext = React.createContext<{ sortMode?: any; updateSort?: any }>({});

export const ExSortableTableList = innerTablesStyled(TableList);

export const getColumn =
  (i: number) =>
  ({ label, style, order }: Column) =>
    (
      <ListTableViewHeaderContextWrapper
        context={ExSortableTableContext}
        key={i}
        label={label}
        style={style}
        order={order}
      />
    );

export const renderHeader = <T, K extends keyof T>({ columns }: { columns: T }) => {
  const cols = Object.keys(columns) as Array<K>;
  return <>{cols.map((col, i) => pipe(columns[col], getColumn(i)))}</>;
};
