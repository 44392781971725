import React from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

import { SourceEntity } from 'model';

import { CheckableList } from 'components/Form/CheckableList';
import { StepFormBody } from 'components/StepForm';

import { Job } from 'store/entities/jobs';

export interface JobRequirementsFormBodyProps extends StepFormBody {
  className?: string;
  formData?: Partial<Job>;
}

export const JobRequirementsFormBodyStyled = styled.div``;

export const JobRequirementsFormBody: React.FC<JobRequirementsFormBodyProps> = ({ className, formData, isStep }) => {
  const {
    field: { ref, onChange },
  } = useController({ name: 'requirements' });

  return (
    <JobRequirementsFormBodyStyled className={className}>
      <CheckableList
        items={formData?.requirements ?? []}
        onChange={onChange}
        placeholder="Add new requirement"
        variant="primary"
        disabled={!isStep}
        sourceEntity={SourceEntity.Job}
        name="requirements"
        inputRef={ref}
        selectAllTitle="Requirements"
        required
      />
    </JobRequirementsFormBodyStyled>
  );
};
