import React from 'react';

import { CompanyIndustriesFormBody } from 'containers/CompanyForms/CompanyIndustriesFormBody';
import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import { ExModalBodyStyled, ExModalWrapperStyled } from 'containers/Modals/ModalsContent/Company/ExModalComponents';
import { useEntitiesStateWithToggle } from 'containers/Modals/ModalsContent/Company/hooks';

import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { DialogProps } from 'components/Dialog';
import { ExDialogActionButton, ExDialogActions } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { updateCompanyIndustries } from 'store/company/company.actions';
import { companySelectors } from 'store/company/company.selectors';
import { useIndustryQuerySelectors } from 'store/dictionary/hooks/useIndustryQuerySelectors';
import { exModalConfirmAction } from 'store/modals/modals.actions';

type CompanyUpdateIndustriesProps = {
  id: string;
};

export const CompanyUpdateIndustries = ({ id, onClose }: CompanyUpdateIndustriesProps & AppModalContentProps) => {
  const defaultValue = useAppSelector(companySelectors.getCompanyIndustries);
  const { selectors: industriesSelectors } = useIndustryQuerySelectors();
  const allEntities = useAppSelector(industriesSelectors.selectAll);

  const { entities, setEntities, toggleSelectAll, allSelected } = useEntitiesStateWithToggle(defaultValue, allEntities);

  const dispatch = useAppDispatch();

  const content: DialogProps['content'] = {
    title: 'Add Industries',
    withTitle: true,
  };

  const onSubmitClickHandler = () => {
    dispatch(exModalConfirmAction({ id, modalResult: entities }));
  };

  return (
    <ExModalWrapperStyled onClose={onClose} content={content}>
      <ExModalBodyStyled>
        <CompanyIndustriesFormBody
          onChange={setEntities}
          defaultValue={entities}
          toggleSelectAll={toggleSelectAll}
          allSelected={allSelected}
        />
        <ExDialogActions>
          <ExDialogActionButton variant="light" onClick={onClose}>
            Cancel
          </ExDialogActionButton>
          <ExLoaderHandlerWrapper action={updateCompanyIndustries}>
            <ExDialogActionButton onClick={onSubmitClickHandler}>Save</ExDialogActionButton>
          </ExLoaderHandlerWrapper>
        </ExDialogActions>
      </ExModalBodyStyled>
    </ExModalWrapperStyled>
  );
};
