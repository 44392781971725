import { useCallback } from 'react';
import { AnyAction } from 'redux';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';

export const useShowModal = () => {
  const dispatch = useAppDispatch();
  const clickHandler = useCallback(
    (action: AnyAction): React.MouseEventHandler =>
      () => {
        dispatch(action);
      },
    [dispatch],
  );

  return { clickHandler };
};
