import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { IconArrowRight } from 'components/Icons/IconArrowRight';
import { ExCard } from 'components/ui/ExCard';
import { ExCardBodyStyled } from 'components/ui/ExCard/ExCardComponents';
import { getTranslate } from 'utils/i18utils';
import { getThemeColor } from 'utils/styled/getThemeColor';

const LastChild = styled.div`
  &:last-child {
    margin-bottom: 0;
  }
`;

export const WidgetItemWrapper = styled(ExCard)`
  margin-bottom: 20px;

  ${ExCardBodyStyled} {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;

const WidgetItemHeaderArrowLinkStyled = styled(Link)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #25282b;
`;

export const WidgetItemHeaderArrowLink = ({ to, ...rest }: Parameters<typeof Link>[0]) => {
  return (
    <WidgetItemHeaderArrowLinkStyled to={to} {...{ rest }}>
      <IconArrowRight />
    </WidgetItemHeaderArrowLinkStyled>
  );
};

export const WidgetItem = styled(LastChild)`
  border-radius: ${({ theme }) => theme.border.borderRadiusSm};
  background-color: ${(props) => props.theme.colors.bodyBackgroundColor};
  padding: 15px 20px;
  margin-bottom: 10px;
  color: inherit;
  display: block;

  button& {
    border: 0;
    text-align: left;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: inherit;
  }
`;

export const WidgetItemNotEnoughData = ({ text }: { text?: string }) => {
  return <WidgetItem>{text || getTranslate('notEnoughData')}</WidgetItem>;
};

export const WidgetLoaderWrapper = styled(WidgetItem)`
  min-height: 51px;
`;

export const WidgetItemContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WidgetItemLeft = styled.div`
  margin-right: 15px;
`;

export const WidgetItemMiddle = styled(LastChild)`
  margin-bottom: 10px;
  flex-grow: 1;
`;

export const WidgetItemAvatar = styled(LastChild)`
  margin-bottom: 10px;
  width: 40px;
  height: 40px;
`;

export const WidgetItemSubTitle = styled.div`
  color: #999;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  white-space: nowrap;
`;

export const WidgetItemTitle = styled(LastChild)`
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  margin-bottom: 8px;
`;

export const WidgetItemTitleWrapper = styled.div`
  margin-bottom: 8px;
  display: inline-block;

  ${WidgetItemTitle} {
    margin-right: 10px;
    margin-bottom: 0;
  }
`;

export const WidgetItemSecondTitle = styled(LastChild)`
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  color: ${getThemeColor('dark')};
  margin-bottom: 8px;

  strong {
    font-weight: 700;
  }
`;

export const WidgetItemPreTitle = styled(WidgetItemSecondTitle)`
  color: #777;
  font-weight: 400;
`;

export const WidgetItemContent = styled(LastChild)`
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;
`;

export const WidgetItemFooter = styled.div`
  margin-top: 15px;
  text-align: center;
`;

export const WidgetItemFooterLink = styled(Link)`
  text-decoration: none;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const WidgetItemTagLine = styled.div`
  color: #999;
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
  white-space: nowrap;
`;
