import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';

import { dateFormat } from 'config';

import JobAdAdditionalRequirementsFormBuilder from 'containers/JobAdForms/FormBody/JobAdAdditionalRequirementsFormBuilder';
import { jobAdFormEffects } from 'containers/JobAdForms/state';
import { Loader } from 'containers/Loader';
import { ModalsTypeKey } from 'containers/Modals/AppModalProps';
import { modalsActions, modalsSelectors } from 'containers/Modals/store';

import { Form } from 'components';
import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { DialogProps } from 'components/Dialog';
import { ExButton } from 'components/ui/ExButton/ExButton';
import { ExDialogBody } from 'components/ui/ExDialog';
import { loaderThunkActionWrapper } from 'modules/LoaderManager/redux';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { JobAd, jobAdSelectors } from 'store/entities/job-ads';
import { jobBoardSelectors } from 'store/entities/job-boards';

import {
  DocumentPreviewContent,
  DocumentPreviewModalFooter,
  EditJobAdAdditionalFieldsModalStyled,
} from './EditJobAdAdditionalFieldsModalComponents';
import { EditJobAdAdditionalFieldsModalProps } from './EditJobAdAdditionalFieldsModalProps';

const FormStyled = styled(Form)`
  width: 100%;
`;

export const EditJobAdAdditionalFieldsModal: React.FC<EditJobAdAdditionalFieldsModalProps> = ({
  className,
  onClose,
}) => {
  const formRef = useRef<any>();
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();
  const jobAdId = useAppSelector(modalsSelectors.modalJobAdId);
  const item = useAppSelector(jobAdSelectors.getById, jobAdId);

  const content: DialogProps['content'] = {
    title: 'Edit Job board additional requirements',
    withTitle: true,
  };

  const jobBoard = useAppSelector(jobBoardSelectors.selectById, item?.jobBoardId);

  const formValue = useMemo(
    () => ({
      ...item,
      ...(item?.startDate && {
        startDate: format(parseISO(item?.startDate), dateFormat.formatDateForDateInput),
      }),
    }),
    [item],
  );

  const submitHandler = async (data: Partial<JobAd>) => {
    dispatch(modalsActions.saveResult({ id: ModalsTypeKey.editJobAdAdditionalFields, payload: data }));
  };

  useEffect(() => {
    if (!item || !jobAdId) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [item, jobAdId]);

  if (!show) {
    return <Loader />;
  }

  return (
    <EditJobAdAdditionalFieldsModalStyled className={className} onClose={onClose} content={content}>
      <DocumentPreviewContent>
        <ExDialogBody>
          <FormStyled
            onSubmit={submitHandler}
            defaultValues={formValue}
            onSaveAsDraft={submitHandler}
            saveAsDraftHandlerRef={formRef}
          >
            <JobAdAdditionalRequirementsFormBuilder jobBoard={jobBoard} />
            <ExButton type="submit" hidden />
          </FormStyled>
        </ExDialogBody>
        <DocumentPreviewModalFooter className="justify-content-center">
          <ExButton variant="light" onClick={() => onClose(false)}>
            Cancel
          </ExButton>
          <ExLoaderHandlerWrapper action={loaderThunkActionWrapper(jobAdFormEffects.updateJobAd)}>
            <ExButton className="ml-3" variant="primary" type="submit" onClick={() => formRef.current()}>
              Update
            </ExButton>
          </ExLoaderHandlerWrapper>
        </DocumentPreviewModalFooter>
      </DocumentPreviewContent>
    </EditJobAdAdditionalFieldsModalStyled>
  );
};
