import React from 'react';
import styled from 'styled-components';

import { ReactComponent as IconFilter } from 'assets/img/redesign/icons/filters.svg';

import { ExButton } from './ExButton';

type ExFilterButtonProps = {
  className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const useExFilterButtonState = ({ className, ...rest }: ExFilterButtonProps) => {
  return {
    className,
    ...rest,
  };
};

const IconFilterStyled = styled(IconFilter)``;

export const ExFilterButton: React.FC<ExFilterButtonProps> = props => {
  const { className, ...rest } = useExFilterButtonState(props);

  return (
    <ExButton {...rest} className={className} variant="light" prependIcon={IconFilterStyled}>
      Filters
    </ExButton>
  );
};
