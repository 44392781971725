import React from 'react';
import Nav from 'react-bootstrap/Nav';
import NavLink from 'react-bootstrap/NavLink';

import { ExBadge } from 'components/ui/ExBadge';
import { ExBadgeVariant } from 'components/ui/ExBadge/ExBadgeProps';
import { useRouteFromRoot } from 'utils/hooks/useRouteFromRoot';

import { ExNavLink, ExTabMenuItemV2Styled, LabelWrapper, StepNumber } from './TabMenuComponents';
import { TabMenuItemPropsV2 } from './TabMenuProps';

const useMakeUrlFromRoot = (path: string) => {
  const { makeUrlFromRoot } = useRouteFromRoot();
  return makeUrlFromRoot(path);
};

export const ExTabMenuItemV2 = React.forwardRef<NavLink, TabMenuItemPropsV2>((props, ref) => {
  const { className, active, item } = props;
  const to = useMakeUrlFromRoot(item.path);

  return (
    <ExTabMenuItemV2Styled className={className}>
      <Nav.Link ref={ref} eventKey={item.path} as={ExNavLink} to={to} disabled={item.disabled} $isActiveTab={active}>
        {item.stepNumber && <StepNumber>{item.stepNumber}</StepNumber>}
        <LabelWrapper>
          {item.label}
          {item?.renderSubTitle && React.createElement(item.renderSubTitle)}
        </LabelWrapper>
        {item.badgeNumber !== undefined && <ExBadge variant={ExBadgeVariant.tabActive}>{item.badgeNumber}</ExBadge>}
      </Nav.Link>
    </ExTabMenuItemV2Styled>
  );
});
