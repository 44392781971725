import styled, { css } from 'styled-components';

export type TabLineProps = {
  left?: number;
  width?: number;
  top?: number;
  bottom?: number;
  height?: number;
};

const TabLinePosition = ({ left, width, top, bottom, height }: TabLineProps) => {
  const leftValue = left ? `${left}px;` : '0;';
  const widthValue = width !== undefined ? `${width}px;` : '4px;';
  const heightValue = height !== undefined ? `${height}px;` : '4px;';
  const topValue = top !== undefined ? `${top}px;` : undefined;
  const bottomValue = bottom !== undefined ? `${bottom}px;` : undefined;

  return css`
    left: ${leftValue};
    width: ${widthValue};
    height: ${heightValue};
    top: ${topValue};
    bottom: ${bottomValue};
  `;
};

const TabLine = styled.div<TabLineProps>`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: all 0.3s;
  ${TabLinePosition};
`;

export default TabLine;
