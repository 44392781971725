import { createEntityAdapter, EntityId } from '@reduxjs/toolkit';

import { EntityStateWithPagination, providesList, transformListResponse } from 'api-client/axiosBaseQuery';
import { replaceParamsInUrl } from 'api-client/utils';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';

import { getTranslate } from 'utils/i18utils';
import { ListStateFilter } from 'utils/reducer/reducer-helper';

import { candidatesApi } from 'store/entities/candidates/candidates.api';
import { AvailabilityBaseFilter } from 'store/entities/candidates/models';
import { TalentPool } from 'store/entities/talent-pools';
import { RootState } from 'store/rootReducer';
import { talentPoolCandidateListUiSelectors } from 'store/ui/talent-pools/talent-pools-candidate-list/talent-pools-candidate-list.selectors';
//-----------------------------TYPES------------------------------------
export type TalentPoolCandidateListItem = {
  candidateId: string;
  talentPoolId: string;
  fullName: string;
  photoId: string;
  photoUrl: string;
  addedDate: string;
  resumeExtracted: boolean;
  doNotHire: boolean;
};
// START AddCandidateToTalentPoolFunc
// type AddCandidateToTalentPoolFuncRequest = any;
// type AddCandidateToTalentPoolFuncResponse = any;
// END AddCandidateToTalentPoolFunc

// START BulkAddCandidatesToTalentPoolFunc
type BulkAddCandidatesToTalentPoolFuncRequest = {
  candidates: EntityId[];
  talentPoolId: TalentPool['id'];
};
type BulkAddCandidatesToTalentPoolFuncResponse = void;
// END BulkAddCandidatesToTalentPoolFunc

// START DeleteCandidatesFromTalentPool
type DeleteCandidatesFromTalentPoolRequest = {
  talentPoolId: TalentPool['id'];
  candidateIds: EntityId[];
};
type DeleteCandidatesFromTalentPoolResponse = void;
// END DeleteCandidatesFromTalentPool

// START TalentPoolCandidatesAutocompleteFunc
// type TalentPoolCandidatesAutocompleteFuncRequest = any;
// type TalentPoolCandidatesAutocompleteFuncResponse = any;
// END TalentPoolCandidatesAutocompleteFunc

// START SearchTalentPoolCandidatesAsync
export type SearchTalentPoolCandidatesAsyncRequest = {
  talentPoolId: string;
  searchTerm?: string;
  locations?: Array<{
    countryCode: string;
    locationId: number;
  }>;
  countries?: string[];
  availabilities?: AvailabilityBaseFilter[];
  candidateIds?: string[];
  countryCodes?: string[];
  keywords?: string[];
  doNotHire?: boolean;
  pageSize?: number;
  pageNo?: number;
  orderBy?: string;
};
type SearchTalentPoolCandidatesAsyncResponse = EntityStateWithPagination<TalentPoolCandidateListItem> & {
  filter?: ListStateFilter;
};

// END SearchTalentPoolCandidatesAsync

//-----------------------------TYPES------------------------------------

const TalentPoolCandidatesEndpointsApi = {
  // POST
  AddCandidateToTalentPoolFunc: '/candidate/{candidateId}/talent-pool-assign',
  // POST
  BulkAddCandidatesToTalentPoolFunc: '/talent-pool/{talentPoolId}/assign',
  // DELETE
  DeleteCandidatesFromTalentPool: '/talent-pool/{talentPoolId}/delete-candidates',
  // GET
  TalentPoolCandidatesAutocompleteFunc: '/talent-pool/{talentPoolId}/autocomplete',
  // POST
  SearchTalentPoolCandidatesAsync: '/talent-pool/{talentPoolId}/candidates',
  // POST
  SetDoNotHireCandidatesFunc: '/candidate/do-not-hire/set',
  // POST
  RemoveDoNotHireCandidatesFunc: '/candidate/do-not-hire/remove',
} as const;

export const talentPoolCandidateAdapter = createEntityAdapter<TalentPoolCandidateListItem>({
  selectId: (candidate) => candidate.candidateId,
});

const talentPoolCandidatesApiInjected = candidatesApi.injectEndpoints({
  endpoints: (builder) => ({
    talentPoolCandidateListLoadMore: builder.query<
      SearchTalentPoolCandidatesAsyncResponse,
      SearchTalentPoolCandidatesAsyncRequest
    >({
      query: (args) => ({
        url: replaceParamsInUrl(TalentPoolCandidatesEndpointsApi.SearchTalentPoolCandidatesAsync, {
          talentPoolId: args.talentPoolId,
        }),
        method: 'POST',
        data: { ...args },
      }),
      transformResponse: transformListResponse(talentPoolCandidateAdapter),
      onQueryStarted: async (args, { queryFulfilled, dispatch, getCacheEntry }) => {
        try {
          await queryFulfilled;

          const { data } = getCacheEntry();
          if (data) {
            dispatch(
              talentPoolCandidatesApiInjected.util.updateQueryData(
                'talentPoolCandidateListLoadMore',
                {
                  ...args,
                  pageNo: 0,
                },
                (draft) => {
                  talentPoolCandidateAdapter.upsertMany(
                    draft,
                    talentPoolCandidateAdapter.getSelectors().selectAll(data),
                  );
                  draft.pageCount = data.pageCount;
                  draft.pageNo = data.pageNo;
                },
              ),
            );
          }
        } catch (error) {}
      },
    }),
    talentPoolCandidateList: builder.query<
      SearchTalentPoolCandidatesAsyncResponse,
      SearchTalentPoolCandidatesAsyncRequest
    >({
      query: (args) => ({
        url: replaceParamsInUrl(TalentPoolCandidatesEndpointsApi.SearchTalentPoolCandidatesAsync, {
          talentPoolId: args.talentPoolId,
        }),
        method: 'POST',
        data: args,
      }),
      transformResponse: transformListResponse(talentPoolCandidateAdapter),
    }),
    bulkAddCandidatesToTalentPoolFunc: builder.mutation<
      BulkAddCandidatesToTalentPoolFuncResponse,
      BulkAddCandidatesToTalentPoolFuncRequest
    >({
      query: (arg) => ({
        url: replaceParamsInUrl(TalentPoolCandidatesEndpointsApi.BulkAddCandidatesToTalentPoolFunc, {
          talentPoolId: arg.talentPoolId,
        }),
        method: 'POST',
        data: { ...arg },
      }),
    }),
    bulkRemoveCandidatesFromTalentPoolFunc: builder.mutation<
      DeleteCandidatesFromTalentPoolResponse,
      DeleteCandidatesFromTalentPoolRequest
    >({
      query: (args) => ({
        url: replaceParamsInUrl(TalentPoolCandidatesEndpointsApi.DeleteCandidatesFromTalentPool, {
          talentPoolId: args.talentPoolId,
        }),
        method: 'DELETE',
        data: { ...args },
      }),
    }),
    setDoNotHireCandidatesFunc: builder.mutation({
      query: (arg) => ({
        url: replaceParamsInUrl(TalentPoolCandidatesEndpointsApi.SetDoNotHireCandidatesFunc, {
          talentPoolId: arg.talentPoolId,
        }),
        method: 'POST',
        data: { ...arg },
      }),
    }),
  }),
});

export const talentPoolCandidatesApi = talentPoolCandidatesApiInjected.enhanceEndpoints({
  addTagTypes: ['TalentPoolCandidates', 'TalentPool', 'Candidates'],
  endpoints: {
    talentPoolCandidateList: {
      providesTags: (data) => providesList(data?.ids, 'TalentPoolCandidates'),
    },
    bulkAddCandidatesToTalentPoolFunc: {
      invalidatesTags: (_, error, arg) =>
        !error
          ? [
              { type: 'TalentPool' as const, id: arg.talentPoolId },
              ...arg.candidates.map((id) => ({ type: 'Candidates' as const, id })),
            ]
          : [],
      onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
        const candidatesCount = arg?.candidates.length;
        try {
          await queryFulfilled;
          const message =
            candidatesCount > 1
              ? getTranslate('candidates_added_to_talent_pool_success')
              : getTranslate('candidate_added_to_talent_pool_success');
          dispatch(alertsEffects.showSuccess({ message }));
        } catch (e) {}
      },
    },
    bulkRemoveCandidatesFromTalentPoolFunc: {
      invalidatesTags: (_, error, arg) =>
        !error
          ? [
              { type: 'TalentPool' as const, id: arg.talentPoolId },
              ...arg.candidateIds.map((id) => ({ type: 'Candidates' as const, id })),
              ...arg.candidateIds.map((id) => ({ type: 'TalentPoolCandidates' as const, id })),
            ]
          : [],

      onQueryStarted: async (args, { queryFulfilled, dispatch, getState }) => {
        const rootState = getState() as RootState;
        const filtersForApi = talentPoolCandidateListUiSelectors.filtersForApi(rootState);
        const patches = dispatch(
          talentPoolCandidatesApi.util.updateQueryData(
            'talentPoolCandidateList',
            { talentPoolId: args.talentPoolId, ...filtersForApi },
            (draft) => {
              talentPoolCandidateAdapter.removeMany(draft, args.candidateIds);
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patches.undo();
        }
      },
    },
    setDoNotHireCandidatesFunc: {
      invalidatesTags: (_, error, arg) =>
        !error
          ? [
              ...arg.candidateIds.map((id) => ({ type: 'Candidates' as const, id })),
              ...arg.candidateIds.map((id) => ({ type: 'TalentPoolCandidates' as const, id })),
            ]
          : [],
      onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          const message = getTranslate('candidate.update.doNotHireSuccess');
          dispatch(alertsEffects.showSuccess({ message }));
        } catch (e) {}
      },
    },
  },
});
