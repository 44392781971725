import React, { useLayoutEffect } from 'react';
import styled from 'styled-components/macro';

import { ApplicantList } from 'containers/ApplicantLists';
import { applicantListActions } from 'containers/ApplicantLists/store';
import { ApplicantTabProps } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/Tabs/ApplicantTabProps';

import { useAppDispatch } from 'utils/hooks/useAppDispatch';

import { ApplicantBelongsTo } from 'store/entities/applicants';

import { columns } from './listColumns';

const ApplicantListStyled = styled(ApplicantList)`
  &&& {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const useApplicationsTabState = ({ className, item }: ApplicantTabProps) => {
  const dispatch = useAppDispatch();

  const candidateId = item?.candidateId;

  useLayoutEffect(() => {
    dispatch(
      applicantListActions.updateFilters({
        id: ApplicantBelongsTo.candidate,
        filters: {
          belongsTo: [ApplicantBelongsTo.candidate, candidateId],
        },
      }),
    );
  }, [candidateId]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    className,
  } as const;
};

export const ApplicationsTab: React.FC<ApplicantTabProps> = (props) => {
  const { className } = useApplicationsTabState(props);

  return <ApplicantListStyled id={ApplicantBelongsTo.candidate} columns={columns} className={className} />;
};
