import React, { useMemo } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Routes } from 'router';

import { AppRouterParams } from 'model/router';

import { ApplicantsTabMenuSection } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/ApplicantModalV3Components';
import {
  ApplicantCoverLetterTab,
  ApplicantResumeTab,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV3/Tabs';
import { ApplicantModalAlert } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalAlert';
import {
  ApplicantModalAlertsWrapper,
  ApplicantModalBodyStyled,
  TabContentWrapperStyled,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/ApplicantModalV4Components';
import { applicantModalSliceSelectors } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/store';
import { ApplicationsTab } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/Tabs';
import { ApplicantOverviewTabView } from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/Tabs/ApplicantOverviewTabView';

import { ApplicantSideModalCommentTab } from 'components/ApplicantSideModal/components/ApplicantSideModalCommentTab';
import { ExTabMenuV2, NavItem } from 'components/TabMenu';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { applicantSelectors } from 'store/entities/applicants';

type ApplicantModalBodyViewProps = {
  className?: string;
};

const useApplicantModalBodyViewState = ({ className }: ApplicantModalBodyViewProps) => {
  const match = useRouteMatch<AppRouterParams>();

  const { applicantId } = match.params;
  const applicant = useAppSelector(applicantSelectors.selectApplicantModalData, applicantId);
  const previousApplicationsNumber = applicant?.previousApplicationsNumber;
  const numberOfComments = useAppSelector(applicantModalSliceSelectors.selectApplicantModalNumberOfComments);

  const navTabs: NavItem[] = useMemo(
    () => [
      {
        component: ApplicantOverviewTabView,
        label: 'Overview',
        path: Routes.applicantOverview,
      },
      {
        component: ApplicantResumeTab,
        label: 'Resume',
        path: Routes.applicantResume,
      },
      {
        component: ApplicantCoverLetterTab,
        label: 'Cover Letter',
        path: Routes.applicantCoverLetter,
      },
      ...(!!previousApplicationsNumber
        ? [
            {
              component: ApplicationsTab,
              label: 'Other Applications',
              path: Routes.applicantApplications,
              badgeNumber: previousApplicationsNumber + 1,
            },
          ]
        : []),
      {
        component: ApplicantSideModalCommentTab,
        label: 'Comments',
        path: Routes.applicantComments,
        badgeNumber: numberOfComments,
      },
    ],
    [previousApplicationsNumber, numberOfComments],
  );

  return {
    className,
    match,
    applicant,
    navTabs,
  } as const;
};

export const ApplicantModalBodyView: React.FC<ApplicantModalBodyViewProps> = (props) => {
  const { className, match, applicant, navTabs } = useApplicantModalBodyViewState(props);

  return (
    <ApplicantModalBodyStyled className={className}>
      <ApplicantsTabMenuSection>
        <ExTabMenuV2 navItems={navTabs} />
      </ApplicantsTabMenuSection>
      <ApplicantModalAlertsWrapper>
        <ApplicantModalAlert />
      </ApplicantModalAlertsWrapper>
      <TabContentWrapperStyled>
        <Switch>
          {navTabs.map(({ component: Component, ...route }) => (
            <Route
              key={route.path}
              path={`${match.path}/${route.path}`}
              render={() => <Component className="applicant-tab" item={applicant} />}
            />
          ))}
          <Redirect from="*" to={`${match.url}/${Routes.applicantOverview}`} />
        </Switch>
      </TabContentWrapperStyled>
    </ApplicantModalBodyStyled>
  );
};
