import React, { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { push } from 'connected-react-router';
import { ExRoutes } from 'router/routes';
import * as yup from 'yup';
import styled from 'styled-components/macro';
import uniqBy from 'lodash/uniqBy';

import { talentPoolCandidatesApi } from 'api-endpoints/talent-pool-candidates/talent-pool-candidates.api';

import { rbacConstants } from 'containers/Auth/AuthMatrix';
import { RBAC } from 'containers/Auth/components/RBAC';
import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import {
  Body,
  CandidateAvatarStyled,
  CandidatesWrapper,
  ExSizedBoxBottomStyled,
  ExSizedBoxTopStyled,
  Message,
} from 'containers/Modals/ModalsContent/TalentPool/TalentPool.styles';
import { modalsSelectors } from 'containers/Modals/store';

import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { Form } from 'components/Form';
import { FormCard } from 'components/FormCard';
import { FormSelect } from 'components/FormSelect';
import { Spacer } from 'components/Spacer';
import { ExButton } from 'components/ui/ExButton';
import { ExDialogActionButton, ExDialogActions } from 'components/ui/ExDialog';
import { ExTooltip } from 'components/ui/ExTooltip';
import { ExVisible } from 'components/ui/ExVisible';
import { pickAndRename } from 'utils/funcs';
import { getUserInitials } from 'utils/getUserInitials';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useNoScrollBodyWhen } from 'utils/hooks/useNoScrollBody';
import { useAppSelector } from 'utils/hooks/useSelectors';
import { pluck } from 'utils/pluck';
import { pluralize } from 'utils/pluralize';
import { getThemeColor } from 'utils/styled/getThemeColor';

import { talentPoolsApi } from 'store/entities/talent-pools/talent-pools.api';
import { ModalStepProps } from 'store/modals/config';
import { exModalHideAction, updateWizardPage } from 'store/modals/modals.actions';
import { modalResultById } from 'store/modals/modals.selectors';
import { candidatesListUISelectors } from 'store/ui/candidates/candidates-list/candidates-list.selectors';

type BulkAddCandidateToTalentPoolProps = AppModalContentProps & ModalStepProps;

const AddToTalentPoolStyled = styled.div`
  margin: 0 auto;
  width: 550px;
  max-height: 95vh;

  ${Message},
  ${CandidatesWrapper} {
    background-color: ${getThemeColor('bodyBackgroundColor')};
  }
`;

const schema = yup.object().shape({
  selectedTalentPool: yup.string().required().label('Select Talent Pool').nullable(),
});

export const BulkAddCandidateToTalentPool: React.FC<BulkAddCandidateToTalentPoolProps> = ({ id }) => {
  const dispatch = useAppDispatch();

  const selectEntities = useAppSelector(candidatesListUISelectors.selectAll);

  const doNotHireEntities = selectEntities.filter((entity) => entity.status === 'DoNotHire');
  const doNotHireEntitiesCount = doNotHireEntities.length;
  const hasDoNotHireEntities = Boolean(doNotHireEntitiesCount);

  const modalTalentPoolId = useAppSelector(modalsSelectors.modalTalentPoolId);

  const modalResultByModalId = useAppSelector(modalResultById, id);

  const talentPoolId = modalResultByModalId?.talentPoolId ?? modalTalentPoolId;

  const { data: talentPool, isLoading: isLoadingTalentPool } = talentPoolsApi.endpoints.talentPool.useQuery(
    talentPoolId ? { talentPoolId } : skipToken,
  );

  const { data: talentPools } = talentPoolsApi.endpoints.talentPoolList.useQuery(
    {
      pageNo: 0,
      pageSize: 1000,
    },
    { refetchOnMountOrArgChange: true },
  );

  const talentPoolOptions = useMemo(() => {
    const options =
      talentPools?.items.map(
        pickAndRename([
          ['id', 'id'],
          ['name', 'name'],
        ]),
      ) ?? [];

    if (talentPool) {
      options.push({ name: talentPool.name, id: talentPool.id });
    }

    return uniqBy(options, 'id');
  }, [talentPool, talentPools?.items]);

  const defaultValues = {
    selectedTalentPool: talentPoolId,
  };

  const [addCandidates, { isLoading }] = talentPoolCandidatesApi.useBulkAddCandidatesToTalentPoolFuncMutation();

  const onSubmitClickHandler = async (data) => {
    if (!data.selectedTalentPool) {
      return;
    }
    const candidateMutationResult = await addCandidates({
      talentPoolId: data.selectedTalentPool,
      candidates: selectEntities.map(pluck('candidateId')),
    });

    if ('error' in candidateMutationResult) {
      return;
    }

    dispatch(exModalHideAction({ id }));
    dispatch(push(ExRoutes.talentPoolPage({ talentPoolId: data.selectedTalentPool })));
  };

  const onNewTalentPoolClickHandler = () => {
    dispatch(updateWizardPage({ id, page: 'createNewTalentPoolForBulkAdd' }));
  };

  const onCloseClickHandler = () => {
    dispatch(exModalHideAction({ id }));
  };

  useNoScrollBodyWhen(true);

  return (
    <AddToTalentPoolStyled>
      <Body>
        <Form onSubmit={onSubmitClickHandler} validationSchema={schema} defaultValues={defaultValues}>
          <div>
            <div className="d-flex p-1">
              <FormCard.InputLabel>
                Select Talent Pool <FormCard.InputLabelRequired>*</FormCard.InputLabelRequired>
              </FormCard.InputLabel>
              <Spacer />
              <RBAC feature={rbacConstants.talentPools.create}>
                <ExButton variant="link" onClick={onNewTalentPoolClickHandler}>
                  New Talent Pool
                </ExButton>
              </RBAC>
            </div>
            <FormSelect
              isLoading={isLoadingTalentPool || isLoading}
              name="selectedTalentPool"
              className="mb-0"
              required
              options={talentPoolOptions}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.id}
            />
          </div>

          <Message>You are about to put these candidates into your selected Talent Pool.</Message>
          <CandidatesWrapper>
            <ExSizedBoxTopStyled height={10} />
            {selectEntities.map((item) => (
              <ExTooltip key={item.candidateId} text={item.fullName} tooltipPosition="top" variant="white">
                <div>
                  <CandidateAvatarStyled src={item.photoUrl} initials={getUserInitials(item.fullName)} />
                </div>
              </ExTooltip>
            ))}
            <ExSizedBoxBottomStyled height={10} />
          </CandidatesWrapper>
          <ExVisible visible={hasDoNotHireEntities}>
            <div className="text-center">
              There are {doNotHireEntitiesCount} {pluralize('candidates', doNotHireEntitiesCount)} marked as “Do not
              Hire”.
            </div>
          </ExVisible>
          <ExDialogActions>
            <ExDialogActionButton variant="light" onClick={onCloseClickHandler} disabled={isLoading}>
              Cancel
            </ExDialogActionButton>
            <ExLoaderHandlerWrapper isLoading={isLoading}>
              <ExDialogActionButton type="submit" className="ml-3" disabled={isLoading}>
                Confirm
              </ExDialogActionButton>
            </ExLoaderHandlerWrapper>
          </ExDialogActions>
        </Form>
      </Body>
    </AddToTalentPoolStyled>
  );
};
