import * as yup from 'yup';
import { MixedSchema } from 'yup/lib/mixed';
import { TransformFunction } from 'yup/lib/types';

import { Ability } from 'model';

import {
  emptyStringOrZeroToUndefined,
  emptyStringToUndefined,
  linkValidator,
  maxLengthForCurrencyAmount,
  stringRequired,
  validateStringLengthWithoutHtmlTags,
} from 'utils';

export const emptyValuesToNull: TransformFunction<MixedSchema> = (v, o) => {
  return !o && o !== null ? null : v;
};

export const emptyValuesToUndefined: TransformFunction<MixedSchema> = (v, o) => {
  return !o ? undefined : v;
};

const jobNameLabel = 'Job title';
const jobNameMaxLength = 75;

const validationSchemaJobTitle = yup
  .string()
  .label(jobNameLabel)
  .trim()
  .required('Please add a title to help identify this job.')
  .max(jobNameMaxLength, `${jobNameLabel} must be at most ${jobNameMaxLength} characters.`);

const validationSchemaJobPosition: yup.AnyObjectSchema = yup.object({
  category: yup.string().nullable(),
  name: yup.string().nullable(),
  categoryId: yup.string().label('Position category').transform(emptyValuesToUndefined),
  positionId: yup
    .number()
    .label('Position type')
    .transform(emptyValuesToUndefined)
    .typeError(({ label }) => `${label} is a required field`),
});

const minimalJobDescriptionLength = 150;
const maximalJobDescriptionLength = 15_000;
const jobDescriptionValidationMessage = `Please add a description that contains at least ${minimalJobDescriptionLength} characters.`;

export const descriptionValidationSchema = yup
  .string()
  // eslint-disable-next-line no-template-curly-in-string
  .test('valid-link', '${path} have invalid link(s)', linkValidator)
  .test(
    'stripped-html',
    // eslint-disable-next-line no-template-curly-in-string
    jobDescriptionValidationMessage,
    validateStringLengthWithoutHtmlTags(minimalJobDescriptionLength),
  )
  .max(maximalJobDescriptionLength)
  .label('Job description')
  .nullable()
  .required(jobDescriptionValidationMessage);

const maximum = yup.number().label('To').min(1).test(maxLengthForCurrencyAmount).transform(emptyValuesToUndefined);

const validationSchemaMoneyRange: yup.AnyObjectSchema = yup.object({
  minimum: yup
    .number()
    .label('From')
    .min(1)
    .transform(emptyValuesToUndefined)
    .test(maxLengthForCurrencyAmount)
    .required(),
  maximum: yup
    .number()
    .required()
    .when('minimum', (minimum: number) => {
      if (minimum > 0) {
        return maximum
          .required()
          .default(minimum)
          .min(yup.ref('minimum'), ({ label }) => `${label} must be greater than or equal From.`);
      }
      if (minimum === 0) {
        return maximum.required().min(1);
      }
      return maximum.required().min(1);
    }),
});

export const validationSchemaSalary: yup.AnyObjectSchema = yup.object({
  currency: yup
    .string()
    .label('Currency')
    .transform(emptyStringOrZeroToUndefined)
    .required('Please specify the salary currency.'),
  frequency: yup
    .number()
    .label('Frequency')
    .transform(emptyStringOrZeroToUndefined)
    .required('Please specify the salary frequency for this job.'),
  moneyRange: validationSchemaMoneyRange.notRequired().default(undefined),
});

export const validationSchemaFormSalary = yup.object({
  salary: validationSchemaSalary.notRequired().default(undefined),
});

export const validationSchemaJobV2: yup.AnyObjectSchema = yup
  .object({
    id: yup.string().notRequired(),
    jobId: yup.string().notRequired(),
    jobName: validationSchemaJobTitle,
    position: validationSchemaJobPosition.label('Position').typeError('Position is required'),
    employmentType: yup
      .number()
      .label('Employment type')
      .transform(emptyValuesToUndefined)
      .required('Please select an employment type.'),
    vacancies: yup
      .number()
      .typeError('Only ${type} are accepted') // eslint-disable-line no-template-curly-in-string
      .integer()
      .label('Number of vacancies')
      .default(1)
      .min(1)
      .max(999)
      .transform((v, o) => (o === '' ? 1 : v)),
    countryHiringFrom: yup
      .string()
      .required('Please select the country this job is located in.')
      .label('Country')
      .nullable(),
    jobLocation: yup.object().label('Location').nullable().required('Please select a location.'),
  })
  .concat(
    yup.object({
      description: descriptionValidationSchema,
      responsibilities: yup.array<Ability>().label('Job responsibilities'),
      requirements: yup.array<Ability>().label('Job requirements'),
    }),
  )
  .concat(
    yup.object({
      industryId: yup
        .string()
        .required('Please specify the industry this job belongs in.')
        .label('Industry')
        .transform(emptyStringOrZeroToUndefined),
      experience: yup.string().label('Experience').transform(emptyValuesToNull).nullable(),
      education: yup.string().label('Education').transform(emptyValuesToNull).nullable(),
    }),
  )
  .concat(validationSchemaFormSalary)
  .concat(
    yup.object({
      hideSalary: yup.boolean().label('Hide salary').default(false),
    }),
  )
  .concat(
    yup.object({
      pipelineId: stringRequired.label('Hiring pipeline').transform(emptyStringToUndefined),
      interviewTemplates: yup.mixed().label('Interview template'),
      hiringManagers: yup.mixed().label('Hiring managers'),
    }),
  );

export const validationSchemaJobDuplicate = yup.object({
  jobName: validationSchemaJobTitle,
});

export const jobCreateCastSchema: yup.AnyObjectSchema = yup
  .object({
    position: yup
      .object({
        category: yup.string(),
        name: yup.string(),
        categoryId: yup.string().label('Position category').nullable(),
        positionId: yup.number().label('Position type').nullable(),
      })
      .label('Position')
      .nullable(),
    employmentType: yup.number().label('Employment type').nullable(),
    vacancies: yup.number().integer().label('Number of vacancies'),
  })
  .concat(
    yup.object({
      salary: yup.object({
        moneyRange: yup
          .object({
            minimum: yup.number().label('From').transform(emptyValuesToNull).nullable(),
            maximum: yup.number().label('To').transform(emptyValuesToNull).nullable(),
          })
          .nullable(),
      }),
    }),
  );
