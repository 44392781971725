import React from 'react';

import { CompanyCurrenciesFormBody } from 'containers/CompanyForms/CompanyCurrenciesFormBody';
import { AppModalContentProps } from 'containers/Modals/AppModalProps';
import { ExModalBodyStyled, ExModalWrapperStyled } from 'containers/Modals/ModalsContent/Company/ExModalComponents';
import { useEntitiesStateWithToggle } from 'containers/Modals/ModalsContent/Company/hooks';

import { ExLoaderHandlerWrapper } from 'components/Common/LoaderHandlerWrapper';
import { ExButton } from 'components/ui/ExButton';
import { ExDialogActions } from 'components/ui/ExDialog';
import { useAppDispatch } from 'utils/hooks/useAppDispatch';
import { useAppSelector } from 'utils/hooks/useSelectors';

import { updateCompanyCurrencies } from 'store/company/company.actions';
import { CompanyInfoCurrency } from 'store/company/company.models';
import { companySelectors } from 'store/company/company.selectors';
import { useCurrencyQuerySelectors } from 'store/dictionary/hooks/useCurrencyQuerySelectors';
import { exModalConfirmAction } from 'store/modals/modals.actions';
import { ExModal } from 'store/modals/modals.interfaces';

type CompanyUpdateCurrenciesProps = {
  id: string;
} & ExModal &
  AppModalContentProps;

export const CompanyUpdateCurrencies = ({
  id,
  onClose,
  modalConfig,
}: CompanyUpdateCurrenciesProps & AppModalContentProps) => {
  const defaultValue = useAppSelector(companySelectors.getCompanyCurrencies);
  const { selectors: currenciesSelectors } = useCurrencyQuerySelectors();
  const allEntities = useAppSelector(currenciesSelectors.selectAll).map(CompanyInfoCurrency);

  const { entities, setEntities, allSelected, setAllSelected } = useEntitiesStateWithToggle(defaultValue, allEntities);

  const dispatch = useAppDispatch();

  const toggleSelectAll = () => {
    const updatedValue = !allSelected;
    if (updatedValue) {
      /**
       * In the code below we filter already selected countries and then merge them.
       * This functionality needs to prevent reset of previously selected default country
       */
      const countriesForUpdate = allEntities.filter((currency) => {
        const isSelected = entities.map((entitiesCurrency) => entitiesCurrency.currency).includes(currency.currency);

        return !isSelected;
      });

      setEntities([...entities, ...countriesForUpdate]);
    } else {
      setEntities([]);
    }

    setAllSelected(updatedValue);
  };

  const onSubmitClickHandler = () => {
    dispatch(exModalConfirmAction({ id, modalResult: { entities: entities || [] } }));
  };
  return (
    <ExModalWrapperStyled onClose={onClose} content={modalConfig?.content ?? {}}>
      <ExModalBodyStyled>
        <CompanyCurrenciesFormBody
          onChange={setEntities}
          defaultValue={entities}
          toggleSelectAll={toggleSelectAll}
          allSelected={allSelected}
        />
        <ExDialogActions>
          <ExButton variant="light" onClick={onClose}>
            Cancel
          </ExButton>
          <ExLoaderHandlerWrapper action={updateCompanyCurrencies}>
            <ExButton className="ml-3" onClick={onSubmitClickHandler}>
              Save
            </ExButton>
          </ExLoaderHandlerWrapper>
        </ExDialogActions>
      </ExModalBodyStyled>
    </ExModalWrapperStyled>
  );
};
