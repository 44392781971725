import React from 'react';

import { Ability, SourceEntity } from 'model';

import { AbilityModalBody } from 'components/AbilityModalBody';
import { DialogProps } from 'components/Dialog';
import { Spinner } from 'components/Spinner';

type JobAbilitiesModalProps = {
  className?: string;
  title: string;
  onSubmitValidation: (value: Ability[]) => Promise<void>;
  items?: Ability[] | null;
} & DialogProps;

export const JobAbilitiesModal = ({ onClose, className, title, items, onSubmitValidation }: JobAbilitiesModalProps) => {
  const content: DialogProps['content'] = {
    title,
    withTitle: true,
  };
  const submitHandler = async (submitItems: Ability[]) => {
    await onSubmitValidation(submitItems);
  };

  if (!items) {
    return <Spinner />;
  }

  return (
    <AbilityModalBody
      content={content}
      className={className}
      items={items ?? []}
      submitHandler={submitHandler}
      onClose={onClose}
      sourceEntity={SourceEntity.Job}
    />
  );
};
