import styled from 'styled-components/macro';

import {
  InterviewTableCellComponent,
  InterviewTableCellComponentProps,
} from 'containers/Modals/ModalsContent/Applicant/ApplicantViewV4/Tabs/InterviewsTab/InterviewsTabProps';

import { EllipsisWithTooltip } from 'components/EllipsisWithTooltip';

const InterviewTablePublishedByStyled = styled.div``;

const useInterviewTablePublishedByState = ({ item }: InterviewTableCellComponentProps) => {
  return {
    publishedBy: item?.publishedByName || '--',
  } as const;
};

export const InterviewTablePublishedBy: InterviewTableCellComponent = (props) => {
  const { publishedBy } = useInterviewTablePublishedByState(props);

  return (
    <InterviewTablePublishedByStyled>
      <EllipsisWithTooltip label={publishedBy}>
        <div>{publishedBy}</div>
      </EllipsisWithTooltip>
    </InterviewTablePublishedByStyled>
  );
};
