import { JobBoard } from 'store/entities/job-boards';

export const getJobBoardAdditionalFieldsFuncTransformer = (data?: Pick<JobBoard, 'additionalFields'>) => {
  if (!data?.additionalFields) {
    return data;
  }

  const transformedAdditionalFields = data.additionalFields.map((additionalField) => {
    return {
      ...additionalField,
      options: additionalField.values || additionalField.options,
    };
  });

  return {
    additionalFields: transformedAdditionalFields,
  };
};
