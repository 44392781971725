import styled, { AnyStyledComponent, css } from 'styled-components';

import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';

export const routerLinkCurrentColor = css`
  a {
    color: currentColor;
    &:hover {
      color: currentColor !important;
      text-decoration: none;
    }
  }
`;

export const HoveredBoxShadowCard = (component: AnyStyledComponent | React.FC<any>) => styled(component)`
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
  border-radius: ${getThemeBorderProps('borderRadius')};

  &:hover {
    box-shadow: 0 9px 50px rgba(0, 0, 0, 0.1);
    border-color: ${(props) => props.theme.colors.light};
  }
`;

/**
 * This tweak is needed because in safari nested elements with `display: flex`
 * is shrinked automatically.
 */
export const safariFlexBoxTweak = css`
  flex: 0 0 auto;
`;
