import { AppImageEditorTypes } from 'components/AppImageEditor/AppImageEditorProps';

export interface AppImagePickerDialogProps {
  className?: string;
  image?: File | string;
  file?: File;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
  onSave: (file: File | null) => void;
  onError: (message: string) => void;
  square?: boolean;
  bgcolor?: string | null;
  type?: AppImageEditorTypes;
  loaderAction?: string;
}

export interface DropZonePlaceholderProps {
  isDragAccept?: boolean;
  isDragReject?: boolean;
  isDragActive?: boolean;
  isImageSelected?: boolean;
}

export enum DropZoneErrors {
  FILE_INVALID_TYPE = 'file-invalid-type',
  FILE_TOO_LARGE = 'file-too-large',
  FILE_TOO_SMALL = 'file-too-small',
  TOO_MANY_FILES = 'too-many-files',
}
