import { companyPositionCategoriesAdapter, useCompanyPositionCategoriesQuery } from 'store/dictionary/dictionary.api';

type PositionCategoriesQueryOptionsProps = {};

export const useCompanyPositionCategoriesQuerySelectors = (_: any, options?: PositionCategoriesQueryOptionsProps) => {
  const query = useCompanyPositionCategoriesQuery(undefined, {
    refetchOnMountOrArgChange: false,
    ...options,
  });

  const adapterSelectors = companyPositionCategoriesAdapter.getSelectors(
    () => query.data ?? companyPositionCategoriesAdapter.getInitialState(),
  );

  const selectors = {
    ...adapterSelectors,
  };

  return {
    ...query,
    selectors,
  };
};
