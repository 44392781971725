import { Routes } from 'router/names';

import { JobBoardSelectStep } from 'containers/JobBoardsForm/Steps/JobBoardSelectStep';
import { JobBoardSettingsStep } from 'containers/JobBoardsForm/Steps/JobBoardSettingsStep';

import { Step } from 'components/StepForm';

import { SelectedJobBoard } from './JobBoardsSubscribeComponents';

export const initFormSteps: Step[] = [
  {
    stepNumber: 1,
    path: Routes.jobBoardsFormSelectBoard,
    label: 'Select Job Board',
    component: JobBoardSelectStep,
    disabled: true,
    renderSubTitle: SelectedJobBoard,
  },
  {
    stepNumber: 2,
    path: Routes.jobBoardsFormSettings,
    label: 'Job Board Settings',
    component: JobBoardSettingsStep,
    disabled: true,
    navButtonsProps: {
      nextButtonText: 'Subscribe',
    },
  },
];
