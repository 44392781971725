import React, { ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useCustomCompareCallback } from 'use-custom-compare';
import styled from 'styled-components/macro';
import isEqual from 'lodash/isEqual';

import { IsEntity } from 'model/utils';

import {
  ExCardBodyStyled,
  ExCardFooterStyled,
  ExCardHeaderActionsStyled,
  ExCardHeaderStyled,
  ExCardHeaderTitleStyled,
  ExCardStyled,
  HoverableExCardStyled,
} from 'components/ui/ExCard/ExCardComponents';
import { ExCardProps, HoverableExCardProps } from 'components/ui/ExCard/ExCardProps';
import { isFunction } from 'utils/funcs';
import { useDateDefaultFormatFunction } from 'utils/hooks/dateTime';
import { useModalPath } from 'utils/hooks/useModalPath';
import { useMouseHover } from 'utils/hooks/useMouseHover';
import { getThemeBorderProps } from 'utils/styled/getThemeBorderProps';

const WrapperWithLink = <T extends object>({
  children,
  modalPath,
  preventNavigation,
}: Pick<HoverableExCardProps<T>, 'preventNavigation' | 'children'> & { modalPath: string }) =>
  preventNavigation ? (
    <ExCard>{children}</ExCard>
  ) : (
    <Link to={modalPath} style={{ color: 'inherit', textDecoration: 'inherit' }}>
      <ExCard>{children}</ExCard>
    </Link>
  );

export const ExCard: React.FC<ExCardProps> = ({
  className,
  header,
  footer,
  children,
  renderRows,
  title,
  headerActions,
  bodyMaxHeight,
  ...rest
}) => {
  const renderWithWrapper = useCustomCompareCallback(
    (content, Wrapper, key = undefined) => {
      let innerContent: ReactNode;
      if (!content) {
        return;
      }
      if (typeof content === 'function') {
        innerContent = content(rest);
      } else {
        innerContent = content;
      }
      return <Wrapper key={key}>{innerContent}</Wrapper>;
    },
    [rest],
    (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps),
  );

  const renderedHeader = useMemo(() => {
    const headerContent: React.ReactNode[] = [];
    if (title) {
      const renderedTitle = renderWithWrapper(title, ExCardHeaderTitleStyled, 'title');
      headerContent.push(renderedTitle);
    }
    if (header) {
      const rh = renderWithWrapper(header, React.Fragment, 'header');
      headerContent.push(rh);
    }
    if (headerActions) {
      const renderedHeaderActions = renderWithWrapper(headerActions, ExCardHeaderActionsStyled, 'headerActions');
      headerContent.push(renderedHeaderActions);
    }
    return headerContent.length ? renderWithWrapper(headerContent, ExCardHeaderStyled) : null;
  }, [header, headerActions, renderWithWrapper, title]);

  const renderedFooter = useMemo(() => renderWithWrapper(footer, ExCardFooterStyled), [footer, renderWithWrapper]);
  const renderedRows = useMemo(() => renderWithWrapper(renderRows, React.Fragment), [renderRows, renderWithWrapper]);

  return (
    <ExCardStyled className={className}>
      {renderedHeader}
      <ExCardBodyStyled bodyMaxHeight={bodyMaxHeight}>{children ?? renderedRows}</ExCardBodyStyled>
      {renderedFooter}
    </ExCardStyled>
  );
};

export const ExCardHoverable = <T extends object>({
  entity,
  handleSelect,
  preventNavigation,
  preventSelect,
  nameClickPath,
  children,
  ...props
}: HoverableExCardProps<IsEntity<T>>) => {
  const [isHovered, handlerMouseOver, handlerMouseLeave] = useMouseHover<HTMLDivElement>();

  const { formatDateToDefault } = useDateDefaultFormatFunction();

  const { handleClick, handleClickTick, modalPath } = useModalPath<T>({
    entity,
    handleSelect,
    preventSelect,
    preventNavigation,
    nameClickPath,
  });

  if (children && isFunction(children)) {
    return (
      <HoverableExCardStyled
        onMouseOver={handlerMouseOver}
        onMouseLeave={handlerMouseLeave}
        onClick={handleClick}
        {...props}
      >
        <WrapperWithLink {...{ modalPath, preventNavigation }}>
          {children({
            isHovered,
            entity,
            formatDateToDefault,
            handleClickTick,
            modalPath,
          })}
        </WrapperWithLink>
      </HoverableExCardStyled>
    );
  }
  return (
    <HoverableExCardStyled
      onMouseOver={handlerMouseOver}
      onMouseLeave={handlerMouseLeave}
      onClick={handleClick}
      {...props}
    >
      <WrapperWithLink {...{ modalPath, preventNavigation }}>{children}</WrapperWithLink>
    </HoverableExCardStyled>
  );
};

export const ExCardWithoutShadow = styled(ExCard)`
  box-shadow: none;
  height: 100%;
  flex-grow: 1;
  border-radius: ${getThemeBorderProps('borderRadiusSm')} !important;
`;

export const ExTableCardWrapper = styled(ExCard)`
  & > ${ExCardBodyStyled} {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;
