import { createSlice } from '@reduxjs/toolkit';

import {
  TalentPoolFormsCaseReducer,
  TalentPoolFormsState,
} from 'containers/TalentPoolForms/state/talentPoolForms.models';

import { updateState } from 'utils/reducer/updateState';

import { TalentPool } from 'store/entities/talent-pools/models';

export const talentPoolFormKey = 'formTalentPool';

export const formTalentPoolInitialState: TalentPoolFormsState = {
  selectedTalentPoolsIds: [],
  candidateTalentPoolIds: [],
  apiErrors: [],
};

const clearFormTalentPool: TalentPoolFormsCaseReducer<any> = (state) => ({
  ...state,
  ...formTalentPoolInitialState,
});

const toggleSelectedTalentPoolId: TalentPoolFormsCaseReducer<{
  talentPoolId: TalentPool['id'];
}> = (state, { payload }) => {
  const idx = state.selectedTalentPoolsIds.findIndex((id) => id === payload.talentPoolId);
  if (idx > -1) {
    state.selectedTalentPoolsIds.splice(idx, 1);
  } else {
    state.selectedTalentPoolsIds.push(payload.talentPoolId);
  }
};

const setSelectedTalentPoolsIds: TalentPoolFormsCaseReducer<{
  selectedTalentPoolsIds: Array<TalentPool['id']>;
}> = (state, { payload }) => ({ ...state, ...payload });

const setCandidateTalentPoolsIds: TalentPoolFormsCaseReducer<{
  candidateTalentPoolIds: Array<TalentPool['id']>;
}> = (state, { payload }) => ({ ...state, ...payload });

type TalentPoolFormsCaseReducers = {
  clearFormTalentPool: typeof clearFormTalentPool;
  toggleSelectedTalentPoolId: typeof toggleSelectedTalentPoolId;
  setSelectedTalentPoolsIds: typeof setSelectedTalentPoolsIds;
  setCandidateTalentPoolsIds: typeof setCandidateTalentPoolsIds;
  updateState: TalentPoolFormsCaseReducer<Partial<TalentPoolFormsState>>;
};

const talentPoolFormsSlice = createSlice<TalentPoolFormsState, TalentPoolFormsCaseReducers>({
  name: talentPoolFormKey,
  initialState: formTalentPoolInitialState,
  reducers: {
    clearFormTalentPool,
    toggleSelectedTalentPoolId,
    setSelectedTalentPoolsIds,
    setCandidateTalentPoolsIds,
    updateState,
  },
});

export const talentPoolFormActions = talentPoolFormsSlice.actions;

export const talentPoolFormReducer = talentPoolFormsSlice.reducer;

export default talentPoolFormsSlice;
