import { useCallback } from 'react';

import { PipelineStageType } from 'model/api-enums.constants';

import { useSinglePipeline } from 'pages/Jobs/Job/hooks/useSinglePipeline';

import { loaderSelectors } from 'containers/Loader/store';

import { useAppSelector } from 'utils/hooks/useSelectors';

import { companySelectors } from 'store/company/company.selectors';
import { Applicant } from 'store/entities/applicants';
import { hiringPipelinesStagesSelectors } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.reducer';
import type { HiringPipelineStage } from 'store/entities/hiring-pipeline-stages/hiring-pipeline-stages.types';
import { Job } from 'store/entities/jobs';
import { loadJobWorkflowTaskId } from 'store/entities/jobs/job.constants';
import { useJobPipelinesQuery } from 'store/entities/jobs/jobs.api';

export type Workflow = (HiringPipelineStage & { applicants: Applicant[] })[];

export function useWorkflow(jobId: Job['id']): {
  workflow: Workflow;
  pipelineStages: HiringPipelineStage[];
  selectPipelineStageCallback: (stageId: HiringPipelineStage['pipelineStageId']) => HiringPipelineStage | undefined;
  isWorkflowLoading: boolean;
} {
  useJobPipelinesQuery({ jobId });

  const isWorkflowLoading = useAppSelector(loaderSelectors.isTaskActive, { taskId: loadJobWorkflowTaskId });

  useSinglePipeline({ jobId });

  const workflow = useAppSelector(hiringPipelinesStagesSelectors.getWorkflowByJobId, jobId);
  const pipelineStages = useAppSelector(hiringPipelinesStagesSelectors.selectJobPipelineStages, jobId);

  const selectPipelineStageCallback = useCallback(
    (stageId: HiringPipelineStage['pipelineStageId']) => {
      return pipelineStages.find((stage) => stage.pipelineStageId === stageId);
    },
    [pipelineStages],
  );

  return { workflow, pipelineStages, selectPipelineStageCallback, isWorkflowLoading };
}

export const usePipelineStageChecks = () => {
  const isEnabledInHr = useAppSelector(companySelectors.selectIsEnabledInHr);

  const isInOnboardingDisabled = useCallback(
    ({ stageType }: { stageType: PipelineStageType }) => {
      return isEnabledInHr && stageType === PipelineStageType.InOnboarding;
    },
    [isEnabledInHr],
  );

  return {
    isInOnboardingDisabled,
  };
};
