import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Routes } from 'router';
import styled from 'styled-components';

import { CardTitle } from 'components/CardTitle';
import { Ellipsis } from 'components/Ellipsis';
import { JobActionButton } from 'components/JobActionButton';
import { SwitchChildren } from 'components/SwitchChildren';
import { ExDeadlineBadge } from 'components/ui/ExDeadlineBadge/ExDeadlineBadge';
import { useJobAnalytics } from 'utils/hooks/job';
import { useNavigation } from 'utils/hooks/useNavigation';

import { JobCardProps } from './JobListProps';

function useJobCardState({ list, listId, className, job }: JobCardProps) {
  const [hover, setHover] = useState(false);
  const handlerMouseOver = () => setHover(true);
  const handlerMouseLeave = () => setHover(false);
  const { generatePath } = useNavigation();
  const { jobId } = job;
  const analytics = useJobAnalytics(jobId);

  const linkTo = React.useMemo(
    () => generatePath(`${Routes.jobs}/${jobId}/${Routes.jobOverview}`, { searchKeys: ['status'] }),
    //eslint-disable-next-line
    [jobId],
  );
  return { job, className, analytics, listId, list, linkTo, hover, handlerMouseLeave, handlerMouseOver } as const;
}

const JobCard: React.FC<JobCardProps> = (props) => {
  const { analytics, linkTo, listId, list, className, handlerMouseLeave, handlerMouseOver, hover, job } =
    useJobCardState(props);

  return (
    <div className={className} onMouseLeave={handlerMouseLeave} onMouseOver={handlerMouseOver}>
      <CardTitle>
        {list ? (
          <>
            <div>
              <Ellipsis className="ref" title={job.referenceNo}>
                Ref: {job.referenceNo}
              </Ellipsis>
              <Link to={linkTo} className="card-title">
                <Ellipsis title={job.jobName}>{job.jobName || 'No Title'}</Ellipsis>
              </Link>
            </div>
            <SwitchChildren switch={hover}>
              <JobActionButton listId={listId} job={job} size="sm" />
              <ExDeadlineBadge deadline={job?.deadline} prependIcon />
            </SwitchChildren>
          </>
        ) : (
          <span>Activity Summary</span>
        )}
      </CardTitle>
      <div className="items row text-center">
        {analytics.map((step) => {
          return (
            <div className="item" key={step.name}>
              <div className="item-number">{step.count}</div>
              <div className="item-text">{step.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default styled(JobCard)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.borderRadius};
  border: 1px solid ${({ theme }) => theme.border.borderColorOuter};
  overflow: hidden;
  min-width: 320px;

  .ref {
    font-size: 10px;
  }

  .card-title {
    color: inherit;
  }

  .items {
    margin-left: -1px;
    margin-right: -1px;
  }

  .item {
    padding-top: 0.5em;
    padding-bottom: 1em;
    border-width: ${({ list }) => (list ? '1px 1px 0 0' : '1px 0 0 0')};
    border-style: solid;
    border-color: ${({ theme }) => theme.border.borderColorInner};
    flex: 1 1 ${(props) => (props.list ? '33%' : 'calc(100% / 6)')};
    min-width: 110px;
  }

  .item-number {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .item-text {
    text-transform: uppercase;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.subtitle};
  }
`;
