import React from 'react';
import styled from 'styled-components';

import { useShowModal } from 'pages/Company/CompanyTabs/hooks/useShowModal';

import { ExModalBodyStyledWide } from 'containers/Modals/ModalsContent/Company/ExModalComponents';

import { ExDialogActionButton, ExDialogActions } from 'components/ui/ExDialog';

import { JobTeamMember } from 'store/entities/jobs';
import { jobTeamMemberPrepareRemove } from 'store/entities/jobs/job.actions';
import { wizardBackward } from 'store/modals/modals.actions';

const MessageWrapper = styled.div`
  min-height: 100px;
  max-width: 80%;
  margin: auto;
  text-align: center;
`;
type JobTeamRemoveMemberModalProps = {
  className?: string;
  id: string;
} & { modalProps: { teamMember: JobTeamMember; jobId: string } };

const JobTeamRemoveMemberModalStyled = styled(ExModalBodyStyledWide)``;

const useJobTeamRemoveMemberModalState = ({
  id,
  className,
  modalProps: { teamMember, jobId },
}: JobTeamRemoveMemberModalProps) => {
  const { userId, name } = teamMember;
  const { clickHandler } = useShowModal();
  const onBackClickHandler = clickHandler.bind(null, wizardBackward({ id }));
  const onConfirmClickHandler = clickHandler.bind(null, jobTeamMemberPrepareRemove({ jobId, userId }));

  return {
    className,
    name,
    onBackClickHandler,
    onConfirmClickHandler,
  };
};

export const JobTeamRemoveMemberModal: React.FC<JobTeamRemoveMemberModalProps> = (props) => {
  const { className, name, onBackClickHandler, onConfirmClickHandler } = useJobTeamRemoveMemberModalState(props);

  return (
    <JobTeamRemoveMemberModalStyled className={className}>
      <MessageWrapper>You confirm remove {name}</MessageWrapper>
      <ExDialogActions>
        <ExDialogActionButton variant="light" onClick={onBackClickHandler()}>
          Back
        </ExDialogActionButton>
        <ExDialogActionButton variant="primary" onClick={onConfirmClickHandler()}>
          Confirm
        </ExDialogActionButton>
      </ExDialogActions>
    </JobTeamRemoveMemberModalStyled>
  );
};
