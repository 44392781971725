import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { findLocations } from 'api-endpoints/dictionary';
import { FindLocationsParams } from 'api-endpoints/dictionary/models';

import { Location } from 'model';

import { alertsEffects } from 'containers/AlertManager/store/alert.actions';
import { loaderActions } from 'containers/Loader/store';

import { AppEffectParams, ThunkApiConfig } from 'store/types';

const LOCATION_FIND = 'location/find';

export const findLocationsThunk = createAsyncThunk<Location[], AppEffectParams<FindLocationsParams>, ThunkApiConfig>(
  LOCATION_FIND,
  async ({ preloader, params }, { dispatch, signal, requestId }) => {
    const source = axios.CancelToken.source();

    const { countryCode, placeName } = params;
    /**
     * Deny user find location if search string is less then 3 symbols.
     */
    if (placeName.trim().length < 3) {
      return [];
    }
    if (preloader) {
      dispatch(loaderActions.start(requestId));
    }
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    const { data, message } = await findLocations({
      cancelToken: source.token,
      params: { countryCode, placeName, pageSize: 100, pageNo: 0 },
    });
    if (preloader) {
      dispatch(loaderActions.stop(requestId));
    }
    if (message) {
      dispatch(alertsEffects.showError({ message }));
      return [];
    }

    return data.items;
  },
);
